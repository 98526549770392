<template>
  <st-modal v-model="showModal">
    <template v-slot:modalTitle>
      <span class="font-bold">{{ title }}</span>
    </template>
    <template v-slot:modalActions>
      <div class="st-team__add-members-modal-body py-1">
        <template>
          <div class="st-team__add my-3">
            <p class="mt-10 text-black font-600">
              {{ description }}
            </p>
            <p class="my-3 text-gray-two">
              To continue, please upgrade your plan.
            </p>
          </div>
        </template>
      </div>
    </template>

    <template v-slot:modalButton>
      <div class="flex space-x-2 justify-end">
        <button
          class="bg-gray-100 text-gray-two px-3 py-3 rounded-4p"
          @click="toggle"
        >
          Cancel
        </button>
        <st-button
          caption="Upgrade plan"

          icon-name="add-member"
          :show-action="true"
          @click="upgradePlan"
        />
      </div>
    </template>
  </st-modal>
</template>

<script>
import { StModal, StButton } from '../../common'
export default {
  name: 'UpgradePlanModal',

  components: {
    StModal,
    StButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  },

  data () {
    return {
      showModal: false
    }
  },

  computed: {},

  mounted () {},

  methods: {
    upgradePlan () {
      window.location.href = '/plans/upgrade_plan'
    },
    toggle () {
      this.showModal = !this.showModal
    }
  }
}
</script>
