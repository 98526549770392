<template>
  <div v-if="responses.length">
        
    <!-- <div
      class="st-bar-graph-stack__timeline-label flex items-center mt-8p mb-20p justify-between"
    >
      <div class="flex items-center  ">
        <span class="font-16p"> {{ currentPageLabel }}</span>
        <span
          class="st-bar-graph-stack__change-graph-buttons ml-10p cursor-pointer"
          @click="changePage(-1)"
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 4 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.501062 3.52511C0.506541 3.58676 0.531931 3.64501 0.573623 3.69167L2.96827 6.39218C3.01945 6.45357 3.09402 6.49215 3.17486 6.49893C3.25571 6.5057 3.33589 6.48016 3.39722 6.42816C3.45843 6.37628 3.49531 6.30225 3.49958 6.22327C3.50386 6.14416 3.475 6.06687 3.41967 6.009L1.19567 3.50008L3.41967 0.991164C3.475 0.933166 3.50386 0.855878 3.49958 0.776765C3.49531 0.697783 3.45843 0.623754 3.39722 0.571751C3.33602 0.519748 3.25571 0.494203 3.17486 0.50111C3.09402 0.508018 3.01945 0.546466 2.96827 0.607853L0.573623 3.30836C0.520439 3.36806 0.494247 3.44626 0.501062 3.52498L0.501062 3.52511Z"
              fill="black"
            />
          </svg>
        </span>
        <span
          class="st-bar-graph-stack__change-graph-buttons ml-4p cursor-pointer"
          @click="changePage(1)"
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 4 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.49894 3.47489C3.49346 3.41324 3.46807 3.35499 3.42638 3.30833L1.03173 0.607816C0.980548 0.546429 0.905982 0.50785 0.825136 0.501073C0.74429 0.494295 0.664112 0.519841 0.602776 0.571844C0.541574 0.623716 0.504692 0.697746 0.500416 0.776728C0.496139 0.85584 0.525004 0.933128 0.580326 0.990996L2.80433 3.49992L0.580326 6.00884C0.525004 6.06683 0.496139 6.14412 0.500416 6.22323C0.504692 6.30222 0.541574 6.37625 0.602776 6.42825C0.663979 6.48025 0.74429 6.5058 0.825136 6.49889C0.905982 6.49198 0.980548 6.45353 1.03173 6.39215L3.42638 3.69164C3.47956 3.63194 3.50575 3.55374 3.49894 3.47502V3.47489Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
    </div> -->
    <div class="flex items-center justify-between mt-4 flex-col md:flex-row">
      <div class="rounded-8p tw-hidden md:tw-flex md:tw-w-full" v-if="showSelectors">
        <span
          :class="[
            {
              'st-bar-graph-stack__trend-buttons cursor-pointer': true,
            },
            trend == 'TOTAL' ? 'bg-blue-primary text-white' : 'border-1-grey',
          ]"
          @click="changeTrend('TOTAL')"
        >
          Total sentiment
        </span>
        <span
          :class="[
            {
              'st-bar-graph-stack__trend-buttons2 cursor-pointer': true,
            },
            trend == 'STACK' ? 'bg-blue-primary text-white' : 'border-1-grey',
          ]"
          @click="changeTrend('STACK')"
        >
          Stack trend
        </span>
        <div
          class=" flex items-center ml-20p
      "
        >
          <st-checkbox
            :checked="showTrendLine"
            class="st-survey-insights__checkbox mb-0"
            @input="toggleShowTrendLine"
            v-if="trend === 'STACK'"
          >
            <span class="text-16p">Show trend line</span>
          </st-checkbox>
        </div>
      </div>
      <p class="tw-px-3 tw-text-lg tw-font-semibold tw-w-full md:tw-hidden">
        Total sentiments
      </p>
      <div class="mt-3 md:tw-px-3 md:mt-0 tw-flex tw-w-full tw-justify-between md:tw-justify-end  tw-space-x-1">
        <p v-if="title" class="st-diverging-bar-graph__title">
          {{ title }}
        </p>
        <div class="st-diverging-bar-graph__key tw-hidden md:tw-flex">
          <div
            v-for="name in bucketNames"
            :key="name"
            class="st-diverging-bar-graph__key-color-and-value"
          >
            <div
              :class="[
                { 'st-diverging-bar-graph__key-color': true },
                sentimentColor(name),
              ]"
            />
            <p class="st-diverging-bar-graph__key-value">
              {{ name }}
            </p>
          </div>
        </div>

        <div
            v-for="(bar, index) in activeBucketsWithVotesAndPercents"
            :key="index"
           class="md:tw-hidden"
          >
            
          <div
          class="tw-flex tw-justify-center tw-items-center tw-flex-col"
          >
          <span class="st-diverging-bar-graph__key-color tw-w-6 tw-h-6 tw-flex tw-justify-center tw-items-center tw-flex-col "      :class="[{'tw-text-white': bar.name!='Neutral' }, sentimentColor(bar.name)]">
            {{ bar.count  }}
          </span>   
             <span class=" tw-text-xs">
              {{ bar.name }}
             </span>    

              </div>
          </div>
        <!-- <div
          v-for="(d, i) in filteredSentiments"
          :key="i"
          class="st-diverging-bar-graph__key-color-and-value tw-flex  tw-items-center md:tw-hidden "
        >
          <div
            v-for="(score, j) in d.scores"
            :key="j"
            

          > 
          <span class="st-diverging-bar-graph__key-color tw-w-6 tw-h-6 tw-flex tw-justify-center tw-items-center"             :style="stackItemStyle(d.scores, j)">
            {{ score  }}
          </span>   
             <span class=" tw-text-xs">
              {{ bucketNames[j] }}
             </span>    
          </div>

        </div> -->


        <!-- <div
            v-for="(name, index ) in bucketNames"
            :key="name"
            class="st-diverging-bar-graph__key-color-and-value tw-flex tw-flex-col tw-items-center md:tw-hidden "
          >
          
            <div
              :class="[
                { 'st-diverging-bar-graph__key-color tw-w-6 tw-h-6 tw-flex tw-justify-center tw-items-center tw-text-xs': true },
                sentimentColor(name),
              ]"
            >
            {{ filteredSentiments[0].scores[index] }}
            </div>
            <p class="st-diverging-bar-graph__key-value tw-text-xs">
              {{ name }} 
            </p>
           
          </div> -->




      </div>
    </div>
    <div v-if="trend === 'STACK'" class="st-diverging-bar-graph__key">
      <!-- <div
        v-for="name in bucketNames"
        :key="name"
        class="st-diverging-bar-graph__key-color-and-value"
      >
        <div
          :class="[
            { 'st-diverging-bar-graph__key-color': true },
            sentimentColor(name),
          ]"
        />
        <p class="st-diverging-bar-graph__key-value">
          {{ name }}
        </p>
      </div> -->
    </div>
    <div v-if="trend === 'STACK' || defaultType==='STACK' || defaultType === 'STACKTRNEDLINE'" class="relative">
      <line-graph v-if="showTrendLine || defaultType === 'STACKTRNEDLINE'" :averages="stackAverages" />
      <div class="st-bar-graph-stack__container">
        <div
          v-for="(d, i) in filteredSentiments"
          :key="i"
          class="st-bar-graph-stack__stack-container "
        >
          <!-- <span class="st-bar-graph-stack__stack-label text-13p flex flex-col"
            >{{ stackLabel(d, i)["weekNo"] }}
            <span class="text-16p my-1">
              {{ stackLabel(d, i)["date"] }}
            </span>
          </span> -->

          <div
            v-for="(score, j) in d.scores.slice(2)"
            :key="j"
            :style="stackItemStyle(d.scores, j + 2)"
            class="graph-stack-bar"
          >
            <st-tooltip
              v-if="score > 0"
              :key="'tip' + j + 2"
              class="st-diverging-bar-graph__tooltip"
              message-class="st-diverging-bar-graph__tooltip-message"
              :max-width="isDesktop ? 411 : 125"
              :tip="score + ' participant felt ' + bucketNames[j + 2]"
            >
              <div class="st-diverging-bar-graph__tooltip" />
            </st-tooltip>
          </div>
        </div>
      </div>
      <div class="st-bar-graph-stack__container-second">
        <div
          v-for="(d, i) in filteredSentiments"
          :key="i"
          class="st-bar-graph-stack__stack-container-second "
        >
          <div
            v-for="(score, j) in d.scores.slice(0, 2)"
            :key="j"
            :style="stackItemStyle(d.scores, j)"
            class="graph-stack-bar2"
          >
            <st-tooltip
              v-if="score > 0"
              :key="'tip' + j"
              class="st-diverging-bar-graph__tooltip"
              message-class="st-diverging-bar-graph__tooltip-message"
              :max-width="isDesktop ? 411 : 125"
              :tip="score + ' participant felt ' + bucketNames[j]"
            >
              <div class="st-diverging-bar-graph__tooltip" />
            </st-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="{
        'st-opinion-metric-insights-grid st-opinion-metric-insights__block': true,
        'st-opinion-metric-insights-grid__no-media ': true,
      }"
    >
      <div
        :class="{
          'st-opinion-metric-insights-grid__average-and-bar-graph': true,
          'st-opinion-metric-insights-grid__average-and-bar-graph--no-media ': true,
        }"
      >
     
        <div class="">
          <diverging-bar-graph :sentiment-values="allResponsesSentiments" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  distributeInWeeks,
  distributeInWeekDays,
  distributeInMonths,
noFilterDistribution,
} from "../../../utilities/response_utilities";
import { StButton, StTooltip, StCheckbox } from "../../common";

import DivergingBarGraph from "./diverging-bar-graph.vue";
import { WINDOW_TYPE } from "../../../components/pages/surveys/constants";
import { kebabCase } from "lodash";
import { isDesktop } from "../../../mixins";
import { bucketCounts, percent, sum } from "../../../utilities/data_utilities";
const TREND_TYPE = {
  TOTAL: "TOTAL",
  STACK: "STACK",
  STACKTRNEDLINE: 'STACKTRNEDLINE'
};

const SENTIMENT_COLORS = [
  "#ac5555",
  "#f9776f",
  "#e9e9e9",
  "#667e6e",
  "#7ecd87",
];

export default {
  name: "BarGraphStack",
  components: {
    StButton,
    DivergingBarGraph,
    StTooltip,
    StCheckbox,
    LineGraph: () => import("./line-graph"),
  },
  mixins: [isDesktop],
  props: {
    defaultType: {
      type : String,
      required: false,
      default: TREND_TYPE.TOTAL
    },
    window: {
      type: String,
      required: true,
    },
    showSelectors: {
      type: Boolean,
      required: false,
      default:true
    },
    responses: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: function() {
    return {
      showTrendLine: false,
      bucketNames: [
        "Very negative",
        "Negative",
        "Neutral",
        "Positive",
        "Very positive",
      ],
      title: "",
      trend: TREND_TYPE.TOTAL,
      currentPage: 0,
      totalPages: 0,
    };
  },

  computed: {
    activeBucketNames () {
      return Object.keys(this.numberOfResponsesPerBucket).filter((key, value) => {
        return this.numberOfResponsesPerBucket[key] > 0
      }).map(key => { return `${this.bucketNames[key]}` })
    },
    activeBucketsWithVotesAndPercents () {
      return Object.fromEntries(
        this.activeBucketNames.map((name, i) => [name, {
          count: this.reducedNumberOfResponsesPerBucked[i],
          name: name,
          percent: this.percentOfVotes[i],
          tooltip: `${this.categoryBarTooltip[i] + name}`
        }])
      )
    },
    barsContainerStyle () {
      return { left: this.neutralOffsetFraction * 100 + '%' }
    },
    categoryBarTooltip () {
      return this.unitsOfMeasureIsVotes ? this.participantTooltipArray : this.percentTooltipArray
    },
    centerAxisLabel () {
      return this.unitsOfMeasureIsVotes ? '0' : '0%'
    },
    dashedGridStyling () {
      return {
        height: this.dashedHeight + 10 + 'px',
        width: this.dashedWidth + 'px'
      }
    },
    maxAxisLabel () {
      return this.unitsOfMeasureIsVotes ? `${this.numberOfVotes}` : '100%'
    },
    midAxisLabel () {
      return this.unitsOfMeasureIsVotes ? `${(this.numberOfVotes / 2)}` : '50%'
    },
    neutralCenterFraction () {
      let bucketWidths = this.numberOfResponsesPerBucket.map(value => value / this.numberOfVotes)
      let result = bucketWidths[0] + bucketWidths[1]
      result += bucketWidths[2] / 2
      return result / 2
    },
    neutralOffsetFraction () {
      return 0.5 - this.neutralCenterFraction
    },
    numberOfResponsesPerBucket () {
      return bucketCounts(this.sentimentValues)
    },
    numberOfVerticalLines () {
      return this.isDesktop ? 9 : 5
    },
    numberOfVotes () {
      return sum(this.reducedNumberOfResponsesPerBucked)
    },
    participantTooltipArray () {
      return this.reducedNumberOfResponsesPerBucked.map(value => {
        return value === 1 ? `1 participant felt ` : `${value} participants felt `
      })
    },
    percentTooltipArray () {
      return this.percentOfVotes.map(value => {
        return `${value}% felt `
      })
    },
    percentOfVotes () {
      return this.reducedNumberOfResponsesPerBucked.map(value => {
        return percent(value, this.numberOfVotes, 0)
      })
    },
    reducedNumberOfResponsesPerBucked () {
      return this.numberOfResponsesPerBucket.filter(responses => { return responses > 0 })
    },
    unitsOfMeasureIsVotes () {
      return this.activeQuestionModifier === 'votes'
    },
    numberOfVotes () {
      return sum(this.reducedNumberOfResponsesPerBucked)
    },
    reducedNumberOfResponsesPerBucked () {
      return this.numberOfResponsesPerBucket.filter(responses => { return responses > 0 })
    },
    numberOfResponsesPerBucket () {
      return bucketCounts(this.allResponsesSentiments)
    },
    filteredSentiments() {
      let filteredResponses = [];
      let primaryFilter = null;
      let secondaryFilter = null;
     
      if(this.window === WINDOW_TYPE.ALL){
        primaryFilter = noFilterDistribution
        secondaryFilter = noFilterDistribution
        filteredResponses = primaryFilter(this.responses);
        return filteredResponses
      }
      else if (this.window === WINDOW_TYPE.MONTHLY) {
        primaryFilter = distributeInMonths;
        secondaryFilter = distributeInWeeks;
      } else if (this.window === WINDOW_TYPE.WEEKLY) {
        primaryFilter = distributeInWeeks;
        secondaryFilter = distributeInWeekDays;
      }
      
      filteredResponses = primaryFilter(this.responses);
      this.totalPages = filteredResponses.length;
      if (this.currentPage >= this.totalPages)
        this.currentPage = this.totalPages - 1;
      filteredResponses = secondaryFilter(
        filteredResponses[this.currentPage].responses
      );
      return filteredResponses;
    },
    allResponsesSentiments() {
      const responses = [];
      this.filteredSentiments.forEach(({ responses: res }) =>{
        responses.push(...res)
      }
      );
      const temp = responses.map(({ sentimentScore }) => sentimentScore);
      // return 0
      return temp;
    },
    stackAverages() {
      const averages = this.filteredSentiments.map(
        ({ responses }) =>
          responses.reduce((prev, curr) => prev + curr.sentimentScore, 0) /
          (responses.length || 1)
      );
      return averages;
    },
    // currentPageLabel() {
    //   const responses = this.filteredSentiments;
    //   if (this.window === WINDOW_TYPE.WEEKLY) {
    //     return `From ${responses[0].start.format("ddd DD")} to ${responses[
    //       responses.length - 1
    //     ].end.format("ddd DD")}`;
    //   } else if (this.window === WINDOW_TYPE.MONTHLY)
    //     return `${responses[0].start.format("MMMM")}`;
    // },
  },
  // mounted(){
  //   console.log(this.defaultType)
  // },
  methods: {
    getPositiveResponses(scores) {
      return scores.splice(0, 2);
    },
    toggleShowTrendLine() {
      this.showTrendLine = !this.showTrendLine;
    },
    stackItemStyle(scores, index) {
      const max = Math.max(
        ...this.filteredSentiments.map(({ scores }) => Math.max(...scores))
      );
      return {
        backgroundColor: SENTIMENT_COLORS[index],
        flex: scores[index] / max,
      };
    },
    stackLabel(data, index) {
      console.log(this.window, this.trend, this.defaultType, "whateverrrrr")
      if (
        this.window === WINDOW_TYPE.WEEKLY &&
        (this.trend === TREND_TYPE.STACK || this.defaultType=== TREND_TYPE.STACK || this.defaultType === TREND_TYPE.STACKTRNEDLINE)
        ) {
        console.log(data, index, "whateverrrrr")
        return { date: data.start.format("ddd D, YYYY") };
      } else if (
        this.window === WINDOW_TYPE.MONTHLY &&
        (this.trend === TREND_TYPE.STACK || this.defaultType === TREND_TYPE.STACK || this.defaultType === TREND_TYPE.STACKTRNEDLINE)
      ) {
        console.log(`Week ${`00${index + 1}`?.slice(-2)}`, "whateverrrrr")
        return {
          weekNo: `Week ${`00${index + 1}`?.slice(-2)}`,
          date: `${data.start.format("ddd DD")} - ${data.end.format("ddd DD")}`,
        };
      }
    },
    changePage(count) {
      let newPageIndex = this.currentPage + count;
      if (newPageIndex < 0 || newPageIndex >= this.totalPages) return;
      this.currentPage = newPageIndex;
    },
    sentimentsByBucket(bucket){
      const responses = this.filteredSentiments;
      console.log(responses, "responses")
    },
    changeTrend(selectedTrend) {
      this.trend = selectedTrend;
    },
    sentimentColor(value) {
      return { [`st-sentiment-badge--${kebabCase(value)}`]: true };
    },
  },
};
</script>
