<template>
  <div class=" tw-my-5 tw-flex tw-flex-col tw-justify-between tw-h-full">
    <div class="tw-max-w-full tw-mt-3 tw-min-h-[50vh] ">
      <div class="tw-w-full tw-mb-8 tw-overflow-hidden">
        <div class="tw-w-full tw-overflow-x-auto">
          <div class="tw-w-full">
            <div
              class="tw-tracking-wide tw-grid tw-grid-cols-12 tw-my-2 tw-justify-between tw-text-left tw-text-gray-900 tw-text-sm tw-border-solid tw-border-b tw-border-gray-100"
            >
              <div class="tw-px-2 tw-py-3">
                Status
              </div>
              <div class="tw-px-2 tw-py-3">
                First name
              </div>
              <div class="tw-px-2 tw-py-3">
                last name
              </div>
              <div class="tw-px-2 tw-py-3 tw-col-span-4">
                email
              </div>
              <div class="tw-px-2 tw-py-3">
                Role
              </div>
              <div class="tw-px-2 tw-py-3 tw-col-span-2">
                Account
              </div>
              <div class="tw-px-2 tw-py-3">
                Last login
              </div>
              <!-- <div class="tw-px-2 tw-py-3 tw-col-s tw-col-span-2" /> -->
            </div>

            <div class="tw-bg-white">
              <div
                v-for="user in usersData.data"
                :key="user.user_id"
                class="tw-text-gray-700 tw-my-3 tw-relative tw-grid tw-grid-cols-12 tw-justify-between tw-items-center "
              >
                <div
                  class="tw-px-2 tw-py-3 tw-text-ms tw-cursor-text tw-font-semibold"
                >
                  <study-status>
                    Active
                  </study-status>
                </div>

                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ user.first_name }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ user.last_name }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm tw-col-span-4">
                  {{ user.email }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ user.role }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm tw-col-span-2">
                  {{ user.account_name }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ user.last_login }}
                </div>
                <!-- <div class="tw-px-2 tw-py-3 tw-text-sm tw-col-span-2">
                  <span
                    class="tw-px-3 tw-py-2 tw-border tw-border-gray-200 tw-rounded-md tw-border-solid tw-font-semibold"
                  >
                    CoBrowse
                  </span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :current-page="page"
      :per-page="usersData.per_page"
      :total-pages="usersData.total_pages"
      title="Users per page"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { Pagination, StudyStatus } from '../common'
export default {
  components: {
    Pagination,
    StudyStatus
  },
  props: {
    accountId: {
      type: Number,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      usersData: [],
      per_page: 5,
      page: 1
    }
  },

  mounted () {
    this.$store.commit('toggleFullSpinner', true)
    setTimeout(() => {
      this.getAccountUsers()
    }, 150)
  },

  methods: {
    getAccountUsers () {
      this.$axios({
        method: 'get',
        url: `/users/get_all_users?account_id=${this.accountId}&per_page=${this.per_page}&page=${this.page}`
      })
        .then((res) => {
          console.log(res.data)
          this.usersData = res.data
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    changePage (currentPage, perPage) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'get',
        url: `/users/get_all_users?per_page=${perPage}&page=${currentPage}`
      })
        .then((res) => {
          console.log(res.data)
          this.usersData = res.data
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
