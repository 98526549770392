<template>
  <div class="icon_checkmark">
    <div class="icon_checkmark__svg">
      <svg

        width="14"
        height="14"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.72313 3.79387H8.90811C8.89477 3.64448 8.84702 3.49589 8.76211 3.36003C8.47375 2.89855 7.86577 2.75821 7.40441 3.04667C7.40437 3.0467 7.40432 3.04673 7.40428 3.04675L4.3334 4.96612L3.8936 4.30643C3.89356 4.30637 3.89352 4.30631 3.89348 4.30625C3.59176 3.85345 2.97987 3.73114 2.52718 4.03307C2.07468 4.33487 1.95205 4.94658 2.25404 5.39944C2.25406 5.39946 2.25407 5.39949 2.25409 5.39952L3.2247 6.85533C3.52032 7.29876 4.11493 7.4267 4.56678 7.1442L8.44897 4.71788L9.74939 3.90514C9.81713 4.21025 9.85286 4.52742 9.85286 4.85294C9.85286 7.25712 7.90409 9.20588 5.49991 9.20588C3.09574 9.20588 1.14697 7.25712 1.14697 4.85294C1.14697 2.44877 3.09574 0.5 5.49991 0.5C7.53881 0.5 9.25017 1.90158 9.72313 3.79387Z"
          fill="white"
          stroke="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
