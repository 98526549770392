<template>
  <div
    class="tw-sticky tw-top-0 tw-z-[9] tw-px-4 tw-py-2 tw-flex tw-justify-center tw-space-x-4"
    :class="{
      'tw-bg-black-two tw-text-white': feedbackTimeExpired,
      'tw-bg-territory-light tw-text-black': !feedbackTimeExpired
    }"
  >
    <div
      v-if="!feedbackTimeExpired"
      class="tw-flex tw-space-x-3 tw-text-sm tw-justify-center tw-items-center "
    >
      <span class="tw-mt-1">
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 0C12.14 0 15.5 3.36 15.5 7.5C15.5 11.64 12.14 15 8 15C3.86 15 0.5 11.64 0.5 7.5C0.5 3.36 3.86 0 8 0ZM8 1C4.415 1 1.5 3.91 1.5 7.5C1.5 11.09 4.415 14 8 14C11.585 14 14.5 11.085 14.5 7.5C14.5 3.915 11.585 1 8 1ZM7.5 3C7.5 2.725 7.725 2.5 8 2.5C8.275 2.5 8.5 2.725 8.5 3V6.92C8.5 7.1 8.595 7.265 8.75 7.355L11.745 9.065C11.985 9.2 12.07 9.51 11.93 9.745C11.795 9.985 11.485 10.07 11.25 9.93L8.255 8.22C7.79 7.955 7.5 7.455 7.5 6.92V3Z"
            fill="black"
          />
        </svg>
      </span>
      <span>
        Feedback time expires in {{ localHours }} hr, {{ localMinutes }} min
      </span>
    </div>
    <div
      v-else-if="feedbackTimeExpired"
      class="  tw-flex tw-justify-between tw-text-sm  tw-items-center tw-space-x-3 "
    >
      <span
        class="tw-flex
              tw-space-x-3 tw-justify-center"
      >
        <span class="tw-mt-1">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_55_3014)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5 0C11.64 0 15 3.36 15 7.5C15 11.64 11.64 15 7.5 15C3.36 15 0 11.64 0 7.5C0 3.36 3.36 0 7.5 0ZM7.5 1C3.915 1 1 3.91 1 7.5C1 11.09 3.915 14 7.5 14C11.085 14 14 11.085 14 7.5C14 3.915 11.085 1 7.5 1ZM7 3C7 2.725 7.225 2.5 7.5 2.5C7.775 2.5 8 2.725 8 3V6.92C8 7.1 8.095 7.265 8.25 7.355L11.245 9.065C11.485 9.2 11.57 9.51 11.43 9.745C11.295 9.985 10.985 10.07 10.75 9.93L7.755 8.22C7.29 7.955 7 7.455 7 6.92V3Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_55_3014">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </span>
        <span class="tw-mt-[2px]">
          Feedback time expired
        </span>
      </span>
      <ah-button
        v-if="feedbackTimeExpired && showExtendButton"
        type="outlined"
        class="tw-cursor-pointer"
        @click="extendTimeHandler"
      >
        Extend
      </ah-button>
    </div>
    <div>
      <ah-button
        v-if="showShareButton && !feedbackTimeExpired"
        type="outlined"
        @click="sharingHandler"
      >
        Share
      </ah-button>
    </div>
  </div>
</template>

<script>
import {
  AhButton

} from '../../common'
export default {
  name: 'AskhumansFeedbackTime',
  components: {
    AhButton
  },
  props: {
    feedbackTimeExpired: {
      type: Boolean,
      default: false
    },
    hoursRemaining: {
      type: Number,
      default: 0
    },
    minutesRemaining: {
      type: Number,
      default: 0
    },
    extendTimeHandler: {
      type: Function,
      default: () => {}
    },
    showExtendButton: {
      type: Boolean,
      default: false
    },
    showShareButton: {
      type: Boolean,
      default: false
    },
    sharingHandler: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      localMinutes: 0,
      localHours: 0

    }
  },
  // watch for changes in hours and minutes
  // then reload the component
  watch: {
    hoursRemaining () {
      this.localHours = this.hoursRemaining
    },
    minutesRemaining () {
      this.localMinutes = this.minutesRemaining
    }
  },

  mounted () {
    this.localHours = this.hoursRemaining
    this.localMinutes = this.minutesRemaining
    this.startDecreasingTime()
    // this.showExtendButton = true
    // this.feedbackTimeExpired = true
    // console.log(this.localHours, this.localMinutes)
  },

  methods: {
    startDecreasingTime () {
      setInterval(() => {
        if (this.localMinutes > 0) {
          this.localMinutes = this.localMinutes - 1
        } else {
          if (this.localHours > 0) {
            this.localHours = this.localHours - 1
            this.localMinutes = 59
          }
        }
      }, 60000)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
