<template>
  <div class="st-slides">
    <div
      ref="slidesMobile"
      class="st-slides__canvas-main"
      style=" border:none; border-style: hidden; overflow:hidden; overflow-y: hidden"
    >
      <div
        v-for="(templateVal, templateKey, index) in templates"
        :key="'pr'+index"
        :class="{
          'st-slides__block': true,
          'st-slides__block--active': currentTemplate === templateKey,
          'st-slides__block--1x1' : templateKey === '1x1',
          'st-slides__block--1x2' : templateKey === '1x2',
          'st-slides__block--2x1' : templateKey === '2x1',
          'st-slides__block--1x3' : templateKey === '1x3'
        }"
        :style="{backgroundColor: slideColors[index]}"
        style="border:none; overflow-y: hidden"
      >
        <!-- eslint-disable -->
        <span
          v-for="(text, i) in objects.filter(o => o.type === 'text').sort((a,b)=>a.y>b.y?1:-1)"
          :key="'text'+i"
          :style="{position: 'absolute', left: text.x+'%', top: text.y+'%', zIndex: 99, fontSize:i==0?'22px':'', fontWeight:i==0?600:400}"
          v-html="text.content"
          class="st-slides__scale-mobile"
        />
        <!-- eslint-enable -->
        <img
          v-for="(image,i) in objects.filter(o => o.type === 'image').splice(0,1)"
          :key="i"
          :style="{position: 'absolute', objectFit: 'cover', left: image.x+'%', bottom: image.y+'%', width: image.width+'%', height: image.height+'%'}"
          :src="image.blob"
          alt=""
        >
        <template v-if="currentTemplate === templateKey">
          <div
            v-for="(val, boxIndx) in templateVal"
            :key="'preview'+val"
            :ref="'preview-box' + val"
            :class="{
              'st-slides__box': true,
            }"
            :style="{backgroundColor: slideColors[boxIndx], }"
            style=" height:100%;"
          />
        </template>
      </div>
    </div>
    <div class="st-specifications__button-slides-canvas">
      <st-button
        class="st-concept-ranking__button"
        caption="Next"
        icon-name="arrow"
        :underline="false"
        :variant="['primary']"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { StButton } from '../../../../common'
import { SLIDES_TEMPLATES } from '../../../../../utilities/enums'

export default {
  components: {
    StButton
  },
  data: function () {
    return {
      templates: SLIDES_TEMPLATES
    }
  },
  computed: {
    ...mapGetters('specifications', ['color', 'slideColors', 'currentTemplate', 'objects'])
  },
  watch: {
    color (newVal) {
      if (this.$refs[newVal.previewElementRef]) {
        this.$refs[newVal.previewElementRef][0].style.background = newVal.code
      }
    }
  }
}
</script>
