var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"st-slides st-slides__canvas hidden",on:{"click":function($event){return _vm.clickedOnContainer($event)}}},[_vm._l((_vm.templates),function(templateVal,templateKey,index){return [(_vm.currentTemplate === templateKey)?_c('div',{key:index,class:{
        'st-slides__block': true,
        'st-slides__block--active': _vm.currentTemplate === templateKey,
        'st-slides__block--1x1' : templateKey === '1x1',
        'st-slides__block--1x2' : templateKey === '1x2',
        'st-slides__block--2x1' : templateKey === '2x1',
        'st-slides__block--1x3' : templateKey === '1x3'
      },style:({backgroundColor: _vm.slideColors[index], })},_vm._l((templateVal),function(val,boxIndx){return _c('div',{key:'box'+val,ref:'box' + val,refInFor:true,class:{
          'st-slides__box': true,
          'st-slides__box--1x1' : templateKey === '1x1',
          'st-slides__box--1x2' : templateKey === '1x2',
          'st-slides__box--2x1' : templateKey === '2x1',
          'st-slides__box--1x3' : templateKey === '1x3'
        },staticStyle:{"height":"400px"},style:({backgroundColor: _vm.slideColors[boxIndx]})})}),0):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }