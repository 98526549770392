<template>
  <st-drawer
    v-model="showDrawer"
    @closeModal="closeModal"
  >
    <template v-slot:modalTitle>
      <div class="flex justify-between">
        <span class="font-18p">{{ title }}</span>
      </div>
    </template>

    <template v-slot:modalActions>
      <ah-small-loader
        v-if="loading"
        :loading="loading"
      />
      <div
        v-else-if="!studyList.length"
        class="tw-flex tw-space-x-1 tw-justify-center tw-flex-col tw-px-10 tw-h-full"
      >
        <div
          class="tw-flex-col tw-justify-center tw-items-center tw-gap-5 tw-flex tw-h-full"
        >
          <img
            class="tw-w-64"
            :src="cloudGif"
            alt=""
          >
          <div
            class="tw-text-center tw-text-stone-700 tw-text-lg tw-font-['SF Pro']"
          >
            In order to begin searching for answers, you need to have at least
            one study created with Answers enabled and a sufficient number of
            responses.
          </div>
        </div>
      </div>

      <div
        v-else
        class="tw-h-[93%]"
      >
        <div
          v-if="showAnswerSavedRemoved"
          class="tw-text-white tw-bg-success tw-p-2"
        >
          <p class="tw-flex tw-justify-center tw-space-x-3 tw-items-center">
            <span> Answer {{ answerStatus }} </span>
            <span
              class="tw-underline tw-cursor-pointer"
              @click="hideToastMessage"
            >
              Close
            </span>
          </p>
        </div>
        <div class="tw-flex tw-h-full tw-justify-center">
          <div class="tw-w-full ">
            <div
              class=" tw-sticky tw-top-0 tw-bg-white"
              style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
            >
              <div class="tw-flex tw-px-10">
                <button
                  :class="{
                    'tw-border-b-[3px] tw-border-solid tw-border-b-primary':
                      activeTab === 'generate',
                  }"
                  class="tw-flex-1 tw-py-4 tw-px-5 tw-text-center tw-border-l-0 tw-border-t-0 tw-border-r-0"
                  @click="setActiveTab('generate')"
                >
                  Generate
                </button>
                <button
                  id="previous-answers-view"
                  :class="{
                    'tw-border-b-[3px] tw-border-solid tw-border-b-primary':
                      activeTab === 'answerLog',
                  }"
                  class="tw-flex-1 tw-py-4 tw-px-5 tw-bg-white tw-text-center tw-border-l-0 tw-border-t-0 tw-border-r-0"
                  @click="setActiveTab('answerLog')"
                >
                  Answer Log
                </button>
              </div>
            </div>

            <div
              v-if="activeTab === 'generate'"
              class="tab-content tw-px-5 tw-h-full"
            >
              <generate-tab
                :studies="studyList"
                @showNotification="showNotificationHandler"
              />
            </div>
            <div
              v-if="activeTab === 'answerLog'"
              class="tw-h-full"
            >
              <answers-tab @showToastMessage="showToastMessage" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </st-drawer>
</template>

<script>
import StDrawer from '../st-drawer.vue'
import AhInput from '../ah-input.vue'
import StCheckbox from '../st-checkbox.vue'
import AhButton from '../ah-button.vue'
import StDropdownMenu from '../st-dropdown-menu.vue'
import TextDivider from '../text-divider.vue'
import GenerateTab from './generate-tab.vue'
import AnswersTab from './answers-tab.vue'
import AhSmallLoader from '../ah-small-loader.vue'
// import { StDrawer, AhInput, StCheckbox, AhButton, StDropdownMenu, TextDivider } from "../"

import cloudGif from '../../../../assets/images/cloud.gif'

export default {
  components: {
    StDrawer,
    AhInput,
    AhButton,
    TextDivider,
    StDropdownMenu,
    StCheckbox,
    GenerateTab,
    AnswersTab,
    AhSmallLoader
  },
  props: {
    title: {
      type: String,
      require: true,
      default: ''
    },
    showNotification: {
      type: Function,
      require: false,
      default: () => {}
    }
  },
  data () {
    return {
      cloudGif: cloudGif,
      showDrawer: false,
      isStudyExist: false,
      activeTab: 'generate',
      studyList: [],
      loading: false,
      selectedStudies: [],
      showAnswerSavedRemoved: false,
      answerStatus: null,
      initialActiveOption: 'Select studies'
    }
  },
  mounted () {
    this.checkStudyExistence()
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    hideToastMessage () {
      this.showAnswerSavedRemoved = false
    },
    showNotificationHandler (history_id, last_updated_at) {
      this.$emit('showNotification', history_id, last_updated_at)
    },
    showToastMessage (status) {
      this.answerStatus = status
      this.showAnswerSavedRemoved = true
      setTimeout(() => {
        this.showAnswerSavedRemoved = false
      }, 5000)
    },
    toggleDrawer () {
      this.showDrawer = !this.showDrawer
    },
    checkStudyExistence () {
      this.loading = true
      setTimeout(() => {
        this.$axios({
          method: 'post',
          url: `/study/get_answers_studies`
        })
          .then((response) => {
          // console.log(response.data.surveys);
            this.loading = false
            this.studyList = response.data.surveys.filter(
              (survey) =>
                survey.plans.some((plan) =>
                  plan.title.toLowerCase().includes('answers')
                )
            )
            // console.log(this.studyList)
            // this.isStudyExist = this.studiesList?.length === 0;
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      }, 200)
    },

    setActiveTab (tabName) {
      this.activeTab = tabName
    }
  }
}
</script>
