<template>
  <div
    class="tw-flex tw-w-full tw-max-w-full tw-flex-wrap tw-justify-between tw-items-center tw-my-10 tw-space-x-1 tw-text-gray-100"
  >
    <div class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap ">
      <button
        v-if="totalPages != 1"
        title="Previous"
        type="button"
        class="tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-py-0 tw-border tw-rounded-md tw-bg-gray-100 tw-border-gray-800"
        :class="{
          'tw-opacity-80 tw-cursor-not-allowed': currentPage === 1
        }"
        :disabled="currentPage === 1"
        @click="changePageHandler(currentPage - 1)"
      >
        <svg
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="tw-w-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
      </button>

      <template v-for="range in visiblePageNumbers">
        <button
          v-if="range === '...'"
          :key="range"
          class="tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-text-sm  tw-rounded  tw-bg-gray-100 tw-text-black"
          :disabled="true"
        >
          {{ range }}
        </button>
        <button
          v-else
          :key="range"
          type="button"
          class="tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-text-sm  tw-rounded  "
          :class="{
            'tw-bg-primary-light tw-border-primary/30 tw-text-primary tw-font-semibold tw-border tw-border-solid ': range === currentPage,
            'tw-bg-gray-100 tw-text-black': range !== currentPage
          }"
          @click="changePageHandler(range)"
        >
          {{ range }}
        </button>
      </template>

      <button
        v-if="totalPages > 2 && totalPages != 1"
        :class="{
          'tw-opacity-70 tw-cursor-not-allowed': currentPage === totalPages
        }"
        :disabled="currentPage === totalPages"
        title="Next"
        type="button"
        class="tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-py-0 tw-border tw-rounded-md tw-bg-gray-100 tw-border-gray-800"
        @click="changePageHandler(currentPage + 1)"
      >
        <svg
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="tw-w-4"
        >
          <polyline points="9 18 15 12 9 6" />
        </svg>
      </button>
    </div>
    <div class="tw-flex tw-space-x-2 tw-items-center tw-text-gray-800">
      <div>
        {{ title }}
      </div>
      <div class="tw-relative ">
        <div
          tabindex="2"
          class="tw-border tw-border-white-two tw-rounded-lg tw-space-x-2 tw-items-center tw-flex tw-cursor-pointer tw-px-1 tw-py-1 tw-border-solid"
          @click="togglePaginationDropdown"
          @blur="handleBlur"
        >
          <span>
            {{ selectedPaginationOption }}
          </span>
          <svg
            width="11"
            height="12"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.88537 9.02744L0.9697 4.14556C0.50781 3.5697 0.882685 2.66846 1.58411 2.66846H9.41546C10.1169 2.66846 10.4918 3.5697 10.0299 4.14556L6.11419 9.02744C5.78893 9.43297 5.21064 9.43297 4.88537 9.02744Z"
              fill="#747474"
            />
          </svg>
        </div>
        <div
          v-if="showPaginationDropdown"
          class="tw-flex tw-rounded-md tw-flex-col tw-absolute tw-bottom-8 tw-z-10 tw-bg-white tw-shadow-xl tw-border tw-border-white-two tw-border-solid tw-left-0 tw-w-full  tw-space-y-1"
        >
          <span
            v-for="option in paginationOptions"
            :key="option"
            class="tw-cursor-pointer tw-w-full tw-p-2"
            @click="changeSelectedPaginationOption(option)"
          >
            {{ option }}
          </span>
        </div>
      </div>
      <div>
        {{ currentPage }}- {{ perPage*currentPage }} of {{ totalEntries }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalEntries: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ''
    },
    perPage: {
      type: Number,
      default: 5
    },
    changePage: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      paginationOptions: [5, 10, 20, 30],
      selectedPaginationOption: 5,
      showPaginationDropdown: false
    }
  },
  computed: {
    visiblePageNumbers () {
      const result = []
      const totalVisiblePages = 15 // You can adjust this number as needed
      const halfVisiblePages = Math.floor(totalVisiblePages / 2)

      if (this.totalPages <= totalVisiblePages) {
        for (let i = 1; i <= this.totalPages; i++) {
          result.push(i)
        }
      } else {
        const startPage = Math.max(1, this.currentPage - halfVisiblePages)
        const endPage = Math.min(this.totalPages, startPage + totalVisiblePages - 1)

        if (startPage > 1) {
          result.push(1, '...')
        }

        for (let i = startPage; i <= endPage; i++) {
          result.push(i)
        }

        if (endPage < this.totalPages) {
          result.push('...', this.totalPages)
        }
      }

      return result
    }
  },
  mounted () {
    this.selectedPaginationOption = this.perPage
  },

  methods: {
    togglePaginationDropdown () {
      this.showPaginationDropdown = !this.showPaginationDropdown
    },
    changeSelectedPaginationOption (option) {
      this.selectedPaginationOption = option
      this.showPaginationDropdown = false
      this.changePageHandler(this.currentPage)
    },
    changePageHandler (page) {
      this.$emit('change-page', page, this.selectedPaginationOption)
    },
    handleBlur () {
      setTimeout(() => {
        this.showPaginationDropdown = false
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
