<template>
  <div class="">
    <st-toast-notification
      v-if="showToast"
      kind="error"
      title="Error"
      :sub-title="toastError"
      @click="showToast = false"
    />
    <!-- <div class="mobile-header">
      <a :href="backURL">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div
        v-if="!survey.published"
        class="mobile-header__button"
      >
        <st-button
          :caption="'Save & Continue'"
          :disabled="(selectedSegments.length===0 && !isEditingDisabled) || isEditingDisabled"
          @click="saveAndToHub"
        />
      </div>
    </div> -->
    <div>
      <div class="">
        <div class="space-between ">
          <!-- <div>
            <div class="st-edit-survey__back-and-save">
              <st-hyperlink
                :back-link="true"
                text="Back"
                @click.native="saveAndToHub('backLink')"
              />
              <div class="st-edit-survey__mobile-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                />
                <st-hyperlink
                  v-if="!survey.published"
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
            </div>
            <div class="st-edit-survey__header-and-icon">
              <icon-wrapper
                class="st-edit-survey__header-icon"
                icon-name="segment"
              />
              <h1 class="st-edit-survey__page-header">
                Segments
              </h1>
            </div>
            <p
              v-if="isSpacetricsAccount"
              class="st-edit-segments__selected-text st-edit-segments__edit-default-text"
            >
              You are logged into the AskHumans Account, your changes will affect Default Segments for the rest of the app.
            </p>
            <div
              v-else
              class="st-edit-segments__selected-text bx--col-sm-12"
            >
              You have {{ selectedSegments.length }} segments selected. Selecting segments will help you better understand your audience.
            </div>
          </div> -->
          <!-- <div>
            <div class="st-edit-survey__save-link-and-button">
              <div class="st-edit-survey__inline-spiner-and-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                  class="st-edit-survey__desktop-inline-spinner"
                />
                <st-hyperlink
                  v-if="!survey.published"
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
              <st-button
                v-if="!survey.published"
                :caption="'Save & Continue'"
                :disabled="(selectedSegments.length===0 && !isEditingDisabled) || isEditingDisabled"
                @click="saveAndToHub"
              />
            </div>
          </div> -->
        </div>

        <preview-bar
          :survey-id="survey.id"
          :show-preview="true"
        />
        <div class="tw-flex">
          <study-sidebar
            :current-step="4"
            :survey-id="survey.id"
            :survey="survey"
          />
          <div class="tw-flex tw-w-full tw-flex-col tw-px-5 xl:tw-px-10 2xl:tw-px-20   tw-overflow-auto scrollbar-track-gray-400 scrollbar-hover-black scrollbar-thumb-dark-gray-400 scrollbar-thin tw-mt-8 ">
            <div class="tw-max-w-[570px] tw-max-h-full tw-mx-auto ">
              <study-information :survey="survey" />
              <div class="tw-w-full">
                <template v-if="!isSpacetricsAccount">
                  <div class="mx-auto tw-mt-10">
                    <div class="tw-w-full">
                      <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-space-y-3">
                        <span class="font-28p font-600 ">
                          Segments
                        </span>
                        <span class="text-gray-200">Optional step</span>
                      </div>
                      <p class="mt-5 tw-text-center tw-mx-auto">
                        Set up different demographic attributes that can help you understand your audience
                      </p>
                    </div>

                    <div class="w-full">
                      <p class="mt-5  font-18">
                        Default segments
                      </p>
                    </div>
                    <div
                      class="tw-grid tw-grid-cols-2 tw-gap-x-5 tw-gap-y-1 tw-w-full"
                    >
                      <st-segment-accordion
                        v-for="(segment) in defaultSegments"
                        :key="'segment-' + segment.id"
                        class="tw-w-full"
                        :disabled="isEditingDisabled"
                        :segment="segment"
                        :selected="segment.selected"
                        @toggleSelected="toggleDefaultSelected(segment.id)"
                      />
                    </div>
                  </div>
                </template>
                <div
                  v-if="userCustomSegments.length"
                  class="w-full tw-mt-10"
                >
                  <p class="font-18">
                    Custom segments
                  </p>
                </div>
                <div
                  v-if="userCustomSegments.length"
                  class="tw-grid tw-gap-5  tw-grid-cols-2 tw-mt-2 "
                >
                  <!-- <div
              v-for="(column, n) in customSegmentsInColumns"
              :key="'custom-column-' + n+1"
              class="st-edit-segments__segment-column bx--col-xs-6"
            > -->
                  <st-segment-accordion
                    v-for="segment in userCustomSegments"
                    :key="'segment-' + segment.id"
                    class="tw-w-full"
                    :disabled="isEditingDisabled"
                    :editable="true"
                    :segment="segment"
                    :selected="segment.selected"
                    @toggleSelected="toggleCustomSelected(segment.id)"
                    @editSegment="openEditModal(segment.id)"
                    @deleteSegment="openDeleteModal(segment.id)"
                  />
                </div>
                <div
                  :class="{
                    'flex items-center space-x-2 tw-mt-10  tw-py-3 hover:tw-underline tw-px-5 w-fit rounded-4p cursor-pointer': true,
                    'st-edit-segments__create-custom-segment--disabled': isEditingDisabled,
                  }"
                  @click="handleCreateCustomSegment"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1373_7388)">
                      <path
                        d="M7 0C3.14 0 0 3.14067 0 7C0 10.8593 3.14 14 7 14C10.86 14 14 10.8593 14 7C14 3.14067 10.86 0 7 0ZM7 13.2223C3.56933 13.2223 0.777667 10.431 0.777667 7C0.777667 3.569 3.569 0.777667 7 0.777667C10.431 0.777667 13.2223 3.569 13.2223 7C13.2223 10.431 10.4307 13.2223 7 13.2223V13.2223Z"
                        fill="#202020"
                      />
                      <path
                        d="M8.94432 6.61096H7.38866V5.05529C7.38866 4.84063 7.21466 4.66663 6.99999 4.66663C6.78532 4.66663 6.61099 4.84096 6.61099 5.05563V6.61129H5.05532C4.84066 6.61129 4.66666 6.78529 4.66666 6.99996C4.66666 7.21463 4.84099 7.38896 5.05566 7.38896H6.61132V8.94463C6.61132 9.15929 6.78566 9.33363 7.00032 9.33363C7.21499 9.33363 7.38932 9.15929 7.38932 8.94463V7.38896H8.94499C9.15966 7.38896 9.33399 7.21463 9.33399 6.99996C9.33399 6.78529 9.15966 6.61096 8.94466 6.61096H8.94432Z"
                        fill="#202020"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1373_7388">
                        <rect
                          width="14"
                          height="14"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <span class="font-16p">
                    Create Custom Segment
                  </span>
                </div>
                <div class="tw-flex tw-space-x-2 tw-mt-10 tw-justify-end">
                  <ah-button @click="goToNextStep">
                    Next step
                  </ah-button>
                </div>
              <!-- <div class="">
            <div class="bx--col-xs-12  p-5 md:padding-container fixed border-t-gray-100 bg-white bottom-0 left-0 w-full border-top-gray-100 ">
              <div class="flex w-full justify-between px-2 ">
                <a
                  href="/"
                  class="bg-gray-100 text-gray-two rounded-4p p-3 "
                >
                  Cancel
                </a>
                <div class="flex space-x-2 md:space-x-5">
                  <div class="st-edit-survey__inline-spiner-and-save">
                    <st-inline-spinner
                      v-if="$store.state.isInlineSpinnerVisible"
                      class="st-edit-survey__desktop-inline-spinner"
                    />
                    <button
                      v-if="!survey.published"
                      icon-name="save"
                      text="Save"
                      class="bg-create-group rounded-4p px-3 py-3 font-16p text-blue-primary"
                      @click="saveProgress"
                    >
                      Save draft
                    </button>

                  </div>
                  <button
                    v-if="!survey.published"
                    class="bg-success flex space-x-2 text-white items-center font-16p px-4 py-3 rounded-4p"

                    @click="handleSave"
                  >
                    Publish study
                  </button>
                </div>
              </div>
            </div>
          </div> -->
              </div>
            </div>
          </div>
          <phone-preview
            :step="4"
            :survey="updatedSurvey"
          />
        </div>

        <!-- <div class=" bx--col-sm-9 mx-auto flex   ">
          <steps
            v-if="isStudyBeingCreated"
            :step="5"
            :survey-id="survey.id"
          />
          <widget-steps
            v-else
            :step="5"
            :survey-id="survey.id"
          />
        </div> -->

        <!-- </div> -->
        <st-modal
          v-model="addEditSegmentModal"
          @closeModal="closeAddEditModal"
        >
          <template slot="modalTitle">
            {{ editCreateTitle }}
          </template>
          <template
            slot="modalActions"
          >
            <st-input
              v-model="newSegment.name"
              class="st-edit-segments__input"
              label="Segment Display Name"
              placeholder="Segment Display Name"
            />
            <div class="st-edit-segments__input-label">
              <div>Answer Type</div>
              <st-tooltip
                tip="Should participants be able to select one answer, or multiple?"
                class="st-edit-segments__tooltip"
                :allow-wrap="true"
                :direction="isDesktop ? 'right' : 'bottom'"
                :max-width="isDesktop ? 350 : 250"
              >
                <icon-wrapper
                  icon-name="help"
                />
                <template v-slot:tip>
                  <div>
                    <span class="st-edit-segments__tooltip-bold">
                      Single Option:
                    </span> Participants can only select one answer.
                  </div>
                  <div>
                    <span class="st-edit-segments__tooltip-bold">
                      Multiple Choice:
                    </span> Participants can select more than one answer.
                  </div>
                </template>
              </st-tooltip>
            </div>
            <st-radio-button
              v-model="newSegment.multipleSelect"
              :active-value="newSegment.multipleSelect"
              class="st-edit-segments__radio-button-container"
              :options="[{ caption: 'Single Option', value: false }, { caption: 'Multiple Choice', value: true }]"
            />
            <div class="st-edit-segments__input-label st-edit-segments__input-label--mobile">
              <div>Add Answer Options For Participants to Choose</div>
              <st-tooltip
                tip="These will be all the different options you are providing participants to choose from."
                class="st-edit-segments__tooltip"
                :allow-wrap="true"
                :direction="isDesktop ? 'top' : 'left'"
                :max-width="isDesktop ? 250 : 200"
              >
                <icon-wrapper
                  icon-name="help"
                />
              </st-tooltip>
            </div>
            <template v-for="(answer, n) in newSegment.options">
              <st-input
                :key="'newOption-'+n"
                v-model="answer.name"
                class="st-edit-segments__input"
                :label="`Answer Option ${n+1}`"
                placeholder="Segment Display Name"
                :hover-delete="n>1"
                @onDelete="removeSegmentAnswer(n)"
              />
            </template>
            <div
              class="st-edit-segments__create-custom-segment-answer"
              @click="addSegmentAnswer"
            >
              <div>Add Another Answer Option</div>
              <icon-wrapper
                icon-name="plus"
                class="st-edit-segments__custom-icon"
              />
            </div>
          </template>
          <template
            v-if="newSegment.options.length<3"
            slot="modalButton"
          >
            <div class="st-finish-profile__modal-button">
              <st-button
                :caption="editCreateButton"
                icon-name="segment"
                :disabled="!newSegmentComplete"
                :show-action="true"
                @click="onAddEditSubmit"
              />
            </div>
          </template>
          <template
            v-if="newSegment.options.length>2"
            slot="additionalInformation"
          >
            <div class="st-edit-segments__scroll-buttons-container">
              <div
                class="st-edit-segments__scroll-button-cancel"
                @click="addEditSegmentModal=false"
              >
                Cancel
              </div>
              <div
                :class="{
                  'st-edit-segments__scroll-button-create': true,
                  'st-edit-segments__scroll-button-create--disabled': !newSegmentComplete
                }"
                @click="onAddEditSubmit"
              >
                <div>{{ editCreateButton }}</div>
                <icon-wrapper
                  icon-name="segment"
                  class="st-edit-segments__custom-icon"
                />
              </div>
            </div>
          </template>
        </st-modal>
        <st-modal
          v-model="deleteModal"
          :cancel-modal="true"
          :non-exit-modal="true"
          @closeModal="deleteModal = false"
        >
          <template v-slot:modalTitle>
            Delete Segment
          </template>
          <template v-slot:modalActions>
            <div
              class="st-member-groups__modal-text"
            >
              This segment will remain for any survey that has already been published. However, it will be removed anywhere else.
              <br>
              <br>
              Are you sure you want to continue and delete it?
            </div>
          </template>
          <template v-slot:modalButton>
            <st-button
              caption="Delete Segment"
              :show-action="true"
              icon-name="trash"
              @click="deleteSegment"
            />
          </template>
        </st-modal>

        <!-- <p
              :class="{
                'st-page-instructions st-edit-audience__header-spacing': true,
                'st-edit-audience__header-spacing--no-lists': !lists.length
              }"
            >
              {{ instructionsText }}
            </p> -->
      </div>
    </div>
    <st-modal
      v-model="displayPublishConfirmationModal"
      :cancel-modal="true"
      :non-exit-modal="true"
      @closeModal="displayPublishConfirmationModal = false"
    >
      <template v-slot:modalTitle>
        Publish Survey
      </template>
      <template v-slot:modalActions>
        <!-- <div
          v-if="disableButton"
          class="st-publish-options-modal__instructions-container"
        >
          <p class="st-publish-options-modal__instruction-text">
            Not ready to publish. Please complete all the steps.
          </p>
        </div> -->
        <div
          class="st-publish-options-modal__instructions-container"
        >
          <p class="st-publish-options-modal__instruction-text">
            You are about to publish “{{ survey.name }}”. Once published you
            won’t be able to edit the content.
          </p>
          <p class="st-publish-options-modal__instruction-text">
            Are you sure you want to publish right now?
          </p>
        </div>
      </template>
      <template v-slot:modalButton>
        <!-- :disabled="disableButton" -->
        <st-button
          caption="Publish"
          icon-name="publish"
          @click="publishSurvey"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { flatten, chunk } from 'lodash'
import { isDesktop } from '../../../mixins'
import isEditingDisabled from './isEditingDisabled'
import saveUpdates from './saveUpdates'
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInlineSpinner,
  StInput,
  StModal,
  StRadioButton,
  StToastNotification,
  PhonePreview,
  StTooltip,
  AhButton
} from '../../common'
import Steps from './steps.vue'
import WidgetSteps from './widget-steps.vue'
import StSegmentAccordion from './segment-accordion'
import PreviewBar from './preview-bar.vue'
import StudySidebar from './study-sidebar.vue'
import StudyInformation from './study-information.vue'

export default {
  components: {
    IconWrapper,
    Steps,
    StButton,
    WidgetSteps,
    StHyperlink,
    StudySidebar,
    StInlineSpinner,
    StudyInformation,
    AhButton,
    StInput,
    StModal,
    PhonePreview,
    StRadioButton,
    StToastNotification,
    StTooltip,
    PreviewBar,
    'st-segment-accordion': StSegmentAccordion
  },
  mixins: [isDesktop, isEditingDisabled, saveUpdates],
  props: {
    customSegments: {
      type: Array,
      required: true
    },
    standardSegments: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: true
    },
    isSpacetricsAccount: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    let selectedSegments = this.survey.segments.map(segment => segment.id)

    return {
      updatedSurvey: this.survey,
      displayPublishConfirmationModal: false,
      addEditSegmentModal: false,
      deleteModal: false,
      defaultSegments: this.standardSegments.map(segment => {
        let isSelected = false
        if (selectedSegments.includes(segment.id)) {
          isSelected = true
        }
        return Object.assign({ expanded: false, selected: isSelected }, segment)
      }),
      editModal: false,
      userCustomSegments: this.customSegments.map(segment => {
        let isSelected = false
        if (selectedSegments.includes(segment.id)) {
          isSelected = true
        }
        return Object.assign({ expanded: false, selected: isSelected }, segment)
      }),
      newSegment: {
        name: null,
        multipleSelect: null,
        options: [{ name: null }, { name: null }]
      },
      modalSegmentId: null,
      modalSegmentIndex: -1,
      showToast: false,
      toastError: null
    }
  },
  computed: {
    backURL () {
      return `/study/${this.survey.id}/edit/content`
    },
    isStudyBeingCreated () {
      return this.survey.survey_type === 'study'
    },
    customSegmentsInColumns () {
      return this.twoColumns(this.userCustomSegments)
    },
    defaultSegmentsInColumns () {
      return this.twoColumns(this.defaultSegments)
    },
    editCreateButton () {
      return this.editModal ? 'Save Changes' : 'Save Segment'
    },
    editCreateTitle () {
      if (this.isSpacetricsAccount) {
        return this.editModal ? 'Edit DEFAULT Segment' : 'Create DEFAULT Segment'
      } else {
        return this.editModal ? 'Edit Custom Segment' : 'Create Custom Segment'
      }
    },
    newSegmentComplete () {
      if (!this.newSegment.name) return false
      if (this.newSegment.multipleSelect === null) return false
      let emptyAnswers = this.newSegment.options.filter(answer => {
        return !answer.name
      })
      return emptyAnswers.length === 0
    },
    selectedSegments () {
      let selectedSegmentsList = []
      this.userCustomSegments.forEach(segment => {
        if (segment.selected) {
          selectedSegmentsList.push(segment.id)
        }
      })
      this.defaultSegments.forEach(segment => {
        if (segment.selected) {
          selectedSegmentsList.push(segment.id)
        }
      })
      return selectedSegmentsList
    }
  },
  methods: {
    handleCreateCustomSegment () {
      if (this.isEditingDisabled) { return }
      this.addEditSegmentModal = true
    },
    toggleSegmentDetails (idx) {
      this.defaultSegments[idx].expanded = !this.defaultSegments[idx].expanded
    },
    toggleCustomSegmentDetails (idx) {
      this.userCustomSegments[idx].expanded = !this.userCustomSegments[idx].expanded
    },
    saveNewSegment () {
      if (this.newSegmentComplete) {
        let url = `/segments`
        this.$axios.request({
          url: url,
          method: 'post',
          data: { segment: {
            name: this.newSegment.name,
            multiple_select: this.newSegment.multipleSelect,
            options: this.newSegment.options.map(segment => segment.name)
          } }
        })
          .then(res => {
            let newCustomSegment = Object.assign({ selected: false, expanded: false }, res.data)
            this.userCustomSegments.push(newCustomSegment)
          })
        this.closeAddEditModal()
      }
    },
    addSegmentAnswer () {
      this.newSegment.options.push({ name: null })
    },
    addNewSegment () {
      this.addSegment = true
    },
    removeSegmentAnswer (idx) {
      this.newSegment.options.splice(idx, 1)
    },
    updateSurvey () {
      return this.$axios.request({
        method: 'post',
        url: `/study/${this.survey.id}/segments`,
        data: { segment_ids: this.selectedSegments }
      })
        .then(res => {
          console.log(res)
        })
        .catch(err => { console.log(err) })
    },
    goToNextStep () {
      window.location = `/study/${this.survey.id}/plan-details`
    },
    toggleDefaultSelected (id) {
      let idx = this.defaultSegments.map(seg => seg.id).indexOf(id)
      this.defaultSegments[idx].selected = !this.defaultSegments[idx].selected

      // this is just to add the segment to the updatedSurvey object because preview needs to be real time
      this.updatedSurvey = Object.assign({}, this.updatedSurvey, { segments: this.updatedSurvey.segments.map(segment => segment.id).includes(id) ? this.updatedSurvey.segments.filter(segment => segment.id !== id) : [...this.updatedSurvey.segments, this.defaultSegments[idx]] })
      setTimeout(() => {
        this.updateSurvey()
      }, 100)
    },
    toggleCustomSelected (id) {
      let idx = this.userCustomSegments.map(seg => seg.id).indexOf(id)
      this.userCustomSegments[idx].selected = !this.userCustomSegments[idx].selected

      // this is just to add the segment to the updatedSurvey object because preview needs to be real time
      this.updatedSurvey = Object.assign({}, this.updatedSurvey, { segments: this.updatedSurvey.segments.map(segment => segment.id).includes(id) ? this.updatedSurvey.segments.filter(segment => segment.id !== id) : [...this.updatedSurvey.segments, this.userCustomSegments[idx]] })
      setTimeout(() => {
        this.updateSurvey()
      }, 100)
    },
    twoColumns (array) {
      if (array.length < 2) { return chunk(array) }

      let columnSize = Math.floor(array.length / 2)
      let columns = chunk(array, columnSize)
      let extras = flatten(columns.splice(2))
      for (let i = 0; i < extras.length; i++) {
        columns[i].push(extras[i])
      }
      return columns
    },
    onAddEditSubmit () {
      if (this.editModal) {
        this.editSegment()
      } else {
        this.saveNewSegment()
      }
    },
    editSegment () {
      let segmentData = {
        name: this.newSegment.name,
        multiple_select: this.newSegment.multipleSelect,
        options: this.newSegment.options.map(option => option.name)
      }
      let url = `/segments/${this.modalSegmentId}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { segment: segmentData }
      })
        .then(res => {
          this.userCustomSegments.splice(this.modalSegmentIndex, 1, Object.assign({ selected: this.userCustomSegments[this.modalSegmentIndex].selected }, res.data))
          this.closeAddEditModal()
        })
        .catch(err => {
          console.error(err)
        })
    },
    openEditModal (segmentId) {
      this.modalSegmentIndex = this.userCustomSegments.map(x => x.id).indexOf(segmentId)
      // set newSegment info = to copy of segment being edited
      this.newSegment = this.userCustomSegments.slice(this.modalSegmentIndex, this.modalSegmentIndex + 1)[0]
      this.modalSegmentId = segmentId
      this.$nextTick(() => {
        this.editModal = true
        this.addEditSegmentModal = true
      })
    },
    openDeleteModal (segmentId) {
      this.modalSegmentIndex = this.customSegments.map(x => x.id).indexOf(segmentId)
      this.modalSegmentId = segmentId
      this.deleteModal = true
    },
    closeAddEditModal () {
      this.newSegment = {
        name: null,
        multipleSelect: null,
        options: [{ name: null }, { name: null }]
      }
      this.addEditSegmentModal = false
      this.editModal = false
      this.modalSegmentId = null
    },
    deleteSegment () {
      // send ajax request
      let url = `/segments/${this.modalSegmentId}`
      this.$axios.request({
        method: 'delete',
        url: url
      })
        .then(res => {
          this.userCustomSegments.splice(this.modalSegmentIndex, 1)
          this.updatedSurvey = Object.assign({}, this.updatedSurvey, { segments: this.updatedSurvey.segments.filter(segment => segment.id !== this.modalSegmentId) })
          this.$nextTick(() => {
            this.modalSegmentIndex = -1
            this.deleteModal = false
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    handleSave () {
      this.updateSurvey()
      this.displayPublishConfirmationModal = true
    },
    handleSkip () {
      this.skipStep('audience')
    },
    cancelDeleteSegmentModal () {
      this.deleteModal = false
      this.modalSegmentId = null
    },
    isQuestionAdded () {
      const questions = this.survey.questions.filter((question) => { return question.questionType !== 'slide' })
      return questions.length
    },
    publishSurvey () {
      let url = `/study/${this.survey.id}/publish_survey`
      if (this.survey.name == '' || this.survey.name == null) {
        this.showToast = true
        this.displayPublishConfirmationModal = false,
        this.toastError = "Study name can't be empty. Please add study name."
        return
      }
      if (!this.isQuestionAdded()) {
        this.showToast = true
        this.displayPublishConfirmationModal = false,
        this.toastError = 'Please add at least one question prompt before publishing study.'
        return
      }
      this.$axios
        .request({
          url: url,
          method: 'post',
          data: { survey: this.survey }
        })
        .then((res) => {
          res.data.survey.survey.published
            ? (window.location = `/study/${this.survey.id}/edit/audience`)
            : (window.location = `/study/${this.survey.id}/edit`)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}
</script>
