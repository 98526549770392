<template>
  <div>
    <st-toast-notification
      v-if="showToast"
      class="st-toast-notification--fixed"
      kind="info"
      title="Downloading..."
      caption="The report is being generated. Please wait for few seconds."
      @click="showToast = false"
    />
    <span
      class="tw-bg-white tw-p-2 tw-cursor-pointer tw-rounded-full tw-relative tw-flex tw-items-center"
      title="Download PDF"
      @click="exportPDF"
    >
    
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1126_2897)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.07373 1.14258C7.4121 1.14258 7.68615 1.41689 7.68615 1.755V7.62642L9.70323 5.6096C9.94237 5.37046 10.3304 5.37046 10.5695 5.6096C10.8086 5.84875 10.8086 6.23646 10.5695 6.47587L7.50712 9.5385C7.26798 9.77764 6.88 9.77764 6.64086 9.5385L3.57849 6.47587C3.33935 6.23673 3.33935 5.84875 3.57849 5.6096C3.81763 5.37046 4.20535 5.37046 4.44476 5.6096L6.46183 7.62642V1.755C6.46183 1.41663 6.73615 1.14258 7.07426 1.14258H7.07373ZM1.56115 8.49268C1.89952 8.49268 2.17357 8.767 2.17357 9.1051V9.84011C2.17357 10.3649 2.1741 10.7213 2.19667 10.9972C2.21872 11.2658 2.25836 11.4031 2.30719 11.4989C2.42479 11.7294 2.61195 11.9168 2.8427 12.0341C2.93851 12.0829 3.0758 12.1228 3.34434 12.1446C3.61997 12.1672 3.97671 12.1677 4.50119 12.1677H9.64627C10.171 12.1677 10.5275 12.1672 10.8034 12.1446C11.0719 12.1226 11.2092 12.0829 11.305 12.0341C11.5355 11.9168 11.7229 11.7294 11.8403 11.4989C11.8891 11.4031 11.929 11.2658 11.9508 10.9972C11.9734 10.7216 11.9739 10.3649 11.9739 9.84011V9.1051C11.9739 8.76674 12.2482 8.49268 12.5863 8.49268C12.9244 8.49268 13.1987 8.767 13.1987 9.1051V9.86532C13.1987 10.3583 13.1987 10.7652 13.1717 11.0967C13.1436 11.4411 13.0832 11.7574 12.9318 12.0549C12.6968 12.5158 12.322 12.8907 11.8613 13.1256C11.5639 13.2771 11.2475 13.3375 10.9031 13.3655C10.5716 13.3926 10.1647 13.3926 9.67173 13.3926H4.47599C3.98301 13.3926 3.57587 13.3926 3.24459 13.3655C2.90018 13.3375 2.58387 13.2771 2.28645 13.1256C1.82549 12.8907 1.45064 12.5158 1.2157 12.0549C1.06423 11.7574 1.00386 11.4411 0.975768 11.0967C0.94873 10.7652 0.94873 10.3583 0.94873 9.86532V9.1051C0.94873 8.76674 1.22278 8.49268 1.56115 8.49268V8.49268Z"
            fill="#747474"
          />
        </g>
        <defs>
          <clipPath id="clip0_1126_2897">
            <rect
              width="12.25"
              height="12.25"
              fill="white"
              transform="translate(0.94873 1.14258)"
            />
          </clipPath>
        </defs>
      </svg>

    </span>
    <vue-html2pdf
      ref="html2Pdf"
      class="tw-w-full tw-h-full"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="invoicePDF"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="1300px"
      :html-to-pdf-options="{
        filename: 'invoice.pdf',
        margin: [0.4, 0, 0.4, 0],
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',

        },
        html2canvas: { useCORS: true, scale: 3, } // resolution }
        
      }"
    >
   
    


      <section
        slot="pdf-content"
        class="tw-w-full tw-h-full  tw-mb-2 px-7 " 
      >
      <div
      v-for="([key, value], index) in Object.entries(version.description)"
          :key="key"
          :class="{'tw-max-h-full tw-max-w-2xl tw-ml-10 tw-py-3': true, 
          'tw-border-b tw-border-gray-800 tw-border-solid': index !==Object.entries(version.description).length-1
          }"
        >
         <div
        class=""
        :style="{ marginLeft: getIndentationLevel() + 'px' }"
        :class="{
          
        }"
        >
        
        <p
          v-if="(typeof key) !== 'number' && !thematicExcludedKeys.includes(key)"
          class="tw-font-semibold tw-pb-1 tw-capitalize"
          :style="{
            fontSize: getFontSize(getIndentationLevel()),
          }
          "
        >
          {{ removeUnderscore(key) }}
        </p>
        <ReportRenderer
          v-if="typeof value === 'object' && !Array.isArray(value) && !thematicExcludedKeys.includes(key) && key!== 'data_collection_and_analysis'"
          :version-description="value"
          :isPDFGenerating="true"
          :type="type"
        />
      </div>
      <template v-if="Array.isArray(value)">
        <section>
        <div :style="{ marginLeft: getIndentationLevel() + 'px' }">
          <template
            v-for="(item, index) in value"
          >
            <ReportRenderer
              v-if="typeof item === 'object' && !Array.isArray(item) && !thematicExcludedKeys.includes(key)"
              :key="`item_${index}`"
              :version-description="item"
              :isPDFGenerating="true"
              :type="type"
            />
            <ul
              v-else
              :key="`item_else_${index}`"
              class="tw-pb-1 tw-pl-10"
            >
              <li
                :key="item"
                class="tw-text-[15px] tw-py-1"
              >
                {{ item }}
            </li>
              
            </ul>
          </template>
        </div>
        </section>
      </template>
     
      <template v-if="type=='thematicAnalysis'">
       <div class="tw-ml-10">
        <thematic-analysis
          v-if="key === 'data_collection_and_analysis'"
          :data="value"
          section="collection"
        />
        <section class="pdf-item">
        <thematic-analysis
          v-if="key === 'major_themes' || key === 'thematic_divergence' || key === 'thematic_alignment' || key === 'thematic_breakdown'"
          :data="value"
          section="thematicBreakdown"
        />
        </section>
       </div>
      </template>
      <template v-if="(typeof value != 'object') ">
        <div
          v-if="!thematicExcludedKeys.includes(key)"
          :style="{ marginLeft: getIndentationLevel() + 'px' }"
        >
          <p class="tw-text-[15px]">
            {{ value }}
          </p>
        </div>
       
      </template>
    </div>
  </section>


    </vue-html2pdf>
  </div>
</template>

<script>
import ReportRenderer from './new-report-description.vue'
import VueHtml2pdf from 'vue-html2pdf'
import ThematicAnalysis from './ThematicAnalysis.vue'
import { StToastNotification } from '../../common'
export default {
  name: 'AskhumansDownloadStrategyReport',
  components: {
    VueHtml2pdf,
    ThematicAnalysis,
    ReportRenderer,
    StToastNotification
  },
  props: {
    version: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      thematicExcludedKeys: ['number_of_participants', 'total_number_of_answers', 'approximate_total_speaking_time','representative_quotes', 'name', 'quote', 'theme', 'description',],
      showToast: false,
      
    }
  },

  mounted () {
    // console.log('mounted', this.version)
  },

  methods: {
    exportPDF () {
      // this.isPDFDownloading = true
      this.$refs.html2Pdf.generatePdf()
      this.showToast = true
    },
    removeUnderscore (string) {
      return string.replace(/_/g, ' ')
    },
    getFontSize (level) {
      if (level === 0) {
        return '22px'
      } else if (level === 10) {
        return '19px'
      } else if (level === 20) {
        return '17px'
      } else if (level === 3) {
        return '15px'
      } else return '14px'
    },
    getIndentationLevel () {
      let level = 0
      let parent = this.$parent

      while (parent && parent.$options.name === 'ReportRenderer') {
        level++
        parent = parent.$parent
      }

      // Assuming each level of indentation is 20px, adjust as needed
      return level * 10
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
