<template>
  <div class="mt-minus-20">
    <a href="/welcome">
      <icon-wrapper
        v-if="additionalContent === 'left' && isDesktop"
        class="st-preview-wrapper__spacetrics-icon"
        icon-name="spacetrics"
      />
    </a>

    <div :class="{ 'bx--grid': isDesktop }">
      <div
        class="bx--row st-box-set"
        :class="{
          'tw-mt-20': isWidget,
        }"
      >
        <div
          v-if="additionalContent === 'left'"
          class="bx--col-sm-4 st-preview-wrapper__left-content"
        >
          <p class="st-preview-wrapper__instructions-text">
            This survey is for testing only. None of the information that you
            enter here will be saved.
          </p>
          <st-hyperlink
            v-if="exitPreview"
            class="st-preview-wrapper__left-exit-link"
            text="Exit Preview"
            icon-name="hide-password"
            icon-class="st-hyperlink__default-icon-spacing"
            :url="`/study/${survey.id}/publish`"
          />
        </div>
        <div
          :class="{
            'st-preview-wrapper__position-invitation-page bx--offset-xs-0 bx--col-xs-12': true,
            'bx--col-sm-6 ': additionalContent === 'left',
            'bx--offset-sm-2 bx--col-sm-8 bx--offset-lg-3 bx--col-lg-6':
              (additionalContent === 'right' || additionalContent === 'none') &&
              !fullWidth,
            'bx--offset-sm-0 bx--col-sm-12': fullWidth && !additionalContent,
          }"
        >
          <div
            :class="{
              'st-preview-wrapper__flex-container h-full-not-important': true,
              'st-preview-wrapper__invitation-page-container': isDesktop,
              'st-preview-wrapper__full-width': isDesktop && fullWidth,
              'st-preview-wrapper__full-width--survey':
                isDesktop && fullWidth && additionalContent === 'none',
            }"
          >
            <slot />
          </div>
        </div>
        <div
          v-if="
            (exitPreview && additionalContent === 'right') ||
              (exitPreview && !isDesktop && responsePreview)
          "
          :class="{
            'bx--offset-sm-1 bx--col-sm-2  bx--col-offset-sm-0 st-preview-wrapper__right-content': true,
            'bx--grid tw-hidden sm:tw-block': !isDesktop,
          }"
        >
          <st-hyperlink
            class="st-preview-wrapper__right-exit-link"
            text="Exit Preview"
            icon-name="hide-password"
            icon-class="st-hyperlink__default-icon-spacing"
            :url="exitRightLink"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isDesktop } from "../../../mixins";
import { IconWrapper, StHyperlink } from "../../common";

export default {
  components: {
    IconWrapper,
    StHyperlink,
  },
  mixins: [isDesktop],
  props: {
    //* * Placement direction of the content outside of the invitation page preview */
    additionalContent: {
      type: String,
      required: false,
      default: "none",
    },
    //* * Determines if the exit preview hyperlink is used (for invitation-page preview, publish preview) */
    exitPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    //* * Used for mobile repsonse previews */
    responsePreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    //* * Current survey the preview is a part of */
    survey: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data: function() {
    return {
      isWidget:false,
    };
  },
  computed: {
    ...mapGetters("participants", ["fullWidth"]),
    exitRightLink() {
      return this.exitPreview && this.additionalContent === "right"
        ? `/study/${this.survey.id}/edit/landing_page`
        : `/study/${this.survey.id}/publish`;
    },
  },
  mounted() {
    // check if the active widget is in the url
    const url = new URL(window.location.href);
    const widget = url.searchParams.get("active_widget");
    this.isWidget = widget;
  },
};
</script>
