var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-input__container",class:_vm.containerClass,on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover= false}}},[(_vm.styleType=='secondary')?_c('div',{staticClass:"w-full"},[_c('input',_vm._g({ref:'stInput',staticClass:"secondary-input font-14p",attrs:{"id":_vm.stUid,"disabled":_vm.disabled,"name":_vm.name,"autocomplete":_vm.autocomplete,"focus":_vm.hasFocus,"placeholder":_vm.placeholder,"type":_vm.inputType,"tabindex":_vm.tabIndex},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter.apply(null, arguments)},"blur":function($event){return _vm.$emit('blur', $event)}}},_vm.inputListeners))]):(_vm.styleType=='rounded')?_c('div',{staticClass:"w-full"},[_c('input',_vm._g({ref:'stInput',class:{'w-full my-2 tw-py-4 !tw-rounded-lg !tw-px-4 tw-placeholder-black-three  font-14p':true, 'border-gray-100': _vm.errorClass==null, '!tw-border-red-500 !tw-outline-none':_vm.errorClass=='error' },attrs:{"id":_vm.stUid,"disabled":_vm.disabled,"name":_vm.name,"autocomplete":_vm.autocomplete,"focus":_vm.hasFocus,"placeholder":_vm.placeholder,"type":_vm.inputType,"tabindex":_vm.tabIndex},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter.apply(null, arguments)},"blur":function($event){return _vm.$emit('blur', $event)}}},_vm.inputListeners))]):_c('div',{class:[{
      'st-input': true,
      'st-input__focused': _vm.inputBoxFocused || _vm.hover,
      'st-input__label-above-spacing': _vm.label && _vm.showLabel,
      'st-input__hover-delete': _vm.hoverDelete && _vm.hover,
      'st-input--disabled': _vm.disabled
    }, _vm.inputContainerClasses, _vm.inputContainerClass],on:{"click":_vm.handleMobileClick}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showLabelAbove)?_c('label',{class:[{ 'st-input__show-label-above': true }, _vm.labelAboveClasses],attrs:{"for":_vm.stUid}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.newPassword)?_c('div',{staticClass:"st-input-password__bars-container"},_vm._l((4),function(bar,index){return _c('div',{key:bar.index,staticClass:"st-input-password__bar"},[_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.passwordLevel >= index + 1)?_c('div',{key:index,class:_vm.passwordClass()}):_vm._e()])],1)}),0):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.showIcon)?_c('icon-wrapper',{class:{
        'st-input__icon': true,
        'st-input__icon--input-focused': _vm.inputBoxFocused || (_vm.hover && _vm.value),
      },attrs:{"icon-name":_vm.iconName}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"st-input__align-content"},[(!_vm.inputBoxFocused && !_vm.value)?_c('label',{class:[{
          'st-input__show-label-in-field': true,
          'st-input__show-label-in-field--disabled': _vm.disabled
        }, _vm.labelInFieldClass, _vm.labelInFieldClasses],staticStyle:{"overflow":"hidden"},attrs:{"for":_vm.stUid},on:{"click":function($event){return _vm.reFocusInput()}}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")]):_vm._e(),_vm._v(" "),_c('input',_vm._g({ref:'stInput',class:[{
          'st-input__field': true,
          'st-input__field--toggle-mobile-on font-14p': _vm.inputBoxFocused,
          'st-input__label': _vm.inputBoxFocused && _vm.label,
          'st-input__field--disabled': _vm.disabled,
        }, _vm.inputClass],attrs:{"id":_vm.stUid,"disabled":_vm.disabled,"name":_vm.name,"autocomplete":_vm.autocomplete,"focus":_vm.hasFocus,"placeholder":_vm.placeholder,"type":_vm.inputType,"tabindex":_vm.tabIndex},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter.apply(null, arguments)},"blur":function($event){return _vm.$emit('blur', $event)}}},_vm.inputListeners)),_vm._v(" "),(_vm.password)?_c('label',{attrs:{"for":_vm.stUid,"type":"button"},on:{"click":_vm.viewPassword,"mousedown":function($event){_vm.flippingPasswordMode = true},"mouseup":function($event){_vm.flippingPasswordMode = false},"mouseout":function($event){_vm.flippingPasswordMode = false}}},[(_vm.showPassword)?_c('icon-wrapper',{class:{
            'st-input__password-icon': true,
            'st-input__password-icon--value-entered': _vm.value && _vm.inputBoxFocused
          },attrs:{"icon-name":"hide-password"}}):_c('icon-wrapper',{class:{
            'st-input__password-icon': true,
            'st-input__password-icon--value-entered': _vm.value && _vm.inputBoxFocused
          },attrs:{"icon-name":"view-password"}})],1):_vm._e()])],1),_vm._v(" "),(_vm.hoverDelete && (_vm.hover || !_vm.isDesktop))?_c('div',{staticClass:"st-input__trash-icon",on:{"click":function($event){return _vm.$emit('onDelete')}}},[_c('icon-wrapper',{attrs:{"icon-name":"trash"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }