import { render, staticRenderFns } from "./billing-tab.vue?vue&type=template&id=2f3dcc46&scoped=true&"
import script from "./billing-tab.vue?vue&type=script&lang=js&"
export * from "./billing-tab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f3dcc46",
  null
  
)

export default component.exports