var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('st-spinner'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-bg-black tw-text-center tw-py-20 tw-text-[30px] tw-text-white tw-font-semibold"},[_vm._v("\n    Settings\n  ")]),_vm._v(" "),_c('div',{staticClass:" st-body\n    "},[_c('div',{staticClass:"flex space-x-2 px-20 tw-pt-5 mt-0-important tw-text-black-three tw-overflow-x-auto"},_vm._l((_vm.tabsList),function(tab){return _c('span',{key:tab,class:[
          {
            'py-3 px-2 cursor-pointer w-full md:fit-content text-center': true,
            'border-bottom-2-primary tw-text-black': tab === _vm.activeTab,

            'tw-text-black-three hover:tw-text-black tw-transition-all tw-duration-300':
              tab !== _vm.activeTab,
          } ],on:{"click":function($event){return _vm.changeActiveTab(tab)}}},[_vm._v(_vm._s(tab))])}),0),_vm._v(" "),(_vm.activeTab==_vm.tabsList[0])?_c('billing'):_vm._e(),_vm._v(" "),(_vm.activeTab==_vm.tabsList[1])?_c('team'):_vm._e()],1),_vm._v(" "),_c('stripe-payment-form',{ref:"stripeForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }