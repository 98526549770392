<template>
  <div class="tw-relative tw-z-0">
    <div
      id="button-1"
      class="button r"
    >
      <input
        v-model="isButtonOn"
        type="checkbox"
        class="checkbox"
        @change="onChangeHandler"
      >
      <div class="knobs" />
      <div class="layer" />
    </div>
  </div>
</template>

<script>
import StUid from '../../mixins/uid'
import isDesktop from '../../mixins/isDesktop'

export default {
  components: {},
  mixins: [isDesktop, StUid],
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    onChangeHandler: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  data: function () {
    return {
      isButtonOn: null,
      hover: false,
      hasFocus: false,
      flippingPasswordMode: false,
      passwordLevel: 0,
      showPassword: false,
      showPasswordBar: false
    }
  },
  computed: {
  },
  mounted () {
    this.isButtonOn = this.checked
  },
  methods: {
    changeCheckedState (newState) {
      this.isButtonOn = newState
    }
  }
}
</script>
<style scoped>
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  width: 34px;
  height: 20px;

  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: "";
  position: absolute;
  top: 1.5px;
  left: 4px;
  width: 9px;
  height: 9px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 4px 4px;
  background-color: #cbd5e1;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before {
  content: "";
  left: 16px;
  background-color: #BE6744;
}

#button-1 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}
</style>
