<template>
      <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.9188 12.4552C19.2591 9.85258 18.5105 7.42794 17.036 5.53717C16.8546 5.29249 16.5143 5.22575 16.2421 5.38146L14.9491 6.11553C14.6089 6.29348 14.5408 6.73837 14.7903 7.02755C15.9018 8.38445 16.4916 10.164 16.2421 12.0993C15.8565 15.1022 13.3386 17.5046 10.2762 17.8383C6.19312 18.2832 2.72248 15.1467 2.72248 11.2095C2.72248 7.53917 5.76213 4.55842 9.50498 4.55842V5.35922C9.50498 5.82635 10.004 6.11553 10.4123 5.87084L14.1552 3.75763C14.5635 3.53518 14.5635 2.95683 14.1552 2.71214L10.4119 0.576684C10.0036 0.35424 9.50457 0.643417 9.50457 1.0883V1.8891C4.26458 1.8891 0 6.0488 0 11.1872C0 16.6816 4.85436 21.0637 10.5934 20.4409C14.8807 19.9738 18.3513 16.6371 18.9184 12.4552H18.9188Z" fill="black"/>
</svg>

</template>

<script>
export default {}
</script>
