<template>
  <div class="bx--grid tw-w-full ">
    <st-toast-notification
      v-if="copied"
      class="st-toast-notification--fixed tw-z-[9999]"
      kind="success"
      title="Copied!"
      caption="Content copied to clipboard."
      @click="copied=false"
    />
    <div class="bx--row  tw-w-full">
      <div class="tw-h-full tw-flex tw-w-full tw-py-6 tw-space-x-10 ">
        <div class="tw-flex tw-max-w-3xl tw-w-full   tw-max-h-full ">
          <div class="tw-w-full  ">
            <div
              v-if="firstVersion.name !== 'initial version' && isReportAvailable"
              class="tw-flex tw-space-x-3 tw-justify-between tw-items-center tw-my-2"
            >
              <button

                class="tw-flex tw-bg-white tw-relative tw-min-w-fit tw-justify-between  tw-py-2 tw-rounded-xl tw-px-3 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                @click="showVersions('selected')"
              >
                <span
                  class=""
                  :class="{
                    'tw-font-semibold': firstVersion.name === 'initial version'
                  }"
                >
                  {{ firstVersion.name =='initial version' ? strategy.name : firstVersion.name }}
                </span>
                <span class="tw-text-end tw-block tw-pt-1">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_930_2517)">
                      <path
                        d="M8.50709 10.9945C8.33286 10.9945 8.16163 10.9308 8.03546 10.815L4.19334 7.10336C3.92899 6.85148 3.92899 6.44036 4.19034 6.18559C4.45168 5.93081 4.87825 5.93081 5.14261 6.18269L8.51009 9.43978L11.8746 6.18269C12.1479 5.94239 12.5715 5.96266 12.8208 6.22612C13.0522 6.47221 13.0522 6.84859 12.8208 7.09468L8.97872 10.815C8.85255 10.9308 8.68132 10.9974 8.50709 10.9945Z"
                        fill="#474747"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_930_2517">
                        <rect
                          width="5"
                          height="9"
                          fill="white"
                          transform="translate(12.9951 5.99414) rotate(90)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                </span>
                <div
                  v-if="showStrategyVersions && allStrategyVersions.length "
                  class="flex flex-col justify-center !tw-text-sm !tw-min-w-fit !tw-right-auto tw-left-0 !tw-top-10 cursor-pointer absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
                >
                  <template
                    v-for="(version, index) in allStrategyVersions"
                  >
                    <span
                      v-if="version.name!==selectedDropdownVersion && version.name!== 'initial version'"
                      :key="`version_${index}`"
                      class="!tw-p-2 tw-truncate !tw-min-w-fit tw-flex !tw-flex-col "

                      @click="
                        changeSelectedVersion($event, version)
                      "
                    >
                      <strong
                        :class="
                          version.name === 'initial version' ? '!tw-font-semibold': '!tw-font-normal'
                        "
                      >

                        {{ version.name =='initial version' ? strategy.name : version.name }}
                      </strong>
                      <strong
                        v-if="version.name !='initial version'"
                        class="!tw-text-left !tw-font-normal tw-text-xs "
                      >
                        -  {{ getReadableDate(version.createdAt) }}
                      </strong>
                    </span>
                  </template>
                </div>
              </button>
              <div class="tw-relative">
                <span
                  class="tw-bg-white tw-p-1.5 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-full"
                  @click="toggleDownloadMenus('first')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="tw-w-5 tw-h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                  </svg>

                </span>
                <div
                  v-if="showDownloadMenusForFirstVersion"
                  class="tw-absolute tw-z-10 tw-left-[36px] tw-top-0 tw-flex tw-flex-col tw-space-y-3 tw-justify-center tw-items-center"
                >
                  <span
                    class="tw-bg-white tw-p-2 tw-cursor-pointer tw-rounded-full tw-relative tw-flex tw-items-center"
                    title="Copy"
                    @click="copyStrategy"
                  >
                    <span
                      v-if="copied"
                      class="tw-flex tw-space-x-2  tw-bg-white tw-px-2 tw-py-1 tw-rounded-md tw-text-black tw-ml-6 tw-items-center tw-absolute"
                    >
                      Copied
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="tw-w-4 tw-h-4 tw-ml-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>

                    </span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_370_1059)">
                        <path
                          d="M2.59618 4.45565H4.31604V1.69632C4.31604 1.22931 4.50947 0.805032 4.82117 0.497835C5.13261 0.190638 5.56336 0 6.03722 0H12.2791C12.7529 0 13.1834 0.190638 13.4951 0.497835C13.8068 0.805032 14.0003 1.22931 14.0003 1.69632V7.84803C14.0003 8.31504 13.8063 8.73932 13.4951 9.04651C13.1834 9.35371 12.7529 9.54435 12.2791 9.54435H10.5592V12.3037C10.5592 12.7707 10.3658 13.195 10.0541 13.5022C9.74239 13.8094 9.3119 14 8.83804 14H2.59618C2.12232 14 1.69183 13.8094 1.38013 13.5022C1.06843 13.195 0.875 12.7707 0.875 12.3037V6.15197C0.875 5.68496 1.06843 5.26068 1.38013 4.95349C1.69183 4.64629 2.12232 4.45565 2.59618 4.45565ZM5.27795 4.45565H8.83804C9.3119 4.45565 9.74239 4.64629 10.0541 4.95349C10.3658 5.26068 10.5592 5.68496 10.5592 6.15197V8.59634H12.2791C12.4875 8.59634 12.6773 8.5119 12.8147 8.37617C12.9524 8.24044 13.0381 8.05343 13.0381 7.84829V1.69658C13.0381 1.49117 12.9524 1.30416 12.8147 1.16818C12.677 1.03245 12.487 0.948011 12.2791 0.948011H6.03722C5.82881 0.948011 5.63905 1.03245 5.50134 1.16818C5.36362 1.3039 5.27795 1.49117 5.27795 1.69658V4.45591V4.45565ZM4.79936 5.40366H2.59565C2.38724 5.40366 2.19749 5.4881 2.06003 5.62383C1.92232 5.75956 1.83664 5.94657 1.83664 6.15223V12.3039C1.83664 12.5093 1.92232 12.6964 2.06003 12.8321C2.19775 12.9678 2.3875 13.0522 2.59618 13.0522H8.83804C9.04646 13.0522 9.23621 12.9678 9.37392 12.8321C9.51164 12.6964 9.59732 12.5093 9.59732 12.3039V6.15223C9.59732 5.94683 9.51164 5.75982 9.37392 5.62383C9.23621 5.4881 9.04646 5.40366 8.83804 5.40366H4.79936Z"
                          fill="#747474"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_370_1059">
                          <rect
                            width="13.125"
                            height="14"
                            fill="white"
                            transform="translate(0.875)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                  </span>
                  <download-strategy-report
                    :version="firstVersion"
                  />
                </div>
              </div>
              <!-- <div class="tw-flex tw-items-center tw-space-x-2">
                <span
                  class="tw-bg-white tw-p-2 tw-cursor-pointer tw-rounded-full tw-relative tw-flex tw-items-center"
                  title="Copy"
                  @click="copyStrategy('first')"
                >

                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_370_1059)">
                      <path
                        d="M2.59618 4.45565H4.31604V1.69632C4.31604 1.22931 4.50947 0.805032 4.82117 0.497835C5.13261 0.190638 5.56336 0 6.03722 0H12.2791C12.7529 0 13.1834 0.190638 13.4951 0.497835C13.8068 0.805032 14.0003 1.22931 14.0003 1.69632V7.84803C14.0003 8.31504 13.8063 8.73932 13.4951 9.04651C13.1834 9.35371 12.7529 9.54435 12.2791 9.54435H10.5592V12.3037C10.5592 12.7707 10.3658 13.195 10.0541 13.5022C9.74239 13.8094 9.3119 14 8.83804 14H2.59618C2.12232 14 1.69183 13.8094 1.38013 13.5022C1.06843 13.195 0.875 12.7707 0.875 12.3037V6.15197C0.875 5.68496 1.06843 5.26068 1.38013 4.95349C1.69183 4.64629 2.12232 4.45565 2.59618 4.45565ZM5.27795 4.45565H8.83804C9.3119 4.45565 9.74239 4.64629 10.0541 4.95349C10.3658 5.26068 10.5592 5.68496 10.5592 6.15197V8.59634H12.2791C12.4875 8.59634 12.6773 8.5119 12.8147 8.37617C12.9524 8.24044 13.0381 8.05343 13.0381 7.84829V1.69658C13.0381 1.49117 12.9524 1.30416 12.8147 1.16818C12.677 1.03245 12.487 0.948011 12.2791 0.948011H6.03722C5.82881 0.948011 5.63905 1.03245 5.50134 1.16818C5.36362 1.3039 5.27795 1.49117 5.27795 1.69658V4.45591V4.45565ZM4.79936 5.40366H2.59565C2.38724 5.40366 2.19749 5.4881 2.06003 5.62383C1.92232 5.75956 1.83664 5.94657 1.83664 6.15223V12.3039C1.83664 12.5093 1.92232 12.6964 2.06003 12.8321C2.19775 12.9678 2.3875 13.0522 2.59618 13.0522H8.83804C9.04646 13.0522 9.23621 12.9678 9.37392 12.8321C9.51164 12.6964 9.59732 12.5093 9.59732 12.3039V6.15223C9.59732 5.94683 9.51164 5.75982 9.37392 5.62383C9.23621 5.4881 9.04646 5.40366 8.83804 5.40366H4.79936Z"
                        fill="#747474"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_370_1059">
                        <rect
                          width="13.125"
                          height="14"
                          fill="white"
                          transform="translate(0.875)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                </span>
                <download-strategy-report
                  v-if="isReportAvailable"
                  :version="firstVersion"
                />
              </div> -->
            </div>
            <div
              v-if="firstVersion && isReportAvailable "
              ref="strategyDescriptionFirst"
              :class="{'tw-overflow-y-clip': showFirstVersionFeedbacks, 'tw-overflow-y-auto': !showFirstVersionFeedbacks}"
              class="tw-bg-white tw-relative tw-rounded-xl scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black  tw-h-[calc(100vh-260px)]"
            >
              <div
                class="tw-sticky tw-w-full tw-flex tw-justify-end tw-top-4 tw-px-4 tw-cursor-pointer"
                title="Show feedbacks"
              >
                <span
                  v-if="!showFirstVersionFeedbacks"
                  @click="showFirstVersionFeedbacks=true"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.96329 1.9797C10.1341 2.15056 10.1341 2.42757 9.96329 2.59842L5.02265 7.53906L9.96329 12.4797C10.1341 12.6506 10.1341 12.9276 9.96329 13.0984C9.79243 13.2693 9.51543 13.2693 9.34457 13.0984L4.09457 7.84842C3.92372 7.67757 3.92372 7.40056 4.09457 7.2297L9.34457 1.9797C9.51543 1.80885 9.79244 1.80885 9.96329 1.9797Z"
                      fill="#747474"
                    />
                  </svg>
                </span>
              </div>
              <small-feedback-sidebar
                v-if="showFirstVersionFeedbacks"
                :close-handler="() => showFirstVersionFeedbacks = false"
                :strategy="strategy"
                :version="firstVersion"
                :is-report-available="isReportAvailable"
                :current-user="currentUser"
              />

              <div class="tw-py-4 tw-px-8">
                <report-renderer
                  v-if="!showChanges"
                  :version-description="firstVersion.description"
                />
                <nested-changes-list
                  v-else

                  :show-changes="'removed'"
                  :first-version="firstVersion.description"
                  :second-version="secondVersion.description"
                />
                <!-- <div class="tw-my-4">
                  <p class="tw-py-2 tw-font-semibold tw-text-base">
                    Strengths
                  </p>

                  <ul
                    class="tw-px-5"
                  >
                    <template v-if="showChanges">
                      <changes-list-item
                        key-name="strengths"
                        :items-array="firstVersion.description.strengths"
                        version-to-compare="second"
                        :first-version="firstVersion.description"
                        :second-version="secondVersion.description"
                      />
                    </template>
                    <template v-else>
                      <li
                        v-for="(strength, index) in firstVersion.description.strengths"
                        :key="`strength_${index}`"
                        class="tw-py-1  tw-text-black-one"
                      >
                        {{ strength }}
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="tw-my-4">
                  <p class="tw-py-2 tw-font-semibold tw-text-base">
                    Weakness
                  </p>

                  <ul
                    class="tw-px-5"
                  >
                    <template v-if="showChanges">
                      <changes-list-item
                        key-name="weaknesses"
                        :items-array="firstVersion.description.weaknesses"
                        version-to-compare="second"
                        :first-version="firstVersion.description"
                        :second-version="secondVersion.description"
                      />
                    </template>
                    <template v-else>
                      <li
                        v-for="(weakness, index) in firstVersion.description.weaknesses"
                        :key="`weakness_${index}`"
                        class="tw-py-1  tw-text-black-one"
                      >
                        {{
                          weakness
                        }}
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="tw-my-4">
                  <p class="tw-py-2 tw-font-semibold tw-text-base">
                    Opportunities
                  </p>

                  <ul
                    class="tw-px-5"
                  >
                    <template v-if="showChanges">
                      <changes-list-item
                        key-name="opportunities"
                        :items-array="firstVersion.description.opportunities"
                        version-to-compare="second"
                        :first-version="firstVersion.description"
                        :second-version="secondVersion.description"
                      />
                    </template>
                    <template v-else>
                      <li
                        v-for="(opportunity, index) in firstVersion.description.opportunities"
                        :key="`opportunity_${index}`"
                        class="tw-py-1  tw-text-black-one"
                      >
                        {{
                          opportunity
                        }}
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="tw-my-4">
                  <p class="tw-py-2 tw-font-semibold tw-text-base">
                    Threats
                  </p>

                  <ul
                    class="tw-px-5"
                  >
                    <template v-if="showChanges">
                      <changes-list-item
                        key-name="threats"
                        :items-array="firstVersion.description.threats"
                        version-to-compare="second"
                        :first-version="firstVersion.description"
                        :second-version="secondVersion.description"
                      />
                    </template>
                    <template v-else>
                      <li
                        v-for="(threat, index) in firstVersion.description.threats"
                        :key="`threat_${index}`"
                        class="tw-py-1  tw-text-black-one"
                      >
                        {{
                          threat
                        }}
                      </li>
                    </template>
                  </ul>
                </div> -->
              </div>
            </div>
            <!-- <div
              v-if="firstVersion && isReportAvailable "
              class="tw-bg-white tw-p-8 tw-relative tw-rounded-xl scrollbar-thin tw-overflow-auto scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black  tw-h-[calc(100vh-260px)]"
            >
              <report-renderer
                v-if="!showChanges"
                :version-description="firstVersion.description"
              />
              <nested-changes-list
                v-else

                :show-changes="'removed'"
                :first-version="firstVersion.description"
                :second-version="secondVersion.description"
              />
            </div> -->
            <p
              v-else
              class="tw-bg-white tw-p-8 tw-rounded-xl tw-text-xl tw-font-semibold"
            >
              We are still processing the analysis on the report. Please come back later.
            </p>
          </div>
        </div>
        <div class="tw-flex tw-max-w-3xl tw-w-full   tw-max-h-full ">
          <div class="tw-w-full ">
            <div
              class="tw-flex tw-space-x-3 tw-py-0 tw-justify-between  tw-items-center tw-my-2"
            >
              <button

                class="tw-flex tw-bg-white tw-relative tw-min-w-fit tw-w-fit tw-justify-between !tw-right-auto tw-left-0 tw-py-2 tw-rounded-xl tw-px-3 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                @click="showVersions('compare')"
              >
                <span class=" tw-text-left tw-w-full">
                  {{ secondVersion ? secondVersion.name : 'Select version' }}
                </span>
                <span class="tw-w-fit  tw-block tw-pt-1">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_930_2517)">
                      <path
                        d="M8.50709 10.9945C8.33286 10.9945 8.16163 10.9308 8.03546 10.815L4.19334 7.10336C3.92899 6.85148 3.92899 6.44036 4.19034 6.18559C4.45168 5.93081 4.87825 5.93081 5.14261 6.18269L8.51009 9.43978L11.8746 6.18269C12.1479 5.94239 12.5715 5.96266 12.8208 6.22612C13.0522 6.47221 13.0522 6.84859 12.8208 7.09468L8.97872 10.815C8.85255 10.9308 8.68132 10.9974 8.50709 10.9945Z"
                        fill="#474747"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_930_2517">
                        <rect
                          width="5"
                          height="9"
                          fill="white"
                          transform="translate(12.9951 5.99414) rotate(90)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                </span>
                <div>
                  <div
                    v-if="showStrategyVersionsToCompare && allStrategyVersions.length > 2"
                    class="flex flex-col -tw-right-[1px] !tw-text-sm  justify-center cursor-pointer tw-border-[0.5px] tw-border-solid tw-border-white-two tw-rounded-lg tw-overflow-hidden absolute bg-white tw-shadow-lg !tw-ml-0 !tw-min-w-fit !tw-w-[123px] export-popup !tw-top-[33px] tw-border-t-0 tw-rounded-t-none !tw-text-left"
                  >
                    <template
                      v-for="(version, index) in allStrategyVersions.filter((v)=>{
                        return v.name!=='initial version'
                      })"
                    >
                      <span
                        v-if="version.name!==selectedDropdownVersion"
                        :key="`version_${index}`"
                        class="!tw-p-2 tw-truncate !tw-min-w-fit "
                        @click="
                          changeSelectedVersionToCompare($event, version)
                        "
                      >
                        {{ version.name }}
                        <strong
                          v-if="version.name !='initial version'"
                          class="!tw-text-left !tw-font-normal tw-text-xs "
                        >
                          - {{ getReadableDate(version.createdAt) }}
                        </strong>
                      </span>
                    </template>
                  </div>
                </div>
              </button>
              <!-- <span>
                Show changes
              </span>
              <ah-toggle-switch
                id="show-toggle-second"
                class="tw-ml-2"
                :checked="showChanges"
                @input="toggleShowChanges"
                />
              -->
              <div class="tw-relative">
                <span
                  class="tw-bg-white tw-p-1.5 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-full"
                  @click="toggleDownloadMenus('second')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="tw-w-5 tw-h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                  </svg>

                </span>
                <div
                  v-if="showDownloadMenusForSecondVersion"
                  class="tw-absolute tw-z-10 tw-left-[36px] tw-top-0 tw-flex tw-flex-col tw-space-y-3 tw-justify-center tw-items-center"
                >
                  <span
                    class="tw-bg-white tw-p-2 tw-cursor-pointer tw-rounded-full tw-relative tw-flex tw-items-center"
                    title="Copy"
                    @click="copyStrategy"
                  >
                    <span
                      v-if="copied"
                      class="tw-flex tw-space-x-2  tw-bg-white tw-px-2 tw-py-1 tw-rounded-md tw-text-black tw-ml-6 tw-items-center tw-absolute"
                    >
                      Copied
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="tw-w-4 tw-h-4 tw-ml-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>

                    </span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_370_1059)">
                        <path
                          d="M2.59618 4.45565H4.31604V1.69632C4.31604 1.22931 4.50947 0.805032 4.82117 0.497835C5.13261 0.190638 5.56336 0 6.03722 0H12.2791C12.7529 0 13.1834 0.190638 13.4951 0.497835C13.8068 0.805032 14.0003 1.22931 14.0003 1.69632V7.84803C14.0003 8.31504 13.8063 8.73932 13.4951 9.04651C13.1834 9.35371 12.7529 9.54435 12.2791 9.54435H10.5592V12.3037C10.5592 12.7707 10.3658 13.195 10.0541 13.5022C9.74239 13.8094 9.3119 14 8.83804 14H2.59618C2.12232 14 1.69183 13.8094 1.38013 13.5022C1.06843 13.195 0.875 12.7707 0.875 12.3037V6.15197C0.875 5.68496 1.06843 5.26068 1.38013 4.95349C1.69183 4.64629 2.12232 4.45565 2.59618 4.45565ZM5.27795 4.45565H8.83804C9.3119 4.45565 9.74239 4.64629 10.0541 4.95349C10.3658 5.26068 10.5592 5.68496 10.5592 6.15197V8.59634H12.2791C12.4875 8.59634 12.6773 8.5119 12.8147 8.37617C12.9524 8.24044 13.0381 8.05343 13.0381 7.84829V1.69658C13.0381 1.49117 12.9524 1.30416 12.8147 1.16818C12.677 1.03245 12.487 0.948011 12.2791 0.948011H6.03722C5.82881 0.948011 5.63905 1.03245 5.50134 1.16818C5.36362 1.3039 5.27795 1.49117 5.27795 1.69658V4.45591V4.45565ZM4.79936 5.40366H2.59565C2.38724 5.40366 2.19749 5.4881 2.06003 5.62383C1.92232 5.75956 1.83664 5.94657 1.83664 6.15223V12.3039C1.83664 12.5093 1.92232 12.6964 2.06003 12.8321C2.19775 12.9678 2.3875 13.0522 2.59618 13.0522H8.83804C9.04646 13.0522 9.23621 12.9678 9.37392 12.8321C9.51164 12.6964 9.59732 12.5093 9.59732 12.3039V6.15223C9.59732 5.94683 9.51164 5.75982 9.37392 5.62383C9.23621 5.4881 9.04646 5.40366 8.83804 5.40366H4.79936Z"
                          fill="#747474"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_370_1059">
                          <rect
                            width="13.125"
                            height="14"
                            fill="white"
                            transform="translate(0.875)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                  </span>
                  <download-strategy-report
                    :version="secondVersion"
                  />
                </div>
              </div>
              <!-- <div class="tw-flex tw-space-x-2 tw-items-center">
                <span
                  class="tw-bg-white tw-p-2 tw-cursor-pointer tw-rounded-full tw-relative tw-flex tw-items-center"
                  title="Copy"
                  @click="copyStrategy('second')"
                >

                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_370_1059)">
                      <path
                        d="M2.59618 4.45565H4.31604V1.69632C4.31604 1.22931 4.50947 0.805032 4.82117 0.497835C5.13261 0.190638 5.56336 0 6.03722 0H12.2791C12.7529 0 13.1834 0.190638 13.4951 0.497835C13.8068 0.805032 14.0003 1.22931 14.0003 1.69632V7.84803C14.0003 8.31504 13.8063 8.73932 13.4951 9.04651C13.1834 9.35371 12.7529 9.54435 12.2791 9.54435H10.5592V12.3037C10.5592 12.7707 10.3658 13.195 10.0541 13.5022C9.74239 13.8094 9.3119 14 8.83804 14H2.59618C2.12232 14 1.69183 13.8094 1.38013 13.5022C1.06843 13.195 0.875 12.7707 0.875 12.3037V6.15197C0.875 5.68496 1.06843 5.26068 1.38013 4.95349C1.69183 4.64629 2.12232 4.45565 2.59618 4.45565ZM5.27795 4.45565H8.83804C9.3119 4.45565 9.74239 4.64629 10.0541 4.95349C10.3658 5.26068 10.5592 5.68496 10.5592 6.15197V8.59634H12.2791C12.4875 8.59634 12.6773 8.5119 12.8147 8.37617C12.9524 8.24044 13.0381 8.05343 13.0381 7.84829V1.69658C13.0381 1.49117 12.9524 1.30416 12.8147 1.16818C12.677 1.03245 12.487 0.948011 12.2791 0.948011H6.03722C5.82881 0.948011 5.63905 1.03245 5.50134 1.16818C5.36362 1.3039 5.27795 1.49117 5.27795 1.69658V4.45591V4.45565ZM4.79936 5.40366H2.59565C2.38724 5.40366 2.19749 5.4881 2.06003 5.62383C1.92232 5.75956 1.83664 5.94657 1.83664 6.15223V12.3039C1.83664 12.5093 1.92232 12.6964 2.06003 12.8321C2.19775 12.9678 2.3875 13.0522 2.59618 13.0522H8.83804C9.04646 13.0522 9.23621 12.9678 9.37392 12.8321C9.51164 12.6964 9.59732 12.5093 9.59732 12.3039V6.15223C9.59732 5.94683 9.51164 5.75982 9.37392 5.62383C9.23621 5.4881 9.04646 5.40366 8.83804 5.40366H4.79936Z"
                        fill="#747474"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_370_1059">
                        <rect
                          width="13.125"
                          height="14"
                          fill="white"
                          transform="translate(0.875)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>

                <download-strategy-report
                  v-if="isReportAvailable"
                  :version="secondVersion"
                />
              </div> -->
            </div>
            <div
              v-if="!isSelected && secondVersion"
              class="tw-bg-white tw-p-8 tw-rounded-xl "
            >
              <p class="tw-text-center tw-text-lg tw-font-semibold">
                No version selected
              </p>
              <p class="tw-text-center tw-mt-2 tw-text-[15px]">
                Choose the version you wish to compare to another selected version
              </p>
            </div>
            <div
              v-else-if="secondVersion && isSelected && isReportAvailable "
              ref="strategyDescriptionTwo"
              :class="{'tw-overflow-y-clip': showSecondVersionFeedbacks, 'tw-overflow-y-auto': !showSecondVersionFeedbacks}"
              class="tw-bg-white tw-relative tw-rounded-xl scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black  tw-h-[calc(100vh-260px)]"
            >
              <div
                class="tw-sticky tw-w-full tw-flex tw-justify-end tw-top-4 tw-px-4 tw-cursor-pointer"
                title="Show feedbacks"
              >
                <span
                  v-if="!showSecondVersionFeedbacks"
                  @click="showSecondVersionFeedbacks=true"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.96329 1.9797C10.1341 2.15056 10.1341 2.42757 9.96329 2.59842L5.02265 7.53906L9.96329 12.4797C10.1341 12.6506 10.1341 12.9276 9.96329 13.0984C9.79243 13.2693 9.51543 13.2693 9.34457 13.0984L4.09457 7.84842C3.92372 7.67757 3.92372 7.40056 4.09457 7.2297L9.34457 1.9797C9.51543 1.80885 9.79244 1.80885 9.96329 1.9797Z"
                      fill="#747474"
                    />
                  </svg>
                </span>
              </div>
              <small-feedback-sidebar
                v-if="showSecondVersionFeedbacks"
                :close-handler="() => showSecondVersionFeedbacks = false"
                :strategy="strategy"
                :version="secondVersion"
                :is-report-available="isReportAvailable"
                :current-user="currentUser"
              />

              <div class="tw-py-4 tw-px-8">
                <report-renderer
                  v-if="!showChanges"
                  :version-description="secondVersion.description"
                />
                <nested-changes-list
                  v-else

                  :show-changes="'added'"
                  :first-version="firstVersion.description"
                  :second-version="secondVersion.description"
                />
                <!-- <div class="tw-my-4">
                  <p class="tw-py-2 tw-font-semibold tw-text-base">
                    Strengths
                  </p>

                  <ul
                    class="tw-px-5"
                  >
                    <template v-if="showChanges">
                      <changes-list-item
                        key-name="strengths"
                        :items-array="secondVersion.description.strengths"
                        version-to-compare="first"
                        :first-version="firstVersion.description"
                        :second-version="secondVersion.description"
                      />
                    </template>
                    <template v-else>
                      <li
                        v-for="(strength, index) in secondVersion.description.strengths"
                        :key="`strength_${index}`"
                        class="tw-py-1  tw-text-black-one"
                      >
                        {{ strength }}
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="tw-my-4">
                  <p class="tw-py-2 tw-font-semibold tw-text-base">
                    Weakness
                  </p>

                  <ul
                    class="tw-px-5"
                  >
                    <template v-if="showChanges">
                      <changes-list-item
                        key-name="weaknesses"
                        :items-array="secondVersion.description.weaknesses"
                        version-to-compare="first"
                        :first-version="firstVersion.description"
                        :second-version="secondVersion.description"
                      />
                    </template>
                    <template v-else>
                      <li
                        v-for="(weakness, index) in secondVersion.description.weaknesses"
                        :key="`weakness_${index}`"
                        class="tw-py-1  tw-text-black-one"
                      >
                        {{
                          weakness
                        }}
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="tw-my-4">
                  <p class="tw-py-2 tw-font-semibold tw-text-base">
                    Opportunities
                  </p>

                  <ul
                    class="tw-px-5"
                  >
                    <template v-if="showChanges">
                      <changes-list-item
                        key-name="opportunities"
                        :items-array="secondVersion.description.opportunities"
                        version-to-compare="first"
                        :first-version="firstVersion.description"
                        :second-version="secondVersion.description"
                      />
                    </template>
                    <template v-else>
                      <li
                        v-for="(opportunity, index) in secondVersion.description.opportunities"
                        :key="`opportunity_${index}`"
                        class="tw-py-1  tw-text-black-one"
                      >
                        {{
                          opportunity
                        }}
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="tw-my-4">
                  <p class="tw-py-2 tw-font-semibold tw-text-base">
                    Threats
                  </p>

                  <ul
                    class="tw-px-5"
                  >
                    <template v-if="showChanges">
                      <changes-list-item
                        key-name="threats"
                        :items-array="secondVersion.description.threats"
                        version-to-compare="first"
                        :first-version="firstVersion.description"
                        :second-version="secondVersion.description"
                      />
                    </template>
                    <template v-else>
                      <li
                        v-for="(threat, index) in secondVersion.description.threats"
                        :key="`threat_${index}`"
                        class="tw-py-1  tw-text-black-one"
                      >
                        {{
                          threat
                        }}
                      </li>
                    </template>
                  </ul>
                </div> -->
              </div>
            </div>
            <!-- <div
              v-else-if="secondVersion && isReportAvailable && isSelected && strategy.framework !=
                'SWOT'"
              class="tw-bg-white tw-p-8 tw-relative tw-rounded-xl scrollbar-thin tw-overflow-auto scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black  tw-h-[calc(100vh-260px)]"
            >
              <report-renderer
                v-if="!showChanges"
                :version-description="secondVersion.description"
              />
              <nested-changes-list
                v-else

                :show-changes="'added'"
                :first-version="firstVersion.description"
                :second-version="secondVersion.description"
              />
            </div> -->
            <p
              v-else
              class="tw-bg-white tw-p-8 tw-rounded-xl tw-text-xl tw-font-semibold"
            >
              We are still processing the analysis on the report. Please come back later.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AhToggleSwitch,
  StToastNotification
} from '../../common'
import ChangesListItem from './changes-list-item.vue'
import SmallFeedbackSidebar from './small-feedback-sidebar.vue'
import DownloadStrategyReport from './download-strategy-report.vue'
import ReportRenderer from './new-report-description.vue'
import NestedChangesList from './nested-change-list.vue'
import { first } from 'lodash'
export default {
  name: 'AskhumansReportViewDetail',

  components: {
    DownloadStrategyReport,
    ReportRenderer,
    NestedChangesList,
    AhToggleSwitch,
    SmallFeedbackSidebar,
    ChangesListItem,
    StToastNotification
  },

  props: {
    firstVersion: {
      type: Object,
      required: true
    },
    isReportAvailable: {
      type: Boolean,
      required: true
    },
    secondVersion: {
      type: Object,
      required: true
    },
    strategy: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    showChanges: {
      type: Boolean,
      required: false,
      default: false
    },
    allStrategyVersions: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      // showChanges: false,
      showSecondChanges: false,
      selectedDropdownVersion: '',
      copied: false,
      showFirstVersionFeedbacks: false,
      showSecondVersionFeedbacks: false,
      showStrategyVersionsToCompare: false,
      showStrategyVersions: false,
      showDownloadMenusForFirstVersion: false,
      showDownloadMenusForSecondVersion: false
    }
  },

  mounted () {
    // console.log('first version---', this.secondVersion)
    // console.log('second', this.secondVersion)
  },

  methods: {

    // toggleShowChanges () {
    //   this.showChanges = !this.showChanges
    // },
    toggleDownloadMenus (version) {
      if (version == 'first') {
        this.showDownloadMenusForFirstVersion = !this.showDownloadMenusForFirstVersion
      } else {
        this.showDownloadMenusForSecondVersion = !this.showDownloadMenusForSecondVersion
      }
      // this.showDownloadMenusForFirstVersion = !this.showDownloadMenusForFirstVersion
    },
    showVersions (type) {
      if (type == 'selected') {
        this.showStrategyVersionsToCompare = false
        if (this.showStrategyVersions) {
          this.showStrategyVersions = false
        } else {
          this.showStrategyVersions = true
        }
      } else if (type == 'compare') {
        this.showStrategyVersions = false
        if (this.showStrategyVersionsToCompare) {
          this.showStrategyVersionsToCompare = false
        } else {
          this.showStrategyVersionsToCompare = true
        }
      }

      // this.showStrategyStudies = false
    },
    redirectToVersion (id) {
      this.$store.commit('toggleFullSpinner', true)
      window.location.href = `/reports/${this.strategy.id}?version=${id}`
    },
    changeSelectedVersion (e, version) {
      e.stopPropagation()
      // this.selectedStrategyVersion = version
      this.selectedDropdownVersion = version.name
      this.showVersions('selected')
      this.redirectToVersion(version.id)
    },
    changeSelectedVersionToCompare (event, version) {
      this.$emit('changeSelectedVersionToCompare', event, version)
      this.showStrategyVersionsToCompare = false
    },
    getReadableDate (unformattedDate) {
      if (!unformattedDate) return
      const date = new Date(unformattedDate)
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },
    copyStrategy (version) {
      let strategyDescription
      if (version == 'first') {
        strategyDescription = this.$refs.strategyDescriptionFirst
      } else {
        strategyDescription = this.$refs.strategyDescriptionTwo
      }
      const range = document.createRange()
      range.selectNode(strategyDescription)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)

      try {
        // Copy the HTML content
        document.execCommand('copy')
        console.log('Content copied to clipboard')
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 2000)
      } catch (err) {
        console.error('Unable to copy content to clipboard', err)
      }

      // Clear the selection
      selection.removeAllRanges()
    },
    getChanges (category, index, compareVersion, item) {
      if (compareVersion == 'first') {
        if (this.firstVersion[category][index] == item) {
          return {
            'same': item
          }
        } else if (this.firstVersion[category].includes(item)) {
          return {
            'same': item
          }
        } else {
          if (this.secondVersion[category].includes(this.firstVersion[category][index])) {
            return {
              added: this.secondVersion[category][index]

            }
          } else {
            return {
              ...(this.firstVersion[category][index] && { removed: this.firstVersion[category][index] }),
              added: this.secondVersion[category][index]
            }
          }
        }
      } else if (compareVersion == 'second') {
        if (this.secondVersion[category][index] == item) {
          return {
            'same': item
          }
        } else if (this.secondVersion[category].includes(item)) {
          return {
            'same': item
          }
        } else {
          if (this.firstVersion[category].includes(this.secondVersion[category][index])) {
            return {
              added: this.firstVersion[category][index]
            }
          } else {
            return {
              ...(this.secondVersion[category][index] && { removed: this.secondVersion[category][index] }),
              added: this.firstVersion[category][index]
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
