<template>
  <div>
    <upgrade-plan-modal
      ref="upgradePlan"
      title="Create study"
      description="You have already reached the maximum amount of studies allowed for your plan."
    />
    <!-- <top-header /> -->
    <div class="bx--grid mobile-header flex justify-end md:hidden">
      <!-- <a href="/welcome">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a> -->
      <div
        v-if="project.canCreateSurvey"
        class="mobile-header__button"
      >
        <st-button
          caption="Create Study"
          icon-name="pointer"
          :show-action="true"
          @click="createStudyWithDefaultName"
        />
      </div>
    </div>
    <div
      class="edit-cover-photo__photo-and-button edit-cover-photo--hide-on-mobile"
      tabIndex="0"
      @focus="focusEditCoverPhoto"
      @focusout="coverPhotoHovered = false"
      @mouseleave="coverPhotoHovered = false"
      @mouseover="coverPhotoHovered = true"
    >
      <div
        :class="{
          'edit-cover-photo__photo': true,
          'edit-cover-photo__default': !useLargeDefaultCoverPhoto,
          'edit-cover-photo__default--large': useLargeDefaultCoverPhoto,
          'edit-cover-photo__photo--hovered': coverPhotoHovered,
        }"
        :style="projectCoverPhotoStyle"
      />
      <transition name="fade">
        <div
          v-if="
            (coverPhotoHovered && isCurrentUserInProject) ||
              coverPhotoHovered & isUserAdmin
          "
          ref="editCoverPhotoButton"
          :class="{
            'edit-cover-photo__button': true,
            'edit-cover-photo__button--active': editCoverPhotoActive,
          }"
          tabIndex="0"
          @focus="coverPhotoHovered = true"
          @keyup.enter="openMediaUploader('cover_image')"
          @mouseup="openMediaUploader('cover_image')"
        >
          Change Cover Photo
        </div>
      </transition>
    </div>
    <div class="bx--grid">
      <div class="bx--row">
        <div class="bx--col-xs-12">
          <st-hyperlink
            class="back-hyperlink-spacing"
            :back-link="true"
            text="Back"
            url="/projects"
          />
          <div class="project__name-and-edit-icon">
            <div class="project__name-edit-canvas">
              <h1 class="project__page-header">
                {{ projectName }}
              </h1>
              <div
                v-if="isCurrentUserInProject || isUserAdmin"
                @click="editNameAndLocationModal = true"
              >
                <icon-wrapper
                  icon-name="edit"
                  class="st-survey-hub__edit-name-icon"
                />
              </div>
            </div>
            <div
              v-if="project.canCreateSurvey"
              class="desktop-page-right-action-button desktop-page-right-action-button--zero-space hidden md:block"
            >
              <st-button
                ref="create"
                caption="Create Study"
                icon-name="pointer"
                :show-action="true"
                @click="createStudyWithDefaultName"
              />
            </div>
          </div>
          <div
            :class="{
              'project__location-and-icon': true,
              'project__location-and-icon--nav-open': $store.state.navOpen,
            }"
          >
            <icon-wrapper
              class="project__location--icon"
              icon-name="location"
              :neutral="true"
            />
            <div class="project__location">
              {{ projectLocation }}
            </div>
          </div>
          <project-members
            :can-edit="project.canEdit"
            :members="projectInformation.users"
            :users="users"
            :project-id="projectInformation.id"
            @updateMember="updateMember"
            @updateMembers="updateMembers"
          />

          <!-- TODO: add calender dropdown / updats stats -->
          <!-- <div class="project__summary-statement">
            <p>
              Here is a summary of the last
            </p>
            <div class="project__calender-dropdown">
              30 Days
            </div>
          </div>
          <div class="project__statistics">
            <div class="statistic">
              <p class="statistic__number">
                {{ projectInformation.surveys.length }}
              </p>
              <p class="statistic__description">
                Surveys Sent
              </p>
            </div>
            <div class="statistic statistic--center">
              <p class="statistic__number">
                {{ Math.floor(projectInformation.responseRate * 100) }}%
              </p>
              <p class="statistic__description">
                Response Rate
              </p>
            </div>
            <div class="statistic">
              <p class="statistic__number">
                {{ projectInformation.participantCount }}
              </p>
              <p class="statistic__description">
                Participants
              </p>
            </div>
          </div> -->
          <div class="bx--row">
            <div class="bx--col-sm-10 bx--col-xs-12">
              <div
                v-if="projectInformation.surveys.length"
                class="lookup-and-dropdown"
              >
                <st-input
                  ref="search"
                  v-model="surveyLookupValue"
                  placeholder="Search"
                  class="st-input--default lookup-and-dropdown__space-input"
                  :show-icon="true"
                  :show-label="false"
                  style-type="secondary"
                  label="Survey Lookup"
                  label-in-field-class="st-input--hide-mobile-label"
                  @blur="updateSearchParam"
                />
                <st-dropdown-menu
                  caption="Surveys Filter"
                  :initial-active-option="activeSurveyFilterOption"
                  :options="Object.values(surveyFilterValues)"
                  @updateOption="setDropdownOption"
                />
              </div>
              <div class="surveys">
                <st-card
                  v-for="survey in activePageSurveys"
                  :key="'s-' + survey.id"
                  tabindex="0"
                  class="survey-card__position-contents"
                  :url="
                    survey.published
                      ? `/study/${survey.id}/results`
                      : `/study/${survey.id}/edit`
                  "
                  @click.native.stop="advanceViaSurveyCard(survey)"
                >
                  <template v-slot:card-content="slotProps">
                    <div class="survey-card__title-and-date">
                      <div class="survey-card__position-title">
                        <icon-wrapper
                          class="survey-card__title-icon"
                          icon-name="survey"
                        />
                        <p
                          :class="{
                            'survey-card__title': true,
                            'survey-card__title--hover': slotProps.hover,
                          }"
                        >
                          {{ survey.name ? survey.name : "[Study Name]" }}
                        </p>
                      </div>
                      <div class="survey-card__position-date space-x-3">
                        <icon-wrapper
                          class="project-and-survey-cards__date-icon"
                          icon-name="calendar"
                          :actionable="false"
                        />
                        <p class="project-and-survey-cards__date">
                          Modified {{ survey.updatedAt.format("MMM DD, YYYY") }}
                        </p>
                        <p class="project-and-survey-cards__date--mobile">
                          {{ survey.updatedAt.format("MM/DD/YYYY") }}
                        </p>
                        <div
                          :class="{
                            'survey-card__status survey-card__status--mobile': true,
                            [`survey-card__status--${tagCaption(
                              survey
                            ).toLowerCase()}`]: true,
                            'survey-card__status--hover': slotProps.hover,
                          }"
                        >
                          {{ tagCaption(survey) }}
                        </div>
                      </div>
                    </div>
                    <div
                      :class="{
                        'survey-card__status': true,
                        [`survey-card__status--${tagCaption(
                          survey
                        ).toLowerCase()}`]: true,
                        'survey-card__status--hover': slotProps.hover,
                      }"
                    >
                      {{ tagCaption(survey) }}
                    </div>
                    <div
                      class="project-and-survey-cards__position-statistic project-and-survey-cards__position-statistic--question-or-survey-count"
                    >
                      <p
                        :class="{
                          'project-and-survey-cards__statistic-number': true,
                          'project-and-survey-cards__statistic-number--hover':
                            slotProps.hover,
                        }"
                      >
                        {{ survey.questionCount }}
                      </p>
                      <p
                        :class="{
                          'project-and-survey-cards__statistic-title': true,
                          'project-and-survey-cards__statistic-title--hover':
                            slotProps.hover,
                        }"
                      >
                        Questions
                      </p>
                    </div>
                    <div
                      v-if="!$store.state.navOpen"
                      class="project-and-survey-cards__position-statistic project-and-survey-cards__position-statistic--member-count"
                    >
                      <p
                        :class="{
                          'project-and-survey-cards__statistic-number': true,
                          'project-and-survey-cards__statistic-number--hover':
                            slotProps.hover,
                        }"
                      >
                        {{ survey.memberCount }}
                      </p>
                      <p
                        :class="{
                          'project-and-survey-cards__statistic-title': true,
                          'project-and-survey-cards__statistic-title--hover':
                            slotProps.hover,
                        }"
                      >
                        Members
                      </p>
                    </div>
                    <st-tooltip
                      class="project-and-survey-cards__overflow-menu"
                      :direction="isDesktop ? 'right' : 'left'"
                      :flash="survey.linkCopied"
                      :is-active="false"
                      :tip="tipMessage"
                    >
                      <st-overflow-menu
                        :key="'s-overflow-' + survey.id"
                        :class="{
                          'project-and-survey-cards__overflow-menu-hover':
                            slotProps.hover,
                        }"
                        :title="`${survey.name}`"
                        icon-name="survey"
                        :is-empty="isOverflowEmpty(survey)"
                      >
                        <st-overflow-menu-item
                          v-if="survey.questionCount > 0"
                          text="Create Template"
                          icon-name="pointer"
                          @mouseup.native="checkCreateTemplate(survey)"
                        />
                        <st-overflow-menu-item
                          v-if="survey.published"
                          text="Hub"
                          icon-name="hub"
                          :path="`/study/${survey.id}/edit`"
                        />
                        <st-overflow-menu-item
                          v-if="survey.published"
                          text="Share"
                          icon-name="share"
                          @mouseup="copyToClipboard(survey.id)"
                        />
                        <st-overflow-menu-item
                          v-if="project.canCreateSurvey"
                          text="Duplicate"
                          icon-name="duplicate"
                          @mouseup.native="duplicateSurvey(survey.id)"
                        />
                        <template v-if="survey.canEdit">
                          <st-overflow-menu-item
                            v-if="survey.archived"
                            text="Unarchive"
                            icon-name="archive"
                            @mouseup="openArchiveDialogue(survey.id, false)"
                          />
                          <st-overflow-menu-item
                            v-else
                            text="Archive"
                            icon-name="archive"
                            @mouseup="openArchiveDialogue(survey.id, true)"
                          />
                        </template>
                      </st-overflow-menu>
                    </st-tooltip>
                    <textarea
                      :ref="'copyText' + survey.id"
                      class="st-share-text-area"
                      readonly
                      :value="`${baseUrl}/study/${survey.id}`"
                    />
                  </template>
                </st-card>
              </div>
              <st-pagination
                v-if="projectInformation.surveys.length"
                :number-of-items="filteredSurveysBySearch.length"
                @updateActiveIndices="updateIndicesOfSurveysShown"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <st-media-uploader
      v-if="showMediaUploader"
      :aspect-ratio="4.23"
      :folders="folders"
      prop-name="project[cover_image]"
      :modal-title="`Change ${project.name} Cover Photo`"
      :url="`/projects/${project.id}`"
      @closeMediaUploader="showMediaUploader = false"
      @uploadComplete="uploadComplete"
    />
    <st-modal v-model="archiveModal">
      <template v-slot:modalTitle>
        <template v-if="archiveBoolean">
          Archive Survey
        </template>
        <template v-else>
          Unarchive Survey
        </template>
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text">
          <div v-if="archiveBoolean">
            Are you sure you want to archive this survey? You will be unable to
            view or access insights unless you unarchive it later.
          </div>
          <div v-else>
            Are you sure you want to unarchive this survey?
          </div>
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          :caption="archiveBoolean ? 'Archive Survey' : 'Unarchive Survey'"
          :show-action="true"
          icon-name="archive"
          @click="archiveSurvey"
        />
      </template>
    </st-modal>
    <st-modal v-model="editNameAndLocationModal">
      <template slot="modalTitle">
        Edit Project Information
      </template>
      <template slot="modalActions">
        <st-input
          v-model="projectInformation.name"
          class="st-survey-hub__edit-name-input"
          :show-label="true"
          label="Project Name"
        />
        <st-input
          ref="autoComplete"
          v-model="autoCompleteValue"
          class="st-survey-hub__edit-name-input"
          :show-label="true"
          label="Project Location"
        />
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          :disabled="!projectInformation.name"
          icon-name="save"
          @click="editProjectInfo"
        />
      </template>
    </st-modal>
    <st-modal
      v-model="confirmationModal"
      :cancel-modal="true"
      :non-exit-modal="true"
      @closeModal="confirmationModal = false"
    >
      <template v-slot:modalTitle>
        Create {{ selectedSurvey.name }} Template
      </template>
      <template v-slot:modalActions>
        <div class="st-publish-options-modal__instructions-container">
          <p class="st-publish-options-modal__instruction-text">
            You are logged into the AskHumans Account, Templates created here
            will show up as Default Templates for every other account.
          </p>
          <p class="st-publish-options-modal__instruction-text">
            Are you sure you want to create this template?
          </p>
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Create Template"
          icon-name="pointer"
          @click="createTemplate(selectedSurvey)"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  advanceToUrl,
  googleAddressAutoComplete,
  isDesktop,
  isUserAdmin,
  refineUrlFilter
} from '../../../mixins'
import { Survey } from '../../../structures/survey'
import {
  IconWrapper,
  StButton,
  StCard,
  StDropdownMenu,
  StHyperlink,
  StInput,
  StMediaUploader,
  StModal,
  StOverflowMenu,
  StOverflowMenuItem,
  StPagination,
  StTooltip,
  TopHeader
} from '../../common/'
import ProjectMembers from './project-members'
import UpgradePlanModal from '../plan/upgrade-plan-modal.vue'
export default {
  components: {
    TopHeader,
    IconWrapper,
    ProjectMembers,
    StButton,
    StCard,
    StDropdownMenu,
    StHyperlink,
    StInput,
    StMediaUploader,
    StModal,
    StOverflowMenu,
    StOverflowMenuItem,
    StPagination,
    StTooltip,
    UpgradePlanModal
  },
  mixins: [
    advanceToUrl,
    googleAddressAutoComplete,
    isDesktop,
    isUserAdmin,
    refineUrlFilter
  ],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    project: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    let projectInformation = {
      ...this.project,
      surveys: this.project.surveys.map(Survey)
    }
    return {
      activeIndices: [0, 0],
      activeSurveyFilterOption: '',
      confirmationModal: false,
      coverPhotoHovered: false,
      editCoverPhotoActive: false,
      editNameAndLocationModal: false,
      initialAutoCompleteValue: projectInformation.locationName,
      projectInformation: {
        ...projectInformation,
        surveys: projectInformation.surveys.map((survey) => {
          return { ...survey, linkCopied: false }
        })
      },
      showMediaUploader: false,
      selectedSurvey: null,
      surveyLookupValue: '',
      tipMessage: 'Link Copied',
      archiveModal: false,
      archveBoolean: null,
      archiveSurveyId: null
    }
  },
  computed: {
    ...mapState(['currentUser']),
    baseUrl () {
      return window.location.origin
    },
    projectCoverPhotoStyle () {
      return this.projectInformation.coverImageUrl
        ? {
          'background-image': `url('${this.projectInformation.coverImageUrl}')`
        }
        : {}
    },
    projectLocation () {
      return this.projectInformation.locationName
        ? this.projectInformation.locationName
        : '[Location]'
    },
    projectName () {
      return this.projectInformation.name
        ? this.projectInformation.name
        : '[Project Name]'
    },
    surveys () {
      return this.projectInformation.surveys
    },
    unarchivedSurveys () {
      return this.surveys.filter((survey) => !survey.archived)
    },
    editableSurveys () {
      return this.unarchivedSurveys.filter((survey) =>
        survey.members.some(
          (member) =>
            member.userId === this.currentUser.id &&
            member.permission !== 'read'
        )
      )
    },
    mySurveys () {
      return this.unarchivedSurveys.filter((survey) =>
        survey.members.some((member) => member.userId === this.currentUser.id)
      )
    },
    draftSurveys () {
      return this.unarchivedSurveys.filter((survey) => !survey.published)
    },
    publishedSurveys () {
      return this.unarchivedSurveys.filter((survey) => survey.published)
    },
    archivedSurveys () {
      return this.surveys.filter((survey) => survey.archived)
    },
    // scheduledSurveys () {
    //   return this.unarchivedSurveys.filter(survey => survey.status === 'scheduled')
    // },
    allSurveysCaption () {
      return `All Surveys (${this.unarchivedSurveys.length})`
    },
    editableSurveysCaptions () {
      return `Editable Surveys (${this.editableSurveys.length})`
    },
    mySurveysCaption () {
      return `My Surveys (${this.mySurveys.length})`
    },
    draftSurveysCaption () {
      return `Draft Surveys (${this.draftSurveys.length})`
    },
    publishedSurveysCaption () {
      return `Published Surveys (${this.publishedSurveys.length})`
    },
    archivedSurverysCaption () {
      return `Archived Surveys (${this.archivedSurveys.length})`
    },
    // scheduledSurveysCaption () {
    //   return `Scheduled Surveys (${this.scheduledSurveys.length})`
    // },
    surveyFilterValues () {
      return {
        allSurveys: this.allSurveysCaption,
        editableSurveys: this.editableSurveysCaptions,
        mySurveys: this.mySurveysCaption,
        draftSurveys: this.draftSurveysCaption,
        publishedSurveys: this.publishedSurveysCaption,
        archivedSurveys: this.archivedSurverysCaption
        // this.scheduledSurveysCaption
      }
    },
    filteredSurveysByDropdown () {
      let surveys = []
      switch (this.getSurveyFilterKey(this.activeSurveyFilterOption)) {
        case 'editableSurveys':
          surveys = this.editableSurveys
          break
        case 'mySurveys':
          surveys = this.mySurveys
          break
        case 'draftSurveys':
          surveys = this.draftSurveys
          break
        case 'publishedSurveys':
          surveys = this.publishedSurveys
          break
        case 'archivedSurveys':
          surveys = this.archivedSurveys
          break
        // case this.scheduledSurveysCaption:
        //   surveys = this.scheduledSurveys
        //   break
        default:
          surveys = this.unarchivedSurveys
            .slice()
            .sort((a, b) => b.updatedAt - a.updatedAt)
      }
      return surveys
    },
    filteredSurveysBySearch () {
      return this.filteredSurveysByDropdown.filter((survey) => {
        if (survey.name === undefined || survey.name === null) return true
        return survey?.name
          .toLowerCase()
          .includes(this.surveyLookupValue.toLowerCase())
      })
    },
    activePageSurveys () {
      return this.filteredSurveysBySearch.slice(...this.activeIndices)
    },
    isCurrentUserInProject () {
      return this.project.users.some((user) => user.id === this.currentUser.id)
    },
    stateToWatch () {
      return this.editNameAndLocationModal
    },
    useLargeDefaultCoverPhoto () {
      return !this.projectInformation.coverImageUrl
        ? !this.$store.state.navOpen
        : null
    },
    isPlanUpgrade () {
      return true
      // let surveysCount = 0
      // this.users.forEach((user) => {
      //   surveysCount += user.surveys.length
      // })
      // return this.studiesCount > surveysCount
    }
  },
  created () {
    this.defaultFilter = this.allSurveysCaption
    this.activeSurveyFilterOption = this.allSurveysCaption
    this.surveyLookupValue = this.searchParam
  },
  methods: {
    advanceViaSurveyCard (survey) {
      survey.published
        ? this.advanceToUrl(`/study/${survey.id}/results`)
        : this.advanceToUrl(`/study/${survey.id}/edit`)
    },
    createStudyWithDefaultName () {
      if (this.isPlanUpgrade) {
        let url = '/study/'
        let data = {
          name: '',
          virtual_presentation: false,
          // project_id: this.project.id
        }
        this.$axios
          .request({
            url: url,
            method: 'post',
            data: data
          })
          .then((res) => {
            window.location.href = `/study/${res.data.id}/edit`
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.$refs.upgradePlan.toggle()
      }
    },
    checkCreateTemplate (survey) {
      this.selectedSurvey = survey
      this.currentUser.isSpacetricsAccount
        ? (this.confirmationModal = true)
        : this.createTemplate(survey)
    },
    copyToClipboard (surveyId) {
      this.$nextTick(() => {
        this.$refs['copyText' + surveyId][0].select()
        let supported = document.execCommand('copy')
        if (!supported) {
          this.tipMessage = 'Tap to copy is not supported'
        }
        this.showLinkCopiedMessage(surveyId)
      })
    },
    createTemplate (survey) {
      let url = `/study/${survey.id}/create_template`
      let data = {
        name: survey.name
      }
      this.$axios
        .request({
          url: url,
          method: 'post',
          data: data
        })
        .then((res) => {
          window.location.href = `/templates/${res.data.id}/edit`
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editProjectInfo () {
      this.$axios
        .request({
          method: 'patch',
          url: `/projects/${this.project.id}`,
          data: {
            ...this.projectInformation,
            name: this.projectInformation.name,
            location_name: this.autoCompleteValue
          }
        })
        .then((res) => {
          this.editNameAndLocationModal = false
          this.projectInformation.locationName = res.data.locationName
        })
        .catch((err) => {
          console.log(err)
          this.projectInformation.name = this.project.name
          this.projectInformation.locationName = this.project.locationName
        })
    },
    focusEditCoverPhoto () {
      if (this.isCurrentUserInProject) {
        this.coverPhotoHovered = true
        this.$nextTick(() => {
          this.$refs.editCoverPhotoButton.focus()
        })
      }
    },
    openMediaUploader (mediaType) {
      this.editCoverPhotoActive = true
      setTimeout(() => {
        this.showMediaUploader = true
        this.editCoverPhotoActive = false
      }, 400)
    },
    getSurveyFilterKey (payload) {
      return Object.keys(this.surveyFilterValues).find(
        (key) => this.surveyFilterValues[key] === payload
      )
    },
    removeMember (payload) {
      this.projectInformation.users.splice(
        this.projectInformation.users.indexOf(payload),
        1
      )
    },
    updateProjectMember (payload) {
      let member = this.projectInformation.users.filter((member) => {
        return member.email === payload.email
      })
      member[0].kind = payload.kind
    },
    setDropdownOption (payload) {
      this.activeSurveyFilterOption = payload
      this.updateUrlParams('filter', this.getSurveyFilterKey(payload))
    },
    showLinkCopiedMessage (surveyId) {
      let activeSurvey = this.projectInformation.surveys.find((survey) => {
        return survey.id === surveyId
      })
      activeSurvey.linkCopied = true
      setTimeout(() => {
        this.$nextTick(() => {
          activeSurvey.linkCopied = false
        })
      }, 1250)
    },
    updateIndicesOfSurveysShown (payload) {
      this.activeIndices = payload
    },
    uploadComplete (data) {
      this.showMediaUploader = false
      this.projectInformation.coverImageUrl = data.coverImageUrl
    },
    updateMember (payload) {
      let member = this.projectInformation.users.filter((users) => {
        return users.email === payload.email
      })
      member[0].kind = payload.kind
    },
    updateMembers (newMembers) {
      this.projectInformation.users = newMembers
    },
    openArchiveDialogue (surveyId, bool) {
      this.archiveModal = true
      this.archiveSurveyId = surveyId
      this.archiveBoolean = bool
    },
    archiveSurvey () {
      this.$axios
        .request({
          method: 'patch',
          url: `/study/${this.archiveSurveyId}/archive`,
          data: { archived: this.archiveBoolean }
        })
        .then((resp) => {
          this.archiveModal = false
          let surveyToUpdate = this.surveys.find(
            (survey) => survey.id === this.archiveSurveyId
          )
          surveyToUpdate.archived = this.archiveBoolean
          this.setDropdownOption(
            this.surveyFilterValues[this.urlParams().get('filter')]
          )
          this.archiveSurveyId = null
          this.archiveBoolean = null
        })
        .catch((err) => console.error(err))
    },
    tagCaption (survey) {
      let caption = survey.published ? 'Published' : 'Draft'
      if (survey.archived) {
        caption = 'Archived'
      }
      return caption
    },
    duplicateSurvey (surveyId) {
      if (this.isPlanUpgrade) {
        this.$axios
          .request({
            method: 'post',
            url: `/study/${surveyId}/duplicate`
          })
          .then((res) => {
            window.location.href = `/study/${res.data.id}/edit`
          })
          .catch((err) => console.error(err))
      } else {
        this.$refs.upgradePlan.toggle()
      }
    },
    isOverflowEmpty (survey) {
      if (survey.published) {
        return false
      }
      if (survey.canEdit) {
        return false
      }
      if (survey.questionCount > 0) {
        return false
      }
      if (this.project.canCreateSurvey > 0) {
        return false
      }
      return true
    }
  }
}
</script>
