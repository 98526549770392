<template>
  <div
    ref="container"
    class="st-tooltip__container"
    @click="showTip(!displayTooltip)"
    @mouseenter="showTip(true)"
    @mouseleave="showTip(false)"
  >
    <slot>
      <icon-wrapper icon-name="info" />
    </slot>
    <div
      ref="tooltip"
      :class="[{
        'st-tooltip__message': true,
        'st-flash-tooltip': flash
      }, messageClassObj()]"
      :style="styleObject"
    >
      <div
        v-if="maintainVisibility"
        tabindex="0"
        class="st-tooltip__close-icon-container"
        @click="maintainVisibility = false"
        @keyup.enter="maintainVisibility = false"
      >
        <icon-wrapper
          class="st-tooltip__close-icon"
          icon-name="plus"
          :invert="true"
        />
      </div>
      <slot name="tip">
        {{ tip }}
      </slot>
    </div>
    <div
      :class="[{
        'st-tooltip__arrow': true,
        'st-tooltip__arrow--flash st-flash-tooltip': flash
      }, arrowClassObj()]"
      :style="{
        visibility: visibility
      }"
    />
  </div>
</template>

<script>
import IconWrapper from './icon-wrapper'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  props: {
    alwaysVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    allowWrap: {
      type: Boolean,
      required: false,
      default: false
    },
    //* * Used to change styling of st-tooltip arrow */
    arrowClass: {
      type: String,
      required: false,
      default: ''
    },
    containerSizeMultiplier: {
      type: Number,
      required: false,
      default: 1
    },
    direction: {
      type: String,
      default: 'top',
      required: false,
      validator: function (value) {
        return ['top', 'bottom', 'left', 'right'].indexOf(value) !== -1
      }
    },
    //* * Used to temporarily show st-tooltip (setTimeout declared in parent) */
    flash: {
      type: Boolean,
      required: false,
      default: false
    },
    isActive: {
      type: Boolean,
      required: false,
      default: true
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 340
    },
    //* * Used to change styling of st-tooltip message container */
    messageClass: {
      type: String,
      required: false,
      default: ''
    },
    tip: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      displayTooltip: false,
      isMounted: false,
      maintainVisibility: this.alwaysVisible,
      tipMargin: 12
    }
  },
  computed: {
    containerHeight () {
      if (!this.isMounted) { return }
      return this.$refs.container.offsetHeight * this.containerSizeMultiplier
    },
    containerWidth () {
      if (!this.isMounted) { return }
      return this.$refs.container.offsetWidth * this.containerSizeMultiplier
    },
    tooltipHeight () {
      if (!this.isMounted) { return }
      return this.$refs.tooltip.clientHeight
    },
    tooltipWidth () {
      if (!this.isMounted) { return }
      return this.$refs.tooltip.clientWidth
    },
    visibility () {
      let visibility = 'hidden'
      if (this.displayTooltip || this.flash || this.maintainVisibility || this.alwaysVisible) {
        visibility = 'visible'
      }
      return visibility
    },
    top () {
      let top = 'unset'
      if (this.direction === 'left' || this.direction === 'right') {
        top = (this.containerHeight - this.tooltipHeight) / 2 + 'px'
      } else if (this.direction === 'bottom') {
        top = this.containerHeight + this.tipMargin + 'px'
      }
      return top
    },
    bottom () {
      let bottom = 'unset'
      if (this.direction === 'top') {
        bottom = this.containerHeight + this.tipMargin + 'px'
      }
      return bottom
    },
    right () {
      let right = 'unset'
      if (this.direction === 'left') {
        right = this.containerWidth + this.tipMargin + 'px'
      }
      return right
    },
    left () {
      let left = 'unset'
      if (this.direction === 'right') {
        left = this.containerWidth + this.tipMargin + 'px'
      } else if (this.direction === 'top' || this.direction === 'bottom') {
        left = (this.containerWidth - this.tooltipWidth) / 2 + 'px'
      }
      return left
    },
    styleObject () {
      let style = {
        visibility: this.visibility,
        top: this.top,
        bottom: this.bottom,
        right: this.right,
        left: this.left,
        maxWidth: '330px'
      }
      if (this.allowWrap) {
        style.padding = '16px'
      }
      return style
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    arrowClassObj () {
      let arrowClass = { [this.arrowClass]: true }
      arrowClass[`st-tooltip__arrow--${this.direction}`] = true
      if (this.flash) { arrowClass[`st-tooltip__arrow--${this.direction}--flash`] = true }
      return arrowClass
    },
    messageClassObj () {
      let messageClass = { [this.messageClass]: true }
      if (this.flash) { messageClass[`st-tooltip__message--${this.direction}-flash`] = true }
      return messageClass
    },
    showTip (bool) {
      if (this.isActive) {
        this.displayTooltip = bool
      }
    }
  }
}
</script>
