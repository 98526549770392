import { SPECS_TABS, SLIDES_TEMPLATES } from '../../utilities/enums'

function noActiveQuestion (state) {
  if (state.activeQuestionIdx === -1) { return true }

  return state.activeQuestionIdx >= state.questions.length
}

function replaceActiveQuestion (state, questionData) {
  let questions = [...state.questions]
  if (questions[state.activeQuestionIdx].id !== questionData.id) return
  questions[state.activeQuestionIdx] = questionData
  state.questions = questions
}

function updatePriorities (rootState, state, questionIds, surveyId) {
  return rootState.axios.request({
    url: `/study/${surveyId}/update_priorities`,
    method: 'patch',
    data: { question_ids: questionIds }
  })
    .then(res => {
      let newQuestions = [...state.questions]
      state.questions = res.data.ids.map((id, idx) => {
        const newQuestion = newQuestions.find(question => question.id === id)
        if (newQuestion) { newQuestion.priority = idx }
        return newQuestion
      })
    })
}

export default {
  namespaced: true,
  state: {
    activeTab: SPECS_TABS.CONTENT,
    dummyAudioPath: '',
    questions: [],
    activeQuestionIdx: -1,
    isBlocked: false,
    activeViewpointIdentifier: null,
    assignedViewpoints: [],
    moveVirtualTourPreviewFn: () => {},
    color: { code: '', elementRef: '', previewElementRef: '' },
    currentTemplate: Object.keys(SLIDES_TEMPLATES)[0],
    colors: [],
    texts: [],
    slideImages: [],
    objects: [],
    activeObject: null,
    extraTags: 'no'
  },
  getters: {
    questionCount: (state) => {
      return state.questions.length
    },
    extraTags: (state) => {
      return state.extraTags
    },
    question: (state) => (idx) => {
      return state.questions[idx]
    },
    questions: (state) => {
      return state.questions
    },
    activeQuestion: (state) => {
      if (noActiveQuestion(state)) {
        return {}
      }

      return state.questions[state.activeQuestionIdx]
    },
    activeViewpoint: (state) => {
      if (state.assignedViewpoints.length > 0) {
        return state.assignedViewpoints.find(viewpoint => viewpoint.identifier === state.activeViewpointIdentifier)
      } else {
        return ''
      }
    },
    color: (state) => {
      return state.color
    },
    slideColors: (state) => {
      return state.colors
    },
    currentTemplate: (state) => {
      return state.currentTemplate
    },
    texts: (state) => {
      return state.texts
    },
    slideImages: (state) => {
      return state.slideImages
    },
    objects: (state) => {
      return state.objects
    },
    activeObject: (state) => {
      return state.activeObject
    }
  },
  mutations: {
    changeTab: (state, newTab) => {
      // newTab should be a SPECS_TABS value
      state.activeTab = newTab
    },
    setExtraTags: (state, payload) => {
      state.extraTags = payload
    },
    setActiveQuestionIdx: (state, idx) => {
      if (state.isBlocked) { return }

      if (idx < state.questions.length) {
        state.activeQuestionIdx = idx
      }
    },
    setActiveViewpointIdentifier: (state, identifier) => {
      state.activeViewpointIdentifier = identifier
    },
    updateViewpoints: (state, viewpointsArray) => {
      state.assignedViewpoints = viewpointsArray
    },
    replaceActiveQuestion: (state, questionData) => {
      if (state.isBlocked) { return }

      replaceActiveQuestion(state, questionData)
    },
    appendQuestion: (state, questionData) => {
      state.questions = [...state.questions, questionData]
    },
    mergeActiveQuestion: (state, newQuestionData) => {
      // merge in new data without updating the backend
      if (state.isBlocked) { return }

      let currentQuestion = state.questions[state.activeQuestionIdx]
      replaceActiveQuestion(state, { ...currentQuestion, ...newQuestionData })
    },
    replaceQuestions: (state, questionsData) => {
      if (state.isBlocked) { return }

      state.questions = questionsData
    },
    blockUpdates: (state) => {
      state.isBlocked = true
    },
    unblockUpdates: (state) => {
      state.isBlocked = false
    },
    removeIdx: (state, idx) => {
      let newQuestions = [...state.questions]
      newQuestions.splice(idx, 1)
      state.questions = newQuestions
      if (idx >= state.activeQuestionIdx) {
        state.activeQuestionIdx--
      }
      if (state.activeQuestionIdx === -1) {
        state.activeTab = SPECS_TABS.CONTENT
      }
    },
    setMoveVirtualTour: (state, fn) => {
      state.moveVirtualTourPreviewFn = fn
    },
    moveVirtualTourPreview: (state, identifier) => {
      state.moveVirtualTourPreviewFn(identifier)
    },
    setDummyAudioPath: (state, newAudioPath) => {
      state.dummyAudioPath = newAudioPath
    },
    updatedSelectedTarget: (state, payload) => {
      state.selectedTarget = payload.target
      state.selectedTargetRef = payload.ref
    },
    updateNewColor: (state, payload) => {
      state.color = payload
      state.colors[payload.boxIndex] = payload.code
    },
    setSlideColors: (state, payload) => {
      state.colors = payload
    },
    updateNewTemplate: (state, templateKey) => {
      state.currentTemplate = templateKey
      state.selectedTarget = null
      state.color = { code: '', elementRef: '' }
    },
    addObject: (state, payload) => {
      state.objects.push(payload)
    },
    updateObject: (state, payload) => {
      const objects = [...state.objects]
      const updatedObjectIndex = objects.findIndex(ob => ob.id === payload.id)
      objects[updatedObjectIndex] = {
        ...state.objects[updatedObjectIndex],
        ...payload
      }
      state.objects = objects
    },
    setObjects: (state, payload) => {
      state.objects = payload
    },
    setActiveObject: (state, payload) => {
      state.activeObject = payload
    },
    removeObject: (state, id) => {
      const allObjects = [...state.objects]
      state.objects = allObjects.filter(ob => ob.id !== id)
    },
    resetSlideColors: (state) => {
      state.color = { code: '', elementRef: '', previewElementRef: '' }
      state.colors = []
    }
  },
  actions: {
    reindexQuestions: ({ rootState, commit, state, dispatch }, { newQuestions, surveyId }) => {
      // no block so we can set the active index (can't make it wait)
      let oldQuestions = state.questions
      commit('toggleInlineSpinner', true, { root: true })
      commit('replaceQuestions', newQuestions)
      let questionIds = newQuestions.map(question => { return question.id })
      updatePriorities(rootState, state, questionIds, surveyId)
        .then(() => {
          setTimeout(() => {
            commit('toggleInlineSpinner', false, { root: true })
          }, 2450)
        })
        .catch((err) => {
          console.log('err', err)
          commit('replaceQuestions', oldQuestions)
          commit('toggleInlineSpinner', false, { root: true })
        })
    },
    updateActiveQuestion: ({ commit, rootState, getters, dispatch }, questionUpdateData) => {
      // send the object as an update to the active question
      // on success update the active question in the array
      // TODO: should we have an action for updating a "dirty" activeQuestion?
      commit('toggleInlineSpinner', true, { root: true })
      commit('blockUpdates')

      rootState.axios.request({
        url: `/questions/${getters.activeQuestion.id}`,
        method: 'patch',
        data: questionUpdateData
      })
        .then(res => {
          commit('unblockUpdates')
          commit('replaceActiveQuestion', res.data)
          setTimeout(() => {
            commit('toggleInlineSpinner', false, { root: true })
          }, 2450)
        })
        .catch(err => {
          commit('unblockUpdates')
          commit('toggleInlineSpinner', false, { root: true })
          console.log('err', err)
        })
    },
    deleteQuestionIdx: ({ commit, rootState, state, dispatch }, { questionIdx, surveyId }) => {
      console.log('question index---------', questionIdx)
      commit('toggleInlineSpinner', true, { root: true })
      commit('blockUpdates')

      return new Promise((resolve, reject) => {
        let id = state.questions[questionIdx].id
        rootState.axios.request({
          url: `/questions/${id}`,
          method: 'delete'
        })
          .then(res => {
            commit('unblockUpdates')
            commit('removeIdx', questionIdx)
            setTimeout(() => {
              commit('toggleInlineSpinner', false, { root: true })
            }, 2450)
            dispatch('reindexQuestions', { newQuestions: state.questions, surveyId: surveyId })
            resolve()
          })
          .catch(err => {
            commit('unblockUpdates')
            commit('toggleInlineSpinner', false, { root: true })
            console.log('err', err)
            reject(err)
          })
      })
    },
    updateAssignedViewpoints: ({ commit }, viewpointsArray) => {
      commit('updateViewpoints', viewpointsArray)
      commit('participants/updatePanos', viewpointsArray, { root: true })
    },
    setColor: ({ commit }, payload) => {
      commit('updateNewColor', payload)
    },
    setCurrentTemplate: ({ commit }, templateKey) => {
      commit('updateNewTemplate', templateKey)
    }
  }
}
