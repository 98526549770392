<template>
  <div
    :class="{
      'st-upload-preview tw-flex tw-items-center tw-space-x-3': true,
      'st-upload-preview--hide-bar': hideBar,
      'st-upload-preview--large-preview': displayLargePreview && !hideBar,
      'st-upload-preview--large-modal-preview': isLargeModalPreview
    }"
  >
    <div
      v-if="hideBar"
      class="st-upload-preview__media-and-filename"
    >
      <video
        v-if="isVideo"
        class="st-upload-preview__media st-upload-preview__media--hide-bar"
      >
        >
        <source
          :src="src"
          type="video/mp4"
        > Your browser does not support the video tag.
      </video>
      <div
        v-else
        :class="{
          'st-upload-preview__media--hide-bar': true,
          'st-upload-preview__media--hide-bar st-upload-preview__media--hide-bar--large': displayLargePreview,
        }"
        :style="{
          background: `url('${previewUrl}') center / cover no-repeat`
        }"
      />
      <!-- <input
        v-if="input"
        v-model="name"
        type="text"
        :placeholder="'Image Name ( Optional)'"
        class="custom-input"
        @blur="handleNameChange"
      >
      <div
        v-else
        class="st-upload-preview__filename st-upload-preview__filename--hide-bar"
      >
        {{ displayFilename }}
      </div> -->
    </div>
    <div
      v-if="!hideBar"
      :class="{
        'st-upload-preview__media tw-w-[150px] tw-h-[150px] tw-rounded-lg': true,
        'st-upload-preview__media--large-modal-preview': isLargeModalPreview
      }"
    >
      <video
        v-if="isVideo"
        class="st-upload-preview__media-contents st-upload-preview__media-contents--video"
      >
        >
        <source
          :src="src || previewUrl"
          type="video/mp4"
        > Your browser does not support the video tag.
      </video>
      <div
        v-else
        class="st-upload-preview__media-contents tw-rounded-lg"
        :style="{
          background: `url('${previewUrl}') center / cover no-repeat`
        }"
      />
    </div>
    <!-- <div
      v-if="!hideBar"
      :class="{
        'st-upload-preview__filename': true,
        'st-upload-preview__filename--large-modal-preview': isLargeModalPreview
      }"
    >
      {{ displayFilename }}
    </div> -->
    <div
      v-if="shouldShowPercent"
      :class="{
        'st-upload-preview__bar-container': true,
        'st-upload-preview__bar-container--large-modal-preview': isLargeModalPreview
      }"
    >
      <div
        class="st-upload-preview__bar"
        :style="{ width: uploadPercent + '%' }"
      />
      <div class="st-upload-preview__percentage">
        {{ uploadPercent }}%
      </div>
    </div>
    <div
      :class="{
        'st-upload-preview__delete': true,
        'st-upload-preview__delete--disabled': disabled,
        'st-upload-preview__delete--hide-bar': hideBar,
        'st-upload-preview__delete--large-modal-preview': isLargeModalPreview
      }"
      tabindex="0"
      @click="handleRemove"
      @keyup.enter="handleRemove"
    >
      <icon-wrapper
        :class="{
          'st-upload-preview__delete-icon': true,
          'st-upload-preview__delete-icon--disabled': disabled,
        }"
        icon-name="trash"
      />
    </div>
  </div>
</template>

<script>
import IconWrapper from './icon-wrapper'
import { getPreviewUrl } from '../../utilities/preview_file'
export default {
  components: { IconWrapper },
  props: {
    // if there should be an input field or not
    input: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    optionId: {
      type: Number,
      required: false,
      default: null
    },
    /** a file we want a preview for */
    file: {
      type: Blob,
      required: false,
      default: null
    },
    /** Blocks the upload preview from usage. */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Determine if the preview of the image is larger (usually for cover photos) */
    displayLargePreview: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Change the appearance of st-upload-preview to not have an upload bar */
    hideBar: {
      type: Boolean,
      required: false,
      default: false
    },
    /** the url (if we already have it) for the image */
    src: {
      type: String,
      required: false,
      default: ''
    },
    /** filename */
    filename: {
      type: String,
      required: false,
      default: ''
    },
    /** current uploaded percent to show in bar. values handled by parent */
    uploadPercent: {
      type: Number,
      required: false,
      default: null
    },
    /** Can be used to change the kind of uploadPreview (ex: modal) */
    variant: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    if (this.file && !this.src) {
      this.initializeReader()
    }
    return {
      readerUrl: '',
      name: this.value
    }
  },
  computed: {
    previewUrl () {
      return this.src || this.readerUrl
    },
    isLargeModalPreview () {
      return this.displayLargePreview && this.variant.includes('modal')
    },
    isPreviewUrlData () {
      return this.previewUrl.slice(0, 5) === 'data:'
    },
    isVideo () {
      return this.isPreviewUrlData ? this.previewUrl.split('/')[0].includes('video') : this.previewUrl.split('.').pop().includes('mp4')
    },
    previewUrlImageType () {
      return this.previewUrl.split('/')[0]
    },
    shouldShowPercent () {
      return this.uploadPercent || this.uploadPercent === 0
    },
    displayFilename () {
      if (this.filename) {
        return this.filename
      } else if (this.file) {
        return this.file.name
      } else {
        return ''
      }
    }
  },
  methods: {
    handleRemove () {
      if (this.disabled) { return }
      this.$emit('remove')
    },
    handleNameChange (e) {
      this.$emit('nameChange', { 'name': this.name, 'id': this.optionId })
    },
    makeFilePreview () {
      if (this.file.type.startsWith('image/')) {
        this.initializeReader()
      }
    },
    initializeReader () {
      getPreviewUrl(this.file)
        .then(previewUrl => {
          this.readerUrl = previewUrl
        })
    }
  }
}
</script>
<style>
.custom-input{
border:0px solid white; width:90%; height:100%; padding:5px;
}
.custom-input:focus{
  border:none;
  outline: none;

  border: 0px solid white;
}
</style>
