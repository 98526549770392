export default {
  namespaced: true,

  state: {
    creditBalance: 0,
  },
  getters: {
    creditBalance: (state) => state.creditBalance,
  },
  mutations: {
    setCreditBalance: (state, payload) => {
      state.creditBalance = payload;
    },
  },
  actions: {
    fetchCreditBalance: ({ rootState, commit }, accountId) => {
      rootState.axios
        .request({
          url: `/accounts/${accountId}/total_credit_balance`,
          method: "get",
        })
        .then((res) => {
          commit("setCreditBalance", Math.abs(res.data.credit_balance));
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
