<template>
      <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="21" cy="21.5" r="21" fill="#69C2DE"/>
<path d="M29.5 20.634C30.1667 21.0189 30.1667 21.9811 29.5 22.366L17.5 29.2942C16.8333 29.6791 16 29.198 16 28.4282L16 14.5718C16 13.802 16.8333 13.3209 17.5 13.7058L29.5 20.634Z" fill="white"/>
</svg>

</template>

<script>
export default {}
</script>
