<template>
  <div>
    <div
      id="slider"
      ref="slider"
      :class="sliderClass"

      :style="{'color': '#667E6E'}"
    >
      <!--
        the overlay is the dark color stripe that will follow
        the button
      -->
      <div
        id="overlay"
        ref="overlay"
        :style="[overlayStyle, {'background-color': overlayColor}]"
      >
        <span
          v-if="showOverlayText"
          class="overlay-text"
        >You’re almost there!</span>
      </div>
      <div
        ref="slideButton"
        :style="[{'background-color': 'none'}, slideButtonStyle]"
        class="slide-button"
        @mousedown="startSwipe"
        @mousemove="continueSwipe"
        @mouseup="endSwipe"
        @touchstart="startSwipe"
        @touchmove="continueSwipe"
        @touchend="endSwipe"
      >
        <div class="button button-text">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1991_39700)">
              <path
                d="M15.4698 6.52331L9.71025 0.481682C9.24713 0.047749 8.60386 -0.106461 8.00692 0.0736084C7.40997 0.253678 6.94294 0.742798 6.77062 1.36847C6.598 1.99414 6.74415 2.669 7.15736 3.15529L9.88046 6.01621H1.80528C1.16051 6.01621 0.564168 6.37698 0.241786 6.96292C-0.0805955 7.54854 -0.0805955 8.27039 0.241786 8.85601C0.564168 9.44163 1.16021 9.80272 1.80528 9.80272H9.90753L7.15736 12.6917C6.78686 13.0392 6.56883 13.5312 6.555 14.0522C6.54116 14.5731 6.73273 15.0771 7.08428 15.4454C7.43613 15.8138 7.917 16.014 8.4138 15.9989C8.91061 15.9837 9.37975 15.7545 9.71025 15.3653L15.4698 9.324C15.809 8.96923 15.9994 8.48767 15.9988 7.98531V7.9235C16.0159 7.39969 15.8244 6.89228 15.4698 6.52299V6.52331Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1991_39700">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div :class="showOverlayText?'slide-text-two': 'slide-text'">
        <span>
          {{ instructionText }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SwipeButton',
  props: {
    initialInstructionText: {
      type: String,
      default: 'When ready, swipe to redeem'
    },
    completedText: {
      type: String,
      default: 'Confirmed'
    },
    buttonText: {
      type: String,
      default: '>>>'
    },
    sliderBackgroundColor: {
      type: String,
      default: '#FFFFFF'
    },
    overlayColor: {
      type: String,
      default: '#667E6E'
    },
    buttonColor: {
      type: String,
      default: '#D88373'
    },
    textColor: {
      type: String,
      default: '#F5E2C8'
    }
  },
  data () {
    return {
      initialMouseX: 0,
      currentMouseX: 0,
      startDrag: false,
      showOverlayText: false,
      endPoint: 0,
      initialSliderWidth: 0,
      initialSlideButtonPosition: 0,
      instructionText: this.initialInstructionText,
      overlayStyle: {
        width: '0px'
      },
      slideButtonStyle: {
        left: '0px'
      },
      sliderClass: '',
      completed: false
    }
  },
  mounted () {
    document.addEventListener('mousemove', this.continueSwipe)
    document.addEventListener('mouseup', this.endSwipe)
    this.initEndPoint()
  },
  destroyed () {
    document.removeEventListener('mousemove', this.continueSwipe)
    document.removeEventListener('mouseup', this.endSwipe)
  },
  methods: {
    startSwipe (event) {
      if (this.completed) {
        return
      }
      // this will be used to calculate the offset to increase the width
      // of the slider
      this.initialMouseX = this.getMouseXPosFromEvent(event)
      // once our slider's x button position >= slider - button's width,
      // the action is confirmed
      this.endPoint = this.getEndingPoint()
      this.calculateSliderInitialWidth()
      this.calculateSlideButtonInitialPosition()
      this.updateSlideButton(0)
      this.updateSlider(0)
      this.startDrag = true
      // for transition animation
      this.sliderClass = 'started'
    },
    getEndingPoint () {
      const clientRects = this.$refs.slider.getClientRects()[0]
      return clientRects.right
    },
    calculateSliderInitialWidth () {
      const sliderLeftPos = this.$refs.slider.getClientRects()[0].x
      this.initialSliderWidth = this.initialMouseX - sliderLeftPos
      if (this.initialSliderWidth < 0) {
        this.initialSliderWidth = 0
      }
    },
    calculateSlideButtonInitialPosition () {
      this.initialSlideButtonPosition = this.$refs.slider.getClientRects()[0].x
    },
    continueSwipe (event) {
      if (!this.startDrag) {
        return
      }
      this.instructionText = 'Swipe here'
      this.showOverlayText = true
      this.currentMouseX = this.getMouseXPosFromEvent(event)
      const delta = this.currentMouseX - this.initialMouseX
      this.updateSlider(delta)
      this.updateSlideButton(delta)
      if (this.sliderReachedEndPoint()) {
        this.endSwipe()
      }
    },
    endSwipe () {
      this.startDrag = false
      if (this.sliderReachedEndPoint()) {
        this.sliderClass = 'completed'
        this.overlayStyle.width = `${this.$refs.slider.getClientRects()[0].width}px`
        this.actionConfirmed()
      } else {
        this.sliderClass = ''
        this.overlayStyle.width = '0px'
        this.slideButtonStyle.left = '0px'
        this.instructionText = this.initialInstructionText
        this.showOverlayText = false
      }
    },
    getMouseXPosFromEvent (event) {
      return event.clientX || event.touches[0].pageX
    },
    updateSlider (delta) {
      const sliderWidth = this.getSliderWidth()
      let newWidth = this.initialSliderWidth + delta
      // prevent overflow
      if (newWidth > sliderWidth) {
        newWidth = sliderWidth
      }
      this.overlayStyle.width = `${newWidth}px`
    },
    getSliderWidth () {
      return this.$refs.slider.getClientRects()[0].width
    },
    updateSlideButton (delta) {
      if (delta < 0) {
        return
      }
      this.slideButtonStyle.left = `${delta}px`
      // prevent overflow
      if (this.sliderReachedEndPoint()) {
        const buttonLeftPos = this.getSliderWidth() - this.getButtonWidth()
        this.slideButtonStyle.left = `${buttonLeftPos}px`
      }
    },
    getButtonWidth () {
      const slideButtonRect = this.$refs.slideButton.getClientRects()[0]
      return slideButtonRect.width
    },
    sliderReachedEndPoint () {
      const slideButtonRect = this.$refs.slideButton.getClientRects()[0]
      return slideButtonRect.right >= this.endPoint
    },
    actionConfirmed () {
      // ensure the event is only fire once
      if (!this.completed) {
        this.completed = true
        this.instructionText = this.completedText
        this.$emit('actionConfirmed')
      }
      this.instructionText = '.'
    },
    reset () {
      this.completed = false
      this.instructionText = this.initialInstructionText
      this.sliderClass = ''
      this.overlayStyle.transition = '3s'
      // this.updateSlider(0)
      this.updateSlideButton(0)
      this.slideButtonStyle.left = '0px'
    },
    initEndPoint () {
      this.endPoint = this.getEndingPoint()
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin nonselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
#slider {
  // background-color: red;
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  @include flex-center;
  @include nonselect;
  font-family: "Avenir Next";
}
#overlay {
  /* this defines the return animation when user touchout */
  transition: 0.5s ease;
  position: absolute;
  left: 0px;
  width: 50px;
  height: 50px;
  border-radius: 100px 35px 35px 100px;
  // border-radius: 100px;
  // margin: 0px 4px;
  @include flex-center;
  @include nonselect;
}
.slide-text {
  font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height */
width: 90%;
padding: 0 12px;
text-align: end;
}
.slide-button{
  // padding-top: 8px;
}
.started {
  #overlay, .slide-button {
    transition: none;
  }
}
.slide-text{
  // background-color: blue;
  font-size: 14px;
  // text-align: end;
  display: flex;
  width: 100%;
  justify-content: center;
   padding-left: 20px;
}
.slide-text-two{
  // background-color: blue;
  font-size: 14px;
  // text-align: end;
  display: flex;
  justify-content: end;
  width: 100%;
  padding-right: 20px;
  text-align: end;
  // padding-right: 50px;
}
.overlay-text{
  min-width:200px;
  color:#ECECEC;
  position:absolute;
  left: 20px;
}
.button{
  width: 50px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;

/* States/Positive */

background: #667E6E;
// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 40px;
}
.slide-button {
  transition: 0.5s ease;
  height: 50px;
  position: absolute;
  padding-left: 5px;
  padding-right: 5px;
  @include flex-center;
  .carrot {
    width: 25px;
    height: 25px;
    margin-top: 2px;
    margin-left: 5px;
  }
}
</style>
