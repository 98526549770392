<script>
import { mapState } from 'vuex'
import { getBlobDuration } from '../utilities/audio_utilities'
function initializeMediaRecorder (stream, vueInstance) {
  let mediaRecorder = new MediaRecorder(stream)
  vueInstance.stream = stream
  vueInstance.mediaRecorder = mediaRecorder
  addMediaRecorderListeners(mediaRecorder, vueInstance)
}

function addMediaRecorderListeners (mediaRecorder, vueInstance) {
  // called when chunk is complete or when stopped (before onstop)
  mediaRecorder.addEventListener('dataavailable', ev => {
    vueInstance.mediaChunks.push(ev.data)
  })
  mediaRecorder.addEventListener('stop', ev => {
    // combine multiple blob chunks into one blob
    let blob = new Blob(vueInstance.mediaChunks, {
      type: 'audio/mp3'
    })
    console.log(blob)
    vueInstance.isRecording = false
    vueInstance.onRecordingAvailable(blob)
  })
}

// epxects canvasWidth, canvasHeight, and stream to be available
// also expect audioCanvas to be defined as a ref
// TODO: I think this could be improved by creating an object with all relevant properties, and only saving that to vue
//    then all the methods just reference properties of itself
function visualize (vueInstance) {
  const drawColor = '#5a6872' // gray1
  const backgroundColor = 'white'
  const lineWidth = 4

  const canvas = vueInstance.$refs.audioCanvas
  const canvasCtx = canvas ? canvas.getContext('2d') : null
  window.canvasCtx = canvasCtx
  const canvasHeight = vueInstance.canvasHeight
  const canvasWidth = vueInstance.canvasWidth
  canvasCtx.translate(0.5, 0.5) // makes lines drawn crisper - has to do with weird definitions for line pixels

  /* eslint-disable no-undef */
  let audioCtx = new (window.AudioContext || webkitAudioContext)()
  let analyser = audioCtx.createAnalyser()
  analyser.fftSize = 1024
  let timeSliceSize = analyser.frequencyBinCount
  let timeSlice = new Uint8Array(timeSliceSize)
  let sliceWidth = (canvasWidth * 1.0) / timeSliceSize

  let source = vueInstance.isSpecificationsPreview ? audioCtx.createMediaElementSource(vueInstance.$_audioEl) : audioCtx.createMediaStreamSource(vueInstance.stream)
  source.connect(analyser)
  if (vueInstance.isSpecificationsPreview) { vueInstance.$_audioEl.play() }

  draw()

  function draw () {
    requestAnimationFrame(draw) // trigger next draw on next screen refresh
    wipeCanvas()
    drawAudioFrame()
  }

  function wipeCanvas () {
    canvasCtx.fillStyle = backgroundColor
    canvasCtx.fillRect(0, 0, canvasWidth, canvasHeight)
  }

  function drawAudioFrame () {
    analyser.getByteTimeDomainData(timeSlice)

    canvasCtx.beginPath()
    canvasCtx.strokeStyle = drawColor
    canvasCtx.lineWidth = lineWidth
    drawAudioFrameSlices()
    canvasCtx.stroke()
  }

  function drawAudioFrameSlices () {
    for (var i = 0, x = 0; i < timeSliceSize; i++) {
      let val = timeSlice[i] / 256.0 // lock to [0,1]
      let y = val * canvasHeight // val => [0, height]

      if (i === 0) {
        canvasCtx.moveTo(x, y)
      } else {
        canvasCtx.lineTo(x, y)
      }
      x += sliceWidth
    }
  }
}

export default {
  props: {
    autoStopTime: {
      type: Number,
      default: 1800000, // ms = 30 min
      required: false
    }
  },
  data () {
    return {
      canvasWidth: 1024,
      canvasHeight: 256,
      isRecording: false,
      mediaRecorder: null,
      mediaChunks: [],
      stream: null
    }
  },
  computed: {
    ...mapState('specifications', ['dummyAudioPath']),
    isVisualizerVisible () {
      return this.isRecording
    },
    isSpecificationsPreview () {
      // should be overridden by the question component
      return false
    }
  },
  methods: {
    // returns a promise because we have to wait for the user to respond
    tryInitializingMediaRecorder (incrementPage = false) {
      if (this.isSpecificationsPreview) {
        return this.tryInitializingPreviewMediaRecorder(incrementPage)
      } else {
        return this.tryInitializingVoiceMediaRecorder(incrementPage)
      }
    },
    tryInitializingPreviewMediaRecorder (incrementPage) {
      return new Promise((resolve, reject) => {
        this.mediaRecorder = {
          // dummy that can be called
          start () {},
          stop () { this.isRecording = false },
          pause () { this.isRecording = false }
        }
        const audioEl = new Audio()
        audioEl.src = this.dummyAudioPath
        audioEl.muted = false
        audioEl.loop = true
        audioEl.addEventListener('canplaythrough', () => {
          if (incrementPage) { this.$store.commit('participants/incrementInstructionsPage') }
          // this.mediaRecorder = new MediaRecorder(stream)
          resolve()
        })
        audioEl.addEventListener('error', (e) => {
          reject(e)
        })
        this.$_audioEl = audioEl
      })
    },
    tryInitializingVoiceMediaRecorder (incrementPage = false) {
      return new Promise((resolve, reject) => {
        if (navigator.mediaDevices) {
          let constraints = { audio: true }
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
              initializeMediaRecorder(stream, this)
              if (incrementPage) { this.$store.commit('participants/incrementInstructionsPage') }
              resolve()
            })
            .catch(err => {
              this.errorMessage =
                location.protocol !== 'https:'
                  ? 'Audio capture was disabled - you may need to access this page through https if the option was not given'
                  : 'Microphone permission required, Please enable permissions by clicking on the top-left icon in the address bar or by going to settings'
              console.log('err', err)
              reject(err)
            })
        } else {
          console.log('no navigator')
          this.errorMessage = 'Audio capture is not possible on this browser'
          reject(new Error('no navigator'))
        }
      })
    },
    initiateRecording (defaultVisualizer = true) {
      // console.log("starting recording...")
      this.isRecording = true
      this.mediaRecorder.start()
      this.mediaChunks = []
      if (defaultVisualizer) {
        this.$nextTick(() => visualize(this))
      }
      setTimeout(() => {
        this.stopRecording()
      }, this.autoStopTime)
    },

    pauseRecording () {
      console.log('pausing')
      this.isRecording = false
      this.mediaRecorder.pause()
    },

    resumeRecording () {
      this.isRecording = true
      this.mediaRecorder.resume()
    },

    stopRecording () {
      if (this.isSpecificationsPreview) {
        this.$_audioEl.pause()
        this.isRecording = false
        this.onRecordingAvailable(blob)
        this.setAudioSrc(this.dummyAudioPath)
      } else {
        if (this.mediaRecorder) {
          this.mediaRecorder.stop()
        }
      }
    },
    initializeNewMediaRecorder () {
      let constraints = { audio: true }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.stream = stream
          this.mediaRecorder = new MediaRecorder(stream)
          addMediaRecorderListeners(this.mediaRecorder, this)
        })
    },
    onRecordingAvailable (blob) {
      console.log(blob)
      this.audioBlob = blob
      this.readyToSubmit = true
      getBlobDuration(this.audioBlob).then((duration) => {
        this.audioDuration = duration
      })

      this.$nextTick(() => {
        this.setAudioSrc()
      })
    },
    setAudioSrc (audioUrl = '') {
      setTimeout(() => {
        audioUrl = window.URL.createObjectURL(this.audioBlob)
        this.localAudioURL = audioUrl
        console.log(this.$refs.newlySubmittedResponseAudio)
        this.$refs.newlySubmittedResponseAudio.src = audioUrl
        this.$refs.newlySubmittedResponseAudio.load()
      }, 2000)
    },
    playAgain () {
      this.$refs.audioPlayer.play()
      this.$refs.audioPlayer.ontimeupdate = () => {
        this.audioDuration = this.$refs.audioPlayer.currentTime
        if (
          this.$refs.audioPlayer.duration === this.$refs.audioPlayer.currentTime
        ) {
          this.replayAudio = false
        }
      }
      this.replayAudio = true
    },
    countingTimeSpent () {
      this.initiateRecording()
      this.intervalId = setInterval(() => {
        this.seconds++
        if (this.seconds > 59) {
          this.seconds = 0
          this.mins++
        }
      }, 1000)
    },
    startRecording () {
      this.readyToSubmit = false
      this.initializeRecorder()
        .then((res) => {
          this.countingTimeSpent()
        })
        .catch((error) => alert(JSON.stringify(error, null, 4)))
    },
    initializeRecorder () {
      return this.tryInitializingMediaRecorder()
    }
  }
}
</script>
