<template>
  <!-- <div
    class="icon_spacetrics"
  >
    <div class="icon_spacetrics__svg">
      <svg
        ref="svg"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4106.219 5578.334a.937.937 0 11-1.326-1.325c1.637-1.639 1.325-1.295 1.63-1.87a3.326 3.326 0 00-4.449-4.511c-.664.34-.331.1-7.628 7.4a3.322 3.322 0 000 4.7 5.19 5.19 0 010 7.344c-7.284 7.283-7.116 7.236-8.083 7.736a5.2 5.2 0 01-7.58-4.618c0-2.4 1.4-3.547 2.548-4.7a.937.937 0 011.326 1.325c-1.063 1.063-2 1.763-2 3.373a3.324 3.324 0 004.844 2.952c.654-.338.317-.1 7.617-7.4a3.317 3.317 0 000-4.693 5.2 5.2 0 010-7.347c7.276-7.274 7.119-7.241 8.1-7.744a5.2 5.2 0 016.042 8.3z"
          transform="translate(-4078.784 -5568.389)"
        />
        <path
          d="M27 13A14 14 0 1113-1a14 14 0 0114 14zm-1.959 0A12.041 12.041 0 1013 25.041 12.041 12.041 0 0025.041 13z"
          transform="translate(1.999 2)"
        />
      </svg>
    </div>
  </div> -->
</template>

<script>
export default {}
</script>
