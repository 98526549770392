<template>
  <div>
    <div
      class="tw-h-[172px] tw-relative tw-flex"
      style="background: linear-gradient(158deg, #A0783B 0%, #B8AB7F 100%);"
    >
      <div class="bx--grid">
        <div class="tw-w-full tw-h-full bx--col-sm-12 bx--col-xs-12">
          <div
            class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-h-full"
          >
            <div class="tw-flex tw-space-x-3 tw-items-center">
              <profile-image :user="currentUser" />
              <!-- <div>
              <img
                src="https://placekitten.com/100"
                class="tw-rounded-full tw-w-[60px] tw-h-[60px]"
                alt=""
              />
            </div> -->
              <div>
                <p class="tw-text-2xl tw-text-white tw-font-semibold">
                  Hi {{ currentUser.firstName }} 👋
                </p>
                <p class="tw-text-white">
                  Welcome to the admin dashboard
                </p>
              </div>
            </div>
            <!-- <div class="tw-flex tw-space-x-3 tw-items-center tw-cursor-pointer">
              <span class="tw-p-3 tw-bg-gray-50/20 tw-rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
              </span>

              <p class="tw-text-[30px] tw-text-white tw-font-semibold">
                Dashboard
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bx--grid">
      <div class="bx--col-sm-12 bx--col-xs-12 ">
        <!-- <div class="tw-flex tw-space-x-[30px] tw-transform -tw-translate-y-8">
          <div class="card-bg tw-border tw-border-gray-200 tw-border-solid tw-px-4 tw-rounded-[20px] tw-h-[122px] tw-flex tw-flex-col tw-justify-center tw-w-1/4">
            <p class="tw-text-lg tw-font-semibold">
              40
            </p>
            <p class="tw-text-[13px]">
              Active accounts
            </p>
          </div>
          <div class="card-bg tw-border tw-border-gray-200 tw-border-solid tw-px-4 tw-rounded-[20px] tw-h-[122px] tw-flex tw-flex-col tw-justify-center tw-w-1/4">
            <p class="tw-text-lg tw-font-semibold">
              40
            </p>
            <p class="tw-text-[13px]">
              New accounts in last 30 days
            </p>
          </div>
          <div class="card-bg tw-border tw-border-gray-200 tw-border-solid tw-px-4 tw-rounded-[20px] tw-h-[122px] tw-flex tw-flex-col tw-justify-center tw-w-1/4">
            <p class="tw-text-lg tw-font-semibold">
              402
            </p>
            <p class="tw-text-[13px]">
              MRR
            </p>
          </div>
          <div class="card-bg tw-border tw-border-gray-200 tw-border-solid tw-px-4 tw-rounded-[20px] tw-h-[122px] tw-flex tw-flex-col tw-justify-center tw-w-1/4">
            <p class="tw-text-lg tw-font-semibold">
              $440
            </p>
            <p class="tw-text-[13px]">
              Average account value
            </p>
          </div>
        </div> -->
        <div class="tw-flex tw-justify-between tw-mt-5">
          <div class="tw-flex tw-space-x-3 tw-items-center">
            <!-- <p class="tw-text-sm tw-text-gray-400">
              Account
            </p> -->
            <div>
              <div class="tw-relative tw-inline-flex">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="tw-w-3 tw-h-3 tw-absolute tw-top-0 tw-right-0 tw-m-4 tw-pointer-events-none"
                >
                  <path
                    d="M7.1064 12.5365L1.41087 6.02734C0.739032 5.25952 1.28431 4.05786 2.30456 4.05786H13.6956C14.7159 4.05786 15.2611 5.25952 14.5893 6.02733L8.89377 12.5365C8.42065 13.0772 7.57951 13.0772 7.1064 12.5365Z"
                    fill="#474747"
                  />
                </svg>

                <select
                  class="tw-border tw-border-transparent hover:tw-border-solid hover:tw-border-gray-700 tw-bg-[#F6F6F6] tw-rounded-full tw-text-gray-600 tw-h-10 tw-pl-5 tw-pr-10 tw-cursor-pointer tw-focus:outline-none tw-appearance-none"
                >
                  <option class="tw-rounded-full">
                    All accounts
                  </option>
                  <option class="tw-rounded-full">
                    Active
                  </option>
                  <option class="tw-rounded-full">
                    Inactive
                  </option>
                </select>
              </div>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <g clip-path="url(#clip0_155_3660)">
                  <path
                    d="M8 0.5C3.58857 0.5 0 4.08933 0 8.5C0 12.9107 3.58857 16.5 8 16.5C12.4114 16.5 16 12.9107 16 8.5C16 4.08933 12.4114 0.5 8 0.5ZM8 15.6112C4.07924 15.6112 0.888762 12.4211 0.888762 8.5C0.888762 4.57886 4.07886 1.38876 8 1.38876C11.9211 1.38876 15.1112 4.57886 15.1112 8.5C15.1112 12.4211 11.9208 15.6112 8 15.6112Z"
                    fill="#474747"
                  />
                  <path
                    d="M10.2223 8.05535H8.44435V6.27744C8.44435 6.03211 8.2455 5.83325 8.00016 5.83325C7.75483 5.83325 7.55559 6.03249 7.55559 6.27782V8.05573H5.77769C5.53235 8.05573 5.3335 8.25459 5.3335 8.49992C5.3335 8.74525 5.53273 8.94449 5.77807 8.94449H7.55597V10.7224C7.55597 10.9677 7.75521 11.167 8.00054 11.167C8.24588 11.167 8.44511 10.9677 8.44511 10.7224V8.94449H10.223C10.4684 8.94449 10.6676 8.74525 10.6676 8.49992C10.6676 8.25459 10.4684 8.05535 10.2226 8.05535H10.2223Z"
                    fill="#474747"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_155_3660">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-justify-center">
            <span>
              Amazon funds: {{ funds }}
            </span>
          </div>
          <div class="tw-flex tw-space-x-6 tw-relative">
            <!-- <div class="tw-relative">
              <button
                class="tw-rounded-lg tw-space-x-2 tw-px-3 tw-py-3 tw-items-center tw-flex tw-bg-primary tw-text-white"
                @click="toggleCreatePopup"
                @blur="showCreatePopup = false"
              >
                <span>
                  Create
                </span>
                <svg
                  class="tw-w-3 tw-h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.64645 5.14645C1.84171 4.95118 2.15829 4.95118 2.35355 5.14645L8 10.7929L13.6464 5.14645C13.8417 4.95118 14.1583 4.95118 14.3536 5.14645C14.5488 5.34171 14.5488 5.65829 14.3536 5.85355L8.35355 11.8536C8.15829 12.0488 7.84171 12.0488 7.64645 11.8536L1.64645 5.85355C1.45118 5.65829 1.45118 5.34171 1.64645 5.14645Z"
                    fill="white"
                  />
                </svg>
              </button>
              <div
                v-if="showCreatePopup"
                class="tw-absolute tw-top-10 tw-bg-white tw-w-full tw-shadow-md tw-text-sm tw-border tw-border-gray-100 tw-border-solid tw-right-0 tw-z-10"
              >
                <span
                  class="tw-px-2 tw-cursor-pointer hover:tw-bg-primary-light tw-py-2 tw-block "
                >Account</span>
                <span
                  class="tw-px-2 tw-cursor-pointer hover:tw-bg-primary-light tw-py-2 tw-block "
                >User
                </span>
                <span
                  class="tw-px-2 tw-cursor-pointer hover:tw-bg-primary-light tw-py-2 tw-block "
                >Template</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-border-y   tw-mt-4 tw-border-solid tw-border-t-gray-200 tw-border-b-gray-100"
    >
      <div class="bx--grid">
        <div class="bx--col-sm-12 tw-justify-between bx--col-xs-12 tw-flex  tw-mt-3">
          <div class="tw-flex tw-items-center">
            <div
              v-for="tab in Tabs"
              :key="tab"
              :class="{
                'tw-border-b-[4px] tw-border-solid tw-border-primary tw-text-black':
                  tab === activeTab,
                'tw-text-black-three tw-border-b-[4px] tw-border-transparent tw-border-solid hover:tw-text-black tw-transition-all tw-duration-300': tab !== activeTab
              }"
              class="tw-py-4  tw-px-3 tw-cursor-pointer"
              @click="changeActiveTab(tab)"
            >
              {{ tab }}
            </div>
          </div>
          <div
            v-if="activeTab == Tabs[1]"
            class="tw-relative  tw-flex tw-items-center"
          >
            <input
              ref="searchInput"
              v-model="searchInput"
              type="text"
              placeholder="Search"
              class="tw-w-[347px] tw-border tw-border-transparent hover:tw-border-solid hover:tw-border-gray-700  tw-px-4 tw-py-2.5 !tw-rounded-full tw-bg-[#F6F6F6]  tw-ring-0"
              @keydown.enter.prevent="filterAccounts"
            >
            <span
              v-if="searchInput!=='' "
              class="tw-text-xs tw-px-2 tw-cursor-pointer tw-absolute tw-right-2 tw-z-10 -translate-y-1/2"
              @click="clearSearchInput"
            >Clear</span>
          </div>
          <div
            v-if="activeTab == Tabs[2]"
            class="tw-relative  tw-flex tw-items-center"
          >
            <input
              ref="userSearchInput"
              v-model="userSearchInput"
              type="text"
              placeholder="Search"
              class="tw-w-[347px] tw-border tw-border-transparent hover:tw-border-solid hover:tw-border-gray-700  tw-px-4 tw-py-2.5 !tw-rounded-full tw-bg-[#F6F6F6]  tw-ring-0"
              @keydown.enter.prevent="filterUsers"
            >
            <span
              v-if="userSearchInput!=='' "
              class="tw-text-xs tw-px-2 tw-cursor-pointer tw-absolute tw-right-2 tw-z-10 -translate-y-1/2"
              @click="clearUserSearchInput"
            >Clear</span>
          </div>
        </div>
      </div>
    </div>
    <!-- DASHBOARD HOME -->
    <div
      v-if="activeTab == Tabs[0]"
      class="bx--col-sm-12 bx--col-xs-12 tw-px-20  tw-mt-8"
    >
      <activity-tab />
    </div>

    <div
      v-if="activeTab == Tabs[1]"
      class="bx--grid"
    >
      <accounts-tab ref="accountTab" />
    </div>

    <!-- ACCOUNTS PAGE ENDS HERE -->
    <div
      v-if="activeTab == Tabs[2]"
      class="bx--grid"
    >
      <users-tab ref="usersTab" />
    </div>
  </div>
</template>

<script>

import {
  IconWrapper,
  StButton,
  StDataTable,
  StDataTableCheckboxRow,
  StDataTableDropdownInfo,
  StDropdownButton,
  StDropdownMenu,
  StEmailInput,
  StHyperlink,
  StInput,
  StModal,
  StDrawer,
  StPagination,
  StTooltip,
  ProfileImage
} from './../common'
import { ActivityTab, AccountsTab, UsersTab } from './admin-dashboard'
import { mapState } from 'vuex'

// TO DO: add Import CSV, Create List, Export List to All Recipients,

// {
//   caption: 'Import CSV',
//   iconName: 'import-csv'
// }, {
//   caption: 'Create List',
//   iconName: 'add-list'
// }, {
//   caption: 'Export List',
//   iconName: 'export-list'
// }
// ];

export default {
  components: {
    IconWrapper,
    StButton,
    StDropdownMenu,
    StDataTable,
    StDataTableCheckboxRow,
    StDataTableDropdownInfo,
    StDropdownButton,
    StEmailInput,
    StHyperlink,
    StInput,
    StModal,
    StDrawer,
    StPagination,
    StTooltip,
    ActivityTab,
    AccountsTab,
    UsersTab,

    ProfileImage
  },
  // props: {
  //   recipients: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  data: function () {
    return {
      Tabs: ['Activity', 'Accounts', 'Users' ],
      activeTab: 'Activity',
      showCreatePopup: false,
      searchInput: '',
      userSearchInput: '',
      funds: ''
    }
  },
  computed: {
    ...mapState(['currentUser'])

    // getPlanInfo() {
    //   return this.currentUser?.account?.plan;
    // },

  },
  mounted () {
    // check if the url has tab
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('tab')) {
      this.activeTab = queryParams.get('tab')
    }
    if (queryParams.has('username')) {
      this.userSearchInput = queryParams.get('username')
    }
    document.addEventListener('keydown', this.hideCreatePopup)
    setTimeout(() => {
      this.getIncentiveAmountFromAmazon()
    }, 200)
  },
  created () {

  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    hideCreatePopup (e) {
      if (e.key === 'Escape') {
        this.showCreatePopup = false
      }
    },
    filterAccounts () {
      this.$store.commit('toggleFullSpinner', true)
      if (this.searchInput != '') {
        if (this.page) {
          const link = `/accounts?per_page=${this.per_page}&page=${this.page}&name=${this.searchInput}`
          //  add search input to href
          const url = new URL(window.location.href)

          url.searchParams.set('search', this.searchInput)
          window.history.pushState({}, '', url)
          this.$refs.accountTab.getFilteredAccounts(link)
        } else {
          const link = `/accounts?name=${this.searchInput}`
          console.log(link)
          const url = new URL(window.location.href)
          url.searchParams.set('search', this.searchInput)
          window.history.pushState({}, '', url)
          this.$refs.accountTab.getFilteredAccounts(link)
        }
      } else {
        const link = `/accounts`

        this.$refs.accountTab.getFilteredAccounts(link)
      }
    },
    filterUsers () {
      // this.$store.commit('toggleFullSpinner', true)
      if (this.userSearchInput != '') {
        //  url: `/users/get_all_users?per_page=${this.per_page}&page=${this.currentPage}&sort_by=${this.selectedSortingField}&sort_order=${this.selectedSortingType}`
        if (this.page) {
          const link = `/users/get_all_users?per_page=${this.per_page}&page=${this.page}&username=${this.userSearchInput}`
          //  add search input to href
          const url = new URL(window.location.href)

          url.searchParams.set('username', this.userSearchInput)
          window.history.pushState({}, '', url)
          this.$refs.usersTab.getFilteredUsers(link)
        } else {
          const link = `/users/get_all_users?username=${this.userSearchInput}`
          const url = new URL(window.location.href)
          url.searchParams.set('username', this.userSearchInput)
          window.history.pushState({}, '', url)
          this.$refs.usersTab.getFilteredUsers(link)
        }
      } else {
        const link = `/users/get_all_users`
        // reset the query params
        const url = new URL(window.location.href)
        url.searchParams.delete('username')
        window.history.pushState({}, '', url)

        this.$refs.usersTab.getFilteredUsers(link)
      }
    },
    changeActiveTab (tab) {
      this.activeTab = tab
      const url = new URL(window.location.href)

      url.searchParams.set('tab', tab)
      window.history.pushState({}, '', url)
    },
    clearSearchInput () {
      this.searchInput = ''
      this.$refs.searchInput.focus()
      this.filterAccounts()
    },
    clearUserSearchInput () {
      this.userSearchInput = ''
      this.$refs.userSearchInput.focus()
      this.filterUsers()
    },
    toggleCreatePopup () {
      this.showCreatePopup = !this.showCreatePopup
    },
    getIncentiveAmountFromAmazon () {
      this.$axios.request({
        method: 'get',
        url: `/accounts/available/funds`
      })
        .then(({ data }) => {
          this.funds = data.available_funds
        })
        .catch((error) => {
          console.log(error)
        })
    }

  }
}
</script>
<style>
.card-bg {
  background: linear-gradient(166deg, #ddd 0%, #fbfbfb 100%);
}
</style>
