import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../locales/en.json';
import hi from '../locales/hi.json';
import es from '../locales/es.json';

Vue.use(VueI18n);

const messages = {
  en,
  hi,
  es
};

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
