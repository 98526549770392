var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-sentiment-data-table-container"},[_c('table',{class:{'table-border-bottom': _vm.tableCompleted,
             'max-w-800p ': _vm.printing,
             'st-sentiment-data-table': !_vm.printing}},[_c('thead',{staticClass:"st-sentiment-data-table__header"},[_c('tr',{staticClass:"st-sentiment-data-table__header-row-pdf"},[_c('th',{staticClass:"st-sentiment-data-table__head-item"},[_c('div',{staticClass:"st-sentiment-data-table__header-and-column-chevron"},[_vm._v("\n            "+_vm._s(_vm.columnTitle)+"\n          ")])]),_vm._v(" "),_vm._l((_vm.tableHeaders),function(header,index){return _c('th',{key:index,staticClass:"st-sentiment-data-table__head-item",class:{'p-8p-important':_vm.printing}},[(_vm.tableHeaders.includes(header) && header!==_vm.tableHeaders[0])?_c('div',{class:[
              { 'box-15p rounded-full mx-auto ': true },
              _vm.sentimentColor(header) ]}):_c('div',{staticClass:"st-sentiment-data-table__header-and-column-chevron"},[_c('span',{staticClass:"font-500"},[_vm._v("\n              "+_vm._s(header)+"\n            ")])])])})],2)]),_vm._v(" "),_c('tbody',{staticClass:"st-sentiment-data-table__body-pdf"},[_vm._l((Object.keys(_vm.filterSentimentRow)),function(row,index){return [_vm._l((_vm.filterGroupedRowData(row)),function(sentimentRow,idxx){return [(
              row === 'unGroup' && index + idxx < _vm.keywordsTableLength
            )?_c('tr',{key:sentimentRow.entityId,class:{
              'st-sentiment-data-table__row border-b-gray-100 px-5': true,
              'st-sentiment-data-table__row--header':
                sentimentRow.entityId === 0 && _vm.isFirstRowHeader,
            }},[(_vm.expandable)?_c('td',{staticClass:"st-sentiment-data-table__item st-sentiment-data-table__item--link",on:{"click":function($event){return _vm.toggleRowExpansion(sentimentRow.entityId, sentimentRow)}}},[_c('div',{class:{
                  'st-sentiment-data-table__keyword tw-max-w-[350px]': true,
                  'st-sentiment-data-table__keyword-open': _vm.isExpanded(
                    sentimentRow.entityId
                  ),
                }},[_vm._v("\n                "+_vm._s(sentimentRow.title)+"\n              ")]),_vm._v(" "),_c('div',{class:{
                  'st-sentiment-data-table__spacer': true,
                  'st-sentiment-data-table__spacer--expanded': _vm.isExpanded(
                    sentimentRow.entityId
                  ),
                }})]):_c('td',{staticClass:"st-sentiment-data-table__item"},[_vm._v("\n              "+_vm._s(sentimentRow.title)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"st-sentiment-data-table__item"},[_c('sentiment-tag',{attrs:{"sentiment":_vm.rowAverages.find(
                    function (row) { return row.entityId === sentimentRow.entityId; }
                  ).sentiments}})],1),_vm._v(" "),(_vm.includeCount)?_c('td',{staticClass:"st-sentiment-data-table__item"},[_vm._v("\n              "+_vm._s(_vm.rowTotalCounts.find(
                  function (row) { return row.entityId === sentimentRow.entityId; }
                ).sentimentsLength)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.activeQuestionModifier === 'votes')?_vm._l((_vm.rowSentimentCounts.find(
                          function (row) { return row.entityId == sentimentRow.entityId; }
                        ).sentimentCounts),function(sentimentCount,percentIdx){return _c('td',{key:percentIdx + 'percentIndex',staticClass:"st-sentiment-data-table__item"},[_vm._v("\n                "+_vm._s(sentimentCount)+"\n              ")])}):_vm._l((_vm.rowSentimentPercents.find(
                          function (row) { return row.entityId == sentimentRow.entityId; }
                        ).sentimentPercents),function(sentimentPercent,percentIdx){return _c('td',{key:percentIdx + 'anotherpercent',staticClass:"st-sentiment-data-table__item"},[_vm._v("\n                "+_vm._s(sentimentPercent)+"%\n              ")])})],2):_vm._e()]})]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }