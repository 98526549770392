<template>
  <div>
    <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-2 tw-bg-black tw-py-16 tw-white tw-text-white">
      <span>
        Change plan
      </span>
      <span class="tw-font-semibold tw-text-[30px]">
        {{ survey.name }}
      </span>
    </div>
    <div class="tw-p-5 md:padding-container tw-mt-12">
      <div>
        <p class="tw-font-semibold tw-text-2xl tw-text-center">
          Select a study plan
        </p>

        <template>
          <div class="tw-gap-10 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-mt-12 tw-text-black-one">
            <div
              v-for="(plan, index) in allStudyPlans.filter((plan)=>plan.plan_type !='free')"
              :key="plan.title"
              class="tw-flex tw-flex-col tw-justify-between md:tw-max-w-[350px]"
            >
              <div>
                <div
                  class="tw-py-3 md:tw-max-w-[350px]"
                  :class="{'tw-bg-black': index==0, 'tw-bg-success': index==1, 'tw-bg-[#647CB9]': index==2, 'tw-bg-[#D4E5EB]': index==3}"
                />
                <div class="tw-px-4">
                  <p class="tw-mt-5 tw-text-[30px] tw-text-center">
                    {{ plan.title }}
                  </p>
                  <p
                    v-if="index==0"
                    class="tw-mt-10 tw-text-center"
                  >
                    For entrepreneurs solidifying product feedback.
                  </p>
                  <p
                    v-if="index==1"
                    class="tw-mt-10 tw-text-center"
                  >
                    For business scaling their customer base.
                  </p>
                  <p
                    v-if="index==2"
                    class="tw-mt-10 tw-text-center"
                  >
                    For business tracking customer concerns and trends.
                  </p>
                  <p
                    v-if="index==3"
                    class="tw-mt-10 tw-text-center"
                  >
                    For business building proprietary data sources.
                  </p>
                  <p class="tw-flex tw-justify-center tw-items-center tw-space-x-1 tw-mt-5">
                    <span class="tw-font-semibold tw-text-[40px]">
                      ${{ plan.price.toLocaleString() }}
                    </span>
                    <span class="tw-text-[15px] tw-text-black-three">
                      /month
                    </span>
                  </p>
                  <p class="tw-text-black-three tw-text-center">
                    Per study
                  </p>
                  <div class="tw-border-white-three tw-my-5 tw-border tw-border-solid" />
                  <p class="tw-my-5 tw-text-black-three tw-text-center">
                    <span v-if="index==0">
                      Includes
                    </span>
                    <span v-else-if="index==1">
                      Everything in small and:
                    </span>
                    <span v-else-if="index==2">
                      Everything in medium and:
                    </span>
                    <span v-else-if="index==3">
                      Everything in large and:
                    </span>
                  </p>
                  <p
                    v-if="index==0"
                    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-3 tw-text-center"
                  >
                    <span>
                      {{ plan.allow_responses.toLocaleString() }} responses per month
                    </span>
                    <span>
                      Segmentation
                    </span>
                    <span>
                      PDF & CSV report
                    </span>
                    <span>
                      Voice Recordings
                    </span>
                    <span>
                      Voice Questions
                    </span>
                    <span>
                      Distribution through shareable link & QR Code
                    </span>
                    <span>
                      Widget integration
                    </span>
                    <span>
                      Unlimited team members
                    </span>
                  </p>
                  <p
                    v-if="index==1"
                    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-3 tw-text-center"
                  >
                    <span>
                      {{ plan.allow_responses.toLocaleString() }} responses per month
                    </span>
                    <span>
                      Incentives
                    </span>
                    <span>
                      Time series insights
                    </span>
                  </p>
                  <p
                    v-if="index==2"
                    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-3 tw-text-center"
                  >
                    <span>
                      {{ plan.allow_responses.toLocaleString() }} responses per month
                    </span>
                    <span>
                      Public insights
                    </span>
                    <span>
                      Sentiment alerts <span class="tw-bg-[#FFAF37] tw-ml-2 tw-p-2 tw-rounded-xl tw-text-xs">Soon</span>
                    </span>
                  </p>
                  <p
                    v-if="index==3"
                    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-3 tw-text-center"
                  >
                    <span>
                      {{ plan.allow_responses.toLocaleString() }} responses per month
                    </span>
                    <span>
                      White label product page
                    </span>
                  </p>

                  <div class="tw-border-white-three tw-my-5 tw-border tw-border-solid" />
                  <p class="tw-my-5 tw-text-black-three tw-text-center">
                    Additional
                  </p>
                  <p class="tw-font-semibold tw-text-center">
                    Answers
                  </p>
                  <p class="tw-flex tw-justify-center tw-items-center tw-space-x-3">
                    <span>
                      Per study
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_114_5116)">
                        <path
                          d="M7.5 0.934082C3.63078 0.934082 0.5 4.06513 0.5 7.93408C0.5 11.8033 3.63105 14.9341 7.5 14.9341C11.3692 14.9341 14.5 11.803 14.5 7.93408C14.5 4.06486 11.3689 0.934082 7.5 0.934082ZM7.5 13.8403C4.23535 13.8403 1.59375 11.1985 1.59375 7.93408C1.59375 4.66943 4.23557 2.02783 7.5 2.02783C10.7647 2.02783 13.4062 4.66965 13.4062 7.93408C13.4062 11.1987 10.7644 13.8403 7.5 13.8403Z"
                          fill="#A6684F"
                        />
                        <path
                          d="M7.5 6.79468C7.19796 6.79468 6.95312 7.03951 6.95312 7.34155V10.8632C6.95312 11.1653 7.19796 11.4101 7.5 11.4101C7.80204 11.4101 8.04688 11.1652 8.04688 10.8632V7.34155C8.04688 7.03951 7.80204 6.79468 7.5 6.79468Z"
                          fill="#A6684F"
                        />
                        <path
                          d="M7.5 6.125C7.90774 6.125 8.23828 5.79446 8.23828 5.38672C8.23828 4.97898 7.90774 4.64844 7.5 4.64844C7.09226 4.64844 6.76172 4.97898 6.76172 5.38672C6.76172 5.79446 7.09226 6.125 7.5 6.125Z"
                          fill="#A6684F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_114_5116">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0.5 0.934082)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </p>
                  <p class="tw-flex tw-justify-center tw-items-center tw-space-x-1 tw-mt-2">
                    <span class="tw-font-semibold tw-text-lg">
                      ${{ getAnswerStrategyPlan(plan.plan_type, 'answers').price }}
                    </span>
                    <span class="tw-text-[15px] tw-text-black-three">
                      /month
                    </span>
                  </p>
                  <p
                    v-if="currentPlanHasStrategies && currentAnswerStrategyPlan.planType == plan.plan_type || (currentPlanHasAnswers && getAnswerStrategyPlan(plan.plan_type, 'answers').id==currentAnswerStrategyPlan.id)"
                    class="tw-mt-2 tw-text-center"
                  >
                    <span class="tw-border-white-two hover:tw-bg-light-gray-four tw-p-2 tw-border tw-border-solid tw-rounded-xl tw-text-[13px] tw-cursor-pointer">
                      Included
                    </span>
                  </p>
                  <p
                    v-else-if="selectedPlanForAnswersStrategies && getAnswerStrategyPlan(plan.plan_type, 'answers').id==selectedPlanForAnswersStrategies.id"
                    class="tw-mt-2 tw-text-center"
                  >
                    <span
                      class="tw-bg-[#494A3D] tw-p-2 tw-border-solid tw-rounded-xl tw-text-[13px] tw-text-white tw-cursor-pointer"
                      @click="selectPlanForAnswersStrategies(getAnswerStrategyPlan(plan.plan_type, 'answers').id)"
                    >
                      Selected
                    </span>
                  </p>
                  <p
                    v-else
                    class="tw-mt-2 tw-text-center"
                  >
                    <span
                      class="tw-border-white-two hover:tw-bg-light-gray-four tw-p-2 tw-border tw-border-solid tw-rounded-xl tw-text-[13px] tw-cursor-pointer"
                      @click="selectPlanForAnswersStrategies(getAnswerStrategyPlan(plan.plan_type, 'answers').id)"
                    >
                      Add
                    </span>
                  </p>
                  <p class="tw-mt-5 tw-font-semibold tw-text-center">
                    Answers & Reports
                  </p>
                  <p class="tw-flex tw-justify-center tw-items-center tw-space-x-3">
                    <span>
                      Per study
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_114_5116)">
                        <path
                          d="M7.5 0.934082C3.63078 0.934082 0.5 4.06513 0.5 7.93408C0.5 11.8033 3.63105 14.9341 7.5 14.9341C11.3692 14.9341 14.5 11.803 14.5 7.93408C14.5 4.06486 11.3689 0.934082 7.5 0.934082ZM7.5 13.8403C4.23535 13.8403 1.59375 11.1985 1.59375 7.93408C1.59375 4.66943 4.23557 2.02783 7.5 2.02783C10.7647 2.02783 13.4062 4.66965 13.4062 7.93408C13.4062 11.1987 10.7644 13.8403 7.5 13.8403Z"
                          fill="#A6684F"
                        />
                        <path
                          d="M7.5 6.79468C7.19796 6.79468 6.95312 7.03951 6.95312 7.34155V10.8632C6.95312 11.1653 7.19796 11.4101 7.5 11.4101C7.80204 11.4101 8.04688 11.1652 8.04688 10.8632V7.34155C8.04688 7.03951 7.80204 6.79468 7.5 6.79468Z"
                          fill="#A6684F"
                        />
                        <path
                          d="M7.5 6.125C7.90774 6.125 8.23828 5.79446 8.23828 5.38672C8.23828 4.97898 7.90774 4.64844 7.5 4.64844C7.09226 4.64844 6.76172 4.97898 6.76172 5.38672C6.76172 5.79446 7.09226 6.125 7.5 6.125Z"
                          fill="#A6684F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_114_5116">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0.5 0.934082)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </p>
                  <p class="tw-flex tw-justify-center tw-items-center tw-space-x-1 tw-mt-2">
                    <span class="tw-font-semibold tw-text-lg">
                      ${{ getAnswerStrategyPlan(plan.plan_type, 'strategies').price }}
                    </span>
                    <span class="tw-text-[15px] tw-text-black-three">
                      /month
                    </span>
                  </p>
                  <p
                    v-if="currentPlanHasStrategies && getAnswerStrategyPlan(plan.plan_type, 'strategies').id==currentAnswerStrategyPlan.id"
                    class="tw-mt-2 tw-text-center"
                  >
                    <span class="tw-border-white-two hover:tw-bg-light-gray-four tw-p-2 tw-border tw-border-solid tw-rounded-xl tw-text-[13px] tw-cursor-pointer">
                      Included
                    </span>
                  </p>
                  <p
                    v-else-if="selectedPlanForAnswersStrategies && getAnswerStrategyPlan(plan.plan_type, 'strategies').id==selectedPlanForAnswersStrategies.id"
                    class="tw-mt-2 tw-text-center"
                  >
                    <span
                      class="tw-bg-[#494A3D] tw-p-2 tw-border-solid tw-rounded-xl tw-text-[13px] tw-text-white tw-cursor-pointer"
                      @click="selectPlanForAnswersStrategies(getAnswerStrategyPlan(plan.plan_type, 'answers').id)"
                    >
                      Selected
                    </span>
                  </p>
                  <p
                    v-else
                    class="tw-mt-2 tw-text-center"
                  >
                    <span
                      class="tw-border-white-two hover:tw-bg-light-gray-four tw-p-2 tw-border tw-border-solid tw-rounded-xl tw-text-[13px] tw-cursor-pointer"
                      @click="selectPlanForAnswersStrategies(getAnswerStrategyPlan(plan.plan_type, 'strategies').id)"
                    >
                      Add
                    </span>
                  </p>
                </div>
              </div>
              <div class="tw-flex tw-items-end tw-mt-5 tw-h-full">
                <ah-button
                  v-if="currentStudyPlan.priceId !== plan.stripe_price_id"
                  class="tw-w-full"
                  :type="currentStudyPlan.price < plan.price ? 'primary' : 'secondary'"
                  @click="changeSelectedPlanForUpgradeDowngrade(plan, currentStudyPlan.price < plan.price ? 'upgrade' : 'downgrade')"
                >
                  {{ getUpgradeDowngradeButtonCaption(plan.id) }}
                </ah-button>
                <button
                  v-else
                  :class="getCurrentPlanButtonCaption == 'Current'? 'tw-bg-[#494A3D] tw-cursor-not-allowed': 'tw-bg-primary'"
                  class="tw-p-3 tw-rounded-xl tw-w-full tw-text-white"
                  @click="addAnswersStrategies(getCurrentPlanButtonCaption)"
                >
                  {{ getCurrentPlanButtonCaption }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <study-plan-upgrade-drawer
      v-if="selectedPlanForUpgradeDowngrade"
      ref="upgradePlanDrawer"
      type="answers"
      :action="action"
      :study="survey"
      :new-plan="selectedPlanForUpgradeDowngrade"
      :answers-plan="selectedPlanForAnswersStrategies"
      @close="hideUpgradePlanDrawer"
    />
    <study-plan-downgrade-disabled
      v-if="selectedPlanForUpgradeDowngrade"
      ref="downgradeDisabledDrawer"
      type="answers"
      :action="action"
      :study="survey"
      :new-plan="selectedPlanForUpgradeDowngrade"
      :answers-plan="selectedPlanForAnswersStrategies"
      @close="hideDowngradedDisabledDrawer"
    />
    <answers-strategy-drawer
      v-if="selectedPlanForAnswersStrategies"
      ref="answersStrategyDrawer"
      :type="selectedPlanForAnswersStrategies.title.toLowerCase().includes('strategies') ? 'strategies' : 'answers'"
      :action="currentPlanHasAnswers ? 'upgrade' : 'add'"
      :study="survey"
      @close="hideStrategyDrawer"
    />
  </div>
</template>

<script>
// import { AhButton } from '../../common'
// import { AhButton, StudyPlanUpgradeDrawer } from '../../common'
import AhButton from '../../common/ah-button.vue'
import StudyPlanUpgradeDrawer from '../../common/study-plan-upgrade-drawer.vue'
import answersStrategyDrawer from '../../common/answers-strategy-drawer.vue'
import StudyPlanDowngradeDisabled from '../../common/study-plan-downgrade-disabled.vue'

export default {
  components: {
    AhButton,
    StudyPlanUpgradeDrawer,
    answersStrategyDrawer,
    StudyPlanDowngradeDisabled
  },

  props: {
    survey: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      allStudyPlans: [],
      allPlans: [],
      currentStudyPlan: null,
      currentAnswerStrategyPlan: null,
      selectedPlanForUpgradeDowngrade: null,
      selectedPlanForAnswersStrategies: null,
      action: null,
      responsesInCurrentMonth: null
    }
  },

  computed: {
    currentPlanHasStrategies () {
      return this.currentAnswerStrategyPlan ? this.currentAnswerStrategyPlan.title.toLowerCase().includes('strategies') : false
    },
    currentPlanHasAnswers () {
      return this.currentAnswerStrategyPlan ? this.currentAnswerStrategyPlan.title.toLowerCase().includes('answers') : false
    },
    getCurrentPlanButtonCaption () {
      if (this.selectedPlanForAnswersStrategies && this.selectedPlanForAnswersStrategies.plan_type == this.currentStudyPlan.planType) {
        return `Add ${this.selectedPlanForAnswersStrategies.title.toLowerCase().includes('strategies') ? 'Answers & Strategies' : 'Answers'} ($${this.selectedPlanForAnswersStrategies.price})`
      } else {
        return 'Current'
      }
    }
  },

  mounted () {
    this.$store.commit('toggleFullSpinner', true)
    this.currentStudyPlan = this.survey.plans.find((plan) => {
      return plan.title.toLowerCase().includes('answers') == false
    })
    this.currentAnswerStrategyPlan = this.survey.plans.find((plan) => {
      return plan.title.toLowerCase().includes('answers') == true
    })

    setTimeout(() => {
      this.getAllPlans()
      this.getResponsesInCurrentMonth()
    }, 200)
  },

  methods: {
    changeSelectedPlanForUpgradeDowngrade (plan, action) {
      this.action = action
      this.selectedPlanForUpgradeDowngrade = plan
      if (action == 'downgrade') {
        if (this.responsesInCurrentMonth >= plan.allow_responses) {
          setTimeout(() => {
            this.$refs.downgradeDisabledDrawer.toggleDrawer()
          }, 100)
          return
        }
      }

      setTimeout(() => {
        this.$refs.upgradePlanDrawer.toggleDrawer()
      }, 100)
    },

    hideUpgradePlanDrawer () {
      this.selectedPlanForUpgradeDowngrade = null
    },
    hideDowngradedDisabledDrawer () {
      this.selectedPlanForUpgradeDowngrade = null
    },
    hideStrategyDrawer () {
      this.selectedPlanForAnswersStrategies = null
    },
    addAnswersStrategies (type) {
      if (type == 'Current') {
        return
      }
      this.$refs.answersStrategyDrawer.toggleDrawer()
    },
    getAllPlans () {
      this.$axios
        .request({
          method: 'get',
          url: `plans`
        })
        .then(({ data }) => {
          this.$store.commit('toggleFullSpinner', false)
          this.allPlans = data
          const withoutAnswersPlans = data.filter((plan) => plan.title.toLowerCase().includes('answers') === false)
          this.allStudyPlans = withoutAnswersPlans
          // console.log(this.allPlans)
        })
        .catch((err) => {
          this.$store.commit('toggleFullSpinner', false)
          console.log(err)
        })
    },
    selectPlanForAnswersStrategies (id) {
      console.log(id, this.selectedPlanForAnswersStrategies)
      if (this.selectedPlanForAnswersStrategies && this.selectedPlanForAnswersStrategies.id == id) {
        console.log('sdfdsfsdf')
        this.selectedPlanForAnswersStrategies = null
        return
      }
      console.log('this is not good')
      this.selectedPlanForAnswersStrategies = this.allPlans.find((plan) => plan.id == id)
    },
    getAnswerStrategyPlan (size, type) {
      const planToReturn = this.allPlans.find((plan) => plan.title.toLowerCase().includes(type) && plan.plan_type.toLowerCase().includes(size)
      )
      return planToReturn
    },
    getResponsesInCurrentMonth () {
      this.$axios
        .request({
          method: 'get',
          url: `/study/${this.survey.id}/total_responses_current_month`
        })
        .then(({ data }) => {
          // console.log(data.total_responses)
          this.responsesInCurrentMonth = data.total_responses

          // console.log(data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getUpgradeDowngradeButtonCaption (id) {
      const plan = this.allPlans.find((plan) => plan.id == id)
      if (this.currentStudyPlan.price < plan.price) {
        if (this.selectedPlanForAnswersStrategies && this.selectedPlanForAnswersStrategies.plan_type == plan.plan_type) {
          return `Upgrade Plan ($${plan.price + this.selectedPlanForAnswersStrategies.price})`
        } else {
          return `Upgrade`
        }
      } else {
        return 'Downgrade'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
