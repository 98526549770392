<script>
// Intended to be used within a question specifications component
// TODO: we could instead write a helper function to create a computed getter/setter object
//       so that we could just use v-model & include the update
//       see https://vuex.vuejs.org/guide/forms.html
//       could be good to include lodash.debounce and/or .lazy modifier on v-model
import { mapGetters } from 'vuex'
import { snakeCase } from 'lodash'

export function actionSetter (propNames) {
  let obj = {}
  propNames.forEach(propName => {
    obj[propName] = {
      get () {
        return this.activeQuestion[propName]
      },
      set (newValue) {
        this.updateQuestion(snakeCase(propName), newValue)
      }
    }
  })
  return obj
}

export function mutationSetter (propNames) {
  let obj = {}
  propNames.forEach(propName => {
    obj[propName] = {
      get () {
        return this.activeQuestion[propName]
      },
      set (newValue) {
        this.mutateQuestion(snakeCase(propName), newValue)
      }
    }
  })
  return obj
}

export default {
  computed: {
    ...mapGetters('specifications', ['activeQuestion'])
  },
  methods: {
    mutateQuestion (key, value) {
      if (this.isPreview) { return }

      let question = {
        [key]: value
      }

      this.$store.commit('specifications/mergeActiveQuestion', question)
    },
    updateQuestion (key, value) {
      if (this.isPreview) { return }

      let updateData = {
        [key]: value
      }
      // console.log({ updateData })
      this.$store.dispatch('specifications/updateActiveQuestion', updateData)
    }
  }
}
</script>
