<template>
  <div
    v-if="showModal"
    class="tw-h-full"
  >
    <div
      ref="stModal"
      class="st-modal__opacitor"
      tabindex="0"
      @click="closeModal"
      @keyup.esc="escape"
    />
    <div
      ref="stModalContent"
      style="z-index:9999"
      :class="[{
        'st-drawer tw-p-5 md:tw-p-0 tw-overflow-x-hidden animation tw-flex tw-flex-col': true,
      }, sizeClass]"
    >
      <slot />
      <div
        v-if="!hideTitleAndClose"
        :class="{
          'st-modal__container--title-and-close': true,
          'st-modal__container--title-and-close--hide-close': hideClose,
        }"
      >
        <div class="flex justify-between w-full tw-border-b tw-border-solid tw-border-white-two">
          <span
            class="st-modal__title flex justify-between w-full items-center p-5 px-2p"
          >
            <slot
              name="modalTitle"
            />
            <span
              class="cursor-pointer  tw-w-[60px] tw-text-end tw-flex tw-items-center tw-justify-end"
              data-title="Close"
              @click="closeModal"
            >

              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.00024 16.5C3.58168 16.5 0 12.9182 0 8.5C0 4.08178 3.58168 0.5 8.00024 0.5C12.4188 0.5 16 4.08178 16 8.5C16 12.9187 12.4183 16.5 8.00024 16.5ZM8.03206 2.43505C4.68216 2.41795 1.95304 5.11913 1.93546 8.46913C1.91837 11.8186 4.61947 14.5483 7.96889 14.5659C11.3183 14.583 14.0479 11.8813 14.0655 8.53182C14.0831 5.18183 11.3815 2.45215 8.03206 2.43505ZM11.2523 11.5294L11.0267 11.755C10.7089 12.0728 10.1936 12.0728 9.87633 11.755L7.98266 9.86128L6.089 11.755C5.77125 12.0728 5.25639 12.0728 4.93864 11.755L4.71304 11.5294C4.39529 11.2116 4.39529 10.6963 4.71304 10.379L6.6067 8.48528L4.71304 6.59155C4.39529 6.27379 4.39529 5.75845 4.71304 5.44116L4.93864 5.21555C5.25639 4.89779 5.77172 4.89779 6.089 5.21555L7.98266 7.10927L9.87633 5.21555C10.1941 4.89779 10.7094 4.89779 11.0267 5.21555L11.2523 5.44116C11.57 5.75892 11.57 6.27427 11.2523 6.59155L9.35863 8.48528L11.2523 10.379C11.57 10.6968 11.57 11.2121 11.2523 11.5294Z"
                  fill="black"
                />
              </svg>

            </span>
          </span>
        </div>
      </div>
      <div
        class="tw-h-[91vh]"
        :class="actionsClass"
      >
        <slot
          name="modalActions"
        />
      </div>
      <slot
        name="additionalInformation"
      />
    </div>
  </div>
</template>

<script>
// import IconWrapper from '../common/icon-wrapper'

export default {
  name: 'StDrawer',
  components: {
    // 'icon-wrapper': IconWrapper
  },
  model: {
    prop: 'showModal',
    event: 'closeModal'
  },
  props: {
    /** Used to change the styling on the main content section */
    actionsClass: {
      type: String,
      required: false,
      default: ''
    },
    /** Used hide the default button at the bottom of modals */
    hideButton: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Used to show "cancel X" at the bottom left of st-modal (in conjunction with a nonExitModal) */
    cancelModal: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Allows for the removal of the "x" at the top right of the modal */
    hideClose: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Allows for the removal of the title and the "x" at the top right of the modal */
    hideTitleAndClose: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Change the size of the modal (default, large). */
    sizeClass: {
      type: String,
      required: false,
      default: 'st-drawer--default'
    },
    /** Determines if the modals is displayed. */
    value: Boolean,
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    nonExitModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
    }
  },
  watch: {
    showModal (newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.stModal.focus()
        })
      }
    }
  },
  methods: {
    closeModal () {
      // animate out before closing
      this.$refs.stModalContent.classList.add('animate-out')
      setTimeout(() => {
        if (!this.nonExitModal) {
          this.$emit('closeModal', false)
        }
      }, 500)
    },
    escape (event) {
      this.$emit('closeModal', false)
    }
  }
}
</script>
<style>
.animation{
  animation: slideIn 0.5s forwards;
}
.animate-out{
  animation: slideOut 0.5s forwards;
}
@keyframes slideIn {
  0% {
    opacity: 0;
    right: -500px;
  }
  100% {
    opacity: 1;
    right: 0;
  }

}
@keyframes slideOut {
  0% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: -500px;
  }
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #050505;
  color: #ffffff;
  font-size: 14px;
  border-radius: 999px;
  position: absolute;
  padding: 2px 4px 4px 2px;
  bottom: -1.6em;
  right: 10px;
  white-space: nowrap;
  /* box-shadow: 1px 1px 3px #b3b3b3; */
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}</style>
