<template>
  <div class="st-reset-password  tw-h-[90vh]">
    <div class=" tw-flex tw-justify-center tw-items-center tw-h-full">
      <div class=" tw-w-full md:tw-max-w-xl tw-mx-auto tw-my-auto ">
        <div class="st-reset-password__header-row">
          <p class="tw-font-semibold tw-text-[40px]">
            Forgot password
          </p>
        </div>

        <p class="tw-my-4">
          Enter your email to receive a password reset link.
        </p>

        <form
          id="new_user"
          action="/users/password"
          accept-charset="UTF-8"
          method="post"
          class="new_user"
        >
          <input
            type="hidden"
            name="authenticity_token"
            :value="token"
          >
          <st-input
            v-model="email"
            style-type="rounded"
            label="Email"
            class=""
            name="user[email]"
            theme="light"
            placeholder="Enter your email"
          />
          <slot />

          <div class="st-reset-password__submit-btn">
            <st-button
              class="tw-px-6 tw-rounded-xl"
              type="submit"
              caption="Reset password"
              icon-name="arrow-up"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  StInput,
  StButton
} from '../../common'

export default {
  components: {
    StInput,
    StButton
  },
  props: {
  },
  data: function () {
    return {
      email: ''
    }
  },
  computed: {
    token () {
      const tokenTag = document.querySelector('meta[name=csrf-token]')
      return tokenTag === null ? '' : tokenTag.content
    }
  },
  methods: {
  }
}
</script>
