import { forIn, get } from 'lodash'

import { average, matchesFilters } from '../../utilities/data_utilities'
import {
  mapFromArr,
  assignPropertyList
} from '../../utilities/object_utilities'
import { questionResponseFactory } from '../../structures/question-response'

function selectedOptions (segment) {
  return segment.options.filter((option) => option.selected)
}

function selectedOptionIds (segment) {
  return selectedOptions(segment).map((option) => option.id)
}

function assignRoomResponses (responsesByRoom, response) {
  if (!response.roomSentiments) {
    return
  }

  response.roomSentiments.forEach((roomSentiment) => {
    responsesByRoom[roomSentiment.roomId].push(
      detailedRoomSentiment(response, roomSentiment)
    )
  })
}

function detailedRoomSentiment (response, roomSentiment) {
  let result = { ...roomSentiment }
  return assignPropertyList(
    result,
    response,
    'id',
    'firstName',
    'fullName',
    'lastName',
    'email',
    'roomTranscripts',
    'sentences'
  )
}

function assignTopicResponses (responsesByTopic, response) {
  if (!response.topicSentiments) {
    return
  }

  response.topicSentiments.forEach((topicSentiment) => {
    responsesByTopic[topicSentiment.topicPrompt].push(
      detailedTopicResponse(response, topicSentiment)
    )
  })
}

function detailedTopicResponse (response, topicSentiment) {
  let result = { ...topicSentiment }
  return assignPropertyList(
    result,
    response,
    'id',
    'firstName',
    'fullName',
    'lastName',
    'email',
    'sentences'
  )
}

function transformResponse (rawResponse, getters) {
  return questionResponseFactory(rawResponse, getters)
}

function responseFilterer (question) {
  if (['opinion_metric', 'virtual_tour'].includes(question.questionType)) {
    return (response) => {
      return response.sentimentScore !== null && response.transcript
    }
  } else {
    return (_response) => true
  }
}

const titleCaseBuckets = [
  'veryNegative',
  'negative',
  'neutral',
  'positive',
  'veryPositive'
]

export default {
  namespaced: true,
  state: {
    activeQuestionModifier: 'votes',
    activeQuestionView: 'Results',
    activePanelSection: '',
    surveyQuestions: [],
    surveyQuestionsData: [],
    surveyThemeImprovements: [],
    activeQuestionIndex: 0,
    responses: [[]],
    segments: [],
    segmentOptions: {},
    moveVirtualTourFn: null,
    optionIds: [],
    activeViewpointIdentifier: null,
    queuedViewpointIdentifier: null,
    selectedSurveyResponseIds: [], // will carry over from question to question
    sentiments: {
      veryNegative: true,
      negative: true,
      neutral: true,
      positive: true,
      veryPositive: true
    },
    skippedCount: 0,
    tourMode: false,
    tourSegmentsOpen: false,
    tourTranscriptsOpen: false,
    sentimentGroups: []
  },
  getters: {
    getSentimentGroups: (state) => {
      return state.sentimentGroups
    },
    checkedSegments: (state) => {
      return state.segments.reduce((result, segment) => {
        result[segment.segmentId] = selectedOptionIds(segment)
        return result
      }, {})
    },
    activeQuestion: (state) => {
      // excluding slide questions for insights
      const surveyQuestions = state.surveyQuestions.filter(
        (question) => question.questionType !== 'slide'
      )
      return surveyQuestions[state.activeQuestionIndex]
    },
    activeQuestionResponses: (state) => {
      return state.responses[state.activeQuestionIndex]
    },
    activeQuestionHasSentiment: (_state, getters) => {
      return ['opinion_metric', 'virtual_tour'].includes(
        getters.activeQuestion.questionType
      )
    },
    responsesPassingSegmentFilter: (_state, getters) => {
      return getters.activeQuestionResponses.filter((response) => {
        return matchesFilters(
          getters.checkedSegments,
          response.segmentResponses
        )
      })
    },
    responsesPassingSentimentFilter: (state, getters) => {
      const checkedSentiments = titleCaseBuckets.map(
        (bucket) => state.sentiments[bucket]
      )
      return getters.responsesPassingSegmentFilter.filter((response) => {
        return checkedSentiments[response.sentimentValueIdx]
      })
    },
    responsesPassingParticipantCheckboxFilter: (state, getters) => {
      let responses = getters.activeQuestionHasSentiment
        ? getters.responsesPassingSentimentFilter
        : getters.responsesPassingSegmentFilter
      return responses.filter(
        (response) => state.selectedSurveyResponseIds[response.surveyResponseId]
      )
    },
    responsesById: (_state, getters) => {
      return getters.activeQuestionResponses.reduce((map, response) => {
        map[response.id] = response
        return map
      }, {})
    },
    findResponse: (_state, getters) => (responseId) => {
      return getters.responsesById[responseId]
    },
    rooms: (_state, getters) => {
      return getters.activeQuestion.rooms || []
    },
    roomsById: (_state, getters) => {
      let roomObj = {}
      getters.rooms.forEach((room) => {
        roomObj[room.id] = room
      })
      return roomObj
    },
    roomIds: (_state, getters) => {
      return getters.rooms.map((room) => room.id)
    },
    topics: (_state, getters) => {
      const panoramas = getters.activeQuestion.panoramas || []
      const topicSet = panoramas
        .filter((pano) => pano.prompt)
        .filter((pano) => pano.topic)
        .map((pano) => pano.prompt)
        .reduce((uniqueTopics, topic) => uniqueTopics.add(topic), new Set())
      return Array.from(topicSet)
    },
    viewpoints: (_state, getters) => {
      let viewpoints = []
      getters.rooms.forEach((room) => {
        room.panoramas.forEach((pano) => {
          viewpoints.push(pano)
        })
      })
      return viewpoints
    },
    promptByViewpointIdentifier (_state, getters) {
      let promptObject = {}
      getters.viewpoints.forEach((pano) => {
        promptObject[pano.identifier] = pano.prompt
      })
      return promptObject
    },
    viewpointsByIdentifier (_state, getters) {
      let viewpointsObject = {}
      getters.viewpoints.forEach((pano) => {
        viewpointsObject[pano.identifier] = pano
      })
      return viewpointsObject
    },
    roomIdByViewpointIdentifier (_state, getters) {
      let roomObject = {}
      getters.rooms.forEach((room) => {
        room.panoramas.forEach((pano) => {
          roomObject[pano.identifier] = room.id
        })
      })
      return roomObject
    },
    responsesByRoom: (_state, getters) => {
      let listsPerRoom = mapFromArr(getters.roomIds, () => [])
      getters.responsesPassingParticipantCheckboxFilter.forEach((response) =>
        assignRoomResponses(listsPerRoom, response)
      )

      for (let roomId in listsPerRoom) {
        listsPerRoom[roomId].sort((a, b) => {
          return a.sentimentScore - b.sentimentScore
        })
      }
      return listsPerRoom
    },
    responsesByTopic: (_state, getters) => {
      let listsPerTopic = mapFromArr(getters.topics, () => [])
      getters.responsesPassingParticipantCheckboxFilter.forEach((response) =>
        assignTopicResponses(listsPerTopic, response)
      )
      for (let topic in listsPerTopic) {
        listsPerTopic[topic].sort(
          (a, b) => a.sentimentScore - b.sentimentScore
        )
      }
      return listsPerTopic
    },
    sentimentScoresByRoom (_state, getters) {
      let groupedScores = mapFromArr(getters.roomIds, () => [])
      Object.keys(groupedScores).forEach((roomId) => {
        groupedScores[roomId] = getters.responsesByRoom[roomId].map(
          (response) => response.sentimentScore
        )
      })
      return groupedScores
    },
    panoramasByRoomId (_state, getters) {
      let result = {}
      getters.rooms.forEach((room) => {
        result[room.id] = room.panoramas.map((pano) => pano.identifier)
      })
      return result
    },
    transcriptsByViewpointIdentifier (_state, getters) {
      let result = mapFromArr(getters.viewpoints, () => [])
      getters.responsesPassingParticipantCheckboxFilter.forEach((response) => {
        forIn(
          response.viewpointTranscripts,
          (viewpointTranscript, panoramaId) => {
            if (panoramaId in result) {
              result[panoramaId].push(viewpointTranscript)
            } else {
              result[panoramaId] = [viewpointTranscript]
            }
          }
        )
      })
      return result
    },
    activeRoom (state, getters) {
      if (state.activeViewpointIdentifier) {
        return getters.roomsById[
          getters.roomIdByViewpointIdentifier[state.activeViewpointIdentifier]
        ]
      } else {
        return { name: '' }
      }
    },
    activeRoomSentiment (state, getters) {
      if (
        state.activeViewpointIdentifier &&
        getters.responsesPassingParticipantCheckboxFilter.length > 0
      ) {
        return average(getters.roomSentiments[getters.activeRoom.id])
      } else if (
        getters.responsesPassingParticipantCheckboxFilter.length === 0
      ) {
        return average(
          getters.activeQuestionResponses.map(
            (response) => response.sentimentScore
          )
        )
      } else {
        return average(
          getters.responsesPassingParticipantCheckboxFilter.map(
            (response) => response.sentimentScore
          )
        )
      }
    },
    activeViewpointTranscripts (state, getters) {
      return (
        getters.transcriptsByViewpointIdentifier[
          state.activeViewpointIdentifier
        ] || []
      )
    },
    segmentIdsWithUncheckedOptions (state) {
      let unchecked = state.segments.filter((segment) => {
        return segment.options.map((option) => option.selected).includes(false)
      })
      return unchecked.map((segment) => segment.segmentId)
    },
    mappedSegmentTagsToDisplay (state, getters) {
      if (state.segments.length === 0) {
        return []
      } else {
        let tags = []
        state.segments.forEach((segment) => {
          tags.push({
            anyUnchecked: getters.segmentIdsWithUncheckedOptions.includes(
              segment.segmentId
            ),
            options: segment.options
          })
        })
        return tags
      }
    },
    roomSentiments (_state, getters) {
      let obj = {}
      getters.responsesPassingParticipantCheckboxFilter.forEach((response) => {
        response.roomSentiments.forEach((sentiment) => {
          if (obj[sentiment.roomId]) {
            obj[sentiment.roomId].push(sentiment.sentimentScore)
          } else {
            obj[sentiment.roomId] = [sentiment.sentimentScore]
          }
        })
      })
      return obj
    }
  },
  mutations: {
    replaceSentimentGroups: (state, groups) => {
      state.sentimentGroups = groups
    },
    replaceEntitySentiments: (state, entitySentiments) => {
      state.responses.splice(state.activeQuestionIndex, 1, entitySentiments)
    },
    replaceSurveyQuestions: (state, surveyQuestions) => {
      state.surveyQuestions = surveyQuestions
    },
    setSurveyQuestionsData: (state, data) => {
      state.surveyQuestionsData = data
    },
    setSurveyThemeImprovements: (state, data) => {
      state.surveyThemeImprovements = data.splice(0, 3)
    },
    replaceSurveyQuestion: (state, surveyQuestion) => {
      let idx = state.surveyQuestions.indexOf((q) => {
        return q.id === surveyQuestion.id
      })
      state.surveyQuestions.splice(idx, 1, surveyQuestion)
    },
    replaceTourImage: (state, imageUrl) => {
      state.surveyQuestions[
        state.activeQuestionIndex
      ].tourImageFullUrl = imageUrl
    },
    setActiveQuestionIndex: (state, activeIdx) => {
      state.activeQuestionIndex = activeIdx
    },
    setInitialResponses: (state) => {
      state.responses = state.surveyQuestions.map((q) => [])
    },
    setInitialSelectedSurveyResponsIds: (state, surveyResponseIds) => {
      state.selectedSurveyResponseIds = mapFromArr(surveyResponseIds, true)
    },
    replaceModifier: (state, modifier) => {
      state.activeQuestionModifier = modifier
    },
    replaceView: (state, view) => {
      state.activeQuestionView = view
    },
    replaceResponses: (state, payload) => {
      if (state.responses[payload.index].length === 0) {
        state.responses[payload.index].splice(0, 1, ...payload.responses)
      }
    },
    replaceSkippedCount: (state, payload) => {
      state.skippedCount = payload
    },
    replaceSegments: (state, segments) => {
      state.segments = segments
    },
    toggleSegmentOption: (state, payload) => {
      state.segments[payload.segmentIdx].options[payload.optionIdx].selected =
        payload.value
    },
    toggleTourMode: (state, payload) => {
      state.tourMode = payload
    },
    resetSegments: (state) => {
      state.segments.forEach((segment) => {
        segment.expanded = true
        segment.options.forEach((option) => {
          option.selected = true
        })
      })
    },
    setActiveViewpointIdentifier: (state, identifier) => {
      state.activeViewpointIdentifier = identifier
    },
    setQueuedViewpointIdentifier: (state, identifier) => {
      state.queuedViewpointIdentifier = identifier
    },
    resetParticipants: (state, selected = true) => {
      const newSelectedSurveyResponseIds = {}
      for (const [responseId] of Object.entries(
        state.selectedSurveyResponseIds
      )) {
        newSelectedSurveyResponseIds[responseId] = selected
      }
      state.selectedSurveyResponseIds = newSelectedSurveyResponseIds
    },
    toggleParticipant: (state, { surveyResponseId, selected }) => {
      if (!surveyResponseId) {
        return
      }

      if (selected === undefined) {
        selected = !state.selectedSurveyResponseIds[surveyResponseId]
      }
      state.selectedSurveyResponseIds = {
        ...state.selectedSurveyResponseIds,
        [surveyResponseId]: selected
      }
    },

    toggleSentiment: (state, { sentimentKey, value }) => {
      state.sentiments[sentimentKey] = value
    },
    togglePanel: (state, section) => {
      if (state.activePanelSection === section) {
        state.activePanelSection = null
      } else {
        state.activePanelSection = section
      }
    },
    openPanel: (state, section) => {
      state.activePanelSection = section
    },
    toggleTourSegments: (state, payload) => {
      state.tourSegmentsOpen = payload
    },
    toggleTourTranscripts: (state, payload) => {
      state.tourTranscriptsOpen = payload
    },
    setMoveVirtualTour: (state, fn) => {
      state.moveVirtualTourFn = fn
    },
    selectSingleResponse: (state, singleSurveyResponseId) => {
      const newSelectedSurveyResponseIds = {}
      for (const [responseId] of Object.entries(
        state.selectedSurveyResponseIds
      )) {
        // using eqeq on purpose
        newSelectedSurveyResponseIds[responseId] =
          responseId == singleSurveyResponseId // eslint-disable-line eqeqeq
      }
      state.selectedSurveyResponseIds = newSelectedSurveyResponseIds
    }
  },
  actions: {
    moveVirtualTour: ({ state, commit }, identifier) => {
      commit('setQueuedViewpointIdentifier', identifier)
      if (state.moveVirtualTourFn !== null) {
        state.moveVirtualTourFn(identifier)
      }
    },
    openSingleTranscript: (
      { getters, commit },
      { responseId, surveyResponseId }
    ) => {
      if (!surveyResponseId) {
        const response = getters.findResponse(responseId)
        surveyResponseId = response && response.surveyResponseId
      }
      if (surveyResponseId) {
        commit('selectSingleResponse', surveyResponseId)
      }
      commit('replaceView', 'Transcripts')
      commit('openPanel', 'modifiers')
    },
    setActiveFromUrl ({ dispatch, commit, state }) {
      const url = new URL(window.location)
      const questionId = parseInt(url.searchParams.get('question'))
      const idx = state.surveyQuestions.findIndex((q) => q.id == questionId)
      const mode = url.searchParams.get('mode')
      dispatch('setActive', idx).then(() => {
        switch (mode) {
          case 'tour':
            commit('toggleTourMode', true)
            break
          case 'transcript':
            commit('replaceView', 'Transcripts')
            if (url.searchParams.get('response')) {
              const id = Number(url.searchParams.get('response'))
              dispatch('openSingleTranscript', { responseId: id })
            } else {
              commit('replaceView', 'Transcripts')
              commit('openPanel', 'modifiers')
            }
            break
        }
      })
    },
    setActive ({ dispatch, commit, state }, idx) {
      let newActiveQuestion = state.surveyQuestions[idx]

      commit('toggleFullSpinner', true, { root: true })
      let title = 'question: ' + newActiveQuestion.questionType
      let url = new URL(window.location)
      let params = new URLSearchParams(url.search)
      let mode = params.get('mode') || 'normal'
      let newState = { question: newActiveQuestion, mode: mode }
      params.set('question', newActiveQuestion.id)

      return dispatch('populateResponsesForIndex', idx)
        .then((res) => {
          commit('replaceSkippedCount', res.data.skippedCount)
          commit('toggleFullSpinner', false, { root: true })

          // TODO we should tweak the parameters to make sense for this question type
          // e.g. it will still have the old mode even if the new question doesn't have it
          window.history.replaceState(newState, title, `?${params}`)
        })
        .then()
        .catch(() => {
          commit('toggleInlineSpinner', false, { root: true })
        })
    },
    populateResponsesForIndex ({ commit, state, rootState, getters }, idx) {
      let newActiveQuestion = state.surveyQuestions[idx]
      commit('setActiveQuestionIndex', idx)

      rootState.axios
        .request({
          url: `/entity_sentiments_groups/question/${newActiveQuestion.id}`,
          method: 'get'
        })
        .then((res) => {
          commit(
            'replaceSentimentGroups',
            res.data.groups
          )
        })
        .catch((err) => {
          console.log(err)
        })

      return rootState.axios
        .request({
          method: 'get',
          url: `/questions/${newActiveQuestion.id}/results.json`
        })
        .then((res) => {
          let responses = res.data.responses
            .filter((response) => !response.skipped)
            .map((resp) => transformResponse(resp, getters))
            .filter(responseFilterer(newActiveQuestion))
          commit('replaceResponses', { responses: responses, index: idx })
          return res
        })
    },
    replaceEditedResponse: ({ state, getters }, rawEditedResponse) => {
      let newResponses = state.responses[state.activeQuestionIndex]
      let editedResponse = transformResponse(rawEditedResponse, getters)
      let idx = newResponses
        .map((response) => response.id)
        .indexOf(editedResponse.id)
      newResponses[idx] = editedResponse
      state.responses.splice(state.activeQuestionIndex, 1, newResponses)
    },
    updateEntitySentimentGroup: ({ commit, state }, editEntitySentiment) => {
      const activeResponse = state.responses[state.activeQuestionIndex]
      const { entityId, groupId } = editEntitySentiment
      const updatedEntitySentiments = activeResponse.map((res) => {
        return {
          ...res,
          entitySentiments: res.entitySentiments.map((row) =>
            row.id === entityId
              ? { ...row, entitySentimentsGroupId: groupId }
              : row
          )
        }
      })
      commit('replaceEntitySentiments', updatedEntitySentiments)
    },
    updateEntitySentimentName: ({ commit, state }, editEntitySentiment) => {
      const activeResponse = state.responses[state.activeQuestionIndex]
      const { entityId, name } = editEntitySentiment
      // console.log('sfdsfdsf', name)
      const updatedEntitySentiments = activeResponse.map((res) => {
        return {
          ...res,
          entitySentiments: res.entitySentiments.map((row) =>
            row.id === entityId ? { ...row, name: name } : row
          )
        }
      })
      commit('replaceEntitySentiments', updatedEntitySentiments)
    },
    deleteEntitySentiment ({ commit, state }, deleteEntitySentiment) {
      const activeResponse = state.responses[state.activeQuestionIndex]
      const { entityId } = deleteEntitySentiment
      const updatedEntitySentiments = activeResponse.map((res) => {
        return {
          ...res,
          entitySentiments: res.entitySentiments.filter((row) => row.id !== entityId)
        }
      })
      commit('replaceEntitySentiments', updatedEntitySentiments)
    },
    updateEntitySentiment ({ commit, state }, editEntitySentiment) {
      const activeResponse = state.responses[state.activeQuestionIndex]
      const { entityId, sentiment } = editEntitySentiment
      const updatedEntitySentiments = activeResponse.map((res) => {
        return {
          ...res,
          entitySentiments: res.entitySentiments.map((row) =>
            row.id === entityId ? { ...row, sentimentScore: sentiment, magnitudeScore: sentiment, salience: sentiment } : row
          )
        }
      })
      commit('replaceEntitySentiments', updatedEntitySentiments)
    },
    updateSentimentGroup: ({ commit, state, getters }, sentimentGroup) => {
      const activeSentimentGroup = getters.getSentimentGroups
      const { group, type } = sentimentGroup
      if (type === 'add') {
        state.sentimentGroups.push(group)
      } else if (type === 'update') {
        const groupIndex = activeSentimentGroup.findIndex(
          (row) => row.id === group.id
        )
        state.sentimentGroups.splice(groupIndex, 1, group)
      } else if (type === 'delete') {
        const groupIndex = activeSentimentGroup.findIndex(
          (row) => row.id === group.id
        )
        state.sentimentGroups.splice(groupIndex, 1)
      }
    }
  }
}
