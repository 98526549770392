<template>
  <div>
    <div class="st-welcome--landing">
      <div class="st-welcome--main">
        <div class="bx--grid">
          <div class="st-welcome__intro st-welcome__dots">
            <div class="bx--row">
              <div class="bx--col-xs-12 bx--col-lg-10 bx--offset-lg-1 bx--col-xl-8 bx--offset-xl-2">
                <h5
                  class="st-welcome__subheading st-welcome__intro-subheading"
                  data-aos="fade-down"
                >
                  MAKE real estate decisions with a better understanding of what people want.
                </h5>
                <div class="st-welcome__main-rotate">
                  <div class="st-welcome__main-rotate-text">
                    <h1
                      ref="question"
                      class="page-header st-welcome__main-heading st-welcome__intro-heading"
                      data-aos="fade-down"
                    >
                      <span class="st-welcome__intro-highlight">{{ questionDisplay.slice(0, highlightEndIdx) }}</span><span class="st-welcome__intro-plain">{{ questionDisplay.slice(highlightEndIdx) }}</span>
                    </h1>
                  </div>
                  <div
                    class="st-welcome__workplace-strategist-canvas"
                    data-aos="fade-up"
                  >
                    <div
                      class="st-welcome__workplace-strategist-component"
                      :class="{'st-welcome__workplace-strategist-component--faded': isSlideFaded}"
                    >
                      <div
                        :key="activeSlideIdx"
                        class="st-welcome__workplace-strategist-img"
                        :class="{[`st-welcome__workplace-strategist-img--${activeSlideIdx}`]: true}"
                      />
                      <div class="st-welcome__workplace-strategist-detail">
                        <h6 class="st-welcome__workplace-strategist-title">
                          {{ activeSlide.name }}
                        </h6>
                        <p class="st-welcome__workplace-strategist-para">
                          {{ activeSlide.role }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="st-welcome__button-canvas"
                  data-aos="fade-right"
                >
                  <st-button
                    class="st-welcome__button-top st-welcome__button-set st-button__set"
                    :variant="['secondary']"
                    caption="TRY OPINIONGRAPHS"
                    icon-name="pointer"
                    :show-action="true"
                    :underline="false"
                    url="/prospective/begin"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="st-welcome__building-intelligence st-welcome__dots">
            <div class="bx--row st-welcome__row-vertical-center">
              <div class="bx--col-xs-12 bx--col-lg-6">
                <h5
                  class="st-welcome__subheading"
                  data-aos="fade-down"
                >
                  Building Intelligence
                </h5>
                <h1
                  class="page-header st-welcome__main-heading st-welcome__building-intel-heading"
                  data-aos="fade-down"
                >
                  Quantify people’s feelings about real estate space.
                </h1>
                <div class="st-welcome__button-canvas-intel">
                  <div data-aos="fade-right">
                    <st-button
                      class="st-welcome__button-set st-button__set st-welcome__button-canvas-building-intel st-welcome__button-secondary-intel"
                      :variant="['secondary']"
                      caption="TRY OPINIONGRAPHS"
                      icon-name="pointer"
                      :show-action="true"
                      :underline="false"
                      url="/prospective/begin"
                    />
                  </div>
                  <div data-aos="fade-up">
                    <st-button
                      class="st-welcome__button-set st-button__set"
                      caption="SCHEDULE A DEMO"
                      icon-name="calendar"
                      :show-action="true"
                      :underline="false"
                      @click="scrollIntoView"
                    />
                  </div>
                </div>
              </div>
              <div class="bx--col-xs-12 bx--col-lg-6">
                <div class="st-welcome__building-component">
                  <div class="wistia_embed wistia_async_zijkeqxn8n videoFoam=true" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="spacetricsSteps"
        class="st-welcome__steps-component"
      >
        <div class="bx--grid">
          <div class="bx--row">
            <div class="bx--col-xs-12 bx--col-lg-10 bx--col-xl-8 st-welcome__steps-heading-col">
              <h1
                class="page-header st-welcome__main-heading st-welcome__steps-heading"
                data-aos="fade-down"
              >
                In 3 steps determine what people think about your space.
              </h1>
            </div>
          </div>
          <div class="st-welcome__steps-container">
            <div class="st-welcome__steps-content">
              <div class="st-welcome__steps-block st-welcome__steps-block-survey">
                <div class="st-welcome__steps-head">
                  <div class="st-welcome__steps-head-img st-welcome__steps-head-img-survey" />
                  <div class="st-welcome__steps-head-detail">
                    <h4
                      class="st-welcome__medium-heading"
                      data-aos="fade-right"
                    >
                      1. Immersive survey CREATION
                    </h4>
                    <p
                      class="st-welcome__paragraph st-welcome__step-paragraph"
                      data-aos="fade-right"
                    >
                      Set up unique surveys with 3D virtual tours and different media that showcases your
                      spaces and design attributes.
                    </p>
                  </div>
                </div>
                <div class="st-welcome__steps-detail">
                  <div class="st-welcome__steps-desktop">
                    <div class="wistia_embed wistia_async_g2jus9693f videoFoam=true" />
                  </div>
                  <div class="st-welcome__steps-mobile st-welcome__steps-mobile-survey" />
                </div>
              </div>
              <div class="st-welcome__survey">
                <div class="st-welcome__survey-send st-welcome__dotted-line">
                  <div
                    class="st-welcome__survey-send-box"
                    data-aos="fade-down"
                  >
                    <icon-wrapper
                      icon-name="paper-plane"
                      class="st-welcome__survey-send-icon"
                    />
                    <h5 class="st-welcome__subheading st-welcome__survey-send-heading">
                      SEND SURVEYS to a selected audience
                    </h5>
                  </div>
                </div>
                <div class="st-welcome__survey-via">
                  <div class="st-welcome__survey-via-canvas">
                    <div class="st-welcome__survey-via-col st-welcome__dotted-line">
                      <div
                        class="st-welcome__survey-via-box"
                        data-aos="fade-down"
                      >
                        <div class="st-welcome__survey-via-img st-welcome__survey-via-img-link" />
                        <h4 class="st-welcome__subheading st-welcome__via-subheading">
                          VIA Shared LINK
                        </h4>
                      </div>
                    </div>
                    <div class="st-welcome__survey-via-col st-welcome__dotted-line">
                      <div
                        class="st-welcome__survey-via-box"
                        data-aos="fade-down"
                      >
                        <div class="st-welcome__survey-via-img st-welcome__survey-via-img-mail" />
                        <h4 class="st-welcome__subheading st-welcome__via-subheading">
                          VIA EMAIL
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="st-welcome__steps-container">
                  <div class="st-welcome__survey-people-canvas st-welcome__dotted-line">
                    <div class="st-welcome__survey-people-inner">
                      <div class="st-welcome__survey-people-group st-welcome__survey-people-group-first">
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-1"
                          data-aos="fade-down"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-1" />
                        </div>
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-2"
                          data-aos="fade-right"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-2" />
                        </div>
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-3"
                          data-aos="fade-up"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-3" />
                        </div>
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-4"
                          data-aos="fade-up"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-4" />
                        </div>
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-5"
                          data-aos="fade-down"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-5" />
                        </div>
                      </div>
                      <div class="st-welcome__survey-people-group st-welcome__survey-people-group-second">
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-6"
                          data-aos="fade-right"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-6" />
                        </div>
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-7"
                          data-aos="fade-up"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-7" />
                        </div>
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-8"
                          data-aos="fade-up"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-8" />
                        </div>
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-9"
                          data-aos="fade-down"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-9" />
                        </div>
                        <div
                          class="st-welcome__survey-people st-welcome__survey-people-10"
                          data-aos="fade-right"
                        >
                          <div class="st-welcome__survey-people-img st-welcome__survey-people-img-10" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="st-welcome__steps-block st-welcome__steps-block-feedback">
                <div class="st-welcome__steps-head">
                  <div class="st-welcome__steps-head-img st-welcome__steps-head-img-feedback" />
                  <div class="st-welcome__steps-head-detail">
                    <h4
                      class="st-welcome__medium-heading"
                      data-aos="fade-down"
                    >
                      2. Open ended feedback
                    </h4>
                    <p
                      class="st-welcome__paragraph st-welcome__step-paragraph"
                      data-aos="fade-up"
                    >
                      After your audience receives a survey they can openly share their thoughts about
                      different type of media. Photos of renders, video walkthroughs or 3d virtual spaces.
                    </p>
                  </div>
                </div>
                <div class="st-welcome__steps-detail">
                  <div class="st-welcome__steps-desktop">
                    <div class="wistia_embed wistia_async_7qj1x8a4y5 videoFoam=true" />
                  </div>
                  <div class="st-welcome__steps-mobile st-welcome__steps-mobile-feedback" />
                </div>
              </div>
              <div class="st-welcome__survey-send st-welcome__survey-feedback st-welcome__dotted-line">
                <div
                  class="st-welcome__survey-send-box"
                  data-aos="fade-down"
                >
                  <div class="st-welcome__survey-send-icon st-welcome__survey-send-img" />
                  <h5 class="st-welcome__subheading st-welcome__survey-send-heading">
                    feedback is analyzed for you to understand what matters most
                  </h5>
                </div>
              </div>
              <div class="st-welcome__survey-people-canvas st-welcome__survey-people-feedback  st-welcome__dotted-line">
                <div class="st-welcome__survey-people-inner">
                  <div class="st-welcome__survey-people-group st-welcome__survey-people-group-first">
                    <div class="st-welcome__survey-people st-welcome__survey-people-1">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-1" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-1"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-1">
                          Very Positive
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-1">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-1">
                            Love it!
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="st-welcome__survey-people st-welcome__survey-people-2">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-2" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-2"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-2">
                          Positive
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-2">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-2">
                            I really like this office
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="st-welcome__survey-people st-welcome__survey-people-3">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-3" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-3"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-3">
                          very positive
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-3">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-3">
                            Really love this space
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="st-welcome__survey-people st-welcome__survey-people-4">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-4" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-4"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-4">
                          NEUTRAL
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-4">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-4">
                            Looks interesting
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="st-welcome__survey-people st-welcome__survey-people-5">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-5" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-5"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-5">
                          very negative
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-5">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-5">
                            I don’t like this space
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="st-welcome__survey-people-group st-welcome__survey-people-group-second">
                    <div class="st-welcome__survey-people st-welcome__survey-people-6">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-6" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-6"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-6">
                          negative
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-6">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-6">
                            I am not a fan
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="st-welcome__survey-people st-welcome__survey-people-7">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-7" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-7"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-7">
                          very negative
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-7">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-7">
                            I hate it
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="st-welcome__survey-people st-welcome__survey-people-8">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-8" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-8"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-8">
                          neutral
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-8">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-8">
                            Not sure
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="st-welcome__survey-people st-welcome__survey-people-9">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-9" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-9"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-9">
                          positive
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-9">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-9">
                            Very nice
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="st-welcome__survey-people st-welcome__survey-people-10">
                      <div class="st-welcome__survey-people-img st-welcome__survey-people-img-10" />
                      <div
                        class="st-welcome__survey-overlay st-welcome__survey-overlay-10"
                        data-aos="fade-up"
                      >
                        <p class="st-welcome__survey-overlay-status st-welcome__survey-overlay-status-10">
                          very positive
                        </p>
                        <div class="st-welcome__survey-overlay-box st-welcome__survey-overlay-box-10">
                          <div class="st-welcome__survey-comment st-welcome__survey-comment-10">
                            Wow!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="st-welcome__steps-block st-welcome__steps-block-insights">
                <div class="st-welcome__steps-head">
                  <div class="st-welcome__steps-head-img st-welcome__steps-head-img-insights" />
                  <div class="st-welcome__steps-head-detail">
                    <h4
                      class="st-welcome__medium-heading"
                      data-aos="fade-down"
                    >
                      3. Powerful Insights
                    </h4>
                    <p
                      class="st-welcome__paragraph st-welcome__step-paragraph"
                      data-aos="fade-up"
                    >
                      Respondents answers are analyzed with our AI-based algorithm that breaks sentiment
                      into key data-points.
                    </p>
                  </div>
                </div>
                <div class="st-welcome__steps-detail">
                  <div class="st-welcome__steps-desktop">
                    <div class="wistia_embed wistia_async_lzo8xzj3eg videoFoam=true" />
                  </div>
                  <div class="st-welcome__steps-mobile st-welcome__steps-mobile-insights" />
                </div>
              </div>
              <div class="st-welcome__button-canvas-intel st-welcome__button-canvas-steps">
                <div data-aos="fade-right">
                  <st-button
                    class="st-button__set st-welcome__button-set st-welcome__button-secondary-intel"
                    :variant="['secondary']"
                    caption="TRY OPINIONGRAPHS"
                    icon-name="pointer"
                    :show-action="true"
                    :underline="false"
                    url="/prospective/begin"
                  />
                </div>
                <div data-aos="fade-up">
                  <st-button
                    class="st-button__set st-welcome__button-bg-transparent st-welcome__button-set"
                    caption="SCHEDULE A DEMO"
                    icon-name="calendar"
                    :show-action="true"
                    :underline="false"
                    @click="scrollIntoView"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="st-welcome__testimonial">
        <div class="st-welcome__steps-container">
          <div class="bx--grid">
            <div class="bx--row st-welcome__testimonial-row">
              <div class="bx--col-xs-12 bx--col-xl-11">
                <div class="st-welcome__testimonial-component">
                  <div class="st-welcome__testimonial-slides">
                    <span class="st-welcome__testimonial-dots active st-welcome__testimonial-dots-1" />
                    <span class="st-welcome__testimonial-dots st-welcome__testimonial-dots-2" />
                  </div>
                  <div
                    class="st-welcome__testimonial-person-box"
                    data-aos="fade-right"
                  >
                    <div class="st-welcome__testimonial-person" />
                  </div>
                  <div class="st-welcome__testimonial-detail">
                    <p
                      class="st-welcome__testimonial-paragraph"
                      data-aos="fade-down"
                    >
                      When working with architects, planners, and marketers we always prioritize an
                      understanding of our customers’ tastes and preferences. The ability to put
                      quantitative insight into the hands of our developers, marketers, and their
                      partners is a game changer for us.
                    </p>
                    <div
                      class="st-welcome__testimonial-info"
                      data-aos="fade-up"
                    >
                      <span class="st-welcome__testimonial-name">
                        -Evan Regan-Levine
                      </span>
                      <span class="st-welcome__testimonial-designation">
                        JBG Smith, Executive Vice President
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="st-welcome__combine-component">
        <div class="bx--grid">
          <div class="st-welcome__marketing">
            <div class="bx--row">
              <div class="bx--col-xs-12 bx--col-xl-6">
                <h5 class="st-welcome__subheading st-welcome__subheading-white">
                  Find out how else we can help you
                </h5>
                <h1 class="st-welcome__combine-heading st-welcome__combine-heading-marketing">
                  We have also built other tools to complement your marketing needs.
                </h1>
              </div>
            </div>
            <div class="bx--row">
              <div
                class="bx--col-xs-12 bx--col-md-6 st-welcome__marketing-col"
                data-aos="fade-up"
              >
                <a
                  class="st-welcome__marketing-box"
                  href="https://www.officetour.com/"
                  target="_blank"
                >
                  <div class="st-welcome__marketing-img-officetour" />
                  <div class="st-welcome__marketing-canvas">
                    <p class="st-welcome__paragraph st-welcome__paragraph-marketing">
                      Create immersive, easy to share, and trackable presentations for your commercial real estate
                      prospects, based on your available listings.
                    </p>
                    <icon-wrapper
                      icon-name="arrow"
                      class="st-welcome__marketing-arrow"
                    />
                  </div>
                </a>
              </div>
              <div
                class="bx--col-xs-12 bx--col-md-6 st-welcome__marketing-col"
                data-aos="fade-up"
              >
                <a
                  class="st-welcome__marketing-box"
                  href="https://hometour.com/"
                  target="_blank"
                >
                  <div class="st-welcome__marketing-img-hometour" />
                  <div class="st-welcome__marketing-canvas">
                    <p class="st-welcome__paragraph st-welcome__paragraph-marketing">
                      Create immersive, easy to share, and trackable presentations for your commercial real estate
                      prospects, based on your available listings.
                    </p>
                    <icon-wrapper
                      icon-name="arrow"
                      class="st-welcome__marketing-arrow"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            id="contactForm"
            ref="contactForm"
            class="st-welcome__help"
          >
            <div class="bx--row">
              <div class="bx--col-xs-12 bx--col-lg-6 bx--col-xl-5">
                <h1 class="st-welcome__combine-heading st-welcome__combine-heading-help">
                  See how AskHumans can help you understand what people think about your real estate spaces.
                </h1>
                <h5 class="st-welcome__subheading st-welcome__subheading-lightgrey">
                  Fill in the form and we will be in touch shortly to give you a demo of the platform.
                </h5>
              </div>
              <div class="bx--col-xs-12 bx--col-lg-6 bx--offset-xl-1 bx--col-xl-5">
                <form
                  class="st-welcome__help-group"
                  action="https://formspree.io/f/zak@dupontstudios.com"
                  method="post"
                >
                  <input
                    id="fullName"
                    class="st-welcome__help-input"
                    name="Full Name"
                    placeholder="FULL NAME"
                    type="name"
                  >
                  <input
                    id="companyName"
                    class="st-welcome__help-input"
                    name="Company Name"
                    placeholder="COMPANY NAME"
                    type="name"
                  >
                  <input
                    id="email"
                    class="st-welcome__help-input"
                    name="Email"
                    placeholder="EMAIL"
                    type="email"
                  >
                  <input
                    id="phoneNumber"
                    class="st-welcome__help-input"
                    name="Phone Number"
                    placeholder="PHONE NUMBER"
                    type="tel"
                  >
                  <button
                    class="st-welcome__button-submit"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <footer class="st-footer">
            <a
              href="/"
              class="st-footer__logo"
            >
              <icon-wrapper
                icon-name="spacetrics"
                class="st-footer__logo-icon"
              />
              <span class="st-footer__logo-text">AskHumans</span>
            </a>
            <div class="st-footer__content">
              <div class="st-footer__list">
                <a
                  class="st-footer__link"
                  href="/terms"
                  target="_blank"
                >terms & conditions</a>
                <a
                  class="st-footer__link"
                  href="/privacy"
                  target="_blank"
                >privacy policy</a>
              </div>
              <p class="st-footer__copyright">
                ©2021 AskHumans
              </p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { IconWrapper, StButton } from '../common'

/* eslint-disable */
const MS_PER_CHAR = 50                    // how long between "type" each character
const MS_AFTER_ADDING_CHARS = 1100        // how long to wait after typing before highlighting
const MS_PER_HIGHLIGHT_CHAR = 20          // how long between highlight each character
const MS_AFTER_HIGHLIGHTING_CHARS = 1400  // time after highlight before clearing
const MS_BEFORE_NEXT_SLIDE = 1200         // time after clearing before starting next slide
const MS_FADE = 400                       // fade transition time in css (MUST match) 
/* eslint-enable */

const SLIDES = [
  {
    question: 'What do my engineers think about our office space compared to our executive team?',
    name: 'Monique',
    role: 'Workplace Strategist'
  },
  {
    question: 'What do my hotel guests think of the new bathroom renovations?',
    name: 'Mark',
    role: 'Boutique Hotel Operator'
  },
  {
    question: 'What does the client team think about these design concepts?',
    name: 'Laura',
    role: 'VP of Design Innovation'
  },
  {
    question: 'What unit mix should we consider for our downtown development?',
    name: 'Ethan',
    role: 'Head of Strategy & Design'
  }
]

export default {
  components: {
    IconWrapper,
    StButton
  },
  prop: {
  },
  data () {
    return {
      activeSlideIdx: 0,
      questionDisplay: SLIDES[0].question,
      highlightEndIdx: 0,
      isSlideFaded: false,
      aosInterval: null,
      contactForm: {
        companyName: '',
        email: '',
        name: '',
        phoneNumber: ''
      },
      wistiaVideo: false
    }
  },
  computed: {
    activeSlide () {
      return SLIDES[this.activeSlideIdx]
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
    this.refreshAosOnWistiaLoad()
    // this.typeQuestionText()
    window.setTimeout(() => {
      // note this only works as long as the 1st prompt is longest
      this.$refs.question.style.minHeight = this.$refs.question.offsetHeight + 'px'
      this.startWipe()
    }, MS_AFTER_ADDING_CHARS)
    // TODO add resize observer
    // would need to reset text to longest question and reset height
    // only when width is smaller
  },
  methods: {
    refreshAosOnWistiaLoad () {
      const wq = window._wq || []
      const reload = () => { window.setTimeout(() => { this.$root.refreshAOS() }, 1000) }
      [
        'zijkeqxn8n',
        '7qj1x8a4y5',
        'lzo8xzj3eg',
        'g2jus9693f'
      ].forEach(id => {
        wq.push({ id: id, onReady: reload })
      })
      reload()
    },
    scrollIntoView () {
      this.$root.scrollIntoView({ element: this.$refs.contactForm, block: 'center' })
    },
    submitContactForm () {

    },
    resetSlide () {
      this.questionDisplay = ''
      this.highlightEndIdx = 0
      this.isSlideFaded = false
    },
    typeQuestionText () {
      this.resetSlide()
      const fullText = this.activeSlide.question
      let nextCharIdx = 0

      const interval = window.setInterval(() => {
        this.questionDisplay += fullText[nextCharIdx++]
        if (nextCharIdx >= fullText.length) {
          window.clearInterval(interval)
          window.setTimeout(() => { this.startWipe() }, MS_AFTER_ADDING_CHARS)
        }
      }, MS_PER_CHAR)
    },
    startWipe () {
      const interval = window.setInterval(() => {
        this.highlightEndIdx++
        if (this.highlightEndIdx >= this.questionDisplay.length) {
          window.clearInterval(interval)
          window.setTimeout(() => { this.fadeSlide() }, MS_AFTER_HIGHLIGHTING_CHARS - MS_FADE)
        }
      }, MS_PER_HIGHLIGHT_CHAR)
    },
    fadeSlide () {
      this.isSlideFaded = true
      window.setTimeout(() => {
        this.advanceSlide()
      }, MS_FADE + 10)
    },
    advanceSlide () {
      this.highlightEndIdx = 0
      this.questionDisplay = '|'
      this.activeSlideIdx = (this.activeSlideIdx + 1) % SLIDES.length
      window.setTimeout(() => {
        this.typeQuestionText()
      }, MS_BEFORE_NEXT_SLIDE)
    }
  }
}
</script>
