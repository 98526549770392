<template>
  <div class="tw-max-w-[520px] tw-mx-auto h-full tw-flex tw-flex-col fade-in">
    <div class="flex tw-justify-center tw-flex-col tw-w-full tw-max-h-full  ">
      <div class="tw-flex tw-flex-col tw-items-center ">
        <img
          :src="wizardIcon"
          alt=""
        >
        <span class="tw-text-2xl tw-font-semibold ">
          Wizard
        </span>
      </div>
      <p class="tw-text-black-two tw-my-4">
        Here is a preview of suggested questions for your study. You can modify these questions according to your need.
      </p>
    </div>
    <div class="tw-border-[3px] tw-border-solid tw-overflow-hidden tw-border-territory-dark  tw-rounded-xl tw-mt-3">
      <div class="tw-bg-light-four tw-p-5">
        <span class="tw-font-semibold">
          {{ survey.name }}
        </span>
      </div>
      <div class="tw-p-5">
        <p class="">
          The following shows for suggested questions to help you create a study.  You can go back to previous step and change the information to get different suggestions or continue to the next step where you’ll be able to modify specific questions, add incentives and set-up a welcome page.
        </p>
      </div>

      <div class="tw-border-t tw-py-5 tw-border-solid tw-border-white-two">
        <div class="tw-px-5 ">
          <p class="tw-text-sm tw-text-black-three tw-my-1">
            Question
          </p>
          <div class="tw-flex tw-space-x-3 tw-items-center">
            <img
              :src="questionImage"
              class="tw-rounded-xl"
              alt=""
            >
            <p>
              {{ questions[0] }}
            </p>
          </div>
        </div>
        <div class="tw-px-5 tw-py-1">
          <p class="tw-text-sm tw-text-black-three tw-my-1">
            Question
          </p>
          <div class="tw-flex tw-space-x-3 tw-items-center">
            <img
              :src="questionImage"
              class="tw-rounded-xl"
              alt=""
            >
            <p>
              {{ questions[1] }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="tw-mt-4"
    >
      <ah-button
        type="outlined"
        size="large"
        @click="showGuidanceModalHandler"
      >
        Add additional guidance
      </ah-button>
    </div> -->

    <div class="tw-w-full tw-flex tw-justify-between tw-mt-6">
      <ah-button
        type="outlined"
        size="large"
        @click="goBack"
      >
        Go back
      </ah-button>
      <ah-button
        type="primary"
        size="large"
        @click="addQuestionsAndRedirect"
      >
        Start building
      </ah-button>
    </div>
    <st-modal v-model="showGuidanceModal">
      <template v-slot:modalTitle>
        <span class="font-18p font-bold">
          Add additional guidance
        </span>
      </template>
      <template v-slot:modalActions>
        <div class="tw-mt-3">
          <p>
            Provide more guidance to regenerate this template as you need.
          </p>
          <div class="tw-mt-3">
            <ah-input
              ref="additionalGuidance"
              placeholder="Write here"
            />
          </div>
        </div>
        <div class="flex space-x-2 justify-end ">
          <ah-button
            type="outlined"
            @click="showGuidanceModal = false"
          >
            Cancel
          </ah-button>
          <ah-button
            class="bg-blue-primary p-3   rounded-4p  text-white"
          >
            Start building
          </ah-button>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { AhInput, AhButton, StModal } from '../../common'
import wizardIcon from '../../../../assets/images/wizard_icon.png'
import defaultImage from '../../../../assets/images/default-study-image.png'

export default {
  components: {
    AhInput,
    AhButton,
    StModal
  },
  props: {
    survey: {
      type: Object,
      required: true
    },
    questions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showGuidanceModal: false,
      organizationInfo: '',
      primaryTopic: '',
      themesInterested: '',
      showAdditionalGuidance: false,
      additionalGuidance: null,
      wizardIcon: wizardIcon,
      questionImage: defaultImage
    }
  },
  computed: {
    ...mapState(['currentUser'])
  },
  mounted () {

    // setTimeout(() => {
    //   console.log(JSON.stringify(this.currentUser))
    // }, 100)
  },

  methods: {
    showGuidanceModalHandler () {
      this.showGuidanceModal = true
      setTimeout(() => {
        this.$refs.additionalGuidance.focus()
      }, 100)
    },
    hideAdditionalGuidance () {
      this.showGuidanceModal = false
    },

    // emit back handler
    goBack () {
      this.$emit('back')
    },
    addQuestionsAndRedirect () {
      this.$store.commit('toggleFullSpinner', true)
      let type = 'study'
      let url = `/${type}/` + this.survey.id + '/questions'

      // Create an array to hold the promises for adding questions
      let questionPromises = []

      // Add the first question to the array
      questionPromises.push(
        this.$axios.request({
          url: url,
          method: 'post',
          data: {
            question: {
              container_id: this.survey.id,
              container_type: 'Survey',
              question_type: 'opinion_metric',
              priority: 1,
              description: this.questions[0],
              ask_for_explanation: 3
            }
          }
        })
      )

      // Add the second question to the array (modify as needed)
      questionPromises.push(
        this.$axios.request({
          url: url,
          method: 'post',
          data: {
            question: {
              container_id: this.survey.id,
              container_type: 'Survey',
              question_type: 'opinion_metric',
              priority: 2, // Change the priority as needed
              description: this.questions[1],
              ask_for_explanation: 3
            }
          }
        })
      )

      // Use Promise.all() to wait for all promises to resolve
      Promise.all(questionPromises)
        .then((responses) => {
          this.$store.commit('toggleFullSpinner', false)
          // console.log('responses', responses)
          window.location.href = `/study/${this.survey.id}/edit/landing_page`

          // All questions were added successfully
          // Now you can redirect the user to a new page
          // this.$router.push('/new-page') // Modify the URL as needed
        })
        .catch((error) => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error', error)
        })
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
