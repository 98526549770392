<template>
  <div>
    <div class="st-prospectives--before-begin-space st-prospectives__dots">
      <div class="bx--grid st-prospectives__before-begin-grid">
        <div class="bx--row">
          <div class="bx--col-xs-12 bx--col-lg-10 bx--offset-lg-1 bx--col-xl-6 bx--offset-xl-3">
            <h1 class="st-prospectives__heading st-prospectives__before-begin-heading">
              Before you begin
            </h1>
            <div class="st-prospectives__formGr">
              <st-input
                v-model.trim="fullName"
                class="st-input--default st-prospectives__input"
                label="Full Name"
                placeholder="Full Name"
                :show-label="false"
                @blur="fullName = $event.target.value"
              />
              <st-input
                v-model.trim="email"
                class="st-input--default st-prospectives__input"
                label="Email"
                placeholder="Email"
                :show-label="false"
              />
              <div class="st-prospectives__button-canvas">
                <st-button
                  class="st-prospectives__button st-prospectives__button--begin st-button--secondary"
                  caption="Begin"
                  :show-action="true"
                  :underline="false"
                  @click="submitInfo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StButton, StInput } from '../../common'

export default {
  components: {
    StButton,
    StInput
  },
  props: {
    demoSurvey: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data: function () {
    return {
      email: '',
      fullName: ''
    }
  },
  computed: {
    splitName () {
      return this.fullName.split(' ')
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    submitInfo () {
      this.createParticipant()
        .then(res => { this.updateCreatedParticipant() })
        .then(() => {
          window.location.href = '/prospective/how_it_works'
        })
    },
    createParticipant () {
      return this.$axios.request({
        method: 'post',
        url: `/study/${this.demoSurvey.id}/response`,
        data: {
          participant: { email: this.email },
          skipSegments: true,
          survey: this.demoSurvey
        }
      })
    },
    updateCreatedParticipant () {
      return this.$axios.request({
        method: 'post',
        url: `/study/${this.demoSurvey.id}/response`,
        data: {
          participant: {
            email: this.email,
            first_name: this.splitName[0],
            last_name: this.splitName[this.splitName.length - 1]
          }
        }
      })
    }
  }
}
</script>
