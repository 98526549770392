<template>
  <span
    class="tw-px-3 tw-py-2 tw-pointer-events-none tw-cursor-text tw-text-sm tw-rounded-lg "
    :class="{ 'tw-bg-success tw-text-white': type === studyTypes[0], 'tw-bg-white-two ': type == studyTypes[1], 'tw-bg-black-two tw-text-white': type == studyTypes[3] }"
  >
    <slot />
  </span>
</template>

<script>

export default {
  components: {
  },
  props: {

    type: {
      type: String,
      required: false,
      default: 'active'
    }

  },
  data: function () {
    return {
      studyTypes: [
        'active',
        'draft',
        'completed',
        'close',
        'archived'
      ]
    }
  },
  computed: {
  }
}
</script>
