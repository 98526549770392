<template>
  <div
    class="tw-flex tw-flex-col  tw-justify-between tw-flex-1  tw-overflow-y-auto"
  >
    <random-people :text="text" />
    <recording-feedback
      :version="version"
      :save-audio-feedback="saveAudioResponse"
      :text-response-submitted="saveTextResponse"
    />
  </div>
</template>

<script>
import {
  AhButton
} from '../../common'
import RecordingFeedback from './recording-feedback.vue'
import RandomPeople from './random-people.vue'
export default {
  name: 'AskhumansSharingColleagueView',

  components: {
    AhButton,
    RandomPeople,
    RecordingFeedback
  },

  props: {
    text: {
      type: String,
      default: ''
    },
    shareHandler: {
      type: Function,
      default: () => {}
    },
    version: {
      type: Object,
      required: true
    }
  },

  data () {
    return {

    }
  },

  mounted () {
    // console.log('mounted', this.version)
  },

  methods: {
    saveAudioResponse (response) {
      this.$emit('feedback-given')
      // console.log('saveAudioResponse', response)
    },
    saveTextResponse (response) {
      this.$emit('feedback-given')
      // console.log('saveTextResponse', response)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
