<template>
  <div class="st-image-board__mobile-body st-image-board__image-body-participant">
    <p
      ref="question"
      class="st-participant__question-container-para"
    >
      {{ description }}
    </p>
    <div
      class="bx--grid st-image-board__mobile-body-grid"
    >
      <div class="bx--row st-image-board__mobile-body-row">
        <div
          v-for="option in options"
          :key="option.id"
          class="bx--col-xs-4"
        >
          <span
            @click="selectBoard(option)"
          >
            <st-lazy-image
              :src="option.mediaUrl"
              background="#eee"
              :custom-class="selectedBoard && option.id === selectedBoard.id ? 'st-image-board__img st-image-board__img--active': 'st-image-board__img' "
            />
          </span>
        </div>
      </div>
      <div v-if="showExplanationInput && askForExplanation === 'text'">
        <st-text-area
          v-model="writtenResponse"
          :class="{
            'st-image-board__text-area': true
          }"
          :button="textAreaFocused"
          label="Share your thoughts here"
          placeholder="Type your response"
          @focus="focusTextArea"
          @focusout="focusOutTextArea"
        />
        <st-tooltip
          v-if="nextButtonTextArea"
          class="st-opinion-metric__button-tooltip"
          tip="Preview only button"
          :is-active="isSpecificationsPreview"
        >
          <st-button
            class="st-opinion-metric__button st-opinion-metric__next-question-button"
            :caption="buttonCaption"
            :disabled="!writtenResponse.length"
            icon-name="arrow"
            :underline="false"
            :variant="['secondary']"
            @click="submitQuestionAnswer"
          />
        </st-tooltip>
      </div>
      <div
        v-else-if="
          showExplanationInput && askForExplanation === 'voice_recording'
        "
      >
        <st-button
          v-if="isRecordButton"
          :class="{
            'st-opinion-metric__button st-opinion-metric__record-thoughts-button': true,
          }"
          caption="Record My Thoughts"
          icon-name="microphone"
          :underline="false"
          :variant="['secondary']"
          @click="initiateRecording"
        />
        <div
          v-else-if="isStopButton"
          :class="{
            'st-opinion-metric__stop-button-and-visualizer': true,
          }"
        >
          <button
            type="button"
            class="st-opinion-metric__audio-button-container"
            @click="stopRecording"
          >
            <div class="st-opinion-metric__stop-square" />
          </button>
          <canvas
            v-if="isVisualizerVisible"
            ref="audioCanvas"
            :width="canvasWidth"
            :height="canvasHeight"
            :class="{
              'st-opinion-metric__audio-visualizer': true,
              'st-opinion-metric__audio-visualizer--preview': isSpecsDesktopPreview,
            }"
          />
        </div>
        <audio
          v-if="isAudioPlayer"
          ref="audioPlayer"
          class="st-opinion-metric__audio-player"
          controls
        >
          Your browser does not support the <code>audio</code> element.
        </audio>
        <div
          v-if="readyToSubmit"
          :class="{
            'st-opinion-metric__ready-to-submit': true,
            'st-opinion-metric__record-thoughts--multi-media': hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
            'st-opinion-metric__record-thoughts--multi-media-no-description': hasMultipleMedia && isDesktopSurvey && !description,
            'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview
          }"
        >
          <button
            v-if="readyToSubmit && !replayAudio"
            type="button"
            class="st-opinion-metric__audio-button-container"
            @click="playAgain"
          >
            <icon-wrapper
              class="st-opinion-metric__play-button"
              icon-name="play-button"
              :spacetrics-blue="true"
            />
          </button>
          <button
            v-if="readyToSubmit && replayAudio"
            type="button"
            class="st-opinion-metric__audio-button-container"
            @click="stopReplay"
          >
            <div class="st-opinion-metric__pause-bars">
              &#9612;
            </div>
            <div class="st-opinion-metric__pause-bars st-opinion-metric__pause-bars--right">
              &#9612;
            </div>
          </button>
          <p
            v-if="readyToSubmit"
            class="st-opinion-metric__audio-duration"
          >
            {{ formatAudioDuration }}
          </p>
         
          <div
            v-if="readyToSubmit"
            class="st-opinion-metric__trash-icon-container"
            @click="rerecord"
          >
            <icon-wrapper
              class="st-opinion-metric__trash-icon"
              icon-name="trash"
            />
          </div>
          <st-tooltip
            v-if="readyToSubmit"
            class="st-opinion-metric__button-tooltip"
            tip="Preview only button"
            :is-active="isSpecificationsPreview"
          >
            <st-button
              class="st-opinion-metric__button"
              :caption="buttonCaption"
              icon-name="arrow"
              :underline="false"
              :variant="['secondary']"
              @click="submitQuestionAnswer"
            />
          </st-tooltip>
        </div>
      </div>
      <div
        v-else
        class="st-image-board__btn-canvas"
      >
        <st-button
          :disabled="selectedBoard === null"
          class="st-image-board__btn st-button--small"
          :caption="buttonCaption"
          :underline="false"
          icon-name="arrow"
          :variant="['secondary']"
          @click="submitQuestionAnswer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatSecondsToStopwatch } from '../../../../utilities/data_utilities'
import { getBlobDuration } from '../../../../utilities/audio_utilities'
import {
  audioRecorder,
  handlePreviewBack,
  isDesktop,
  previousResponse,
  submitQuestionAnswer
} from '../../../../mixins'
import {
  IconWrapper,
  StButton,
  StTextArea,
  StTooltip,
  StLazyImage
} from '../../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StTextArea,
    StTooltip,
    StLazyImage
  },
  mixins: [
    audioRecorder,
    handlePreviewBack,
    isDesktop,
    previousResponse,
    submitQuestionAnswer
  ],
  props: {
    askForExplanation: {
      type: String,
      required: false,
      default: ''
    },
    autoStopTime: {
      type: Number,
      default: 900000, // ms = 15 min
      required: false
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    media: {
      type: Object,
      required: false,
      default: () => ({})
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    priority: {
      type: Number,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonCaption: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      audioBlob: null,
      audioDuration: null,
      errorMessage: '',
      imageWidth: 0,
      lightboxImageSrc: '',
      mobileWindowScrollDistance: 0,
      multiMediaImagesScrollDistance: 0,
      isStickyQuestionActive: false,
      overflowDescription: false,
      readyToSubmit: false, // Change this later
      replayAudio: false,
      resizeObserver: null,
      showExpanedPhoto: false,
      surveyWrapperScrollDistance: 0,
      textAreaFocused: false,
      writtenResponse: '',
      zoomLevel: 100,
      showExplanationInput: false,
      selectedBoard: null
    }
  },
  computed: {
    ...mapState('participants', {
      participantActiveQuestion: 'activeQuestion'
    }),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    activeQuestion () {
      return this.isSpecsDesktopPreview
        ? this.specsActiveQuestion
        : this.participantActiveQuestion
    },
    contentType () {
      if (!this.hasSingleMedia) {
        return ''
      }
      if (!this.media.hasOwnProperty('contentType')) {
        return ''
      }
      return this.media.contentType
    },
    errorTooltipWidth () {
      return this.isDesktop ? 411 : window.innerWidth - 40
    },
    formatAudioDuration () {
      return formatSecondsToStopwatch(this.audioDuration)
    },
    hasResponseType () {
      return this.askForExplanation
    },
    isAudioPlayer () {
      return this.audioBlob !== null
    },
    isExpandPhotoIcon () {
      return this.isDesktop
        ? !this.isSpecificationsPreview
        : !this.isSpecificationsPreview && !this.textAreaFocused
    },
    isDesktopSurvey () {
      return this.isDesktop && !this.isPreview
    },
    isDesktopSurveyPreview () {
      return this.isDesktop && this.isSurveyPreview
    },
    isMultiMediaDesktopSurveyPreview () {
      return this.hasMultipleMedia && this.isDesktopSurveyPreview
    },
    isResponseTypeText () {
      return this.askForExplanation === 'text'
    },
    isRecordButton () {
      if (this.mediaRecorder === null) {
        return false
      }
      if (this.isRecording) {
        return false
      }
      if (this.audioBlob !== null) {
        return false
      }
      return true
    },
    isSpecificationsPreview () {
      return this.isPreview && !this.isSurveyPreview
    },
    isSpecsDesktopPreview () {
      return this.isSpecificationsPreview && this.isDesktop
    },
    isStopButton () {
      return this.isRecording
    },
    isSubmissionSkippable () {
      return (
        this.questionResponses &&
        this.audioBlob === this.questionResponses[0].srcUrl
      )
    },
    nextButtonTextArea () {
      return this.writtenResponse.length && !this.textAreaFocused
    }
  },
  watch: {
    askForExplanation (newValue, oldValue) {
      if (newValue === 'voice_recording') {
        this.tryInitializingMediaRecorder()
          .then(() => {})
          .catch(() => {})
      }
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  beforeMount () {
    if (this.askForExplanation === 'voice_recording') {
      this.tryInitializingMediaRecorder()
        .then(() => {})
        .catch(() => {})
    }
  },
  methods: {
    selectBoard (option) {
      this.selectedBoard = option
      this.showExplanationInput = true
    },
    answerData () {
      let answer = new FormData()
      answer.append('response[value]', this.selectedBoard.id)
      if (this.askForExplanation !== 'no_explanation') {
        answer.append(
          ...(this.isResponseTypeText
            ? ['response[transcript]', this.writtenResponse]
            : ['response[audio_response]', this.audioBlob])
        )
      }
      return answer
    },
    focusOutTextArea () {
      this.textAreaFocused = false
    },
    focusTextArea () {
      this.textAreaFocused = true
    },
    loadResponses () {
      if (
        !this.isResponseTypeText &&
        this.questionResponses &&
        this.questionResponses.length > 0
      ) {
        this.audioBlob = this.questionResponses[0].srcUrl
        this.$nextTick(() => {
          this.$refs.audioPlayer.src = this.questionResponses[0].srcUrl
          this.$refs.audioPlayer.load()
          this.readyToSubmit = true
        })
      }
    },
    onRecordingAvailable (blob) {
      this.audioBlob = blob
      this.readyToSubmit = true
      getBlobDuration(this.audioBlob).then((duration) => {
        this.audioDuration = duration
      })

      this.$nextTick(() => {
        this.setAudioSrc()
      })
    },
    playAgain () {
      this.$refs.audioPlayer.play()
      this.$refs.audioPlayer.ontimeupdate = () => {
        this.audioDuration = this.$refs.audioPlayer.currentTime
        if (
          this.$refs.audioPlayer.duration === this.$refs.audioPlayer.currentTime
        ) {
          this.replayAudio = false
        }
      }
      this.replayAudio = true
    },
    rerecord () {
      this.audioBlob = null
      this.isRecording = false
      this.readyToSubmit = false
    },
    resetZoomLevel () {
      if (this.isDesktopSurveyPreview) {
        this.zoomLevel = 100
      }
    },
    setAudioSrc (audioUrl = '') {
      if (!this.isSpecificationsPreview) {
        audioUrl = window.URL.createObjectURL(this.audioBlob)
      }
      this.$refs.audioPlayer.src = audioUrl
      this.$refs.audioPlayer.load()
    },
    stopReplay () {
      this.replayAudio = false
      this.$refs.audioPlayer.pause()
    }
  }
}
</script>
