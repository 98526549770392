var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full"},[(_vm.type === 'primary')?_c('input',{ref:'input',class:[
      'tw-rounded-xl',
      'tw-outline-none',
      'tw-p-4',
      'tw-border-none',
      'tw-transition-all',
      'tw-duration-200',
      'hover:tw-ring-black-two',
      'hover:tw-ring-[2px]',
      'focus:tw-bg-white-one',
      'focus:tw-ring-[2px]',
      'tw-ring-[1px]',
      'tw-w-full' ].concat( _vm.classes,
      [{'tw-ring-white-two': _vm.error==null }],
      [{'focus:tw-ring-black-two': _vm.error==null}],
      [{ 'tw-ring-error': _vm.error!=null }]
    ),attrs:{"autofocus":_vm.autofocus,"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.$emit('blur')},"input":function($event){return _vm.$emit('input', $event.target.value)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.$emit('enter-pressed')}}}):_vm._e(),_vm._v(" "),(_vm.error)?_c('span',{staticClass:"tw-text-sm tw-text-error tw-mt-1 tw-inline-block tw-px-0.5"},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }