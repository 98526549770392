<template>
  <div>
    <st-toast-notification
      v-if="showToast"
      kind="error"
      title="Error"
      sub-title="You should unpublish survey before editing custom page"
      @click="showToast = false"
    />
    <div class="bx--grid mobile-header">
      <a href="/welcome">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
    </div>
    <div class="bx--grid">
      <div class="bx--row">
        <div class="bx--offset-sm-1 bx--col-sm-9 bx--col-xs-12">
          <st-hyperlink
            class="back-hyperlink-spacing--extra-space"
            :back-link="true"
            text="Back"
            :url="`/study/${survey.id}/edit`"
          />
          <h1 class="page-header st-publish-options__header-spacing">
            {{ survey.name }}
          </h1>
          <st-icon-description-card
            v-for="(option, index) in pageOptions"
            :key="index"
            class="bx--offset-sm-3 st-publish-options__survey-cards"
            :check-mark="true"
            :description="option.description"
            :icon-name="option.iconName"
            :title="option.title"
            @click="option.select"
          />
          <st-tooltip
            direction="top"
            tip="Code copied!"
            :is-active="false"
            :flash="showIframeTooltip"
            style="display:block"
            arrow-class="st-tooltip__arrow_none"
          >
            <st-icon-description-card
              class="bx--offset-sm-3 st-publish-options__survey-cards"
              :check-mark="true"
              description="Copy Iframe code to integrate into your website."
              icon-name="code-html"
              title="Embed"
              @click="copyIframeCode"
            />
          </st-tooltip>
        </div>
      </div>
      <st-modal
        v-model="displayPublishConfirmationModal"
        :cancel-modal="true"
        :non-exit-modal="true"
        @closeModal="displayPublishConfirmationModal = false"
      >
        <template v-slot:modalTitle>
          Publish Survey
        </template>
        <template v-slot:modalActions>
          <div class="st-publish-options-modal__instructions-container">
            <p class="st-publish-options-modal__instruction-text">
              You are about to publish “{{ survey.name }}”. Once published you
              won’t be able to edit the content.
            </p>
            <p class="st-publish-options-modal__instruction-text">
              Are you sure you want to publish right now?
            </p>
          </div>
        </template>
        <template v-slot:modalButton>
          <st-button
            caption="Publish"
            icon-name="publish"
            @click="publishSurvey"
          />
        </template>
      </st-modal>
      <st-modal
        v-model="displayTestModal"
        :cancel-modal="true"
        :non-exit-modal="true"
        @closeModal="resetTestModal"
      >
        <template v-slot:modalTitle>
          Send Test Link
        </template>
        <template v-slot:modalActions>
          <st-email-input
            class="st-publish-options-modal__email-input"
            label="Type an email"
            @addNewEmail="addTestEmail"
            @removeEmail="removeTestEmail"
          />
        </template>
        <template v-slot:modalButton>
          <st-button
            caption="Send Test Link"
            :show-action="true"
            icon-name="test"
            :disabled="!testEmails.length"
            @click="sendTestEmails"
          />
        </template>
      </st-modal>
    </div>
  </div>
</template>

<script>
import {
  IconWrapper,
  StButton,
  StEmailInput,
  StHyperlink,
  StIconDescriptionCard,
  StModal,
  StTooltip,
  StToastNotification
} from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StEmailInput,
    StHyperlink,
    StIconDescriptionCard,
    StModal,
    StTooltip,
    StToastNotification
  },
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    let pageOptions = [
      {
        description: 'Send a link to a colleague for review.',
        iconName: 'test',
        select: () => {
          this.displayTestModal = true
        },
        title: 'Test'
      },
      {
        description: 'Interact with the survey as a participant.',
        iconName: 'view-password',
        select: () => {
          window.location = `/study/${this.survey.id}/response/preview`
        },
        title: 'Preview'
      },
      {
        description: 'Setup custom page for your audience.',
        iconName: 'edit',
        select: () => {
          this.editHandler()
        },
        title: 'Setup Custom Page'
      }
    ]
    if (this.survey.canEdit) {
      pageOptions.unshift({
        description: 'Survey invitations will be sent right away.',
        iconName: 'publish',
        select: () => {
          this.displayPublishConfirmationModal = true
        },
        title: 'Publish Now',
        unpublishedOnly: true
      })
    }

    if (this.survey.published) {
      pageOptions.unshift({
        description: 'After unpublished you can edit survey.',
        iconName: 'publish',
        select: () => {
          this.publishSurvey()
        },
        title: 'Unpublish'
      })
    }

    return {
      displayPublishConfirmationModal: false,
      displayTestModal: false,
      initialPageOptions: pageOptions,
      testEmails: [],
      showIframeTooltip: false,
      showToast: false
    }
  },
  computed: {
    pageOptions () {
      if (!this.survey.published) {
        return this.initialPageOptions
      }

      return this.initialPageOptions.filter(
        (option) => !option.unpublishedOnly
      )
    }
  },
  mounted: function () {},
  methods: {
    addTestEmail (email) {
      this.testEmails.push(email.email)
    },
    editHandler () {
      if (!this.survey.published) {
        window.location = `/study/${this.survey.id}/custom_page_edit`
      } else {
        this.showToast = true
        setTimeout(() => (this.showToast = false), [3000])
      }
    },
    publishSurvey () {
      let url = `/study/${this.survey.id}/publish_survey`

      this.$axios
        .request({
          url: url,
          method: 'post',
          data: { survey: this.survey }
        })
        .then((res) => {
          res.data.survey.survey.published
            ? (window.location = `/study/${this.survey.id}/published`)
            : (window.location = `/study/${this.survey.id}/edit`)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    removeTestEmail (email) {
      if (!email.error) {
        this.testEmails.splice(this.testEmails.indexOf(email.email), 1)
      }
    },
    resetTestModal () {
      this.displayTestModal = false
      this.testEmails = []
    },
    sendTestEmails () {
      let url = `/study/${this.survey.id}/send_test_emails`

      this.$axios
        .request({
          url: url,
          method: 'post',
          data: { emails: this.testEmails }
        })
        .then((res) => {
          this.displayTestModal = false
          this.testEmails = []
        })
        .catch((err) => {
          console.error(err)
        })
    },
    copyIframeCode () {
      const iframeCode = `<iframe
  src="${window.location.origin}/study/${this.survey.id}"
  title="AskHumans Embedded Survey"
  style="width:1000px;height:800px;border: 0px"
  allow="microphone"
>
</iframe>`
      navigator.clipboard.writeText(iframeCode)
      this.showIframeTooltip = true
      setTimeout(() => {
        this.$nextTick(() => {
          this.showIframeTooltip = false
        })
      }, 1250)
    }
  }
}
</script>
