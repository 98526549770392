<template>
  <div class="bx--grid st-member-groups">
    <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-dropdown-button
          caption="Manage Recipients"
          icon-name="caret-down"
          :show-action="true"
          :options="dropdownOptions"
          @updateOption="handleDropdownOption"
        />
      </div>
    </div>
    <div>
      <div class="desktop-page-and-dropdown-button bx--col-sm-12 bx--col-xs-12">
        <h1 class="page-header">
          Audience Lists
        </h1>
        <st-dropdown-button
          class="page-dropdown-button"
          caption="Manage Lists"
          :show-action="true"
          :options="dropdownOptions"
          @updateOption="handleDropdownOption"
        />
      </div>
      <div class="lookup-and-dropdown bx--col-sm-12 bx--col-xs-12">
        <st-input
          v-model="recipientLookup"
          class="st-input--default lookup-and-dropdown__space-input"
          :show-icon="true"
          :show-label="false"
          label="Recipient Lookup"
          label-in-field-class="st-input--hide-mobile-label"
          @blur="updateSearchParam"
        />
        <st-dropdown-menu
          caption="Sort By"
          :display-caption="true"
          :initial-active-option="filterOption"
          :options="['All Recipients', 'A - Z', 'Z - A', 'Surveys']"
          @updateOption="setFilterOption"
        />
      </div>
      <div class="st-member-groups__accordions bx--col-sm-9 bx--col-xs-12">
        <template v-for="(list, i) in sortedRecipientLists">
          <st-accordion
            v-if="isDesktop"
            :key="list.id"
            :expanded-text="numberOfRecipientsInList(sortedRecipientLists[i].participants.length)"
          >
            <template v-slot:visible-section>
              <div class="st-member-groups__group-row">
                <icon-wrapper
                  icon-name="list"
                  class="st-member-groups__group-icon"
                />
                <div class="st-member-groups__group-name">
                  {{ list.name }}
                </div>
                <div
                  class="st-member-groups__action-icon"
                  @click.stop="addRecipientModal(i)"
                >
                  <st-tooltip
                    tip="add Recipients"
                    class="st-member-groups__tooltip"
                  >
                    <icon-wrapper
                      icon-name="add-member"
                      class="st-member-groups__hover-icon"
                    />
                  </st-tooltip>
                </div>
                <div
                  class="st-member-groups__action-icon"
                  @click.stop="openEditModal(i)"
                >
                  <st-tooltip
                    tip="edit group name"
                    class="st-member-groups__tooltip"
                  >
                    <icon-wrapper
                      icon-name="edit"
                      class="st-member-groups__hover-icon"
                    />
                  </st-tooltip>
                </div>
                <div
                  class="st-member-groups__action-icon"
                  @click.stop="openDeleteModal(i)"
                >
                  <st-tooltip
                    tip="delete group"
                    class="st-member-groups__tooltip"
                  >
                    <icon-wrapper
                      icon-name="trash"
                      class="st-member-groups__hover-icon"
                    />
                  </st-tooltip>
                </div>
              </div>
            </template>
            <template v-slot:expanded-section>
              <div class="st-member-groups__group-expanded-section">
                <template v-if="list.participants.length < 1">
                  <div>There are currently no recipients in this list.</div>
                </template>
                <template v-else>
                  <template
                    v-for="recipient in sortedRecipientLists[i].participants"
                  >
                    <participant-avatar
                      :key="`avi-${recipient.id}-${list.id}`"
                      :participant="recipient"
                      class="st-audience-lists__avatar"
                    />
                    <div
                      :key="`name-${recipient.id}-${list.id}`"
                      class="st-member-groups__name-email"
                    >
                      <div class="st-member-groups__name">
                        {{ recipient.firstName }} {{ recipient.lastName }}
                      </div>
                      <div class="st-member-groups__email">
                        {{ recipient.email }}
                      </div>
                    </div>
                    <div
                      :key="`surveys-${recipient.id}-${list.id}`"
                      class="st-audience-lists__surveys-column"
                    >
                      <st-data-table-dropdown-info
                        class="st-member-groups__department-dropdown"
                        :center-closed-text="true"
                        :display-text="closedTitle(recipient)"
                        :open-title="openTitle(recipient)"
                      >
                        <div
                          v-for="(survey, index) in recipient.surveyProgress"
                          :key="index"
                          class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__spacer"
                        >
                          <div class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__stacker">
                            <p
                              class="st-all-recipients-table-dropdown__survey-name"
                              @click="handleSurveyNameClick(survey)"
                            >
                              {{ survey.surveyTitle }}
                            </p>
                            <div class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__questions">
                              <p>
                                {{ survey.questionsComplete }}
                              </p>
                              /
                              <p class="st-all-recipients-table-dropdown__question-spacing">
                                {{ survey.questionsCount }}
                              </p>
                              questions
                            </div>
                          </div>
                          <p
                            v-if="survey.questionsComplete === survey.questionsCount"
                            class="st-all-recipients-table-dropdown__complete"
                          >
                            Completed
                          </p>
                          <p
                            v-else-if="survey.surveyArchived"
                            class="st-all-recipients-table-dropdown__complete"
                          >
                            Archived
                          </p>
                          <template v-else-if="survey.surveyPublished">
                            <st-tooltip
                              direction="top"
                              tip="Invite Sent!"
                              :is-active="false"
                              :flash="showTooltip && hoverIdx === index"
                            >
                              <st-hyperlink
                                text="Invite Again"
                                icon-name="paper-plane"
                                @click.native="sendInviteAgain(recipient.id, survey.surveyId, index)"
                              />
                            </st-tooltip>
                          </template>
                          <p
                            v-else
                            class="st-all-recipients-table-dropdown__complete"
                          >
                            Survey Not Published
                          </p>
                        </div>
                      </st-data-table-dropdown-info>
                    </div>
                    <div
                      :key="`status-${recipient.id}-${list.id}`"
                      class="st-member-groups__member-kind"
                    >
                      Active
                    </div>
                    <div
                      :key="`remove-${recipient.id}-${list.id}`"
                      class="st-member-groups__remove-container"
                    >
                      <div
                        class="st-member-groups__remove"
                        @click="openRemoveModal(i, [recipient.id], recipient.fullName)"
                      >
                        <div class="st-member-groups__remove-text">
                          Remove
                        </div>
                        <icon-wrapper icon-name="trash" />
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </st-accordion>
          <st-card
            v-else
            :key="list.id"
            class="st-list-and-group-card"
          >
            <template v-slot:card-content>
              <div class="st-list-and-group-card__list-name-and-recipients">
                <div class="st-list-and-group-card__icon-and-info">
                  <icon-wrapper
                    icon-name="list"
                    class="st-list-and-group-card__icon"
                  />
                  <div class="st-list-and-group-card__name">
                    {{ list.name }}
                  </div>
                </div>
                <div class="st-list-and-group-card__icon-and-info">
                  <icon-wrapper
                    icon-name="participants"
                    class="st-list-and-group-card__icon"
                    :actionable="false"
                  />
                  <div class="st-list-and-group-card__recipients">
                    {{ numberOfRecipientsInList(list.participants.length) }}
                  </div>
                </div>
              </div>
              <st-overflow-menu
                :title="`${list.name}`"
                icon-name="list"
              >
                <st-overflow-menu-item
                  text="View Recipients"
                  icon-name="participants"
                  @mouseup="viewRecipientModal(i)"
                />
                <st-overflow-menu-item
                  text="Add Recipients"
                  icon-name="add-member"
                  @mouseup="addRecipientModal(i)"
                />
                <st-overflow-menu-item
                  text="Edit List Name"
                  icon-name="edit"
                  @mouseup="openEditModal(i)"
                />
                <st-overflow-menu-item
                  text="Delete List"
                  icon-name="trash"
                  @mouseup="openDeleteModal(i)"
                />
              </st-overflow-menu>
            </template>
          </st-card>
        </template>
      </div>
    </div>
    <st-modal v-model="createAudienceListModal">
      <template v-slot:modalTitle>
        Create Audience List
      </template>
      <template
        v-slot:modalActions
      >
        <div class="st-member-groups__modal-text">
          <st-input
            v-model="newListName"
            class="st-input--default st-member-groups__group-name-input"
            :show-label="true"
            label="Audience List Name"
            placeholder="Name goes here"
            @emitEnter="createNewList"
          />
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Create List"
          :show-action="true"
          icon-name="add-list"
          @click="createNewList"
        />
      </template>
    </st-modal>
    <st-modal
      v-model="addRecipientsModal"
      size-class="st-modal--large"
    >
      <template v-slot:modalTitle>
        Add Recipients to {{ modalListName }}
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text">
          <st-email-input
            ref="emailInput"
            label="Recipients' Emails"
            add-email-word="Add"
            after-email-text="as a recipient"
            :existing-members-list="allRecipients"
            @addNewEmail="addNewEmail"
            @uncheckEmail="uncheckEmail"
            @removeEmail="removeNewEmail"
          />
          <st-data-table
            class="st-member-groups__member-table"
            :active-column="activeColumn"
            :headers="['Name', 'Email', 'Surveys Taken']"
            :modal-table="true"
            :rows="sortedRecipientsByActiveColumn"
            :sortable-columns="['Name', 'Email', 'Surveys Taken']"
            @setActiveColumn="updateActiveColumn"
            @toggleActiveColumn="updateActiveColumn"
            @selectAll="toggleAllChecks(true)"
            @unselectAll="toggleAllChecks(false)"
          >
            <template v-slot:default="{ row: recipient, anyRowsChecked }">
              <st-data-table-checkbox-row
                :key="recipient.id"
                :row-data="recipient"
                :avatar-url="recipient.avatarUrl"
                :cell-keys="['fullName', 'email', 'surveyProgress']"
                :checked="recipient.selected"
                :show-all-checks="anyRowsChecked"
                @input="(val) => { updateCheck(recipient.id, val)}"
              >
                <template v-slot:cell-3>
                  <div class="st-audience-lists__surveys-column">
                    <st-data-table-dropdown-info
                      class="st-member-groups__department-dropdown"
                      :center-closed-text="true"
                      :disabled="!isDesktop"
                      :display-text="closedTitle(recipient)"
                      :open-title="openTitle(recipient)"
                    >
                      <div
                        v-for="(survey, index) in recipient.surveyProgress"
                        :key="index"
                        class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__spacer"
                      >
                        <div class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__stacker">
                          <p
                            class="st-all-recipients-table-dropdown__survey-name"
                            @click="handleSurveyNameClick(survey)"
                          >
                            {{ survey.name }}
                          </p>
                          <div class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__questions">
                            <p>
                              {{ survey.questionsComplete }}
                            </p>
                            /
                            <p class="st-all-recipients-table-dropdown__question-spacing">
                              {{ survey.questionsCount }}
                            </p>
                            questions
                          </div>
                        </div>
                        <p
                          v-if="survey.questionsComplete === survey.questionsCount"
                          class="st-all-recipients-table-dropdown__complete"
                        >
                          Completed
                        </p>
                        <template v-else-if="survey.surveyPublished">
                          <st-tooltip
                            direction="top"
                            tip="Invite Sent!"
                            :is-active="false"
                            :flash="showTooltip && hoverIdx === index"
                          >
                            <st-hyperlink
                              text="Invite Again"
                              icon-name="paper-plane"
                              @click.native="sendInviteAgain(recipient.id, survey.surveyId, index)"
                            />
                          </st-tooltip>
                        </template>
                        <p
                          v-else
                          class="st-all-recipients-table-dropdown__complete"
                        >
                          Survey Not Published
                        </p>
                      </div>
                    </st-data-table-dropdown-info>
                  </div>
                </template>
                <template v-slot:cell-4>
                  <div
                    class="st-data-table-centered__text-values"
                  >
                    {{ recipient.status }}
                  </div>
                </template>
                <template v-slot:cell-5>
                  <div
                    class="st-data-table-centered__text-values"
                  >
                    {{ recipient.source }}
                  </div>
                </template>
              </st-data-table-checkbox-row>
            </template>
          </st-data-table>
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          :disabled="newEmails.length<1 && selectedRecipients.length<1"
          caption="Add Recipients"
          :show-action="true"
          icon-name="add-member"
          @click="addRecipients"
        />
      </template>
    </st-modal>
    <st-modal v-model="editListModal">
      <template v-slot:modalTitle>
        Edit Audience List
      </template>
      <template
        v-slot:modalActions
      >
        <div class="st-member-groups__modal-text">
          <st-input
            ref="editAudienceListName"
            v-model="modalListName"
            class="st-input--default st-member-groups__edit-name-input"
            :show-label="true"
            label="List Name"
            @emitEnter="editListName"
          />
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          icon-name="save"
          @click="editListName"
        />
      </template>
    </st-modal>
    <st-modal v-model="deleteListModal">
      <template v-slot:modalTitle>
        Delete List
      </template>
      <template
        v-slot:modalActions
      >
        <div class="st-member-groups__modal-text st-member-groups__modal-text--delete-group">
          Are you sure you want to delete {{ modalListName }}? This won't affect surveys in which recipients are a part of.
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Delete List"
          :show-action="true"
          icon-name="trash"
          @click="deleteList"
        />
      </template>
    </st-modal>
    <st-modal
      v-model="removeRecipientModal"
      @closeModal="closeRemoveRecipientModal"
    >
      <template v-slot:modalTitle>
        Remove Recipient from List
      </template>
      <template
        v-slot:modalActions
      >
        <div class="st-member-groups__modal-text">
          Are you sure you want to remove {{ modalRecipientName }} from {{ modalListName }}?
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Remove Recipient"
          :show-action="true"
          icon-name="trash"
          @click="removeRecipient"
        />
      </template>
    </st-modal>
    <st-modal v-model="viewRecipientsModal">
      <template v-slot:modalTitle>
        Recipients in {{ modalListName }}
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__group-expanded-section st-member-groups__group-expanded-section--mobile">
          <template v-if="modalListRecipients.length < 1">
            <div>There are currently no recipients in this list.</div>
          </template>
          <template v-else>
            <template v-for="recipient in modalListRecipients">
              <participant-avatar
                :key="`avi-${recipient.id}-${modalListId}`"
                :participant="recipient"
                class="st-audience-lists__avatar"
              />
              <div
                :key="`name-${recipient.id}-${modalListId}`"
                class="st-member-groups__name-email"
              >
                <div class="st-member-groups__name">
                  {{ recipient.firstName }} {{ recipient.lastName }}
                </div>
                <div class="st-member-groups__email">
                  {{ recipient.email }}
                </div>
              </div>
              <div
                :key="`surveys-${recipient.id}-${modalListId}`"
                class="st-audience-lists__surveys-column"
              >
                <st-data-table-dropdown-info
                  class="st-member-groups__department-dropdown"
                  :center-closed-text="true"
                  :disabled="true"
                  :display-text="closedTitle(recipient)"
                  :open-title="openTitle(recipient)"
                >
                  <div
                    v-for="(survey, index) in recipient.surveyProgress"
                    :key="index"
                    class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__spacer"
                  >
                    <div class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__stacker">
                      <p
                        class="st-all-recipients-table-dropdown__survey-name"
                        @click="handleSurveyNameClick(survey)"
                      >
                        {{ survey.surveyTitle }}
                      </p>
                      <div class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__questions">
                        <p>
                          {{ survey.questionsComplete }}
                        </p>
                        /
                        <p class="st-all-recipients-table-dropdown__question-spacing">
                          {{ survey.questionsCount }}
                        </p>
                        questions
                      </div>
                    </div>
                    <p
                      v-if="survey.questionsComplete === survey.questionsCount"
                      class="st-all-recipients-table-dropdown__complete"
                    >
                      Completed
                    </p>
                    <p
                      v-else-if="survey.surveyArchived"
                      class="st-all-recipients-table-dropdown__complete"
                    >
                      Archived
                    </p>
                    <template v-else-if="survey.surveyPublished">
                      <st-tooltip
                        direction="top"
                        tip="Invite Sent!"
                        :is-active="false"
                        :flash="showTooltip && hoverIdx === index"
                      >
                        <st-hyperlink
                          text="Invite Again"
                          icon-name="paper-plane"
                          @click.native="sendInviteAgain(recipient.id, survey.surveyId, index)"
                        />
                      </st-tooltip>
                    </template>
                    <p
                      v-else
                      class="st-all-recipients-table-dropdown__complete"
                    >
                      Survey Not Published
                    </p>
                  </div>
                </st-data-table-dropdown-info>
              </div>
              <div
                :key="`status-${recipient.id}-${modalListId}`"
                class="st-member-groups__member-kind"
              >
                Active
              </div>
              <div
                :key="`remove-${recipient.id}-${modalListId}`"
                class="st-member-groups__remove-container"
              >
                <div
                  class="st-member-groups__remove"
                  @click="openRemoveModal(modalListIndex, [recipient.id], recipient.fullName)"
                >
                  <div class="st-member-groups__remove-text">
                    Remove
                  </div>
                  <icon-wrapper icon-name="trash" />
                </div>
              </div>
            </template>
          </template>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { caseInsensitiveSortBy, doubleCaseInsensitiveSortBy } from '../../../utilities/data_utilities'
import { isDesktop } from '../../../mixins'
import ParticipantAvatar from '../insights/participant-avatar'

import {
  IconWrapper,
  StAccordion,
  StButton,
  StCard,
  StDataTable,
  StDataTableCheckboxRow,
  StDataTableDropdownInfo,
  StDropdownButton,
  StDropdownMenu,
  StEmailInput,
  StHyperlink,
  StInput,
  StModal,
  StOverflowMenu,
  StOverflowMenuItem,
  StTooltip
} from '../../common'
import refineUrlFilter from '../../../mixins/refineUrlFilter'

const DROPDOWN_OPTIONS = [{
  caption: 'Create List',
  iconName: 'add-list'
}
// , {
//   caption: 'Import CSV',
//   iconName: 'import-csv'
// }
]

function includesLookupString (baseString, lookupString) {
  return baseString.toLowerCase().includes(lookupString.toLowerCase())
}

export function sortRecipientsAZ (arr) {
  return caseInsensitiveSortBy(arr, 'fullName')
}

export function sortRecipientsStatus (arr) {
  return caseInsensitiveSortBy(arr, 'status')
}

export function sortRecipientsZA (arr) {
  return caseInsensitiveSortBy(arr, 'fullName', true)
}

export default {
  components: {
    IconWrapper,
    StAccordion,
    StButton,
    StCard,
    StDropdownMenu,
    StDataTable,
    StDataTableCheckboxRow,
    StDataTableDropdownInfo,
    StDropdownButton,
    StEmailInput,
    StHyperlink,
    StInput,
    StModal,
    StOverflowMenu,
    StOverflowMenuItem,
    StTooltip,
    'participant-avatar': ParticipantAvatar
  },
  mixins: [isDesktop, refineUrlFilter],
  props: {
    initialLists: {
      type: Array,
      required: true
    },
    recipients: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      activeColumn: { name: 'Name', ascending: true },
      addRecipientFilterOption: 'All Recipients',
      addRecipientsModal: false,
      allRecipients: this.recipients.map(recipient => {
        return Object.assign({ selected: false }, recipient.participant)
      }),
      createAudienceListModal: false,
      csvModal: false,
      deleteListModal: false,
      dropdownOptions: DROPDOWN_OPTIONS,
      editListModal: false,
      filterOption: 'All Recipients',
      hoverIdx: false,
      lists: this.initialLists,
      modalListIndex: -1,
      modalRecipientIds: [],
      modalRecipientName: '',
      newEmails: [],
      newListName: '',
      newRecipientEmail: '',
      recipientLookup: '',
      removeRecipientModal: false,
      showTooltip: false,
      viewRecipientsModal: false
    }
  },
  computed: {
    listIds () {
      return this.lists.map(list => list.id)
    },
    modalListName: {
      get () {
        return this.lists[this.modalListIndex].name
      },
      set (newName) {
        this.lists[this.modalListIndex].name = newName
      }
    },
    modalListId () {
      return this.listIds[this.modalListIndex]
    },
    modalListRecipients () {
      return this.lists[this.modalListIndex].participants
    },
    selectedRecipients () {
      return this.allRecipients.filter(recipient => recipient.selected)
    },
    selectedRecipientsIds () {
      return this.selectedRecipients.map(recipient => recipient.id)
    },
    sortedRecipientsByActiveColumn () {
      let recipients = []
      switch (this.activeColumn.name) {
        case 'Name':
          return this.activeColumn.ascending
            ? doubleCaseInsensitiveSortBy(this.allRecipients, 'lastName', 'firstName')
            : doubleCaseInsensitiveSortBy(this.allRecipients, 'lastName', 'firstName', true)
        case 'Email':
          return this.sortColumnProperty('email')
        case 'Surveys Taken':
          return this.activeColumn.ascending
            ? this.sortParticipantSurveyProgress(true)
            : this.sortParticipantSurveyProgress(false)
      }
      return recipients
    },
    filteredListByRecipients () {
      return this.lists.map(list => {
        return {
          id: list.id,
          name: list.name,
          participants: list.participants.filter(recipient => includesLookupString(recipient.fullName, this.recipientLookup))
        }
      })
    },
    sortedRecipientLists () {
      let recipientsList
      switch (this.filterOption) {
        case 'All Recipients':
          recipientsList = this.filteredListByRecipients
          break
        case 'A - Z':
          recipientsList = this.filteredListByRecipients.map(list => {
            return {
              ...list,
              participants: sortRecipientsAZ(list.participants)
            }
          })
          break
        case 'Z - A':
          recipientsList = this.filteredListByRecipients.map(list => {
            return {
              ...list,
              participants: sortRecipientsZA(list.participants)
            }
          })
          break
        case 'Surveys':
          recipientsList = this.filteredListByRecipients.map(list => {
            return {
              ...list,
              participants: list.participants.slice().sort((a, b) =>
                (b.surveysCompleted / b.surveyProgress.length) -
             (a.surveysCompleted / a.surveyProgress.length))
            }
          })
          break
        // case 'Newest First': [TBD]
        //   recipientsList = this.filteredListByRecipients.map(list => {
        //     return list.slice().sort((a, b) => b.createdAt - a.createdAt)
        //   })
        //   break
        // case 'Oldest First': [TBD]
        //   recipientsList = this.filteredListByRecipients.map(list => {
        //     return list.slice().sort((a, b) => a.createdAt - b.createdAt)
        //   })
        //   break
        // case 'Status': [TBD]
        //   recipientsList = this.filteredListByRecipients.map(list => {
        //     return {
        //       ...list,
        //       recipients: sortRecipientsStatus(list.participants, 'status')
        //     }
        //   })
        //   break
      }
      return recipientsList
    }
  },
  mounted () {
    this.$store.commit('setActivePage', 'audience-lists')
  },
  created () {
    this.defaultFilter = 'All Recipients'
    this.filterOption = this.filterParam
    this.recipientLookup = this.searchParam
  },
  methods: {
    addRecipients () {
      this.inviteNewParticipants()
        .then(ids => { return this.addParticipantsToList(ids.concat(this.selectedRecipientsIds)) })
        .then(res => {
          this.lists[this.modalListIndex].participants = res.data.participants
          this.addRecipientsModal = false
          this.toggleAllChecks(false)
          this.modalListIndex = -1
        })
        .catch(err => { console.log(err) })
    },
    addParticipantsToList (ids) {
      return this.$axios.request({
        method: 'post',
        url: `/audience_lists/${this.modalListId}/participants`,
        data: { ids: ids }
      })
    },
    inviteNewParticipants () {
      let newEmailsToAdd = this.newEmails.map(participant => participant.email)
      if (newEmailsToAdd.length > 0) {
        return this.$axios.request({
          method: 'post',
          url: `/participants`,
          data: { emails: newEmailsToAdd }
        }).then(res => { return res.data.map(participant => participant.id) })
      } else {
        return Promise.resolve([])
      }
    },
    addNewEmail (newRecipient) {
      newRecipient.id ? this.updateCheck(newRecipient.id, true) : this.newEmails.push(newRecipient)
    },
    closeRemoveRecipientModal () {
      this.removeRecipientModal = false
      this.modalListIndex = -1
      this.modalRecipientIds = []
      this.modalRecipientName = ''
      this.toggleAllChecks(false)
    },
    closedTitle (recipient) {
      return recipient.surveyProgress.length === 1
        ? `${recipient.surveysCompleted.length} / ${recipient.surveyProgress.length} Survey`
        : `${recipient.surveysCompleted.length} / ${recipient.surveyProgress.length} Surveys`
    },
    createNewList () {
      let url = `/audience_lists`
      this.$axios.request({
        method: 'post',
        url: url,
        data: { name: this.newListName }
      })
        .then(res => {
          this.lists.push(res.data)
          this.newListName = ''
          this.createAudienceListModal = false
        })
        .catch(err => { console.error(err) })
    },
    deleteList () {
      let url = `/audience_lists/${this.modalListId}`
      this.$axios.request({
        method: 'delete',
        url: url
      })
        .then(res => {
          this.lists.splice(this.modalListIndex, 1)
          this.deleteListModal = false
          this.$nextTick(() => { this.modalListIndex = -1 })
        })
        .catch(err => { console.error(err) })
    },
    editListName () {
      let url = `/audience_lists/${this.modalListId}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { name: this.modalListName }
      })
        .then(res => { this.editListModal = false })
        .catch(err => { console.error(err) })
    },
    handleDropdownOption (option) {
      switch (option.caption) {
        case 'Create List':
          this.createAudienceListModal = true
          break
        // case 'Import CSV':
        //   this.csvModal = true
        //   break
      }
    },
    handleSurveyNameClick (survey) {
      window.location = `/study/${survey.id}/edit`
    },
    numberOfRecipientsInList (recipientCount) {
      return recipientCount === 1 ? '1 recipient' : `${recipientCount} recipients`
    },
    addRecipientModal (listIdx) {
      this.modalListIndex = listIdx
      this.$nextTick(() => { this.addRecipientsModal = true })
    },
    viewRecipientModal (listIdx) {
      this.modalListIndex = listIdx
      this.$nextTick(() => { this.viewRecipientsModal = true })
    },
    openDeleteModal (listIdx) {
      this.deleteListModal = true
      this.modalListIndex = listIdx
    },
    openEditModal (listIdx) {
      this.editListModal = true
      this.modalListIndex = listIdx
      setTimeout(() => { this.$refs.editAudienceListName.focus() }, 10)
    },
    openRemoveModal (modalIdx, userIds, userName) {
      this.viewRecipientsModal = false
      this.removeRecipientModal = true
      this.modalRecipientName = userName
      this.modalListIndex = modalIdx
      this.modalRecipientIds = userIds
    },
    openTitle (recipient) {
      return recipient.surveyProgress.length === 1
        ? `${recipient.surveysCompleted.length} out of ${recipient.surveyProgress.length} Survey Taken`
        : `${recipient.surveysCompleted.length} out of ${recipient.surveyProgress.length} Surveys Taken`
    },
    removeRecipient () {
      let url = `/audience_lists/${this.modalListId}/participants`
      this.$axios.request({
        method: 'delete',
        url: url,
        data: { ids: this.modalRecipientIds }
      })
        .then(res => {
          this.lists[this.modalListIndex].participants = res.data.participants
          this.closeRemoveRecipientModal()
        })
        .catch(err => { console.error(err) })
    },
    removeNewEmail (email) {
      let idx = this.newEmails.map(recipient => recipient.email).indexOf(email)
      this.newEmails.splice(idx, 1)
    },
    sendInviteAgain (participantId, surveyId, idx) {
      let url = `/participants/${participantId}/invite_again`
      this.$axios.request({
        method: 'post',
        url: url,
        data: { survey_id: surveyId }
      }).then(res => {
        this.showTooltip = true
        this.hoverIdx = idx
        setTimeout(() => {
          this.$nextTick(() => {
            this.showTooltip = false
            this.hoverIdx = null
          })
        }, 1250)
      })
        .catch(err => { console.error('err', err) })
    },
    setFilterOption (option) {
      this.updateUrlParams('filter', option)
      this.filterOption = option
    },
    sortColumnProperty (property) {
      return this.activeColumn.ascending
        ? caseInsensitiveSortBy(this.allRecipients, property)
        : caseInsensitiveSortBy(this.allRecipients, property, true)
    },
    sortParticipantSurveyProgress (ascending) {
      let zeroSurveyParticipants = this.allRecipients.filter(recipient => !recipient.surveyProgress.length)
      let participantsWithSurveys = this.allRecipients.filter(recipient => recipient.surveyProgress.length)
      if (ascending) {
        let sortedParticipantsWithSurveys = participantsWithSurveys.slice().sort((a, b) => (b.surveysCompleted.length / b.surveyProgress.length) - (a.surveysCompleted.length / a.surveyProgress.length))
        return [...sortedParticipantsWithSurveys, ...zeroSurveyParticipants]
      } else {
        let sortedParticipantsWithSurveys = participantsWithSurveys.slice().sort((a, b) => (a.surveysCompleted.length / a.surveyProgress.length) - (b.surveysCompleted.length / b.surveyProgress.length))
        return [...zeroSurveyParticipants, ...sortedParticipantsWithSurveys]
      }
    },
    toggleAllChecks (bool) {
      this.allRecipients.forEach(recipients => { recipients.selected = bool })
      this.$refs.emailInput.toggleAllExisting(bool)
    },
    uncheckEmail (recipient) {
      let tableRecipient = this.allRecipients.find(participant => { return participant.id === recipient.id })
      tableRecipient.selected = false
    },
    updateCheck (id, val) {
      let recipient = this.allRecipients.find(recipient => { return recipient.id === id })
      recipient.selected = val

      if (val) {
        this.$refs.emailInput.addEmailFromDataTable(recipient.email)
      } else {
        let idx = this.$refs.emailInput.newEmailList.map(recipient => recipient.id).indexOf(id)
        this.$refs.emailInput.newEmailList.splice(idx, 1)
      }
    },
    updateActiveColumn (payload) {
      this.activeColumn = payload
    }
  }
}
</script>
