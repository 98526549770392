<template>
  <v-tour
    v-if="showTour"
    :name="name"
    :steps="steps"
    :callbacks="tourCallbacks"
  >
    <template slot-scope="tour">
      <transition name="fade">
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :finish="tour.finish"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <template>
            <div
              slot="actions"
              class="!tw-flex !tw-w-full !tw-items-center !tw-justify-between"
            >
              <p class="tw-text-[13px] tw-flex tw-space-x-2">
                <span>
                  Done
                </span>
                <span
                  class="tw-underline tw-cursor-pointer"
                  @click="tour.skip"
                >
                  Skip tour
                </span>
              </p>
              <ah-button
                v-if="!tour.isLast"
                @click="tour.nextStep"
              >
                Got it
              </ah-button>
              <ah-button
                v-else
                @click="tour.finish"
              >
                Got it
              </ah-button>
            </div>
          </template>
        </v-step>
      </transition>
    </template>
  </v-tour>
</template>
<script>
import AhButton from './ah-button.vue'

export default {
  name: 'TourView',
  components: {
    AhButton
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    showTour: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tourCallbacks: {} // Initialize as an empty object
    }
  },
  created () {
    // Define the tourCallbacks object in the created lifecycle hook
    this.tourCallbacks = {
      onSkip: this.finishTour,
      onFinish: this.finishTour,
      onStop: this.hideTour
    }
  },
  methods: {
    finishTour () {
      this.$emit('tourFinished', this.name)
    },
    hideTour () {
      // this.$emit('tourFinished', this.name)
    }
  }
}
</script>
<style scoped>

</style>
