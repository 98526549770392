<template>
  <div>
    <!-- <opinion-metric-transcripts v-if="activeQuestionView === 'Transcripts'" /> -->
    <!-- v-else -->
    <opinion-metric-results
      :participant-token="participantToken"
      :survey="survey"
      :user-signed-in="userSignedIn"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OpinionMetricResults from './opinion-metric-results'
import OpinionMetricTranscripts from './opinion-metric-transcripts'

export default {
  components: {
    OpinionMetricResults,
    OpinionMetricTranscripts
  },
  props: {
    userSignedIn: {
      type: String,
      required: false
    },
    survey: {
      type: Object,
      required: false
    },
    participantToken: {
      type: String,
      required: false
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('insights', ['activeQuestionView'])
  }
}
</script>
