<template>
  <div
    class="tw-h-full tw-flex-1 "
    :class="!isGuest && currentUserFeedbacks.length ? 'tw-max-h-[calc(100vh-260px)]' : 'tw-max-h-[calc(100vh-200px)]' "
  >
    <feedback-time
      v-if="isParentLoaded"
      :feedback-time-expired="feedbackTimeExpired"
      :hours-remaining="hoursRemaining"
      :minutes-remaining="minutesRemaining"
    />
    <div
      v-if="!isGuest && !feedbackTimeExpired && currentUserFeedbacks.length"
      class="tw-py-3 tw-px-8 "
    >
      <div
        class="tw-flex tw-cursor-pointer relative tw-space-x-2 tw-border tw-max-w-fit "
        @click.stop="toggleDropdownOptions"
      >
        <ah-button type="outlined">
          <span class="tw-space-x-0.5 tw-min-w-fit">
            <span>
              {{ selectedDropdownOption }}
            </span>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_393_1239)">
                <path
                  d="M5.01197 5.50036C4.83774 5.50036 4.66651 5.43667 4.54035 5.32086L0.698222 1.60922C0.43387 1.35734 0.43387 0.946223 0.695218 0.691446C0.956567 0.436669 1.38314 0.436669 1.64749 0.688551L5.01498 3.94564L8.37946 0.688551C8.65283 0.44825 9.07639 0.468516 9.32573 0.731979C9.55703 0.97807 9.55703 1.35445 9.32573 1.60054L5.4836 5.32086C5.35743 5.43667 5.18621 5.50326 5.01197 5.50036Z"
                  fill="#474747"
                />
              </g>
              <defs>
                <clipPath id="clip0_393_1239">
                  <rect
                    width="5"
                    height="9"
                    fill="white"
                    transform="translate(9.5 0.5) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
        </ah-button>
        <div
          v-if="showDropdown"
          class="flex flex-col justify-center !tw-text-xs !tw-w-24 tw-max-w-full !tw-top-10 cursor-pointer tw-overflow-hidden absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
        >
          <span
            v-for="(option) in dropdownOptions.filter((opt)=>{
              return opt != selectedDropdownOption
            })"
            :key="option"
            class=""
            @click="changeSelectedDropdownOption($event, option)"
          >
            {{ option }}
          </span>
        </div>
      </div>
    </div>
    <template v-if="!showFeedback">
      <div class="tw-px-7">
        <div
          class="tw-flex tw-cursor-pointer relative tw-space-x-2 tw-mt-5 tw-border tw-max-w-fit "
          @click.stop="toggleDropdownOptions"
        >
          <ah-button type="outlined">
            <span class="tw-space-x-0.5 tw-min-w-fit">
              <span>
                {{ selectedDropdownOption }}
              </span>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_393_1239)">
                  <path
                    d="M5.01197 5.50036C4.83774 5.50036 4.66651 5.43667 4.54035 5.32086L0.698222 1.60922C0.43387 1.35734 0.43387 0.946223 0.695218 0.691446C0.956567 0.436669 1.38314 0.436669 1.64749 0.688551L5.01498 3.94564L8.37946 0.688551C8.65283 0.44825 9.07639 0.468516 9.32573 0.731979C9.55703 0.97807 9.55703 1.35445 9.32573 1.60054L5.4836 5.32086C5.35743 5.43667 5.18621 5.50326 5.01197 5.50036Z"
                    fill="#474747"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_393_1239">
                    <rect
                      width="5"
                      height="9"
                      fill="white"
                      transform="translate(9.5 0.5) rotate(90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </ah-button>
          <div
            v-if="showDropdown"
            class="flex flex-col justify-center !tw-text-xs !tw-w-24 tw-max-w-full !tw-top-10 cursor-pointer tw-overflow-hidden absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
          >
            <span
              v-for="(option) in dropdownOptions.filter((opt)=>{
                return opt != selectedDropdownOption
              })"
              :key="option"
              class=""
              @click="changeSelectedDropdownOption($event, option)"
            >
              {{ option }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="tw-py-1"
      >
        <div
          v-for="study in strategy.surveys"
          :key="study.name"
          class="tw-px-8 tw-flex tw-justify-between tw-items-center tw-border-b tw-border-white-two tw-border-solid  tw-py-2"
        >
          <p class="tw-flex tw-flex-col ">
            <span class=" tw-text-base">
              {{ study.name }}
            </span>
            <span class="tw-text-sm tw-mt-1 tw-space-x-1">
              <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.50558 6.50013C7.15973 6.50013 8.45965 5.18022 8.45965 3.50006C8.45965 1.8199 7.15999 0.5 5.50558 0.5C3.85144 0.5 2.55151 1.82016 2.55151 3.50006C2.55151 5.17997 3.85144 6.50013 5.50558 6.50013ZM11 11.78C10.232 8.72003 7.15973 6.79995 4.14667 7.58012C2.13804 8.12012 0.542885 9.68019 0.0111674 11.78C-0.0478279 12.0801 0.129411 12.4401 0.48389 12.5H10.4093C10.7638 12.5 11 12.2601 11 11.9001V11.78Z"
                  fill="#A7AA93"
                />
              </svg>
              <span class="">
                {{ study.totalResponsesCompleted }}
              </span>

            </span>
          </p>
        </div>
      </div>
    </template>
    <template v-if="showFeedback">
      <div
        class="tw-flex tw-flex-col tw-justify-between   tw-h-full  tw-overflow-y-auto scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black "
      >
        <div
          v-if="!currentUserFeedbacks.length && feedbackTimeExpired"
          class="tw-flex tw-flex-col tw-flex-1"
        >
          <random-people

            text="You cannot give feedback anymore, because feedback time has expired"
          />
        </div>
        <div
          v-else-if="!currentUserFeedbacks.length && !feedbackTimeExpired"
          class="tw-flex tw-flex-col tw-flex-1"
        >
          <div class="tw-px-7">
            <div
              class="tw-flex tw-cursor-pointer relative tw-space-x-2 tw-mt-5 tw-border tw-max-w-fit "
              @click.stop="toggleDropdownOptions"
            >
              <ah-button type="outlined">
                <span class="tw-space-x-0.5 tw-min-w-fit">
                  <span>
                    {{ selectedDropdownOption }}
                  </span>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_393_1239)">
                      <path
                        d="M5.01197 5.50036C4.83774 5.50036 4.66651 5.43667 4.54035 5.32086L0.698222 1.60922C0.43387 1.35734 0.43387 0.946223 0.695218 0.691446C0.956567 0.436669 1.38314 0.436669 1.64749 0.688551L5.01498 3.94564L8.37946 0.688551C8.65283 0.44825 9.07639 0.468516 9.32573 0.731979C9.55703 0.97807 9.55703 1.35445 9.32573 1.60054L5.4836 5.32086C5.35743 5.43667 5.18621 5.50326 5.01197 5.50036Z"
                        fill="#474747"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_393_1239">
                        <rect
                          width="5"
                          height="9"
                          fill="white"
                          transform="translate(9.5 0.5) rotate(90)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </ah-button>
              <div
                v-if="showDropdown"
                class="flex flex-col justify-center !tw-text-xs !tw-w-24 tw-max-w-full !tw-top-10 cursor-pointer tw-overflow-hidden absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
              >
                <span
                  v-for="(option) in dropdownOptions.filter((opt)=>{
                    return opt != selectedDropdownOption
                  })"
                  :key="option"
                  class=""
                  @click="changeSelectedDropdownOption($event, option)"
                >
                  {{ option }}
                </span>
              </div>
            </div>
          </div>
          <random-people

            text="Read the report and type or record what you think about it."
          />
        </div>
        <template v-else>
          <div>
            <div
              v-if="currentUserFeedbacks.length"
              class="tw-px-8 "
            >
              <div
                v-for="(userFeedback, index) in currentUserFeedbacks"
                :key="index + 'feedback'"
                class="tw-flex tw-flex-col tw-py-4  tw-border-b tw-border-solid tw-border-white-two"
                @mouseover="highlightSection(userFeedback.section)"
                @mouseleave="RemoveHighlightedSection(userFeedback.section)"
              >
                <p
                  v-if="userFeedback.section"
                  class="tw-text-sm tw-font-semibold tw-pb-2 tw-capitalize"
                >
                  {{ removeUnderScore(userFeedback.section) + ' :' }}
                </p>
                <div class="tw-flex tw-justify-between">
                  <div class="tw-flex tw-space-x-2  tw-items-center">
                    <span class="tw-text-sm tw-text-black-three">
                      {{ getReadableDate(userFeedback) }}
                    </span>
                  </div>
                  <div class="tw-flex tw-items-center tw-mt-2">
                    <span class="tw-flex tw-items-center tw-space-x-3">
                      <svg
                        v-if="userFeedback.explanationType != 'audio'"
                        class="tw-cursor-pointer"
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="
                          showEditingFeedbackForm(
                            userFeedback.id,
                            userFeedback.transcript
                          )
                        "
                      >
                        <g clip-path="url(#clip0_252_633)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.64375 12.25H11.875V10.8281H6.06562L4.64375 12.25ZM7.96484 2.65234L0.5 10.1172V12.25H2.63281L10.0977 4.78516L7.96484 2.65234ZM11.875 3.00781C11.875 1.83009 10.9203 0.875 9.74219 0.875L8.59717 2.02042L10.73 4.15323L11.875 3.00781Z"
                            fill="#747474"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_252_633">
                            <rect
                              width="11.375"
                              height="11.375"
                              fill="white"
                              transform="translate(0.5 0.875)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <svg
                        class="tw-cursor-pointer"
                        width="25"
                        height="26"
                        viewBox="0 0 25 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="showDeleteFeedbackModal(userFeedback.id)"
                      >
                        <rect
                          y="0.5"
                          width="25"
                          height="25"
                          rx="12.5"
                          fill="white"
                        />
                        <g clip-path="url(#clip0_252_635)">
                          <path
                            d="M12.5 19.3438C15.934 19.3438 18.7222 16.5991 18.7222 13.2188C18.7222 9.83837 15.934 7.09375 12.5 7.09375C9.06596 7.09375 6.27777 9.83837 6.27777 13.2188C6.27777 16.5991 9.06596 19.3438 12.5 19.3438ZM12.5 8.17919C15.3197 8.17919 17.6195 10.4431 17.6195 13.2188C17.6195 15.9944 15.3197 18.2583 12.5 18.2583C9.6803 18.2583 7.38044 15.9944 7.38044 13.2188C7.38044 10.4431 9.6803 8.17919 12.5 8.17919Z"
                            fill="#747474"
                          />
                          <path
                            d="M10.4522 13.8393H14.5478C14.8471 13.8393 15.0991 13.5912 15.0991 13.2966C15.0991 13.002 14.8471 12.7539 14.5478 12.7539H10.4522C10.1529 12.7539 9.90082 13.002 9.90082 13.2966C9.90082 13.5912 10.1529 13.8393 10.4522 13.8393Z"
                            fill="#474747"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_252_635">
                            <rect
                              width="12.4444"
                              height="12.25"
                              fill="white"
                              transform="translate(6.27777 7.09375)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>

                <div v-if="showEditing && feedbackToEditId == userFeedback.id">
                  <textarea
                    v-model="feedbackToEdit"
                    class="tw-w-full tw-h-28 tw-py-2  tw-px-3 tw-border tw-border-solid tw-border-white-two tw-rounded-xl tw-resize-none scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black"
                  />
                  <div class="tw-my-2">
                    <ah-button
                      type="outlined"
                      @click="showEditing = false"
                    >
                      Cancel
                    </ah-button>
                    <ah-button
                      type="secondary"
                      @click="updateFeedback"
                    >
                      Save changes
                    </ah-button>
                  </div>
                </div>
                <div v-else>
                  <p class=" tw-text-sm">
                    {{ userFeedback.transcript }}
                  </p>
                  <span
                    v-if="
                      userFeedback.explanationType == 'audio'
                    "
                    class="tw-py-2 tw-block"
                  >
                    <audio
                      :ref="userFeedback.audioUrl? 'audioPlayer' : 'localAudioPlayer'"
                      controls
                      class="tw-h-10"
                    >
                      <source
                        :src="userFeedback.audioUrl || localAudioURL"
                        :type="userFeedback.contentType||'audio/mp3'"
                      >

                      Your browser does not support the audio element.
                    </audio>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <recording-feedback
          v-if="!feedbackTimeExpired"
          :version="version"
          @audioResponseSubmitted="saveAudioResponse"
          @textResponseSubmitted="saveTextResponse"
        />
      </div>
    </template>
    <st-modal
      v-if="deleteFeedbackModal"
      v-model="deleteFeedbackModal"
    >
      <h3 class="font-600">
        Delete comment
      </h3>
      <div class="tw-py-2">
        <p>
          After deleting this comment, you will not be able to recover it. Are
          you sure you want to proceed?
        </p>
      </div>
      <div class="flex justify-end mt-2">
        <button
          class="tw-px-3 tw-py-3 tw-rounded-lg tw-bg-error tw-text-white "
          @click="deleteFeedbackConfirmation"
        >
          Delete comment
        </button>
      </div>
    </st-modal>
  </div>
</template>

<script>
import { AhButton, StModal } from '../../common'
import RecordingFeedback from './recording-feedback.vue'
import RandomPeople from './random-people.vue'
import { mapState } from 'vuex'
import {
  audioRecorder,
  handlePreviewBack,
  isDesktop,
  previousResponse,
  submitQuestionAnswer
} from '../../../mixins'
import { getBlobDuration } from '../../../utilities/audio_utilities'
import FeedbackTime from './feedback-time.vue'
export default {
  components: {
    AhButton,
    StModal,
    FeedbackTime,
    RecordingFeedback,
    RandomPeople
  },
  mixins: [
    audioRecorder,
    handlePreviewBack,
    isDesktop,
    previousResponse,
    submitQuestionAnswer
  ],

  props: {
    strategy: {
      type: Object,
      required: true
    },
    version: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showFeedback: true,
      feedbackTimeExpired: false,
      showEditing: false,
      showByMember: true,
      readyToSubmit: false,
      showMembers: true,
      maxHeight: 300, // Set your maximum height
      breakpoint: 300, // Set your breakpoint
      feedback: '',
      hoursRemaining: null,
      minutesRemaining: null,
      feedbackToEditId: null,
      feedbackToEdit: '',
      currentUserFeedbacks: [],
      deleteFeedbackModal: false,
      deleteFeedbackId: null,
      showVoiceRecorder: false,
      seconds: 0,
      mins: 0,
      isParentLoaded: false,
      localAudioURL: '',
      dropdownOptions: ['Feedback', 'Sources'],
      selectedDropdownOption: 'Feedback',
      showDropdown: false
    }
  },
  computed: {
    ...mapState(['currentUser']),
    isGuest () {
      const currentURL = window.location.href
      const params = new URL(currentURL)
      const guestToken = params.searchParams.get('guest_token')
      if (guestToken) {
        return true
      }
      return false
    }
  },
  mounted () {
    // get the guest token from the url
    const currentURL = window.location.href
    const params = new URL(currentURL)
    const guestToken = params.searchParams.get('guest_token')
    if (guestToken) {
      this.currentUserFeedbacks = this.version.feedbacks.filter((feedback) => {
        return feedback.feedbackGuestUser.guestToken === guestToken
      })
    } else if (this.currentUser && this.currentUser.id) {
      this.currentUserFeedbacks = this.version.feedbacks.filter((feedback) => {
        return feedback.feedbackUser.id === this.currentUser.id
      })
    }
    if (this.version.expiryEndAt) {
      const expiryEndAt = new Date(this.version.expiryEndAt)
      const now = new Date()
      const diff = expiryEndAt - now
      if (diff < 0) {
        this.feedbackTimeExpired = true
      } else {
        // calculate the hours and minutes remaining in the feedback expiry
        const hours = Math.floor(diff / (1000 * 60 * 60))
        const minutes = Math.floor((diff / (1000 * 60)) % 60)
        this.hoursRemaining = hours
        this.minutesRemaining = minutes
      }
      this.isParentLoaded = true
    }

    setTimeout(() => {
      this.checkFeedbackTranscript(this.currentUserFeedbacks.map((f) => f.id).filter((f) => !f.transcript || !f.summary))
    }, 1000)
  },
  methods: {
    saveTextResponse (response) {
      this.currentUserFeedbacks = [
        ...this.currentUserFeedbacks,
        { createdAt: response.created_at,
          explanationType: 'text',
          guestUserId: null,
          id: response.id,
          level: 0,
          summary: response.summary,
          section: response.section,
          transcript: response.transcript,

          feedbackUser: this.currentUser }
      ]
    },
    saveAudioResponse (response) {
      this.currentUserFeedbacks = [
        ...this.currentUserFeedbacks,
        { createdAt: response.created_at,
          explanationType: 'audio',
          guestUserId: null,
          id: response.id,
          section: response.section,
          level: 0,
          summary: response.summary,
          transcript: response.transcript,
          audioUrl: response.audio_url,
          feedbackUser: this.currentUser }
      ]
      // this.showOriginalCommentIds = [
      //   ...this.showOriginalCommentIds,
      //   response.id
      // ]

      this.showVoiceRecorder = false
      this.readyToSubmit = false
      this.checkFeedbackTranscript([response.id])
    },
    // saveTextResponse (response) {
    //   this.userFeedbacks = [
    //     ...this.userFeedbacks,
    //     { ...response.data, feedbackUser: this.currentUser }
    //   ]
    // },
    // saveAudioResponse (response) {
    //   console.log('saving...')
    //   this.userFeedbacks = [
    //     ...this.userFeedbacks,
    //     { ...response.data, explanationType: 'audio', feedbackUser: this.currentUser }
    //   ]
    //   this.showOriginalCommentIds = [
    //     ...this.showOriginalCommentIds,
    //     response.data.id
    //   ]

    //   this.showVoiceRecorder = false
    //   this.readyToSubmit = false
    //   this.checkFeedbackTranscript([response.data.id])
    // },
    toggleDropdownOptions () {
      this.showDropdown = !this.showDropdown
    },

    showAndStartRecording () {
      this.showVoiceRecorder = true
      setTimeout(() => {
        this.startRecording()
      }, 10)
    },
    stopCounting () {
      this.showVoiceRecorder = false
      this.readyToSubmit = true
      this.seconds = 0
      this.minutes = 0
      clearInterval(this.intervalId)
      this.stopRecording()
    },
    submitRecording () {
      const currentURL = window.location.href
      const params = new URL(currentURL)
      const guestToken = params.searchParams.get('guest_token')
      clearInterval(this.intervalId)
      this.showVoiceRecorder = false
      this.stopRecording()
      this.readyToSubmit = true
      this.seconds = 0
      this.minutes = 0
      setTimeout(() => {
        const formData = new FormData()
        formData.append('feedback[audio_response]', this.audioBlob)
        formData.append('feedback[user_id]', this.currentUser.id)
        formData.append('feedback[version_id]', this.version.id)
        formData.append('feedback[explanation_type]', 'audio')
        formData.append('guest_token', guestToken)

        this.$axios
          .post('/feedbacks', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            this.currentUserFeedbacks = [
              ...this.currentUserFeedbacks,
              { ...response.data, explanationType: 'audio', feedbackUser: this.currentUser }
            ]
            this.showVoiceRecorder = false
            this.readyToSubmit = false
            this.audioBlob = null
            this.checkFeedbackTranscript([response.data.id])
          })
          .catch((error) => {
            console.log(error)
          })
      }, 1000)
    },
    async checkFeedbackTranscript (feedbackIds) {
      if (!feedbackIds.length) {
        return
      }
      const feedbacks = await Promise.all(
        feedbackIds.map((feedbackId) => this.fetchFeedback(feedbackId))
      )

      // with transcript and summary
      const feedbacksWithTranscriptAndSummary = feedbacks.filter(
        (feedback) => feedback.transcript && feedback.summary
      )

      if (feedbacksWithTranscriptAndSummary.length > 0) {
        this.currentUserFeedbacks = this.currentUserFeedbacks.map((feedback) => {
          const feedbackWithTranscriptAndSummary = feedbacksWithTranscriptAndSummary.find(
            (f) => f.id === feedback.id
          )

          if (feedbackWithTranscriptAndSummary) {
            return {
              ...feedback,
              transcript: feedbackWithTranscriptAndSummary.transcript,
              summary: feedbackWithTranscriptAndSummary.summary
            }
          }

          return feedback
        })
      }

      const feedbacksWithoutTranscriptAndSummary = feedbacks.filter(
        (feedback) => !feedback.transcript || !feedback.summary
      )

      if (feedbacksWithoutTranscriptAndSummary.length > 0) {
        await this.sleep(5000)
        this.checkFeedbackTranscript(
          feedbacksWithoutTranscriptAndSummary.map((feedback) => feedback.id)
        )
      }
    },
    removeUnderScore (text) {
      return text.replace(/_/g, ' ')
    },
    async fetchFeedback (feedbackId) {
      const result = await this.$axios.request({
        method: 'get',
        url: `/feedbacks/${feedbackId}`
      })

      return result.data
    },
    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    onRecordingAvailable (blob) {
      this.audioBlob = blob
      this.readyToSubmit = true
      getBlobDuration(this.audioBlob).then((duration) => {
        this.audioDuration = duration
      })

      this.$nextTick(() => {
        this.setAudioSrc()
      })
    },
    setAudioSrc (audioUrl = '') {
      console.log('setAudioSrc', this.audioBlob)
      setTimeout(() => {
        audioUrl = window.URL.createObjectURL(this.audioBlob)
        this.localAudioURL = audioUrl
        console.log(this.$refs.newlySubmittedResponseAudio)
        this.$refs.newlySubmittedResponseAudio.src = audioUrl
        this.$refs.newlySubmittedResponseAudio.load()
      }, 2000)
    },
    playAgain () {
      this.$refs.audioPlayer.play()
      this.$refs.audioPlayer.ontimeupdate = () => {
        this.audioDuration = this.$refs.audioPlayer.currentTime
        if (
          this.$refs.audioPlayer.duration === this.$refs.audioPlayer.currentTime
        ) {
          this.replayAudio = false
        }
      }
      this.replayAudio = true
    },
    countingTimeSpent () {
      this.initiateRecording()
      this.intervalId = setInterval(() => {
        this.seconds++
        if (this.seconds > 59) {
          this.seconds = 0
          this.mins++
        }
      }, 1000)
    },
    startRecording () {
      this.readyToSubmit = false
      this.initializeRecorder()
        .then((res) => {
          this.countingTimeSpent()
        })
        .catch((error) => alert(JSON.stringify(error, null, 4)))
    },
    initializeRecorder () {
      return this.tryInitializingMediaRecorder()
    },
    showDeleteFeedbackModal (feedbackId) {
      this.deleteFeedbackModal = true
      this.deleteFeedbackId = feedbackId
    },
    deleteFeedbackConfirmation () {
      this.$axios
        .delete(`/feedbacks/${this.deleteFeedbackId}`)
        .then((response) => {
          // console.log(response)
          this.currentUserFeedbacks = this.currentUserFeedbacks.filter(
            (feedback) => {
              return feedback.id !== this.deleteFeedbackId
            }
          )
          // console.log(this.currentUserFeedbacks)
          this.deleteFeedbackModal = false
          this.deleteFeedbackId = null
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateFeedback () {
      this.$axios
        .patch(`/feedbacks/${this.feedbackToEditId}`, {
          transcript: this.feedbackToEdit
        })
        .then((response) => {
          // console.log(response)
          this.currentUserFeedbacks.find((feedback) => {
            if (feedback.id === this.feedbackToEditId) {
              feedback.transcript = this.feedbackToEdit
            }
          })
          this.showEditing = false
          this.feedbackToEditId = null
          this.feedbackToEdit = ''
        })
        .catch((error) => {
          console.log(error)
        })
    },
    adjustTextareaHeight () {
      const textarea = this.$refs.autoResizeTextarea
      textarea.style.height = 'auto'

      // Check if the current height is less than the maximum height
      if (textarea.scrollHeight <= this.maxHeight) {
        textarea.style.height = `${textarea.scrollHeight}px`
      } else {
        // Set the height to the breakpoint if it exceeds the maximum height
        textarea.style.height = `${this.breakpoint}px`
      }
    },
    changeSelectedDropdownOption (e, option) {
      e.stopPropagation()
      this.selectedDropdownOption = option
      this.showDropdown = false
      if (option == this.dropdownOptions[0]) {
        this.showFeedback = true
      } else {
        this.showFeedback = false
      }
      // const tabName = option == 'Feedbacks' ? 'feedbacks' : 'studies'
      // this.$emit('changeTab', tabName)
    },
    highlightSection (section) {
      if (section) {
        const element = document.getElementById(section)
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        element.classList.add('tw-bg-blue-100/50')
        // document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth', block: 'center' }).style.border = '1px solid red'
      }
    },
    RemoveHighlightedSection (section) {
      if (section) {
        const element = document.getElementById(section)
        element.classList.remove('tw-bg-blue-100/50')
        // element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        // element.style.border = 'none'
        // document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth', block: 'center' }).style.border = '1px solid red'
      }
    },
    getReadableDate (data) {
      if (!data.createdAt) return
      const date = new Date(data.createdAt)
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },
    toggleShowMembers () {
      this.showMembers = !this.showMembers
    },
    toggleEditing () {
      this.showEditing = !this.showEditing
    },
    showEditingFeedbackForm (feedbackId, feedback) {
      this.feedbackToEditId = feedbackId
      this.feedbackToEdit = feedback
      this.showEditing = true
    },
    saveTextFeedback () {
      const currentURL = window.location.href
      const params = new URL(currentURL)
      const guestToken = params.searchParams.get('guest_token')

      this.$axios
        .post('/feedbacks', {
          explanation_type: 'text',
          transcript: this.feedback,
          user_id: this.currentUser.id,
          version_id: this.version.id,
          guest_token: guestToken
        })
        .then((response) => {
          // console.log(this.currentUserFeedbacks)
          this.currentUserFeedbacks = [
            ...this.currentUserFeedbacks,
            { ...response.data, createdAt: response.data.created_at }
          ]
          // console.log(response.data)
          this.feedback = ''
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
