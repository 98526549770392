<template>
  <div

    class="tw-w-[720px]  tw-mx-auto tw-my-28"
  >
    <p class="tw-text-center tw-text-[30px] tw-text-territory-dark tw-font-semibold">
      How it works
    </p>
    <section class="tw-text-gray-600 tw-body-font tw-my-6">
      <div class="tw-container tw-px-5 tw-mx-auto tw-flex tw-flex-wrap">
        <div class="tw-flex tw-flex-wrap tw-w-full">
          <div class="tw-lg:w-2/5 tw-md:w-1/2 tw-md:pr-10 tw-md:py-6">
            <div class="tw-flex tw-items-center tw-relative tw-pb-8">
              <div class="tw-h-full tw-w-20 tw-mt-5 tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                <div class="tw-h-full tw-w-1 tw-bg-[#BAD2BC] tw-pointer-events-none" />
              </div>
              <div class="tw-flex-shrink-0  tw-rounded-full  tw-inline-flex tw-items-center tw-justify-center tw-text-white tw-relative tw-z-10">
                <img
                  :src="reportIcon"
                  class="tw-w-[76px] tw-h-[70px]"
                  alt=""
                >
              </div>
              <div class="tw-flex-grow tw-pl-10">
                <p class="tw-font-semibold tw-text-lg tw-title-font tw-text-black-two  tw-tracking-wider">
                  Generate a report
                </p>
              </div>
            </div>
            <div class="tw-flex tw-items-center tw-relative tw-pb-8">
              <div class="tw-h-full tw-w-20 tw-mt-5 tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                <div class="tw-h-full tw-w-1 tw-bg-[#BAD2BC] tw-pointer-events-none" />
              </div>
              <div class="tw-flex-shrink-0  tw-rounded-full  tw-inline-flex tw-items-center tw-justify-center tw-text-white tw-relative tw-z-10">
                <img
                  class="tw-w-[76px] tw-h-[70px]"
                  :src="reportIcon"
                  alt=""
                >
              </div>
              <div class="tw-flex-grow tw-pl-10">
                <p class="tw-font-semibold tw-text-lg tw-title-font tw-text-black-two  tw-tracking-wider">
                  Edit your Report
                </p>
              </div>
            </div>
            <div class="tw-flex tw-items-center tw-relative tw-pb-8">
              <div class="tw-h-full tw-w-20 tw-mt-5 tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                <div class="tw-h-full tw-w-1 tw-bg-[#BAD2BC] tw-pointer-events-none" />
              </div>
              <div class="tw-flex-shrink-0  tw-rounded-full  tw-inline-flex tw-items-center tw-justify-center tw-text-white tw-relative tw-z-10">
                <img
                  class="tw-w-[76px] tw-h-[70px]"
                  :src="reportIcon"
                  alt=""
                >
              </div>
              <div class="tw-flex-grow tw-pl-10">
                <p class="tw-font-semibold tw-text-lg tw-title-font tw-text-black-two  tw-tracking-wider">
                  Share your report for feedback
                </p>
              </div>
            </div>
            <div class="tw-flex tw-items-center tw-relative tw-pb-8">
              <div class="tw-h-full tw-w-20 tw-mt-5 tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                <div class="tw-h-full tw-w-1 tw-bg-[#BAD2BC] tw-pointer-events-none" />
              </div>
              <div class="tw-flex-shrink-0  tw-rounded-full  tw-inline-flex tw-items-center tw-justify-center tw-text-white tw-relative tw-z-10">
                <img
                  :src="reportIcon"
                  class="tw-w-[76px] tw-h-[70px]"
                  alt=""
                >
              </div>
              <div class="tw-flex-grow tw-pl-10">
                <p class="tw-font-semibold tw-text-lg tw-title-font tw-text-black-two  tw-tracking-wider">
                  Toggle feedback for different versions
                </p>
              </div>
            </div>
            <!-- Repeat similar blocks for STEP 3 and STEP 4 -->
            <div class="tw-flex tw-items-center tw-relative tw-pb-8">
              <div class="tw-flex-shrink-0  tw-rounded-full  tw-inline-flex tw-items-center tw-justify-center tw-text-white tw-relative tw-z-10">
                <img
                  class="tw-w-[76px] tw-h-[70px]"
                  :src="reportIcon"
                  alt=""
                >
              </div>
              <div class="tw-flex-grow tw-pl-10">
                <p class="tw-font-semibold tw-text-lg tw-title-font tw-text-black-two  tw-tracking-wider">
                  Export version(s) you want to use
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-mt-5 tw-flex tw-justify-center">
        <ah-button
          size="large"
          @click="hideThisPage"
        >
          Create report
        </ah-button>
      </div>
    </section>
  </div>
</template>

<script>
import { AhButton } from '../../common'
import ReportIcon from '../../../../assets/images/reports-icon.png'
export default {
  components: {
    AhButton
  },
  props: {

  },
  data () {
    return {
      reportIcon: ReportIcon
    }
  },

  mounted () {

  },

  methods: {
    hideThisPage () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
