<template>
  <div class="st-insights-transcripts__blocks-container">
    <div class="flex justify-between my-7 px-2 ">
      <span class="font-14p font-600">{{ responsesPassingParticipantCheckboxFilter.length }} Participant</span>
      <div>
        <span>Avg. Sentiment</span>
      </div>
    </div>
    <div
      v-for="(response) in responsesPassingParticipantCheckboxFilter"
      :key="'response-' + response.id"
      ref="stInsightsTranscriptsBlock"
      class="st-insights-transcripts__block"
    >
      <div class="st-insights-transcripts__avatar-and-sentiment">
        <div class="st-insights-transcripts__avatar-and-name">
          <participant-avatar
            class="st-insights-transcripts__avatar"
            :participant="response"
          />
          <p class="st-insights-transcripts__name">
            {{ response.fullName || 'Anonymous' }}
          </p>
        </div>
        <div :class="[{'st-insights-transcripts__sentiment': true}, keywordColor(response.sentimentScore)]">
          {{ keywordBucket(response.sentimentScore) }}
        </div>
      </div>
      <!-- <div
        v-if="editResponse && response.id === editResponse.id"
        class="st-insights-transcripts__edit-opinion-transcript"
      >
        <textarea
          ref="textarea"
          v-model="editTranscriptText"
          class="st-input st-virtual-tour-insights__full-transcript-input"
          type="text"
          placeholder=" "
          :style="{
            minHeight: textAreaHeight
          }"
          @input="reStyleMinHeight"
        />
        <div class="st-virtual-tour-specs__prompt-actions">
          <div
            class="st-virtual-tour-specs__prompt-action st-virtual-tour-specs__prompt-action--cancel"
            @click="editResponse=null"
          >
            Cancel
          </div>
          <div
            class="st-virtual-tour-specs__prompt-action"
            @click="saveChanges"
          >
            Save
          </div>
        </div>
      </div> -->
      <template>
        <p class="st-insights-transcripts__transcript">
          {{ response.transcript }}
        </p>
        <p class="st-insights-transcripts__transcript">
          <span class="font-bold text-black">GPT REPLY</span>: {{ response.openaiResponse }}
        </p>
        <div
          class="st-virtual-tour-insights__edit-transcript flex justify-center items-center mr-20p space-x-2"
          @click="openEditAIResponse(response)"
        >
          <icon-wrapper icon-name="edit" />
          <span>Edit generated response</span>
        </div>
        <div
          class="st-virtual-tour-insights__edit-transcript flex justify-center items-center mr-20p space-x-2"
          @click="regenerateAIResponse(response)"
        >
          <icon-wrapper icon-name="edit" />
          <span>Regenerate response</span>
        </div>

        <div
          v-if="response.srcUrl"
          class="st-virtual-tour-insights__transcript-actions flex  space-x-5"
        >
          <div
            class="st-virtual-tour-insights__edit-transcript flex justify-center items-center mr-20p space-x-2"
            @click="openEditMode(response)"
          >
            <icon-wrapper icon-name="edit" />
            <span>Edit transcript</span>
          </div>
          <div
            class="st-virtual-tour-insights__play-audio-transcript flex justify-center items-center ml-20p space-x-2"
            @click="playTranscript(response)"
          >
            <icon-wrapper
              icon-name="play"
              class="st-virtual-tour-insights__play-audio-transcript-icon"
            />
            <span>Listen</span>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="activeAudioUrl"
      :class="{
        'st-virtual-tour-insights__audio-bar': true,
        'st-virtual-tour-insights__audio-bar--panel-open': activePanelSection
      }"
    >
      <audio
        ref="transcriptAudio"
        class="st-virtual-tour-insights__full-audio"
        autoplay
        controls
        @timeupdate="handleTimeUpdate"
      >
        <source
          :src="activeAudioUrl"
          :type="activeAudioContentType"
        >
        <!-- <source
          :src="activeAudioUrl"
          type="audio/mpeg"
        > -->
        <source
          :src="activeAudioUrl"
          type="audio/ogg"
        >
        <source
          v-if="activeAudioFlacUrl"
          :src="activeAudioFlacUrl"
          :type="activeAudioFlacContentType"
        />
      </audio>
    </div>
    <st-modal v-model="showModal">
      <template v-slot:modalTitle>
        <span class="font-18p font-bold">

          Edit transcript
        </span>
      </template>
      <template v-slot:modalActions>
        <textarea
          ref="textarea"
          v-model="editTranscriptText"
          class="w-full rounded-4p px-2-important py-3 mb-3"
          type="text"
          placeholder=" "
          :style="{
            minHeight: textAreaHeight
          }"
          @input="reStyleMinHeight"
        />

        <div class="flex space-x-2 justify-end">
          <button
            class="bg-gray-100 text-gray-two font-600 rounded-4p p-3 "
            @click="hideAIModal"
          >
            Cancel
          </button>
          <button
            class="bg-blue-primary  font-600 p-3 rounded-4p  text-white"
            @click="saveChanges"
          >
            Save changes
          </button>
        </div>
      </template>
    </st-modal>
    <st-modal v-model="showOpenAIResponseModal">
      <template v-slot:modalTitle>
        <span class="font-18p font-bold">

          Edit OPEN AI response
        </span>
      </template>
      <template v-slot:modalActions>
        <textarea
          ref="textarea"
          v-model="editAIResponseText"
          class="w-full rounded-4p px-2-important py-3 mb-3"
          type="text"
          placeholder="edit response"
          :style="{
            minHeight: textAreaHeight
          }"
        />
        <!-- @input="reStyleMinHeight" -->

        <div class="flex space-x-2 justify-end">
          <button
            class="bg-gray-100 text-gray-two font-600 rounded-4p p-3 "
            @click="hideAIModal"
          >
            Cancel
          </button>
          <button
            class="bg-blue-primary  font-600 p-3 rounded-4p  text-white"
            @click="saveNewReply"
          >
            Save changes
          </button>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { kebabCase } from 'lodash'
import { bucketIdx } from '../../../../utilities/data_utilities'
import ParticipantAvatar from '../participant-avatar'

import { IconWrapper, StModal } from '../../../common'

const bucketNames = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

export default {
  components: {
    IconWrapper,
    ParticipantAvatar,
    StModal
  },
  props: {
  },
  data: function () {
    return {
      activeResponse: {},
      editResponse: null,
      editedResponseIds: [],
      editTranscriptText: '',
      editAIResponseText: '',
      anyChanges: false,
      currentAudioTime: 0,
      textAreaHeight: '',
      showModal: false,
      showOpenAIResponseModal: false
    }
  },
  computed: {
    ...mapState('insights', ['activePanelSection']),
    ...mapGetters('insights', ['responsesPassingParticipantCheckboxFilter']),
    activeAudioFlacUrl () {
      return this.activeResponse.mpegSrcUrl
    },
    activeAudioFlacContentType() {
      return this.activeResponse.mpegContentType;
    },
    activeAudioUrl () {
      return this.activeResponse.srcUrl
    },
    activeAudioContentType () {
      return this.activeResponse.contentType
    }
  },
  mounted () {
    if (this.$refs.stInsightsTranscriptsBlock && this.$refs.stInsightsTranscriptsBlock.length > 0) {
      this.$refs.stInsightsTranscriptsBlock[0].scrollIntoView({
        block: 'center',
        behavior: 'instant'
      })
    }
  },
  beforeDestroy () {
    if (!this.anyChanges) { return }

    this.editedResponseIds.forEach(responseId => {
      this.$axios.patch(`/question_responses/${responseId}/reanalyze`)
        .then(res => console.log('success'))
        .catch(err => console.log('error reanalyzing', err))
    })
  },
  methods: {
    hideModal () {
      this.showModal = false
    },
    hideAIModal () {
      this.showOpenAIResponseModal = false
    },
    avatarStyle (avatar) {
      return avatar ? { 'background-image': `url('${avatar}')` } : {}
    },
    keywordBucket (sentiment) {
      return bucketNames[bucketIdx(sentiment)]
    },
    keywordColor (sentiment) {
      return { [`st-sentiment-badge--${kebabCase(this.keywordBucket(sentiment))}`]: true }
    },
    playTranscript (response) {
      if (response.srcUrl !== this.activeAudioUrl) {
        this.activeResponse = response
        this.$nextTick(() => {
          this.$refs.transcriptAudio.load()
        })
      }
    },
    openEditAIResponse (response) {
      console.log(response)
      this.editResponse = response
      this.showOpenAIResponseModal = true
      this.editAIResponseText = response.openaiResponse
      // if (this.activeAudioUrl !== response.audioUrl) {
      //   this.activeAudioUrl = response.srcUrl
      //   if (response.srcUrl) {
      //     this.$nextTick(() => {
      //       this.$refs.transcriptAudio.load()
      //     })
      //   }
      // }
      // this.$nextTick(() => {
      //   this.reStyleMinHeight()
      // })
    },
    regenerateAIResponse (response) {
      let url = `/question_responses/${response.id}/regenerate_openai_response`
      let data = {
        transcript: response.transcript
      }
      this.$axios.request({
        method: 'patch',
        url: url,
        data: data
      })
        .then(res => {
          this.$store.dispatch('insights/replaceEditedResponse', res.data)
          this.editedResponseIds.push(response.id)
          this.$nextTick(() => {
            this.editTranscriptText = ''
            this.editResponse = null
          })
        })
        .catch(err => {
          console.error('err', err)
        })
    },
    openEditMode (response) {
      this.editResponse = response
      this.showModal = true
      this.editTranscriptText = response.transcript
      if (this.activeAudioUrl !== response.audioUrl) {
        this.activeAudioUrl = response.srcUrl
        if (response.srcUrl) {
          this.$nextTick(() => {
            this.$refs.transcriptAudio.load()
          })
        }
      }
      this.$nextTick(() => {
        this.reStyleMinHeight()
      })
    },
    exitEditMode () {
      this.editResponse = null
      this.editTranscriptText = ''
    },
    handleTimeUpdate (ev) {
      this.currentAudioTime = ev.srcElement.currentTime
    },
    saveChanges () {
      this.showModal = false
      this.anyChanges = true
      let url = `/question_responses/${this.editResponse.id}/opinion_transcript`
      let data = {
        transcript: this.editTranscriptText
      }
      this.$axios.request({
        method: 'patch',
        url: url,
        data: data
      })
        .then(res => {
          this.$store.dispatch('insights/replaceEditedResponse', res.data)
          this.editedResponseIds.push(this.editResponse.id)
          this.$nextTick(() => {
            this.editTranscriptText = ''
            this.editResponse = null
          })
        })
        .catch(err => {
          console.error('err', err)
        })
    },
    saveNewReply () {
      this.showOpenAIResponseModal = false
      this.anyChanges = true
      let url = `/question_responses/${this.editResponse.id}/openai_response`
      console.log('function is running-- url', url)

      let data = {
        openai_response: this.editAIResponseText
      }
      this.$axios.request({
        method: 'patch',
        url: url,
        data: data
      })
        .then(res => {
          this.$store.dispatch('insights/replaceEditedResponse', res.data)
          this.editedResponseIds.push(this.editResponse.id)
          this.$nextTick(() => {
            this.editAIResponseText = ''
            this.editResponse = null
          })
        })
        .catch(err => {
          console.error('err', err)
        })
    },
    reStyleMinHeight () {
      this.textAreaHeight = this.$refs.textarea[0].scrollHeight + 'px'
    }
  }
}
</script>
