<template>
  <div class="tw-rounded-md tw-overflow-hidden tw-flex  tw-w-[100px] md:tw-w-auto">
    <!-- class="st-sentiment-badge" -->
    <!-- <p
      :class="sentimentClass"
      class="tw-bg-red-100 tw-min-w-[200px] tw-flex"
    >
      <span
        v-for="(sentiment, index) in sentiments"
        :key="sentiment+'key'"
        class="tw-block"
      >
        {{ sentiment }}
      </span>
    </p> -->
    <div
      v-for="(sentiment, index) in sentiments.reverse()"
      :key="sentiment+'key'+index"
      :style="{
        backgroundColor: colors[index],
        width: `${(sentiment / mentions) * 100}%`
      }"
      class="bar-segment"
    >
      <!-- {{ sentiment }} -->
    </div>
  </div>
</template>

<script>
import { bucketIdx } from '../../../utilities/data_utilities'

const SENTIMENTS = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

const SENTIMENT_CLASSES = [
  'st-sentiment-badge--very-negative',
  'st-sentiment-badge--negative',
  'st-sentiment-badge--neutral',
  'st-sentiment-badge--positive',
  'st-sentiment-badge--very-positive'
]

export default {
  props: {
    mentions: {
      type: Number,
      required: true
    },
    sentiments: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      // sentiments: [2, 4, 6, 4, 8],
      colors: [
        '#AC5555',
        '#F9776F',
        '#E9E9E9',
        '#7ECD87',
        '#667E6E'
      ]
    }
  },
  computed: {
    sentimentClass () {
      return SENTIMENT_CLASSES[bucketIdx(this.sentiment)]
    },
    sentimentString () {
      return SENTIMENTS[bucketIdx(this.sentiment)]
    }

  },
  mounted () {
    // console.log(this.sentiments, this.mentions)
  },
  methods: {
    barWidth (count) {
      return { width: (count / this.mentions) * 100 + '%' }
    }
  }
}
</script>
<style scoped>
.bar-segment {
  display: block;
  height: 12px;
  overflow: hidden;
  /* border-right: 1px solid #000; */
  white-space: nowrap;
}
</style>
