<template>
  <div
    v-if="showVoiceRecorder"
    class="tw-min-w-[100vw] tw-left-0 tw-h-screen tw-absolute tw-top-0 tw-bg-black/80 tw-z-[999]"
    @click="stopCounting"
  >
    <div
      class="tw-w-[380px] tw-flex tw-space-x-3 tw-absolute tw-bottom-5 tw-right-2 tw-items-center "
      @click="(e) => e.stopPropagation()"
    >
      <div
        class="tw-bg-white tw-rounded-xl tw-w-full tw-p-3 tw-flex tw-space-x-3 tw-items-center tw-justify-between"
      >
        <span
          v-if="!readyToSubmit"
          class="tw-text-black-three tw-flex tw-items-center tw-space-x-2"
        >
          <svg
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="4"
              cy="4.5"
              r="4"
              fill="#B71E1E"
            />
          </svg>

          <span class="">{{ mins < 9 ? `0${mins}` : mins }}</span>:<span>{{ seconds < 10 ? `0${seconds}` : seconds }}</span>
        </span>
        <!-- <canvas
          v-if="isVisualizerVisible"
          ref="audioCanvas"
          :width="500"
          :height="100"
          :class="{
            'st-opinion-metric__audio-visualizer': true,
          }"
        /> -->
        <audio
          v-else-if="readyToSubmit"
          ref="audioPlayer"
          controls
        />
        <div
          class="tw-cursor-pointer"
          @click="stopCounting"
        >
          Cancel
        </div>
        <div class="tw-flex tw-space-x-2 tw-items-center">
          <div
            v-if="!pausedRecording"
            class="tw-p-2 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-bg-white tw-border tw-border-white-two tw-border-solid tw-rounded-full"
            @click="pauseCurrentRecording($event)"
          >
            <span
              v-if="!readyToSubmit"
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13.1667H6.66667V3.83334H4V13.1667ZM9.33333 3.83334V13.1667H12V3.83334H9.33333Z"
                  fill="#474747"
                />
              </svg>

            </span>
          </div>
          <div
            v-else-if="pausedRecording"
            class="tw-p-2 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-bg-white tw-border tw-border-white-two tw-border-solid tw-rounded-full"
            @click="resumeCurrentRecording"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1326_4161)">
                <path
                  d="M2 7.83337H3.5C3.5 9.26232 4.35757 10.5831 5.75 11.2974C7.14243 12.0117 8.85757 12.0117 10.25 11.2974C11.6424 10.5827 12.5 9.26232 12.5 7.83337H14C13.9987 9.13165 13.4647 10.3846 12.4983 11.3579C11.5319 12.3313 10.199 12.9576 8.75 13.1198V15.1667H11V16.5H5V15.1667H7.25V13.1198C5.801 12.9576 4.46814 12.3313 3.50171 11.3579C2.53529 10.3846 2.00129 9.13165 2 7.83337ZM4.25 7.83337V3.83337C4.25 2.64251 4.96486 1.54194 6.125 0.94651C7.28514 0.351082 8.71486 0.351082 9.875 0.94651C11.0351 1.54194 11.75 2.64251 11.75 3.83337V7.83337C11.75 9.02423 11.0351 10.1248 9.875 10.7202C8.71486 11.3157 7.28514 11.3157 6.125 10.7202C4.96486 10.1248 4.25 9.02423 4.25 7.83337ZM5.75 7.83337C5.75 8.54803 6.179 9.20823 6.875 9.56518C7.571 9.92251 8.429 9.92251 9.125 9.56518C9.821 9.20784 10.25 8.54765 10.25 7.83337H8.75V6.50003H10.25V4.50003H8.75V3.1667H10.115C9.87329 2.57394 9.33071 2.11756 8.65229 1.9347C7.97343 1.75184 7.23757 1.86461 6.66629 2.23794C6.095 2.61127 5.75557 3.20289 5.75 3.83337V7.83337Z"
                  fill="#474747"
                />
              </g>
              <defs>
                <clipPath id="clip0_1326_4161">
                  <rect
                    width="12"
                    height="16"
                    fill="white"
                    transform="translate(2 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            class="tw-p-3 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-bg-primary tw-rounded-full"
            @click="submitRecording"
          >
            <span
              v-if="!readyToSubmit"
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66675 8.50002L3.60675 9.44002L7.33342 5.72002V13.8334H8.66675V5.72002L12.3867 9.44669L13.3334 8.50002L8.00008 3.16669L2.66675 8.50002Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <!-- <div>
        <svg
          class="tw-cursor-pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="stopCounting"
        >
          <rect
            x="0.5"
            y="0.5"
            width="31"
            height="31"
            rx="15.5"
            fill="#B71E1E"
          />
          <rect
            x="0.5"
            y="0.5"
            width="31"
            height="31"
            rx="15.5"
            stroke="#ECECEC"
          />
          <g clip-path="url(#clip0_316_2824)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.0002 24C11.5817 24 8 20.4182 8 16C8 11.5818 11.5817 8 16.0002 8C20.4188 8 24 11.5818 24 16C24 20.4187 20.4183 24 16.0002 24ZM16.0321 9.93505C12.6822 9.91795 9.95304 12.6191 9.93546 15.9691C9.91837 19.3186 12.6195 22.0483 15.9689 22.0659C19.3183 22.083 22.0479 19.3813 22.0655 16.0318C22.0831 12.6818 19.3815 9.95215 16.0321 9.93505ZM19.2523 19.0294L19.0267 19.255C18.7089 19.5728 18.1936 19.5728 17.8763 19.255L15.9827 17.3613L14.089 19.255C13.7712 19.5728 13.2564 19.5728 12.9386 19.255L12.713 19.0294C12.3953 18.7116 12.3953 18.1963 12.713 17.879L14.6067 15.9853L12.713 14.0916C12.3953 13.7738 12.3953 13.2584 12.713 12.9412L12.9386 12.7155C13.2564 12.3978 13.7717 12.3978 14.089 12.7155L15.9827 14.6093L17.8763 12.7155C18.1941 12.3978 18.7094 12.3978 19.0267 12.7155L19.2523 12.9412C19.57 13.2589 19.57 13.7743 19.2523 14.0916L17.3586 15.9853L19.2523 17.879C19.57 18.1968 19.57 18.7121 19.2523 19.0294Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_316_2824">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(8 8)"
              />
            </clipPath>
          </defs>
        </svg>
      </div> -->
    </div>
  </div>
  <div
    v-else
    class="tw-flex-1 tw-flex tw-flex-col"
  >
    <template v-if="showFeedback">
      <feedback-time
        v-if="isParentLoaded"
        :feedback-time-expired="feedbackTimeExpired"
        :hours-remaining="hoursRemaining"
        :minutes-remaining="minutesRemaining"
        :show-extend-button="true"
        :extend-time-handler="showExtendTimeHandler"
        :show-share-button="true"
        :sharing-handler="sharingHandler"
      />

      <div
        class="tw-flex tw-sticky tw-top-0 tw-bg-white tw-z-10 tw-justify-between  tw-px-8 tw-items-center tw-py-3 tw-border-y tw-border-solid tw-border-white-two"
      >
        <div class="tw-flex tw-justify-between  tw-w-full">
          <div
            id="colleague-sources-view"
            class="tw-flex tw-cursor-pointer relative tw-space-x-2"
            @click.stop="toggleDropdownOptions"
          >
            <ah-button type="outlined">
              <span class="tw-space-x-0.5 tw-min-w-fit">
                <span>
                  {{ selectedDropdownOption }}
                </span>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_393_1239)">
                    <path
                      d="M5.01197 5.50036C4.83774 5.50036 4.66651 5.43667 4.54035 5.32086L0.698222 1.60922C0.43387 1.35734 0.43387 0.946223 0.695218 0.691446C0.956567 0.436669 1.38314 0.436669 1.64749 0.688551L5.01498 3.94564L8.37946 0.688551C8.65283 0.44825 9.07639 0.468516 9.32573 0.731979C9.55703 0.97807 9.55703 1.35445 9.32573 1.60054L5.4836 5.32086C5.35743 5.43667 5.18621 5.50326 5.01197 5.50036Z"
                      fill="#474747"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_393_1239">
                      <rect
                        width="5"
                        height="9"
                        fill="white"
                        transform="translate(9.5 0.5) rotate(90)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </ah-button>
            <div
              v-if="showDropdown"
              class="flex flex-col justify-center !tw-min-w-fit !tw-w-[170px] !tw-text-sm !tw-right-auto tw-left-0 !tw-top-10 cursor-pointer tw-overflow-hidden absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
            >
              <span
                v-for="(option) in dropdownOptions"
                :key="option"
                class=""
                @click="changeSelectedDropdownOption($event, option)"
              >
                {{ option }}
              </span>
            </div>
          </div>
          <div
            v-if="showFeedbacks"
            class="tw-flex tw-space-x-3 tw-text-sm tw-items-center"
          >
            <span>
              Show colleagues
            </span>
            <ah-toggle-switch
              id="members-toggle"
              class="tw-ml-2"
              :checked="showMembers"
              @input="toggleShowMembers"
            />
          </div>
        </div>
      </div>

      <div
        class="tw-flex tw-flex-col tw-justify-between tw-flex-1  tw-overflow-y-auto scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black "
      >
        <div class="tw-max-w-full">
          <div
            v-if="showFeedbacks"
            class="tw-py-1 "
          >
            <div
              v-for="(userFeedback, index) in userFeedbacks"
              :key="index + 'feedback'"
              class="tw-flex tw-flex-col tw-py-3 tw-px-8 tw-my-1 tw-border-b tw-border-solid tw-border-white-two"
            >
              <div v-if="showEditing && feedbackToEditId == userFeedback.id">
                <textarea
                  v-model="feedbackToEdit"
                  class="tw-w-full tw-h-28 tw-py-2  tw-px-3 tw-border tw-border-solid tw-border-white-two tw-rounded-xl tw-resize-none scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black"
                />
                <div class="tw-my-2">
                  <ah-button
                    type="outlined"
                    @click="showEditing = false"
                  >
                    Cancel
                  </ah-button>
                  <ah-button
                    type="secondary"
                    @click="updateFeedback"
                  >
                    Save changes
                  </ah-button>
                </div>
              </div>
              <template v-else>
                <div
                  @mouseover="highlightSection(userFeedback.section)"
                  @mouseleave="RemoveHighlightedSection(userFeedback.section)"
                >
                  <p
                    v-if="userFeedback.section"
                    class="tw-text-sm tw-font-semibold tw-pb-2 tw-capitalize"
                  >
                    {{ removeUnderScore(userFeedback.section) + ' :' }}
                  </p>
                  <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
                    <div

                      class="tw-flex tw-space-x-2  tw-w-full tw-items-center"
                    >
                      <template v-if="showMembers">
                        <img
                          v-if="userFeedback.feedbackUser.avatarUrl"
                          :src="userFeedback.feedbackUser.avatarUrl"
                          width="34"
                          height="34"
                          class="tw-object-cover tw-rounded-full"
                        >
                        <span
                          v-else
                          class="tw-rounded-full tw-w-8 tw-flex tw-justify-center tw-items-center tw-h-7 tw-text-sm tw-bg-blue-500 tw-text-white tw-capitalize"
                        >
                          {{ userFeedback.feedbackUser && userFeedback.feedbackUser.fullName && userFeedback.feedbackUser.fullName[0] }}
                          {{ userFeedback.feedbackGuestUser && userFeedback.feedbackGuestUser.fullName && userFeedback.feedbackGuestUser.fullName[0] }}
                        </span>
                      </template>
                      <div
                        class="tw-flex tw-w-full  tw-items-center"
                        :class="{
                          'tw-justify-end': !showMembers,
                          'tw-justify-between': showMembers
                        }"
                      >
                        <template v-if="showMembers">
                          <span
                            v-if="userFeedback.feedbackUser"
                            class="tw-text-[15px] tw-w-full tw-font-semibold tw-capitalize"
                          >
                            {{
                              userFeedback.feedbackUser.id == currentUser.id
                                ? "You"
                                : userFeedback.feedbackUser.fullName
                            }}
                            <span v-if="userFeedback.feedbackGuestUser">
                              {{
                                userFeedback.feedbackGuestUser && userFeedback.feedbackGuestUser.fullName && userFeedback.feedbackGuestUser.fullName
                              }}
                            </span>
                          </span>
                        </template>
                        <button
                          class="tw-flex tw-bg-white tw-relative tw-min-w-fit tw-justify-between tw-space-x-2 tw-py-1.5 tw-rounded-xl tw-px-2 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                          @click="showLevelDropdown(userFeedback.id)"
                        >
                          <span class="">
                            {{ getLevelText(userFeedback.level) }}
                          </span>
                          <span class="tw-text-end tw-block tw-pt-1">
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_930_2517)">
                                <path
                                  d="M8.50709 10.9945C8.33286 10.9945 8.16163 10.9308 8.03546 10.815L4.19334 7.10336C3.92899 6.85148 3.92899 6.44036 4.19034 6.18559C4.45168 5.93081 4.87825 5.93081 5.14261 6.18269L8.51009 9.43978L11.8746 6.18269C12.1479 5.94239 12.5715 5.96266 12.8208 6.22612C13.0522 6.47221 13.0522 6.84859 12.8208 7.09468L8.97872 10.815C8.85255 10.9308 8.68132 10.9974 8.50709 10.9945Z"
                                  fill="#474747"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_930_2517">
                                  <rect
                                    width="5"
                                    height="9"
                                    fill="white"
                                    transform="translate(12.9951 5.99414) rotate(90)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>

                          </span>
                          <div
                            v-if="showLevelDropdownId == userFeedback.id"
                            class="flex flex-col -tw-right-[0px] !tw-text-xs  justify-center cursor-pointer tw-border-[0.5px] tw-border-solid tw-border-white-two tw-rounded-lg tw-overflow-hidden absolute bg-white tw-shadow-lg !tw-ml-0 tw-min-w-full !tw-w-[124px] export-popup !tw-top-[35px] tw-border-t-0 tw-rounded-t-none !tw-text-left"
                          >
                            <template
                              v-for="(level, index) in [0, 1, 2]"
                            >
                              <span

                                :key="`level_${index}`"
                                class="!tw-p-2 tw-overflow-clip tw-max-h-10"
                                @click="
                                  updateFeedbackLevel(userFeedback.id, level)
                                "
                              >
                                {{ getLevelText(level) }}
                              </span>
                            </template>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="tw-flex tw-items-center tw-mt-2">
                      <span
                        v-if="
                          userFeedback.feedbackUser.id == currentUser.id &&
                            showOriginalCommentIds.includes(userFeedback.id)
                        "
                        class="tw-flex tw-items-center tw-space-x-3 tw-ml-2"
                      >
                        <svg
                          v-if="userFeedback.explanationType != 'audio'"
                          class="tw-cursor-pointer"
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          @click="
                            showEditingFeedbackForm(
                              userFeedback.id,
                              userFeedback.transcript
                            )
                          "
                        >
                          <g clip-path="url(#clip0_252_633)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.64375 12.25H11.875V10.8281H6.06562L4.64375 12.25ZM7.96484 2.65234L0.5 10.1172V12.25H2.63281L10.0977 4.78516L7.96484 2.65234ZM11.875 3.00781C11.875 1.83009 10.9203 0.875 9.74219 0.875L8.59717 2.02042L10.73 4.15323L11.875 3.00781Z"
                              fill="#747474"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_252_633">
                              <rect
                                width="11.375"
                                height="11.375"
                                fill="white"
                                transform="translate(0.5 0.875)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <svg
                          class="tw-cursor-pointer"
                          width="25"
                          height="26"
                          viewBox="0 0 25 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          @click="showDeleteFeedbackModal(userFeedback.id)"
                        >
                          <rect
                            y="0.5"
                            width="25"
                            height="25"
                            rx="12.5"
                            fill="white"
                          />
                          <g clip-path="url(#clip0_252_635)">
                            <path
                              d="M12.5 19.3438C15.934 19.3438 18.7222 16.5991 18.7222 13.2188C18.7222 9.83837 15.934 7.09375 12.5 7.09375C9.06596 7.09375 6.27777 9.83837 6.27777 13.2188C6.27777 16.5991 9.06596 19.3438 12.5 19.3438ZM12.5 8.17919C15.3197 8.17919 17.6195 10.4431 17.6195 13.2188C17.6195 15.9944 15.3197 18.2583 12.5 18.2583C9.6803 18.2583 7.38044 15.9944 7.38044 13.2188C7.38044 10.4431 9.6803 8.17919 12.5 8.17919Z"
                              fill="#747474"
                            />
                            <path
                              d="M10.4522 13.8393H14.5478C14.8471 13.8393 15.0991 13.5912 15.0991 13.2966C15.0991 13.002 14.8471 12.7539 14.5478 12.7539H10.4522C10.1529 12.7539 9.90082 13.002 9.90082 13.2966C9.90082 13.5912 10.1529 13.8393 10.4522 13.8393Z"
                              fill="#474747"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_252_635">
                              <rect
                                width="12.4444"
                                height="12.25"
                                fill="white"
                                transform="translate(6.27777 7.09375)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div class="tw-my-2 tw-space-x-2 tw-items-center tw-text-sm">
                    <span
                      class=""
                      :class="
                        !showOriginalCommentIds.includes(userFeedback.id)
                          ? 'tw-text-primary  tw-pointer-events-none'
                          : 'tw-text-black-one hover:tw-text-primary tw-cursor-pointer hover:tw-underline'
                      "
                      @click="addRemoveToOriginalComment(userFeedback.id)"
                    >
                      Summary
                    </span>
                    <span
                      class=""
                      :class="
                        !showOriginalCommentIds.includes(userFeedback.id)
                          ? 'tw-text-black-one tw-cursor-pointer hover:tw-text-primary  hover:tw-underline'
                          : 'tw-text-primary   tw-pointer-events-none'
                      "
                      @click="addRemoveToOriginalComment(userFeedback.id)"
                    >
                      Original comment
                    </span>
                  </div>
                  <p
                    v-if="showOriginalCommentIds.includes(userFeedback.id)"
                    class=" tw-text-sm"
                  >
                    <span v-if="userFeedback.transcript">
                      {{ userFeedback.transcript }}

                      <span
                        v-if="
                          userFeedback.explanationType == 'audio'
                        "
                        class="tw-py-2 tw-block"
                      >
                        <audio
                          controls
                          class="tw-h-10"
                        >
                          <source
                            :src="userFeedback.audioUrl || localAudioURL"
                            :type="userFeedback.contentType||'audio/mp3'"
                          >

                          Your browser does not support the audio element.
                        </audio>
                      </span>
                    </span>
                    <span v-else>
                      <span class="tw-py-1 tw-block">
                        No comment available
                      </span>
                      <span
                        v-if="
                          userFeedback.explanationType == 'audio'
                        "
                        class="tw-py-2 tw-block"
                      >
                        <audio
                          :ref="userFeedback.audioUrl? 'audioPlayer' : 'localAudioPlayer'"
                          controls
                          class="tw-h-10"
                        >
                          <source
                            :src="userFeedback.audioUrl || localAudioURL"
                            :type="userFeedback.contentType||'audio/mp3'"
                          >

                          Your browser does not support the audio element.
                        </audio>
                      </span>

                    </span>
                  </p>
                  <p
                    v-else
                    class=" tw-text-sm"
                  >
                    <span v-if="userFeedback.summary">
                      {{ userFeedback.summary }}
                    </span>
                    <span v-else>
                      No summary available
                    </span>
                  </p>
                </div>
              </template>
            </div>
            <div
              v-for="(user, index) in version.sharedUsers"
              :key="index + 'user'"
              class="tw-flex tw-flex-col "
            >
              <template v-if="!isFeedbackProvided(user.id) && showMembers">
                <div class="tw-py-3 tw-px-8 tw-my-1 tw-border-b tw-border-solid tw-border-white-two">
                  <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
                    <div
                      class="tw-flex tw-space-x-2  tw-w-full tw-items-center"
                    >
                      <span

                        class="tw-rounded-full tw-w-8 tw-flex tw-justify-center tw-items-center tw-h-7 tw-text-sm tw-bg-blue-500 tw-text-white tw-capitalize"
                      >
                        {{ user && user.fullName && user.fullName[0] }}
                      </span>
                      <div class="tw-flex tw-w-full  tw-justify-between tw-items-center">
                        <span
                          v-if="user"
                          class="tw-text-[15px] tw-w-full tw-font-semibold tw-capitalize"
                        >
                          {{
                            user.id == currentUser.id
                              ? "You"
                              : user.fullName
                          }}

                        </span>
                        <span
                          v-if="sendingReminderId==user.id"
                          class="tw-bg-white tw-relative tw-min-w-fit tw-w-fit tw-justify-between tw-space-x-2 tw-py-1.5 tw-rounded-xl tw-px-2 tw-items-center  tw-border tw-border-white-two tw-border-solid"
                        >
                          Sending reminder...
                        </span>
                        <span
                          v-else-if="reminderSentIds.includes(user.id)"
                          class="tw-bg-white tw-relative tw-min-w-fit tw-w-fit tw-justify-between tw-space-x-2 tw-py-1.5 tw-rounded-xl tw-px-2 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                        >
                          Reminder sent
                        </span>
                        <button
                          v-else
                          class="tw-flex tw-bg-white tw-relative tw-min-w-fit tw-w-fit tw-justify-between tw-space-x-2 tw-py-1.5 tw-rounded-xl tw-px-2 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                          @click="sendReminder(user.id)"
                        >
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1279_1472)">
                              <path
                                d="M16.0679 1.20674C16.0679 1.20674 16.0679 1.15535 16.0679 1.1268C16.0508 1.09253 16.0337 1.05827 16.0108 1.02401C15.9937 0.995461 15.9708 0.96691 15.948 0.944069L15.8623 0.875546C15.788 0.824155 15.7023 0.789893 15.611 0.778473C15.5424 0.761342 15.4739 0.761342 15.4053 0.778473C15.3767 0.778473 15.3425 0.778473 15.3139 0.778473H15.2739L0.456363 5.91766C0.250722 5.99189 0.107916 6.16891 0.0793545 6.3859C0.0450811 6.60859 0.142189 6.837 0.336406 6.95692L2.35854 8.25884V13.9119C2.35854 14.226 2.61559 14.483 2.92977 14.483H8.64201L9.84729 16.4873C9.95583 16.6643 10.15 16.7728 10.3557 16.7671H10.4128C10.6356 16.7442 10.8241 16.6015 10.8983 16.3902L16.0394 1.54364C16.0394 1.54364 16.0394 1.49225 16.0394 1.4637C16.0508 1.42944 16.0622 1.38947 16.0679 1.34949C16.0736 1.30381 16.0736 1.25242 16.0679 1.20674ZM1.94726 6.64285L13.5031 2.64571L6.83694 9.74921L3.24965 7.46512L1.94726 6.64285Z"
                                fill="#474747"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1279_1472">
                                <rect
                                  width="16"
                                  height="16"
                                  fill="white"
                                  transform="translate(0.0737305 0.767578)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>
                            Send reminder
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <p class="tw-mt-3 tw-text-black-three tw-text-sm tw-text-center">
                    No feedback provided
                  </p>
                </div>
              </template>
            </div>
          </div>
          <div v-else-if="!showFeedbacks && showThemes">
            <div
              v-for="(versionTheme, idx) in version.theme"
              :key="`${idx}_theme`"
              class="tw-py-4 tw-px-8 tw-my-1 tw-border-b tw-border-solid tw-border-white-two"
            >
              <p class=" tw-font-semibold tw-my-1 tw-capitalize">
                {{ versionTheme.heading }}
              </p>
              <p>
                {{ versionTheme.description }}
              </p>
            </div>
          </div>
          <div
            v-if="!showFeedbacks && !showThemes && showSources"
            class="tw-py-1"
          >
            <div
              v-for="study in strategy.surveys"
              :key="study.name"
              class="tw-px-8 tw-flex tw-justify-between tw-items-center tw-border-b tw-border-white-two tw-border-solid  tw-py-2"
            >
              <p class="tw-flex tw-flex-col ">
                <span class=" tw-text-base">
                  {{ study.name }}
                </span>
                <span class="tw-text-sm tw-mt-1 tw-space-x-1">
                  <svg
                    width="11"
                    height="13"
                    viewBox="0 0 11 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.50558 6.50013C7.15973 6.50013 8.45965 5.18022 8.45965 3.50006C8.45965 1.8199 7.15999 0.5 5.50558 0.5C3.85144 0.5 2.55151 1.82016 2.55151 3.50006C2.55151 5.17997 3.85144 6.50013 5.50558 6.50013ZM11 11.78C10.232 8.72003 7.15973 6.79995 4.14667 7.58012C2.13804 8.12012 0.542885 9.68019 0.0111674 11.78C-0.0478279 12.0801 0.129411 12.4401 0.48389 12.5H10.4093C10.7638 12.5 11 12.2601 11 11.9001V11.78Z"
                      fill="#A7AA93"
                    />
                  </svg>
                  <span class="">
                    {{ study.totalResponsesCompleted }}
                  </span>

                </span>
              </p>
            </div>
          </div>
        </div>
        <recording-feedback
          v-if="showFeedback && !showSources"
          :version="version"
          @audioResponseSubmitted="saveAudioResponse"
          @textResponseSubmitted="saveTextResponse"
        />
      </div>
    </template>
    <st-modal
      v-model="showExtendExpiryModal"
      @closeModal="showExtendExpiryModal = false"
    >
      <p class="tw-text-xl tw-font-semibold tw-py-3">
        Extend feedback deadline
      </p>
      <template v-slot:modalActions>
        <div class="st-publish-options-modal__instructions-container">
          <st-dropdown-menu
            caption="Select days"
            :initial-active-option="'1 day'"
            :full-width="true"
            :options="['1 day', '2 days', '3 days', '5 days', '10 days']"
            @updateOption="extendExpiryDate"
          />
        </div>
        <div class="tw-w-full tw-mt-4 tw-flex tw-justify-end">
          <ah-button
            type="secondary"
            @click="confirmExtendExpiry"
          >
            Confirm
          </ah-button>
        </div>
      </template>
    </st-modal>
    <st-modal
      v-if="deleteFeedbackModal"
      v-model="deleteFeedbackModal"
    >
      <h3 class="font-600">
        Delete comment
      </h3>
      <div class="tw-py-2">
        <p>
          After deleting this comment, you will not be able to recover it. Are
          you sure you want to proceed?
        </p>
      </div>
      <div class="flex justify-end mt-2">
        <button
          class="tw-px-3 tw-py-3 tw-rounded-lg tw-bg-error tw-text-white "
          @click="deleteFeedbackConfirmation"
        >
          Delete comment
        </button>
      </div>
    </st-modal>
  </div>
</template>

<script>
import {
  AhButton,
  StDropdownMenu,
  AhInput,
  StModal,
  AhToggleSwitch
} from '../../common'
import { mapState } from 'vuex'
import { getBlobDuration } from '../../../utilities/audio_utilities'
import SharedWith from './shared-with'
import feedbackTime from './feedback-time.vue'
import RecordingFeedback from './recording-feedback.vue'
import {
  audioRecorder,
  handlePreviewBack,
  isDesktop,
  previousResponse,
  submitQuestionAnswer
} from '../../../mixins'
export default {
  components: {
    AhButton,
    AhToggleSwitch,
    StDropdownMenu,
    AhInput,
    SharedWith,
    StModal,
    RecordingFeedback,
    feedbackTime
  },
  mixins: [
    audioRecorder,
    handlePreviewBack,
    isDesktop,
    previousResponse,
    submitQuestionAnswer
  ],
  props: {
    strategy: {
      type: Object,
      required: true
    },
    version: {
      type: Object,
      required: true
    },
    sharingHandler: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      activeRangeSliderId: null,
      showFeedback: true,
      feedbackTimeExpired: false,
      showEditing: false,
      showThemes: false,
      showSources: false,
      showLevelDropdownId: null,
      showFeedbacks: true,
      showMembers: true,
      maxHeight: 300, // Set your maximum height
      breakpoint: 300, // Set your breakpoint
      feedback: '',
      hoursRemaining: null,
      minutesRemaining: null,
      showOriginalComment: false,
      processFeedbackModal: false,
      saveAsNewStrategy: false,
      feedbackToEditId: null,
      feedbackToEdit: '',
      showExtendExpiryModal: false,
      userFeedbacks: [],
      deleteFeedbackModal: false,
      deleteFeedbackId: null,
      showVoiceRecorder: false,
      readyToSubmit: false,
      seconds: 0,
      mins: 0,
      showDropdown: false,
      showDefaultButtons: true,
      pausedRecording: false,
      showTextFeedback: false,
      versionName: '',
      dropdownOptions: ['Feedback by colleague', 'Feedback by theme', 'Sources'],
      selectedDropdownOption: 'Feedback by colleague',
      showOriginalCommentIds: [],
      showProcessFeedbackDropdown: false,
      saveAsNewVersion: false,
      emptyVersionNameError: null,
      isParentLoaded: false,
      localAudioURL: null,
      sendingReminderId: null,
      reminderSentIds: [],
      selectedExtendExpiry: '1 day'
    }
  },
  computed: {
    ...mapState(['currentUser'])
  },
  mounted () {
    this.userFeedbacks = this.version.feedbacks

    if (this.version.expiryEndAt) {
      this.calculateExpiryTime(this.version.expiryEndAt)
    }
    // console.log(this.hoursRemaining, this.minutesRemaining)

    setTimeout(() => {
      this.checkFeedbackTranscript(this.userFeedbacks.map((f) => f.id).filter((f) => !f.transcript || !f.summary))
    }, 1000)
  },

  methods: {
    calculateExpiryTime (endTime) {
      const expiryEndAt = new Date(endTime)
      const now = new Date()
      const diff = expiryEndAt - now
      if (diff < 0) {
        this.feedbackTimeExpired = true
      } else {
        // calculate the hours and minutes remaining in the feedback expiry
        const hours = Math.floor(diff / (1000 * 60 * 60))
        const minutes = Math.floor((diff / (1000 * 60)) % 60)
        this.hoursRemaining = hours
        this.minutesRemaining = minutes
      }
      this.isParentLoaded = true
    },
    saveTextResponse (response) {
      this.userFeedbacks = [
        ...this.userFeedbacks,
        { createdAt: response.created_at,
          explanationType: 'text',
          guestUserId: null,
          id: response.id,
          level: 0,
          summary: response.summary,
          section: response.section,
          transcript: response.transcript,

          feedbackUser: this.currentUser }
      ]
    },
    saveAudioResponse (response) {
      this.userFeedbacks = [
        ...this.userFeedbacks,
        { createdAt: response.created_at,
          explanationType: 'audio',
          guestUserId: null,
          id: response.id,
          level: 0,
          section: response.section,
          summary: response.summary,
          transcript: response.transcript,
          audioUrl: response.audio_url,
          feedbackUser: this.currentUser }
      ]
      // this.showOriginalCommentIds = [
      //   ...this.showOriginalCommentIds,
      //   response.id
      // ]

      this.showVoiceRecorder = false
      this.readyToSubmit = false
      this.checkFeedbackTranscript([response.id])
    },
    sendReminder (userId) {
      this.sendingReminderId = userId
      this.$axios
        .post(`/versions/${this.version.id}/send_reminder`, {
          user_id: userId
        })
        .then((response) => {
          this.reminderSentIds = [...this.reminderSentIds, userId]
          this.sendingReminderId = null
        })
        .catch((error) => {
          console.log(error)
          this.sendingReminderId = null
        })
    },
    getTooltipText (level) {
      switch (level) {
        case 0:
          return 'Skip'
        case 1:
          return 'Somewhat Important'
        case 2:
          return 'Very Important'
        case 3:
          return 'Crucial'
      }
    },
    showLevelDropdown (id) {
      if (this.showLevelDropdownId === id) {
        this.showLevelDropdownId = null
      } else {
        this.showLevelDropdownId = id
      }
    },
    getLevelText (level) {
      switch (level) {
        case 0:
          return 'Skip'
        case 1:
          return 'Weigh moderately'
        case 2:
          return 'Weigh heavily'
        default:
          return ''
      }
    },

    changeSelectedDropdownOption (e, option) {
      e.stopPropagation()
      this.selectedDropdownOption = option
      this.showDropdown = false
      if (option === this.dropdownOptions[0]) {
        this.showFeedbacks = true
        this.showThemes = false
        this.showSources = false
      } else if (option === this.dropdownOptions[1]) {
        this.showFeedbacks = false
        this.showThemes = true
        this.showSources = false
      } else if (option === this.dropdownOptions[2]) {
        this.showFeedbacks = false
        this.showThemes = false
        this.showSources = true
      }
    },
    showProcessFeedbackModal () {
      this.showProcessFeedbackDropdown = false
      this.processFeedbackModal = true
    },
    showSaveAsNewModal () {
      this.showProcessFeedbackDropdown = false
      this.saveAsNewStrategy = true
      this.processFeedbackModal = false
      this.saveAsNewVersion = true
      // console.log('save as new', this.saveAsNewVersion)
    },
    toggleDropdownOptions () {
      this.showDropdown = !this.showDropdown
    },
    processFeedbackConfirmation () {
      if (!this.versionName) {
        this.emptyVersionNameError = 'Please enter a version name'
      } else {
        this.emptyVersionNameError = ''
        this.$axios
          .post('/versions', {
            name: this.versionName,
            current_version_id: this.version.id,
            save_as_new: this.saveAsNewVersion
          })
          .then((response) => {
            console.log(response)
            this.$store.commit('toggleFullSpinner', true)
            window.location.href = `/reports`
          })
          .catch((error) => {
            console.log(error)
            this.emptyVersionNameError = 'The name has already been taken'
          })
      }
    },
    showExtendTimeHandler () {
      this.showExtendExpiryModal = true
    },
    updateFeedbackLevel (feedbackId, level) {
      this.$axios
        .patch(`/feedbacks/${feedbackId}`, {
          level
        })
        .then((response) => {
          // replace the feedback with the updated one
          this.userFeedbacks = this.userFeedbacks.map((feedback) => {
            if (feedback.id === feedbackId) {
              return {
                ...feedback,
                level: response.data.level
              }
            } else {
              return feedback
            }
          })
          // console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleProcessFeedbackDropdown () {
      this.showProcessFeedbackDropdown = !this.showProcessFeedbackDropdown
    },
    addRemoveToOriginalComment (id) {
      if (this.showOriginalCommentIds.includes(id)) {
        this.showOriginalCommentIds = this.showOriginalCommentIds.filter(
          (commentId) => commentId !== id
        )
      } else {
        this.showOriginalCommentIds = [...this.showOriginalCommentIds, id]
      }
    },

    isFeedbackProvided (userId) {
      return this.userFeedbacks.some((feedback) => feedback.feedbackUser.id === userId)
    },

    async checkFeedbackTranscript (feedbackIds) {
      const feedbacks = await Promise.all(
        feedbackIds.map((feedbackId) => this.fetchFeedback(feedbackId))
      )

      // with transcript and summary
      const feedbacksWithTranscriptAndSummary = feedbacks.filter(
        (feedback) => feedback.transcript && feedback.summary
      )

      if (feedbacksWithTranscriptAndSummary.length > 0) {
        this.userFeedbacks = this.userFeedbacks.map((feedback) => {
          const feedbackWithTranscriptAndSummary = feedbacksWithTranscriptAndSummary.find(
            (f) => f.id === feedback.id
          )

          if (feedbackWithTranscriptAndSummary) {
            return {
              ...feedback,
              transcript: feedbackWithTranscriptAndSummary.transcript,
              summary: feedbackWithTranscriptAndSummary.summary
            }
          }

          return feedback
        })
      }

      const feedbacksWithoutTranscriptAndSummary = feedbacks.filter(
        (feedback) => !feedback.transcript || !feedback.summary
      )

      if (feedbacksWithoutTranscriptAndSummary.length > 0) {
        await this.sleep(5000)
        this.checkFeedbackTranscript(
          feedbacksWithoutTranscriptAndSummary.map((feedback) => feedback.id)
        )
      }
    },
    async fetchFeedback (feedbackId) {
      const result = await this.$axios.request({
        method: 'get',
        url: `/feedbacks/${feedbackId}`
      })

      return result.data
    },
    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    extendExpiryDate (option) {
      this.selectedExtendExpiry = option
    },
    showDeleteFeedbackModal (feedbackId) {
      this.deleteFeedbackModal = true
      this.deleteFeedbackId = feedbackId
    },
    deleteFeedbackConfirmation () {
      this.$axios
        .delete(`/feedbacks/${this.deleteFeedbackId}`)
        .then((response) => {
          this.userFeedbacks = this.userFeedbacks.filter((feedback) => {
            return feedback.id !== this.deleteFeedbackId
          })
          // console.log(this.userFeedbacks)
          this.deleteFeedbackModal = false
          this.deleteFeedbackId = null
        })
        .catch((error) => {
          console.log(error)
        })
    },
    hideStudiesTab () {
      this.selectedDropdownOption = 'Feedback by colleague'
      this.showFeedback = true
      this.showFeedbacks = true
    },
    confirmExtendExpiry () {
      // add a patch request to versions/:id

      this.$axios
        .patch(`/versions/${this.strategy.versions[0].id}`, {
          expiry_duration: Number(this.selectedExtendExpiry.split(' ')[0])
        })
        .then((res) => {
          console.log('res', res)
          this.showExtendExpiryModal = false
          this.version.expiryEndAt = res.data.expiry_end_at
          this.calculateExpiryTime(res.data.expiry_end_at)
        })
        .catch((error) => {
          console.log('error', error)
        })

      this.showExtendExpiryModal = false
      this.feedbackTimeExpired = false
    },
    highlightSection (section) {
      if (section) {
        const element = document.getElementById(section)
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        element.classList.add('tw-bg-blue-100/50')
        // document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth', block: 'center' }).style.border = '1px solid red'
      }
    },
    RemoveHighlightedSection (section) {
      if (section) {
        const element = document.getElementById(section)
        element.classList.remove('tw-bg-blue-100/50')
        // element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        // element.style.border = 'none'
        // document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth', block: 'center' }).style.border = '1px solid red'
      }
    },
    updateFeedback () {
      this.$axios
        .patch(`/feedbacks/${this.feedbackToEditId}`, {
          transcript: this.feedbackToEdit
        })
        .then((response) => {
          this.userFeedbacks.find((feedback) => {
            if (feedback.id === this.feedbackToEditId) {
              feedback.transcript = this.feedbackToEdit
            }
          })
          this.showEditing = false
          this.feedbackToEditId = null
          this.feedbackToEdit = ''
        })
        .catch((error) => {
          console.log(error)
        })
    },
    adjustTextareaHeight () {
      const textarea = this.$refs.autoResizeTextarea
      textarea.style.height = 'auto'

      // Check if the current height is less than the maximum height
      if (textarea.scrollHeight <= this.maxHeight) {
        textarea.style.height = `${textarea.scrollHeight}px`
      } else {
        // Set the height to the breakpoint if it exceeds the maximum height
        textarea.style.height = `${this.breakpoint}px`
      }
    },
    toggleShowMembers () {
      this.showMembers = !this.showMembers
    },
    toggleEditing () {
      this.showEditing = !this.showEditing
    },

    showEditingFeedbackForm (feedbackId, feedback) {
      this.feedbackToEditId = feedbackId
      this.feedbackToEdit = feedback
      this.showEditing = true
    },
    removeUnderScore (text) {
      return text.replace(/_/g, ' ')
    },
    saveTextFeedback () {
      this.$axios
        .post('/feedbacks', {
          explanation_type: 'text',
          transcript: this.feedback,
          user_id: this.currentUser.id,
          version_id: this.version.id
        })
        .then((response) => {
          console.log(response.data)
          console.log(this.userFeedbacks)
          this.userFeedbacks = [
            ...this.userFeedbacks,
            { ...response.data, feedbackUser: this.currentUser }
          ]
          console.log(this.userFeedbacks)
          this.feedback = ''
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  margin-left: auto;
  margin-right: auto;
  -webkit-appearance: none;
  width: 98%;
  height: 8px;
  // background: #6b806e;
  outline: none;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  // border: 3px solid white;
  height: 20px;
  border-radius: 100%;
  background: #ededed;
  cursor: pointer;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #ededed;
  border-radius: 100%;
  cursor: pointer;
}
</style>
