import IconAddComment from './Icon_AddComment (master)'
import IconAddList from './Icon_AddList (master)'
import IconAddMember from './Icon_AddMember (master)'
import IconAddPrompt from './Icon_Add-Prompt (master)'
import IconAddVirtualRoom from './Icon_Add-Virtual-Room (master)'
import IconAgeCalendar from './Icon_Age-Calendar (master)'
import IconAlert from './Icon_Alert (master)'
import IconArchive from './Icon_Archive (master)'
import IconArrow from './Icon_Arrow (master)'
import IconArrowDown from './Icon_ArrowDown (master)'
import IconArrowUp from './Icon_ArrowUp (master)'
import IconArrowOG from './Icon_ArrowOG (master)'
import IconAvatar from './Icon_Avatar (master)'
import IconBilling from './Icon_Billing (master)'
import IconCalendar from './Icon_Calendar (master)'
import IconCancel from './Icon_Cancel (master)'
import IconCamera from './Icon_Camera (master)'
import IconCaretDown from './Icon_CaretDown (master)'
import IconCaretUp from './Icon_CaretUp (master)'
import IconChat from './Icon_Chat (master)'
import IconCheck from './Icon_Check (master)'
import IconCheckbox from './Icon_Checkbox'
import IconCheckmark from './Icon_CheckMark (master)'
import IconChevron from './Icon_Chevron (master)'
import IconDropdown from './Icon_Dropdown'
import IconClock from './Icon_Clock (master)'
import IconCompany from './Icon_Company (master)'
import IconContent from './Icon_Content (master)'
import IconConceptRanking from './Icon_ConceptRanking (master)'
import IconCreateFolder from './Icon_CreateFolder (master)'
import IconCsv from './Icon_CSVFile (master)'
import IconCustomLabel from './Icon_Custom-Label (master)'
import IconCustomLabelDisable from './Icon_Custom-Label-Disable (master)'
import IconDefaultIntercom from './Icon_DefaultIntercom (master)'
import IconDuplicate from './Icon_Duplicate (master)'
import IconDont from './Icon_Dont (master)'
import IconEducation from './Icon_Education (master)'
import IconEmojiDislike from './Icon_Emoji_Dislike (master)'
import IconEmojiLikeIt from './Icon_Emoji_Like-It (master)'
import IconEmojiHateIt from './Icon_Emoji_Hate-It (master)'
import IconEmojiIndifferent from './Icon_Emoji_Indifferent (master)'
import IconEmojiLoveIt from './Icon_Emoji_Love-It (master)'
import IconEdit from './Icon_Edit (master)'
import IconElementPreference from './Icon_ElementPreference (master)'
import IconExpandImage from './Icon_Expand-Image (master)'
import IconExport from './Icon_Export (master)'
import IconExportList from './Icon_ExportList (master)'
import IconFaq from './Icon_FAQ (master)'
import IconFinance from './Icon_Finance (master)'
import IconGender from './Icon_Gender (master)'
import IconHeart from './Icon_Heart (master)'
import IconHelp from './Icon_Help (master)'
import IconHome from './Icon_Home (master)'
import IconHidePassword from './Icon_View'
import IconHub from './Icon_Hub (master)'
import IconImageBoard from './Icon_ImageBoard (master)'
import IconImportCsv from './Icon_ImportCSV (master)'
import IconIncentives from './Icon_Incentives (master).vue'
import IconInfo from './Icon_Info (master)'
import IconInsights from './Icon_Insights (master)'
import IconKeyboard from './Icon_Keyboard (master)'
import IconLibraries from './Icon_Libraries (master)'
import IconLibrary from './Icon_Library (master)'
import IconLink from './Icon_Link (master)'
import IconList from './Icon_AudienceList (master)'
import IconLivePresentation from './Icon_Live-Presentation (master)'
import IconLocation from './Icon_Location (master)'
import IconMembers from './Icon_MultipleMembers (master)'
import IconMenu from './Icon_ExpandContractMenu (master)'
import IconMicrophone from './Icon_Microphone (master)'
import IconMicrophoneOG from './Icon_Microphone_OG (master)'
import IconMinus from './Icon_Minus (master)'
import IconModify from './Icon_Modify (master)'
import IconMove from './Icon_MoveMedia (master)'
import IconNext from './Icon_Next'
import IconNotes from './Icon_Notes (master)'
import IconNotifications from './Icon_Notifications (master)'
import IconOpinionMetric from './Icon_OpinionMetric (master)'
import IconOverflow from './Icon_OverflowMenu (master)'
import IconParticipants from './Icon_Participants (master)'
import IconPauseRecording from './Icon_PauseRecording (master)'
import IconPdfFile from './Icon_PDF-file (master)'
import IconPerceptionSlider from './Icon_PerceptionSliders (master)'
import IconPickFavorite from './Icon_PickFavorite (master)'
import IconPlane from './Icon_PaperPlane (master)'
import IconPlay from './Icon_Play (master)'
import IconPlayButton from './Icon_PlayButton (master)'
import IconPlayRecording from './Icon_PlayRecording (master)'
import IconPlus from './Icon_Add (master)'
import IconPointer from './Icon_Pointer (master)'
import IconPreferences from './Icon_Preferences (master)'
import IconPreferenceSlider from './Icon_PreferenceSlider (master)'
import IconPrevious from './Icon_Previous'
import IconProfile from './Icon_Profile (master)'
import IconProjects from './Icon_Projects (master)'
import IconPublish from './Icon_Publish (master)'
import IconQuestions from './Icon_Questions (master)'
import IconQuestionDescriptionToggle from './Icon_QuestionDescriptionToggle (master)'
import IconQuestionNameToggle from './Icon_QuestionNameToggle (master)'
import IconQuadrantPlacement from './Icon_QuadrantPlacement (master)'
import IconReceipt from './Icon_Receipt (master)'
import IconReplace from './Icon_Replace (master)'
import IconRestartRecording from './Icon_RestartRecording (master)'
import IconRequestAudience from './Icon_Request-Audience (master)'
import IconSave from './Icon_Save (master)'
import IconSearch from './Icon_Search (master)'
import IconSecondaryMic from './Icon_Secondary-Mic (master).vue'
import IconSegment from './Icon_Segment (master)'
import IconSettings from './Icon_Settings (master)'
import IconShare from './Icon_Share (master)'
import IconSignIn from './Icon_SignIn (master)'
import IconSignOut from './Icon_SignOut (master)'
import IconSignUp from './Icon_SignUp (master)'
import IconSlide from './Icon_Slide (master).vue'
import IconSpacetrics from './Icon_Spacetrics (master)'
// import IconSpatialAdjacencies from './'
import IconSpatialPreference from './Icon_SpatialPreference (master)'
import IconSpeechBubbles from './Icon_Speech-Bubbles (master).vue'
import IconStarOutline from './Icon_Star-Border (master)'
import IconStarFilled from './Icon_Star-Filled (master)'
import IconStopRecording from './Icon_StopRecording (master)'
import IconSurvey from './Icon_Surveys (master)'
import IconSurveyInsights from './Icon_Survey-Insight (master)'
import IconSurveyTemplate from './Icon_SurveyTemplate (master)'
import IconTeam from './Icon_Team (master)'
import IconTest from './Icon_Test (master)'
import IconTourMode from './Icon_TourMode (master)'
import IconTranscript from './Icon_Transcripts (master)'
import IconTrash from './Icon_Trash (master)'
import IconTutorial from './Icon_Tutorials (master)'
import IconUpdates from './Icon_Updates (master)'
import IconUpload from './Icon_Upload (master)'
import IconUploadPhoto from './Icon_UploadPhoto (master)'
import IconViewPassword from './Icon_View (master)'
import IconVirtualRoom from './Icon_Virtual-Room (master)'
import IconVirtualTour from './Icon_VirtualTour (master)'
import IconWelcome from './Icon_Welcome (master)'
import IconWorldLocation from './Icon_World-Location (master)'
import IconPayment from './Icon_Payment.vue'
import IconCode from './Icon_Code'
import IconFilter from './Icon_Filter'
import IconAddKeyword from './Icon_Add_Keyword'

export const iconMap = {
  'add-comment': IconAddComment,
  'add-list': IconAddList,
  'add-member': IconAddMember,
  'add-prompt': IconAddPrompt,
  'add-virtual-room': IconAddVirtualRoom,
  'age-calendar': IconAgeCalendar,
  'alert': IconAlert,
  'arrow': IconArrow,
  'arrow-down': IconArrowDown,
  'arrow-up': IconArrowUp,
  'arrow-og': IconArrowOG,
  'archive': IconArchive,
  'avatar': IconAvatar,
  'billing': IconBilling,
  'calendar': IconCalendar,
  'camera': IconCamera,
  'cancel': IconCancel,
  'caret-down': IconCaretDown,
  'caret-up': IconCaretUp,
  'chat': IconChat,
  'check': IconCheck,
  'checkbox': IconCheckbox,
  'check-mark': IconCheckmark,
  'chevron': IconChevron,
  'code-html': IconCode,
  'clock': IconClock,
  'company': IconCompany,
  'concept-ranking': IconConceptRanking,
  'create-folder': IconCreateFolder,
  'csv': IconCsv,
  'culture-board': IconImageBoard,
  'custom-label': IconCustomLabel,
  'custom-label-disable': IconCustomLabelDisable,
  'duplicate': IconDuplicate,
  'dont': IconDont,
  'education': IconEducation,
  'emoji-dislike': IconEmojiDislike,
  'emoji-like-it': IconEmojiLikeIt,
  'emoji-hate-it': IconEmojiHateIt,
  'emoji-indifferent': IconEmojiIndifferent,
  'emoji-love-it': IconEmojiLoveIt,
  'star-filled': IconStarFilled,
  'star-outline': IconStarOutline,
  'edit': IconEdit,
  'element-preference': IconElementPreference,
  'expand-contract-menu': IconMenu,
  'expand-image': IconExpandImage,
  'export': IconExport,
  'export-list': IconExportList,
  'faq': IconFaq,
  'file': IconLibrary,
  'finance': IconFinance,
  'gender': IconGender,
  'heart': IconHeart,
  'help': IconHelp,
  'hide-password': IconHidePassword,
  'home': IconHome,
  'hub': IconHub,
  'image-board': IconImageBoard,
  'import-csv': IconImportCsv,
  'incentives': IconIncentives,
  'info': IconInfo,
  'insights': IconInsights,
  'intercom': IconDefaultIntercom,
  'keyboard': IconKeyboard,
  'libraries': IconLibraries,
  'list': IconList,
  'live-presentation': IconLivePresentation,
  'location': IconLocation,
  'menu-icon': IconMenu,
  'members': IconMembers,
  'microphone': IconMicrophone,
  'microphone_og': IconMicrophoneOG,
  'minus': IconMinus,
  'modify': IconModify,
  'move': IconMove,
  'multiple-members': IconMembers,
  'next': IconNext,
  'notes': IconNotes,
  'notifications': IconNotifications,
  'opinion-metric': IconOpinionMetric,
  'overflow': IconOverflow,
  'paper-plane': IconPlane,
  'participants': IconParticipants,
  'pause-recording': IconPauseRecording,
  'pdf': IconPdfFile,
  'perception-slider': IconPerceptionSlider,
  'pick-favorite': IconPickFavorite,
  'play': IconPlay,
  'play-button': IconPlayButton,
  'play-recording': IconPlayRecording,
  'plus': IconPlus,
  'pointer': IconPointer,
  'preferences': IconPreferences,
  'preference-slider': IconPreferenceSlider,
  'previous': IconPrevious,
  'profile': IconProfile,
  'projects': IconProjects,
  'publish': IconPublish,
  'quadrant-placement': IconQuadrantPlacement,
  'question-description-toggle': IconQuestionDescriptionToggle,
  'question-name-toggle': IconQuestionNameToggle,
  'questions': IconQuestions,
  'receipt': IconReceipt,
  'replace': IconReplace,
  'restart-recording': IconRestartRecording,
  'request-audience': IconRequestAudience,
  'save': IconSave,
  'search': IconSearch,
  'secondary-microphone': IconSecondaryMic,
  'segment': IconSegment,
  'settings': IconSettings,
  'share': IconShare,
  'share-link': IconLink,
  'sign-in': IconSignIn,
  'sign-out': IconSignOut,
  'sign-up': IconSignUp,
  'slide': IconSlide,
  'spacetrics': IconSpacetrics,
  'spatial-adjacencies': IconSpatialPreference,
  'spatial-preference': IconSpatialPreference,
  'speech-bubbles': IconSpeechBubbles,
  'stop-recording': IconStopRecording,
  'survey': IconSurvey,
  'survey-content': IconContent,
  'survey-insights': IconSurveyInsights,
  'survey-template': IconSurveyTemplate,
  'team': IconTeam,
  'test': IconTest,
  'tour-mode': IconTourMode,
  'transcript': IconTranscript,
  'trash': IconTrash,
  'tutorial': IconTutorial,
  'updates': IconUpdates,
  'upload': IconUpload,
  'upload-photo': IconUploadPhoto,
  'view-password': IconViewPassword,
  'virtual-room': IconVirtualRoom,
  'virtual-tour': IconVirtualTour,
  'welcome': IconWelcome,
  'world-location': IconWorldLocation,
  'icon-filter': IconFilter,
  'add-keyword': IconAddKeyword,
  'icon-dropdown': IconDropdown,
  'icon-payment': IconPayment
}

export default iconMap

export const iconNames = new Set(Object.keys(iconMap))
