<template>
  <div
    class="tw-px-3 tw-py-2 tw-text-center tw-text-territory-dark tw-cursor-pointer tw-transition-all tw-duration-200 tw-w-full tw-bg-white tw-border-[3px] tw-text-sm tw-rounded-xl tw-border-solid"
    :class="{'tw-border-territory': selected,
             'tw-border-white-two hover:tw-border-territory hover:tw-border-[3px]': !selected,
             [classes]: classes
    }"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    classes: {
      type: String,
      required: false,
      default: ''
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }

  },
  data: function () {
    return {

    }
  },
  computed: {
  }
}
</script>
