<template>
  <div v-if="responses.length">
    <div
      class="st-bar-graph-stack__timeline-label flex items-center mt-8p mb-20p justify-between"
    >
      
    </div>
    <div class="flex items-center justify-between mt-4 flex-col md:flex-row">
      

      <div class="mt-3  md:mt-0">
        <p v-if="title" class="st-diverging-bar-graph__title">
          {{ title }}
        </p>
        <div class="st-diverging-bar-graph__key flex flex-col-reverse tw-mr-9 w-full">
          <div
            v-for="name, index in bucketNames"
            :key="name"
            class="st-diverging-bar-graph__key-color-and-value w-full py-1"
          >
            <div
              :class="[
                { 'st-diverging-bar-graph__key-color': true },
                sentimentColor(name),
              ]"
            />
            <p class="st-diverging-bar-graph__key-value text-12p">
              {{ name }} ({{ numberOfResponsesPerBucket[index] }})
            </p>
          </div>
          
        </div>
      </div>
      <!-- <div v-if="trend === 'STACK'" class="st-diverging-bar-graph__key">
    </div> -->
    <div v-if="trend === 'STACK' || defaultType==='STACK' || defaultType === 'STACKTRNEDLINE'" class="relative tw-ml-12 w-full">
      <line-graph v-if="showTrendLine || defaultType === 'STACKTRNEDLINE'" :averages="stackAverages" />
      <div class="st-bar-graph-stack__container">
        <div
          v-for="(d, i) in filteredSentiments"
          :key="i"
          class="st-bar-graph-stack__stack-container "
        >
          <span class="st-bar-graph-stack__stack-label text-13p flex flex-col"
            >{{ stackLabel(d, i)["weekNo"] }}
            <span class="text-16p my-1">
              {{ stackLabel(d, i)["date"] }}
            </span>
          </span>

          <div
            v-for="(score, j) in d.scores.slice(2)"
            :key="j"
            :style="stackItemStyle(d.scores, j + 2)"
            class="graph-stack-bar"
          >
            <st-tooltip
              v-if="score > 0"
              :key="'tip' + j + 2"
              class="st-diverging-bar-graph__tooltip"
              message-class="st-diverging-bar-graph__tooltip-message"
              :max-width="isDesktop ? 411 : 125"
              :tip="score + ' participant felt ' + bucketNames[j + 2]"
            >
              <div class="st-diverging-bar-graph__tooltip" />
            </st-tooltip>
          </div>
        </div>
      </div>
      <div class="st-bar-graph-stack__container-second">
        <div
          v-for="(d, i) in filteredSentiments"
          :key="i"
          class="st-bar-graph-stack__stack-container-second "
        >
          <div
            v-for="(score, j) in d.scores.slice(0, 2)"
            :key="j"
            :style="stackItemStyle(d.scores, j)"
            class="graph-stack-bar2"
          >
            <st-tooltip
              v-if="score > 0"
              :key="'tip' + j"
              class="st-diverging-bar-graph__tooltip"
              message-class="st-diverging-bar-graph__tooltip-message"
              :max-width="isDesktop ? 411 : 125"
              :tip="score + ' participant felt ' + bucketNames[j]"
            >
              <div class="st-diverging-bar-graph__tooltip" />
            </st-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="{
        'st-opinion-metric-insights-grid st-opinion-metric-insights__block ml-20 w-full': true,
        'st-opinion-metric-insights-grid__no-media ': true,
      }"
    >
      <div
        :class="{
          'st-opinion-metric-insights-grid__average-and-bar-graph': true,
          'st-opinion-metric-insights-grid__average-and-bar-graph--no-media ': true,
        }"
      >
        <div class="">
          <diverging-bar-graph :sentiment-values="allResponsesSentiments" />
        </div>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import {
  distributeInWeeks,
  distributeInWeekDays,
  distributeInMonths,
} from "../../../utilities/response_utilities";
import { StButton, StTooltip, StCheckbox } from "../../common";

import DivergingBarGraph from "./diverging-bar-graph.vue";
import { WINDOW_TYPE } from "../../../components/pages/surveys/constants";
import { kebabCase } from "lodash";
import { isDesktop } from "../../../mixins";
import { bucketCounts } from '../../../utilities/data_utilities';
const TREND_TYPE = {
  TOTAL: "TOTAL",
  STACK: "STACK",
  STACKTRNEDLINE: 'STACKTRNEDLINE'
};

const SENTIMENT_COLORS = [
  "#ac5555",
  "#f9776f",
  "#e9e9e9",
  "#667e6e",
  "#7ecd87",
];

export default {
  name: "BarGraphStack",
  components: {
    StButton,
    DivergingBarGraph,
    StTooltip,
    StCheckbox,
    LineGraph: () => import("./line-graph"),
  },
  mixins: [isDesktop],
  props: {
    defaultType: {
      type : String,
      required: false,
      default: TREND_TYPE.TOTAL
    },
    window: {
      type: String,
      required: true,
    },
    showSelectors: {
      type: Boolean,
      required: false,
      default:true
    },
    responses: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: function() {
    return {
      showTrendLine: false,
      bucketNames: [
        "Very negative",
        "Negative",
        "Neutral",
        "Positive",
        "Very positive",
      ],
      title: "",
      trend: TREND_TYPE.TOTAL,
      currentPage: 0,
      totalPages: 0,
    };
  },

  computed: {
    filteredSentiments() {
      let filteredResponses = [];
      let primaryFilter = null;
      let secondaryFilter = null;
      if (this.window === WINDOW_TYPE.MONTHLY) {
        primaryFilter = distributeInMonths;
        secondaryFilter = distributeInWeeks;
      } else if (this.window === WINDOW_TYPE.WEEKLY) {
        primaryFilter = distributeInWeeks;
        secondaryFilter = distributeInWeekDays;
      }
      filteredResponses = primaryFilter(this.responses);
      this.totalPages = filteredResponses.length;
      if (this.currentPage >= this.totalPages)
        this.currentPage = this.totalPages - 1;
      filteredResponses = secondaryFilter(
        filteredResponses[this.currentPage].responses
      );
      return filteredResponses;
    },
    allResponsesSentiments() {
      const responses = [];
      this.filteredSentiments.forEach(({ responses: res }) =>
        responses.push(...res)
      );
      const temp = responses.map(({ sentimentScore }) => sentimentScore);
      return temp;
    },
    numberOfResponsesPerBucket () {
      return bucketCounts(this.allResponsesSentiments)
    },
    stackAverages() {
      const averages = this.filteredSentiments.map(
        ({ responses }) =>
          responses.reduce((prev, curr) => prev + curr.sentimentScore, 0) /
          (responses.length || 1)
      );
      return averages;
    },
    // currentPageLabel() {
    //   const responses = this.filteredSentiments;
    //   if (this.window === WINDOW_TYPE.WEEKLY) {
    //     return `From ${responses[0].start.format("ddd DD")} to ${responses[
    //       responses.length - 1
    //     ].end.format("ddd DD")}`;
    //   } else if (this.window === WINDOW_TYPE.MONTHLY)
    //     return `${responses[0].start.format("MMMM")}`;
    // },
  },
  // mounted(){
  //   console.log(this.defaultType)
  // },
  methods: {
    getPositiveResponses(scores) {
      return scores.splice(0, 2);
    },
    toggleShowTrendLine() {
      this.showTrendLine = !this.showTrendLine;
    },
    stackItemStyle(scores, index) {
      const max = Math.max(
        ...this.filteredSentiments.map(({ scores }) => Math.max(...scores))
      );
      return {
        backgroundColor: SENTIMENT_COLORS[index],
        flex: scores[index] / max,
      };
    },
    stackLabel(data, index) {
      if (
        this.window === WINDOW_TYPE.WEEKLY &&
        (this.trend === TREND_TYPE.STACK || this.defaultType=== TREND_TYPE.STACK || this.defaultType === TREND_TYPE.STACKTRNEDLINE)
      ) {
        return { date: data.start.format("ddd D, YYYY") };
      } else if (
        this.window === WINDOW_TYPE.MONTHLY &&
        (this.trend === TREND_TYPE.STACK || this.defaultType === TREND_TYPE.STACK || this.defaultType === TREND_TYPE.STACKTRNEDLINE)
      ) {
        return {
          weekNo: `Week ${`00${index + 1}`?.slice(-2)}`,
          date: `${data.start.format("ddd DD")} - ${data.end.format("ddd DD")}`,
        };
      }
    },
    changePage(count) {
      let newPageIndex = this.currentPage + count;
      if (newPageIndex < 0 || newPageIndex >= this.totalPages) return;
      this.currentPage = newPageIndex;
    },
    changeTrend(selectedTrend) {
      this.trend = selectedTrend;
    },
    sentimentColor(value) {
      return { [`st-sentiment-badge--${kebabCase(value)}`]: true };
    },
  },
};
</script>
