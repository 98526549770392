<template>
  <div class="tw-h-screen tw-w-full tw-flex">
    <div class="tw-w-full lg:tw-w-3/5 px-5 md:tw-px-0 tw-bg-white tw-h-full">
      <div class="tw-max-w-[380px] tw-flex tw-flex-col tw-mx-auto tw-h-full">
        <div class="tw-flex tw-items-center tw-space-x-1 tw-my-10">
          <a
            href="https://askhumans.io"
            class="tw-flex tw-space-x-2 tw-items-center"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_5735)">
                <path
                  opacity="0.8"
                  d="M21.3881 5.21827L18.5947 3.42763L17.7359 2.87795L14.4913 0.796864C13.7018 0.277127 12.776 0 11.8294 0C10.8827 0 9.95692 0.277127 9.16738 0.796864L5.91076 2.87795L2.25853 5.21827C1.55748 5.65374 0.981274 6.26192 0.585708 6.98395C0.190141 7.70598 -0.0113924 8.51739 0.000616489 9.33964V19.1522C-0.0126463 19.9757 0.188289 20.7886 0.583914 21.5119C0.97954 22.2353 1.55641 22.8447 2.25853 23.2808L6.40287 20.5708L23.6412 9.33964C23.654 8.5178 23.4533 7.7066 23.0585 6.98457C22.6638 6.26255 22.0884 5.65415 21.3881 5.21827Z"
                  fill="#6FA8DC"
                />
                <path
                  opacity="0.8"
                  d="M22.739 0.717651L18.5947 3.42763L12.8461 7.17214L1.3515 14.6612C1.34092 15.4839 1.54412 16.2955 1.9414 17.0171C2.33868 17.7388 2.91657 18.3461 3.61906 18.7801L5.00614 19.6803L6.40286 20.5756L7.26406 21.1205L10.5062 23.1992C11.297 23.7214 12.2251 24 13.1742 24C14.1233 24 15.0514 23.7214 15.8422 23.1992L19.0868 21.1205L22.739 18.7777C23.4405 18.3431 24.0172 17.7355 24.4132 17.0139C24.8092 16.2922 25.0111 15.4809 24.9993 14.6588V4.84622C25.013 4.0224 24.812 3.20909 24.4159 2.48556C24.0198 1.76202 23.4421 1.15293 22.739 0.717651Z"
                  fill="#FE795D"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_5735">
                  <rect
                    width="25"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
            <span class="tw-font-semibold ">
              AskHumans
            </span>
          </a>
        </div>
        <div class="tw-mt-16 tw-w-full">
          <p class="tw-text-territory">
            Log in
          </p>
          <p class="tw-text-territory-dark tw-text-[40px] tw-font-semibold tw-w-full md:tw-w-3/4 tw-tracking-wider tw-leading-[44px] tw-my-3">
            Your journey continues here
          </p>

          <div class="tw-w-full tw-space-x-2 tw-flex tw-justify-end tw-mt-9">
            <div class="tw-w-full tw-justify-center tw-text-center  tw-border-white-two tw-border-solid tw-rounded-xl">
              <div v-html="omniauth" />
            </div>
          </div>
          <div class="tw-mt-4 tw-flex tw-items-center">
            <p class="tw-h-0.5 tw-bg-white-two tw-w-full" />
            <p class="tw-px-4 tw-text-base tw-text-light-gray-two">
              or
            </p>
            <p class="tw-h-0.5 tw-bg-white-two tw-w-full" />
          </div>
          <form
            id="new_user"
            action="/users/sign_in"
            accept-charset="UTF-8"
            method="post"
            class="tw-mt-4"
          >
            <input
              type="hidden"
              name="authenticity_token"
              :value="token"
            >
            <st-input
              v-model="email"
              style-type="rounded"
              label="Email"
              name="user[email]"
              placeholder="Email address"
              :autofocus="true"
            />
            <div class="tw-relative tw-flex tw-w-full tw-items-center">
              <st-input
                v-model="password"
                style-type="rounded"
                label="Password"
                name="user[password]"
                type="password"
                class="tw-w-full"
                :password="true"
                placeholder="Password"
              />
              <span
                class="tw-absolute tw-right-2 tw-p-2 tw-cursor-pointer"
                @click="changeType"
              >
                <svg
                  v-if="typePassword"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="tw-w-5 tw-h-5"
                >
                  <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                  <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                  <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                </svg>

                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="tw-w-5 tw-h-5"
                >
                  <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                  <path
                    fill-rule="evenodd"
                    d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                    clip-rule="evenodd"
                  />
                </svg>

              </span>
            </div>
            <div class="tw-mt-4 tw-flex tw-space-between tw-w-full tw-items-center ">
              <div class="tw-w-full">
                <st-checkbox
                  v-model="rememberMe"
                  label-class="tw-ml-4 tw-cursor-pointer tw-mt-1"
                  name="user[remember_me]"
                >
                  Remember Me
                </st-checkbox>
              </div>
              <div class="tw-w-full tw-text-end">
                <a href="/users/password/new">
                  Forgot password?
                </a>
              </div>
            </div>
            <div class="tw-mt-4">
              <st-button
                type="submit"
                caption="Sign In"
                icon-name="sign-in"
                class="tw-w-full tw-rounded-xl tw-p-4"
              />
            </div>
            <div class="tw-mt-7 tw-flex tw-space-x-2 tw-justify-center tw-items-center">
              <span>
                Don't have an account?
              </span>
              <a
                href="/users/sign_up"
                class="tw-text-primary"
              >
                Sign Up
              </a>
            </div>
          </form>
        </div>
        <div class=" tw-bottom-0 tw-h-full tw-flex tw-justify-end tw-items-end">
          <p class="tw-py-10">
            Protected by reCAPTCHA and subject to the <a
              href="/"
              class="tw-text-primary"
            > AskHumans </a>  <a
              href="/privacy"
              class="tw-text-primary"
            > Privacy Policy </a>  and <a
              href="/terms"
              class="tw-text-primary"
            > Terms of Service </a>
          </p>
        </div>
      </div>
    </div>
    <div
      class="tw-hidden md:tw-w-2/5 px-8 2xl:tw-px-0 tw-bg-light-gray-four tw-h-full lg:tw-flex tw-items-center tw-fixed tw-top-0 tw-right-0"
    >
      <div
        class="tw-max-w-[480px] tw-h-full tw-flex tw-flex-col tw-justify-between tw-items-end tw-mx-auto"
      >
        <div class="tw-h-full  tw-flex tw-flex-col tw-justify-center">
          <p class="tw-text-territory-dark tw-text-[40px] tw-font-semibold tw-w-full 2xl:tw-w-3/4 tw-tracking-wider tw-leading-[44px]">
            Welcome back to AskHumans
          </p>
          <p class="tw-text-black-three tw-my-4">
            Unlock valuable insights from private customer
            feedback and send personalized responses at scale
          </p>
          <!-- <div class="tw-flex tw-space-x-2 tw-items-center">
            <div class="tw-flex -tw-space-x-4">
              <span class="tw-rounded-full tw-bg-black tw-block tw-border tw-border-white tw-border-solid tw-w-8 tw-h-8" />
              <span class="tw-rounded-full tw-bg-primary tw-block tw-border tw-border-white tw-border-solid tw-w-8 tw-h-8" />
              <span class="tw-rounded-full tw-bg-territory tw-block tw-border tw-border-white tw-border-solid tw-w-8 tw-h-8" />
            </div>
            <p>
              3k+ people joined us, now it’s your turn
            </p>
          </div> -->
        </div>
        <div>
          <p class="tw-py-10">
            Having troubles? <a
              href="mailto:support@askhumans.com?subject=Help required for askhumans"
              class="tw-text-primary"
            >Get help</a>
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="bx--col-xs-10 bx--offset-xs-1 bx--col-sm-6 bx--offset-sm-3">
      <div class="st-sign-in__header-row">
        <h2 class="st-sign-in__title">
          Sign in
        </h2>
        <div
          v-if="false"
          class="st-sign-in__sign-up"
        >
          <div class="st-sign-in__help-text">
            Don't have an account?
          </div>
          <st-hyperlink
            text="Sign Up"
            url="/users/sign_up"
            class="st-sign-in__sign-up-link"
            icon-name="sign-up"
            icon-class="st-hyperlink__default-icon-spacing"
          />
        </div>
      </div>
      <form
        id="new_user"
        action="/users/sign_in"
        accept-charset="UTF-8"
        method="post"
        class="new_user"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="token"
        >
        <div class="st-sign-in__input">
          <st-input
            v-model="email"
            label="Email"
            name="user[email]"
            placeholder="Your email goes here"
            :autofocus="true"
          />
        </div>
        <st-input
          v-model="password"
          label="Password"
          name="user[password]"
          type="password"
          :password="true"
          placeholder="Password goes here"
        />
        <div class="st-sign-in__remember-me">
          <st-checkbox
            v-model="rememberMe"
            name="user[remember_me]"
          >
            Remember Me
          </st-checkbox>
        </div>
        <div class="st-sign-in__submit-btn tw-w-full tw-items-center tw-justify-between">
          <div v-html="omniauth" />
          <st-button
            type="submit"
            caption="Sign In"
            icon-name="sign-in"
          />
        </div>
      </form>

      <div class="st-page__shared-links">
        <p class="st-sign-in__reset-password">
          <span class="st-sign-in__help-text">
            Trouble logging in?
          </span>
          <st-hyperlink
            text="Reset my password"
            url="/users/password/new"
            class="st-sign-in__reset-password-link"
          />
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  IconWrapper,
  StInput,
  StButton,
  StCheckbox,
  StHyperlink
} from '../../common'

export default {
  components: {
    IconWrapper,
    StInput,
    StButton,
    StCheckbox,
    StHyperlink
  },
  props: {
    savedEmail: {
      type: String,
      required: true
    },
    omniauth: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      rememberMe: false,
      email: this.savedEmail,
      password: '',
      showVerificationModal: false,
      typePassword: true
    }
  },
  computed: {
    token () {
      const tokenTag = document.querySelector('meta[name=csrf-token]')
      return tokenTag === null ? '' : tokenTag.content
    }
  },
  methods: {
    changeType () {
      const input = document.querySelector('input[name="user[password]"]')
      if (input.type === 'password') {
        this.typePassword = false
        input.type = 'text'
      } else {
        this.typePassword = true
        input.type = 'password'
      }
    }
  }
}
</script>
