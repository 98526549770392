<template>
  <div class="tw-top-0 tw-z-40 tw-sticky tw-w-full">
    <div class="tw-top-0 tw-z-10 tw-sticky flex justify-between tw-bg-white tw-px-28 tw-py-3 p-5 tw-border-b-white-two tw-border-solid w-full md:padding-container tw-border-b">
      <div class="flex items-center space-x-2 p-3">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_216_4310)">
            <path
              opacity="0.8"
              d="M21.3881 5.21827L18.5947 3.42763L17.7359 2.87795L14.4913 0.796864C13.7018 0.277127 12.776 0 11.8294 0C10.8827 0 9.95692 0.277127 9.16738 0.796864L5.91076 2.87795L2.25853 5.21827C1.55748 5.65374 0.981274 6.26192 0.585708 6.98395C0.190141 7.70598 -0.0113924 8.51739 0.000616489 9.33964V19.1522C-0.0126463 19.9757 0.188289 20.7886 0.583914 21.5119C0.97954 22.2353 1.55641 22.8447 2.25853 23.2808L6.40287 20.5708L23.6412 9.33964C23.654 8.5178 23.4533 7.7066 23.0585 6.98457C22.6638 6.26255 22.0884 5.65415 21.3881 5.21827Z"
              fill="#6FA8DC"
            />
            <path
              opacity="0.8"
              d="M22.7395 0.717651L18.5951 3.42763L12.8466 7.17214L1.35196 14.6612C1.34138 15.4839 1.54458 16.2955 1.94186 17.0171C2.33914 17.7388 2.91703 18.3461 3.61952 18.7801L5.0066 19.6803L6.40332 20.5756L7.26452 21.1205L10.5067 23.1992C11.2975 23.7214 12.2255 24 13.1747 24C14.1238 24 15.0519 23.7214 15.8427 23.1992L19.0872 21.1205L22.7395 18.7777C23.4409 18.3431 24.0176 17.7355 24.4137 17.0139C24.8097 16.2922 25.0116 15.4809 24.9998 14.6588V4.84622C25.0135 4.0224 24.8125 3.20909 24.4164 2.48556C24.0203 1.76202 23.4425 1.15293 22.7395 0.717651Z"
              fill="#FE795D"
            />
          </g>
          <defs>
            <clipPath id="clip0_216_4310">
              <rect
                width="25"
                height="24"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
        <a
          href="/welcome"
          class="cursor-pointer"
        >
          <span class="font-bold">AskHumans</span></a>
      </div>
      <div class="tw-flex tw-items-center tw-space-x-3">
        <template v-if="showPreview">
          <ah-button
            type="outlined"
            @click="redirectToHome"
          >
            Save draft & close
          </ah-button>
          <ah-button
            type="secondary"
            @click="togglePreviewModal"
          >
            Live preview
          </ah-button>
        </template>
        <button
          class="tw-bg-error hover:tw-bg-[#AC5555] tw-p-3 tw-rounded-xl tw-text-white"
          @click="toggleDeleteModal"
        >
          Abandon
        </button>
      </div>
      <div
        v-if="showPreviewModal"
        class="top-16 right-0 md:right-20 absolute bg-white px-5 py-5 rounded-12p max-w-350p"
        style="box-shadow: 5px 5px 40px rgba(24, 50, 77, 0.5); "
      >
        <p class="py-2 font-18p font-600 text-gray-200">
          scan this QR code with your smartphone to preview this study OR <a
            :href="getStudyURL"
            target="_blank"
            class="tw-text-primary-dark tw-underline"
          >click to preview in browser</a> .
        </p>
        <div class="mx-auto my-2">
          <qrcode-vue
            id="qr-code"
            :value="getStudyURL"
            :size="300"
            level="H"
          />
        </div>
        <div>
          <button
            class="border-gray-100 my-2 py-2 rounded-8p w-full"
            @click="togglePreviewModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <st-modal v-model="showDeleteModal">
      <template v-slot:modalTitle>
        <span class="font-18p font-bold">
          Delete draft
        </span>
      </template>
      <template v-slot:modalActions>
        <p class="tw-my-3 tw-w-[500px]">
          After you sure you want delete this draft? you will lose all the
          information already created. Do you wish to proceed?
        </p>
        <div class="flex justify-end space-x-2">
          <ah-button
            type="outlined"
            @click="toggleDeleteModal"
          >
            Cancel
          </ah-button>
          <ah-button
            @click="deleteStudyHandler"
          >
            Delete
          </ah-button>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>

import QrcodeVue from 'qrcode.vue'
import { AhButton, StModal } from '../../common'
import { mapState } from 'vuex'
export default {
  components: {
    QrcodeVue,
    AhButton,
    StModal
  },

  props: {

    surveyId: {
      type: Number,
      required: true
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: true
    }

  },
  data () {
    return {
      showPreviewModal: false,
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState(['currentUser']),
    getStudyURL () {
      const baseUrl = window.location.origin
      return `${baseUrl}/study/${this.surveyId}/preview`
    }
  },
  methods: {
    togglePreviewModal () {
      this.showPreviewModal = !this.showPreviewModal
    },
    toggleDeleteModal () {
      this.showDeleteModal = !this.showDeleteModal
    },
    redirectToHome () {
      window.location.href = '/welcome'
    },
    deleteStudyHandler () {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios
        .delete(`/study/${this.surveyId}`)
        .then((res) => {
          if (res.status === 200) {
            // this.getAllStudies()
            this.$emit('updateStudiesData')
            window.location.href = '/welcome'
            // this.toggleDeleteModal()
            // this.deleteDraftId = null
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

}
</script>
