<template>
  <div class="tw-top-[72px] tw-left-0 tw-sticky tw-bg-white-one tw-py-20 tw-h-[calc(100vh-76px)] tw-min-w-[230px]">
    <a
      :href="`/study/${surveyId}/edit/landing_page`"
      class="tw-flex tw-justify-between tw-items-center tw-p-5 tw-cursor-pointer"
      :class="{
        'tw-bg-positive-dark tw-text-white': currentStep == 1,
        'hover:tw-bg-secondary-light': currentStep != 1 ,
        'tw-bg-[#FAF3DA] tw-text-black': currentStep != 1 && !survey.headline && !survey.introVideoUrl && !survey.introVideoUrl

      }"
    >
      <span>
        Introduction
      </span> <svg
        v-if="survey.headline || survey.introVideoUrl || survey.introVideoUrl && currentStep> 1 "
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_581_2993)">
          <path
            d="M13.8318 0.619336C9.36793 3.41668 5.02911 8.70443 5.02911 8.70443C4.14401 7.78691 3.20891 6.92083 2.2288 6.10618C1.65207 5.62834 0.795308 5.70467 0.315255 6.27873C-0.0931237 6.76653 -0.106458 7.47001 0.285252 7.97274C1.68041 9.77956 2.89554 11.7191 3.90899 13.7615C4.23902 14.4202 5.04245 14.689 5.70419 14.3605C5.95588 14.2361 6.1609 14.0353 6.29259 13.7881C9.06789 8.58994 11.4732 5.67646 14.8185 1.74425C15.0902 1.42569 15.0502 0.949509 14.7302 0.679066C14.4768 0.465034 14.1118 0.440147 13.8301 0.617677L13.8318 0.619336Z"
            fill="#7ECD87"
          />
        </g>
        <defs>
          <clipPath id="clip0_581_2993">
            <rect
              width="15"
              height="14"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        v-if="!survey.headline && !survey.introVideoUrl && currentStep == 1 "
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_962_3671)">
          <path
            d="M9.50016 -8.30545e-07C10.041 -7.83265e-07 10.5422 0.279677 10.8192 0.762258L18.7917 14.6611C19.0687 15.1465 19.0714 15.7442 18.7917 16.2295C18.549 16.6518 18.0874 17.0027 17.5465 17L1.45378 17C0.91296 17 0.451283 16.6518 0.208573 16.2295C-0.071073 15.7442 -0.0684351 15.1465 0.208573 14.6611L8.18108 0.762257C8.45809 0.279677 8.95934 -8.77825e-07 9.50016 -8.30545e-07ZM9.50016 1.09677C9.34451 1.09677 9.15984 1.20645 9.08861 1.3271L1.1161 15.2177C1.1161 15.2177 1.1161 15.2232 1.1161 15.226C1.05014 15.3494 1.04751 15.5413 1.1161 15.6619C1.22162 15.8456 1.29813 15.9032 1.45378 15.9032L17.5465 15.9032C17.7022 15.9032 17.7787 15.8484 17.8842 15.6619C17.9528 15.5413 17.9502 15.3494 17.8842 15.226C17.8842 15.2232 17.8842 15.2205 17.8842 15.2177L9.91171 1.3271C9.84048 1.20371 9.65581 1.09677 9.50016 1.09677ZM9.50016 4.93548C9.79036 4.93548 10.0278 5.18226 10.0278 5.48387L10.0278 11.5161C10.0278 11.8177 9.79035 12.0645 9.50016 12.0645C9.20996 12.0645 8.97253 11.8177 8.97253 11.5161L8.97253 5.48387C8.97253 5.18226 9.20996 4.93548 9.50016 4.93548ZM9.50016 13.1613C9.93809 13.1613 10.2916 13.5287 10.2916 13.9839C10.2916 14.439 9.93809 14.8065 9.50016 14.8065C9.06222 14.8065 8.70871 14.439 8.70871 13.9839C8.70871 13.5287 9.06222 13.1613 9.50016 13.1613Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_962_3671">
            <rect
              width="19"
              height="17"
              fill="white"
              transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 19 0)"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        v-if="!survey.headline && !survey.introVideoUrl && currentStep > 1 "
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_962_3675)">
          <path
            d="M9.50016 -8.30545e-07C10.041 -7.83265e-07 10.5422 0.279677 10.8192 0.762258L18.7917 14.6611C19.0687 15.1465 19.0714 15.7442 18.7917 16.2295C18.549 16.6518 18.0874 17.0027 17.5465 17L1.45378 17C0.91296 17 0.451283 16.6518 0.208573 16.2295C-0.071073 15.7442 -0.0684351 15.1465 0.208573 14.6611L8.18108 0.762257C8.45809 0.279677 8.95934 -8.77825e-07 9.50016 -8.30545e-07ZM9.50016 1.09677C9.34451 1.09677 9.15984 1.20645 9.08861 1.3271L1.1161 15.2177C1.1161 15.2177 1.1161 15.2232 1.1161 15.226C1.05014 15.3494 1.04751 15.5413 1.1161 15.6619C1.22162 15.8456 1.29813 15.9032 1.45378 15.9032L17.5465 15.9032C17.7022 15.9032 17.7787 15.8484 17.8842 15.6619C17.9528 15.5413 17.9502 15.3494 17.8842 15.226C17.8842 15.2232 17.8842 15.2205 17.8842 15.2177L9.91171 1.3271C9.84048 1.20371 9.65581 1.09677 9.50016 1.09677ZM9.50016 4.93548C9.79036 4.93548 10.0278 5.18226 10.0278 5.48387L10.0278 11.5161C10.0278 11.8177 9.79035 12.0645 9.50016 12.0645C9.20996 12.0645 8.97253 11.8177 8.97253 11.5161L8.97253 5.48387C8.97253 5.18226 9.20996 4.93548 9.50016 4.93548ZM9.50016 13.1613C9.93809 13.1613 10.2916 13.5287 10.2916 13.9839C10.2916 14.439 9.93809 14.8065 9.50016 14.8065C9.06222 14.8065 8.70871 14.439 8.70871 13.9839C8.70871 13.5287 9.06222 13.1613 9.50016 13.1613Z"
            fill="#DCAD05"
          />
        </g>
        <defs>
          <clipPath id="clip0_962_3675">
            <rect
              width="19"
              height="17"
              fill="white"
              transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 19 0)"
            />
          </clipPath>
        </defs>
      </svg>

    </a>
    <a
      :href="`/study/${surveyId}/edit/content`"
      class="tw-flex tw-justify-between tw-items-center tw-p-5 tw-cursor-pointer"
      :class="{
        'tw-bg-positive-dark tw-text-white': currentStep == 2,
        'hover:tw-bg-secondary-light': currentStep != 2 ,
        'tw-bg-[#FAF3DA] tw-text-black': currentStep >= 2 && !isQuestionPromptFilled

      }"
    >
      <span>
        Question prompt
      </span>
      <svg
        v-if="isQuestionPromptFilled && currentStep> 2 "
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_581_2993)">
          <path
            d="M13.8318 0.619336C9.36793 3.41668 5.02911 8.70443 5.02911 8.70443C4.14401 7.78691 3.20891 6.92083 2.2288 6.10618C1.65207 5.62834 0.795308 5.70467 0.315255 6.27873C-0.0931237 6.76653 -0.106458 7.47001 0.285252 7.97274C1.68041 9.77956 2.89554 11.7191 3.90899 13.7615C4.23902 14.4202 5.04245 14.689 5.70419 14.3605C5.95588 14.2361 6.1609 14.0353 6.29259 13.7881C9.06789 8.58994 11.4732 5.67646 14.8185 1.74425C15.0902 1.42569 15.0502 0.949509 14.7302 0.679066C14.4768 0.465034 14.1118 0.440147 13.8301 0.617677L13.8318 0.619336Z"
            fill="#7ECD87"
          />
        </g>
        <defs>
          <clipPath id="clip0_581_2993">
            <rect
              width="15"
              height="14"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
      <svg
        v-if="!isQuestionPromptFilled && currentStep==2 "
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_962_3671)">
          <path
            d="M9.50016 -8.30545e-07C10.041 -7.83265e-07 10.5422 0.279677 10.8192 0.762258L18.7917 14.6611C19.0687 15.1465 19.0714 15.7442 18.7917 16.2295C18.549 16.6518 18.0874 17.0027 17.5465 17L1.45378 17C0.91296 17 0.451283 16.6518 0.208573 16.2295C-0.071073 15.7442 -0.0684351 15.1465 0.208573 14.6611L8.18108 0.762257C8.45809 0.279677 8.95934 -8.77825e-07 9.50016 -8.30545e-07ZM9.50016 1.09677C9.34451 1.09677 9.15984 1.20645 9.08861 1.3271L1.1161 15.2177C1.1161 15.2177 1.1161 15.2232 1.1161 15.226C1.05014 15.3494 1.04751 15.5413 1.1161 15.6619C1.22162 15.8456 1.29813 15.9032 1.45378 15.9032L17.5465 15.9032C17.7022 15.9032 17.7787 15.8484 17.8842 15.6619C17.9528 15.5413 17.9502 15.3494 17.8842 15.226C17.8842 15.2232 17.8842 15.2205 17.8842 15.2177L9.91171 1.3271C9.84048 1.20371 9.65581 1.09677 9.50016 1.09677ZM9.50016 4.93548C9.79036 4.93548 10.0278 5.18226 10.0278 5.48387L10.0278 11.5161C10.0278 11.8177 9.79035 12.0645 9.50016 12.0645C9.20996 12.0645 8.97253 11.8177 8.97253 11.5161L8.97253 5.48387C8.97253 5.18226 9.20996 4.93548 9.50016 4.93548ZM9.50016 13.1613C9.93809 13.1613 10.2916 13.5287 10.2916 13.9839C10.2916 14.439 9.93809 14.8065 9.50016 14.8065C9.06222 14.8065 8.70871 14.439 8.70871 13.9839C8.70871 13.5287 9.06222 13.1613 9.50016 13.1613Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_962_3671">
            <rect
              width="19"
              height="17"
              fill="white"
              transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 19 0)"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        v-else-if="!isQuestionPromptFilled && currentStep>=2 "
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_962_3675)">
          <path
            d="M9.50016 -8.30545e-07C10.041 -7.83265e-07 10.5422 0.279677 10.8192 0.762258L18.7917 14.6611C19.0687 15.1465 19.0714 15.7442 18.7917 16.2295C18.549 16.6518 18.0874 17.0027 17.5465 17L1.45378 17C0.91296 17 0.451283 16.6518 0.208573 16.2295C-0.071073 15.7442 -0.0684351 15.1465 0.208573 14.6611L8.18108 0.762257C8.45809 0.279677 8.95934 -8.77825e-07 9.50016 -8.30545e-07ZM9.50016 1.09677C9.34451 1.09677 9.15984 1.20645 9.08861 1.3271L1.1161 15.2177C1.1161 15.2177 1.1161 15.2232 1.1161 15.226C1.05014 15.3494 1.04751 15.5413 1.1161 15.6619C1.22162 15.8456 1.29813 15.9032 1.45378 15.9032L17.5465 15.9032C17.7022 15.9032 17.7787 15.8484 17.8842 15.6619C17.9528 15.5413 17.9502 15.3494 17.8842 15.226C17.8842 15.2232 17.8842 15.2205 17.8842 15.2177L9.91171 1.3271C9.84048 1.20371 9.65581 1.09677 9.50016 1.09677ZM9.50016 4.93548C9.79036 4.93548 10.0278 5.18226 10.0278 5.48387L10.0278 11.5161C10.0278 11.8177 9.79035 12.0645 9.50016 12.0645C9.20996 12.0645 8.97253 11.8177 8.97253 11.5161L8.97253 5.48387C8.97253 5.18226 9.20996 4.93548 9.50016 4.93548ZM9.50016 13.1613C9.93809 13.1613 10.2916 13.5287 10.2916 13.9839C10.2916 14.439 9.93809 14.8065 9.50016 14.8065C9.06222 14.8065 8.70871 14.439 8.70871 13.9839C8.70871 13.5287 9.06222 13.1613 9.50016 13.1613Z"
            fill="#DCAD05"
          />
        </g>
        <defs>
          <clipPath id="clip0_962_3675">
            <rect
              width="19"
              height="17"
              fill="white"
              transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 19 0)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
    <a
      v-if="survey.reward"
      :href="`/study/${surveyId}/edit/incentives`"
      class="tw-flex tw-justify-between tw-items-center tw-p-5 tw-cursor-pointer"
      :class="{
        'tw-bg-positive-dark tw-text-white': currentStep == 3,
        'hover:tw-bg-secondary-light': !isIncentivesStepCompleted && currentStep != 3 ,
        'tw-bg-[#FAF3DA] tw-text-black': currentStep >= 3 && !isIncentivesStepCompleted

      }"
    >
      <span>
        Incentives
      </span>
      <svg
        v-if="isIncentivesStepCompleted && currentStep >=3 "
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_581_2993)">
          <path
            d="M13.8318 0.619336C9.36793 3.41668 5.02911 8.70443 5.02911 8.70443C4.14401 7.78691 3.20891 6.92083 2.2288 6.10618C1.65207 5.62834 0.795308 5.70467 0.315255 6.27873C-0.0931237 6.76653 -0.106458 7.47001 0.285252 7.97274C1.68041 9.77956 2.89554 11.7191 3.90899 13.7615C4.23902 14.4202 5.04245 14.689 5.70419 14.3605C5.95588 14.2361 6.1609 14.0353 6.29259 13.7881C9.06789 8.58994 11.4732 5.67646 14.8185 1.74425C15.0902 1.42569 15.0502 0.949509 14.7302 0.679066C14.4768 0.465034 14.1118 0.440147 13.8301 0.617677L13.8318 0.619336Z"
            fill="#7ECD87"
          />
        </g>
        <defs>
          <clipPath id="clip0_581_2993">
            <rect
              width="15"
              height="14"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        v-else-if="!isIncentivesStepCompleted && currentStep>=3"
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_962_3675)">
          <path
            d="M9.50016 -8.30545e-07C10.041 -7.83265e-07 10.5422 0.279677 10.8192 0.762258L18.7917 14.6611C19.0687 15.1465 19.0714 15.7442 18.7917 16.2295C18.549 16.6518 18.0874 17.0027 17.5465 17L1.45378 17C0.91296 17 0.451283 16.6518 0.208573 16.2295C-0.071073 15.7442 -0.0684351 15.1465 0.208573 14.6611L8.18108 0.762257C8.45809 0.279677 8.95934 -8.77825e-07 9.50016 -8.30545e-07ZM9.50016 1.09677C9.34451 1.09677 9.15984 1.20645 9.08861 1.3271L1.1161 15.2177C1.1161 15.2177 1.1161 15.2232 1.1161 15.226C1.05014 15.3494 1.04751 15.5413 1.1161 15.6619C1.22162 15.8456 1.29813 15.9032 1.45378 15.9032L17.5465 15.9032C17.7022 15.9032 17.7787 15.8484 17.8842 15.6619C17.9528 15.5413 17.9502 15.3494 17.8842 15.226C17.8842 15.2232 17.8842 15.2205 17.8842 15.2177L9.91171 1.3271C9.84048 1.20371 9.65581 1.09677 9.50016 1.09677ZM9.50016 4.93548C9.79036 4.93548 10.0278 5.18226 10.0278 5.48387L10.0278 11.5161C10.0278 11.8177 9.79035 12.0645 9.50016 12.0645C9.20996 12.0645 8.97253 11.8177 8.97253 11.5161L8.97253 5.48387C8.97253 5.18226 9.20996 4.93548 9.50016 4.93548ZM9.50016 13.1613C9.93809 13.1613 10.2916 13.5287 10.2916 13.9839C10.2916 14.439 9.93809 14.8065 9.50016 14.8065C9.06222 14.8065 8.70871 14.439 8.70871 13.9839C8.70871 13.5287 9.06222 13.1613 9.50016 13.1613Z"
            fill="#DCAD05"
          />
        </g>
        <defs>
          <clipPath id="clip0_962_3675">
            <rect
              width="19"
              height="17"
              fill="white"
              transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 19 0)"
            />
          </clipPath>
        </defs>
      </svg>

    </a>
    <a
      :href="`/study/${surveyId}/edit/segments`"
      class="tw-flex tw-justify-between tw-items-center tw-p-5 tw-cursor-pointer"
      :class="currentStep == 4 ? 'tw-bg-positive-dark tw-text-white': 'hover:tw-bg-secondary-light'"
    >
      <span>
        Segments <span class="tw-px-2 tw-text-black-four">Optional</span>
      </span>
      <svg
        v-if="survey.segments.length || currentStep>4"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_581_2993)">
          <path
            d="M13.8318 0.619336C9.36793 3.41668 5.02911 8.70443 5.02911 8.70443C4.14401 7.78691 3.20891 6.92083 2.2288 6.10618C1.65207 5.62834 0.795308 5.70467 0.315255 6.27873C-0.0931237 6.76653 -0.106458 7.47001 0.285252 7.97274C1.68041 9.77956 2.89554 11.7191 3.90899 13.7615C4.23902 14.4202 5.04245 14.689 5.70419 14.3605C5.95588 14.2361 6.1609 14.0353 6.29259 13.7881C9.06789 8.58994 11.4732 5.67646 14.8185 1.74425C15.0902 1.42569 15.0502 0.949509 14.7302 0.679066C14.4768 0.465034 14.1118 0.440147 13.8301 0.617677L13.8318 0.619336Z"
            fill="#7ECD87"
          />
        </g>
        <defs>
          <clipPath id="clip0_581_2993">
            <rect
              width="15"
              height="14"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
    <a
      :href="`/study/${surveyId}/plan-details`"
      class="tw-flex tw-justify-between tw-items-center tw-p-5 tw-cursor-pointer"
      :class="currentStep == 5 ? 'tw-bg-positive-dark tw-text-white': 'hover:tw-bg-secondary-light'"
    >
      Respondents
    </a>
  </div>
</template>

<script>

export default {
  components: {
  },

  props: {
    currentStep: {
      type: Number,
      required: true
    },
    surveyId: {
      type: Number,
      required: true
    },
    survey: {
      type: Object,
      required: true
    }
    // showPreview: {
    //   type: Boolean,
    //   required: false,
    //   default: true
    // }

  },
  data () {
    return {
      showPreviewModal: false
    }
  },
  computed: {
    isIncentivesStepCompleted () {
      if (!this.survey.reward) {
        return true
      } else {
        if (this.survey.rewardType == 'coupon' && this.survey.couponValue && this.survey.couponCode) {
          return true
        } else if (this.survey.rewardType == 'gift card' && this.survey.incentiveAmount && this.survey.totalRespondents) {
          return true
        } else {
          return false
        }
      }
    },

    isQuestionPromptFilled () {
      if (this.survey.questions.filter((question) => question.questionType === 'opinion_metric').length === 0) {
        return false
      } else {
        const questions = this.survey.questions.filter((question) => question.questionType === 'opinion_metric')
        if (questions && questions.every(question => question.description && question.askForExplanation)) return true
        else return false
      }
    }
  },
  mounted () {

    // console.log(this.survey, 'in study sidebar')
  },
  methods: {
    redirectToPage () {
      // console.log('sdfdsfsf')
    }

  }

}
</script>
