<template>
  <div class="st-participant-invite-page">
    <div
      v-if="survey.pageImageUrl"
      class="edit-cover-photo__photo-and-button st-participant-invite-page__cover-photo"
      @focus="coverPhotoHovered = true"
      @focusout="coverPhotoHovered = false"
      @mouseleave="coverPhotoHovered = false"
      @mouseover="coverPhotoHovered = true"
    >
      <div
        :class="{
          'edit-cover-photo__photo': true,
          'edit-cover-photo__default': !survey.pageImageUrl,
          'edit-cover-photo__photo--hovered': coverPhotoHovered
        }"
        :style="surveyCoverPhotoStyle"
      />
    </div>
    <div class="bx--grid st-participant-invite-page__grid">
      <div class="bx--row st-participant-invite-page__row">
        <div
          :class="{
            'st-participant-invite-page__information-and-button': true,
            'st-participant-invite-page__information-and-button--no-cover-photo': !survey.pageImageUrl
          }"
        >
          <div
            key="company-logo"
            class="edit-company-logo__company-logo edit-company-logo--participant-invite-page"
            :style="companyLogoStyle"
          />
          <div
            class="st-participant-invite-page__archived-section"
            :class="{'st-participant-invite-page__archived-section--without-cover-photo': !survey.pageImageUrl }"
          >
            <icon-wrapper
              icon-name="info"
              :actionable="false"
              class="st-participant-invite-page__archived-icon"
            />
            <div class="st-participant-invite-page__archived-text">
              Hi, this survey is no longer available.
            </div>
          </div>
        </div>
        <div class="st-powered-by-spacetrics st-powered-by-spacetrics--participant-invite">
          Powered by
          <icon-wrapper
            class="st-powered-by-spacetrics__icon"
            :actionable="false"
            icon-name="spacetrics"
          />
          AskHumans
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { companyLogoStyle } from '../../../mixins'
import {
  IconWrapper
} from '../../common'

export default {
  components: {
    IconWrapper
  },
  mixins: [companyLogoStyle],
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      coverPhotoHovered: false
    }
  },
  computed: {
    companyLogo () {
      return this.survey.logoUrl
    },
    surveyCoverPhotoStyle () {
      return this.survey.pageImageUrl ? { 'background-image': `url('${this.survey.pageImageUrl}')` } : {}
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
