var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-minus-20"},[_c('a',{attrs:{"href":"/welcome"}},[(_vm.additionalContent === 'left' && _vm.isDesktop)?_c('icon-wrapper',{staticClass:"st-preview-wrapper__spacetrics-icon",attrs:{"icon-name":"spacetrics"}}):_vm._e()],1),_vm._v(" "),_c('div',{class:{ 'bx--grid': _vm.isDesktop }},[_c('div',{staticClass:"bx--row st-box-set",class:{
        'tw-mt-20': _vm.isWidget,
      }},[(_vm.additionalContent === 'left')?_c('div',{staticClass:"bx--col-sm-4 st-preview-wrapper__left-content"},[_c('p',{staticClass:"st-preview-wrapper__instructions-text"},[_vm._v("\n          This survey is for testing only. None of the information that you\n          enter here will be saved.\n        ")]),_vm._v(" "),(_vm.exitPreview)?_c('st-hyperlink',{staticClass:"st-preview-wrapper__left-exit-link",attrs:{"text":"Exit Preview","icon-name":"hide-password","icon-class":"st-hyperlink__default-icon-spacing","url":("/study/" + (_vm.survey.id) + "/publish")}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{class:{
          'st-preview-wrapper__position-invitation-page bx--offset-xs-0 bx--col-xs-12': true,
          'bx--col-sm-6 ': _vm.additionalContent === 'left',
          'bx--offset-sm-2 bx--col-sm-8 bx--offset-lg-3 bx--col-lg-6':
            (_vm.additionalContent === 'right' || _vm.additionalContent === 'none') &&
            !_vm.fullWidth,
          'bx--offset-sm-0 bx--col-sm-12': _vm.fullWidth && !_vm.additionalContent,
        }},[_c('div',{class:{
            'st-preview-wrapper__flex-container h-full-not-important': true,
            'st-preview-wrapper__invitation-page-container': _vm.isDesktop,
            'st-preview-wrapper__full-width': _vm.isDesktop && _vm.fullWidth,
            'st-preview-wrapper__full-width--survey':
              _vm.isDesktop && _vm.fullWidth && _vm.additionalContent === 'none',
          }},[_vm._t("default")],2)]),_vm._v(" "),(
          (_vm.exitPreview && _vm.additionalContent === 'right') ||
            (_vm.exitPreview && !_vm.isDesktop && _vm.responsePreview)
        )?_c('div',{class:{
          'bx--offset-sm-1 bx--col-sm-2  bx--col-offset-sm-0 st-preview-wrapper__right-content': true,
          'bx--grid tw-hidden sm:tw-block': !_vm.isDesktop,
        }},[_c('st-hyperlink',{staticClass:"st-preview-wrapper__right-exit-link",attrs:{"text":"Exit Preview","icon-name":"hide-password","icon-class":"st-hyperlink__default-icon-spacing","url":_vm.exitRightLink}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }