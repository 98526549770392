<template>
  <div class="bx--grid">
    <st-toast-notification
      v-if="showToast"
      kind="info"
      title="Info"
      :sub-title="toastMessage"
      @click="showToast = false"
    />
    <!-- <div class="mobile-header">
      <a href="/welcome">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          :caption="'Save & Continue'"
          :disabled="buttonDisabled"
          @click="saveAndToHub"
        />
      </div>
    </div> -->

    <div>
      <div class="bx--col-sm-12 bx--col-xs-12">
        <!-- <div class="space-between">
          <div>
            <div class="st-edit-survey__back-and-save">
              <st-hyperlink
                :back-link="true"
                text="Back"
                @click.native="saveAndToHub"
              />
              <div class="st-edit-survey__mobile-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                />
                <st-hyperlink
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
            </div>
            <div class="st-edit-survey__header-and-icon">
              <icon-wrapper
                class="st-edit-survey__header-icon"
                icon-name="participants"
              />
              <h1 class="st-edit-survey__page-header">
                Audience
              </h1>
            </div>
            <p
              :class="{
                'st-page-instructions st-edit-audience__header-spacing': true,
                'st-edit-audience__header-spacing--no-lists': !lists.length
              }"
            >
              {{ instructionsText }}
            </p>
          </div>
          <div>
            <div class="">
              <div class="st-edit-survey__inline-spiner-and-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                  class="st-edit-survey__desktop-inline-spinner"
                />
                <st-hyperlink
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
              <st-button
                :caption="'Save & Continue'"
                :disabled="buttonDisabled"
                @click="saveAndToHub"
              />
            </div>
          </div>
        </div> -->
        <!-- <div class="flex w-full py-3 justify-end">
          <button
            class="bg-white p-3 border-gray-50 border-2p rounded-4p cursor-pointer"

            @click="showPreviewModal = true"
          >
            Preview and try this study yourself
          </button>
        </div> -->
        <!-- <div class="bx--col-sm-9 mx-auto flex">
          <steps :step="5" />
        </div> -->

        <div
          v-if="isStudyBeingCreated"
          class="bx--col-sm-9 mx-auto mt-20 pb-20"
        >
          <div class="w-full">
            <!-- <a
              class=""
              :href="backURL"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.49686 1.00314C9.66771 1.174 9.66771 1.451 9.49686 1.62186L4.55622 6.5625L9.49686 11.5031C9.66771 11.674 9.66771 11.951 9.49686 12.1219C9.326 12.2927 9.04899 12.2927 8.87814 12.1219L3.62814 6.87186C3.45729 6.701 3.45729 6.42399 3.62814 6.25314L8.87814 1.00314C9.049 0.832286 9.326 0.832286 9.49686 1.00314Z"
                  fill="#202020"
                />isStudyBeingCreated
            </a> -->
            <span class="font-28p font-600 ">
              Congratulations! Your study is ready to be shared.
            </span>
          </div>

          <!-- <p class="mt-5">
            Set up different demographic attributes that can help you understand your audience
          </p> -->
          <!-- <div class="mt-5 w-full  divider w-full " />
          <div class="w-full">
            <p class="mt-5 font-600 font-18">
              Audience
            </p>
          </div> -->
          <div class="w-full  ">
            <!-- <div v-if="lists.length > 0">
              <st-dropdown-menu
                class="st-edit-audience__lists-dropdown"
                caption="Audience Lists"
                :accordion-contents-values="{
                  nestedProperty: 'participants',
                  nestedValue: 'email'
                }"
                :active-toggle-disable="survey.published && !isEditingDisabled"
                :display-caption="true"
                :disable-checkbox="isEditingDisabled"
                :full-width="true"
                :initial-active-options-array="initialActiveOptionsArray"
                :options="lists"
                :option-caption-fn="(list) => list.name"
                placeholder="Select one or more"
                :select-multiple="true"
                @updateMultiple="updateLists"
              >
                <template v-slot:accordion-expanded-section="{value: options}">
                  <div class="st-dropdown-menu__expanded-accordion-container">
                    <p
                      v-for="(participant, index) in options['participants']"
                      :key="'participant ' + index"
                    >
                      {{ participant.email }}
                    </p>
                  </div>
                </template>
              </st-dropdown-menu>
              <p class="st-edit-audience__additional-instructions">
                You can select an audience list and also add individual emails
              </p>
            </div>
            <st-email-input
              ref="emailInput"
              :disabled="isEditingDisabled"
              email-area-class="st-edit-audience__emails-container"
              invite-text="survey"
              label="Individual Recipients' Emails"
              :existing-members-list="allAccountParticipants"
              :non-removable-list="nonRemovableEmails"
              :removable-list="removableEmails"
              recipient-text="Recipients'"
              variant="survey-audience"
              @addNewEmail="addNewEmail"
              @removeEmail="removeNewEmail"
            /> -->
            <!-- <div class="st-edit-audience__anonymous-section mt-0-important">
              <div class="st-edit-audience__anonymous-instructions mt-3">
                Would you like to make this audience anonymous?
                <st-tooltip
                  class="st-edit-audience__anonymous-tooltip"
                  :direction="isDesktop ? 'top' : 'left'"
                  :max-width="isDesktop ? 411 : 250"
                  tip="Making an audience anonymous means we won’t display any identifiable information in your insights, such as email, first or last name."
                >
                  <icon-wrapper
                    icon-name="info"
                  />
                </st-tooltip>
              </div>
              <st-radio-button
                v-model="anonymousSurvey"
                class="st-radio-button__default-container"
                :disabled="survey.published || isEditingDisabled"
                :options="[
                  { caption: 'Yes', value: true },
                  { caption: 'No', value: false }
                ]"
              />
            </div>
            <div class="st-edit-audience__anonymous-section">
              <div class="st-edit-audience__anonymous-instructions">
                Do you want the insights to be public?
                <st-tooltip
                  class="st-edit-audience__anonymous-tooltip"
                  :direction="isDesktop ? 'top' : 'left'"
                  :max-width="isDesktop ? 411 : 250"
                  tip="If you make the insights public, everyone can see the what other people are saying. "
                >
                  <icon-wrapper
                    icon-name="info"
                  />
                </st-tooltip>
              </div>
              <st-radio-button
                v-model="insightType"
                class="st-radio-button__default-container"
                :disabled="survey.published || isEditingDisabled"
                :options="[
                  { caption: 'Yes', value: true },
                  { caption: 'No', value: false }
                ]"
              />
            </div> -->
          </div>
          <div class="w-full  flex-col space-y-5 md:flex-row md:space-y-0 md:tw-space-x-5 flex mt-5">
            <!-- <div class=" mt-3">
              Study URL
              <st-tooltip
                class="st-edit-audience__anonymous-tooltip"
                :direction="isDesktop ? 'top' : 'left'"
                :max-width="isDesktop ? 411 : 250"
                tip="Copy study URL to share as link to others"
              >
                <icon-wrapper
                  icon-name="info"
                />
              </st-tooltip>
            </div> -->
            <div class="flex flex-col  px-4 py-4 rounded-8p items-start justify-between border-gray-100 ">
              <div>
                <p class="font-bold font-18">
                  Study URL
                </p>
                <p class="mt-2">
                  You can share this url with the list of users who wish to complete this study
                </p>

                <p class="text-primary-brown mt-2">
                  {{ getStudyURL }}
                </p>
              </div>
              <div
                v-if="!copied"
                class=" mt-5"
              >
                <button

                  class="px-3 py-2 bg-white rounded-4p border-gray-100"
                  @click="copyToClipboard"
                >
                  Copy URL
                </button>
              </div>
              <p
                v-else
                class="bg-success flex space-x-2 items-center px-3 py-2 rounded-4p disabled"
              >
                <span><svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 0.875C10.3829 0.875 13.125 3.61729 13.125 7C13.125 10.3827 10.3827 13.125 7 13.125C3.61729 13.125 0.875 10.3827 0.875 7C0.875 3.61729 3.61729 0.875 7 0.875ZM3.96779 7.08002C3.74167 6.82989 3.76111 6.44395 4.01078 6.21806C4.26091 5.99193 4.64685 6.01137 4.87275 6.26104L6.39845 7.94679L9.69542 4.54122C9.93092 4.29909 10.3175 4.29383 10.5597 4.52933C10.8018 4.76483 10.8071 5.15146 10.5716 5.39358L6.82212 9.26649L6.8212 9.26558C6.81229 9.27473 6.80291 9.28387 6.79308 9.29256C6.54341 9.51868 6.15747 9.49948 5.93111 9.24958L3.96711 7.07979H3.96757L3.96779 7.08002Z"
                    fill="#F5F5F5"
                  />
                </svg>
                </span>
                <span class="text-white">
                  Copied
                </span>
              </p>
            </div>
            <!-- <div class="mt-5">
              <p>
                or download this QR code so they can scan it from their mobile device.
              </p>
            </div> -->
            <div class="flex flex-col space-x-2 px-4 py-4 rounded-8p items-start justify-between border-gray-100 ">
              <div>
                <p class="font-bold font-18">
                  QR Code
                </p>
                <p class="mt-2">
                  Use this QR code for users to scan it from their mobile devices and access directly to this study.
                </p>
                <p class="text-primary-brown mt-4">
                  <qrcode-vue
                    id="qr-code"
                    :value="getStudyURL"
                    :size="200"
                    level="H"
                  />
                </p>
              </div>

              <button

                class="px-3 py-2 bg-white rounded-4p border-gray-100 mt-5"
                @click="downloadQr"
              >
                Download
              </button>
            </div>
            <!-- <div class="mt-5">
              Website embed (iframe)
              <st-tooltip
                class="st-edit-audience__anonymous-tooltip"
                :direction="isDesktop ? 'top' : 'left'"
                :max-width="isDesktop ? 411 : 250"
                tip="Copy study URL to share as link to others"
              >
                <icon-wrapper
                  icon-name="info"
                />
              </st-tooltip>
            </div> -->

            <div class="flex flex-col space-x-2 px-4 py-4 rounded-8p items-start justify-between border-gray-100 ">
              <div>
                <p class="font-bold font-18">
                  Study URL
                </p>
                <p class="mt-2">
                  Embed this study in your website using this code.
                </p>

                <p class="text-primary-brown">
                  Copy iframe code to embed study into your website.
                </p>
              </div>
              <button
                v-if="!copiedIframe"
                class="px-3 py-2 bg-white rounded-4p border-gray-100"
                @click="copyIframeCode"
              >
                Copy embed code
              </button>
              <p
                v-else
                class="bg-success flex space-x-2 items-center px-3 py-2 rounded-4p disabled"
              >
                <span><svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7 0.875C10.3829 0.875 13.125 3.61729 13.125 7C13.125 10.3827 10.3827 13.125 7 13.125C3.61729 13.125 0.875 10.3827 0.875 7C0.875 3.61729 3.61729 0.875 7 0.875ZM3.96779 7.08002C3.74167 6.82989 3.76111 6.44395 4.01078 6.21806C4.26091 5.99193 4.64685 6.01137 4.87275 6.26104L6.39845 7.94679L9.69542 4.54122C9.93092 4.29909 10.3175 4.29383 10.5597 4.52933C10.8018 4.76483 10.8071 5.15146 10.5716 5.39358L6.82212 9.26649L6.8212 9.26558C6.81229 9.27473 6.80291 9.28387 6.79308 9.29256C6.54341 9.51868 6.15747 9.49948 5.93111 9.24958L3.96711 7.07979H3.96757L3.96779 7.08002Z"
                    fill="#F5F5F5"
                  />
                </svg>
                </span>
                <span class="text-white">
                  Copied
                </span>
              </p>
            </div>
          </div>
          <div
            v-if="survey.reward"
            class="mt-5"
          >
            <p class=" font-600">
              Rewards
            </p>
            <div v-if="survey.rewardType===rewardTypes.COUPON">
              <p>
                After respondents complete the survey, they can see the discount code.
              </p>
              <div class="flex space-x-5 mt-5">
                <div class="flex flex-col">
                  <span class="font-600">Coupon code </span>
                  <span class="mt-3">
                    {{ survey.couponCode }}
                  </span>
                </div>
                <div class="divider-x-gray" />
                <div class="flex flex-col">
                  <span class="font-600">Coupon value </span>
                  <span class="mt-3">
                    {{ survey.couponValue }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else>
              <p>
                After respondents complete this survey, go to the insights section where you will be able to send each their reward.
              </p>
              <div class="flex space-x-5 mt-5">
                <div class="flex flex-col">
                  <span class="font-600">Reward </span>
                  <span class="mt-3">
                    {{ survey.rewardType=="gift card"? "Amazon gift card":"" }}
                  </span>
                </div>
                <div class="divider-x-gray" />
                <div class="flex flex-col">
                  <span class="font-600">Gift card amount </span>
                  <span class="mt-3">
                    ${{ survey.incentiveAmount }}
                  </span>
                </div>
                <div class="divider-x-gray" />
                <div class="flex flex-col">
                  <span class="font-600"># of respondents </span>
                  <span class="mt-3">
                    {{ survey.totalRespondents }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="bx--col-sm-9 tw-mx-auto tw-mt-20 tw-pb-20"
        >
          <p class="font-28p font-600 tw-w-full ">
            Congratulations! Your study is now configured and ready to be installed on your website.
          </p>
          <p class="tw-mt-2">
            To install this widget in your website please follow the next steps.
          </p>
          <p class="tw-flex tw-flex-col tw-space-y-2 tw-my-2">
            <span class="tw-text-very-positive tw-font-semibold">
              Step 01
            </span>
            <span>
              Copy and paste the following code into your <span class="tw-text-very-positive"> &lt;head&gt; &lt;/head&gt; </span>  tag of your html page.
            </span>
          </p>
          <div class="tw-bg-[#2E2E2E] tw-flex tw-justify-between tw-items-center tw-rounded-2xl tw-px-4">
            <pre class="tw-text-white">

  &lt;script&gt;
    const _ahConfig = {
        studyID: {{ survey.id }},
        token: "{{ survey.widgetConfig.token ? survey.widgetConfig.token: '' }}"
      };
&lt;/script&gt;
&lt;script src="https://cdn.askhumans.io/main.js" defer&gt;&lt;/script&gt;
              </pre>

            <div>
              <button
                class="tw-bg-[#2E2E2E] tw-text-gray-100 tw-py-2 tw-px-4 tw-rounded-md tw-font-semibold tw-mt-2 tw-border tw-border-gray-100 tw-border-solid"
                @click="copyScript"
              >
                {{ copiedScript ? 'Copied' : 'Copy script' }}
              </button>
            </div>
          </div>
          <p class="tw-flex tw-flex-col tw-space-y-2 tw-my-5">
            <span class="tw-text-very-positive tw-font-semibold">
              Step 02 (Optional)
            </span>
            <span>
              Call this function  in the same script with the logged in user’s information as soon as you have the user’s information.
            </span>
          </p>
          <div class="tw-bg-[#2E2E2E] tw-flex tw-justify-between tw-items-center tw-rounded-2xl tw-px-4">
            <pre class="tw-text-white">

  &lt;script&gt;

  const _ahConfig = {
        studyID: {{ survey.id }},
        token: "{{ survey.widgetConfig.token ? survey.widgetConfig.token: '' }}"
      };

   <span class="tw-text-gray-500">// replace the following with the logged in user’s information </span>

    setUserDetails({
      firstName: "John",
      lastName: "Doe",
      email: "johndoe@example.com",
    });

&lt;/script&gt;
              </pre>
            <!-- <div>
              <button
                class="tw-bg-[#2E2E2E] tw-text-gray-100 tw-py-2 tw-px-4 tw-rounded-md tw-font-semibold tw-mt-2 tw-border tw-border-gray-100 tw-border-solid"
              >
                Copy script
              </button>
            </div> -->
          </div>

          <div>
            <div
              v-if="survey.reward"
              class="mt-5"
            >
              <p class=" font-600">
                Rewards
              </p>
              <div v-if="survey.rewardType===rewardTypes.COUPON">
                <p>
                  After respondents complete the survey, they can see the discount code.
                </p>
                <div class="flex space-x-5 mt-5">
                  <div class="flex flex-col">
                    <span class="font-600">Coupon code </span>
                    <span class="mt-3">
                      {{ survey.couponCode }}
                    </span>
                  </div>
                  <div class="divider-x-gray" />
                  <div class="flex flex-col">
                    <span class="font-600">Coupon value </span>
                    <span class="mt-3">
                      {{ survey.couponValue }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>
                  After respondents complete this survey, go to the insights section where you will be able to send each their reward.
                </p>
                <div class="flex space-x-5 mt-5">
                  <div class="flex flex-col">
                    <span class="font-600">Reward </span>
                    <span class="mt-3">
                      {{ survey.rewardType=="gift card"? "Amazon gift card":"" }}
                    </span>
                  </div>
                  <div class="divider-x-gray" />
                  <div class="flex flex-col">
                    <span class="font-600">Gift card amount </span>
                    <span class="mt-3">
                      ${{ survey.incentiveAmount }}
                    </span>
                  </div>
                  <div class="divider-x-gray" />
                  <div class="flex flex-col">
                    <span class="font-600"># of respondents </span>
                    <span class="mt-3">
                      {{ survey.totalRespondents }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bx--col-xs-12  p-5 md:padding-container fixed border-t-gray-100 bg-white bottom-0 left-0 w-full border-top-gray-100 ">
        <div class="flex w-full justify-end px-2 ">
          <!-- <a
          href="/projects"
          class="bg-gray-100 text-gray-two rounded-4p p-3 "
        >
          Cancel
        </a> -->
          <div class="flex space-x-2 md:space-x-5">
            <div class="st-edit-survey__inline-spiner-and-save">
              <st-inline-spinner
                v-if="$store.state.isInlineSpinnerVisible"
                class="st-edit-survey__desktop-inline-spinner"
              />
              <!-- v-if="!survey.published" -->
              <button
                icon-name="save"
                text="Save"
                class="bg-create-group rounded-4p px-3 py-3  text-blue-primary"
                @click="redirectTo('dashboard')"
              >
                Go to dashboard
              </button>
            </div>
            <!-- v-if="!survey.published" -->
            <st-button
              @click="redirectTo('insights')"
            >
              Go to insights
            </st-button>
          </div>
        </div>
        <!-- <st-hyperlink
        class="st-edit-invitation__preview-link"
        text="Preview"
        icon-name="view-password"
        icon-class="st-hyperlink__default-icon-spacing"
        @click="showPreviewModal = true"
      /> -->
        <!-- :url="`${url}/preview`" -->
      </div>
      <st-modal
        v-model="displayPublishConfirmationModal"
        :cancel-modal="true"
        :non-exit-modal="true"
        @closeModal="displayPublishConfirmationModal = false"
      >
        <template v-slot:modalTitle>
          Publish Survey
        </template>
        <template v-slot:modalActions>
          <!-- <div
          v-if="disableButton"
          class="st-publish-options-modal__instructions-container"
        >
          <p class="st-publish-options-modal__instruction-text">
            Not ready to publish. Please complete all the steps.
          </p>
        </div> -->
          <div
            class="st-publish-options-modal__instructions-container"
          >
            <p class="st-publish-options-modal__instruction-text">
              You are about to publish “{{ survey.name }}”. Once published you
              won’t be able to edit the content.
            </p>
            <p class="st-publish-options-modal__instruction-text">
              Are you sure you want to publish right now?
            </p>
          </div>
        </template>
        <template v-slot:modalButton>
          <!-- :disabled="disableButton" -->
          <st-button
            caption="Publish"
            icon-name="publish"
            @click="publishSurvey"
          />
        </template>
      </st-modal>
    </div>
  </div>
</template>

<script>
import { isDesktop } from '../../../mixins'
import isEditingDisabled from './isEditingDisabled'
import PreviewBar from './preview-bar.vue'
import saveUpdates from './saveUpdates'
import QrcodeVue from 'qrcode.vue'

import {
  IconWrapper,
  StButton,
  StDropdownMenu,
  StEmailInput,
  StHyperlink,
  StInlineSpinner,
  StRadioButton,
  StToastNotification,
  StTooltip,
  StModal
} from '../../common'
import { REWARD_TYPES } from './constants'
import Steps from './steps.vue'
export default {
  components: {
    QrcodeVue,
    IconWrapper,
    StButton,
    StDropdownMenu,
    PreviewBar,
    StEmailInput,
    StHyperlink,
    Steps,
    StModal,
    StInlineSpinner,
    StRadioButton,
    StToastNotification,
    StTooltip
  },
  mixins: [isDesktop, isEditingDisabled, saveUpdates],
  props: {
    allAccountParticipants: {
      type: Array,
      required: true
    },
    initialLists: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      copied: false,
      displayPublishConfirmationModal: false,
      copiedIframe: false,
      anonymousSurvey: this.survey.anonymous,
      insightType: this.survey.insightType,
      lists: this.initialLists.filter(list => { return list.participants.length }),
      listEmails: [],
      newEmails: [],
      surveyEmails: this.survey.responses.map(response => response.email),
      rewardTypes: { ...REWARD_TYPES },
      copiedScript: false
    }
  },
  computed: {

    backURL () {
      return `/study/${this.survey.id}/edit/segments`
    },
    isStudyBeingCreated () {
      return this.survey.surveyType === 'study'
    },
    disableButton () {
      return !this.survey.anonymous !== null
    },
    addListsToNewEmails () {
      return this.filteredNewEmailsByLists.concat(this.listEmails)
    },
    buttonDisabled () {
      if (this.insightType != null || this.anonymousSurvey != null) {
        return false
      }

      return ((this.totalNewEmails.length === 0 && !this.isEditingDisabled) &&
      (this.survey.responses.length === 0 && !this.isEditingDisabled)) || (this.isEditingDisabled)
    },
    instructionsText () {
      return this.lists.length > 0
        ? 'Create an audience by selecting it from a list, typing your recipient’s emails, or both!'
        : 'Create an audience by typing your recipient’s emails.'
    },
    nonRemovableSurveyEmails () {
      if (this.survey.published) {
        return this.surveyEmails.filter(email => {
          if (this.initialEmailsInSelectedLists.includes(email)) { return }
          return email
        })
      } else {
        return []
      }
    },
    nonRemovableEmails () {
      return this.survey.responses.filter(response => {
        if (this.nonRemovableSurveyEmails.includes(response.email)) {
          return response
        }
      })
    },
    removableSurveyEmails () {
      if (!this.survey.published) {
        return this.surveyEmails.filter(email => !this.initialEmailsInSelectedLists.includes(email))
      } else {
        return []
      }
    },
    removableEmails () {
      return this.survey.responses.filter(response => {
        if (this.removableSurveyEmails.includes(response.email)) {
          return response
        }
      })
    },
    initialEmailsInAnyList () {
      let emails = this.lists.map(list => {
        return list.participants.map(participant => participant.email)
      }).flat(1)
      return Array.from(new Set(emails))
    },
    initialActiveOptionsArray () {
      let options = []
      this.lists.forEach(list => {
        let emails = list.participants.map(participant => participant.email)
        if (emails.every(email => this.surveyEmails.includes(email))) { options.push(list) }
      })
      return options
    },
    initialEmailsInSelectedLists () {
      let emails = this.initialActiveOptionsArray.map(list => {
        return list.participants.map(participant => participant.email)
      }).flat(1)
      return Array.from(new Set(emails))
    },
    initialEmailsNotListed () {
      return this.initialEmailsInSelectedLists.filter(email => !this.addListsToNewEmails.includes(email))
    },
    listEmailsToRemove () {
      return this.listResponsesToRemove.map(list => list.email)
    },
    listResponsesToRemove () {
      return this.survey.responses.filter(response => {
        if (this.initialEmailsNotListed.includes(response.email)) { return response }
      })
    },
    newEmailsArray () {
      return this.newEmails.map(value => value.email)
    },
    filteredNewEmailsByLists () {
      return this.newEmailsArray.filter(email => !this.listEmails.includes(email))
    },
    totalNewEmails () {
      return this.addListsToNewEmails.filter(email => !this.surveyEmails.includes(email))
    },
    getStudyURL () {
      const baseUrl = window.location.origin
      return `${baseUrl}/study/${this.survey.id}`
    }
  },
  mounted () {
    // const id = this.survey.id
    // const scriptTag = document.createElement('script')
    // scriptTag.setAttribute('src', 'https://cdn.askhumans.io/main.js')
    // const scriptTwo = document.createElement('script')
    // scriptTwo.innerText = `let studyID = ${id}`
    // const scriptsToCopy = scriptTag + scriptTwo
    // const textArea = document.createElement('textarea')
    // textArea.innerText = scriptsToCopy
    // document.body.appendChild(textArea)
    // textArea.select()
    // document.execCommand('copy')
    // document.body.removeChild(textArea)
    // console.log(textArea)
    this.listEmails = this.initialEmailsInSelectedLists
  },
  methods: {

    downloadQr () {
      const link = document.createElement('a')
      link.download = `${this.survey.name}-qr-code.png`
      link.href = document.getElementById('qr-code').childNodes[0].toDataURL()
      link.click()
    },
    redirectTo (path) {
      if (path == 'dashboard') {
        window.location = '/projects'
      } else {
        window.location = `/study/${this.survey.id}/results`
      }
    },
    addNewEmail (payload) {
      this.newEmails.push(payload)
    },
    addNewEmailsToSurvey () {
      let url = `/study/${this.survey.id}/invite_emails`
      return this.$axios.request({
        url: url,
        method: 'post',
        data: { emails: this.totalNewEmails }
      })
        .then(res => { console.log('res', res) })
        .catch((err) => { console.log('error', err) })
    },
    removeListEmails () {
      return Promise.all(this.listEmailsToRemove.map(email => {
        let responseEmail = this.survey.responses.find(response => { return response.email === email })
        return this.removeNewEmail(responseEmail)
      }))
    },
    removeNewEmail (payload) {
      if (this.newEmails.indexOf(payload) > -1) {
        let emailToRemoveFromNew = this.newEmails.indexOf(payload)
        this.newEmails.splice(emailToRemoveFromNew, 1)
        return Promise.resolve()
      } else {
        return Promise.all(
          this.survey.responses.filter(member => member.email === payload.email).map(member => {
            let url = `/survey_responses/${payload.id}/`
            return this.$axios.request({
              url: url,
              method: 'delete',
              data: payload
            })
              .then(res => { console.log('response', res) })
              .catch((err) => { console.log('error', err) })
          })
        )
      }
    },
    updateSurveyAnonymity () {
      let url = `/study/${this.survey.id}`
      return this.$axios.request({
        url: url,
        method: 'patch',
        data: { survey: { anonymous: this.anonymousSurvey } }
      })
    },

    copyScript () {
      const textArea = document.createElement('textarea')
      const startingScriptSurvey = `<script> const _ahConfig = {
        studyID: ${this.survey.id},
        token: "${this.survey.widgetConfig.token ? this.survey.widgetConfig.token : ''}",
      }; `
      const endingScriptSurvey = `${'</'}script> \n`
      const newline = String.fromCharCode(13, 10)
      const startingScriptCDN = `${newline} <script src="https://cdn.askhumans.io/main.js">`
      const endingScriptCDN = `${'</'}script>`
      textArea.innerText = startingScriptSurvey + endingScriptSurvey + startingScriptCDN + endingScriptCDN
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      this.copiedScript = true
    },
    updateInsightType () {
      let url = `/study/${this.survey.id}`
      return this.$axios.request({
        url: url,
        method: 'patch',
        data: { survey: { insight_type: this.insightType } }
      })
    },
    updateLists (payload, index) {
      let newEmails = []
      payload.forEach(value => {
        value.participants.forEach(participant => {
          if (newEmails.includes(participant.email)) { return }
          newEmails.push(participant.email)
        })
      })
      this.listEmails = newEmails
    },
    copyToClipboard () {
      const url = this.getStudyURL
      navigator.clipboard.writeText(url)
      this.copied = true
    },
    copyIframeCode () {
      const iframeCode = `<iframe
  src="${window.location.origin}/study/${this.survey.id}"
  title="AskHumans Embedded Survey"
  style="width:1000px;height:800px;border: 0px"
  allow="microphone"
>
</iframe>`
      navigator.clipboard.writeText(iframeCode)
      this.copiedIframe = true
    },
    updateSurvey () {
      const reqs = []
      if (this.totalNewEmails.length) { reqs.push(this.addNewEmailsToSurvey()) }
      if (this.listEmailsToRemove) { reqs.push(this.removeListEmails()) }
      if (this.anonymousSurvey !== undefined) { reqs.push(this.updateSurveyAnonymity()) }
      if (this.insightType != null) {
        reqs.push(this.updateInsightType())
      }
      return Promise.all(reqs)
    },

    publishSurvey () {
      let url = `/study/${this.survey.id}/publish_survey`

      this.$axios
        .request({
          url: url,
          method: 'post',
          data: { survey: this.survey }
        })
        .then((res) => {
          res.data.survey.survey.published
            ? (window.location = `/study/${this.survey.id}/published`)
            : (window.location = `/study/${this.survey.id}/edit`)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    handleSave () {
      this.updateSurvey()
      this.displayPublishConfirmationModal = true
    }
  }
}
</script>
