<template>
  <div
    class="st-specifications__slide-icon-box"
  >
    <!-- @blur="closeMenu('showSlide')" -->
    <!-- <div
      class="st-specifications__slide-icon st-specifications__slide-color"
      :class="{ 'st-specifications__slide-active': showSlide }"
      tabindex="1"
      @click.self="toggleMenu('showSlide', $event)"
    >
      <div
        v-if="showSlide"
        class="st-specifications__slide-menu-overlay"
      >
        <div class="st-specifications__slide-menu">
          <div
            v-for="(c, boxIndex) in templates[currentTemplate]"
            :key="c"
            class="st-specifications__slide-box st-specifications__slide-box-active"
            @click.stop="selectBox(c, $event)"
          >
            <color-picker
              :box-index="boxIndex"
              @changed="onValueChnaged"
              @closed="handleClosed"
            />
          </div>
        </div>
      </div>

      <div />
    </div> -->
    <!-- <div
      class="st-specifications__slide-icon st-specifications__slide-text"
      tabindex="2"
      @click="addNewObject(null, 'text')"
    />
    <div
      class="st-specifications__slide-icon st-specifications__slide-temp"
      :class="{ 'st-specifications__slide-active': showTemplate }"
      tabindex="3"
      @click.self="toggleMenu('showTemplate', $event)"
      @blur="closeMenu('showTemplate')"
    >
      <div class="st-specifications__slide-menu-overlay">
        <div class="st-specifications__slide-menu">
          <div class="st-specifications__slide-box">
            <span
              class="st-specifications__temp-icon st-specifications__temp-icon-1"
              @click="$emit('changeTemplate', '1x1')"
            />
          </div>
          <div class="st-specifications__slide-box">
            <span
              class="st-specifications__temp-icon st-specifications__temp-icon-2"
              @click="$emit('changeTemplate', '1x2')"
            />
          </div>
          <div class="st-specifications__slide-box">
            <span
              class="st-specifications__temp-icon st-specifications__temp-icon-3"
              @click="$emit('changeTemplate', '2x1')"
            />
          </div>
          <div class="st-specifications__slide-box">
            <span
              class="st-specifications__temp-icon st-specifications__temp-icon-4"
              @click="$emit('changeTemplate', '1x3')"
            />
          </div>
        </div>
      </div>
    </div> -->
    <div
      class="w-full tw-flex tw-space-x-5 tw-mt-2"
      tabindex="4"
    >
      <!-- {{ isImageUploaded }} -->
      <div
        v-if="isImageUploaded"
        class="tw-flex tw-space-x-2 tw-items-center"
      >
        <img
          :src="activeQuestion.slideQuestionObjects[2].mediaUrl"
          class="tw-w-20 tw-h-20 tw-rounded-lg"
        >
        <span
          title="Delete image"
          class="tw-block tw-p-2 tw-mx-2 tw-text-end tw-cursor-pointer tw-rounded-md tw-border-white-two tw-border-solid"
          @click="deleteSlideImage(activeQuestion.slideQuestionObjects[2].id)"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_422_2869)">
              <path
                d="M8.49978 15.2498C12.4244 15.2498 15.6109 12.113 15.6109 8.24976C15.6109 4.38646 12.4244 1.24976 8.49978 1.24976C4.57517 1.24976 1.38867 4.38646 1.38867 8.24976C1.38867 12.113 4.57517 15.2498 8.49978 15.2498ZM8.49978 2.49026C11.7223 2.49026 14.3507 5.0776 14.3507 8.24976C14.3507 11.4219 11.7223 14.0092 8.49978 14.0092C5.27728 14.0092 2.64887 11.4219 2.64887 8.24976C2.64887 5.0776 5.27728 2.49026 8.49978 2.49026Z"
                fill="#474747"
              />
              <path
                d="M6.1594 8.95852H10.8401C11.1822 8.95852 11.4702 8.67498 11.4702 8.33827C11.4702 8.00156 11.1822 7.71802 10.8401 7.71802H6.1594C5.81734 7.71802 5.5293 8.00156 5.5293 8.33827C5.5293 8.67498 5.81734 8.95852 6.1594 8.95852Z"
                fill="#474747"
              />
            </g>
            <defs>
              <clipPath id="clip0_422_2869">
                <rect
                  width="14.2222"
                  height="14"
                  fill="white"
                  transform="translate(1.38867 1.24976)"
                />
              </clipPath>
            </defs>
          </svg>

        </span>
      </div>
      <ah-button
        v-else
        type="secondary"
        @click="isUploadOpen = true"
      >
        Upload Media
      </ah-button>
    </div>
    <st-media-uploader
      v-if="isUploadOpen"
      accept=".jpg, .jpeg, .png"
      :additional-props="additionalProps"
      :folders="folders"
      modal-title="Upload Media or Select From Library"
      :multi-upload="true"
      prop-name="slide_question_object[media]"
      :skip-cropper="true"
      request-type="post"
      :url="`/questions/${activeQuestion.id}/slide_question_objects`"
      @closeMediaUploader="isUploadOpen = false"
      @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { SLIDES_TEMPLATES } from '../../../../../utilities/enums'
import updateQuestion from '../../../../../mixins/question/updateQuestion.vue'
import { updateSlideQuestionObject } from '../../../../../mixins'
import { IconWrapper, StMediaUploader, AhButton } from '../../../../common'
import { SLIDE_COLORS } from '../constants'
import ColorPicker from './ColorPicker.vue'
export default {
  components: {
    IconWrapper,
    StMediaUploader,
    ColorPicker,
    AhButton
  },
  mixins: [updateQuestion, updateSlideQuestionObject],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    changeTemplate: {
      type: Function,
      required: false,
      default: null
    },
    uploaded: {
      type: Function,
      required: false,
      default: null
    }
  },
  data () {
    return {
      templates: SLIDES_TEMPLATES,
      colors: SLIDE_COLORS,
      showSlide: false,
      showText: false,
      showTemplate: false,
      selectedBox: null,
      isUploadOpen: false,

      showPicker: false
    }
  },
  computed: {
    ...mapGetters('specifications', ['currentTemplate', 'color', 'slideColors']),
    isImageUploaded () {
      if (this.activeQuestion.slideQuestionObjects.length < 3) return false
      return this.activeQuestion.slideQuestionObjects.some(object => object.slideObjectType === 'image')
    }
  },

  // watch for changes in the active question
  watch: {
    activeQuestion: {
      handler () {
        this.reset()
      },
      deep: true
    }
  },

  mounted () {
    // console.log(this.activeQuestion)
  },

  methods: {
    ...mapMutations('specifications', ['addImage', 'addObject', 'setActiveObject']),
    ...mapActions('specifications', ['setColor', 'addText']),
    additionalProps () {
      const newIndex = this.activeQuestion.slideQuestionObjects.length

      return {
        'slide_question_object[question_id]': this.activeQuestion.id,
        'slide_question_object[object_index]': newIndex,
        'slide_question_object[slide_object_type]': 'image',
        'slide_question_object[x_position]': 0,
        'slide_question_object[y_position]': 0,
        'slide_question_object[width]': 100,
        'slide_question_object[height]': 50,
        'slide_question_object[y]': 100
      }
    },
    deleteSlideImage (id) {
      this.deleteSlideObject(id, null)
      setTimeout(() => {
        this.$emit('uploaded', null)
        // this.$store.commit('toggleFullSpinner', true)
        // this.$axios.request({
        //   url: `/questions/${this.activeQuestion.id}`,
        //   method: 'get'
        // })
        //   .then((res) => {
        //     this.$store.commit('toggleFullSpinner', false)

        //     this.replaceActiveQuestion(res.data)

        //   })
        //   .catch(err => {
        //     this.$store.commit('toggleFullSpinner', false)

        //     console.log('bad', err)
        //   })
      }, 1000)
    },
    handleClosed () {
      this.closeMenu('showSlide')
    },
    onValueChnaged (color, index) {
      this.updateColor(color, index)
      this.showPicker = false
    },
    toggleShowPicker () {
      this.showPicker = true
    },
    toggleMenu (prop, event) {
      event.target.focus()
      if (this[prop]) {
        this[prop] = false
        return
      }
      this.showSlide = false
      this.showText = false
      this.showTemplate = false
      this[prop] = !this[prop]
    },
    closeMenu (prop) {
      if (this[prop]) {
        this[prop] = false
      }
    },
    selectBox (box, event) {
      if (this.selectedBox && box === this.selectedBox.boxNumber) {
        this.selectedBox = { boxNumber: null, show: false }
      } else {
        this.selectedBox = { boxNumber: box, show: true }
      }
    },
    updateColor (code, boxIndex) {
      let newSelectedBox
      if (!this.selectedBox.boxNumber) {
        newSelectedBox = boxIndex + 1
      } else {
        newSelectedBox = this.selectedBox.boxNumber
      }
      this.$nextTick(() => {
        this.setColor({
          code,
          boxIndex,
          elementRef: `box${newSelectedBox}`,
          previewElementRef: `preview-box${newSelectedBox}`
        })
        this.updateQuestion('slide_colors', this.slideColors)
        if (this.colors.indexOf(code) != -1) {
          this.closeMenu('showSlide')
        }
      })
    },
    addNewObject (event, type) {
      let object = {}
      const { slideQuestionObjects } = this.activeQuestion
      const containerWidth = 100
      const containerHeight = 100
      // const containerWidth = document.querySelector('.st-slides__canvas').offsetWidth
      // const containerHeight = document.querySelector('.st-slides__canvas').offsetHeight
      // getting index of new element
      const newIndex = slideQuestionObjects.length

      if (type === 'text') {
        object = {
          type,
          index: newIndex,
          content: 'Welcome',
          x: 0,
          y: 12,
          pxX: '0px',
          pxY: '10px',
          question_id: this.activeQuestion.id,
          object_index: newIndex,
          slide_object_type: type,
          x_position: 0,
          y_position: 0,
          pxWidth: object.width * containerWidth / 100 + 'px',
          pxHeight: object.height * containerHeight / 100 + 'px'
        }
        this.createNewObject({ slide_question_object: object }, null, res => {
          this.addObject({ ...object, id: res.id })
        })
      }
    },
    // deleteSlideObject (id) {
    //   deleteSlideObject(id, null)
    // },
    uploadComplete (payload) {
      const containerWidth = 100
      const containerHeight = 100
      // console.log(payload)
      // const containerWidth = document.querySelector('.st-slides__canvas').offsetWidth
      // const containerHeight = document.querySelector('.st-slides__canvas').offsetHeight

      payload.forEach(object => {
        this.addObject({
          id: object.id,
          index: object.objectIndex,
          type: object.slideObjectType,
          blob: object.mediaUrl,
          width: object.width,
          height: object.height,
          x: object.xPosition,
          y: object.yPosition,
          pxWidth: object.width * containerWidth / 100 + 'px',
          pxHeight: object.height * containerHeight / 100 + 'px',
          pxX: object.xPosition * containerWidth / 100 + 'px',
          pxY: object.yPosition * containerHeight / 100 + 'px'
        })
      })
      this.$emit('uploaded', null)
      this.isUploadOpen = false
    },
    reset () {
      this.showSlide = false
      this.showText = false
      this.showTemplate = false
      this.selectedBox = null
    }
  }
}
</script>
