<template>
  <div
    :class="{
      'tw-border-solid tw-flex tw-relative tw-mt-4 tw-rounded-xl tw-group tw-max-w-[680px] ': true,
      'tw-border-white-two tw-border-2  tw-cursor-pointer hover:tw-border-territory tw-transition-all tw-duration-200': !isActive,
      'tw-border-territory tw-border-[3px]': isActive,

      // 'st-new-question-tile--active': isActive
    }"
    @click="changeActiveQuestion(question)"
  >
  <div class="tw-opacity-0 group-hover:tw-opacity-100 tw-absolute tw-top-[40%] -tw-left-7 tw-cursor-move">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_931_3110)">
<path d="M5.48463 4.78203C6.23499 4.78203 6.84328 4.18767 6.84328 3.45449C6.84328 2.72131 6.23499 2.12695 5.48463 2.12695C4.73427 2.12695 4.12598 2.72131 4.12598 3.45449C4.12598 4.18767 4.73427 4.78203 5.48463 4.78203Z" fill="#474747"/>
<path d="M6.71285 3.45454C6.70893 3.95062 6.38917 4.41353 5.90627 4.58187C5.41684 4.75275 4.86998 4.60482 4.53587 4.21587C4.20436 3.82947 4.16912 3.25688 4.43668 2.82967C4.69901 2.40756 5.22237 2.17801 5.71963 2.27621C6.29259 2.38971 6.70893 2.8845 6.71285 3.45454C6.71415 3.61905 6.97518 3.61905 6.97387 3.45454C6.96996 2.84497 6.57711 2.29916 5.99241 2.0862C5.41423 1.87705 4.73164 2.05814 4.3414 2.52488C3.94464 2.99928 3.8833 3.68026 4.21219 4.20822C4.53848 4.73235 5.17669 4.99887 5.79011 4.879C6.47531 4.74382 6.96996 4.1317 6.97518 3.45454C6.97518 3.29003 6.71415 3.29003 6.71285 3.45454Z" fill="#474747"/>
<path d="M5.48463 9.32829C6.23499 9.32829 6.84328 8.73393 6.84328 8.00076C6.84328 7.26758 6.23499 6.67322 5.48463 6.67322C4.73427 6.67322 4.12598 7.26758 4.12598 8.00076C4.12598 8.73393 4.73427 9.32829 5.48463 9.32829Z" fill="#474747"/>
<path d="M6.71285 8.00081C6.70893 8.49688 6.38917 8.9598 5.90627 9.12813C5.41684 9.29901 4.86998 9.15108 4.53587 8.76213C4.20436 8.37573 4.16912 7.80314 4.43668 7.37593C4.69901 6.95382 5.22237 6.72428 5.71963 6.82247C6.29259 6.93597 6.70893 7.43077 6.71285 8.00081C6.71415 8.16531 6.97518 8.16531 6.97387 8.00081C6.96996 7.39124 6.57711 6.84543 5.99241 6.63246C5.41423 6.42332 4.73164 6.6044 4.3414 7.07115C3.94464 7.54554 3.8833 8.22653 4.21219 8.75448C4.53848 9.27861 5.17669 9.54514 5.79011 9.42526C6.47531 9.29009 6.96996 8.67797 6.97518 8.00081C6.97518 7.8363 6.71415 7.8363 6.71285 8.00081Z" fill="#474747"/>
<path d="M5.48463 13.8746C6.23499 13.8746 6.84328 13.2802 6.84328 12.547C6.84328 11.8138 6.23499 11.2195 5.48463 11.2195C4.73427 11.2195 4.12598 11.8138 4.12598 12.547C4.12598 13.2802 4.73427 13.8746 5.48463 13.8746Z" fill="#474747"/>
<path d="M6.71285 12.5471C6.70893 13.0431 6.38917 13.5061 5.90627 13.6744C5.41684 13.8453 4.86998 13.6973 4.53587 13.3084C4.20436 12.922 4.16912 12.3494 4.43668 11.9222C4.69901 11.5001 5.22237 11.2705 5.71963 11.3687C6.29259 11.4822 6.70893 11.977 6.71285 12.5471C6.71415 12.7116 6.97518 12.7116 6.97387 12.5471C6.96996 11.9375 6.57711 11.3917 5.99241 11.1787C5.41423 10.9696 4.73164 11.1507 4.3414 11.6174C3.94464 12.0918 3.8833 12.7728 4.21219 13.3007C4.53848 13.8249 5.17669 14.0914 5.79011 13.9715C6.47531 13.8364 6.96996 13.2242 6.97518 12.5471C6.97518 12.3826 6.71415 12.3826 6.71285 12.5471Z" fill="#474747"/>
<path d="M10.509 4.78203C11.2594 4.78203 11.8677 4.18767 11.8677 3.45449C11.8677 2.72131 11.2594 2.12695 10.509 2.12695C9.75868 2.12695 9.15039 2.72131 9.15039 3.45449C9.15039 4.18767 9.75868 4.78203 10.509 4.78203Z" fill="#474747"/>
<path d="M11.7373 3.45454C11.7333 3.95062 11.4136 4.41353 10.9307 4.58187C10.4413 4.75275 9.8944 4.60482 9.56028 4.21587C9.22878 3.82947 9.19354 3.25688 9.46109 2.82967C9.72343 2.40756 10.2468 2.17801 10.744 2.27621C11.317 2.38971 11.7333 2.8845 11.7373 3.45454C11.7386 3.61905 11.9996 3.61905 11.9983 3.45454C11.9944 2.84497 11.6015 2.29916 11.0168 2.0862C10.4386 1.87705 9.75605 2.05814 9.36582 2.52488C8.96905 2.99928 8.90771 3.68026 9.23661 4.20822C9.56289 4.73235 10.2011 4.99887 10.8145 4.879C11.4997 4.74382 11.9944 4.1317 11.9996 3.45454C11.9996 3.29003 11.7386 3.29003 11.7373 3.45454Z" fill="#474747"/>
<path d="M10.509 9.32829C11.2594 9.32829 11.8677 8.73393 11.8677 8.00076C11.8677 7.26758 11.2594 6.67322 10.509 6.67322C9.75868 6.67322 9.15039 7.26758 9.15039 8.00076C9.15039 8.73393 9.75868 9.32829 10.509 9.32829Z" fill="#474747"/>
<path d="M11.7373 8.00081C11.7333 8.49688 11.4136 8.9598 10.9307 9.12813C10.4413 9.29901 9.8944 9.15108 9.56028 8.76213C9.22878 8.37573 9.19354 7.80314 9.46109 7.37593C9.72343 6.95382 10.2468 6.72428 10.744 6.82247C11.317 6.93597 11.7333 7.43077 11.7373 8.00081C11.7386 8.16531 11.9996 8.16531 11.9983 8.00081C11.9944 7.39124 11.6015 6.84543 11.0168 6.63246C10.4386 6.42332 9.75605 6.6044 9.36582 7.07115C8.96905 7.54554 8.90771 8.22653 9.23661 8.75448C9.56289 9.27861 10.2011 9.54514 10.8145 9.42526C11.4997 9.29009 11.9944 8.67797 11.9996 8.00081C11.9996 7.8363 11.7386 7.8363 11.7373 8.00081Z" fill="#474747"/>
<path d="M10.509 13.8746C11.2594 13.8746 11.8677 13.2802 11.8677 12.547C11.8677 11.8138 11.2594 11.2195 10.509 11.2195C9.75868 11.2195 9.15039 11.8138 9.15039 12.547C9.15039 13.2802 9.75868 13.8746 10.509 13.8746Z" fill="#474747"/>
<path d="M11.7373 12.5471C11.7333 13.0431 11.4136 13.5061 10.9307 13.6744C10.4413 13.8453 9.8944 13.6973 9.56028 13.3084C9.22878 12.922 9.19354 12.3494 9.46109 11.9222C9.72343 11.5001 10.2468 11.2705 10.744 11.3687C11.317 11.4822 11.7333 11.977 11.7373 12.5471C11.7386 12.7116 11.9996 12.7116 11.9983 12.5471C11.9944 11.9375 11.6015 11.3917 11.0168 11.1787C10.4386 10.9696 9.75605 11.1507 9.36582 11.6174C8.96905 12.0918 8.90771 12.7728 9.23661 13.3007C9.56289 13.8249 10.2011 14.0914 10.8145 13.9715C11.4997 13.8364 11.9944 13.2242 11.9996 12.5471C11.9996 12.3826 11.7386 12.3826 11.7373 12.5471Z" fill="#474747"/>
</g>
<defs>
<clipPath id="clip0_931_3110">
<rect width="8" height="12" fill="white" transform="translate(4 2)"/>
</clipPath>
</defs>
</svg>

  </div>
    <!-- <icon-wrapper
    class="st-new-question-tile__question-type-icon"
    :actionable="isActive"
    :icon-name="iconName"
    /> -->
    <div class="tw-w-full">
      <div
        :class="isActive? 'tw-bg-light-four': ''"
        class="tw-flex  tw-p-5  tw-w-full tw-items-center tw-space-x-3 tw-justify-between tw-border-solid tw-rounded-xl tw-cursor-pointer"
        @click="setInActiveQuestion"
      >
        <div
          class="tw-flex tw-items-center tw-space-x-3 "
          :class="!isActive? 'tw-w-full': null"
        >
          <p v-if="question.questionType == 'opinion_metric'">
            Q{{ index + 1 }} 
          </p>
          <p
            v-else-if="question.questionType== 'slide'" 
            class="tw-flex tw-space-x-2 tw-items-center"
          >
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.14 1V13C19.14 13.41 18.8 13.75 18.39 13.75C17.98 13.75 17.64 13.41 17.64 13V1C17.64 0.59 17.98 0.25 18.39 0.25C18.8 0.25 19.14 0.59 19.14 1ZM1.25 0.25C0.84 0.25 0.5 0.59 0.5 1V13C0.5 13.41 0.84 13.75 1.25 13.75C1.66 13.75 2 13.41 2 13V1C2 0.59 1.66 0.25 1.25 0.25ZM13.82 0.25H5.82C4.3 0.25 3.07 1.48 3.07 3V8.01L5.76 5.81C6.44 5.36 7.25 5.38 7.84 5.82L9.67 7.19L11.72 5.82C12.41 5.36 13.34 5.45 13.93 6.04L16.57 8.08V3C16.57 1.48 15.34 0.25 13.82 0.25ZM12.55 7.08L10.94 8.15L11.27 8.4C11.6 8.65 11.67 9.12 11.42 9.45C11.17 9.78 10.7 9.85 10.37 9.6L6.94 7.03C6.86 6.97 6.74 6.96 6.65 7.03L3.07 9.96V11.01C3.07 12.53 4.3 13.76 5.82 13.76H13.82C15.34 13.76 16.57 12.53 16.57 11.01V9.99L12.94 7.18C12.78 7.03 12.65 7.02 12.55 7.09V7.08Z"
                fill="black"
              />
            </svg>
            <span>
              Slide 
            </span>
          </p>

          <p
            v-if="!isActive && question.questionType === 'opinion_metric'"
            
            class="tw-border tw-border-white-two tw-w-full tw-border-solid tw-rounded-xl tw-p-4 tw-max-h-[60px] tw-overflow-y-hidden"
            v-html="question.description"
            >
            <!-- {{ question.description || 'No description added yet!' }} -->
          </p>
        </div>
        <span
          v-if="!isActive"
          title="Delete Question"
          class="tw-block tw-p-2 tw-mx-2 tw-text-end hover:tw-border tw-rounded-md tw-border-white-two tw-border-solid"
          @click="deleteQuestionHandler($event)"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_422_2869)">
              <path
                d="M8.49978 15.2498C12.4244 15.2498 15.6109 12.113 15.6109 8.24976C15.6109 4.38646 12.4244 1.24976 8.49978 1.24976C4.57517 1.24976 1.38867 4.38646 1.38867 8.24976C1.38867 12.113 4.57517 15.2498 8.49978 15.2498ZM8.49978 2.49026C11.7223 2.49026 14.3507 5.0776 14.3507 8.24976C14.3507 11.4219 11.7223 14.0092 8.49978 14.0092C5.27728 14.0092 2.64887 11.4219 2.64887 8.24976C2.64887 5.0776 5.27728 2.49026 8.49978 2.49026Z"
                fill="#474747"
              />
              <path
                d="M6.1594 8.95852H10.8401C11.1822 8.95852 11.4702 8.67498 11.4702 8.33827C11.4702 8.00156 11.1822 7.71802 10.8401 7.71802H6.1594C5.81734 7.71802 5.5293 8.00156 5.5293 8.33827C5.5293 8.67498 5.81734 8.95852 6.1594 8.95852Z"
                fill="#474747"
              />
            </g>
            <defs>
              <clipPath id="clip0_422_2869">
                <rect
                  width="14.2222"
                  height="14"
                  fill="white"
                  transform="translate(1.38867 1.24976)"
                />
              </clipPath>
            </defs>
          </svg>

        </span>

        <editor
          v-if="isParentRendered && isActive && question.questionType === 'opinion_metric'"
          @blur="updateQuestion('description', activeQuestionDescription)"
          @keyup="questionDescriptionChangeHandler"
          classes="tw-border tw-border-solid tw-border-black-three tw-p-1 tw-rounded-xl tw-w-full tw-max-w-full"
          :description = "activeQuestionDescription"
          />

        <!-- <st-input
          v-if="isActive && question.questionType === 'opinion_metric'"
          v-model="description"
          class="tw-w-full"
          @click="preventEventBubble"
          name="question[description]"
          style-type="rounded"
          placeholder="Your title goes here, be brief and welcoming"
          @blur="updateQuestion('description', activeQuestion.description)"
        /> -->
        <span
          v-if="isActive"
          title="Delete Question"
          class="tw-block tw-p-2 tw-mx-2 tw-text-end tw-cursor-pointer hover:tw-border tw-rounded-md tw-border-white-two tw-border-solid"
          @click="deleteQuestionHandler($event)"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_422_2869)">
              <path
                d="M8.49978 15.2498C12.4244 15.2498 15.6109 12.113 15.6109 8.24976C15.6109 4.38646 12.4244 1.24976 8.49978 1.24976C4.57517 1.24976 1.38867 4.38646 1.38867 8.24976C1.38867 12.113 4.57517 15.2498 8.49978 15.2498ZM8.49978 2.49026C11.7223 2.49026 14.3507 5.0776 14.3507 8.24976C14.3507 11.4219 11.7223 14.0092 8.49978 14.0092C5.27728 14.0092 2.64887 11.4219 2.64887 8.24976C2.64887 5.0776 5.27728 2.49026 8.49978 2.49026Z"
                fill="#474747"
              />
              <path
                d="M6.1594 8.95852H10.8401C11.1822 8.95852 11.4702 8.67498 11.4702 8.33827C11.4702 8.00156 11.1822 7.71802 10.8401 7.71802H6.1594C5.81734 7.71802 5.5293 8.00156 5.5293 8.33827C5.5293 8.67498 5.81734 8.95852 6.1594 8.95852Z"
                fill="#474747"
              />
            </g>
            <defs>
              <clipPath id="clip0_422_2869">
                <rect
                  width="14.2222"
                  height="14"
                  fill="white"
                  transform="translate(1.38867 1.24976)"
                />
              </clipPath>
            </defs>
          </svg>

        </span>
      </div>
      <div
        v-if="isActive && question.questionType === 'opinion_metric'"
        class="tw-w-full"
      >
        <div class="st-specifications tw-pt-5 tw-px-5">
          <div class="st-specifications__section-title-row">
            <p class="tw-text-black-two tw-text-[15px]">
              How would you like participants to answer?
            </p>
            <st-tooltip
              tip="Voice recording requires participants to enable their microphone and speak their answer out loud. With typed response they will be able to type out their response."
              class="st-specifications__section-icon-tooltip"
              :direction="isDesktop ? 'bottom' : 'left'"
              :max-width="isDesktop ? 411 : 225"
            >
              <icon-wrapper
                icon-name="info"
              />
            </st-tooltip>
          </div>

          <div class="tw-flex tw-space-x-3 tw-mt-2">
            <selectable-button
              classes="!tw-p-5 "
              :selected="askForExplanation === 'voice_recording'"
              @click="updateQuestion('ask_for_explanation', 'voice_recording')"
            >
              <div class="tw-flex tw-flex-col tw-space-y-1">
                <span>
                  <svg
                    width="12"
                    height="17"
                    viewBox="0 0 12 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_422_2416)">
                      <path
                        d="M0.666992 7.79165H2.04199C2.04199 9.30991 2.8281 10.7132 4.10449 11.4721C5.38089 12.2311 6.9531 12.2311 8.22949 11.4721C9.50589 10.7128 10.292 9.30991 10.292 7.79165H11.667C11.6658 9.17107 11.1763 10.5023 10.2904 11.5365C9.40453 12.5707 8.18274 13.2361 6.85449 13.4085V15.5833H8.91699V17H3.41699V15.5833H5.47949V13.4085C4.15124 13.2361 2.92946 12.5707 2.04356 11.5365C1.15767 10.5023 0.668171 9.17107 0.666992 7.79165ZM2.72949 7.79165V3.54165C2.72949 2.27636 3.38478 1.107 4.44824 0.47436C5.51171 -0.158283 6.82228 -0.158283 7.88574 0.47436C8.94921 1.107 9.60449 2.27636 9.60449 3.54165V7.79165C9.60449 9.05693 8.94921 10.2263 7.88574 10.8589C6.82228 11.4916 5.51171 11.4916 4.44824 10.8589C3.38478 10.2263 2.72949 9.05693 2.72949 7.79165ZM4.10449 7.79165C4.10449 8.55098 4.49774 9.25243 5.13574 9.63169C5.77374 10.0114 6.56024 10.0114 7.19824 9.63169C7.83624 9.25203 8.22949 8.55057 8.22949 7.79165H6.85449V6.37498H8.22949V4.24998H6.85449V2.83331H8.10574C7.88417 2.2035 7.38681 1.7186 6.76492 1.52431C6.14264 1.33003 5.4681 1.44984 4.94442 1.8465C4.42074 2.24317 4.1096 2.87177 4.10449 3.54165V7.79165Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_422_2416">
                        <rect
                          width="11"
                          height="17"
                          fill="white"
                          transform="translate(0.666992)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                </span>
                <span>
                  Voice Recording
                </span>
              </div>
            </selectable-button>
            <selectable-button
              classes="!tw-p-5 "
              :selected="askForExplanation === 'text'"
              @click="updateQuestion('ask_for_explanation', 'text')"
            >
              <div class="tw-flex tw-flex-col tw-space-y-1">
                <span>
                  <svg
                    width="23"
                    height="17"
                    viewBox="0 0 23 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_422_2424)">
                      <path
                        d="M22.3746 16.4996H0.624996C0.279744 16.4996 0 16.2159 0 15.8659V4.56276C0 4.21271 0.279744 3.92908 0.624996 3.92908H22.375C22.7203 3.92908 23 4.21271 23 4.56276V15.8663C23 16.2164 22.7203 16.4996 22.375 16.4996H22.3746ZM1.24958 15.2314H21.7488V5.19603H1.24958V15.2314Z"
                        fill="black"
                      />
                      <path
                        d="M4.51457 7.94877H2.59179C2.24654 7.94877 1.9668 7.66513 1.9668 7.31508C1.9668 6.96503 2.24654 6.6814 2.59179 6.6814H4.51457C4.85982 6.6814 5.13957 6.96503 5.13957 7.31508C5.13957 7.66513 4.85982 7.94877 4.51457 7.94877Z"
                        fill="black"
                      />
                      <path
                        d="M8.48039 7.94877H6.55761C6.21236 7.94877 5.93262 7.66513 5.93262 7.31508C5.93262 6.96503 6.21236 6.6814 6.55761 6.6814H8.48039C8.82564 6.6814 9.10539 6.96503 9.10539 7.31508C9.10539 7.66513 8.82564 7.94877 8.48039 7.94877Z"
                        fill="black"
                      />
                      <path
                        d="M12.3261 7.94877H10.4033C10.0581 7.94877 9.77832 7.66513 9.77832 7.31508C9.77832 6.96503 10.0581 6.6814 10.4033 6.6814H12.3261C12.6713 6.6814 12.9511 6.96503 12.9511 7.31508C12.9511 7.66513 12.6722 7.94877 12.3261 7.94877Z"
                        fill="black"
                      />
                      <path
                        d="M16.3515 7.94877H14.4287C14.0835 7.94877 13.8037 7.66513 13.8037 7.31508C13.8037 6.96503 14.0835 6.6814 14.4287 6.6814H16.3515C16.6967 6.6814 16.9765 6.96503 16.9765 7.31508C16.9765 7.66513 16.6967 7.94877 16.3515 7.94877Z"
                        fill="black"
                      />
                      <path
                        d="M20.4081 7.94877H18.4853C18.1401 7.94877 17.8604 7.66513 17.8604 7.31508C17.8604 6.96503 18.1401 6.6814 18.4853 6.6814H20.4081C20.7534 6.6814 21.0331 6.96503 21.0331 7.31508C21.0331 7.66513 20.7534 7.94877 20.4081 7.94877Z"
                        fill="black"
                      />
                      <path
                        d="M4.51457 10.4254H2.59179C2.24654 10.4254 1.9668 10.1418 1.9668 9.79176C1.9668 9.44171 2.24654 9.15808 2.59179 9.15808H4.51457C4.85982 9.15808 5.13957 9.44171 5.13957 9.79176C5.13957 10.1418 4.85982 10.4254 4.51457 10.4254Z"
                        fill="black"
                      />
                      <path
                        d="M8.48039 10.4254H6.55761C6.21236 10.4254 5.93262 10.1418 5.93262 9.79176C5.93262 9.44171 6.21236 9.15808 6.55761 9.15808H8.48039C8.82564 9.15808 9.10539 9.44171 9.10539 9.79176C9.10539 10.1418 8.82564 10.4254 8.48039 10.4254Z"
                        fill="black"
                      />
                      <path
                        d="M12.3261 10.4254H10.4033C10.0581 10.4254 9.77832 10.1418 9.77832 9.79176C9.77832 9.44171 10.0581 9.15808 10.4033 9.15808H12.3261C12.6713 9.15808 12.9511 9.44171 12.9511 9.79176C12.9511 10.1418 12.6722 10.4254 12.3261 10.4254Z"
                        fill="black"
                      />
                      <path
                        d="M16.3515 10.4254H14.4287C14.0835 10.4254 13.8037 10.1418 13.8037 9.79176C13.8037 9.44171 14.0835 9.15808 14.4287 9.15808H16.3515C16.6967 9.15808 16.9765 9.44171 16.9765 9.79176C16.9765 10.1418 16.6967 10.4254 16.3515 10.4254Z"
                        fill="black"
                      />
                      <path
                        d="M20.4081 10.4254H18.4853C18.1401 10.4254 17.8604 10.1418 17.8604 9.79176C17.8604 9.44171 18.1401 9.15808 18.4853 9.15808H20.4081C20.7534 9.15808 21.0331 9.44171 21.0331 9.79176C21.0331 10.1418 20.7534 10.4254 20.4081 10.4254Z"
                        fill="black"
                      />
                      <path
                        d="M4.51457 12.9013H2.59179C2.24654 12.9013 1.9668 12.6176 1.9668 12.2676C1.9668 11.9175 2.24654 11.6339 2.59179 11.6339H4.51457C4.85982 11.6339 5.13957 11.9175 5.13957 12.2676C5.13957 12.6176 4.85982 12.9013 4.51457 12.9013Z"
                        fill="black"
                      />
                      <path
                        d="M20.4081 12.9013H18.4853C18.1401 12.9013 17.8604 12.6176 17.8604 12.2676C17.8604 11.9175 18.1401 11.6339 18.4853 11.6339H20.4081C20.7534 11.6339 21.0331 11.9175 21.0331 12.2676C21.0331 12.6176 20.7534 12.9013 20.4081 12.9013Z"
                        fill="black"
                      />
                      <path
                        d="M15.3907 13.147H7.51953C7.17428 13.147 6.89453 12.8634 6.89453 12.5133C6.89453 12.1633 7.17428 11.8796 7.51953 11.8796H15.3907C15.7359 11.8796 16.0157 12.1633 16.0157 12.5133C16.0157 12.8634 15.7359 13.147 15.3907 13.147Z"
                        fill="black"
                      />
                      <path
                        d="M2.59124 5.19649C2.30943 5.19649 2.054 5.00141 1.98478 4.71193C1.97036 4.65345 1.65107 3.27706 2.37494 2.17594C2.80754 1.51803 3.49474 1.1095 4.41679 0.961626C6.95632 0.556018 13.8222 0.415663 15.4401 0.548916C15.7833 0.577322 16.04 0.883094 16.012 1.23189C15.9852 1.58069 15.6795 1.83801 15.3392 1.81169C13.9808 1.70183 7.15655 1.80626 4.61166 2.21354C4.04063 2.30544 3.64965 2.52265 3.41564 2.87813C2.9987 3.51182 3.19646 4.40365 3.19852 4.41243C3.28009 4.75245 3.07451 5.0954 2.73997 5.17769C2.69012 5.19022 2.63985 5.19607 2.59083 5.19607L2.59124 5.19649Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_422_2424">
                        <rect
                          width="23"
                          height="16"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                </span>
                <span>
                  Typed Response
                </span>
              </div>
            </selectable-button>
            <selectable-button
              classes="!tw-p-5 "
              :selected="askForExplanation === 'let_user_decide'"
              @click="updateQuestion('ask_for_explanation', 'let_user_decide')"
            >
              <div class="tw-flex tw-flex-col tw-space-y-1">
                <span>
                  <svg
                    width="24"
                    height="23"
                    viewBox="0 0 24 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_422_2445)">
                      <path
                        d="M12.5489 7.90625H19.7364C19.9449 7.90625 20.1389 7.82 20.2755 7.66187L23.1505 4.4275C23.4164 4.13281 23.3877 3.68 23.093 3.41406L19.4993 0.179688C19.3699 0.0646875 19.1974 0 19.0177 0H11.8302C11.4349 0 11.1114 0.323437 11.1114 0.71875V7.1875H4.64269C4.463 7.1875 4.2905 7.25219 4.16113 7.37438L0.567375 10.6088C0.272688 10.8747 0.243938 11.3275 0.509875 11.6222L3.38488 14.8566C3.52144 15.0075 3.7155 15.0938 3.92394 15.0938H11.1114V21.5625H9.67394C9.27863 21.5625 8.95519 21.8859 8.95519 22.2812C8.95519 22.6766 9.27863 23 9.67394 23H13.9864C14.3818 23 14.7052 22.6766 14.7052 22.2812C14.7052 21.8859 14.3818 21.5625 13.9864 21.5625H12.5489V7.90625ZM21.598 4.01062L19.413 6.46875H12.5489V1.4375H18.7446L21.598 4.01062ZM4.24738 13.6562L2.09113 11.1981L4.96613 8.625H11.1114V13.6562H4.24738Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_422_2445">
                        <rect
                          width="23.0072"
                          height="23"
                          fill="white"
                          transform="translate(0.330078)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                </span>
                <span>
                  Let user decide
                </span>
              </div>
            </selectable-button>
          </div>
        </div>
        <div class="tw-mt-5 tw-px-5">
          <p class="tw-my-2 tw-text-black-two tw-text-[15px]">
            Upload an image (Optional)
          </p>
          <ah-button

            type="secondary"
            @click="openUploader"
          >
            Upload image
          </ah-button>
        </div>
        <draggable
          v-model="options"
          
          v-bind="dragOptions"
          class="st-specifications__draggable-options tw-px-5"
          @change="handleDrop"
        >
          <transition-group
            name="fade"
            mode="out-in"
          >
            <template v-if="options.length > 0">
              <st-upload-preview
                v-for="option in options"
                :key="`media-preview-${option.id}`"
                class="st-specifications__draggable-tile"

                :filename="fileNameFromUrl(option.mediaUrl)"
                :hide-bar="false"
                :src="option.mediaUrl"
                @remove="deleteOption(option.id)"
              />
            </template>
          </transition-group>
        </draggable>
        <div
          class="mb-20p mt-5 tw-px-5"
        >
          <div class="tw-flex tw-space-x-2 tw-items-center">
            <p class="tw-text-black-two tw-text-[15px]">
              Add follow up question?
            </p>
            <st-tooltip
              class="st-edit-audience__anonymous-tooltip"
              tip="Follow up question will add another question after the main question. For example, &quot;Please tell us more&quot;"
            >
              <icon-wrapper
                icon-name="info"
              />
            </st-tooltip>
          </div>

          <div class="tw-flex tw-space-x-3 tw-mt-2">
            <selectable-button
              classes="!tw-p-5 "
              :selected="followupQuestion"
              @click="toggleFollowupQuestion(true)"
            >
              Yes
            </selectable-button>
            <selectable-button
              classes="!tw-p-5 "
              :selected="!followupQuestion"
              @click="toggleFollowupQuestion(false)"
            >
              No
            </selectable-button>
          </div>
          <div
            v-if="followupQuestion"
            class="flex  mt-20p mb-20p"
          >
            <st-dropdown-menu
              :display-caption="true"
              :full-width="true"
              :initial-active-option="getSelectedFilterOption"
              :options="filterSelectOptions"
              @updateOption="filterOptionHandler"
            />
          </div>
          <div class="tw-mt-3 tw-flex tw-space-x-3 tw-items-center" @click="preventEventBubble">
      <ah-toggle-switch
        id="optionalQuestionButton"
        :checked="markAsOptional"
        @input="changeOptionalQuestion"
      />
      <span>
        Mark this question as optional
      </span>
    </div>
        </div>
      </div>
      <div v-else-if="isActive && question.questionType == 'slide'">
        <div class="w-full  tw-py-4 tw-px-5">
          <label class="">Layout</label>
          <st-dropdown-menu
            container-class="h-40p mt-1 w-full border-none rounded-4p border-black w-full-important"
            selected-class="rounded-4p border-gray-important bg-white min-w-full w-full-important"
            :display-caption="true"
            :initial-active-option="selectedSlideOption"
            :options="slideOptions"
            :class="{ ' border-gray-100 rounded-4p w-full': true }"
            @updateOption="slideSelectHandler"
          />
          <label class="tw-mt-3 tw-block">Header*</label>
          <st-input
            v-model="slideHeading"
            style-type="rounded"
            @input="headingChangeHandler"
            @blur="saveObject('heading')"
            
            @click="preventEventBubble"
          />

          <label class="tw-mt-1 tw-block">Description* </label>
         <div class="tw-mt-2 tw-border tw-border-white-three tw-rounded-md tw-border-solid">
          <editor
          v-if="isParentRendered"
          @blur="saveObject('description')"
          @keyup="descriptionChangeHandler"
          :description = "slideDescription"
          />
         </div>
          <!-- <textarea
            v-model="slideDescription"
            class="my-1 w-full tw-rounded-lg px-2 py-1 font-14"
            rows="10"
            @blur="saveObject('description')"
            @keyup="descriptionChangeHandler"
          /> -->
          
          <slide-controls
            v-if="activeQuestion.questionType === 'slide' && selectedSlideOption!==slideOptions[0]"
            @uploaded="uploadComplete"
            @changeTemplate="changeTemplate"
          />
        </div>
      </div>
      <!-- <span v-if="question.questionType=='slide'">
          <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1400_6287)">
              <path
                d="M0 0.995096V2.31493H0.641196V14.1938H10.2567V15.7182C9.88497 15.8238 9.61588 16.1734 9.61588 16.5896C9.61588 17.091 10.0131 17.5 10.5002 17.5C10.9873 17.5 11.3845 17.091 11.3845 16.5896C11.3845 16.1738 11.115 15.8306 10.7437 15.7182V14.1938H20.3592V2.31493H21V0.5H0.00041208V0.995096H0ZM10.8975 16.5832C10.8975 16.8076 10.7178 16.999 10.4936 16.999C10.2694 16.999 10.0898 16.814 10.0898 16.5832C10.0898 16.3524 10.2694 16.1674 10.4936 16.1674C10.7178 16.1674 10.8975 16.3588 10.8975 16.5832ZM19.8717 13.6992H1.12209V2.31535H19.8717V13.6992ZM0.481309 0.995096H20.5129V1.81983H0.481309V0.995096Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_1400_6287">
                <rect
                  width="21"
                  height="17"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>

        </span> -->
      <!-- <span>
        {{ question.questionType=='slide'? "Slide": question.description || 'Question' }}
      </span> -->
      <!-- </p> -->
      <!-- <p>
        {{ index + 1 }}
      </p> -->
    </div>
    <!-- <div
      v-if="!question.complete"
      class="st-new-question-tile__alert-icon-container"
    >
      <st-tooltip tip="This question is incomplete">
        <icon-wrapper
          class="st-new-question-tile__alert-icon"
          icon-name="alert"
        />
      </st-tooltip>
    </div> -->
    <st-media-uploader
      v-if="isUploadOpen"
      accept=".jpg, .jpeg, .png, .mp4"
      :additional-props="additionalProps"
      :folders="folders"
      modal-title="Upload Media or Select From Library"
      :multi-upload="true"
      prop-name="question_option[media]"
      :skip-cropper="true"
      url="/question_options"
      @closeMediaUploader="isUploadOpen = false"
      @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState, mapMutations, mapGetters } from 'vuex'
import updateQuestion, { actionSetter, mutationSetter } from '../../../mixins/question/updateQuestion.vue'
import { IconWrapper, StTooltip, StInput, AhButton, StDropdownMenu, StUploadPreview, SelectableButton, StMediaUploader, AhToggleSwitch, Editor } from '../../common'
import { FOLLOW_UP_QUESTIONS, SLIDE_TYPES } from './constants'
import saveUpdates from './saveUpdates'
import { fileName, isDesktop, updateSlideQuestionObject } from '../../../mixins'
import SlideControls from './question_specifications/slide/slide-controls'
export default {
  components: {
    IconWrapper,
    StTooltip,
    StInput,
    SelectableButton,
    StMediaUploader,
    AhButton,
    StUploadPreview,
    StDropdownMenu,
    SlideControls,
    AhToggleSwitch,
    draggable,
    Editor
  },
  mixins: [fileName, isDesktop, updateQuestion, saveUpdates, updateSlideQuestionObject],
  props: {
    index: {
      type: Number,
      required: true
    },
    folders: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: false,
      default: () => ({})
    },
    changeUpdatedSurvey: {
      type: Function,
      required: false,
      default: () => {}
    },
    updateSlide: {
      type: Function,
      required: false,
      default: () => {}
    },

  },
  data: function () {
    
    return {
      slideOptions: Object.values(SLIDE_TYPES),
      selectedSlideOption: Object.values(SLIDE_TYPES)[0],
      showImageButton: false,
      acceptedFileTypes: '.jpg, .jpeg, .png, .mp4',
      isUploadOpen: false,
      followupQuestion: false,
      selectedFilterOption: FOLLOW_UP_QUESTIONS[0],
      filterSelectOptions: FOLLOW_UP_QUESTIONS,
      slideHeading: "",
      markAsOptional: false,
      slideDescription: "",
      isParentRendered: false,
      activeQuestionDescription: "",
      timeoutId: null
    }
  },
  computed: {
    ...mapState('specifications', ['questions', 'activeQuestionIdx']),
    ...actionSetter(['askForExplanation']),
    ...mutationSetter(['description', 'options']),
    ...mapGetters('specifications', ['activeQuestion', '"objects",']),

    // new props
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        forceFallback: true
      }
    },
    getSelectedFilterOption () {
      let index = 0
      if (this.activeQuestion.followUpQuestion) {
        index = FOLLOW_UP_QUESTIONS.indexOf(this.activeQuestion.followUpQuestion)
      }
      return FOLLOW_UP_QUESTIONS[index]
    },
    getSelectedFollowUpQuestion () {
      return this.activeQuestion.followUpQuestion
    },
    highestOptionPriority () {
      const l = this.activeQuestion.options.length
      return l === 0 ? 0 : this.activeQuestion.options[l - 1].priority
    },

    // old computed props
    iconName () {
      return this.questionType === 'favorite_choice'
        ? 'check-mark'
        : this.questionType.split('_').join('-')
    },
    isActive () {
      return this.activeQuestionIdx === this.index
    },
    isIncomplete () {
      return !this.question.complete
    },
    metricName () {
      return this.questionType === 'culture_board' ? 'Image Board' : this.questionType.split('_').join(' ')
    },
    question () {
      return this.questions[this.index]
    },
    questionId () {
      // console.log("question id",this.question)
      return this.question.id
    },
    questionType () {
      if(!this.question)
      return
      return this.question.questionType
    },
    slideQuestionObjects () {
      return this.activeQuestion.slideQuestionObjects
    }
  },



  // // watch for the active question to change
  // watch: {
  //   activeQuestion: {
  //     handler (newVal, oldVal) {
  //       console.log(newVal)
  //       if (newVal.questionType === 'slide' && this.activeQuestion.slideQuestionObjects.length > 1) {
  //         this.slideHeading = this.activeQuestion.slideQuestionObjects[0].content
  //         this.slideDescription = this.activeQuestion.slideQuestionObjects[1].content
  //       }
  //     },
  //     deep: true
  //   }
  // },


  mounted () {
    // console.log('this is active question', this.activeQuestion)
    // console.log(this.question)
    // console.log(this.activeQuestion)
    this.markAsOptional = this.activeQuestion.optional
    if (this.activeQuestion.followUpQuestion) {
      this.followupQuestion = true
    } 
    if(this.activeQuestion.questionType === 'slide'){
      const headingObj = this.activeQuestion.slideQuestionObjects.find((obj)=>Number(obj.yPosition)==0)
      const descriptionObj = this.activeQuestion.slideQuestionObjects.find((obj)=>Number(obj.yPosition)==10)
      this.slideHeading = headingObj ? headingObj?.content : ""
      this.slideDescription = descriptionObj ? descriptionObj?.content : ""
      // this.slideHeading = this.activeQuestion.slideQuestionObjects[0].content
      // this.slideDescription = this.activeQuestion.slideQuestionObjects[1].content
      // console.log('this is slide saved description', this.activeQuestion.slideQuestionObjects[1].content)
    }
    if(this.activeQuestion.questionType !== 'slide'){
      // console.log(this.activeQuestion.description)
      this.activeQuestionDescription = this.activeQuestion.description || ""
    }
    this.$nextTick(() => {
      let alertIcons = []
      let offsets = []

      let allAlertIcons = document.querySelectorAll('.st-new-question-tile__alert-icon-tooltip')
      for (let i = 0; i < allAlertIcons.length; i++) {
        alertIcons.push(allAlertIcons[i])
        offsets.push(allAlertIcons[i].getBoundingClientRect().top)
      }

      // let parentScrollArea = document.querySelector('.st-new-question__scroll-area')
      // parentScrollArea.onscroll = () => {
      //   let scrollTop = parentScrollArea.scrollTop
      //   for (let i = 0; i < alertIcons.length; i++) {
      //     alertIcons[i].style.top = ((offsets[i] - scrollTop) - 80) + 'px'
      //   }
      // }
    })
    this.isParentRendered = true
  },


  // watch if the active question is changed
  watch: {
    activeQuestion: {
      handler (newVal, oldVal) {
        if (newVal === oldVal) {
        // Exit early if the value hasn't changed
        return;
      }
      if(!newVal){
        return
      }
      // check if the new value askForExplanation is changed
      // then update the question by calling changeUpdatedSurvey

      if(newVal.askForExplanation !== oldVal.askForExplanation){
        this.$emit('changeUpdatedSurvey', newVal)
      }
        
        if (newVal.questionType === 'slide' && this.activeQuestion.slideQuestionObjects.length > 1) {
          const headingObj = this.activeQuestion.slideQuestionObjects.find((obj)=>Number(obj.yPosition)==0)
      const descriptionObj = this.activeQuestion.slideQuestionObjects.find((obj)=>Number(obj.yPosition)==10)
      this.slideHeading = headingObj ? headingObj?.content : ""
      this.slideDescription = descriptionObj ? descriptionObj?.content : ""
        }
        if(newVal.questionType === 'slide' && this.activeQuestion.slideQuestionObjects.length >2){
          this.selectedSlideOption = Object.values(SLIDE_TYPES)[1]
          
        }
        if(newVal.id==oldVal.id){
          return
        }
        // if the new value is not slide than check for optional question
        if(newVal.questionType !== 'slide'){
          this.markAsOptional = this.activeQuestion.optional
          this.activeQuestionDescription = newVal.description
        }
      },
      deep: true
    }
  },


  methods: {
    ...mapMutations(['toggleFullSpinner']),
    ...mapMutations('specifications', ['mergeActiveQuestion', 'replaceActiveQuestion',"updateObject",]),

    changeOptionalQuestion () {
      this.markAsOptional = !this.markAsOptional

      // change updated survey and markAsOptional the currently active question
      setTimeout(() => {
        this.$emit('changeUpdatedSurvey', { ...this.activeQuestion, optional: this.markAsOptional })
        this.updateQuestion('optional', this.markAsOptional)
      }, 300)
    },
    preventEventBubble (e) {
      // console.log("prevent event bubble")
      e.stopPropagation()
    },
    setInActiveQuestion(e){
      
      if(this.isActive){
        this.$emit('setInActive')
        e.stopPropagation()
        return
      }
    },
    changeActiveQuestion(question){
      
      this.$emit('setActive')
      this.$emit('changeUpdatedSurvey', question)
    },
    headingChangeHandler(text) {
      this.$emit('updateSlide', { id: this.activeQuestion.id, heading: text })
      this.updateObject({
        type: "text",
        id: this.objects[0]?.id,
        content: text,
      });
    },
    descriptionChangeHandler(description) {
      // console.log(description)
      this.slideDescription = description
      this.$emit('updateSlide', { id: this.activeQuestion.id, description: description })
      this.updateObject({
        type: "text",
        id: this.objects[1]?.id,
        content: description,
      });
    },
    questionDescriptionChangeHandler(description) {
      // console.log(description)
      this.activeQuestionDescription = description
      this.$emit('changeUpdatedSurvey', { ...this.activeQuestion, description: description })
      // add debounce to update the question description
      

      if(this.timeoutId){
        clearTimeout(this.timeoutId)
      }
      this.timeoutId = setTimeout(() => {
        this.updateQuestion('description', description)
      }, 500);
    },
    changeFollowup () {
      if (this.followupQuestion) {
        this.updateQuestion('follow_up_question', this.selectedFilterOption)
      } else {
        this.updateQuestion('follow_up_question', null)
      }
    },
    slideSelectHandler (option) {
      if (option.includes('Image')) {
        this.showImageButton = true
      } else {
        this.showImageButton = false
      }
      this.selectedSlideOption = option
    },
    deleteQuestionHandler (e) {
      e.stopPropagation()
      this.$emit('deleteQuestion', this.questionId)
    },
    deleteOption (id) {
      this.toggleFullSpinner(true)
      this.$axios({
        url: `/question_options/${id}`,
        method: 'delete'
      })
        .then(res => {
          const idx = this.activeQuestion.options.findIndex(option => option.id === id)
          const newOptions = [...this.activeQuestion.options]
          newOptions.splice(idx, 1)
          this.mergeActiveQuestion(res.data.question)
          this.toggleFullSpinner(false)
        })
        .catch(() => { this.toggleFullSpinner(false) })
    },
    handleDrop () {
      let updatedOptions = this.options.map((option, index) => {
        if (option.priority !== (index + 1)) {
          this.updateQuestionOption(option.id, index, { priority: (index + 1) }, true)
        }
        return { ...option, priority: (index + 1) }
      })
      this.mutateQuestion('options', updatedOptions)
    },
    toggleFollowupQuestion (value) {
      this.followupQuestion = value
      this.changeFollowup()
    },
    filterOptionHandler (option) {
      this.selectedFilterOption = option
      this.changeFollowup()
    },
    openUploader () {
      if (this.isReadOnly) { return }
      this.isUploadOpen = true
    },
    deleteTile () {
      this.$emit('openDeleteModal')
    },
    showSpecifications () {
      this.$emit('showSpecifications', true)
    },
    additionalProps () {
      let i = this.highestOptionPriority
      return {
        'question_option[question_id]': this.activeQuestion.id,
        'question_option[priority]': () => { i++; return i }
      }
    },
    uploadComplete (payload) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        url: `/questions/${this.activeQuestion.id}`,
        method: 'get'
      })
        .then((res) => {
          this.$store.commit('toggleFullSpinner', false)
          this.isUploadOpen = false
          this.replaceActiveQuestion(res.data)
          this.$emit('changeUpdatedSurvey', res.data)
          // console.log(res.data)
        })
        .catch(err => {
          this.$store.commit('toggleFullSpinner', false)
          this.isUploadOpen = false
          console.log('bad', err)
        })
       
    },
    saveObject(field) {
      let object = {};
      if (field == "heading") {
       
        object = this.objects.find((obj) => {
          return obj.yPosition == 0;
        });
        object.content = this.slideHeading;
      } else if (field == "description") {
        object = this.objects.find((obj) => {
          return obj.yPosition == 10;
        });
        object.content = this.slideDescription;
      }
      const current = this.objects.find((obj) => {
        return obj.id === object.id;
      });
      // console.log(object);
      this.updateSlideQuestionObject(object.id, current.index, {
        slide_question_object: { content: object?.content, y: object.y },
      });
    },
    changeTemplate(template) {
      const confirm = window.confirm("Some changes will be lost");
      if (confirm) {
        this.setCurrentTemplate(template);
        this.updateQuestion("slide_template_key", template);
        setTimeout(() => {
          this.updateQuestion("slide_colors", []);
        }, 1000);
        this.resetSlideColors();
      }
    },
  }
}
</script>
<style scoped>
.question-title{
  line-height: 20px;
}
</style>
