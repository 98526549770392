export const QUESTION_DESCRIPTIONS = Object.freeze([
  // {
  //   description: 'Select your favorite image from a list of different images.',
  //   questionType: 'culture_board',
  //   name: 'Image Board',
  //   underScoredName: 'culture_board'
  // },
  // {
  //   description: 'Measure either positive or negative responses to a statement, image or rendering.',
  //   questionType: 'concept_ranking',
  //   iconName: 'concept-ranking',
  //   name: 'Concept Ranking',
  //   underScoredName: 'concept_ranking'
  // },
  // {
  //   description: 'Upload an image and let users select their favorite part of it.',
  //   questionType: 'element_preference',
  //   name: 'Element preference',
  //   optionsCount: 3,
  //   underScoredName: 'element_preference'
  // },
  // {
  //   description: 'This question will help you understand what participants will prefer based on the options you provide. Ask a question, provide as many options and participants will select only one of their choice.',
  //   questionType: 'favorite_choice',
  //   iconName: 'check-mark',
  //   name: 'Favorite Choice',
  //   underScoredName: 'favorite_choice'
  // },
  {
    description: 'This question allows for respondents to give open ended reactions to images, videos, or to a simply written question. Respondents’ commentary is analyzed by IBM Watson’s sentiment protocol.',
    questionType: 'opinion_metric',
    iconName: 'opinion-metric',
    name: 'Question',
    underScoredName: 'opinion_metric'
  },
  // {
  //   description: 'Choose your favorite concept from a set of different options.',
  //   questionType: 'pick_favorite',
  //   name: 'Pick Favorite',
  //   optionsCount: 2,
  //   underScoredName: 'pick_favorite'
  // },
  // {
  //   description: 'Tag the companies’ perception in the present and the future.',
  //   questionType: 'perception_slider',
  //   name: 'Perception Slider',
  //   optionsCount: 2,
  //   underScoredName: 'perception_slider'
  // },
  // {
  //   description: 'Drag a slider left or right between two concepts.',
  //   questionType: 'preference_slider',
  //   name: 'Preference Slider',
  //   underScoredName: 'preference_slider'
  // },
  // {
  //   description: 'Drag & Drop Pins along a X and Y axis to determine space needs.',
  //   questionType: 'quadrant_placement',
  //   name: 'Quadrant Placement',
  //   optionsCount: 2,
  //   underScoredName: 'quadrant_placement'
  // },
  // {
  //   description: 'Place tags into a plan to represent your desired use of space.',
  //   questionType: 'spatial_adjacencies',
  //   name: 'Spatial Adjacencies',
  //   optionsCount: 2,
  //   underScoredName: 'spatial_adjacencies'
  // },
  {
    description: 'Use slides to include a screen with additional information for your survey.',
    questionType: 'slide',
    iconName: 'slide',
    name: 'Slide',
    underScoredName: 'slide'
  }
  // {
  //   description: 'This question allows respondents to walk around a 360 virtual space and give feedback about what they see around them. Respondents’ commentary is transcribed and then analyzed by IBM Watson’s sentiment protocol.',
  //   questionType: 'virtual_tour',
  //   iconName: 'virtual-tour',
  //   name: 'Virtual Tour',
  //   underScoredName: 'virtual_tour'
  // },
  // {
  //   description: 'Select your favorite image from a list of different images.',
  //   questionType: 'culture_board',
  //   iconName: 'culture-board',
  //   name: 'Image Board',
  //   underScoredName: 'culture_board'
  // },
  // {
  //   description: 'Easily find relative appeal amongst two different options. This question type, text based, allows for ideas and concepts to be measured against one another for greater insight into what recipients value when a trade-off is necessary.',
  //   questionType: 'quadrant_placement',
  //   iconName: 'quadrant-placement',
  //   name: 'Quadrant Placement',
  //   underScoredName: 'quadrant_placement'
  // }
])

export const buttonPrimaryColor = '#7635FF'
export const buttonSecondaryColor = '#e8ddff'
export const WINDOW_TYPE = {
  ALL: 'All',
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly'
}

export const SLIDE_TYPES = {
  TEXT_ONLY: 'Text only',
  BOTTOM_IMAGE: 'Text + Image'
  // TOP_IMAGE: 'Text + Image Top'

}
export const FOLLOW_UP_QUESTIONS = [
  'Thank you, can you share some more detail?',
  'Can you explain further please.',
  'Is there anything additional you would like to say?',
  'Okay, can you go into more detail?',
  'Can you spend about 30 seconds sharing more detail?'

]

export const REWARD_TYPES = {
  COUPON: 'coupon',
  GIFT_CARD: 'gift card'
}
