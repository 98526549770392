<template>
  <div
    class="st-slides tw-h-full tw-space-y-2"
    style="min-width: 100%; min-height:500px;"
  >
    <div
      ref="slidesMobile"
      class="st-slides__canvas-main  scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black "
      style=" border:none; border-style: hidden; overflow-y:auto"
    >
      <!-- v-for="(templateVal, templateKey, index) in templates" -->
      <div

        :class="{
          'tw-mt-20 tw-bg-white tw-w-full': account && account.logoUrl,
        }"

        style="border:none; overflow-y: auto;"
      >
        <!-- 'st-slides__block': true,
        'st-slides__block--active': currentTemplate === templateKey,
        'st-slides__block--1x1' : templateKey === '1x1',
        'st-slides__block--1x2' : templateKey === '1x2',
        'st-slides__block--2x1' : templateKey === '2x1',
        'st-slides__block--1x3' : templateKey === '1x3' -->
        <!-- eslint-disable -->
          <div
            v-for="(text, i) in texts"
            class="tw-py-1 tw-pr-2 "
            :class="{
              '!tw-text-lg !tw-font-semibold': Number(text.yPosition) == 0,
              '': Number(text.yPosition) == 0 && account && account.logoUrl,
              '': Number(text.yPosition) !==0 && account && account.logoUrl,
            }"
            :key="'text'+i"
            :style="{ left: text.xPosition+'%', display:'block', top: text.yPosition+'%', zIndex: 99,fontSize:i==0?'22px':'', fontWeight:i==0?600:400}"
            
            v-html="addStylesToContent(text.content)"
          />
        <!-- eslint-enable -->
        <img
          v-for="(image,i) in images"
          :key="i"
          :style="{ objectFit: 'cover', left: image.xPosition+'%', bottom: image.yPosition+'%', width: image.width+'%', height: image.height+'%', zIndex: 9}"
          :src="image.mediaUrl"
          alt=""
          class="tw-mt-4"
        >
        <!-- <template v-if="currentTemplate === templateKey">
          <div
            v-for="(val, boxIndx) in templateVal"
            :key="'preview'+val"
            :ref="'preview-box' + val"
            :class="{
              'st-slides__box': true,
            }"
            :style="{backgroundColor: slideColors[boxIndx]}"
          />
        </template> -->
      </div>
    </div>
    <div class="st-specifications__button-slides-canvas">
      <st-button
        class="st-slide__button"
        :caption="buttonCaption"
        :underline="false"
        icon-name="arrow"
        :variant="['primary']"
        @click="submitQuestionAnswer"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SLIDES_TEMPLATES } from '../../../../utilities/enums'
import { StButton } from '../../../common'
import { submitQuestionAnswer } from '../../../../mixins'

export default {
  components: {
    StButton
  },
  mixins: [submitQuestionAnswer],
  props: {
    account: {
      type: Object,
      required: true
    },

    questionId: {
      type: Number,
      required: true
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonCaption: {
      type: String,
      required: false
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data: function () {
    return {
      templates: SLIDES_TEMPLATES
    }
  },
  mounted () {
    console.log('mounted', this.account)
    // this.$nextTick(() => {
    //   this.$refs.slidesMobile.scrollTop = 0
    // })
  },
  computed: {
    ...mapState('participants', { participantActiveQuestion: 'activeQuestion' }),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    activeQuestion () {
      return this.isSpecsDesktopPreview ? this.specsActiveQuestion : this.participantActiveQuestion
    },
    isSpecsDesktopPreview () {
      return this.isSpecificationsPreview
    },
    currentTemplate () {
      return this.activeQuestion.slideTemplateKey
    },
    slideColors () {
      return this.activeQuestion.slideColors
    },
    objects () {
      return this.activeQuestion.slideQuestionObjects
    },
    texts () {
      if (!this.activeQuestion.slideQuestionObjects) return []
      return this.activeQuestion.slideQuestionObjects.filter(o => o.slideObjectType === 'text')
    },
    images () {
      if (!this.activeQuestion.slideQuestionObjects) return []
      return this.activeQuestion.slideQuestionObjects.filter(o => o.slideObjectType === 'image')
    }
  },
  methods: {
    addStylesToContent (content) {
      // Add styles to ul, ol, li, and p elements
      const styledContent = content
        .replace(/<ul/g, '<ul style="list-style-type: disc !important; padding-left: 1.5rem !important;"')
        .replace(/<ol/g, '<ol style="list-style-type: decimal !important; padding-left: 1.5rem !important;"')
        .replace(/<li/g, '<li style="padding-top: 7px !important; padding-bottom: 7px !important;"')
        .replace(/<p/g, '<p style="margin-top: 7px !important; margin-bottom: 7px !important;"')
        .replace(/<a/g, '<a style="color: #BE6744 !important; text-decoration: underline !important;"')

      return styledContent
    },

    answerData () {
      let answer = new FormData()
      answer.append('response[value]', '')
      return answer
    }
  }
}
</script>
