<template>
  <st-modal v-model="showModal">
    <template v-slot:modalTitle>
      <span class="font-18p font-bold">
        {{ title }}
      </span>
    </template>
    <template v-slot:modalActions>
      <div class="st-member-groups__modal-text">
        <input
          v-model="groupName"
          class="w-full my-2 py-3 rounded-4p px-2-important border-gray-100 placeholder-black"
          :show-label="true"
          label="Group name *"
          placeholder="Group name*"
          @keyup.enter="groupSubmitHandler"
        >
        <span class="text-error">{{ error }}</span>
      </div>
      <div class="flex space-x-2 justify-end">
        <button
          class="bg-gray-100 text-gray-two rounded-4p p-3 "
          @click="toggle"
        >
          Cancel
        </button>
        <button
          class="bg-blue-primary p-3   rounded-4p  text-white"
          @click="groupSubmitHandler"
        >
          {{ buttonName }}
        </button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import { StInput, StModal } from '../../common'
export default {
  components: {
    StInput,
    StModal
  },
  props: {
    title: {
      type: String,
      require: true,
      default: ''
    }
  },
  data: function () {
    return {
      showModal: false,
      groupName: '',
      error: '',
      groupId: null,
      buttonName: ''
    }
  },
  methods: {
    toggle (groupId, name) {
      this.showModal = !this.showModal
      this.groupName = name
      this.error = ''
      this.groupId = groupId
      this.buttonName = this.groupId ? 'Rename group' : 'Create group'
    },
    groupSubmitHandler () {
      this.$emit('loader', true)
      const params = new URLSearchParams(window.location.search)
      if (this.groupName !== '') {
        if (!this.groupId) {
          // Create new group
          this.$axios
            .request({
              url: '/entity_sentiments_groups/create/',
              method: 'post',
              data: {
                name: this.groupName,
                question_id: params.get('question')
              }
            })
            .then((res) => {
              if (res.data.error) {
                this.error = res.data.message
              } else {
                this.$store.dispatch('insights/updateSentimentGroup', {
                  group: res.data.group,
                  type: 'add'
                })
                this.$emit('showToast', 'Group created successfully')
                this.showModal = false
                this.groupName = ''
                this.error = ''
                this.groupId = null
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$axios
            .request({
              url: `/entity_sentiments_groups/${this.groupId}/update/`,
              method: 'patch',
              data: {
                name: this.groupName,
                question_id: params.get('question')
              }
            })
            .then((res) => {
              if (res.data.error) {
                this.error = res.data.message
              } else {
                this.$store.dispatch('insights/updateSentimentGroup', {
                  group: res.data.group,
                  type: 'update'
                })
                this.$emit('showToast', 'Group renamed successfully')
                this.showModal = false
                this.groupName = ''
                this.error = ''
                this.groupId = null
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      } else {
        this.error = 'Please enter group name'
      }
      setTimeout(() => {
        this.$emit('loader', false)
      }, [1000])
    }
  }
}
</script>
