<script>

export default {
  props: {
    questionResponses: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
  },
  mounted: function () {
    this.loadResponses()
  },
  methods: {
    loadResponses () {
      // should be defined in each question type
      // console.log('previous response data', this.questionResponses)
    }
  }
}
</script>
