<template>
  <div class="tw-h-[90vh] st-survey-hub">
    <template v-if="initialSurvey.account.verified">

      <preview-bar :survey-id="survey.id" :show-preview="false" />
      <div class="bx--grid tw-h-full">
        <div class="flex flex-col tw-justify-center bx--col-sm-9 mx-auto tw-h-full h-full">
          <div class="tw-mx-auto tw-w-full tw-max-w-[550px]">
          
            <text-divider text="Set a name for your study" />
            <div class="mt-5">
          
              <ah-input
                v-model="surveyName"
                placeholder="Study title"
                :classes="'!tw-text-lg'"
                @blur="editSurveyName"
                @enter="editSurveyName"
                :error="errorClass?'Name is taken': null"
              />
              
            </div>
            <div class="mt-5">
              <text-divider
                text="Select study type and incentives"
              ></text-divider>
            </div>
            <st-dropdown-menu
              ref="audienceDropDown"
              container-class="tw-mt-6 w-full border-none tw-rounded-xl border-black"
              selected-class="tw-rounded-xl tw-border-white-two bg-white tw-py-4 "
              caption="Audience"
              :display-caption="true"
              :full-width="true"
              :initial-active-option="selectedStudyType"
              :options="studyTypeOptions"
              @updateOption="changeSelectedInsightType"
            />
            <p
              class="tw-mt-4 tw-text-black-one tw-text-sm"
              v-if="
                selectedStudyType === studyTypeOptions[0] ||
                  selectedStudyType === studyTypeOptions[1]
              "
            >
              This is the most popular study that is run. It allows organizations
              to respond to each respondent, send rewards or discounts and keep
              all the insights {{ publicInsight? 'Public': 'Private' }}.
            </p>
            <p v-else class="tw-mt-4">
              With this type of study, organizations can't respond or send rewards
              to participants as their identity will be anonymous. 
            </p>
            <st-dropdown-menu
              v-if="selectedStudyType === studyTypeOptions[0] || selectedStudyType === studyTypeOptions[1]"
              ref="incentiveDropDown"
              container-class="tw-mt-12 w-full border-none tw-rounded-xl border-black"
              selected-class="tw-rounded-xl tw-border-white-two bg-white tw-py-4 "
              caption="Audience"
              :display-caption="true"
              :full-width="true"
              :initial-active-option="getSelectedIncentives"
              :options="incentiveOptions"
              @updateOption="changeSelectedIncentive"
            />
            <div
              class="tw-flex tw-items-center tw-space-x-3 tw-mt-4 tw-w-full"
              v-if="selectedIncentive === incentiveOptions[1]"
            >
              <svg
                width="34"
                height="18"
                class="tw-flex-shrink-0"
                viewBox="0 0 34 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_77_5927)">
                  <path
                    d="M24.7385 0C21.6102 0 18.6877 1.56 17 4.04C15.3123 1.56 12.3898 0 9.2615 0C4.15739 0 0 4.04 0 9C0 13.96 4.15739 18 9.2615 18C12.3898 18 15.3123 16.44 17 13.96C18.6877 16.44 21.6102 18 24.7385 18C29.8426 18 34 13.96 34 9C34 4.04 29.8838 0 24.7385 0ZM24.7385 16.4C21.7337 16.4 18.9758 14.64 17.7409 11.96L17 10.28L16.2591 11.96C15.0242 14.64 12.3075 16.4 9.2615 16.4C5.06295 16.4 1.64649 13.08 1.64649 9C1.64649 4.92 5.06295 1.6 9.2615 1.6C12.2663 1.6 15.0242 3.32 16.2591 6.04L17 7.72L17.7409 6.04C18.9758 3.36 21.6925 1.6 24.7385 1.6C27.7845 1.6 30.6659 3.52 31.8184 6.2C31.0775 5.6 30.1719 5.2 29.1017 5.2C26.8378 5.2 25.0266 7 25.0266 9.16C25.0266 11.32 26.8789 13.12 29.1017 13.12C30.0073 13.12 30.8305 12.84 31.5303 12.36C30.2542 14.76 27.7022 16.4 24.7385 16.4Z"
                    fill="black"
                  />
                  <path
                    d="M12.2663 13.16C14.5169 13.16 16.3414 11.387 16.3414 9.19999C16.3414 7.01294 14.5169 5.23999 12.2663 5.23999C10.0158 5.23999 8.19128 7.01294 8.19128 9.19999C8.19128 11.387 10.0158 13.16 12.2663 13.16Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_77_5927">
                    <rect width="34" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
  
              <span>
                This study will be available within 72 hours after gift cards get
                approved. If needed, you can introduce additional rewards to
                reinstate it. Sending rewards must be done manually through our
                platform.
              </span>
            </div>
            <div class="tw-mt-5">
              <text-divider
                text="Select language for study"
              ></text-divider>
            </div>
            <st-dropdown-menu
              
              ref="incentiveDropDown"
              container-class="tw-mt-5 w-full border-none tw-rounded-xl border-black"
              selected-class="tw-rounded-xl tw-border-white-two bg-white tw-py-4 "
              caption="Audience"
              :display-caption="true"
              :full-width="true"
              :initial-active-option="selectedLanguage"
              :options="languageOptions"
              @updateOption="changeSelectedLanguage"
            />
            <div>
             <div class="tw-flex tw-items-center tw-space-x-3 tw-mt-3">
              <ah-toggle-switch :checked="collectEmails" id="collectEmailToggle" @input="changeCollectEmail" >
             
            </ah-toggle-switch>
            <span>
              Ask respondents for email address as optional
            </span>
             </div>
            </div>
            <div class="tw-flex tw-justify-end tw-mt-9 tw-w-full">
              <ah-button
              size="large"
              v-if="!survey.published"
              :disabled="surveyName == '' || !surveyName"
              class="py-3"
              @click="saveAndToHub"
            >
            Next Step
            </ah-button>
            </div>
           </div>
        </div>
  
          </div>
    </template>
    <template v-else>
      <div  class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-screen">
        
        <div>
          <p class="tw-font-semibold tw-text-[24px] tw-text-center">
          You’ll have to get credits to publish
        </p>
        <div class="tw-flex tw-justify-between">
          <p class="tw-text-center">
            Please schedule a time with us to get more credits, and 
learn more about our pricing options.
          </p>
        </div>
        <p class="tw-text-center tw-text-primary">
          <a href="mailto:zak@askhumans.com?subject=Credits%20Required&body=Hello%20Zak,%0D%0A%0D%0AI%20need%20more%20credits%20for%20my%20project.%0D%0A%0D%0AThank%20you,%0D%0A[Your%20Name]">      Get more credits
          </a>
        </p>
        </div>
        
      </div>
            
           
    </template>
   
    <st-modal v-model="editNameModal" :scrollable-modal="true">
      <template slot="modalTitle">
        Edit Study Name
      </template>
      <template slot="modalActions">
        <st-input
          v-model="surveyName"
          autofocus
          class="st-survey-hub__edit-name-input"
          :show-label="true"
          label="Study Name"
        />
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          :disabled="!surveyName"
          icon-name="save"
          @click="editSurveyName"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInput,
  StModal,
  TopHeader,
  StInlineSpinner,
  StDropdownMenu,
  TextDivider,
  AhButton,
  AhToggleSwitch,
  AhInput,
} from "../../common";
import { isDesktop } from "../../../mixins";
import { ParticipantAvatar } from "../insights";
import SurveyHubCard from "./survey-hub-card";
import SurveyMembers from "./survey-members";
import Steps from "./steps.vue";
import PreviewBar from "./preview-bar.vue";
import { REWARD_TYPES } from "./constants";

export default {
  components: {
    TopHeader,
    IconWrapper,
    StInlineSpinner,
    Steps,
    StButton,
    StHyperlink,
    StInput,
    StModal,
    SurveyHubCard,
    SurveyMembers,
    ParticipantAvatar,
    PreviewBar,
    StDropdownMenu,
    TextDivider,
    AhButton,
    AhToggleSwitch,
    AhInput
  },
  mixins: [isDesktop],
  props: {
    initialSurvey: {
      type: Object,
      required: true,
    },
    questionCount: {
      type: Number,
      required: true,
    },
    users: {
      type: Array,
      required: false,
      default: () => [],
    },
    account: {
      type: Object,
      required: false,
  
    }
  },
  data: function() {
    return {
      addMembersModalOpen: false,
      editNameModal: false,
      anonymous: false,
      publicInsight: false,
      shareableLink: true,
      incentiveOptions: ["No Incentive", "Amazon Gift Cards", "Coupon Code"],
      selectedIncentive: "No Incentive",
      languageOptions: ["English", "Hindi", "Spanish"],
      selectedLanguage: "English",
      studyTypeOptions: [
        "Non Anonymous with Private Insights",
        "Non Anonymous with Public Insights",
        "Anonymous with Private Insights",
        "Anonymous with Public Insights",
      ],
      selectedStudyType: 'Non Anonymous with Private Insights',
      
      survey: this.initialSurvey,
      surveyName: this.initialSurvey.name,
      errorClass: null,
      collectEmails: false,
      languageOptionsObject: {
        "en-US": "English",
        "hi-IN": "Hindi",
        "es-ES": "Spanish",
      }
    };
  },
  computed: {
    ...mapState(["currentUser", "navOpen"]),
    limitSurveyTeamMemberAvatars() {
      return this.survey.members.slice(0, 5);
    },
    getSelectedStudyType() {
      
      return this.selectedStudyType;
    },
    getSelectedIncentives() {
      if(this.initialSurvey.reward){
        if(this.initialSurvey.rewardType == REWARD_TYPES.GIFT_CARD){
          return this.incentiveOptions[1]
        }
        else if(this.initialSurvey.rewardType == REWARD_TYPES.COUPON){
          return this.incentiveOptions[2]
        }
      }
      else{
        return this.incentiveOptions[0]
      }
      
      return this.selectedIncentive;
    },
    shareButtonSpacing() {
      let styleObject = {};
      this.limitSurveyTeamMemberAvatars.length <= 3
        ? (styleObject["margin"] = "0 0 0 16px")
        : (styleObject["margin"] = "0 0 0 -16px");
      return styleObject;
    },
    surveySectionsComplete() {
      return this.requiredSections.every((section) => {
        return section.complete;
      });
    },
    surveyUsers() {
      return this.survey.members.map((member) => {
        return {
          ...member.user,
          permission: member.permission,
          surveyMemberId: member.id,
        };
      });
    },
    canEdit() {
      return this.initialSurvey.canEdit;
    },
  },
  watch: {
    navOpen(newValue) {
      if (!this.isDesktop) {
        this.addMembersModalOpen = newValue;
      }
    },
  },
  mounted() {
      console.log(this.initialSurvey)
      this.collectEmails = this.initialSurvey.collectEmail
      if(this.initialSurvey.insightType && this.initialSurvey.anonymous){ 
        this.selectedStudyType = this.studyTypeOptions[3]
      }
      else if(this.initialSurvey.insightType && !this.initialSurvey.anonymous){
        this.selectedStudyType = this.studyTypeOptions[1]
      }
      else if(!this.initialSurvey.insightType && this.initialSurvey.anonymous){
        this.selectedStudyType = this.studyTypeOptions[2]
      }
      else if(!this.initialSurvey.insightType && !this.initialSurvey.anonymous){
        this.selectedStudyType = this.studyTypeOptions[0]
      }
      
      else{
        this.selectedStudyType = this.studyTypeOptions[0]
      }

      if(this.initialSurvey.language == "en-US"){
        this.selectedLanguage = this.languageOptions[0]
      }
      else if(this.initialSurvey.language == "hi-IN"){
        this.selectedLanguage = this.languageOptions[1]
      }
      else if(this.initialSurvey.language == "es-ES"){
        this.selectedLanguage = this.languageOptions[2]
      }
     


      if(this.initialSurvey.reward){
        if(this.initialSurvey.rewardType == REWARD_TYPES.GIFT_CARD){
          this.selectedIncentive = this.incentiveOptions[1]
        }
        else if(this.initialSurvey.rewardType == REWARD_TYPES.COUPON){
          this.selectedIncentive = this.incentiveOptions[2]
        }
      }
      else{
        this.selectedIncentive = this.incentiveOptions[0]
      }

    this.shareableLink =
      this.initialSurvey.surveyType === "study" ? true : false;
    this.anonymous = this.initialSurvey.anonymous;
    this.publicInsight = this.initialSurvey.insightType;
  },
  methods: {
    changeSelectedLanguage(language) {
      
      this.selectedLanguage = language;
      this.saveChangedLanguage();
    },
    changeCollectEmail(){
      this.collectEmails = !this.collectEmails
      this.$axios.request({
        method: 'patch',
        url: `study/${this.survey.id}/`,
        data: {
          survey: { collect_email: this.collectEmails }
        }
      })
        .then(res => { console.log('saved') })
        .catch(err => { console.log(err) })
    },
    changeShareableLink(option) {
      if (option === "link") {
        this.shareableLink = true;
      } else {
        this.shareableLink = false;
      }
      this.updateSurveyType();
    },
    saveAndToHub() {
      this.$store.commit('toggleFullSpinner', true)
      setTimeout(() => {
        if (!this.errorClass) {
          const url = `/study/${this.survey.id}/edit/landing_page`;
          window.location = url;
          this.$store.commit("toggleInlineSpinner", false);
        }
      }, 100);
    },
    editSurveyName() {
      
      this.errorClass = null;
     if(this.surveyName){
      let url = `/study/${this.survey.id}`;
      this.$store.commit("toggleInlineSpinner", true);
      this.$axios
        .request({
          method: "patch",
          url: url,
          data: { name: this.surveyName },
        })
        .then((res) => {
          if (res.data?.message?.includes("already")) {
            this.errorClass = "error";
          }
          this.editNameModal = false;
          setTimeout(() => {
            this.$store.commit("toggleInlineSpinner", false);
          }, 500);
        })
        .catch((err) => {
          console.error(err);
          this.surveyName = this.survey.name;
        });
     }
    },
    changeSelectedIncentive(type) {
      this.selectedIncentive = type;  
      this.saveReward()
    },
    saveChangedLanguage() {
      const languageShortNames = {
        English: "en-US",
        Hindi: "hi-IN",
        Spanish: "es-ES",
      };
      this.$axios.request({
        method: 'patch',
        url: `study/${this.survey.id}/`,
        data: {
          survey: { language: languageShortNames[this.selectedLanguage] }
        }
      })
        .then(res => { console.log('saved') })
        .catch(err => { console.log(err) })
    },
    saveReward(){
      let rewardType = null
      if(this.selectedIncentive == this.incentiveOptions[1]){
        rewardType = REWARD_TYPES.GIFT_CARD
      }
      else if(this.selectedIncentive == this.incentiveOptions[2]){
        rewardType = REWARD_TYPES.COUPON
      }
      const data = {
          reward: this.selectedIncentive !== "No Incentive" ? true : false,
          reward_type: rewardType
      
        }
      this.$axios.request({
        method: 'patch',
        url: `study/${this.survey.id}/`,
        data: {
          survey: { ...data }
        }
      })
        .then(res => { console.log('saved') })
        .catch(err => { console.log(err) })
    },
    changeSelectedInsightType(type) {
      this.selectedStudyType = type;
      if (type === this.studyTypeOptions[0]) {
        this.anonymous = false;
        this.publicInsight = false;
      } else if (type === this.studyTypeOptions[1]) {
        this.anonymous = false;
        this.publicInsight = true;
      } else if (type === this.studyTypeOptions[2]) {
        this.anonymous = true;

        // setting reward to false if anonymous is selected
        this.publicInsight = false;
        this.selectedIncentive = this.incentiveOptions[0]
        this.saveReward()
      } else if (type === this.studyTypeOptions[3]) {
        this.anonymous = true;
        this.publicInsight = true;

        // setting reward to false if anonymous is selected
        this.selectedIncentive = this.incentiveOptions[0]
        this.saveReward()
      }
      this.updateInsights(this.anonymous, this.publicInsight);
    },
    updateInsights(anonymous, publicInsight) {
      this.anonymous = anonymous;
      this.publicInsight = publicInsight;
      let url = `/study/${this.survey.id}`;
      this.$axios
        .request({
          method: "patch",
          url: url,
          data: { anonymous: anonymous, insight_type: publicInsight },
        })
        .then((res) => {
          this.editNameModal = false;
        })
        .catch((err) => {
          console.error(err);
          // this.surveyName = this.survey.name
        });
    },
    updateSurveyType() {
      let url = `/study/${this.survey.id}`;
      this.$axios
        .request({
          method: "patch",
          url: url,
          data: { survey_type: this.shareableLink ? "study" : "widget" },
        })
        .then((res) => {
          // this.editNameModal = false;
        })
        .catch((err) => {
          console.error(err);
          // this.surveyName = this.survey.name
        });
    },
    removeSurveyMember(payload) {
      this.survey.members.splice(this.survey.members.indexOf(payload), 1);
    },
    updateMembers(members) {
      this.survey.members = members;
    },
    updateSurveyMember(payload) {
      let member = this.survey.members.filter((member) => {
        return member.user.email === payload.email;
      });
      member[0].permission = payload.permission;
    },
  },
};
</script>
