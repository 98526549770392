<template>
  <div>
    <div
      class="tw-mt-10"
    >
      <div class="tw-mt-10 tw-flex tw-space-x-5">
        <div class="tw-p-5 tw-relative  tw-w-[250px] tw-border tw-rounded-xl tw-border-solid tw-border-white-two tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-justify-between">
            <p>
              Active studies
            </p>
            <p class="tw-font-semibold tw-text-xl tw-mt-4 ">
              {{ billingData ? billingData.active_studies : 0 }}
            </p>
          </div>
          <div class="tw-rounded-full tw-absolute tw-bg-black tw-w-16 tw-h-16 -tw-bottom-1 -tw-right-2 tw-" />
          <div class="tw-rounded-full tw-absolute tw-bg-territory-light tw-w-16 tw-h-16 tw-top-0 tw-z-10 -tw-right-7" />
        </div>
        <div class="tw-p-5 tw-relative  tw-w-[250px] tw-border tw-rounded-xl tw-border-solid tw-border-white-two tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-justify-between">
            <p>
              Lifetime respondents
            </p>
            <p class="tw-font-semibold tw-text-xl tw-mt-4 ">
              {{ billingData ? billingData.lifetime_respondents.toLocaleString() : 0 }}
            </p>
          </div>
          <div class="tw-rounded-full tw-absolute tw-bg-black tw-w-16 tw-h-16 -tw-bottom-1 -tw-right-2 tw-" />
          <div class="tw-rounded-full tw-absolute tw-bg-territory-light tw-w-16 tw-h-16 tw-top-0 tw-z-10 -tw-right-7" />
        </div>
        <div class="tw-p-5 tw-relative  tw-w-[250px] tw-border tw-rounded-xl tw-border-solid tw-border-white-two tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-justify-between">
            <p>
              Lifetime budget invested
            </p>
            <p class="tw-font-semibold  tw-mt-4 ">
              <span>
                $
              </span>
              <span class="tw-text-xl">
                {{ billingData ? (billingData.lifetime_budget_invested/100).toLocaleString() : 0 }}
              </span>
            </p>
          </div>
          <div class="tw-rounded-full tw-absolute tw-bg-black tw-w-16 tw-h-16 -tw-bottom-1 -tw-right-2 tw-" />
          <div class="tw-rounded-full tw-absolute tw-bg-territory-light tw-w-16 tw-h-16 tw-top-0 tw-z-10 -tw-right-7" />
        </div>
      </div>
      <div class="tw-mt-10">
        <p class="tw-font-semibold tw-text-2xl">
          Billing
        </p>
        <div class="tw-my-5 tw-border tw-border-solid tw-border-white-two tw-w-full" />
        <div
          v-if="billingData "
          class="tw-flex tw-justify-start tw-space-x-3 tw-max-w-[300px] tw-items-center "
        >
          <st-dropdown-menu

            ref="filterDropDown"
            container-class=" w-full border-none tw-rounded-xl border-black"
            selected-class="tw-rounded-xl tw-border-white-two bg-white tw-py-4 "
            caption="Select month"
            :display-caption="true"
            :full-width="true"
            :initial-active-option="selectedInvoiceFilter"
            :options="invoiceFilterOptions"
            @updateOption="changeSelectedInvoice"
          />
          <span
            class="tw-cursor-pointer"
            title="Download"
            @click="exportPDF"
          >
            <svg
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="1"
                width="39"
                height="39"
                rx="19.5"
                fill="white"
              />
              <g clip-path="url(#clip0_1076_924)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 13.5C20.3867 13.5 20.6999 13.8135 20.6999 14.1999V20.9101L23.0051 18.6052C23.2784 18.3319 23.7219 18.3319 23.9952 18.6052C24.2685 18.8785 24.2685 19.3216 23.9952 19.5952L20.4953 23.0953C20.222 23.3686 19.7786 23.3686 19.5053 23.0953L16.0054 19.5952C15.7321 19.3219 15.7321 18.8785 16.0054 18.6052C16.2787 18.3319 16.7219 18.3319 16.9955 18.6052L19.3007 20.9101V14.1999C19.3007 13.8132 19.6142 13.5 20.0006 13.5H20ZM13.6999 21.9001C14.0866 21.9001 14.3998 22.2136 14.3998 22.6V23.44C14.3998 24.0398 14.4004 24.4472 14.4262 24.7625C14.4514 25.0694 14.4967 25.2263 14.5525 25.3358C14.6869 25.5992 14.9008 25.8134 15.1645 25.9475C15.274 26.0033 15.4309 26.0489 15.7378 26.0738C16.0528 26.0996 16.4605 26.1002 17.06 26.1002H22.94C23.5398 26.1002 23.9472 26.0996 24.2625 26.0738C24.5694 26.0486 24.7263 26.0033 24.8358 25.9475C25.0992 25.8134 25.3134 25.5992 25.4475 25.3358C25.5033 25.2263 25.5489 25.0694 25.5738 24.7625C25.5996 24.4475 25.6002 24.0398 25.6002 23.44V22.6C25.6002 22.2133 25.9137 21.9001 26.3001 21.9001C26.6865 21.9001 27 22.2136 27 22.6V23.4688C27 24.0323 27 24.4973 26.9691 24.8762C26.937 25.2698 26.868 25.6313 26.6949 25.9712C26.4264 26.498 25.998 26.9264 25.4715 27.1949C25.1316 27.368 24.7701 27.437 24.3765 27.4691C23.9976 27.5 23.5325 27.5 22.9691 27.5H17.0312C16.4677 27.5 16.0024 27.5 15.6238 27.4691C15.2302 27.437 14.8687 27.368 14.5288 27.1949C14.002 26.9264 13.5736 26.498 13.3051 25.9712C13.132 25.6313 13.063 25.2698 13.0309 24.8762C13 24.4973 13 24.0323 13 23.4688V22.6C13 22.2133 13.3132 21.9001 13.6999 21.9001Z"
                  fill="#474747"
                />
              </g>
              <rect
                x="0.5"
                y="1"
                width="39"
                height="39"
                rx="19.5"
                stroke="#ECECEC"
              />
              <defs>
                <clipPath id="clip0_1076_924">
                  <rect
                    width="14"
                    height="14"
                    fill="white"
                    transform="translate(13 13.5)"
                  />
                </clipPath>
              </defs>
            </svg>

          </span>
        </div>
        <div

          class="my-5"
        >
          <div

            ref="invoiceTable"
          >
            <div class="tw-grid tw-grid-cols-5 tw-pr-2 tw-py-2 tw-text-sm tw-text-black-two tw-border-b tw-border-solid tw-border-white-two">
              <p class="tw-text-sm ">
                Study
              </p>
              <p class="tw-text-sm ">
                Start date
              </p>
              <p class="tw-text-sm ">
                Add ons
              </p>
              <p class="tw-text-sm ">
                Incentives
              </p>
              <p class="tw-text-sm tw-text-right">
                Prorated charges
              </p>
            </div>
            <div
              v-if="billingData && billingData.study_details && billingData.study_details.length > 0"
              class="tw-mt-5"
            >
              <div

                v-for="study in billingData.study_details"
                :key="study.name"
                class="tw-py-3 tw-grid tw-grid-cols-5 tw-pr-2 tw-text-black-two tw-border-b tw-border-solid tw-items-center tw-border-white-two"
              >
                <p class="tw-flex tw-flex-col tw-space-y-1">
                  <span class="tw-text-base tw-font-semibold tw-pr-4">
                    {{ study.survey.name }}
                  </span>
                  <span
                    v-if="study.base_plan"
                    class="tw-text-[13px]"
                  >
                    {{ study.base_plan.split(" ")[0] }} study
                  </span>
                </p>
                <p class="tw-text-sm ">
                  {{ convertDateToReadableFormat(study.survey.created_at) }}
                </p>
                <p class="tw-text-sm ">
                  ${{ study.add_on / 100 }}
                </p>
                <p

                  class="tw-text-sm"
                >
                  <span v-if="study.survey.reward && study.survey.reward_type=='gift card'">
                    ${{ (Number(study.incentive_amount)/100).toLocaleString() }}
                  </span>
                </p>

                <p class="tw-text-end tw-text-sm">
                  ${{ (study.prorated_charges / 100).toLocaleString() }}
                </p>
              </div>
            </div>

            <div
              v-if="billingData && billingData.study_details.length > 0"
              class="tw-w-full tw-bg-white-one tw-flex tw-py-4 tw-justify-end tw-items-center tw-space-x-10 tw-pr-2"
            >
              <span class="tw-text-black-two tw-text-sm">
                Total charges
              </span>
              <span class="tw-font-semibold tw-text-sm">
                ${{ getTotalCharges().toLocaleString() }}
              </span>
            </div>
          </div>
          <!-- <div

            class="tw-w-full tw-flex tw-flex-col tw-justify-end tw-items-center tw-bg-white-one tw-p-20"
          >
            <p class="tw-text-lg tw-font-semibold">
              No billing information available
            </p>
            <p>
              To access the information you must have previously created a study
            </p>
          </div> -->
          <div class="tw-flex tw-space-x-5 tw-py-4 tw-border-b tw-border-solid tw-border-white-two">
            <div class="tw-w-[300px]">
              <p class="tw-font-semibold">
                Billing contact
              </p>
            </div>
            <div class="tw-w-[300px]">
              <p class="tw-font-semibold">
                {{ account.accountOwnerName }}
              </p>
              <p class="tw-text-sm">
                {{ account.accountOwnerEmail }}
              </p>
            </div>
          </div>
          <div

            class="tw-flex tw-space-x-5 tw-py-4 tw-border-b tw-border-solid tw-border-white-two"
          >
            <div class="tw-w-[300px]">
              <p class="tw-font-semibold">
                Payment method
              </p>
            </div>
          </div>
          <div class="tw-flex tw-space-x-5 tw-py-4 ">
            <div class="tw-w-[300px]">
              <p class="tw-font-semibold">
                Account created
              </p>
            </div>

            <div
              v-if="account.cardInfo"
              class="tw-w-[300px]"
            >
              <p class="tw-font-semibold">
                {{ cardInfo.brand }} ending with {{ cardInfo.last4 }}
              </p>
              <p class="tw-text-sm">
                Expires {{ cardInfo.expireMonth }}/{{ cardInfo.expireYear }}
              </p>
            </div>

            <div class="tw-w-[300px]">
              <p class="">
                Created on {{ currentUserCreatedDate }}
              </p>
            </div>
          </div>
        </div>

        <vue-html2pdf
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="invoicePDF"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="1300px"
          :html-to-pdf-options="{
            filename: 'invoice.pdf',
            margin: [0.4, 0, 0.4, 0],
            jsPDF: {
              unit: 'in',
              format: 'a4',
              orientation: 'portrait',
            },
          }"
        >
          <section
            slot="pdf-content"
            class=""
          >
            <div class="tw-max-w-[1000px] tw-pl-10 tw-pr-60">
              <div class="tw-flex tw-space-x-2 tw-items-center tw-w-fit tw-mx-auto tw-my-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1183_1419)">
                    <path
                      opacity="0.8"
                      d="M21.3881 5.21827L18.5947 3.42763L17.7359 2.87795L14.4913 0.796864C13.7018 0.277127 12.776 0 11.8294 0C10.8827 0 9.95692 0.277127 9.16738 0.796864L5.91076 2.87795L2.25853 5.21827C1.55748 5.65374 0.981274 6.26192 0.585708 6.98395C0.190141 7.70598 -0.0113924 8.51739 0.000616489 9.33964V19.1522C-0.0126463 19.9757 0.188289 20.7886 0.583914 21.5119C0.97954 22.2353 1.55641 22.8447 2.25853 23.2808L6.40287 20.5708L23.6412 9.33964C23.654 8.5178 23.4533 7.7066 23.0585 6.98457C22.6638 6.26255 22.0884 5.65415 21.3881 5.21827Z"
                      fill="#6FA8DC"
                    />
                    <path
                      opacity="0.8"
                      d="M22.739 0.717773L18.5947 3.42775L12.8462 7.17226L1.35153 14.6613C1.34095 15.4841 1.54415 16.2956 1.94143 17.0172C2.33871 17.7389 2.9166 18.3462 3.61909 18.7803L5.00617 19.6804L6.4029 20.5757L7.26409 21.1206L10.5062 23.1993C11.297 23.7215 12.2251 24.0001 13.1742 24.0001C14.1234 24.0001 15.0514 23.7215 15.8422 23.1993L19.0868 21.1206L22.739 18.7779C23.4405 18.3433 24.0172 17.7356 24.4132 17.014C24.8093 16.2923 25.0112 15.4811 24.9994 14.6589V4.84634C25.0131 4.02252 24.8121 3.20922 24.416 2.48568C24.0198 1.76214 23.4421 1.15306 22.739 0.717773Z"
                      fill="#FE795D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1183_1419">
                      <rect
                        width="25"
                        height="24"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <span class="tw-font-semibold tw-text-lg">
                  AskHumans
                </span>
              </div>

              <p class="tw-flex tw-justify-center tw-center tw-w-full tw-py-3 tw-my-5 ">
                <span>
                  Invoice for {{ currentMonth }}
                </span>
              </p>
              <div class="tw-grid tw-grid-cols-6 tw-pr-2 tw-py-2 tw-gap-2 tw-text-sm tw-text-black-two tw-border-b tw-border-solid tw-border-white-two">
                <p class="tw-text-sm tw-col-span-2 ">
                  Study
                </p>
                <p class="tw-text-sm ">
                  Start date
                </p>
                <p class="tw-text-sm ">
                  Add ons
                </p>
                <p class="tw-text-sm ">
                  Incentives
                </p>
                <p class="tw-text-sm tw-text-right">
                  Prorated charges
                </p>
              </div>
              <div
                v-if="billingData && billingData.study_details && billingData.study_details.length > 0"
                class="tw-mt-5"
              >
                <div

                  v-for="study in billingData.study_details"
                  :key="study.name"
                  class="tw-py-3 tw-grid tw-grid-cols-6 tw-pr-2 tw-gap-2 tw-text-black-two tw-border-b tw-border-solid tw-items-center tw-border-white-two"
                >
                  <p class="tw-flex tw-flex-col tw-space-y-1  tw-col-span-2 ">
                    <span class="tw-text-base tw-font-semibold tw-pr-4">
                      {{ study.survey.name }}
                    </span>
                    <span
                      v-if="study.base_plan"
                      class="tw-text-[13px]"
                    >
                      {{ study.base_plan.split(" ")[0] }} study
                    </span>
                  </p>

                  <p class="tw-text-sm">
                    ${{ study.add_on / 100 }}
                  </p>
                  <p

                    class="tw-text-sm"
                  >
                    <span v-if="study.survey.reward && study.survey.reward_type=='gift card'">
                      ${{ Number(study.incentive_amount)/100 }}
                    </span>
                  </p>

                  <p class="tw-text-end tw-text-sm">
                    ${{ study.prorated_charges / 100 }}
                  </p>
                </div>
              </div>

              <div class="tw-grid tw-bg-white-one tw-py-4 tw-grid-cols-5">
                <div class="tw-col-span-5 tw-text-end tw-flex tw-justify-end tw-pr-2 tw-space-x-10">
                  <span class="tw-text-black-two tw-text-sm">
                    Total charges
                  </span>
                  <span class="tw-font-semibold tw-text-sm">
                    ${{ getTotalCharges() }}
                  </span>
                </div>
              </div>
              <p class="tw-flex tw-justify-center tw-center tw-w-full tw-py-3 tw-mt-5 ">
                <span>
                  If you have any questions, please contact us at <span class="tw-text-primary"> contact@askhumans.com </span>
                </span>
              </p>
            </div>
          </section>
        </vue-html2pdf>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { monthNames } from '../insights/pdfs/utilities'
import { StDropdownMenu } from '../../common'
import VueHtml2pdf from 'vue-html2pdf'
export default {

  components: {
    StDropdownMenu,
    VueHtml2pdf

  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      billingData: null,
      invoiceFilterOptions: ['This month'],
      selectedInvoiceFilter: 'This month'
    }
  },
  computed: {
    ...mapState(['currentUser']),
    currentMonth () {
      const currentDate = new Date()
      const currentMonthIndex = currentDate.getMonth()
      return monthNames[currentMonthIndex]
    },

    getRewardBalance () {
      if (this.rewardBalance) {
        return this.rewardBalance / 100
      }
      return 0
    },
    // getPlanInfo() {
    //   return this.currentUser?.account?.plan;
    // },

    currentUserCreatedDate () {
      const createdAt = new Date(this.account.accountCreatedAt)
      return `${
        monthNames[createdAt.getMonth()]
      } ${createdAt.getDate()}, ${createdAt.getFullYear()}`
    }
  },
  mounted () {
    console.log(this.account)
    this.$store.commit('toggleFullSpinner', true)
    setTimeout(() => {
      this.getBillingDetails()
    }, 200)
  },

  methods: {
    convertDateToReadableFormat (date) {
      const d = new Date(date)
      const month = monthNames[d.getMonth()]
      const day = d.getDate()
      const year = d.getFullYear()
      return `${month} ${day}, ${year}`
    },
    changeSelectedInvoice (option) {
      this.selectedInvoiceFilter = option

      this.getBillingDetails(option.split(' ')[0].slice(0, 3), option.split(' ')[1].slice(2))
    },
    previewPDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    exportPDF () {
      // this.isPDFDownloading = true
      this.$refs.html2Pdf.generatePdf()
    },
    getTotalCharges () {
      let total = 0
      if (this.billingData && this.billingData.study_details && this.billingData.study_details.length > 0) {
        this.billingData.study_details.forEach((study) => {
          total += study.prorated_charges + (Number(study.incentive_amount)) + study.add_on
        })
      }
      return total / 100
    },
    getBillingDetails (month = null, year) {
      let currentDateMonth = null
      let currentDateYear = null
      if (!month) {
        currentDateMonth = new Date().toLocaleString('default', { month: 'short' })
        currentDateYear = String(new Date().getFullYear()).slice(2)
      } else {
        currentDateMonth = month
        currentDateYear = year
      }

      this.$axios
        .request({
          method: 'post',
          data: {
            month: `${currentDateMonth.toLocaleLowerCase()}-${currentDateYear}`,
            account_id: this.account.accountId
          },
          url: `accounts/billing_details`
        })
        .then((res) => {
          this.$store.commit('toggleFullSpinner', false)
          console.log(res.data.billing_details)
          // this.monthsFromAccountCreated()
          this.billingData = res.data.billing_details
        })
        .catch((err) => {
          this.$store.commit('toggleFullSpinner', false)
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
