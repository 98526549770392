<template>
<div class="tw-flex tw-h-full tw-flex-col">
  <div v-if="!topics.length" class="tw-py-10 tw-text-3xl tw-px-2">
    No topics found
  </div>
  <div class="tw-overflow-x-auto tw-sm:-mx-6 tw-lg:-mx-8 tw-max-w-[900px]" v-else>
    <div class="tw-inline-block tw-min-w-full tw-py-2 tw-sm:px-6 tw-lg:px-8">
      <div class="tw-overflow-hidden">
        <table class="tw-min-w-full tw-border tw-border-gray-300 tw-border-solid tw-rounded-md tw-text-left tw-text-sm tw-font-light tw-text-surface ">
          <thead class="tw-border-b tw-border-neutral-200 tw-font-medium ">
            <tr>
            <th scope="col" class="tw-px-6 tw-py-4 tw-font-semibold">Sr. No.</th>
            <th scope="col" class="tw-px-6 tw-py-4 tw-font-semibold tw-border-l tw-border-solid tw-border-gray-300">Topic</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(topic, index) in topics" class="tw-border-b tw-border-neutral-200 ">
              <td class="tw-whitespace-nowrap tw-px-6 tw-py-4 tw-font-medium">{{ index+1 }}</td>
              <td class="tw-whitespace-nowrap tw-px-6 tw-py-4 tw-border-l tw-border-solid tw-border-gray-300">{{ topic }}</td>
              
            </tr>
           
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  components: {
  },
  props: {
    topics: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
     
    }
  },
  computed: {
   

  },
  mounted () {
  },

  methods: {
    
  }
}
</script>
