<template>
  <div class="tw-bg-white tw-w-full h-full">
    <div class="tw-flex tw-justify-between tw-w-full h-full">
      <div
        class="tw-flex tw-flex-col tw-justify-between scrollbar-thumb-dark-gray-400 tw-h-[calc(100vh-210px)] tw-w-full tw-overflow-y-auto scrollbar-hover-black scrollbar-thin scrollbar-track-gray-200"
      >
        <div class="tw-mx-auto tw-py-10">
          <div
            class="tw-gap-x-2 lg:tw:tw-gap-x-10 tw-border-white-two tw-grid tw-grid-cols-12 tw-my-5 lg:tw-pr-10 tw-border tw-border-solid tw-rounded-xl tw- tw-max-w-[560px] tw-transition-all tw-duration-200"
          >
            <p
              class="tw-col-span-12 tw-px-10 tw-py-5 tw-w-[560px] font-600 tw-text-[25px]"
            >
              How to use webhook?
            </p>
            <div class="tw-col-span-12 tw-px-5 tw-pb-10">
              <p class="tw-my-2">
                To use the webhook, you need to send a POST request to the
                following endpoint:
              </p>
              <div
                class="tw-flex tw-items-center tw-bg-[#2E2E2E] tw-mb-2 tw-px-4 tw-rounded-2xl tw-text-white"
              >
                <pre class="tw-mt-3"
                  >{{
                    cloudEndPoint
                  }}
                  </pre
                >
              </div>

              <div
                class="tw-relative tw-flex tw-justify-between tw-items-center tw-bg-[#2E2E2E] tw-px-4 tw-rounded-2xl tw-w-full"
              >
                <pre class="tw-mt-5 tw-text-white">

  {
    "studyApiKey": "{{ survey.apiKey }}",
    "studyId": {{ survey.id }},
    "metadata": {
      "some_key": "some value" <span class="tw-text-gray-500">// optional metadata</span>
    },
    "respondent": {
      "firstName": "{first_name}",
      "lastName": "{last_name}",
      "email": "{email}"
    },
    <span class="tw-text-gray-500">// audio should be mp3 for responses</span>
    "responses": {{
            surveyQuestions.map((question, index) => {
                    return {
                      "questionId": question.id,
                      "question": stripHtmlTags(question.description),
                      "feedbackAudioURL": "{audio_url}"
                    }
            })
    }}

}
             </pre>

                <div class="tw-top-2 tw-right-3 tw-absolute tw-min-w-fit">
                  <button
                    class="tw-border-gray-100 tw-bg-[#2E2E2E] tw-mt-2 tw-px-4 tw-py-2 tw-border tw-border-solid tw-rounded-md tw-min-w-fit tw-font-semibold tw-text-gray-100"
                    @click="copyScript"
                  >
                    {{ copiedScript ? "Copied" : "Copy Payload" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p class="tw-px-4 tw-py-1">
              If you want to get the data sent back to you, you can insert a url
              here
            </p>

            <div
              class="tw-flex tw-items-center tw-space-x-3 tw-mb-2 tw-px-4 tw-rounded-2xl tw-text-white"
            >
              <ah-input
                v-model="webhookUrl"
                placeholder="Enter your webhook url"
                class="tw-w-full tw-text-white"
                @keydown.enter="saveWebhookUrl"
              />
              <ah-button @click="saveWebhookUrl">
                Save
              </ah-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import AhButton from "../../common/ah-button.vue";
import AhInput from "../../common/ah-input.vue";

export default {
  components: {
    AhButton,
    AhInput,
  },

  props: {
    survey: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      enableWebhook: false,
      copiedScript: false,
      webhookUrl: "",
    };
  },
  computed: {
    ...mapState("insights", [
      "activePanelSection",
      "activeQuestionIndex",
      "activeQuestionView",
      "surveyQuestions",
      "tourMode",
      "surveyThemeImprovements",
    ]),
    cloudEndPoint() {
      return `https://us-central1-askhumans-${process.env.RAILS_ENV}.cloudfunctions.net/web-hook`;
    },
  },
  mounted() {
    this.webhookUrl = this.survey.webhookUrl;
  },

  methods: {
    saveWebhookUrl() {
      this.$axios
        .request({
          url: `/study/${this.survey.id}`,
          method: "patch",
          data: {
            webhook_url: this.webhookUrl,
          },
        })
        .then((res) => {
          // console.log(res)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleEnableWebhook() {
      this.enableWebhook = !this.enableWebhook;
    },
    copyScript() {
      const textArea = document.createElement("textarea");

      textArea.value = `{
    "studyApiKey": "${this.survey.apiKey}",
    "studyId": ${this.survey.id},
    "metadata": {
      "callerId": "{caller_id}",
      "conversationId": "{conversation_id}",
      "some_key": "some value"  // optional metadata
    },
    "respondent": {
      "firstName": "{first_name}",
      "lastName": "{last_name}",
      "email": "{email}"
    },
    // audio should be mp3 for responses
    "responses": ${JSON.stringify(
      this.surveyQuestions.map((question, index) => {
        return {
          questionId: question.id,
          question: this.stripHtmlTags(question.description),
          feedbackAudioURL: "{audio_url}",
        };
      })
    )}
}`;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      this.copiedScript = true;
      setTimeout(() => {
        this.copiedScript = false;
      }, 2000);
    },

    stripHtmlTags(html) {
      return html.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, " ");
    },
  },
};
</script>
<style scoped></style>
