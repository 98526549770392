<template>
  <div
    v-if="feedbackType=='text'"
    class="tw-flex tw-items-center tw-space-x-1 tw-bg-white tw-py-2 tw-px-2 tw-rounded-lg"
  >
    <div
      v-if="submittingResponse"
      class="tw-px-7 tw-bg-white tw-flex tw-w-full tw-min-w-[200px]  tw-gap-2 tw-sticky tw-bottom-0 tw-py-4 tw-justify-center tw-items-center"
    >
      <div
        aria-label="Loading..."
        role="status"
      >
        <svg
          class="tw-animate-spin tw-w-8 tw-h-8 tw-fill-slate-800"
          viewBox="3 3 18 18"
        >
          <path
            class="tw-opacity-20"
            d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
          />
          <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z" />
        </svg>
      </div>
    </div>
    <template v-else>
      <div
        class="tw-relative tw-flex  tw-rounded-lg tw-max-w-fit tw-items-center tw-w-full"
      >
        <textarea
          ref="autoResizeTextarea"
          v-model="feedback"
          autofocus
          placeholder="Type your feedback here"
          :class="{
            '!tw-pr-10 !tw-mb-0': true,

          }"
          rows="1"
          class="tw-resize-none  tw-border tw-min-h-fit tw-border-solid tw-bg-white tw-bg-transparent tw-py-2 tw-w-full tw-pl-3 tw-pr-6 tw-text-sm tw-rounded-lg scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black"
          @input="adjustTextareaHeight"
          @keydown.enter.prevent="saveTextFeedback"
        />
        <button
          :disabled="!feedback"
          class="disabled:tw-cursor-not-allowed"
          @click="saveTextFeedback"
        >
          <svg
            class=" tw-right-3 tw-absolute tw-top-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_214_1194)">
              <path
                d="M16.4944 0.939649C16.4944 0.939649 16.4944 0.888257 16.4944 0.859706C16.4772 0.825444 16.4601 0.791183 16.4373 0.756922C16.4201 0.728371 16.3973 0.69982 16.3744 0.676979L16.2887 0.608457C16.2145 0.557065 16.1288 0.522804 16.0374 0.511383C15.9689 0.494253 15.9003 0.494253 15.8318 0.511383C15.8032 0.511383 15.7689 0.511383 15.7404 0.511383H15.7004L0.882815 5.65057C0.677175 5.7248 0.534368 5.90182 0.505807 6.11881C0.471534 6.3415 0.568642 6.56991 0.762858 6.68983L2.78499 7.99175V13.6449C2.78499 13.9589 3.04204 14.2159 3.35622 14.2159H9.06846L10.2737 16.2202C10.3823 16.3972 10.5765 16.5057 10.7821 16.5H10.8393C11.062 16.4771 11.2505 16.3344 11.3248 16.1231L16.4658 1.27655C16.4658 1.27655 16.4658 1.22516 16.4658 1.19661C16.4772 1.16235 16.4887 1.12238 16.4944 1.0824C16.5001 1.03672 16.5001 0.98533 16.4944 0.939649ZM2.37371 6.37576L13.9296 2.37862L7.26339 9.48212L3.6761 7.19803L2.37371 6.37576Z"
                fill="#474747"
              />
            </g>
            <defs>
              <clipPath id="clip0_214_1194">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0.5 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <button
        class="tw-text-[13px] tw-p-2 tw-bg-primary-light tw-text-primary-dark tw-rounded-lg"
        @click="cancel('text')"
      >
        Cancel
      </button>
    </template>
  </div>
  <div
    v-else-if="feedbackType=='voice'"
    class="tw-relative tw-py-1 tw-rounded-md tw-flex tw-items-center tw-right-7 tw-bg-white tw-flex-col tw-justify-center  "
  >
    <!-- tw-min-w-[400px] -->
    <div
      v-if="submittingResponse"
      class="tw-px-7 tw-bg-white tw-flex  tw-gap-2 tw-sticky tw-bottom-0 tw-py-4 tw-justify-center tw-items-center"
    >
      <div
        aria-label="Loading..."
        role="status"
      >
        <svg
          class="tw-animate-spin tw-w-8 tw-h-8 tw-fill-slate-800"
          viewBox="3 3 18 18"
        >
          <path
            class="tw-opacity-20"
            d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
          />
          <path d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z" />
        </svg>
      </div>
    </div>
    <div
      v-if="!submittingResponse"
      class="tw-relative tw-w-fit"
    >
      <div
        class="tw-w-[170px] tw-flex tw-space-x-3  tw-items-center "
        @click="(e) => e.stopPropagation()"
      >
        <div
          class="tw-bg-white tw-flex-col tw-rounded-xl tw-w-full tw-p-3 tw-flex tw-space-y-3 tw-items-center tw-justify-between"
        >
          <span
            v-if="!readyToSubmit"
            class="tw-text-black-three tw-flex tw-items-center tw-space-x-2"
          >
            <svg
              width="8"
              height="9"
              viewBox="0 0 8 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="4"
                cy="4.5"
                r="4"
                fill="#B71E1E"
              />
            </svg>

            <span class="">{{ mins < 9 ? `0${mins}` : mins }}</span>:<span>{{ seconds < 10 ? `0${seconds}` : seconds }}</span>
          </span>

          <!-- <audio
            v-else-if="readyToSubmit"
            ref="audioPlayer"
            controls
          /> -->
          <div
            class="tw-bg-primary tw-rounded-xl tw-cursor-pointer tw-text-white tw-w-full tw-p-3 tw-text-center"
            @click="submitRecording"
          >
            Send
          </div>

          <div class="tw-flex tw-w-full tw-space-x-2 tw-items-center">
            <div
              v-if="!pausedRecording"
              class="tw-p-2 tw-flex tw-cursor-pointer tw-items-center tw-justify-center  tw-rounded-xl tw-w-full tw-bg-primary-light"
              @click="pauseCurrentRecording($event)"
            >
              <span
                v-if="!readyToSubmit"
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 13.1666H7.16667V3.83331H4.5V13.1666ZM9.83333 3.83331V13.1666H12.5V3.83331H9.83333Z"
                    fill="#BE6744"
                  />
                </svg>

              </span>
            </div>
            <div
              v-else-if="pausedRecording"
              class="tw-p-2 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-xl tw-w-full tw-bg-primary-light"
              @click="resumeCurrentRecording"
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1326_4161)">
                  <path
                    d="M2 7.83337H3.5C3.5 9.26232 4.35757 10.5831 5.75 11.2974C7.14243 12.0117 8.85757 12.0117 10.25 11.2974C11.6424 10.5827 12.5 9.26232 12.5 7.83337H14C13.9987 9.13165 13.4647 10.3846 12.4983 11.3579C11.5319 12.3313 10.199 12.9576 8.75 13.1198V15.1667H11V16.5H5V15.1667H7.25V13.1198C5.801 12.9576 4.46814 12.3313 3.50171 11.3579C2.53529 10.3846 2.00129 9.13165 2 7.83337ZM4.25 7.83337V3.83337C4.25 2.64251 4.96486 1.54194 6.125 0.94651C7.28514 0.351082 8.71486 0.351082 9.875 0.94651C11.0351 1.54194 11.75 2.64251 11.75 3.83337V7.83337C11.75 9.02423 11.0351 10.1248 9.875 10.7202C8.71486 11.3157 7.28514 11.3157 6.125 10.7202C4.96486 10.1248 4.25 9.02423 4.25 7.83337ZM5.75 7.83337C5.75 8.54803 6.179 9.20823 6.875 9.56518C7.571 9.92251 8.429 9.92251 9.125 9.56518C9.821 9.20784 10.25 8.54765 10.25 7.83337H8.75V6.50003H10.25V4.50003H8.75V3.1667H10.115C9.87329 2.57394 9.33071 2.11756 8.65229 1.9347C7.97343 1.75184 7.23757 1.86461 6.66629 2.23794C6.095 2.61127 5.75557 3.20289 5.75 3.83337V7.83337Z"
                    fill="#474747"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1326_4161">
                    <rect
                      width="12"
                      height="16"
                      fill="white"
                      transform="translate(2 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              class="tw-cursor-pointer"
              @click="cancel('voice')"
            >
              Cancel
            </div>
            <!-- <div
              class="tw-p-3 tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-bg-primary hover:tw-bg-primary-dark tw-rounded-full"
              @click="submitRecording"
            >
              <span
                v-if="!readyToSubmit"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.66675 8.50002L3.60675 9.44002L7.33342 5.72002V13.8334H8.66675V5.72002L12.3867 9.44669L13.3334 8.50002L8.00008 3.16669L2.66675 8.50002Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  audioRecorder,
  handlePreviewBack,
  isDesktop,
  previousResponse,
  submitQuestionAnswer
} from '../../../mixins'
export default {
  name: 'AskhumansSectionFeedback',
  mixins: [
    audioRecorder,
    handlePreviewBack,
    isDesktop,
    previousResponse,
    submitQuestionAnswer
  ],
  props: {

    highlightedKey: {
      type: String,
      default: ''
    },
    feedbackType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showDefaultButtons: true,
      pausedRecording: false,
      showTextFeedback: false,
      feedback: '',
      emptyError: false,
      intervalId: null,
      mins: 0,
      seconds: 0,
      readyToSubmit: false,
      localAudioURL: '',
      submittingResponse: false
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.feedbackType == 'voice') {
        this.showAndStartRecording()
      }
    })
  },
  computed: {
    ...mapState(['currentUser'])
  },

  methods: {
    showAndStartRecording () {
      setTimeout(() => {
        this.startRecording()
      }, 10)
    },
    setAudioSrc (audioUrl = '') {
      setTimeout(() => {
        audioUrl = window.URL.createObjectURL(this.audioBlob)
        this.localAudioURL = audioUrl
        console.log(audioUrl)
        // console.log(this.$refs.newlySubmittedResponseAudio)
        // this.$refs.newlySubmittedResponseAudio.src = audioUrl
        // this.$refs.newlySubmittedResponseAudio.load()
      }, 1000)
    },
    pauseCurrentRecording (e) {
      e.stopPropagation()
      this.pausedRecording = true
      clearInterval(this.intervalId)
      this.pauseRecording()
    },
    resumeCurrentRecording (e) {
      e.stopPropagation()
      this.pausedRecording = false
      this.resumeRecording()
      this.resumeCounting()
    },
    toggleDefaultButtons () {
      this.showDefaultButtons = !this.showDefaultButtons
    },
    showTextFeedbackHandler () {
      this.showTextFeedback = true
      this.showDefaultButtons = false
    },
    pauseCounting () {
      clearInterval(this.intervalId)
    },
    stopCounting () {
      // console.log('stopping counting')
      this.pausedRecording = false
      this.readyToSubmit = true
      this.seconds = 0
      this.minutes = 0
      clearInterval(this.intervalId)
      this.stopRecording()
    },
    cancel (type) {
      if (type == 'voice') {
        this.stopCounting()
      }

      this.$emit('cancel')
    },
    resumeCounting () {
      this.intervalId = setInterval(() => {
        this.seconds++
        if (this.seconds > 59) {
          this.seconds = 0
          this.mins++
        }
      }, 1000)
    },
    submitRecording () {
      const currentURL = window.location.href
      const params = new URL(currentURL)
      const guestToken = params.searchParams.get('guest_token')
      // console.log('submitting recording', guestToken)
      const versionId = params.searchParams.get('version')
      clearInterval(this.intervalId)
      this.pausedRecording = false
      this.stopRecording()
      this.readyToSubmit = true
      this.seconds = 0
      this.minutes = 0
      this.submittingResponse = true
      setTimeout(() => {
        const formData = new FormData()
        formData.append('feedback[audio_response]', this.audioBlob)
        formData.append('feedback[section]', this.highlightedKey)
        formData.append('feedback[version_id]', versionId)
        formData.append('feedback[explanation_type]', 'audio')
        if (guestToken) {
          formData.append('guest_token', guestToken)
        } else {
          formData.append('feedback[user_id]', this.currentUser.id)
        }
        this.$axios
          .post('/feedbacks', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            setTimeout(() => {
              this.submittingResponse = false
              this.cancel('voice')
              this.$emit('feedback-submitted', { ...response.data, audio_url: this.localAudioURL })
            }, 2000)
          })
          .catch((error) => {
            this.submittingResponse = false
            this.cancel('voice')
            console.log(error)
          })
      }, 1000)
    },
    adjustTextareaHeight () {
      const textarea = this.$refs.autoResizeTextarea
      textarea.style.height = 'auto'

      // Check if the current height is less than the maximum height
      if (textarea.scrollHeight <= this.maxHeight) {
        textarea.style.height = `${textarea.scrollHeight}px`
      } else {
        // Set the height to the breakpoint if it exceeds the maximum height
        textarea.style.height = `${this.breakpoint}px`
      }
    },
    saveTextFeedback () {
      if (this.feedback.trim() == '') {
        // this.emptyError = true
        return
      }

      const currentURL = window.location.href
      const params = new URL(currentURL)
      const guestToken = params.searchParams.get('guest_token')
      const versionId = params.searchParams.get('version')
      this.submittingResponse = true
      this.$axios
        .post('/feedbacks', {
          explanation_type: 'text',
          transcript: this.feedback,
          user_id: this.currentUser.id,
          version_id: versionId,
          section: this.highlightedKey,
          ...(guestToken && { guest_token: guestToken })
        })
        .then((response) => {
          this.submittingResponse = false
          this.$emit('feedback-submitted', response.data)
          this.cancel('text')

          // this.$emit('textResponseSubmitted', response.data)
          // this.submittingResponse = false
          // this.showDefaultButtons = true
          // this.showTextFeedback = false
          // console.log(response.data)
          // console.log(this.userFeedbacks)
          // this.userFeedbacks = [
          //   ...this.userFeedbacks,
          //   { ...response.data, feedbackUser: this.currentUser }
          // ]
          // console.log(this.userFeedbacks)
          this.feedback = ''
        })
        .catch((error) => {
          this.submittingResponse = false
          this.cancel('text')
          console.log(error)
        })
    }
  }
}
</script>
