<template>
  <section class="text-gray-600 body-font  px-10 flex justify-center mx-auto">
    <div class="hidden sm:flex  mx-auto items-center bg-white w-full justify-center items-center">
      <div class="flex  mx-auto">
        <div class="flex ml-10p relative pb-5">
          <div class="h-full w-5 absolute inset-0 flex items-center justify-center">
            <div
              class="w-full  w-1 pointer-events-none"
              :class="step>1?'bg-selected':'bg-gray-200'"
            />
          </div>
          <div class="flex-shrink-0 w-5 h-5 rounded-full bg-box inline-flex items-center justify-center text-white relative ">
            <svg
              v-if="step>1"
              width="14"
              height="14"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72313 3.79387H8.90811C8.89477 3.64448 8.84702 3.49589 8.76211 3.36003C8.47375 2.89855 7.86577 2.75821 7.40441 3.04667C7.40437 3.0467 7.40432 3.04673 7.40428 3.04675L4.3334 4.96612L3.8936 4.30643C3.89356 4.30637 3.89352 4.30631 3.89348 4.30625C3.59176 3.85345 2.97987 3.73114 2.52718 4.03307C2.07468 4.33487 1.95205 4.94658 2.25404 5.39944C2.25406 5.39946 2.25407 5.39949 2.25409 5.39952L3.2247 6.85533C3.52032 7.29876 4.11493 7.4267 4.56678 7.1442L8.44897 4.71788L9.74939 3.90514C9.81713 4.21025 9.85286 4.52742 9.85286 4.85294C9.85286 7.25712 7.90409 9.20588 5.49991 9.20588C3.09574 9.20588 1.14697 7.25712 1.14697 4.85294C1.14697 2.44877 3.09574 0.5 5.49991 0.5C7.53881 0.5 9.25017 1.90158 9.72313 3.79387Z"
                fill="white"
                stroke="white"
              />
            </svg>
          </div>
          <div
            class="flex-grow translate-x-10p relative relative border-t-gray-200 w-20  "
          >
            <p
              class="font-600  title-font  mb-1 tracking-wider w-200p pt-3"

              style="transform: translate(-60px, 10px);"
            >
              <a
                v-if="step>1"
                :href="`/study/${surveyId}/edit/landing_page`"
                class="text-completed"
              >
                Popup Wave
              </a>
              <a
                v-else

                :href="`/study/${surveyId}/edit/landing_page`"
                :class="step>0? 'text-black': 'text-gray-200'"
              >Popup Wave</a>
            </p>
          </div>
        </div>
        <div class="flex relative pb-12">
          <div class="h-full w-5 absolute inset-0 flex items-center justify-center">
            <div
              class="w-full  w-1 pointer-events-none"
              :class="step>2?'bg-selected':'bg-gray-200'"
            />
          </div>
          <div
            class="flex-shrink-0 w-5 h-5 rounded-full  inline-flex items-center justify-center text-white relative "
            :class="step>1?'bg-box':'bg-gray-200'"
          >
            <svg
              v-if="step>2"
              width="14"
              height="14"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72313 3.79387H8.90811C8.89477 3.64448 8.84702 3.49589 8.76211 3.36003C8.47375 2.89855 7.86577 2.75821 7.40441 3.04667C7.40437 3.0467 7.40432 3.04673 7.40428 3.04675L4.3334 4.96612L3.8936 4.30643C3.89356 4.30637 3.89352 4.30631 3.89348 4.30625C3.59176 3.85345 2.97987 3.73114 2.52718 4.03307C2.07468 4.33487 1.95205 4.94658 2.25404 5.39944C2.25406 5.39946 2.25407 5.39949 2.25409 5.39952L3.2247 6.85533C3.52032 7.29876 4.11493 7.4267 4.56678 7.1442L8.44897 4.71788L9.74939 3.90514C9.81713 4.21025 9.85286 4.52742 9.85286 4.85294C9.85286 7.25712 7.90409 9.20588 5.49991 9.20588C3.09574 9.20588 1.14697 7.25712 1.14697 4.85294C1.14697 2.44877 3.09574 0.5 5.49991 0.5C7.53881 0.5 9.25017 1.90158 9.72313 3.79387Z"
                fill="white"
                stroke="white"
              />
            </svg>
          </div>
          <div
            class="flex-grow translate-x-10p relative relative border-t-gray-200 w-20  "
          >
            <p
              class="font-600  title-font mb-1 tracking-wider w-200p pt-3"
              :class="step>1? 'text-black': 'text-gray-200'"
              style="transform:translate(-45px, 10px)"
            >
              <a
                v-if="step>2"
                :href="`/study/${surveyId}/edit/content`"
                class="text-completed"
              >
                Question
              </a>
              <a
                v-else
                :href="`/study/${surveyId}/edit/content`"
                :class="step>1? 'text-black': 'text-gray-200'"
              >Question</a>
            </p>
          </div>
        </div>
        <div class="flex relative pb-12">
          <div class="h-full w-5 absolute inset-0 flex items-center justify-center">
            <div
              class="w-full  w-1 pointer-events-none"
              :class="step>2?'bg-selected':'bg-gray-200'"
            />
          </div>
          <div
            class="flex-shrink-0 w-5 h-5 rounded-full inline-flex items-center justify-center text-white relative "
            :class="step>2?'bg-box':'bg-gray-200'"
          >
            <svg
              v-if="step>3"
              width="14"
              height="14"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72313 3.79387H8.90811C8.89477 3.64448 8.84702 3.49589 8.76211 3.36003C8.47375 2.89855 7.86577 2.75821 7.40441 3.04667C7.40437 3.0467 7.40432 3.04673 7.40428 3.04675L4.3334 4.96612L3.8936 4.30643C3.89356 4.30637 3.89352 4.30631 3.89348 4.30625C3.59176 3.85345 2.97987 3.73114 2.52718 4.03307C2.07468 4.33487 1.95205 4.94658 2.25404 5.39944C2.25406 5.39946 2.25407 5.39949 2.25409 5.39952L3.2247 6.85533C3.52032 7.29876 4.11493 7.4267 4.56678 7.1442L8.44897 4.71788L9.74939 3.90514C9.81713 4.21025 9.85286 4.52742 9.85286 4.85294C9.85286 7.25712 7.90409 9.20588 5.49991 9.20588C3.09574 9.20588 1.14697 7.25712 1.14697 4.85294C1.14697 2.44877 3.09574 0.5 5.49991 0.5C7.53881 0.5 9.25017 1.90158 9.72313 3.79387Z"
                fill="white"
                stroke="white"
              />
            </svg>
          </div>
          <div
            class="flex-grow translate-x-10p relative relative border-t-gray-200 w-20  "
          >
            <p
              class="font-600  title-font text-gray-900 mb-1 translate-custom tracking-wider w-200p pt-3"
            >
              <a
                v-if="step>3"
                :href="`/study/${surveyId}/edit/incentives`"
                class="text-completed"
              >
                Rewards
              </a>
              <a
                v-else
                :href="`/study/${surveyId}/edit/incentives`"
                :class="step>2? 'text-black': 'text-gray-200'"
              >Rewards</a>
            </p>
          </div>
        </div>
        <div class="flex relative pb-12">
          <div
            class="flex-shrink-0 w-5 h-5 rounded-full inline-flex items-center justify-center text-white relative "
            :class="step>3?'bg-box':'bg-gray-200'"
          >
            <svg
              v-if="step>4"
              width="14"
              height="14"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72313 3.79387H8.90811C8.89477 3.64448 8.84702 3.49589 8.76211 3.36003C8.47375 2.89855 7.86577 2.75821 7.40441 3.04667C7.40437 3.0467 7.40432 3.04673 7.40428 3.04675L4.3334 4.96612L3.8936 4.30643C3.89356 4.30637 3.89352 4.30631 3.89348 4.30625C3.59176 3.85345 2.97987 3.73114 2.52718 4.03307C2.07468 4.33487 1.95205 4.94658 2.25404 5.39944C2.25406 5.39946 2.25407 5.39949 2.25409 5.39952L3.2247 6.85533C3.52032 7.29876 4.11493 7.4267 4.56678 7.1442L8.44897 4.71788L9.74939 3.90514C9.81713 4.21025 9.85286 4.52742 9.85286 4.85294C9.85286 7.25712 7.90409 9.20588 5.49991 9.20588C3.09574 9.20588 1.14697 7.25712 1.14697 4.85294C1.14697 2.44877 3.09574 0.5 5.49991 0.5C7.53881 0.5 9.25017 1.90158 9.72313 3.79387Z"
                fill="white"
                stroke="white"
              />
            </svg>
          </div>
          <div
            class="flex-grow translate-x-10p relative relative"
          >
            <!-- :class="step>4? 'border-t-brown': 'border-t-gray-200'" -->
            <!-- a -->
            <p
              style="transform:translate(-60px, 10px)"
              class="font-600  title-font text-gray-900 mb-1 translate-custom tracking-wider w-200p tw-pt-4"
            >
              <a
                v-if="step>4"
                :href="`/study/${surveyId}/edit/widget`"
                class="text-completed"
              >
                Configuration
              </a>
              <a
                v-else
                :href="`/study/${surveyId}/edit/widget`"
                :class="step>3? 'text-black': 'text-gray-200'"
              >Configuration</a>
            </p>
          </div>
        </div>
        <!-- <div class="flex relative pb-12">
          <div
            class="flex-shrink-0 w-5 h-5 rounded-full inline-flex items-center justify-center text-white relative "
            :class="step>4?'bg-box':'bg-gray-200'"
          >
            <svg
              v-if="step>5"
              width="14"
              height="14"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72313 3.79387H8.90811C8.89477 3.64448 8.84702 3.49589 8.76211 3.36003C8.47375 2.89855 7.86577 2.75821 7.40441 3.04667C7.40437 3.0467 7.40432 3.04673 7.40428 3.04675L4.3334 4.96612L3.8936 4.30643C3.89356 4.30637 3.89352 4.30631 3.89348 4.30625C3.59176 3.85345 2.97987 3.73114 2.52718 4.03307C2.07468 4.33487 1.95205 4.94658 2.25404 5.39944C2.25406 5.39946 2.25407 5.39949 2.25409 5.39952L3.2247 6.85533C3.52032 7.29876 4.11493 7.4267 4.56678 7.1442L8.44897 4.71788L9.74939 3.90514C9.81713 4.21025 9.85286 4.52742 9.85286 4.85294C9.85286 7.25712 7.90409 9.20588 5.49991 9.20588C3.09574 9.20588 1.14697 7.25712 1.14697 4.85294C1.14697 2.44877 3.09574 0.5 5.49991 0.5C7.53881 0.5 9.25017 1.90158 9.72313 3.79387Z"
                fill="white"
                stroke="white"
              />
            </svg>
          </div>
          <div
            class="flex-grow translate-x-10p relative relative w-fit "
          >
            <p
              class="font-600  title-font text-gray-900 mb-1 translate-custom tracking-wider w-200p pt-3"
            >
              <a
                v-if="step>5"
                :href="`/study/${surveyId}/edit/segments`"
                class="text-completed"
              >
                Segments
              </a>
              <a
                v-else
                :href="`/study/${surveyId}/edit/segments`"
                :class="step==5? 'text-black': 'text-gray-200'"
              >Segments</a>
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'Steps',
  components: {

  },
  props: {

    step: {
      type: Number,
      required: true
    },
    surveyId: {
      type: Number,
      requred: false
    }
  },
  data: function () {
    return {

    }
  },
  computed: {

  },

  methods: {

  }
}
</script>
<style>
.pointer-events-none {
  pointer-events: none;
}
.hidden{
  display: none;
}
.bg-box{
  background: linear-gradient(227.23deg, #A9502C 13.2%, #EA9A7A 141.73%);
}
.border-t-gray-200{
  border-top: 2px solid #C9C9C9;
}
.border-2-brown{
  border-top: 2px solid #BE6744;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.translate-custom{
  transform:translate(-40px,10px)
}

.translate-x-10p{
  transform:translateY(10px);
}
.pt-5{
  padding-top: 20px;
}
.w-200p{
  width:fit-content;
}

.z-10{
  z-index: 10;
}
.w-20{
  width:120px;
}
.h-20{
  height: 100px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.h-5 {
  height: 1.25rem;
}

.h-full {
  height: 100%;
}

.w-1 {
  width: 0.25rem;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-selected{
  background-color:  #BE6744;;
}
.text-completed{
  color: #BE6744;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-4 {
  padding-left: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.font-medium {
  font-weight: 500;
}

.tracking-wider {
  letter-spacing: 0.05em;
}
.bg-gray-200{
  background-color:  #C9C9C9
}
.text-gray-600 {

  color: rgb(75 85 99);
}
.text-gray-200{
  color: #747474;
}

.text-gray-900 {

  color: rgb(17 24 39);
}

.text-white {

  color: rgb(255 255 255);
}

@media (min-width: 768px) {
  .md\:w-1\/2 {
    width: 50%;
  }
  .md\:block {
    display: block;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:pr-10 {
    padding-right: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:w-2\/5 {
    width: 40%;
  }
}

/* new styles */

.bottom-\[0\.8rem\] {
  bottom: 0.8rem;
}

.left-0 {
  left: 0px;
}

.left-1\/2 {
  left: 50%;
}

.right-2 {
  right: 0.5rem;
}

.right-2\.5 {
  right: 0.625rem;
}

.top-2 {
  top: 0.5rem;
}

.top-2\.5 {
  top: 0.625rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.block {
  display: block;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.rounded-full {
  border-radius: 9999px;
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.font-medium {
  font-weight: 500;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.before\:block::before {
  content: var(--tw-content);
  display: block;
}

.before\:h-1::before {
  content: var(--tw-content);
  height: 0.25rem;
}

.before\:w-full::before {
  content: var(--tw-content);
  width: 100%;
}

.before\:rounded-lg::before {
  content: var(--tw-content);
  border-radius: 0.5rem;
}

.before\:bg-gray-200::before {

  background-color: rgb(229 231 235);
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }
  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }
}

</style>
