export function scaleFactor (px) { return px * (815 / 1440) }
export function directMmToPx (mm) { return (mm / 0.26458333) }
export function pxToMm (px) { return scaleFactor(px) * 0.2645833333 }
export function pxToPt (px) { return scaleFactor(px) * 0.75 }
export function ptToMm (pt) { return pt * 0.352778 }
export function mmToPx (mm) { return (mm / 0.26458333) * (1440 / 815) }
export const monthNames = [
  'January', 'February', 'March',
  'April', 'May', 'June', 'July',
  'August', 'September', 'October',
  'November', 'December'
]

export const colorVariables = {
  insightsBarGreen: '#32938f',
  insightsBarRed: '#c61e85',
  lightGrey: '#BCBAB8',
  lighterGrey: '#707070',
  mediumGrey: '#6A7074',
  segmentsGrey: 'F7F7F7',
  sentiments: [
    '#870A24',
    '#D25265',
    '#BCBAB8',
    '00A3A5',
    '1E5DC6'
  ],
  spacetricsBlack: '#222222',
  spacetricsBlue: '#1e5dc6',
  white: '#FFFFFF'
}

export const rgbColors = {
  mediumGrey: [106, 112, 116],
  spacetricsBlack: [34, 34, 34]
}

export const horizontalBarGraphColors = [
  colorVariables.spacetricsBlue,
  colorVariables.insightsBarGreen,
  colorVariables.insightsBarRed
]

export const InnerPageMinX = 60
export const InnerPageMaxX = 1380
export const InnerPageWidth = 1320
export const PageHeight = 1864
export const RightColumnStartingX = 760

export function getTextHeight (doc, string, fontSize, maxWidth) {
  doc.setFontSize(pxToPt(fontSize))
  let textDimensions = doc.getTextDimensions(string)
  let widthRatio = (textDimensions.w / pxToMm(maxWidth))
  let numberOfLines = Math.ceil(widthRatio)
  let textHeightInPt = pxToPt(fontSize) * numberOfLines * 1.15
  return ptToMm(textHeightInPt)
}
export function getTextWidth (doc, string, fontSize, maxWidth) {
  doc.setFontSize(pxToPt(fontSize))
  let stringDimensions = doc.getTextDimensions(string)
  return mmToPx(stringDimensions.w) > maxWidth ? maxWidth : mmToPx(stringDimensions.w)
}

export function formatTimeToReadableFormat (time) {
  const date = new Date(time)
  const month = monthNames[date.getMonth()]
  const day = date.getDate()
  const hour = date.getHours() % 12 || 12
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const ampm = date.getHours() < 12 ? 'AM' : 'PM'

  return `${month} ${day}, ${hour}:${minutes} ${ampm}`
}

export function getCurrentMonth () {
  const currentDate = new Date()
  const currentMonthIndex = currentDate.getMonth()
  return monthNames[currentMonthIndex]
}
export function formatSegmentData (response, survey) {
  let segmentResponseIds = []
  Object.keys(response.segmentResponses).map((key, value) => {
    response.segmentResponses[key].forEach(key => segmentResponseIds.push(key))
  })

  return Object.entries(survey.segments).map(([key, value]) => {
    return {
      segment: survey.segments[key].name,
      response: survey.segments[key].options.map(option => {
        if (segmentResponseIds.includes(option.id)) { return option.name.charAt(0).toUpperCase() + option.name.slice(1) }
      }).filter(Boolean).join(', ')
    }
  })
}

export function splitInTwoArrays (array) {
  let arr = [...array]
  let halfLength = Math.ceil(arr.length / 2)
  let newArray = []
  newArray.push(arr.splice(0, halfLength))
  newArray.push(arr)
  return newArray
}

export function splitArrayUpToSpecifiedLength (array, length) {
  let splitArrays = []
  let i = 0
  while (i < array.length) { splitArrays.push(array.slice(i, i += length)) }
  return splitArrays
}

export function getAmountInCents (amount) {
  return amount / 100
}

export function convertAmountInCents (amount) {
  return amount * 100
}

export function getReadableDate (date) {
  if (!date) return
  const month = date.toLocaleString('default', { month: 'short' })
  const day = date.getDate()
  const year = date.getFullYear()
  return `${month} ${day}, ${year}`
}
