var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-virtual-tour-insights__transcripts"},[(_vm.editResponse)?_c('div',[_c('edit-virtual-tour-transcript',{attrs:{"response":_vm.editResponse,"initial-sentences":_vm.sentences,"current-sentence-index":_vm.currentSentenceIndex},on:{"playSentence":_vm.playSentence,"exitEditMode":_vm.exitEditMode}})],1):_vm._l((_vm.responsesPassingParticipantCheckboxFilter),function(response){return _c('div',{key:'response-' + response.id},[_c('div',{staticClass:"st-virtual-tour-insights__transcript-row"},[_c('div',{staticClass:"st-virtual-tour-insights__transcript-participant"},[_c('participant-avatar',{attrs:{"participant":response}}),_vm._v(" "),_c('div',{class:{
              'st-virtual-tour-insights__transcript-name': true,
              'st-virtual-tour-insights__transcript-name--active': _vm.activeAudioUrl === response.audioUrl
            }},[_vm._v("\n            "+_vm._s(response.fullName)+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"st-sentiment-badge",class:_vm.sentimentClass(response.sentimentScore)},[_vm._v("\n          "+_vm._s(_vm.sentimentString(response.sentimentScore))+"\n        ")])]),_vm._v(" "),(response.transcript)?[(!_vm.isDesktop && _vm.showFullTranscriptId !== response.id && response.transcript.length > 97)?[_c('div',{class:{
              'st-virtual-tour-insights__transcript-text': true,
              'st-virtual-tour-insights__transcript-text--active': _vm.activeAudioUrl === response.audioUrl
            }},[_vm._v("\n            "+_vm._s(_vm.shortTranscript(response.transcript))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"st-virtual-tour-insights__read-more",on:{"click":function($event){_vm.showFullTranscriptId = response.id}}},[_vm._v("\n            Read More\n          ")])]:_c('div',{class:{
            'st-virtual-tour-insights__transcript-text': true,
            'st-virtual-tour-insights__transcript-text--active': _vm.activeAudioUrl === response.audioUrl
          }},[_vm._v("\n          "+_vm._s(response.transcript)+"\n        ")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"st-virtual-tour-insights__transcript-actions"},[(response.audioUrl)?_c('div',{staticClass:"st-virtual-tour-insights__play-audio-transcript",on:{"click":function($event){return _vm.playTranscript(response)}}},[_c('icon-wrapper',{staticClass:"st-virtual-tour-insights__play-audio-transcript-icon",attrs:{"icon-name":"play"}})],1):_vm._e(),_vm._v(" "),(response.transcript || response.audioUrl)?_c('div',{staticClass:"st-virtual-tour-insights__edit-transcript",on:{"click":function($event){return _vm.openEditMode(response)}}},[_c('icon-wrapper',{attrs:{"icon-name":"edit"}})],1):_vm._e()])],2)}),_vm._v(" "),(_vm.activeAudioUrl)?_c('div',{class:{
      'st-virtual-tour-insights__audio-bar': true,
      'st-virtual-tour-insights__audio-bar--panel-open': _vm.activePanelSection
    }},[_c('audio',{ref:"transcriptAudio",staticClass:"st-virtual-tour-insights__full-audio",attrs:{"controls":""},on:{"timeupdate":_vm.handleTimeUpdate}},[_c('source',{attrs:{"src":_vm.activeAudioUrl,"type":_vm.activeAudioContentType}}),_vm._v(" "),(_vm.activeAudioFlacUrl)?_c('source',{attrs:{"src":_vm.activeAudioFlacUrl,"type":_vm.activeAudioFlacContentType}}):_vm._e()])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }