<template>
  <div
    class="tw-h-fit tw-bg-black  tw-min-h-screen tw-top-0 tw-z-[999] tw-relative tw-w-full tw-overflow-auto   -tw-mt-20"
  >
    <div class="tw-px-5 tw-py-4">
      <p class="tw-text-white tw-flex tw-justify-between">
        <span>
          Answers
        </span>
        <span
          class="tw-px-3 tw-rounded-md tw-py-1 tw-border-white tw-border-solid tw-border tw-cursor-pointer"
          @click="hideCrossStudyModal"
        >
          Close
        </span>
      </p>
    </div>
    <div
      class="tw-px-5 tw-min-h-[93vh] tw-bg-white tw-mt-2 tw-justify-center tw-h-fit tw-overflow-auto tw-pb-20 tw-py-10 tw-rounded-2xl"
    >
      <div
        v-if="loading || loadingQuestions"
        class="tw-max-w-2xl tw-mx-auto tw-min-h-[93vh] tw-justify-center tw-items-center tw-flex"
      >
        <div
          role="status"
          class="tw-flex tw-justify-center tw-items-center"
        >
          <svg
            aria-hidden="true"
            class="tw-w-8 tw-h-8 tw-mr-2 tw-text-gray-200 tw-animate-spin  tw-fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      </div>
      <div
        v-else
        class="tw-max-w-2xl tw-mx-auto tw-flex tw-flex-col tw-items-center tw-space-y-4"
      >
        <div
          class="tw-flex  tw-rounded-md    tw-border-2 tw-mx-auto  tw-border-gray-200 tw-max-w-[382px] tw-border-solid "
        >
          <span
            v-for="(option, index) in filterOptions"
            :key="`${index}_option`"
            class="tw-px-8 tw-flex tw-items-center py-2 cursor-pointer  tw-border-r tw-border-gray-200 tw-border-solid last-of-type:tw-border-none"
            :class="
              option === selectedFilterOption
                ? 'tw-bg-secondary-dark tw-text-white'
                : 'tw-bg-white tw-secondary-dark'
            "
            @click="changeFilterOption(option)"
          >{{ option }}</span>
        </div>
        <div class="tw-flex tw-items-center tw-flex-col tw-w-full">
          <p class="tw-text-secondary-dark">
            Project
          </p>
          <div class="tw-relative tw-w-full">
            <p
              class="tw-flex tw-space-x-2 tw-w-fit tw-mx-auto tw-items-center tw-cursor-pointer"
              @click="showProjectsHandler"
            >
              <span class="tw-text-xl tw-font-semibold">
                {{ selectedProject.name }}
              </span>
              <span class="tw-flex tw-items-center tw-justify-center">
                <svg
                  v-if="!showAllProjects"
                  width="15"
                  height="15"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.55295 6.01838L0.705192 2.76379C0.369272 2.37988 0.641909 1.77905 1.15203 1.77905H6.84756C7.35769 1.77905 7.63032 2.37988 7.2944 2.76379L4.44664 6.01838C4.21008 6.28873 3.78951 6.28873 3.55295 6.01838Z"
                    fill="#202020"
                  />
                </svg>
                <svg
                  v-else
                  width="15"
                  height="15"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.4468 0.981624L7.29456 4.23621C7.63048 4.62012 7.35785 5.22095 6.84772 5.22095L1.1522 5.22095C0.642071 5.22095 0.369433 4.62012 0.705353 4.23621L3.55312 0.981624C3.78967 0.711273 4.21024 0.711273 4.4468 0.981624Z"
                    fill="#202020"
                  />
                </svg>
              </span>
            </p>
            <div
              v-if="showAllProjects"
              class="tw-absolute tw-top-10 tw-w-full tw-z-20 tw-bg-white"
            >
              <div
                v-for="(project, index) in projects"
                :key="`${index}-project`"
                class="tw-px-5 tw-py-3 tw-flex  tw-justify-between  hover:tw-bg-secondary-light tw-cursor-pointer"
                :class="
                  selectedProject.id === project.id
                    ? 'tw-bg-secondary-light'
                    : ''
                "
                @click="selectProject(project)"
              >
                <span class="tw-flex tw-items-center tw-space-x-2">
                  <span class="tw-font-semibold ">
                    {{ project.name ? project.name : "[Project Name]" }}
                  </span>
                  <span class="tw-text-xs tw-font-normal">
                    {{ project.surveyCount }} studies
                  </span>
                </span>
                <span
                  v-if="selectedProject.id === project.id"
                  class="tw-text-sm"
                >
                  Active
                </span>
              </div>
            </div>
          </div>
        </div>
        <template
          v-if="
            !showSuggestedQuestions &&
              !showCurrentQuestionAnswer &&
              selectedFilterOption !== filterOptions[2]
          "
        >
          <div class="tw-mt-4">
            Select the studies
          </div>
          <div class="tw-relative tw-py-4  tw-w-full tw-text-center">
            <div class="tw-space-x-3 ">
              <span
                v-for="option in studySelectionOptions"
                :key="option"
                class="tw-px-10 tw-cursor-pointer tw-rounded-lg tw-py-2"
                :class="
                  option === selectedStudyOption
                    ? 'tw-border-black tw-border-solid tw-border-2 '
                    : 'tw-border-gray-200 tw-border-solid tw-border '
                "
                @click="changeSelectedStudyOption(option)"
              >{{ option }}</span>
            </div>
            <div
              v-if="showCustomStudies"
              class="tw-absolute tw-top-12 tw-w-full tw-z-10 tw-bg-white tw-space-y-2 tw-border-gray-200 tw-border-solid tw-border-b-2 tw-py-2 "
            >
              <div
                v-for="(survey, index) in projectSurveys"
                :key="`${index}-survey`"
                :class="
                  selectedStudies.includes(survey.id)
                    ? 'tw-border-2 tw-border-secondary-dark tw-border-solid'
                    : ''
                "
                class="tw-px-5 tw-py-3 tw-flex tw-justify-between tw-text-primary-dark tw-bg-primary-light tw-cursor-pointer  tw-rounded-xl "
                @click="addRemoveToSelectedStudies(survey.id)"
              >
                <span>
                  {{ survey.name ? survey.name : "[Survey Name]" }}
                </span>
                <svg
                  v-if="selectedStudies.includes(survey.id)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="tw-w-4 tw-h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div
                class="tw-pt-5 tw-pb-10  tw-flex tw-items-center tw-justify-end tw-px-4 tw-w-full "
              >
                <st-button @click="applyFilterForCustomStudies">
                  Apply filter
                </st-button>
              </div>
            </div>
          </div>
          <div>
            <img
              :src="magnifier"
              alt=""
            >
          </div>
          <template v-if="selectedFilterOption === filterOptions[0]">
            <div class="tw-flex tw-flex-col tw-items-center">
              <p class="tw-text-center tw-font-semibold">
                Guided Cross Analysis
              </p>
              <p class="tw-mt-3 tw-text-center">
                This tool will help you generate questions, interlinking the
                results of each study on a specific project. To get started,
                just select the type of question you want to ask and the
                keywords you want to associate with the question.
              </p>
              <p
                class="tw-w-10 tw-h-2 tw-mt-4 tw-bg-secondary-dark tw-mx-auto tw-rounded-full"
              />
            </div>
            <div class="tw-space-x-3  tw-py-4">
              <span
                v-for="option in whyQuestions"
                :key="option"
                class="tw-px-5 tw-cursor-pointer tw-rounded-lg tw-py-2"
                :class="
                  option === selectedWhyQuestion
                    ? 'tw-border-black tw-border-solid tw-border-2 '
                    : 'tw-border-gray-200 tw-border-solid tw-border '
                "
                @click="changeSelectedWhyQuestion(option)"
              >{{ option }}</span>
            </div>
            <div class="tw-w-full">
              <div
                class="tw-rounded-lg tw-flex tw-gap-2 tw-flex-wrap tw-border-gray-200 tw-border-solid tw-border-2 tw-py-2 tw-px-4 tw-h-auto tw-items-start tw-justify-start tw-w-full"
              >
                <span
                  v-for="keyword in filteredNounKeywords"
                  :key="keyword"
                  class="tw-flex tw-space-x-2 tw-px-2 tw-py-1  tw-items-center tw-rounded-md tw-border-secondary-dark  tw-w-fit tw-border-solid tw-border"
                >
                  <span class="">
                    {{ keyword }}
                  </span>
                  <svg
                    class="tw-cursor-pointer"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="removeNoun(keyword)"
                  >
                    <g clip-path="url(#clip0_141_408)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.00015 10C2.23855 10 0 7.76138 0 5C0 2.23862 2.23855 0 5.00015 0C7.76175 0 10 2.23862 10 5C10 7.76168 7.76145 10 5.00015 10ZM5.02004 1.2094C2.92635 1.19872 1.22065 2.88696 1.20967 4.9807C1.19898 7.07416 2.88717 8.78021 4.98056 8.79119C7.07395 8.80188 8.77995 7.11334 8.79093 5.01989C8.80191 2.92614 7.11343 1.22009 5.02004 1.2094ZM7.03268 6.89337L6.89168 7.03438C6.69309 7.23298 6.371 7.23298 6.17271 7.03438L4.98917 5.8508L3.80562 7.03438C3.60703 7.23298 3.28524 7.23298 3.08665 7.03438L2.94565 6.89337C2.74705 6.69477 2.74705 6.37268 2.94565 6.17438L4.12919 4.9908L2.94565 3.80722C2.74705 3.60862 2.74705 3.28653 2.94565 3.08823L3.08665 2.94722C3.28524 2.74862 3.60733 2.74862 3.80562 2.94722L4.98917 4.1308L6.17271 2.94722C6.3713 2.74862 6.69338 2.74862 6.89168 2.94722L7.03268 3.08823C7.23128 3.28683 7.23128 3.60892 7.03268 3.80722L5.84914 4.9908L7.03268 6.17438C7.23128 6.37297 7.23128 6.69507 7.03268 6.89337Z"
                        fill="#202020"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_141_408">
                        <rect
                          width="10"
                          height="10"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
              <!-- <input
            id=""
            type="text"
            name=""
            class="tw-w-full !tw-rounded-xl tw-px-4 tw-py-4 "
            disabled
            multiple
          > -->
            </div>
            <div>
              <st-button
                class=""
                @click="getImportantNouns(false)"
              >
                Generate more keywords
              </st-button>
            </div>
            <div
              class="tw-bg-gray-100 tw-space-x-3 tw-flex tw-items-center tw-w-full tw-px-5 tw-py-4"
            >
              <div>
                <svg
                  width="32"
                  height="25"
                  viewBox="0 0 32 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_29_2511)">
                    <path
                      d="M12.499 6.30416C11.9601 5.26546 10.916 4.66235 9.63606 4.66235C7.64885 4.66235 6.60471 6.03611 6.40262 7.44337C6.36894 7.74492 6.60471 7.97947 6.90785 7.97947H7.64885C7.88462 7.97947 8.08671 7.81193 8.12039 7.57739C8.22143 7.04129 8.55825 6.37117 9.63606 6.37117C10.4781 6.37117 10.8149 6.80675 10.9496 7.0748C11.1517 7.47687 11.1181 7.91245 10.8823 8.24751C10.6465 8.58258 10.4107 8.7166 10.1413 8.91764C9.50134 9.35322 8.99611 9.82231 8.89507 11.129C8.86139 11.4306 9.09716 11.6651 9.36661 11.6651H10.1076C10.3434 11.6651 10.5792 11.4641 10.6128 11.2296C10.6465 10.6935 10.7812 10.5594 11.1517 10.3249C11.4549 10.1239 11.8591 9.82231 12.2632 9.28621C12.9032 8.38154 13.0042 7.27584 12.4996 6.30416H12.499Z"
                      fill="#364139"
                    />
                    <path
                      d="M9.77069 12.5029C9.19811 12.5029 8.72656 12.972 8.72656 13.5416C8.72656 14.1112 9.19811 14.5803 9.77069 14.5803C10.3433 14.5803 10.8148 14.1112 10.8148 13.5416C10.8148 12.972 10.3433 12.5029 9.77069 12.5029Z"
                      fill="#364139"
                    />
                    <path
                      d="M31.8993 23.2583L30.1142 19.4051C30.9899 17.7633 31.293 15.887 30.9899 14.0106C30.4173 10.3919 27.4533 7.44341 23.8157 6.84029C21.7948 6.50523 19.8076 6.8738 18.1235 7.84548C17.7867 6.20368 16.9783 4.72941 15.7658 3.48968C13.7786 1.51341 11.0161 0.641651 8.1868 1.07783C4.54918 1.68094 1.5852 4.62949 1.01261 8.24815C0.709472 10.1245 1.01261 11.9673 1.88833 13.6426L0.103202 17.4959C-0.0988885 17.9649 0.00215651 18.501 0.338973 18.8696C0.574745 19.1042 0.87788 19.2382 1.18102 19.2382C1.34942 19.2382 1.51783 19.2047 1.68624 19.1377L5.59332 17.3283C7.2774 18.1995 9.12989 18.501 11.0161 18.1995C12.0265 18.032 13.0033 17.6969 13.8453 17.1943C14.5526 20.6454 17.4156 23.3929 20.9522 23.9626C22.8383 24.2641 24.6908 23.9626 26.3749 23.0914L30.2483 24.8672C30.4167 24.9342 30.5851 24.9677 30.7535 24.9677C31.0567 24.9677 31.3598 24.8337 31.5956 24.5992C31.9998 24.2306 32.1008 23.6945 31.8987 23.2589L31.8993 23.2583ZM10.7472 16.5236C9.09681 16.7916 7.48009 16.4901 6.06546 15.6524C5.82969 15.5184 5.52655 15.4849 5.29078 15.6189L2.19207 17.0262L3.6067 13.9436C3.70774 13.7091 3.70774 13.4075 3.57302 13.173C2.73097 11.7657 2.42784 10.1574 2.69729 8.5156C3.16884 5.60057 5.52655 3.22163 8.45686 2.75254C8.86104 2.68553 9.23154 2.65202 9.63572 2.65202C11.4882 2.65202 13.2397 3.35565 14.5869 4.6959C15.7658 5.86862 16.4394 7.34289 16.6078 8.95118C16.5068 9.0517 16.3721 9.15222 16.271 9.25274C14.6206 10.8945 13.7449 13.0389 13.7112 15.3174C12.8349 15.9205 11.8244 16.356 10.7466 16.5236H10.7472ZM26.7123 21.382C26.6113 21.315 26.4766 21.315 26.3755 21.315C26.2408 21.315 26.0724 21.3485 25.9377 21.4155C24.523 22.2531 22.9063 22.5547 21.2559 22.2866C18.3256 21.8176 15.9342 19.4386 15.4626 16.5236C15.0922 14.2452 15.8331 12.0338 17.4499 10.4255C19.0666 8.81716 21.2896 8.11353 23.5799 8.4821C26.5102 8.95118 28.868 11.3301 29.3395 14.2452C29.609 15.887 29.3058 17.4953 28.4638 18.9025C28.3291 19.1371 28.2954 19.4386 28.4301 19.6732L29.8447 22.7557L26.7123 21.382Z"
                      fill="#364139"
                    />
                    <path
                      d="M22.4012 14.0442C21.6602 14.0442 21.054 14.6473 21.054 15.3844C21.054 16.1216 21.6602 16.7247 22.4012 16.7247C23.1422 16.7247 23.7485 16.1216 23.7485 15.3844C23.7485 14.6473 23.1422 14.0442 22.4012 14.0442Z"
                      fill="#364139"
                    />
                    <path
                      d="M26.4769 14.0442C25.7359 14.0442 25.1296 14.6473 25.1296 15.3844C25.1296 16.1216 25.7359 16.7247 26.4769 16.7247C27.2179 16.7247 27.8242 16.1216 27.8242 15.3844C27.8242 14.6473 27.2179 14.0442 26.4769 14.0442Z"
                      fill="#364139"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_29_2511">
                      <rect
                        width="32"
                        height="24"
                        fill="white"
                        transform="translate(0 0.967773)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>
                <p>
                  Types of questions to be generated
                </p>
                <p
                  v-for="(number, index) in [1, 4]"
                  :key="`${index}-question`"
                >
                  {{ selectedWhyQuestion }}
                  {{ filteredNounKeywords[number] }} __________?
                </p>
              </div>
            </div>
          </template>
          <template v-if="selectedFilterOption === filterOptions[1]">
            <div class="tw-flex tw-flex-col tw-items-center">
              <p class="tw-text-center tw-font-semibold">
                Open-Ended Analysis
              </p>
              <p class="tw-mt-3 tw-text-center">
                Ask a question about this project. Only selected studies will be
                considered.
              </p>
              <textarea
                v-model="customQuestion"
                type="text"
                multiple
                placeholder="Type your question here"
                class="tw-px-4 tw-py-3 tw-rounded-xl tw-h-40 tw-border-gray-200 tw-mt-2 tw-border-solid tw-border-2 tw-w-full"
              />
            </div>
            <div>
              <p class="tw-text-center tw-py-2 ">
                Suggested questions
              </p>
              <div class="tw-flex tw-flex-col tw-space-y-3">
                <span
                  v-for="(question, index) in openEndedSuggestedQuestions"
                  :key="`${index}-suggested-question-open-ended`"
                  class="tw-bg-primary-light tw-max-w-[400px] tw-text-primary-dark tw-px-3 tw-py-2 tw-rounded-lg tw-cursor-pointer"
                  @click="appendQuestion(question)"
                >
                  {{ question }}
                </span>
              </div>
            </div>
          </template>
          <p
            class="tw-w-10 tw-h-2 tw-mt-4 tw-bg-secondary-dark tw-mx-auto tw-rounded-full"
          />
          <div>
            <st-button @click="getSuggestedQuestions">
              {{
                selectedFilterOption === filterOptions[1]
                  ? "Generate answers"
                  : "Generate questions"
              }}
            </st-button>
          </div>
        </template>

        <!-- THIS IS THE RESULTS OF THE GENERATED QUESTIONS -->
        <template
          v-if="showHistoryQuestions"
          class="tw-w-full"
        >
          <div class="tw-w-full">
            <p
              v-if="historyQuestions.length"
              class="tw-w-full tw-px-4 tw-text-sm"
            >
              Showing {{ historyQuestions.length }} results
            </p>
            <p
              v-else-if="!historyQuestions.length && showHistoryQuestions"
              class="tw-w-full tw-px-4 tw-text-sm tw-text-center"
            >
              No results found
            </p>
            <div
              v-for="(question, index) in historyQuestions"
              :key="`${index}-history-question`"
              class="tw-flex tw-justify-between tw-cursor-pointer tw-px-4 tw-border tw-border-gray-100 tw-border-solid tw-my-3 tw-rounded-lg tw-py-4 tw-items-center tw-w-full"
              @click="getHistoryQuestionAnswer(question.id)"
            >
              <div>
                <p>
                  {{ question.question }}
                </p>
              </div>
              <div>
                <span
                  class="tw-px-3 tw-py-3 tw-bg-primary-light tw-rounded-full tw-flex tw-items-center tw-justify-center"
                >
                  <svg
                    width="9"
                    height="10"
                    viewBox="0 0 9 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.5 4.13396C9.16667 4.51886 9.16667 5.48111 8.5 5.86601L2.04265 9.59416C1.10912 10.1331 0.100947 9.01702 0.731772 8.14292L2.57766 5.58518C2.82982 5.23578 2.82982 4.76416 2.57766 4.41476L0.731788 1.85705C0.100962 0.98295 1.10913 -0.133163 2.04267 0.405815L8.5 4.13396Z"
                      fill="#A6684F"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="suggestedQuestions.length && showSuggestedQuestions"
          class="tw-w-full"
        >
          <div
            class="tw-bg-primary-light tw-w-full tw-px-10 tw-py-4 tw-flex tw-justify-between tw-items-center"
          >
            <div>
              <p class="tw-text-secondary-dark">
                Result generated based on
              </p>
              <p class="tw-font-semibold tw-text-lg">
                {{ selectedWhyQuestion }} / {{ selectedProject.name }}
              </p>
            </div>
            <div>
              <st-button @click="showStartingPage">
                Generate new questions
              </st-button>
            </div>
          </div>
          <p
            class="tw-text-center tw-space-x-2 tw-flex tw-justify-center tw-my-3 tw-items-center"
          >
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_29_2634)">
                <path
                  d="M6.92602 13.7392V14.5976C6.92602 14.8122 7.11581 15 7.33273 15H10.5593C10.7762 15 10.966 14.8122 10.966 14.5976V13.5783C10.966 12.5858 11.3185 11.5933 11.6439 10.8959C11.7794 10.574 11.9692 10.2789 12.1861 10.0107C12.6742 9.34006 12.9724 8.53534 12.9724 7.67696C12.9724 5.34326 10.9118 3.46556 8.49863 3.70698C6.60065 3.92157 5.08226 5.47738 4.94669 7.35507C4.86535 8.34757 5.19072 9.28642 5.733 10.0375C5.9228 10.3057 6.1126 10.6008 6.24817 10.8959C6.57353 11.5933 6.92602 12.6126 6.92602 13.6051M10.4242 13.2564C10.4242 13.2832 10.3971 13.31 10.37 13.31H7.52301C7.4959 13.31 7.46878 13.2832 7.46878 13.2564C7.46878 13.0955 7.44167 12.9345 7.41455 12.7736C7.41455 12.7736 7.41455 12.7467 7.44167 12.7467H10.4513C10.4513 12.7467 10.4784 12.7467 10.4784 12.7736C10.4518 12.9345 10.4247 13.0955 10.4247 13.2564H10.4242ZM7.55012 14.383V13.9002C7.55012 13.8823 7.55916 13.8734 7.57724 13.8734H10.3971C10.4152 13.8734 10.4242 13.8821 10.4242 13.8997V14.3825C10.4242 14.4004 10.4152 14.4094 10.3971 14.4094H7.57724C7.55012 14.4094 7.55012 14.383 7.55012 14.383ZM11.8341 9.58148C11.807 9.63513 11.7528 9.68878 11.7257 9.74243C11.5359 10.0107 11.3461 10.2521 11.2105 10.5472C11.1292 10.7081 11.0478 10.9227 10.9665 11.1373C10.8309 11.4592 10.7225 11.8079 10.614 12.1834C10.614 12.2103 10.5869 12.2103 10.5869 12.2103H7.33321C7.31514 12.2103 7.3061 12.2013 7.3061 12.1834C7.19764 11.8079 7.08918 11.4592 6.95361 11.1373C6.87227 10.9227 6.79093 10.7349 6.70959 10.5472C6.57402 10.2521 6.38422 9.98385 6.19442 9.74243C6.16731 9.68878 6.11308 9.63513 6.08597 9.58148C5.62503 8.91088 5.40812 8.0525 5.54369 7.14048C5.7606 5.69197 6.89939 4.53853 8.36354 4.29711C10.5327 3.92157 12.4306 5.58468 12.4306 7.67696C12.4035 8.37439 12.1866 9.01817 11.8341 9.58148Z"
                  fill="black"
                />
                <path
                  d="M11.3998 7.62335C11.2372 7.62335 11.1287 7.48923 11.1287 7.35511C11.1016 5.9066 9.71877 5.55789 9.71877 5.53106C9.55608 5.50424 9.47474 5.34329 9.52897 5.18235C9.55608 5.0214 9.71877 4.94093 9.88145 4.99458C10.5051 5.15552 11.6981 5.85295 11.7252 7.32829C11.671 7.48923 11.5625 7.62335 11.4003 7.62335H11.3998Z"
                  fill="black"
                />
                <path
                  d="M15.7381 11.6201C15.6839 11.6201 15.6296 11.5933 15.5754 11.5665L13.2436 10.0911C13.108 10.0107 13.0538 9.79607 13.1351 9.66195C13.2165 9.52783 13.4334 9.47418 13.569 9.55466L15.9008 11.03C16.0363 11.1105 16.0906 11.3251 16.0092 11.4592C15.955 11.5933 15.8465 11.6201 15.7381 11.6201Z"
                  fill="black"
                />
                <path
                  d="M13.4334 5.12872C13.3249 5.12872 13.2165 5.07508 13.1622 4.9946C13.0809 4.86048 13.108 4.64589 13.2707 4.56542L15.6025 3.09008C15.7381 3.00961 15.955 3.03644 16.0363 3.19738C16.1177 3.3315 16.0906 3.5461 15.9279 3.62657L13.5961 5.1019C13.5418 5.12872 13.4876 5.12872 13.4334 5.12872Z"
                  fill="black"
                />
                <path
                  d="M17.2022 7.81102H14.057C13.8943 7.81102 13.7316 7.6769 13.7316 7.48913C13.7316 7.30136 13.8672 7.16724 14.057 7.16724H17.1751C17.3378 7.16724 17.5005 7.30136 17.5005 7.48913C17.5005 7.6769 17.3649 7.81102 17.2022 7.81102Z"
                  fill="black"
                />
                <path
                  d="M2.15397 11.6202C2.04551 11.6202 1.93705 11.5665 1.88283 11.4861C1.80148 11.3519 1.8286 11.1373 1.99128 11.0569L4.32309 9.58154C4.45866 9.50107 4.67557 9.52789 4.75691 9.68884C4.83825 9.84978 4.81114 10.0376 4.64846 10.118L2.31665 11.5934C2.26242 11.6202 2.20819 11.6202 2.15397 11.6202Z"
                  fill="black"
                />
                <path
                  d="M4.48577 5.12866C4.43154 5.12866 4.37731 5.10184 4.32308 5.07502L1.99128 3.59968C1.85571 3.51921 1.80148 3.30462 1.88282 3.1705C1.96416 3.03638 2.18108 2.98273 2.31665 3.0632L4.64845 4.53853C4.78402 4.619 4.83825 4.8336 4.75691 4.96772C4.67557 5.07502 4.56711 5.12866 4.48577 5.12866Z"
                  fill="black"
                />
                <path
                  d="M3.83502 7.65013H0.825368C0.662684 7.65013 0.5 7.51601 0.5 7.32824C0.5 7.14047 0.63557 7.00635 0.825368 7.00635H3.83502C3.99771 7.00635 4.16039 7.14047 4.16039 7.32824C4.16039 7.51601 3.99771 7.65013 3.83502 7.65013Z"
                  fill="black"
                />
                <path
                  d="M10.8033 3.27772C10.7491 3.27772 10.722 3.27772 10.6678 3.2509C10.5051 3.17043 10.4508 2.98266 10.5051 2.84854L11.6981 0.327059C11.7794 0.166114 11.9692 0.112466 12.1048 0.166114C12.2675 0.246587 12.3217 0.434356 12.2675 0.568477L11.0745 3.08995C11.0202 3.22407 10.9118 3.27772 10.8033 3.27772Z"
                  fill="black"
                />
                <path
                  d="M7.25138 3.27784C7.14293 3.27784 7.03447 3.22419 6.98024 3.09007L5.70589 0.43447C5.62454 0.273525 5.70589 0.0857553 5.84146 0.0321069C6.00414 -0.0483657 6.19394 0.0321069 6.24817 0.166228L7.52252 2.82182C7.60387 2.98277 7.52252 3.17054 7.38695 3.22419C7.33321 3.27784 7.27898 3.27784 7.25138 3.27784Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_29_2634">
                  <rect
                    width="17"
                    height="15"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span>
              The questions you select will be saved in your history.
            </span>
          </p>
          <p class="tw-w-full tw-px-4 tw-text-sm">
            Showing {{ suggestedQuestions.length }} results
          </p>
          <div
            v-for="(question, index) in suggestedQuestions"
            :key="`${index}-suggested-question`"
            class="tw-flex tw-justify-between tw-cursor-pointer tw-px-4 tw-border tw-border-gray-100 tw-border-solid tw-my-3 tw-rounded-lg tw-py-4 tw-items-center tw-w-full"
            @click="getQuestionAnswer(question)"
          >
            <div>
              <p>
                {{ question }}
              </p>
            </div>
            <div>
              <span
                class="tw-px-3 tw-py-3 tw-bg-primary-light tw-rounded-full tw-flex tw-items-center tw-justify-center"
              >
                <svg
                  width="9"
                  height="10"
                  viewBox="0 0 9 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 4.13396C9.16667 4.51886 9.16667 5.48111 8.5 5.86601L2.04265 9.59416C1.10912 10.1331 0.100947 9.01702 0.731772 8.14292L2.57766 5.58518C2.82982 5.23578 2.82982 4.76416 2.57766 4.41476L0.731788 1.85705C0.100962 0.98295 1.10913 -0.133163 2.04267 0.405815L8.5 4.13396Z"
                    fill="#A6684F"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <!--- FOLLOWING IS THE QUESTION ANSWER SECTION -->
        <div
          v-if="!showSuggestedQuestions && showCurrentQuestionAnswer"
          class="tw-w-full tw-flex tw-flex-col"
        >
          <div class="tw-flex tw-items-center tw-mt-3 tw-space-x-3 tw-w-full">
            <span
              class="tw-px-3 tw-py-2 tw-bg-gray-100 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-cursor-pointer"
              @click="hideCurrentQuestionAnswer"
            >
              <svg
                width="7"
                height="13"
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.49686 1.00314C6.66771 1.174 6.66771 1.451 6.49686 1.62186L1.55622 6.5625L6.49686 11.5031C6.66771 11.674 6.66771 11.951 6.49686 12.1219C6.326 12.2927 6.04899 12.2927 5.87814 12.1219L0.62814 6.87186C0.457286 6.701 0.457286 6.42399 0.62814 6.25314L5.87814 1.00314C6.049 0.832286 6.326 0.832286 6.49686 1.00314Z"
                  fill="#474747"
                />
              </svg>
            </span>
            <p class="tw-text-lg tw-font-semibold">
              {{ currentAskedQuestion }}
            </p>
          </div>
          <div
            class="tw-flex tw-justify-between tw-px-4 tw-border tw-border-gray-100 tw-border-solid tw-my-3 tw-rounded-lg tw-py-4 tw-items-center tw-w-full"
          >
            <div>
              <p class="tw-flex tw-flex-col">
                <span class="tw-font-semibold ">
                  Answer:
                </span>
                <span>
                  {{ currentQuestionAnswer.answer }}
                </span>
              </p>
            </div>
          </div>
          <div
            class="tw-flex tw-justify-between tw-px-4 tw-border tw-border-gray-100 tw-border-solid tw-my-3 tw-rounded-lg tw-py-4 tw-items-center tw-w-full"
          >
            <div class="tw-w-full">
              <div class="tw-flex tw-flex-col">
                <p class="tw-font-semibold tw-text-lg ">
                  Sources:
                </p>
                <div
                  v-for="(source, index) in currentQuestionAnswer.sources"
                  :key="`${index}-source`"
                  class="tw-border-b tw-border-solid tw-border-gray-100 tw-py-3 tw-w-full"
                >
                  <p class="tw-flex tw-flex-col tw-w-full">
                    <span class="tw-text-base tw-font-semibold">
                      {{ source.participantName }}
                    </span>
                    <span class="tw-text-xs">
                      {{ getReadableDate(source) }}
                    </span>
                  </p>
                  <p class="tw-mt-3 tw-w-full">
                    {{ source.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Magnifier from '../../../../assets/images/magnifier.png'
import { StButton } from '../../common'
export default {
  components: {
    StButton
  },
  props: {
    projects: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      filterOptions: ['Guided', 'Open-Ended', 'History'],
      studySelectionOptions: ['All', 'Custom'],
      selectedFilterOption: 'Guided',
      selectedStudyOption: 'All',
      showAllProjects: false,
      customQuestion: '',
      showCustomStudies: false,
      whyQuestions: ['Why', 'When', 'How', 'Where', 'What'],
      selectedWhyQuestion: 'Why',
      magnifier: Magnifier,
      selectedProject: this.projects[0],
      projectSurveys: this.projects[0].allSurveys.filter(
        (survey) => survey.published
      ),
      nounKeywords: [],
      filteredNounKeywords: [],
      loading: true,
      suggestedQuestions: [],
      historyQuestions: [],
      currentQuestionAnswer: {},
      currentAskedQuestion: '',
      showSuggestedQuestions: false,
      showHistoryQuestions: false,
      selectedStudies: [],
      showCurrentQuestionAnswer: false,
      openEndedSuggestedQuestions: [],
      loadingQuestions: false
    }
  },

  mounted () {
    setTimeout(() => {
      this.getImportantNouns()
    }, 500)
  },

  methods: {
    appendQuestion (question) {
      this.customQuestion = question
    },
    applyFilterForCustomStudies () {
      this.showCustomStudies = false
      if (this.selectedFilterOption === 'Guided') {
        this.getImportantNouns()
      }
      if (this.selectedFilterOption === this.filterOptions[2]) {
        this.getQuestionsHistory()
      }
      if (this.selectedFilterOption === this.filterOptions[1]) {
        this.getSuggestedQuestionForOpenEnded()
      }
    },
    addRemoveToSelectedStudies (id) {
      if (this.selectedStudies.includes(id)) {
        this.selectedStudies = this.selectedStudies.filter((s) => s !== id)
      } else {
        this.selectedStudies.push(id)
      }
    },
    changeFilterOption (option) {
      if (option === this.filterOptions[2]) {
        this.getQuestionsHistory()
      }
      if (option === this.filterOptions[1]) {
        this.filteredNounKeywords = []
        this.selectedWhyQuestion = null
        this.loading = true
        this.getSuggestedQuestionForOpenEnded()
      }
      this.selectedWhyQuestion = 'Why'
      this.showCurrentQuestionAnswer = false
      this.showSuggestedQuestions = false
      this.showHistoryQuestions = false
      this.getImportantNouns()
      this.selectedFilterOption = option
    },
    removeNoun (noun) {
      this.filteredNounKeywords = this.filteredNounKeywords.filter(
        (n) => n !== noun
      )
    },
    changeSelectedStudyOption (option) {
      if (option === 'Custom') {
        this.showCustomStudies = true
      } else {
        this.showCustomStudies = false
        this.selectedStudies = []
        this.nounKeywords = []
      }
      this.selectedStudyOption = option
    },
    changeSelectedWhyQuestion (option) {
      this.selectedWhyQuestion = option
    },
    showProjectsHandler () {
      this.showAllProjects = !this.showAllProjects
    },
    hideCrossStudyModal () {
      this.$emit('hideCrossStudyModal')
    },
    selectProject (project) {
      this.selectedProject = project
      this.showAllProjects = false
      const studies = JSON.parse(JSON.stringify(project)).allSurveys.filter(
        (survey) => survey.published
      )
      this.projectSurveys = studies
      if (this.selectedFilterOption === this.filterOptions[1]) {
        this.getSuggestedQuestionForOpenEnded()
      }
      if (this.selectedFilterOption === this.filterOptions[2]) {
        this.getQuestionsHistory()
      }
      if (this.selectedFilterOption === this.filterOptions[0]) {
        this.getImportantNouns()
      }
      this.showStartingPage()
    },
    hideCurrentQuestionAnswer () {
      if (this.selectedFilterOption === this.filterOptions[1]) {
        this.showCurrentQuestionAnswer = false
        return
      }
      if (this.selectedFilterOption === this.filterOptions[2]) {
        this.showCurrentQuestionAnswer = false
        this.showHistoryQuestions = true
        return
      }
      this.showCurrentQuestionAnswer = false
      this.showSuggestedQuestions = true
    },
    showStartingPage () {
      this.showSuggestedQuestions = false
      this.showCurrentQuestionAnswer = false
      this.suggestedQuestions = []
      this.showHistoryQuestions = false
      this.historyQuestions = []
    },
    getHistoryQuestionAnswer (id) {
      const answer = this.historyQuestions.find(
        (question) => question.id === id
      )

      this.currentQuestionAnswer = answer
      this.currentQuestionAnswer.sources = answer.sources
      this.showCurrentQuestionAnswer = true
      this.showHistoryQuestions = false
    },

    getImportantNouns (showLoader = true) {
      const data = {}
      if (this.selectedStudies.length > 0) {
        data.survey_ids = this.selectedStudies
      }
      this.loading = showLoader
      this.$axios({
        method: 'get',
        data,
        url: `projects/${this.selectedProject.id}/get_nouns`
      })
        .then((res) => {
          this.nounKeywords = res.data.data
          this.filteredNounKeywords = res.data.data
          this.loading = false
        })
        .catch(() => {
          console.log('error')
          this.loading = false
        })
    },

    async getSuggestedQuestions () {
      if (
        this.customQuestion !== '' &&
        this.selectedFilterOption === this.filterOptions[1]
      ) {
        this.getQuestionAnswer(this.customQuestion)
        return
      }
      const data = {
        keywords: this.filteredNounKeywords,
        question_word: this.selectedWhyQuestion
      }
      if (this.selectedStudies.length > 0) {
        data.survey_ids = this.selectedStudies
      }
      this.loading = true

      const getPrompt = await this.$axios({
        method: 'post',
        url: `projects/${this.selectedProject.id}/suggested_question_prompt`,
        data
      })

      const { prompt } = getPrompt.data

      // const getQuestions = await fetch(
      //   'https://us-central1-spacetrics.cloudfunctions.net/suggested-answers',
      //   {
      //     method: 'post',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //       prompt,
      //       type: 'question'
      //     })
      //   }
      // )

      // if (!getQuestions.ok) {
      //   this.loading = false
      //   throw new Error(`HTTP error! status: ${getQuestions.status}`)
      // }
      // const getQuestionsData = await getQuestions.json()

      this.suggestedQuestions = prompt
      this.loading = false
      this.showSuggestedQuestions = true

      // this.$axios({
      //   method: "post",
      //   url: `projects/${this.selectedProject.id}/suggested_question_prompt`,
      //   data,
      // })
      //   .then((res) => {
      //     this.suggestedQuestions = res.data.data;
      //     this.loading = false;
      //     this.showSuggestedQuestions = true;
      //   })
      //   .catch(() => {
      //     console.log("error");
      //     this.loading = false;
      //   });
    },
    async getQuestionAnswer (question) {
      this.currentAskedQuestion = question
      this.loading = true

      let data = {
        suggested_question: question
      }

      if (this.selectedFilterOption === 'Guided') {
        data.keywords = this.filteredNounKeywords
      }

      const getPrompt = await this.$axios({
        method: 'post',
        data,
        url: `projects/${this.selectedProject.id}/suggested_answer_prompt`
      })

      const { prompt } = getPrompt.data

      const getAnswer = await fetch(
        'https://us-central1-spacetrics.cloudfunctions.net/suggested-answers',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            prompt,
            type: 'answer'
          })
        }
      )

      if (!getAnswer.ok) {
        this.loading = false
        throw new Error(`HTTP error! status: ${getAnswer.status}`)
      }
      const getAnswerData = await getAnswer.json()

      this.currentQuestionAnswer = getAnswerData
      this.showSuggestedQuestions = false
      this.showCurrentQuestionAnswer = true
      this.loading = false

      const { answer, sources } = getAnswerData

      await this.$axios({
        method: 'post',
        data: {
          suggested_question: question,
          answer,
          sources
        },
        url: `projects/${this.selectedProject.id}/save_suggested_answers`
      })

      // this.$axios({
      //   method: 'post',
      //   data: {...data, answer, sources },
      //   url: `projects/${this.selectedProject.id}/save_suggested_answers`
      // })
      //   .then((res) => {
      //     // console.log(res.data.data)
      //     this.currentQuestionAnswer = res.data.data
      //     this.showSuggestedQuestions = false
      //     this.showCurrentQuestionAnswer = true
      //     this.loading = false
      //   })
      //   .catch(() => {
      //     console.log('error')
      //     this.loading = false
      //   })
    },

    getReadableDate (data) {
      if (!data.createdAt) return
      const date = new Date(data.createdAt)
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },

    getQuestionsHistory () {
      this.loading = true
      this.$axios({
        method: 'post',
        data: {
          // keywords: this.nounKeywords.splice(0, 5),
          project_id: this.selectedProject.id
        },
        url: `/suggested_answer_historys/get_history`
      })
        .then((res) => {
          this.loading = false
          this.historyQuestions = res.data.result.filter(
            (question) => question.question !== null
          )
          this.showHistoryQuestions = true
          // console.log(res.data.result)
        })
        .catch(() => {
          console.log('error')
          this.loading = false
        })
    },

    async getSuggestedQuestionForOpenEnded () {
      this.loadingQuestions = true
      const getPrompt = await this.$axios({
        method: 'get',
        url: `/projects/${this.selectedProject.id}/top_suggested_question_prompt`
      })

      const { prompt } = getPrompt.data

      this.loading = true
      const getQuestions = await fetch(
        'https://us-central1-spacetrics.cloudfunctions.net/suggested-answers',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            prompt,
            type: 'question'
          })
        }
      )

      if (!getQuestions.ok) {
        this.loading = false
        throw new Error(`HTTP error! status: ${getQuestions.status}`)
      }

      const getQuestionsData = await getQuestions.json()
      this.openEndedSuggestedQuestions = getQuestionsData
      this.loading = false
      this.loadingQuestions = false
      // this.$axios({
      //   method: "get",
      //   url: `/projects/${this.selectedProject.id}/top_suggested_question_prompt`,
      // })
      //   .then((res) => {
      //     this.loading = false;
      //     this.openEndedSuggestedQuestions = res.data.data;
      //     // console.log(res.data.data)
      //   })
      //   .catch(() => {
      //     console.log("error");
      //     this.loading = false;
      //   });
    }
  }
}
</script>

<style lang="scss" scoped></style>
