var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.url ? 'a' : 'button',{key:_vm.disabled,tag:"component",staticClass:"st-button",class:_vm.modifierClasses,attrs:{"href":_vm.url,"type":_vm.type,"disabled":_vm.disabled},on:{"click":_vm.handleNewPath,"focus":function($event){_vm.hover = true},"focusout":function($event){(_vm.active = false), (_vm.hover = false)},"mousedown":function($event){_vm.active = true},"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){(_vm.active = false), (_vm.hover = false)}}},[_c('div',{class:{
      'st-button-content': true,
      'st-button-content--hover--secondary':
        _vm.hover && !_vm.disabled && _vm.variant.includes('secondary'),
      'st-button-content--hover--white-background':
        _vm.hover && !_vm.disabled && _vm.variant.includes('whiteBackground'),
      'st-button-content--active': _vm.active,
      'st-button-content--icon-left': _vm.iconPosition === 'left',
    }},[_vm._t("default",function(){return [_c('div',{class:{ 'st-button__caption text-center w-full ': _vm.caption }},[_vm._v("\n        "+_vm._s(_vm.caption)+"\n      ")])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }