<template>
  <div class="bx--grid">
    <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
    </div>
    <div class="bx--col-xs-12">
      <st-hyperlink
        class="back-hyperlink-spacing"
        :back-link="true"
        text="Back"
        :url="`/projects/${projectId}`"
      />
      <h1 class="page-header">
        {{ projectName }} - New Study
      </h1>
    </div>
    <div class="bx--col-sm-6 bx--col-xs-12 bx--offset-sm-3">
      <st-input
        v-model="name"
        class="input-spacing"
        label="Study Name"
        name="survey[name]"
        placeholder="Study Name"
      />
      <div class="st-new-survey__radio-button-label">
        How would you like to deliver this survey?
      </div>

      <st-radio-button
        v-model="virtualPresentation"
        class="st-radio-button__default-container"
        :options="[
          { caption: 'Send Virtually', value: false, iconName: 'paper-plane'},
        ]"
      />
      <div class="st-new-survey__create-button-container">
        <st-button
          class="st-new-survey__create-button"
          caption="Create Study"
          icon-name="pointer"
          :disabled="isCreateDisabled"
          @click="createSurvey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { IconWrapper, StButton, StHyperlink, StInput, StRadioButton } from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StInput,
    StRadioButton
  },
  props: {
    projectName: {
      type: String,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      name: '',
      virtualPresentation: null
    }
  },
  computed: {
    isCreateDisabled () {
      return false
      // return this.virtualPresentation === null || !this.name
    }
  },
  methods: {
    createSurvey () {
      console.log(this.virtualPresentation)
      // let url = '/study/'
      // let data = {
      //   name: this.name,
      //   virtual_presentation: this.virtualPresentation,
      //   project_id: this.projectId
      // }
      // this.$axios.request({
      //   url: url,
      //   method: 'post',
      //   data: data
      // })
      //   .then(res => {
      //     window.location.href = `/study/${res.data.id}/edit`
      //   })
      //   .catch(err => { console.log(err) })
    }
  }
}
</script>
