<template>
  <div>
    <st-toast-notification
      v-if="showSuccessMessage"
      class="st-toast-notification--fixed tw-z-[9999]"
      kind="success"
      title="Changes saved!"
      caption="Changes saved!"
      @click="showSuccessMessage=false"
    />
    <div class="bx--grid mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          caption="Save Changes"
          icon-name="save"
          :disabled="!pageInfoChanged"
          :show-action="true"
          @click="saveChanges"
        />
      </div>
    </div>
    <div class="bx--grid">
      <div
        class="bx--row"
      >
        <div class="bx--offset-sm-1 bx--col-sm-8 bx--col-xs-12">
          <h1 class="page-header">
            Company Info
          </h1>

          <div class="flex justify-between items-center">
            <div
              class="edit-company-logo__company-info-page edit-company-logo__hover-to-edit"
              tabIndex="0"
              @focus="companyLogoHovered = true"
              @focusout="companyLogoHovered = false"
              @keyup.enter="openMediaUploader"
              @mouseenter="companyLogoHovered = true"
              @mouseleave="companyLogoHovered = false"
            >
              <transition name="fade-div">
                <div
                  v-if="companyLogoHovered && isUserAdmin"
                  :class="{
                    'edit-company-logo': true,
                    'edit-company-logo--active': editCompanyLogoActive
                  }"
                  @mouseup="openMediaUploader"
                >
                  <transition-group
                    name="fade"
                    class="edit-company-logo__transition-group"
                  >
                    <icon-wrapper
                      key="uploadIcon"
                      :class="{
                        'edit-company-logo__icon': true,
                        'edit-company-logo__icon--active': editCompanyLogoActive
                      }"
                      icon-name="upload"
                      :invert="true"
                    />
                    <span
                      v-if="companyLogoHovered && !editCompanyLogoActive"
                      key="editCompanyLogoInstructions"
                      class="edit-company-logo__instructions"
                    >
                      Change Logo
                    </span>
                  </transition-group>
                </div>
              </transition>
              <div
                class="edit-company-logo__company-logo"
                :style="companyLogoStyle"
              />
            </div>
            <!-- <a
              href="/plans/upgrade_plan"
              class="st-button-secondary bg-primary-brown py-2 px-3 rounded-4p text-white"
            >
              View plan details
            </a> -->
          </div>
          <div v-if="isUserAdmin">
            <st-input
              v-model.trim="user.companyName"
              class="profile__field"
              label="Name"
              name="user[company_name]"
              placeholder="Wayne Industries"
              @emitEnter="saveChanges"
            />
            <st-input
              v-model.trim="user.website"
              class="profile__field"
              label="Website"
              name="user[website]"
              placeholder="www.wayneindustries.com"
              @emitEnter="saveChanges"
            />
            <st-dropdown-menu
              ref="companySizeDropdown"
              class="profile__dropdown"
              caption="Size (# of employees)"
              :display-caption="true"
              :full-width="true"
              :initial-active-option="getActiveCompanySizeCategoryOption"
              :options="companySizeCategoryValues"
              @updateOption="updateCompanySize"
            />
            <st-dropdown-menu
              ref="industryDropdown"
              class="profile__dropdown"
              caption="Industry"
              :display-caption="true"
              :full-width="true"
              :initial-active-option="getActiveIndustryOption"
              :options="industryValues"
              @updateOption="updateIndustry"
            />
          </div>
          <div v-else>
            <div class="profile__field">
              <p class="profile__field-title">
                Name
              </p>
              <p class="profile__field-value">
                {{ user.companyName }}
              </p>
            </div>
            <div class="profile__field">
              <p class="profile__field-title">
                Website
              </p>
              <p class="profile__field-value">
                {{ user.website }}
              </p>
            </div>
            <div class="profile__field">
              <p class="profile__field-title">
                Size
              </p>
              <p class="profile__field-value">
                {{ getActiveCompanySizeCategoryOption }} Employees
              </p>
            </div>
            <div class="profile__field">
              <p class="profile__field-title">
                Industry
              </p>
              <p class="profile__field-value">
                {{ getActiveIndustryOption }}
              </p>
            </div>
            <div />
          </div>
        </div>
        <div
          v-if="isUserAdmin"
          class="bx--offset-sm-1 bx--col-sm-2 desktop-page-right-action-button"
        >
          <st-button
            caption="Save Changes"
            icon-name="save"
            :disabled="!pageInfoChanged"
            :show-action="true"
            @click="saveChanges"
          />
        </div>
      </div>
      <st-media-uploader
        v-if="showMediaUploader"
        :aspect-ratio="1.0"
        :folders="folders"
        prop-name="account[logo]"
        modal-title="Change Company Logo"
        :url="`/accounts/${currentUser.accountId}`"
        @closeMediaUploader="showMediaUploader = false"
        @uploadComplete="uploadComplete"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEqual } from 'lodash'
import {
  COMPANY_SIZE_CATEGORIES,
  INDUSTRY_CATEGORIES
} from '../../../utilities/enums'
import { companyLogoStyle, isUserAdmin } from '../../../mixins'
import {
  IconWrapper,
  StButton,
  StDropdownMenu,
  StInput,
  StToastNotification,
  StMediaUploader
} from '../../common/'

export default {
  components: {
    IconWrapper,
    StButton,
    StDropdownMenu,
    StInput,
    StMediaUploader,
    StToastNotification
  },
  mixins: [companyLogoStyle, isUserAdmin],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    userInfo: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      companyLogoHovered: false,
      editCompanyLogoActive: false,
      showMediaUploader: false,
      showSuccessMessage: false,
      user: this.userInfo
    }
  },
  computed: {
    ...mapState(['currentUser']),
    companyLogo () {
      return this.currentUser.companyLogoUrl
    },

    companySizeCategoryValues () {
      return Object.values(COMPANY_SIZE_CATEGORIES)
    },
    getActiveCompanySizeCategoryOption () {
      return COMPANY_SIZE_CATEGORIES[this.user.companySizeCategory]
    },
    getActiveIndustryOption () {
      return INDUSTRY_CATEGORIES[this.user.industry]
    },
    industryValues () {
      return Object.values(INDUSTRY_CATEGORIES)
    },
    matchCompanySizeCategoryListValue () {
      return Object.keys(COMPANY_SIZE_CATEGORIES).find(key => COMPANY_SIZE_CATEGORIES[key] === this.user.companySizeCategory)
    },
    matchIndustryListValue () {
      return Object.keys(INDUSTRY_CATEGORIES).find(key => INDUSTRY_CATEGORIES[key] === this.user.industry)
    },
    pageInfoChanged () {
      return !isEqual(this.user, this.currentUser)
    },
    updateRecordData () {
      let data = {
        name: this.user.companyName,
        company_size_category: this.matchCompanySizeCategoryListValue,
        industry: this.matchIndustryListValue,
        website: this.user.website
      }
      return data
    },
    updateStoreData () {
      let data = {
        companyName: this.user.companyName,
        companySizeCategory: this.user.companySizeCategory,
        industry: this.user.industry,
        website: this.user.website
      }
      return data
    }
  },
  methods: {
    openMediaUploader () {
      this.editCompanyLogoActive = true
      setTimeout(() => {
        this.showMediaUploader = true
        this.editCompanyLogoActive = false
      }, 400)
    },
    saveChanges () {
      let url = `/accounts/${this.user.accountId}`
      this.$axios.request({
        method: 'patch',
        url: url,
        data: { account: this.updateRecordData }
      }).then(res => {
        this.showSuccessMessage = true
        setTimeout(() => {
          this.$store.commit('setCurrentUser', { ...this.currentUser, ...this.updateStoreData })
        }, 250)
        setTimeout(() => {
          this.showSuccessMessage = false
        }, 3000)
      }).catch(err => { console.log('err', err) })
    },
    uploadComplete (data) {
      this.user.companyLogoUrl = data.logoUrl
      this.$store.commit('setCurrentUser', { ...this.currentUser, 'companyLogoUrl': data.logoUrl })
      this.showMediaUploader = false
    },
    updateCompanySize (valueFromDropDown) {
      this.user.companySizeCategory = valueFromDropDown
    },
    updateIndustry (valueFromDropDown) {
      this.user.industry = valueFromDropDown
    }
  }
}
</script>
