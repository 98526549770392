<template>
      <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.4429 11.7824C11.334 11.9129 11.1755 11.9916 11.0058 11.9989C10.8361 12.0065 10.6709 11.9427 10.5507 11.8225C10.4306 11.7024 10.3667 11.5373 10.3743 11.368C10.3819 11.1983 10.4607 11.0396 10.5912 10.931L14.9365 6.58226L0.574185 6.58225C0.365529 6.571 0.176948 6.45333 0.0759658 6.27059C-0.0253206 6.08784 -0.0253205 5.86587 0.0759659 5.68313C0.177252 5.50038 0.36553 5.38271 0.574186 5.37146L14.9365 5.37146L10.5845 1.02756C10.475 0.912927 10.4139 0.760285 10.4139 0.601867C10.4139 0.443448 10.475 0.290806 10.5845 0.176173C10.6974 0.0630598 10.8507 -0.000490199 11.0103 -0.000490171C11.17 -0.000490143 11.3233 0.0630599 11.4362 0.176173L16.8186 5.55694C16.9342 5.66823 16.9999 5.82179 16.9999 5.98264C16.9999 6.14349 16.9345 6.29704 16.8186 6.40833L11.4429 11.7824Z" fill="#7635FF"/>
</svg>

</template>

<script>
export default {}
</script>
