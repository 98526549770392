<template>
  <div class="bx--offset-sm-3 bx--col-sm-6 bx--col-xs-12">
    <!-- <st-toast-notification
      v-if="showToast"
      kind="info"
      title="info"
      caption="Your email is verified"
      @click="showToast = false"
    /> -->
    <div class="st-finish-profile__header-row">
      <h1 class="st-finish-profile__header">
        Finish Your Profile
      </h1>
      <div
        class="tw-cursor-pointer"
        @click="handleSkip"
      >
        skip
      </div>
    </div>
    <p
      v-if="showError"
      class="tw-text-error"
    >
      Please add at least your company name!
    </p>
    <div class="st-finish-profile__inputs">
      <template v-if="activePageIndex === 0">
        <st-input
          v-model="user.companyName"
          class="st-finish-profile__input"
          label="Company Name"
          style-type="rounded"
          name="user[account_name]"
          placeholder="Company Name"
        />
        <st-input
          v-model="user.website"
          class="st-finish-profile__input"
          label="Website URL (Optional)"
          style-type="rounded"
          name="user[website]"
          placeholder="Website URL (Optional)"
        />
        <st-input
          v-model="user.locationName"
          class="st-finish-profile__input"
          label="Location (Optional)"
          style-type="rounded"
          name="user[location_name]"
          placeholder="Location (Optional)"
        />
        <st-dropdown-menu
          class="st-finish-profile__dropdown-menu"
          caption="Company Size (Optional)"
          :display-caption="true"
          :initial-active-option="initialActiveSize"
          :options="Object.values(companySizeCategoryList)"
          @updateOption="setSizeOption"
        />
        <st-dropdown-menu
          class="st-finish-profile__dropdown-menu"
          caption="Industry (Optional)"
          :initial-active-option="initialActiveIndustry"
          :options="Object.values(industryList)"
          :display-caption="true"
          @updateOption="setIndustryOption"
        />
      </template>
      <template v-else-if="activePageIndex === 1">
        <st-dropdown-menu
          key="department-dropdown"
          class="st-finish-profile__dropdown-menu"
          caption="Department (Optional)"
          placeholder="Select as many as they apply"
          :display-caption="true"
          :select-multiple="true"
          :initial-active-options-array="user.departments"
          :option-caption-fn="(department) => department.name"
          :options="departments"
          @updateMultiple="setDepartmentsOptions"
        />
        <st-upload-preview
          v-if="user.companyLogoUrl"
          class="st-finish-profile__image-preview"
          :src="user.companyLogoUrl"
          :filename="fileName(user.companyLogoUrl)"
          :hide-bar="true"
          @remove="user.companyLogoUrl = null"
        />
        <st-hyperlink
          v-else
          class="st-finish-profile__upload"
          icon-class="st-hyperlink__default-icon-spacing"
          icon-name="upload"
          text="Upload Company Logo"
          @click="
            openModal(
              'account[logo]',
              'Company Logo',
              `/accounts/${userInfo.accountId}`
            )
          "
        />
        <st-upload-preview
          v-if="user.companyCoverImageUrl"
          class="st-finish-profile__image-preview"
          :display-large-preview="true"
          :src="user.companyCoverImageUrl"
          :filename="fileName(user.companyCoverImageUrl)"
          :hide-bar="true"
          @remove="user.companyCoverImageUrl = null"
        />
        <st-hyperlink
          v-else
          class="st-finish-profile__upload"
          icon-class="st-hyperlink__default-icon-spacing"
          icon-name="upload"
          text="Upload Cover Photo"
          @click="
            openModal(
              'account[cover_image]',
              'Cover Photo',
              `/accounts/${userInfo.accountId}`
            )
          "
        />
        <st-upload-preview
          v-if="user.avatarUrl"
          class="st-finish-profile__image-preview"
          :src="user.avatarUrl"
          :filename="fileName(user.avatarUrl)"
          :hide-bar="true"
          @remove="user.avatarUrl = null"
        />
        <st-hyperlink
          v-else
          class="st-finish-profile__upload"
          icon-class="st-hyperlink__default-icon-spacing"
          icon-name="upload"
          text="Upload Profile Photo"
          @click="
            openModal('user[avatar]', 'Profile Photo', `/users/${userId}`)
          "
        />
      </template>
    </div>
    <div class="tw-w-full tw-my-10 tw-space-y-2">
      <div class="st-finish-profile__screens-text tw-flex tw-items-center tw-space-x-2">
        <span
          v-if="activePageIndex > 0"
          class="tw-text-2xl tw-gray-500 hover:tw-text-gray-700 tw-cursor-pointer"
          @click="activePageIndex = 0"
        ><</span>
        <div>Steps {{ activePageIndex + 1 }} / 2</div>
      </div>
      <div class="tw-flex  tw-space-x-3">
        <span
          class="tw-w-1/4 tw-h-2 tw-rounded-lg "
          :class="{ 'tw-bg-gray-200': activePageIndex == 1,
                    'tw-bg-primary': activePageIndex == 0
          }"
        />
        <span
          class="tw-w-1/4 tw-h-2 tw-rounded-lg  "
          :class="{ 'tw-bg-gray-200': activePageIndex == 0,
                    'tw-bg-primary': activePageIndex == 1
          }"
        />
      </div>
    </div>
    <div class="st-finish-profile__button-row tw-mt-10 tw-flex tw-justify-end">
      <st-button
        class="st-finish-profile__continue-button !tw-rounded-xl tw-px-4"
        caption="Continue"
        icon-name="arrow"
        @click="handleContinue"
      />
      <!-- :disabled="!user.companyName" -->
    </div>
    <st-media-uploader
      v-if="modalVisible"
      :aspect-ratio="modalFileName === 'account[cover_image]' ? 4.23 : 1.0"
      :folders="folders"
      :modal-title="`Upload ${uploadModalType}`"
      :prop-name="modalFileName"
      :url="url"
      @closeMediaUploader="modalVisible = false"
      @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
// import { mapState } from "vuex";
import {
  COMPANY_SIZE_CATEGORIES,
  INDUSTRY_CATEGORIES
} from '../../../utilities/enums'

import {
  IconWrapper,
  StButton,
  StDropdownMenu,
  StHyperlink,
  StInput,
  StMediaUploader,
  StUploadPreview,
  StToastNotification
} from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StDropdownMenu,
    StHyperlink,
    StInput,
    StMediaUploader,
    StUploadPreview,
    StToastNotification
  },
  props: {
    departments: {
      type: Array,
      required: true
    },
    folders: {
      type: Array,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    }
  },
  data: function () {
    let sizeCategory = null
    if (this.userInfo.companySizeCategory) {
      sizeCategory = this.userInfo.companySizeCategory
        .substring(5)
        .replace('_', ' - ')
        .replace(' - plus', ' +')
    }
    let industry = null
    if (this.userInfo.industry) {
      industry = this.userInfo.industry
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(' ')
    }
    return {
      activePageIndex: 0,
      companySizeCategoryList: COMPANY_SIZE_CATEGORIES,
      departmentsArray: this.departments.map((department) => {
        return Object.assign(
          {
            selected: this.userInfo.departments
              .map((x) => x.id)
              .includes(department.id)
          },
          department
        )
      }),
      industryList: INDUSTRY_CATEGORIES,
      initialActiveSize: sizeCategory,
      initialActiveIndustry: industry,
      modalFile: null,
      modalFileName: '',
      modalVisible: false,
      uploadModalType: '',
      url: '',
      user: this.userInfo,
      showToast: true,
      showError: false
    }
  },
  computed: {
    // ...mapState(["currentUser"]),
    selectedDepartmentIds () {
      return this.user.departments.map((x) => x.id)
    },
    skipDisabled () {
      // return true
      console.log(this.user.companyName)
      return !this.user.companyName
    }
    // showEmailVerifiedToast() {
    //   return this.currentUser.verified;
    // },
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    fileName (srcUrl) {
      return srcUrl
        .split('/')
        .slice(-1)
        .pop()
    },
    handleContinue () {
      if (this.activePageIndex === 0) {
        let url = `/accounts/${this.userInfo.accountId}`
        let data = {
          name: this.user.companyName,
          website: this.user.website,
          location_name: this.user.locationName,
          company_size_category: this.user.companySizeCategory,
          industry: this.user.industry
        }
        this.$axios
          .request({
            url: url,
            method: 'patch',
            data: {
              id: this.userInfo.accountId,
              account: data
            }
          })
          .then((res) => {
            this.activePageIndex++
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        let url = `/users/${this.userId}`
        let data = {
          department_ids: this.selectedDepartmentIds
        }
        this.$axios
          .request({
            url: url,
            method: 'patch',
            data: {
              id: this.userId,
              user: data
            }
          })
          .then((res) => {
            window.location.href = '/welcome'
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    handleSkip () {
      // window.location.href = "/welcome";
      if (!this.skipDisabled) {
        console.log('not disabled', this.user.companyName, this.userInfo.companyName)
        // not sure why this is here
        // if (this.user.companyName !== this.userInfo.companyName) {
        console.log('dsfdsf')
        let url = `/accounts/${this.userInfo.accountId}`
        let data = {
          name: this.user.companyName
        }
        this.$axios.request({
          url: url,
          method: 'patch',
          data: {
            id: this.userInfo.accountId,
            account: data
          }
        })
          .then(res => { window.location.href = '/welcome' })
          .catch(err => { console.log(err) })
        // }
      } else {
        this.showError = true
        this.activePageIndex = 0
        setTimeout(() => {
          this.showError = false
        }, 3000)
      }
    },
    openModal (fileName, modalType, uploadUrl) {
      this.modalFileName = fileName
      this.modalVisible = true
      this.uploadModalType = modalType
      this.url = uploadUrl
    },
    setDepartmentsOptions (options) {
      this.user.departments = options
    },
    setIndustryOption (option) {
      this.user.industry = option.toLowerCase().replace(' ', '_')
    },
    setSizeOption (option) {
      this.user.companySizeCategory =
        'size_' + option.replace(' - ', '_').replace(' +', '_plus')
    },
    uploadComplete (payload) {
      if (this.modalFileName === 'account[logo]') {
        this.user.companyLogoUrl = payload.logoUrl
      } else if (this.modalFileName === 'account[cover_image]') {
        this.user.companyCoverImageUrl = payload.coverImageUrl
      } else {
        this.user.avatarUrl = payload.avatarUrl
      }
      this.modalVisible = false
    }
  }
}
</script>
