<template>
  <span
    class="tw-px-3 tw-py-2  tw-cursor-pointer tw-w-fit tw-bg-white tw-border-2 tw-text-sm tw-rounded-lg tw-border-solid"
    :class="selected? 'tw-border-black tw-text-black': 'tw-border-white-two tw-text-black-three'"
    @click="$emit('click')"
  >
    <slot />
  </span>
</template>

<script>

export default {
  components: {
  },
  props: {

    selected: {
      type: Boolean,
      required: false,
      default: false
    }

  },
  data: function () {
    return {

    }
  },
  computed: {
  }
}
</script>
