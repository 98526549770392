import moment from 'moment'
import { bucketCounts } from './data_utilities'

function uniqueKeyForPair (response) {
  return `${response.surveyResponseId}-${response.questionOptionId}`
}

function translateContext (context) {
  switch (context) {
    case 'x_axis':
      return 'xValue'
    case 'y_axis':
      return 'yValue'
    default:
      return 'invalid context'
  }
}

function findOrInitializeDot (dotsById, resp) {
  let dot
  let key = uniqueKeyForPair(resp)
  if (dotsById.hasOwnProperty(key)) {
    dot = dotsById[key]
  } else {
    dot = {
      optionId: resp.questionOptionId,
      key: key
    }
    dotsById[key] = dot
  }
  return dot
}

export function sortIntoXYPairs (responses) {
  // initialize hash -> id: {id: ##, x: 123, y: 123}
  let dotsById = {}
  responses.forEach((resp) => {
    let dot = findOrInitializeDot(dotsById, resp)
    let prop = translateContext(resp.context)
    dot[prop] = resp.value
  })
  let allDots = Object.values(dotsById)
  return allDots
}

function generateWeeksByDateRange (start, end) {
  const weeks = []
  const startDate = moment(start).startOf('week')
  const endDate = moment(end).endOf('week')

  while (startDate < endDate) {
    weeks.push({ start: moment(startDate), end: moment(startDate.endOf('week')) })
    startDate.add(1, 'day')
  }
  return weeks
}

function generateMonthsByDateRange (start, end) {
  const months = []
  const startDate = moment(start).startOf('month')
  const finishDate = moment(end).endOf('month')

  while (startDate < finishDate) {
    months.push({ start: moment(startDate), end: moment(startDate.endOf('month')) })
    startDate.add(1, 'day')
  }
  return months
}

function generateDaysOfWeek (date) {
  const days = []
  const startDate = moment(date).startOf('week')
  for (let i = 0; i < 7; i++) {
    days.push({ start: moment(startDate).startOf('day'), end: moment(startDate.endOf('day')) })
    startDate.add(1, 'day')
  }
  return days
}

function sortByCreatedAtAsc (responses) {
  return responses.map(({ createdAt, ...rest }) => ({ createdAt: new Date(createdAt), ...rest })).sort((a, b) => a.createdAt > b.createdAt)
}

function filterByRange (start, end, responses) {
  // console.log('start date ', start)
  // console.log('end date ', end)
  const filteredResponses = responses.filter(({ createdAt }) => createdAt >= start && createdAt < end)
  const scores = bucketCounts(filteredResponses.map(({ sentimentScore }) => sentimentScore))
  return {
    start,
    end,
    responses: filteredResponses,
    scores
  }
}

export function distributeInWeeks (responses) {
  responses = sortByCreatedAtAsc(responses)
  const weeks = generateWeeksByDateRange(responses[0]?.createdAt, responses[responses.length - 1]?.createdAt)
  const weeklyResponses = weeks.map(({ start, end }) => filterByRange(start, end, responses)).filter((response)=>{
    return response.responses.length
  })
  return weeklyResponses
}

export function distributeInMonths (responses) {
  responses = sortByCreatedAtAsc(responses)
  const months = generateMonthsByDateRange(responses[0]?.createdAt, responses[responses.length - 1]?.createdAt)
  const monthlyResponses = months.map(({ start, end }) => filterByRange(start, end, responses)).filter((response)=>{
    return response.responses.length
  })
  return monthlyResponses
}
export function noFilterDistribution (responses) {
  responses =  sortByCreatedAtAsc(responses)
  const months = generateMonthsByDateRange(responses[0]?.createdAt, responses[responses.length - 1]?.createdAt)
  const monthlyResponses = months.map(({ start, end }) => filterByRange(start, end, responses)).filter((response)=>{
    return response.responses.length
  })
  return monthlyResponses
}

export function distributeInWeekDays (responses) {
  responses = sortByCreatedAtAsc(responses)
  const days = generateDaysOfWeek(responses[0].createdAt)
  const dailyResponses = days.map(({ start, end }) => filterByRange(start, end, responses))
  return dailyResponses
}
