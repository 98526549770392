<template>
  <div>
    <st-cropper
      v-if="cropperOpen"
      class="st-media-uploader__cropper"
      :aspect-ratio="aspectRatio"
      :button-values="{
        caption:'Crop',
        iconName: 'arrow'
      }"
      :modal-title="cropperTitle"
      :src="mediaToUpload[0].previewUrl"
      :show-cropper="cropperOpen"
      @closeCropper="onCropperClose"
      @upload="onCropperUpload"
    />
    <st-modal
      v-else
      :hide-close="!isDesktop"
      :show-modal="true"
      size-class="st-modal--large st-modal__upload-modal"
      @closeModal="closeMediaUploader"
    >
      <template slot="modalTitle">
        <div class="st-media-uploader__mobile-bar" />
        <p class="st-media-uploader__title">
          {{ modalTitle }}
        </p>
      </template>
      <template slot="modalActions">
        <div class="st-media-uploader__wrap-actions">
          <div class="action-toggle">
            <div
              :class="{
                'action-toggle__button': true,
                'action-toggle__button--active': modalActionStage === 'upload'
              }"
              @click="updateModalActionStage('upload')"
            >
              Upload
            </div>
            <div
              :class="{
                'action-toggle__button': true,
                'action-toggle__button--active': modalActionStage === 'researcher'
              }"
              @click="updateModalActionStage('researcher')"
            >
              Library
            </div>
            <!-- <div
              :class="{
                'action-toggle__button': true,
                'action-toggle__button--active': modalActionStage === 'spacetrics'
              }"
              @click="updateModalActionStage('spacetrics')"
            >
              <icon-wrapper
                icon-name="spacetrics"
                :actionable="false"
              />
              <p class="action-toggle__spacetrics-library-text">
                Library
              </p>
            </div> -->
          </div>
          <div
            v-if="modalActionStage === 'upload'"
            :class="{ 'st-media-uploader__upload-section': skipCropper }"
          >
            <st-dropzone
              v-if="mediaToUpload.length === 0 || multiUpload"
              :active-question="this.activeQuestion"
              :class="{
                'st-media-uploader__dropzone-empty': mediaToUpload.length === 0,
                'st-media-uploader__dropzone-with-files': mediaToUpload.length > 0
              }"
              :accept="accept"
              name="asset[media]"
              :multiple="multiUpload"
              @input="onDropzone"
            />
            <div
              v-if="mediaToUpload.length>0"
              :class="{ 'st-media-uploader__uploader-previews': multiUpload }"
            >
              <st-upload-preview
                v-for="(media, index) in mediaToUpload"
                :key="'media ' + index"
                class="st-media-library__upload-preview"
                :display-large-preview="aspectRatio === 4.23"
                :file="media.file"
                :filename="media.name"
                :src="media.previewUrl"
                :upload-percent="media.uploadPercent"
                :variant="['modal']"
                @remove="removePreview(media)"
              />
            </div>
          </div>
          <div
            v-if="modalActionStage === 'researcher' && libraries !== null"
            class="st-media-uploader__library-container"
          >
            <div class="st-media-uploader__library-scroll-area">
              <st-accordion v-if="filteredRecentAssets.length > 0">
                <template v-slot:visible-section>
                  <div class="st-media-library__accordion-row">
                    <icon-wrapper
                      icon-name="clock"
                      class="st-accordion__section-icon"
                    />
                    <div class="st-accordion__group-name">
                      Recently Uploaded
                    </div>
                  </div>
                </template>
                <template v-slot:expanded-section>
                  <div class="st-media-library__group-expanded-section">
                    <div
                      v-for="(asset) in filteredRecentAssets"
                      :key="'recent-asset-'+ asset.id"
                    >
                      <st-card
                        :class="{
                          'st-media-library__asset-card st-media-uploader__asset-card': true,
                          'st-media-uploader__asset-card--selected': selectedLibraryAssets.includes(asset.id)
                        }"
                        @click="selectLibraryMedia(asset)"
                      >
                        <template v-slot:card-content="slotProps">
                          <video
                            v-if="asset.mediaUrl.includes('mp4')"
                            class="st-media-library__asset-card-preview"
                          >
                            <source
                              :src="asset.mediaUrl"
                              type="video/mp4"
                            > Your browser does not support the video tag.
                          </video>
                          <div
                            v-else
                            class="st-media-library__asset-card-preview"
                            :style="{
                              background: `url('${asset.mediaUrl}') center / contain no-repeat`
                            }"
                          />
                          <div
                            :class="{
                              'st-media-library__asset-name st-media-library__asset-name--uploader': true,
                              'st-media-library__asset-name--hover': slotProps.hover
                            }"
                          >
                            {{ asset.filename }}
                          </div>
                          <div class="st-media-library__asset-card-bottom-row">
                            <div class="st-media-library__date-row">
                              <icon-wrapper
                                icon-name="calendar"
                                class="st-media-library__calendar-icon"
                              />
                              <div>{{ formatDate(asset.updatedAt) }}</div>
                            </div>
                          </div>
                        </template>
                      </st-card>
                    </div>
                  </div>
                </template>
              </st-accordion>
              <st-accordion
                v-for="(accountFolder) in filteredAccountFolders"
                :key="'account-folder'+ accountFolder.id"
              >
                <template v-slot:visible-section>
                  <div class="st-media-library__accordion-row">
                    <icon-wrapper
                      icon-name="file"
                      class="st-accordion__section-icon"
                    />
                    <div class="st-accordion__group-name">
                      {{ accountFolder.name }}
                    </div>
                  </div>
                </template>
                <template v-slot:expanded-section>
                  <div class="st-media-library__group-expanded-section">
                    <div
                      v-for="(asset) in accountFolder.assets"
                      :key="'account-asset-'+ asset.id"
                    >
                      <st-card
                        :class="{
                          'st-media-library__asset-card st-media-uploader__asset-card': true,
                          'st-media-uploader__asset-card--selected': selectedLibraryAssets.includes(asset.id)
                        }"
                        @click="selectLibraryMedia(asset)"
                      >
                        <template v-slot:card-content="slotProps">
                          <video
                            v-if="asset.mediaUrl.includes('mp4')"
                            class="st-media-library__asset-card-preview"
                          >
                            <source
                              :src="asset.mediaUrl"
                              type="video/mp4"
                            > Your browser does not support the video tag.
                          </video>
                          <div
                            v-else
                            class="st-media-library__asset-card-preview"
                            :style="{
                              background: `url('${asset.mediaUrl}') center / contain no-repeat`
                            }"
                          />
                          <div
                            :class="{
                              'st-media-library__asset-name st-media-library__asset-name--uploader': true,
                              'st-media-library__asset-name--hover': slotProps.hover
                            }"
                          >
                            {{ asset.filename }}
                          </div>
                          <div class="st-media-library__asset-card-bottom-row">
                            <div class="st-media-library__date-row">
                              <icon-wrapper
                                icon-name="calendar"
                                class="st-media-library__calendar-icon"
                              />
                              <div>{{ formatDate(asset.updatedAt) }}</div>
                            </div>
                          </div>
                        </template>
                      </st-card>
                    </div>
                  </div>
                </template>
              </st-accordion>
            </div>
          </div>
          <div
            v-if="modalActionStage === 'spacetrics' && libraries !== null"
            class="st-media-uploader__library-container"
          >
            <div class="st-media-uploader__library-scroll-area">
              <st-accordion
                v-for="(spacetricsFolder) in filteredSpacetricsFolders"
                :key="'spacetrics-folder'+ spacetricsFolder.id"
              >
                <template v-slot:visible-section>
                  <div class="st-media-library__accordion-row">
                    <icon-wrapper
                      icon-name="file"
                      class="st-accordion__section-icon"
                    />
                    <div class="st-accordion__group-name">
                      {{ spacetricsFolder.name }}
                    </div>
                  </div>
                </template>
                <template v-slot:expanded-section>
                  <div class="st-media-library__group-expanded-section">
                    <div
                      v-for="(asset) in spacetricsFolder.assets"
                      :key="'account-asset-'+ asset.id"
                    >
                      <st-card
                        :class="{
                          'st-media-library__asset-card st-media-uploader__asset-card': true,
                          'st-media-uploader__asset-card--selected': selectedLibraryAssets.includes(asset.id)
                        }"
                        @click="selectLibraryMedia(asset)"
                      >
                        <template v-slot:card-content="slotProps">
                          <video
                            v-if="asset.mediaUrl.includes('mp4')"
                            class="st-media-library__asset-card-preview"
                          >
                            <source
                              :src="asset.mediaUrl"
                              type="video/mp4"
                            > Your browser does not support the video tag.
                          </video>
                          <div
                            v-else
                            class="st-media-library__asset-card-preview"
                            :style="{
                              background: `url('${asset.mediaUrl}') center / contain no-repeat`
                            }"
                          />
                          <div
                            :class="{
                              'st-media-library__asset-name st-media-library__asset-name--uploader': true,
                              'st-media-library__asset-name--hover': slotProps.hover
                            }"
                          >
                            {{ asset.filename }}
                          </div>
                          <div class="st-media-library__asset-card-bottom-row">
                            <div class="st-media-library__date-row">
                              <icon-wrapper
                                icon-name="calendar"
                                class="st-media-library__calendar-icon"
                              />
                              <div>{{ formatDate(asset.updatedAt) }}</div>
                            </div>
                          </div>
                        </template>
                      </st-card>
                    </div>
                  </div>
                </template>
              </st-accordion>
            </div>
          </div>
          <div
            v-if="modalActionStage === 'upload'"
            class="st-media-uploader__auto-complete-input"
          >
            <st-autocomplete
              :initial-matchable-list="folders"
              :initial-value="folder"
              :caption-fn="(folder) => folder.name"
              create-caption="a New Folder"
              :create-fn="createNewFolder"
              label="Select folder or create one (Optional)"
              option-icon-name="file"
              :placeholder="folders.length > 0 ? '' : 'There are currently no folders create one to get started'"
              toast-caption="Folder Created"
              :variant="['separateCreate']"
              @failure="handleAutocompleteFailure"
              @selectOption="(val) => {folder = val}"
              @success="handleAutocompleteSuccess"
              @unselectOption="folder = null"
            />
          </div>
        </div>
      </template>
      <template slot="modalButton">
        <div class="st-media-uploader__upload-button-container">
          <st-button
            :key="uploadButtonDisabled"
            :caption="uploadCaption"
            class="st-media-uploader__upload-button"
            :icon-name="uploadIcon"
            :disabled="uploadButtonDisabled"
            :show-action="true"
            @click="uploadMedia"
          />
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapState, mapMutations } from 'vuex'
import { DirectUpload } from '@rails/activestorage'
import { isDesktop } from '../../mixins'
import { buildNewFilePreview } from '../../utilities/preview_file'
import IconWrapper from './icon-wrapper'
import StAccordion from './st-accordion'
import StAutocomplete from './st-autocomplete.vue'
import StButton from './st-button'
import StCard from './st-card'
import StCropper from './st-cropper'
import StDropzone from './st-dropzone'
import StModal from './st-modal'
import StUploadPreview from './st-upload-preview'

class UploadEntry {
  constructor () {
    this.assetId = null
    this.file = null
    this.folderId = null
    this.isAsset = false
    this._originalFile = null
    this._previewUrl = ''
    this.uploadPercent = 0
  }

  static createFromAsset (asset) {
    let uploadEntry = new UploadEntry()
    uploadEntry.isAsset = true
    uploadEntry.folderId = asset.folderId
    uploadEntry._previewUrl = asset.mediaUrl
    uploadEntry.assetId = asset.id
    return this.getLibraryBlob(asset)
      .then((res) => {
        uploadEntry.file = new File([res.data], asset.filename, { type: asset.contentType })
        return uploadEntry
      })
  }

  static createFromInput (file) {
    let uploadEntry = new UploadEntry()
    uploadEntry.file = file
    return uploadEntry
  }

  static getLibraryBlob (asset) {
    return axios.request({
      url: `${asset.mediaUrl}`,
      method: 'get',
      responseType: 'blob',
      baseURL: null
    })
  }

  buildPreviewUrl () {
    if (this._previewUrl) { return Promise.resolve(this._previewUrl) }
    return buildNewFilePreview(this.file)
      .then(previewFile => {
        this._previewUrl = previewFile.src
        return this._previewUrl
      })
  }

  get previewUrl () {
    return this._previewUrl
  }

  get originalFile () {
    if (this._originalFile) { return this._originalFile }
    return this.file
  }

  get name () {
    return this.file.name
  }

  updateFile (newBlob) {
    this._originalFile = this.file
    this.file = new File([newBlob], this.file.name, { type: this.file.type })
  }
}

export default {
  components: {
    IconWrapper,
    StAccordion,
    StAutocomplete,
    StButton,
    StCard,
    StCropper,
    StDropzone,
    StModal,
    StUploadPreview
  },
  mixins: [isDesktop],
  props: {

    // active question just to pass to drp-zone
    activeQuestion: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    /** Type of media available for upload (passed to st-dropzone). */
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png',
      required: false
    },
    /** Proportion of image being uploaded */
    aspectRatio: {
      type: Number,
      required: false,
      default: 1.6
    },
    /** Main action of cropper(passed to cropper) */
    cropperTitle: {
      type: String,
      required: false,
      default: 'Crop Image'
    },
    /** Various media files where the image the user selects to be uploaded could be added to */
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    /** Main action of image uploader modal, displayed at the top. */
    modalTitle: {
      type: String,
      required: false,
      default: 'Upload Media'
    },
    /** Determines if the media-uploader can upload more than 1 media at a time */
    multiUpload: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Name of media uploaded to server */
    propName: {
      type: String,
      required: false,
      default: ''
    },
    /** Used to dynamically change formData when updating a record */
    additionalProps: {
      type: Function,
      required: false,
      default: () => ({})
    },
    /** Used to upload an image without using st-cropper */
    skipCropper: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Path from parent that the media it sent to via 'patch' */
    url: {
      type: String,
      required: false,
      default: ''
    },
    requestType: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      acceptedArray: this.accept.split(',').map(value => value.split(' ').join('').slice(1)),
      cropperOpen: false,
      folder: null,
      libraries: null,
      mediaToUpload: [],
      modalActionStage: 'upload',
      selectedLibraryAssets: [],
      showToast: false
    }
  },
  computed: {
    ...mapState(['directUploadUrl']),
    filteredAccountFolders () {
      return this.libraries.accountFolders.map(folder => {
        return { ...folder, assets: this.filterByAcceptedTypes(folder.assets) }
      })
    },
    filteredRecentAssets () {
      return this.filterByAcceptedTypes(this.libraries.recentAccountAssets)
    },
    filteredSpacetricsFolders () {
      return this.libraries.spacetricsOwnedFolders.map(folder => {
        return { ...folder, assets: this.filterByAcceptedTypes(folder.assets) }
      })
    },
    isSingleUpload () {
      return !this.multiUpload
    },
    isUploadStage () {
      return this.modalActionStage === 'upload'
    },
    uploadButtonDisabled () {
      if (this.mediaToUpload.length === 0) { return true }
      return this.mediaToUpload.some(entry => entry.uploadPercent < 100)
    },
    uploadCaption () {
      if (this.isSingleUpload && this.mediaToUpload.length) { return 'Save & Continue' }
      if (this.multiUpload && this.mediaToUpload.length > 1) { return `Upload [${this.mediaToUpload.length}] Files` }
      if (this.multiUpload && this.mediaToUpload.length === 1) { return `Upload [${this.mediaToUpload.length}] File` }
      if (this.isSingleUpload) { return 'Upload File' }
      if (this.multiUpload) { return 'Upload Files' }
      return ''
    },
    uploadIcon () {
      return this.isSingleUpload && this.mediaToUpload.length ? 'save' : 'upload'
    }
  },
  methods: {
    ...mapMutations(['updateToastOptions']),
    updateModalActionStage (stage) {
      stage !== 'upload' && this.libraries === null ? this.getLibraryMedia(stage) : this.modalActionStage = stage
    },
    createNewFolder (newName) {
      return this.$axios.request({
        method: 'post',
        url: 'folders',
        data: { name: newName }
      })
        .then((res) => { return res.data })
    },
    closeMediaUploader () {
      this.mediaToUpload = []
      this.$emit('closeMediaUploader')
    },
    fakeUpload (entry) {
      entry.uploadPercent = 0
      let fakeUploadDelay = Math.floor(Math.random() * (500 - 200) + 200)
      for (let i = 1; i <= 100; i++) {
        setTimeout(() => {
          entry.uploadPercent++
        }, fakeUploadDelay)
      }
      return entry
    },
    filterByAcceptedTypes (array) {
      return array.map(asset => {
        let assetType = asset.mediaUrl.split('.').slice(-1).pop()
        if (this.acceptedArray.includes(assetType)) { return asset }
      }).filter(asset => { return asset !== undefined })
    },
    findMimeType (file) {
      return file.type.split('/')[0]
    },
    formatDate (date) {
      let dateMoment = moment(date)
      return dateMoment.format('MM/DD/YYYY')
    },
    handleAutocompleteFailure (reason = '') {
      let toastCaption
      if (reason === 'duplicate') {
        toastCaption = 'This folders already exists, and has been selected'
        this.updateToastOptions({ caption: toastCaption, kind: 'info', title: 'Info' })
      } else {
        toastCaption = 'Folders without a name cannot be created'
        this.updateToastOptions({ caption: toastCaption, kind: 'error', title: 'Error' })
      }
    },
    handleAutocompleteSuccess () {
      let toastCaption
      toastCaption = 'Folder has been created, see more detail of this folder in the Media Library'
      this.updateToastOptions({ caption: toastCaption, kind: 'info', title: 'Info' })
    },
    removePreview (media) {
      this.selectedLibraryAssets.splice(this.selectedLibraryAssets.indexOf(media.id), 1)
      this.mediaToUpload.splice(this.mediaToUpload.indexOf(media), 1)
    },
    onCropperClose () {
      this.selectedLibraryAssets = []
      this.mediaToUpload = []
      this.cropperOpen = false
    },
    onCropperUpload (blob) {
      let newEntry = this.mediaToUpload[0]
      newEntry.updateFile(blob)
      this.fakeUpload(newEntry)
      this.$set(this.mediaToUpload, 0, newEntry)
      this.cropperOpen = false
      this.modalActionStage = 'upload'
    },
    onDropzone (files) {
      if (!this.skipCropper && this.isSingleUpload) {
        let uploadEntry = this.buildUploadEntry(files[0])
        this.mediaToUpload.push(uploadEntry)
        uploadEntry.buildPreviewUrl()
          .then(() => {
            this.cropperOpen = true
          })
      } else {
        for (let i = 0; i < files.length; i++) {
          let uploadEntry = this.buildUploadEntry(files[i])
          this.fakeUpload(uploadEntry)
          this.mediaToUpload.push(uploadEntry)
        }
      }
    },
    buildUploadEntry (file) {
      return UploadEntry.createFromInput(file)
    },
    selectLibraryMedia (asset) {
      if (this.mediaToUpload.length > 0 && this.isSingleUpload) { this.mediaToUpload = [] }
      if (asset.folderId) { this.folder = this.folders.find(folder => { return folder.id === asset.folderId }) }

      return UploadEntry.createFromAsset(asset)
        .then(uploadEntry => {
          this.fakeUpload(uploadEntry)
          this.selectedLibraryAssets.push(asset.id)
          this.mediaToUpload.push(uploadEntry)
          this.skipCropper ? this.modalActionStage = 'upload' : this.cropperOpen = true
        })
    },
    getLibraryMedia (stage) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        method: 'get',
        url: '/assets/library'
      })
        .then(res => {
          this.libraries = res.data
          this.modalActionStage = stage
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(err => {
          console.error(err)
          this.$store.commit('toggleFullSpinner', false)
        })
    },
    isSelectedFromLibrary (entry) {
      if (this.folder) {
        return (entry.folderId && (entry.folderId !== this.folder.id)) || entry.folderId === null
      }
    },
    duplicateSelectedAssetToNewFolder (entry) {
      this.$axios.request({
        method: 'post',
        url: `/assets/${entry.assetId}/duplicate`,
        data: {
          id: entry.assetId,
          name: `${entry.name}`,
          folder_id: this.folder.id
        }
      })
    },
    assetPromise (entry) {
      let assetsUrl = `/assets`
      let assetData = new FormData()
      return this.buildDirectUpload(entry.originalFile, 'media')
        .then(signedId => {
          assetData.append('asset[media]', signedId)
          if (this.folder) { assetData.append('asset[folder_id]', this.folder.id) }
          return this.$axios.request({
            method: 'post',
            url: assetsUrl,
            data: assetData
          })
        })
        .catch(err => { console.log('err', err) })
    },
    updateRecordPromise (entry) {
      let method = ''
      if (this.requestType) { method = this.requestType } else { method = this.propName.includes('question_option') ? 'post' : 'patch' }
      if (this.isSelectedFromLibrary(entry)) { this.duplicateSelectedAssetToNewFolder(entry) }
      return this.buildRecordData(entry)
        .then(recordData => {
          return this.$axios.request({
            method: method,
            url: this.url,
            data: recordData
          })
        })
        .catch(err => { console.log('err', err) })
    },
    buildRecordData (entry) {
      console.log('entry', entry)
      let updateRecordData = new FormData()
      if (this.multiUpload || this.activeQuestion.questionType == 'culture_board') {
        Object.entries(this.additionalProps()).map(([key, value]) => {
          let additionalValue = typeof value === 'function' ? value(entry) : value
          updateRecordData.append(key, additionalValue)
        })
      }

      return this.buildDirectUpload(entry.file, this.propName)
        .then(signedId => {
          updateRecordData.append(this.propName, signedId)
          return updateRecordData
        })
        .catch(err => { console.log(err) })
    },
    buildDirectUpload (uploadFile, propName) {
      return new Promise((resolve, reject) => {
        const upload = new DirectUpload(uploadFile, this.directUploadUrl)
        upload.create((error, blob) => {
          error ? reject(error) : resolve(blob.signed_id)
        })
      })
    },
    uploadFailed (err) {
      this.$emit('uploadError', err)
    },
    uploadMedia () {
      this.$store.commit('toggleFullSpinner', true)
      let uploadEntryPromises = this.mediaToUpload.map(entry => { return this.uploadEntry(entry) })
      Promise.all([...uploadEntryPromises]).then(res => {
        this.$store.commit('toggleFullSpinner', false)
        this.multiUpload
          ? this.$emit('uploadComplete', res)
          : this.$emit('uploadComplete', res[0])
      })
    },
    uploadEntry (entry) {
      this.$store.commit('toggleFullSpinner', true)
      if (entry.isAsset) {
        if (this.url && this.propName) {
          return this.updateRecordPromise(entry).then(res => {
            return res.data
          })
        } else {
          return entry.file
        }
      } else {
        if (this.url && this.propName) {
          return Promise.all([this.assetPromise(entry), this.updateRecordPromise(entry)]).then(res => {
            return res[1].data
          }).catch(err => { this.uploadFailed(err) })
        } else {
          return this.assetPromise(entry).then(() => {
            return entry.file
          }).catch(err => { this.uploadFailed(err) })
        }
      }
    }
  }
}
</script>
