<template>
  <div>
    <div
      class="tw-flex tw-justify-between  tw-px-8 tw-items-center tw-py-3 tw-border-y tw-border-solid tw-border-white-two"
    >
      <div class=" tw-py-2 tw-flex tw-gap-3 tw-text-sm">
        <span class="tw-text-lg tw-font-semibold"> {{ strategy.surveys.length }} studies </span>
      </div>
      <div class="tw-flex tw-space-x-2">
        <ah-button
          type="outlined"
          @click="sharingHandler"
        >
          Share
        </ah-button>

        <div
          class="flex tw-cursor-pointer relative"
          @click.stop="toggleDropdownOptions"
        >
          <ah-button type="outlined">
            <span class="tw-space-x-0.5 tw-min-w-fit">
              <span>
                {{ selectedDropdownOption }}
              </span>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_393_1239)">
                  <path
                    d="M5.01197 5.50036C4.83774 5.50036 4.66651 5.43667 4.54035 5.32086L0.698222 1.60922C0.43387 1.35734 0.43387 0.946223 0.695218 0.691446C0.956567 0.436669 1.38314 0.436669 1.64749 0.688551L5.01498 3.94564L8.37946 0.688551C8.65283 0.44825 9.07639 0.468516 9.32573 0.731979C9.55703 0.97807 9.55703 1.35445 9.32573 1.60054L5.4836 5.32086C5.35743 5.43667 5.18621 5.50326 5.01197 5.50036Z"
                    fill="#474747"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_393_1239">
                    <rect
                      width="5"
                      height="9"
                      fill="white"
                      transform="translate(9.5 0.5) rotate(90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </ah-button>
          <div
            v-if="showDropdown"
            class="flex flex-col ml-10p justify-center !tw-text-xs !tw-w-24 !tw-top-10 cursor-pointer tw-overflow-hidden absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
          >
            <span
              class=""
              @click="changeSelectedDropdownOption($event, selectedDropdownOption == 'Feedbacks' ? 'Sources' : 'Feedbacks')"
            >
              {{ selectedDropdownOption == 'Feedbacks' ? 'Sources' : 'Feedbacks' }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-py-3"
    >
      <div
        v-for="study in strategy.surveys"
        :key="study.name"
        class="tw-px-8 tw-flex tw-justify-between tw-items-center tw-border-b tw-border-white-two tw-border-solid  tw-py-2"
      >
        <p class="tw-flex tw-flex-col ">
          <span class=" tw-text-base">
            {{ study.name }}
          </span>
          <span class="tw-text-sm tw-mt-2 tw-space-x-1">
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.50558 6.50013C7.15973 6.50013 8.45965 5.18022 8.45965 3.50006C8.45965 1.8199 7.15999 0.5 5.50558 0.5C3.85144 0.5 2.55151 1.82016 2.55151 3.50006C2.55151 5.17997 3.85144 6.50013 5.50558 6.50013ZM11 11.78C10.232 8.72003 7.15973 6.79995 4.14667 7.58012C2.13804 8.12012 0.542885 9.68019 0.0111674 11.78C-0.0478279 12.0801 0.129411 12.4401 0.48389 12.5H10.4093C10.7638 12.5 11 12.2601 11 11.9001V11.78Z"
                fill="#A7AA93"
              />
            </svg>
            <span class="">
              {{ study.totalResponsesCompleted }}
            </span>

          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AhButton
} from '../../common'
export default {
  components: {
    AhButton
  },
  props: {
    strategy: {
      type: Object,
      default: () => {}
    },
    closeHandler: {
      type: Function,
      default: () => {}
    },
    sharingHandler: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      selectedDropdownOption: 'Sources',
      showDropdown: false
    }
  },

  mounted () {

  },

  methods: {
    changeSelectedDropdownOption (e, option) {
      e.stopPropagation()
      this.selectedDropdownOption = option
      this.showDropdown = false
      const tabName = option == 'Feedbacks' ? 'feedbacks' : 'studies'
      this.$emit('changeTab', tabName)
    },

    toggleDropdownOptions () {
      this.showDropdown = !this.showDropdown
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
