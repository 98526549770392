<template>
  <div>
    <template
      v-for="(item, index) in itemsArray"
    >
      <template v-if="Object.keys(getChanges(keyName, index, versionToCompare, item)).includes('same')">
        <li
          :key="`item_${item}_${index}_default`"
          class="tw-py-1  tw-text-black-one"
        >
          {{ getChanges(keyName, index, versionToCompare, item).same }}
        </li>
      </template>
      <template v-else>
        <li
          v-if="Object.keys(getChanges(keyName, index, versionToCompare, item)).includes('added')"
          :key="`item_${item}_${index}_added`"
          class="tw-py-1  tw-text-black-one tw-bg-green-100"
          title="Added"
        >
          {{ getChanges(keyName, index, versionToCompare, item).added }}
        </li>
        <li
          v-if="Object.keys(getChanges(keyName, index, versionToCompare, item)).includes('removed')"
          :key="`item_${item}_${index}_removed`"
          title="Removed"
          class="tw-py-1  tw-text-black-one tw-bg-red-100"
        >
          {{ getChanges(keyName, index, versionToCompare, item).removed }}
        </li>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AskhumansChangesListItem',
  props: {
    itemsArray: {
      type: Array,
      required: true,
      default: () => []
    },
    keyName: {
      type: String,
      required: true
    },
    versionToCompare: {
      type: String,
      required: true
    },
    firstVersion: {
      type: Object,
      required: true
    },
    secondVersion: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },

  mounted () {
    // console.log(this.firstVersion, this.secondVersion)
  },

  methods: {
    getChanges (category, index, compareVersion, item) {
      if (compareVersion == 'first') {
        if (this.firstVersion[category] && this.firstVersion[category][index] == item) {
          return {
            'same': item
          }
        } else if (this.firstVersion[category].includes(item)) {
          return {
            'same': item
          }
        } else {
          return {
            added: this.secondVersion[category][index]
          }
          // if (this.secondVersion[category].includes(this.firstVersion[category][index])) {
          //   return {
          //     added: this.secondVersion[category][index]

          //   }
          // } else {
          //   return {
          //     ...(this.firstVersion[category][index] && { removed: this.firstVersion[category][index] }),
          //     added: this.secondVersion[category][index]
          //   }
          // }
        }
      } else if (compareVersion == 'second') {
        if (this.secondVersion[category][index] == item) {
          return {
            'same': item
          }
        } else if (this.secondVersion[category].includes(item)) {
          return {
            'same': item
          }
        } else {
          return {
            removed: this.firstVersion[category][index]
          }
          // if (this.firstVersion[category].includes(this.secondVersion[category][index])) {
          //   return {
          //     added: this.firstVersion[category][index]
          //   }
          // } else {
          //   return {
          //     ...(this.secondVersion[category][index] && { removed: this.secondVersion[category][index] }),
          //     added: this.firstVersion[category][index]
          //   }
          // }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
