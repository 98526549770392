<template>
  <div class="icon_edit">
    <div class="icon_edit__svg">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_198_4775)">
          <path
            d="M11.379 0.620964C10.551 -0.206988 9.21357 -0.206988 8.38561 0.620964L8.13086 0.875719L11.1242 3.86908L11.379 3.61433C12.2069 2.80761 12.2069 1.44892 11.379 0.620964Z"
            fill="black"
          />
          <path
            d="M0.212295 8.77309C0.0849182 8.90047 0 9.09154 0 9.2826V11.2782C0 11.6815 0.318443 12 0.721804 12H2.71738C2.90845 12 3.09951 11.9151 3.22689 11.7877L10.2326 4.78194L7.21804 1.78857L0.212295 8.77309Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_198_4775">
            <rect
              width="12"
              height="12"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
