<template>
  <div class="icon_checkmark">
    <div class="icon_checkmark__svg">
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.72265 4.44094H8.90762C8.89428 4.29154 8.84653 4.14296 8.76163 4.00709C8.47326 3.54562 7.86528 3.40528 7.40392 3.69373C7.40388 3.69376 7.40384 3.69379 7.40379 3.69382L4.33291 5.61319L3.89311 4.95349C3.89307 4.95343 3.89303 4.95337 3.89299 4.95331C3.59127 4.50051 2.97938 4.3782 2.52669 4.68013C2.0742 4.98194 1.95157 5.59365 2.25355 6.0465C2.25357 6.04653 2.25358 6.04655 2.2536 6.04658L3.22421 7.50239C3.51984 7.94583 4.11445 8.07377 4.56629 7.79126L8.44848 5.36494L9.7489 4.5522C9.81664 4.85731 9.85237 5.17449 9.85237 5.50001C9.85237 7.90418 7.9036 9.85295 5.49943 9.85295C3.09525 9.85295 1.14648 7.90418 1.14648 5.50001C1.14648 3.09583 3.09525 1.14706 5.49943 1.14706C7.53832 1.14706 9.24968 2.54864 9.72265 4.44094Z"
          fill="white"
          stroke="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
