<template>
  <div class="st-body "
  :class="{'px-20': isMainPage}"
  >
    <st-spinner v-if="isLoading" />
    <div id="pricing" class="main-container">
      <div class="">
        <h3 class="font-semibold text-28p ">Change Plan</h3>
      <div class="divider my-5"></div>
      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-y-10 divide-y-2 md:divide-y-0 xl:gap-y-0 gap-x-20 lg:grid-cols-3 xl:grid-cols-4"
      >
        <div>
          <h3 class="text-28p font-semibold my-5">Free</h3>
          <p class="min-h-[50px]">
            For entrepreneurs solidifying product feedback.
          </p>
          <p class="my-5 flex space-x-2 items-end">
            <span class="text-28p font-semibold">$0 USD</span>
            <span>/mo</span>
          </p>
          <a
            v-if="getPlanInfo.title === 'FREE'"
            class="w-full px-4 py-4 bg-black block rounded-[4px] text-white text-center my-4 disabled"
            href="#"
            disabled
          >
            Current Plan
          </a>
          <a
            v-else
            class="w-full px-4 py-4 bg-primary-brown hover:bg-primary-brown-blue block rounded-[4px] text-white text-center my-4"
            href="#"
            @click="upgradePlan('FREE')"
          >
            Downgrade Plan
          </a>
          <p class=" my-5">Includes</p>
          <p class="py-1"><span class="mr-2">✓</span> 20 Responses per month</p>
          <p class="py-1"><span class="mr-2">✓</span> 5 studies</p>
          <p class="py-1"><span class="mr-2">✓</span> Segmentation</p>
          <p class="py-1"><span class="mr-2">✓</span> PDF & CSV reports</p>

          <p class="py-1"><span class="mr-2">✓</span> Voice recordings</p>
          <p class="py-1"><span class="mr-2">✓</span> 3 team members</p>
        </div>
        <div>
          <h3 class="text-28p font-semibold my-5">Base</h3>
          <p class="min-h-[50px]">
            For businesses scaling their customer base.
          </p>
          <p class="my-5 flex space-x-2 items-end">
            <span class="text-28p font-semibold">$49 USD</span>
            <span>/mo</span>
          </p>
          <a
            v-if="getPlanInfo.title === 'Base'"
            class="w-full px-4 py-4 bg-black block rounded-[4px] text-white text-center my-4 disabled"
            href="#"
            disabled
          >
            Current Plan
          </a>
          <a
            v-else
            class="w-full px-4 py-4 bg-primary-brown hover:bg-primary-brown-blue block rounded-[4px] text-white text-center my-4"
            href="#"
            @click="upgradePlan('Base')"
          >
            Upgrade Plan
          </a>
          <p class=" my-5">Everything in Free and:</p>
          <p class="py-1">
            <span class="mr-2">✓ </span> 200 Response included and 35¢ per
            additional responses
          </p>
          <p class="py-1"><span class="mr-2">✓</span> Unlimited studies</p>
          <p class="py-1"><span class="mr-2">✓</span> Incentives</p>
          <p class="py-1"><span class="mr-2">✓</span> Anonymous studies</p>
          <p class="py-1"><span class="mr-2">✓</span> Time series insights</p>
        </div>
        <div>
          <h3 class="text-28p font-semibold my-5">Pro</h3>
          <p class="min-h-[50px]">
            For businesses tracking customer concerns and trends.
          </p>
          <p class="my-5  flex space-x-2 items-end">
            <span class="text-28p font-semibold">$349 USD</span>
            <span>/mo</span>
          </p>
          <a
            v-if="getPlanInfo.title === 'Pro'"
            class="w-full px-4 py-4 bg-black block rounded-[4px] text-white text-center my-4 disabled"
            href="#"
            disabled
          >
            Current Plan
          </a>
          <a
            v-else
            class="w-full px-4 py-4 bg-primary-brown hover:bg-primary-brown-blue block rounded-[4px] text-white text-center my-4"
            href="#"
            @click="upgradePlan('Pro')"
          >
            Upgrade Plan
          </a>
          <p class=" my-5">Everything in Free and:</p>
          <p class="py-1">
            <span class="mr-2">✓</span> 1,000 Response included and 30¢ per
            additional responses
          </p>
          <p class="py-1"><span class="mr-2">✓</span> Unlimited team members</p>
          <p class="py-1"><span class="mr-2">✓</span> Public insights</p>
          <p class="py-1">
            <span class="mr-2">✓</span> Sentiment alerts (coming soon)
          </p>
        </div>
        <div>
          <h3 class="text-28p font-semibold my-5">
            Pro<span class="px-1">+</span>
          </h3>
          <p class="min-h-[50px]">
            For business building proprietary data sources.
          </p>
          <p class="my-5 flex space-x-2 items-end">
            <span class="text-28p font-semibold">$649 USD</span>
            <span>/mo</span>
          </p>
          <a
            v-if="getPlanInfo.title === 'Pro+'"
            class="w-full px-4 py-4 bg-black block rounded-[4px] text-white text-center my-4 disabled"
            href="#"
            disabled
          >
            Current Plan
          </a>
          <a
            v-else
            class="w-full px-4 py-4 bg-primary-brown hover:bg-primary-brown-blue block rounded-[4px] text-white text-center my-4"
            href="#"
            @click="upgradePlan('Pro+')"
          >
            Upgrade Plan
          </a>
          <p class=" my-5">Everything in Pro and:</p>
          <p>
            <span class="mr-2">✓</span> 2,500 Responses included and 25¢ per
            additional response
          </p>
          <p><span class="mr-2">✓</span> White label product page</p>
        </div>
      </div>    
      </div>
    </div>
    
    <stripe-payment-form ref="stripeForm" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { StripePaymentForm, StSpinner } from "../../common";
import { getCurrentMonth, monthNames } from '../insights/pdfs/utilities';
// import { advanceToUrl, companyLogoStyle, isUserAdmin } from '../../mixins'
// import { IconWrapper, StCard, StMediaUploader, TopHeader } from '../common/'

export default {
  components: {
    StripePaymentForm,
    StSpinner,
  },
  // components: {
  // IconWrapper,
  // StCard,
  // StMediaUploader,
  // TopHeader
  // },
  // mixins: [advanceToUrl, companyLogoStyle, isUserAdmin],
  props: {
    isMainPage: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    getPlanInfo() {
      return this.currentUser.account?.plan;
    },
    currentMonth(){
      return getCurrentMonth()
    },
    currentUserCreatedDate(){
      const createdAt = new Date(this.currentUser.createdAt)
      return `${monthNames[createdAt.getMonth()]} ${createdAt.getDate()}, ${createdAt.getFullYear()}`
    }
  },
  methods: {
   
    upgradePlan(title) {
      if (this.currentUser?.account.customerId) {
        if (this.currentUser?.account.subscriptionId) {
          this.isLoading = true;
          this.$axios
            .request({
              method: "post",
              url: "/payments/update_subscription",
              data: {
                title: title,
              },
            })
            .then((res) => {
              console.log(res);
              if (res.data) {
                this.isLoading = false;
                window.location.href = "/plans/upgrade_plan";
              }
              // change status to 'deactivated' for selected members
              // this.selectedMembers.forEach(member => {
              //   member.status = 'deactivated'
              //   member.selected = false
              // })
              // this.deleteMemberModal = false
              // this.toggleAllChecks(false)
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          this.$axios
            .request({
              method: "post",
              url: "/payments/create_subscription",
              data: {
                title: title,
              },
            })
            .then((res) => {
              console.log(res);
              if (res.data) {
                this.isLoading = false;
                window.location.href = "/plans/upgrade_plan";
              }
              // change status to 'deactivated' for selected members
              // this.selectedMembers.forEach(member => {
              //   member.status = 'deactivated'
              //   member.selected = false
              // })
              // this.deleteMemberModal = false
              // this.toggleAllChecks(false)
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        this.$refs.stripeForm.toggle();
      }
    },
  },
};
</script>

<style scoped>
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.min-h-\[50px\] {
  min-height: 50px;
}

.w-full {
  width: 100%;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  
  margin-right: calc(0.5rem );
  margin-left: calc(0.5rem );
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  
  border-top-width: calc(2px );
  border-bottom-width: calc(2px );
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-black {
  /* --tw-border-opacity: 1; */
  border-bottom-color: rgb(0 0 0 );
}

.bg-\[\#DDD0B7\] {
  /* --tw-bg-opacity: 1; */
  background-color: rgb(221 208 183 );
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.text-center {
  text-align: center;
}

.text-\[80px\] {
  font-size: 80px;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[40px\] {
  font-size: 40px;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-white {
  /* --tw-text-opacity: 1; */
  color: rgb(255 255 255);
}

.text-\[\#C9C9C9\] {
  /* --tw-text-opacity: 1; */
  color: rgb(201 201 201);
}

.text-black {
  /* --tw-text-opacity: 1; */
  color: rgb(0 0 0);
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.max-w-7xl {
  max-width: 1280px;
}
.mb-20 {
  margin-bottom: 80px;
}
.mb-10 {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    
    border-top-width: calc(0px );
    border-bottom-width: calc(0px );
  }
}

@media (min-width: 1024px) {
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:gap-y-0 {
    row-gap: 0px;
  }
}
</style>
