<template>
  <st-modal v-model="showModal">
    <template v-slot:modalTitle>
      <span class="font-18p font-bold">
        {{ title }}
      </span>
    </template>
    <template v-slot:modalActions>
      <div class="group-modal-height my-2">
        <div
          v-for="(group, index) in sentimentGroups"
          :key="group.name"
          class=""
        >
          <p
            class="w-full px-2-important py-2 hover-bg-group-row flex items-center my-1 space-x-5 "
            :class="{ 'selected-group-row': index === selectedGroup }"
            @click="markAsSelected(index)"
          >
            <!-- <span class="pt-2p">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_1436)">
                  <path
                    d="M0.737273 3.85248L0.580706 6.01227C0.557376 6.32074 0.657952 6.62506 0.860141 6.85887C1.06233 7.09269 1.34954 7.23629 1.65749 7.25755H1.74148C2.03543 7.25859 2.31849 7.14764 2.53416 6.94753C2.74983 6.74741 2.88151 6.47368 2.90277 6.18025L3.05674 4.01734C3.07541 3.76798 3.18324 3.53313 3.36003 3.35634C3.53681 3.17955 3.77114 3.07172 4.02103 3.05306L6.18082 2.90219C6.59557 2.87264 6.96314 2.62431 7.14511 2.25C7.32708 1.87621 7.29545 1.43347 7.06216 1.08871C6.82886 0.743956 6.42967 0.550062 6.01492 0.579613L3.85513 0.733587C3.04793 0.793725 2.28946 1.14159 1.71763 1.71394C1.14528 2.28629 0.797411 3.04476 0.737273 3.85144V3.85248Z"
                    fill="currentColor"
                  />
                  <path
                    d="M16.3421 10.742C16.0336 10.7186 15.7293 10.8192 15.4955 11.0214C15.2617 11.2236 15.1181 11.5108 15.0968 11.8187L14.9429 13.9816C14.9242 14.231 14.8164 14.4659 14.6396 14.6427C14.4628 14.8194 14.2285 14.9273 13.9786 14.9459L11.8188 15.0968C11.404 15.1118 11.0287 15.3467 10.8343 15.7137C10.6399 16.0803 10.6559 16.523 10.8763 16.875C11.0966 17.227 11.488 17.4344 11.9028 17.4194H11.9867L14.1465 17.2654C14.9527 17.2042 15.7107 16.8564 16.2825 16.284C16.8538 15.7117 17.2017 14.9537 17.2618 14.1475L17.4184 11.9878C17.4417 11.6793 17.3411 11.375 17.1389 11.1412C16.9367 10.9073 16.6495 10.7637 16.3416 10.7425L16.3421 10.742Z"
                    fill="currentColor"
                  />
                  <path
                    d="M0 15.0968C0 15.8666 0.305876 16.6049 0.85023 17.1498C1.39459 17.6941 2.13335 18 2.90323 18H5.51613C6.286 18 7.02425 17.6941 7.56912 17.1498C8.11348 16.6054 8.41935 15.8666 8.41935 15.0968V12.4839C8.41935 11.714 8.11348 10.9757 7.56912 10.4309C7.02477 9.8865 6.286 9.58063 5.51613 9.58063H2.90323C2.13335 9.58063 1.3951 9.8865 0.85023 10.4309C0.305876 10.9752 0 11.714 0 12.4839V15.0968ZM2.32258 12.4839C2.32258 12.1629 2.58283 11.9032 2.90323 11.9032H5.51613C5.6701 11.9032 5.81786 11.9644 5.92673 12.0733C6.0356 12.1821 6.09677 12.3299 6.09677 12.4839V15.0968C6.09677 15.2507 6.0356 15.3985 5.92673 15.5074C5.81786 15.6162 5.6701 15.6774 5.51613 15.6774H2.90323C2.58283 15.6774 2.32258 15.4171 2.32258 15.0968V12.4839Z"
                    fill="currentColor"
                  />
                  <path
                    d="M18 2.90323C18 2.13335 17.6942 1.3951 17.1498 0.85023C16.6055 0.305876 15.8667 0 15.0968 0H12.4839C11.714 0 10.9758 0.305876 10.4309 0.85023C9.88656 1.39459 9.58069 2.13335 9.58069 2.90323V5.51613C9.58069 6.286 9.88656 7.02425 10.4309 7.56912C10.9753 8.11348 11.714 8.41935 12.4839 8.41935H15.0968C15.8667 8.41935 16.6049 8.11348 17.1498 7.56912C17.6942 7.02477 18 6.286 18 5.51613V2.90323ZM15.6775 5.51613C15.6775 5.6701 15.6163 5.81786 15.5074 5.92673C15.3985 6.0356 15.2508 6.09677 15.0968 6.09677H12.4839C12.1635 6.09677 11.9033 5.83652 11.9033 5.51613V2.90323C11.9033 2.58283 12.1635 2.32258 12.4839 2.32258H15.0968C15.2508 2.32258 15.3985 2.38376 15.5074 2.49263C15.6163 2.6015 15.6775 2.74925 15.6775 2.90323V5.51613Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_1436">
                    <rect
                      width="18"
                      height="18"
                      fill="currentColor"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span> -->
            <span class="pb-2p flex font-600 justify-between px-2 font-14p items-center w-full">
              {{ group.name }}
              <svg
                v-if="index === selectedGroup"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 0.375C15.316 0.375 19.625 4.68432 19.625 10C19.625 15.3157 15.3157 19.625 10 19.625C4.68432 19.625 0.375 15.3157 0.375 10C0.375 4.68432 4.68432 0.375 10 0.375ZM5.23511 10.1258C4.87977 9.73269 4.91031 9.12621 5.30265 8.77123C5.69571 8.41589 6.30219 8.44643 6.65717 8.83878L9.05471 11.4878L14.2357 6.1362C14.6057 5.75572 15.2133 5.74745 15.5938 6.11752C15.9743 6.48759 15.9825 7.09515 15.6125 7.47563L9.72047 13.5616L9.71904 13.5602C9.70502 13.5746 9.69029 13.5889 9.67484 13.6026C9.2825 13.9579 8.67602 13.9277 8.32032 13.535L5.23403 10.1254H5.23475L5.23511 10.1258Z"
                  fill="#A6684F"
                />
              </svg>

              <!-- <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                strokes=""
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_2036)">
                  <path
                    d="M0.308586 6.66995C-0.103513 6.20058 -0.103513 5.43912 0.308586 4.96974C0.720314 4.49995 1.38826 4.49995 1.80036 4.96974L3.57862 6.99698L8.12528 0.455426C8.4848 -0.0635101 9.14531 -0.151623 9.60051 0.258018C10.0557 0.667872 10.133 1.42086 9.77349 1.93979L4.55165 9.45293C4.51226 9.52198 4.46618 9.58743 4.41267 9.64801C4.00094 10.1178 3.333 10.1178 2.92127 9.64801L0.308958 6.66995H0.308586Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_2036">
                    <rect
                      width="10"
                      height="10"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg> -->
            </span>
          </p>
        </div>
        <span class="text-error px-5">{{ error }}</span>
      </div>
      <div class="flex justify-between mt-40p">
        <div>
          <button
            class="bg-create-group rounded-4p px-3 py-3  text-blue-primary"
            @click="groupModalHandler"
          >
            Create group
          </button>
        </div>
        <div class="flex space-x-2 ">
          <button
            class="bg-gray-100 text-gray-two px-3 py-3 rounded-4p"
            @click="toggle"
          >
            Cancel
          </button>
          <button
            class="bg-blue-primary px-3 py-3  text-white rounded-4p"
            @click="assignGroupHandler"
          >
            Assign
          </button>
        </div>
      </div>
    </template>
  </st-modal>
</template>

<script>
import { StInput, StModal } from '../../common'
export default {
  components: {
    StInput,
    StModal
  },
  props: {
    title: {
      type: String,
      require: true,
      default: ''
    },
    sentimentGroups: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      showModal: false,
      groupName: '',
      selectedGroup: -1,
      entityId: '',
      entityGroupId: '',
      error: ''
    }
  },
  methods: {
    markAsSelected (index) {
      this.selectedGroup = index
    },
    toggle (entityId, entityGroupId) {
      this.entityId = entityId
      this.error = ''
      this.entityGroupId = entityGroupId
      this.showModal = !this.showModal
      this.groupName = ''
      if (this.entityGroupId) {
        this.selectedGroup = this.sentimentGroups.findIndex(
          (group) => group.id === this.entityGroupId
        )
      } else {
        this.selectedGroup = -1
      }
    },
    groupModalHandler () {
      this.showModal = false
      this.$emit('groupModalHandler', 'Create Group')
    },
    assignGroupHandler () {
      if (this.selectedGroup != -1) {
        this.$emit('loader', true)
        const { id: groupId } = this.sentimentGroups[this.selectedGroup]
        this.$axios
          .request({
            url: `/entity_sentiments/${this.entityId}/assign/`,
            method: 'patch',
            data: { entity_sentiments_group_id: groupId }
          })
          .then((res) => {
            this.showModal = false
            this.$emit('showToast', 'Group assigned successfully')
            this.$store.dispatch('insights/updateEntitySentimentGroup', {
              entityId: this.entityId,
              groupId
            })
          })
          .catch((err) => {
            console.log(err)
          })
        setTimeout(() => {
          this.$emit('loader', false)
        }, [1000])
      } else {
        this.error = 'Please select group'
      }
    }
  }
}
</script>
