<template>
  <div class="st-prospectives--about">
    <div class="st-prospectives__about-intro st-prospectives__dots">
      <div class="bx--grid">
        <div class="bx--row st-prospectives__about-row">
          <div class="bx--col-xs-12 bx--col-lg-7 bx--col-xl-6">
            <h5 class="st-welcome__subheading st-welcome__intro-subheading">
              About Us
            </h5>
            <h1 class="st-prospectives__heading st-prospectives__about-heading">
              We believe that a product is as good as the people working on it.
            </h1>
          </div>
          <div class="bx--col-xs-12 bx--offset-xl-1 bx--col-lg-5">
            <p class="st-prospectives__about-para">
              Through partnerships and collaboration we have found the right group of people to take
              our ideas to the finish line in a timely manner.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="st-prospectives__about-team">
      <div class="bx--grid">
        <h2 class="st-prospectives__about-team-heading">
          Meet the team
        </h2>
        <div class="bx--row st-prospectives__about-team-content">
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-1" />
              <h5 class="st-prospectives__team-name">
                Zak Kidd
              </h5>
              <span class="st-prospectives__team-designation">CEO</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-2" />
              <h5 class="st-prospectives__team-name">
                Bob Fox
              </h5>
              <span class="st-prospectives__team-designation">CDO</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-3" />
              <h5 class="st-prospectives__team-name">
                Stephen Hogsten
              </h5>
              <span class="st-prospectives__team-designation">CTO</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-4" />
              <h5 class="st-prospectives__team-name">
                Qasim Asad Salam
              </h5>
              <span class="st-prospectives__team-designation">Technical Advisor</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-5" />
              <h5 class="st-prospectives__team-name">
                Talha
              </h5>
              <span class="st-prospectives__team-designation">Technical Advisor</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-6" />
              <h5 class="st-prospectives__team-name">
                Austin Regan
              </h5>
              <span class="st-prospectives__team-designation">Full Stack Developer</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-7" />
              <h5 class="st-prospectives__team-name">
                Hibba
              </h5>
              <span class="st-prospectives__team-designation">Project Manager</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-8" />
              <h5 class="st-prospectives__team-name">
                Zain
              </h5>
              <span class="st-prospectives__team-designation">Back End Developer</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-9" />
              <h5 class="st-prospectives__team-name">
                Afzaal
              </h5>
              <span class="st-prospectives__team-designation">Full Stack Developer</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-10" />
              <h5 class="st-prospectives__team-name">
                Umar Dawood
              </h5>
              <span class="st-prospectives__team-designation">UI/UX Developer</span>
            </div>
          </div>
          <div class="bx--col-xs-12 bx--col-sm-6 bx--col-md-4 bx--col-xl-3">
            <div class="st-prospectives__team-box">
              <div class="st-prospectives__team-img st-prospectives__team-img-11" />
              <h5 class="st-prospectives__team-name">
                Hayley Kidd
              </h5>
              <span class="st-prospectives__team-designation">Marketing Representative</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="st-welcome__combine-component">
      <div class="bx--grid">
        <div
          id="contactForm"
          ref="contactForm"
          class="st-welcome__help"
        >
          <div class="bx--row">
            <div class="bx--col-xs-12 bx--col-lg-6 bx--col-xl-5">
              <h1 class="st-welcome__combine-heading st-welcome__combine-heading-help">
                See how AskHumans can help you understand what people think about your real estate spaces.
              </h1>
              <h5 class="st-welcome__subheading st-welcome__subheading-lightgrey">
                Fill in the form and we will be in touch shortly to give you a demo of the platform.
              </h5>
            </div>
            <div class="bx--col-xs-12 bx--col-lg-6 bx--offset-xl-1 bx--col-xl-5">
              <form
                class="st-welcome__help-group"
                action="https://formspree.io/f/zak@dupontstudios.com"
                method="post"
              >
                <input
                  id="fullName"
                  class="st-welcome__help-input"
                  name="Full Name"
                  placeholder="FULL NAME"
                  type="name"
                >
                <input
                  id="companyName"
                  class="st-welcome__help-input"
                  name="Company Name"
                  placeholder="COMPANY NAME"
                  type="name"
                >
                <input
                  id="email"
                  class="st-welcome__help-input"
                  name="Email"
                  placeholder="EMAIL"
                  type="email"
                >
                <input
                  id="phoneNumber"
                  class="st-welcome__help-input"
                  name="Phone Number"
                  placeholder="PHONE NUMBER"
                  type="tel"
                >
                <button
                  class="st-welcome__button-submit"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <footer class="st-footer">
          <a
            href="/"
            class="st-footer__logo"
          >
            <icon-wrapper
              icon-name="spacetrics"
              class="st-footer__logo-icon"
            />
            <span class="st-footer__logo-text">AskHumans</span>
          </a>
          <div class="st-footer__content">
            <div class="st-footer__list">
              <a
                class="st-footer__link"
                href="/terms"
                target="_blank"
              >terms & conditions</a>
              <a
                class="st-footer__link"
                href="/privacy"
                target="_blank"
              >privacy policy</a>
            </div>
            <p class="st-footer__copyright">
              ©2021 AskHumans
            </p>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { IconWrapper } from '../common'

export default {
  components: {
    IconWrapper
  },
  props: {
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
