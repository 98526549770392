var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dropdown",class:( _obj = {
    'st-dropdown-menu': true,
    'st-dropdown-menu--open': _vm.open
  }, _obj[_vm.containerClass] = true, _obj ),style:({
    width: _vm.minWidth && _vm.mobileMinWidth,
  })},[(_vm.open)?_c('div',{staticClass:"st-dropdown-menu__mobile-opacitor",on:{"click":function($event){_vm.open = false}}}):_vm._e(),_vm._v(" "),_c('div',{class:( _obj$1 = {
      'st-dropdown-menu__align-chosen': true,
      'st-dropdown-menu__align-chosen--menu-open !hover:tw-border !tw-border !tw-border-black-two': _vm.open,
      'st-dropdown-menu__disabled': _vm.disabled
    }, _obj$1[_vm.selectedClass] = true, _obj$1 ),style:({
      width: _vm.minWidth && _vm.mobileMinWidth,
    }),on:{"click":_vm.checkIfDisabled}},[_c('input',{ref:"chosen",class:_vm.inputClasses,attrs:{"value":_vm.dropdownValue,"type":"button"}}),_vm._v(" "),(_vm.open)?_c('div',{staticClass:"st-dropdown-menu__option-type-mobile"},[_vm._v("\n      "+_vm._s(_vm.caption)+"\n    ")]):_vm._e(),_vm._v(" "),_c('icon-wrapper',{class:{
        'st-dropdown-menu__icon': true,
        'st-dropdown-menu__icon--open': _vm.open,
      },attrs:{"icon-name":_vm.checkIconName}})],1),_vm._v(" "),(_vm.open)?_c('div',{staticClass:"st-dropdown-menu__options-container"},[(_vm.activeOptionText)?_c('input',{class:{
        'st-dropdown-menu__chosen-option-mobile st-dropdown-menu__options tw-w-full ': true,
        'st-dropdown-menu__options--select-multiple': _vm.selectMultiple,
      },attrs:{"value":_vm.activeOptionText,"type":"button"}}):_vm._e(),_vm._v(" "),(_vm.selectMultiple && !Object.keys(_vm.accordionContentsValues).length)?[_c('div',{staticClass:"st-dropdown-menu__options-scroll"},_vm._l((_vm.optionsData),function(option,idx){return _c('st-checkbox',{key:'option ' + idx,staticClass:"st-dropdown-menu__multiple-select-option",attrs:{"checked":option.selected},on:{"input":function($event){return _vm.updateMultiple(idx)}}},[_vm._v("\n          "+_vm._s(option.name)+"\n        ")])}),1)]:(
        _vm.selectMultiple && Object.keys(_vm.accordionContentsValues).length
      )?_vm._l((_vm.optionsData),function(option,idx){return _c('st-accordion',{key:'option ' + idx,staticClass:"st-dropdown-menu__accordion",attrs:{"expanded-text":"" + (option.value.participants.length) + ' recipients',"visible-row-class":"st-dropdown-menu__visible-row-size"}},[_c('template',{slot:"visible-section"},[_c('st-checkbox',{class:{
              'st-dropdown-menu__accordion-checkbox': true,
              'st-dropdown-menu__accordion-checkbox--selected':
                option.selected,
            },attrs:{"checked":option.selected,"disabled":_vm.activeToggleDisable ? option.disabled : _vm.disableCheckbox},on:{"input":function($event){return _vm.updateMultiple(idx)}}},[_vm._v("\n            "+_vm._s(option.name)+"\n          ")])],1),_vm._v(" "),_c('template',{slot:"expanded-section"},[_vm._t("accordion-expanded-section",null,{"value":option.value})],2)],2)}):_vm._l((_vm.inactiveOptions),function(option){return _c('div',{key:option,staticClass:"st-dropdown-menu__options-wrap"},[_c('input',{staticClass:"st-dropdown-menu__options",attrs:{"type":"button","value":option},on:{"click":function($event){return _vm.updateSingle(option)}}})])}),_vm._v(" "),(_vm.open)?_c('div',{staticClass:"st-dropdown-menu__mobile-bar"}):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }