<template>
  <div v-if="crossStudyModal">
    <cross-study-modal
      :projects="activePageProjects"
      @hideCrossStudyModal="hideCrossStudyModal"
    />
  </div>
  <div v-else>
    <!-- <top-header /> -->
    <div class="bx--grid mobile-header justify-center items-center">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          caption="Create project"
          icon-name="arrow"
          :show-action="true"
          :url="`/projects/new`"
        />
      </div>
    </div>
    <div class="bx--offset-sm-1 bx--col-sm-10 bx--col-xs-12">
      <div class="bx--grid ">
        <div class="bx--row">
          <div class="bx--col-xs-12">
            <div class="st-projects__head">
              <h1 class="page-header">
                Projects
              </h1>
              <div class="desktop-page-right-action-button">
                <st-button
                  ref="create"
                  caption="Create project"
                  :show-action="true"
                  icon-name="arrow"
                  :url="`/projects/new`"
                />
              </div>
            </div>
          </div>
          <div class="bx--col-sm-10 bx--col-xs-12">
            <div
              v-if="projects.length"
              ref="lookupAndDropdown"
              class="lookup-and-dropdown tw-flex tw-justify-between"
            >
              <div class="tw-flex tw-items-center tw-space-x-2">
                <st-input
                  ref="search"
                  v-model="projectLookupValue"
                  placeholder="Search project"
                  style-type="secondary"
                  class="st-input--default lookup-and-dropdown__space-input"
                  :show-icon="false"
                  :show-label="false"
                  label="Search project"
                  label-in-field-class="st-input--hide-mobile-label"
                  @blur="updateSearchParam"
                />
                <st-dropdown-menu
                  caption="Projects Filter"
                  :initial-active-option="activeProjectFilterOption"
                  :options="Object.values(projectFilterValues)"
                  @updateOption="getDropdownOption"
                />
              </div>
            </div>
            <div class="projects__cards">
              <st-card
                v-for="(project, index) in activePageProjects"
                :key="index"
                tabindex="0"
                class="project-card__position-contents"
                :class="{'project-card__archived-card': project.archived }"
                :url="`/projects/${project.id}`"
                @click.native.stop="handleClickProject(project)"
              >
                <template v-slot:card-content="slotProps">
                  <div
                    class="project-card__cover-image-and-title  project-card-width"
                  >
                    <div
                      :class="{
                        'project-card__contain-cover-image': true,
                        'project-card__contain-cover-image--default': !project.coverImageUrl,
                      }"
                    >
                      <div
                        :class="{
                          'project-card__cover-image': true,
                          'project-card__cover-image--hover': slotProps.hover,
                          'project-card__cover-image--default': !project.coverImageUrl,
                        }"
                        :style="{ backgroundImage: project.coverImageUrl ? `url('${project.coverImageUrl}')` : '' }"
                      />
                    </div>
                    <div
                      :class="{
                        'project-card__title-and-date': true,
                        'project-card__title-and-date--nav-open': $store.state.navOpen
                      }"
                    >
                      <div class="project-card__title-and-location">
                        <p
                          :class="{
                            'project-card__title': true,
                            'project-card__title--hover': slotProps.hover
                          }"
                        >
                          {{ project.name ? project.name : '[Project Name]' }}
                        </p>
                        <!-- <div class="project-card__location-and-icon">
                          <icon-wrapper
                            class="project-card__location-icon"
                            icon-name="location"
                            :neutral="true"
                          />
                          <p class="project-card__location">
                            {{ project.locationName ? project.locationName : '[Location]' }}
                          </p>
                        </div> -->
                      </div>
                      <div class="project-card__date-and-icon">
                        <!-- <icon-wrapper
                          class="project-and-survey-cards__date-icon"
                          icon-name="calendar"
                          :actionable="false"
                        /> -->
                        <p class="project-and-survey-cards__date">
                          Last modified {{ project.updatedAt.format('MMM DD, YYYY') }}
                        </p>
                        <p class="project-and-survey-cards__date--mobile">
                          {{ project.updatedAt.format('MM/DD/YYYY') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-full justify-end items-center">
                    <div class="project-and-survey-cards__position-statistic project-and-survey-cards__position-statistic--question-or-survey-count ">
                      <p
                        :class="{
                          'project-and-survey-cards__statistic-number text-28p': true,
                          'project-and-survey-cards__statistic-number--hover': slotProps.hover
                        }"
                      >
                        {{ project.surveyCount }}
                      </p>
                      <p
                        :class="{
                          'project-and-survey-cards__statistic-title text-16p': true,
                          'project-and-survey-cards__statistic-title--hover': slotProps.hover
                        }"
                      >
                        Studies
                      </p>
                    </div>

                    <div class="project-and-survey-cards__position-statistic project-and-survey-cards__position-statistic--member-count mx-20 ">
                      <p
                        :class="{
                          'project-and-survey-cards__statistic-number text-28p': true,
                          'project-and-survey-cards__statistic-number--hover': slotProps.hover
                        }"
                      >
                        {{ project.userCount }}
                      </p>
                      <p
                        :class="{
                          'project-and-survey-cards__statistic-title text-16p': true,
                          'project-and-survey-cards__statistic-title--hover': slotProps.hover
                        }"
                      >
                        Members
                      </p>
                    </div>
                  </div>
                  <st-overflow-menu
                    :class="{
                      'project-and-survey-cards__overflow-menu-hover': slotProps.hover
                    }"
                    :title="`${project.name}`"
                    icon-name="projects"
                    :is-empty="!project.canCreateSurvey"
                  >
                    <st-overflow-menu-item
                      v-if="project.canEdit && !project.archived"
                      text="Edit Project"
                      icon-name="edit"
                      @mouseup="openEditProjectInfoModal(project.id)"
                    />
                    <st-overflow-menu-item
                      v-if="project.canCreateSurvey && !project.archived"
                      text="Create Study"
                      icon-name="pointer"
                      :path="`/projects/${project.id}/study/new`"
                    />
                    <st-overflow-menu-item
                      v-if="!project.archived && project.canCreateSurvey"
                      text="Add Team Member"
                      icon-name="add-member"
                      @mouseup="openAddTeamMembersModal(project.id)"
                    />

                    <template v-if="project.canEdit">
                      <st-overflow-menu-item
                        v-if="project.archived"
                        text="Unarchive"
                        icon-name="archive"
                        @mouseup="openArchiveDialogue(project.id, false)"
                      />
                      <st-overflow-menu-item
                        v-else
                        text="Archive"
                        icon-name="archive"
                        @mouseup="openArchiveDialogue(project.id, true)"
                      />
                    </template>
                  </st-overflow-menu>
                </template>
              </st-card>
            </div>
            <st-pagination
              :number-of-items="filteredProjectsBySearch.length"
              @updateActiveIndices="updateIndicesOfProjectsShown"
            />
          </div>
          <!-- <div class="bx--col-sm-2 desktop-page-right-action-button">
            <st-button
              ref="create"
              caption="Create project"
              :show-action="true"
              icon-name="arrow"
              :url="`/projects/new`"
            />
          </div> -->
        </div>
      </div>
    </div>
    <st-modal
      v-model="addMembersModal"
      size-class="st-modal--large"
    >
      <template v-slot:modalTitle>
        Add Team Members
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text">
          <st-email-input
            ref="emailInput"
            label="Type a name or email"
            :existing-members-list="accountMembersNotInProject"
            :only-autocomplete="currentUser.kind !== 'admin' && currentUser.kind !== 'spacetrics_admin'"
            @addNewEmail="addNewEmail"
            @removeEmail="removeNewEmail"
          />
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Add Members"
          icon-name="add-member"
          :disabled="!newEmails.length"
          @click="addTeamMembers"
        />
      </template>
      <template v-slot:additionalInformation>
        <div class="project-add-member-modal__position-contents">
          <p class="project-add-member-modal__title">
            {{ modalProjectName }} Team
          </p>
          <div class="project-add-member-modal__current-members">
            <div
              v-for="(member, index) in modalProjectMembers"
              :key="index"
              class="project-add-member-modal__row"
            >
              <div class="project-add-member-modal__avatar-and-name">
                <participant-avatar
                  class="project-add-member-modal__avatar"
                  :participant="member"
                />
                <p class="project-add-member-modal__fullName">
                  {{ member.fullName }}
                </p>
              </div>
              <p class="project-add-member-modal__kind">
                {{ member.kind }}
              </p>
            </div>
            <div v-if="!modalProjectMembers.length">
              <p>There are no members currently in this project.</p>
            </div>
          </div>
        </div>
      </template>
    </st-modal>

    <st-modal v-model="archiveModal">
      <template v-slot:modalTitle>
        <template v-if="archiveBoolean">
          Archive Project
        </template>
        <template v-else>
          Unarchive Project
        </template>
      </template>
      <template
        v-slot:modalActions
      >
        <div class="st-member-groups__modal-text">
          <div v-if="archiveBoolean">
            Are you sure you want to archive this project? You will be unable to view or access any pages unless you unarchive it later.
          </div>
          <div v-else>
            Are you sure you want to unarchive this project?
          </div>
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          :caption="archiveBoolean ? 'Archive Project' : 'Unarchive Project'"
          :show-action="true"
          icon-name="archive"
          @click="archiveProject"
        />
      </template>
    </st-modal>
    <st-modal v-model="editNameAndLocationModal">
      <template slot="modalTitle">
        Edit Project Information
      </template>
      <template slot="modalActions">
        <st-input
          v-model="modalProjectName"
          class="st-survey-hub__edit-name-input"
          :show-label="true"
          label="Project Name"
        />
        <st-input
          ref="autoComplete"
          v-model="autoCompleteValue"
          class="st-survey-hub__edit-name-input"
          :show-label="true"
          label="Project Location"
        />
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Save"
          :show-action="true"
          :disabled="!modalProjectName"
          icon-name="save"
          @click="editProjectInfo"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { caseInsensitiveSortBy, compareObjects } from '../../../utilities/data_utilities'
import { Project } from '../../../structures/project'
import { advanceToUrl, googleAddressAutoComplete, refineUrlFilter } from '../../../mixins'
import ParticipantAvatar from '../insights/participant-avatar'
import crossStudyModal from './cross-study-modal'
import {
  IconWrapper,
  StButton,
  StCard,
  StDropdownMenu,
  StEmailInput,
  StInput,
  StModal,
  StOverflowMenu,
  StOverflowMenuItem,
  StPagination,
  TopHeader
} from '../../common'
export default {
  components: {
    crossStudyModal,
    TopHeader,
    IconWrapper,
    StButton,
    StCard,
    StDropdownMenu,
    StInput,
    StEmailInput,
    StModal,
    StOverflowMenu,
    StOverflowMenuItem,
    StPagination,
    ParticipantAvatar
  },
  mixins: [advanceToUrl, googleAddressAutoComplete, refineUrlFilter],
  props: {
    accountMembers: {
      type: Array,
      required: false,
      default: () => []
    },
    projects: {
      type: Array,
      required: true
    }
  },
  data: function () {
      // get unique projects from projects]
      let uniqueProjects = this.projects.filter((project, index, self) =>
        index === self.findIndex((t) => (
          t.id === project.id
        ))
      )



    // let uniqueProjects = [...new Map(array.map(item =>
    //                       [item["id"], item])).values()]

    let projects = uniqueProjects.map(Project)
    return {
      crossStudyModal: false,
      activeIndices: [0, 0],
      activeProjectFilterOption: '',
      addMembersModal: false,
      allProjects: projects,
      archiveBoolean: null,
      archiveModal: false,
      editNameAndLocationModal: false,
      modalProjectId: -1,
      newEmails: [],
      projectLookupValue: '',
      searchParams: null
    }
  },
  computed: {
    ...mapState(['currentUser']),
    baseUrl () { return window.location.origin },
    accountMembersNotInProject () {
      return compareObjects(this.accountMembers, this.modalProjectMembers, 'email', true)
    },
    newEmailsNotInProject () {
      return compareObjects(this.newEmails, this.unarchivedProjects, 'email', true)
    },
    newAccountEmailsAddedToProject () {
      return compareObjects(this.newEmailsNotInProject, this.accountMembers, 'email')
    },
    newEmailsNotInAccountOrProject () {
      return compareObjects(this.newEmailsNotInProject, this.accountMembers, 'email', true)
    },
    modalProject () {
      return this.allProjects.find(project => project.id === this.modalProjectId)
    },
    initialAutoCompleteValue () {
      return this.modalProject ? this.modalProject.locationName : ''
    },
    stateToWatch () {
      return this.editNameAndLocationModal
    },
    modalProjectName: {
      get () {
        return this.modalProject ? this.modalProject.name : ''
      },
      set (newName) {
        this.modalProject.name = newName
      }
    },
    modalProjectMembers () {
      return this.modalProject.users
    },
    myProjects () {
      return this.unarchivedProjects.filter(project => project.createdById === this.currentUser.id)
    },
    otherProjects () {
      return this.unarchivedProjects.filter(project => project.createdById !== this.currentUser.id)
    },
    allProjectsCaption () {
      return `All Projects (${this.unarchivedProjects.length})`
    },
    myProjectsCaption () {
      return `My Projects (${this.myProjects.length})`
    },
    otherProjectsCaption () {
      return `Other Projects (${this.otherProjects.length})`
    },
    projectFilterValues () {
      return {
        'allProjects': this.allProjectsCaption,
        'myProjects': this.myProjectsCaption,
        'otherProjects': this.otherProjectsCaption,
        'a-z': 'A - Z',
        'z-a': 'Z - A',
        'archivedProjects': 'Archived Projects'
      }
    },
    unarchivedProjects () {
      return this.allProjects.filter(project => !project.archived)
    },
    filteredProjectsByDropdown () {
      let projects = []
      switch (this.getProjectFilterKey(this.activeProjectFilterOption)) {
        case 'myProjects':
          projects = this.myProjects
          break
        case 'otherProjects':
          projects = this.otherProjects
          break
        case 'a-z':
          projects = caseInsensitiveSortBy(this.unarchivedProjects, 'name')
          break
        case 'z-a':
          projects = caseInsensitiveSortBy(this.unarchivedProjects, 'name', true)
          break
        case 'archivedProjects':
          projects = this.allProjects.filter(project => project.archived)
          break
        default:
          projects = this.unarchivedProjects.slice().sort((a, b) => b.updatedAt - a.updatedAt)
      }
      return projects
    },
    filteredProjectsBySearch () {
      return this.filteredProjectsByDropdown.filter(project => {
        return project.name.toLowerCase().includes(this.projectLookupValue.toLowerCase())
      })
    },
    activePageProjects () {
      return this.filteredProjectsBySearch.slice(...this.activeIndices)
    }
  },
  mounted () {
    this.$store.commit('setActivePage', 'projects')
    this.searchParams = window.location.search.split('?')[1]
    if (this.searchParams) {
      const page = this.searchParams.split('=')[1]
      if (page === 'analysis') {
        this.crossStudyModal = true
        this.searchParam = page
      }
    }
  },
  created () {
    this.defaultFilter = this.allProjectsCaption
    this.activeProjectFilterOption = this.allProjectsCaption
    this.projectLookupValue = this.searchParam
  },
  methods: {
    accountMembersPromise () {
      let inviteAccountMembersPromise
      if (this.newAccountEmailsAddedToProject.length !== 0) {
        let addAccountMembersUrl = `/projects/${this.modalProjectId}/users`
        inviteAccountMembersPromise = this.$axios.request({
          method: 'post',
          url: addAccountMembersUrl,
          data: { ids: this.newAccountEmailsAddedToProject.map(account => account.id) }
        })
      } else {
        inviteAccountMembersPromise = this.$axios.request({
          method: 'get',
          url: `projects/${this.modalProjectId}`
        })
      }
      return inviteAccountMembersPromise
    },
    addNewEmail (email) {
      this.newEmails.push(email)
    },
    addTeamMembers () {
      this.newMembersPromise()
        .then(() => this.accountMembersPromise())
        .then(res => {
          let idx = this.allProjects.map(project => project.id).indexOf(this.modalProjectId)
          const newProject = { ...this.allProjects[idx], ...Project(res.data) }
          this.allProjects.splice(idx, 1, newProject)
          this.addMembersModal = false
          this.newEmails = []
          this.modalProjectId = null
        })
        .catch(err => { console.log('err', err) })
    },
    archiveProject () {
      this.$axios.request({
        method: 'patch',
        url: `/projects/${this.modalProjectId}`,
        data: { project: { archived: this.archiveBoolean } }
      })
        .then(resp => {
          this.archiveModal = false
          let project = this.modalProject
          project.archived = this.archiveBoolean
          this.archiveModal = false
          this.modalProjectId = null
          this.archiveBoolean = null
        })
        .catch(err => console.error(err))
    },
    editProjectInfo () {
      this.$axios.request({
        method: 'patch',
        url: `/projects/${this.modalProject.id}`,
        data: { ...this.modalProject, name: this.modalProjectName, location_name: this.autoCompleteValue }
      })
        .then(res => {
          this.modalProject.locationName = res.data.locationName
          this.editNameAndLocationModal = false
          this.modalProjectId = null
        })
        .catch(err => { console.error(err) })
    },
    getProjectFilterKey (payload) {
      return Object.keys(this.projectFilterValues).find(key => this.projectFilterValues[key] === payload)
    },
    getDropdownOption (payload) {
      this.updateUrlParams('filter', this.getProjectFilterKey(payload))
      this.activeProjectFilterOption = payload
    },
    handleClickProject (project) {
      if (!project.archived) { this.advanceToUrl(`${this.baseUrl}/projects/${project.id}`) }
    },
    newMembersPromise () {
      let inviteNewUsersPromise
      if (this.newEmailsNotInAccountOrProject.length !== 0) {
        let inviteNewUsersUrl = `/users/invite`
        inviteNewUsersPromise = this.$axios.request({
          method: 'post',
          url: inviteNewUsersUrl,
          data: {
            emails: this.newEmailsNotInAccountOrProject.map(newUser => newUser.email),
            project_ids: [this.modalProjectId]
          }
        })
      } else {
        inviteNewUsersPromise = Promise.resolve()
      }
      return inviteNewUsersPromise
    },
    hideCrossStudyModal () {
      if (this.searchParams) {
        window.history.back()
      } else {
        this.crossStudyModal = false
      }
    },
    openCrossStudyAnalysisModal () {
      // this.modalProjectId = projectId
      this.$nextTick(() => { this.crossStudyModal = true })
    },
    openAddTeamMembersModal (projectId) {
      this.modalProjectId = projectId
      this.$nextTick(() => { this.addMembersModal = true })
    },
    openEditProjectInfoModal (projectId) {
      this.modalProjectId = projectId
      this.$nextTick(() => { this.editNameAndLocationModal = true })
    },
    openArchiveDialogue (projectId, bool) {
      this.archiveModal = true
      this.modalProjectId = projectId
      this.archiveBoolean = bool
    },
    removeNewEmail (email) {
      this.newEmails.splice(this.newEmails.indexOf(email), 1)
    },
    updateIndicesOfProjectsShown (payload) {
      this.activeIndices = payload
    }
  }
}
</script>
