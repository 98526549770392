<template>
  <div class="faq">
    <div class="bx--grid">
      <div class="mobile-header">
        <a
          href="/welcome"
        >
          <icon-wrapper
            class="mobile-header__spacetrics-icon"
            icon-name="spacetrics"
          />
        </a>
      </div>
      <div
        class="bx--offset-sm-1 bx--col-sm-9 bx--col-xs-12"
      >
        <h1>
          {{ faqTitle }}
        </h1>
        <p class="st-help-section__message">
          Here you will find answers to most questions you may have. For additional help, check our templates, resources, or chat with us!
        </p>
        <st-input
          v-model="questionLookupValue"
          class="st-help-section__questions-search"
          :show-icon="true"
          :show-label="false"
          label="Question Lookup"
          @blur="updateSearchParam"
        />
        <div
          v-for="(section, index) in filteredSections"
          :key="index"
          class="st-faq-section"
        >
          <div class="st-faq-section__title">
            {{ section.title }}
          </div>
          <st-accordion
            v-for="question in section.questions"
            :key="question.inquiry"
          >
            <template
              slot="visible-section"
            >
              <div class="st-faq-section__inquiry">
                {{ question.inquiry }}
              </div>
            </template>
            <template slot="expanded-section">
              <div class="st-faq-section__answer">
                {{ question.answer }}
              </div>
            </template>
          </st-accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconWrapper, StAccordion, StInput } from '../../common'
import refineUrlFilter from '../../../mixins/refineUrlFilter'

function includesLookupString (baseString, lookupString) {
  return baseString.toLowerCase().includes(lookupString.toLowerCase())
}

export default {
  components: {
    IconWrapper,
    StAccordion,
    StInput
  },
  mixins: [refineUrlFilter],
  props: {
  },
  data: function () {
    return {
      faqInformation: [
        {
          title: 'About',
          questions: [ {
            inquiry: 'What is AskHumans?',
            answer: 'AskHumans is a web application that helps you understand how people feel about the spaces that surround them. It does so by gathering feedback through unique surveying tools that are then analyzed with AI & sentiment analysis.'
          },
          {
            inquiry: 'Who is this application intended for?',
            answer: 'AskHumans is intended for architects and real estate developers that want to understand the feedback about their designs. It is also intended for companies that want to understand how their employees feel about their working spaces.'
          },
          {
            inquiry: 'Who works at AskHumans?',
            answer: 'Our team is composed of 2 full stack developers (Austin Regan, Stephen Hogsten), and a CEO (Zak Kidd).'
          },
          {
            inquiry: 'Where is AskHumans Located?',
            answer: 'AskHumans is located in Washington, D.C. Some of our development team works remotely from Seattle, WA.'
          },
          {
            inquiry: 'Is there an API for AskHumans?',
            answer: `Currently there’s no API for AskHumans. To find out when there’s one available, follow our updates page!`
          }]
        }
        // {
        //   title: 'Insights',
        //   questions: [ {
        //     inquiry: 'What are insights?',
        //     answer: 'Insights are...'
        //   },
        //   {
        //     inquiry: 'Where do I find insights?',
        //     answer: 'Insights are found...'
        //   }]
        // },
        // {
        //   title: 'Surveys',
        //   questions: [
        //     {
        //       inquiry: 'TBD',
        //       answer: 'TBD...'
        //     }
        //   ]
        // },
        // {
        //   title: 'Templates',
        //   questions: [
        //     {
        //       inquiry: 'TBD',
        //       answer: 'TBD...'
        //     }
        //   ]
        // },
        // {
        //   title: 'Participants',
        //   questions: [
        //     {
        //       inquiry: 'TBD',
        //       answer: 'TBD...'
        //     }
        //   ]
        // },
        // {
        //   title: 'Team',
        //   questions: [
        //     {
        //       inquiry: 'TBD',
        //       answer: 'TBD...'
        //     }
        //   ]
        // },
        // {
        //   title: 'Account',
        //   questions: [
        //     {
        //       inquiry: 'TBD',
        //       answer: 'TBD...'
        //     }
        //   ]
        // },
        // {
        //   title: 'Billing',
        //   questions: [
        //     {
        //       inquiry: 'TBD',
        //       answer: 'TBD...'
        //     }
        //   ]
        // }
      ],
      questionLookupValue: ''
    }
  },
  computed: {
    faqTitle () {
      let title = '';
      (window.innerWidth <= 576) ? title = 'FAQ' : title = 'Frequently Asked Questions'
      return title
    },
    filteredQuestionsBySection () {
      return this.faqInformation.map(section => {
        return {
          title: section.title,
          questions: section.questions.filter(question => includesLookupString(question.inquiry, this.questionLookupValue))
        }
      })
    },
    filteredSections () {
      return this.filteredQuestionsBySection.filter(section => section.questions.length > 0)
    }
  },
  mounted () {
    this.$store.commit('setActivePage', 'faq')
  },
  created () {
    this.questionLookupValue = this.searchParam
  }
}
</script>
