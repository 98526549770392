import { render, staticRenderFns } from "./study-plan-downgrade-disabled.vue?vue&type=template&id=d2ad9b5c&scoped=true&"
import script from "./study-plan-downgrade-disabled.vue?vue&type=script&lang=js&"
export * from "./study-plan-downgrade-disabled.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2ad9b5c",
  null
  
)

export default component.exports