<template>
  <div
    v-if="matterportId"
    :class="{
      'st-virtual-tour': true,
      'st-virtual-tour--specs-or-demo-preview': isSpecificationsPreview || isDemoPreview,
      'st-virtual-tour--demo-preview': isDemoPreview && mobileDemoView,
      'st-virtual-tour--demo-preview-disabled': isDemoPreview && questionPhase === PHASES.demoSteps
    }"
  >
    <microphone-access
      v-if="showToast && errorMessage"
      :show-microphone-access="showToast && errorMessage !== ''"
      @closeMicrophoneAccess="showToast = false"
    />
    <matterport-iframe
      class="st-virtual-tour__matterport-iframe"
      :matterport-id="matterportId"
      @matterport-showcase-loaded="handleMatterportLoaded"
    />
    <div class="st-virtual-tour__inner-wrapper">
      <transition-group name="fade">
        <matterport-indicator
          v-if="questionPhase === PHASES.matterportIndicator"
          key="matterport-indicator"
          @indicator-continued="handleIndicatorContinued"
        />
        <transition-group
          v-else
          key="tour-elements"
          name="fade-numbers"
        >
          <instructions
            v-if="questionPhase === PHASES.instructions"
            key="instructions"
            :is-single-question-survey="isSingleQuestionSurvey"
            :has-media-recorder="mediaRecorder !== null"
            :company-logo="activeQuestion.logoUrl"
            :returning="hasResponses"
            @ask-for-audio-permission="askForAudioPermission"
            @skip-tour="skipThisQuestion"
            @start-countdown="startCountdown"
          />
          <countdown
            v-else-if="questionPhase === PHASES.countdown"
            key="countdown"
            :company-logo="activeQuestion.logoUrl"
            :is-join-survey="isJoinSurvey"
            :is-specifications-preview="isSpecificationsPreview"
            :spacetrics-logo="spacetricsLogo"
            @end-countdown="handleEndCountdown"
          />
          <demo-steps-overlay
            v-else-if="questionPhase === PHASES.demoSteps"
            key="countdown"
            :company-logo="activeQuestion.logoUrl"
            :is-specifications-preview="isSpecificationsPreview"
            @end-countdown="handleEndCountdown"
          />
          <overlay
            v-else-if="showOverlay"
            key="overlay"
            :company-logo="activeQuestion.logoUrl"
            :audio-stream="stream"
            :is-demo-preview="isDemoPreview"
            :is-join-survey="isJoinSurvey"
            :is-specifications-preview="isSpecificationsPreview"
            :spacetrics-logo="spacetricsLogo"
            @open-exit-prompt="setActivePhase(PHASES.exit)"
            @open-topic-list="openTopicList"
          />
          <div
            v-else-if="questionPhase === PHASES.replay"
            key="replay-response"
            class="st-virtual-tour__replay"
          >
            <div class="st-virtual-tour__instructions-box st-virtual-tour__replay-box bx--offset-lg-3 bx--col-lg-6 bx--offset-md-2 bx--col-md-8 bx--offset-sm-1 bx--col-sm-10 bx--col-xs-12">
              <div
                class="st-virtual-tour__replay-logo"
                :style="companyLogoStyle"
              />
              <div class="st-virtual-tour__replay-image" />
              <div class="st-virtual-tour__replay-thank-you">
                You sound great!
              </div>
              <div class="st-virtual-tour__replay-review">
                Play back your recording to review your feedback.
              </div>

              <audio
                ref="responseAudio"
                controls
                class="st-virtual-tour__retake-audio"
              >
                <source
                  :src="blobUrl"
                >
              </audio>

              <st-button
                :underline="false"
                class="st-virtual-tour__replay-btn st-button--mobile-tall"
                :variant="['secondary']"
                :caption="submitBtnText"
                @click="submitQuestionAnswer"
              />

              <div
                class="st-virtual-tour__retake-tour"
                @click="resetRecording"
              >
                Retake Virtual Tour
              </div>
            </div>
          </div>
          <topic-list
            v-if="questionPhase === PHASES.topicList"
            key="topic-list"
            :is-demo-preview="isDemoPreview"
            :is-specifications-preview="isSpecificationsPreview"
            @close-topic-list="closeTopicList"
            @movePano="topicListMovePano"
          />
          <exit-prompt
            v-if="questionPhase === PHASES.exit"
            key="exit-prompt"
            @continue-tour="setActivePhase(PHASES.tour)"
            @exit-tour="triggerSubmitQuestionAnswer"
          />
        </transition-group>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { audioRecorder, companyLogoStyle, isDesktop, previousResponse, submitQuestionAnswer } from '../../../../mixins'
import MatterportIframe from '../../../matterport/matterport-iframe'
import { StButton } from '../../../common'
import MicrophoneAccess from '../microphone-access'
import { Countdown, DemoStepsOverlay, ExitPrompt, Instructions, MatterportIndicator, Overlay, TopicList } from './virtual_tour'

export default {
  components: {
    Countdown,
    DemoStepsOverlay,
    ExitPrompt,
    Instructions,
    MatterportIframe,
    MatterportIndicator,
    MicrophoneAccess,
    Overlay,
    StButton,
    TopicList
  },
  mixins: [ audioRecorder, companyLogoStyle, isDesktop, previousResponse, submitQuestionAnswer ],
  props: {
    canSkip: {
      type: Boolean,
      required: false,
      default: false
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSingleQuestionSurvey: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    logoUrl: {
      type: String,
      required: false,
      default: ''
    },
    matterportId: {
      type: String,
      required: false,
      default: ''
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    nextQuestionId: {
      type: Number,
      required: false,
      default: 0
    },
    priority: {
      type: Number,
      required: true
    },
    panoskinId: {
      type: String,
      required: false,
      default: ''
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    promptLookup: {
      type: Object,
      required: false,
      default: () => ({})
    },
    questionId: {
      type: Number,
      required: true
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    surveyQuestionCount: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      audioBlob: null,
      blobUrl: null,
      desktopTopicList: false,
      errorMessage: '',
      initiatedTimestamp: null,
      matterportLoaded: false,
      movements: [],
      moveToMethod: null,
      panoChanges: [],
      showToast: false,
      topicTTSLookup: {}
    }
  },
  computed: {
    ...mapState('participants', ['currentPano', 'hideMatterportIndicator', 'mobileDemoView', 'isTopicClosed', 'viewedTopics', 'questionPhase']),
    ...mapState('participants', { participantActiveQuestion: 'activeQuestion' }),
    ...mapGetters('participants', ['isDemoPreview', 'isSpecificationsPreview', 'isTopicListOpen', 'PHASES', 'topicsByPanoId']),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    activeQuestion () {
      return this.isSpecificationsPreview ? this.specsActiveQuestion : this.participantActiveQuestion
    },
    hasResponses () {
      return (this.questionResponses !== null) && this.questionResponses.length > 0
    },
    showOverlay () {
      let phasesArray = isDesktop
        ? [this.PHASES.matterportIndicator, this.PHASES.tour, this.PHASES.topicList, this.PHASES.exit]
        : [this.PHASES.matterportIndicator, this.PHASES.tour, this.PHASES.exit]
      return phasesArray.includes(this.questionPhase)
    },
    submitBtnText () {
      return this.activeQuestion.nextQuestionId ? 'Next Question' : 'Submit'
    },
    companyLogo () {
      if (this.isJoinSurvey) { return this.spacetricsLogo }
      return this.logoUrl
    },
    showMatterportIndicator () {
      return this.questionPhase === this.PHASES.matterportIndicator &&
      !this.isSpecificationsPreview &&
      !this.isSurveyPreview &&
      !this.isDemoPreview
    }
  },
  mounted () {
    this.$emit('hideLogoHeader', true)
    this.$emit('hideSpacetricsFooter', true)
    if (this.isDemoPreview) {
      this.$nextTick(() => { this.setActivePhase(this.PHASES.demoSteps) })
    } else if (!this.isSpecificationsPreview) {
      return this.$nextTick(() => { this.checkShowInstructions() })
    }
  },
  created () {
    if (this.isSpecificationsPreview) {
      this.askForAudioPermission()
      return this.$nextTick(() => { this.setActivePhase(this.PHASES.tour) })
    } else if (!this.isDemoPreview) {
      return this.$nextTick(() => { this.checkShowInstructions() })
    }
  },
  methods: {
    ...mapMutations('participants', ['setActivePhase', 'closeTopic', 'openTopic']),
    ...mapMutations(['toggleFullSpinner']),
    ...mapActions('participants', ['changePano', 'playTopicAudio', 'resetIndicatorTimeout', 'topicViewedTimeout']),
    checkShowInstructions () {
      if (this.activeQuestion.showInstructions) {
        this.setActivePhase(this.PHASES.instructions)
      } else {
        this.askForAudioPermission()
        this.startCountdown()
      }
    },
    loadAudio () {
      this.activeQuestion.panoramas.forEach(pano => {
        if (!pano.topic || !pano.topicAudioUrl) { return }
        if (pano.prompt in this.topicTTSLookup) { return }

        let audio = new Audio()
        this.topicTTSLookup[pano.prompt] = audio
        audio.src = pano.topicAudioUrl
        audio.load()
      })
      return this.topicTTSLookup
    },
    askForAudioPermission (incrementPage) {
      this.errorMessage = null
      this.showToast = true
      this.toggleFullSpinner(true)
      this.$nextTick(() => {
        this.tryInitializingMediaRecorder(incrementPage)
          .then(() => { console.log('good'); this.toggleFullSpinner(false) })
          .catch(() => { console.log('bad'); this.toggleFullSpinner(false) })
      })
    },
    handleEndCountdown () {
      !this.hideMatterportIndicator && !this.isSurveyPreview && !this.isSpecificationsPreview
        ? this.setActivePhase(this.PHASES.matterportIndicator)
        : this.startTour()
    },
    handleIndicatorContinued () {
      if (this.initiatedTimestamp) {
        this.setActivePhase(this.PHASES.tour)
        this.resetIndicatorTimeout()
      } else {
        this.startTour()
      }
    },
    handleMatterportLoaded (sdk) {
      // add listeners
      sdk.on(sdk.Sweep.Event.ENTER, (x, y) => this.transitionMatterportPano(x, y))
      sdk.on(sdk.Camera.Event.MOVE, movement => this.handleMatterportMovement(movement))
      this.moveToMethod = sdk.Sweep.moveTo

      if (this.isSpecificationsPreview) {
        this.$store.commit('specifications/setMoveVirtualTour', this.moveToMethod)
        sdk.on(sdk.Sweep.Event.EXIT, (identifier) => this.useActiveViewpoint(identifier))
      }
    },
    useActiveViewpoint (identifier) {
      if (this.activeQuestion.initialViewpoint) { return }

      this.saveInitialActiveViewpoint(identifier)
      this.blockPreview = false
    },
    saveInitialActiveViewpoint (viewpoint) {
      this.$store.commit('specifications/mergeActiveQuestion', { initialViewpoint: viewpoint })
      this.$axios.request({
        method: 'patch',
        url: `/virtual_tours/${this.activeQuestion.virtualTourId}`,
        data: { virtual_tour: { initial_viewpoint: viewpoint } }
      })
        .then(() => { this.$store.commit('toggleFullSpinner', false) })
        .catch(() => { this.$store.commit('toggleFullSpinner', false) })
    },
    handleMatterportMovement (movement) {
      // movement will be a Camera#Pose data type
      // there's enough of these triggered that it doesn't seem good to save them all
      // option 1: set a time limit (e.g. 2 seconds) and only save 1 movement per time frame
      // option 2: somehow track the rotation and only save when they change direction a certain amount
      //   (or change trajectory). this seems much harder
    },
    closeTopicList () {
      this.openTopic()
      this.setActivePhase(this.PHASES.tour)
    },
    openTopicList () {
      if (this.questionPhase === this.PHASES.topicList) {
        this.setActivePhase(this.PHASES.tour)
        this.openTopic()
      } else {
        this.setActivePhase(this.PHASES.topicList)
      }
    },
    topicListMovePano (payload) {
      if (this.moveToMethod && typeof this.moveToMethod === 'function') {
        this.moveToMethod(payload)
      }
    },
    transitionMatterportPano (_oldIdentifier, newIdentifier) {
      this.transitionPano(newIdentifier)
      this.matterportLoaded = true
    },
    transitionPano (newPanoIdentifier) {
      if (this.currentPano.length > 0) {
        this.panoChanges.push({
          timestamp: new Date(),
          startingId: this.currentPano,
          endingId: newPanoIdentifier
        })
      }
      this.changePano(newPanoIdentifier)
      this.playTopicAudio(newPanoIdentifier)
      if (this.initiatedTimestamp && !this.hideMatterportIndicator) { this.resetIndicatorTimeout() }
    },
    playTopicAudio (panoIdentifier) {
      const topicText = this.topicsByPanoId[panoIdentifier]
      if (topicText && !(topicText in this.viewedTopics)) {
        // unseen topic
        // make it big
        // if it has audio, play and shrink on finish (or 5 seconds?)
        // else shrink after 3 seconds or w/e
        const audio = this.topicTTSLookup[topicText]
        if (audio) {
          audio.addEventListener('ended', () => {
            this.topicViewedTimeout()
          })
          window.setTimeout(() => {
            audio.play()
          }, 250)
        } else {
          window.setTimeout(() => {
            this.topicViewedTimeout()
          }, 3000)
        }
      }
    },
    startCountdown () {
      this.setActivePhase(this.PHASES.countdown)
      this.loadAudio()
    },
    startTour () {
      this.setActivePhase(this.PHASES.tour)
      this.initiateRecording(false)
      this.initiatedTimestamp = new Date()
      if (!this.hideMatterportIndicator) { this.resetIndicatorTimeout() }
      if (this.currentPano.length > 0) { this.playTopicAudio(this.currentPano) }
    },
    buildFormData (blob) {
      let formData = new FormData()
      formData.append('response[audio_response]', blob)
      formData.append('response[starting_timestamp]', this.initiatedTimestamp)
      // add pano changes
      for (let i = 0; i < this.panoChanges.length; i++) {
        let prefix = 'response[panorama_changes][]'
        let panoChange = this.panoChanges[i]
        formData.append(prefix + '[timestamp]', panoChange.timestamp.toISOString())
        formData.append(prefix + '[starting_id]', panoChange.startingId)
        formData.append(prefix + '[ending_id]', panoChange.endingId)
      }

      for (let i = 0; i < this.movements.length; i++) {
        let prefix = 'response[movements][]'
        let movement = this.movements[i]
        formData.append(prefix + '[timestamp]', movement.timestamp.toISOString())
        formData.append(prefix + '[x_position]', movement.position[0])
        formData.append(prefix + '[y_position]', movement.position[1])
        formData.append(prefix + '[z_position]', movement.position[2])
        formData.append(prefix + '[x_rotation]', movement.rotation[0])
        formData.append(prefix + '[y_rotation]', movement.rotation[1])
      }
      return formData
    },
    triggerSubmitQuestionAnswer () {
      // the actual submitQuestionAnswer is triggered on mediaRecord.stop
      this.submitting = true
      this.$store.commit('toggleFullscreen', true)
      this.isRecording = false
      this.mediaRecorder.stop()
    },
    onRecordingAvailable (blob) {
      this.audioBlob = blob
      this.blobUrl = URL.createObjectURL(blob)
      if (!this.isDemoPreview) { this.setActivePhase(this.PHASES.replay) }

      this.$nextTick(() => {
        if (!this.isDemoPreview) { this.$refs.responseAudio.load() }
        this.submitResponseWithoutLoadingNextQuestion()
      })
    },
    answerData () {
      let answer = this.buildFormData(this.audioBlob)
      return answer
    },
    skipThisQuestion () {
      if (this.isSurveyPreview) {
        if (this.nextQuestionId) {
          window.location.href = `/questions/${this.nextQuestionId}/preview`
          return
        } else {
          window.location.href = `/study/${this.surveyId}/preview_complete`
          return
        }
      }
      this.createSkippedResponse()
    },
    createSkippedResponse () {
      this.$emit('loading', true)
      let url = '/questions/' + this.questionId + '/submit'
      let data = { skipped: true }
      this.$axios.request({
        url: url,
        method: 'post',
        data: { response: data }
      })
        .then(res => {
          if (res.data.surveyComplete) {
            window.location.href = `/study/${this.surveyId}/complete`
          } else {
            this.$emit('nextQuestion', res.data.nextQuestion)
          }
        })
        .catch(err => {
          console.log(err)
          this.$emit('errorMessage')
        })
    },
    resetRecording () {
      this.initiatedTimestamp = null
      // throw away old recording and create a new one
      this.mediaRecorder = null
      this.stream = null
      // start new one
      this.initializeNewMediaRecorder()
      //  reset movements
      this.panoChanges = []
      this.$nextTick(() => { this.setActivePhase(this.PHASES.countdown) })
    },
    submitResponseWithoutLoadingNextQuestion () {
      if (!this.isPreview) {
        this.$store.commit('toggleFullSpinner', true)
        let url = '/questions/' + this.questionId + '/submit'
        let data = this.answerData()
        this.$emit('loading', true)
        this.$axios.request({
          url: url,
          method: 'post',
          data: data
        })
          .then(res => {
            if (this.isDemoPreview) {
              window.location.href = '/prospective/thank_you'
            } else {
              this.$store.commit('toggleFullSpinner', false)
            }
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('toggleFullSpinner', false)
            this.$emit('errorMessage')
          })
      }
    }
  }
}
</script>
