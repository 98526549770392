<template>
  <div class="st-layout__body">
    <div class="st-body">
      <div class="bx--grid">
        <div class=" h-screen">
          <div class="flex justify-between  w-full h-20vh items-center ">
            <div class="flex items-center ">
              <img
                :src="logoUrl"
                class="max-w-200 max-h-40 object-cover"
                alt=""
              >
            </div>
          </div>
          <div class="mt-80p">
            <div
              class="flex flex-col space-y-5 md:space-y-0 md:flex-row justify-between my-auto md:space-x-10 space-x-0"
            >
              <div class="w-full md:w-1/2 flex flex-col">
                <div class="w-full ax-h-430 h-full">
                  <div
                    v-if="!isMediaUrl"
                    :class="{
                      'edit-cover-photo__photo': true,
                      'w-full object-cover max-h-430 h-full': true,
                      'edit-cover-photo__default': true,
                    }"
                  />
                  <img
                    v-if="isMediaUrl"
                    :src="questionMediaUrl"
                    alt=""
                    class="w-full object-cover max-h-430"
                  >
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <h2 class="font-38">
                  {{ survey.headline }}
                </h2>
                <p class="mt-40p">
                  {{ this.questions[0].description }}
                </p>
                <div v-if="!isCompleted">
                  <div
                    v-if="!isEmailSent && isRecordButton"
                    class="mt-60p"
                  >
                    <a
                      href="#"
                      class="custom-page-button"
                      :style="'background:' + primaryColor"
                      @click="handleContinue"
                    >Record Your Thoughts</a>
                  </div>
                  <div
                    v-else-if="isStopButton"
                    :class="{
                      'st-opinion-metric__stop-button-and-visualizer mt-40p': true,
                    }"
                  >
                    <!-- 'st-opinion-metric__record-thoughts--multi-media': hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
              'st-opinion-metric__record-thoughts--multi-media-no-description': hasMultipleMedia && isDesktopSurvey && !description,
              'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview -->
                    <!-- :width="canvasWidth" -->
                    <!-- :height="canvasHeight" -->
                    <canvas
                      v-if="isVisualizerVisible"
                      ref="audioCanvas"
                      :class="{
                        'st-opinion-metric__audio-visualizer w-full': true,
                        'st-opinion-metric__audio-visualizer--preview': isSpecsDesktopPreview,
                      }"
                    />

                    <span
                      v-if="!readyToSubmit && !replayAudio"
                      class="recording-button-red mt-20p"
                      @click="stopCounting"
                    >
                      <icon-wrapper
                        class="st-opinion-metric__stop-recording-button"
                        icon-name="stop-recording"
                      />
                      <span class="record-timer cursor-pointer">
                        <span>{{ mins < 9 ? `0${mins}` : mins }}</span>:<span>{{
                          seconds < 10 ? `0${seconds}` : seconds
                        }}</span>
                      </span>
                    </span>
                  </div>
                  <audio
                    v-if="isAudioPlayer"
                    ref="audioPlayer"
                    class="st-opinion-metric__audio-player mt-20p"
                    controls
                  >
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                  <div
                    v-if="readyToSubmit"
                    :class="{
                      'st-opinion-metric__ready-to-submit': true,
                    }"
                  >
                    <!-- 'st-opinion-metric__record-thoughts--multi-media':
                      hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
                    'st-opinion-metric__record-thoughts--multi-media-no-description':
                      hasMultipleMedia && isDesktopSurvey && !description,
                    'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview, -->

                    <div class="st-opinion-metric__recording-container">
                      <div class="st-opinion-metric__recording-col">
                        <span
                          v-if="readyToSubmit && !replayAudio"
                          @click="playAgain"
                        >
                          <icon-wrapper
                            icon-name="play-recording"
                            class="st-opinion-metric__play-recording-button"
                          />
                        </span>

                        <span
                          v-if="readyToSubmit && replayAudio"
                          @click="stopReplay"
                        >
                          <icon-wrapper
                            icon-name="pause-recording"
                            class="st-opinion-metric__play-recording-button"
                          />
                        </span>
                        <p
                          v-if="readyToSubmit"
                          class="st-opinion-metric__audio-duration"
                        >
                          {{ formatAudioDuration }}
                        </p>

                        <span class="line" />
                      </div>
                    </div>
                    <div
                      v-if="readyToSubmit"
                      class="bottom-button-container "
                    >
                      <div
                        class="rerecord-delete-button mt-20p"
                        :style="'background:' + secondaryColor"
                        @click="rerecord"
                      >
                        <span style="width:20px; height:20px">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </span>
                        <span>
                          Re-record
                        </span>
                      </div>

                      <div
                        class="submit-button-special mt-20p"
                        :style="'background:' + primaryColor"
                        @click="submitQuestionAnswer"
                      >
                        <span>
                          Submit
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="st-complete__column mt-20p"
                  style="height: 40vh"
                >
                  <p class="st-complete__text st-complete__text--header">
                    Thank you for submitting your feedback!
                  </p>
                  <div class="custom-button-container">
                    <st-input
                      v-if="survey.anonymous && !emailSubmitted"
                      v-model.trim="email"
                      class="w-full"
                      :label="inputLabel"
                      :variant="inputVariants"
                      @blur="handleInputFocusOut"
                      @focus="handleInputFocus"
                      @input="checkIfInputIsValid"
                    />
                    <div
                      v-if="survey.anonymous && !emailSubmitted"
                      class="w-full st-participant-invite-page__set-button-full-width"
                    >
                      <st-button
                        class="st-participant-invite-page__continue-button btn-submit"
                        caption="See what others are saying"
                        :underline="false"
                        :variant="['secondary']"
                        :disabled="!isEmailValid"
                        :style="'background:' + primaryColor"
                        @click="submitEmail"
                      />
                      <st-spinner v-if="submittingEmail" />
                    </div>
                  </div>
                  <div class="st-join-complete__additional-and-email-us flex flex-col">
                    <p class="st-complete__text">
                      We appreciate your time. If you have anything to share
                      please feel free to
                    </p>
                    <br>
                    <div class="st-complete__email-container">
                      <a
                        href="mailto:zak@askhumans.io"
                        class="st-complete__email-us"
                        :style="'color:' + primaryColor"
                      >
                        Email us
                      </a>
                      <icon-wrapper
                        class="st-complete__arrow"
                        icon-name="arrow-og"
                      />
                    </div>
                  </div>
                  <st-hyperlink
                    v-if="isPreview && !isDesktop"
                    text="Exit Preview"
                    icon-name="hide-password"
                    icon-class="st-hyperlink__default-icon-spacing"
                    :url="`/study/${survey.id}/publish`"
                  />

                  <div
                    :class="{
                      'st-powered-by-spacetrics st-complete__powered-by-complete': true,
                      'st-powered-by-spacetrics--desktop': isDesktop,
                    }"
                  >
                    Powered by
                    <icon-wrapper
                      class="st-powered-by-spacetrics__icon"
                      :actionable="false"
                      icon-name="spacetrics"
                    />
                    AskHumans
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import {
  formatSecondsToStopwatch,
  AnonymousEmailGenerator
} from '../../../utilities/data_utilities'
import { getBlobDuration } from '../../../utilities/audio_utilities'
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInput,
  StModal,
  StMediaUploader,
  StSpinner
} from '../../common'
import { ParticipantAvatar } from '../insights'
import SurveyHubCard from './survey-hub-card'
import SurveyMembers from './survey-members'
import { any } from 'expect'
import {
  audioRecorder,
  handlePreviewBack,
  isDesktop,
  previousResponse,
  submitQuestionAnswer
} from '../../../mixins'
import { isValidEmail } from '../../../utilities/validation_utilities'
import { buttonPrimaryColor, buttonSecondaryColor } from './constants'
export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StInput,
    StModal,
    SurveyHubCard,
    StMediaUploader,
    SurveyMembers,
    ParticipantAvatar,
    StSpinner
  },
  mixins: [
    audioRecorder,
    handlePreviewBack,
    isDesktop,
    previousResponse,
    submitQuestionAnswer
  ],
  props: {
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    survey: {
      type: Object,
      required: true
    },

    account: {
      type: Object
    },

    questions: {
      type: any
    }
  },
  data: function () {
    return {
      isMediaUrl: false,
      logoUrl: '',
      questionMediaUrl: '',
      surveyInfo: this.survey,
      isEmailSent: false,
      seconds: 0,
      mins: 0,
      intervalId: 0,
      replayAudio: false,
      audioBlob: null,
      audioDuration: null,
      errorMessage: '',
      imageWidth: 0,
      lightboxImageSrc: '',
      mobileWindowScrollDistance: 0,
      multiMediaImagesScrollDistance: 0,
      isStickyQuestionActive: false,
      overflowDescription: false,
      readyToSubmit: false, // Change this later
      replayAudio: false,
      resizeObserver: null,
      showExpanedPhoto: false,
      surveyWrapperScrollDistance: 0,
      textAreaFocused: false,
      writtenResponse: '',
      zoomLevel: 100,
      showInstructions: this.showQuestionInstructions,
      audioPermissionGranted: false,
      showToast: false,
      noRedirect: true,
      isCompleted: false,
      email: '',
      showEmailError: false,
      submittingEmail: false,
      emailSubmitted: false,
      primaryColor: buttonPrimaryColor,
      secondaryColor: buttonSecondaryColor
    }
  },
  mounted: function () {
    if (this.survey.customPageLogoUrl) {
      this.logoUrl = this.survey.customPageLogoUrl
    }
    if (this.survey.customPageCoverUrl) {
      this.isMediaUrl = true
      this.questionMediaUrl = this.survey.customPageCoverUrl
    }
    if (this.survey.primaryColor) {
      this.primaryColor = this.survey.primaryColor
    }
    if (this.survey.secondaryColor) {
      this.secondaryColor = this.survey.secondaryColor
    }
  },
  computed: {
    ...mapState('participants', {
      participantActiveQuestion: 'activeQuestion',
      opinionMetricPhase: 'opinionMetricPhase'
    }),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    ...mapGetters('participants', ['PHASES']),
    activeQuestion () {
      return this.isSpecsDesktopPreview
        ? this.specsActiveQuestion
        : this.participantActiveQuestion
    },

    questionId () {
      return this.survey.firstQuestionId
    },

    inputLabel () {
      return this.showEmailError ? 'Email is not valid' : 'Enter your email'
    },
    inputVariants () {
      return this.showEmailError
        ? ['fullBorder', 'largeFieldLabel', 'persistLabelAbove', 'invalid']
        : ['fullBorder', 'largeFieldLabel', 'persistLabelAbove']
    },
    isEmailValid () {
      return isValidEmail(this.email)
    },

    contentType () {
      if (!this.hasSingleMedia) {
        return ''
      }
      if (!this.media.hasOwnProperty('contentType')) {
        return ''
      }
      return this.media.contentType
    },
    errorTooltipWidth () {
      return this.isDesktop ? 411 : window.innerWidth - 40
    },
    formatAudioDuration () {
      return formatSecondsToStopwatch(this.audioDuration)
    },
    hasExternalMedia () {
      return this.activeQuestion.externalVideoUrl
    },
    hasMultipleMedia () {
      return this.options.length > 1
    },
    hasNoMedia () {
      return (
        !this.hasSingleMedia && !this.hasMultipleMedia && !this.hasExternalMedia
      )
    },
    hasSingleMedia () {
      return this.options.length === 1
    },
    hasResponseType () {
      return this.askForExplanation
    },
    isAudioPlayer () {
      return this.audioBlob !== null
    },
    isExpandPhotoIcon () {
      return this.isDesktop
        ? !this.isSpecificationsPreview
        : !this.isSpecificationsPreview && !this.textAreaFocused
    },
    isDesktopSurvey () {
      return this.isDesktop && !this.isPreview
    },
    isDesktopSurveyPreview () {
      return this.isDesktop && this.isSurveyPreview
    },
    isMediaVideo () {
      if (!this.options[0]) {
        return false
      } else {
        return this.options[0].media.contentType.split('/')[0] === 'video'
      }
    },
    isMultiMediaDesktopSurveyPreview () {
      return this.hasMultipleMedia && this.isDesktopSurveyPreview
    },
    isResponseTypeText () {
      return this.askForExplanation === 'text'
    },
    isSpecificationsPreview () {
      return this.isPreview && !this.isSurveyPreview
    },
    isSpecsDesktopPreview () {
      return this.isSpecificationsPreview && this.isDesktop
    },
    isSubmissionSkippable () {
      return false
    },
    mediaExtension () {
      return !this.hasSingleMedia
        ? ''
        : this.options[0].mediaUrl.split('.').pop()
    },
    mediaFormatCategory () {
      return !this.hasSingleMedia
        ? ''
        : this.options[0].media.contentType.split('/')[0]
    },
    mediaFullUrl () {
      return this.options ? this.options[0].mediaFullUrl : ''
    },
    mediaUrl () {
      return this.options ? this.options[0].mediaUrl : ''
    },
    nextButtonTextArea () {
      return this.writtenResponse.length && !this.textAreaFocused
    },
    imageStyle () {
      if (this.zoomLevel <= 100) {
        return {
          marginLeft: `${100 - this.zoomLevel}%`,
          marginRight: `${100 - this.zoomLevel}%`
        }
      } else if (this.zoomLevel > 100) {
        return {
          width: `${this.imageWidth * (this.zoomLevel / 100)}px`
        }
      } else {
        return {}
      }
    },
    showAudioInstructions () {
      // display instructions only when question has voice recording requirement
      if (
        this.askForExplanation === 'voice_recording' &&
        this.showInstructions
      ) {
        // for specs preview page
        if (!this.isPreview && !this.isSurveyPreview) return true
        // for participant page
        else if (this.isPreview && this.isSurveyPreview) return true
        else return false
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapMutations('participants', ['incrementInstructionsPage']),
    answerData () {
      let answer = new FormData()
      answer.append(
        ...(this.isResponseTypeText
          ? ['response[transcript]', this.writtenResponse]
          : ['response[audio_response]', this.audioBlob])
      )
      return answer
    },
    handleButtonAction () {
      return this.tryInitializingMediaRecorder()
    },
    handleEmailSent () {
      this.isEmailSent = true
      this.handleButtonAction()
        .then((res) => {
          this.countingTimeSpent()
        })
        .catch((error) => alert(JSON.stringify(error, null, 4)))
    },
    handleSubmission () {
      this.isCompleted = true
    },
    checkIfInputIsValid () {
      if (this.showEmailError && this.isEmailValid) {
        this.showEmailError = false
      }
    },
    handleInputFocusOut () {
      this.showEmailError = !this.isEmailValid
      if (this.email === '') {
        this.showEmailError = false
      }
    },
    handleInputFocus () {
      if (!this.isDesktop) {
        this.showPoweredBy = false
      }
    },
    loadResponses () {
      if (
        !this.isResponseTypeText &&
        this.questionResponses &&
        this.questionResponses.length > 0 &&
        !this.showAudioInstructions
      ) {
        this.$nextTick(() => {
          this.$refs.audioPlayer.src = this.questionResponses[0].srcUrl
          this.$refs.audioPlayer.load()
          this.readyToSubmit = true
        })
      }
    },
    onRecordingAvailable (blob) {
      this.audioBlob = blob
      this.readyToSubmit = true
      getBlobDuration(this.audioBlob).then((duration) => {
        this.audioDuration = duration
      })

      this.$nextTick(() => {
        this.setAudioSrc()
      })
    },
    playAgain () {
      this.$refs.audioPlayer.play()
      this.$refs.audioPlayer.ontimeupdate = () => {
        this.audioDuration = this.$refs.audioPlayer.currentTime
        if (
          this.$refs.audioPlayer.duration === this.$refs.audioPlayer.currentTime
        ) {
          this.replayAudio = false
        }
      }
      this.replayAudio = true
    },

    setAudioSrc (audioUrl = '') {
      if (!this.isSpecificationsPreview) {
        audioUrl = window.URL.createObjectURL(this.audioBlob)
      }
      this.$refs.audioPlayer.src = audioUrl
      this.$refs.audioPlayer.load()
    },
    stopReplay () {
      this.replayAudio = false
      this.$refs.audioPlayer.pause()
    },
    countingTimeSpent () {
      this.initiateRecording()
      this.intervalId = setInterval(() => {
        this.seconds++
        if (this.seconds > 59) {
          this.seconds = 0
          this.mins++
        }
      }, 1000)
    },
    stopCounting () {
      clearInterval(this.intervalId)
      this.stopRecording()
      this.readyToSubmit = true
      this.seconds = 0
      this.minutes = 0
    },

    playAgain () {
      this.$refs.audioPlayer.play()
      this.$refs.audioPlayer.ontimeupdate = () => {
        this.audioDuration = this.$refs.audioPlayer.currentTime
        if (
          this.$refs.audioPlayer.duration === this.$refs.audioPlayer.currentTime
        ) {
          this.replayAudio = false
        }
      }
      this.replayAudio = true
    },
    rerecord () {
      this.audioBlob = null
      this.isRecording = false
      this.readyToSubmit = false
      this.countingTimeSpent()
    },
    isRecordButton () {
      if (this.mediaRecorder === null) {
        return false
      }
      if (this.isRecording) {
        return false
      }
      if (this.audioBlob !== null) {
        return false
      }
      return true
    },
    isStopButton () {
      return this.isRecording
    },

    submitEmail () {
      this.submittingEmail = true
      const params = new URLSearchParams(window.location.search)
      const url = `/survey_responses/submit_participant_email`
      this.$axios
        .request({
          url: url,
          method: 'post',
          data: { email: this.email, survey_id: this.survey.id }
        })
        .then((res) => {
          this.submittingEmail = false
          this.emailSubmitted = true
        })
        .catch((err) => {
          this.submittingEmail = false
        })
    },
    handleContinue () {
      const anonymousEmail = AnonymousEmailGenerator()

      let url = `/study/${this.survey.id}/response`
      this.$axios
        .request({
          method: 'post',
          url: url,
          data: {
            participant: { email: anonymousEmail },
            survey: this.survey
          }
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data)
            this.handleEmailSent()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
