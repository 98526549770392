<template>
  <div class="tw-relative">
    <span
      class="tw-absolute -tw-right-2 tw-cursor-pointer -tw-top-2"
      @click="togglePanel('segments')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

    </span>
    <div
      v-if="!withoutHeader"
      class="st-survey-insights__panel-title st-survey-insights__segment-panel-title"
    >
      Segments
    </div>
    <div
      :class="{
        'st-survey-insights__panel-text': true,
        'st-survey-insights__panel-text--invert': invert
      }"
    >
      {{ resultsCountText }}
    </div>
    <div
      v-if="skippedCount>0"
      class="st-survey-insights__skipped-text"
    >
      {{ skippedCountText }}
    </div>
    <segment-accordion
      v-for="(segment, idx) in segments"
      :key="'segment- '+ segment.segmentId"
      :class="{
        'st-survey-insights__panel-section-with-line': segments.length>1 && idx !== segments.length - 1
      }"
      :segment="segment"
      :idx="idx"
      :invert="invert"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import SegmentAccordion from './segment-accordion'

export default {
  components: {
    'segment-accordion': SegmentAccordion
  },
  props: {
    withoutHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    invert: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      hover: false
    }
  },
  computed: {
    ...mapState('insights', ['segments', 'skippedCount']),
    ...mapGetters('insights', ['responsesPassingParticipantCheckboxFilter']),
    resultsCountText () {
      if (this.responsesPassingParticipantCheckboxFilter.length === 1) {
        return 'Displaying 1 result'
      } else {
        return `Displaying ${this.responsesPassingParticipantCheckboxFilter.length} results`
      }
    },
    skippedCountText () {
      if (this.skippedCount === 1) {
        return '1 participant skipped this question'
      } else {
        return `${this.skippedCount} participants skipped this question`
      }
    }
  },
  methods: {
    ...mapMutations('insights', ['togglePanel'])
  }
}
</script>
