<template>
  <div
    v-if="loading"
    class="px-5 tw-flex tw-flex-col tw-h-[700px] tw-justify-center tw-items-center tw-relative"
  >
    <ah-small-loader />
  </div>
  <div
    v-else-if="generatedQuestions.length && !showThinkingScreen && !loading"
    class="tw-p-2 tw-my-4 tw-h-full tw-flex tw-flex-col tw-justify-between"
  >
    <div
      class="tw-max-h-[750px]  scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black  tw-overflow-auto"
    >
      <div class="tw-flex tw-items-center   tw-cursor-pointer">
        <div
          class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-shrink-0 tw-justify-center tw-rounded-full tw-bg-primary-light"
          @click="goBack"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        <p class="tw-w-full tw-text-center tw-pr-12 ">
          Generated question
        </p>
      </div>
      <!-- <ah-button
          class="tw-w-full"
          type="secondary"
          size="large"
          @click="goBack"
        >
          Go back
        </ah-button> -->
      <!-- <p class="tw-my-3 tw-text-center tw-font-semibold">
        Generated Questions
      </p> -->

      <div
        v-for="(question, index) in generatedQuestions"
        :key="`${index}_question`"
        class="tw-flex tw-cursor-pointer tw-justify-between tw-items-center tw-rounded-xl tw-my-3 tw-border tw-border-white-two tw-border-solid tw-px-3 tw-py-3 "
        @click="generateAnswer(question, 'Guided question')"
      >
        <p class="tw-flex tw-space-x-3 tw-items-center">
          <span class=" tw-text-lg">
            {{ question }}
          </span>
        </p>
      </div>
    </div>
    <div class="tw-my-3 tw-w-full">
      <ah-button
        class="tw-w-full"
        size="large"
        @click="getQuestions"
      >
        Generate more questions
      </ah-button>
    </div>
  </div>
  <div
    v-else-if="showThinkingScreen"
    class="px-5 tw-flex tw-flex-col tw-h-full tw-justify-between tw-relative"
  >
    <div class="">
      <div class="tw-mt-16 tw-mb-5 tw-flex tw-justify-center">
        <!-- <img
          src="http://via.placeholder.com/200"
          class="tw-rounded-lg"
          alt=""
        > -->
      </div>
      <p class="tw-text-2xl tw-font-semibold tw-my-4">
        Thinking. . . feel free to close this window and come back in a minute
      </p>
      <text-divider text="Selected studies" />

      <div
        class="tw-flex tw-justify-between tw-items-center tw-border-b tw-border-white-two tw-border-solid tw-px-3 tw-py-4"
      >
        <p
          v-for="(study, index) in selectedStudies"
          :key="`${index}-study`"
          class="tw-flex tw-flex-col "
        >
          <span class=" tw-text-lg">
            {{ study.name }}
          </span>
          <span class="tw-text-sm">
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.50558 6.50013C7.15973 6.50013 8.45965 5.18022 8.45965 3.50006C8.45965 1.8199 7.15999 0.5 5.50558 0.5C3.85144 0.5 2.55151 1.82016 2.55151 3.50006C2.55151 5.17997 3.85144 6.50013 5.50558 6.50013ZM11 11.78C10.232 8.72003 7.15973 6.79995 4.14667 7.58012C2.13804 8.12012 0.542885 9.68019 0.0111674 11.78C-0.0478279 12.0801 0.129411 12.4401 0.48389 12.5H10.4093C10.7638 12.5 11 12.2601 11 11.9001V11.78Z"
                fill="#A7AA93"
              />
            </svg>

            <span>
              {{ study.totalResponsesCompleted }}
              <span v-if="study.totalRespondents">
                of {{ study.totalRespondents }} respondents
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
    <div
      class=" tw-w-full tw-sticky tw-py-5 tw-bottom-0 tw-right-0 tw-bg-white"
    >
      <ah-button
        class="tw-w-full"
        type="secondary"
        size="large"
        @click="goBack"
      >
        Go back
      </ah-button>
    </div>
  </div>
  <div v-else-if="showInitialScreen">
    <div class="tw-mt-5 ">
      <text-divider text="Studies" />
    </div>
    <div
      id="select-studies-view"
      class=" tw-mt-5"
    >
      <st-dropdown-menu
        caption="Select studies"
        :initial-active-option="initialActiveOption"
        :full-width="true"
        :options="studyNameList"
        @updateOption="addStudy"
      />
      <div
        v-for="study in selectedStudies"
        :key="study.name"
        class="tw-flex tw-justify-between tw-items-center tw-border-b tw-border-white-two tw-border-solid tw-px-3 tw-py-4"
      >
        <p class="tw-flex tw-flex-col ">
          <span class=" tw-text-lg">
            {{ study.name }}
          </span>
          <span class="tw-text-sm">
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.50558 6.50013C7.15973 6.50013 8.45965 5.18022 8.45965 3.50006C8.45965 1.8199 7.15999 0.5 5.50558 0.5C3.85144 0.5 2.55151 1.82016 2.55151 3.50006C2.55151 5.17997 3.85144 6.50013 5.50558 6.50013ZM11 11.78C10.232 8.72003 7.15973 6.79995 4.14667 7.58012C2.13804 8.12012 0.542885 9.68019 0.0111674 11.78C-0.0478279 12.0801 0.129411 12.4401 0.48389 12.5H10.4093C10.7638 12.5 11 12.2601 11 11.9001V11.78Z"
                fill="#A7AA93"
              />
            </svg>
            {{ study.totalResponsesCompleted }}
            <span v-if="study.totalRespondents">
              of {{ study.totalRespondents }} respondents
            </span>
          </span>
        </p>
        <span
          class="tw-cursor-pointer"
          @click="removeStudy(study)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="tw-w-5 tw-h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>
      </div>
    </div>
    <div

      class=" tw-pt-5"
    >
      <div
        id="ask-question-view"
        class="tw-border-white-three tw-border tw-rounded-xl tw-flex tw-border-solid tw-overflow-hidden"
      >
        <span
          class="tw-block tw-w-1/2 tw-px-2 tw-py-4 tw-text-center tw-cursor-pointer"
          :class="{
            'tw-bg-territory tw-text-white': activeSubTab === 'suggested',
          }"
          @click="setActiveSubTab('suggested')"
        >
          Suggested questions
        </span>
        <span
          class="tw-block tw-w-1/2 tw-px-2 tw-py-4 tw-text-center tw-cursor-pointer"
          :class="{
            'tw-bg-territory tw-text-white': activeSubTab === 'openEnded',
          }"
          @click="setActiveSubTab('openEnded')"
        >
          Open-ended questions
        </span>
      </div>
      <div v-if="activeSubTab === 'suggested'">
        <p class="tw-text-center tw-my-5">
          We will generate questions that will help you have a better
          understanding of the selected studies.
        </p>
        <div class="tw-my-3">
          <ah-button
            :disabled="selectedStudies.length == 0"
            size="large"
            class="tw-w-full"
            @click="getQuestions"
          >
            Generate questions
          </ah-button>
        </div>
      </div>
      <div v-if="activeSubTab === 'openEnded'">
        <div
          class="tw-px-1 tw-py-5 tw-flex-col tw-justify-center tw-items-center tw-gap-5 tw-inline-flex"
        >
          <div class="tw-text tw-text-stone-700 tw-text-base">
            Formulate a question connected with the studies selected, and we'll
            supply an answer designed to enhance your comprehension in those
            subjects.
          </div>
          <ah-input
            v-model="suggestedQuestion"
            :disabled="selectedStudies.length == 0"
            placeholder="Write here your question "
            classes="disabled:tw-cursor-not-allowed"
          />
          <ah-button
            size="large"
            :disabled="suggestedQuestion == '' || selectedStudies.length == 0"
            class="tw-w-full"
            @click="generateAnswer(suggestedQuestion, 'Open ended question')"
          >
            Generate answer
          </ah-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StDrawer from '../st-drawer.vue'
import AhInput from '../ah-input.vue'
import StCheckbox from '../st-checkbox.vue'
import AhButton from '../ah-button.vue'
import StDropdownMenu from '../st-dropdown-menu.vue'
import TextDivider from '../text-divider.vue'
import AhSmallLoader from '../ah-small-loader.vue'
export default {
  components: {
    StDropdownMenu,
    TextDivider,
    AhButton,
    StCheckbox,
    AhInput,
    StDrawer,
    AhSmallLoader
  },

  props: {
    studies: {
      type: Array,
      required: true
    },
    showNotification: {
      type: Function,
      require: false,
      default: () => {}
    }
  },

  data () {
    return {
      showDrawer: false,
      activeTab: 'generate',
      activeSubTab: 'suggested',
      generatedQuestions: [],
      suggestedQuestion: '',
      studyList: [],
      studyNameList: [],
      selectedStudies: [],
      loading: false,
      showThinkingScreen: false,
      initialActiveOption: 'Select studies',
      timeout: 10000 * 60 * 5, // 5 minutes
      successMessage: null,
      showInitialScreen: true
    }
  },

  mounted () {
    this.studyList = this.studies.filter(study =>
      study.plans.some(plan => plan.title.toLowerCase().includes('answers'))
    )

    this.studyNameList = this.studyList.map((study) => study.name)

    // setTimeout(() => {
    //   this.$emit('showNotification')
    // }, 2000)
  },

  methods: {
    addStudy (study) {
      const newStudy = this.studyList.find((survey) => survey.name === study)
      if (!this.selectedStudies.includes(newStudy.name)) {
        this.selectedStudies.push(newStudy)
      }
      this.initialActiveOption = 'Select studies'
    },

    removeStudy (study) {
      this.selectedStudies = this.selectedStudies.filter(
        (selectedStudy) => selectedStudy.name !== study.name
      )
    },

    setActiveSubTab (subTabName) {
      this.activeSubTab = subTabName
    },

    getQuestions () {
      this.generatedQuestions = []
      this.loading = true
      this.$axios({
        method: 'post',
        url: `/projects/generate_suggested_question`,
        data: {
          survey_ids: this.selectedStudies.map((survey) => survey.id)
        }
      })
        .then((response) => {
          this.generatedQuestions = response.data.result
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    goBack () {
      this.showThinkingScreen = false
      this.showInitialScreen = true
      this.generatedQuestions = []
    },

    generateAnswer (question, type) {
      this.$axios({
        method: 'post',
        url: `/projects/cloud_suggested_answer`,
        data: {
          suggested_question: question,
          survey_ids: this.selectedStudies.map((survey) => survey.id),
          question_type: type
        }
      })
        .then((response) => {
          this.showThinkingScreen = true
          this.suggestedQuestion = ''

          const { history_id, last_updated_at } = response.data
          this.$emit('showNotification', history_id, last_updated_at)
          // this.checkHistoryUpdates(history_id, last_updated_at)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // async checkHistoryUpdates (history_id, last_updated_at) {
    //   const start_time = Date.now()

    //   while (true) {
    //     const updatedHistory = await this.fetchHistoryUpdates(
    //       history_id,
    //       last_updated_at
    //     )

    //     if (updatedHistory.success) {
    //       this.successMessage = 'History updated successfully.'
    //       console.log(this.successMessage)
    //       return
    //     }

    //     if (Date.now() - start_time >= this.timeout) {
    //       return
    //     }

    //     await this.sleep(10000)
    //   }
    // },
    // async fetchHistoryUpdates (history_id, last_updated_at) {
    //   const result = await this.$axios.request({
    //     method: 'post',
    //     url: `/projects/long_polling/${history_id}`,
    //     data: {
    //       last_updated_at: last_updated_at
    //     }
    //   })
    //   return result.data
    // },
    // sleep (ms) {
    //   return new Promise((resolve) => setTimeout(resolve, ms))
    // },
    startCheckingHistory () {
      this.successMessage = null // Clear any previous success message
      this.checkHistoryUpdates()
    }
  }
}
</script>

<style lang="scss" scoped></style>
