<template>
  <st-drawer v-model="showDrawer" @closeModal="closingModal">
    <template v-slot:modalTitle>
      <div class="flex justify-between  ">
        <span class="font-18p">
          {{ title }}
        </span>
      </div>
    </template>

    <template v-slot:modalActions>
      <!-- <div class="py-7  tw-bg-[#494A3D] tw-text-white ">
        <p class="text-center text-1p4-rem tw-font-semibold">
          Spotify Vs. Apple Music
        </p>
      </div> -->

      <div
        class="tw-flex tw-space-x-1 tw-justify-between  tw-h-full tw-flex-col"
      >
        <div>
          <div class="tw-bg-black tw-text-white tw-px-10 tw-py-12">
            <p class="tw-text-[30px] tw-mt-2 tw-leading-tight tw-font-semibold">
              {{ description }}
            </p>
            <!-- <p v-if="action!=='add'" class="tw-text-white tw-mt-3">
            However, all history related to this study will remain recorded in your account.
          </p> -->
          </div>
          <div class="tw-px-10">
            <div
              class="tw-py-4 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"
            >
              <p class="tw-text-sm tw-text-black-three">
                Study
              </p>
              <p class="tw-text-base tw-font-semibold">
                {{ study.name }}
              </p>
            </div>

            <div
              v-if="action == 'upgrade' || action == 'downgrade'"
              class="tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid tw-flex tw-space-x-3 tw-justify-between"
            >
              <div>
                <p class="tw-text-sm tw-text-black-three">
                  Current plan
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{ currentStudyBasePlan && currentStudyBasePlan.title }}
                </p>
                <p class="tw-text-[15px]">
                  ${{
                    currentStudyBasePlan &&
                      currentStudyBasePlan.price.toLocaleString()
                  }}
                  per month
                </p>
              </div>
              <div>
                <p class="tw-text-sm tw-text-black-three">
                  New plan
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{ newPlan && newPlan.title }}
                </p>
                <p class="tw-text-[15px]">
                  ${{ newPlan && newPlan.price.toLocaleString() }} per month
                </p>
              </div>
            </div>
            <div
              v-if="answersPlan"
              class="tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"
            >
              <p class="tw-text-sm tw-text-black-three">
                Add on
              </p>
              <p class="tw-text-base tw-font-semibold">
                {{ type == "answers" ? "Answers" : "Answers & Reports" }}
              </p>
              <p class="tw-text-[15px]">
                ${{ answersPlan && answersPlan.price.toLocaleString() }} per
                month
              </p>
            </div>
            <template v-if="action != 'remove'">
              <div
                v-if="
                  checkCreditBalance &&
                    paymentInformation &&
                    !loadingPaymentInformation
                "
                class="tw-py-3 tw-h-full tw-flex tw-justify-between tw-items-center tw-border-b tw-border-b-white-two tw-border-solid"
              >
                <div>
                  <p class="tw-text-sm tw-text-black-three">
                    Payment method
                  </p>

                  <p class="tw-text-base tw-font-semibold">
                    {{ paymentInformation.brand }} ending
                    {{ paymentInformation.last4 }}
                  </p>
                </div>
                <p
                  class="tw-text-sm tw-text-primary tw-cursor-pointer"
                  @click="changePaymentPlan"
                >
                  Change payment method
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  Credit balance
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    creditBalance >= 0
                      ? `$${creditBalance.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  To be charged
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    proratedPrice !== null
                      ? `$${proratedPrice.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  Remaining credit balance
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    remainingCreditBalance !== null
                      ? `$${remainingCreditBalance.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  Total
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    currentAmountDue >= 0
                      ? `$${currentAmountDue.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  To be charged every month after
                </p>
                <p v-if="newPlan" class="tw-text-base tw-font-semibold">
                  {{
                    newPlan
                      ? `$${newPlan.price.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
            </template>
          </div>
        </div>
        <div v-if="!loadingPaymentInformation" class="tw-px-10">
          <button
            v-if="!checkCreditBalance && !paymentInformation"
            @click="changePaymentPlan"
            class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
          >
            Add payment method
          </button>
          <button
            v-else
            class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
            @click="upgradeDowngradeHandler"
          >
            {{ buttonTextUpgradingDowngrading }}
          </button>
        </div>
      </div>
    </template>
    <stripe-payment-form ref="stripeForm" type="update" />
  </st-drawer>
</template>

<script>
// import { StDrawer, SecondaryButton, ToggleButton, SentimentChip } from "./";
import StDrawer from "./st-drawer.vue";
import SecondaryButton from "./secondary-button.vue";
import ToggleButton from "./toggle-button.vue";
import SentimentChip from "./sentiment-chip.vue";
import StripePaymentForm from "./stripe-payment-form.vue";
import { mapState } from "vuex";
export default {
  components: {
    StDrawer,
    SecondaryButton,
    ToggleButton,
    StripePaymentForm,
    SentimentChip,
  },
  props: {
    type: {
      type: String,
      require: true,
      default: "",
    },
    study: {
      type: Object,
      require: true,
      default: () => {},
    },
    action: {
      type: String,
      require: false,
      default: "add",
    },
    close: {
      type: Function,
      require: false,
      default: () => {},
    },
    newPlan: {
      type: Object,
      require: false,
      default: () => {},
    },
    answersPlan: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      paymentInformation: null,
      showDrawer: false,
      allPlans: [],
      currentStudyBasePlan: null,
      currentAnswerStrategyPlan: null,
      proratedPrice: null,
      remainingCreditBalance: null,
      loadingPaymentInformation: false,
    };
  },
  computed: {
    ...mapState("stripe", ["creditBalance"]),
    currentAmountDue() {
      return this.totalAmount();
    },
    checkCreditBalance() {
      return this.totalAmount() > 0 ? false : true;
    },
    title() {
      return this.action == "upgrade" ? "Upgrade plan" : "Downgrade plan";
    },
    description() {
      if (this.action == "upgrade") {
        return `You are about to upgrade your plan to “${
          this.newPlan ? this.newPlan.title : ""
        }”`;
      } else
        return `You are about to downgrade your plan to “${
          this.newPlan.title ? this.newPlan.title : ""
        }”`;
    },
    buttonText() {
      return this.type == "answers" ? "Add answers" : "Add strategy";
    },
    buttonTextForRemoving() {
      return this.type == "answers" ? "Remove answers" : "Remove strategy";
    },
    buttonTextUpgradingDowngrading() {
      return this.action == "upgrade" ? "Upgrade" : "Downgrade";
    },
  },
  mounted() {
    this.getAllPlans();
    this.checkPaymentInformation();
    setTimeout(() => {
      this.filterCurrentPlan();
    }, 200);
  },
  // watch for type to change
  watch: {
    type: function(val) {
      this.filterCurrentPlan();
    },
  },
  methods: {
    changePaymentPlan() {
      this.$refs.stripeForm.toggle();
    },
    totalAmount() {
      const totalAmount = this.creditBalance - this.proratedPrice;
      return totalAmount > 0 ? 0 : Math.abs(totalAmount);
    },
    closingModal() {
      this.$emit("close");
      this.showDrawer = false;
    },
    upgradeDowngradeHandler() {
      this.$store.commit("toggleFullSpinner", true);
      let plan_ids_to_upgrade = [this.newPlan?.id];

      if (
        this.answersPlan &&
        this.answersPlan.plan_type === this.newPlan.plan_type
      ) {
        plan_ids_to_upgrade.push(this.answersPlan.id);
      }

      this.$axios
        .request({
          method: "post",
          url: "payments/update_subscription",

          data: {
            plan_id: plan_ids_to_upgrade,
            survey_id: this.study.id,
          },
        })
        .then((res) => {
          // console.log(res)
          this.showDrawer = false;
          // this.$store.commit("toggleFullSpinner", false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleFullSpinner", false);
        });
    },
    removePlanFromStudy() {
      const currentAnswerStrategyPlan = this.study.plans.find((plan) => {
        return plan.title.toLowerCase().includes(this.type);
      });
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "post",
          url: `payments/cancel_subscription`,
          data: {
            survey_id: this.study.id,
            price_id: currentAnswerStrategyPlan.priceId,
          },
        })
        .then(({ data }) => {
          // console.log(data);
          this.showDrawer = false;
          window.location.reload();
          // this.$store.commit("toggleFullSpinner", false);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleFullSpinner", false);
        });
    },

    checkPaymentInformation() {
      this.loadingPaymentInformation = true;
      this.$axios
        .request({
          method: "get",
          url: `payments/payment_information`,
        })
        .then(({ data }) => {
          if (!data.data) {
            this.loadingPaymentInformation = false;
            return;
          }
          const info = {};
          info.brand = data.data.brand;
          info.last4 = data.data.last4;
          info.expireYear = data.data.exp_year;
          info.expireMonth =
            data.data.exp_month < 10
              ? "0" + data.data.exp_month
              : data.data.exp_month;
          this.paymentInformation = { ...info };
          this.loadingPaymentInformation = false;
        })
        .catch((err) => {
          this.loadingPaymentInformation = false;
          console.log(err);
        });
    },
    populateProratedCharges() {
      const plan_ids_to_calculate = [this.newPlan?.id];
      if (
        this.answersPlan &&
        this.answersPlan.plan_type === this.newPlan.plan_type
      ) {
        plan_ids_to_calculate.push(this.answersPlan.id);
      }
      this.$axios
        .request({
          method: "post",
          url: "payments/calculate_upcoming_invoice",
          data: {
            survey_id: this.study.id,
            plan_id: [...plan_ids_to_calculate],
          },
        })
        .then((res) => {
          const { upcoming_invoice: upcomingInvoice } = res.data;
          this.proratedPrice = upcomingInvoice.prorated_price;
          this.remainingCreditBalance =
            upcomingInvoice.remaining_credit_balance;
        })
        .catch((err) => {
          console.log(err);
        });
      // })
    },

    getAllPlans() {
      this.$axios
        .request({
          method: "get",
          url: `plans`,
        })
        .then(({ data }) => {
          this.allPlans = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterCurrentPlan() {
      const plansForCurrentStudy = this.allPlans.filter((plan) => {
        return plan.title.toLowerCase().includes("answers") == false;
      });

      const currentStudyPlan = this.study.plans.find((plan) => {
        return plan.title.toLowerCase().includes("answers") == false;
      });

      this.currentStudyBasePlan = currentStudyPlan;
      this.populateProratedCharges();
      // if(this.type== 'answers'){
      //   return
      // }
      // else{
      //   this.currentStudyBasePlan = currentPlanType[1]
      //   return
      // }
    },
    toggleDrawer() {
      this.showDrawer = true;
    },
    changePaymentPlan() {
      this.$refs.stripeForm.toggle();
    },
    addPlanToStudy() {
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "post",
          url: `payments/create_subscription`,
          data: {
            price_id: this.currentStudyBasePlan?.stripe_price_id,
            survey_id: this.study.id,
          },
        })
        .then(({ data }) => {
          // console.log(data);
          this.showDrawer = false;
          // this.$store.commit("toggleFullSpinner", false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleFullSpinner", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
