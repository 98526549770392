<template>
  <div class="bx--col-sm-12 bx--col-xs-12">
    <div class="tw-max-w-full tw-mx-auto tw-mt-8 tw-font-mono">
      <div class="tw-w-full tw-mb-8 tw-overflow-hidden">
        <div class="tw-w-full tw-overflow-x-auto">
          <div class="tw-w-full">
            <div
              class="tw-tracking-wide tw-grid tw-grid-cols-12 tw-my-2 tw-justify-between tw-text-left tw-text-gray-900 tw-text-sm tw-border-solid tw-border-b tw-border-gray-100"
            >
              <div class="tw-px-2 tw-py-3">
                Status
              </div>
              <div
                class="tw-px-2 tw-py-3 tw-cursor-pointer"
                @click="sortByFirstName"
              >
                First name  <svg
                  class="tw-inline-block tw-ml-1 tw-align-middle tw-fill-current tw-text-gray-500"
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                    fill="#747474"
                  />
                </svg>
              </div>
              <div
                class="tw-px-2 tw-py-3 tw-cursor-pointer"
                @click="sortByLastName"
              >
                Last name  <svg
                  class="tw-inline-block tw-ml-1 tw-align-middle tw-fill-current tw-text-gray-500"
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                    fill="#747474"
                  />
                </svg>
              </div>
              <div
                class="tw-px-2 tw-py-3 tw-cursor-pointer tw-col-span-4"
                @click="sortByEmail"
              >
                Email  <svg
                  class="tw-inline-block tw-ml-1 tw-align-middle tw-fill-current tw-text-gray-500"
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                    fill="#747474"
                  />
                </svg>
              </div>
              <div class="tw-px-2 tw-py-3">
                Role
              </div>
              <div class="tw-px-2 tw-py-3 tw-col-span-2">
                Account
              </div>
              <div class="tw-px-2 tw-py-3">
                Last login
              </div>
              <!-- <div class="tw-px-2 tw-py-3 tw-col-s tw-col-span-2" /> -->
            </div>

            <div class="tw-bg-white">
              <div
                v-for="user in usersData.data"
                :key="user.user_id"
                class="tw-text-gray-700 tw-my-3 tw-relative tw-grid tw-grid-cols-12 tw-justify-between tw-items-center "
              >
                <div
                  class="tw-px-2 tw-py-3 tw-text-ms tw-cursor-text tw-font-semibold"
                >
                  <study-status>
                    Active
                  </study-status>
                </div>

                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ user.first_name }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ user.last_name }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm tw-col-span-4">
                  {{ user.email }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ user.role }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm tw-col-span-2">
                  {{ user.account_name }}
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ user.last_login }}
                </div>
                <!-- <div class="tw-px-2 tw-py-3 tw-text-sm tw-col-span-2">
                  <span
                    class="tw-px-3 tw-py-2 tw-border tw-border-gray-200 tw-rounded-md tw-border-solid tw-font-semibold"
                  >
                    {{ user }}
                    CoBrowse
                  </span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :current-page="usersData.current_page"
      :per-page="usersData.per_page"
      :total-pages="usersData.total_pages"
      title="Users per page"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { Pagination, StudyStatus } from '../../common'
export default {
  components: {
    Pagination,
    StudyStatus
  },
  data () {
    return {
      usersData: [],
      firstNameSortType: 'asc',
      lastNameSortType: 'asc',
      emailSortType: 'asc',
      per_page: 5,
      currentPage: 1,
      selectedSortingType: null,
      selectedSortingField: null
    }
  },

  mounted () {
    console.log('this is users tab')
    this.$store.commit('toggleFullSpinner', true)
    this.$axios({
      method: 'get',
      url: `/users/get_all_users?per_page=${this.per_page}&page=${this.currentPage}`
    })
      .then((res) => {
        this.usersData = res.data
        this.$store.commit('toggleFullSpinner', false)
      })
      .catch(() => {
        this.$store.commit('toggleFullSpinner', false)
        console.log('error')
      })
  },

  methods: {
    sortByFirstName () {
      this.sortUsers('first_name', this.firstNameSortType)
      if (this.firstNameSortType === 'asc') {
        this.firstNameSortType = 'desc'
      } else {
        this.firstNameSortType = 'asc'
      }
    },

    sortByLastName () {
      this.sortUsers('last_name', this.lastNameSortType)
      if (this.lastNameSortType === 'asc') {
        this.lastNameSortType = 'desc'
      } else {
        this.lastNameSortType = 'asc'
      }
    },

    sortByEmail () {
      this.sortUsers('email', this.emailSortType)
      if (this.emailSortType === 'asc') {
        this.emailSortType = 'desc'
      } else {
        this.emailSortType = 'asc'
      }
    },
    getFilteredUsers (link) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'get',
        url: link
      })
        .then((res) => {
          this.usersData = res.data
          // console.log('res', res)
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    sortUsers (field, type) {
      this.selectedSortingField = field
      this.selectedSortingType = type
      this.$store.commit('toggleFullSpinner', true)
      let url = `/users/get_all_users?per_page=${this.per_page}&page=${this.currentPage}&sort_by=${this.selectedSortingField}&sort_order=${this.selectedSortingType}`
      if (this.checkSearchParamForUsers) {
        url = `/users/get_all_users?per_page=${this.per_page}&page=${this.currentPage}&sort_by=${this.selectedSortingField}&sort_order=${this.selectedSortingType}&username=${this.checkSearchParamForUsers()}`
      }
      this.$axios({
        method: 'get',
        url
      })
        .then((res) => {
          this.usersData = res.data
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    checkSearchParamForUsers () {
      const urlParams = new URLSearchParams(window.location.search)
      const name = urlParams.get('username')
      if (name) {
        return name
      }
      return ''
    },
    changePage (currentPage, perPage) {
      this.currentPage = currentPage
      this.per_page = perPage
      this.$store.commit('toggleFullSpinner', true)
      let url = `/users/get_all_users?per_page=${this.per_page}&page=${this.currentPage}&sort_by=${this.selectedSortingField}&sort_order=${this.selectedSortingType}`
      if (this.checkSearchParamForUsers) {
        url = `/users/get_all_users?per_page=${this.per_page}&page=${this.currentPage}&sort_by=${this.selectedSortingField}&sort_order=${this.selectedSortingType}&username=${this.checkSearchParamForUsers()}`
      }
      this.$axios({
        method: 'get',
        url
      })
        .then((res) => {
          console.log(res.data)
          this.usersData = res.data
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    }
  }
}
</script>
