<template>
  <div class="tw-w-full tw-h-full">
    <div
      v-if="!loadingData && !allStudiesDataWithoutFilters.surveys.length && displayWelcomeMessage"
      class="welcome  tw-h-[90vh] tw-flex tw-justify-center tw-items-center tw-w-full"
    >
      <div class="tw-max-w-lg">
        <p class="tw-text-center tw-text-6xl tw-my-2">
          &#128075;
        </p>
        <p class="tw-text-center tw-font-semibold tw-text-[30px]">
          Welcome to AskHumans {{ currentUser.firstName }}!
        </p>
        <p class="tw-text-center tw-my-3 tw-text-lg">
          Embark on a journey of exploration to uncover significant insights about
          your customer base through a comprehensive study.
        </p>
        <div class="tw-flex tw-justify-center tw-my-4 tw-space-x-2">
          <button
            class="tw-py-4 tw-px-4 tw-text-base tw-rounded-xl tw-text-primary-dark tw-bg-primary-light"
          >
            How it works
          </button>
          <button
            class="tw-py-4 tw-px-4 tw-text-base tw-rounded-xl tw-text-white tw-bg-primary"
            @click="createStudy"
          >
            Start now
          </button>
        </div>
      </div>
    </div>
    <div
      v-else-if="!loadingData"
      class="welcome"
    >
      <div class="mobile-header--centered">
        <a href="/welcome">
          <div class="mobile-header__centered-icon">
            <icon-wrapper icon-name="spacetrics" />
          </div>
        </a>
      </div>
      <div tabIndex="0">
        <div
          class="tw-h-[180px]  tw-relative"
          :style="companyCoverPhotoStyle"
          :class="{
            'edit-cover-photo__photo': true,
            'edit-cover-photo__default': !useLargeDefaultCoverPhoto,

          }"
          @focusout="coverPhotoHovered = false"
          @mouseleave="coverPhotoHovered = false"
          @mouseover="coverPhotoHovered = true"
        >
          <div class="tw-w-full tw-h-full tw-backdrop-brightness-50 tw-backdrop-blur-[1px]">
            <div class="bx--grid tw-absolute tw-top-1/2  -tw-translate-y-1/2">
              <div class="bx--row">
                <div class="tw-flex tw-space-x-3 tw-items-center">
                  <div>
                    <profile-image :user="currentUser" />
                    <!-- <img
                  src="https://placekitten.com/100"
                  class="tw-rounded-full tw-w-[60px] tw-h-[60px]"
                  alt=""
                /> -->
                  </div>
                  <div>
                    <p class="tw-text-2xl tw-text-white tw-font-semibold">
                      Hi {{ currentUser.firstName }} 👋
                    </p>
                    <p class="tw-text-white">
                      Unlock the true potential of your projects by creating studies
                      and gathering valuable feedback from your users and clients
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="coverPhotoHovered && isUserAdmin"
              class="tw-absolute tw- tw-bottom-4 tw-right-4"
              @click="openMediaUploader('cover_image')"
            >
              <button
                class="tw-px-3 tw-py-2 tw-rounded-full tw-bg-black tw-border tw-border-solid tw-border-white tw-text-white"
              >
                Change cover photo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isPaymentAboutToExpire"
        class="tw-bg-[#FAF3DA] tw-flex tw-space-x-3 tw-items-center px-20 tw-py-4"
      >
        <span>
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1077_3166)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0003 21.5C4.4771 21.5 0 16.7989 0 11C0 5.20109 4.4771 0.5 10.0003 0.5C15.5235 0.5 20 5.20109 20 11C20 16.7995 15.5229 21.5 10.0003 21.5ZM10.0401 3.03975C5.8527 3.01731 2.4413 6.56261 2.41933 10.9595C2.39796 15.3557 5.77433 18.9384 9.96111 18.9615C14.1479 18.9839 17.5599 15.438 17.5819 11.0418C17.6038 6.6449 14.2269 3.06219 10.0401 3.03975ZM14.0654 14.9761L13.7834 15.2722C13.3862 15.6892 12.742 15.6892 12.3454 15.2722L9.97833 12.7867L7.61124 15.2722C7.21406 15.6892 6.57049 15.6892 6.1733 15.2722L5.89129 14.9761C5.49411 14.559 5.49411 13.8826 5.89129 13.4662L8.25838 10.9807L5.89129 8.49516C5.49411 8.0781 5.49411 7.40171 5.89129 6.98528L6.1733 6.68916C6.57049 6.2721 7.21465 6.2721 7.61124 6.68916L9.97833 9.17467L12.3454 6.68916C12.7426 6.2721 13.3868 6.2721 13.7834 6.68916L14.0654 6.98528C14.4626 7.40233 14.4626 8.07873 14.0654 8.49516L11.6983 10.9807L14.0654 13.4662C14.4626 13.8832 14.4626 14.5596 14.0654 14.9761Z"
                fill="#202020"
              />
            </g>
            <defs>
              <clipPath id="clip0_1077_3166">
                <rect
                  width="20"
                  height="21"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>

        </span>
        <span>
          Your payment method has expired. Kindly refresh your information. If you fail to do so by the end of the month, open studies will be marked as "Closed" until you update your details.
        </span>
        <span
          class="tw-px-3 tw-bg-transparent tw-border tw-cursor-pointer tw-border-black tw-border-solid tw-rounded-xl tw-min-w-fit tw-py-2"
          @click="changePaymentPlanHandler"
        >
          Update payment method
        </span>
      </div>
      <div
        v-else-if="isPaymentMethodExpired"
        class="tw-bg-[#AC5555] tw-flex tw-space-x-3 tw-items-center tw-text-white px-20 tw-py-4"
      >
        <span>
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1077_3157)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0003 21.5C4.4771 21.5 0 16.7989 0 11C0 5.20109 4.4771 0.5 10.0003 0.5C15.5235 0.5 20 5.20109 20 11C20 16.7995 15.5229 21.5 10.0003 21.5ZM10.0401 3.03975C5.8527 3.01731 2.4413 6.56261 2.41933 10.9595C2.39796 15.3557 5.77433 18.9384 9.96111 18.9615C14.1479 18.9839 17.5599 15.438 17.5819 11.0418C17.6038 6.6449 14.2269 3.06219 10.0401 3.03975ZM14.0654 14.9761L13.7834 15.2722C13.3862 15.6892 12.742 15.6892 12.3454 15.2722L9.97833 12.7867L7.61124 15.2722C7.21406 15.6892 6.57049 15.6892 6.1733 15.2722L5.89129 14.9761C5.49411 14.559 5.49411 13.8826 5.89129 13.4662L8.25838 10.9807L5.89129 8.49516C5.49411 8.0781 5.49411 7.40171 5.89129 6.98528L6.1733 6.68916C6.57049 6.2721 7.21465 6.2721 7.61124 6.68916L9.97833 9.17467L12.3454 6.68916C12.7426 6.2721 13.3868 6.2721 13.7834 6.68916L14.0654 6.98528C14.4626 7.40233 14.4626 8.07873 14.0654 8.49516L11.6983 10.9807L14.0654 13.4662C14.4626 13.8832 14.4626 14.5596 14.0654 14.9761Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1077_3157">
                <rect
                  width="20"
                  height="21"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>

        </span>
        <span>
          Your payment method has expired. Kindly refresh your information. Open studies are currently marked as "Closed" until you update your details.
        </span>
        <span
          class="tw-px-3 tw-bg-transparent tw-border tw-cursor-pointer tw-border-white tw-border-solid tw-rounded-xl tw-min-w-fit tw-py-2"
          @click="changePaymentPlanHandler"
        >
          Update payment method
        </span>
      </div>
      <div
        class="border-bottom-gray tw-h-[60px] border-top-gray tw-flex tw-justify-between tw-items-center bg-insights-bar md:tw-bg-none"
      >
        <div class="bx--grid tw-text-sm tw-w-full  ">
          <div
            class="bx--row tw-flex tw-justify-between tw-items-center tw-w-full "
          >
            <div
              class="flex space-x-1 tw-px-2  pb-0-important mt-0-important tw-text-black-three tw-overflow-x-auto"
            >
              <span
                v-for="tab in tabsList"
                :key="tab"
                :class="[
                  {
                    'py-4 px-3 cursor-pointer w-full md:fit-content text-center': true,
                    'border-bottom-2-primary tw-text-black': tab === activeTab,

                    'tw-text-black-three hover:tw-text-black tw-transition-all tw-duration-300':
                      tab !== activeTab,
                  },
                ]"
                @click="changeActiveTab(tab)"
              >{{ tab }}</span>
            </div>
            <div>
              <ah-button @click="createStudy">
                Create Study
              </ah-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-bottom-gray tw-h-[60px] border-top-gray tw-flex tw-justify-between tw-items-center bg-insights-bar md:tw-bg-none"
      >
        <div class="bx--grid">
          <div class="bx--row">
            <div
              class="tw-w-full tw-px-4 tw-text-sm tw-flex tw-space-x-2 tw-items-center tw-py-3"
            >
              <div class="tw-flex tw-space-x-2 tw-items-center">
                <span>
                  Show
                </span>
                <div
                  class="tw-relative tw-px-1 tw-justify-between tw-items-center tw-w-full tw-cursor-pointer tw-flex "
                  @click="toggleDropdownOptions"
                >
                  <button
                    class="tw-flex tw-w-full tw-justify-between tw-space-x-2 tw-py-3 tw-rounded-lg tw-px-2 tw-items-center tw-min-w-[115px] tw-border tw-border-white-two tw-border-solid "
                    @blur="handleDropdownBlur"
                  >
                    <span class="">
                      {{ selectedDropdownOption }}
                    </span>
                    <span class="tw-text-end tw-block">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.88562 7.02744L0.969944 2.14556C0.508054 1.5697 0.882929 0.668457 1.58435 0.668457H9.4157C10.1171 0.668457 10.492 1.5697 10.0301 2.14556L6.11443 7.02744C5.78917 7.43297 5.21088 7.43297 4.88562 7.02744Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                  <div v-if="showDropdownOptions">
                    <div
                      class="tw-absolute tw-top-9 tw-max-w-[115px] tw-bg-white tw-w-full tw-shadow-md tw-text-sm tw-border tw-border-gray-100 tw-border-solid tw-left-1 tw-z-10"
                    >
                      <span
                        v-for="option in filterDropdownOptions"
                        :key="option"
                        class="tw-px-2 tw-cursor-pointer hover:tw-bg-primary-light tw-py-2 tw-w-full tw-block "
                        @click="changeSelectedDropdownOption($event, option)"
                      >{{ option }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <secondary-button
                :selected="secondaryFilter === 'all'"
                @click="changeSecondaryFilter('all')"
              >
                All
              </secondary-button>
              <secondary-button
                :selected="secondaryFilter === 'isAnswer'"
                @click="changeSecondaryFilter('isAnswer')"
              >
                W/ Answers
              </secondary-button>
              <secondary-button
                :selected="secondaryFilter === 'isAnswersAndStrategies'"
                @click="changeSecondaryFilter('isAnswersAndStrategies')"
              >
                W/ Reports
              </secondary-button>
              <secondary-button
                :selected="secondaryFilter === 'pending_to_reply'"
                @click="changeSecondaryFilter('pending_to_reply')"
              >
                Pending to reply
              </secondary-button>
            </div>
          </div>
        </div>
      </div>
      <div class="bx--grid tw-text-sm ">
        <div
          v-if="allStudiesDataWithoutFilters.surveys.length > 0"
          class="bx--row tw-py-5"
        >
          <div

            class="tw-w-full tw-font-mono"
          >
            <studies-tab
              v-if="activeTab == tabsList[0]"
              :studies="studiesData"
              :per-page="perPage"
              :current-page="currentPage"
              @updateStudiesData="getAllStudies"
            />
            <manage-tab
              v-if="activeTab == tabsList[1]"
              :studies="studiesData"
              :per-page="perPage"
              :current-page="currentPage"
              @sort-studies="changeSelectedSorting"
            />
          </div>
          <Pagination
            :per-page="studiesData.perPage"
            :current-page="studiesData.currentPage"
            :total-pages="studiesData.totalPages"
            :total-entries="studiesData.totalEntries"
            title="Studies per page"
            @change-page="changePage"
          />
        </div>
        <div
          v-else
          class="tw-h-[400px] tw-flex tw-justify-center tw-items-center"
        >
          <p class="tw-text-2xl tw-text-center tw-font-semibold">
            No studies found!
          </p>
        </div>
      </div>
    </div>

    <st-media-uploader
      v-if="showMediaUploader"
      :aspect-ratio="mediaUploaderPropValues.mediaUploaderAspectRatio"
      :folders="folders"
      :prop-name="`account[${mediaType}]`"
      :modal-title="mediaUploaderPropValues.mediaUploaderModalTitle"
      :url="`/accounts/${currentUser.accountId}`"
      @closeMediaUploader="showMediaUploader = false"
      @uploadComplete="uploadComplete"
    />
    <stripe-payment-form
      ref="stripeForm"
      type="update"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { StudiesTab, ManageTab } from './studies-home'
import { advanceToUrl, companyLogoStyle, isUserAdmin } from '../../mixins'
import {
  IconWrapper,
  ProfileImage,
  Pagination,
  AhButton,
  StMediaUploader,
  StripePaymentForm,
  SecondaryButton
} from '../common/'
import posthog from 'posthog-js'

export default {
  components: {
    IconWrapper,
    ProfileImage,
    StudiesTab,
    ManageTab,
    Pagination,
    AhButton,
    StripePaymentForm,
    StMediaUploader,
    SecondaryButton
  },
  mixins: [advanceToUrl, companyLogoStyle, isUserAdmin],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      displayWelcomeMessage: true,
      tabsList: ['Studies', 'Manage'],
      filterDropdownOptions: [
        'All studies',
        'Active studies',
        'Close studies',
        'Draft'
      ],
      selectedDropdownOption: 'All studies',
      showDropdownOptions: false,
      allStudiesDataWithoutFilters: {},
      activeTab: 'Studies',
      companyLogoHovered: false,
      coverPhotoHovered: false,
      editCompanyLogoActive: false,
      editCoverPhotoActive: false,
      mediaType: '',
      showMediaUploader: false,
      paymentInformation: null,
      showDeleteModal: false,
      showTagsPopup: false,
      studiesData: {},
      loadingData: true,
      currentPage: 1,
      perPage: 5,
      activeFilter: 'all',
      secondaryFilter: 'all',
      selectedColumnNameForSorting: 'name',
      selectedTypeOfSorting: 'asc',
      welcomeCards: [
        //   {
        //   icon: 'request-audience',
        //   description: 'Order a set of recipients based on specific demographics. It will take 5-7 days for us to collect your audience.',
        //   title: 'Request an Audience',
        //   url: '/audience/request'
        // },
        {
          icon: 'projects',
          description:
            'Gather unique data by starting a project and publishing it.',
          title: 'Create project',
          url: '/projects/new'
        }
        // {
        //   icon: 'survey-template',
        //   description: 'Get ahead and save time by creating survey templates.',
        //   title: 'Create a Template',
        //   url: '/templates/new'
        // }
      ]
    }
  },
  computed: {
    ...mapState(['currentUser']),
    companyCoverPhotoStyle () {
      return this.currentUser.companyCoverImageUrl
        ? {
          'background-image': `url('${this.currentUser.companyCoverImageUrl}')`
        }
        : {}
    },
    isPaymentAboutToExpire () {
      if (!this.paymentInformation) return false

      const today = new Date()
      const expiryDate = new Date(this.paymentInformation.expireYear, this.paymentInformation.expireMonth - 1)

      // Check if the expiry year is the same as the current year
      if (today.getFullYear() === expiryDate.getFullYear()) {
        // Check if the expiry month is the same or earlier than the current month
        return today.getMonth() >= expiryDate.getMonth()
      }

      // If the expiry year is later than the current year, it is not about to expire
      return false
    },
    // isPaymentAboutToExpire () {
    //   if (!this.paymentInformation) return false
    //   const today = new Date()
    //   const expiryDate = new Date(this.paymentInformation.expireYear, this.paymentInformation.expireMonth)
    //   return today.getMonth() === expiryDate.getMonth()
    // },

    // check if the payment method is expired
    isPaymentMethodExpired () {
      if (!this.paymentInformation) return false
      const today = new Date()
      const expiryDate = new Date(this.paymentInformation.expireYear, this.paymentInformation.expireMonth)
      return today > expiryDate
    },
    companyLogo () {
      return this.currentUser.companyLogoUrl
    },
    // getCurrentPlan() {
    //   return this.currentUser.account?.plan.title;
    // },
    getEmailVerified () {
      return this.currentUser.verified
    },
    mediaUploaderPropValues () {
      let propValues = {}
      if (this.mediaType === 'logo') {
        propValues['mediaUploaderAspectRatio'] = 1.0
        propValues['mediaUploaderModalTitle'] = 'Change Company Logo'
      } else {
        propValues['mediaUploaderAspectRatio'] = 4.23
        propValues['mediaUploaderModalTitle'] = 'Change Company Cover Photo'
      }
      return propValues
    },
    useLargeDefaultCoverPhoto () {
      let useLarge = null
      if (!this.currentUser.companyCoverImageUrl) {
        this.$store.state.navOpen ? (useLarge = false) : (useLarge = true)
      }
      return useLarge
    }
  },

  mounted () {
    this.$store.commit('toggleFullSpinner', true)
    // check if the page and per_page params are present in the url
    // if yes, then set the current page and per page to the values in the url
    const url = new URL(window.location.href)
    const page = url.searchParams.get('page')
    const perPage = url.searchParams.get('per_page')
    if (page && perPage) {
      this.currentPage = parseInt(page)
      this.perPage = parseInt(perPage)
    }
    setTimeout(() => {
      this.getAllStudies()
      this.checkPaymentInformation()
    }, 200)
    posthog.identify(this.currentUser.id, { email: this.currentUser.email })
  },

  methods: {
    handleDropdownBlur () {
      setTimeout(() => {
        this.showDropdownOptions = false
      }, 200)
    },
    toggleDropdownOptions () {
      this.showDropdownOptions = !this.showDropdownOptions
    },
    changeSelectedDropdownOption (e, option) {
      e.stopPropagation()
      this.showDropdownOptions = false
      this.selectedDropdownOption = option
      if (option === this.filterDropdownOptions[0]) {
        this.filterStudies('all')
      } else if (option === this.filterDropdownOptions[1]) {
        this.filterStudies('active')
      } else if (option === this.filterDropdownOptions[2]) {
        this.filterStudies('close')
      } else if (option === this.filterDropdownOptions[3]) {
        this.filterStudies('draft')
      }
    },
    focusEditCoverPhoto () {
      if (this.isUserAdmin) {
        this.coverPhotoHovered = true
        this.$nextTick(() => {
          this.$refs.editCoverPhotoButton.focus()
        })
      }
    },
    toggleDeleteModal () {
      this.showDeleteModal = !this.showDeleteModal
    },

    changeSecondaryFilter (type) {
      this.secondaryFilter = type
      // add filter to the current link by appending filter at the end
      // this will help in maintaining the filter when the page is reloaded
      const url = new URL(window.location.href)
      url.searchParams.set('secondary_filter', type)
      window.history.pushState({}, '', url)
      this.getAllStudies()
    },
    changeActiveTab (option) {
      this.activeTab = option
    },
    changeSelectedSorting (columnName, type) {
      this.selectedColumnNameForSorting = columnName
      this.selectedTypeOfSorting = type
      this.getAllStudies()
    },

    openMediaUploader (mediaType) {
      this.mediaType = mediaType
      this.mediaType === 'logo'
        ? (this.editCompanyLogoActive = true)
        : (this.editCoverPhotoActive = true)

      setTimeout(() => {
        this.showMediaUploader = true
        this.mediaType === 'logo'
          ? (this.editCompanyLogoActive = false)
          : (this.editCoverPhotoActive = false)
      }, 400)
    },
    getAllStudies () {
      this.$store.commit('toggleFullSpinner', true)
      let url = `/study?per_page=${this.perPage}&page=${this.currentPage}`
      if (this.activeFilter === 'active') {
        url = url + '&status=active'
      } if (this.activeFilter === 'draft') {
        url = url + '&status=draft'
      } if (this.activeFilter === 'close') {
        url = url + '&status=close'
      }

      if (this.secondaryFilter) {
        url = url + `&${this.secondaryFilter}=true`
      }
      if (this.selectedColumnNameForSorting && this.selectedTypeOfSorting) {
        url = url + `&sort_column=${this.selectedColumnNameForSorting}&sort_direction=${this.selectedTypeOfSorting}`
      }
      this.$axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            this.studiesData = { ...res.data }
            this.allStudiesDataWithoutFilters = { ...res.data }
            this.currentPage = res.data.currentPage
            this.perPage = res.data.perPage
            this.activeFilter = 'all'
          }
          if (this.displayWelcomeMessage && res.data.surveys.length) {
            this.displayWelcomeMessage = false
          }

          this.$store.commit('toggleFullSpinner', false)
          this.loadingData = false
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('toggleFullSpinner', false)
          this.loadingData = false
        })
    },
    uploadComplete (data) {
      this.showMediaUploader = false
      this.mediaType === 'logo'
        ? this.$store.commit('setCurrentUser', {
          ...this.currentUser,
          companyLogoUrl: data.logoUrl
        })
        : this.$store.commit('setCurrentUser', {
          ...this.currentUser,
          companyCoverImageUrl: data.coverImageUrl
        })
    },

    filterStudies (type) {
      this.activeFilter = type
      // add filter to the current link by appending filter at the end
      // this will help in maintaining the filter when the page is reloaded
      const url = new URL(window.location.href)
      url.searchParams.set('primary_filter', type)
      window.history.pushState({}, '', url)

      this.getAllStudies()
    },
    createStudy () {
      this.$store.commit('toggleFullSpinner', true)
      let url = '/study/'
      let data = {
        name: '',
        virtual_presentation: false
        // project_id: this.project.id
      }
      this.$axios
        .request({
          url: url,
          method: 'post',
          data: data
        })
        .then((res) => {
          window.location.href = `/study/${res.data.id}/edit`
        })
        .catch((err) => {
          console.log(err)
        })
    },
    changePage (currentPage, perPage) {
      this.currentPage = currentPage
      this.perPage = perPage
      // console.log(currentPage, perPage)
      let url = `/study?per_page=${this.perPage}&page=${this.currentPage}`
      // check if the current url has primary filter
      // if yes, then add the primary filter to the url
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('primary_filter')) {
        url = url + `&status=${urlParams.get('primary_filter')}`
      }
      if (urlParams.has('secondary_filter')) {
        url = url + `&${urlParams.get('secondary_filter')}=true`
      }
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'get',
        url: url
      })
        .then((res) => {
          // add pagination params to url
          const url = new URL(window.location.href)
          url.searchParams.set('page', this.currentPage)
          url.searchParams.set('per_page', this.perPage)
          window.history.pushState({}, '', url)

          // console.log(res.data)
          this.studiesData = res.data
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    changePaymentPlanHandler () {
      this.$refs.stripeForm.toggle()
    },
    checkPaymentInformation () {
      this.$axios
        .request({
          method: 'get',
          url: `payments/payment_information`
        })
        .then((res) => {
          // this.$store.commit('toggleFullSpinner', false)
          // console.log(res)
          const info = {}
          if (res.data && res.data.data) {
            info.brand = res.data.data.brand
            info.last4 = res.data.data.last4
            info.expireYear = res.data.data.exp_year
            info.expireMonth = res.data.data.exp_month
            this.paymentInformation = { ...info }
          }
        })
        .catch((err) => {
          // this.$store.commit('toggleFullSpinner', false)
          console.log(err)
        })
    }
  }
}
</script>
