import { render, staticRenderFns } from "./wizard-information.vue?vue&type=template&id=788eb7a7&scoped=true&"
import script from "./wizard-information.vue?vue&type=script&lang=js&"
export * from "./wizard-information.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "788eb7a7",
  null
  
)

export default component.exports