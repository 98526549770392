<template>
  <div>
    <vue-apex-charts
      type="area"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    VueApexCharts
  },
  props: {
    dates: {
      type: Array,
      required: true
    },
    counts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {

      series: [{
        name: 'participants',
        data: this.counts
      }],
      chartOptions: {
        colors: ['#BE6744'],
        chart: {
          height: '200px',
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: this.dates
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return Number(val).toFixed(0)
            }
          }
        },
        tooltip: {
          x: {
            format: 'MM/dd/yy '
          },
          y: {
            formatter: function (val) {
              return Number(val).toFixed(0)
            }
          }
        }
      }
    }
  },

  mounted () {

  },

  methods: {

  }
}
</script>
