<template>
  <div class="st-sentiment-data-table-container  tw-py-3 ">
    <table
      class="st-sentiment-data-table"
    >
      <thead
        v-if="Object.keys(filterSentimentRow).length -1"
        class="st-sentiment-data-table__header"
      >
        <tr class="">
          <th class="st-sentiment-data-table__head-item tw-font-semibold tw-space-x-1">
            <!-- <div class="st-sentiment-data-table__header-and-column-chevron"> -->
            <!-- @click="handleColumnSorting('Keyword')" -->
            <!-- @mouseenter="hoverIdx = 'Keyword'" -->
            <!-- @mouseleave="hoverIdx = null" -->
            <svg
              class="tw-mt-[2px]"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.77389 9.03688L1.14627 2.5277C0.600402 1.75988 1.04344 0.558228 1.87239 0.558228L11.1276 0.558228C11.9566 0.558228 12.3996 1.75988 11.8537 2.5277L7.22612 9.03687C6.84172 9.57758 6.15829 9.57758 5.77389 9.03688Z"
                fill="black"
              />
            </svg>
            <span>
              {{ columnTitle }} ({{ Object.keys(filterSentimentRow).length -1 }})
            </span>
            <!-- <div class="st-sentiment-data-table__column-chevron-container">
                <transition name="fade">
                  <icon-wrapper
                    v-if="
                      (activeColumn && activeColumn.name === 'Keyword') ||
                        hoverIdx === 'Keyword'
                    "
                    :class="{
                      'st-sentiment-data-table__column-chevron': true,
                      'st-sentiment-data-table__column-chevron--descending':
                        activeColumn &&
                        activeColumn.name === 'Keyword' &&
                        !activeColumn.ascending,
                    }"
                    icon-name="chevron"
                  />
                </transition>
              </div> -->
            <!-- </div> -->
          </th>
        </tr>
      </thead>
      <tbody class="md:tw-border-solid tw-border-[#ECECEC] tw-border tw-border-none">
        <template v-for="(row, index) in Object.keys(filterSentimentRow)">
          <tr
            v-if="row !== 'unGroup' && index < keywordsTableLength"
            :key="row + 'grouping'"
            style="min-width:100%"
            :class="{
              'st-sentiment-data-table__row px-5 hover-show !tw-bg-white tw-border-gray-200 tw-border-solid tw-border md:tw-border-none': true,
              'st-sentiment-data-table__row--header':
                index === 0 && isFirstRowHeader,
              'st-sentiment-data-table__row--expandable': expandable,

              'st-sentiment-data-table__row--expanded': isExpandedGroup(
                index
              ),
            }"
            @mouseover="showByIndex = index"
            @mouseout="showByIndex = null"
            @click="toggleGroupExpansion(index)"
          >
            <td
              v-if="expandable"
              class="st-sentiment-data-table__item flex space-x-2 md:tw-min-w-[300px]"
            >
              <!-- <icon-wrapper
                :class="{
                  'st-sentiment-data-table__chevron': true,
                  'st-sentiment-data-table__chevron--open': isExpandedGroup(
                    index
                  ),
                }"
                icon-name="icon-dropdown"
                :neutral="!isExpandedGroup(index)"
              /> -->
              <svg
                v-if="isExpandedGroup(
                  index
                )"
                class="tw-mt-[2px] "
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.77389 9.03688L1.14627 2.5277C0.600402 1.75988 1.04344 0.558228 1.87239 0.558228L11.1276 0.558228C11.9566 0.558228 12.3996 1.75988 11.8537 2.5277L7.22612 9.03687C6.84172 9.57758 6.15829 9.57758 5.77389 9.03688Z"
                  fill="black"
                />
              </svg>
              <svg
                v-else
                class="tw-mt-[2px]"
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.03655 6.72651L2.52738 11.3541C1.75956 11.9 0.557907 11.457 0.557907 10.628L0.557906 1.37278C0.557906 0.543822 1.75956 0.100786 2.52738 0.646656L9.03655 5.27427C9.57726 5.65868 9.57726 6.3421 9.03655 6.72651Z"
                  fill="black"
                />
              </svg>

              <span
                :class="{
                  'st-sentiment-data-table__keyword  tw-relative' : true,
                  'st-sentiment-data-table__keyword-open': isExpandedGroup(
                    index
                  ),
                }"
              >
                <span class="text-black text-14p tw-flex tw-flex-col tw-space-y-2 md:tw-space-y-0">
                  <span class="font-bold ">
                    {{ row }}
                  </span>
                  <span class="md:tw-hidden tw-text-xs">
                  {{ filterGroupedRowData(row).length }} Keywords 
                  </span>
                </span>
                <div
                  v-show="showByIndex === index"
                  class="tw-absolute -tw-top-20 tw-hidden  tw-left-20 tw-shadow-2xl tw-bg-white tw-py-4 tw-px-6 md:first-letter :tw-flex tw-flex-col tw-space-y-1 tw-z-10"
                >
                  <sentiment-tag
                    :sentiment="totalFilterGroupedRowData(row).totalSentiments"
                  />
                  <span class="tw-py-1">
                    {{ totalFilterGroupedRowData(row).totalParticipantMember }} mentions
                  </span>
                  <span
                    v-if="totalFilterGroupedRowData(row).veryPositive"
                    class="flex tw-space-x-2"
                  >
                    <span class="tw-bg-very-positive tw-block tw-w-3 tw-h-3  tw-rounded-full" /> <span>
                      {{ totalFilterGroupedRowData(row).veryPositive }}
                    </span>
                  </span>
                  <span
                    v-if="totalFilterGroupedRowData(row).positive"
                    class="flex tw-space-x-2"
                  >
                    <span class="tw-bg-positive tw-block tw-w-3 tw-h-3  tw-rounded-full" /> <span>
                      {{ totalFilterGroupedRowData(row).positive }}
                    </span>
                  </span>
                  <span
                    v-if="totalFilterGroupedRowData(row).neutral"
                    class="flex tw-space-x-2"
                  >
                    <span class="tw-bg-neutral tw-block tw-w-3 tw-h-3  tw-rounded-full" /> <span>
                      {{ totalFilterGroupedRowData(row).neutral }}
                    </span>
                  </span>
                  <span
                    v-if="totalFilterGroupedRowData(row).negative"
                    class="flex tw-space-x-2"
                  >
                    <span class="tw-bg-negative tw-block tw-w-3 tw-h-3  tw-rounded-full" /> <span>
                      {{ totalFilterGroupedRowData(row).negative }}
                    </span>
                  </span>
                  <span
                    v-if="totalFilterGroupedRowData(row).veryNegative"
                    class="flex tw-space-x-2"
                  >
                    <span class="tw-bg-very-negative tw-block tw-w-3 tw-h-3  tw-rounded-full" /> <span>
                      {{ totalFilterGroupedRowData(row).veryNegative }}
                    </span>
                  </span>

                </div>
              </span>
            </td>
            <td
              class="st-sentiment-data-table__item md:tw-min-w-[300px]"
              colspan="6"
            >
              <!-- <sentiment-tag
                :sentiment="totalFilterGroupedRowData(row).totalSentiments"
              /> -->
              <sentiment-mentions-bar
                :mentions="totalFilterGroupedRowData(row).totalParticipantMember"
                :sentiments="[totalFilterGroupedRowData(row).veryPositive,totalFilterGroupedRowData(row).positive ,totalFilterGroupedRowData(row).neutral,totalFilterGroupedRowData(row).negative,totalFilterGroupedRowData(row).veryNegative]"
              />
            </td>
            <td class="tw-hidden md:tw-table-cell">
              <span>
                {{ filterGroupedRowData(row).length }} keywords {{ ` / ` }}
              </span>
              <span>
                {{ totalFilterGroupedRowData(row).veryPositive + totalFilterGroupedRowData(row).positive + totalFilterGroupedRowData(row).neutral + totalFilterGroupedRowData(row).negative + totalFilterGroupedRowData(row).veryNegative }} Mentions
              </span>
            </td>
            <!-- <td class="st-sentiment-data-table__item ">
              {{ totalFilterGroupedRowData(row).totalParticipantMember }}
            </td>
            <td class="st-sentiment-data-table__item ">
              {{ totalFilterGroupedRowData(row).veryPositive }}
            </td>
            <td class="st-sentiment-data-table__item ">
              {{ totalFilterGroupedRowData(row).positive }}
            </td>
            <td class="st-sentiment-data-table__item ">
              {{ totalFilterGroupedRowData(row).neutral }}
            </td>
            <td class="st-sentiment-data-table__item ">
              {{ totalFilterGroupedRowData(row).negative }}
            </td>
            <td class="st-sentiment-data-table__item ">
              {{ totalFilterGroupedRowData(row).veryNegative }}
            </td> -->
            <td class="st-sentiment-data-table__item ">
              <div
                v-if="userSignedIn === 'true'"
                class="flex font-semibold text-black md:tw-min-w-[100px]"
              >
              <div
                  v-show="showByIndex === index"
                  class="flex tw-space-x-2"
                >
                  <div

                    class="flex"
                  >
                    <span
                      class="text-underline tw-font-semibold"
                      @click="
                        renameGroupHandler(
                          $event,
                          filterGroupedRowData(row)[0].entitySentimentsGroupId,
                          row
                        )
                      "
                    >
                    <span class="md:tw-hidden">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-h-4 tw-w-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>

                    </span>
                    <span class="hidden md:tw-block">
                      Rename
                    </span>
                    </span>
                  </div>
                  <div class="flex">
                    <span
                      class="text-underline  tw-font-semibold"
                      @click="
                        deleteGroupHandler(
                          $event,
                          filterGroupedRowData(row)[0].entitySentimentsGroupId,
                          row
                        )
                      "
                    >
                     <span class="md:tw-hidden">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-h-4 tw-w-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

                     </span>
                     <span class="hidden md:tw-block">
                      Delete
                      </span>
                    </span>
                  </div>
                </div>
                <!-- <div
                  v-show="showByIndex === index"
                  class="flex tw-space-x-2"
                >
                  <div

                    class="flex"
                  >
                    <span
                      class="text-underline tw-font-semibold"
                      @click="
                        renameGroupHandler(
                          $event,
                          filterGroupedRowData(row)[0].entitySentimentsGroupId,
                          row
                        )
                      "
                    >
                      Rename
                    </span>
                  </div>
                  <div class="flex">
                    <span
                      class="text-underline  tw-font-semibold"
                      @click="
                        deleteGroupHandler(
                          $event,
                          filterGroupedRowData(row)[0].entitySentimentsGroupId,
                          row
                        )
                      "
                    >
                      Delete
                    </span>
                  </div>
                </div> -->
              </div>
            </td>
          </tr>
          <template v-for="(sentimentRow, idxx) in filterGroupedRowData(row)">
            <tr
              v-if="isExpandedGroup(index) && row !== 'unGroup'"
              :key="sentimentRow.entityId"
              :class="{
                'st-sentiment-data-table__row px-5': true,
                'st-sentiment-data-table__row--header':
                  sentimentRow.entityId === 0 && isFirstRowHeader,
                'st-sentiment-data-table__row--expandable': expandable,
              }"
              @click="toggleRowExpansion(sentimentRow.entityId, sentimentRow)"
            >
              <td
                v-if="expandable"
                class="st-sentiment-data-table__item st-sentiment-data-table__item--link !tw-pl-12 bg-yellow-200"
              >
                <!-- @click="toggleRowExpansion(sentimentRow.entityId, sentimentRow)" -->
                <!-- <icon-wrapper
                  icon-name="chevron"
                  :class="{
                    'st-sentiment-data-table__chevron': true,
                    'st-sentiment-data-table__chevron--open': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                  :neutral="!isExpanded(sentimentRow.entityId)"
                /> -->

                <div
                  :class="{
                    'st-sentiment-data-table__keyword tw-min-w-[100px] tw-flex tw-flex-col tw-space-y-2 md:tw-space-y-0': true,
                    'st-sentiment-data-table__keyword-open ': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                >
                  <span>
                    {{ sentimentRow.title }}
                  </span>
                  <span
                v-if="includeCount"
                class="md:tw-hidden"
              >
                {{
                  rowTotalCounts.find(
                    (row) => row.entityId === sentimentRow.entityId
                  ).sentimentsLength
                }} mentions
                  </span>
                </div>
                <div
                  :class="{
                    'st-sentiment-data-table__spacer': true,
                    'st-sentiment-data-table__spacer--expanded': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                />
              </td>
              <td
                v-else
                class="st-sentiment-data-table__item"
              >
                {{ sentimentRow.title }}
              </td>
              <td
                class="st-sentiment-data-table__item"
                colspan="6"
              >
                <sentiment-mentions-bar
                  :mentions="rowTotalCounts.find(
                    (row) => row.entityId === sentimentRow.entityId
                  ).sentimentsLength"
                  :sentiments="rowSentimentCounts.find(
                    (row) => row.entityId == sentimentRow.entityId
                  ).sentimentCounts"
                />
                <!-- <sentiment-tag
                  :sentiment="
                    rowAverages.find(
                      (row) => row.entityId === sentimentRow.entityId
                    ).sentiments
                  "
                /> -->
              </td>
              <td
                v-if="includeCount"
                class="st-sentiment-data-table__item tw-hidden md:tw-table-cell" 
              >
                {{
                  rowTotalCounts.find(
                    (row) => row.entityId === sentimentRow.entityId
                  ).sentimentsLength
                }} mentions
              </td>
              <template v-if="activeQuestionModifier === 'votes'">
                <!-- <td
                  v-for="(sentimentCount,
                          percentIdx) in rowSentimentCounts.find(
                            (row) => row.entityId == sentimentRow.entityId
                          ).sentimentCounts"
                  :key="percentIdx + 'percentIndex'"
                  class="st-sentiment-data-table__item"
                >
                  {{ sentimentCount }}
                </td> -->
                <td class="st-sentiment-data-table__item md:tw-min-w-[140px] tw-flex tw-justify-end">
                  <div
                    v-if="userSignedIn === 'true'"
                    class="flex relative"
                  >
                    <div
                      v-if="isPopupOpenForRow(sentimentRow.entityId)"
                      class="flex flex-col  justify-center rounded-6p absolute bg-white shadow-5p group-popup"
                    >
                      <span
                        class="font-bold"
                        @click="
                          addToGroupHandler(
                            $event,
                            sentimentRow.entityId,
                            sentimentRow.entitySentimentsGroupId
                          )
                        "
                      >
                        Add to other group
                      </span>
                      <span
                        class="font-bold"
                        @click="
                          removeFromGroup(
                            $event,
                            sentimentRow.entityId,
                            sentimentRow.entitySentimentsGroupId
                          )
                        "
                      >
                        Remove from group
                      </span>
                    </div>
                    <div @click="togglePopup($event, sentimentRow.entityId)">
                      <span
                        :class="{
                          'st-sentiment-data-table__chevron': true,
                          'st-sentiment-data-table__chevron--open': isExpanded(
                            sentimentRow.entityId
                          ),
                        }"
                      >
                        <span
                          class="flex flex-col justify-center items-center rotate-90"
                        >
                          <svg
                            width="4"
                            height="16"
                            viewBox="0 0 4 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="2"
                              cy="2"
                              r="2"
                              fill="#1B1B1B"
                            />
                            <circle
                              cx="2"
                              cy="8"
                              r="2"
                              fill="#1B1B1B"
                            />
                            <circle
                              cx="2"
                              cy="14"
                              r="2"
                              fill="#1B1B1B"
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </td>
              </template>
              <template v-else>
                <td
                  v-for="(sentimentPercent,
                          percentIdx) in rowSentimentPercents.find(
                            (row) => row.entityId == sentimentRow.entityId
                          ).sentimentPercents"
                  :key="percentIdx + 'anotherpercent'"
                  class="st-sentiment-data-table__item"
                >
                  {{ sentimentPercent }}%
                </td>
              </template>
            </tr>

            <tr
              v-if="isExpanded(sentimentRow.entityId)"
              :key="'expanded' + sentimentRow.entityId"
            >
              <td
                class="st-sentiment-data-table__item"
                :colspan="includeCount ? 8 : 7"
              >
                <slot
                  name="expanded-row"
                  :row="sentimentRow"
                  :row-idx="sentimentRow.entityId"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
    <st-drawer v-model="showModal">
      <template v-slot:modalTitle>
        <div class="flex justify-between  ">
          <span class="font-18p font-bold">
            {{ drawerSentimentRowTitle }}
          </span>
        </div>
      </template>
      <template />

      <template v-slot:modalActions>
        <div class="py-7 text-1p2-rem  tw-text-white tw-bg-territory-dark ">
          <p class="text-center">
            {{ entityMentionCount }}/{{ responses.length }} participants have mentioned the word
          </p>
          <p class="text-center text-1p4-rem font-bold">
            "{{ drawerSentimentRowTitle }}"
          </p>
        </div>
        <div class="tw-px-3 flex tw-space-x-3 py-4">
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==4 }"
            v-if="activeTranscriptsForSentiments(4)"
            @click="updateSentimentOption('Very Positive')"
          >Very Positive  ({{ activeTranscriptsForSentiments(4) }})</span>
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==3 }"
            v-if="activeTranscriptsForSentiments(3)"
            @click="updateSentimentOption('Positive')"
          >Positive  ({{ activeTranscriptsForSentiments(3) }})</span>
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==2 }"
            v-if="activeTranscriptsForSentiments(2)"
            @click="updateSentimentOption('Neutral')"
          >Neutral  ({{ activeTranscriptsForSentiments(2) }})</span>
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==1 }"
            v-if="activeTranscriptsForSentiments(1)"
            @click="updateSentimentOption('Negative')"
          >Negative  ({{ activeTranscriptsForSentiments(1) }})</span>
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==0 }"
            v-if="activeTranscriptsForSentiments(0)"
            @click="updateSentimentOption('Very Negative')"
          >Very Negative ({{ activeTranscriptsForSentiments(0) }})</span>
        </div>
        <p class="text-12p font-600 py-4 text-center" v-if="sentimentMentionCount">
          {{ sentimentMentionCount }}/{{ entityMentionCount }} participants have
          {{ activeSentiment }} views about "{{ drawerSentimentRowTitle }}"
        </p>
        <p v-else class="tw-px-5 tw-py-3 tw-text-center">
          Select sentiment type to view list of participants        
        </p>

        <div class="st-sentiment-data-table-detail__transcripts py-4 w-full">
          <div
            v-for="transcript in activeTranscripts"
            :key="transcript.responseId"
            class="st-sentiment-data-table-detail__one-transcript-container "
          >
            <div class="border-b-slate-100 px-8 ">
              <div class="st-insights__transcript-avatar-row ">
                <div
                  class="st-insights__transcript-avatar-with-name"
                  :class="{'hover:tw-cursor-pointer':transcript.srcUrl}"
                  @click="playTranscript(transcript.responseId)"
                >
                  <participant-avatar :participant="transcript" />
                  <div class="st-sentiment-data-table-detail__one-transcript-name">
                    {{ activeName(transcript) }}
                  </div>
                </div>
              </div>
              <div class="st-sentiment-data-table-detail__one-transcript-section">
                <div
                  class="st-insights__transcript-section-text st-sentiment-data-table-detail__transcript text-16p"
                >
                  <template v-for="(text, idx) in splitTranscriptText(transcript)">
                    <span
                      v-if="idx > 0"
                      :key="'keyword' + idx"
                      class="st-sentiment-data-table-detail__bold-keyword font-600 text-black"
                    >
                      {{ drawerSentimentRowTitle }}
                    </span>
                    <span :key="'text' + idx">
                      {{ text }}
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="activeAudioUrl"
            :class="{
              'st-virtual-tour-insights__audio-bar': true,
            }"
          >
            <audio
              ref="transcriptAudio"
              class="st-virtual-tour-insights__full-audio"
              autoplay
              controls
              @timeupdate="handleTimeUpdate"
            >
              <source
                :src="activeAudioUrl"
                :type="activeAudioContentType"
              >
              <!-- <source
                :src="activeAudioUrl"
                type="audio/mpeg"
              > -->
              <source
                :src="activeAudioUrl"
                type="audio/ogg"
              >
              <source
                v-if="activeAudioFlacUrl"
                :src="activeAudioFlacUrl"
                :type="activeAudioFlacContentType"
              />
            </audio>
          </div>
        </div>
        <!-- <div class="py-4 px-8 border-b-slate-100">
          <div class="flex items-center space-x-3 p-1">
            <div class="rounded-full p-1 bg-primary-brown text-white text-12p">
              P1
            </div>
            <span>
              Participant
            </span>
          </div>
          <div class="px-2-important py-2">
            <p class="text-12p  ">
              I really love my iPhone , many. I have the 12 and I would love for Apple in the future to build another one, and particularly love the form factor. I love the size, those are all great. The battery life is not that great, but I'm willing to sacrifice a little bit of battery life for a smaller device Factor.
            </p>
          </div>
        </div>
        <div class="py-4 px-8 border-b-slate-100">
          <div class="flex items-center space-x-3 p-1">
            <div class="rounded-full p-1 bg-primary-brown text-white text-12p">
              P1
            </div>
            <span>
              Participant
            </span>
          </div>
          <div class="px-2-important py-2">
            <p class="text-12p  ">
              I really love my iPhone , many. I have the 12 and I would love for Apple in the future to build another one, and particularly love the form factor. I love the size, those are all great. The battery life is not that great, but I'm willing to sacrifice a little bit of battery life for a smaller device Factor.
            </p>
          </div>
        </div> -->
      </template>
    </st-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  average,
  bucketCounts,
  bucketIdx,
  caseInsensitiveSortBy,
  percent,
  sum
} from '../../../utilities/data_utilities'
import { IconWrapper, StButton, StDrawer } from '../../common'
import participantAvatar from './participant-avatar.vue'
import SentimentMentionsBar from './sentiment-mentions-bar.vue'
import SentimentTag from './sentiment-tag'
const SENTIMENTS = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

export default {
  components: {
    IconWrapper,
    SentimentMentionsBar,
    StDrawer,
    'sentiment-tag': SentimentTag,
    StButton,
    participantAvatar
  },
  props: {
    tableCompleted: {
      type: Boolean,
      required: false,
      default: true
    },
    sentimentRows: {
      type: Array, // each should be an object with title and sentiments[] defined
      required: false,
      default: () => []
    },
    includeCount: {
      type: Boolean,
      required: false,
      default: false
    },
    isFirstRowHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    totalParticipants: {
      type: Number,
      required: false,
      default: 0
    },
    expandable: {
      type: Boolean,
      required: false,
      default: false
    },
    columnTitle: {
      type: String,
      required: false,
      default: 'Room Name'
    },
    sentimentGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    userSignedIn: {
      type: String,
      required: false
    },
    keywordsTableLength: {
      type: Number,
      required: true
    },
    responses: {
      type: Array,
      required: false
    }
  },
  data: function () {
    return {
      activeColumn: { name: 'Avg. Sentiment', ascending: true },
      expandedRows: {},
      expendedGroups: {},
      activeResponse: {},
      showByIndex: null,
      isPopupOpen: {},
      drawerSentimentRowTitle: '',
      headers: [
        'Avg. Sentiment',
        'Mentions',
        'Very Positive',
        'Positive',
        'Neutral',
        'Negative',
        'Very Negative',
        ''
      ],
      sentiments: [
        'Very Negative',
        'Negative',
        'Neutral',
        'Positive',
        'Very Positive'
      ],
      showModal: false,
      hoverIdx: null,
      activeSentimentIdx: -1
    }
  },
  mounted () {
    // this.activeSentimentIdx = 4
    // console.log(this.keywordsTableLength)
  },
  computed: {
    ...mapState('insights', ['activeQuestionModifier']),
    activeAudioFlacUrl () {
      return this.activeResponse?.mpegSrcUrl
    },
    activeAudioFlacContentType() {
      return this.activeResponse.mpegContentType;
    },
    activeAudioUrl () {
      return this.activeResponse?.srcUrl
    },
    activeAudioContentType () {
      return this.activeResponse?.contentType
    },
    sentimentMentionCount () {
      return this.activeTranscripts.length
    },
    transcripts () {
      return this.entitySentimentsKeywords[this.drawerSentimentRowTitle].transcripts
    },
    entityMentionCount () {
      return this.transcripts.length
    },
    activeSentiment () {
      return SENTIMENTS[this.activeSentimentIdx]
    },
    activeTranscripts () {
      return this.transcripts.filter(
        (transcript) => transcript.bucketIdx === Number(this.activeSentimentIdx)
      )
    },

    entitySentimentsKeywords () {
      let keyWordSentiments = {}
      this.responses.forEach((response) => {
        response.entitySentiments.map((entity) => {
          if (keyWordSentiments.hasOwnProperty(entity.name)) {
            keyWordSentiments[entity.name].sentiments.push(
              entity.sentimentScore
            )
            keyWordSentiments[entity.name].transcripts.push({
              bucketIdx: entity.sentimentValueIdx,
              firstName: response.firstName,
              lastName: response.lastName,
              name: response.fullName,
              surveyResponseId: response.surveyResponseId,
              responseId: response.id,
              transcript: response.transcript,
              srcUrl: response.srcUrl
            })
          } else {
            keyWordSentiments[entity.name] = {
              entityId: entity.id,
              entitySentimentsGroupId: entity.entitySentimentsGroupId,
              sentiments: [entity.sentimentScore],
              transcripts: [
                {
                  bucketIdx: entity.sentimentValueIdx,
                  firstName: response.firstName,
                  lastName: response.lastName,
                  name: response.fullName,
                  responseId: response.id,
                  surveyResponseId: response.surveyResponseId,
                  transcript: response.transcript,
                  srcUrl: response.srcUrl
                }
              ]
            }
          }
        })
      })
      return keyWordSentiments
    },

    filterSentimentRow () {
      const filterSentimentRows = { unGroup: [] }

      for (let i = 0; i < this.sentimentGroups.length; i++) {
        const group = this.sentimentGroups[i]
        filterSentimentRows[group.name] = []
      }

      for (let i = 0; i < this.sentimentRows.length; i++) {
        const sentiment = this.sentimentRows[i]
        let groupFound = false
        for (let j = 0; j < this.sentimentGroups.length; j++) {
          const group = this.sentimentGroups[j]
          if (sentiment.entitySentimentsGroupId === group.id) {
            filterSentimentRows[group.name].push(sentiment)
            groupFound = true
          }
        }
        if (!groupFound) {
          filterSentimentRows.unGroup.push(sentiment)
        }
      }

      for (const key in filterSentimentRows) {
        if (
          Array.isArray(filterSentimentRows[key]) &&
          !filterSentimentRows[key].length
        ) {
          delete filterSentimentRows[key]
        }
      }

      let sortGroupedRow = {}
      let unGroupRow = {}
      const reversedKeys = Object.keys(filterSentimentRows).sort()
      reversedKeys.forEach((key) => {
        if ([key] !== 'unGroup') {
          sortGroupedRow = {
            ...sortGroupedRow,
            [key]: filterSentimentRows[key]
          }
        } else {
          unGroupRow = {
            ...unGroupRow,
            [key]: filterSentimentRows[key]
          }
        }
      })
      return { ...unGroupRow, ...sortGroupedRow }
    },
    rowAverages () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentiments: average(sentimentRow.sentiments || [])
        }
      })
    },
    rowTotalCounts () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentimentsLength: sentimentRow.sentiments.length
        }
      })
    },
    rowAverageBucketIndexes () {
      return this.rowAverages.map((average) => bucketIdx(average))
    },
    rowSentimentCounts () {
      return this.sortedRowsByActiveColumn.map((row) => {
        return {
          entityId: row.entityId,
          sentimentCounts: this.sentimentCounts(row.sentiments)
        }
      })
    },
    rowSentimentPercents () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentimentPercents: this.sentimentPercents(sentimentRow.sentiments)
        }
      })
    },
    tableHeaders () {
      return this.includeCount
        ? this.headers
        : this.headers.filter((header) => header !== 'Mentions')
    },
    sortedRowsByActiveColumn () {
      let rows = []
      switch (this.activeColumn.name) {
        case 'Keyword':
          return this.sortColumnProperty('title')
        case 'Avg. Sentiment':
          rows = this.activeColumn.ascending
            ? this.sentimentRows
              .slice()
              .sort((a, b) => b.averageSentiment - a.averageSentiment)
            : this.sentimentRows
              .slice()
              .sort((a, b) => a.averageSentiment - b.averageSentiment)
          break
        case 'Participant Mentions':
          rows = this.activeColumn.ascending
            ? this.sentimentRows
              .slice()
              .sort((a, b) => b.sentiments.length - a.sentiments.length)
            : this.sentimentRows
              .slice()
              .sort((a, b) => a.sentiments.length - b.sentiments.length)
          break
        case 'Very Negative':
          return this.sortSentiment(0)
        case 'Negative':
          return this.sortSentiment(1)
        case 'Neutral':
          return this.sortSentiment(2)
        case 'Positive':
          return this.sortSentiment(3)
        case 'Very Positive':
          return this.sortSentiment(4)
      }

      return rows
    },
    keywordPattern () {
      let strippedKeyword = this.drawerSentimentRowTitle.trim()
      if (strippedKeyword.slice(-1) === '.') {
        strippedKeyword = strippedKeyword.slice(0, -1)
      }
      let keywordPattern = strippedKeyword.replace('.', '\\.')
      return new RegExp(keywordPattern)
    }
  },

  methods: {
    handleTimeUpdate (ev) {
      this.currentAudioTime = ev.srcElement.currentTime
    },
    activeTranscriptsForSentiments (index) {
      return this.transcripts.filter(
        (transcript) => transcript.bucketIdx === Number(index)
      ).length
    },
    playTranscript (responseId) {
      const response = this.$store.getters['insights/findResponse'](responseId)
      if(!response.srcUrl){
        return
      }
      if (response.srcUrl !== this.activeAudioUrl) {
        this.activeResponse = response
        this.$nextTick(() => {
          this.$refs.transcriptAudio.load()
        })
      }
    },
    updateSentimentOption (sentiment) {
      this.activeSentimentIdx = SENTIMENTS.indexOf(sentiment)
    },
    transcriptText (transcript) {
      if (this.identifier) {
        let response = this.$store.getters['insights/findResponse'](
          transcript.responseId
        )
        let matchingSentences = response.sentences.filter((sentence) =>
          sentence.occursOverRoom(this.identifier)
        )
        return matchingSentences.map((sentence) => sentence.text).join(' ').replace(/�/g, '')
      } else {
        if (!transcript.transcript) return ''
        // console.log('transcript', transcript.transcript)
        return transcript.transcript.replace(/�/g, '')
      }
    },
    splitTranscriptText (transcript) {
      // if a period is in tHe keyword it will be missing when we combine
      return this.transcriptText(transcript).split(this.keywordPattern)
    },
    activeName (activeResponse) {
      let name = 'Anonymous User'
      if (activeResponse.firstName && activeResponse.lastName) {
        name = activeResponse.firstName + ' ' + activeResponse.lastName
      } else if (activeResponse.email) {
        name = activeResponse.email
      }
      return name
    },
    renameGroupHandler (e, groupId, groupName) {
      e.stopPropagation()
      this.$emit('groupModalHandler', 'Rename Group', groupId, groupName)
    },
    closeModal () {
      this.showModal = false
    },
    deleteGroupHandler (e, groupId, groupName) {
      e.stopPropagation()
      this.$emit('deleteGroupModalHandler', groupId, groupName)
    },
    addToGroupHandler (e, id, groupId) {
      let oldValue = this.isPopupOpen[id]
      this.$set(this.isPopupOpen, id, !oldValue)
      e.stopPropagation()
      this.$emit('existGroupModalHandler', id, groupId)
      this.isPopupOpen = {}
    },
    removeFromGroup (e, id) {
      let oldValue = this.isPopupOpen[id]
      this.$set(this.isPopupOpen, id, !oldValue)
      e.stopPropagation()

      this.$axios
        .request({
          url: `/entity_sentiments/${id}/assign/`,
          method: 'patch',
          data: { entity_sentiments_group_id: null }
        })
        .then((res) => {
          this.$store.dispatch('insights/updateEntitySentimentGroup', {
            entityId: id,
            groupId: null
          })
          this.$emit('showToast', 'Remove Successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    sentimentPercents (sentiments) {
      let rowCounts = this.sentimentCounts(sentiments)
      let totalCount = sum(rowCounts)
      return rowCounts.map((rowCount) => percent(rowCount, totalCount, 0))
    },
    sentimentCounts (sentiments) {
      // reversing this array because the previous implementation headers were like this
      // ["very negative","negative", "neutral", "positive", "very positive"]
      // the new implementation headers are changed and reversed. so the array is reversed
      // ["very positive","positive", "neutral", "negative", "very negative"]
      return bucketCounts(sentiments).reverse()
    },
    handleColumnSorting (header) {
      this.activeColumn.name === header
        ? (this.activeColumn = {
          ...this.activeColumn,
          ascending: !this.activeColumn.ascending
        })
        : (this.activeColumn = { name: header, ascending: true })
    },
    isExpanded (idx) {
      return Boolean(this.expandedRows[idx])
    },
    isPopupOpenForRow (idx) {
      return Boolean(this.isPopupOpen[idx])
    },
    isExpandedGroup (idx) {
      return Boolean(this.expendedGroups[idx])
    },
    sortColumnProperty (property) {
      return this.activeColumn.ascending
        ? caseInsensitiveSortBy(this.sentimentRows, property)
        : caseInsensitiveSortBy(this.sentimentRows, property, true)
    },
    sortSentiment (index) {
      if (this.activeQuestionModifier === 'votes') {
        return this.activeColumn.ascending
          ? this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentCounts(b.sentiments)[index] -
                  this.sentimentCounts(a.sentiments)[index]
            )
          : this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentCounts(a.sentiments)[index] -
                  this.sentimentCounts(b.sentiments)[index]
            )
      } else {
        return this.activeColumn.ascending
          ? this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentPercents(b.sentiments)[index] -
                  this.sentimentPercents(a.sentiments)[index]
            )
          : this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentPercents(a.sentiments)[index] -
                  this.sentimentPercents(b.sentiments)[index]
            )
      }
    },
    totalFilterGroupedRowData (key) {
      let totalParticipantMember = 0
      let veryNegative = 0
      let negative = 0
      let neutral = 0
      let positive = 0
      let veryPositive = 0
      let totalSentiments = 0
      let counter = 0
      for (let i = 0; i < this.filterSentimentRow[key].length; i++) {
        const sentimentRow = this.filterSentimentRow[key][i]

        totalParticipantMember += sentimentRow.sentiments.length
        const sentimentCounts = this.sentimentCounts(sentimentRow.sentiments)

        totalSentiments += average(sentimentRow.sentiments || [])

        counter++

        veryNegative += sentimentCounts[4]
        negative += sentimentCounts[3]
        neutral += sentimentCounts[2]
        positive += sentimentCounts[1]
        veryPositive += sentimentCounts[0]
      }
      return {
        totalParticipantMember,
        veryNegative,
        negative,
        neutral,
        positive,
        veryPositive,
        totalSentiments: totalSentiments / counter
      }
    },
    filterGroupedRowData (key) {
      const data = this.filterSentimentRow
      return data[key]
    },
    toggleRowExpansion (idx, row) {
      this.drawerSentimentRowTitle = row.title
      if (!this.expandable) {
        return
      }
      // let oldValue = this.expandedRows[idx]
      // this.$set(this.expandedRows, idx, !oldValue)
      this.isPopupOpen = {}
      this.activeSentimentIdx = 4
      this.showDrawer()
    },
    showDrawer () {
      this.showModal = true
    },
    toggleGroupExpansion (idx) {
      let oldValue = this.expendedGroups[idx]
      this.$set(this.expendedGroups, idx, !oldValue)
    },
    togglePopup (e, idx) {
      e.stopPropagation()
      let oldValue = this.isPopupOpen[idx]
      this.$set(this.isPopupOpen, idx, !oldValue)
    }
  }
}
</script>
