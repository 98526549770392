<template>
  <div class="">
    <st-spinner v-if="isLoading" />
    <div class="tw-bg-black tw-text-center tw-py-20 tw-text-[30px] tw-text-white tw-font-semibold">
      Settings
    </div>
    <div
      class=" st-body
      "
    >
      <div
        class="flex space-x-2 px-20 tw-pt-5 mt-0-important tw-text-black-three tw-overflow-x-auto"
      >
        <span
          v-for="tab in tabsList"
          :key="tab"
          :class="[
            {
              'py-3 px-2 cursor-pointer w-full md:fit-content text-center': true,
              'border-bottom-2-primary tw-text-black': tab === activeTab,

              'tw-text-black-three hover:tw-text-black tw-transition-all tw-duration-300':
                tab !== activeTab,
            },
          ]"
          @click="changeActiveTab(tab)"
        >{{ tab }}</span>
      </div>
      <!-- <company v-if="activeTab==tabsList[0]" /> -->
      <billing v-if="activeTab==tabsList[0]" />
      <team v-if="activeTab==tabsList[1]" />
    </div>
    <!-- <div
      id="pricing"
      class="main-container"
    >
      <h2 class="font-600">
        Account information
      </h2>
      <div class="divider w-full my-4" />

      <h2 class="font-600 mt-20">
        Billing information
      </h2>
      <div class="divider w-full my-4" />
      <div class="flex space-x-3">
        <h3 class="font-600 w-300p">
          Subscription Plan
        </h3>
      </div>
      <div class="divider w-full my-4" />
      <div class="flex">
        <div class="w-300p">
          <p class="font-600">
            Usage
          </p>
          <p>
            Additional charges for respondents over your plan’s monthly limit
            appear here
          </p>
        </div>
        <div class="flex flex-col px-10 ">
          <p class="font-600" />
          <p class="flex flex-col ">
            <span>No additional charges</span>
          </p>
        </div>
      </div>
      <div class="flex  bg-gray-100 px-3 py-2 my-4">
        <div class="w-300p">
          <p class="font-600">
            Current monthly bill
          </p>
        </div>
        <div class="flex flex-col px-10">
          <h4 class="font-600" />
        </div>
      </div>
      <div class="flex  bg-gray-100 px-3 py-2 my-4">
        <div class="w-300p">
          <p class="font-600">
            Reward balance
          </p>
        </div>
        <div class="flex flex-col px-10">
          <h4 class="font-600" />
        </div>
      </div>

      <div class="flex my-4 mt-20">
        <div class="w-300p">
          <p class="font-600">
            Payment method details
          </p>
        </div>
        <div class="flex flex-col px-10 ">
          <p class="flex flex-col ">
            <a
              href="#"
              class="text-underline text-primary-brown"
            >Show card details</a>
          </p>
        </div>
      </div>
      <div class="divider" />
      <div class="flex my-4 ">
        <div class="w-300p">
          <p class="font-600">
            Receipt email
          </p>
        </div>
        <div class="flex flex-col px-10 ">
          <p class="flex flex-col " />
        </div>
      </div>
      <div class="divider" />
      <div class="flex my-4 ">
        <div class="w-300p">
          <p class="font-600">
            Account created
          </p>
        </div>
        <div class="flex flex-col px-10 ">
          <p class="flex flex-col " />
        </div>
      </div>
      <div class="my-5" />
      <upgrade-plan :is-main-page="false" />
    </div>
    <h2 class="font-600 mt-20">
      Account information
    </h2>
    <div class="divider my-5" />
    <p class="my-5 mb-20 ">
      <a
        href="#"
        class="rounded-4p px-4 w-fit py-2 border-black block w-fit"
      >Download account data</a>
      <a
        href="#"
        class="rounded-4p px-4 py-2 border-black block w-fit mt-4"
      >Cancel this account</a>
    </p> -->
    <stripe-payment-form ref="stripeForm" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { StripePaymentForm, StSpinner } from '../../common'
import { getCurrentMonth, monthNames } from '../insights/pdfs/utilities'
import { UpgradePlan } from './'
import Company from './company.vue'
import Billing from './billing.vue'
import Team from './team.vue'

// import { advanceToUrl, companyLogoStyle, isUserAdmin } from '../../mixins'
// import { IconWrapper, StCard, StMediaUploader, TopHeader } from '../common/'

export default {
  components: {
    StripePaymentForm,
    StSpinner,
    UpgradePlan,
    Company,
    Billing,
    Team
  },
  // components: {
  // IconWrapper,
  // StCard,
  // StMediaUploader,
  // TopHeader
  // },
  // mixins: [advanceToUrl, companyLogoStyle, isUserAdmin],
  props: {},
  data: function () {
    return {
      isLoading: true,
      rewardBalance: null,
      tabsList: ['Billing', 'Team'],
      activeTab: ''
    }
  },
  mounted () {
    // check if params has tab name
    const tab = location.search.split('tab=')[1]
    if (tab) {
      this.activeTab = this.tabsList[1]
    } else {
      this.activeTab = this.tabsList[0]
    }
    setTimeout(() => {
      this.$axios.request({
        method: 'get',
        url: `charges/check_balance`
      })
        .then(res => {
          this.isLoading = false
          this.rewardBalance = res.data.balance
        })
        .catch(err => { console.log(err) })
    }, 500)
  },
  computed: {
    ...mapState(['currentUser']),
    // getPlanInfo() {
    //   return this.currentUser?.account?.plan;
    // },
    // getRewardBalance(){
    //   if(this.rewardBalance){
    //     return this.rewardBalance/100
    //   }
    //   return 0;
    // },
    currentMonth () {
      return getCurrentMonth()
    },
    currentUserCreatedDate () {
      const createdAt = new Date(this.currentUser.createdAt)
      return `${monthNames[createdAt.getMonth()]} ${createdAt.getDate()}, ${createdAt.getFullYear()}`
    }
  },
  methods: {
    changeActiveTab (tab) {
      this.activeTab = tab
    }
  }
}
</script>
