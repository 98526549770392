<template>
  <div
    class="st-sentiment-data-table-container"
  >
    <table
      :class="{'table-border-bottom': tableCompleted,
               'max-w-800p ': printing,
               'st-sentiment-data-table': !printing}"
    >
      <thead class="st-sentiment-data-table__header">
        <tr class="st-sentiment-data-table__header-row-pdf">
          <th class="st-sentiment-data-table__head-item">
            <div class="st-sentiment-data-table__header-and-column-chevron">
              {{ columnTitle }}
            </div>
          </th>
          <th
            v-for="(header, index) in tableHeaders"
            :key="index"
            class="st-sentiment-data-table__head-item"
            :class="{'p-8p-important':printing}"
          >
            <div
              v-if="tableHeaders.includes(header) && header!==tableHeaders[0]"
              :class="[
                { 'box-15p rounded-full mx-auto ': true },
                sentimentColor(header),
              ]"
            />

            <div
              v-else
              class="st-sentiment-data-table__header-and-column-chevron"
            >
              <span class="font-500">
                {{ header }}
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="st-sentiment-data-table__body-pdf">
        <template v-for="(row, index) in Object.keys(filterSentimentRow)">
          <template v-for="(sentimentRow, idxx) in filterGroupedRowData(row)">
            <tr
              v-if="
                row === 'unGroup' && index + idxx < keywordsTableLength
              "
              :key="sentimentRow.entityId"
              :class="{
                'st-sentiment-data-table__row border-b-gray-100 px-5': true,
                'st-sentiment-data-table__row--header':
                  sentimentRow.entityId === 0 && isFirstRowHeader,
              }"
            >
              <td
                v-if="expandable"
                class="st-sentiment-data-table__item st-sentiment-data-table__item--link"
                @click="toggleRowExpansion(sentimentRow.entityId, sentimentRow)"
              >
                <div
                  :class="{
                    'st-sentiment-data-table__keyword tw-max-w-[350px]': true,
                    'st-sentiment-data-table__keyword-open': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                >
                  {{ sentimentRow.title }}
                </div>
                <div
                  :class="{
                    'st-sentiment-data-table__spacer': true,
                    'st-sentiment-data-table__spacer--expanded': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                />
              </td>
              <td
                v-else
                class="st-sentiment-data-table__item"
              >
                {{ sentimentRow.title }}
              </td>
              <td class="st-sentiment-data-table__item">
                <sentiment-tag
                  :sentiment="
                    rowAverages.find(
                      (row) => row.entityId === sentimentRow.entityId
                    ).sentiments
                  "
                />
              </td>
              <td
                v-if="includeCount"
                class="st-sentiment-data-table__item"
              >
                {{
                  rowTotalCounts.find(
                    (row) => row.entityId === sentimentRow.entityId
                  ).sentimentsLength
                }}
              </td>
              <template v-if="activeQuestionModifier === 'votes'">
                <td
                  v-for="(sentimentCount,
                          percentIdx) in rowSentimentCounts.find(
                            (row) => row.entityId == sentimentRow.entityId
                          ).sentimentCounts"
                  :key="percentIdx + 'percentIndex'"
                  class="st-sentiment-data-table__item"
                >
                  {{ sentimentCount }}
                </td>
              </template>
              <template v-else>
                <td
                  v-for="(sentimentPercent,
                          percentIdx) in rowSentimentPercents.find(
                            (row) => row.entityId == sentimentRow.entityId
                          ).sentimentPercents"
                  :key="percentIdx + 'anotherpercent'"
                  class="st-sentiment-data-table__item"
                >
                  {{ sentimentPercent }}%
                </td>
              </template>
            </tr>
            <!-- <tr
              v-if="isExpanded(sentimentRow.entityId)"
              :key="'expanded' + sentimentRow.entityId"
            >
              <td
                class="st-sentiment-data-table__item"
                :colspan="includeCount ? 8 : 7"
              >
                <slot
                  name="expanded-row"
                  :row="sentimentRow"
                  :row-idx="sentimentRow.entityId"
                />
              </td>
            </tr> -->
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  average,
  bucketIdx,
  bucketCounts,
  caseInsensitiveSortBy,
  percent,
  sum
} from '../../../utilities/data_utilities'
import SentimentTag from './sentiment-tag'
import { kebabCase } from 'lodash'
const SENTIMENTS = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

export default {
  components: {

    'sentiment-tag': SentimentTag

  },
  props: {
    tableCompleted: {
      type: Boolean,
      required: false,
      default: true
    },
    printing: {
      type: Boolean,
      required: false,
      default: false
    },
    sentimentRows: {
      type: Array, // each should be an object with title and sentiments[] defined
      required: false,
      default: () => []
    },
    includeCount: {
      type: Boolean,
      required: false,
      default: false
    },
    isFirstRowHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    totalParticipants: {
      type: Number,
      required: false,
      default: 0
    },
    expandable: {
      type: Boolean,
      required: false,
      default: false
    },
    columnTitle: {
      type: String,
      required: false,
      default: 'Room Name'
    },
    sentimentGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    userSignedIn: {
      type: String,
      required: false
    },
    keywordsTableLength: {
      type: Number,
      required: true
    },
    responses: {
      type: Array,
      required: false
    }
  },
  data: function () {
    return {
      activeColumn: { name: 'Avg. Sentiment', ascending: true },
      expandedRows: {},
      expendedGroups: {},
      showByIndex: null,
      isPopupOpen: {},
      drawerSentimentRowTitle: '',
      headers: [
        'Avg. Sentiment',
        'Mentions',
        'Very Positive',
        'Positive',
        'Neutral',
        'Negative',
        'Very Negative'
      ],
      sentiments: [
        'Very Negative',
        'Negative',
        'Neutral',
        'Positive',
        'Very Positive'
      ],
      showModal: false,
      hoverIdx: null,
      activeSentimentIdx: -1
    }
  },

  computed: {
    ...mapState('insights', ['activeQuestionModifier']),
    sentimentMentionCount () {
      return this.activeTranscripts.length
    },
    transcripts () {
      return this.entitySentimentsKeywords[this.drawerSentimentRowTitle].transcripts
    },
    entityMentionCount () {
      return this.transcripts.length
    },
    activeSentiment () {
      return SENTIMENTS[this.activeSentimentIdx]
    },
    activeTranscripts () {
      return this.transcripts.filter(
        (transcript) => transcript.bucketIdx === Number(this.activeSentimentIdx)
      )
    },

    entitySentimentsKeywords () {
      let keyWordSentiments = {}
      this.responses.forEach((response) => {
        response.entitySentiments.map((entity) => {
          if (keyWordSentiments.hasOwnProperty(entity.name)) {
            keyWordSentiments[entity.name].sentiments.push(
              entity.sentimentScore
            )
            keyWordSentiments[entity.name].transcripts.push({
              bucketIdx: entity.sentimentValueIdx,
              firstName: response.firstName,
              lastName: response.lastName,
              name: response.fullName,
              surveyResponseId: response.surveyResponseId,
              responseId: response.id,
              transcript: response.transcript
            })
          } else {
            keyWordSentiments[entity.name] = {
              entityId: entity.id,
              entitySentimentsGroupId: entity.entitySentimentsGroupId,
              sentiments: [entity.sentimentScore],
              transcripts: [
                {
                  bucketIdx: entity.sentimentValueIdx,
                  firstName: response.firstName,
                  lastName: response.lastName,
                  name: response.fullName,
                  responseId: response.id,
                  surveyResponseId: response.surveyResponseId,
                  transcript: response.transcript
                }
              ]
            }
          }
        })
      })
      return keyWordSentiments
    },

    filterSentimentRow () {
      const filterSentimentRows = { unGroup: [] }

      for (let i = 0; i < this.sentimentGroups.length; i++) {
        const group = this.sentimentGroups[i]
        filterSentimentRows[group.name] = []
      }

      for (let i = 0; i < this.sentimentRows.length; i++) {
        const sentiment = this.sentimentRows[i]
        let groupFound = false
        for (let j = 0; j < this.sentimentGroups.length; j++) {
          const group = this.sentimentGroups[j]
          if (sentiment.entitySentimentsGroupId === group.id) {
            filterSentimentRows[group.name].push(sentiment)
            groupFound = true
          }
        }
        if (!groupFound) {
          filterSentimentRows.unGroup.push(sentiment)
        }
      }

      for (const key in filterSentimentRows) {
        if (
          Array.isArray(filterSentimentRows[key]) &&
          !filterSentimentRows[key].length
        ) {
          delete filterSentimentRows[key]
        }
      }

      let sortGroupedRow = {}
      let unGroupRow = {}
      const reversedKeys = Object.keys(filterSentimentRows).sort()
      reversedKeys.forEach((key) => {
        if ([key] !== 'unGroup') {
          sortGroupedRow = {
            ...sortGroupedRow,
            [key]: filterSentimentRows[key]
          }
        } else {
          unGroupRow = {
            ...unGroupRow,
            [key]: filterSentimentRows[key]
          }
        }
      })
      return { ...unGroupRow, ...sortGroupedRow }
    },
    rowAverages () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentiments: average(sentimentRow.sentiments || [])
        }
      })
    },
    rowTotalCounts () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentimentsLength: sentimentRow.sentiments.length
        }
      })
    },
    rowAverageBucketIndexes () {
      return this.rowAverages.map((average) => bucketIdx(average))
    },
    rowSentimentCounts () {
      return this.sortedRowsByActiveColumn.map((row) => {
        return {
          entityId: row.entityId,
          sentimentCounts: this.sentimentCounts(row.sentiments)
        }
      })
    },
    rowSentimentPercents () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentimentPercents: this.sentimentPercents(sentimentRow.sentiments)
        }
      })
    },
    tableHeaders () {
      return this.includeCount
        ? this.headers
        : this.headers.filter((header) => header !== 'Mentions')
    },
    sortedRowsByActiveColumn () {
      let rows = []
      switch (this.activeColumn.name) {
        case 'Keyword':
          return this.sortColumnProperty('title')
        case 'Avg. Sentiment':
          rows = this.activeColumn.ascending
            ? this.sentimentRows
              .slice()
              .sort((a, b) => b.averageSentiment - a.averageSentiment)
            : this.sentimentRows
              .slice()
              .sort((a, b) => a.averageSentiment - b.averageSentiment)
          break
        case 'Participant Mentions':
          rows = this.activeColumn.ascending
            ? this.sentimentRows
              .slice()
              .sort((a, b) => b.sentiments.length - a.sentiments.length)
            : this.sentimentRows
              .slice()
              .sort((a, b) => a.sentiments.length - b.sentiments.length)
          break
        case 'Very Negative':
          return this.sortSentiment(0)
        case 'Negative':
          return this.sortSentiment(1)
        case 'Neutral':
          return this.sortSentiment(2)
        case 'Positive':
          return this.sortSentiment(3)
        case 'Very Positive':
          return this.sortSentiment(4)
      }

      return rows
    },
    keywordPattern () {
      let strippedKeyword = this.drawerSentimentRowTitle.trim()
      if (strippedKeyword.slice(-1) === '.') {
        strippedKeyword = strippedKeyword.slice(0, -1)
      }
      let keywordPattern = strippedKeyword.replace('.', '\\.')
      return new RegExp(keywordPattern)
    }
  },
  mounted () {
    this.activeSentimentIdx = 4
    // console.log(this.keywordsTableLength)
    // console.log(this.keywordsTableLength)
  },
  methods: {

    updateSentimentOption (sentiment) {
      this.activeSentimentIdx = SENTIMENTS.indexOf(sentiment)
    },
    transcriptText (transcript) {
      if (this.identifier) {
        let response = this.$store.getters['insights/findResponse'](
          transcript.responseId
        )
        let matchingSentences = response.sentences.filter((sentence) =>
          sentence.occursOverRoom(this.identifier)
        )
        return matchingSentences.map((sentence) => sentence.text).join(' ').replace(/�/g, '')
      } else {
        // console.log('transcript', transcript.transcript)
        if (!transcript.transcript) return ''
        return transcript.transcript.replace(/�/g, '')
      }
    },
    splitTranscriptText (transcript) {
      // if a period is in tHe keyword it will be missing when we combine
      return this.transcriptText(transcript).split(this.keywordPattern)
    },
    activeName (activeResponse) {
      let name = 'Anonymous User'
      if (activeResponse.firstName && activeResponse.lastName) {
        name = activeResponse.firstName + ' ' + activeResponse.lastName[0]
      } else if (activeResponse.email) {
        name = activeResponse.email
      }
      return name
    },
    renameGroupHandler (e, groupId, groupName) {
      e.stopPropagation()
      this.$emit('groupModalHandler', 'Rename Group', groupId, groupName)
    },
    closeModal () {
      this.showModal = false
    },
    deleteGroupHandler (e, groupId, groupName) {
      e.stopPropagation()
      this.$emit('deleteGroupModalHandler', groupId, groupName)
    },
    addToGroupHandler (e, id, groupId) {
      let oldValue = this.isPopupOpen[id]
      this.$set(this.isPopupOpen, id, !oldValue)
      e.stopPropagation()
      this.$emit('existGroupModalHandler', id, groupId)
      this.isPopupOpen = {}
    },
    removeFromGroup (e, id) {
      let oldValue = this.isPopupOpen[id]
      this.$set(this.isPopupOpen, id, !oldValue)
      e.stopPropagation()

      this.$axios
        .request({
          url: `/entity_sentiments/${id}/assign/`,
          method: 'patch',
          data: { entity_sentiments_group_id: null }
        })
        .then((res) => {
          this.$store.dispatch('insights/updateEntitySentimentGroup', {
            entityId: id,
            groupId: null
          })
          this.$emit('showToast', 'Remove Successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    sentimentPercents (sentiments) {
      let rowCounts = this.sentimentCounts(sentiments)
      let totalCount = sum(rowCounts)
      return rowCounts.map((rowCount) => percent(rowCount, totalCount, 0))
    },
    sentimentCounts (sentiments) {
      // reversing this array because the previous implementation headers were like this
      // ["very negative","negative", "neutral", "positive", "very positive"]
      // the new implementation headers are changed and reversed. so the array is reversed
      // ["very positive","positive", "neutral", "negative", "very negative"]
      return bucketCounts(sentiments).reverse()
    },
    handleColumnSorting (header) {
      this.activeColumn.name === header
        ? (this.activeColumn = {
          ...this.activeColumn,
          ascending: !this.activeColumn.ascending
        })
        : (this.activeColumn = { name: header, ascending: true })
    },
    isExpanded (idx) {
      return Boolean(this.expandedRows[idx])
    },
    isPopupOpenForRow (idx) {
      return Boolean(this.isPopupOpen[idx])
    },
    isExpandedGroup (idx) {
      return Boolean(this.expendedGroups[idx])
    },
    sortColumnProperty (property) {
      return this.activeColumn.ascending
        ? caseInsensitiveSortBy(this.sentimentRows, property)
        : caseInsensitiveSortBy(this.sentimentRows, property, true)
    },
    sortSentiment (index) {
      if (this.activeQuestionModifier === 'votes') {
        return this.activeColumn.ascending
          ? this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentCounts(b.sentiments)[index] -
                  this.sentimentCounts(a.sentiments)[index]
            )
          : this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentCounts(a.sentiments)[index] -
                  this.sentimentCounts(b.sentiments)[index]
            )
      } else {
        return this.activeColumn.ascending
          ? this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentPercents(b.sentiments)[index] -
                  this.sentimentPercents(a.sentiments)[index]
            )
          : this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentPercents(a.sentiments)[index] -
                  this.sentimentPercents(b.sentiments)[index]
            )
      }
    },
    totalFilterGroupedRowData (key) {
      let totalParticipantMember = 0
      let veryNegative = 0
      let negative = 0
      let neutral = 0
      let positive = 0
      let veryPositive = 0
      let totalSentiments = 0
      let counter = 0
      for (let i = 0; i < this.filterSentimentRow[key].length; i++) {
        const sentimentRow = this.filterSentimentRow[key][i]

        totalParticipantMember += sentimentRow.sentiments.length
        const sentimentCounts = this.sentimentCounts(sentimentRow.sentiments)

        totalSentiments += average(sentimentRow.sentiments || [])

        counter++

        veryNegative += sentimentCounts[0]
        negative += sentimentCounts[1]
        neutral += sentimentCounts[2]
        positive += sentimentCounts[3]
        veryPositive += sentimentCounts[4]
      }
      return {
        totalParticipantMember,
        veryNegative,
        negative,
        neutral,
        positive,
        veryPositive,
        totalSentiments: totalSentiments / counter
      }
    },
    filterGroupedRowData (key) {
      const data = this.filterSentimentRow
      return data[key]
    },
    toggleRowExpansion (idx, row) {
      this.drawerSentimentRowTitle = row.title
      if (!this.expandable) {
        return
      }
      // let oldValue = this.expandedRows[idx]
      // this.$set(this.expandedRows, idx, !oldValue)
      this.isPopupOpen = {}
      this.activeSentimentIdx = 4
      this.showDrawer()
    },
    showDrawer () {
      this.showModal = true
    },
    toggleGroupExpansion (idx) {
      let oldValue = this.expendedGroups[idx]
      this.$set(this.expendedGroups, idx, !oldValue)
    },
    togglePopup (e, idx) {
      e.stopPropagation()
      let oldValue = this.isPopupOpen[idx]
      this.$set(this.isPopupOpen, idx, !oldValue)
    },
    sentimentColor (value) {
      return { [`st-sentiment-badge--${kebabCase(value)}`]: true }
    }
  }
}
</script>
