<template>
  <button
    :disabled="disabled"
    :class="{
      // disable classes
      'tw-transition-all tw-duration-200': !disabled,
      'tw-cursor-not-allowed tw-opacity-40': disabled,

      // rounded classes
      'tw-rounded-full': rounded,
      'tw-rounded-xl': !rounded,

      // variant classes
      'tw-bg-primary tw-text-white': type === 'primary',
      'tw-text-black-three tw-bg-white': type === 'tertiary',
      'tw-bg-primary-light tw-text-primary': type === 'secondary',
      'tw-bg-white tw-text-black tw-border tw-border-solid tw-border-white-two': type === 'outlined',

      // hover classes
      'hover:tw-bg-primary-dark': !disabled && type === 'primary',
      'hover:tw-bg-secondary-dark hover:tw-text-black': !disabled && type === 'secondary',
      'hover:tw-bg-light-gray-four hover:tw-text-black': !disabled && type === 'outlined',

      // size classes
      'tw-p-4 tw-text-sm': size === 'large',
      'tw-p-3 tw-text-[13px]': size === 'default',
      'tw-p-2 tw-text-[13px]': size === 'small',
      'tw-w-full tw-p-3': size === 'full'
    }"
    @click="$emit('click')"
    @blur="$emit('blur')"
  >
    <slot>
      <span>
        {{ caption }}
      </span>
    </slot>
  </button>
</template>

<script>
export default {

  props: {
    type: {
      type: String,
      required: false,
      default: 'primary'
    },
    size: {
      type: String,
      required: false,
      default: 'default'
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }

  },

  data () {
    return {

    }
  },

  mounted () {

  },

  methods: {

  }
}
</script>
