<template>
      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="21" cy="21" r="21" fill="#69C2DE"/>
<rect x="14" y="13" width="5" height="16" rx="2.5" fill="white"/>
<rect x="23" y="13" width="5" height="16" rx="2.5" fill="white"/>
</svg>


</template>

<script>
export default {}
</script>
