<template>
  <div
    :class="{
      'st-participant__container tw-relative': true,
      'st-participant__container--demo': isDemoPreview
    }"
  >
    <div
      v-if="account && account.logoUrl && isStudy"
      class="tw-top-[84px] sm:tw-top-6 tw-left-0 tw-absolute tw-flex tw-justify-center tw-bg-white tw-w-full"
    >
      <img
        :src="account && account.logoUrl"
        class="tw-block tw-mx-auto tw-h-16 tw-w-auto"
      >
    </div>
    <!-- <div
      v-if="surveyQuestionCount > 1"
      :class="{
        'st-participant__header': true,
        'st-participant__header--hide': hideLogoHeader || question.questionType === 'virtual_tour',
        'st-participant__header--desktop': isDesktop,
        'st-participant__header--full-width': question.fullWidth
      }"
    >
      <a
        href="#"
        class="st-participant__back-icon"
        @click="$goBack"
      >
        <icon-wrapper
          class="st-hyperlink__back-icon"
          icon-name="chevron"
        />
      </a>
      <st-hyperlink
        v-if="isPreview && !isDesktop"
        text="Exit Preview"
        icon-name="hide-password"
        icon-class="st-hyperlink__default-icon-spacing"
        :url="`${baseUrl}/study${surveyId}/edit/content?question=${question.id}&type=${question.questionType}`"
      />
      <div
        v-else
        class="st-participant__question-count"
      >
        {{ question.priority + 1 }} of {{ surveyQuestionCount }}
      </div>
    </div> -->
    <div
      :class="{
        'st-participant__component-contain': !isDemoPreview,
        'st-participant__component-and-footer': isDesktop,
        'st-participant__component-and-footer--demo-preview': isDemoPreview
      }"
    >
      <!-- <p class="tw-top-5 tw-left-0 tw-absolute tw-px-8">
        This is logo
      </p> -->
      <div

        :class="{
          'bx--grid': question.questionType !== 'virtual_tour' && isStudy,
          'st-participant__component-wrapper ': true,
          // 'st-participant__component-wrapper--hide-logo-header': hideLogoHeader,
          'st-participant__component-wrapper--full-width': fullWidth,
          'st-participant__component-wrapper--virtual-tour': question.questionType === 'virtual_tour',
          'st-participant__component-wrapper--demo-preview': isDemoPreview
        }"
      >
        <div :class="{'tw-h-full tw-flex tw-flex-col tw-mt-12 tw-justify-center tw-items-center tw-my-auto tw-py-2 tw-px-2 tw-w-full tw-rounded-3xl tw-relative widget-container': !isStudy, 'tw-h-full': isStudy}">
          <div
            :class="{'tw-p-5 tw-h-[360px] tw-rounded-3xl tw-overflow-auto tw-w-full tw-flex-col tw-flex tw-justify-center tw-relative tw-my-1 tw-shadow-[0px_4px_8px_0_rgba(0,0,0,0.25)]': !isStudy, 'tw-h-full': isStudy}"
            :style="containerStyle"
          >
            <template v-if="!isStudy">
              <div
                v-if="initialQuestion.questionVideoUrl"
                class="-tw-top-20 tw-right-0 tw-left-0 tw-z-10 tw-absolute tw-flex tw-justify-center tw-m-auto"
              >
                <span
                  v-if="!videoPlaying"
                  class="tw-z-20 tw-absolute tw-flex tw-justify-center tw-items-center tw-bg-black/20 tw-rounded-full tw-h-[130px] tw-w-[130px] tw-text-white tw-cursor-pointer"
                  @click="playVideo"
                >

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="tw-h-10 tw-w-10"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                      clip-rule="evenodd"
                    />
                  </svg>

                </span>
                <video
                  ref="videoPlayer"
                  style="transform:scaleX(-1)"
                  playsinline
                  webkit-playsinline
                  class="tw-z-10 tw-rounded-full tw-h-[130px] tw-object-cover tw-w-[130px]"
                  @play="handleVideoPlay"
                  @ended="handleVideoEnd"
                >
                  <source
                    :src="initialQuestion.questionVideoUrl"
                    type="video/mp4"
                  >
                  Your browser does not support this video
                </video>
                <!-- <img
                  :src="PersonImagePath"
                  width="200"
                > -->
              </div>
              <!-- <span class="tw-top-2 tw-right-2 tw-absolute tw-flex tw-justify-center tw-items-center tw-bg-[#F8F8F8] tw-rounded-full tw-h-[25px] tw-w-[25px] tw-cursor-pointer">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_118_1961)">
                    <path
                      d="M8.54266 7.51546L13.1884 2.52368C13.6027 2.07723 13.6027 1.35079 13.1884 0.904345C12.7857 0.471901 12.0831 0.471901 11.6804 0.904345L7.03319 5.89612L2.31935 0.834345C1.9036 0.387901 1.22853 0.387901 0.811329 0.834345C0.395573 1.28079 0.395573 2.00723 0.811329 2.45368L5.52517 7.51701L0.844648 12.5461C0.428891 12.9926 0.428891 13.719 0.844648 14.1655C1.04601 14.3817 1.314 14.4999 1.59793 14.4999C1.88187 14.4999 2.14986 14.3817 2.35122 14.1655L7.03319 9.13634L11.6789 14.125C11.8803 14.3412 12.1483 14.4595 12.4322 14.4595C12.7162 14.4595 12.9842 14.3412 13.187 14.125C13.6013 13.6786 13.6013 12.9521 13.187 12.5057L8.54121 7.51701L8.54266 7.51546Z"
                      fill="#979797"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_118_1961">
                      <rect
                        width="13"
                        height="14"
                        fill="white"
                        transform="translate(0.5 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>

              </span> -->

              <div
                v-if="!hideQuestionText && !isStudy"
                class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-6 tw-h-full"
              >
                <p
                  class="tw-flex tw-flex-col tw-text-center"
                  :style="textStyle"
                >
                  <span class="tw-text-center tw-text-gray-600 tw-text-sm">
                    Question
                  </span>
                  <span class="tw-font-semibold tw-text-xl">
                    {{ stripHtmlTags(question.description) || '' }}
                  </span>
                </p>
              </div>
              <div class="tw-border-gray-100 tw-my-3 tw-border-b tw-border-solid tw-w-full" />

              <div v-if="showAnswerButtons">
                <button
                  v-if="question.askForExplanation==='let_user_decide' || question.askForExplanation==='voice_recording'"
                  class="tw-flex tw-justify-center tw-items-center tw-space-x-3 tw-mt-4 tw-px-4 tw-py-3 tw-rounded-md tw-w-full"
                  :style="buttonStyle"
                  @click="askForAudioPermission"
                >
                  <span
                    class="tw-flex tw-items-center"
                  >Record answer</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    />
                  </svg>
                </button>
                <button
                  v-if="question.askForExplanation==='let_user_decide' || question.askForExplanation==='text'"
                  class="tw-flex tw-justify-center tw-items-center tw-space-x-3 tw-mt-2 tw-px-4 tw-py-3 tw-rounded-md tw-w-full"
                  :style="buttonStyle"
                  @click="typeYourAnswer"
                >
                  <span
                    class="tw-flex tw-items-center"
                  >Type answer</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    />
                  </svg>
                </button>
              </div>
            </template>

            <component
              :is="componentName"
              :key="question.id"
              ref="questionComponent"
              :ask-for-explanation="question.askForExplanation"
              :description="question.description"
              :image="question.image"
              :image-url="question.imageUrl"
              :options="question.options"
              :question-id="question.id"
              :priority="question.priority"
              :prompt-lookup="question.promptLookup"
              :logo-url="companyLogo"
              :min-x-label="question.minXLabel"
              :max-x-label="question.maxXLabel"
              :min-y-label="question.minYLabel"
              :max-y-label="question.maxYLabel"
              :media-full-url="question.mediaFullUrl"
              :media-url="question.mediaUrl"
              :matterport-id="question.matterportId"
              :panoskin-id="question.panoskinId"
              :media="question.media"
              :next-question-id="question.nextQuestionId || 0"
              :next-preview-question="question.nextPreviewQuestion"
              :previous-preview-question="question.previousPreviewQuestion"
              :survey-id="surveyId"
              :survey-question-count="surveyQuestionCount"
              :is-demo-preview="isDemoPreview"
              :is-preview="isPreview"
              :is-join-survey="isJoinSurvey"
              :is-study="isStudy"
              :widget-config="widgetConfig"
              :is-survey-preview="isPreview"
              :question-responses="question.questionResponses"
              :is-single-question-survey="surveyQuestionCount === 1"
              :can-skip="question.canSkip"
              :optional="question.optional"
              :account="account"
              :scale-length="question.scaleLength"
              :scale-style="question.scaleStyle"
              :spacetrics-logo="spacetricsLogo"
              :button-caption="buttonCaption"
              :show-question-instructions="question.showInstructions"
              :active-widget="activeWidget"
              :option-for-survey="currentSurveyOption"
              @changeSurveyOption="handleChangeSurveyOption"
              @errorMessage="errorMessage"
              @hideLogoHeader="handleHideLogoHeader"
              @hideSpacetricsFooter="handleHideSpacetricsFooter"
              @loading="active = true"
              @nextQuestion="nextQuestion"
              @hideQuestionTextHandler="hideQuestionTextHandler"
              @showQuestionTextHandler="showQuestionTextHandler"
            />
          </div>
        </div>
      </div>
    </div>
    <st-toast-notification
      v-if="showErrorToast"
      class="st-toast-notification--fixed"
      kind="error"
      title="Error"
      :caption="error"
      @click="showErrorToast = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyLogoStyle, isDesktop, setSurveyType } from '../../../mixins'
import { sendNameEmail } from '../../../utilities/fullstory'
import { IconWrapper, StHyperlink, StToastNotification } from '../../common'
// import Loading from '@carbon/vue/src/components/cv-loading/cv-loading'
// import CultureBoard from './question_types/culture-board'
// import PerceptionSlider from './question_types/perception-slider'
// import QuadrantPlacement from './question_types/quadrant-placement'
// import SpatialAdjacencies from './question_types/spatial-adjacencies'
// import ElementPreferences from './question_types/element-preferences'
// import ConceptRanking from './question_types/concept-ranking'
// import PickFavorite from './question_types/pick-favorite'
// import PreferenceSlider from './question_types/preference-slider'
import PersonImage from '../../../../assets/images/microphone_person.png'
import * as QuestionTypes from './question_types'

export default {
  components: {
    IconWrapper,
    ...QuestionTypes,
    StHyperlink,
    StToastNotification
  },
  mixins: [companyLogoStyle, isDesktop, setSurveyType],
  props: {
    hideMatterportIndicator: {
      type: Boolean,
      required: false,
      default: false
    },
    initialQuestion: {
      type: Object,
      required: true
    },
    isDemoPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    participantEmail: {
      default: '',
      type: String,
      required: false
    },
    participantName: {
      default: '',
      type: String,
      required: false
    },
    participantToken: {
      default: '',
      type: String,
      required: false
    },
    questionResponses: {
      type: Array,
      required: false,
      default: () => []
    },
    surveyId: {
      type: Number,
      required: true
    },
    surveyQuestionCount: {
      type: Number,
      required: false,
      default: 0
    },
    surveyName: {
      type: String,
      required: true
    },
    account: {
      type: Object,
      required: false,
      default: () => ({})
    },
    survey: {
      type: Object,
      required: false,
      default: () => ({})
    },
    widgetConfig: {
      type: Object,
      required: false,
      default: () => ({})
    },
    spacetricsLogo: {
      type: String,
      required: false,
      default: ''
    },
    isJoinSurvey: {
      type: Boolean,
      required: false,
      default: false
    },
    responseType: {
      type: String,
      required: false,
      default: null
    },
    activeWidget: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: function () {
    return {
      active: false,
      companyLogo: this.initialQuestion.logoUrl,
      error: null,
      hideLogoHeader: false,
      hideSpacetricsFooter: false,
      question: this.initialQuestion,
      showErrorToast: false,
      currentSurveyOption: this.responseType,
      PersonImagePath: PersonImage,
      showAnswerButtons: true,
      videoPlaying: false,
      hideQuestionText: false
    }
  },

  computed: {
    ...mapGetters('participants', ['fullWidth']),
    baseUrl () { return window.location.origin },
    componentName () {
      return this.question.questionType.replace('_', '-')
    },
    containerStyle () {
      if (this.widgetConfig && this.activeWidget) {
        return { background: this.widgetConfig.background_color }
      } else {
        return {}
      }
    },
    textStyle () {
      if (this.widgetConfig) {
        return { color: this.widgetConfig.text_color }
      } else {
        return {}
      }
    },
    headerStyle () {
      if (this.widgetConfig) {
        return { color: this.widgetConfig.text_color }
      } else {
        return {}
      }
    },
    buttonStyle () {
      if (this.widgetConfig) {
        return { background: this.widgetConfig.primary_button_background_color, color: this.widgetConfig.primary_button_text_color }
      } else {
        return {}
      }
    },
    isStudy () {
      return !this.activeWidget
    },
    hideFullWidthFooter () {
      return this.fullWidth && this.isDesktop
    },
    buttonCaption () {
      if (this.question.priority + 1 === this.surveyQuestionCount) {
        return this.$t('submit')
      }
      return this.$t('next')
    },
    isStatic () {
      return this.question.askForExplanation !== 'voice_recording' || this.question.questionType === 'concept_ranking' || this.question.options.length <= 1
    }
  },
  // beforeMounted () {
  //   this.currentSurveyOption = this.responseType
  // },
  beforeMount () {
    this.changeLanguage(this.survey.language)
  },
  mounted () {
    setTimeout(() => {
      this.playVideo()
    }, 400)
    setTimeout(() => {
      if (this.activeWidget) {
        this.removeClassesOfWidget()
      }
    }, 100)
    if (!this.isPreview) { sendNameEmail(this.participantName, this.participantEmail) }

    window.addEventListener('popstate', this.reloadQuestion)
    let state = { questionId: this.initialQuestion.id }
    let title = 'question: ' + this.question.id
    let url = '/questions/' + this.question.id

    if (this.isPreview) { url = '/questions/' + this.question.id + '/preview' }
    if (!this.isDemoPreview) { window.history.replaceState(state, title, url) }

    this.$store.commit('hideSidebar', true)
    this.$store.commit('participants/setParticipantSubmissionToken', { token: this.participantToken })
    this.$store.commit('participants/setActiveQuestion', this.question) // TBD: Update each component to refer to activeQuestion in participant store, rather than pass in via props
    this.$store.commit('participants/setMatterportIndicator', this.hideMatterportIndicator)
    this.$store.commit('participants/setParticipantEmail', this.participantEmail)
  },
  created () {
    this.setSurveyType()
  },
  methods: {
    changeLanguage (lang) {
      this.$i18n.locale = lang
    },
    removeClassesOfWidget () {
      const elements = document.getElementsByClassName('widget-container')

      Array.from(elements).forEach((element) => {
        element.style.background = 'transparent'
        let parent = element.parentElement
        while (parent) {
          parent.style.background = 'transparent'
          parent = parent.parentElement
        }
      })
    },
    playVideo () {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.load()
        this.$refs.videoPlayer.play()
        this.$ref.videoPlayer.muted = false
        this.videoPlaying = true
      }
    },

    handleVideoPlay () {
      // Video started playing

      this.videoPlaying = true
    },
    handleVideoEnd () {
      // Video ended playing
      this.videoPlaying = false
    },
    handleChangeSurveyOption (option) {
      this.currentSurveyOption = option
    },

    askForAudioPermission () {
      this.showAnswerButtons = false
      this.$refs.questionComponent.hideWidgetScreen()
      this.$refs.questionComponent.askForAudioPermission()
    },

    typeYourAnswer () {
      this.showAnswerButtons = false
      this.$refs.questionComponent.hideWidgetScreen()
      this.$refs.questionComponent.typeYourAnswer()
    },

    errorMessage () {
      // TODO: make this a more specific message (emitted from submitQuestionAnswer mixin)
      this.error = 'There was a problem submitting your question answer.'
      this.showErrorToast = true
      this.active = false
    },
    handleHideLogoHeader (payload) {
      this.hideLogoHeader = payload
    },
    handleHideSpacetricsFooter (payload) {
      this.hideSpacetricsFooter = payload
    },
    stripHtmlTags (html) {
      return html.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
    },
    hideQuestionTextHandler () {
      this.hideQuestionText = true
    },
    showQuestionTextHandler () {
      this.hideQuestionText = false
      this.showAnswerButtons = true
    },
    nextQuestion (nextQuestionData) {
      this.$store.commit('participants/setActiveQuestion', nextQuestionData)
      this.$store.commit('toggleFullSpinner', false)
      this.question = nextQuestionData
      this.active = false
      let state = { questionId: nextQuestionData.id }
      let title = 'question: ' + nextQuestionData.id
      let url = '/questions/' + nextQuestionData.id

      if (this.isPreview) { url = '/questions/' + nextQuestionData.id + '/preview' }
      window.history.pushState(state, title, url)
    },
    reloadQuestion () {
      this.active = true
      this.$nextTick(() => {
        let url = `/questions/${window.history.state.questionId}/question_and_response.json`
        this.$axios.get(url).then(res => {
          this.question = res.data
          this.active = false
          this.submitting = false
          this.$store.commit('participants/setActiveQuestion', res.data)
        })
          .catch(err => { console.log(err) })
      })
    }
  }
}
</script>
