import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import browserDetect from 'vue-browser-detect-plugin'
import AOS from 'aos'

// carbon components //
import CvButton from '@carbon/vue/src/components/cv-button/cv-button'
import CvIcon from '@carbon/vue/src/components/cv-icon/cv-icon'
// import CvLoading from '@carbon/vue/src/components/cv-loading/cv-loading'
import CvTextInput from '@carbon/vue/src/components/cv-text-input/cv-text-input'
import CvTile from '@carbon/vue/src/components/cv-tile/cv-tile'
import CvTooltip from '@carbon/vue/src/components/cv-tooltip/cv-tooltip'
// import CvToastNotification from '@carbon/vue/src/components/cv-toast-notification/cv-toast-notification'
import CvOverflowMenuItem from '@carbon/vue/src/components/cv-overflow-menu/cv-overflow-menu-item'
import 'vue-swatches/dist/vue-swatches.css'
// New Components
import '../../tailwindcss/tailwind-output.css'
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInput,
  SecondaryButton,
  StSpinner,
  PhonePreview,
  StToastNotification,
  StOverflowMenu,
  StOverflowMenuItem,
  TopHeader,
  ToggleButton,
  SentimentEmoji,
  StripePaymentForm,
  StudyStatus,
  Pagination,
  ProfileImage,
  AhButton,
  SelectableButton,
  AhInput,
  AhToggleSwitch,
  TextDivider,
  AhSmallLoader
  // AnswersDrawer
} from '../components/common/'

import StSidebar from '../components/layout/st-sidebar'

// New Pages
import * as AudiencesPages from '../components/pages/audiences'
import * as HelpsPages from '../components/pages/helps'
import * as InsightsPages from '../components/pages/insights'
import * as LibrariesPages from '../components/pages/libaries'
import * as OnboardingPages from '../components/pages/onboarding'
import * as Participants from '../components/pages/participants'
import * as ProfilePages from '../components/pages/profile'
import * as ProjectsPages from '../components/pages/projects'
import * as ProspectivePages from '../components/pages/prospectives'
import * as SurveysPages from '../components/pages/surveys'
import * as TeamsPages from '../components/pages/teams'
import * as StrategiesPages from '../components/pages/strategies'
import * as GuestUsersPages from '../components/pages/guest-users'

import About from '../components/pages/about.vue'
import Home from '../components/pages/home.vue'
import Welcome from '../components/pages/welcome.vue'
import Admin from '../components/pages/admin.vue'
import QuotaExceeded from '../components/pages/quota-exceeded.vue'
import AdminAccount from '../components/pages/admin-account.vue'
import AdminPlayground from '../components/pages/admin-playground.vue'

// utilities
import storeFactory from '../store'
import initializeVuePrototype from '../utilities/vue_prototype_initializer'
import { axiosInstance } from '../utilities/request_utilities'
import VueQuillEditor from 'vue-quill-editor'
import posthogPlugin from '../../../plugins/posthog'
import * as Sentry from '@sentry/vue'
import VueTour from 'vue-tour'
import posthog from 'posthog-js'
import i18n from './i18n';
import VueI18n from 'vue-i18n';
require('vue-tour/dist/vue-tour.css')
document.addEventListener('DOMContentLoaded', () => {
  posthog.init(process.env.POSTHOG_API_KEY, { api_host: process.env.POSTHOG_API_HOST })
  // Sentry initialization
  Sentry.init({
    Vue,
    dsn: process.env.VUE_SENTRY_DSN,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    integrations: [],
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost:3000', process.env.BASE_URL],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
  Vue.use(Vuex)
  Vue.use(VueI18n)
  // Vue.use(posthogPlugin)
  Vue.use(VueQuillEditor)
  Vue.use(browserDetect)
  Vue.use(VueTour)
  Vue.prototype.$goBack = (ev) => {
    ev.preventDefault()
    window.history.back()
  }

  const el = document.getElementById('vue-wrapper')
  const directUploadUrl = el ? el.dataset.directUploadUrl : null

  const app = new Vue({
    i18n,
    store: storeFactory(),
    el: '#vue-wrapper',
    components: {
      'cv-button': CvButton,
      'cv-icon': CvIcon,
      // 'cv-loading': CvLoading,
      'cv-overflow-menu-item': CvOverflowMenuItem,
      'cv-text-input': CvTextInput,
      'cv-tile': CvTile,
      // 'cv-toast-notification': CvToastNotification,
      'cv-tooltip': CvTooltip,

      // New Components
      IconWrapper,
      StButton,
      StHyperlink,
      StInput,
      StSidebar,
      StSpinner,
      StToastNotification,
      StOverflowMenu,
      StOverflowMenuItem,
      TopHeader,
      ToggleButton,
      SecondaryButton,
      StudyStatus,
      SentimentEmoji,
      StripePaymentForm,
      Pagination,
      ProfileImage,
      AhButton,
      TextDivider,
      PhonePreview,
      AhToggleSwitch,
      AhInput,
      AhSmallLoader,
      // AnswersDrawer,
      SelectableButton,
      ...AudiencesPages,
      ...HelpsPages,
      ...InsightsPages,
      ...LibrariesPages,
      ...OnboardingPages,
      ...Participants,
      ...ProfilePages,
      ...ProjectsPages,
      ...ProspectivePages,
      ...SurveysPages,
      ...TeamsPages,
      ...StrategiesPages,
      ...GuestUsersPages,

      // uncategorized pages
      'about': About,
      'home': Home,
      'welcome': Welcome,
      'admin': Admin,
      'quota-exceeded': QuotaExceeded,
      'admin-account': AdminAccount,
      'admin-playground': AdminPlayground
    },
    data: {
      hideNotifications: false,
      tmp: ''
    },
    computed: {
      ...Vuex.mapState({
        loadingOverlay: state => state.isFullSpinnerVisible
      })
    },
    mounted () {
      window.vue = this
      this.$store.commit('setCustomAxios', axiosInstance())
      this.$store.commit('addDirectUploadUrl', directUploadUrl)
      this.getBrowserType()
      this.getDeviceType()
      AOS.init()
    },
    methods: {
      signOut (path) {
        // could generalize this if we always knew where we should go
        // we could also use the rails_ujs strategy of inserting a form and submitting it
        this.$store.commit('toggleFullSpinner', true)
        Vue.prototype.$axios.request({
          url: path,
          method: 'DELETE'
        })
          .then(() => {
            window.location = '/users/sign_in'
          })
          .catch(error => {
            console.log('error', error)
          })
      },
      closeNotifications () {
        this.hideNotifications = true
      },
      closeToast () {
        this.$store.commit('closeToast')
      },
      getBrowserType () {
        let browser = Object.keys(this.$browserDetect).filter(key => { return this.$browserDetect[key] })[0]

        if (this.$browserDetect.meta.ua.includes('Safari')) {
          this.$store.commit('setBrowserType', 'isSafari')
        } else {
          this.$store.commit('setBrowserType', browser)
        }
      },
      getDeviceType () {
        let deviceType = ''
        if (this.$browserDetect.meta.ua.includes('iPhone')) { deviceType = 'iOS' }
        this.$store.commit('setDeviceType', deviceType)
      },
      refreshAOS () {
        AOS.refresh()
      },
      scrollIntoView ({ block, id, element }) {
        element = element || document.getElementById(id)
        if (element) {
          element.scrollIntoView({
            block: block,
            behavior: 'smooth'
          })
        }
      }
    }
  })

  initializeVuePrototype(app)
})
