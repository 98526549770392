<template>
  <div
    :class="{
      'st-sentiment-data-table-detail': true,
      'st-sentiment-data-table-detail--panel-open': activePanelSection,
    }"
  >
    <st-toast-notification
      v-if="showToast"
      class="st-toast-notification--fixed"
      kind="info"
      title="info"
      caption="Entities deleted successfully, changes will be reflected when you next load the page."
      @click="showToast = false"
    />
    <h5 class="st-sentiment-data-table-detail__keyword-heading">
      {{ entityMentionCount }}/{{ totalParticipants }} participants have
      mentioned the word "{{ entityName }}"
    </h5>
    <div class="st-sentiment-data-table-detail__dropdown-container">
      <st-dropdown-menu
        class="st-sentiment-data-table-detail__dropdown"
        caption="Sentiment Filter"
        :display-caption="false"
        :initial-active-option="SENTIMENTS[mostPositiveBucketIdx]"
        :full-width="true"
        :options="SENTIMENTS"
        :participants-count-by-bucket-idx="participantsCountByBucketIdx"
        @updateOption="updateSentimentOption"
      />
    </div>
    <h6 class="st-sentiment-data-table-detail__keyword-subheading">
      {{ sentimentMentionCount }}/{{ entityMentionCount }} participants have
      {{ activeSentiment }} views about "{{ entityName }}"
    </h6>
    <div class="st-sentiment-data-table-detail__transcripts">
      <div
        v-for="transcript in activeTranscripts"
        :key="transcript.responseId"
        class="st-sentiment-data-table-detail__one-transcript-container"
      >
        <div class="st-insights__transcript-avatar-row">
          <div class="st-insights__transcript-avatar-with-name">
            <participant-avatar :participant="transcript" />
            <div class="st-sentiment-data-table-detail__one-transcript-name">
              {{ activeName(transcript) }}
            </div>
          </div>
          <div
            class="st-sentiment-data-table-detail__view-full-transcript-group"
            @click="$emit('expand-result', transcript)"
          >
            <div
              class="st-sentiment-data-table-detail__view-full-transcript-text"
            >
              {{ expandResultText }}
            </div>
            <icon-wrapper :icon-name="expandResultIcon" />
          </div>
        </div>
        <div class="st-sentiment-data-table-detail__one-transcript-section">
          <div
            class="st-insights__transcript-section-text st-sentiment-data-table-detail__transcript"
          >
            <template v-for="(text, idx) in splitTranscriptText(transcript)">
              <span
                v-if="idx > 0"
                :key="'keyword' + idx"
                class="st-sentiment-data-table-detail__bold-keyword"
              >
                {{ entityName }}
              </span>
              <span :key="'text' + idx">
                {{ text }}
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="isUserAdmin"
      class="st-sentiment-data-table-detail__trash-icon"
      type="button"
      @click="openDeleteDialog"
    >
      <icon-wrapper icon-name="trash" />
    </button>
    <st-modal
      v-model="isDeleteOpen"
      :cancel-modal="true"
    >
      <template slot="modalTitle">
        Delete Entity
      </template>
      <template slot="modalActions">
        <p class="st-modal__body">
          Are you sure you want to remove all entities named "{{ entityName }}"
          from this question?
        </p>
      </template>
      <template slot="modalButton">
        <st-button
          caption="Delete"
          icon-name="trash"
          @click="deleteEntity"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { isUserAdmin } from '../../../mixins'
import { formatSecondsToStopwatch } from '../../../utilities/data_utilities'
import {
  IconWrapper,
  StButton,
  StDropdownMenu,
  StModal,
  StToastNotification
} from '../../common'
import ParticipantAvatar from './participant-avatar'

const SENTIMENTS = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

export default {
  components: {
    IconWrapper,
    StButton,
    StDropdownMenu,
    StModal,
    StToastNotification,
    'participant-avatar': ParticipantAvatar
  },
  mixins: [isUserAdmin],
  props: {
    entityName: {
      type: String,
      required: true
    },
    expandResultOption: {
      type: String,
      required: true,
      validator: (val) => ['openTourMode', 'openTranscript'].includes(val)
    },
    identifier: {
      type: [Number, String],
      required: false,
      default: null
    },
    transcripts: {
      type: Array, // expected to contain objects with transcript, bucketIdx, name, responseId defined,
      required: true
    },
    totalParticipants: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      activeSentimentIdx: 3,
      isDeleteOpen: false,
      showToast: false,
      SENTIMENTS: SENTIMENTS
    }
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('insights', ['activePanelSection', 'activeQuestionIndex']),
    ...mapGetters('insights', ['activeQuestion']),
    sentimentMentionCount () {
      return this.activeTranscripts.length
    },
    activeSentiment () {
      return SENTIMENTS[this.activeSentimentIdx]
    },
    activeTranscripts () {
      return this.transcripts.filter(
        (transcript) => transcript.bucketIdx === Number(this.activeSentimentIdx)
      )
    },
    entityMentionCount () {
      return this.transcripts.length
    },
    expandResultIcon () {
      let icon = ''
      switch (this.expandResultOption) {
        case 'openTranscript':
          icon = 'transcript'
          break
        case 'openTourMode':
          icon = 'tour-mode'
          break
      }
      return icon
    },
    expandResultText () {
      let text = ''
      switch (this.expandResultOption) {
        case 'openTranscript':
          text = 'View Full Transcript'
          break
        case 'openTourMode':
          text = 'Open Tour Mode'
          break
      }
      return text
    },
    mostPositiveBucketIdx () {
      return this.transcripts.reduce(
        (acc, curr) => Math.max(acc, curr.bucketIdx),
        0
      )
    },
    participantsCountByBucketIdx () {
      const participantsCount = {
        'Very Negative': 0,
        Negative: 0,
        Neutral: 0,
        Positive: 0,
        'Very Positive': 0
      }
      this.transcripts.forEach((transcript) => {
        const { bucketIdx } = transcript
        switch (bucketIdx) {
          case 0:
            participantsCount['Very Negative']++
            break
          case 1:
            participantsCount['Negative']++

            break
          case 2:
            participantsCount['Neutral']++

            break
          case 3:
            participantsCount['Positive']++
            break

          default:
            participantsCount['Very Positive']++
            break
        }
      })
      return { ...participantsCount }
    },
    keywordPattern () {
      let strippedKeyword = this.entityName.trim()
      if (strippedKeyword.slice(-1) === '.') {
        strippedKeyword = strippedKeyword.slice(0, -1)
      }
      let keywordPattern = strippedKeyword.replace('.', '\\.')
      return new RegExp(keywordPattern)
    }
  },
  mounted () {
    this.activeSentimentIdx = this.mostPositiveBucketIdx
  },
  methods: {
    transcriptText (transcript) {
      if (this.identifier) {
        let response = this.$store.getters['insights/findResponse'](
          transcript.responseId
        )
        let matchingSentences = response.sentences.filter((sentence) =>
          sentence.occursOverRoom(this.identifier)
        )
        return matchingSentences.map((sentence) => sentence.text).join(' ').replace(/�/g, '')
      } else {
        // console.log('transcript', transcript.transcript)
        if (!transcript.transcript) return ''
        return transcript.transcript.replace(/�/g, '')
      }
    },
    splitTranscriptText (transcript) {
      // if a period is in tHe keyword it will be missing when we combine
      return this.transcriptText(transcript).split(this.keywordPattern)
    },
    isKeyword (word) {
      let strippedDownWord = word
        .toLowerCase()
        .trim()
        .replace(/[^ \-\w]/g, '') // some keywords have spaces or hyphens
      return strippedDownWord === this.entityName.toLowerCase()
    },
    activeName (activeResponse) {
      let name = 'Anonymous User'
      if (activeResponse.firstName && activeResponse.lastName) {
        name = activeResponse.firstName + ' ' + activeResponse.lastName
      } else if (activeResponse.email) {
        name = activeResponse.email
      }
      return name
    },
    formatSeconds (time) {
      return formatSecondsToStopwatch(time)
    },
    updateSentimentOption (sentiment) {
      this.activeSentimentIdx = SENTIMENTS.indexOf(sentiment)
    },
    openDeleteDialog (el) {
      this.isDeleteOpen = true
    },
    deleteEntity () {
      this.$axios
        .request({
          method: 'delete',
          url: '/question_responses/entities',
          data: {
            question_id: this.activeQuestion.id,
            entity_name: this.entityName
          }
        })
        .then((resp) => {
          console.log('resp', resp)
          // this.$store.dispatch('insights/setActive', this.activeQuestionIndex)
          this.isDeleteOpen = false
          this.showToast = true
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }
}
</script>
