<template>
  <st-modal
    v-model="showModal"
    size-class="st-cropper--large"
    @closeModal="close"
  >
    <template slot="modalTitle">
      {{ modalTitle }}
    </template>
    <template slot="modalActions">
      <div class="st-cropper tw-mt-3">
        <div
          ref="imageWrapper"
          class="st-cropper__image-container"
        />
        <!-- <div class="st-cropper__drag-container">
          <svg
            viewBox="0 0 300 10"
          >
            <line
              x1="5"
              x2="295"
              y1="4"
              y2="4"
              stroke="#BCBAB8"
              stroke-width="3"
              stroke-linecap="square"
            />
          </svg>
          <ds-drag-element
            id="slider"
            class="st-cropper__slider"
            :initial-left="10"
            :initial-radius="8"
            :initial-top="8"
            :max-x="199"
            :max-y="0"
            :min-x="0"
            :min-y="0"
            :slider-x="true"
            @moving="moving($event)"
          />
        </div> -->
      </div>
    </template>
    <template slot="modalButton">
      <st-button
        class="st-cropper__button"
        :caption="buttonValues.caption"
        :icon-name="buttonValues.iconName"
        @click="upload"
      />
    </template>
  </st-modal>
</template>

<script>
import Cropper from 'cropperjs'
import DragElement from './drag-element'
import StButton from './st-button'
import StModal from './st-modal'

export default {
  name: 'ImageCropper',
  components: {
    'ds-drag-element': DragElement,
    'st-button': StButton,
    'st-modal': StModal
  },
  props: {
    /**
       * Ratio of image uploaded, determined by crop box.
       */
    aspectRatio: {
      type: Number,
      required: false,
      default: 1
    },
    /**
      * Information passed to st-button (caption, iconName).
    */
    buttonValues: {
      type: Object,
      required: false,
      default: () => {
        return {
          caption: 'Upload',
          iconName: 'upload'
        }
      }
    },
    /**
      * Main action of cropper modal, displayed at the top.
    */
    modalTitle: {
      type: String,
      required: false,
      default: 'Crop & Upload'
    },
    /**
       * Determines if the cropper is shown on screen.
       */
    showCropper: {
      type: Boolean,
      required: true
    },
    /**
       * Source of image, applied in openCropper.
       */
    src: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      cropper: {},
      minimumRatio: 1,
      showModal: this.showCropper
    }
  },
  computed: {
  },
  mounted () {
    this.$store.commit('toggleFullSpinner', true)
    const image = document.createElement('img')
    image.src = this.src
    image.crossOrigin = 'anonymous'
    image.style.display = 'block'
    image.style.maxHeight = '100%'
    image.style.maxWidth = '100%'
    this.$refs.image = image
    this.$refs.imageWrapper.appendChild(image)
    this.$refs.image.addEventListener('load', () => {
      this.initializeCropper(image)
    })
  },
  beforeDestroy () {
    if (this.cropper.hasOwnProperty('destroy')) { this.cropper.destroy() }
  },
  methods: {
    initializeCropper (el) {
      let cropper = new Cropper(el, {
        aspectRatio: this.aspectRatio,
        center: true,
        checkCrossOrigin: true,
        checkOrientation: false,
        cropBoxResizable: true,
        dragMode: 'move',
        guides: false,
        highlight: true,
        minCropBoxHeight: 128,
        minCropBoxWidth: 128,
        modal: true,
        movable: true,
        ready: () => {
          this.cropper = cropper
          this.$store.commit('toggleFullSpinner', false)
          this.setMinimumRatio()
        },
        scalable: true,
        viewMode: 2,
        zoomOnWheel: false
      })
    },
    close () {
      this.$emit('closeCropper')
    },
    moving (data) {
      this.zoomImage(data.x)
    },
    setMinimumRatio () {
      let counter = 0
      this.$refs.image.addEventListener('zoom', (event) => {
        if (counter === 0) {
          counter++
          this.triggerZoomAtCurrentRatio()
        } else if (counter === 1) {
          counter++
          this.minimumRatio = event.detail.ratio
        }
      })
      this.cropper.zoomTo(0.005) // zoom past minimum ratio
    },
    triggerZoomAtCurrentRatio () {
      this.$nextTick(() => {
        this.cropper.zoom(0)
      })
    },
    upload () {
      this.cropper.getCroppedCanvas().toBlob((blob) => {
        this.showModal = false
        this.$emit('upload', blob)
      })
    },
    zoomImage (position) {
      let zoomFraction = (position - 8) / 183 // ranges from 0 to 1
      this.cropper.zoomTo(this.minimumRatio + 2 * zoomFraction)
    }
  }
}
</script>
