<template>
  <div class="tw-w-full">
    <input
      v-if="type === 'primary'"
      :ref="'input'"
      :class="[
        'tw-rounded-xl',
        'tw-outline-none',
        'tw-p-4',
        'tw-border-none',
        'tw-transition-all',
        'tw-duration-200',
        'hover:tw-ring-black-two',
        'hover:tw-ring-[2px]',
        'focus:tw-bg-white-one',
        'focus:tw-ring-[2px]',
        'tw-ring-[1px]',
        'tw-w-full',
        ...classes,
        {'tw-ring-white-two': error==null },
        {'focus:tw-ring-black-two': error==null},
        { 'tw-ring-error': error!=null }
      ]"
      :autofocus="autofocus"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="value"
      @blur="$emit('blur')"
      @input="$emit('input', $event.target.value)"
      @keydown.enter.prevent="$emit('enter-pressed')"
    >
    <span
      v-if="error"
      class="tw-text-sm tw-text-error tw-mt-1 tw-inline-block tw-px-0.5"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {

  props: {
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    classes: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    refName: {
      type: String,
      required: false,
      default: null
    },

    type: {
      type: String,
      required: false,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }

  },

  data () {
    return {

    }
  },

  mounted () {
  },

  methods: {
    focus () {
      // pass focus html element calls to input element
      this.$refs.input.focus()
      this.$emit('focus')
    }
  }
}
</script>
