<template>
  <st-drawer
    v-model="showDrawer"
    @closeModal="closingModal"
  >
    <template v-slot:modalTitle>
      <div class="flex justify-between  ">
        <span class="font-18p">
          {{ title }}
        </span>
      </div>
    </template>

    <template v-slot:modalActions>
      <div class="tw-flex tw-space-x-1 tw-justify-between  tw-h-full tw-flex-col">
        <div>
          <div class="tw-bg-black tw-text-white tw-px-10 tw-py-12">
            <p class="tw-text-[30px] tw-mt-2 tw-leading-tight tw-font-semibold">
              {{ description }}
            </p>
            <p

              class="tw-text-white tw-mt-3"
            >
              After closing this study you will not be able to reopen it.
            </p>
          </div>
          <div class="tw-px-10">
            <div
              class="tw-py-4 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"
            >
              <p class="tw-text-sm tw-text-territory-dark">
                Study
              </p>
              <p class="tw-text-base tw-font-semibold">
                {{ study.name }}
              </p>
            </div>

            <div
              class="tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid tw-flex tw-space-x-3 tw-justify-between"
            >
              <div>
                <p class="tw-text-sm tw-text-territory-dark">
                  Current plan
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{ currentStudyBasePlan && currentStudyBasePlan.title }} / {{ study.allowResponses }} responses per month
                </p>
                <p class="tw-text-[15px]">
                  ${{ currentStudyBasePlan && currentStudyBasePlan.price }} per month
                </p>
              </div>
            </div>
            <div
              v-if="study.reward && study.rewardType=='gift card'"
              class="tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid tw-flex tw-space-x-3 tw-justify-between"
            >
              <div>
                <p class="tw-text-sm tw-text-territory-dark">
                  Reward funds to be accredited
                </p>
                <p class="tw-text-base tw-font-semibold">
                  ${{ (study.totalRespondents - study.totalResponsesCompleted) * study.incentiveAmount }}
                </p>

                <p class="tw-text-sm tw-text-territory-dark tw-mt-2">
                  This study still has incentives available and these will be credited to your global account so that you can use it later with any other study.
                </p>
              </div>
            </div>

            <div
              v-if="currentAnswerStrategyPlan"
              class="tw-flex tw-space-x-3 tw-items-center tw-py-3"
            >
              <svg
                class="tw-shrink-0"
                width="34"
                height="19"
                viewBox="0 0 34 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_49_44076)">
                  <path
                    d="M24.7385 0.5C21.6102 0.5 18.6877 2.06 17 4.54C15.3123 2.06 12.3898 0.5 9.2615 0.5C4.15739 0.5 0 4.54 0 9.5C0 14.46 4.15739 18.5 9.2615 18.5C12.3898 18.5 15.3123 16.94 17 14.46C18.6877 16.94 21.6102 18.5 24.7385 18.5C29.8426 18.5 34 14.46 34 9.5C34 4.54 29.8838 0.5 24.7385 0.5ZM24.7385 16.9C21.7337 16.9 18.9758 15.14 17.7409 12.46L17 10.78L16.2591 12.46C15.0242 15.14 12.3075 16.9 9.2615 16.9C5.06295 16.9 1.64649 13.58 1.64649 9.5C1.64649 5.42 5.06295 2.1 9.2615 2.1C12.2663 2.1 15.0242 3.82 16.2591 6.54L17 8.22L17.7409 6.54C18.9758 3.86 21.6925 2.1 24.7385 2.1C27.7845 2.1 30.6659 4.02 31.8184 6.7C31.0775 6.1 30.1719 5.7 29.1017 5.7C26.8378 5.7 25.0266 7.5 25.0266 9.66C25.0266 11.82 26.8789 13.62 29.1017 13.62C30.0073 13.62 30.8305 13.34 31.5303 12.86C30.2542 15.26 27.7022 16.9 24.7385 16.9Z"
                    fill="black"
                  />
                  <path
                    d="M12.2663 13.6602C14.5169 13.6602 16.3414 11.8873 16.3414 9.70023C16.3414 7.51319 14.5169 5.74023 12.2663 5.74023C10.0158 5.74023 8.19128 7.51319 8.19128 9.70023C8.19128 11.8873 10.0158 13.6602 12.2663 13.6602Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_49_44076">
                    <rect
                      width="34"
                      height="18"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <span class="tw-text-territory-dark">
                This study has Answers active. In order to deactivate this Add-on you must do it from the home page or study dashboard.
              </span>
            </div>
          </div>
        </div>
        <div class="tw-px-10">
          <button
            class="tw-bg-primary-light tw-text-primary-dark tw-rounded-lg tw-p-3 tw-w-full"
            @click="closeStudyConfirmation"
          >
            Close study
          </button>
        </div>
      </div>
    </template>
  </st-drawer>
</template>

<script>
// import { StDrawer, SecondaryButton, ToggleButton, SentimentChip } from "./";
import StDrawer from './st-drawer.vue'
import SecondaryButton from './secondary-button.vue'
import ToggleButton from './toggle-button.vue'
import SentimentChip from './sentiment-chip.vue'
import StripePaymentForm from './stripe-payment-form.vue'
export default {
  components: {
    StDrawer,
    SecondaryButton,
    ToggleButton,
    StripePaymentForm,
    SentimentChip
  },
  props: {

    study: {
      type: Object,
      require: true,
      default: () => {}
    },

    close: {
      type: Function,
      require: false,
      default: () => {}

    }
  },
  data () {
    return {
      responsesInCurrentMonth: null,
      showDrawer: true,
      currentAnswerStrategyPlan: null,
      currentStudyBasePlan: null
    }
  },
  computed: {
    title () {
      return 'Close study'
    },
    description () {
      if (this.responsesInCurrentMonth !== null && this.responsesInCurrentMonth < this.study.allowResponses) {
        return 'This study still has available quotas for responses. Are you sure you want to close it?'
      }
      return ' Are you sure you want to close this study?'
    }
  },
  mounted () {
    this.getResponsesInCurrentMonth()
    this.currentAnswerStrategyPlan = this.study.plans.find((plan) => {
      return plan.title.toLowerCase().includes('strategies')
    })
    this.currentStudyBasePlan = this.study.plans.find((plan) => {
      return plan.title.toLowerCase().includes('answers') == false
    })
  },

  methods: {
    closingModal () {
      this.$emit('close')
    },
    getResponsesInCurrentMonth () {
      this.$axios
        .request({
          method: 'get',
          url: `/study/${this.study.id}/total_responses_current_month`
        })
        .then(({ data }) => {
          this.responsesInCurrentMonth = data.total_responses

          // console.log(data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    closeStudyConfirmation () {
      this.$axios
        .request({
          method: 'patch',
          url: `study/${this.study.id}/`,
          data: {
            status: 'close'
          }
        })
        .then(({ data }) => {
          this.$store.commit('toggleFullSpinner', true)
          // console.log(data)
          // reload the current page
          window.location.reload()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    showDrawerHandler () {
      this.showDrawer = true
    },
    toggleDrawer () {
      this.showDrawer = !this.showDrawer
    }

  }
}
</script>

<style lang="scss" scoped></style>
