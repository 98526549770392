<template>
  <div class="tw-flex tw-px-8 tw-items-center tw-flex-1 tw-flex-col tw-justify-center">
    <div class="tw-w-2/3 tw-h-32 tw-relative ">
      <div class="-tw-top-0 tw-absolute tw-left-10 2xl:tw-left-20">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7.85262"
            cy="8.29756"
            r="7.68331"
            fill="#E4D8B9"
          />
        </svg>
      </div>
      <div class="tw-w-16 tw-absolute -tw-top-10  2xl:-tw-top-6 tw-right-20 2xl:tw-right-16">
        <img
          src="https://www.shutterstock.com/image-photo/young-happy-man-smiling-camera-260nw-307284695.jpg"
          width="57"
          height="57"
          class="tw-rotate-12 tw-object-cover tw-rounded-full"
        >
      </div>
      <div class="2xl:tw-top-10 tw-top-7 tw-right-12 tw-absolute 2xl:tw-right-14">
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="13.234"
            cy="13.6056"
            r="13.0616"
            fill="#B9D4E4"
          />
        </svg>
      </div>
      <div class="tw-w-16 tw-absolute tw-top-5 tw-left-1 2xl:tw-left-8">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg3pIBJYMlbU230vnXtQmG1M7nLCoIi_sTMw_iEVZMJq1QVROgsYzjjg_z70pq_9JRLy8&usqp=CAU"
          width="57"
          height="57"
          class="-tw-rotate-45 tw-object-cover tw-rounded-full"
        >
      </div>
      <div class="tw-top-[88px] tw-absolute tw-left-16">
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="6.80271"
            cy="6.49723"
            r="6.14664"
            fill="#CEB9E4"
          />
        </svg>
      </div>
      <div class="tw-w-16 tw-absolute tw-top-16 tw-right-20">
        <img
          src="https://www.shutterstock.com/image-photo/young-handsome-man-beard-wearing-600nw-1768126784.jpg"
          alt=""
          width="57"
          height="57"
          class="tw-rotate-12 tw-object-cover tw-rounded-full"
        >
      </div>
    </div>
    <div
      class="tw-mt-3 tw-flex tw-flex-col tw-justify-center tw-items-center"
    >
      <p class="tw-text-center tw-w-4/5 tw-py-3 tw-text-black-three tw-text-[15px]">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AskhumansRandomPeople',

  props: {
    text: {
      type: String,
      default: ''
    }

  },

  data () {
    return {

    }
  },

  mounted () {

  },

  methods: {

  }
}
</script>
