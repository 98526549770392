<template>
  <div class=" tw-h-[80vh] tw-flex tw-justify-center tw-items-center">
    <div class="tw-px-10 tw-w-full md:tw-max-w-xl tw-mx-auto tw-my-auto">
      <div class="">
        <p class="tw-font-semibold tw-text-[40px]">
          Change your password
        </p>
      </div>

      <p class="tw-my-5">
        Passwords must be at least 6 characters.
      </p>
      <form
        id="new_user"
        action="/users/password"
        accept-charset="UTF-8"
        method="post"
        class="new_user"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="token"
        >

        <input
          id="user_reset_password_token"
          type="hidden"
          name="user[reset_password_token]"
          :value="passwordToken"
        >

        <input
          type="hidden"
          name="_method"
          value="put"
        >

        <st-input
          v-model="password"
          style-type="rounded"
          label="New Password"
          name="user[password]"
          theme="light"
          :password="true"
          placeholder="New password"
        />

        <st-input
          v-model="passwordConfirmation"
          style-type="rounded"
          label="Confirm New Password"
          name="user[password_confirmation]"
          theme="light"
          :password="true"
          placeholder="Confirm new password"
          @input="showPasswordErrorToggle"
        />
        <p
          v-if="showPasswordError"
          class="tw-text-error"
        >
          Password and confirmation must match.
        </p>
        <slot />

        <div class="st-reset-password__submit-btn">
          <st-button
            class="tw-px-6 tw-rounded-xl"
            :disabled="password.length<6 || password!=passwordConfirmation"
            type="submit"
            caption="Change Password"
            icon-name="arrow-up"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  StInput,
  StButton
} from '../../common'

export default {
  components: {
    StInput,
    StButton
  },
  props: {
    passwordToken: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      password: '',
      passwordConfirmation: '',
      showPasswordError: false
    }
  },
  computed: {
    token () {
      const tokenTag = document.querySelector('meta[name=csrf-token]')
      return tokenTag === null ? '' : tokenTag.content
    }
  },
  methods: {
    showPasswordErrorToggle () {
      if (this.password == this.passwordConfirmation) {
        this.showPasswordError = false
        return
      }
      this.showPasswordError = true
    }

  }
}
</script>
