var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('label',{staticClass:"font-600"},[_vm._v("Question*")]),_vm._v(" "),_c('st-input',{attrs:{"disabled":_vm.isReadOnly,"name":"question[description]","style-type":"rounded","placeholder":"Your title goes here, be brief and welcoming"},on:{"blur":function($event){return _vm.updateQuestion('description', _vm.activeQuestion.description)}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_vm._v(" "),_c('div',{staticClass:"st-specifications"},[_c('div',{staticClass:"st-specifications__section-title-row"},[_c('p',{staticClass:"st-specifications__section-title"},[_vm._v("\n        How would you like participants to answer?\n      ")]),_vm._v(" "),_c('st-tooltip',{staticClass:"st-specifications__section-icon-tooltip",attrs:{"tip":"Voice recording requires participants to enable their microphone and speak their answer out loud. With typed response they will be able to type out their response.","direction":_vm.isDesktop ? 'bottom' : 'left',"max-width":_vm.isDesktop ? 411 : 225}},[_c('icon-wrapper',{attrs:{"icon-name":"info"}})],1)],1),_vm._v(" "),_c('st-radio-button',{staticClass:"st-radio-button__default-container",attrs:{"disabled":_vm.isReadOnly,"options":[
        { caption: 'Voice Recording', value: 'voice_recording', iconName: 'microphone'},
        { caption: 'Typed Response', value: 'text', iconName: 'keyboard' },
        { caption: 'Let user decide', value: 'let_user_decide', iconName: 'keyboard' }
      ]},model:{value:(_vm.askForExplanation),callback:function ($$v) {_vm.askForExplanation=$$v},expression:"askForExplanation"}})],1),_vm._v(" "),(_vm.showMedia)?_c('st-hyperlink',{staticClass:"st-specifications__upload-link st-specifications__upload-link--spacing mt-5",attrs:{"disabled":_vm.isReadOnly,"icon-class":"st-hyperlink__default-icon-spacing","icon-name":"upload","text":"Upload Media (optional)"},on:{"click":_vm.openUploader}}):_vm._e(),_vm._v(" "),_c('draggable',_vm._b({staticClass:"st-specifications__draggable-options",on:{"change":_vm.handleDrop},model:{value:(_vm.options),callback:function ($$v) {_vm.options=$$v},expression:"options"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.options.length > 0)?_vm._l((_vm.options),function(option){return _c('st-upload-preview',{key:("media-preview-" + (option.id)),staticClass:"st-specifications__draggable-tile",attrs:{"disabled":_vm.isReadOnly,"filename":_vm.fileNameFromUrl(option.mediaUrl),"hide-bar":true,"src":option.mediaUrl},on:{"remove":function($event){return _vm.deleteOption(option.id)}}})}):_vm._e()],2)],1),_vm._v(" "),(_vm.showFollowup)?_c('div',{staticClass:"mt-5 divider"}):_vm._e(),_vm._v(" "),(_vm.showFollowup)?_c('div',{staticClass:"mb-20p mt-5"},[_vm._v("\n    Add follow up question?\n    "),_c('st-tooltip',{staticClass:"st-edit-audience__anonymous-tooltip",attrs:{"tip":"Follow up question will add another question after the main question. For example, \"Please tell us more\""}},[_c('icon-wrapper',{attrs:{"icon-name":"info"}})],1),_vm._v(" "),_c('st-radio-button',{staticClass:"st-radio-button__default-container pt-10p",attrs:{"on-change-handler":_vm.changeFollowup,"options":[
        { caption: 'Yes', value: true },
        { caption: 'No', value: false }
      ]},model:{value:(_vm.followupQuestion),callback:function ($$v) {_vm.followupQuestion=$$v},expression:"followupQuestion"}}),_vm._v(" "),(_vm.getSelectedFollowUpQuestion)?_c('div',{staticClass:"flex space-x-10 mt-20p mb-20p"},[_c('st-dropdown-menu',{attrs:{"display-caption":true,"initial-active-option":_vm.getSelectedFilterOption,"options":_vm.filterSelectOptions},on:{"updateOption":_vm.filterOptionHandler}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.isUploadOpen)?_c('st-media-uploader',{attrs:{"accept":".jpg, .jpeg, .png, .mp4","additional-props":_vm.additionalProps,"folders":_vm.folders,"modal-title":"Upload Media or Select From Library","multi-upload":true,"prop-name":"question_option[media]","skip-cropper":true,"url":"/question_options"},on:{"closeMediaUploader":function($event){_vm.isUploadOpen = false},"uploadComplete":_vm.uploadComplete}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }