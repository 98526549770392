<template>
  <div
    class="tw-h-full "
    @click="toggleShowAdditionalMenus($event, null)"
  >
    <st-toast-notification
      v-if="showToast"
      class="st-toast-notification"
      :kind="toastKind"
      :title="toastTitle"
      :caption="toastCaption"
      @click="showToast = false"
    />
    <div class="tw-bg-black tw-text-white tw-px-10 tw-py-12">
      <p
        class="tw-text-[30px] tw-text-center tw-mt-2 tw-leading-tight tw-font-semibold"
      >
        Reports
      </p>
    </div>
    <div
      class="border-bottom-gray border-top-gray tw-flex tw-justify-between tw-py-1 tw-items-center bg-insights-bar md:tw-bg-none tw-w-full"
    >
      <div class="bx--grid tw-w-full">
        <div class="bx--row tw-w-full">
          <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
            <div
              class="tw-w-full tw-px-6 tw-text-[15px] tw-flex tw-space-x-2 tw-items-center tw-py-3"
            >
              <secondary-button
                :selected="secondaryFilter === 'all'"
                @click="changeSecondaryFilter('all')"
              >
                All
              </secondary-button>
              <secondary-button
                :selected="secondaryFilter === 'your'"
                @click="changeSecondaryFilter('your')"
              >
                Your reports
              </secondary-button>
              <secondary-button
                :selected="secondaryFilter === 'shared'"
                @click="changeSecondaryFilter('shared')"
              >
                Shared with you
              </secondary-button>
            </div>
            <div class="tw-min-w-fit">
              <ah-button @click="redirectToCreatePage">
                Create report
              </ah-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="strategies.length"
      class="bx--grid tw-text-[15px]  tw-mt-3 "
    >
      <div class="tw-w-full tw-mb-8 tw-overflow-hidden">
        <div class="tw-w-full tw-overflow-x-auto  ">
          <div class="tw-w-full">
            <div>
              <div
                class="tw-tracking-wide tw-grid tw-grid-cols-12 tw-justify-between tw-text-left tw-text-gray-900 tw-border-solid tw-border-b tw-border-gray-100"
              >
                <div class="tw-px-4 tw-py-3 tw-col-span-3">
                  Title
                </div>
                <div class="tw-px-2 tw-py-3 tw-col-span-2">
                  Report Framework
                </div>
                <div class="tw-px-2 tw-py-3 tw-col-span-2">
                  Created on
                </div>
                <div class="tw-px-2 tw-py-3 tw-col-span-2">
                  # of sources
                </div>
                <div class="tw-px-2 tw-py-3 tw-col-span-1">
                  Versions
                </div>
                <div class="tw-px-2 tw-py-3 tw-col-span-2">
                  Shared with
                </div>

                <div class="tw-px-2 tw-py-3" />
              </div>
            </div>

            <div
              v-for="strategy in strategies"
              :key="strategy.name + strategy.id"
              class="tw-cursor-pointer tw-h-fit"
              @click="redirectToDetailsPage(strategy.id)"
            >
              <div
                class="tw-text-gray-700 tw-bg-white tw-h-fit hover:tw-bg-light-four  tw-relative tw-grid tw-grid-cols-12 tw-gap-x-1 mt-0 tw-justify-between tw-items-center"
              >
                <div class="tw-px-4 tw-py-3 tw-border tw-col-span-3">
                  <p class="tw-text-[18px] tw-to-black-one tw-flex tw-gap-2 tw-items-center">
                    <span>
                      {{ strategy.name ? strategy.name : "[Strategy Name]" }}
                    </span>
                    <span
                      v-if="
                        strategy.versions.some(
                          (version) => version.status === 'processing'
                        )
                      "
                      class="tw-py-1 tw-px-1 tw-bg-[#6E70A4] tw-rounded-md tw-text-white tw-text-[15px]"
                    >
                      Processing
                    </span>
                  </p>
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-[15px] tw-text-black-two tw-col-span-2">
                  <span> {{ getStrategyName(strategy.framework) }}  </span>
                </div>
                <div
                  class="tw-px-2 tw-py-3 tw-text-[15px] tw-text-black-two tw-col-span-2"
                >
                  <span>
                    {{ getReadableDate(strategy.createdAt) }}
                  </span>
                </div>
                <div
                  class="tw-px-2 tw-py-2 tw-flex tw-flex-col tw-justify-center tw-text-[15px] tw-text-black-two tw-border tw-col-span-2"
                >
                  <span
                    class=" tw-flex tw-py-3 tw-space-x-2"
                    @click="toggleExpandStudies($event, strategy.id)"
                  >
                    <span>
                      {{ strategy.surveys.length }} studies
                    </span>
                    <svg
                      v-if="toggleStudyExpand != strategy.id"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_930_1854)">
                        <path
                          d="M8.15409 11.3197C7.97986 11.3197 7.80863 11.256 7.68247 11.1402L3.84034 7.42856C3.57599 7.17668 3.57599 6.76556 3.83734 6.51078C4.09869 6.25601 4.52526 6.25601 4.78961 6.50789L8.1571 9.76498L11.5216 6.50789C11.7949 6.26759 12.2185 6.28785 12.4678 6.55131C12.6992 6.79741 12.6992 7.17378 12.4678 7.41987L8.62572 11.1402C8.49955 11.256 8.32833 11.3226 8.15409 11.3197Z"
                          fill="#474747"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_930_1854">
                          <rect
                            width="5"
                            height="9"
                            fill="white"
                            transform="translate(12.6421 6.31982) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg
                      v-else
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_930_2099)">
                        <path
                          d="M8.13012 6.31995C8.30435 6.31995 8.47558 6.38364 8.60174 6.49945L12.4439 10.2111C12.7082 10.463 12.7082 10.8741 12.4469 11.1289C12.1855 11.3836 11.759 11.3836 11.4946 11.1318L8.12711 7.87467L4.76263 11.1318C4.48926 11.3721 4.0657 11.3518 3.81636 11.0883C3.58506 10.8422 3.58506 10.4659 3.81636 10.2198L7.65849 6.49945C7.78466 6.38364 7.95588 6.31705 8.13012 6.31995Z"
                          fill="#474747"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_930_2099">
                          <rect
                            width="5"
                            height="9"
                            fill="white"
                            transform="translate(3.64209 11.3198) rotate(-90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                  </span>
                </div>
                <div
                  class="tw-px-2 tw-py-2 tw-flex  tw-text-[15px] tw-text-black-two tw-border tw-col-span-1"
                >
                  <span
                    class="tw-flex tw-items-center tw-py-3 tw-space-x-2"
                    @click="toggleExpandVersion($event, strategy.id)"
                  >
                    <span>
                      {{ strategy.versions.length - 1 }} versions
                    </span>
                    <template v-if="strategy.versions.length>1">
                      <svg
                        v-if="toggleExpandVersionId != strategy.id"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_930_1854)">
                          <path
                            d="M8.15409 11.3197C7.97986 11.3197 7.80863 11.256 7.68247 11.1402L3.84034 7.42856C3.57599 7.17668 3.57599 6.76556 3.83734 6.51078C4.09869 6.25601 4.52526 6.25601 4.78961 6.50789L8.1571 9.76498L11.5216 6.50789C11.7949 6.26759 12.2185 6.28785 12.4678 6.55131C12.6992 6.79741 12.6992 7.17378 12.4678 7.41987L8.62572 11.1402C8.49955 11.256 8.32833 11.3226 8.15409 11.3197Z"
                            fill="#474747"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_930_1854">
                            <rect
                              width="5"
                              height="9"
                              fill="white"
                              transform="translate(12.6421 6.31982) rotate(90)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <svg
                        v-else
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_930_2099)">
                          <path
                            d="M8.13012 6.31995C8.30435 6.31995 8.47558 6.38364 8.60174 6.49945L12.4439 10.2111C12.7082 10.463 12.7082 10.8741 12.4469 11.1289C12.1855 11.3836 11.759 11.3836 11.4946 11.1318L8.12711 7.87467L4.76263 11.1318C4.48926 11.3721 4.0657 11.3518 3.81636 11.0883C3.58506 10.8422 3.58506 10.4659 3.81636 10.2198L7.65849 6.49945C7.78466 6.38364 7.95588 6.31705 8.13012 6.31995Z"
                            fill="#474747"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_930_2099">
                            <rect
                              width="5"
                              height="9"
                              fill="white"
                              transform="translate(3.64209 11.3198) rotate(-90)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </template>
                  </span>
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-[15px] tw-text-black-two tw-col-span-1">
                  <shared-with
                    :users="strategy.versions.map((version) => {
                      return version.sharedUsers
                    })"
                    :label="false"
                  />
                </div>
                <div
                  class="tw-px-2 tw-py-3 tw-text-[15px] tw-text-black-two tw-col-span-1  tw-text-end tw-cursor-pointer hover:tw-underline"
                >
                  <div
                    class="font-600  tw-p-1 rounded-4p border-2p cursor-pointer  tw-rounded-full "
                    @click="toggleShowAdditionalMenus($event, strategy.id)"
                  >
                    <svg
                      width="4"
                      height="14"
                      viewBox="0 0 4 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.25015 11.7921C0.25015 12.6145 0.91688 13.2812 1.73933 13.2812C2.56179 13.2812 3.22852 12.6145 3.22852 11.7921C3.22852 10.9696 2.56179 10.3029 1.73933 10.3029C0.91688 10.3029 0.25015 10.9696 0.25015 11.7921ZM0.25015 6.82814C0.25015 7.65059 0.91688 8.31732 1.73933 8.31732C2.56179 8.31732 3.22852 7.65059 3.22852 6.82814C3.22852 6.00569 2.56179 5.33896 1.73933 5.33896C0.91688 5.33896 0.25015 6.00569 0.25015 6.82814ZM0.25015 1.86417C0.25015 2.68663 0.91688 3.35336 1.73933 3.35336C2.56179 3.35336 3.22852 2.68663 3.22852 1.86417C3.22852 1.04172 2.56179 0.374991 1.73933 0.374991C0.91688 0.374991 0.25015 1.04172 0.25015 1.86417Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div
                    v-if="showAdditionalMenusId == strategy.id"
                    class="flex flex-col tw-right-4  justify-center cursor-pointer tw-border-[0.5px] tw-border-solid tw-border-white-two tw-rounded-lg tw-overflow-hidden absolute bg-white tw-shadow-lg !tw-w-20 export-popup !tw-text-left"
                  >
                    <span
                      class=""
                      @click="
                        showRenameStrategyModalHandler($event, strategy.id)
                      "
                    >
                      Rename
                    </span>
                    <span
                      class=""
                      @click="showDeleteModalHandler($event, strategy.id)"
                    >
                      Delete
                    </span>
                  </div>
                </div>
              </div>
              <template v-if="toggleStudyExpand == strategy.id">
                <div class="tw-col-span-12 tw-grid tw-grid-cols-12">
                  <div class="tw-col-span-7" />
                  <div class="tw-col-span-5 tw-ml-5  tw-flex tw-items-center  tw-border-b tw-border-solid tw-border-b-gray-100">
                    <span
                      class="tw-w-0.5  tw-bg-gray-300 tw-block"
                      :class="{
                        'tw-h-10': true,

                      }"
                    />
                    <span class="tw-block tw-px-8">

                      Name
                    </span>
                  </div>
                  <div class="tw-col-span-7  tw-flex" />
                  <div class="tw-col-span-5 tw-px-5 ">
                    <div
                      v-for="(study, index) in selectedReportStudies"
                      :key="study.id"
                      class=" tw-flex tw-items-center hover:tw-bg-light-four"
                    >
                      <span
                        class="tw-w-0.5  tw-bg-gray-300 tw-block"
                        :class="{
                          'tw-h-10': index !== selectedReportStudies.length - 1,
                          'tw-h-5 tw-mb-[18px]': index === selectedReportStudies.length - 1
                        }"
                      />
                      <span class="tw-w-5 tw-h-0.5 tw-bg-gray-300 tw-block" />
                      <span class="tw-py-2 tw-px-3 tw-block">
                        {{ study.name }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="w-text-gray-700 tw-relative tw-col-span-12 tw-mt-4 tw-pb-4 tw-grid tw-grid-cols-12 tw-justify-between tw-items-center "
                >
                  <div class="tw-col-span-7" />
                  <inpage-pagination
                    v-if="selectedStudyTotalPages >1"
                    :total-pages="selectedStudyTotalPages"
                    :selected-page="selectedAccountStudyCurrentPage"
                    container-class="tw-col-span-5"
                    @changePage="changePageHandler"
                  />
                </div>
              </template>
              <template v-if="toggleExpandVersionId == strategy.id && selectedReportVersions.length>1">
                <div class="tw-col-span-12 tw-grid tw-grid-cols-12">
                  <div class="tw-col-span-8" />
                  <div class="tw-col-span-4 tw-ml-5  tw-flex tw-items-center  tw-border-b tw-border-solid tw-border-b-gray-100">
                    <span
                      class="tw-w-0.5  tw-bg-gray-300 tw-block"
                      :class="{
                        'tw-h-10': true,

                      }"
                    />
                    <span class="tw-block tw-px-8">

                      Name
                    </span>
                  </div>
                  <div class="tw-col-span-8  tw-flex" />
                  <div class="tw-col-span-4 tw-px-5 ">
                    <div
                      v-for="(version, index) in selectedReportVersions.filter((v)=>{
                        return v.name !=='initial version'
                      })"
                      :key="version.id"
                      class=" tw-flex tw-items-center hover:tw-bg-light-four tw-group "
                      @click="redirectToSpecificVersion($event, strategy.id, version.id)"
                    >
                      <span
                        class="tw-w-0.5  tw-bg-gray-300 tw-block"
                        :class="{
                          'tw-h-10': checkInitialVersion(index, selectedReportVersions.length),
                          'tw-h-5 tw-mb-[18px]': !checkInitialVersion(index, selectedReportVersions.length)
                        }"
                      />
                      <span class="tw-w-5 tw-h-0.5 tw-bg-gray-300 tw-block" />
                      <span class="tw-py-2 tw-px-3 tw-flex tw-justify-between tw-space-x-2 tw-w-full">
                        <span class="tw-flex tw-space-x-2 tw-items-center">
                          <span class="">
                            {{ version.name }}
                          </span>
                          <!-- edit icon -->
                          <span
                            class="tw-hidden group-hover:tw-inline-block"
                            @click="showRenameVersionHandler($event, strategy.id, version.id)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1577_2960)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4.73571 13.7578H13V12.1328H6.36071L4.73571 13.7578ZM8.53125 2.78906L0 11.3203V13.7578H2.4375L10.9688 5.22656L8.53125 2.78906ZM13 3.19531C13 1.84935 11.9089 0.757812 10.5625 0.757812L9.25391 2.06687L11.6914 4.50437L13 3.19531Z"
                                  fill="#202020"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1577_2960">
                                  <rect
                                    width="13"
                                    height="13"
                                    fill="white"
                                    transform="translate(0 0.757812)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>

                        </span>

                        <svg
                          v-if="version.isFavourite"
                          class="tw-flex-shrink-0"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          @click="addOrRemoveFavourite($event, strategy.id, version.id)"
                        >
                          <path
                            d="M8.64208 12.3333L11.4087 14.0067C11.9154 14.3133 12.5354 13.86 12.4021 13.2867L11.6687 10.14L14.1154 8.01999C14.5621 7.63333 14.3221 6.89999 13.7354 6.85333L10.5154 6.57999L9.25541 3.60666C9.02874 3.06666 8.25541 3.06666 8.02874 3.60666L6.76874 6.57333L3.54874 6.84666C2.96208 6.89333 2.72208 7.62666 3.16874 8.01333L5.61541 10.1333L4.88208 13.28C4.74874 13.8533 5.36874 14.3067 5.87541 14L8.64208 12.3333Z"
                            fill="#DCAD05"
                          />
                        </svg>
                        <svg
                          v-else
                          class="tw-flex-shrink-0"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          @click="addOrRemoveFavourite($event, strategy.id, version.id)"
                        >
                          <path
                            d="M13.7421 6.84649L10.5154 6.56649L9.25541 3.59982C9.02874 3.05982 8.25541 3.05982 8.02874 3.59982L6.76874 6.57316L3.54874 6.84649C2.96208 6.89316 2.72208 7.62649 3.16874 8.01316L5.61541 10.1332L4.88208 13.2798C4.74874 13.8532 5.36874 14.3065 5.87541 13.9998L8.64208 12.3332L11.4087 14.0065C11.9154 14.3132 12.5354 13.8598 12.4021 13.2865L11.6687 10.1332L14.1154 8.01316C14.5621 7.62649 14.3287 6.89316 13.7421 6.84649ZM8.64208 11.0865L6.13541 12.5998L6.80208 9.74649L4.58874 7.82649L7.50874 7.57316L8.64208 4.88649L9.78208 7.57982L12.7021 7.83316L10.4887 9.75316L11.1554 12.6065L8.64208 11.0865Z"
                            fill="#C9C9C9"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="w-text-gray-700 tw-relative tw-col-span-12 tw-mt-4 tw-pb-4 tw-grid tw-grid-cols-12 tw-justify-between tw-items-center "
                >
                  <div class="tw-col-span-8" />
                  <inpage-pagination
                    v-if="selectedVersionTotalPages >1"
                    :total-pages="selectedVersionTotalPages"
                    :selected-page="selectedVersionCurrentPage"
                    container-class="tw-col-span-4"
                    @changePage="changeVersionPageHandler"
                  />
                </div>
                <!-- <div class="tw-col-span-12 tw-grid tw-grid-cols-12">
                  <div class="tw-col-span-8" />
                  <div class="tw-col-span-4 tw-ml-5  tw-flex tw-items-center  tw-border-b tw-border-solid tw-border-b-gray-100">
                    <span
                      class="tw-w-0.5  tw-bg-gray-300 tw-block"
                      :class="{
                        'tw-h-10': true,

                      }"
                    />
                    <span class="tw-block tw-px-8">

                      Name
                    </span>
                  </div>
                  <div class="tw-col-span-8  tw-flex" />
                  <div class="tw-col-span-4 tw-px-5 ">
                    <div
                      v-for="(version, index) in selectedReportVersions.filter((v)=>{
                        return v.name !=='initial version'
                      })"
                      :key="version.id"
                      class="tw-h-1/2 tw-flex tw-items-center "
                      @click="redirectToSpecificVersion($event, strategy.id, version.id)"
                    >
                      <span
                        class="tw-w-0.5  tw-bg-gray-300 tw-block"
                        :class="{
                          'tw-h-full': index !== selectedReportVersions.length - 2,
                          'tw-h-1/2 tw-mb-[22px]': index === selectedReportVersions.length - 2
                        }"
                      />
                      <span class="tw-w-5 tw-h-0.5 tw-bg-gray-300 tw-block" />
                      <span class="tw-flex tw-text-sm tw-justify-between tw-items-center tw-w-full">

                        <span class="tw-py-2 tw-px-3 tw-block">
                          {{ version.name }}
                        </span>
                        <svg
                          v-if="version.isFavourite"
                          class="tw-flex-shrink-0"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.64208 12.3333L11.4087 14.0067C11.9154 14.3133 12.5354 13.86 12.4021 13.2867L11.6687 10.14L14.1154 8.01999C14.5621 7.63333 14.3221 6.89999 13.7354 6.85333L10.5154 6.57999L9.25541 3.60666C9.02874 3.06666 8.25541 3.06666 8.02874 3.60666L6.76874 6.57333L3.54874 6.84666C2.96208 6.89333 2.72208 7.62666 3.16874 8.01333L5.61541 10.1333L4.88208 13.28C4.74874 13.8533 5.36874 14.3067 5.87541 14L8.64208 12.3333Z"
                            fill="#DCAD05"
                          />
                        </svg>
                        <svg
                          v-else
                          class="tw-flex-shrink-0"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.7421 6.84649L10.5154 6.56649L9.25541 3.59982C9.02874 3.05982 8.25541 3.05982 8.02874 3.59982L6.76874 6.57316L3.54874 6.84649C2.96208 6.89316 2.72208 7.62649 3.16874 8.01316L5.61541 10.1332L4.88208 13.2798C4.74874 13.8532 5.36874 14.3065 5.87541 13.9998L8.64208 12.3332L11.4087 14.0065C11.9154 14.3132 12.5354 13.8598 12.4021 13.2865L11.6687 10.1332L14.1154 8.01316C14.5621 7.62649 14.3287 6.89316 13.7421 6.84649ZM8.64208 11.0865L6.13541 12.5998L6.80208 9.74649L4.58874 7.82649L7.50874 7.57316L8.64208 4.88649L9.78208 7.57982L12.7021 7.83316L10.4887 9.75316L11.1554 12.6065L8.64208 11.0865Z"
                            fill="#C9C9C9"
                          />
                        </svg>

                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="w-text-gray-700 tw-relative tw-col-span-12 tw-mt-4 tw-pb-4 tw-grid tw-grid-cols-12 tw-justify-between tw-items-center "
                >
                  <div class="tw-col-span-8" />
                  <inpage-pagination
                    :total-pages="selectedVersionTotalPages"
                    :selected-page="selectedVersionCurrentPage"
                    container-class="tw-col-span-4"
                    @changePage="changeVersionPageHandler"
                  />
                </div> -->
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="bx--grid tw-my-5 tw-min-h-[500px] tw-flex tw-flex-col tw-py-10 tw-justify-center tw-items-center tw-gap-3"
    >
      <div
        v-if="secondaryFilter=='your'"
        class="tw-py-10 tw-justify-center tw-flex tw-flex-col tw-items-center tw-gap-3"
      >
        <p class="tw-text-lg tw-font-semibold">
          You haven't created any report yet.
        </p>
        <ah-button @click="redirectToCreatePage">
          Create report
        </ah-button>
      </div>
      <div
        v-else-if="secondaryFilter=='all'"
        class="tw-py-10
        tw-justify-center
        tw-flex
        tw-flex-col
        tw-items-center
        tw-gap-3"
      >
        <p class="tw-text-black-two tw-max-w-sm tw-text-center">
          You don’t have any reports yet. Click ‘Create report’ button to create your first report.
        </p>
        <!-- <ah-button @click="redirectToCreatePage">
          Create strategy
        </ah-button> -->
      </div>
      <div v-else-if="secondaryFilter=='shared'">
        <p class="tw-text-black-two tw-max-w-sm tw-text-center">
          No report shared with you yet.
        </p>
      </div>
    </div>

    <st-modal
      v-if="showRenameStrategyModal"
      v-model="showRenameStrategyModal"
    >
      <h3 class="font-600">
        Rename report
      </h3>
      <div class="tw-py-2">
        <st-input
          v-model="strategyNameToRename"
          style-type="rounded"
          placeholder="Report name"
        />
      </div>
      <div class="flex justify-end mt-2">
        <st-button @click="renameStudyConfirmationHandler">
          Rename report
        </st-button>
      </div>
    </st-modal>
    <st-modal
      v-if="showRenameVersionModal"
      v-model="showRenameVersionModal"
    >
      <h3 class="font-600">
        Edit version
      </h3>
      <div class="tw-py-2">
        <span class="tw-text-[15px]">
          Version name
        </span>
        <st-input
          v-model="renameVersionText"
          style-type="rounded"
          placeholder="version name"
        />
      </div>
      <div class="flex justify-end mt-2">
        <st-button @click="renameVersionConfirmationHandler">
          Rename version
        </st-button>
      </div>
    </st-modal>
    <st-modal
      v-if="deleteStrategyModal"
      v-model="deleteStrategyModal"
    >
      <h3 class="font-600">
        Delete report
      </h3>
      <div class="tw-py-2">
        <p>
          After deleting this report, you will not be able to recover it. Are
          you sure you want to proceed?
        </p>
      </div>
      <div class="flex justify-end mt-2">
        <button
          class="tw-px-3 tw-py-3 tw-rounded-lg tw-bg-error tw-text-white "
          @click="deleteStrategyConfirmationHandler"
        >
          Delete report
        </button>
      </div>
    </st-modal>
  </div>
</template>

<script>
import {
  AhButton,
  SecondaryButton,
  StButton,
  StInput,
  StModal,
  StToastNotification
} from '../../common'
import InpagePagination from './inpage-pagination.vue'
import SharedWith from './shared-with.vue'
export default {
  components: {
    AhButton,
    InpagePagination,
    SecondaryButton,
    StToastNotification,
    StInput,
    StButton,
    StModal,
    SharedWith
  },
  props: {
    ownedStrategies: {
      type: Array,
      required: true
    },
    sharedStrategies: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      toastCaption: '',
      toastKind: '',
      toastTitle: '',
      showToast: false,
      showAdditionalMenusId: null,
      showRenameVersionModal: false,
      renameVersionText: '',
      showRenameStrategyModal: false,
      strategyNameToRename: '',
      deleteStrategyModal: false,
      secondaryFilter: 'all',
      selectedStrategyToEditId: null,
      selectedVersionToEditId: null,
      toggleStudyExpand: null,
      toggleExpandVersionId: null,
      selectedReportStudies: [],
      strategies: [],
      selectedStudyTotalPages: 1,
      selectedVersionTotalPages: 1,
      selectedVersionCurrentPage: 1,
      selectedReportVersions: [],
      selectedAccountStudyCurrentPage: 1
    }
  },
  mounted () {
    this.strategies = this.ownedStrategies.concat(this.sharedStrategies)
    // console.log(this.strategies)
    // console.log('users', this.strategies[1].versions[0].sharedUsers)

    setTimeout(() => {
      const processingVersionIds = this.ownedStrategies
        .map((strategy) => {
          return strategy.versions.map((version) => {
            if (version.status === 'processing') {
              return version.id
            }
          })
        })
        .flat()
        .filter((versionId) => {
          return versionId
        })

      // console.log('processingVersionIds', processingVersionIds)

      if (processingVersionIds.length) {
        this.checkVersionStatus(processingVersionIds)
      }
    }, 1000)
  },

  methods: {
    addOrRemoveFavourite (e, strategyId, versionId) {
      e.stopPropagation()
      this.$store.commit('toggleFullSpinner', true)
      const strategy = this.strategies.find((strategy) => {
        return strategy.id === strategyId
      })
      const version = strategy.versions.find((version) => {
        return version.id === versionId
      })
      this.$axios.request({
        method: 'patch',
        url: `/versions/${version.id}`,
        data: {
          is_favourite: !version.isFavourite
        }
      }).then((response) => {
        this.$store.commit('toggleFullSpinner', false)
        this.showToast = true
        this.toastKind = 'success'
        this.toastTitle = 'Success'
        this.toastCaption = 'Operation successful'
        this.strategies = this.strategies.map((strategy) => {
          return {
            ...strategy,
            versions: strategy.versions.map((v) => {
              if (v.id === version.id) {
                return {
                  ...v,
                  isFavourite: !v.isFavourite
                }
              } else {
                return v
              }
            })
          }
        })
      }).catch((error) => {
        this.$store.commit('toggleFullSpinner', false)
        this.showToast = true
        this.toastKind = 'error'
        this.toastTitle = 'Error'
        this.toastCaption = error
        console.log(error)
      })
      this.selectedReportVersions = this.selectedReportVersions.map((v) => {
        if (v.id === versionId) {
          return {
            ...v,
            isFavourite: !v.isFavourite
          }
        } else {
          return v
        }
      })
      // this.toggleExpandVersionId = null
    },
    renameVersionConfirmationHandler () {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        method: 'patch',
        url: `/versions/${this.selectedVersionToEditId}`,
        data: {
          name: this.renameVersionText
        }
      }).then((response) => {
        this.$store.commit('toggleFullSpinner', false)
        this.showToast = true
        this.toastKind = 'success'
        this.toastTitle = 'Success'
        this.toastCaption = 'Version renamed successfully'
        this.strategies = this.strategies.map((strategy) => {
          return {
            ...strategy,
            versions: strategy.versions.map((version) => {
              if (version.id === this.selectedVersionToEditId) {
                return {
                  ...version,
                  name: this.renameVersionText
                }
              } else {
                return version
              }
            })
          }
        })
        // console.log(this.strategies)
        // console.log(response)
        // this.selectedStrategyVersion.isFavourite = response.data.is_favourite
      }).catch((error) => {
        this.$store.commit('toggleFullSpinner', false)
        this.showToast = true
        this.toastKind = 'error'
        this.toastTitle = 'error'
        this.toastCaption = error
        console.log(error)
      })
      this.showRenameVersionModal = false
      this.selectedReportVersions = this.selectedReportVersions.map((v) => {
        if (v.id === this.selectedVersionToEditId) {
          return {
            ...v,
            name: this.renameVersionText
          }
        } else {
          return v
        }
      })
    },
    showRenameVersionHandler (e, strategyId, versionId) {
      e.stopPropagation()
      this.showRenameVersionModal = true
      const strategy = this.strategies.find((strategy) => {
        return strategy.id === strategyId
      })
      const version = strategy.versions.find((version) => {
        return version.id === versionId
      })
      this.renameVersionText = version.name
      this.selectedVersionToEditId = versionId
    },
    getStrategyName (name) {
      if (name == 'SWOT') {
        return 'SWOT Analysis'
      } else if (name == 'primaryThemes') {
        return 'Primary Themes'
      } else if (name == 'retrospective') {
        return 'Retrospective'
      } else if (name == 'ideaGeneration') {
        return 'Idea Generation'
      } else if (name == 'thematicAnalysis') {
        return 'Thematic Analysis'
      }
        else if(name === 'summaryWithThemes'){
          return 'Summary with Themes'
        }
        else if(name === 'summaryWithQuestions'){
          return 'Summary with Questions'
        }
        else if(name==='summaryWithQuotes'){
          return 'Summary with Quotes'
        }
    },
    toggleExpandStudies (e, id) {
      this.toggleExpandVersionId = null
      e.stopPropagation()
      if (this.toggleStudyExpand == id) {
        this.toggleStudyExpand = null
      } else {
        this.toggleStudyExpand = id
        this.selectedReportStudies = this.strategies.find((strategy) => {
          return strategy.id === id
        }).surveys
        if (this.selectedReportStudies.length > 5) {
          this.selectedStudyTotalPages = Math.ceil(
            this.selectedReportStudies.length / 5
          )
          this.selectedReportStudies = this.selectedReportStudies.slice(0, 5)
          // console.log(this.selectedReportStudies)
        } else {
          this.selectedStudyTotalPages = 1
          this.selectedReportStudies = this.selectedReportStudies
        }
      }
    },
    toggleExpandVersion (e, id) {
      this.toggleStudyExpand = null
      // this.toggleExpandStudies = null
      e.stopPropagation()
      if (this.toggleExpandVersionId == id) {
        this.toggleExpandVersionId = null
      } else {
        this.toggleExpandVersionId = id
        this.selectedReportVersions = this.strategies.find((strategy) => {
          return strategy.id === id
        }).versions
        if (this.selectedReportVersions.length > 5) {
          this.selectedVersionTotalPages = Math.ceil(
            this.selectedReportVersions.length / 5
          )
          this.selectedReportVersions = this.selectedReportVersions.slice(0, 5)
          // console.log(this.selectedReportStudies)
        } else {
          this.selectedVersionTotalPages = 1
          this.selectedReportVersions = this.selectedReportVersions
        }
      }
    },
    changePageHandler (page) {
      this.selectedAccountStudyCurrentPage = page
      const studies = this.strategies.find((strategy) => {
        return strategy.id === this.toggleStudyExpand
      }).surveys
      const start = (page - 1) * 5
      const end = start + 5
      this.selectedReportStudies = studies.slice(start, end)
    },
    changeVersionPageHandler (page) {
      this.selectedVersionCurrentPage = page
      const versions = this.strategies.find((strategy) => {
        return strategy.id === this.toggleExpandVersionId
      }).versions
      const start = (page - 1) * 5
      const end = start + 5
      this.selectedReportVersions = versions.slice(start, end)
    },
    checkInitialVersion (index, length) {
      if (this.selectedReportVersions.some((v) => v.name === 'initial version')) {
        return index !== length - 2
      }
      return index !== length - 1
    },
    async checkVersionStatus (processingVersionIds) {
      // if any of the them are still processing, then we will recursively call this function again exclude the version ids that are done processing and done processing update the strategy

      const versionStatuses = await Promise.all(
        processingVersionIds.map((versionId) => {
          return this.fetchVersion(versionId)
        })
      )

      const completedVersionIds = versionStatuses
        .filter((versionStatus) => {
          return versionStatus.status === 'completed'
        })
        .map((versionStatus) => {
          return versionStatus.id
        })

      if (completedVersionIds.length > 0) {
        const completedVersions = this.ownedStrategies.map((strategy) => {
          return strategy.versions.filter((version) => {
            return completedVersionIds.includes(version.id)
          })
        })

        const completedVersionIdsFlat = completedVersions
          .flat()
          .map((version) => {
            return version.id
          })

        const doneProcessingStrategies = this.ownedStrategies.map(
          (strategy) => {
            return {
              ...strategy,
              versions: strategy.versions.map((version) => {
                if (completedVersionIdsFlat.includes(version.id)) {
                  return {
                    ...version,
                    status: 'completed'
                  }
                } else {
                  return version
                }
              })
            }
          }
        )

        this.strategies = this.sharedStrategies.concat(
          doneProcessingStrategies
        )
      }

      const notCompletedVersionIds = versionStatuses
        .filter((versionStatus) => {
          return versionStatus.status === 'processing'
        })
        .map((versionStatus) => {
          return versionStatus.id
        })

      if (notCompletedVersionIds.length > 0) {
        await this.sleep(5000)
        await this.checkVersionStatus(notCompletedVersionIds)
      } else {
      }
    },

    async fetchVersion (versionId) {
      const result = await this.$axios.request({
        method: 'get',
        url: `/versions/${versionId}`
      })

      return result.data
    },
    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    redirectToCreatePage () {
      window.location.href = '/reports/new'
    },
    redirectToDetailsPage (strategyId) {
      this.$store.commit('toggleFullSpinner', true)
      const strategy = this.strategies.find((strategy) => {
        return strategy.id === strategyId
      })
      const firstVersionId = strategy.versions.find((version) => {
        return version.name === 'initial version'
      }).id
      window.location.href = `/reports/${strategyId}?version=${firstVersionId}`
      this.$store.commit('toggleFullSpinner', false)
      // window.location.href = `/strategies/${strategyId}`
    },
    redirectToSpecificVersion (e, strategyId, versionId) {
      e.stopPropagation()
      this.$store.commit('toggleFullSpinner', true)
      window.location.href = `/reports/${strategyId}?version=${versionId}`
      this.$store.commit('toggleFullSpinner', false)
    },
    showDeleteModalHandler (e, studyId) {
      e.stopPropagation()
      this.deleteStrategyModal = true
      this.selectedStrategyToEditId = studyId
      this.showAdditionalMenusId = null
    },
    deleteStrategyConfirmationHandler () {
      this.$axios
        .delete(`/strategies/${this.selectedStrategyToEditId}`)
        .then((res) => {
          this.showToast = true
          this.toastKind = 'success'
          this.toastTitle = 'Success'
          this.toastCaption = 'Deleted successfully!'
          this.deleteStrategyModal = false
          this.strategies = this.strategies.filter((strategy) => {
            return strategy.id !== this.selectedStrategyToEditId
          })
        }).catch((error) => {
          this.showToast = true
          this.toastKind = 'error'
          this.toastTitle = 'Error'
          this.toastCaption = error
          console.log(error)
        })
    },
    showRenameStrategyModalHandler (e, id) {
      e.stopPropagation()
      this.showRenameStrategyModal = true
      this.selectedStrategyToEditId = id
      this.strategyNameToRename = this.strategies.find((strategy) => {
        return strategy.id === id
      }).name
      this.showAdditionalMenusId = null
    },
    toggleShowAdditionalMenus (e, id) {
      e.stopPropagation()
      if (this.showAdditionalMenusId === id) {
        this.showAdditionalMenusId = null
      } else {
        this.showAdditionalMenusId = id
      }
    },
    changeSecondaryFilter (filter) {
      this.secondaryFilter = filter
      if (filter == 'your') {
        this.strategies = this.ownedStrategies
      }
      if (filter == 'shared') {
        this.strategies = this.sharedStrategies
      }
      if (filter == 'all') {
        this.strategies = this.ownedStrategies.concat(this.sharedStrategies)
      }
    },

    getReadableDate (unformattedDate) {
      if (!unformattedDate) return
      const date = new Date(unformattedDate)
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },
    renameStudyConfirmationHandler () {
      // console.log('renaming...')
      // send a patch request to update the strategy name on /strategies/1
      this.$axios
        .patch(`/strategies/${this.selectedStrategyToEditId}`, {
          name: this.strategyNameToRename
        })
        .then((res) => {
          this.showToast = true
          this.toastKind = 'success'
          this.toastTitle = 'Success'
          this.toastCaption = 'Renamed successfully!'
          // console.log('res', res)
          this.showRenameStrategyModal = false
          this.strategies = this.strategies.map((strategy) => {
            if (strategy.id === this.selectedStrategyToEditId) {
              strategy.name = this.strategyNameToRename
            }
            return strategy
          })
        }).catch((error) => {
          this.showToast = true
          this.toastKind = 'error'
          this.toastTitle = 'Error'
          this.toastCaption = error
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
