<template>
  <div>
    <st-toast-notification
        v-if="showSuccessMessage"
        class="st-toast-notification--fixed tw-z-[9999]"
        kind="success"
        title="Answer completed"
        caption="Answer completed"
        @click="showSuccessMessage=false"
      />
    <div
      v-if="!tourMode && userSignedIn == 'true'"
      class="st-survey-insights__top-header top-0 z-99"
    >
      <div class="st-survey-insights__row  ">
        <div class="flex space-x-2 px-3 items-center">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_216_4310)">
              <path
                opacity="0.8"
                d="M21.3881 5.21827L18.5947 3.42763L17.7359 2.87795L14.4913 0.796864C13.7018 0.277127 12.776 0 11.8294 0C10.8827 0 9.95692 0.277127 9.16738 0.796864L5.91076 2.87795L2.25853 5.21827C1.55748 5.65374 0.981274 6.26192 0.585708 6.98395C0.190141 7.70598 -0.0113924 8.51739 0.000616489 9.33964V19.1522C-0.0126463 19.9757 0.188289 20.7886 0.583914 21.5119C0.97954 22.2353 1.55641 22.8447 2.25853 23.2808L6.40287 20.5708L23.6412 9.33964C23.654 8.5178 23.4533 7.7066 23.0585 6.98457C22.6638 6.26255 22.0884 5.65415 21.3881 5.21827Z"
                fill="#6FA8DC"
              />
              <path
                opacity="0.8"
                d="M22.7395 0.717651L18.5951 3.42763L12.8466 7.17214L1.35196 14.6612C1.34138 15.4839 1.54458 16.2955 1.94186 17.0171C2.33914 17.7388 2.91703 18.3461 3.61952 18.7801L5.0066 19.6803L6.40332 20.5756L7.26452 21.1205L10.5067 23.1992C11.2975 23.7214 12.2255 24 13.1747 24C14.1238 24 15.0519 23.7214 15.8427 23.1992L19.0872 21.1205L22.7395 18.7777C23.4409 18.3431 24.0176 17.7355 24.4137 17.0139C24.8097 16.2922 25.0116 15.4809 24.9998 14.6588V4.84622C25.0135 4.0224 24.8125 3.20909 24.4164 2.48556C24.0203 1.76202 23.4425 1.15293 22.7395 0.717651Z"
                fill="#FE795D"
              />
            </g>
            <defs>
              <clipPath id="clip0_216_4310">
                <rect
                  width="25"
                  height="24"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          <a
            href="/welcome"
            class="cursor-pointer"
          >
            <span class="font-bold">AskHumans</span></a>
        </div>
      </div>
      <div
        class="st-survey-insights__row st-survey-insights__hide-mobile tw-relative"
      >
        <template v-if="!getCurrentURL.includes('admin')">
          <div
            ref="searchContainer"
            class="tw-w-[580px]  tw-z-10 tw-absolute -tw-left-1 tw-top-0"
            tabindex="1"
            @blur="handleSearchboxBlur"
          >
          <div
              v-if="showSearchbox"
              ref="searchbox"
              class="tw-absolute tw-z-10 tw-bg-white animate-width tw-rounded-xl tw-overflow-hidden "
              style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
            >
              <div
                class="animate-width tw-flex tw-justify-center tw-items-center tw-px-2 tw-bg-white-two"
              >
                <span class="tw-flex tw-space-x-2">
                  <span
                    v-for="tag in searchInputTags"
                    :key="tag.id"
                    class=" tw-flex tw-space-x-2 tw-py-1 tw-px-2 tw-rounded-md "
                    :class="`tw-bg-[${tag.background_color}]/20 tw-text-${tag.background_color} `"
                  >
                    <span>
                      {{ tag }}
                    </span>
                    <span
                      class="tw-cursor-pointer"
                      @click="removeTagFromSearchInput(tag)"
                    >
                      X
                    </span>
                  </span>
                </span>

                <input
                  ref="mainsearch"
                  v-model="searchStudyString"
                  class="tw-w-full tw-text-base tw-border-none tw-py-3 tw-px-2 tw-space-x-2 tw- focus:tw-border-none focus:tw-outline-none hover:tw-outline-none hover:border-none placeholder:tw-text-black-three tw-bg-white-two"
                  type="text"
                  placeholder="Search..."
                  @keydown.down.prevent="selectNextResult"
                  @keydown.up.prevent="selectNextResult"
                  @input="handleSearchInput"
                >
                <span
                  class="tw-cursor-pointer"
                  @click="hideSearchboxHandler"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_216_27590)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.00024 16C3.58168 16 0 12.4182 0 8C0 3.58178 3.58168 0 8.00024 0C12.4188 0 16 3.58178 16 8C16 12.4187 12.4183 16 8.00024 16ZM8.03206 1.93505C4.68216 1.91795 1.95304 4.61913 1.93546 7.96913C1.91837 11.3186 4.61947 14.0483 7.96889 14.0659C11.3183 14.083 14.0479 11.3813 14.0655 8.03182C14.0831 4.68183 11.3815 1.95215 8.03206 1.93505ZM11.2523 11.0294L11.0267 11.255C10.7089 11.5728 10.1936 11.5728 9.87633 11.255L7.98266 9.36128L6.089 11.255C5.77125 11.5728 5.25639 11.5728 4.93864 11.255L4.71304 11.0294C4.39529 10.7116 4.39529 10.1963 4.71304 9.879L6.6067 7.98528L4.71304 6.09155C4.39529 5.77379 4.39529 5.25845 4.71304 4.94116L4.93864 4.71555C5.25639 4.39779 5.77172 4.39779 6.089 4.71555L7.98266 6.60927L9.87633 4.71555C10.1941 4.39779 10.7094 4.39779 11.0267 4.71555L11.2523 4.94116C11.57 5.25892 11.57 5.77427 11.2523 6.09155L9.35863 7.98528L11.2523 9.879C11.57 10.1968 11.57 10.7121 11.2523 11.0294Z"
                        fill="#474747"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_216_27590">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
              <div
                v-if="getAllTags.length"
                class="tw-px-3 tw-py-3"
              >
                <p class="tw-py-1 tw-text-black-four tw-font-thin">
                  Search by tag
                </p>
                <div
                  class="tw-flex tw-justify-between tw-gap-2 tw-flex-wrap tw-items-center"
                  
                >
                  <div class="tw-flex tw-gap-2 tw-justify-start tw-items-center tw-max-w-full  tw-flex-wrap">
                    <span
                      v-for="tag in getAllTags"
                      :key="tag.id"
                      :class="`tw-bg-[${tag.background_color}]/20 tw-text-${tag.background_color} `"
                      class="tw-cursor-pointer  tw-py-1 tw-min-w-fit tw-px-2 tw-rounded-md "
                      @click="addTagToSearchInput(tag.tag_name)"
                    >
                      {{ tag.tag_name }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="tw-text-primary tw-cursor-pointer"
                      @click="showManageTagsPopup"
                    >Manage tags</span>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  searchStudyString == '' &&
                    !searchInputTags.length && recentSearches.length
                "
                class=""
              >
                <p class="tw-py-1 tw-px-3 tw-text-black-four tw-font-thin">
                  Recent searches
                </p>
                <div class="tw-flex tw-flex-col tw-justify-start tw-items-start"  ref="recentSearchesDiv">
                  <p
                   
                    :tabindex="2"
                  @keydown.down.prevent="selectNextResult"
                  @keydown.up.prevent="selectNextResult"
                    v-for="search in recentSearches"
                    :key="search.query"
                    class="tw-py-3 tw-px-3 tw-cursor-pointer focus:tw-bg-territory-light focus:tw-outline-none focus:tw-border-none hover:tw-bg-territory-light tw-w-full"
                    @click="redirectToSurvey(search.survey_id)"
                    @key-down.enter="redirectToSurvey(search.survey_id)"
                  >
                    {{ search.query }}
                    
                  </p>
                <!-- <p class="tw-py-3 tw-px-3 tw-cursor-pointer hover:tw-bg-territory-light tw-w-full">
                Free figma
              </p>
              <p class="tw-py-3 tw-px-3 tw-cursor-pointer hover:tw-bg-territory-light tw-w-full">
                Union Kitchens Branch NY
              </p> -->
                </div>
              </div>
              <div
                v-if="searchStudyString != '' || searchInputTags.length"
                class=""
              >
                <p class="tw-py-1 tw-px-3 tw-text-black-four tw-font-thin">
                {{ gettingSearchResults ? 'Searching...' : 'Search results' }}
                </p>
                <div
                  v-if="searchedStudies.length && !gettingSearchResults"
                  
                  
                  ref="searchedStudiesDiv"
                  class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-max-h-[400px] tw-overflow-auto scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black "
                >
                  <p
                  :tabindex="0"
                  @keydown.down.prevent="selectNextResult"
                  @keydown.up.prevent="selectNextResult"
                  @keydown.enter.prevent="redirectSearchResult(study.id, study.name)"
                    v-for="study in searchedStudies"
                    :key="study.id"
                    class="tw-py-3 tw-px-3 tw-cursor-pointer focus:tw-bg-territory-light focus:tw-outline-none focus:tw-border-none hover:tw-bg-territory-light tw-w-full"
                    @click="redirectSearchResult(study.id, study.name)"
                  >
                    {{ study.name }}
                  </p>
                <!-- <p class="tw-py-3 tw-px-3 tw-cursor-pointer hover:tw-bg-territory-light tw-w-full">
                Free figma
              </p>
              <p class="tw-py-3 tw-px-3 tw-cursor-pointer hover:tw-bg-territory-light tw-w-full">
                Union Kitchens Branch NY
              </p> -->
                </div>
                <div
                  v-else-if="!gettingSearchResults && searchStudyString != '' && !searchedStudies.length"
                  class="tw-flex tw-px-3 tw-py-3 tw-flex-col tw-justify-start tw-items-start"
                >
                  No results found
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="showManageTags"
            ref="searchByTagDiv"
            class="tw-w-[580px] tw-z-10 tw-absolute -tw-left-1 tw-top-0"
          >
            <div
              class="tw-absolute tw-z-10 tw-bg-white tw-w-full tw-rounded-xl tw-overflow-hidden "
              style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
            >
              <div
                class="tw-w-full tw-flex tw-justify-between tw-py-4 tw-items-center tw-px-2 tw-bg-white-two"
              >
                <p class="tw-font-semibold tw-px-1">
                  Manage tags
                </p>
                <span
                  class="tw-cursor-pointer"
                  @click="hideManageTagsPopup"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_216_27590)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.00024 16C3.58168 16 0 12.4182 0 8C0 3.58178 3.58168 0 8.00024 0C12.4188 0 16 3.58178 16 8C16 12.4187 12.4183 16 8.00024 16ZM8.03206 1.93505C4.68216 1.91795 1.95304 4.61913 1.93546 7.96913C1.91837 11.3186 4.61947 14.0483 7.96889 14.0659C11.3183 14.083 14.0479 11.3813 14.0655 8.03182C14.0831 4.68183 11.3815 1.95215 8.03206 1.93505ZM11.2523 11.0294L11.0267 11.255C10.7089 11.5728 10.1936 11.5728 9.87633 11.255L7.98266 9.36128L6.089 11.255C5.77125 11.5728 5.25639 11.5728 4.93864 11.255L4.71304 11.0294C4.39529 10.7116 4.39529 10.1963 4.71304 9.879L6.6067 7.98528L4.71304 6.09155C4.39529 5.77379 4.39529 5.25845 4.71304 4.94116L4.93864 4.71555C5.25639 4.39779 5.77172 4.39779 6.089 4.71555L7.98266 6.60927L9.87633 4.71555C10.1941 4.39779 10.7094 4.39779 11.0267 4.71555L11.2523 4.94116C11.57 5.25892 11.57 5.77427 11.2523 6.09155L9.35863 7.98528L11.2523 9.879C11.57 10.1968 11.57 10.7121 11.2523 11.0294Z"
                        fill="#474747"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_216_27590">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>

              <div class="">
                <div class="tw-flex tw-flex-col tw-justify-start tw-items-start">
                  <div
                    v-for="tag in getAllTags"
                    :key="tag.id"
                    class="tw-w-full"
                  >
                 
                    <p
                      v-if="tag.id == editTagId"
                      class="tw-py-2 tw-px-3 tw-flex tw-justify-between tw-bg-territory-light tw-w-full"
                    >
                      <input
                        v-model="editTagName"
                        type="text"
                        class="tw-w-full !tw-rounded-lg tw-px-2 tw-py-2"
                        @keyup.enter="updateTag(tag.id)"
                      >
                    </p>
                    <p
                      v-else
                      class="tw-py-3 tw-px-3 tw-flex tw-justify-between hover:tw-bg-territory-light tw-group tw-w-full"
                    >
                      <span class="tw-flex tw-space-x-2 tw-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_504_3226)">
                            <path
                              d="M6.02344 0.5H5.11914C4.5798 0.5 4.14258 0.957107 4.14258 1.52098V2.11314C4.14258 2.67701 4.5798 3.13412 5.11914 3.13412H6.02344C6.56278 3.13412 7 2.67701 7 2.11314V1.52098C7 0.957107 6.56278 0.5 6.02344 0.5Z"
                              fill="#747474"
                            />
                            <path
                              d="M6.02344 4.68213H5.11914C4.5798 4.68213 4.14258 5.13924 4.14258 5.70311V6.29527C4.14258 6.85914 4.5798 7.31625 5.11914 7.31625H6.02344C6.56278 7.31625 7 6.85914 7 6.29527V5.70311C7 5.13924 6.56278 4.68213 6.02344 4.68213Z"
                              fill="#747474"
                            />
                            <path
                              d="M1.88086 4.68213H0.976562C0.437222 4.68213 0 5.13924 0 5.70311V6.29527C0 6.85914 0.437222 7.31625 0.976562 7.31625H1.88086C2.4202 7.31625 2.85742 6.85914 2.85742 6.29527V5.70311C2.85742 5.13924 2.4202 4.68213 1.88086 4.68213Z"
                              fill="#747474"
                            />
                            <path
                              d="M1.88086 0.5H0.976562C0.437222 0.5 0 0.957107 0 1.52098V2.11314C0 2.67701 0.437222 3.13412 0.976562 3.13412H1.88086C2.4202 3.13412 2.85742 2.67701 2.85742 2.11314V1.52098C2.85742 0.957107 2.4202 0.5 1.88086 0.5Z"
                              fill="#747474"
                            />
                            <path
                              d="M6.02344 8.86572H5.11914C4.5798 8.86572 4.14258 9.32283 4.14258 9.8867V10.4789C4.14258 11.0427 4.5798 11.4998 5.11914 11.4998H6.02344C6.56278 11.4998 7 11.0427 7 10.4789V9.8867C7 9.32283 6.56278 8.86572 6.02344 8.86572Z"
                              fill="#747474"
                            />
                            <path
                              d="M1.88086 8.86572H0.976562C0.437222 8.86572 0 9.32283 0 9.8867V10.4789C0 11.0427 0.437222 11.4998 0.976562 11.4998H1.88086C2.4202 11.4998 2.85742 11.0427 2.85742 10.4789V9.8867C2.85742 9.32283 2.4202 8.86572 1.88086 8.86572Z"
                              fill="#747474"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_504_3226">
                              <rect
                                width="7"
                                height="11"
                                fill="white"
                                transform="translate(0 0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span
                        :class="`tw-bg-[${tag.background_color}]/20 tw-text-${tag.background_color} `"
                          class=" tw-px-2 tw-py-0.5 tw-cursor-pointer tw-rounded-md"
                        >
                          {{ tag.tag_name }}
                        </span>
                      </span>
                      <span
                        class="tw-space-x-2 tw-items-center tw-hidden group-hover:tw-flex"
                      >
                        <span
                          class="tw-cursor-pointer"
                          @click="deleteCurrentTag(tag.id)"
                        >Delete</span>
                        <span
                          class="tw-cursor-pointer"
                          @click="editCurrentTag(tag.id)"
                        >Edit</span>
                      </span>
                    </p>
                  </div>
                <!-- <p class="tw-py-3 tw-px-3 tw-cursor-pointer tw-flex tw-justify-between hover:tw-bg-territory-light tw-w-full">
                <span class="tw-flex tw-space-x-2 tw-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_504_3226)">
                      <path
                        d="M6.02344 0.5H5.11914C4.5798 0.5 4.14258 0.957107 4.14258 1.52098V2.11314C4.14258 2.67701 4.5798 3.13412 5.11914 3.13412H6.02344C6.56278 3.13412 7 2.67701 7 2.11314V1.52098C7 0.957107 6.56278 0.5 6.02344 0.5Z"
                        fill="#747474"
                      />
                      <path
                        d="M6.02344 4.68213H5.11914C4.5798 4.68213 4.14258 5.13924 4.14258 5.70311V6.29527C4.14258 6.85914 4.5798 7.31625 5.11914 7.31625H6.02344C6.56278 7.31625 7 6.85914 7 6.29527V5.70311C7 5.13924 6.56278 4.68213 6.02344 4.68213Z"
                        fill="#747474"
                      />
                      <path
                        d="M1.88086 4.68213H0.976562C0.437222 4.68213 0 5.13924 0 5.70311V6.29527C0 6.85914 0.437222 7.31625 0.976562 7.31625H1.88086C2.4202 7.31625 2.85742 6.85914 2.85742 6.29527V5.70311C2.85742 5.13924 2.4202 4.68213 1.88086 4.68213Z"
                        fill="#747474"
                      />
                      <path
                        d="M1.88086 0.5H0.976562C0.437222 0.5 0 0.957107 0 1.52098V2.11314C0 2.67701 0.437222 3.13412 0.976562 3.13412H1.88086C2.4202 3.13412 2.85742 2.67701 2.85742 2.11314V1.52098C2.85742 0.957107 2.4202 0.5 1.88086 0.5Z"
                        fill="#747474"
                      />
                      <path
                        d="M6.02344 8.86572H5.11914C4.5798 8.86572 4.14258 9.32283 4.14258 9.8867V10.4789C4.14258 11.0427 4.5798 11.4998 5.11914 11.4998H6.02344C6.56278 11.4998 7 11.0427 7 10.4789V9.8867C7 9.32283 6.56278 8.86572 6.02344 8.86572Z"
                        fill="#747474"
                      />
                      <path
                        d="M1.88086 8.86572H0.976562C0.437222 8.86572 0 9.32283 0 9.8867V10.4789C0 11.0427 0.437222 11.4998 0.976562 11.4998H1.88086C2.4202 11.4998 2.85742 11.0427 2.85742 10.4789V9.8867C2.85742 9.32283 2.4202 8.86572 1.88086 8.86572Z"
                        fill="#747474"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_504_3226">
                        <rect
                          width="7"
                          height="11"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg> <span class="tw-bg-success/20 tw-px-2 tw-py-0.5">
                    Technology
                  </span>
                </span>
                <span class="tw-flex tw-space-x-2 tw-items-center">
                  <span>Delete</span>
                  <span>Edit</span>
                </span>
              </p>
              <p class="tw-py-3 tw-px-3 tw-cursor-pointer tw-flex tw-justify-between hover:tw-bg-territory-light tw-w-full">
                <span class="tw-flex tw-space-x-2 tw-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_504_3226)">
                      <path
                        d="M6.02344 0.5H5.11914C4.5798 0.5 4.14258 0.957107 4.14258 1.52098V2.11314C4.14258 2.67701 4.5798 3.13412 5.11914 3.13412H6.02344C6.56278 3.13412 7 2.67701 7 2.11314V1.52098C7 0.957107 6.56278 0.5 6.02344 0.5Z"
                        fill="#747474"
                      />
                      <path
                        d="M6.02344 4.68213H5.11914C4.5798 4.68213 4.14258 5.13924 4.14258 5.70311V6.29527C4.14258 6.85914 4.5798 7.31625 5.11914 7.31625H6.02344C6.56278 7.31625 7 6.85914 7 6.29527V5.70311C7 5.13924 6.56278 4.68213 6.02344 4.68213Z"
                        fill="#747474"
                      />
                      <path
                        d="M1.88086 4.68213H0.976562C0.437222 4.68213 0 5.13924 0 5.70311V6.29527C0 6.85914 0.437222 7.31625 0.976562 7.31625H1.88086C2.4202 7.31625 2.85742 6.85914 2.85742 6.29527V5.70311C2.85742 5.13924 2.4202 4.68213 1.88086 4.68213Z"
                        fill="#747474"
                      />
                      <path
                        d="M1.88086 0.5H0.976562C0.437222 0.5 0 0.957107 0 1.52098V2.11314C0 2.67701 0.437222 3.13412 0.976562 3.13412H1.88086C2.4202 3.13412 2.85742 2.67701 2.85742 2.11314V1.52098C2.85742 0.957107 2.4202 0.5 1.88086 0.5Z"
                        fill="#747474"
                      />
                      <path
                        d="M6.02344 8.86572H5.11914C4.5798 8.86572 4.14258 9.32283 4.14258 9.8867V10.4789C4.14258 11.0427 4.5798 11.4998 5.11914 11.4998H6.02344C6.56278 11.4998 7 11.0427 7 10.4789V9.8867C7 9.32283 6.56278 8.86572 6.02344 8.86572Z"
                        fill="#747474"
                      />
                      <path
                        d="M1.88086 8.86572H0.976562C0.437222 8.86572 0 9.32283 0 9.8867V10.4789C0 11.0427 0.437222 11.4998 0.976562 11.4998H1.88086C2.4202 11.4998 2.85742 11.0427 2.85742 10.4789V9.8867C2.85742 9.32283 2.4202 8.86572 1.88086 8.86572Z"
                        fill="#747474"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_504_3226">
                        <rect
                          width="7"
                          height="11"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg> <span class="tw-bg-success/20 tw-px-2 tw-py-0.5">
                    Technology
                  </span>
                </span>
                <span class="tw-flex tw-space-x-2 tw-items-center">
                  <span>Delete</span>
                  <span>Edit</span>
                </span>
              </p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <a class="px-3 " href="/reports">
              
              <div
                class="st-survey-insights__row st-survey-insights__top-header-link space-x-2 hover-text-black cursor-pointer"
                :class="{
                  'text-black-important border-b-2-black':
                    getCurrentPage === 'projects',
                }"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_290_3164)">
                    <path
                      d="M8.01512 0C7.56144 0 7.22873 0.362949 7.22873 0.816635V1.66352C4.35539 2.02647 2.05671 4.32514 1.69376 7.19849H0.846881C0.846881 7.19849 0.786389 7.19849 0.756144 7.19849C0.332703 7.22873 0 7.59168 0 8.04537C0 8.49905 0.393195 8.83176 0.846881 8.80151H1.69376C2.05671 11.6749 4.35539 13.9735 7.22873 14.3365V15.1834C7.22873 15.6371 7.59168 15.9698 8.01512 15.9698C8.46881 15.9698 8.80151 15.6068 8.80151 15.1834V14.3365C11.6749 13.9735 13.9735 11.6749 14.3365 8.80151H15.1834C15.6371 8.80151 15.9698 8.43856 16 8.01512C16 7.56144 15.6371 7.19849 15.2136 7.19849H14.3667C14.0038 4.32514 11.7051 2.02647 8.83176 1.66352V0.816635C8.83176 0.362949 8.46881 0 8.01512 0ZM8.83176 3.29679C10.828 3.62949 12.4008 5.20227 12.7637 7.22873H12.0378C12.0378 7.22873 11.9773 7.22873 11.9471 7.22873C11.4934 7.22873 11.1607 7.62193 11.1909 8.07561C11.1909 8.5293 11.5841 8.862 12.0378 8.83176H12.7637C12.431 10.8582 10.8582 12.431 8.83176 12.7637V12.0378C8.83176 11.5841 8.49905 11.2212 8.04537 11.2212C7.59168 11.2212 7.25898 11.5841 7.25898 12.0378V12.7637C5.26276 12.431 3.65974 10.8582 3.32703 8.83176H4.05293C4.50662 8.83176 4.83932 8.46881 4.86957 8.04537C4.86957 7.59168 4.50662 7.22873 4.08318 7.22873H3.35728C3.68998 5.20227 5.26276 3.65974 7.28923 3.29679V4.02268C7.28923 4.47637 7.65217 4.80907 8.07561 4.80907C8.5293 4.80907 8.862 4.44613 8.862 4.02268V3.29679H8.83176Z"
                      fill="#474747"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_290_3164">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <span>Reports</span>
              </div>
            </a>
            <!-- href="/projects?page=analysis" -->
            <span
              class="px-3"
              @click="showAnswersDrawer"
            >
              <div
                class="st-survey-insights__row st-survey-insights__top-header-link space-x-2 tw-flex tw-items-center hover-text-black cursor-pointer"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_290_3169)">
                    <path
                      d="M12.3617 0H1.6383C0.732766 0 0 0.732766 0 1.6383V9.38298C0 10.2885 0.732766 11.0183 1.6383 11.0213H7.14894L10.5983 13.8957C10.6817 13.9672 10.7889 14.003 10.8962 14C10.9617 14 11.0272 13.9851 11.0868 13.9583C11.2328 13.8779 11.3221 13.72 11.3191 13.5532V11.0213H12.3617C13.2672 11.0213 13.997 10.2885 14 9.38298V1.6383C14 0.732766 13.2672 0.00297872 12.3617 0ZM13.1064 9.38298C13.1064 9.79404 12.7728 10.1277 12.3617 10.1277H10.8723C10.6251 10.1277 10.4255 10.3272 10.4255 10.5745V12.6L8.53106 11.0213L7.58383 10.2319C7.50043 10.1634 7.39319 10.1247 7.28596 10.1277H1.62638C1.2183 10.1217 0.893617 9.79106 0.893617 9.38298V1.6383C0.893617 1.22723 1.22723 0.893617 1.6383 0.893617H12.3617C12.7728 0.893617 13.1064 1.22723 13.1064 1.6383V9.38298Z"
                      fill="#474747"
                    />
                    <path
                      d="M10.8731 3.57453H3.12845C2.88122 3.57453 2.68164 3.37495 2.68164 3.12772C2.68164 2.88048 2.88122 2.68091 3.12845 2.68091H10.8731C11.1204 2.68091 11.3199 2.88048 11.3199 3.12772C11.3199 3.37495 11.1204 3.57453 10.8731 3.57453Z"
                      fill="white"
                    />
                    <path
                      d="M10.8731 5.95734H3.12845C2.88122 5.95734 2.68164 5.75776 2.68164 5.51053C2.68164 5.2633 2.88122 5.06372 3.12845 5.06372H10.8731C11.1204 5.06372 11.3199 5.2633 11.3199 5.51053C11.3199 5.75776 11.1204 5.95734 10.8731 5.95734Z"
                      fill="white"
                    />
                    <path
                      d="M7.00079 8.34039H3.12845C2.88122 8.34039 2.68164 8.14082 2.68164 7.89359C2.68164 7.64635 2.88122 7.44678 3.12845 7.44678H7.00079C7.24802 7.44678 7.4476 7.64635 7.4476 7.89359C7.4476 8.14082 7.24802 8.34039 7.00079 8.34039Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_290_3169">
                      <rect
                        width="14"
                        height="14"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <span>Find an answer</span>
              </div>
            </span>
            <div class="tw-w-[245px] tw-relative">
              <input
                type="text"
                class="tw-px-4 tw-py-2.5 tw-bg-light-gray-four tw-border-none tw-w-full !tw-rounded-full tw-text-sm focus:tw-outline-none focus:tw-border-none"
                placeholder="Search"
                @focus="showSearchboxHandler"
              >
            </div>
          <!-- <a
          class="px-3"
          href="/insights_list"
        >
          <div
            class="st-survey-insights__row st-survey-insights__top-header-link space-x-2 hover-text-black cursor-pointer"
            :class="{'text-black-important border-b-2-black': getCurrentPage === 'insights'}"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 10H1V13H3V10ZM8 6H6V13H8V6ZM13 1L11 1V13H13V1ZM11 0C10.4477 0 10 0.447716 10 1V13C10 13.5523 10.4477 14 11 14H13C13.5523 14 14 13.5523 14 13V1C14 0.447715 13.5523 0 13 0H11ZM5 6C5 5.44772 5.44772 5 6 5H8C8.55228 5 9 5.44772 9 6V13C9 13.5523 8.55228 14 8 14H6C5.44772 14 5 13.5523 5 13V6ZM0 10C0 9.44772 0.447715 9 1 9H3C3.55228 9 4 9.44772 4 10V13C4 13.5523 3.55228 14 3 14H1C0.447715 14 0 13.5523 0 13V10Z"
                fill="black"
              />
            </svg>

            <span>
              Insights
            </span>
          </div>
        </a> -->
          <!-- <a
          class="px-3"
          href="/team"
        >
          <div
            class="st-survey-insights__row st-survey-insights__top-header-link space-x-2 hover-text-black cursor-pointer"
            :class="{'text-black-important border-b-2-black': getCurrentPage === 'members'}"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.125 12.25C13.125 12.25 14 12.25 14 11.375C14 10.5 13.125 7.875 9.625 7.875C6.125 7.875 5.25 10.5 5.25 11.375C5.25 12.25 6.125 12.25 6.125 12.25H13.125ZM6.14455 11.375H13.1054C13.1085 11.3747 13.1127 11.3741 13.1179 11.3732C13.1203 11.3728 13.1227 11.3724 13.125 11.372C13.1237 11.1409 12.979 10.471 12.4607 9.86632C11.9758 9.30069 11.1276 8.75 9.625 8.75C8.12235 8.75 7.27417 9.30069 6.78935 9.86632C6.27101 10.471 6.12628 11.1409 6.12501 11.372C6.12733 11.3724 6.12971 11.3728 6.13213 11.3732C6.13728 11.3741 6.14152 11.3747 6.14455 11.375Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.625 6.125C10.5915 6.125 11.375 5.3415 11.375 4.375C11.375 3.4085 10.5915 2.625 9.625 2.625C8.6585 2.625 7.875 3.4085 7.875 4.375C7.875 5.3415 8.6585 6.125 9.625 6.125ZM12.25 4.375C12.25 5.82475 11.0747 7 9.625 7C8.17525 7 7 5.82475 7 4.375C7 2.92525 8.17525 1.75 9.625 1.75C11.0747 1.75 12.25 2.92525 12.25 4.375Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.06894 8.11997C5.74712 8.01703 5.38959 7.94219 4.993 7.90392C4.79671 7.88498 4.59085 7.875 4.375 7.875C0.875 7.875 0 10.5 0 11.375C0 11.9583 0.291667 12.25 0.875 12.25H4.56432C4.44112 12.0014 4.375 11.7067 4.375 11.375C4.375 10.491 4.70509 9.58827 5.32859 8.83417C5.54161 8.57653 5.78888 8.33624 6.06894 8.11997ZM4.30504 8.7504C2.84593 8.76721 2.0166 9.30953 1.53935 9.86632C1.01871 10.4737 0.875 11.1469 0.875 11.375H3.5C3.5 10.4603 3.78224 9.54941 4.30504 8.7504Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.3125 4.8125C1.3125 3.36275 2.48775 2.1875 3.9375 2.1875C5.38725 2.1875 6.5625 3.36275 6.5625 4.8125C6.5625 6.26225 5.38725 7.4375 3.9375 7.4375C2.48775 7.4375 1.3125 6.26225 1.3125 4.8125ZM3.9375 3.0625C2.971 3.0625 2.1875 3.846 2.1875 4.8125C2.1875 5.779 2.971 6.5625 3.9375 6.5625C4.904 6.5625 5.6875 5.779 5.6875 4.8125C5.6875 3.846 4.904 3.0625 3.9375 3.0625Z"
                fill="black"
              />
            </svg>

            <span> Members</span>
          </div>
        </a> -->
          </div>
        </template>

        <st-overflow-menu
          class="st-survey-insights__top-header-overflow-link st-survey-insights__hide-mobile tw-ml-4"
          :flip-menu="true"
          :fixed-page-position="true"
          vertical-position="top"
        >
          <template v-slot:open-menu-element>
            <div
              class="st-survey-insights__row st-survey-insights__top-header-link "
            >
              <icon-wrapper
                class="st-survey-insights__link-icon"
                icon-name="help"
                :neutral="true"
              />
            </div>
          </template>
          <template v-slot:default>
            <st-overflow-menu-item
              icon-name="faq"
              text="FAQ"
              path="/help/faq"
            />
            <st-overflow-menu-item
              icon-name="tutorial"
              text="Tutorials"
              path="/help/tutorials"
            />
          <!-- <st-overflow-menu-item
            id="Intercom"
            icon-name="chat"
            text="Chat with us"
            path="javascript:void(o)"
          /> -->
          </template>
        </st-overflow-menu>
        <div
          class="st-survey-insights__header-avatar-container st-survey-insights__hide-mobile ml-10p cursor-pointer"
        >
          <st-overflow-menu
            :flip-menu="true"
            vertical-position="top"
            :fixed-page-position="true"
          >
            <template v-slot:open-menu-element>
              <participant-avatar
                class="st-survey-insights__header-avatar"
                :participant="currentUser"
              />
            </template>

            <template v-slot:default>
              <!-- <st-overflow-menu-item
              v-if="currentUser.kind === 'spacetrics_admin'"
              text="Admin"
              path="/admin"
            /> -->
              <st-overflow-menu-item
                icon-name="profile"
                text="Profile"
                :path="'/profile'"
              />
              <st-overflow-menu-item
                icon-name="company"
                text="Company Info"
                path="/company_info"
              />
              <st-overflow-menu-item
                icon-name="team"
                text="Team"
                path="/plans/payment_information?tab=team"
              />
              <st-overflow-menu-item
                v-if="isUserAdmin"
                icon-name="icon-payment"
                text="Payment details"
                path="/plans/payment_information"
              />

              <st-overflow-menu-item
                icon-name="sign-out"
                text="Sign Out"
                @mouseup="$root.signOut('../users/sign_out')"
              />
            </template>
          </st-overflow-menu>
        </div>
      </div>
      <div class="sm:hidden">
        <span
          class="cursor-pointer "
          @click="toggleMenu"
        >
          <span>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_426_4896)">
                <rect
                  y="17.1763"
                  width="22"
                  height="4.32353"
                  rx="2.16176"
                  fill="#0D0D0D"
                />
                <rect
                  x="8.55554"
                  y="8.5293"
                  width="13.4444"
                  height="4.32353"
                  rx="2.16176"
                  fill="#0D0D0D"
                />
                <rect
                  y="0.5"
                  width="22"
                  height="4.32353"
                  rx="2.16176"
                  fill="#0D0D0D"
                />
              </g>
              <defs>
                <clipPath id="clip0_426_4896">
                  <rect
                    width="22"
                    height="21"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
        </span>
      </div>
      <div
        v-if="showMobileMenu"
        class=" top-60 tw-left-0 px-3 w-full bg-white w-full absolute z-99"
      >
        <div class="tw-px-3">
          <a
            class="px-3"
            href="/reports"
          >
          <div
            class="st-survey-insights__row st-survey-insights__top-header-link max-w-fit space-x-2 hover-text-black cursor-pointer"
            :class="{'text-black-important border-b-2-black': getCurrentPage === 'projects'}"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_290_3164)">
                <path
                  d="M8.01512 0C7.56144 0 7.22873 0.362949 7.22873 0.816635V1.66352C4.35539 2.02647 2.05671 4.32514 1.69376 7.19849H0.846881C0.846881 7.19849 0.786389 7.19849 0.756144 7.19849C0.332703 7.22873 0 7.59168 0 8.04537C0 8.49905 0.393195 8.83176 0.846881 8.80151H1.69376C2.05671 11.6749 4.35539 13.9735 7.22873 14.3365V15.1834C7.22873 15.6371 7.59168 15.9698 8.01512 15.9698C8.46881 15.9698 8.80151 15.6068 8.80151 15.1834V14.3365C11.6749 13.9735 13.9735 11.6749 14.3365 8.80151H15.1834C15.6371 8.80151 15.9698 8.43856 16 8.01512C16 7.56144 15.6371 7.19849 15.2136 7.19849H14.3667C14.0038 4.32514 11.7051 2.02647 8.83176 1.66352V0.816635C8.83176 0.362949 8.46881 0 8.01512 0ZM8.83176 3.29679C10.828 3.62949 12.4008 5.20227 12.7637 7.22873H12.0378C12.0378 7.22873 11.9773 7.22873 11.9471 7.22873C11.4934 7.22873 11.1607 7.62193 11.1909 8.07561C11.1909 8.5293 11.5841 8.862 12.0378 8.83176H12.7637C12.431 10.8582 10.8582 12.431 8.83176 12.7637V12.0378C8.83176 11.5841 8.49905 11.2212 8.04537 11.2212C7.59168 11.2212 7.25898 11.5841 7.25898 12.0378V12.7637C5.26276 12.431 3.65974 10.8582 3.32703 8.83176H4.05293C4.50662 8.83176 4.83932 8.46881 4.86957 8.04537C4.86957 7.59168 4.50662 7.22873 4.08318 7.22873H3.35728C3.68998 5.20227 5.26276 3.65974 7.28923 3.29679V4.02268C7.28923 4.47637 7.65217 4.80907 8.07561 4.80907C8.5293 4.80907 8.862 4.44613 8.862 4.02268V3.29679H8.83176Z"
                  fill="#474747"
                />
              </g>
              <defs>
                <clipPath id="clip0_290_3164">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>

            <span>Reports</span>
          </div>
          </a>
          <!-- href="/projects?page=analysis" -->
          <span
            class="px-3"
            @click="showAnswersDrawer"
          >
            <div
              class="st-survey-insights__row st-survey-insights__top-header-link max-w-fit space-x-2 hover-text-black cursor-pointer"
              :class="{
                'text-black-important border-b-2-black':
                  getCurrentPage === 'insights',
              }"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_290_3169)">
                  <path
                    d="M12.3617 0H1.6383C0.732766 0 0 0.732766 0 1.6383V9.38298C0 10.2885 0.732766 11.0183 1.6383 11.0213H7.14894L10.5983 13.8957C10.6817 13.9672 10.7889 14.003 10.8962 14C10.9617 14 11.0272 13.9851 11.0868 13.9583C11.2328 13.8779 11.3221 13.72 11.3191 13.5532V11.0213H12.3617C13.2672 11.0213 13.997 10.2885 14 9.38298V1.6383C14 0.732766 13.2672 0.00297872 12.3617 0ZM13.1064 9.38298C13.1064 9.79404 12.7728 10.1277 12.3617 10.1277H10.8723C10.6251 10.1277 10.4255 10.3272 10.4255 10.5745V12.6L8.53106 11.0213L7.58383 10.2319C7.50043 10.1634 7.39319 10.1247 7.28596 10.1277H1.62638C1.2183 10.1217 0.893617 9.79106 0.893617 9.38298V1.6383C0.893617 1.22723 1.22723 0.893617 1.6383 0.893617H12.3617C12.7728 0.893617 13.1064 1.22723 13.1064 1.6383V9.38298Z"
                    fill="#474747"
                  />
                  <path
                    d="M10.8731 3.57453H3.12845C2.88122 3.57453 2.68164 3.37495 2.68164 3.12772C2.68164 2.88048 2.88122 2.68091 3.12845 2.68091H10.8731C11.1204 2.68091 11.3199 2.88048 11.3199 3.12772C11.3199 3.37495 11.1204 3.57453 10.8731 3.57453Z"
                    fill="white"
                  />
                  <path
                    d="M10.8731 5.95734H3.12845C2.88122 5.95734 2.68164 5.75776 2.68164 5.51053C2.68164 5.2633 2.88122 5.06372 3.12845 5.06372H10.8731C11.1204 5.06372 11.3199 5.2633 11.3199 5.51053C11.3199 5.75776 11.1204 5.95734 10.8731 5.95734Z"
                    fill="white"
                  />
                  <path
                    d="M7.00079 8.34039H3.12845C2.88122 8.34039 2.68164 8.14082 2.68164 7.89359C2.68164 7.64635 2.88122 7.44678 3.12845 7.44678H7.00079C7.24802 7.44678 7.4476 7.64635 7.4476 7.89359C7.4476 8.14082 7.24802 8.34039 7.00079 8.34039Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_290_3169">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>

              <span>
                Find an answer
              </span>
            </div>
          </span>

          <span
            class="px-3"
            href="/team"
          >
            <div
              class="st-survey-insights__row st-survey-insights__top-header-link space-x-2 hover-text-black cursor-pointer"
              :class="{
                'text-black-important border-b-2-black':
                  getCurrentPage === 'members',
              }"
            >
              <input
                type="text"
                class="tw-px-4 tw-py-2.5 tw-bg-light-gray-four tw-border-none !tw-rounded-full tw-text-sm tw-w-full"
                placeholder="Search"
                @click="toggleSearchbox"
              >
            <!-- <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.125 12.25C13.125 12.25 14 12.25 14 11.375C14 10.5 13.125 7.875 9.625 7.875C6.125 7.875 5.25 10.5 5.25 11.375C5.25 12.25 6.125 12.25 6.125 12.25H13.125ZM6.14455 11.375H13.1054C13.1085 11.3747 13.1127 11.3741 13.1179 11.3732C13.1203 11.3728 13.1227 11.3724 13.125 11.372C13.1237 11.1409 12.979 10.471 12.4607 9.86632C11.9758 9.30069 11.1276 8.75 9.625 8.75C8.12235 8.75 7.27417 9.30069 6.78935 9.86632C6.27101 10.471 6.12628 11.1409 6.12501 11.372C6.12733 11.3724 6.12971 11.3728 6.13213 11.3732C6.13728 11.3741 6.14152 11.3747 6.14455 11.375Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.625 6.125C10.5915 6.125 11.375 5.3415 11.375 4.375C11.375 3.4085 10.5915 2.625 9.625 2.625C8.6585 2.625 7.875 3.4085 7.875 4.375C7.875 5.3415 8.6585 6.125 9.625 6.125ZM12.25 4.375C12.25 5.82475 11.0747 7 9.625 7C8.17525 7 7 5.82475 7 4.375C7 2.92525 8.17525 1.75 9.625 1.75C11.0747 1.75 12.25 2.92525 12.25 4.375Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.06894 8.11997C5.74712 8.01703 5.38959 7.94219 4.993 7.90392C4.79671 7.88498 4.59085 7.875 4.375 7.875C0.875 7.875 0 10.5 0 11.375C0 11.9583 0.291667 12.25 0.875 12.25H4.56432C4.44112 12.0014 4.375 11.7067 4.375 11.375C4.375 10.491 4.70509 9.58827 5.32859 8.83417C5.54161 8.57653 5.78888 8.33624 6.06894 8.11997ZM4.30504 8.7504C2.84593 8.76721 2.0166 9.30953 1.53935 9.86632C1.01871 10.4737 0.875 11.1469 0.875 11.375H3.5C3.5 10.4603 3.78224 9.54941 4.30504 8.7504Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.3125 4.8125C1.3125 3.36275 2.48775 2.1875 3.9375 2.1875C5.38725 2.1875 6.5625 3.36275 6.5625 4.8125C6.5625 6.26225 5.38725 7.4375 3.9375 7.4375C2.48775 7.4375 1.3125 6.26225 1.3125 4.8125ZM3.9375 3.0625C2.971 3.0625 2.1875 3.846 2.1875 4.8125C2.1875 5.779 2.971 6.5625 3.9375 6.5625C4.904 6.5625 5.6875 5.779 5.6875 4.8125C5.6875 3.846 4.904 3.0625 3.9375 3.0625Z"
                fill="black"
              />
            </svg>

            <span> Members</span> -->
            </div>
          </span>
          <a
            class="px-3"
            href="/profile"
          >
            <div
              class="st-survey-insights__row st-survey-insights__top-header-link max-w-fit space-x-2 hover-text-black cursor-pointer"
              :class="{
                'text-black-important border-b-2-black':
                  getCurrentPage === 'profile',
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-16p"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <span> Profile</span>
            </div>
          </a>
        </div>
      </div>
   
    </div>
    <tour-view
      v-if="showAnswersTour"
      name="answers-tour"
      :steps="answersTourSteps"
      :show-tour="showAnswersTour"
      @tourFinished="tourFinished"
    />
    <answers-drawer
      v-if="showAnswersDrawerModal"
      @closeModal="closeModal"
      ref="answersDrawerModal"
      title="Find answers"
      @showNotification="showNotification"
    />
  </div>
</template>

<script>
import { StOverflowMenuItem, StOverflowMenu, IconWrapper, StToastNotification } from './index'
import { ParticipantAvatar } from '../pages/insights/index'
import { mapActions, mapState } from 'vuex'
import { isUserAdmin } from '../../mixins'
import { debounce } from 'lodash'
import AnswersDrawer from './answers/answers-drawer.vue'
import TourView from "./tour-view.vue"
export default {
  name: 'TopHeader',
  components: {
    IconWrapper,
    TourView,
    StOverflowMenuItem,
    StOverflowMenu,
    ParticipantAvatar,
    AnswersDrawer,
    StToastNotification
  },
  mixins: [isUserAdmin],
  props: {
    userSignedIn: {
      type: String,
      required: false,
      default: 'true'
    },
    user: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      showMobileMenu: false,
      gettingSearchResults: false,
      showAnswersDrawerModal: false,
      showSearchbox: false,
      showManageTags: false,
      searchStudiesAndTagsDebounced: null,
      editTagName: '',
      editTagId: '',
      searchedStudies: [],
      searchStudyString: '',
      recentSearches: [],
      searchInputTags: [],
      showSuccessMessage: false,
      showAnswersTour: false,
      answersTourSteps: [
        {
          target: '#select-studies-view', // We're using document.querySelector() under the hood
          header: {
            title: 'Select studies'
          },
          content: `Choose one or several studies as the foundation for your question.`,
          params: {
            highlight: true
          }
          // params: {
          //   placement: 'left'
          // }
        },
        {
          target: '#ask-question-view', // We're using document.querySelector() under the hood
          header: {
            title: 'Ask a question'
          },
          content: `Generate questions or formulate a question yourself - it’s up to you.`,
          params: {
            placement: 'left',
            highlight: true
          }
        },
                {
                  target: '#previous-answers-view', // We're using document.querySelector() under the hood
                  header: {
                    title: 'Previous answers'
                  },
                  content: `
        Access your answer history at any time through the answer.`,
        params: {
            highlight: true
          }
        
                }
      ]
    }
  },
  computed: {
    ...mapState([
      'currentUser',
      'navOpen',
      'activePage',
      'hideSidebar',
      'tags'
    ]),
    ...mapState('insights', [
      'activePanelSection',
      'activeQuestionIndex',
      'activeQuestionView',
      'surveyQuestions',
      'tourMode'
    ]),
    getAllTags () {
      return this.tags
    },
    getCurrentURL () {
      const url = window.location.href
      const currentURL = url.split('/')
      return currentURL
    },
    getCurrentPage () {
      if (
        this.activePage === 'member-groups' ||
        this.activePage === 'all-members'
      ) {
        return 'members'
      }
      if (this.activePage === 'insights_list') {
        return 'insights'
      }
      if (this.activePage === 'projects') {
        return 'projects'
      }
      return 'null'
    }
  },
  mounted: function () {
    this.$store.commit('setCurrentUser', this.user)
    setTimeout(() => {
      this.getAllTagsFromDB()
    }, 200)

    setTimeout(() => {
      this.$store.dispatch('stripe/fetchCreditBalance', this.user.accountId)
    }, 500)

    document.addEventListener("mousedown", this.hideSearchBoxOnClickOutside)
  },
  beforeDestroy () {
    document.removeEventListener("mousedown", this.hideSearchBoxOnClickOutside)
  },
  methods: {
    tourFinished (name) {
      if (name === 'answers-tour') {
        this.showAnswersTour = false
      }
      this.$axios
        .post('/tours', {
          name: name,
          user_id: this.currentUser.id
        })
        .then((res) => {
          // console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    closeModal () {
      this.showAnswersDrawerModal = false
    },
    redirectToSurvey(id){
      this.$store.commit("toggleFullSpinner", true);
      const recentHistorySearch = this.recentSearches.find(survey => survey.survey_id === id)
      if(recentHistorySearch.survey.published){
        window.location.href = `/study/${recentHistorySearch.survey.id}/results`
      }
      else{
        window.location.href = `/study/${recentHistorySearch.survey.id}/edit`
      }
    },
    selectNextResult(event) {
      
      if(this.searchStudyString == '' &&
                    !this.searchedStudies.length ){
        const recentSearchesDiv = this.$refs.recentSearchesDiv;
        const results = recentSearchesDiv.querySelectorAll('p');
        const currentFocus = document.activeElement;
        const currentIndex = Array.from(results).indexOf(currentFocus);
        event.preventDefault();
        if(event.key === 'ArrowDown' && results.length){
          if(currentIndex == -1) {
            results[0].focus();
            return;
          }
          else if (currentIndex < results.length - 1) {
            results[currentIndex + 1].focus();
          } else {
            results[0].focus();
          }
        }
        else if(event.key === 'ArrowUp' && results.length ){
          if(currentIndex == -1) {
            results[results.length - 1].focus();
            return;
          }
          else if (currentIndex > 0) {
            results[currentIndex - 1].focus();
          } else {
            results[results.length - 1].focus();
          }
        }
      }
      else{
      const searchedStudiesDiv = this.$refs.searchedStudiesDiv;
      const results = searchedStudiesDiv.querySelectorAll('p');
      const currentFocus = document.activeElement;
      const currentIndex = Array.from(results).indexOf(currentFocus);
      event.preventDefault();
      if (event.key === 'ArrowDown' && results.length > 0) {
        if(currentIndex == -1) {
          results[0].focus();
          return;
        }
        else if (currentIndex < results.length - 1) {
          results[currentIndex + 1].focus();
        } else {
          results[0].focus();
        }
      }
      else if(event.key === 'ArrowUp' && results.length ){
        
        if(currentIndex == -1) {
          results[results.length - 1].focus();
          return;
        }
        else if (currentIndex > 0) {
          results[currentIndex - 1].focus();
        } else {
          results[results.length - 1].focus();
        }
      }
      }
      
      
    },
    isTourSeen (name) {
      return this.currentUser.tours.some((tour) => tour.name === name)
    },
    hideSearchBoxOnClickOutside (event) {
      const node = event?.target;
      if (this.showSearchbox && !this.$refs.searchbox.contains(node)) this.hideSearchboxHandler()
      if(this.showManageTags && !this.$refs.searchByTagDiv.contains(node)) this.hideManageTagsPopup()
    },
    showNotification(history_id,last_updated_at){
      // console.log(history_id,last_updated_at)
      this.checkHistoryUpdates(history_id,last_updated_at)
     
    },
    async checkHistoryUpdates (history_id, last_updated_at) {
      const start_time = Date.now()

      while (true) {
        const updatedHistory = await this.fetchHistoryUpdates(
          history_id,
          last_updated_at
        )

        if (updatedHistory.success) {
          this.successMessage = 'History updated successfully.'
          this.showSuccessMessage = true
          console.log(this.successMessage)
          return
        }

        if (Date.now() - start_time >= this.timeout) {
          return
        }

        await this.sleep(10000)
      }
    },
    async fetchHistoryUpdates (history_id, last_updated_at) {
      const result = await this.$axios.request({
        method: 'post',
        url: `/projects/long_polling/${history_id}`,
        data: {
          last_updated_at: last_updated_at
        }
      })
      return result.data
    },
    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },



    showAnswersDrawer () {
      this.showAnswersDrawerModal = true
      setTimeout(() => {
        
        this.$refs.answersDrawerModal.toggleDrawer()
        
      }, 250)
      setTimeout(() => {
        if(this.currentUser && !this.isTourSeen('answers-tour')){
          const selectStudiesElement = document.getElementById("select-studies-view")
          if(selectStudiesElement){
            console.log('starting tour')
            this.showAnswersTour = true
           setTimeout(()=>{
            this.$tours['answers-tour'].start()
           }, 100)
          }
        }
        else{
          this.showAnswersTour = false
        }
      }, 800)
    },
    toggleMenu () {
      this.showMobileMenu = !this.showMobileMenu
    },
    toggleSearchbox () {
      this.showSearchbox = !this.showSearchbox
    },
    hideSearchboxHandler () {
      this.$refs.searchbox.classList.add('animate-width-out')
      setTimeout(() => {
        // this.$refs.searchbox.classList.remove('animate-width-out')
        this.showSearchbox = false
        this.searchStudyString = ''
        this.searchInputTags = []
      }, 100)

      // this.showSearchbox = false
      // this.searchStudyString = ''
      // this.searchInputTags = []
    },
    showSearchboxHandler () {
      this.showSearchbox = true
      this.getRecentSearches()
      setTimeout(() => {
        this.$refs.mainsearch.focus()
      }, 100)
    },
    showManageTagsPopup () {
      this.showSearchbox = false
      this.showManageTags = true
    },
    hideManageTagsPopup () {
      this.showManageTags = false
    },
    getTags () {
      this.$axios
        .get('/tags')
        .then((res) => {
          this.$store.commit('setTags', res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getAllTagsFromDB () {
      this.$axios
        .get('/tags')
        .then((res) => {
          this.$store.commit('setTags', res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateTag (tagId) {
      this.$axios
        .put(`/tags/${tagId}`, { tag_name: this.editTagName })
        .then((response) => {
          this.getTags()
          this.editTagName = ''
          this.editTagId = ''
        })
        .catch((error) => {
          console.log(error)
        })
    },
    editCurrentTag (tagId) {
      const tag = this.tags.find((tag) => tag.id === tagId)
      this.editTagName = tag.tag_name
      this.editTagId = tag.id
    },
    deleteCurrentTag (tagId) {
      this.$axios
        .delete(`/tags/${tagId}`)
        .then((response) => {
          this.getTags()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    searchStudiesAndTags (searchTerm) {
      let url = `study${searchTerm ? `?name=${searchTerm}` : ''}${
        this.searchInputTags
          ? `${searchTerm ? '&' : '?'}tags=${this.searchInputTags.toString()}`
          : ''
      }`

      this.$axios
        .get(url)
        .then((res) => {
          this.gettingSearchResults = false
          // console.log(res)
          this.searchedStudies = res.data.surveys
        })
        .catch((err) => {
          this.gettingSearchResults = false
          console.log(err)
        })
    },
    getRecentSearches () {
      this.$axios
        .get('/search_histories')
        .then((res) => {
          // console.log(res)
          this.recentSearches = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    redirectSearchResult (id, name) {
      const currentStudy = this.searchedStudies.find(
        (study) => study.id === id
      )
      this.$store.commit("toggleFullSpinner", true);
      this.$axios.request({
        method: 'post',
        url: `/search_histories`,
        data: { name, survey_id: id }
      })

      // console.log(currentStudy)
      if (currentStudy.published) {
        window.location.href = `/study/${id}/results`
      } else {
        window.location.href = `/study/${id}/edit`
      }
    },
    handleSearchInput (event) {
      this.gettingSearchResults = true
      const searchTerm = event.target.value
      if(searchTerm == ''){
        this.searchedStudies = []
        return
      }
      if (!this.searchStudiesAndTagsDebounced) {
        this.searchStudiesAndTagsDebounced = debounce(
          this.searchStudiesAndTags,
          500
        )
      }
      this.searchStudiesAndTagsDebounced(searchTerm)
    },
    addTagToSearchInput (tag) {
      this.searchInputTags.push(tag)
      this.$refs.mainsearch.focus()
      this.gettingSearchResults = true
      this.searchStudiesAndTags(this.searchStudyString)
    },
    removeTagFromSearchInput (tag) {
      this.searchInputTags = this.searchInputTags.filter(
        (item) => item !== tag
      )
      this.$refs.mainsearch.focus()
      if(!this.searchInputTags.length){
        this.gettingSearchResults = false
        this.searchedStudies = []
        
      }
    },
    handleSearchboxBlur () {
      
    }
  }
}
</script>
<style>
.animate-width {
  width: 100%;
  animation: animateWidth 0.3s ease-in-out;
}
.animate-width-out {
  animation: animateHide 0.2s ease-in-out;
}

@keyframes animateHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes animateWidth {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
