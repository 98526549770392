<template>
  <div>
    <div class="tw-bg-white-one tw-py-10">
      <div class="bx--grid">
        <div class="tw-flex tw-justify-between tw-items-center">
          <div class="tw-flex tw-space-x-3">
            <div class="tw-flex tw-space-x-2">
              <a
                href="/admin"
                class="tw-bg-white tw-w-10 tw-h-10 tw-rounded-full  tw-items-center tw-flex tw-justify-center"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_58_1383)">
                    <path
                      d="M5.50013 8.01197C5.50013 7.83774 5.56382 7.66651 5.67963 7.54034L9.39127 3.69822C9.64315 3.43387 10.0543 3.43387 10.309 3.69522C10.5638 3.95657 10.5638 4.38314 10.3119 4.64749L7.05484 8.01498L10.3119 11.3795C10.5522 11.6528 10.532 12.0764 10.2685 12.3257C10.0224 12.557 9.64604 12.557 9.39995 12.3257L5.67963 8.4836C5.56382 8.35743 5.49723 8.18621 5.50013 8.01197Z"
                      fill="#474747"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_58_1383">
                      <rect
                        width="5"
                        height="9"
                        fill="white"
                        transform="translate(10.5 12.5) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>

              </a>
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-1">
              <span class="tw-text-black-three tw-font-[13px]">
                Account
              </span>
              <p class="tw-text-lg tw-font-semibold">
                {{ account.accountName }}
              </p>
            </div>
          </div>
          <div class="tw-flex tw-space-x-3">
            <ah-button
              type="secondary"
              @click="forceLogin(account.projectOwnerId)"
            >
              Account login
            </ah-button>
            <button
              class="tw-p-3 tw-rounded-lg tw-bg-white hover:tw-bg-primary hover:tw-text-white"
              @click="showNotesDrawer=true"
            >
              Notes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bx--grid tw-h-full">
      <div class="bx--col-sm-12 tw-justify-between bx--col-xs-12 tw-flex  tw-mt-3">
        <div class="tw-flex tw-items-center">
          <div
            v-for="tab in Tabs"
            :key="tab"
            :class="{
              'tw-border-b-[4px] tw-border-solid tw-border-primary tw-text-black':
                tab === activeTab,
              'tw-text-black-three tw-border-b-[4px] tw-border-transparent tw-border-solid hover:tw-text-black tw-transition-colors tw-duration-200': tab !== activeTab
            }"
            class="tw-py-4  tw-px-3 tw-cursor-pointer"
            @click="changeActiveTab(tab)"
          >
            {{ tab }}
          </div>
        </div>
        <div class="tw-relative  tw-flex tw-items-center">
          <input
            ref="searchInput"
            v-model="searchInput"
            type="text"
            placeholder="Search"

            class="tw-w-[347px] tw-border tw-border-transparent hover:tw-border-solid hover:tw-border-gray-700  tw-px-4 tw-py-2.5 !tw-rounded-full tw-bg-[#F6F6F6]  tw-ring-0"
            @keydown.enter.prevent="filterStudies"
          >
          <span
            v-if="searchInput!==''"
            class="tw-text-xs tw-px-2 tw-cursor-pointer tw-absolute tw-right-2 tw-z-10 -translate-y-1/2"
            @click="clearSearchInput"
          >Clear</span>
        </div>
      </div>

      <account-studies-tab
        v-if="activeTab == Tabs[0]"
        :account="account"
        :per-page="Number(per_page)"
        :page="Number(page)"
      />
      <account-users-tab
        v-if="activeTab == Tabs[1]"
        :account-id="account.accountId"
      />
      <div
        v-if="activeTab == Tabs[2]"
        class=""
      >
        <billing-tab :account="account" />
      </div>
    </div>
    <st-drawer
      v-model="showNotesDrawer"
      @closeModal="closeNotesDrawer"
    >
      <template v-slot:modalTitle>
        <div class="flex justify-between  ">
          <span class="font-18p">
            Add notes to account
          </span>
        </div>
      </template>

      <template v-slot:modalActions>
        <div

          class="tw-flex tw-space-x-1 tw-justify-between  tw-h-full tw-flex-col"
        >
          <div class="tw-h-[75%] tw-overflow-y-auto scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black ">
            <div
              v-for="accNote in accountNotes"
              :key="accNote.id"
              class="tw-px-5 tw-mt-5"
            >
              <div class=" tw-bg-gray-100 tw-rounded-md tw-p-5 tw-flex tw-flex-col tw-gap-2">
                <p>
                  {{ accNote.content }}
                </p>
                <p class="tw-text-end tw-text-black-two tw-text-sm">
                  {{ getReadableDate(accNote) }}
                </p>
              </div>
            </div>
          </div>
          <div class="tw-px-5 tw-sticky tw-bottom-10">
            <textarea
              v-model="note"
              class="tw-w-full tw-rounded-md tw-p-2 tw-my-2"
              rows="5"
              placeholder="Add your note here..."
            />
            <button
              class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
              @click="saveNote"
            >
              Add note
            </button>
          </div>
        </div>
      </template>
    </st-drawer>
  </div>
</template>

<script>
import { AhButton, StDrawer } from '../common'
import accountStudiesTab from './account-studies-tab.vue'
import accountUsersTab from './account-users-tab.vue'
import BillingTab from '../pages/admin-dashboard/billing-tab.vue'
export default {
  components: {
    AhButton,
    BillingTab,
    accountStudiesTab,
    accountUsersTab,
    StDrawer
  },
  props: {
    account: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      Tabs: ['Studies', 'Users', 'Billing'],
      activeTab: 'Studies',
      searchInput: '',
      per_page: 10,
      page: 1,
      showNotesDrawer: false,
      accountNotes: [],
      note: ''
    }
  },
  mounted () {
    //  check if the url has per_page and page params then set it
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('per_page')) {
      this.per_page = queryParams.get('per_page')
    }
    if (queryParams.has('page')) {
      this.page = queryParams.get('page')
    }
    if (queryParams.has('name')) {
      this.searchInput = queryParams.get('name')
      this.$refs.searchInput.focus()
    }
    setTimeout(() => {
      this.getAccountNotes()
    }, 200)
  },
  methods: {
    getReadableDate (data) {
      if (!data.created_at) return
      const date = new Date(data.created_at)
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },
    getAccountNotes () {
      this.$axios.request({
        method: 'get',
        url: `accounts/${this.account.accountId}/get_notes`
      })
        .then(res => {
          // console.log(res)
          this.accountNotes = res.data.notes.reverse()
          // location.reload()
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveNote () {
      this.$axios.request({
        method: 'post',
        url: `accounts/${this.account.accountId}/add_note`,
        data: {
          content: this.note
        }
      })
        .then(res => {
          // console.log(res.notes)
          this.getAccountNotes()
          this.note = ''
        })
        .catch(err => {
          console.log(err)
        })
    },
    closeNotesDrawer () {
      this.showNotesDrawer = false
    },
    changeActiveTab (tab) {
      this.activeTab = tab
    },
    forceLogin (userId) {
      this.$store.commit('toggleFullSpinner', true)
      const url = `/impersonations/${userId}`
      this.$axios
        .request({
          method: 'post',
          url
        })
        .then((res) => {
          // console.log('res', res)
          window.location.href = '/'
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch((err) => {
          console.error('err', err)
          this.$store.commit('toggleFullSpinner', false)
        })
    },
    filterStudies () {
      if (this.searchInput != '') {
        this.$store.commit('toggleFullSpinner', true)
        location.href = this.page ? `/admin/account/${this.account.accountId}?per_page=${this.per_page}&page=${this.page}&name=${this.searchInput}` : `/admin/account/${this.account.accountId}?name=${this.searchInput}`
      }
    },
    clearSearchInput () {
      this.searchInput = ''
      this.$refs.searchInput.focus()
      this.$store.commit('toggleFullSpinner', true)
      location.href = this.page ? `/admin/account/${this.account.accountId}?per_page=${this.per_page}&page=${this.page}` : `/admin/account/${this.account.accountId}`
    },
    toggleModal () {
      // this.$refs.modal.classList.toggle('tw-hidden')
    }
  }
}

</script>
