<template>
  <div
    v-if="filteredUsers.length"
    class="tw- tw-py-3 tw-flex tw-gap-2 tw-text-sm tw-min-w-fit tw-items-center"
  >
    <span v-if="label">Shared with </span>
    <span class="tw-flex -tw-space-x-[8px]">
      <span
        v-for="(user, index) in filteredUsers.slice(0, 3)"
        :key="`${index}_version_user`"
        class="tw-w-6 tw-h-6 tw-text-sm tw-px-1 tw-py-0.5 tw-text-center tw-rounded-full tw-capitalize"
        :class="{
          'tw-bg-[#267182] tw-text-white': index==0,
          'tw-bg-[#A7AA93] tw-text-black': index == 1,
          'tw-bg-[#D474DC] tw-text-black': index == 2,
        }"
      >
        {{ user.firstName ? user.firstName[0] : 'A' }}
      </span>
    </span>
    <span
      v-if="filteredUsers.length>3"
      class="tw-text-sm"
    >
      + {{ filteredUsers.length - 3 }}
    </span>
  </div>
  <span v-else />
</template>

<script>
export default {

  props: {
    users: {
      type: Array,
      default: () => []
    },
    label: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    filteredUsers () {
      return this.users.flatMap(user => user)
    }
  },

  mounted () {
    // console.log(this.filteredUsers)
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
