<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    nextQuestionId: {
      type: Number,
      required: false,
      default: 0
    },
    activeWidget: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    // console.log('this.activeWidget Answer Data', this.activeWidget)
    return {}
  },
  computed: {
    ...mapGetters('participants', ['participantSubmissionToken']),
    isSubmissionSkippable () {
      return false
    }
  },
  methods: {
    ...mapMutations('participants', ['setInstructionsPage']),
    submitQuestionAnswer () {
      if (this.isPreview) {
        this.advanceForPreview()
      } else if (this.isSubmissionSkippable) {
        this.advanceWithoutSubmission()
      } else {
        this.performSubmission()
      }
    },
    advanceForPreview () {
      if (!this.isSurveyPreview) {
        return
      }
      if (this.nextPreviewQuestion) {
        window.location.href = `/questions/${this.nextPreviewQuestion.id}/preview`
      } else {
        window.location.href = `/study/${this.surveyId}/preview_complete`
      }
    },
    advanceWithoutSubmission () {
      if (!this.nextQuestionId) {
        window.location.href = `/study/${this.surveyId}/complete`
      } else {
        let url =
          '/questions/' + this.nextQuestionId + '/question_and_response.json'
        this.$axios.get(url).then((res) => {
          this.$emit('nextQuestion', res.data)
          this.submitting = false
        })
      }
    },
    performSubmission () {
      let url = `/questions/${this.questionId}/submit?participant_token=${this.participantSubmissionToken}`
      this.$store.commit('toggleFullSpinner', true)
      let data = this.answerData()
      this.$emit('loading', true)
      // console.log('this is the data being submit.....', data)
      for (var pair of data.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      this.$axios
        .request({
          url: url,
          method: 'post',
          data: data
        })
        .then((res) => {
          if (res.data.surveyComplete) {
            if (this.noRedirect) {
              this.$store.commit('toggleFullSpinner', false)
              this.handleSubmission()
            } else {
              window.location.href = `/study/${this.surveyId}/complete?participant_token=${this.participantSubmissionToken}&active_widget=${this.activeWidget}`
            }
          } else {
            this.$emit('nextQuestion', res.data.nextQuestion)
            this.setInstructionsPage(1)
            if (
              this.activeQuestion.questionType === 'virtual_tour' &&
              res.data.nextQuestion.questionType === 'virtual_tour'
            ) {
              this.$nextTick(() => {
                this.panoChanges = []
                this.initializeNewMediaRecorder()
                this.setActivePhase('instructions')
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('toggleFullSpinner', false)
          this.$emit('errorMessage')
        })
    }
  }
}
</script>
