<template>
  <div
    class=" p-2"
  >
    <st-modal
      v-model="showModal"
      width-class="w-500p"
    >
      <template v-slot:modalTitle>
        <span class="font-18p font-bold">
          Share this question
        <!-- {{ title }} -->
        </span>
      </template>
      <template v-slot:modalActions>
        <div class="st-member-groups__modal-text text-black-important">
          Share this question by copying the link below. The more responses we get, the better analytics we can provide for you.
        <!-- <st-input
          v-model="groupName"
          style-type="secondary"
          class="st-input--default st-member-groups__group-name-input"
          :show-label="true"
          label="Group name *"
          placeholder="Enter Group name"
          @emitEnter="groupSubmitHandler"
        />
        <span class="text-error">{{ error }}</span> -->
        </div>
        <div class="flex space-x-2 px-2-important py-2 items-center justify-between bg-primary-brown-100">
          <p class="text-primary-brown">
            {{ `${getBaseUrl}/study/${survey.id}` }}
          </p>
          <st-button
            v-if="!copied"
            @click="copyToClipboard"
          >
            Copy
          </st-button>
          <p
            v-else
            class="bg-success flex space-x-2 items-center px-3 py-2 rounded-4p disabled"
          >
            <span><svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 0.875C10.3829 0.875 13.125 3.61729 13.125 7C13.125 10.3827 10.3827 13.125 7 13.125C3.61729 13.125 0.875 10.3827 0.875 7C0.875 3.61729 3.61729 0.875 7 0.875ZM3.96779 7.08002C3.74167 6.82989 3.76111 6.44395 4.01078 6.21806C4.26091 5.99193 4.64685 6.01137 4.87275 6.26104L6.39845 7.94679L9.69542 4.54122C9.93092 4.29909 10.3175 4.29383 10.5597 4.52933C10.8018 4.76483 10.8071 5.15146 10.5716 5.39358L6.82212 9.26649L6.8212 9.26558C6.81229 9.27473 6.80291 9.28387 6.79308 9.29256C6.54341 9.51868 6.15747 9.49948 5.93111 9.24958L3.96711 7.07979H3.96757L3.96779 7.08002Z"
                fill="#F5F5F5"
              />
            </svg>
            </span>
            <span class="text-white">
              Copied
            </span>
          </p>
        </div>
        <p class="flex justify-end px-4 my-2">
          <button
            class="bg-gray-100 text-gray-two rounded-4p p-3"
            @click="toggle"
          >
            Close
          </button>
        </p>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { StButton, StModal } from '../../common'

export default {
  components: {
    // StInput,
    StButton,
    StModal
  },
  props: {
    title: {
      type: String,
      require: true,
      default: ''
    },
    survey: {
      type: Object,
      require: true

    }

  },
  data: function () {
    return {
      showModal: false,
      groupName: '',
      error: '',
      copied: false,
      groupId: null,
      buttonName: ''
    }
  },

  computed: {
    getBaseUrl () {
      return window.location.origin
    }
  },

  methods: {
    copyToClipboard () {
      const baseUrl = window.location.origin
      const url = `${baseUrl}/study/${this.survey.id}`
      navigator.clipboard.writeText(url)
      this.copied = true
      // console.log(url)
    },
    toggle () {
      this.showModal = !this.showModal
    }
  }
}
</script>
