<template>
  <div class="st-preview-example__phone">
    <div
      :class="{
        'st-preview-example__question-content': true,
        'st-preview-example__question-content__slide': componentName === 'slide'
      }"
      class="st-preview-example__question-content"
    >
      <template v-if="activeQuestion.questionType !== 'virtual_tour'">
        <div class="st-preview-example__mobile-header">
          <a href="/welcome">
            <icon-wrapper
              class="st-preview-example__spacetrics-icon"
              icon-name="spacetrics"
            />
          </a>
          <div class="st-preview-example__question-count">
            <!-- {{ activeQuestion.priority + 1 }} of {{ surveyQuestionCount }} -->
          </div>
        </div>
      </template>

      <!-- <div>
        <img
          v-if="type==slideTypes.TOP_IMAGE"
          src="https://placekitten.com/500"
        >
        <h2 class="font-600">
          {{ heading }}
        </h2>
        <p class="mt-4">
          {{ description }}
        </p>
        <img
          v-if="type==slideTypes.BOTTOM_IMAGE"
          src="https://placekitten.com/500"
        >
      </div> -->
      <component
        :is="componentName"
        v-bind="activeQuestion"
        :button-caption="buttonCaption"
        :is-preview="true"
        :question-id="activeQuestion.id"
        :survey-question-count="0"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { kebabCase } from 'lodash'

import CultureBoardPreview from '../../participant/question_types/culture-board'
import PerceptionSliderPreview from '../../participant/question_types/perception-slider'
import QuadrantPlacemewntPreview from '../../participant/question_types/quadrant-placement'
import SpatialAdjacenciesPreview from '../../participant/question_types/spatial-adjacencies'
import ElementPreferencePreview from '../../participant/question_types/element-preferences'
import PickFavoritePreview from '../../participant/question_types/pick-favorite'
import PreferenceSliderPreview from '../../participant/question_types/preference-slider'

import * as QuestionTypes from '../participants/question_types'
import { IconWrapper } from '../../common'
import VirtualTourPreview from './question_specifications/virtual-tour-preview'
import SlidePreview from './question_specifications/slide/slide-preview'
import { SLIDE_TYPES } from './constants'
export default {
  components: {
    'culture-board-preview': CultureBoardPreview,
    'perception-slider-preview': PerceptionSliderPreview,
    'quadrant-placement-preview': QuadrantPlacemewntPreview,
    'spatial-adjacencies-preview': SpatialAdjacenciesPreview,
    'element-preference-preview': ElementPreferencePreview,
    'pick-favorite-preview': PickFavoritePreview,
    'preference-slider-preview': PreferenceSliderPreview,
    'slide': SlidePreview,

    IconWrapper,
    ...QuestionTypes,
    VirtualTourPreview
  },
  props: {
    surveyName: {
      type: String,
      required: true
    },
    surveyQuestionCount: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    heading: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    img: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      slideTypes: { ...SLIDE_TYPES }
    }
  },
  computed: {
    ...mapGetters('specifications', ['activeQuestion']),
    buttonCaption () {
      if (this.activeQuestion.priority + 1 === this.surveyQuestionCount) { return 'Submit' }
      return 'Next'
    },
    componentName () {
      return kebabCase(this.activeQuestion.questionType)
    }
  },
  mounted () {
    this.$store.commit('participants/setActiveQuestion', this.activeQuestion)
  }
}
</script>
