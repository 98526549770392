<template>
  <div class="tw-h-full tw-flex tw-flex-col">
    <div
      class="tw-flex  tw-px-8 tw-items-center tw-py-3 tw-border-y tw-border-solid tw-border-white-two"
    >
      <div
        class="tw-flex tw-items-center tw-justify-between tw-w-full tw-space-x-2"
      >
        <span class="tw-font-semibold">
          Share report
        </span>
        <ah-button
          type="outlined"
          @click="closeHandler"
        >
          Close
        </ah-button>
      </div>
    </div>
    <div class="tw-flex tw-flex-1 tw-justify-between tw-flex-col">
      <div class="tw-px-8 tw-py-3  tw-flex-1">
        <div class="tw-my-3">
          <p>
            Set feedback deadline
          </p>
          <st-dropdown-menu
            caption="Set feedback"
            :initial-active-option="expiryForFeedback"
            :full-width="true"
            :options="['1 day', '3 days', '7 days']"
            @updateOption="updateExpiryForFeedback"
          />
        </div>
        <div class="tw-mt-4">
          <p>
            Share URL with guests
          </p>
          <div
            class=" tw-pl-3 tw-border tw-border-white-two tw-border-solid tw-rounded-xl tw-flex tw-items-center"
            @click="copyShareableLink"
          >
            <span class="tw-truncate tw-max-w-full tw-py-3">
              {{ shareableLink }}
            </span>
            <span
              v-if="!copiedLink"
              class="tw-flex tw-space-x-2 tw-item-center tw-py-3 tw-px-2 tw-text-white tw-rounded-tr-xl tw-rounded-br-xl tw-justify-center tw-bg-primary tw-cursor-pointer"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_836_10495)">
                  <path
                    d="M3.81496 5.412H5.78052V2.25847C5.78052 1.72475 6.00158 1.23986 6.35781 0.888779C6.71374 0.537697 7.20603 0.319824 7.74758 0.319824H14.8811C15.4227 0.319824 15.9147 0.537697 16.2709 0.888779C16.6271 1.23986 16.8482 1.72475 16.8482 2.25847V9.289C16.8482 9.82273 16.6265 10.3076 16.2709 10.6587C15.9147 11.0098 15.4227 11.2277 14.8811 11.2277H12.9156V14.3812C12.9156 14.9149 12.6945 15.3998 12.3383 15.7509C11.9821 16.102 11.4901 16.3198 10.9485 16.3198H3.81496C3.27341 16.3198 2.78142 16.102 2.42519 15.7509C2.06897 15.3998 1.8479 14.9149 1.8479 14.3812V7.35065C1.8479 6.81692 2.06897 6.33204 2.42519 5.98095C2.78142 5.62987 3.27341 5.412 3.81496 5.412ZM6.87984 5.412H10.9485C11.4901 5.412 11.9821 5.62987 12.3383 5.98095C12.6945 6.33204 12.9156 6.81692 12.9156 7.35065V10.1442H14.8811C15.1193 10.1442 15.3362 10.0477 15.4933 9.89259C15.6507 9.73747 15.7486 9.52375 15.7486 9.2893V2.25877C15.7486 2.02402 15.6507 1.8103 15.4933 1.65488C15.3359 1.49977 15.1187 1.40327 14.8811 1.40327H7.74758C7.50939 1.40327 7.29253 1.49977 7.13515 1.65488C6.97776 1.81 6.87984 2.02402 6.87984 2.25877V5.41229V5.412ZM6.33288 6.49544H3.81436C3.57617 6.49544 3.35931 6.59194 3.20223 6.74706C3.04484 6.90217 2.94692 7.1159 2.94692 7.35094V14.3815C2.94692 14.6162 3.04484 14.8299 3.20223 14.9851C3.35961 15.1402 3.57647 15.2367 3.81496 15.2367H10.9485C11.1867 15.2367 11.4036 15.1402 11.561 14.9851C11.7183 14.8299 11.8163 14.6162 11.8163 14.3815V7.35094C11.8163 7.1162 11.7183 6.90247 11.561 6.74706C11.4036 6.59194 11.1867 6.49544 10.9485 6.49544H6.33288Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_836_10495">
                    <rect
                      width="15"
                      height="16"
                      fill="white"
                      transform="translate(1.8479 0.319824)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <span class="tw-text-sm">
                Copy
              </span>
            </span>
            <span
              v-else
              class="tw-flex tw-space-x-2 tw-item-center tw-py-3 tw-px-2 tw-text-white tw-rounded-tr-xl tw-rounded-br-xl tw-justify-center tw-bg-primary tw-cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="tw-w-5 tw-h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>

              <span class="tw-text-sm">
                Copied
              </span>
            </span>
          </div>
        </div>
        <div class="tw-mt-2 tw-flex tw-items-center">
          <p class="tw-h-0.5 tw-bg-white-two tw-w-full" />
          <p class="tw-px-4 tw-text-base tw-text-light-gray-two">
            or
          </p>
          <p class="tw-h-0.5 tw-bg-white-two tw-w-full" />
        </div>
        <p class="tw-mt-2 tw-mb-1">
          Share with colleagues
        </p>
        <div
          v-if="accountMembers.length && !loadingMembers"
          class="tw-max-h-[280px] tw-overflow-y-auto tw-border tw-border-solid tw-overflow-x-hidden tw-rounded-lg tw-py-1 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black "
          :class="noMemberSelectedError ? 'tw-border-very-negative' : 'tw-border-white-two '"
        >
          <div
            v-for="(user, idx) in accountMembers.filter((member)=>{
              return member.id !== currentUser.id && !version.sharedUsers.some((sharedUser) => sharedUser.id === member.id)
            })"
            :key="'option ' + idx"
          >
            <st-checkbox
              v-if="!user.inactive"
              label-class="tw-cursor-pointer tw-ml-3"
              :checked="selectedMembersToInvite.includes(user.id)"
              class="tw-p-3 tw-cursor-pointer"
              @input="addMemberToInvite(user.id)"
            >
              <div class="tw-flex tw-gap-2 tw-items-center">
                <span
                  class="tw-w-7 tw-h-7 tw-text-sm tw-text-white tw-text-center tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-blue-500"
                >
                  {{ user.first_name ? user.first_name[0] : 'A' }}
                </span>
                <p class="tw-flex tw-flex-col ">
                  <span v-if="user.first_name">
                    {{ user.first_name + " "+ user.last_name }}
                  </span>
                  <span v-else>
                    No name available
                  </span>
                  <span>
                    {{ user.email }}
                  </span>
                </p>
              </div>
            </st-checkbox>
          </div>
        </div>
        <div
          v-else-if="loadingMembers"
          class="tw-my-3 tw-mt-10 tw-flex tw-justify-center tw-items-center tw-flex-col tw-text-center tw-space-y-3"
        >
          <p>
            Loading members...
          </p>
        </div>
        <div
          v-else
          class="tw-my-3 tw-mt-10 tw-flex tw-justify-center tw-items-center tw-flex-col tw-text-center tw-space-y-3"
        >
          <p>
            No members available
          </p>
          <div>
            <ah-button
              @click="
                redirectToTeamsPage
              "
            >
              Add members to your team
            </ah-button>
          </div>
        </div>
        <div class="tw-mt-4">
          <p>
            Write a note to all colleagues
          </p>
          <textarea
            id=""
            v-model="sharingNote"
            class="tw-w-full tw-rounded-lg tw-border tw-border-solid tw-p-3 tw-my-1"
            :class="sharingNoteError ? 'tw-border-very-negative' : 'tw-border-white-two '"
            placeholder="Write a note to all colleagues"
            name=""
            cols="30"
            rows="6"
          />
          <span
            v-if="sharingNoteError"
            class="tw-text-very-negative"
          >{{ sharingNoteError }}</span>
        </div>
        <template v-if="selectedMembersToInvite.length">
          <div
            v-for="(personalNote, index) in personalNoteToUsers"
            :key="`${index}_note`"
            class="tw-mt-4"
          >
            <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
              <div class="tw-flex tw-space-x-2 tw-items-center">
                <div class="tw-flex-shrink-0">
                  <span>
                    Write a note to
                  </span>
                </div>
                <button
                  class="tw-flex tw-bg-white tw-relative tw-w-fit tw-max-w-[180px] tw-justify-between tw-space-x-2 tw-py-2 tw-rounded-xl tw-px-3 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                  @click="toggleShowMembersDropdown(index)"
                >
                  <template v-if="personalNote.user_id">
                    <span
                      class="tw-w-7 tw-h-7 tw-text-sm tw-text-white tw-flex-shrink-0 tw-text-center tw-flex tw-justify-center tw-items-center tw-rounded-full tw-bg-blue-500"
                    >
                      {{ personalNote.user_id ? getMemberInformation(personalNote.user_id).first_name[0] : 'A' }}
                    </span>
                    <span class="">
                      {{ getMemberInformation(personalNote.user_id).first_name + " "+ getMemberInformation(personalNote.user_id).last_name }}
                    </span>
                  </template>
                  <template v-else>
                    <span>
                      Select a member
                    </span>
                  </template>
                  <span class="tw-text-end tw-block tw-pt-1">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_930_2517)">
                        <path
                          d="M8.50709 10.9945C8.33286 10.9945 8.16163 10.9308 8.03546 10.815L4.19334 7.10336C3.92899 6.85148 3.92899 6.44036 4.19034 6.18559C4.45168 5.93081 4.87825 5.93081 5.14261 6.18269L8.51009 9.43978L11.8746 6.18269C12.1479 5.94239 12.5715 5.96266 12.8208 6.22612C13.0522 6.47221 13.0522 6.84859 12.8208 7.09468L8.97872 10.815C8.85255 10.9308 8.68132 10.9974 8.50709 10.9945Z"
                          fill="#474747"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_930_2517">
                          <rect
                            width="5"
                            height="9"
                            fill="white"
                            transform="translate(12.9951 5.99414) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                  </span>
                  <div
                    v-if="showMembersDropdownIds.includes(index)"
                    class="flex flex-col  !tw-text-xs justify-center cursor-pointer tw-border-[0.5px] tw-border-solid tw-border-white-two tw-rounded-lg tw-overflow-hidden absolute bg-white tw-shadow-lg !tw-ml-0 tw-min-w-full tw-max-w-full export-popup !tw-top-[36px] tw-border-t-0 tw-rounded-t-none !tw-text-left"
                  >
                    <!-- {{ membersForDropdown }} -->
                    <template
                      v-for="(members, index2) in membersForDropdown"
                    >
                      <span
                        :key="`dropdown_${index2}`"
                        class="!tw-px-3 !tw-py-1 tw-items-center !tw-flex tw-space-x-2"
                        @click="
                          addToPersonalNote($event, members.id, index)
                        "
                      >
                        <span
                          class="!tw-w-7 !tw-flex !tw-h-7 tw-text-sm tw-flex-shrink-0 tw-text-white tw-text-center  !tw-px-0 hover:!tw-bg-blue-500  tw-justify-center tw-items-center tw-rounded-full tw-bg-blue-500"
                        >
                          {{ members.first_name ? members.first_name[0] : 'A' }}
                        </span>
                        <span class="!tw-px-1 !tw-max-w-full !tw-inline-block tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                          {{ members.first_name + " "+ members.last_name }}
                        </span>
                      </span>
                    </template>
                  </div>
                </button>
              </div>
              <div
                class="tw-p-2 tw-cursor-pointer tw-flex tw-items-center"
                @click="removeFromPersonalNote(index)"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_967_1461)">
                    <path
                      d="M7.49514 14.0693C11.4198 14.0693 14.6063 10.9326 14.6063 7.06934C14.6063 3.20604 11.4198 0.0693359 7.49514 0.0693359C3.57053 0.0693359 0.384033 3.20604 0.384033 7.06934C0.384033 10.9326 3.57053 14.0693 7.49514 14.0693ZM7.49514 1.30984C10.7176 1.30984 13.3461 3.89718 13.3461 7.06934C13.3461 10.2415 10.7176 12.8288 7.49514 12.8288C4.27264 12.8288 1.64423 10.2415 1.64423 7.06934C1.64423 3.89718 4.27264 1.30984 7.49514 1.30984Z"
                      fill="#474747"
                    />
                    <path
                      d="M5.15476 7.7781H9.83549C10.1775 7.7781 10.4656 7.49456 10.4656 7.15785C10.4656 6.82114 10.1775 6.5376 9.83549 6.5376H5.15476C4.8127 6.5376 4.52466 6.82114 4.52466 7.15785C4.52466 7.49456 4.8127 7.7781 5.15476 7.7781Z"
                      fill="#474747"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_967_1461">
                      <rect
                        width="14.2222"
                        height="14"
                        fill="white"
                        transform="translate(0.384033 0.0693359)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <textarea
              id=""
              v-model="personalNoteToUsers[index].note"
              class="tw-w-full tw-rounded-lg tw-border tw-border-solid tw-p-3 tw-my-1"
              placeholder="Write a note"
              name=""
              cols="30"
              rows="5"
            />
            <span
              v-if="sharingNoteError"
              class="tw-text-very-negative"
            >{{ sharingNoteError }}</span>
          </div>
        </template>
        <div
          v-if="selectedMembersToInvite.length && !loadingMembers && personalNoteToUsers.length < selectedMembersToInvite.length"
          class="tw-flex tw-space-x-2 tw-p-3 tw-bg-primary-light tw-items-center tw-justify-center tw-text-primary-dark tw-w-fit tw-mx-auto tw-rounded-xl tw-mt-4 tw-cursor-pointer"
          @click="addPersonalNote"
        >
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_967_587)">
              <path
                d="M7.99512 0.319824C3.58369 0.319824 -0.00488281 3.90916 -0.00488281 8.31982C-0.00488281 12.7305 3.58369 16.3198 7.99512 16.3198C12.4065 16.3198 15.9951 12.7305 15.9951 8.31982C15.9951 3.90916 12.4065 0.319824 7.99512 0.319824ZM7.99512 15.4311C4.07436 15.4311 0.883879 12.241 0.883879 8.31982C0.883879 4.39868 4.07397 1.20859 7.99512 1.20859C11.9163 1.20859 15.1064 4.39868 15.1064 8.31982C15.1064 12.241 11.9159 15.4311 7.99512 15.4311Z"
                fill="#BE6744"
              />
              <path
                d="M10.2171 7.87542H8.43923V6.09751C8.43923 5.85218 8.24037 5.65332 7.99504 5.65332C7.7497 5.65332 7.55046 5.85256 7.55046 6.09789V7.8758H5.77256C5.52723 7.8758 5.32837 8.07465 5.32837 8.31999C5.32837 8.56532 5.52761 8.76456 5.77294 8.76456H7.55085V10.5425C7.55085 10.7878 7.75008 10.987 7.99542 10.987C8.24075 10.987 8.43999 10.7878 8.43999 10.5425V8.76456H10.2179C10.4632 8.76456 10.6625 8.56532 10.6625 8.31999C10.6625 8.07465 10.4632 7.87542 10.2175 7.87542H10.2171Z"
                fill="#BE6744"
              />
            </g>
            <defs>
              <clipPath id="clip0_967_587">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(-0.00488281 0.319824)"
                />
              </clipPath>
            </defs>
          </svg>

          <span>
            Add a personal note
          </span>
        </div>
      </div>
      <div
        class="tw-flex   tw-px-8 tw-items-center tw-py-3 tw-border-t tw-border-solid tw-border-white-two"
      >
        <div class="tw-flex tw-w-full tw-space-x-2 tw-justify-between">
          <ah-button
            type="outlined"
            @click="closeHandler"
          >
            Cancel
          </ah-button>
          <ah-button
            @click="
              showShareReportModal
            "
          >
            Share report
          </ah-button>
        </div>
      </div>
    </div>
    <st-modal
      v-model="shareReportModal"
      @closeModal="shareReportModal = false"
    >
      <p class="tw-text-xl tw-font-semibold tw-py-3">
        Share report
      </p>
      <template v-slot:modalActions>
        <p class="tw-my-3">
          After you share this report, you will not be able to edit it. Are you sure you want to proceed?
        </p>

        <div class="tw-w-full tw-mt-4 tw-flex tw-justify-end">
          <ah-button
            @click="shareStrategyWithUsers"
          >
            Share report
          </ah-button>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import {
  AhButton,
  StDropdownMenu,
  StCheckbox,
  StModal
} from '../../common'
import { mapState } from 'vuex'
export default {
  name: 'AskhumansShareStrategy',

  components: {
    AhButton,
    StDropdownMenu,
    StCheckbox,
    StModal
  },

  props: {
    strategy: {
      type: Object,
      default: () => {}
    },
    closeHandler: {
      type: Function,
      default: () => {}
    },
    sharedHandler: {
      type: Function,
      default: () => {}
    },
    version: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      sharingNoteError: '',
      sharingNote: '',
      expiryForFeedback: '1 day',
      selectedMembersToInvite: [],
      noMemberSelectedError: null,
      accountMembers: [],
      loadingMembers: false,
      shareReportModal: false,
      personalNoteToUsers: [],
      showMembersDropdownIds: [],
      copiedLink: false,
      personalNoteUser: []
    }
  },

  computed: {
    ...mapState(['currentUser']),
    membersForDropdown () {
      const users = this.accountMembers.filter((member) => {
        return this.selectedMembersToInvite.includes(member.id)
      })
      return users
    },
    shareableLink () {
      if (this.version.shareToken) {
        return `${window.location.origin}/reports/${this.strategy.id}?version=${this.version.id}&share_token=${this.version.shareToken
        }`
      } else {
        return `${window.location.origin}/reports/${this.strategy.id}?version=${this.version.id}&share_token=${this.version.share_token
        }`
      }
    }
  },

  mounted () {
    this.getAllTeamMembers()
  },
  methods: {
    showShareReportModal () {
      if (!this.selectedMembersToInvite.length) {
        this.noMemberSelectedError = 'Please select at least one member'
        return
      }
      this.shareReportModal = true
    },
    addPersonalNote () {
      this.personalNoteToUsers.push({
        user_id: null,
        note: ''

      })
    },
    addToPersonalNote (event, memberId, index) {
      console.log(this.personalNoteToUsers)
      this.personalNoteToUsers[index].user_id = memberId
      this.showMembersDropdownIds = this.showMembersDropdownIds.filter(
        (id) => id !== index
      )
      this.showMembersDropdownIds = this.showMembersDropdownIds.includes(index)
        ? this.showMembersDropdownIds.filter((id) => id !== index)
        : [...this.showMembersDropdownIds, index]
    },
    removeFromPersonalNote (index) {
      this.personalNoteToUsers = this.personalNoteToUsers.filter(
        (note, idx) => {
          return idx !== index
        }
      )
    },
    toggleShowMembersDropdown (index) {
      this.showMembersDropdownIds = this.showMembersDropdownIds.includes(index)
        ? this.showMembersDropdownIds.filter((id) => id !== index)
        : [...this.showMembersDropdownIds, index]
    },
    redirectToTeamsPage () {
      window.location.href = '/plans/payment_information?tab=team'
    },
    updateExpiryForFeedback (option) {
      this.expiryForFeedback = option
    },
    getMemberInformation (memberId) {
      return this.accountMembers.find((member) => {
        return member.id === memberId
      })
    },
    shareStrategyWithUsers () {
      // if (!this.sharingNote) {
      //   this.sharingNoteError = 'Please write a note about request'
      //   return
      // }
      if (!this.selectedMembersToInvite.length) {
        this.sharingNoteError = ''
        this.noMemberSelectedError = 'Please select at least one member'
        return
      }

      this.$axios
        .post(`/versions/${this.version.id}/share`, {
          user_ids: this.selectedMembersToInvite.filter((id) => {
            return !this.personalNoteToUsers.find((note) => {
              return note.user_id === id
            })
          }),
          expiry_duration: Number(this.expiryForFeedback.split(' ')[0]),
          request_note: this.sharingNote,
          personal: this.personalNoteToUsers
        })
        .then((res) => {
          this.sharedHandler()
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    copyShareableLink () {
      const textArea = document.createElement('textarea')

      textArea.innerText = this.shareableLink
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      this.copiedLink = true
      setTimeout(() => {
        this.copiedLink = false
      }, 2000)
    },
    getAllTeamMembers () {
      this.loadingMembers = true
      this.$axios.request({
        method: 'post',
        url: 'accounts/team'
      }).then((response) => {
        // console.log(response)
        this.accountMembers = response.data.members
        this.loadingMembers = false
        // .filter((member) => {
        //   return member.kind == 'member'
        // })
        // console.log(response.data.members)
      }).catch((error) => {
        console.log(error)
        this.loadingMembers = false
      })
    },
    addMemberToInvite (id) {
      if (this.selectedMembersToInvite.includes(id)) {
        this.selectedMembersToInvite = this.selectedMembersToInvite.filter(
          (memberId) => {
            return memberId !== id
          }
        )
      } else {
        this.selectedMembersToInvite.push(id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
