<template>
  <div class="">
    <div class="tw-w-full tw-mb-8 tw-overflow-hidden">
      <div class="tw-w-full tw-overflow-x-auto">
        <div class="tw-w-full">
          <div>
            <div
              class="tw-tracking-wide tw-grid tw-grid-cols-10 tw-justify-between tw-text-left tw-text-gray-900 tw-border-solid tw-border-b tw-border-gray-100"
            >
              <div
                class="tw-px-4 tw-py-3 tw-col-span-4 tw-cursor-pointer tw-space-x-2 tw-flex tw-items-center"
                @click="sortByColumnName('name')"
              >
                <span>

                  Study
                </span>
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                    fill="#747474"
                  />
                </svg>
              </div>
              <div
                class="tw-px-4 tw-py-3 tw-col-span-2 tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer"
                @click="sortByColumnName('answers')"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_225_37657)">
                    <path
                      d="M13.3617 1.5H2.6383C1.73277 1.5 1 2.23277 1 3.1383V10.883C1 11.7885 1.73277 12.5183 2.6383 12.5213H8.14894L11.5983 15.3957C11.6817 15.4672 11.7889 15.503 11.8962 15.5C11.9617 15.5 12.0272 15.4851 12.0868 15.4583C12.2328 15.3779 12.3221 15.22 12.3191 15.0532V12.5213H13.3617C14.2672 12.5213 14.997 11.7885 15 10.883V3.1383C15 2.23277 14.2672 1.50298 13.3617 1.5ZM14.1064 10.883C14.1064 11.294 13.7728 11.6277 13.3617 11.6277H11.8723C11.6251 11.6277 11.4255 11.8272 11.4255 12.0745V14.1L9.53106 12.5213L8.58383 11.7319C8.50043 11.6634 8.39319 11.6247 8.28596 11.6277H2.62638C2.2183 11.6217 1.89362 11.2911 1.89362 10.883V3.1383C1.89362 2.72723 2.22723 2.39362 2.6383 2.39362H13.3617C13.7728 2.39362 14.1064 2.72723 14.1064 3.1383V10.883Z"
                      fill="#474747"
                    />
                    <path
                      d="M11.8723 5.07446H4.12766C3.88042 5.07446 3.68085 4.87489 3.68085 4.62766C3.68085 4.38042 3.88042 4.18085 4.12766 4.18085H11.8723C12.1196 4.18085 12.3191 4.38042 12.3191 4.62766C12.3191 4.87489 12.1196 5.07446 11.8723 5.07446Z"
                      fill="white"
                    />
                    <path
                      d="M11.8723 7.45746H4.12766C3.88042 7.45746 3.68085 7.25789 3.68085 7.01065C3.68085 6.76342 3.88042 6.56384 4.12766 6.56384H11.8723C12.1196 6.56384 12.3191 6.76342 12.3191 7.01065C12.3191 7.25789 12.1196 7.45746 11.8723 7.45746Z"
                      fill="white"
                    />
                    <path
                      d="M8 9.84046H4.12766C3.88042 9.84046 3.68085 9.64088 3.68085 9.39365C3.68085 9.14641 3.88042 8.94684 4.12766 8.94684H8C8.24723 8.94684 8.4468 9.14641 8.4468 9.39365C8.4468 9.64088 8.24723 9.84046 8 9.84046Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_225_37657">
                      <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(1 1.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <span>Answers</span>
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                    fill="#747474"
                  />
                </svg>
              </div>
              <div
                class="tw-px-4 tw-py-3 tw-col-span-2 tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-focus-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                    fill="none"
                  />
                  <circle
                    cx="12"
                    cy="12"
                    r=".5"
                    fill="currentColor"
                  />
                  <path
                    d="M12 12m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"
                  />
                  <path d="M12 3l0 2" />
                  <path d="M3 12l2 0" />
                  <path d="M12 19l0 2" />
                  <path d="M19 12l2 0" />
                </svg>
                <span>
                  Reports
                </span>
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                    fill="#747474"
                  />
                </svg>
              </div>
              <div
                class="tw-px-4 tw-py-3 tw-cursor-pointer tw-space-x-2 tw-flex tw-items-center"
                @click="sortByColumnName('incentive_amount')"
              >
                <span>
                  Incentives
                </span>
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                    fill="#747474"
                  />
                </svg>
              </div>
              <div
                class="tw-px-4 tw-py-3 tw-cursor-pointer tw-space-x-2 tw-flex tw-items-center"
              >
                <span>

                  Total billed
                </span>
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                    fill="#747474"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            v-for="(study) in studyList"
            :key="study.name+study.id"
            class="tw-bg-white hover:tw-bg-primary-light"
          >
            <div
              class="tw-text-gray-700 tw-relative tw-grid tw-grid-cols-10 mt-3 tw-justify-between tw-items-center"
            >
              <div class="tw-px-4 tw-py-3 tw-border tw-col-span-4">
                <div class="tw-flex tw-space-x-3 tw-items-center">
                  <div>
                    <img
                      :src="study.pageImageUrl? `${getCompleteImageURL(study.pageImageUrl)}` : defaultStudyImage"
                      class="tw-rounded-lg tw-w-14 tw-h-14  tw-object-cover"
                      alt=""
                    >
                  </div>
                  <div class="tw-w-full">
                    <p class="tw-text-base">
                      {{ study.name }}
                    </p>
                    <p class="tw-text-[13px] tw-space-x-3 tw-items-center">
                      <span>
                        {{ getStudyPlanName(study.id) }}
                      </span>
                      <span v-if="study.reward && study.rewardType=='gift card'">
                        <svg
                          width="11"
                          height="12"
                          viewBox="0 0 11 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.50558 6.00013C7.15973 6.00013 8.45965 4.68022 8.45965 3.00006C8.45965 1.3199 7.15999 0 5.50558 0C3.85144 0 2.55151 1.32016 2.55151 3.00006C2.55151 4.67997 3.85144 6.00013 5.50558 6.00013ZM11 11.28C10.232 8.22003 7.15973 6.29995 4.14667 7.08012C2.13804 7.62012 0.542885 9.18019 0.0111674 11.28C-0.0478279 11.5801 0.129411 11.9401 0.48389 12H10.4093C10.7638 12 11 11.7601 11 11.4001V11.28Z"
                            fill="#A7AA93"
                          />
                        </svg>
                        <span>
                          {{ study.totalResponsesCompleted }}
                          {{
                            study.totalRespondents
                              ? `of ${study.totalRespondents} respondents`
                              : null
                          }}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="study.published"
                class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-2"
              >
                <div v-if="isStrategiesSubscribed(study.id)">
                  <span
                    class="tw-px-2 tw-py-1 tw-border-white-two tw-bg-white tw-border-solid tw-border tw-rounded-md tw-cursor-pointer"
                    @click="showAnswerStrategiesDrawer('answers', study.id, 'downgrade')"
                  >
                    Downgrade
                  </span>
                </div>
                <div
                  v-else-if="isAnswersSubscribed(study.id)"
                  class="tw-flex tw-space-x-2"
                >
                  <p class="tw-flex tw-py-0.5 tw-space-x-1 tw-bg-[#667E6E] tw-text-white tw-rounded-xl tw-w-fit tw-px-2 tw-text-sm tw-items-center">
                    <span class="tw-mt-1">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 1.5C11.8662 1.5 15 4.63405 15 8.5C15 12.366 11.866 15.5 8 15.5C4.13405 15.5 1 12.366 1 8.5C1 4.63405 4.13405 1.5 8 1.5ZM4.53462 8.59146C4.2762 8.30559 4.29841 7.86451 4.58375 7.60635C4.86961 7.34792 5.31069 7.37013 5.56885 7.65547L7.31252 9.58205L11.0805 5.68997C11.3496 5.41325 11.7915 5.40724 12.0682 5.67638C12.3449 5.94552 12.3509 6.38738 12.0818 6.6641L7.79671 11.0903L7.79566 11.0892C7.78547 11.0997 7.77476 11.1101 7.76352 11.1201C7.47818 11.3785 7.0371 11.3565 6.77842 11.0709L4.53384 8.59119H4.53436L4.53462 8.59146Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <span>
                      Active
                    </span>
                  </p>
                  <span
                    class="tw-px-2 tw-py-1 tw-border-white-two tw-bg-white tw-border-solid tw-border tw-rounded-md tw-cursor-pointer"
                    @click="showAnswerStrategiesDrawer('answers', study.id, 'remove')"
                  >
                    Remove
                  </span>
                  <!-- <p class="tw-text-sm tw-mt-1">
                    {{ getFirstOfNextMonth }}
                  </p> -->
                </div>
                <span
                  v-else
                  class="tw-px-2 tw-py-1 tw-border-white-two tw-bg-white tw-border-solid tw-border tw-rounded-md tw-cursor-pointer"
                  @click="showAnswerStrategiesDrawer('answers', study.id, 'add')"
                >
                  Add
                </span>
              </div>
              <div
                v-if="study.published"
                class="tw-px-4 tw-py-3 tw-text-sm tw-border tw-col-span-2"
              >
                <div
                  v-if="isStrategiesSubscribed(study.id)"
                  class="tw-flex tw-space-x-2"
                >
                  <p class="tw-flex tw-py-0.5 tw-space-x-1 tw-bg-[#667E6E] tw-text-white tw-rounded-xl tw-w-fit tw-px-2 tw-text-sm tw-items-center">
                    <span class="tw-mt-1">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 1.5C11.8662 1.5 15 4.63405 15 8.5C15 12.366 11.866 15.5 8 15.5C4.13405 15.5 1 12.366 1 8.5C1 4.63405 4.13405 1.5 8 1.5ZM4.53462 8.59146C4.2762 8.30559 4.29841 7.86451 4.58375 7.60635C4.86961 7.34792 5.31069 7.37013 5.56885 7.65547L7.31252 9.58205L11.0805 5.68997C11.3496 5.41325 11.7915 5.40724 12.0682 5.67638C12.3449 5.94552 12.3509 6.38738 12.0818 6.6641L7.79671 11.0903L7.79566 11.0892C7.78547 11.0997 7.77476 11.1101 7.76352 11.1201C7.47818 11.3785 7.0371 11.3565 6.77842 11.0709L4.53384 8.59119H4.53436L4.53462 8.59146Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <span>
                      Active
                    </span>
                  </p>
                  <span
                    class="tw-px-2 tw-py-1 tw-border-white-two tw-bg-white tw-border-solid tw-border tw-rounded-md tw-cursor-pointer"
                    @click="showAnswerStrategiesDrawer('strategies', study.id, 'remove')"
                  >
                    Remove
                  </span>
                  <!-- <p class="tw-text-sm tw-mt-1">
                    {{ getFirstOfNextMonth }}
                  </p> -->
                </div>
                <span
                  v-else
                  class="tw-px-2 tw-py-1 tw-border-white-two tw-bg-white tw-border-solid tw-border tw-rounded-md tw-cursor-pointer"
                  @click="showAnswerStrategiesDrawer('strategies', study.id, 'add')"
                >
                  Add
                </span>
              </div>

              <div
                v-if="study.published"
                class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-1"
              >
                <span v-if="study.reward && study.rewardType=='gift card'">
                  {{ study.incentiveAmount? `$${study.incentiveAmount.toLocaleString()}` : '' }}
                </span>
              </div>
              <div
                v-if="loadingBilledStudies"
                class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-1"
              >
                Calculating...
              </div>
              <div
                v-else-if="study.published && !loadingBilledStudies"
                class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-1"
              >
                {{ `$${(study.totalBilled / 100).toLocaleString()}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <answers-strategy-drawer
      v-if="selectStudyForAnswersStrategies"
      ref="answersStrategyDrawer"
      :type="typeOfPlan"
      :action="action"
      :study="selectStudyForAnswersStrategies"
      @close="hideStrategyDrawer"
    />
  </div>
</template>

<script>
import { AnswersStrategyDrawer } from '../../common'
import { monthNames } from '../insights/pdfs/utilities'
import StudyDefaultImage from '../../../../assets/images/default-study-image.png'
export default {
  components: {
    AnswersStrategyDrawer
  },
  props: {
    studies: {
      type: Object,
      required: true

    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }

  },
  data () {
    return {
      selectStudyForAnswersStrategies: null,
      typeOfPlan: '',
      action: 'add',
      defaultStudyImage: StudyDefaultImage,
      selectedColumnNameForSorting: null,
      selectedTypeOfSorting: 'asc',
      loadingBilledStudies: false,
      studyList: []
    }
  },

  computed: {
    getFirstOfNextMonth () {
      const date = new Date()
      const firstOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      const dateOfNextMonth = firstOfNextMonth.toLocaleDateString('en-US')
      const monthIndex = dateOfNextMonth.split('/')[0]
      const day = dateOfNextMonth.split('/')[1]
      const year = dateOfNextMonth.split('/')[2]
      return monthNames[monthIndex - 1] + ' ' + day + ', ' + year
    }
  },

  // watch for studies prop changes
  watch: {
    studies: {
      handler (newVal, oldVal) {
        this.getBilledStudies()
      },
      deep: true
    }
  },

  mounted () {
    this.loadingBilledStudies = true
    this.studyList = this.studies.surveys
    this.getBilledStudies()
  },

  methods: {
    sortByColumnName (columnName) {
      if (this.selectedColumnNameForSorting === columnName) {
        this.selectedTypeOfSorting = this.selectedTypeOfSorting === 'asc' ? 'desc' : 'asc'
      } else {
        this.selectedTypeOfSorting = 'asc'
      }
      this.selectedColumnNameForSorting = columnName
      this.$emit('sort-studies', columnName, this.selectedTypeOfSorting)
      // this.sortStudies(columnName, this.selectedTypeOfSorting)
    },
    getStudyPlanName (id) {
      const study = this.studies.surveys.find((study) => study.id === id)

      let studyPlan = null
      if (study && study.plans) {
        studyPlan = study.plans.find((plan) => {
          return plan.title.toLowerCase().includes('answers') == false
        })
      }

      if (studyPlan) {
        if (studyPlan.title == 'Extra Large Plan') {
          return 'Extra Large Study'
        }
        return studyPlan.title.split(' ')[0] + ' Study'
      } else return 'No plan added!'
      // return study.plan ? study.plan.name : 'Free'
    },
    getCompleteImageURL (url) {
      if (!url) {
        return null
      }
      const baseUrl = window.location.origin
      return `${baseUrl}${url}`
    },
    showAnswerStrategiesDrawer (type, studyId, action) {
      if (type == 'strategies' && this.isAnswersSubscribed(studyId) && action !== 'remove') {
        this.action = 'upgrade'
      } else {
        this.action = action
      }

      const currentStudy = this.studies.surveys.find(study => study.id === studyId)

      this.selectStudyForAnswersStrategies = currentStudy

      this.typeOfPlan = type
      setTimeout(() => {
        this.$refs.answersStrategyDrawer.toggleDrawer()
      }, 100)
    },
    hideStrategyDrawer () {
      this.selectStudyForAnswersStrategies = null
    },
    getBilledStudies () {
      this.loadingBilledStudies = true
      this.$axios({
        method: 'post',
        url: `/study/get_study_bill?per_page=${this.perPage}&page=${this.currentPage}`
      })
        .then((response) => {
          this.loadingBilledStudies = false

          this.studyList = response.data.surveys
        })
        .catch((error) => {
          this.loadingBilledStudies = false
          console.log(error)
        })
    },
    isAnswersSubscribed (id) {
      const study = this.studies.surveys.find(study => study.id === id)
      let isAnswersSubscribed = false
      if (study && study.plans) {
        study.plans.find(plan => {
          if (plan.title.split(' ').includes('Answers')) {
            isAnswersSubscribed = true
          }
        })

        return isAnswersSubscribed
      }
    },
    isStrategiesSubscribed (id) {
      const study = this.studies.surveys.find(study => study.id === id)
      let isStrategiesSubscribed = false
      if (study && study.plans) {
        study.plans.find(plan => {
          if (plan.title.split(' ').includes('Strategies')) {
            isStrategiesSubscribed = true
          }
        })
        return isStrategiesSubscribed
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
