<template>
  <div class="bx--grid">
    <!-- <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
    </div> -->
    <div class="bx--col-sm-8 bx--col-xs-12">
      <st-hyperlink
        class="back-hyperlink-spacing"
        :back-link="true"
        text="Back"
        @click.native="$goBack($event)"
      />
      <h1 class="page-header--maximum-space">
        New Project
      </h1>
      <st-input
        v-model.trim="newProject.name"
        class="input-spacing"
        label="Name (required)"
        name="project[name]"
        placeholder="Wayne Tower Headquarters"
      />
      <st-input
        ref="autoComplete"
        v-model.trim="autoCompleteValue"
        class="input-spacing"
        label="Location"
        name="project[locationName]"
        placeholder="Gotham City"
      />
      <div
        class="st-new-project__upload-area"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <st-upload-preview
            v-if="newProject.coverPhotoFile"
            key="image-preview"
            :display-large-preview="true"
            :file="newProject.coverPhotoFile"
            :filename="newProject.coverPhotoFile.name"
            :hide-bar="true"
            @remove="removeImagePreview"
          />
          <st-hyperlink
            v-else
            key="open-image-modal-link"
            icon-class="st-hyperlink__default-icon-spacing"
            icon-name="upload"
            text="Upload Cover Photo"
            @click="showMediaUploader = true"
          />
        </transition>
      </div>
    </div>
    <div class="st-new-project__start-project-spacer" />
    <div class="bx--offset-sm-7 bx--col-sm-3">
      <st-button
        class="st-new-project__start-button"
        caption="Start Project"
        icon-name="arrow"
        :show-action="true"
        :disabled="!newProject.name"
        @click="createNewProject"
      />
    </div>
    <st-media-uploader
      v-if="showMediaUploader"
      :aspect-ratio="4.23"
      :folders="folders"
      :modal-title="modalTitle"
      @closeMediaUploader="showMediaUploader = false"
      @uploadComplete="cropComplete"
    />
  </div>
</template>

<script>
import { googleAddressAutoComplete } from '../../../mixins'
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInput,
  StMediaUploader,
  StUploadPreview
} from '../../common/'

export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StInput,
    StMediaUploader,
    StUploadPreview
  },
  mixins: [googleAddressAutoComplete],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      newProject: {
        coverPhotoFile: null,
        name: ''
      },
      showMediaUploader: false
    }
  },
  computed: {
    modalTitle () {
      return this.newProject.name ? `Add Cover Photo To ${this.newProject.name}` : 'Add Cover Photo To Your New Project'
    }
  },
  methods: {
    createNewProject () {
      this.$store.commit('toggleFullSpinner', true)
      let imageFile = this.newProject.coverPhotoFile ? this.newProject.coverPhotoFile : new File([null], '')
      let formData = new FormData()
      formData.append('project[location_name]', this.autoCompleteValue)
      formData.append('project[name]', this.newProject.name)
      formData.append('project[cover_image]', imageFile)
      let url = '/projects'
      this.$axios.request({
        url: url,
        method: 'post',
        data: formData
      })
        .then(res => { window.location.href = `/projects/${res.data.id}` })
        .catch(err => { console.log(err); this.$store.commit('toggleFullSpinner', false) })
    },
    cropComplete (payload) {
      this.newProject.coverPhotoFile = payload
      this.showMediaUploader = false
    },
    removeImagePreview () {
      this.newProject.coverPhotoFile = null
    }
  }
}
</script>
