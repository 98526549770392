<template>
  <div
    class="tw-h-full "
    @click="toggleShowAdditionalMenus($event, null)"
  >
    <h1>
      THIS IS NOT BEING USED ANYMORE.....
    </h1>
    <!-- <div class="tw-bg-black tw-text-white tw-px-10 tw-py-12">
      <p class="tw-text-[30px] tw-text-center tw-mt-2 tw-leading-tight tw-font-semibold">
        Strategies
      </p>
    </div>
    <div
      class="border-bottom-gray border-top-gray tw-flex tw-justify-between tw-py-1 tw-items-center bg-insights-bar md:tw-bg-none"
    >
      <div class="bx--grid">
        <div class="bx--row">
          <div
            class="tw-w-full tw-px-6 tw-text-sm tw-flex tw-space-x-2 tw-items-center tw-py-3"
          >
            <secondary-button
              :selected="secondaryFilter === 'all'"
              @click="changeSecondaryFilter('all')"
            >
              All
            </secondary-button>
            <secondary-button
              :selected="secondaryFilter === 'isAnswer'"
              @click="changeSecondaryFilter('isAnswer')"
            >
              Your strategies
            </secondary-button>
            <secondary-button
              :selected="secondaryFilter === 'isAnswersAndStrategies'"
              @click="changeSecondaryFilter('isAnswersAndStrategies')"
            >
              Shared with you
            </secondary-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bx--grid tw-text-sm  tw-mt-3">
      <div class="tw-w-full tw-mb-8 tw-overflow-hidden">
        <div class="tw-w-full tw-overflow-x-auto">
          <div class="tw-w-full">
            <div>
              <div
                class="tw-tracking-wide tw-grid tw-grid-cols-12 tw-justify-between tw-text-left tw-text-gray-900 tw-border-solid tw-border-b tw-border-gray-100"
              >
                <div class="tw-px-4 tw-py-3 tw-col-span-4">
                  Title
                </div>
                <div class="tw-px-4 tw-py-3 tw-col-span-2">
                  Strategy Framework
                </div>
                <div class="tw-px-4 tw-py-3 tw-col-span-1">
                  Created on
                </div>
                <div class="tw-px-4 tw-py-3 tw-col-span-1">
                  # of studies
                </div>
                <div class="tw-px-4 tw-py-3 tw-col-span-1">
                  Versions
                </div>
                <div class="tw-px-4 tw-py-3 tw-col-span-2">
                  Shared with
                </div>

                <div class="tw-px-4 tw-py-3" />
              </div>
            </div>

            <div
              v-for="strategy in strategiesList"
              :key="strategy.name+strategy.id"
              class="tw-cursor-pointer tw-h-fit"
              :class="strategiesList.length == 1 ? 'tw-min-h-[300px]': ''"
            >
              <div
                class="tw-text-gray-700 tw-bg-white tw-h-fit hover:tw-bg-light-four  tw-relative tw-grid tw-grid-cols-12 mt-3 tw-justify-between tw-items-center"
              >
                <div class="tw-px-4 tw-py-3 tw-border tw-col-span-4">
                  <p class="tw-text-base tw-flex tw-gap-2 tw-items-center">
                    <span>
                      {{ strategy.name ? strategy.name : '[Strategy Name]' }}
                    </span>
                    <span class="tw-py-1 tw-px-2 tw-bg-[#6E70A4] tw-rounded-md tw-text-white">
                      Processing
                    </span>
                  </p>
                </div>
                <div class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-2">
                  <span>
                    SWOT Analysis
                  </span>
                </div>
                <div class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-1">
                  <span>
                    Nov 20, 2023
                  </span>
                </div>
                <div class="tw-px-4 tw-py-3 tw-flex tw-justify-center tw-text-sm tw-border tw-col-span-1">
                  <span
                    class="tw-text-[#667E6E]"
                  >
                    2
                  </span>
                </div>
                <div class="tw-px-4 tw-py-3 tw-flex tw-justify-center tw-text-sm tw-border tw-col-span-1">
                  <span
                    class="tw-text-[#667E6E]"
                  >
                    3
                  </span>
                </div>
                <div class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-2">
                  <span class="tw-flex -tw-space-x-2">
                    <span class="tw-w-6 tw-h-6 tw-text-sm tw-px-1 tw-py-0.5 tw-text-center tw-bg-blue-600 tw-text-white tw-rounded-full">
                      E
                    </span>
                    <span class="tw-w-6 tw-h-6 tw-text-sm tw-px-1 tw-py-0.5 tw-text-center tw-bg-blue-600 tw-text-white tw-rounded-full">
                      S
                    </span>
                    <span class="tw-w-6 tw-h-6 tw-text-sm tw-px-1 tw-py-0.5 tw-text-center tw-bg-blue-600 tw-text-white tw-rounded-full">
                      A
                    </span>
                  </span>
                </div>
                <div

                  class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-1 tw-cursor-pointer hover:tw-underline"
                >
                  <div
                    class="font-600 py-3 rounded-4p border-2p cursor-pointer  tw-rounded-full "
                    @click="toggleShowAdditionalMenus($event, strategy.id)"
                  >
                    <svg
                      width="4"
                      height="14"
                      viewBox="0 0 4 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.25015 11.7921C0.25015 12.6145 0.91688 13.2812 1.73933 13.2812C2.56179 13.2812 3.22852 12.6145 3.22852 11.7921C3.22852 10.9696 2.56179 10.3029 1.73933 10.3029C0.91688 10.3029 0.25015 10.9696 0.25015 11.7921ZM0.25015 6.82814C0.25015 7.65059 0.91688 8.31732 1.73933 8.31732C2.56179 8.31732 3.22852 7.65059 3.22852 6.82814C3.22852 6.00569 2.56179 5.33896 1.73933 5.33896C0.91688 5.33896 0.25015 6.00569 0.25015 6.82814ZM0.25015 1.86417C0.25015 2.68663 0.91688 3.35336 1.73933 3.35336C2.56179 3.35336 3.22852 2.68663 3.22852 1.86417C3.22852 1.04172 2.56179 0.374991 1.73933 0.374991C0.91688 0.374991 0.25015 1.04172 0.25015 1.86417Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div
                    v-if="showAdditionalMenusId==strategy.id"
                    class="flex flex-col tw-right-20  justify-center cursor-pointer tw-border-[0.5px] tw-border-solid tw-border-white-two tw-rounded-lg tw-overflow-hidden absolute bg-white tw-shadow-lg !tw-w-20 export-popup !tw-text-left"
                  >
                    <span
                      class=""
                      @click="toggleShowRenameStrategyModal($event, strategy.id)"
                    >
                      Rename
                    </span>
                    <span
                      class=""
                      @click="toggleDeleteStrategyModal($event, strategy.id)"
                    >
                      Delete
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <st-modal
      v-if="showRenameStrategyModal"
      v-model="showRenameStrategyModal"
    >
      <h3 class="font-600">
        Rename strategy
      </h3>
      <div class="tw-py-2">
        <st-input
          v-model="strategyNameToRename"
          style-type="rounded"
          placeholder="Strategy name"
        />
      </div>
      <div class="flex justify-end mt-2">
        <st-button

          @click="renameStudyConfirmationHandler"
        >
          Rename strategy
        </st-button>
      </div>
    </st-modal>
    <st-modal
      v-if="deleteStrategyModal"
      v-model="deleteStrategyModal"
    >
      <h3 class="font-600">
        Delete strategy
      </h3>
      <div class="tw-py-2">
        <p>
          After deleting this strategy, you will not be able to recover it. Are you sure you want to proceed?
        </p>
      </div>
      <div class="flex justify-end mt-2">
        <button
          class="tw-px-3 tw-py-3 tw-rounded-lg tw-bg-error tw-text-white "
          @click="deleteStrategyConfirmation"
        >
          Delete strategy
        </button>
      </div>
    </st-modal>
  </div>
</template>

<script>
import { AhButton, StDropdownMenu, AhInput, StCheckbox, SecondaryButton, StInput, StButton, StModal } from '../../common'
export default {
  name: 'AskhumansStrategiesList',
  components: {
    AhButton,
    AhInput,
    StCheckbox,
    SecondaryButton,
    StInput,
    StButton,
    StModal,
    StDropdownMenu

  },
  data () {
    return {
      showAdditionalMenusId: null,
      showRenameStrategyModal: false,
      strategyNameToRename: '',
      deleteStrategyModal: false,
      secondaryFilter: 'all',
      strategiesList: [],
      strategyIdToDelete: null,
      strategyIdToRename: null

    }
  },

  mounted () {
    this.getStudyHavingStrategies()
  },

  methods: {
    toggleDeleteStrategyModal (e, id) {
      console.log('delete')
      e.stopPropagation()
      this.strategyIdToDelete = id
      this.deleteStrategyModal = !this.deleteStrategyModal
    },
    toggleShowRenameStrategyModal (e, id) {
      e.stopPropagation()
      console.log('delete')
      this.strategyIdToRename = id
      this.showRenameStrategyModal = !this.showRenameStrategyModal
    },
    toggleShowAdditionalMenus (e, id) {
      e.stopPropagation()
      if (this.showAdditionalMenusId === id) {
        this.showAdditionalMenusId = null
      } else {
        this.showAdditionalMenusId = id
      }
    },
    changeSecondaryFilter (filter) {
      this.secondaryFilter = filter
    },
    renameStudyConfirmationHandler () {
      // send a request to rename the strategy
      this.$axios({
        method: 'patch',
        url: `/strategies/${this.strategyIdToRename}`,
        data: {
          name: this.strategyNameToRename
        }
      })
        .then((response) => {
          console.log(response)
          // this.$router.push({ name: 'strategies-list' })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteStrategyConfirmation () {
      // send a request to delete the strategy
      console.log('deleting...')
      this.$axios({
        method: 'delete',
        url: `/strategies/${this.strategyIdToDelete}`
      })
        .then((response) => {
          console.log(response)
          // this.$router.push({ name: 'strategies-list' })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getStudyHavingStrategies () {
      this.loading = true
      setTimeout(() => {
        this.$axios({
          method: 'post',
          url: `/study/get_answers_studies`
        })
          .then((response) => {
          // console.log(response.data.surveys);
            this.loading = false
            this.studyList = response.data.surveys.filter(
              (survey) =>
                survey.plans.some((plan) =>
                  plan.title.toLowerCase().includes('answers')
                )
            )
            // this.searchedStudyList = this.studyList
            // console.log(this.studyList)
            // this.isStudyExist = this.studiesList?.length === 0;
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
