<template>
  <div>
    <div class="st-questions-panel__title-and-switch">
      <p class="st-questions-panel__title">
        Questions
      </p>
      <div class="st-questions-panel__switch">
        <div
          :class="{
            'st-questions-panel__switch-icon-container': true,
            'st-questions-panel__switch-icon-container--active': !showDescription,
          }"
          tabindex="0"
          @click="showDescription = false"
          @keyup.enter="showDescription = false"
        >
          <icon-wrapper
            :class="{
              'st-questions-panel__switch-icon': true,
              'st-questions-panel__switch-icon--active': !showDescription,
            }"
            icon-name="question-name-toggle"
            :actionable="!showDescription"
          />
        </div>
        <div class="st-questions-panel__switch-separator" />
        <div
          :class="{
            'st-questions-panel__switch-icon-container': true,
            'st-questions-panel__switch-icon-container--active': showDescription,
          }"
          tabindex="0"
          @click="showQuestionDescriptions"
          @keyup.enter="showQuestionDescriptions"
        >
          <icon-wrapper
            :class="{
              'st-questions-panel__switch-icon': true,
              'st-questions-panel__switch-icon--active': showDescription,
            }"
            icon-name="question-description-toggle"
            :actionable="showDescription"
          />
        </div>
      </div>
    </div>
    <a
      v-for="(question, index) in surveyQuestions"
      :key="question.id"
      tabindex="0"
      :class="{
        'st-questions-panel__row': true,
        'st-questions-panel__row--active': index === activeQuestionIndex,
        'st-questions-panel__row--show-description': showDescription
      }"
      :href="`/study/1/results?question=${index}`"
      @click.prevent="$store.dispatch('insights/setActive', index)"
      @keyup.enter="$store.dispatch('insights/setActive', index)"
    >
      <div
        :class="{
          'st-questions-panel__number': true,
          'st-questions-panel--active': index === activeQuestionIndex
        }"
      >
        {{ index + 1 }}.
      </div>
      <icon-wrapper
        :icon-name="questionIconName(question)"
        :neutral="index !== activeQuestionIndex"
      />
      <div>
        <p
          :class="{
            'st-questions-panel__name': true,
            'st-questions-panel--active': index === activeQuestionIndex
          }"
        >
          {{ questionType(question) }}
        </p>
        <p
          v-if="showDescription"
          :class="{
            'st-questions-panel__description': true,
            'st-questions-panel--active': index === activeQuestionIndex
          }"
        >
          {{ question.description }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IconWrapper } from '../../../common'
export default {
  components: {
    IconWrapper
  },
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      hover: false,
      showDescription: false
    }
  },
  computed: {
    ...mapState('insights', ['surveyQuestions', 'activeQuestionIndex'])
  },
  methods: {
    questionIconName (question) {
      return question.questionType === 'favorite_choice'
        ? 'check-mark'
        : question.questionType.split('').map(value => { return value === '_' ? '-' : value }).join('')
    },
    questionType (question) {
      return question.questionType === 'culture_board' ? 'Image Board' : question.questionType.split('_').join(' ')
    },
    showQuestionDescriptions () {
      this.showDescription = true
      this.$nextTick(() => {
        document.querySelector('.st-questions-panel__row--active').scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })
      })
    }
  }
}
</script>
