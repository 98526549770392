<template>
  <div
    class="st-data-table__container"
    :class="{[containerClass]: true}"
  >
    <table class="st-data-table">
      <thead class="st-data-table__header">
        <tr class="st-data-table__header-row">
          <th>
            <div class="st-data-table__header-checkbox">
              <st-checkbox
                :checked="anyRowsChecked"
                :select-multiple="!allRowsChecked"
                @input="clickAllToggle"
              />
            </div>
          </th>
          <th
            v-for="(header, n) in headers"
            :key="n"
            :class="{
              'st-data-table__head-item': true,
              'st-data-table__head-item--center': centeredHeaders.includes(header),
            }"
          >
            <div
              :class="{
                'st-data-table__header-and-chevron': sortableColumns.includes(header),
                'st-data-table__header-and-chevron--center': centeredHeaders.includes(header) && sortableColumns.includes(header)
              }"
              @click="handleColumnSorting(header)"
              @mouseenter="hoverIdx = n"
              @mouseleave="hoverIdx = null"
            >
              {{ header }}
              <div class="st-data-table__chevron-container">
                <transition name="fade">
                  <icon-wrapper
                    v-if="activeColumn && activeColumn.name === header || (hoverIdx === n && sortableColumns.includes(header))"
                    :class="{
                      'st-data-table__chevron': true,
                      'st-data-table__chevron--descending': activeColumn && activeColumn.name === header && !activeColumn.ascending
                    }"
                    icon-name="chevron"
                  />
                </transition>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="st-data-table__body">
        <tr
          v-if="selectedRowCount>0"
          :class="{
            'st-data-table__selected-row': true,
            'st-data-table__selected-row--modal-table': modalTable
          }"
        >
          <td
            colspan="2"
            :class="{
              'st-data-table__selected-row-text-container': true,
              'st-data-table__selected-row-text-container--modal-table': modalTable
            }"
          >
            <div
              :class="{
                'st-data-table__selected-row-left-text': true,
                'st-data-table__selected-row-left-text--modal-table': modalTable
              }"
            >
              {{ selectedRowCount }} Selected
            </div>
            <st-checkbox
              v-if="modalTable"
              :checked="true"
              :invert="true"
              class="st-data-table__modal-selected-row-checkbox"
              :select-multiple="!allRowsChecked"
              @input="clickAllToggle"
            />
            <div
              :class="{
                'st-data-table__selected-row-arrow': true,
                'st-data-table__selected-row-arrow--modal-table': modalTable
              }"
            />
          </td>
          <td
            v-for="n in (headers.length - 3)"
            :key="'empty-col-'+n"
          />
          <td
            colspan="2"
            :class="{
              'st-data-table__selected-row-text-container': true,
              'st-data-table__selected-row-text-container--modal-table': modalTable
            }"
          >
            <div
              :class="{
                'st-data-table__selected-row-right-text': true,
                'st-data-table__selected-row-right-text--modal-table': modalTable
              }"
            >
              <!-- @slot Anything extra we want to display when rows are selected -->
              <slot name="selected-actions" />
            </div>
          </td>
        </tr>
        <!-- @slot what we want to display for each row, should be `tr` at top level
        row should be the relevant object for this row
        -->
        <slot
          v-for="row in rows"
          :row="row"
          :anyRowsChecked="anyRowsChecked"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import { IconWrapper, StCheckbox } from '../common'

function isSelected (row) { return row.selected }

export default {
  components: {
    IconWrapper,
    StCheckbox
  },
  props: {
    activeColumn: {
      type: Object,
      required: false,
      default: () => ({})
    },
    centeredHeaders: {
      type: Array,
      required: false,
      default: () => ['Surveys Taken', 'Status', 'Source']
    },
    /** Array of column headers  */
    headers: {
      type: Array,
      required: true
    },
    /** Whether to replace header row on select */
    modalTable: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * array of data objects we want to display in our table
     * each object is expected to have `id` defined
     */
    rows: {
      type: Array,
      required: true
    },
    /** Columns in the table that are able to be sorted by accending or decending  */
    sortableColumns: {
      type: Array,
      required: false,
      default: () => []
    },

    /** container class for the data table  */
    containerClass: {
      type: String,
      required: false

    }
  },
  data: function () {
    return {
      hoverIdx: null
    }
  },
  computed: {
    selectedRows () {
      return this.rows.filter(isSelected)
    },
    selectedRowCount () {
      return this.selectedRows.length
    },
    anyRowsChecked () {
      return this.rows.some(isSelected)
    },
    allRowsChecked () {
      return this.rows.every(isSelected)
    }
  },
  methods: {
    clickAllToggle () {
      this.anyRowsChecked ? this.$emit('unselectAll') : this.$emit('selectAll')
    },
    handleColumnSorting (header) {
      if (this.sortableColumns.includes(header)) {
        this.activeColumn.name === header
          ? this.$emit('toggleActiveColumn', { ...this.activeColumn, ascending: !this.activeColumn.ascending })
          : this.$emit('setActiveColumn', { name: header, ascending: true })
      }
    }
  }
}
</script>
