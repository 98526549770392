<template>
  <div class="  ">
    <div class="">
      <!-- <h1
        ref="header"
        class="page-header st-contents__header-spacing"
      >
        Add Content
      </h1> -->
      <!-- <st-icon-description-card
        v-for="(question, index) in QUESTION_DESCRIPTIONS"
        :key="question.name"
        class="bx--offset-sm-1 bx--col-sm-10 st-contents__space-cards"
        :description="question.description"
        :icon-name="question.iconName"
        :show-link="true"
        :title="question.name"
        @click="addMetric(question.underScoredName, question.optionsCount)"
        @hyperlinkClick="openPreviewModal(index)"
      /> -->

      <div class="mt-5" />
      <div class="mt-5 flex md:space-x-5 flex-col md:flex-row space-y-5 md:space-y-0">
        <div class="p-5 border-primary-brown rounded-8p flex flex-col justify-between">
          <div>
            <p class="font-18 font-600">
              Question Prompt
            </p>
            <p class="mt-3">
              This question allows for respondents to give open ended reactions to images, videos, or to a simply written question. Respondents’ commentary is analyzed by GPT.
            </p>
          </div>
          <div>
            <button
              class="bg-blue-primary px-3 py-3  text-white rounded-4p"
              @click="addMetric('opinion_metric',null)"
            >
              Select
            </button>
          </div>
        </div>
        <div class="p-5 border-primary-brown rounded-8p flex flex-col justify-between">
          <div>
            <p class="font-18 font-600">
              Info Screens
            </p>
            <p class="mt-3">
              Use Info Screens to include additional information for your study.  Info Screens are not mandatory but can be help to give your participants context clues for what you’re asking.
            </p>
          </div>
          <div class="mt-5">
            <button
              class="bg-blue-primary px-3 py-3  text-white rounded-4p"
              @click="addMetric('slide', null)"
            >
              Select
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="questionCount > 0"
        class="st-contents__view-questions"
      >
        <div
          class="st-new-question__add-question-text"
          @click="$emit('show-questions')"
        >
          <icon-wrapper
            class="st-new-question__add-question-icon"
            icon-name="view-password"
            :invert="true"
          />
          View Questions
        </div>
      </div>
    </div>
    <!-- <div class="bx--offset-sm-11 bx--col-sm-1 st-contents__help-icon-container">
      <a
        id="Intercom"
        class="st-contents__help-icon"
        href="javascript:void(o)"
      >
        <icon-wrapper
          class="st-intercom-help-icon"
          icon-name="help"
        />
      </a>
    </div> -->
    <question-preview-modal
      v-if="displayPreviewModal"
      :active-preview-metric="activePreviewMetric"
      :display-preview-modal="displayPreviewModal"
      :metric-image-paths="metricImagePaths"
      @addMetric="addMetric(activePreviewMetric.underScoredName, activePreviewMetric.optionsCount)"
      @closePreviewModal="displayPreviewModal = false"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { IconWrapper, StIconDescriptionCard } from '../../common'
import QuestionPreviewModal from './question-preview-modal'
import { QUESTION_DESCRIPTIONS, FOLLOW_UP_QUESTIONS } from './constants'

export default {
  components: {
    IconWrapper,

    StIconDescriptionCard,
    QuestionPreviewModal
  },
  props: {
    metricImagePaths: {
      type: Array,
      required: false,
      default: () => []
    },
    surveyId: {
      type: Number,
      required: true
    },
    containerType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      surveyData: null,
      followupQuestion: false,
      displayPreviewModal: false,
      previewModalIndex: 0,
      selectedFilterOption: FOLLOW_UP_QUESTIONS[0],
      filterSelectOptions: FOLLOW_UP_QUESTIONS,
      QUESTION_DESCRIPTIONS
    }
  },
  computed: {
    ...mapGetters('specifications', ['questionCount']),
    activePreviewMetric: {
      get () {
        return QUESTION_DESCRIPTIONS[this.previewModalIndex]
      },
      set (newMetric) {
        QUESTION_DESCRIPTIONS[this.previewModalIndex] = newMetric
      }
    }

  },

  methods: {
    ...mapMutations('specifications', ['appendQuestion', 'setActiveQuestionIdx']),
    appendQuestionAndFocus (questionData) {
      this.appendQuestion(questionData)
      this.$emit('setActive', this.questionCount - 1)
    },

    filterOptionHandler (option) {
      this.selectedFilterOption = option
      this.changeFollowup()
    },
    addMetric (questionType, optionsCount = 0) {
      let type = this.containerType == 'slides' ? 'slide' : 'study'
      let url = `/${type}/` + this.surveyId + '/questions'
      let data = {
        container_id: this.surveyId,
        container_type: this.containerType,
        question_type: questionType,
        priority: this.questionCount
      }
      this.$store.commit('toggleInlineSpinner', true)

      this.$axios.request({
        url: url,
        method: 'post',
        data: { question: data }
      })
        .then((res) => {
          this.$emit('hideContent', questionType)
          if (optionsCount > 0) {
            // add options
            let optionUrl = '/questions/' + res.data.id + '/question_options/'
            for (let i = 1; i <= optionsCount; i++) {
              this.$axios.request({
                url: optionUrl,
                method: 'post',
                data: { question_option: { question_id: res.data.id, priority: i } }
              })
                .then(res => {
                  if (i === optionsCount) {
                    this.appendQuestionAndFocus(res.data.question)
                  }
                })
                .catch(err => {
                  console.log(err.response.data)
                })
            }
          } else {
            this.appendQuestionAndFocus(res.data)
          }
          setTimeout(() => {
            this.$store.commit('toggleInlineSpinner', false)
          }, 2450)
        }).catch(err => {
          console.log(err.response)
          // TODO: This is just to make culture_board question work
          // and should be removed after creating response from backend
          const data = {
            askForExplanation: null,
            canSkip: true,
            complete: false,
            description: null,
            externalVideoUrl: null,
            hasRooms: false,
            id: 123,
            imageUrl: null,
            matterportId: null,
            maxXLabel: null,
            maxYLabel: null,
            mediaUrl: null,
            minXLabel: null,
            minYLabel: null,
            name: null,
            options: [],
            panoskinId: null,
            priority: 0,
            questionType: 'image_board',
            scaleLength: null,
            scaleStyle: null,
            surveyId: 37,
            url: 'http://localhost:3000/questions/97.json'
          }
          this.appendQuestionAndFocus(data)
        })
    },
    openPreviewModal (index) {
      this.previewModalIndex = index
      this.$nextTick(() => {
        this.displayPreviewModal = true
      })
    }

  }
}
</script>
