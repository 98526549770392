<template>
  <div class="bx--grid">
    <div class="bx--offset-sm-1 bx--col-sm-10 bx--col-xs-12">
      <h1 class="page-header">
        Insights
      </h1>
      <div class="lookup-and-dropdown">
        <st-input
          v-model="insightsLookupValue"
          style-type="secondary"
          placeholder="Search project"
          class="st-input--default lookup-and-dropdown__space-input"
          :show-icon="true"
          :show-label="false"
          label="Insights Lookup"
          label-in-field-class="st-input--hide-mobile-label"
          @blur="updateSearchParam"
        />
        <st-dropdown-menu
          caption="Insights Filter"
          :initial-active-option="activeInsightsFilterOption"
          :options="Object.values(insightsFilterValues)"
          @updateOption="setDropdownOption"
        />
      </div>
      <st-accordion
        v-for="project in activeSurveyInsights"
        :key="project.id"
        :expanded-text="expandedText(project.surveys.length)"
      >
        <template v-slot:visible-section="stAccordionSlotProps">
          <div class="st-insights-list__project-name-and-icon">
            <icon-wrapper
              class="st-insights-list__project-icon"
              icon-name="survey-insights"
            />
            <p class="st-insights-list__project-name">
              {{ stAccordionSlotProps.expanded ? expandedName(project.name) : limitName(project.name) }}
            </p>
          </div>
        </template>
        <template v-slot:expanded-section>
          <st-card
            v-for="survey in project.surveys"
            :key="survey.id"
            tabindex="0"
            class="st-insights-list-survey-card st-insights-list-survey-card__position-contents"
            :url="`/study/${survey.id}/results`"
            @click.native.stop="advanceToInsights(survey.id)"
            @keyup.native.enter="advanceToInsights(survey.id)"
          >
            <template
              v-slot:card-content="stCardSlotProps"
            >
              <div>
                <div
                  class="survey-card__position-title"
                >
                  <icon-wrapper
                    class="survey-card__title-icon"
                    icon-name="survey"
                  />
                  <p
                    :class="{
                      'survey-card__title': true,
                      'survey-card__title--hover': stCardSlotProps.hover
                    }"
                  >
                    {{ survey.name }}
                  </p>
                </div>
                <div class="survey-card__position-date flex space-x-3">
                  <icon-wrapper
                    class="project-and-survey-cards__date-icon"
                    icon-name="calendar"
                    :actionable="false"
                  />
                  <p class="project-and-survey-cards__date">
                    Modified {{ survey.updatedAt.format('MMM DD, YYYY') }}
                  </p>
                  <p class="project-and-survey-cards__date--mobile">
                    {{ survey.updatedAt.format('MM/DD/YYYY') }}
                  </p>
                </div>
              </div>
              <div class="project-and-survey-cards__position-statistic st-insights-list-survey-card__questions-count">
                <p
                  :class="{
                    'project-and-survey-cards__statistic-number text-28p': true,
                    'project-and-survey-cards__statistic-number--hover': stCardSlotProps.hover
                  }"
                >
                  {{ survey.questionCount }}
                </p>
                <p
                  :class="{
                    'project-and-survey-cards__statistic-title text-14p': true,
                    'project-and-survey-cards__statistic-title--hover': stCardSlotProps.hover
                  }"
                >
                  Questions
                </p>
              </div>
              <div class="project-and-survey-cards__position-statistic  st-insights-list-survey-card__individual-responses">
                <p
                  :class="{
                    'project-and-survey-cards__statistic-number text-28p': true,
                    'project-and-survey-cards__statistic-number--hover': stCardSlotProps.hover
                  }"
                >
                  {{ survey.responsesCount }}
                </p>
                <p
                  :class="{
                    'project-and-survey-cards__statistic-title text-14p': true,
                    'project-and-survey-cards__statistic-title--hover': stCardSlotProps.hover
                  }"
                >
                  Individual Responses
                </p>
              </div>
            </template>
          </st-card>
        </template>
      </st-accordion>
      <st-pagination
        class="st-insights-list__pagination"
        :number-of-items="filteredProjects.length"
        @updateActiveIndices="updateIndicesOfProjectsShown"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { caseInsensitiveSortBy } from '../../../utilities/data_utilities'
import { Survey } from '../../../structures/survey'
import { IconWrapper, StAccordion, StCard, StDropdownMenu, StInput, StPagination } from '../../common'
import refineUrlFilter from '../../../mixins/refineUrlFilter'

export default {
  components: {
    IconWrapper,
    StAccordion,
    StCard,
    StDropdownMenu,
    StInput,
    StPagination
  },
  mixins: [refineUrlFilter],
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  data: function () {
    let projectsWithMoment = this.projects.filter(project => !project.archived).map(project => {
      return {
        ...project,
        surveys: project.surveys.map(Survey).filter(survey => !survey.archived)
      }
    })
    return {
      activeIndices: [0, 0],
      activeInsightsFilterOption: '',
      allProjects: projectsWithMoment,
      insightsLookupValue: ''
    }
  },
  computed: {
    ...mapState(['currentUser']),
    projectsWithPublishedSurveysAndResponses () {
      return this.allProjects.map(project => {
        return { ...project,
          surveys: project.surveys.filter(survey => survey.published && !survey.archived && survey.responsesCount > 0)
        }
      })
    },
    projectsWithSurveys () {
      return this.projectsWithPublishedSurveysAndResponses.filter(project => project.surveys.length > 0)
    },
    myProjects () {
      return this.projectsWithSurveys.filter(project => project.createdById === this.currentUser.id)
    },
    otherProjects () {
      return this.projectsWithSurveys.filter(project => project.createdById !== this.currentUser.id)
    },
    allProjectsCaption () {
      return `All Projects (${this.projectsWithSurveys.length})`
    },
    myProjectsCaption () {
      return `My Projects (${this.myProjects.length})`
    },
    otherProjectsCaption () {
      return `Other Projects (${this.otherProjects.length})`
    },
    insightsFilterValues () {
      return {
        allProjects: this.allProjectsCaption,
        lastModified: 'Last Modified',
        myProjects: this.myProjectsCaption,
        otherProjects: this.otherProjectsCaption,
        'a-z': 'A - Z',
        'z-a': 'Z - A'
      }
    },
    filteredInsightsByDropdown () {
      let projects = []
      switch (this.getInsightsFilterKey(this.activeInsightsFilterOption)) {
        case 'lastModified':
          projects = this.projectsWithSurveys.slice().sort((a, b) => b.updatedAt - a.updatedAt)
          break
        case 'myProjects':
          projects = this.myProjects
          break
        case 'otherProjects':
          projects = this.otherProjects
          break
        case 'a-z':
          projects = caseInsensitiveSortBy(this.projectsWithSurveys, 'name')
          break
        case 'z-a':
          projects = caseInsensitiveSortBy(this.projectsWithSurveys, 'name', true)
          break
        default:
          projects = this.projectsWithSurveys
      }
      return projects
    },
    filteredInsightsBySearch () {
      return this.filteredInsightsByDropdown.map(project => {
        return { ...project,
          surveys: project.surveys.filter(survey => {
            return survey.name.toLowerCase().includes(this.insightsLookupValue.toLowerCase())
          })
        }
      })
    },
    filteredProjects () {
      return this.filteredInsightsBySearch.filter(project => project.surveys.length > 0)
    },
    activeSurveyInsights () {
      return this.filteredProjects.slice(...this.activeIndices)
    }
  },
  mounted () {
    this.$store.commit('setActivePage', 'insights_list')
    this.$nextTick(() => {
      this.activeInsightsFilterOption = this.insightsFilterValues[this.filterParam]
    })
  },
  created () {
    this.defaultFilter = ('allProjects')
    this.activeInsightsFilterOption = this.insightsFilterValues[this.filterParam]
    this.insightsLookupValue = this.searchParam
  },
  methods: {
    advanceToInsights (surveyId) {
      window.location = `/study/${surveyId}/results`
    },
    expandedName (name) {
      return name.length > 56 ? name.slice(0, 56) + '...' : name
    },
    expandedText (numberOfSurveys) {
      return numberOfSurveys === 1 ? `${numberOfSurveys} Survey` : `${numberOfSurveys} Surveys`
    },
    limitName (name) {
      return name.length > 26 ? name.slice(0, 26) + '...' : name
    },
    getInsightsFilterKey (payload) {
      return Object.keys(this.insightsFilterValues).find(key => this.insightsFilterValues[key] === payload)
    },
    setDropdownOption (payload) {
      this.updateUrlParams('filter', this.getInsightsFilterKey(payload))
      this.activeInsightsFilterOption = payload
    },
    updateIndicesOfProjectsShown (payload) {
      this.activeIndices = payload
    }
  }
}
</script>
