import { render, staticRenderFns } from "./ah-small-loader.vue?vue&type=template&id=99512ef4&scoped=true&"
import script from "./ah-small-loader.vue?vue&type=script&lang=js&"
export * from "./ah-small-loader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99512ef4",
  null
  
)

export default component.exports