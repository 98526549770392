<template>
  <div class="tw-mt-10 px-20 tw-max-w-7xl">
    <div class="tw-flex tw-justify-between">
      <p class="tw-text-2xl tw-font-semibold">
        Team members
      </p>

      <button
        v-if="currentUser.kind === 'admin'"
        class="tw-flex tw-bg-primary tw-p-3 tw-text-white tw-rounded-xl tw-space-x-2 tw-items-center"
        @click="showTeamMemberModal"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1168_66)">
            <path
              d="M8 0C3.58857 0 0 3.58933 0 8C0 12.4107 3.58857 16 8 16C12.4114 16 16 12.4107 16 8C16 3.58933 12.4114 0 8 0ZM8 15.1112C4.07924 15.1112 0.888762 11.9211 0.888762 8C0.888762 4.07886 4.07886 0.888762 8 0.888762C11.9211 0.888762 15.1112 4.07886 15.1112 8C15.1112 11.9211 11.9208 15.1112 8 15.1112Z"
              fill="white"
            />
            <path
              d="M10.2221 7.55544H8.44417V5.77753C8.44417 5.5322 8.24531 5.33334 7.99998 5.33334C7.75465 5.33334 7.55541 5.53258 7.55541 5.77791V7.55582H5.7775C5.53217 7.55582 5.33331 7.75468 5.33331 8.00001C5.33331 8.24534 5.53255 8.44458 5.77788 8.44458H7.55579V10.2225C7.55579 10.4678 7.75503 10.6671 8.00036 10.6671C8.24569 10.6671 8.44493 10.4678 8.44493 10.2225V8.44458H10.2228C10.4682 8.44458 10.6674 8.24534 10.6674 8.00001C10.6674 7.75468 10.4682 7.55544 10.2225 7.55544H10.2221Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1168_66">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>
          Add member
        </span>
      </button>
    </div>
    <div>
      <div
        v-for="member in teamMembers.filter(
          (member) => member.id !== currentUser.id
        )"
        :key="member.id"
      >
        <div
          class="tw-flex tw-space-x-4 tw-items-center tw-mt-4 tw-p-4 tw-border tw-border-white-two tw-border-solid tw-bg-white tw-rounded-xl tw-w-[500px] tw-justify-between"
        >
          <div class="tw-flex tw-space-x-3 tw-items-center">
            <div
              class="tw-w-8 tw-h-8 tw-bg-secondary-dark tw-rounded-full tw-font-semibold tw-justify-center tw-items-center tw-uppercase tw-flex"
            >
              <span v-if="member.first_name && !member.avatar_url">
                {{ getNameCharacter(member.first_name, member.last_name) }}
              </span>
              <span v-else-if="member.first_name && member.avatar_url">
                <img
                  v-if="member.avatar_url"
                  :src="member.avatar_url"
                  class="tw-w-8 tw-h-8 tw-rounded-full"
                />
              </span>
            </div>
            <div class="tw-flex tw-flex-col">
              <p v-if="member.first_name" class="tw-text-sm tw-font-semibold">
                {{ member.first_name }} {{ member.last_name }}
              </p>
              <p class="tw-text-sm tw-text-gray-500">
                {{ member.email }}
              </p>
            </div>
          </div>
          <div class="tw-flex tw-space-x-10 tw-items-center">
            <div
              v-if="!member.first_name"
              class="tw-flex tw-space-x-3 tw-items-center"
            >
              <span class="tw-bg-[#FFAF37] tw-p-2 tw-rounded-md">
                Waiting
              </span>
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1177_31)">
                    <path
                      d="M15.9942 0.439588C15.9942 0.439588 15.9942 0.388196 15.9942 0.359645C15.9771 0.325383 15.9599 0.291122 15.9371 0.256861C15.92 0.22831 15.8971 0.199759 15.8743 0.176918L15.7886 0.108396C15.7143 0.0570037 15.6286 0.0227425 15.5372 0.0113221C15.4687 -0.00580853 15.4001 -0.00580853 15.3316 0.0113221C15.303 0.0113221 15.2688 0.0113221 15.2402 0.0113221H15.2002L0.382648 5.15051C0.177007 5.22474 0.0342006 5.40176 0.00563934 5.61874C-0.0286341 5.84144 0.068474 6.06985 0.26269 6.18977L2.28483 7.49169V13.1448C2.28483 13.4589 2.54188 13.7158 2.85605 13.7158H8.5683L9.77358 15.7201C9.88211 15.8971 10.0763 16.0056 10.282 15.9999H10.3391C10.5619 15.9771 10.7504 15.8343 10.8246 15.623L15.9657 0.77649C15.9657 0.77649 15.9657 0.725098 15.9657 0.696547C15.9771 0.662286 15.9885 0.622314 15.9942 0.582343C15.9999 0.536661 15.9999 0.485269 15.9942 0.439588ZM1.87354 5.8757L13.4294 1.87856L6.76323 8.98206L3.17594 6.69797L1.87354 5.8757Z"
                      fill="#474747"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1177_31">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
            <div v-if="currentUser.kind === 'admin'" class="tw-cursor-pointer" @click="removeMember(member.id)">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1168_35)">
                  <g clip-path="url(#clip1_1168_35)">
                    <path
                      d="M8 15.2497C11.9246 15.2497 15.1111 12.113 15.1111 8.24973C15.1111 4.38643 11.9246 1.24973 8 1.24973C4.07538 1.24973 0.888885 4.38643 0.888885 8.24973C0.888885 12.113 4.07538 15.2497 8 15.2497ZM8 2.49023C11.2225 2.49023 13.8509 5.07757 13.8509 8.24973C13.8509 11.4219 11.2225 14.0092 8 14.0092C4.77749 14.0092 2.14908 11.4219 2.14908 8.24973C2.14908 5.07757 4.77749 2.49023 8 2.49023Z"
                      fill="#474747"
                    />
                    <path
                      d="M5.65962 8.95859H10.3404C10.6824 8.95859 10.9705 8.67504 10.9705 8.33833C10.9705 8.00162 10.6824 7.71808 10.3404 7.71808H5.65962C5.31757 7.71808 5.02953 8.00162 5.02953 8.33833C5.02953 8.67504 5.31757 8.95859 5.65962 8.95859Z"
                      fill="#474747"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_1168_35">
                    <rect
                      width="16"
                      height="15"
                      fill="white"
                      transform="translate(0 1)"
                    />
                  </clipPath>
                  <clipPath id="clip1_1168_35">
                    <rect
                      width="14.2222"
                      height="14"
                      fill="white"
                      transform="translate(0.888885 1.24973)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <st-modal
      v-if="addTeamMemberModal"
      v-model="addTeamMemberModal"
      size-class="large"
    >
      <h3 class="font-600">
        Add member
      </h3>
      <div class="st-team__add-members-modal-body">
        <template>
          <st-email-input
            ref="emailInput"
            invite-text="account"
            label="Type an email"
            :without-autocomplete="true"
            @addNewEmail="addInviteEmail"
            @removeEmail="removeInviteEmail"
          />

          <st-dropdown-menu
            caption="Departments"
            class="st-team__modal-dropdown"
            :display-label="true"
            :options="departmentNames"
            placeholder="Select as many as they apply"
            :select-multiple="true"
            @updateMultiple="setDepartments"
          />
          <st-dropdown-menu
            caption="Role"
            class="st-team__modal-dropdown"
            :display-label="true"
            :initial-active-option="newMemberRole"
            :options="['Member', 'Admin']"
            placeholder="Select one"
            @updateOption="setRole"
          />
        </template>
      </div>
      <div class="tw-my-2 tw-flex tw-justify-end">
        <ah-button @click="addMembers">
          Invite member
        </ah-button>
      </div>
    </st-modal>
    <st-modal
      v-if="removeTeamMemberModal"
      v-model="removeTeamMemberModal"
      size-class="large"
    >
      <h3 class="font-600">
        Remove member
      </h3>
      <div class="st-team__add-members-modal-body">
        <template>
          <p>
            Are you sure you want to remove this member?
          </p>
        </template>
      </div>
      <div class="tw-my-2 tw-flex tw-space-x-3 tw-justify-end">
        <ah-button type="outlined" @click="removeTeamMemberModal = false">
          Cancel
        </ah-button>
        <ah-button @click="removeMemberConfirmation">
          Remove
        </ah-button>
      </div>
    </st-modal>
  </div>
</template>

<script>
import { StModal, AhButton, StEmailInput, StDropdownMenu } from "../../common";
import { mapState } from "vuex";
export default {
  components: {
    StModal,
    AhButton,
    StEmailInput,
    StDropdownMenu,
  },
  data() {
    return {
      teamMembers: [],
      addTeamMemberModal: false,
      removeTeamMemberModal: false,
      newEmails: [],
      departmentNames: [],
      newMemberDepartments: [],
      newMemberRole: "Member",
      removeTeamMemberIds: [],
    };
  },

  computed: {
    ...mapState(["currentUser"]),
  },

  mounted() {
    this.getAllTeamMembers();
  },

  methods: {
    addInviteEmail(email) {
      this.newEmails.push(email);
    },
    removeInviteEmail(email) {
      let idx = this.newEmails.indexOf(email);
      this.newEmails.splice(idx, 1);
    },
    removeMember(id) {
      this.removeTeamMemberIds = [id];
      this.removeTeamMemberModal = true;
    },

    getAllTeamMembers() {
      this.$axios
        .request({
          method: "post",
          url: "accounts/team",
        })
        .then((response) => {
          // console.log(response)
          this.teamMembers = response.data.members;
          this.departmentNames = response.data.departments.map((department) => {
            return department.name;
          });
          // console.log(response)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setDepartments(departmentsArray) {
      this.newMemberDepartments = departmentsArray;
    },

    setRole(role) {
      this.newMemberRole = role;
    },
    removeMemberConfirmation() {
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "patch",
          url: "/users/deactivate",
          data: {
            ids: this.removeTeamMemberIds,
          },
        })
        .then((response) => {
          this.$store.commit("toggleFullSpinner", false);
          this.removeTeamMemberModal = false;
          this.getAllTeamMembers();
          this.removeTeamMemberIds = [];
        })
        .catch((error) => {
          this.$store.commit("toggleFullSpinner", false);
          console.log(error);
        });
    },
    addMembers() {
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "post",
          url: "/users/invite",
          data: {
            emails: this.newEmails.map((member) => member.email),
            kind: this.newMemberRole.toLowerCase(),
          },
        })
        .then((res) => {
          // console.log(res)
          this.$store.commit("toggleFullSpinner", false);
          this.addTeamMemberModal = false;
          this.teamMembers = this.teamMembers.concat(res.data);
        })
        .catch((err) => {
          this.$store.commit("toggleFullSpinner", false);
          console.error(err);
        });
    },
    showTeamMemberModal() {
      this.addTeamMemberModal = true;
    },
    hideTeamMemberModal() {
      this.addTeamMemberModal = false;
    },
    getNameCharacter(firstName, lastName) {
      return firstName.charAt(0) + lastName.charAt(0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
