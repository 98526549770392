<template>
  <div
    :class="containerClass"
    class="tw-rounded-full tw-flex tw-justify-center tw-items-center tw-px-1 tw-py-1"
    :style="{'background':colors[type]}"
  >
    <svg
      v-if="type==='Very Negative'"
      width="28"
      height="11"
      viewBox="0 0 28 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.72007 4.03002C6.72007 5.83002 5.26007 7.29002 3.46007 7.29002C1.66007 7.29002 0.200073 5.83002 0.200073 4.03002C0.200073 2.23002 1.66007 0.77002 3.46007 0.77002C5.26007 0.77002 6.72007 2.23002 6.72007 4.03002Z"
        fill="#202020"
      />
      <path
        d="M27.6401 4.03002C27.6401 5.83002 26.1801 7.29002 24.3801 7.29002C22.5801 7.29002 21.1201 5.83002 21.1201 4.03002C21.1201 2.23002 22.5801 0.77002 24.3801 0.77002C26.1801 0.77002 27.6401 2.23002 27.6401 4.03002Z"
        fill="#202020"
      />
      <path
        d="M8.28007 10.41C8.17007 10.41 8.05007 10.39 7.94007 10.35C7.42007 10.16 7.15007 9.59002 7.34007 9.07002C7.43007 8.83002 9.48007 3.28002 13.9201 3.28002C18.3601 3.28002 20.4101 8.83002 20.5001 9.07002C20.6901 9.59002 20.4201 10.16 19.9001 10.35C19.3801 10.54 18.8101 10.27 18.6201 9.75002C18.6001 9.71002 16.9601 5.28002 13.9201 5.28002C10.8801 5.28002 9.24007 9.70002 9.22007 9.75002C9.07007 10.16 8.69007 10.41 8.28007 10.41Z"
        fill="#202020"
      />
    </svg>

    <svg
      v-if="type==='Negative'"
      width="28"
      height="11"
      viewBox="0 0 28 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.81419 4.02996C6.81419 5.82996 5.35419 7.28996 3.55419 7.28996C1.75419 7.28996 0.294189 5.82996 0.294189 4.02996C0.294189 2.22996 1.75419 0.769958 3.55419 0.769958C5.35419 0.769958 6.81419 2.22996 6.81419 4.02996Z"
        fill="#202020"
      />
      <path
        d="M27.7242 4.02996C27.7242 5.82996 26.2642 7.28996 24.4642 7.28996C22.6642 7.28996 21.2042 5.82996 21.2042 4.02996C21.2042 2.22996 22.6642 0.769958 24.4642 0.769958C26.2642 0.769958 27.7242 2.22996 27.7242 4.02996Z"
        fill="#202020"
      />
      <path
        d="M19.6542 10.41C19.5142 10.41 19.3642 10.38 19.2342 10.32C19.2242 10.32 17.4942 9.54996 14.0242 9.54996C10.5542 9.54996 8.82419 10.32 8.80419 10.32C8.30419 10.55 7.71419 10.32 7.48419 9.82996C7.25419 9.32996 7.46419 8.73996 7.96419 8.50996C8.04419 8.46996 10.0642 7.54996 14.0242 7.54996C17.9842 7.54996 20.0042 8.46996 20.0842 8.50996C20.5842 8.73996 20.8042 9.33996 20.5642 9.83996C20.3942 10.2 20.0342 10.42 19.6542 10.42V10.41Z"
        fill="#202020"
      />
    </svg>

    <svg
      v-if="type==='Neutral'"
      width="28"
      height="11"
      viewBox="0 0 28 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.81419 4.03002C6.81419 5.83002 5.3542 7.29002 3.5542 7.29002C1.7542 7.29002 0.294189 5.83002 0.294189 4.03002C0.294189 2.23002 1.7542 0.77002 3.5542 0.77002C5.3542 0.77002 6.81419 2.23002 6.81419 4.03002Z"
        fill="#202020"
      />
      <path
        d="M27.7242 4.03002C27.7242 5.83002 26.2642 7.29002 24.4642 7.29002C22.6642 7.29002 21.2042 5.83002 21.2042 4.03002C21.2042 2.23002 22.6642 0.77002 24.4642 0.77002C26.2642 0.77002 27.7242 2.23002 27.7242 4.03002Z"
        fill="#202020"
      />
      <path
        d="M14.0142 10.55C10.2342 10.55 8.38418 10.42 8.30418 10.41C7.75418 10.37 7.34416 9.89002 7.38416 9.34002C7.42416 8.79002 7.90417 8.37002 8.45417 8.42002C8.47417 8.42002 10.3242 8.55002 14.0242 8.55002C17.7242 8.55002 19.5742 8.42002 19.5842 8.42002C20.1242 8.38002 20.6142 8.79002 20.6542 9.34002C20.6942 9.89002 20.2842 10.37 19.7342 10.41C19.6542 10.41 17.8042 10.55 14.0242 10.55H14.0142Z"
        fill="#202020"
      />
    </svg>

    <svg
      v-if="type==='Positive'"
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.81419 4.02996C6.81419 5.82996 5.35418 7.28996 3.55418 7.28996C1.75418 7.28996 0.294189 5.82996 0.294189 4.02996C0.294189 2.22996 1.75418 0.769958 3.55418 0.769958C5.35418 0.769958 6.81419 2.22996 6.81419 4.02996Z"
        fill="#202020"
      />
      <path
        d="M27.7342 4.02996C27.7342 5.82996 26.2742 7.28996 24.4742 7.28996C22.6742 7.28996 21.2142 5.82996 21.2142 4.02996C21.2142 2.22996 22.6742 0.769958 24.4742 0.769958C26.2742 0.769958 27.7342 2.22996 27.7342 4.02996Z"
        fill="#202020"
      />
      <path
        d="M14.0142 15.55C9.57421 15.55 7.5242 9.98996 7.4342 9.75996C7.2442 9.23996 7.51421 8.66996 8.03421 8.47996C8.55421 8.28996 9.12421 8.55996 9.31421 9.07996C9.33421 9.11996 10.9742 13.55 14.0142 13.55C17.0542 13.55 18.6942 9.11996 18.7142 9.07996C18.9042 8.55996 19.4742 8.28996 19.9942 8.47996C20.5142 8.66996 20.7842 9.23996 20.5942 9.75996C20.5042 9.99996 18.4542 15.55 14.0142 15.55Z"
        fill="#202020"
      />
    </svg>

    <svg
      v-if="type==='Very Positive'"
      width="28"
      height="15"
      viewBox="0 0 28 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.8143 4.02996C6.8143 5.82996 5.35432 7.28996 3.55432 7.28996C1.75432 7.28996 0.294312 5.82996 0.294312 4.02996C0.294312 2.22996 1.75432 0.769958 3.55432 0.769958C5.35432 0.769958 6.8143 2.22996 6.8143 4.02996Z"
        fill="#202020"
      />
      <path
        d="M27.7343 4.02996C27.7343 5.82996 26.2743 7.28996 24.4743 7.28996C22.6743 7.28996 21.2143 5.82996 21.2143 4.02996C21.2143 2.22996 22.6743 0.769958 24.4743 0.769958C26.2743 0.769958 27.7343 2.22996 27.7343 4.02996Z"
        fill="#202020"
      />
      <path
        d="M6.21432 9.49996C6.21432 9.49996 8.77432 14.45 14.0243 14.45C19.2743 14.45 21.8343 9.49996 21.8343 9.49996"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    containerClass: {
      type: String,
      default: 'tw-w-12 tw-h-12'
    }

  },
  data () {
    return {
      colors: {
        'Very Negative': '#AC5555',
        'Negative': '#F9776F',
        'Neutral': '#E9E9E9',
        'Positive': '#7ECD87',
        'Very Positive': '#667E6E'
      }
    }
  },

  mounted () {

  },

  methods: {

  }
}
</script>
