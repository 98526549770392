<template>
  <div class="tw-flex  tw-justify-between tw-w-full">
    <p class="tw-flex tw-flex-col">
      <span class="tw-text-sm">
        Template name
      </span>
      <span class="tw-font-semibold">
        {{ survey.name || '[Survey Name]' }}
      </span>
    </p>
    <p class="tw-flex tw-flex-col">
      <span class="tw-text-sm">
        {{ survey.anonymous? 'Anonymous respondents': 'Named respondents' }}
      </span>
      <span class="tw-text-sm">
        with {{ survey.insightType ? 'Public' : 'Private' }} insights
      </span>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {

  },
  props: {
    survey: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      currentStep: 1
    }
  },
  computed: {
    ...mapState(['currentUser'])
  },
  mounted () {
    // console.log('survey', this.survey)
    // setTimeout(() => {
    //   console.log(JSON.stringify(this.currentUser))
    // }, 100)
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
