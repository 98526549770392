<template>
  <div>
    <preview-bar
      :survey-id="survey.id"
      :show-preview="true"
    />
    <div class="tw-flex">
      <study-sidebar
        :current-step="5"
        :survey-id="survey.id"
        :survey="survey"
      />
      <div
        class="tw-flex tw-flex-col tw-mt-8 scrollbar-thumb-dark-gray-400 tw-px-20 tw-w-full tw-max-h-[87vh] tw-overflow-auto scrollbar-hover-black scrollbar-thin scrollbar-track-gray-400"
      >
        <div class="tw-mx-auto tw-w-full tw-max-w-7xl">
          <study-information :survey="survey" />
          <template v-if="!survey.account.newed">
            <div class="tw-mx-auto tw-max-w-[550px]">
              <div class="tw-mt-10">
                <text-divider
                  text="How many responses per month
            you would like to get insights?"
                />
              </div>
              <div class="tw-mx-auto tw-mt-10 tw-max-w-[380px]">
                <div
                  class="tw-border-white-two tw-mx-auto tw-my-4 tw-border focus-within:tw-border focus-within:tw-border-black tw-border-solid tw-rounded-xl tw-w-fit tw-text-[40px]"
                >
                  <input
                    :value="responsesPerMonthLabel"
                    type="text"
                    class="tw-p-4 tw-border hover:tw-border focus:tw-border tw-border-transparent hover:tw-border-black focus:tw-border-black hover:tw-border-solid !tw-rounded-xl tw-outline-none tw-w-[130px] tw-font-semibold tw-text-[40px] tw-text-black-two tw-text-center"
                    @input="changeResponsesPerMonthValue($event.target.value)"
                  >
                </div>
                <div>
                  <input
                    id="customRange1"
                    v-model="responsesPerMonth"
                    type="range"
                    min="0"
                    step="5"
                    :max="sliderMaxVal"
                    class="tw-border-transparent tw-accent-primary tw-h-[3px] tw-transparent tw-w-full tw-cursor-pointer slider"
                    @input="changeResponsesPerMonth($event.target.value)"
                  >
                </div>
                <div class="tw-flex tw-space-x-2 tw-mt-5">
                  <span
                    v-for="(_, bucketIndex) in pricingBuckets"
                    :key="`${bucketIndex}-pricing-bucket`"
                    class="hover:tw-bg-black-three tw-rounded-lg tw-h-1 tw-w-1/4 hover:tw-h-2 tw-transition-colors tw-duration-100 tw-cursor-pointer"
                    :class="
                      selectedPricingBucketIndex === bucketIndex
                        ? 'tw-h-2 tw-bg-black-three'
                        : 'tw-bg-territory'
                    "
                    @click="
                      changeResponsesPerMonth(
                        bucketIndex * (sliderMaxVal / plans.length)
                      )
                    "
                  />
                </div>
              </div>
              <div v-if="selectedPlan">
                <div class="tw-mt-16 tw-w-full">
                  <p class="tw-text-center">
                    Based on your selection, you are choosing the following plan:
                  </p>
                  <p
                    v-if="error"
                    class="tw-text-error"
                  >
                    {{ error }}
                  </p>
                </div>
                <div
                  class="tw-border-white-two tw-mt-5 tw-p-5 tw-border tw-border-solid tw-rounded-xl"
                >
                  <div
                    class="tw-flex tw-justify-between tw-items-center tw-text-territory-dark"
                  >
                    <span class="tw-font-semibold tw-text-2xl">
                      {{ selectedPlan.title }}
                    </span>
                    <span class="tw-font-semibold tw-text-lg">
                      ${{ selectedPlan.price.toLocaleString() }}
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      Responses per month
                    </span>
                    <span class="tw-font-semibold">
                      {{ selectedPlan.allow_responses.toLocaleString() }}
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      QR Code & Short URL
                    </span>
                    <span class="tw-font-semibold">
                      Included
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      Widget integration
                    </span>
                    <span class="tw-font-semibold">
                      Included
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      Rewards & Gift cards
                    </span>
                    <span class="tw-font-semibold">
                      Included
                    </span>
                  </div>
                </div>
                <div class="tw-mt-5 tw-text-center">
                  Need a higher plan?
                  <span
                    class="tw-text-primary hover:tw-underline tw-cursor-pointer"
                    @click="showEnterprisePlanModalHandler"
                  >Click here</span>
                  and we will contact you for our enterprise plans
                </div>
                <div
                  class="tw-border-white-two tw-mt-5 tw-p-5 tw-border tw-border-solid tw-rounded-xl"
                >
                  <div
                    class="tw-flex tw-justify-between tw-items-center tw-text-territory-dark"
                  >
                    <span class="tw-text-xl">
                      To be charged
                    </span>
                    <span
                      v-if="!paymentInformation"
                      class="tw-text-primary hover:tw-underline tw-cursor-pointer"
                      @click="paymentMethodChangeHandler('add')"
                    >
                      Add payment method
                    </span>
                    <span
                      v-else
                      class="tw-text-primary hover:tw-underline tw-cursor-pointer"
                      @click="paymentMethodChangeHandler('update')"
                    >
                      Update payment method
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      Payment method
                    </span>
                    <span
                      v-if="!paymentInformation"
                      class=""
                    >
                      No payment method added
                    </span>
                    <span v-else>
                      {{ paymentInformation.brand }} ending in
                      {{ paymentInformation.last4 }}
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      Credit balance
                    </span>
                    <span class="tw-font-semibold">
                      {{
                        creditBalance >= 0
                          ? `$${creditBalance.toLocaleString()}`
                          : "Calculating..."
                      }}
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span> Plan "{{ selectedPlan.title }}" </span>
                    <span class="tw-font-semibold">
                      {{
                        currentProratedPrice >= 0
                          ? `$${currentProratedPrice.toLocaleString()}`
                          : "Calculating..."
                      }}
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      Incentive budget
                    </span>
                    <span class="tw-font-semibold">
                      ${{ getIncentiveBudget.toLocaleString() }}
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      Remaining Credit balance
                    </span>
                    <span class="tw-font-semibold">
                      {{
                        currentRemainingCreditBalance >= 0
                          ? `$${currentRemainingCreditBalance.toLocaleString()}`
                          : "Calculating..."
                      }}
                    </span>
                  </div>
                  <div
                    class="tw-flex tw-justify-between tw-mt-3 tw-text-black-two tw-text-sm"
                  >
                    <span>
                      Total
                    </span>
                    <span class="tw-font-semibold tw-text-lg">
                      {{
                        currentAmountDue >= 0
                          ? `$${currentAmountDue.toLocaleString()}`
                          : "Calculating..."
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="tw-flex tw-items-center tw-space-x-3 tw-mt-5">
                <ah-toggle-switch
                  id="saveAsTemplate"
                  :checked="saveAsTemplate"
                  @input="toggleSaveAsTemplate"
                />
                <span>
                  Save this configuration as template
                </span>
              </div>
              <div
                class="tw-flex tw-justify-between tw-space-x-2 tw-mt-8 tw-mb-3 tw-w-full"
              >
                <div
                  v-if="checkCreditBalance || paymentInformation"
                  class="tw-flex tw-justify-between tw-space-x-2 tw-w-full"
                >
                  <button
                    class="tw-bg-primary-light tw-p-4 tw-rounded-xl tw-text-primary-dark"
                    @click="redirectToHome"
                  >
                    Close & save as draft
                  </button>
                  <button
                    class="tw-bg-positive disabled:tw-opacity-50 tw-p-4 tw-rounded-xl tw-text-white disabled:tw-cursor-not-allowed"
                    :disabled="!canPublishStudy"
                    @click="publishStudy"
                  >
                    Publish Study
                  </button>
                </div>
                <div
                  v-else
                  class="tw-w-full"
                >
                  <button
                    class="tw-bg-[#F4DDDD] tw-p-4 tw-rounded-xl tw-w-full tw-text-error"
                    @click="paymentMethodChangeHandler('add')"
                  >
                    Add payment method
                  </button>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="tw-mx-auto tw-mt-32 tw-h-full tw-max-w-[550px]">
              <template v-if="isAccountExpired">
                <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
                  <p class="tw-font-semibold tw-text-[24px] tw-text-center">
                    Plan has expired
                  </p>
                  <p class="tw-mt-5">
                    Your plan has expired on {{ getExpiryDate }}.
                  </p>
                  <p class="mt-1">
                    Contact us to renew your account in order to publish the study.
                  </p>
                  <a
                    class="tw-mt-5 tw-text-primary"
                    href="mailto:zak@askhumans.com?subject=Credits%20Required&body=Hello%20Zak,%0D%0A%0D%0AI%20need%20more%20credits%20for%20my%20project.%0D%0A%0D%0AThank%20you,%0D%0A[Your%20Name]"
                  >      Get more credits
                  </a>
                </div>
              </template>
              <template v-else>
                <p class="tw-font-semibold tw-text-[24px] tw-text-center">
                  Plan summary
                </p>
                <div class="tw-flex tw-flex-col tw-space-y-2 tw-mt-5">
                  <div class="tw-flex tw-justify-between">
                    <p>
                      Plan expiration date
                    </p>
                    <p class="tw-font-semibold tw-text-black">
                      {{ getExpiryDate }}
                    </p>
                  </div>
                  <div class="tw-flex tw-justify-between">
                    <p>
                      Respondents number
                    </p>
                    <p class="tw-font-semibold">
                      Unlimited
                    </p>
                  </div>
                </div>
                <div class="tw-flex tw-items-center tw-space-x-3 tw-mt-5">
                  <ah-toggle-switch
                    id="saveAsTemplate"
                    :checked="saveAsTemplate"
                    @input="toggleSaveAsTemplate"
                  />
                  <span>
                    Save this configuration as template
                  </span>
                </div>
                <div

                  class="tw-flex tw-justify-between tw-space-x-2 tw-mt-10 tw-w-full"
                >
                  <button
                    class="tw-bg-primary-light tw-p-4 tw-rounded-xl tw-text-primary-dark"
                    @click="redirectToHome"
                  >
                    Close & save as draft
                  </button>
                  <button
                    class="tw-bg-positive disabled:tw-opacity-50 tw-p-4 tw-rounded-xl tw-text-white disabled:tw-cursor-not-allowed"
                    :disabled="!canPublishStudy"
                    @click="publishStudy"
                  >
                    Publish Study
                  </button>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <st-modal
      v-model="displayPublishConfirmationModal"
      :cancel-modal="true"
      :non-exit-modal="true"
      @closeModal="displayPublishConfirmationModal = false"
    >
      <template v-slot:modalTitle>
        Publish Study
      </template>
      <template v-slot:modalActions>
        <div class="st-publish-options-modal__instructions-container">
          <p class="tw-mt-5 tw-text-black-three">
            You are about to publish “{{ survey.name }}”. Once published you
            won’t be able to edit the content.
          </p>
          <p class="tw-text-black-three">
            Are you sure you want to publish right now?
          </p>
        </div>
      </template>
      <template v-slot:modalButton>
        <!-- :disabled="disableButton" -->
        <ah-button @click="confirmPublish">
          Publish
        </ah-button>
      </template>
    </st-modal>

    <st-modal v-model="showEnterprisePlanModal">
      <div>
        <p class="font-18p font-600">
          Request Enterprise solution
        </p>
        <p class="mt-4 tw-text-black-three">
          Our Enterprise plans are designed to fit the needs of our customers.
          If the studio you are creating needs specific qualities, you are in
          the right place.
        </p>
        <p class="mt-4 tw-text-black-three">
          If you wish to proceed, just click on "Request" and one of our
          representatives will be contacting you.
        </p>
        <p class="mt-4 tw-text-black-three">
          You will not be charged any fees at this time.
        </p>
      </div>
      <div class="flex justify-end space-x-2 mt-10">
        <ah-button
          type="outlined"
          @click="showEnterprisePlanModal = false"
        >
          Cancel
        </ah-button>
        <ah-button @click="addRemoveIncentives(false)">
          Request
        </ah-button>
      </div>
    </st-modal>
    <stripe-payment-form
      ref="stripeForm"
      :type="paymentType"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isDesktop } from '../../../mixins'
import isEditingDisabled from './isEditingDisabled'
import saveUpdates from './saveUpdates'
import {
  TextDivider,
  StripePaymentForm,
  AhToggleSwitch,
  StModal,
  AhButton
} from '../../common'

import PreviewBar from './preview-bar.vue'
import StudySidebar from './study-sidebar.vue'
import StudyInformation from './study-information.vue'
export default {
  components: {
    TextDivider,
    StudySidebar,
    StudyInformation,
    StripePaymentForm,
    PreviewBar,
    AhToggleSwitch,
    StModal,
    AhButton
  },
  mixins: [isDesktop, isEditingDisabled, saveUpdates],
  props: {
    survey: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      responsesPerMonth: 50,
      plans: [],
      error: null,
      selectedPlan: null,
      paymentInformation: null,
      displayPublishConfirmationModal: false,
      saveAsTemplate: false,
      showEnterprisePlanModal: false,
      pricingBuckets: [],
      sliderMaxVal: 1000,
      proratedPrices: {},
      remainingCreditBalance: {},
      amountDue: {},
      paymentType: 'add',

      // widget configuration
      widgetPositions: [
        'bottom_right',
        'bottom_center',
        'bottom_left',
        'top_right',
        'top_center',
        'top_left'
      ],
      selectedWidgetPosition: 'bottom_right',
      selectedFilterOption: '5',
      backgroundColorDefault: '#FFFFFF',
      textColorDefault: '#000000',
      primaryButtonBackgroundColorDefault: '#FFD28D',
      primaryButtonTextColorDefault: '#000000',
      secondaryButtonBackgroundColorDefault: '#DEDBD7',
      secondaryButtonTextColorDefault: '#000000',
      playButtonBackgroundColorDefault: '#328AAF',
      playButtonTextColorDefault: '#FFFFFF',
      videoPlaying: false,
      labelValue: null,
      videoURL: null
    }
  },
  computed: {
    ...mapState('stripe', ['creditBalance']),
    getExpiryDate () {
      // return new Date(this.survey.account.expiry_date).toLocaleDateString()
      const date = new Date(this.survey.account.trialEndsAt)
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },

    isAccountExpired () {
      return new Date(this.survey.account.trialEndsAt) < new Date()
    },

    getIncentiveBudget () {
      if (this.survey.reward && this.survey.rewardType === 'gift card') {
        return this.survey.incentiveAmount * this.survey.totalRespondents
      } else {
        return 0
      }
    },
    isIncentiveCompleted () {
      if (
        this.survey.reward &&
        this.survey.rewardType == 'gift card' &&
        !this.survey.incentiveAmount
      ) {
        return false
      }
      if (
        this.survey.reward &&
        this.survey.rewardType == 'coupon' &&
        (!this.survey.couponValue ||
          !this.survey.couponTitle ||
          !this.survey.couponCode)
      ) {
        // console.log('coupon', this.survey.couponValue, this.couponTitle)
        return false
      } else {
        return true
      }
    },

    isSurveyQuestionCompleted () {
      if (
        this.survey.questions.filter(
          (question) => question.questionType === 'opinion_metric'
        ).length === 0
      ) {
        return false
      } else {
        const questions = this.survey.questions.filter(
          (question) => question.questionType === 'opinion_metric'
        )
        if (
          questions &&
          questions.every(
            (question) => question.description && question.askForExplanation
          )
        ) { return true } else return false
      }
    },

    canPublishStudy () {
      if (!this.survey.headline && !this.survey.introVideoUrl) {
        return false
      } else {
        if (!this.isIncentiveCompleted) {
          return false
        }
        if (!this.isSurveyQuestionCompleted) {
          return false
        } else {
          return true
        }
      }
    },
    selectedPricingBucketIndex () {
      let selectedBucketIndex = parseInt(
        this.responsesPerMonth / (this.sliderMaxVal / (this.plans.length || 1))
      )
      if (selectedBucketIndex === this.plans.length) selectedBucketIndex -= 1
      return selectedBucketIndex
    },
    responsesPerMonthLabel: {
      get () {
        if (!this.plans.length) return 0
        if (this.labelValue) {
          return this.labelValue
        }

        const pricingBucket = this.pricingBuckets[
          this.selectedPricingBucketIndex
        ]
        const bucketLength = this.sliderMaxVal / this.plans.length
        const fMin = bucketLength * this.selectedPricingBucketIndex
        const fMax = fMin + bucketLength
        const scaledResponses =
          ((this.responsesPerMonth - fMin) / (fMax - fMin)) *
            (pricingBucket.max - pricingBucket.min) +
          pricingBucket.min
        return parseInt(scaledResponses)
      },
      set (value) {
        const newPricingBucketAccordingToValue = this.pricingBuckets.find(
          (bucket) => {
            return bucket.min <= value && bucket.max >= value
          }
        )
        const newBucketIndex = this.pricingBuckets.indexOf(
          newPricingBucketAccordingToValue
        )
        this.labelValue = value
        this.selectedPlan = this.plans[newBucketIndex]

        if (newBucketIndex == 0) {
          this.responsesPerMonth = this.sliderMaxVal / this.plans.length - 50
        } else if (newBucketIndex == 1) {
          this.responsesPerMonth =
            this.sliderMaxVal / (newBucketIndex + 1) - 10
        } else if (newBucketIndex == 2) {
          this.responsesPerMonth = this.sliderMaxVal / newBucketIndex
        } else {
          this.responsesPerMonth = this.sliderMaxVal
        }
        // this.responsesPerMonth = this.plans[newBucketIndex].allow_responses
        // console.log(this.plans[newBucketIndex])
        // this.responsesPerMonth = this.selectedPricingBucketIndex * pricingBucket.max / this.plans.length
        // this.responsesPerMonth = this.selectedPricingBucketIndex * (this.sliderMaxVal / this.plans.length)
        // const fMin = bucketLength * this.selectedPricingBucketIndex
        // const fMax = fMin + bucketLength
        // const scaledResponses = ((value - fMin) / (fMax - fMin)) * (pricingBucket.max - pricingBucket.min) + pricingBucket.min
        // console.log(scaledResponses)
        // You can add the logic here to update this.responsesPerMonth when the input changes
        // For example, parse the value and update this.responsesPerMonth accordingly
        // this.responsesPerMonth = parseInt(value * 3.5)
      }
    },
    // responsesPerMonthLabel () {
    //   if (!this.plans.length) return 0
    //   const pricingBucket = this.pricingBuckets[this.selectedPricingBucketIndex]
    //   const bucketLength = this.sliderMaxVal / this.plans.length
    //   const fMin = bucketLength * this.selectedPricingBucketIndex
    //   const fMax = fMin + bucketLength
    //   const scaledResponses = ((this.responsesPerMonth - fMin) / (fMax - fMin)) * (pricingBucket.max - pricingBucket.min) + pricingBucket.min
    //   return parseInt(scaledResponses)
    // },
    currentProratedPrice () {
      const pp = this.plans[this.selectedPricingBucketIndex]
      if (pp) {
        const currentPlanCharges = this.proratedPrices[
          pp.id
        ]
        return currentPlanCharges
      }
    },
    // currentProratedTotal () {
    //   const currentPlanCharges = this.proratedPrices[this.plans[this.selectedPricingBucketIndex].id]
    //   return currentPlanCharges + this.getIncentiveBudget
    // },
    currentRemainingCreditBalance () {
      const remainingCreditBalance = this.remainingCreditBalance[
        this.plans[this.selectedPricingBucketIndex].id
      ]
      if (remainingCreditBalance > 0) {
        return remainingCreditBalance - this.getIncentiveBudget < 0
          ? 0
          : remainingCreditBalance - this.getIncentiveBudget
      }
      return remainingCreditBalance

      // return this.remainingCreditBalance[
      //   this.plans[this.selectedPricingBucketIndex].id
      // ];
    },
    currentAmountDue () {
      return this.totalAmount()
      // return this.amountDue[this.plans[this.selectedPricingBucketIndex].id] + this.getIncentiveBudget;
    },
    checkCreditBalance () {
      return !(this.totalAmount() > 0)
    }
  },

  mounted () {
    console.log(this.survey)
    setTimeout(() => {
      this.$store.dispatch('stripe/fetchCreditBalance', this.user.accountId)
    }, 500)

    this.$store.commit('toggleFullSpinner', true)
    setTimeout(() => {
      this.checkPaymentInformation()
      this.$axios
        .get(`/plans`)
        .then((res) => {
          this.$store.commit('toggleFullSpinner', false)
          if (res.status === 200) {
            this.$store.commit('toggleFullSpinner', false)
            const plansWithResponses = res.data
              .filter((plan) => 'allow_responses' in plan)
              .sort(
                (plan_1, plan_2) =>
                  plan_1.allow_responses - plan_2.allow_responses
              )
            this.plans = plansWithResponses
            this.selectedPlan = this.plans[0]
            this.pricingBuckets = this.plans.map((plan, index) => {
              const min =
                index === 0 ? 0 : this.plans[index - 1].allow_responses + 1
              const max = plan.allow_responses
              return { min, max }
            })
            this.populateProratedCharges()
            console.log(this.plans)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }, 200)
  },
  methods: {
    totalAmount () {
      const total =
        this.creditBalance -
        (this.getIncentiveBudget + this.currentProratedPrice)

      return total > 0 ? 0 : Math.abs(total)
    },
    confirmPublish () {
      let data = {}
      if (this.survey.account.newed) {
        data = {
          plan_id: this.plans.find(plan => plan.plan_type === 'free').id
        }
        console.log(data, this.plans)
      } else {
        data = {
          plan_id: this.selectedPlan.id
          // survey_id: this.survey.id
        }
      }
      if (
        this.survey.reward &&
        this.survey.rewardType === 'gift card' &&
        this.survey.incentiveAmount
      ) {
        data.incentive_amount =
          Number(this.survey.incentiveAmount) * this.survey.totalRespondents
      }
      this.$store.commit('toggleFullSpinner', true)
      this.displayPublishConfirmationModal = false
      // this.$axios
      //   .request({
      //     method: 'post',
      //     url: `/payments/create_subscription`,
      //     data: {
      //       ...data
      //     }
      //   })
      //   .then((res) => {
      //     if (res.status === 200) {
      let url = `/study/${this.survey.id}/publish_survey`
      this.$axios
        .request({
          url: url,

          method: 'post',
          data: { ...data, survey: this.survey }
        })
        .then((res) => {
          this.$store.commit('toggleFullSpinner', false)
          window.location = `/study/${this.survey.id}/final-step`
        })
        .catch((err) => {
          console.error(err)
          this.$store.commit('toggleFullSpinner', false)
          window.location = `/study/${this.survey.id}/final-step`
          // this.error = err.message
        })
      // }
      // })
      // .catch((err) => {
      //   console.log(err)
      //   this.error = err.message
      // })
    },
    redirectToHome () {
      window.location.href = '/welcome'
    },

    updateSurvey () {
      return this.$axios
        .request({
          method: 'patch',
          url: `study/${this.survey.id}`,
          data: {
            survey: {
              is_template: this.saveAsTemplate
            }
          }
        })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleSave () {
      if (this.survey.widgetConfig) {
        return
      }
      const data = {
        survey_id: this.survey.id,
        show_time: Number(this.selectedFilterOption),
        position: this.selectedWidgetPosition,
        background_color: this.backgroundColorDefault,
        text_color: this.textColorDefault,
        primary_button_background_color: this
          .primaryButtonBackgroundColorDefault,
        primary_button_text_color: this.primaryButtonTextColorDefault,
        secondary_button_background_color: this
          .secondaryButtonBackgroundColorDefault,
        secondary_button_text_color: this.secondaryButtonTextColorDefault,
        play_button_background_color: this.playButtonBackgroundColorDefault,
        play_button_text_color: this.playButtonTextColorDefault
      }

      const method = 'post'
      this.$axios
        .request({
          method,
          url: `/study/${this.survey.id}/widget`,
          data
        })
        .then(({ data }) => {
          // window.location = `/study/${this.survey.id}/edit/${link}`;
        })
        .catch((err) => {
          console.log(err)
          // this.$store.commit("toggleFullSpinner", false);
        })
    },
    publishStudy () {
      this.displayPublishConfirmationModal = true
      this.handleSave()
    },
    paymentMethodChangeHandler (type) {
      this.paymentType = type
      // if (!this.useExistingPayment) {
      this.$refs.stripeForm.toggle()
      // }
    },
    showEnterprisePlanModalHandler () {
      this.showEnterprisePlanModal = true
    },
    changeResponsesPerMonth (value) {
      // console.log(value, this.responsesPerMonth)
      this.responsesPerMonth = value
      this.labelValue = null
      this.selectedPlan = this.plans[this.selectedPricingBucketIndex]
    },
    changeResponsesPerMonthValue (value) {
      // console.log(value, this.responsesPerMonth)
      // if (value > 0 && value < 250) {
      //   this.responsesPerMonth = this.responsesPerMonth * 5
      //   this.selectedPlan = this.plans[this.selectedPricingBucketIndex]
      // }
      // this.responsesPerMonth = value
      // this.responsesPerMonth = value
      // console.log(this.responsesPerMonth)
      // this.selectedPlan = this.plans[this.selectedPricingBucketIndex]
    },
    toggleSaveAsTemplate () {
      this.saveAsTemplate = !this.saveAsTemplate
      this.updateSurvey()
    },
    checkPaymentInformation () {
      this.$axios
        .request({
          method: 'get',
          url: `payments/payment_information`
        })
        .then((res) => {
          // console.log(res)
          const info = {}
          info.brand = res.data.data.brand
          info.last4 = res.data.data.last4
          info.expireYear = res.data.data.exp_year
          info.expireMonth = res.data.data.exp_month
          this.paymentInformation = { ...info }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    populateProratedCharges () {
      this.plans.forEach((plan) => {
        this.$axios
          .request({
            method: 'post',
            url: 'payments/calculate_upcoming_invoice',
            data: {
              plan_id: [plan.id]
            }
          })
          .then((res) => {
            this.proratedPrices[plan.id] =
              res.data.upcoming_invoice.prorated_price
            this.responsesPerMonth = this.responsesPerMonth + 1

            this.remainingCreditBalance[plan.id] =
              res.data.upcoming_invoice.remaining_credit_balance
            this.amountDue[plan.id] = res.data.upcoming_invoice.amount_due
          })
          .catch((err) => {
            console.log(err)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #e0dddd;
  outline: none;
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  border: 3px solid white;
  height: 25px;
  border-radius: 100%;
  background: #be6744;
  cursor: pointer;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #be6744;
  border-radius: 100%;
  cursor: pointer;
}
</style>
