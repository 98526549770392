<template>
  <div
    class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center
  tw-h-screen"
  >
    <div class="tw-max-w-md tw-w-full tw-px-5  tw-space-y-8">
      <div>
        <h2 class="tw-text-center tw-font-semibold tw-text-4xl">
          Quota Exceeded
        </h2>
        <p class="tw-mt-5 text-center tw-text-gray-800">
          Sorry, you have exceeded your quota for this month. Please upgrade your account to continue using the service.
        </p>
      </div>
      <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
        <div class="tw-mx-auto tw-flex tw-justify-center tw-items-center tw-space-x-2">
          <span>
            If you are admin, consider
          </span>
          <a
            href="/"
            class="tw-text-primary"
          >
            Upgrading account
          </a>
        </div>
      </div>
      <div class="tw-mx-auto tw-flex tw-justify-center tw-items-center tw-space-x-3">
        <p class="tw-text-center">
          If you are not admin, please inform admin to upgrade the account.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { AhInput, AhButton, StCheckbox } from '../common'
export default {
  components: {
    AhInput,
    AhButton,
    StCheckbox

  }

}
</script>

<style>

</style>
