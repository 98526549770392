<template>
  <div class="relative">

    <div class="tw-fixed tw-top-[7%] tw-w-full tw-px-5 tw-py-5 tw-bg-primary tw-text-white tw-z-[999]" v-if="showNotification">
      <p class="tw-text-center tw-text-3xl">
        The report will be sent to your email address in few minutes.
      </p>
    </div>

    <div class="tw-px-4 tw-py-5 md:tw-px-16 md:tw-py-8">
      <div class="">
        <label class="py-1 block">Report name *</label>
        <input
          v-model="reportName"
          class="block px-4 py-3 rounded-4p filter-input tw-w-full md:tw-w-[400px]"
          type="text"
          placeholder="Name of the study"
        >
      </div>
      <div class="my-4">
        <label class="py-1 block">Set date range</label>
        
        <VueHotelDatepicker
          format="MM-DD-YYYY"
          min-date="10-10-2022"
          class="block   filter-input tw-w-full md:tw-w-[400px]"
          :start-date="setInitialDate[0]"
          :max-date="getTodayDate"
          :end-date="setInitialDate[1]"
          @update="updateDateRangeHandler"
        />
      </div>
      <div class="my-4 ">
        <label class="py-1 block">Show segments</label>
        <st-dropdown-menu
          container-class="h-40p  border-none rounded-4p border-black"
          selected-class="rounded-4p w border-gray-important"
          :display-caption="true"
          :initial-active-option="yesNoOptions[0]"
          :options="yesNoOptions"
          :class="{ '': true }"
          @updateOption="showSegmentHandler"
        />
      </div>
      <div
        v-if="showSegments"
        class="flex  space-x-3"
      >
        <st-checkbox
          v-for="segment in survey.segments"
          :key="'segments-' + segment.name"
          :checked="checkIfSegmentsIncluded.includes(segment.name)"
          class="st-survey-insights__checkbox"
          @input="filterSegments(segment)"
        >
          {{ segment.name }}
        </st-checkbox>
      </div>
      <div class="my-4">
        <label class="py-1 block">Include sentiment graph</label>
        <st-dropdown-menu
          container-class="h-40p  border-none rounded-4p border-black"
          selected-class="rounded-4p w border-gray-important"
          :display-caption="true"
          :initial-active-option="yesNoOptions[0]"
          :options="yesNoOptions"
          :class="{ '': true }"
          @updateOption="includeSentimentHandler"
        />
      </div>
      <div v-if="includeSentimentGraph">
        <div>
          <p class="py-1 block">
            Select graph to include
          </p>

          <input
            id="total"
            v-model="includeGraphType"
            type="radio"
            value="TOTAL"
            checked
            name="includeGraphType"
          >
          <label html-for="total">Total sentiment</label>
          <input
            id="stack"
            v-model="includeGraphType"
            type="radio"
            value="STACK"
            name="includeGraphType"
          >
          <label html-for="total">Stack</label>
          <input
            id="trendline"
            v-model="includeGraphType"
            type="radio"
            value="STACKTRNEDLINE"
            name="includeGraphType"
          >
          <label html-for="trendline">Stack + Trend line</label>
        </div>
        <div class="my-5" />
        <div
          :class="[
            {
              'my st-opinion-metric-insights__overall-sentiment-container w-full': true,
            },
            sentimentColor,
          ]"
        >
          <p
            class="st-opinion-metric-insights__sentiment st-opinion-metric-insights__sentiment--title"
            :class="{'text-black-important': overallScoreBucket=='Neutral'}"
          >
            Average Answer:
          </p>
          <p
            class="st-opinion-metric-insights__sentiment "
            :class="{'text-black-important': overallScoreBucket=='Neutral'}"
          >
            {{ overallScoreBucket }}
          </p>
        </div>
        <bar-graph-stack-pdf
          :responses="responsesFilteredByRange"
          :window="selectedFilterOption"
          :show-selectors="false"
          :default-type="includeGraphType"
        />
      </div>
      <div class="my-4 tw-mt-6">
        <label
          class="py-1 block"
        >How many top keywords you would like to include?</label>
        <input
          v-model="numberOfKeywords"
          class="block px-4 py-3 rounded-4p filter-input tw-w-full md:tw-w-[400px]"
          type="text"
          placeholder="Number of keywords"
        >
        <label
          class="py-1 my-1 block"
        >You can type “0” if you don’t want to display any keyword
          list.</label>
      </div>

      <div
        v-if="numberOfKeywords != 0"
        class="my-4 mt-4"
      >
        <pdf-sentiment-table
          :printing="false"
          :include-count="false"
          :sentiment-rows="entitySentimentsForTable"
          :keywords-table-length="numberOfKeywords"
          :total-participants="responsesFilteredByRange.length"
          :expandable="true"
          :responses="responsesFilteredByRange"
          column-title="Keyword"
          :sentiment-groups="[]"
          :user-signed-in="userSignedIn"
        >
          <!-- @existGroupModalHandler="existGroupModalHandler"
          @showToast="toggleCreateGroupToast"
          @groupModalHandler="groupModalHandler"
          @deleteGroupModalHandler="deleteGroupModalHandler" -->
          <!-- <grouped-table-transcripts
            slot="expanded-row"
            slot-scope="{ row }"
            expand-result-option="openTranscript"
            :total-participants="filteredResponseShow segmentsds[row.title].transcripts"
            @expand-result="openFullTranscript"
          /> -->
        </pdf-sentiment-table>
      </div>
      <div class="my-4 ">
        <label class="py-1 block">Include Transcripts</label>
        <st-dropdown-menu
          container-class="h-40p  border-none rounded-4p border-black"
          selected-class="rounded-4p w border-gray-important"
          :display-caption="true"
          :initial-active-option="yesNoOptions[0]"
          :options="yesNoOptions"
          :class="{ '': true }"
          @updateOption="includeTranscriptsHandler"
        />
        <!-- <select class="block px-4 py-3 filter-input bg-white">
          <option>Yes</option>
          <option>No</option>
        </select> -->
      </div>
      <div
        v-if="includeTranscripts"
        class="my-4 mt-4"
      >
        <!-- :checked="checkIfSegmentsIncluded.includes(segment.name)" -->
        <!-- @input="filterSegments(segment)" -->
        <st-checkbox
          class="st-survey-insights__checkbox mt-20p"
          :checked="
            selectedAllTranscripts &&
              filteredTranscripts.length ===
              responsesFilteredByRange.length
          "
          @input="toggleAllTranscripts"
        >
          <span
            class="ml-20p"
          >{{
            responsesFilteredByRange.length
          }}
            Participants</span>
        </st-checkbox>
        <div class="mt-20p" />
        <div
          v-for="(response, index) in responsesFilteredByRange"
          :key="'res-' + response.id + '-' + index"
          class="flex w-full  justify-between"
        >
          <st-checkbox
            class="st-survey-insights__checkbox mt-20p"
            :checked="checkIfTranscriptIncluded.includes(response.id)"
            @input="filterTranscripts(response)"
          />

          <div
            ref="stInsightsTranscriptsBlock"
            class="st-insights-transcripts__block w-full ml-10p"
          >
            <div class="st-insights-transcripts__avatar-and-sentiment-pdf">
              <div class="st-insights-transcripts__avatar-and-name">
                <participant-avatar
                  class="st-insights-transcripts__avatar"
                  :participant="response"
                />
                <p class="st-insights-transcripts__name">
                  {{ response.firstName + " " + response.lastName || "Anonymous" }}
                </p>
              </div>
              <div
                :class="[
                  { 'st-insights-transcripts__sentiment-pdf ml-12p': true },
                  {'text-black-important': keywordBucket(response.sentimentScore)=='Neutral'},
                  keywordColor(response.sentimentScore),
                ]"
              >
                {{ keywordBucket(response.sentimentScore) }}
              </div>
            </div>
            <p class="st-insights-transcripts__transcript">
              {{ response.transcript }}
            </p>
          </div>
          <div class="divider my-3" />
        </div>

        <!-- <opinion-metric-transcripts /> -->
      </div>
    </div>
    <div>
      <vue-html2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="reportName"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @hasDownloaded="hasDownloaded"
        :html-to-pdf-options="{
          filename: reportName,
          margin: [0.4, 0, 0.4, 0],
          jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait',
          },
        }"
      >
        <!-- @beforeDownload="beforeDownload" -->
        <!-- @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)" -->
        <section slot="pdf-content">
          <div class=" html2pdf__page-break">
            <div class="px-12">
              <p class="flex items-center">
                <span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_459_9944)">
                      <path
                        opacity="0.8"
                        d="M47.9094 12.176L41.6521 7.9978L39.7284 6.71522L32.4606 1.85935C30.692 0.646629 28.6183 0 26.4978 0C24.3772 0 22.3035 0.646629 20.5349 1.85935L13.2401 6.71522L5.05912 12.176C3.48875 13.1921 2.19805 14.6112 1.31199 16.2959C0.425916 17.9806 -0.0255189 19.8739 0.00138094 21.7925V44.6885C-0.0283277 46.6099 0.421767 48.5066 1.30797 50.1945C2.19417 51.8825 3.48637 53.3042 5.05912 54.3218L14.3424 47.9985L52.9563 21.7925C52.9849 19.8749 52.5353 17.9821 51.6511 16.2973C50.7669 14.6126 49.4781 13.193 47.9094 12.176V12.176Z"
                        fill="#6FA8DC"
                      />
                      <path
                        opacity="0.8"
                        d="M50.9355 1.67453L41.6522 7.9978L28.7755 16.735L3.02749 34.2094C3.0038 36.1292 3.45896 38.0228 4.34887 39.7066C5.23878 41.3904 6.53325 42.8075 8.10684 43.8203L11.2139 45.9206L14.3426 48.0097L16.2716 49.2811L23.534 54.1314C25.3054 55.35 27.3843 56 29.5104 56C31.6364 56 33.7153 55.35 35.4867 54.1314L42.7545 49.2811L50.9355 43.8147C52.5068 42.8006 53.7986 41.3829 54.6857 39.699C55.5728 38.0151 56.0251 36.1222 55.9986 34.2038V11.3079C56.0293 9.38561 55.5791 7.4879 54.6918 5.79964C53.8045 4.11139 52.5103 2.69019 50.9355 1.67453V1.67453Z"
                        fill="#FE795D"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_459_9944">
                        <rect
                          width="56"
                          height="56"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span class="ml-2 px-3 font-600 text-28p mb-2p">AskHumans</span>
              </p>
              <div class="mt-50">
                <p class="flex flex-col">
                  <span class="font-600 text-uppercase text-34p">{{
                    survey.name
                  }}</span>
                  <span
                    class="text-secondary font-600  text-uppercase text-34p"
                  >Insights Report</span>
                  <span
                    class="font-600 "
                  >{{ survey.totalResponsesCompleted }} Respondents</span>
                </p>
              </div>
              <div class="flex mt-50 ">
                <div class="flex flex-col">
                  <span class="text-secondary font-600">Date Range</span>
                  <span class="mt-3">
                    {{ Object.keys(selectedRangeForPDF).length? `${convertDateToReadableFormat(selectedRangeForPDF["start"])} - ${convertDateToReadableFormat(selectedRangeForPDF["end"])}`: `${convertDateToReadableFormat(setInitialDate[0])} - ${convertDateToReadableFormat(setInitialDate[1])}` }}
                  </span>
                </div>
                <div
                  class="ml-20 flex flex-col"
                >
                  <span class="text-secondary font-600">Report generated on</span>
                  <span
                    class="mt-3"
                  >
                    {{ getTodayDate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="absolute right-0">
              <svg
                width="370"
                height="370"
                viewBox="0 0 386 358"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  opacity="0.2"
                  clip-path="url(#clip0_513_12701)"
                >
                  <path
                    opacity="0.8"
                    d="M433.751 110.236L377.1 72.4086L359.684 60.7967L293.885 16.8338C277.873 5.8543 259.098 0 239.9 0C220.701 0 201.927 5.8543 185.915 16.8338L119.871 60.7967L45.8033 110.236C31.5859 119.435 19.9005 132.283 11.8784 147.536C3.8563 162.789 -0.230793 179.93 0.0127465 197.3V404.59C-0.256223 421.986 3.81874 439.158 11.842 454.44C19.8653 469.722 31.5643 482.594 45.8033 491.806L129.851 434.558L479.444 197.3C479.703 179.939 475.632 162.802 467.627 147.549C459.622 132.296 447.954 119.444 433.751 110.236V110.236Z"
                    fill="#6FA8DC"
                  />
                  <path
                    opacity="0.8"
                    d="M461.147 15.1604L377.1 72.4086L260.52 151.512L27.4086 309.717C27.1941 327.098 31.3149 344.242 39.3718 359.486C47.4286 374.731 59.1483 387.56 73.3949 396.731L101.525 415.746L129.85 434.659L147.315 446.17L213.066 490.082C229.103 501.115 247.925 507 267.173 507C286.422 507 305.243 501.115 321.28 490.082L387.08 446.17L461.147 396.68C475.373 387.499 487.069 374.663 495.1 359.418C503.132 344.172 507.226 327.035 506.987 309.667V102.376C507.265 84.9732 503.189 67.7921 495.155 52.5074C487.122 37.2227 475.405 24.3557 461.147 15.1604V15.1604Z"
                    fill="#FE795D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_513_12701">
                    <rect
                      width="507"
                      height="507"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class=" px-12   ">
            <div>
              <div class="html2pdf__page-break">
                <div class="my-5">
                  <span class="font-600 py-2 block  text-34p w-full">
                    This report shows responses from
                    <span class="text-secondary">
                      {{ survey.totalResponsesCompleted }} people
                    </span>
                  </span>
                </div>
                <div class="my-5 mt-10">
                  <p class="text-secondary w-fit py-2 pr-20 border-b-2-black text-20p font-600">
                    Question(s)
                  </p>
                  <p
                    v-for="(question, index) in surveyQuestions"
                    :key="`${index}_sdf`"
                    class=" py-2 text-16p "
                  >
                    <span v-if="question">
                      "{{ question.description  ? question.description : ''}}"
                    </span>
                  </p>
                  <div
                    v-if="showSegments && selectedSegments.length"
                    class="flex flex-col"
                  >
                    <span class="text-secondary  mt-10 w-fit py-2 pr-20 border-b-2-black text-20p font-600">
                      Segments
                    </span>

                    <span
                      v-for="segment in selectedSegments"
                      :key="segment.name"
                      class="flex flex-col mt-3"
                    >
                      <span class="font-600"> {{ segment.name }} : </span>
                      <span
                        v-for="option in segment.options"
                        :key="option.name"
                        class="py-1"
                      >
                        {{ option.name }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div >
                <div v-for="(questionId, index) in Object.keys(groupedResponses)" class="tw-mb-20 tw-border-b tw-border-gray-500 tw-border-solid"
                    :key="`${index}_sdf`">
                    <div class="pdf-item">
                    <p class="tw-font-semibold tw-my-2 tw-text-lg" v-if="surveyQuestions[index]">
                      {{ surveyQuestions[index].description ? `Question :  ${surveyQuestions[index].description} ? `: '' }}
                    </p>
                    <div v-if="includeSentimentGraph">

                      <div
                      
                        :class="[
                          {
                            'my st-opinion-metric-insights__overall-sentiment-container w-full p-3 border-none-important': true,
      
                          },
                          sentimentColor,
                        ]"
                      >
                      
                        <p
                          class="st-opinion-metric-insights__sentiment st-opinion-metric-insights__sentiment--title"
                          :class="{'text-black-important': getOverallScore(questionId)=='Neutral'}"
                        >
                          Average Answer:
                        </p>
                        <p
                          class="st-opinion-metric-insights__sentiment "
                          :class="{'text-black-important': getOverallScore(questionId)=='Neutral'}"
                        >
                          {{ getOverallScore(questionId) }}
                        </p>
                      </div>
                      <bar-graph-stack-pdf
                        :responses="groupedResponses[questionId]"
                        :window="selectedFilterOption"
                        :show-selectors="false"
                        :default-type="includeGraphType"
                      />
                    </div>
                  </div>
                  <div
                    v-if="numberOfKeywords != 0"
                    class="my-4 mt-4 "
                  >
                    <div class="my-5">
                      <p class=" w-fit py-2 pr-20 border-b-2-black text-20p font-600">
                        Top Keywords
                      </p>
                    </div>
                    <pdf-sentiment-table
                      :printing="true"
                      :table-completed="true"
                      :include-count="false"
                      :sentiment-rows="entitySentimentsForTable"
                      :keywords-table-length="numberOfKeywords"
                      :total-participants="
                        groupedResponses[questionId].length
                      "
                      :expandable="true"
                      :responses="groupedResponses[questionId]"
                      column-title="Keyword"
                      :sentiment-groups="[]"
                      :user-signed-in="userSignedIn"
                    />
                  </div>
                  <div class="my-5" />
                <div
                  v-if="includeTranscripts && filteredTranscripts.length"
                  class="mt-20  "
                >
                  <section class="pdf-item">
                    <!-- <p class=" py-2 text-16p ">
                      Question  <span class="text-20p font-600 text-secondary"> "{{ ` ${activeQuestion.description} ` }}" </span>
                    </p> -->
                    <div class="tw-my-5">
                      <span class="mt-10 w-fit py-2 pr-20  tw-text-lg tw-font-600 tw-border-b tw-border-gray-300 tw-border-solid ">
                        {{ getFilteredRespondentsNames().length }} Highlighted
                        transcripts</span>
                    </div>
                    <div class="mt-4" />
                    <div
                      v-for="(response, index) in groupedResponses[questionId]"
                      :key="'res-pdf-' + response.id + '-' + index"
                      class="w-full border-bottom-gray tw-mt-3"
                    >
                      <div
                        v-if="getFilteredRespondentsNames().includes(response.fullName)"
                        ref="stInsightsTranscriptsBlock"
                        class="st-insights-transcripts__block-pdf mt-4 w-full "
                      >
                        <div
                          class="st-insights-transcripts__avatar-and-sentiment-pdf"
                        >
                          <div class="st-insights-transcripts__avatar-and-name">
                            <participant-avatar
                              class="st-insights-transcripts__avatar"
                              :participant="response"
                            />
                            <p class="st-insights-transcripts__name">
                              {{ response.fullName || "Anonymous" }}
                            </p>
                          </div>
                          <div
                            :class="[
                              { 'st-insights-transcripts__sentiment-pdf ml-12p': true },
                              {'text-black-important': keywordBucket(response.sentimentScore)=='Neutral'},
                              keywordColor(response.sentimentScore),
                            ]"
                          >
                            {{ keywordBucket(response.sentimentScore) }}
                          </div>
                        </div>
                        <p class="st-insights-transcripts__transcript">
                          {{ response.transcript }}
                        </p>
                      </div>
                    </div>
                    <div class="tw-mb-10"></div>
                  </section>
  
                  <!-- <opinion-metric-transcripts /> -->
                </div>
                </div>
              </div>
              
              <span class="opacity-0">Hidden to prevent extra page</span>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
    <div
      class="st-survey-insights__active-question-header tw-sticky w-full z-99 tw-bottom-0"
    >
      <div class="w-full flex flex-col md:flex-row tw-justify-end tw-items-end">
        <!-- <h2 class="font-semibold">
          Generate PDF Report
        </h2> -->
        <div class="flex space-x-2 ">
          <!-- <button
            class="ml-2 rounded-4p border-black px-3 py-3 font-600 text-black "
            @click="cancelHandler"
          >
            Cancel
          </button> -->
          <!-- <button
            class="bg-blue-primary rounded-4p px-3 py-3 font-600 text-white"
            @click="previewPDF"
          >
            Preview Report
          </button> -->
          <button
            class="bg-blue-primary tw-text-sm rounded-4p px-3 py-3  text-white"
            @click="exportPDF"
            :disabled="isPDFDownloading"
          >
          {{ isPDFDownloading? 'Downloading Pdf...': 'Generate PDF report' }}
            
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueHotelDatepicker from '@northwalker/vue-hotel-datepicker'
import { kebabCase } from 'lodash'
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'
import { mapGetters, mapState } from 'vuex'
import { average, bucketIdx } from '../../../utilities/data_utilities'
import { StCheckbox, StDropdownMenu } from '../../common'
import { WINDOW_TYPE } from '../surveys/constants'
import BarGraphStackPdf from './bar-graph-stack-pdf.vue'
import BarGraphStack from './bar-graph-stack.vue'
import ParticipantAvatar from './participant-avatar.vue'
import PdfSentimentTable from './pdf-sentiment-table.vue'
const bucketNames = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]
export default {
  components: {
    VueHtml2pdf,
    StCheckbox,
    BarGraphStackPdf,
    BarGraphStack,
    StDropdownMenu,
    PdfSentimentTable,
    VueHotelDatepicker,
    // StRadioButton,
    ParticipantAvatar
    // SentimentTable,
    // IconWrapper,
    // SimpleDataTable,
    // VerticalBarGraph
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: true
    },
    cancelHandler: {
      type: Function,
      required: true
    },

    userSignedIn: {
      type: String,
      required: false,
      default: 'false'
    }
  },
  data: function () {
    return {
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      includeGraphType: 'TOTAL',
      previewReport: true,
      selectedRangeForPDF: {},
      downloadPDF: false,
      yesNoOptions: ['Yes', 'No'],
      showNotification: false,
      showSegments: true,
      includeSentimentGraph: true,
      numberOfKeywords: 0,
      responsesFilteredByRange: [],
      includeTranscripts: true,
      selectedSegments: [],
      filteredTranscripts: [],
      selectedAllTranscripts: false,
      groupedResponses: {},
      reportName: this.survey.name,
      selectedFilterOption: WINDOW_TYPE.MONTHLY,
      isPDFDownloading: false,
      filterSelectOptions: [WINDOW_TYPE.MONTHLY, WINDOW_TYPE.WEEKLY]
    }
  },
  computed: {
    ...mapState([
      'currentUser',
      
    ]),
    ...mapState('insights', ['activePanelSection', 'activeQuestionView', 'surveyQuestions',  'surveyQuestionsData' ]),
    ...mapGetters('insights', [
      'activeQuestion',
      'getSentimentGroups',
      'activeQuestionResponses'
      
    ]),
    surveyResponsesByQuestionId () {
      const responses = {}
      this.surveyQuestionsData.forEach(response => {
        const questionId = response.questionId

        if (!responses.hasOwnProperty(questionId)) {
          responses[questionId] = []
        }

        responses[questionId].push(response)
      })
      // console.log(responses)
      return responses
    },
    setInitialDate () {
      let data = this.activeQuestionResponses
      if(!data.length){
        return [new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)]
      }
      let startDate = new Date(data[0].createdAt).toISOString().slice(0, 10)
      let endDate = startDate
      data.forEach((response) => {
        let responseDate = new Date(response.createdAt).toISOString().slice(0, 10)
        if (responseDate < startDate) {
          startDate = responseDate
        }
        if (responseDate > endDate) {
          endDate = responseDate
        }
      })
      return [startDate, endDate]
    },

    // getTodayDate () {
    //   const date = new Date()
    //   return moment(date).format('MMM DD, YYYY')
    // },

    getTodayDate () {
      const date = new Date()

      const month = this.monthNames[date.getMonth()]
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },

    overallScore () {
      return average(
        this.activeQuestionResponses,
        'sentimentScore'
      )
    },
    checkIfSegmentsIncluded () {
      const segment = this.selectedSegments.map((segment) => {
        return segment.name
      })
      return segment
    },
    checkIfTranscriptIncluded () {
      const currentTranscript = this.filteredTranscripts.map((transcript) => {
        return transcript.id
      })
      return currentTranscript
    },
    overallScoreBucket () {
      return bucketNames[bucketIdx(this.overallScore)]
    },
    sentimentColor () {
      return {
        [`st-sentiment-badge--${kebabCase(this.overallScoreBucket)}`]: true
      }
    },
    entitySentimentsKeywords () {
      let keyWordSentiments = {}
      this.responsesFilteredByRange.forEach((response) => {
        response.entitySentiments.map((entity) => {
          if (keyWordSentiments.hasOwnProperty(entity.name)) {
            keyWordSentiments[entity.name].sentiments.push(
              entity.sentimentScore
            )
            keyWordSentiments[entity.name].transcripts.push({
              bucketIdx: entity.sentimentValueIdx,
              firstName: response.firstName,
              lastName: response.lastName,
              name: response.fullName,
              surveyResponseId: response.surveyResponseId,
              responseId: response.id,
              transcript: response.transcript
            })
          } else {
            keyWordSentiments[entity.name] = {
              entityId: entity.id,
              entitySentimentsGroupId: entity.entitySentimentsGroupId,
              sentiments: [entity.sentimentScore],
              transcripts: [
                {
                  bucketIdx: entity.sentimentValueIdx,
                  firstName: response.firstName,
                  lastName: response.lastName,
                  name: response.fullName,
                  responseId: response.id,
                  surveyResponseId: response.surveyResponseId,
                  transcript: response.transcript
                }
              ]
            }
          }
        })
      })
      return keyWordSentiments
    },
    entitySentimentsForTable () {
      let sortedKeyWords = Object.entries(this.entitySentimentsKeywords).map(
        ([title, value]) => {
          return {
            ...value,
            title,
            averageSentiment: average(value.sentiments)
          }
        }
      )
      sortedKeyWords.sort((a, b) => b.average - a.average)
      return sortedKeyWords
    },
    getTotalKeywordsForTable () {
      if (this.entitySentimentsForTable.length) {
        return this.entitySentimentsForTable.length
      }
      return this.numberOfKeywords
    }
  },
  watch: {
    getVisit: function () {
      if (this.responsesFilteredByRange !== null) {
        console.log('watch: getVisit !== null and is not empty!')
        this.toggleAllTranscripts()
      } else {
        console.log('watch: getVisit is NULL or is EMPTY')
      }
    }
  },
  created(){
    this.groupedResponses = this.surveyResponsesByQuestionId
  },
  mounted () {
    // this.getSurveysData()

    this.responsesFilteredByRange = this.activeQuestionResponses
    setTimeout(() => {
      
      this.toggleAllTranscripts()
      if (this.entitySentimentsForTable.length) {
        this.numberOfKeywords = this.entitySentimentsForTable.length
      }
    }, 1000)
  },

  methods: {
    // convertDateToReadableFormat (date) {
    //   return moment(date).format('MMM DD, YYYY')
    // },
    updateDateRangeHandler (range) {
      const filteredResponses = this.activeQuestionResponses.filter(({ createdAt }) => {
        return moment(createdAt).format('MM-DD-YYYY') >= range.start && moment(createdAt).format('MM-DD-YYYY') <= range.end
      })
      this.responsesFilteredByRange = filteredResponses
      this.filterTranscriptsByRange()
      this.selectedRangeForPDF = range
    },

    convertDateToReadableFormat (date) {
      const d = new Date(date)
      const month = this.monthNames[d.getMonth()]
      const day = d.getDate()
      const year = d.getFullYear()
      return `${month} ${day}, ${year}`
    },

    // Implementation of updateDateRangeHandler without Moment.js
    // updateDateRangeHandler (range) {
    //   const filteredResponses = this.activeQuestionResponses.filter(({ createdAt }) => {
    //     const d = new Date(createdAt)
    //     const responseDate = `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`
    //     const startDate = new Date(range.start)
    //     const endDate = new Date(range.end)
    //     return responseDate >= `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}` && responseDate <= `${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`
    //   })
    //   this.responsesFilteredByRange = filteredResponses
    //   this.filterTranscriptsByRange()
    //   this.selectedRangeForPDF = range
    // },
      getFilteredRespondentsNames(){
        const names = this.filteredTranscripts.map((transcript) => {
          return transcript.fullName
        });
        return names
      },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            pdf.text(
              'Page ' + i + ' of ' + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            )
          }
        })
        .save()
    },
    async hasDownloaded(){
      this.isPDFDownloading = false
      // this.$refs.pdfDownloadLink.click()
    
    },
    filterSegments (segment) {
      if (this.checkIfSegmentsIncluded.includes(segment.name)) {
        this.selectedSegments = this.selectedSegments.filter((seg) => {
          return seg.name !== segment.name
        })
      } else {
        this.selectedSegments.push(segment)
      }
    },
    toggleAllTranscripts () {
      if (this.selectedAllTranscripts) {
        this.filteredTranscripts = []
      } else {
        this.filteredTranscripts = this.responsesFilteredByRange
      }
      this.selectedAllTranscripts = !this.selectedAllTranscripts
    },
    filterTranscriptsByRange () {
      this.filteredTranscripts = this.responsesFilteredByRange
    },
    filterTranscripts (transcript) {
      if (this.filteredTranscripts.find((tr) => tr.id === transcript.id)) {
        this.filteredTranscripts = this.filteredTranscripts.filter((trans) => {
          return trans.id !== transcript.id
        })
      } else {
        this.filteredTranscripts.push(transcript)
      }
    },
    keywordBucket (sentiment) {
      return bucketNames[bucketIdx(sentiment)]
    },
    keywordColor (sentiment) {
      return {
        [`st-sentiment-badge--${kebabCase(
          this.keywordBucket(sentiment)
        )}`]: true
      }
    },
    showSegmentHandler (option) {
      this.showSegments = !this.showSegments
    },
    includeSentimentHandler (option) {
      this.includeSentimentGraph = !this.includeSentimentGraph
    },
    includeTranscriptsHandler (option) {
      this.includeTranscripts = !this.includeTranscripts
    },
    previewPDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    exportPDF () {
      this.showNotification = true
      fetch(`https://us-central1-askhumans-${process.env.RAILS_ENV}.cloudfunctions.net/insights-report-sender`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          // questions: this.questions,
          surveyName: this.survey.name,
          startDate: Object.keys(this.selectedRangeForPDF).length? `${this.convertDateToReadableFormat(this.selectedRangeForPDF["start"])}` : `${this.convertDateToReadableFormat(this.setInitialDate[0])}` ,
          endDate: Object.keys(this.selectedRangeForPDF).length? `${this.convertDateToReadableFormat(this.selectedRangeForPDF["end"])}` : `${this.convertDateToReadableFormat(this.setInitialDate[1])}` ,
          responses: this.responsesFilteredByRange,
          keywordsLimit: this.numberOfKeywords,
          activeQuestion: this.activeQuestion,
          userEmail: this.currentUser.email
          // showSegments: this.showSegments,
          // includeSentimentGraph: this.includeSentimentGraph,
          // numberOfKeywords: this.numberOfKeywords,
          // includeTranscripts: this.includeTranscripts,
          // selectedSegments: this.selectedSegments,
          // filteredTranscripts: this.filteredTranscripts,
          // groupedResponses: this.groupedResponses,
          // reportName: this.reportName,
          // selectedFilterOption: this.selectedFilterOption
        })
      })
        .then((res) => {
          // console.log(res.data)
          this.isPDFDownloading = false
          this.$refs.pdfDownloadLink.href = res.data.url
          this.$refs.pdfDownloadLink.click()
        })
        .catch(() => {
          console.log('error')
        })
        
        setTimeout(() => {
          this.showNotification = false
        }, 5000)
      // this.isPDFDownloading = true
      // this.$refs.html2Pdf.generatePdf()
    },
    getOverallScore (id) {
      const avg = average(
        this.groupedResponses[id]?.map((response) => {
          return response.sentimentScore
        })
      )

      return bucketNames[bucketIdx(avg)]
    },
    getSurveysData () {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'get',

        url: `study/${this.survey.id}/results`
      })
        .then((res) => {
          console.log(res.data)
          this.$store.commit('toggleFullSpinner', false)
          if (res.data.responses.length !== 0) {
            res.data.responses.forEach(response => {
              const questionId = response.questionId

              if (!this.groupedResponses.hasOwnProperty(questionId)) {
                this.groupedResponses[questionId] = []
              }

              this.groupedResponses[questionId].push(response)
            })
          }
          console.log(this.groupedResponses)
          // this.selectedResponseId = Object.keys(this.groupedResponses)[0]
        })
        .catch(() => {
          console.log('error')
        })
    }
  }
}
</script>
