<template>
  <div>
    <div class="tw-w-full tw-mb-8 tw-overflow-hidden">
      <div class="tw-w-full tw-overflow-x-auto">
        <div class="tw-w-full">
          <div>
            <div
              class="tw-tracking-wide tw-grid tw-grid-cols-10 tw-justify-between tw-text-left tw-text-gray-900 tw-border-solid tw-border-b tw-border-gray-100"
            >
              <div class="tw-px-4 tw-py-3">
                Status
              </div>
              <div class="tw-px-4 tw-py-3 tw-col-span-4">
                Study
              </div>
              <div class="tw-px-4 tw-py-3 tw-col-span-2">
                Weighted sentiments
              </div>
              <div class="tw-px-4 tw-py-3 tw-col-span-2">
                Activity
              </div>
              <div class="tw-px-4 tw-py-3" />
            </div>
          </div>

          <div
            v-for="study in studies.surveys"
            :key="study.name+study.id"
            class="tw-cursor-pointer tw-h-fit"
            :class="studies.surveys.length == 1 ? 'tw-min-h-[300px]': ''"
          >
            <div
              class="tw-text-gray-700 tw-bg-white tw-h-fit hover:tw-bg-light-four  tw-relative tw-grid tw-grid-cols-10 mt-3 tw-justify-between tw-items-center"
              @click="redirectToNext(study.id)"
            >
              <div
                class="tw-px-4 tw-py-3 tw-text-ms tw-font-semibold tw-border"
              >
                <study-status :type="study.status">
                  <span class="tw-capitalize">
                    {{ study.status }}
                  </span>
                </study-status>
                <!-- <span
                        class="tw-rounded-lg tw-py-2 tw-px-3 tw-text-[13px] tw-font-bold tw-bg-success tw-text-white "
                      >
                        Active
                      </span> -->
              </div>
              <div class="tw-px-4 tw-py-3 tw-border tw-col-span-4">
                <div class="tw-flex tw-space-x-3 tw-items-center">
                  <div>
                    <img
                      :src="study.pageImageUrl? `${getCompleteImageURL(study.pageImageUrl)}` : defaultStudyImage"
                      class="tw-rounded-lg tw-w-14 tw-h-14 tw-object-cover"
                      alt=""
                    >
                  </div>
                  <div class="tw-w-full tw-relative">
                    <div
                      v-if="isTagsPopupOpenForId(study.id)"
                      class="tw-absolute   tw-z-[100] tw-max-h-[200px] tw-bg-white tw-w-full tw-max-w-[400px] tw-rounded-xl "
                      style="box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);"
                      @click="stopRedirect($event)"
                    >
                      <div
                        class="tw-w-full tw-flex tw-justify-center tw-items-center tw-px-2 tw-bg-white-two"
                      >
                        <input
                          :ref="'tagInput_' + study.id"
                          v-model="tag_name"
                          class="tw-w-full tw-text-base tw-border-none tw-py-3 tw-px-2 tw-space-x-2 tw- focus:tw-border-none focus:tw-outline-none hover:tw-outline-none hover:border-none placeholder:tw-text-black-three tw-bg-white-two"
                          type="text"
                          placeholder="Create or select tag"
                        >
                        <span
                          class="tw-cursor-pointer"
                          @click="hideAddTagPopup( study.id)"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_216_27590)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.00024 16C3.58168 16 0 12.4182 0 8C0 3.58178 3.58168 0 8.00024 0C12.4188 0 16 3.58178 16 8C16 12.4187 12.4183 16 8.00024 16ZM8.03206 1.93505C4.68216 1.91795 1.95304 4.61913 1.93546 7.96913C1.91837 11.3186 4.61947 14.0483 7.96889 14.0659C11.3183 14.083 14.0479 11.3813 14.0655 8.03182C14.0831 4.68183 11.3815 1.95215 8.03206 1.93505ZM11.2523 11.0294L11.0267 11.255C10.7089 11.5728 10.1936 11.5728 9.87633 11.255L7.98266 9.36128L6.089 11.255C5.77125 11.5728 5.25639 11.5728 4.93864 11.255L4.71304 11.0294C4.39529 10.7116 4.39529 10.1963 4.71304 9.879L6.6067 7.98528L4.71304 6.09155C4.39529 5.77379 4.39529 5.25845 4.71304 4.94116L4.93864 4.71555C5.25639 4.39779 5.77172 4.39779 6.089 4.71555L7.98266 6.60927L9.87633 4.71555C10.1941 4.39779 10.7094 4.39779 11.0267 4.71555L11.2523 4.94116C11.57 5.25892 11.57 5.77427 11.2523 6.09155L9.35863 7.98528L11.2523 9.879C11.57 10.1968 11.57 10.7121 11.2523 11.0294Z"
                                fill="#474747"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_216_27590">
                                <rect
                                  width="16"
                                  height="16"
                                  fill="white"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>

                      <div class="">
                        <p class=" tw-px-3 tw-py-1">
                          Search for an option or create one
                        </p>
                        <div
                          v-if="!filteredTags.length && tag_name"
                          class="tw-flex tw-cursor-pointer tw-items-center tw-px-3 tw-py-2 tw-bg-territory-light tw-justify-start tw-space-x-2"
                          @click="addTag($event)"
                        >
                          <span>Create</span>
                          <span
                            class="tw-bg-success/20 tw-text-success tw-py-1 tw-cursor-pointer tw-px-2 tw-rounded-md tw-font-semibold"
                          >
                            {{ tag_name }}
                          </span>
                        </div>
                        <div
                          v-else
                          class="tw-flex tw-flex-col tw-space-y-2 tw-max-h-[130px] scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black  tw-bg-white tw-overflow-y-auto tw-mb-3 tw-px-3 tw-py-1 tw-justify-start tw-items-start"
                        >
                          <span
                            v-for="tag in getTagsWithoutStudyTags(study.id)"
                            :key="tag.id+tag.tag_name"
                            class="tw-py-1 tw-cursor-pointer tw-px-2 tw-rounded-md "
                            :class="`tw-bg-[${tag.background_color}]/20 tw-text-${tag.background_color} `"
                            @click="assignTagToStudy(study.id, tag.id)"
                          >
                            {{ tag.tag_name }}
                          </span>
                          <!-- <span
                                  class="tw-bg-warning/20 tw-text-warning tw-py-1 tw-px-2 tw-rounded-md tw-font-semibold"
                                >
                                  Science
                                </span>
                                <span
                                  class="tw-bg-success/20 tw-text-success tw-py-1 tw-px-2 tw-rounded-md tw-font-semibold"
                                >
                                  Sports
                                </span> -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="tw-cursor-pointer"
                      @click="redirectToNext(study.id)"
                    >
                      <p class="tw-text-base">
                        {{ study.name ? study.name : '[Study Name]' }}
                      </p>
                      <p class="tw-text-[13px] tw-space-x-3 tw-items-center">
                        <span>
                          {{ getStudyPlanName(study.id) }}
                        </span>
                        <span v-if="study.reward && study.rewardType=='gift card'">
                          <svg
                            width="11"
                            height="12"
                            viewBox="0 0 11 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.50558 6.00013C7.15973 6.00013 8.45965 4.68022 8.45965 3.00006C8.45965 1.3199 7.15999 0 5.50558 0C3.85144 0 2.55151 1.32016 2.55151 3.00006C2.55151 4.67997 3.85144 6.00013 5.50558 6.00013ZM11 11.28C10.232 8.22003 7.15973 6.29995 4.14667 7.08012C2.13804 7.62012 0.542885 9.18019 0.0111674 11.28C-0.0478279 11.5801 0.129411 11.9401 0.48389 12H10.4093C10.7638 12 11 11.7601 11 11.4001V11.28Z"
                              fill="#A7AA93"
                            />
                          </svg>
                          <span>
                            {{ study.totalResponsesCompleted }}
                            {{
                              study.totalRespondents
                                ? `of ${study.totalRespondents} respondents`
                                : null
                            }}
                          </span>
                        </span>
                      </p>
                    </div>
                    <div
                      class="tw-relative tw-flex tw-gap-2 tw-items-center tw-flex-wrap "
                    >
                      <span
                        v-for="(tag, index ) in study.tags"
                        :key="`tag_${tag.id}_${index}`"
                        class="tw-py-1 tw-cursor-pointer tw-px-2 tw-rounded-md "
                        :class="`tw-bg-[${tag.background_color}]/20 tw-text-${tag.background_color} `"
                      >
                        {{ tag.tag_name }}
                      </span>
                      <span
                        class="tw-cursor-pointer tw-text-primary tw-text-[13px] hover:tw-underline"
                        @click="showTagPopup($event, study.id)"
                      >
                        Add tag
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-px-4 tw-py-3 tw-text-sm tw-col-span-2">
                <div class="tw-w-32">
                  <sentiment-mentions-bar
                    v-if="!loadingWeightedSentiments"
                    :sentiments="getSentimentCounts(study.id)"
                    :mentions="getCurrentStudyResponses(study.id)"
                  />
                  <span v-else>
                    Loading
                  </span>
                </div>
              </div>
              <div class="tw-px-4 tw-py-3 tw-text-sm tw-border tw-col-span-2">
                <p
                  class="tw-flex tw-flex-col tw-text-sm tw-cursor-pointer"
                  @click="toggleRespondentDrawer($event, study.id)"
                >
                  <span
                    v-if="loadingRecentResponses"
                    class="tw-text-[#667E6E]"
                  >
                    Loading new responses
                  </span>
                  <span
                    v-else-if="!loadingRecentResponses"
                    class="tw-text-[#667E6E]"
                  >
                    {{ getNewResponses(study.id) }} new responses
                  </span>
                  <span
                    v-if="isSurveyHasGiftCards(study.id)"
                    class="tw-text-primary tw-text-sm"
                  >
                    <!-- 150 pending for reply -->
                  </span>
                </p>
              </div>

              <div

                class="tw-px-4 tw-py-3 tw-flex tw-flex-col tw-text-sm tw-col-span-1 "
              >
                <span
                  v-if="!study.published"
                  class="tw-cursor-pointer hover:tw-underline"
                  @click="deleteDraft($event, study.id)"
                >
                  Delete draft
                </span>
                <span
                  class="tw-cursor-pointer hover:tw-underline"
                  @click="duplicateStudy($event, study.id)"
                >
                  Duplicate study
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <responses-drawer
      v-if="showResponsesDrawer"
      ref="responsesDrawer"
      :study-id="selectedStudyId"
      :responses="responsesDrawerData"
      :study-name="studyNameForDrawer"
      @closeResponsesDrawer="closeResponsesDrawer"
    />
    <st-modal v-model="showDeleteModal">
      <template v-slot:modalTitle>
        <span class="font-18p font-bold">
          Delete draft
        </span>
      </template>
      <template v-slot:modalActions>
        <p class="tw-my-3 tw-w-[500px]">
          After you sure you want delete this draft? you will lose all the
          information already created. Do you wish to proceed?
        </p>
        <div class="tw-hidden">
          <span class="tw-bg-[#6ea474]/20 tw-text-[#6ea474]" />
          <span class="tw-bg-[#517ca3]/20 tw-text-[#517ca3] " />
          <span class="tw-bg-[#937835]/20 tw-text-[#937835] " />
          <span class="tw-bg-[#000000]/20 tw-text-[#000000] " />
        </div>
        <div class="flex space-x-2 justify-end">
          <ah-button
            type="outlined"
            @click="toggleDeleteModal"
          >
            Cancel
          </ah-button>
          <ah-button
            @click="deleteStudyHandler"
          >
            Delete
          </ah-button>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import {
  ResponsesDrawer,
  StudyStatus,
  StModal,
  AhButton
} from '../../common'
import SentimentMentionsBar from '../../../components/pages/insights/sentiment-mentions-bar.vue'
import { mapState } from 'vuex'
import { bucketCounts } from '../../../utilities/data_utilities'
import StudyDefaultImage from '../../../../assets/images/default-study-image.png'
const tagColors = ['#6ea474', '#517ca3', '#937835', '#000000' ]
export default {
  components: {
    ResponsesDrawer,
    StudyStatus,
    SentimentMentionsBar,
    StModal,
    AhButton
  },
  props: {
    studies: {
      type: Object,
      required: true
    },
    perPage: {
      type: Number,
      required: false,
      default: 5
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data () {
    return {
      tag_name: '',
      showTagsPopup: false,
      showTagsIds: [],
      showDeleteModal: false,
      studyNameForDrawer: '',
      responsesDrawerData: [],
      showResponsesDrawer: false,
      selectedStudyId: null,
      loadingRecentResponses: false,
      deleteDraftId: null,
      responsesForWeightedSentiments: [],
      loadingWeightedSentiments: false,
      studiesWithRecentResponses: [],
      defaultStudyImage: StudyDefaultImage
      // studiesData: this.studies
    }
  },
  computed: {
    ...mapState(['tags']),
    filteredTags () {
      return this.tags.filter((tag) =>
        tag.tag_name.toLowerCase().includes(this.tag_name.toLowerCase())
      )
    }
  },

  // watch for studies prop to change and get recent responses from backend
  watch: {
    studies: {
      handler: function (val, oldVal) {
        this.getRecentResponsesFromBackend()
        this.getStudySentimentScores()
      },
      deep: true
    }
  },

  mounted () {
    this.getRecentResponsesFromBackend()
    this.getStudySentimentScores()
  },

  methods: {
    duplicateStudy (e, surveyId) {
      e.stopPropagation()
      this.$axios
        .request({
          method: 'post',
          url: `/study/${surveyId}/duplicate`
        })
        .then((res) => {
          window.location.href = `/study/${res.data.id}/edit`
        })
        .catch((err) => console.error(err))
    },
    getStudySentimentScores () {
      this.loadingWeightedSentiments = true
      const studyIds = this.studies.surveys.map((study) => study.id)
      this.$axios
        .post('/study/get_study_sentiment', {
          survey_ids: studyIds
        })
        .then((res) => {
          this.loadingWeightedSentiments = false
          if (res.status === 200) {
            // console.log(res.data.responses)
            this.responsesForWeightedSentiments = res.data.responses
          }
        })
        .catch((err) => {
          this.loadingWeightedSentiments = false
          console.log(err)
        })
    },
    closeResponsesDrawer () {
      this.showResponsesDrawer = false
    },
    getCompleteImageURL (url) {
      const baseUrl = window.location.origin
      return `${baseUrl}${url}`
    },
    isTagsPopupOpenForId (id) {
      return this.showTagsIds.includes(id)
    },
    isSurveyHasGiftCards (id) {
      const survey = this.studies.surveys.find((study) => study.id === id)
      return survey.reward && survey.reward.rewardType === 'gift_card'
    },
    getCurrentStudyResponses (id) {
      const study = this.responsesForWeightedSentiments.find(
        (study) => study.surveyId === id
      )
      if (!study) return 0
      if (!study.questionResponses) {
        return 0
      }
      return study.questionResponses.length
    },
    getSentimentCounts (id) {
      const study = this.responsesForWeightedSentiments.find(
        (study) => study.surveyId === id
      )
      if (!study) return []
      if (!study.questionResponses) {
        return []
      }
      const sentiments = study.questionResponses.map((response) => response.sentimentScore)
      return bucketCounts(sentiments).reverse()
    },
    getTagsWithoutStudyTags (id) {
      const study = this.studies.surveys.find((study) => study.id === id)
      const studyTagsIds = study.tags.map((tag) => tag.id)
      return this.tags.filter((tag) => !studyTagsIds.includes(tag.id))
    },
    showTagPopup (e, id) {
      e.stopPropagation()
      this.showTagsIds.push(id)
      setTimeout(() => {
        this.$refs[`tagInput_${id}`][0].focus()
      }, 150)
    },
    // toggleRespondentDrawer () {
    //   this.$refs.responsesDrawer.toggleRespondentDrawer()
    // },
    toggleDeleteModal () {
      this.showDeleteModal = !this.showDeleteModal
    },
    deleteStudyHandler () {
      this.$axios
        .delete(`/study/${this.deleteDraftId}`)
        .then((res) => {
          if (res.status === 200) {
            this.$emit('updateStudiesData')
            this.toggleDeleteModal()
            this.deleteDraftId = null
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    stopRedirect (e) {
      e.stopPropagation()
    },
    addTag (e) {
      // e.stopPropagation()
      this.$axios
        .post('/tags', {
          tag_name: this.tag_name,
          background_color: tagColors[Math.floor(Math.random() * tagColors.length)]
        })
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data)
            // this.getAllTags()
            this.getTags()
            this.tag_name = ''
            // this.$refs.tagInput.focus()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getTags () {
      this.$axios.get('/tags').then(res => {
        this.$store.commit('setTags', res.data)
      }).catch(err => {
        console.log(err)
      })
    },
    deleteDraft (e, id) {
      e.stopPropagation()

      this.showDeleteModal = true
      this.deleteDraftId = id
    },
    toggleRespondentDrawer (e, id) {
      if (this.loadingRecentResponses) return
      this.showResponsesDrawer = true
      e.stopPropagation()
      const responses = this.studiesWithRecentResponses.find((study) => study.id === id).recentSurveyResponses
      // console.log(this.studiesWithRecentResponses)
      this.responsesDrawerData = responses
      const studyName = this.studiesWithRecentResponses.find((study) => study.id === id).name
      this.selectedStudyId = this.studiesWithRecentResponses.find((study) => study.id === id).id
      this.studyNameForDrawer = studyName
      setTimeout(() => {
        this.$refs.responsesDrawer.toggleRespondentDrawer()
      }, 250)
    },

    getStudyPlanName (id) {
      const study = this.studies.surveys.find((study) => study.id === id)
      const studyPlan = study.plans.find((plan) => {
        return plan.title.toLowerCase().includes('answers') == false
      })

      if (studyPlan) {
        if (studyPlan.title == 'Extra Large Plan') {
          return 'Extra Large Study'
        }
        return studyPlan.title.split(' ')[0] + ' Study'
      } else return 'No plan added!'
      // return study.plan ? study.plan.name : 'Free'
    },
    hideAddTagPopup (id) {
      this.showTagsIds = this.showTagsIds.filter((item) => item !== id)
      this.tag_name = ''
      // this.showTagsPopup = false
    },
    getRecentResponsesFromBackend () {
      this.loadingRecentResponses = true
      this.$axios({
        method: 'post',
        url: `/study/get_recent_survey_responses?per_page=${this.perPage}&page=${this.currentPage}`
      })
        .then((response) => {
          this.studiesWithRecentResponses = response.data.surveys
          this.loadingRecentResponses = false

          this.studyList = response.data.surveys
        })
        .catch((error) => {
          this.loadingRecentResponses = false
          console.log(error)
        })
    },
    getNewResponses (studyId) {
      // console.log(studyId, this.studiesWithRecentResponses)
      if (!this.studiesWithRecentResponses.length) return 0
      const studiesWithRecentResponses = this.studiesWithRecentResponses.find((study) => study.id === studyId)

      if (!studiesWithRecentResponses) return 0
      //  group the responses with email
      const total = studiesWithRecentResponses.recentSurveyResponses.reduce((acc, curr) => {
        if (acc[curr.email]) {
          acc[curr.email] += 1
        } else {
          acc[curr.email] = 1
        }
        return acc
      }, {})
      // console.log(total)
      return Object.keys(total).length
      // return studiesWithRecentResponses.recentSurveyResponses.length
    },
    redirectToNext (studyId) {
      this.$store.commit('toggleFullSpinner', true)
      const study = this.studies.surveys.find((study) => study.id === studyId)

      const link = study.published
        ? `/study/${study.id}/results`
        : `/study/${study.id}/edit`
      window.location.href = link
    },
    assignTagToStudy (studyId, tagId) {
      // console.log(studyId, tagId)
      // e.stopPropagation()
      this.$axios
        .post(`/study/${studyId}/assign_tag/${tagId}`, {})
        .then((res) => {
          console.log(res)
          if (res.status === 200) {
            this.$emit('updateStudiesData')
            this.hideAddTagPopup(studyId)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
