<template>
  <div class="st-new-question__page">
    <div
      v-if="showQuestionsPanel"
      class="st-new-question__questions-added st-new-question__questions-added--template"
    >
      <p class="st-new-question__mobile-questions-title">
        Questions
      </p>
      <div
        class="st-new-question__new-tile-scroll-container"
      >
        <div
          :class="{
            'st-new-question__scroll-area': true,
            'st-new-question__scroll-area--empty': questions.length === 0,
          }"
        >
          <new-metric-tile
            v-for="(question, idx) in questions"
            :key="question.id"
            :index="idx"
            @setActive="setActive(idx)"
            @showSpecifications="showSpecifications"
          />
        </div>
      </div>
    </div>
    <div class="bx--grid">
      <div class="bx--row st-new-question__back-and-save-links-container st-new-question__back-and-save-links-container--read-only">
        <div
          v-if="activeTab === SPECS_TABS.SPECIFICATIONS"
          class="st-specifications__mobile-header"
        >
          <a href="/welcome">
            <icon-wrapper
              icon-name="spacetrics"
              class="st-choose-template__mobile-header-icon"
            />
          </a>
        </div>
        <div
          :class="{
            'bx--col-sm-12 st-new-question__back-and-save-links st-edit-survey__col-space': true
          }"
        >
          <st-hyperlink
            :back-link="true"
            text="Back"
            @click="handleBackLink"
          />
          <template
            v-if="showUseTemplate && surveyBackId"
          >
            <div class="st-new-question__preview-choose-message">
              Previewing Template
            </div>
            <st-button
              caption="Choose Template"
              icon-name="pointer"
              :show-action="true"
              @click="useTemplate"
            />
          </template>
          <div
            v-else
            :class="{
              'st-new-question__preview-only-message': true,
              'st-new-question__preview-only-message--read-only': !template.canEdit,
            }"
          >
            <st-button
              v-if="template.canEdit"
              caption="Edit Template"
              icon-name="arrow"
              :show-action="true"
              :url="`/templates/${template.id}/edit`"
            />
            <span v-else>This is a read-only view, you are unable to save changes.</span>
          </div>
        </div>
      </div>
      <div
        class="bx--row"
      >
        <div class="bx--col-md-7 bx--col-sm-6 st-new-question__question-specifications">
          <div
            :class="{
              'st-specifications__header-row': true,
              'st-specifications__header-row--incomplete': !activeQuestion.complete,
            }"
          >
            <p class="st-specifications__title">
              {{ activeMetricName }}
            </p>
            <div class="st-specifications__header-icons-container">
              <st-tooltip
                class="st-specifications__header-icon-info-tooltip"
                :direction="isDesktop ? 'bottom' : 'left'"
                :max-width="isDesktop ? 411 : 225"
                :tip="activeMetricDescription.description"
              >
                <icon-wrapper
                  class="st-specifications__header-icon"
                  icon-name="info"
                />
              </st-tooltip>
            </div>
          </div>
          <div
            v-if="!activeQuestion.complete"
            class="st-specifications__alert-row"
          >
            <icon-wrapper
              class="st-new-question-tile__alert-icon"
              icon-name="alert"
            />
            <p class="st-specifications__alert-description">
              This question is incomplete.
            </p>
          </div>
          <specifications
            :key="activeQuestion.id"
            :is-read-only="true"
          />
        </div>
        <div class="bx--offset-md-1 bx--col-md-4 bx--offset-sm-1 bx--col-sm-5">
          <div
            class="st-new-question__virtual-phone-preview"
          >
            <preview-example
              :survey-name="surveyName"
              :survey-question-count="questions.length"
            />
          </div>
          <!-- <div class="st-new-question__help-icon-container">
            <a
              id="Intercom"
              href="javascript:void(o)"
            >
              <icon-wrapper
                icon-name="help"
                class="st-new-question__help-icon"
              />
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { SPECS_TABS } from '../../../utilities/enums'
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StTooltip
} from '../../common'

import { isDesktop } from '../../../mixins'
import { QUESTION_DESCRIPTIONS } from './constants'
import NewMetricTile from './new-metric-tile'
import PreviewExample from './preview-example'
import Specifications from './specifications'

export default {
  components: {
    IconWrapper,
    NewMetricTile,
    PreviewExample,
    Specifications,
    StButton,
    StHyperlink,
    StTooltip
  },
  mixins: [isDesktop],
  props: {
    template: {
      type: Object,
      required: true
    },
    showUseTemplate: {
      type: Boolean,
      required: false,
      default: false
    },
    surveyBackId: {
      type: Number,
      required: false,
      default: 0
    },
    isSurvey: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      QUESTION_DESCRIPTIONS,
      SPECS_TABS,
      surveyName: this.template.name,
      showQuestions: true
    }
  },

  computed: {
    ...mapState('specifications', ['activeQuestionIdx', 'activeTab', 'questions']),
    ...mapGetters('specifications', ['activeQuestion']),
    activeMetricId () {
      if (this.activeQuestionIdx === -1) { return null }

      return this.activeQuestion.id
    },
    activeMetricName () {
      if (this.activeQuestionIdx > -1) {
        return this.activeQuestion.questionType === 'culture_board' ? 'Image Board' : this.activeQuestion.questionType.split('_').join(' ')
      } else {
        return ''
      }
    },
    activeMetricDescription () {
      if (this.activeQuestionIdx > -1) {
        return this.QUESTION_DESCRIPTIONS.filter(list => {
          return list.questionType === this.activeQuestion.questionType
        })[0]
      } else {
        return { description: '' }
      }
    },
    noQuestions: function () {
      return this.questions.length === 0
    },
    questionIds () {
      return this.questions.map(question => question.id)
    },
    showQuestionsPanel () {
      return this.isDesktop ? true : this.showQuestions
    }
  },
  mounted () {
    this.$nextTick(() => {
      let activeQuestionIdx = 0
      this.changeTab(SPECS_TABS.SPECIFICATIONS)
      this.replaceQuestions(this.template.questions)
      this.setActiveQuestionIdx(activeQuestionIdx)
      this.$store.commit('hideSidebar', true)
    })
  },
  methods: {
    ...mapMutations('specifications', ['changeTab', 'setActiveQuestionIdx', 'replaceQuestions']),
    ...mapActions('specifications', ['deleteQuestionIdx']),
    handleBackLink () {
      if (!this.isDesktop && !this.showQuestions && this.questions.length > 0) {
        this.showQuestions = true
      } else {
        this.rerouteViaBackLink()
      }
    },
    rerouteViaBackLink () {
      let url = '/templates'
      if (this.isSurvey) {
        url = `/study/${this.template.surveyId}/edit`
      } else if (this.showUseTemplate) {
        url = `/study/${this.surveyBackId}/select_template/`
      }

      window.location = url
    },
    setActive (idx) {
      this.changeTab(SPECS_TABS.SPECIFICATIONS)
      this.setActiveQuestionIdx(idx)
      this.showQuestions = false

      let state = { 'survey_id': this.surveyId, 'question_type': this.questions[idx].questionType }
      let title = this.questions[idx].questionType
      let url = '?question=' + this.questions[idx].id + '&type=' + this.questions[idx].questionType

      window.history.replaceState(state, title, url)
    },
    showSpecifications (boolean) {
      if (boolean) { this.changeTab(SPECS_TABS.SPECIFICATIONS) }
    },
    useTemplate () {
      let url = `/study/${this.surveyBackId}/use_template`

      this.$axios.request({
        method: 'post',
        url: url,
        data: { survey: { template_id: this.template.id } }
      })
        .then(res => { window.location = `/study/${this.surveyBackId}/edit/content` })
        .catch(err => { console.log(err) })
    }
  }
}
</script>
