<template>
  <div class="">
    <template v-if="isEqual()">
      <report-renderer
        :version-description="firstVersion"
      />
    </template>
    <template v-else-if="isParentLoaded">
      <report-changes-renderer
        v-if="showChanges ==='removed'"
        :version-description="firstVersion"
        :changes="diff"
        :show-changes="showChanges"
      />
      <report-changes-renderer
        v-else-if="showChanges ==='added'"
        :version-description="secondVersion"
        :changes="diff"
        :show-changes="showChanges"
      />
    </template>
  </div>
</template>

<script>
import {
  YouchamaJsonDiffer,
  ListItemFieldMatchOperator,
  make_ignore_order_func
} from 'jycm'
import ReportRenderer from './new-report-description.vue'
import ReportChangesRenderer from './report-changes-renderer.vue'
export default {
  name: 'NestedChangesList',
  components: {
    ReportRenderer,
    ReportChangesRenderer
  },
  props: {

    showChanges: {
      type: String,
      required: true
    },
    firstVersion: {
      type: [Object, Array],
      required: true
    },
    secondVersion: {
      type: [Object, Array],
      required: true
    }
  },
  data () {
    return {

      isParentLoaded: false,
      diff: {

      }
    }
  },
  // watch for versions to change and get the difference
  watch: {
    firstVersion: {
      handler: function () {
        this.getDifference()
      },
      deep: true
    },
    secondVersion: {
      handler: function () {
        this.getDifference()
      },
      deep: true
    }
  },
  mounted () {
    this.getDifference()
    // console.log('removedItems', diff)
  },
  methods: {
    getDifference () {
      const ycm = new YouchamaJsonDiffer(this.firstVersion, this.secondVersion, {
      })
      const diff = ycm.get_diff()
      // console.log('diff', this.showChanges, diff)
      this.diff = diff
      this.isParentLoaded = true
    },
    isEqual (obj1 = this.firstVersion, obj2 = this.secondVersion) {
      const obj1Keys = Object.keys(obj1)
      const obj2Keys = Object.keys(obj2)

      if (obj1Keys.length !== obj2Keys.length) {
        return false
      }

      for (let objKey of obj1Keys) {
        if (obj1[objKey] !== obj2[objKey]) {
          if (typeof obj1[objKey] === 'object' && typeof obj2[objKey] === 'object') {
            if (!this.isEqual(obj1[objKey], obj2[objKey])) {
              return false
            }
          } else {
            return false
          }
        }
      }

      return true
    }

  }
}
</script>

<style lang="scss" scoped>
/* Add your styles if needed */
</style>
