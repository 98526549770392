<template>
  <div
    :class="classes"
    @click="containerClicked"
  >
    <div>
      <quill-editor
        ref="myTextEditor"
        class="editor"
        :value="content"
        :options="editorOption"
        @change="onEditorChange"
        @blur="onEditorBlur($event)"
      >
        <!-- :id="`toolbar-${objectIndex}`" -->
        <div
          v-show="showToolbar"
          slot="toolbar"
          class="editor-toolbar"
          @click="preventBlurOnSafari($event)"
        >
          <!-- <select class="ql-font">
          <option
            selected
            value="serif"
          />
          <option
            v-for="font in fonts"
            :key="font"
            :value="font"
          />
        </select> -->
          <!-- <select class="ql-size">
          <option selected />
          <option
            v-for="size in fontSizes"
            :key="size"
            :value="size"
          />
        </select> -->
          <button class="ql-bold">
            Bold
          </button>
          <button
            class="ql-list"
            value="bullet"
          />
          <button
            class="ql-link"
            value="link"
          />
          <select class="ql-color" />
        <!-- <button
          class="ql-custom-button"
          @click="removeText"
        >
          <icon-wrapper icon-name="trash" />
        </button> -->
        </div>
      </quill-editor>
    </div>
  </div>
</template>

<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import { mapGetters } from 'vuex'
// import { IconWrapper } from '../../../../common'
// import { EDITOR_FONTS, EDITOR_FONT_SIZES } from '../constants'

const EDITOR_FONTS = [
  'monospace',
  'arial',
  'courier',
  'garamond',
  'tahoma',
  'verdana'
]

const EDITOR_FONT_SIZES = [
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px'
]
// specify the fonts you would
let fonts = EDITOR_FONTS
// generate code friendly names
function getFontName (font) {
  return font.toLowerCase().replace(/\s/g, '-')
}
let fontNames = fonts.map((font) => getFontName(font))
// add fonts to style
let fontStyles = ''
fonts.forEach(function (font) {
  let fontName = getFontName(font)
  fontStyles +=
  `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, 
   .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before 
   {content: '${font}'; font-family: '${font}', sans-serif;}
   .ql-font-${fontName} { font-family: '${font}', sans-serif; }
  `
})

let Font = Quill.import('formats/font')
const sizeStyle = Quill.import('attributors/style/size')
Font.whitelist = fontNames
sizeStyle.whitelist = EDITOR_FONT_SIZES
Quill.register(sizeStyle, true)
Quill.register(Font, true)

export default {
  name: 'QuillExampleSnow',
  title: 'Theme: snow',
  components: {
    quillEditor
    // IconWrapper
  },
  // mixins: [updateSlideQuestionObject],
  props: {
    classes: {
      type: String,
      default: ''
    },
    // object: {
    //   type: Object,
    //   default: () => {}
    // },
    blur: {
      type: Function,
      default: () => {}
    },
    keyup: {
      type: Function,
      default: () => {}
    },
    description: {
      type: String,
      default: ''
    }
    // objectIndex: {
    //   type: Number,
    //   default: null
    // }
  },
  data () {
    return {
      fonts: EDITOR_FONTS,
      fontSizes: EDITOR_FONT_SIZES,
      showToolbar: false,
      editorOption: {

        modules: {
          // toolbar: `#toolbar-${this.objectIndex}`
        }
      },
      content: this.description || '',
      clicked: false,
      debounceTimeoutId: null
    }
  },
  computed: {
    ...mapGetters('specifications', ['activeQuestion', '"objects",']),
    editor () {
      return this.$refs.myTextEditor.quill
    },
    contentCode () {
      return this.content.value
    }
  },
  beforeMount () {
    // if (this.object.content) this.content = this.object.content
  },
  mounted () {
    // console.log('mounted', this.description)
    var node = document.createElement('style')
    node.innerHTML = fontStyles
    document.body.appendChild(node)

    // document.querySelectorAll('.ql-editor')[this.object.index].addEventListener('click', this.onEditorClick)
  },

  methods: {
    containerClicked (e) {
      e.stopPropagation()
    },
    // ...mapMutations('specifications', ['updateObject', 'removeObject']),
    onEditorChange ({ quill, html, text }) {
      if (this.debounceTimeoutId) clearTimeout(this.debounceTimeoutId)
      this.content = html
      // console.log('html', html)
      this.$emit('keyup', html)
      // this.updateObject({
      //   type: 'text',
      //   id: this.object.id,
      //   content: html
      // })
      this.debounceTimeoutId = setTimeout(() => {
        // this.handleUpdateQuestion()
        this.debounceTimeoutId = null
      }, 300)
    },
    onEditorBlur (editor) {
      setTimeout(() => {
        if (this.clicked) {
          this.clicked = false
          return
        }
        // console.log('blur', this.content)
        this.$emit('blur', this.content)
        // this.handleUpdateQuestion()
        this.showToolbar = false
      }, 100)
    },
    onEditorClick () {
      this.editor.focus()
      this.showToolbar = true
    },

    removeText () {
      // this.removeObject(this.object.id)
      // this.deleteSlideObject(this.object.id)
    },
    preventBlurOnSafari (event) {
      this.clicked = true
      setTimeout(() => {
        this.clicked = false
      }, 200)
    }
  }
}
</script>
