<template>
  <div class="tw-w-full tw-h-[calc(100vh-200px)] tw-flex tw-flex-col tw-justify-center ">
    <div>
      <div class="tw-max-w-[630px] tw-flex tw-flex-col tw-mx-auto tw-w-full">
        <img
          class="tw-w-[76px] tw-h-[70px] tw-mx-auto"
          :src="reportIcon"
          alt=""
        >
        <p class="tw-text-[30px] tw-mt-5 tw-font-semibold tw-text-center">
          {{ reportName }}
        </p>
        <p class="tw-mt-5 tw-text-lg tw-text-center tw-px-4 ">
          You were invited to give feedback on this report.  Your feedback will be used to create a new version of the report.  Please fill in the fields below to access the report.
          <!-- You were invited as a guest contributor to give feedback on {{ reportName }}. Please fill in the fields below to access the report. -->
        </p>
        <ah-input
          v-model="name"
          class="tw-mt-5"
          label="Full name"
          name="full name"
          placeholder="Full name"
        />
        <span
          v-if="nameError"
          class="tw-text-sm tw-text-negative tw-mt-2"
        >
          Please enter your full name
        </span>
        <ah-input
          v-model="email"
          class="tw-mt-5"
          label="Email"
          name="Email"
          placeholder="Email"
        />
        <span
          v-if="emailError"
          class="tw-text-sm tw-text-negative tw-mt-2"
        >
          Please enter your email
        </span>
        <div class="tw-w-full tw-flex tw-justify-center">
          <ah-button
            class="tw-mt-5"
            size="large"
            label="Access report"
            @click="handleAccessReport"
          >
            Access report
          </ah-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportIcon from '../../../../assets/images/reports-icon.png'
import { AhInput, AhButton } from '../../common'
export default {
  components: {
    AhInput,
    AhButton
  },
  data () {
    return {
      name: '',
      email: '',
      reportIcon: ReportIcon,
      nameError: null,
      emailError: null,
      id: null,
      shareToken: null,
      version: null,
      reportName: null

    }
  },
  mounted () {
    // get the id from the url using New URLSearchParams
    const urlParams = new URLSearchParams(window.location.search)
    this.id = urlParams.get('id')
    this.shareToken = urlParams.get('share_token')
    this.version = urlParams.get('version')
    this.reportName = urlParams.get('name')
  },
  methods: {
    handleAccessReport () {
      // get the id from the url using New URLSearchParams
      // const urlParams = new URLSearchParams(window.location.search)
      // const id = urlParams.get('id')
      // const shareToken = urlParams.get('share_token')
      // const version = urlParams.get('version')

      if (!this.name) {
        this.nameError = true
        return
      }
      if (!this.email) {
        this.emailError = true
        return
      }
      if (this.name && this.email) {
        this.$axios.post(`guest_users?id=${this.id}&share_token=${this.shareToken}&version=${this.version}`, {
          full_name: this.name,
          email: this.email
        }).then((res) => {
          console.log(res)
          const link = window.location.origin + `/reports/${this.id}?guest_token=${res.data.result.guest_token}&share_token=${this.shareToken}&version=${this.version}`
          // console.log(link)
          window.location.href = link
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<style>

</style>
