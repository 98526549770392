var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('st-drawer',{on:{"closeModal":_vm.closingModal},scopedSlots:_vm._u([{key:"modalTitle",fn:function(){return [_c('div',{staticClass:"flex justify-between  "},[_c('span',{staticClass:"font-18p"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")])])]},proxy:true},{key:"modalActions",fn:function(){return [_c('div',{staticClass:"tw-flex tw-space-x-1 tw-justify-between  tw-h-full tw-flex-col"},[_c('div',[_c('div',{staticClass:"tw-bg-black tw-text-white tw-px-10 tw-py-12"},[_c('p',{staticClass:"tw-text-[30px] tw-mt-2 tw-leading-tight tw-font-semibold"},[_vm._v("\n            "+_vm._s(_vm.description)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"tw-px-10"},[_c('div',{staticClass:"tw-py-4 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"},[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n              Study\n            ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n              "+_vm._s(_vm.study.name)+"\n            ")])]),_vm._v(" "),(_vm.action == 'upgrade' || _vm.action == 'downgrade')?_c('div',{staticClass:"tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid tw-flex tw-space-x-3 tw-justify-between"},[_c('div',[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n                Current plan\n              ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n                "+_vm._s(_vm.currentStudyBasePlan && _vm.currentStudyBasePlan.title)+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-[15px]"},[_vm._v("\n                $"+_vm._s(_vm.currentStudyBasePlan &&
                    _vm.currentStudyBasePlan.price.toLocaleString())+"\n                per month\n              ")])]),_vm._v(" "),_c('div',[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n                New plan\n              ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n                "+_vm._s(_vm.newPlan && _vm.newPlan.title)+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-[15px]"},[_vm._v("\n                $"+_vm._s(_vm.newPlan && _vm.newPlan.price.toLocaleString())+" per month\n              ")])])]):_vm._e(),_vm._v(" "),(_vm.answersPlan)?_c('div',{staticClass:"tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"},[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n              Add on\n            ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n              "+_vm._s(_vm.type == "answers" ? "Answers" : "Answers & Reports")+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-[15px]"},[_vm._v("\n              $"+_vm._s(_vm.answersPlan && _vm.answersPlan.price.toLocaleString())+" per\n              month\n            ")])]):_vm._e(),_vm._v(" "),(_vm.action != 'remove')?[(
                _vm.checkCreditBalance &&
                  _vm.paymentInformation &&
                  !_vm.loadingPaymentInformation
              )?_c('div',{staticClass:"tw-py-3 tw-h-full tw-flex tw-justify-between tw-items-center tw-border-b tw-border-b-white-two tw-border-solid"},[_c('div',[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n                  Payment method\n                ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n                  "+_vm._s(_vm.paymentInformation.brand)+" ending\n                  "+_vm._s(_vm.paymentInformation.last4)+"\n                ")])]),_vm._v(" "),_c('p',{staticClass:"tw-text-sm tw-text-primary tw-cursor-pointer",on:{"click":_vm.changePaymentPlan}},[_vm._v("\n                Change payment method\n              ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-py-3  tw-h-full "},[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n                Credit balance\n              ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n                "+_vm._s(_vm.creditBalance >= 0
                    ? ("$" + (_vm.creditBalance.toLocaleString()))
                    : "Calculating...")+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"tw-py-3  tw-h-full "},[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n                To be charged\n              ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n                "+_vm._s(_vm.proratedPrice !== null
                    ? ("$" + (_vm.proratedPrice.toLocaleString()))
                    : "Calculating...")+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"tw-py-3  tw-h-full "},[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n                Remaining credit balance\n              ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n                "+_vm._s(_vm.remainingCreditBalance !== null
                    ? ("$" + (_vm.remainingCreditBalance.toLocaleString()))
                    : "Calculating...")+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"tw-py-3  tw-h-full "},[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n                Total\n              ")]),_vm._v(" "),_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n                "+_vm._s(_vm.currentAmountDue >= 0
                    ? ("$" + (_vm.currentAmountDue.toLocaleString()))
                    : "Calculating...")+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"tw-py-3  tw-h-full "},[_c('p',{staticClass:"tw-text-sm tw-text-black-three"},[_vm._v("\n                To be charged every month after\n              ")]),_vm._v(" "),(_vm.newPlan)?_c('p',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v("\n                "+_vm._s(_vm.newPlan
                    ? ("$" + (_vm.newPlan.price.toLocaleString()))
                    : "Calculating...")+"\n              ")]):_vm._e()])]:_vm._e()],2)]),_vm._v(" "),(!_vm.loadingPaymentInformation)?_c('div',{staticClass:"tw-px-10"},[(!_vm.checkCreditBalance && !_vm.paymentInformation)?_c('button',{staticClass:"tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full",on:{"click":_vm.changePaymentPlan}},[_vm._v("\n          Add payment method\n        ")]):_c('button',{staticClass:"tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full",on:{"click":_vm.upgradeDowngradeHandler}},[_vm._v("\n          "+_vm._s(_vm.buttonTextUpgradingDowngrading)+"\n        ")])]):_vm._e()])]},proxy:true}]),model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}},[_vm._v(" "),_vm._v(" "),_c('stripe-payment-form',{ref:"stripeForm",attrs:{"type":"update"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }