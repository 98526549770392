<template>
  <div class="tw-flex-1 tw-flex tw-flex-col">
    <div
      class="tw-flex-1 tw-flex tw-h-full tw-flex-col tw-top-0 tw-right-0 tw-bg-white tw-overflow-y-auto scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black tw-text-sm"
      @click="stopPropagation"
    >
      <div

        class="tw-flex  tw-bg-white tw-z-10 tw-justify-between  tw-px-8 tw-items-center tw-py-2 tw-border-y tw-border-solid tw-border-white-two"
      >
        <div
          class="tw-flex tw-sticky tw-top-0 tw-bg-white tw-z-10 tw-justify-between tw-w-full  tw-items-center tw-py-1 "
        >
          <div class="tw-flex tw-justify-between tw-w-full">
            <div
              class="tw-flex tw-cursor-pointer relative  tw-space-x-2"
              @click.stop="toggleDropdownOptions"
            >
              <ah-button type="outlined">
                <span class="tw-space-x-0.5 tw-min-w-fit">
                  <span>
                    {{ selectedDropdownOption }}
                  </span>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_393_1239)">
                      <path
                        d="M5.01197 5.50036C4.83774 5.50036 4.66651 5.43667 4.54035 5.32086L0.698222 1.60922C0.43387 1.35734 0.43387 0.946223 0.695218 0.691446C0.956567 0.436669 1.38314 0.436669 1.64749 0.688551L5.01498 3.94564L8.37946 0.688551C8.65283 0.44825 9.07639 0.468516 9.32573 0.731979C9.55703 0.97807 9.55703 1.35445 9.32573 1.60054L5.4836 5.32086C5.35743 5.43667 5.18621 5.50326 5.01197 5.50036Z"
                        fill="#474747"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_393_1239">
                        <rect
                          width="5"
                          height="9"
                          fill="white"
                          transform="translate(9.5 0.5) rotate(90)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </ah-button>
              <div
                v-if="showDropdown"
                class="flex flex-col justify-center !tw-text-sm !tw-min-w-fit !tw-w-[170px] !tw-right-auto tw-left-0 !tw-top-10 cursor-pointer tw-overflow-hidden absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
              >
                <span
                  v-for="(option) in dropdownOptions"
                  :key="option"
                  class=""
                  @click="changeSelectedDropdownOption($event, option)"
                >
                  {{ option }}
                </span>
              </div>
            </div>
            <div
              v-if="showFeedbacks"
              class="tw-flex tw-space-x-3 tw-text-sm tw-items-center"
            >
              <span>
                Show colleagues
              </span>
              <ah-toggle-switch
                id="members-toggle"
                class="tw-ml-2"
                :checked="showMemberNames"
                @input="toggleShowMembers"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showFeedbacks && !showThemes"
        class="tw-flex tw-flex-col tw-justify-between tw-flex-1  tw-overflow-y-auto scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black "
      >
        <div class="tw-max-w-full">
          <div
            v-if="showFeedbacks && version"
            class="tw-py-2 "
          >
            <div
              v-for="(userFeedback, index) in version.feedbacks"
              :key="index + 'feedback'"
              class="tw-flex tw-flex-col tw-py-4 tw-px-8 tw-my-1 tw-border-b tw-border-solid tw-border-white-two"
            >
              <div>
                <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
                  <div

                    class="tw-flex tw-space-x-2  tw-w-full tw-items-center"
                  >
                    <template v-if="showMemberNames">
                      <img
                        v-if="userFeedback.feedbackUser.avatarUrl"
                        :src="userFeedback.feedbackUser.avatarUrl"
                        width="34"
                        height="34"
                        class="tw-object-cover tw-rounded-full"
                      >
                      <span
                        v-else
                        class="tw-rounded-full tw-px-3 tw-py-[6px] tw-text-sm tw-bg-blue-500 tw-text-white tw-capitalize"
                      >
                        {{ userFeedback.feedbackUser && userFeedback.feedbackUser.fullName && userFeedback.feedbackUser.fullName[0] }}
                        {{ userFeedback.feedbackGuestUser && userFeedback.feedbackGuestUser.fullName && userFeedback.feedbackGuestUser.fullName[0] }}
                      </span>
                    </template>
                    <div
                      class="tw-flex tw-w-full   tw-items-center"
                      :class="showMemberNames? 'tw-justify-between': 'tw-justify-end'"
                    >
                      <template v-if="showMemberNames">
                        <span
                          v-if="userFeedback.feedbackUser"
                          class="tw-text-[15px] tw-w-full tw-font-semibold tw-capitalize"
                        >
                          {{
                            userFeedback.feedbackUser.id == currentUser.id
                              ? "You"
                              : userFeedback.feedbackUser.fullName
                          }}
                          <span v-if="userFeedback.feedbackGuestUser">
                            {{
                              userFeedback.feedbackGuestUser && userFeedback.feedbackGuestUser.fullName && userFeedback.feedbackGuestUser.fullName
                            }}
                          </span>
                        </span>
                      </template>
                      <button
                        :class="getLevelText(userFeedback.level).style"
                        class="tw-flex tw-min-w-fit tw-text-xs tw-justify-between tw-space-x-2 tw-py-1 tw-rounded-xl tw-px-1.5 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                      >
                        {{ getLevelText(userFeedback.level).text }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="tw-my-2 tw-space-x-2 tw-items-center tw-text-sm">
                  <span
                    class=""
                    :class="
                      !showOriginalCommentIds.includes(userFeedback.id)
                        ? 'tw-text-primary  '
                        : 'tw-text-black-one hover:tw-text-primary tw-cursor-pointer hover:tw-underline'
                    "
                    @click="addRemoveToOriginalComment(userFeedback.id)"
                  >
                    Summary
                  </span>
                  <span
                    class=""
                    :class="
                      !showOriginalCommentIds.includes(userFeedback.id)
                        ? 'tw-text-black-one tw-cursor-pointer hover:tw-text-primary  hover:tw-underline'
                        : 'tw-text-primary  '
                    "
                    @click="addRemoveToOriginalComment(userFeedback.id)"
                  >
                    Original comment
                  </span>
                </div>
                <p
                  v-if="showOriginalCommentIds.includes(userFeedback.id)"
                  class=" tw-text-sm"
                >
                  <span v-if="userFeedback.transcript">
                    {{ userFeedback.transcript }}

                    <span
                      v-if="
                        userFeedback.explanationType == 'audio'
                      "
                      class="tw-py-2 tw-block"
                    >
                      <audio
                        controls
                        class="tw-h-10"
                      >
                        <source
                          :src="userFeedback.audioUrl || localAudioURL"
                          :type="userFeedback.contentType||'audio/mp3'"
                        >

                        Your browser does not support the audio element.
                      </audio>
                    </span>
                  </span>
                  <span v-else>
                    <span class="tw-py-1 tw-block">
                      No comment available
                    </span>
                    <span
                      v-if="
                        userFeedback.explanationType == 'audio'
                      "
                      class="tw-py-2 tw-block"
                    >
                      <audio
                        :ref="userFeedback.audioUrl? 'audioPlayer' : 'localAudioPlayer'"
                        controls
                        class="tw-h-10"
                      >
                        <source
                          :src="userFeedback.audioUrl || localAudioURL"
                          :type="userFeedback.contentType||'audio/mp3'"
                        >

                        Your browser does not support the audio element.
                      </audio>
                    </span>

                  </span>
                </p>
                <p
                  v-else
                  class=" tw-text-sm"
                >
                  <span v-if="userFeedback.summary">
                    {{ userFeedback.summary }}
                  </span>
                  <span v-else>
                    No summary available
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="!showFeedbacks && showThemes"
        class=""
      >
        <div
          v-for="(versionTheme, idx) in version.theme"
          :key="`${idx}_theme`"
          class=" tw-my-1 tw-border-b tw-px-8 tw-py-3 tw-border-solid tw-border-white-two"
        >
          <p class=" tw-font-semibold tw-my-1 tw-capitalize">
            {{ versionTheme.heading }}
          </p>
          <p>
            {{ versionTheme.description }}
          </p>
        </div>
      </div>
      <div
        v-else-if="!showFeedbacks && !showThemes && showSources"
        class="tw-py-1"
      >
        <div
          v-for="(study, index) in strategy.surveys"
          :key="study.name"
          class="tw-px-8 tw-flex tw-justify-between tw-items-center  tw-py-2"
          :class="{
            'tw-border-b tw-border-white-two tw-border-solid ': index != strategy.surveys.length - 1
          }"
        >
          <p class="tw-flex tw-flex-col ">
            <span class=" tw-text-base">
              {{ study.name }}
            </span>
            <span class="tw-text-sm tw-mt-1 tw-space-x-1">
              <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.50558 6.50013C7.15973 6.50013 8.45965 5.18022 8.45965 3.50006C8.45965 1.8199 7.15999 0.5 5.50558 0.5C3.85144 0.5 2.55151 1.82016 2.55151 3.50006C2.55151 5.17997 3.85144 6.50013 5.50558 6.50013ZM11 11.78C10.232 8.72003 7.15973 6.79995 4.14667 7.58012C2.13804 8.12012 0.542885 9.68019 0.0111674 11.78C-0.0478279 12.0801 0.129411 12.4401 0.48389 12.5H10.4093C10.7638 12.5 11 12.2601 11 11.9001V11.78Z"
                  fill="#A7AA93"
                />
              </svg>
              <span class="">
                {{ study.totalResponsesCompleted }}
              </span>

            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AhButton,
  StDropdownMenu,
  AhInput,
  StModal,
  AhToggleSwitch
} from '../../common'
export default {

  name: 'AskhumansFeedbacksStaticView',
  components: {
    AhButton,
    StDropdownMenu,
    AhInput,
    StModal,
    AhToggleSwitch
  },
  props: {
    strategy: {
      type: Object,
      default: () => ({})
    },
    version: {
      type: Object,
      default: () => ({})
    },
    currentUser: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      showFeedbacks: true,
      showMemberNames: true,
      showThemes: false,
      showDropdown: false,
      showSources: false,
      showLevelDropdownId: null,
      showOriginalCommentIds: [],
      dropdownOptions: ['Feedback by colleague', 'Feedback by theme', 'Sources'],
      selectedDropdownOption: 'Feedback'

    }
  },

  mounted () {
  },

  methods: {
    toggleShowMembers () {
      this.showMemberNames = !this.showMemberNames
    },
    toggleDropdownOptions () {
      this.showDropdown = !this.showDropdown
    },
    getLevelText (level) {
      switch (level) {
        case 0:
          return {
            text: 'Skipped',
            style: 'tw-text-back tw-bg-neutral'
          }
        case 1:
          return {
            text: 'Weighted moderately',
            style: 'tw-text-white tw-bg-[#4C4C4C]'
          }
        case 2:
          return {
            text: 'Weighted heavily',
            style: 'tw-text-white tw-bg-[#6B806E]'
          }
        default:
          return {
            text: '',
            style: ''
          }
      }
    },
    addRemoveToOriginalComment (id) {
      if (this.showOriginalCommentIds.includes(id)) {
        this.showOriginalCommentIds = this.showOriginalCommentIds.filter(
          (commentId) => commentId !== id
        )
      } else {
        this.showOriginalCommentIds = [...this.showOriginalCommentIds, id]
      }
    },
    stopPropagation (e) {
      e.stopPropagation()
    },
    // changeSelectedDropdownOption (e, option) {
    //   e.stopPropagation()
    //   this.selectedDropdownOption = option
    //   this.showDropdown = false

    //   // const tabName = option == 'Feedbacks' ? 'feedbacks' : 'studies'
    //   // this.$emit('changeTab', tabName)
    // }
    changeSelectedDropdownOption (e, option) {
      e.stopPropagation()
      this.selectedDropdownOption = option
      this.showDropdown = false
      if (option == this.dropdownOptions[0]) {
        this.showFeedbacks = true
        this.showThemes = false
        this.showSources = false
      } else if (option == this.dropdownOptions[1]) {
        this.showFeedbacks = false
        this.showThemes = true
        this.showSources = false
      } else if (option == this.dropdownOptions[2]) {
        this.showFeedbacks = false
        this.showThemes = false
        this.showSources = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
