<template>
  <div>
    <st-toast-notification
      v-if="showToast"
      class="st-toast-notification--fixed"
      kind="success"
      title="Submitted"
      caption="The response has been submitted successfully."
      @click="showToast = false"
    />
    <div class="tw-flex tw-mt-5 tw-space-x-2 tw-px-2">
      <secondary-button
        :selected="secondaryFilter === 'all'"
        @click="changeSecondaryFilter('all')"
      >
        All
      </secondary-button>
      <secondary-button
        :selected="secondaryFilter === 'isAnswer'"
        @click="changeSecondaryFilter('isAnswer')"
      >
        W/ Answers
      </secondary-button>
      <secondary-button
        :selected="secondaryFilter === 'isAnswersAndStrategies'"
        @click="changeSecondaryFilter('isAnswersAndStrategies')"
      >
        W/ Reports
      </secondary-button>
    </div>
    <div
      class="tw-my-5 tw-flex tw-flex-col tw-justify-between tw-h-full tw-min-h-[50vh]"
    >
      <div class="tw-grid tw-grid-cols-12">
        <div class="tw-col-span-12 tw-grid tw-grid-cols-12 tw-border-b-0 ">
          <div
            class="tw-px-6 tw-block tw-py-3 tw-text-sm tw-border-b tw-border-b-white-two tw-border-solid"
          >
            Status
          </div>
          <div class=" tw-col-span-3  tw-text-sm  ">
            <span
              class="tw-border-b tw-border-b-white-two  tw-border-solid tw-py-3 tw-px-2 tw-block"
            >
              Study
            </span>
          </div>

          <div
            class="tw-px-2 tw-py-3 tw-text-sm  tw-border-b tw-border-b-white-two tw-border-solid"
          >
            Values
          </div>
          <div
            class="tw-px-2 tw-py-3 tw-col-span-3 tw-text-sm   tw-border-b tw-border-b-white-two tw-border-solid"
          >
            Plans
          </div>
          <div
            class="tw-px-2 tw-py-3 tw-text-sm tw-col-span-1  tw-border-b tw-border-b-white-two tw-border-solid"
          >
            Satisfaction
          </div>
          <div
            class="tw-col-span-3 tw-px-2 tw-py-3  tw-border-b tw-border-b-white-two tw-border-solid tw-text-center"
          >
            Action
          </div>
        </div>
        <template v-if="account.studies.length">
          <div
            v-for="study in account.studies"
            :key="study.id"
            class="tw-col-span-12 tw-grid-cols-12 tw-"
          >
            <div class="tw-col-span-12 tw-grid tw-grid-cols-12">
              <div
                class="tw-py-3 tw-col-span-1 tw-flex tw-justify-center tw-items-center "
              >
                <study-status :type="study.studyStatus">
                  <span class="tw-capitalize">
                    {{ study.studyStatus }}
                  </span>
                </study-status>
              </div>
              <div class="tw-py-3 tw-col-span-3 tw-border-solid tw-relative ">
                <div class="tw-flex tw-items-center tw-mt-3 tw-space-x-2">
                  <div>
                    <p class="tw-font-semibold">
                      {{ study.studyName }}
                    </p>
                    <p class="tw-text-sm">
                      {{ study.totalRespondents }} respondents
                    </p>
                  </div>
                </div>
              </div>

              <div class="tw-px-2 tw-py-3 tw-text-sm tw-flex tw-items-center">
                ${{ study.values }}
              </div>
              <div
                class="tw-px-2 tw-py-3 tw-col-span-3 tw-flex-wrap tw-text-sm tw-flex tw-gap-2 tw-justify-start tw-items-center"
              >
                <span
                  v-for="plan in study.plans"
                  :key="plan.planName"
                  class="tw-px-3 tw-block tw-py-1 tw-border tw-min-w-fit tw-border-solid tw-border-gray-200 tw-rounded-3xl tw-bg-[#A7AA93] tw-text-white"
                  >{{ plan.title }}</span
                >
              </div>
              <div
                class="tw-px-2 tw-py-3 tw-col-span-1 tw-text-sm tw-text-center"
              >
                {{ study.satisfaction }}
              </div>
              <div
                class="tw-px-2 tw-col-span-3 tw-flex  tw-space-x-2 tw-justify-center tw-items-start tw-py-3 tw-text-sm"
              >
                <button
                  class="tw-px-2 tw-py-1 tw-border tw-border-gray-200 disabled:tw-cursor-not-allowed tw-rounded-md tw-border-solid tw-text-xs tw-cursor-pointer"
                  :disabled="
                    study.studyStatus == 'draft' || study.totalRespondents == 0
                  "
                  @click="redirectToStudyPlayground(study.studyId)"
                >
                  Edit prompt
                </button>
                <span
                  v-if="study.studyStatus == 'draft'"
                  class="tw-px-2 tw-py-1 tw-border tw-border-gray-200 tw-rounded-md tw-border-solid tw-text-xs tw-cursor-pointer"
                  @click="publishStudy(study.studyId)"
                >
                  Publish
                </span>
                <div
                  v-else-if="study.studyStatus == 'active'"
                  class="tw-min-w-fit tw-gap-2 tw-flex tw-items-center"
                >
                  <span
                    class="tw-px-2 tw-py-1 tw-border disabled:tw-cursor-not-allowed tw-border-gray-200 tw-rounded-md tw-border-solid tw-text-xs tw-cursor-pointer"
                    @click="unpublishStudy(study.studyId)"
                  >
                    Unpublish
                  </span>
                  <span
                    v-if="!isAnswersAdded(study.studyId)"
                    class="tw-px-2 tw-py-1 tw-border disabled:tw-cursor-not-allowed tw-border-gray-200 tw-rounded-md tw-border-solid tw-text-xs tw-cursor-pointer"
                    @click="addAnswers(study.studyId)"
                  >
                    Add Answers
                  </span>
                  <span
                    v-if="!isStrategiesAdded(study.studyId)"
                    class="tw-px-2 tw-py-1 tw-border disabled:tw-cursor-not-allowed tw-border-gray-200 tw-rounded-md tw-border-solid tw-text-xs tw-cursor-pointer"
                    @click="addStrategies(study.studyId)"
                  >
                    Add Reports
                  </span>
                  <span
                    class="tw-px-2 tw-py-1 tw-border disabled:tw-cursor-not-allowed tw-border-gray-200 tw-rounded-md tw-border-solid tw-text-xs tw-cursor-pointer"
                    @click="addResponse(study.studyId)"
                  >
                    Add Response
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="tw-col-span-12 tw-grid-cols-12 tw-mt-10">
          <p
            class="tw-text-center tw-text-2xl tw-font-semibold tw-col-span-12 tw-mt-5"
          >
            No studies found!
          </p>
        </div>
      </div>
      <Pagination
        v-if="account.studies.length > 0"
        :total-entries="account.totalEntries"
        :total-pages="account.totalPages"
        :per-page="account.perPage"
        :current-page="account.currentPage"
        title="Studies"
        @change-page="changePage"
      />
    </div>
    <st-modal v-if="showPublishStudyModal" v-model="showPublishStudyModal">
      <h3 class="font-600">
        Publish Study
      </h3>
      <p class="tw-my-3">
        Are you sure you want to publish this study?
      </p>
      <div class="flex justify-end mt-2">
        <ah-button @click="confirmPublishStudy">
          Publish study
        </ah-button>
      </div>
    </st-modal>
    <st-modal v-if="showUnpublishModal" v-model="showUnpublishModal">
      <h3 class="font-600">
        Unpublish Study
      </h3>
      <p class="tw-my-3">
        Are you sure you want to unpublish this study?
      </p>
      <div class="flex justify-end mt-2">
        <ah-button @click="confirmUnpublishStudy">
          Unpublish study
        </ah-button>
      </div>
    </st-modal>
    <st-modal v-if="answersModal" v-model="answersModal">
      <h3 class="font-600">
        Add answers
      </h3>
      <p class="tw-my-3">
        Are you sure you want to add answers to this study?
      </p>
      <div class="flex justify-end mt-2">
        <ah-button @click="confirmAddAnswers">
          Add answers
        </ah-button>
      </div>
    </st-modal>
    <st-modal v-if="strategiesModal" v-model="strategiesModal">
      <h3 class="font-600">
        Add reports
      </h3>
      <p class="tw-my-3">
        Are you sure you want to add reports to this study?
      </p>
      <div class="flex justify-end mt-2">
        <ah-button @click="confirmAddAnswers">
          Add reports
        </ah-button>
      </div>
    </st-modal>
    <st-modal v-if="responseModal" v-model="responseModal">
      <h3 class="font-600">
        Add response
      </h3>

      <ah-input
        v-model="firstName"
        class="tw-mt-5"
        label="First Name"
        name="First Name"
        placeholder="First Name"
      />
      <span  v-if="showError && firstName ==''" class="tw-text-sm tw-pt-1 tw-text-red-500">
        Please add first name
      </span>
      <ah-input
        v-model="lastName"
        class="tw-mt-5"
        label="Last Name"
        name="Last Name"
        placeholder="Last Name"
      />
      <span v-if="showError  && lastName ==''" class="tw-text-sm tw-pt-1 tw-text-red-500">
        Please add last name
      </span>
      <ah-input
        v-model="email"
        class="tw-mt-5"
        label="Email"
        name="Email"
        placeholder="Email"
      />
      <span v-if="showError  && email ==''" class="tw-text-sm tw-pt-1 tw-text-red-500">
        Please add email
      </span>
      <div class="tw-flex tw-flex-col tw-space-y-3 tw-my-5">
        <label for="file" class="tw-text-sm tw-py-2">
          Upload audio file
        </label>
        <span v-if="showError  && !file" class="tw-text-sm tw-pt-1 tw-text-red-500">
        Please add a file
      </span>
        <input
          type="file"
          name="file"
          @change="onFileChange"
          accept=".mp3,audio/*"
        />
      </div>

      <div class="flex justify-end mt-2">
        <ah-button @click="submitResponse">
          Submit
        </ah-button>
      </div>
    </st-modal>
  </div>
</template>

<script>
import {
  AhButton,
  AhInput,
  Pagination,
  SecondaryButton,
  StModal,
  StudyStatus,
  StToastNotification,
} from "../common";
export default {
  components: {
    StudyStatus,
    Pagination,
    SecondaryButton,
    StModal,
    AhButton,
    AhInput,
    StToastNotification,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      secondaryFilter: "all",
      showPublishStudyModal: false,
      showUnpublishModal: false,
      publishStudyId: null,
      unPublishStudyId: null,
      answersModal: false,
      strategiesModal: false,
      selectedStudyId: null,
      selectedType: null,
      responseModal: false,
      firstName: '',
      lastName: '',
      email: '',
      file: null,
      showError:false,
      showToast: false,
    }
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const isAnswer = urlParams.get("isAnswer");
    const isAnswersAndStrategies = urlParams.get("isAnswersAndStrategies");
    if (isAnswer) {
      this.secondaryFilter = "isAnswer";
    }
    if (isAnswersAndStrategies) {
      this.secondaryFilter = "isAnswersAndStrategies";
    }
  },

  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
    },
   async submitResponse() {
      if(this.firstName == '' || this.lastName == '' || !this.file){
        this.showError = true
        return
      }
      
      const formData = new FormData();
      formData.append("response[audio_response]", this.file);
      this.$store.commit("toggleFullSpinner", true);

      try{
        const participantToken = await this.getParticipantToken(
        this.email,
        this.selectedStudyId
      );

      if (participantToken) {
        await this.storeName(
          participantToken,
          this.firstName,
          this.lastName,
          this.selectedStudyId
        );
        const questions = await this.getQuestions(this.selectedStudyId);
        await this.submitFeedback(participantToken, formData, questions[0].id);
        this.showToast = true;
        this.responseModal = false;
        this.$store.commit("toggleFullSpinner", false);
      }
      }
      catch(err){
        console.log(err)
        this.showToast = true;
        this.responseModal = false;
        this.$store.commit("toggleFullSpinner", false);
      }
    },
    async submitFeedback(participantToken, formData, questionId) {
      try {
        const response = await this.$axios.request({
          method: "post",
          url: `/questions/${questionId}/submit?participant_token=${participantToken}`,
          data: formData,
        });
        return response.data;
      } catch (error) {
        console.error(
          "Error submitting feedback:",
          error.response ? error.response.data : error.message
        );
        return null;
      }
    },
    async getQuestions(studyId) {
      try {
        const response = await this.$axios.request({
          method: "get",
          url: `/study/${studyId}/questions`,
        });
        return response.data;
      } catch (error) {
        console.error(
          "Error getting question:",
          error.response ? error.response.data : error.message
        );
        return null;
      }
    },
    async getParticipantToken(email, studyId) {
      const response = await fetch(
        `https://us-central1-askhumans-${process.env.RAILS_ENV}.cloudfunctions.net/response-submitter`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
            studyId,
          }),
        }
      )

      const data = await response.json();
      return data.participantToken;
    },
    async storeName(participantToken, firstName, lastName, studyId) {
      try {
        const response = await this.$axios.request({
          method: "post",
          url: `/study/${studyId}/response?participant_token=${participantToken}`,
          data: {
            participant: { first_name: firstName, last_name: lastName },
          },
        });
        return response.data;
      } catch (error) {
        console.error(
          "Error storing name:",
          error.response ? error.response.data : error.message
        );
        return null;
      }
    },
    isAnswersAdded(studyId) {
      const study = this.account.studies.find(
        (study) => study.studyId == studyId
      );
      const studyPlans = study.plans;
      const answersPlan = studyPlans.find((plan) =>
        plan.title.includes("Answers")
      );
      if (answersPlan) {
        return true;
      }
      return false;
    },
    isStrategiesAdded(studyId) {
      const study = this.account.studies.find(
        (study) => study.studyId == studyId
      );
      const studyPlans = study.plans;
      const strategiesPlan = studyPlans.find((plan) =>
        plan.title.includes("Strategies")
      );
      if (strategiesPlan) {
        return true;
      }
      return false;
    },
    confirmPublishStudy() {
      this.showPublishStudyModal = false;
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "post",
          url: `study/${this.publishStudyId}/free_subscription
`,
        })
        .then((res) => {
          location.reload();
        })
        .catch((err) => {
          this.$store.commit("toggleFullSpinner", false);
          console.log(err);
        });
    },
    confirmAddAnswers() {
      this.answersModal = false;
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "post",
          url: `study/${this.selectedStudyId}/free_add_on_subscription`,
          data: {
            title: this.selectedType,
          },
        })
        .then((res) => {
          location.reload();
        })
        .catch((err) => {
          this.$store.commit("toggleFullSpinner", false);
          console.log(err);
        });
    },

    confirmUnpublishStudy() {
      this.showUnpublishModal = false;
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "patch",
          url: `study/${this.unPublishStudyId}`,
          data: {
            status: "draft",
            published: false,
          },
        })
        .then((res) => {
          // this.$store.commit('toggleFullSpinner', false)
          location.reload();
        })
        .catch((err) => {
          this.$store.commit("toggleFullSpinner", false);
          console.log(err);
        });
    },
    unpublishStudy(studyId) {
      this.unPublishStudyId = studyId;
      this.showUnpublishModal = true;
    },
    publishStudy(studyId) {
      this.publishStudyId = studyId;
      this.showPublishStudyModal = true;
    },
    addAnswers(studyId) {
      this.answersModal = true;
      this.selectedType = "Answers";
      this.selectedStudyId = studyId;
    },
    addStrategies(studyId) {
      this.selectedType = "Strategies";
      this.strategiesModal = true;
      this.selectedStudyId = studyId;
    },
    addResponse(studyId) {
      this.responseModal = true;
      this.selectedStudyId = studyId;
    },
    redirectToStudyPlayground(studyId) {
      this.$store.commit("toggleFullSpinner", true);
      location.href = `/admin/playground/${studyId}`;
    },
    changeSecondaryFilter(filter) {
      this.secondaryFilter = filter;
      this.$store.commit("toggleFullSpinner", true);
      // check if the url has per_page and page query params then embed it with filter
      const urlParams = new URLSearchParams(window.location.search);
      const perPage = urlParams.get("per_page");
      const page = urlParams.get("page");
      let url = `/admin/account/${this.account.accountId}`;
      if (perPage && page) {
        url = `/admin/account/${this.account.accountId}?per_page=${perPage}&page=${page}`;
      }
      if (filter == "isAnswer") {
        url = perPage ? `${url}&isAnswer=true` : `${url}?isAnswer=true`;
      }
      if (filter == "isAnswersAndStrategies") {
        url = perPage
          ? `${url}&isAnswersAndStrategies=true`
          : `${url}?isAnswersAndStrategies=true`;
      }
      location.href = url;
    },
    changePage(currentPage, perPage) {
      this.$store.commit("toggleFullSpinner", true);
      location.href = `/admin/account/${this.account.accountId}?per_page=${perPage}&page=${currentPage}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
