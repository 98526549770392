<template>
  <div>
    <div
      v-for="(log, index) in activityLogsData"
      :key="`${index}_log`"
      class="tw-border-solid tw-border-b-gray-200 tw-px-14 tw-my-5"
      :class="{ 'tw-border-b': activityLogsData.length > 1 }"
    >
      <div class="tw-px-20">
        <p class="tw-font-semibold tw-capitalize">
          {{ log.date }}
        </p>
        <div class="tw-text-gray-600 tw-body-font">
          <div
            class="tw-container tw-px-5 tw-py-3 tw-mx-auto tw-flex tw-flex-wrap"
          >
            <div class="tw-flex tw-flex-wrap tw-w-full">
              <div class="tw-lg:w-2/5 tw-md:w-1/2 tw-md:pr-10  ">
                <div
                  v-for="(activityLogs, ind) in log.logs"
                  :key="`${activityLogs.created_at}_${ind}`"
                  class="tw-flex tw-relative tw-py-6"
                >
                  <div
                    class="tw-h-full tw-w-8 tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
                  >
                    <div
                      v-if="log.logs.length > 1"
                      class="tw-w-0.5 tw-bg-gray-200 tw-pointer-events-none"
                      :class="{ 'tw-h-5/6 tw-mt-[80px]': ind === 0 , 'tw-h-full' : ind > 0 && ind < log.logs.length, 'tw-mb-[80px]': ind === log.logs.length - 1 }"
                    />
                  </div>
                  <div
                    :style="{ 'background-color': getBackgroundColor() }"
                    class="tw-flex-shrink-0 tw-w-8 tw-h-8 tw-rounded-full tw-inline-flex tw-items-center tw-justify-center tw-text-black tw-relative tw-z-10"
                  >
                    {{ activityLogs.user_name[0] }}
                  </div>
                  <div class="tw-flex-grow tw-pl-4">
                    <div class="tw-flex tw-space-x-2">
                      <div class="tw-flex tw-flex-col">
                        <span class="tw-font-semibold tw-text-base ">
                          {{ activityLogs.user_name }}
                        </span>
                        <span class="tw-text-sm ">
                          {{ activityLogs.account_name }}
                        </span>
                        <span class="tw-text-sm ">
                          {{ activityLogs.user_email }}
                        </span>
                      </div>
                      <span class="tw-text-sm">
                        Logged in
                      </span>
                    </div>
                    <p class="tw-text-sm">
                      {{ getTimeFromDate(activityLogs.created_at) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StDrawer } from '../../common'
export default {
  components: {
    StDrawer
  },
  props: {

  },
  data () {
    return {
      activityLogsData: []
    }
  },

  mounted () {
    this.$store.commit('toggleFullSpinner', true)
    setTimeout(() => {
      this.getActivityData()
    }, 500)

    this.$store.commit('setActivePage', 'all-recipients')
  },

  methods: {

    parseDate (s) {
      const [datePart, timePart] = s.split(' ')
      const [day, month, year] = datePart.split('-')
      const [hour, minute] = timePart.split(':')
      return new Date(`${year}-${month}-${day}T${hour}:${minute}`)
    },
    getTimeFromDate (date) {
      const dateTime = date.split(' ')
      const dateObj = new Date(dateTime[0].split('-')[2], dateTime[0].split('-')[1] - 1, dateTime[0].split('-')[0], dateTime[1].split(':')[0], dateTime[1].split(':')[1])

      // Get the hours, minutes, and AM/PM designation
      const hours = dateObj.getHours()
      const minutes = dateObj.getMinutes()
      // console.log(hours, minutes)
      const ampm = hours >= 12 ? 'PM' : 'AM'

      // Format the time in 12-hour clock format (with leading zeros for single-digit values)
      const formattedTime = `${(hours % 12 || 12)
        .toString()
        .padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`
      return formattedTime
    },
    getBackgroundColor () {
      const colors = ['#A7AA93', '#DCAD05', '#939FAA', '#93B857']
      return colors[Math.floor(Math.random() * colors.length)]
    },
    getReadableDate (unformattedDate) {
      if (!unformattedDate) return
      const date = new Date(unformattedDate)
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },
    getActivityData () {
      const result = {}
      // / Get today and yesterday's dates
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const yesterday = new Date(today)
      yesterday.setDate(today.getDate() - 1)
      this.$axios
        .request({
          method: 'get',
          url: '/activity_logs',
          data: {
            name: this.newListName,
            participant_ids: this.selectedParticipantIds
          }
        })
        .then((res) => {
          this.$store.commit('toggleFullSpinner', false)
          // console.log(res.data)
          // Group activity logs by date
          // console.log(res.data)
          res.data.reverse().forEach((log) => {
            const created_at = this.parseDate(log.created_at)
            let dateKey
            if (created_at >= today) {
              dateKey = 'today'
            } else if (created_at >= yesterday) {
              dateKey = 'yesterday'
            } else {
              dateKey = this.getReadableDate(this.parseDate(log.created_at))
            }

            if (!result[dateKey]) {
              result[dateKey] = []
            }
            // console.log(result)
            result[dateKey].push(log)
          })

          // Convert result object into an array of objects
          const resultArray = Object.keys(result).map((date) => ({
            date,
            logs: result[date]
          }))
          this.activityLogsData = resultArray
          // console.log(resultArray)
        }).catch((e) => {
          this.$store.commit('toggleFullSpinner', false)
          console.log(e)
        })
    }
  }
}
</script>
