<template>
  <st-modal v-model="showModal">
    <template v-slot:modalTitle>
      <span class="font-18p font-bold">
        {{ title }}
      </span>
    </template>
    <template v-slot:modalActions>
      <div class="st-member-groups__modal-text">
        <p>
          After you delete this keyword, you won’t be able to recover it.
        </p>
        <p class="mt-3 text-black font-semibold">
          Type here "{{ groupName }}"
        </p>
      </div>
      <div class="mb-3">
        <input
          v-model="groupInput"
          class="w-full my-2 py-3 rounded-4p px-2-important border-gray-100"
          :show-label="true"
          label="Group name *"

          @keyup.enter="groupSubmitHandler"
        >
        <span
          v-if="error!==''"
          class="text-error"
        >{{ error }}</span>
      </div>
      <div class="flex space-x-2 justify-end">
        <button
          class="bg-gray-100 text-gray-two  rounded-4p p-3 "
          @click="toggle"
        >
          Cancel
        </button>
        <button
          class="bg-delete   p-3 rounded-4p  text-white"
          @click="groupSubmitHandler"
        >
          Delete group
        </button>
      </div>
      <!-- <div class="flex space-x-2 justify-end">
        <button
          class="bg-gray-100 px-5 py-5 rounded-4p"
          @click="toggle"
        >
          Cancel
        </button>
        <button
          class="background-delete-button px-10 justify-end py-5 text-white"
          @click="groupSubmitHandler"
        >
          Delete group
        </button>
      </div> -->
    </template>
  </st-modal>
</template>

<script>
import { StInput, StModal } from '../../common'
export default {
  components: {
    StInput,
    StModal
  },
  props: {
    groupName: {
      type: String,
      required: true,
      default: ''
    },
    title: {
      type: String,
      require: true,
      default: ''
    }
  },
  data: function () {
    return {
      showModal: false,
      error: '',
      groupId: null,
      groupInput: ''
    }
  },
  methods: {
    toggle (groupId) {
      this.showModal = !this.showModal
      this.groupId = groupId
      if (this.groupId) this.buttonName = 'Rename group'
    },
    groupSubmitHandler () {
      if (this.groupInput !== this.groupName) {
        this.error = 'Please type the correct group name'
      } else {
        this.$emit('loader', true)
        this.$axios
          .request({
            url: `/entity_sentiments_groups/${this.groupId}/remove/`,
            method: 'delete'
          })
          .then((res) => {
            this.$store.dispatch('insights/updateSentimentGroup', {
              group: { id: this.groupId },
              type: 'delete'
            })
            this.showModal = false
            this.groupId = null
            this.$emit('showToast', 'Group deleted successfully')
          })
          .catch((err) => {
            console.log(err)
          })
        setTimeout(() => {
          this.$emit('loader', false)
        }, [1000])
      }
    }
  }
}
</script>
