<template>
  <div class="st-profile">
    <p
      v-if="showSuccessMessage"
      class="tw-text-success tw-text-center tw-z-10 tw-w-full tw-absolute tw-top-[20%] tw-font-bold  tw-bg-success/10 tw-p-10"
    >
      Profile updated!
    </p>
    <div class="bx--grid mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          caption="Save Changes"
          icon-name="save"
          :disabled="!pageInfoChanged"
          :show-action="true"
          @click="saveChanges"
        />
      </div>
    </div>
    <div class="bx--grid">
      <div class="bx--row">
        <div class="bx--offset-sm-1 bx--col-sm-8 bx--col-xs-12 profile__container">
          <h1 class="page-header">
            Profile
          </h1>
          <div class="profile__avatar">
            <div
              class="edit-user-profile-avatar__hover-to-edit"
              tabIndex="0"
              @focus="avatarHovered = true"
              @focusout="avatarHovered = false, editAvatarActive = false"
              @keyup.enter="openMediaUploader"
              @mouseenter="avatarHovered = true"
              @mouseleave="avatarHovered = false, editAvatarActive = false"
              @mouseup="openMediaUploader"
            >
              <transition name="fade-div">
                <div
                  v-if="avatarHovered"
                  :class="{
                    'edit-user-profile-avatar': true,
                    'edit-user-profile-avatar--active': editAvatarActive
                  }"
                  @mouseup="editAvatarActive = true"
                >
                  <div
                    :class="{
                      'edit-user-profile-avatar__icon': true,
                      'edit-user-profile-avatar__icon--active': editAvatarActive
                    }"
                  >
                    <icon-wrapper
                      icon-name="camera"
                      :invert="true"
                    />
                  </div>
                  <transition name="fade">
                    <span
                      v-if="avatarHovered && !editAvatarActive"
                      class="edit-user-profile-avatar__instructions"
                    >
                      Change Photo
                    </span>
                  </transition>
                </div>
              </transition>
              <div
                class="profile__avatar"
                :style="userAvatarStyle"
              />
            </div>
          </div>
          <st-input
            v-model.trim="user.firstName"
            class="profile__field"
            label="First Name"
            name="user[first_name]"
            placeholder="Bruce"
            @emitEnter="saveChanges"
          />
          <st-input
            v-model.trim="user.lastName"
            class="profile__field"
            label="Last Name"
            name="user[last_name]"
            placeholder="Wayne"
            @emitEnter="saveChanges"
          />
          <st-input
            v-model.trim="user.email"
            class="profile__field"
            label="Email"
            name="user[email]"
            placeholder="bruce.wayne@wayneenterprises.com"
            @emitEnter="saveChanges"
          />
          <st-dropdown-menu
            ref="departmentDropdown"
            class="profile__dropdown"
            caption="Departments"
            :display-caption="true"
            :full-width="true"
            :initial-active-options-array="user.departments"
            :options="departments"
            :option-caption-fn="(department) => department.name"
            placeholder="Select Department(s)"
            :select-multiple="true"
            @updateMultiple="updateUserDepartments"
          />
          <p class="profile__field-title">
            Role
          </p>
          <p class="profile__field-value">
            {{ user.kind.charAt(0).toUpperCase() + user.kind.slice(1) }}
          </p>
        </div>
        <div class="bx--offset-sm-1 bx--col-sm-2 desktop-page-right-action-button">
          <st-button
            caption="Save Changes"
            icon-name="save"
            :disabled="!pageInfoChanged"
            :show-action="true"
            @click="saveChanges"
          />
        </div>
      </div>
      <st-media-uploader
        v-if="showMediaUploader"
        :aspect-ratio="1.0"
        :folders="folders"
        prop-name="user[avatar]"
        modal-title="Change Profile Photo"
        :url="`/users/${user.id}`"
        @closeMediaUploader="showMediaUploader = false"
        @uploadComplete="uploadComplete"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEqual } from 'lodash'
import { isUnsaved } from '../../../mixins'
import {
  IconWrapper,
  StButton,
  StDropdownMenu,
  StInput,
  StMediaUploader
} from '../../common/'

export default {
  components: {
    IconWrapper,
    StButton,
    StDropdownMenu,
    StInput,
    StMediaUploader
  },
  // mixins: [isUnsaved],
  props: {
    departments: {
      type: Array,
      required: true
    },
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    userInfo: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      avatarHovered: false,
      showSuccessMessage: false,
      editAvatarActive: false,
      showMediaUploader: false,
      user: this.userInfo
    }
  },
  computed: {
    ...mapState(['currentUser']),
    pageInfoChanged () {
      return !isEqual(this.user, this.currentUser)
    },
    userAvatarStyle () {
      let userAvatar = this.user.avatarUrl
      let avatarStyleObject = {}
      if (userAvatar) {
        avatarStyleObject['background-image'] = `url('${userAvatar}')`
      } else {
        avatarStyleObject['background'] = '#EFEFEF'
        avatarStyleObject['border'] = '2px solid #FFFFFF'
      }
      return avatarStyleObject
    },
    updateRecordData () {
      let data = {
        department_ids: this.user.departments.map(department => department.id),
        email: this.user.email,
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        role: this.user.role
      }
      return data
    },
    updateStoreData () {
      let data = {
        departments: this.user.departments,
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        role: this.user.role
      }
      return data
    }
  },
  methods: {
    openMediaUploader () {
      this.editAvatarActive = true
      setTimeout(() => {
        this.showMediaUploader = true
        this.editAvatarActive = false
      }, 400)
    },
    saveChanges () {
      let url = `/users/${this.user.id}`
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        url: url,
        method: 'patch',
        data: { user: this.updateRecordData }
      })
        .then(res => {
          this.$store.commit('toggleFullSpinner', false)
          this.showSuccessMessage = true
          setTimeout(() => {
            this.$store.commit('setCurrentUser', { ...this.currentUser, ...this.updateStoreData })
          }, 200)
          setTimeout(() => {
            this.showSuccessMessage = false
          }, 2000)
        }).catch(err => {
          this.$store.commit('toggleFullSpinner', false)
          console.log(err)
        })
    },
    updateUserDepartments (payload, index) {
      this.user.departments = payload
    },
    uploadComplete (data) {
      this.user.avatarUrl = data.avatarUrl
      this.$store.commit('setCurrentUser', { ...this.currentUser, avatarUrl: data.avatarUrl })
      this.showMediaUploader = false
    }
  }
}
</script>
