<template>
  <div class="tw-flex tw-space-x-2 tw-items-center tw-w-full">
    <span class="tw-h-[1px] tw-w-full tw-bg-white-two" />
    <span class="tw-min-w-fit tw-px-2 tw-text-black-two tw-max-w-[100px] tw-block ">
      {{ text }}
    </span>
    <span class="tw-h-[1px] tw-w-full tw-bg-white-two" />
  </div>
</template>

<script>
export default {

  props: {
    text: {
      type: String,
      required: false,
      default: ''
    }

  },

  data () {
    return {

    }
  },

  mounted () {

  },

  methods: {

  }
}
</script>
