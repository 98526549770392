function isFullStoryDefined () {
  return !(typeof FS === 'undefined')
}

export function identify (userId, userDetails = {}) {
  if (!isFullStoryDefined()) { return }

  FS.identify(userId, userDetails) // eslint-disable-line
}

export function identifyNameEmail (userId, name, email) {
  if (!isFullStoryDefined()) { return }

  FS.identify(userId, { // eslint-disable-line
    displayName: name,
    email: email
  })
}

export function sendUserDetails (userDetails = {}) {
  if (!isFullStoryDefined()) { console.log('we dont think its defined'); return }

  // console.log('we think its defined')
  // console.log('userDetails', userDetails)
  FS.setUserVars(userDetails) // eslint-disable-line
}

export function sendNameEmail (name, email) {
  // console.log('fs name', name)
  // console.log('fs email', email)
  sendUserDetails({
    displayName: name,
    email: email
  })
}
