<template>
  <div>
    <quill-editor
      ref="myTextEditor"
      class="editor"
      :value="content"
      :options="editorOption"
      @change="onEditorChange"
      @blur="onEditorBlur($event)"
    >
      <div
        v-show="showToolbar"
        :id="`toolbar-${objectIndex}`"
        slot="toolbar"
        class="editor-toolbar"
        @click="preventBlurOnSafari($event)"
      >
        <select class="ql-font">
          <option
            selected
            value="serif"
          />
          <option
            v-for="font in fonts"
            :key="font"
            :value="font"
          />
        </select>
        <select class="ql-size">
          <option selected />
          <option
            v-for="size in fontSizes"
            :key="size"
            :value="size"
          />
        </select>
        <button class="ql-bold">
          Bold
        </button>
        <button
          class="ql-list"
          value="bullet"
        />
        <button
          class="ql-link"
          value="link"
        />
        <select class="ql-color" />
        <button
          class="ql-custom-button"
          @click="removeText"
        >
          <icon-wrapper icon-name="trash" />
        </button>
      </div>
    </quill-editor>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { quillEditor, Quill } from 'vue-quill-editor'
import { IconWrapper } from '../../../../common'
import { EDITOR_FONTS, EDITOR_FONT_SIZES } from '../constants'
import { updateSlideQuestionObject } from '../../../../../mixins'

// specify the fonts you would
let fonts = EDITOR_FONTS
// generate code friendly names
function getFontName (font) {
  return font.toLowerCase().replace(/\s/g, '-')
}
let fontNames = fonts.map((font) => getFontName(font))
// add fonts to style
let fontStyles = ''
fonts.forEach(function (font) {
  let fontName = getFontName(font)
  fontStyles +=
  `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, 
   .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before 
   {content: '${font}'; font-family: '${font}', sans-serif;}
   .ql-font-${fontName} { font-family: '${font}', sans-serif; }
  `
})

let Font = Quill.import('formats/font')
const sizeStyle = Quill.import('attributors/style/size')
Font.whitelist = fontNames
sizeStyle.whitelist = EDITOR_FONT_SIZES
Quill.register(sizeStyle, true)
Quill.register(Font, true)

export default {
  name: 'QuillExampleSnow',
  title: 'Theme: snow',
  components: {
    quillEditor,
    IconWrapper
  },
  mixins: [updateSlideQuestionObject],
  props: {
    object: {
      type: Object,
      default: () => {}
    },
    blur: {
      type: Function,
      default: () => {}
    },
    objectIndex: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      fonts: EDITOR_FONTS,
      fontSizes: EDITOR_FONT_SIZES,
      showToolbar: false,
      editorOption: {
        modules: {
          toolbar: `#toolbar-${this.objectIndex}`
        }
      },
      content: ``,
      clicked: false,
      debounceTimeoutId: null
    }
  },
  computed: {
    editor () {
      return this.$refs.myTextEditor.quill
    },
    contentCode () {
      return this.content.value
    }
  },
  beforeMount () {
    if (this.object.content) this.content = this.object.content
  },
  mounted () {
    var node = document.createElement('style')
    node.innerHTML = fontStyles
    document.body.appendChild(node)

    document.querySelectorAll('.ql-editor')[this.object.index].addEventListener('click', this.onEditorClick)
  },

  methods: {
    ...mapMutations('specifications', ['updateObject', 'removeObject']),
    onEditorChange ({ quill, html, text }) {
      if (this.debounceTimeoutId) clearTimeout(this.debounceTimeoutId)
      this.content = html
      this.updateObject({
        type: 'text',
        id: this.object.id,
        content: html
      })
      this.debounceTimeoutId = setTimeout(() => {
        this.handleUpdateQuestion()
        this.debounceTimeoutId = null
      }, 300)
    },
    onEditorBlur (editor) {
      setTimeout(() => {
        if (this.clicked) {
          this.clicked = false
          return
        }
        this.$emit('blur', this.content)
        this.handleUpdateQuestion()
        this.showToolbar = false
      }, 100)
    },
    onEditorClick () {
      this.editor.focus()
      this.showToolbar = true
    },
    handleUpdateQuestion () {
      this.updateSlideQuestionObject(this.object.id, this.objectIndex, { slide_question_object: { content: this.content } })
    },
    removeText () {
      this.removeObject(this.object.id)
      this.deleteSlideObject(this.object.id)
    },
    preventBlurOnSafari (event) {
      this.clicked = true
      setTimeout(() => {
        this.clicked = false
      }, 200)
    }
  }
}
</script>
