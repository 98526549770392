<template>
  <div class="st-prospectives--how-works-space st-prospectives__dots">
    <div class="bx--grid">
      <div class="bx--row">
        <div class="bx--col-xs-12">
          <h1 class="st-prospectives__heading st-prospectives__how-work-heading">
            See how AskHumans works.
          </h1>
          <div class="st-prospectives__pick-content">
            <h5 class="st-prospectives__pick-title">
              Pick a type of space
            </h5>
            <div class="bx--row st-prospectives__row-pick">
              <st-radio-button
                v-model="spaceTypeIndex"
                content-class="st-prospectives-radio__radio-wrapper"
                :hide-caption="true"
                :hide-until-active="true"
                indicator-class="st-prospectives-radio__indicator-wrapper"
                indicator-icon-class="st-prospectives-radio__indicator-icon"
                :options="spaceTypes.map((spaceType, index) => {
                  return { value: index}
                })"
                :variant="['fullBorder', 'lighterGreyBorder']"
              >
                <template v-slot:preceding-input="slotProps">
                  <div class="st-space-type-radio__image-and-value">
                    <div
                      :class="[{
                        'st-space-type-radio__image': true
                      }, spaceTypeImage(slotProps.option.value)]"
                    />
                    <h6 class="st-prospectives-radio__title">
                      {{ spaceTypes[slotProps.option.value] }}
                    </h6>
                  </div>
                </template>
              </st-radio-button>
            </div>
          </div>
          <div class="st-prospectives__pick-content st-prospectives__pick-content-use">
            <h5 class="st-prospectives__pick-title">
              Pick a use case
            </h5>
            <div class="bx--row st-prospectives__row-pick">
              <st-radio-button
                v-model="useCaseIndex"
                content-class="st-prospectives-radio__radio-wrapper st-prospectives-radio__radio-wrapper--use-case"
                :hide-caption="true"
                :hide-until-active="true"
                indicator-class="st-prospectives-radio__indicator-wrapper"
                indicator-icon-class="st-prospectives-radio__indicator-icon"
                :options="useCases.map((use, index) => {
                  return { value: index}
                })"
                :variant="['fullBorder', 'lighterGreyBorder']"
              >
                <template v-slot:preceding-input="slotProps">
                  <div class="st-use-case-radio__image-and-value">
                    <div
                      :class="{
                        'st-use-case-radio__image': true,
                        [`st-use-case-radio__image--${slotProps.option.value}`]: true
                      }"
                    />
                    <div class="st-use-case-radio__detail">
                      <p class="st-prospectives-radio__title">
                        {{ useCases[slotProps.option.value] }}
                      </p>
                      <p class="st-use-case-radio__description">
                        {{ useCaseDescriptions[slotProps.option.value] }}
                      </p>
                    </div>
                  </div>
                </template>
              </st-radio-button>
            </div>
          </div>
          <div class="st-prospectives__button-canvas">
            <st-button
              class="st-prospectives__button st-prospectives__button--continue"
              caption="Continue"
              :disabled="isContinueDisabled"
              icon-name="arrow"
              :show-action="true"
              :underline="false"
              :variant="['secondary']"
              @click="advanceToSelectedDemo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { kebabCase } from 'lodash'
import { StButton, StRadioButton } from '../../common'

export default {
  components: {
    StButton,
    StRadioButton
  },
  props: {
  },
  data: function () {
    return {
      spaceTypeIndex: null,
      spaceTypes: ['office', 'hotel', 'fitness center'],
      useCaseDescriptions: [
        'Workplace strategists create studys to understand what are the preference of the workforce in relation to a new or current space.',
        'Designers set up surveys to assess and understand what future tenants think about the different aesthetic features presented throughout a space.',
        'Experience officers create studys to understand how customers and stakeholders feel about different spaces.'
      ],
      useCaseIndex: null,
      useCases: ['workplace strategist', 'designer', 'cxo']
    }
  },
  computed: {
    isContinueDisabled () {
      return this.spaceTypeIndex === null || this.useCaseIndex === null
    }
  },
  mounted () {
    this.$store.commit('hideSidebar', true)
  },
  methods: {
    advanceToSelectedDemo () {
      const url = `/prospective/demo?space_type_index=${this.spaceTypeIndex}&use_case_index=${this.useCaseIndex}`
      window.location.href = url
    },
    spaceTypeImage (value) {
      return {
        [`st-space-type-radio__image--${kebabCase(this.spaceTypes[value])}`]: true
      }
    }
  }
}
</script>
