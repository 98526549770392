<template>
  <div class="icon_segment">
    <div class="icon_segment__svg">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_138_3710)">
          <path
            d="M8.12855 0C7.93765 0 7.78309 0.154564 7.78309 0.345464V5.8721C7.78309 6.063 7.93765 6.21756 8.12855 6.21756H13.6548C13.8457 6.21756 14.0003 6.063 14.0003 5.8721C13.9963 2.63048 11.3698 0.00394967 8.12855 0ZM6.74672 1.38159C3.50384 1.38159 0.875 4.01049 0.875 7.25316C0.875 10.4958 3.50411 13.125 6.74672 13.125C9.98933 13.125 12.6182 10.4961 12.6182 7.25316C12.6182 7.06226 12.4636 6.9077 12.2727 6.9077H7.09191V1.72706C7.09191 1.53616 6.93735 1.38159 6.74645 1.38159H6.74672ZM6.40152 2.08411V7.25343C6.40152 7.44433 6.55608 7.59889 6.74698 7.59889H11.9162C11.7311 10.3184 9.47272 12.4306 6.74698 12.4343C3.88537 12.4354 1.56512 10.1167 1.56407 7.25527C1.56302 4.52684 3.67894 2.26447 6.40152 2.08411V2.08411Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_138_3710">
            <rect
              width="13.125"
              height="13.125"
              fill="white"
              transform="translate(0.875)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {};
</script>
