<template>
  <div class="bx--col-xs-10 bx--offset-xs-1 bx--col-sm-6 bx--offset-sm-3">
    <div class="st-accept-invite__header-row">
      <h2 class="st-sign-in__title">
        Sign Up
      </h2>
      <div class="st-sign-in__sign-up">
        <div class="st-sign-in__help-text">
          Already have an account?
        </div>
        <st-hyperlink
          text="Sign In"
          url="/users/sign_in"
          class="st-sign-in__sign-up-link"
          icon-name="sign-in"
          icon-class="st-sign-in__sign-up-icon"
        />
      </div>
    </div>
    <div class="st-accept-invite__welcome-text">
      Welcome to AskHumans. You have been added to the {{ accountName }} account. Fill out the following fields to sign up and continue to finish your profile
    </div>
    <div>{{ userInfo.email }}</div>
    <form
      id="edit_user"
      action="/users/invitation"
      accept-charset="UTF-8"
      method="post"
      class="edit_user"
    >
      <input
        type="hidden"
        name="authenticity_token"
        :value="token"
      >
      <input
        type="hidden"
        name="_method"
        value="put"
      >
      <input
        id="user_invitation_token"
        readonly="readonly"
        type="hidden"
        :value="invitationToken"
        name="user[invitation_token]"
      >
      <st-input
        v-model="userInfo.firstName"
        class="st-accept-invite__input--first-name st-accept-invite__input"
        label="First Name"
        name="user[first_name]"
        placeholder="First Name"
        :autofocus="true"
      />

      <st-input
        v-model="userInfo.lastName"
        class="st-accept-invite__input"
        label="Last Name"
        name="user[last_name]"
        placeholder="Last name"
      />

      <st-input
        v-model="password"
        class="st-accept-invite__input"
        label="Password"
        name="user[password]"
        type="password"
        :password="true"
        :new-password="true"
        placeholder="Password goes here"
      />

      <div class="st-accept-invite__button-row">
        <div class="st-sign-up__terms-check">
          <st-checkbox
            v-model="terms"
          />
          <div class="st-sign-up__terms-text">
            I agree to
            <span
              class="st-sign-up__terms"
              @click="goToTerms"
            >
              AskHumans Terms
            </span>
          </div>
        </div>

        <st-button
          type="submit"
          caption="Sign Up"
          icon-name="sign-up"
          :disabled="!terms || !userInfo.firstName || !userInfo.lastName || password.length < 6"
        />
      </div>
    </form>
  </div>
</template>

<script>
import StInput from '../../common/st-input'
import StButton from '../../common/st-button'
import StCheckbox from '../../common/st-checkbox'
import StHyperlink from '../../common/st-hyperlink'

export default {
  components: {
    'st-input': StInput,
    'st-button': StButton,
    'st-checkbox': StCheckbox,
    'st-hyperlink': StHyperlink
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    accountName: {
      type: String,
      required: true
    },
    invitationToken: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      userInfo: this.user,
      password: '',
      terms: false
    }
  },
  computed: {
    token () {
      const tokenTag = document.querySelector('meta[name=csrf-token]')
      return tokenTag === null ? '' : tokenTag.content
    }
  },
  methods: {
    goToTerms () {
      window.open('/recording_terms', '_blank')
    }
  }
}
</script>
