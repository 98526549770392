<template>
  <div class="bx--col-sm-12 bx--col-xs-12">
    <div class="tw-mx-auto tw-mt-10 tw-max-w-full tw-font-mono">
      <div class="tw-mb-8 tw-w-full tw-overflow-hidden">
        <div class="tw-w-full tw-overflow-x-auto">
          <div class="tw-w-full">
            <div>
              <div
                class="tw-justify-between tw-border-gray-100 tw-grid tw-grid-cols-12 tw-border-b tw-border-solid tw-text-gray-900 tw-text-left tw-text-sm tw-tracking-wide"
              >
                <div class="tw-px-2 tw-py-3">
                  Status
                </div>
                <div
                  class="tw-col-span-3 tw-px-2 tw-py-3 tw-cursor-pointer"
                  @click="sortByAccountName"
                >
                  Account
                  <svg
                    class="tw-inline-block tw-ml-1 tw-align-middle tw-fill-current tw-text-gray-500"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                      fill="#747474"
                    />
                  </svg>
                </div>
                <div
                  class="tw-px-2 tw-py-3 tw-cursor-pointer"
                  @click="sortByMrr"
                >
                  MRR
                  <svg
                    class="tw-inline-block tw-ml-1 tw-align-middle tw-fill-current tw-text-gray-500"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                      fill="#747474"
                    />
                  </svg>
                </div>
                <div
                  class="tw-col-span-2 tw-px-2 tw-py-3 tw-cursor-pointer"
                  @click="sortByStudies"
                >
                  # of studies
                  <svg
                    class="tw-inline-block tw-ml-1 tw-align-middle tw-fill-current tw-text-gray-500"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                      fill="#747474"
                    />
                  </svg>
                </div>
                <div
                  class="tw-px-2 tw-py-3 tw-cursor-pointer"
                  @click="sortByLogin"
                >
                  Last login
                  <svg
                    class="tw-inline-block tw-ml-1 tw-align-middle tw-fill-current tw-text-gray-500"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.72032 2.03282C0.805748 1.94739 0.944252 1.94739 1.02968 2.03282L3.5 4.50314L5.97032 2.03282C6.05575 1.94739 6.19425 1.94739 6.27968 2.03282C6.36511 2.11825 6.36511 2.25675 6.27968 2.34218L3.65468 4.96718C3.56925 5.05261 3.43075 5.05261 3.34532 4.96718L0.72032 2.34218C0.634893 2.25675 0.634893 2.11825 0.72032 2.03282Z"
                      fill="#747474"
                    />
                  </svg>
                </div>
                <div class="tw-col-span-4 tw-px-2 tw-py-3 tw-col-s" />
              </div>
            </div>
            <div class="tw-bg-white">
              <div
                v-for="account in accountsData.data"
                :key="account.account_id"
                class="tw-relative tw-justify-between tw-items-center tw-grid tw-grid-cols-12 tw-text-gray-700"
                :class="{
                  'hover:tw-bg-light-four tw-cursor-pointer':
                    toggleStudyExpand !== account.account_id,
                }"
                @click="redirectToAccountPage(account.account_id)"
              >
                <div
                  class="tw-px-2 tw-py-3 tw-border tw-font-semibold tw-text-ms tw-cursor-text"
                >
                  <study-status>
                    Active
                  </study-status>
                  <!-- <span
                    class="tw-bg-success tw-px-3 tw-py-2 tw-rounded-lg tw-font-bold tw-text-white tw-text-xs"
                  >
                    Active
                  </span> -->
                </div>
                <div class="tw-col-span-3 tw-px-2 tw-py-3 tw-border">
                  <div class="tw-flex tw-items-center tw-text-sm">
                    <div>
                      <!-- class="tw-md:block tw-relative tw-mr-3 tw-rounded-full tw-h-8 tw-w-8" -->
                      <img
                        class="tw-mr-3 tw-rounded-full tw-h-8 tw-object-cover tw-w-8"
                        src="https://images.pexels.com/photos/5212324/pexels-photo-5212324.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                        alt=""
                        loading="lazy"
                      >
                      <!-- <div
                        class="tw-absolute tw-inset-0 tw-shadow-inner tw-rounded-full"
                        aria-hidden="true"
                      /> -->
                    </div>
                    <div>
                      <p class="tw-text-black">
                        {{ account.account_name }}
                      </p>
                      <p class="tw-text-gray-600 tw-text-sm">
                        {{ account.account_owner }}
                      </p>
                      <p class="tw-text-gray-600 tw-text-sm">
                        {{ account.account_owner_email }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  ${{ account.total_plan_price }}
                </div>
                <div class="tw-col-span-2 tw-px-2 tw-py-3 tw-border tw-text-sm">
                  <p
                    class="tw-flex tw-items-center tw-space-x-2 tw-text-sm"
                    @click="
                      toggleStudyExpandHandler($event, account.account_id)
                    "
                  >
                    <span class="tw-min-w-[80px]">
                      {{ account.studies.length }} Studies
                    </span>
                    <span v-if="toggleStudyExpand != account.account_id">
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.52291 5.55855L3.45468 9.11825C2.97479 9.53815 2.22375 9.19735 2.22375 8.5597L2.22375 1.44029C2.22375 0.802635 2.97479 0.461838 3.45467 0.881738L7.52291 4.44144C7.86085 4.73714 7.86085 5.26285 7.52291 5.55855Z"
                          fill="#474747"
                        />
                      </svg>
                    </span>
                    <span v-else>
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.44156 7.52285L0.881856 3.45462C0.461956 2.97473 0.802752 2.22369 1.44041 2.22369L8.55981 2.22369C9.19747 2.22369 9.53827 2.97473 9.11837 3.45461L5.55867 7.52285C5.26297 7.86079 4.73726 7.86079 4.44156 7.52285Z"
                          fill="#474747"
                        />
                      </svg>
                    </span>
                  </p>
                </div>
                <div class="tw-px-2 tw-py-3 tw-text-sm">
                  {{ account.last_login }}
                </div>
                <div
                  class="tw-flex tw-justify-end tw-space-x-3 tw-col-span tw-col-span-4 tw-px-2 tw-py-3 tw-border tw-text-sm"
                >
                  <button
                    class="tw-border-gray-200 tw-px-3 tw-py-2 tw-border tw-border-solid tw-rounded-md tw-font-semibold"
                    @click="toggleModal($event, account.account_id)"
                  >
                    Credits
                  </button>
                  <button
                    class="tw-bg-primary-light tw-px-3 tw-py-2 tw-rounded-md tw-font-semibold tw-text-primary-dark"
                    @click="forceLogin($event, account.project_owner_id)"
                  >
                    Account Login
                  </button>
                  <button
                    class="tw-border-gray-200 tw-px-3 tw-py-2 tw-border tw-border-solid tw-rounded-md tw-font-semibold"
                  >
                    Account Page
                  </button>
                  <button
                    v-if="!account.verified"
                    class="tw-border-gray-200 tw-px-3 tw-py-2 tw-border tw-border-solid tw-rounded-md tw-font-semibold"
                    @click="verifyAccount($event, account.account_id)"
                  >
                    Verify
                  </button>
                  <span
                    v-else
                    class="tw-flex tw-items-center"
                  >

                    Verified
                  </span>
                </div>

                <template v-if="toggleStudyExpand == account.account_id">
                  <div class="tw-col-span-1 tw-px-2 tw-py-3 tw-text-sm" />

                  <div
                    class="tw-grid tw-grid-cols-11 tw-col-span-11 tw-border-b-0"
                  >
                    <div
                      class="tw-col-span-3 tw-ml-7 tw-pl-4 tw-border-l tw-border-l-gray-300 tw-border-solid tw-text-sm"
                    >
                      <span
                        class="tw-block tw-ml-4 tw-px-3 tw-py-3 tw-border-b tw-border-b-white-two tw-border-solid"
                      >
                        Study
                      </span>
                    </div>

                    <div
                      class="tw-px-2 tw-py-3 tw-border-b tw-border-b-white-two tw-border-solid tw-text-sm"
                    >
                      Values
                    </div>
                    <div
                      class="tw-col-span-4 tw-px-2 tw-py-3 tw-border-b tw-border-b-white-two tw-border-solid tw-text-sm"
                    >
                      Plans
                    </div>
                    <div
                      class="tw-col-span-1 tw-px-2 tw-py-3 tw-border-b tw-border-b-white-two tw-border-solid tw-text-sm"
                    >
                      Satisfaction
                    </div>
                    <div
                      class="tw-col-span-2 tw-border-b tw-border-b-white-two tw-border-solid"
                    />
                  </div>
                  <div
                    v-for="(study, index) in selectedAccountStudies"
                    :key="study.id"
                    class="tw-grid-cols-12 tw-col-span-12 tw-"
                  >
                    <div class="tw-grid tw-grid-cols-12 tw-col-span-11">
                      <div class="tw-col-span-1 tw-px-2 tw-py-3 tw-text-sm" />
                      <div
                        class="tw-relative tw-col-span-3 tw-ml-7 11w-py-3 tw-border-l-gray-300 tw-border-solid"
                      >
                        <span
                          class="tw-block tw-top-0 tw-left-0 tw-absolute tw-border-l tw-border-l-gray-300 tw-border-solid"
                          :class="{
                            'tw-h-full':
                              index !== selectedAccountStudies.length - 1,
                            'tw-h-[50%] md:tw-h-[44%] xl:tw-h-[54%]':
                              index === selectedAccountStudies.length - 1,
                          }"
                        />
                        <div
                          class="tw-flex tw-items-center tw-space-x-2 tw-mt-3"
                        >
                          <div class="tw-flex tw-space-x-2">
                            <span
                              class="tw-border-gray-300 tw-mb-1 tw-border-b tw-border-solid tw-w-4"
                            />
                            <span
                              class="tw-rounded-full tw-h-3 tw-w-3"
                              :class="{
                                'tw-bg-success':
                                  study.study_status === 'active',
                                'tw-bg-gray-300':
                                  study.study_status === 'draft',
                              }"
                            />
                          </div>
                          <div>
                            <p class="tw-font-semibold">
                              {{ study.study_name }}
                            </p>
                            <p class="tw-text-sm">
                              {{ study.total_respondents }} respondents
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="tw-px-2 tw-py-3 tw-text-sm">
                        ${{ study.values }}
                      </div>
                      <div
                        class="tw-flex tw-justify-start tw-items-start tw-space-x-2 tw-col-span-4 tw-px-2 tw-py-3 tw-text-sm"
                      >
                        <span
                          v-for="plan in study.plans"
                          :key="plan.planName"
                          class="tw-block tw-border-gray-200 tw-bg-[#A7AA93] tw-px-3 tw-py-1 tw-border tw-border-solid tw-rounded-3xl tw-min-w-fit tw-text-white"
                        >{{ plan.title }}</span>
                        <!-- <span
                          class="tw-border-gray-200 tw-bg-[#A7AA93] tw-px-3 tw-py-1 tw-border tw-border-solid tw-rounded-3xl tw-text-white"
                        >{{ study.plan_name }}</span>
                        <span
                          class="tw-border-gray-200 tw-bg-white tw-px-3 tw-py-1 tw-border tw-border-solid tw-rounded-3xl"
                        >Medium</span>
                        <span
                          class="tw-border-gray-200 tw-bg-white tw-px-3 tw-py-1 tw-border tw-border-solid tw-rounded-3xl"
                        >Medium</span> -->
                      </div>
                      <div
                        class="tw-col-span-1 tw-px-2 tw-py-3 tw-text-center tw-text-sm"
                      >
                        {{ study.satisfaction }}
                      </div>
                      <div
                        class="tw-flex tw-justify-end tw-items-start tw-col-span-2 tw-px-2 tw-py-3 tw-text-sm"
                      >
                        <span
                          class="tw-border-gray-200 tw-px-3 tw-py-2 tw-border tw-border-solid tw-rounded-md tw-font-semibold tw-cursor-pointer"
                          @click="toggleModal"
                        >
                          Edit prompt
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-relative tw-justify-between tw-items-center tw-grid tw-grid-cols-12 tw-col-span-12 tw-pb-4 w-text-gray-700"
                  >
                    <div class="tw-col-span-2" />
                    <div
                      class="tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-col-span-10"
                    >
                      <button
                        title="Previous"
                        type="button"
                        class="tw-inline-flex tw-justify-center tw-items-center tw-border-gray-800 tw-bg-gray-100 tw-py-0 tw-border tw-rounded-md tw-h-8 tw-w-8"
                        :class="{
                          'tw-opacity-80 tw-cursor-not-allowed':
                            selectedAccountStudyCurrentPage === 1,
                        }"
                        :disabled="selectedAccountStudyCurrentPage === 1"
                        @click="
                          changePageHandler(
                            $event,
                            selectedAccountStudyCurrentPage - 1
                          )
                        "
                      >
                        <svg
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="tw-w-4"
                        >
                          <polyline points="15 18 9 12 15 6" />
                        </svg>
                      </button>
                      <button
                        v-for="range in selectedAccountStudiesTotalPages"
                        :key="range"
                        type="button"
                        class="tw-inline-flex tw-justify-center tw-items-center tw-rounded tw-h-8 tw-w-8 tw-text-sm"
                        :class="{
                          'tw-bg-primary-light tw-border-primary/30 tw-text-primary tw-font-semibold tw-border tw-border-solid ':
                            range === selectedAccountStudyCurrentPage,
                          'tw-bg-gray-100 tw-text-black':
                            range !== selectedAccountStudyCurrentPage,
                        }"
                        @click="changePageHandler($event, range)"
                      >
                        {{ range }}
                      </button>

                      <button
                        :class="{
                          'tw-opacity-70 tw-cursor-not-allowed':
                            selectedAccountStudyCurrentPage ===
                            selectedAccountStudiesTotalPages,
                        }"
                        :disabled="
                          selectedAccountStudyCurrentPage ===
                            selectedAccountStudiesTotalPages
                        "
                        title="Next"
                        type="button"
                        class="tw-inline-flex tw-justify-center tw-items-center tw-border-gray-800 tw-bg-gray-100 tw-py-0 tw-border tw-rounded-md tw-h-8 tw-w-8"
                        @click="
                          changePageHandler(
                            $event,
                            selectedAccountStudyCurrentPage + 1
                          )
                        "
                      >
                        <svg
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="tw-w-4"
                        >
                          <polyline points="9 18 15 12 9 6" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :current-page="accountsData.current_page"
      :per-page="accountsData.per_page"
      :total-pages="accountsData.total_pages"
      title="Accounts per page"
      @change-page="changePage"
    />
    <st-drawer v-model="showModal">
      <template v-slot:modalTitle>
        <div class="flex justify-between">
          <span class="font-18p">
            Credit balance adjustment
          </span>
        </div>
      </template>
      <template />

      <template v-slot:modalActions>
        <div class="border-slate-100 bg-slate-50 py-7 text-1p2-rem">
          <div class="tw-flex tw-justify-evenly tw-space-x-5">
            <div
              class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-2"
            >
              <div class="tw-text-base">
                Starting balance
              </div>
              <div class="tw-text-2xl">
                ${{ startingBalance }}
              </div>
            </div>
            <div
              class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-2"
            >
              <div class="tw-text-base">
                New balance
              </div>
              <div class="tw-text-2xl">
                ${{ newBalance }}
              </div>
            </div>
          </div>
        </div>
        <div class="tw-px-3">
          <div class="tw-px-2 tw-py-4">
            <div class="">
              <p class="tw-text-[15px]">
                Adjustment type
              </p>
              <st-dropdown-menu
                :initial-active-option="initialActiveOption"
                :full-width="true"
                :options="adjustmentType"
                @updateOption="changeAdjustmentType"
              />
            </div>
          </div>
          <div class="tw-px-2 tw-py-3">
            <div class="">
              <p class="tw-text-[15px]">
                Amount
              </p>
              <st-input
                v-model="amount"
                style-type="rounded"
                class="tw-mt-1 tw-w-full"
                placeholder="Enter amount"
                @input="changeAmount"
              />
            </div>
          </div>
          <div
            class="tw-right-0 tw-bottom-0 tw-fixed tw-flex tw-flex-1 tw-justify-end tw-items-end tw-px-2 tw-py-5 tw-h-auto"
          >
            <button
              class="tw-px-3 tw-py-5 tw-rounded-md tw-h-auto tw-font-semibold"
              :class="{
                'tw-cursor-default': amount === null || amount === 0 || amount === undefined,
                'tw-bg-primary-light tw-text-primary-dark': amount > 0,
              }"
              :disabled="newBalance === 0"
              @click="applyToCreditBalance"
            >
              Apply to credit balance
            </button>
          </div>
        </div>
      </template>
    </st-drawer>
    <st-modal
      v-if="showVerifyModal"
      v-model="showVerifyModal"
    >
      <h3 class="font-600">
        Verify accont
      </h3>
      <p class="tw-my-3">
        After verifying account, user can have unlimited credits to publish study with unlimited respondents.
      </p>
      <p>
        Enter number of days to expire the account
      </p>
      <st-input
        v-model="expireDays"
        style-type="rounded"
        class="tw-mt-1 tw-w-full"
        placeholder="Enter number of days like 90 for 3 months"
      />
      <span
        v-if="daysError"
        class="tw-mt-1 tw-text-red-500 tw-text-sm"
      >
        {{ daysError }}
      </span>
      <div class="flex justify-end mt-2">
        <ah-button @click="confirmVerifyHandler">
          Verify account
        </ah-button>
      </div>
    </st-modal>
  </div>
</template>

<script>
import {
  StDrawer,
  StInput,
  Pagination,
  StudyStatus,
  StDropdownMenu,
  AhButton,
  StModal
} from '../../common'
export default {
  components: {
    StDrawer,
    StInput,
    Pagination,
    StudyStatus,
    StDropdownMenu,
    StModal,
    AhButton
  },
  props: {},
  data () {
    return {
      showVerifyModal: false,
      expireDays: null,
      daysError: null,
      accountsData: [],
      toggleStudyExpand: null,
      selectedAccountStudies: [],
      selectedAccountStudiesTotalPages: 0,
      selectedAccountStudyCurrentPage: 1,
      showModal: false,
      accountNameSortType: 'asc',
      mrrSortType: 'asc',
      studiesSortType: 'asc',
      lastLoginSortType: 'asc',
      searchInput: '',
      initialActiveOption: 'Debit',
      adjustmentType: ['Credit', 'Debit'],
      startingBalance: 0,
      amount: null,
      newBalance: 0,
      accountId: null
    }
  },

  mounted () {
    const url = new URL(window.location.href)
    const searchInput = url.searchParams.get('search')
    if (searchInput) {
      this.searchInput = searchInput
    }
    this.$store.commit('toggleFullSpinner', true)
    this.getAllAccounts()
  },
  methods: {
    confirmVerifyHandler () {
      if (!this.expireDays) {
        this.daysError = 'Please enter number of days'
        return
      }
      this.daysError = null

      // account update request with expiry_ends_at
      try {
        this.$store.commit('toggleFullSpinner', true)
        this.$axios({
          method: 'patch',
          url: `/accounts/${this.accountId}`,
          data: {
            account: { days_to_verify: Number(this.expireDays), verified: true }
          }
        })
          .then((res) => {
            this.$store.commit('toggleFullSpinner', false)
            this.showVerifyModal = false
          })
          .catch(() => {
            this.$store.commit('toggleFullSpinner', false)
            console.log('error')
          })
      } catch (error) {
        console.log('error', error)
      }

      this.showVerifyModal = false
    },
    verifyAccount (e, id) {
      e.stopPropagation()
      this.accountId = id
      this.showVerifyModal = true
    },
    changeAmount () {
      const regex = /^\d*\.?\d{0,2}$/

      if (!this.amount) {
        this.newBalance = 0
        return
      }
      const amount = parseFloat(this.amount)

      if (!regex.test(this.amount)) {
        this.amount = this.amount.slice(0, -1)
        return
      }

      this.newBalance =
        this.initialActiveOption === 'Credit'
          ? this.startingBalance + amount
          : this.startingBalance - amount

      this.newBalance = this.newBalance.toFixed(2)
    },
    setStartingBalance () {
      this.$axios({
        method: 'get',
        url: `/accounts/${this.accountId}/total_credit_balance`
      })
        .then((res) => {
          // console.log(res.data)
          this.startingBalance = res.data.credit_balance
          // console.log('res', res)
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    applyToCreditBalance () {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'post',
        url: `/accounts/${this.accountId}/adjust_credit_balance`,
        data: {
          amount: this.amount,
          type: this.initialActiveOption
        }
      })
        .then((res) => {
          // console.log(res.data)
          this.startingBalance = res.data.credit_balance
          // console.log('res', res)
          this.$store.commit('toggleFullSpinner', false)
          this.showModal = false
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    changeAdjustmentType (option) {
      this.initialActiveOption = option

      if (!this.amount) {
        this.newBalance = 0
        return
      }
      const amount = parseFloat(this.amount)

      this.newBalance =
        this.initialActiveOption === 'Credit'
          ? this.startingBalance + amount
          : this.startingBalance - amount

      this.newBalance = this.newBalance.toFixed(2)
    },
    getAllAccounts () {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'get',
        url: `/accounts`
      })
        .then((res) => {
          // console.log(res.data)
          console.log('res', res)
          this.accountsData = res.data
          // console.log('res', res)
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    sortBy (filed, type) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'get',
        url: `/accounts?sort_by=${filed}&sort_order=${type}`
      })
        .then((res) => {
          // console.log(res.data)
          this.accountsData = res.data
          // console.log('res', res)
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    sortByLogin () {
      this.sortBy('last_login', this.lastLoginSortType)
      if (this.lastLoginSortType === 'asc') {
        this.lastLoginSortType = 'desc'
      } else {
        this.lastLoginSortType = 'asc'
      }
    },
    sortByAccountName () {
      this.sortBy('account_name', this.accountNameSortType)
      if (this.accountNameSortType === 'asc') {
        this.accountNameSortType = 'desc'
      } else {
        this.accountNameSortType = 'asc'
      }
    },
    sortByMrr () {
      this.sortBy('total_plan_price', this.mrrSortType)
      if (this.mrrSortType === 'asc') {
        this.mrrSortType = 'desc'
      } else {
        this.mrrSortType = 'asc'
      }
    },
    sortByStudies () {
      this.sortBy('total_studies', this.studiesSortType)
      if (this.studiesSortType === 'asc') {
        this.studiesSortType = 'desc'
      } else {
        this.studiesSortType = 'asc'
      }
    },
    changePageHandler (e, page) {
      e.stopPropagation()
      this.selectedAccountStudyCurrentPage = page
      const accountStudies = this.accountsData.data.find((account) => {
        return account.account_id === this.toggleStudyExpand
      })
      const start = (page - 1) * 5
      const end = start + 5
      this.selectedAccountStudies = accountStudies.studies.slice(start, end)
    },
    changePage (currentPage, perPage) {
      const currentUrl = new URL(window.location.href)

      const searchInput = currentUrl.searchParams.get('search')
      if (searchInput) {
        this.searchInput = searchInput
      }
      this.$store.commit('toggleFullSpinner', true)
      let url = `/accounts?per_page=${perPage}&page=${currentPage}`
      if (this.searchInput) {
        url = url + `&name=${this.searchInput}`
      }
      this.$axios({
        method: 'get',
        url
      })
        .then((res) => {
          // console.log(res.data)
          this.accountsData = res.data
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    toggleModal (e, accountId) {
      e.stopPropagation()

      this.startingBalance = 0
      this.amount = null
      this.newBalance = 0
      this.initialActiveOption = 'Credit'

      this.showModal = !this.showModal
      this.$store.commit('toggleFullSpinner', true)
      this.accountId = accountId
      this.setStartingBalance()
    },
    forceLogin (e, userId) {
      this.$store.commit('toggleFullSpinner', true)

      e.stopPropagation()
      const url = `/impersonations/${userId}`
      this.$axios
        .request({
          method: 'post',
          url
        })
        .then((res) => {
          // console.log('res', res)
          window.location.href = '/'
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch((err) => {
          this.$store.commit('toggleFullSpinner', false)

          console.error('err', err)
        })
    },
    redirectToAccountPage (id) {
      this.$store.commit('toggleFullSpinner', true)
      window.location.href = `/admin/account/${id}`
    },
    getFilteredAccounts (link) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios({
        method: 'get',
        url: link
      })
        .then((res) => {
          this.accountsData = res.data
          // console.log('res', res)
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch(() => {
          this.$store.commit('toggleFullSpinner', false)
          console.log('error')
        })
    },
    toggleStudyExpandHandler (e, id) {
      e.stopPropagation()
      if (this.toggleStudyExpand === id) {
        this.toggleStudyExpand = null
      } else {
        this.toggleStudyExpand = id
        const accountStudies = this.accountsData.data.find((account) => {
          return account.account_id === id
        })
        if (accountStudies.studies.length > 5) {
          this.selectedAccountStudiesTotalPages = Math.ceil(
            accountStudies.studies.length / 5
          )
          this.selectedAccountStudies = accountStudies.studies.slice(0, 5)
          // console.log(this.selectedAccountStudies)
        } else {
          this.selectedAccountStudiesTotalPages = 1
          this.selectedAccountStudies = accountStudies.studies
        }
      }
    }
  }
}
</script>
