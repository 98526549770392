<template>
  <div>
    Company
  </div>
</template>

<script>
export default {
  name: 'AskhumansCompany',

  data () {
    return {

    }
  },

  mounted () {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
