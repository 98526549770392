<template>
  <div>
    <div
      v-if="!withoutHeader"
      class="st-survey-insights__panel-subtitle"
    >
      Participants
    </div>
    <st-checkbox
      class="st-survey-insights__checkbox"
      :checked="anyParticipantsChecked"
      :select-multiple="anyParticipantsChecked && !allParticipantsChecked"
      @input="toggleAllParticipants"
    >
      All Participants
    </st-checkbox>
    <st-checkbox
      v-for="(response) in getUniqueActiveResponses()"
      :key="'participant-' + response.surveyResponseId"
      :checked="selectedSurveyResponseIds[response.surveyResponseId]"
      class="st-survey-insights__checkbox"
      @input="toggleParticipant({surveyResponseId: response.surveyResponseId})"
    >
      {{ response.fullName }}
    </st-checkbox>
    <div
      v-if="skippedCount>0"
      class="st-survey-insights__skipped-participant-text"
    >
      {{ skippedCountText }}
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import {
  StCheckbox
} from '../../../common'

export default {
  components: {
    StCheckbox
  },
  props: {
    withoutHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    survey: {
      type: Object,
      required: false
    },
    participantToken: {
      type: String,
      required: false
    }
  },
  data: function () {
    return {
    }
  },

  computed: {
    ...mapState('insights', ['skippedCount', 'selectedSurveyResponseIds']),
    ...mapGetters('insights', ['activeQuestion', 'responsesPassingSegmentFilter', 'responsesPassingSentimentFilter']),
    activeResponses () {
      let val
      if (this.questionHasSentiment) {
        val = this.responsesPassingSentimentFilter
      } else {
        val = this.responsesPassingSegmentFilter
      }
      return val
    },
    allParticipantsChecked () {
      for (const questionResponse of this.activeResponses) {
        if (!this.selectedSurveyResponseIds[questionResponse.surveyResponseId]) { return false }
      }
      return true
    },
    anyParticipantsChecked () {
      for (const questionResponse of this.activeResponses) {
        if (this.selectedSurveyResponseIds[questionResponse.surveyResponseId]) { return true }
      }
      return false
    },
    skippedCountText () {
      if (this.skippedCount === 1) {
        return '1 participant skipped this question'
      } else {
        return `${this.skippedCount} participants skipped this question`
      }
    },
    questionHasSentiment () {
      return this.activeQuestion.questionType === 'opinion_metric' || this.activeQuestion.questionType === 'virtual_tour'
    }
  },
  methods: {
    ...mapMutations('insights', ['resetParticipants', 'toggleParticipant']),
    toggleAllParticipants () {
      this.resetParticipants(!this.anyParticipantsChecked)
    },
    getUniqueActiveResponses: function () {
      if (this.participantToken && this.survey.anonymous && !this.survey.insightType) {
        return this.activeResponses.filter((response) => {
          return response.token == this.participantToken
        })
      }

      const responseIds = []
      return this.activeResponses.filter(({ surveyResponseId }) => {
        if (!responseIds.includes(surveyResponseId)) {
          responseIds.push(surveyResponseId)
          return true
        }
        return false
      })
    }
  }
}
</script>
