<template>
  <!-- <div>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum possimus voluptatum, est ab non facilis numquam ipsum eum officia voluptas placeat debitis amet illo dicta delectus, sunt alias error cum!
  </div> -->
  <st-drawer v-model="showModal">
    <!-- <template v-slot:modalTitle>
      <span class="font-18p font-bold">

        Edit transcript
      </span>
    </template>
    <template v-slot:modalActions>
      <textarea
        ref="textarea"
        v-model="editTranscriptText"
        class="w-full rounded-4p px-2-important py-3 mb-3"
        type="text"
        placeholder=" "
        :style="{
          minHeight: textAreaHeight
        }"
        @input="reStyleMinHeight"
      />

      <div class="flex space-x-2 justify-end">
        <button
          class="bg-gray-100 text-gray-two font-600 rounded-4p p-3 "
          @click="hideModal"
        >
          Cancel
        </button>
        <button
          class="bg-blue-primary  font-600 p-3 rounded-4p  text-white"
          @click="saveChanges"
        >
          Save changes
        </button>
      </div>
    </template> -->
  </st-drawer>
</template>

<script>
import { StDrawer } from '../../common'
export default {
  name: 'InsightDrawer',
  components: {
    StDrawer

  },
  props: {
    title: {
      type: String,
      require: false,
      default: ''
    },
    sentimentGroups: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: function () {
    return {
      showModal: true,
      groupName: '',
      selectedGroup: -1,
      entityId: '',
      entityGroupId: '',
      error: ''
    }
  },
  mounted: function () {
    console.log('whatever.....')
  },
  methods: {
    markAsSelected (index) {
      this.selectedGroup = index
    },
    toggle (entityId, entityGroupId) {
      this.entityId = entityId
      this.error = ''
      this.entityGroupId = entityGroupId
      this.showModal = !this.showModal
      this.groupName = ''
      if (this.entityGroupId) {
        this.selectedGroup = this.sentimentGroups.findIndex(
          (group) => group.id === this.entityGroupId
        )
      } else {
        this.selectedGroup = -1
      }
    },
    groupModalHandler () {
      this.showModal = false
      this.$emit('groupModalHandler', 'Create Group')
    },
    assignGroupHandler () {
      if (this.selectedGroup !== -1) {
        this.$emit('loader', true)
        const { id: groupId } = this.sentimentGroups[this.selectedGroup]
        this.$axios
          .request({
            url: `/entity_sentiments/${this.entityId}/assign/`,
            method: 'patch',
            data: { entity_sentiments_group_id: groupId }
          })
          .then((res) => {
            this.showModal = false
            this.$emit('showToast', 'Group assigned successfully')
            this.$store.dispatch('insights/updateEntitySentimentGroup', {
              entityId: this.entityId,
              groupId
            })
          })
          .catch((err) => {
            console.log(err)
          })
        setTimeout(() => {
          this.$emit('loader', false)
        }, [1000])
      } else {
        this.error = 'Please select group'
      }
    }
  }
}
</script>
