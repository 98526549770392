<template>
  <div>
    <div
      v-if="isEmpty"
      class="st-overflow-menu__empty-spacer"
    />
    <div
      v-else
      @focusout="checkFocusOut"
    >
      <div
        v-if="open"
        class="st-overflow-menu__disable-screen"
        @click.stop="doToggle"
      />
      <!-- class="cv-overflow-menu bx--overflow-menu st-overflow-menu__overflow-icon" -->
      <div
        data-overflow-menu
        tabindex="0"
        :aria-label="label"
        aria-role="button"
        :class="{ 'bx--overflow-menu--open': open }"
        @click.stop.prevent="doToggle"
        @keydown.enter.prevent="doToggle"
        @keydown.space.prevent
        @keyup.space.prevent="doToggle"
      >
        <slot name="open-menu-element">
          <icon-wrapper
            icon-name="overflow"
            :class="{
              'st-overflow-menu__icon-container': true,
              'st-overflow-menu__icon--horizontal': horizontal
            }"
          />
        </slot>
        <ul
          :id="stUid"
          ref="popup"
          class="bx--overflow-menu-options"
          :class="{
            'bx--overflow-menu--flip': flipMenu,
            'bx--overflow-menu-options--open': open,
            'bx--overflow-menu-options--open-left': open && flipMenu,
            'st-overflow-menu--open-horizontal': open && horizontal,
            'st-overflow-menu--open-header': open && verticalPosition == 'top',
          }"
          tabindex="-1"
          :style="menuStyle"
        >
          <li
            v-if="title.length>0"
            class="st-overflow-menu__mobile-menu-title"
          >
            <div class="st-overflow-menu__mobile-menu-drag-bar" />
            <div class="st-overflow-menu__mobile-menu-grid">
              <div>
                <icon-wrapper
                  v-if="iconName.length > 0"
                  :icon-name="iconName"
                  class="st-overflow-menu__icon"
                />
              </div>
              <div class="st-overflow-menu__mobile-menu-title-text">
                {{ title }}
              </div>
            </div>
          </li>
          <slot :close="() => doToggle" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import IconWrapper from './icon-wrapper'
import stUid from '../../mixins/uid'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  mixins: [stUid],
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    flipMenu: {
      type: Boolean,
      required: false,
      default: false
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false
    },
    iconName: {
      type: String,
      required: false,
      default: ''
    },
    isEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    verticalPosition: {
      type: String,
      required: false,
      default: 'center'
    },
    fixedPagePosition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      open: false,
      left: 0,
      top: 0,
      minWidth: 'unset',
      itemClicked: false,
      menuPosition: null
    }
  },
  computed: {
    menuWidth () {
      let innerWidths = this.$children.map(x => x.$el.clientWidth)
      return Math.max(...innerWidths) + 26 // right padding + 1px border
    },
    isMobile () {
      return window.screen.width < 500
    },
    menuStyle () {
      if (this.isMobile) {
        return { left: 0, bottom: 0 }
      } else {
        return {
          left: this.left + 'px',
          top: this.top + 'px',
          minWidth: this.minWidth
        }
      }
    }
  },
  watch: {
    flipMenu (val) {
      this.flipMenu = val
    }
  },
  beforeDestroy () {
    if (this.$refs.popup) {
      this.$refs.popup.remove()
    }
  },
  mounted () {
    this.$nextTick(() => {
      // set min width to accomodate the hover effect
      let longerLength = Math.max(...this.$children.map(x => x.$el.clientWidth)) * 1.13
      this.minWidth = longerLength + 24 + 'px'

      this.menuPosition = this.$el.getBoundingClientRect()

      // move popup out to body to ensure it appears above other elements
      if (this.$refs.popup) {
        document.body.appendChild(this.$refs.popup)
        this.positionPopup()
      }
    })
  },
  methods: {
    isEventTargetChild (ev) {
      if (ev.relatedTarget === null) {
        return false
      } else if (ev.relatedTarget === this.$el) {
        return true
      } else {
        return this.$refs.popup.contains(ev.relatedTarget)
      }
    },
    checkFocusOut (ev) {
      if (this.open) {
        if (!this.isEventTargetChild(ev)) {
          this.doToggle()
          setTimeout(() => {
            this.$el.focus()
          }, 1)
        }
      }
    },
    menuItemFocusOut (ev) {
      this.checkFocusOut(ev)
    },
    menuItemclick () {
      this.open = false
      setTimeout(() => {
        this.$el.focus()
      }, 1)
    },
    doClose () {
      this.open = false
    },
    doToggle (ev) {
      this.menuPosition = this.$el.getBoundingClientRect()
      this.positionPopup()
      this.open = !this.open

      if (!this.open) {
        this.$nextTick(() => {
          this.$refs.popup
            .querySelector('.bx--overflow-menu-options__btn')
            .focus()
        })
      }
    },
    positionPopup () {
      let menuHeight = this.$refs.popup.clientHeight
      this.top = this.menuPosition.top + (this.menuPosition.height - menuHeight) / 2
      if (this.flipMenu) {
        this.left = this.menuPosition.left - this.menuWidth
      } else {
        this.left = this.menuPosition.right
      }
      if (this.verticalPosition === 'top') {
        this.top = this.menuPosition.top
        this.left = this.menuPosition.left - this.menuWidth - 30
      }
      if (this.horizontal) {
        this.top = this.menuPosition.top - menuHeight + 40
      }
      if (!this.fixedPagePosition) {
        this.top += window.scrollY
      }
    }
  }
}
</script>

<style lang="scss">
</style>
