<template>
  <div
    class="tw-my-auto custom-class tw-flex tw-flex-col tw-flex-1 tw-justify-center tw-w-full"
  >
    <how-reports-work
      v-if="showHowItWorks"
      @close="showHowItWorks = false"
    />
    <div
      v-else
      class="tw-justify-between tw-py-2 tw-items-center bg-insights-bar md:tw-bg-none"
    >
      <div
        class=" tw-w-full tw-flex tw-justify-center tw-items-center  tw-overflow-y-auto"
      >
        <div class="tw-min-w-[660px]  tw-max-w-3xl">
          <p class="tw-text-center tw-font-semibold tw-text-2xl">
            Create a report
          </p>
          <div
            class="tw-flex tw-items-center tw-space-x-2 tw-mt-5 tw-mx-auto tw-w-full"
          >
            <span
              class="tw-border-t tw-w-full tw-h-2 tw-border-white-two tw-border-solid"
            />
            <span class="tw-mb-1 tw-min-w-fit">
              Set a name for your report
            </span>
            <span
              class="tw-border-t tw-w-full tw-h-2 tw-border-white-two tw-border-solid"
            />
          </div>
          <div class="mt-5">
            <ah-input
              v-model="strategyName"
              placeholder="Set a name for this report"
              :classes="['!tw-text-lg']"
              :autofocus="true"
            />
            <span
              v-if="nameError"
              class="tw-text-very-negative tw-py-2 tw-block"
            >
              {{ nameError }}
            </span>
          </div>
          <div
            class="tw-flex tw-items-center tw-space-x-2 tw-mx-auto tw-w-full"
          >
            <span
              class="tw-border-t tw-w-full tw-h-2 tw-border-white-two tw-border-solid"
            />
            <span class="tw-my-5 tw-min-w-fit">
              Select studies
            </span>
            <span
              class="tw-border-t tw-w-full tw-h-2 tw-border-white-two tw-border-solid"
            />
          </div>
          <div class="tw-my-3 tw-flex tw-justify-center tw-space-x-3">
            <button
              class="tw-px-3 tw-min-w-[150px] tw-py-2  tw-cursor-pointer tw-w-fit tw-bg-white tw-border-2 tw-text-sm tw-rounded-lg tw-border-solid"
              :class="
                selectedTypeOfStudies == 'All'
                  ? 'tw-border-black tw-text-black'
                  : 'tw-border-white-two tw-text-black-three'
              "
              @click="selectedTypeOfStudies = 'All'"
            >
              All
            </button>
            <button
              class="tw-px-3 tw-min-w-[150px] tw-py-2  tw-cursor-pointer tw-w-fit tw-bg-white tw-border-2 tw-text-sm tw-rounded-lg tw-border-solid"
              :class="
                selectedTypeOfStudies == 'Custom'
                  ? 'tw-border-black tw-text-black'
                  : 'tw-border-white-two tw-text-black-three'
              "
              @click="selectedTypeOfStudies = 'Custom'"
            >
              Custom ({{ studyList.length }})
            </button>
          </div>
          <div
            v-if="selectedTypeOfStudies == 'Custom'"
            class="tw-flex tw-flex-col tw-gap-4 tw-transition-all tw-duration-300 tw-ease-in-out  tw-overflow-y-auto "
          >
            <div class="tw-relative  tw-flex tw-items-center ">
              <input
                ref="searchInput"
                v-model="searchInput"
                type="text"
                placeholder="Search"
                class="tw-w-full tw-text-sm tw-border tw-border-transparent hover:tw-border-solid hover:tw-border-gray-700  tw-px-4 tw-py-2.5 !tw-rounded-full tw-bg-[#F6F6F6]  tw-ring-0"
                @input="filterStudies"
              >
              <span
                v-if="searchInput !== ''"
                class="tw-text-xs tw-px-2 tw-cursor-pointer tw-absolute tw-right-2 tw-z-10 -translate-y-1/2"
                @click="clearSearchInput"
              >Clear</span>
            </div>
            <div>
              <div class="st-dropdown-multiselect__options-scroll">
                <st-checkbox
                  v-for="(study, idx) in searchedStudyList"
                  :key="'option ' + idx"
                  :checked="selectedStudies.includes(study.id)"
                  class="st-dropdown-multiselect__multiple-select-option"
                  label-class="tw-ml-2 tw-cursor-pointer"
                  @input="selectDeselectStudy(study.id)"
                >
                  {{ study.name }}
                </st-checkbox>
              </div>
            </div>
          </div>
          <div
            class="tw-flex tw-items-center tw-space-x-2 tw-mx-auto tw-w-full"
          >
            <span
              class="tw-border-t tw-w-full tw-h-2 tw-border-white-two tw-border-solid"
            />
            <span class="tw-my-3 tw-min-w-fit">
              Select report framework
            </span>
            <span
              class="tw-border-t tw-w-full tw-h-2 tw-border-white-two tw-border-solid"
            />
          </div>
          <div class="tw-my-3">
            <div class="tw-grid tw-grid-cols-3 tw-gap-3">
              <div
                class=" tw-p-3 hover:tw-bg-primary-light hover:tw-border-primary  tw-border-solid tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col tw-justify-center tw-border-2"
                :class="{
                  'tw-bg-[#FFF6F2]  tw-border-[#BE6744]':
                    selectedTypeOfFramework == 'SWOT',
                  'tw-border-white-two ':
                    selectedTypeOfFramework != framework[0],
                }"
                @click="changeSelectedType(framework[0])"
              >
                <!-- <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="20.3333"
                    cy="20"
                    r="19.5"
                    fill="url(#paint0_linear_1_3)"
                  />
                  <path
                    d="M15.6438 24.7324C14.9237 24.7324 14.2948 24.6162 13.7571 24.3838C13.2239 24.1468 12.8 23.8232 12.4856 23.4131C12.1757 22.9984 11.9957 22.5244 11.9456 21.9912L11.9387 21.916H13.1692L13.176 21.9912C13.2079 22.3193 13.3333 22.6042 13.552 22.8457C13.7753 23.0827 14.0715 23.2673 14.4407 23.3994C14.8144 23.5316 15.2382 23.5977 15.7122 23.5977C16.1588 23.5977 16.5553 23.527 16.9016 23.3857C17.2525 23.2399 17.5282 23.0394 17.7288 22.7842C17.9293 22.529 18.0295 22.235 18.0295 21.9023V21.8955C18.0295 21.4854 17.8792 21.1413 17.5784 20.8633C17.2776 20.5807 16.7877 20.3643 16.1086 20.2139L15.0149 19.9746C14.026 19.7559 13.3059 19.4186 12.8547 18.9629C12.4081 18.5026 12.1848 17.9147 12.1848 17.1992V17.1924C12.1894 16.6455 12.3375 16.1624 12.6292 15.7432C12.9254 15.3239 13.3333 14.9958 13.8528 14.7588C14.3769 14.5218 14.9784 14.4033 15.6575 14.4033C16.3137 14.4033 16.8948 14.5218 17.4006 14.7588C17.9065 14.9912 18.3075 15.3057 18.6038 15.7021C18.9045 16.0986 19.0709 16.543 19.1028 17.0352L19.1096 17.1309H17.8792L17.8655 17.042C17.8199 16.7503 17.7014 16.4928 17.51 16.2695C17.3186 16.0417 17.0634 15.8617 16.7444 15.7295C16.4254 15.5973 16.0517 15.5335 15.6233 15.5381C15.2131 15.5381 14.844 15.6019 14.5159 15.7295C14.1877 15.8571 13.9257 16.0417 13.7297 16.2832C13.5383 16.5202 13.4426 16.8118 13.4426 17.1582V17.165C13.4426 17.557 13.5885 17.8874 13.8801 18.1562C14.1718 18.4251 14.6526 18.6325 15.3225 18.7783L16.4163 19.0244C17.1044 19.1748 17.6581 19.3708 18.0774 19.6123C18.4967 19.8538 18.802 20.1501 18.9934 20.501C19.1894 20.8473 19.2874 21.2575 19.2874 21.7314V21.7383C19.2874 22.3535 19.137 22.8844 18.8362 23.3311C18.5354 23.7777 18.1116 24.124 17.5647 24.3701C17.0178 24.6117 16.3775 24.7324 15.6438 24.7324ZM20.552 24.5L24.1887 14.6357H25.1321V16.2285H24.7424L21.844 24.5H20.552ZM22.1995 21.6768L22.5823 20.6309H27.0393L27.4221 21.6768H22.1995ZM27.7502 24.5L24.8518 16.2285V14.6357H25.4055L29.0422 24.5H27.7502Z"
                    fill="#364139"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_3"
                      x1="20.3333"
                      y1="0.5"
                      x2="20.3333"
                      y2="39.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#EBD7C1" />
                      <stop
                        offset="1"
                        stop-color="#886D61"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                </svg> -->
                <p
                  class="tw-text-base tw-font-semibold tw-my-2 tw-text-primary-dark tw-text-center"
                >
                  SWOT Analysis
                </p>
                <p
                  class="tw-w-2/3 tw-text-sm tw-mx-auto tw-text-center tw-text-primary"
                >
                  Strength, Weakness, Opportunity, Threat
                </p>
              </div>
              <div
                class=" tw-p-3 hover:tw-bg-primary-light hover:tw-border-primary tw-border-2  tw-border-solid tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col  tw-items-center "
                :class="{
                  'tw-bg-[#FFF6F2]  tw-border-[#BE6744]':
                    selectedTypeOfFramework == framework[1],
                  'tw-border-white-two ':
                    selectedTypeOfFramework != framework[1],
                }"
                @click="changeSelectedType(framework[1])"
              >
                <p
                  class="tw-text-base tw-font-semibold tw-my-2 tw-text-primary-dark tw-text-center"
                >
                  Primary Themes
                </p>
                <p
                  class="tw-w-2/3 tw-text-sm tw-mx-auto tw-text-center tw-text-primary"
                >
                  Extract primary themes from the data
                </p>
              </div>
              <div
                class=" tw-p-3 hover:tw-bg-primary-light hover:tw-border-primary tw-border-2  tw-border-solid tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col  tw-items-center "
                :class="{
                  'tw-bg-[#FFF6F2]  tw-border-[#BE6744]':
                    selectedTypeOfFramework == framework[2],
                  'tw-border-white-two ':
                    selectedTypeOfFramework != framework[2],
                }"
                @click="changeSelectedType(framework[2])"
              >
                <p
                  class="tw-text-base tw-font-semibold tw-my-2 tw-text-primary-dark tw-text-center"
                >
                  Retrospective
                </p>
                <p
                  class="tw-w-2/3 tw-text-sm tw-mx-auto tw-text-center tw-text-primary"
                >
                  Analyze the past data
                </p>
              </div>
              <div
                class=" tw-p-3 hover:tw-bg-primary-light hover:tw-border-primary tw-border-2 tw-border-solid tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col ttw-items-center "
                :class="{
                  'tw-bg-[#FFF6F2]  tw-border-[#BE6744]':
                    selectedTypeOfFramework == framework[3],
                  'tw-border-white-two ':
                    selectedTypeOfFramework != framework[3],
                }"
                @click="changeSelectedType(framework[3])"
              >
                <p
                  class="tw-text-base tw-font-semibold tw-my-2 tw-text-primary-dark tw-text-center"
                >
                  Idea Generation
                </p>
                <p
                  class="tw-w-2/3 tw-text-sm tw-mx-auto tw-text-center tw-text-primary"
                >
                  Generate new ideas
                </p>
              </div>
              <div
                class=" tw-p-3 hover:tw-bg-primary-light hover:tw-border-primary tw-border-2 tw-border-solid tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col ttw-items-center "
                :class="{
                  'tw-bg-[#FFF6F2]  tw-border-[#BE6744]':
                    selectedTypeOfFramework == framework[4],
                  'tw-border-white-two ':
                    selectedTypeOfFramework != framework[3],
                }"
                @click="changeSelectedType(framework[4])"
              >
                <p
                  class="tw-text-base tw-font-semibold tw-my-2 tw-text-primary-dark tw-text-center"
                >
                  Thematic Analysis
                </p>
                <p
                  class="tw-w-2/3 tw-text-sm tw-mx-auto tw-text-center tw-text-primary"
                >
                  Generate thematic analysis
                </p>
              </div>
              <div
                class=" tw-p-3 hover:tw-bg-primary-light hover:tw-border-primary tw-border-2 tw-border-solid tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col ttw-items-center "
                :class="{
                  'tw-bg-[#FFF6F2]  tw-border-[#BE6744]':
                    selectedTypeOfFramework == framework[5],
                  'tw-border-white-two ':
                    selectedTypeOfFramework != framework[3],
                }"
                @click="changeSelectedType(framework[5])"
              >
                <p
                  class="tw-text-base tw-font-semibold tw-my-2 tw-text-primary-dark tw-text-center"
                >
                  Summary with Themes
                </p>
                <p
                  class="tw-w-2/3 tw-text-sm tw-mx-auto tw-text-center tw-text-primary"
                >
                  Generate summary with top themes
                </p>
              </div>
              <div
                class=" tw-p-3 hover:tw-bg-primary-light hover:tw-border-primary tw-border-2 tw-border-solid tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col ttw-items-center "
                :class="{
                  'tw-bg-[#FFF6F2]  tw-border-[#BE6744]':
                    selectedTypeOfFramework == framework[6],
                  'tw-border-white-two ':
                    selectedTypeOfFramework != framework[3],
                }"
                @click="changeSelectedType(framework[6])"
              >
                <p
                  class="tw-text-base tw-font-semibold tw-my-2 tw-text-primary-dark tw-text-center"
                >
                  Summary with Questions
                </p>
                <p
                  class="tw-w-2/3 tw-text-sm tw-mx-auto tw-text-center tw-text-primary"
                >
                  Generate summary with questions for discussion
                </p>
              </div>
              <div
                class=" tw-p-3 hover:tw-bg-primary-light hover:tw-border-primary tw-border-2 tw-border-solid tw-cursor-pointer tw-rounded-lg tw-flex tw-flex-col ttw-items-center "
                :class="{
                  'tw-bg-[#FFF6F2]  tw-border-[#BE6744]':
                    selectedTypeOfFramework == framework[7],
                  'tw-border-white-two ':
                    selectedTypeOfFramework != framework[3],
                }"
                @click="changeSelectedType(framework[7])"
              >
                <p
                  class="tw-text-base tw-font-semibold tw-my-2 tw-text-primary-dark tw-text-center"
                >
                  Summary with Quotes
                </p>
                <p
                  class="tw-w-2/3 tw-text-sm tw-mx-auto tw-text-center tw-text-primary"
                >
                  Generate summary with top quotes from responses
                </p>
              </div>
            </div>
          </div>
          <div class="tw-mt-10 tw-flex tw-justify-between">
            <ah-button
              type="outlined"
              size="large"
              @click="goBackHandler"
            >
              Go back
            </ah-button>
            <ah-button
              size="large"
              :disabled="
                !strategyName ||
                  (selectedTypeOfStudies == 'Custom' &&
                  selectedStudies.length === 0)
              "
              @click="generateReportHandler"
            >
              Generate report
            </ah-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AhButton, AhInput, StCheckbox } from '../../common'
import HowReportsWork from './how-reports-work.vue'
export default {
  components: {
    AhButton,
    AhInput,
    StCheckbox,
    HowReportsWork
  },

  data: function () {
    return {
      showDropdownOptions: false,
      activeFilter: 'all',

      strategyName: '',
      selectionTypes: ['All', 'Custom'],
      selectedTypeOfStudies: 'All',
      searchInput: '',
      studyList: [],
      searchedStudyList: [],
      selectedTypeOfFramework: 'SWOT',
      framework: [
        'SWOT',
        'Primary Themes',
        'Retrospective',
        'Idea Generation',
        'Thematic Analysis',
        'Summary with Themes',
        'Summary with Questions',
        'Summary with Quotes'
      ],
      selectedStudies: [],
      nameError: null,
      showHowItWorks: false
    }
  },
  mounted () {
    this.getStudyHavingStrategies()
  },
  methods: {
    goBackHandler () {
      // go back to /welcome page
      window.location = '/reports'
    },
    generateReportHandler () {
      this.$store.commit('toggleFullSpinner', true)
      let selectedFramework = 'SWOT'
      if (this.selectedTypeOfFramework === 'Primary Themes') {
        selectedFramework = 'primaryThemes'
      } else if (this.selectedTypeOfFramework === 'Retrospective') {
        selectedFramework = 'retrospective'
      } else if (this.selectedTypeOfFramework === 'Idea Generation') {
        selectedFramework = 'ideaGeneration'
      } else if (this.selectedTypeOfFramework === 'Thematic Analysis') {
        selectedFramework = 'thematicAnalysis'
      } else if (this.selectedTypeOfFramework === 'Summary with Themes') {
        selectedFramework = 'summaryWithThemes'
      } else if (this.selectedTypeOfFramework === 'Summary with Questions') {
        selectedFramework = 'summaryWithQuestions'
      } else if (this.selectedTypeOfFramework === 'Summary with Quotes') {
        selectedFramework = 'summaryWithQuotes'
      }
      this.$axios({
        method: 'post',
        url: `/strategies`,
        data: {
          name: this.strategyName,
          survey_ids:
            this.selectedStudies.length > 0
              ? this.selectedStudies
              : this.studyList.map((study) => study.id),
          framework: selectedFramework
        }
      })
        .then((response) => {
          console.log(response.data)
          location.href = `/reports`
          this.$store.commit('toggleFullSpinner', false)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('toggleFullSpinner', false)
          this.nameError = 'Name already exists'
        })
    },
    changeSelectedType (type) {
      this.selectedTypeOfFramework = type
    },
    filterStudies () {
      this.searchedStudyList = this.studyList.filter((study) =>
        study.name.toLowerCase().includes(this.searchInput.toLowerCase())
      )
    },
    selectDeselectStudy (idx) {
      // console.log(idx)
      if (this.selectedStudies.includes(idx)) {
        this.selectedStudies = this.selectedStudies.filter(
          (study) => study !== idx
        )
      } else {
        this.selectedStudies.push(idx)
      }
    },
    clearSearchInput () {
      this.searchInput = ''
      this.$refs.searchInput.focus()
    },
    getStudyHavingStrategies () {
      this.loading = true
      setTimeout(() => {
        this.$axios({
          method: 'post',
          url: `/study/get_answers_studies`
        })
          .then((response) => {
            // console.log(response.data.surveys);
            this.loading = false
            this.studyList = response.data.surveys.filter((survey) =>
              survey.plans.some((plan) =>
                plan.title.toLowerCase().includes('strategies')
              )
            )
            this.searchedStudyList = this.studyList
            // console.log(this.studyList)
            // this.isStudyExist = this.studiesList?.length === 0;
          })
          .catch((error) => {
            this.loading = false

            console.log(error)
          })
      }, 200)
    }
  }
}
</script>

<style>
.custom-class {
  @media (min-height: 900px) {
    height: calc(100vh - 10px);
  }
}
</style>
