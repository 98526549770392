var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-max-w-full tw-flex-wrap tw-justify-between tw-items-center tw-my-10 tw-space-x-1 tw-text-gray-100"},[_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2 tw-flex-wrap "},[(_vm.totalPages != 1)?_c('button',{staticClass:"tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-py-0 tw-border tw-rounded-md tw-bg-gray-100 tw-border-gray-800",class:{
        'tw-opacity-80 tw-cursor-not-allowed': _vm.currentPage === 1
      },attrs:{"title":"Previous","type":"button","disabled":_vm.currentPage === 1},on:{"click":function($event){return _vm.changePageHandler(_vm.currentPage - 1)}}},[_c('svg',{staticClass:"tw-w-4",attrs:{"viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"15 18 9 12 15 6"}})])]):_vm._e(),_vm._v(" "),_vm._l((_vm.visiblePageNumbers),function(range){return [(range === '...')?_c('button',{key:range,staticClass:"tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-text-sm  tw-rounded  tw-bg-gray-100 tw-text-black",attrs:{"disabled":true}},[_vm._v("\n        "+_vm._s(range)+"\n      ")]):_c('button',{key:range,staticClass:"tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-text-sm  tw-rounded  ",class:{
          'tw-bg-primary-light tw-border-primary/30 tw-text-primary tw-font-semibold tw-border tw-border-solid ': range === _vm.currentPage,
          'tw-bg-gray-100 tw-text-black': range !== _vm.currentPage
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.changePageHandler(range)}}},[_vm._v("\n        "+_vm._s(range)+"\n      ")])]}),_vm._v(" "),(_vm.totalPages > 2 && _vm.totalPages != 1)?_c('button',{staticClass:"tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-py-0 tw-border tw-rounded-md tw-bg-gray-100 tw-border-gray-800",class:{
        'tw-opacity-70 tw-cursor-not-allowed': _vm.currentPage === _vm.totalPages
      },attrs:{"disabled":_vm.currentPage === _vm.totalPages,"title":"Next","type":"button"},on:{"click":function($event){return _vm.changePageHandler(_vm.currentPage + 1)}}},[_c('svg',{staticClass:"tw-w-4",attrs:{"viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"9 18 15 12 9 6"}})])]):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-space-x-2 tw-items-center tw-text-gray-800"},[_c('div',[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"tw-relative "},[_c('div',{staticClass:"tw-border tw-border-white-two tw-rounded-lg tw-space-x-2 tw-items-center tw-flex tw-cursor-pointer tw-px-1 tw-py-1 tw-border-solid",attrs:{"tabindex":"2"},on:{"click":_vm.togglePaginationDropdown,"blur":_vm.handleBlur}},[_c('span',[_vm._v("\n          "+_vm._s(_vm.selectedPaginationOption)+"\n        ")]),_vm._v(" "),_c('svg',{attrs:{"width":"11","height":"12","viewBox":"0 0 11 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4.88537 9.02744L0.9697 4.14556C0.50781 3.5697 0.882685 2.66846 1.58411 2.66846H9.41546C10.1169 2.66846 10.4918 3.5697 10.0299 4.14556L6.11419 9.02744C5.78893 9.43297 5.21064 9.43297 4.88537 9.02744Z","fill":"#747474"}})])]),_vm._v(" "),(_vm.showPaginationDropdown)?_c('div',{staticClass:"tw-flex tw-rounded-md tw-flex-col tw-absolute tw-bottom-8 tw-z-10 tw-bg-white tw-shadow-xl tw-border tw-border-white-two tw-border-solid tw-left-0 tw-w-full  tw-space-y-1"},_vm._l((_vm.paginationOptions),function(option){return _c('span',{key:option,staticClass:"tw-cursor-pointer tw-w-full tw-p-2",on:{"click":function($event){return _vm.changeSelectedPaginationOption(option)}}},[_vm._v("\n          "+_vm._s(option)+"\n        ")])}),0):_vm._e()]),_vm._v(" "),_c('div',[_vm._v("\n      "+_vm._s(_vm.currentPage)+"- "+_vm._s(_vm.perPage*_vm.currentPage)+" of "+_vm._s(_vm.totalEntries)+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }