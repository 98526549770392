<template>
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26ZM2.73842 26C2.73842 38.847 13.153 49.2616 26 49.2616C38.847 49.2616 49.2616 38.847 49.2616 26C49.2616 13.153 38.847 2.73842 26 2.73842C13.153 2.73842 2.73842 13.153 2.73842 26Z" fill="#ED2C2C" fill-opacity="0.3"/>
<circle cx="26" cy="26" r="21" fill="#ED2C2C"/>
</svg>
</template>

<script>
export default {}
</script>
