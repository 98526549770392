<template>
  <st-modal v-model="showModal" >
    <div v-if="error">
      <p class="tw-text-error">
        {{ error }}
      </p>
    </div>
    <div v-else>
      <div v-if="paymentAdded">
        <p>Your payment method has been {{ type=='add' ? 'added': 'Updated' }}! You can continue by pressing close button.</p>
        <div class="mt-5">
          <st-button @click="refreshPage">
            Close
          </st-button>
        </div>
      </div>
      <div
        v-else
        class="py-10"
      >
        <h2 class="font-semibold">
          {{ type == 'add' ? 'Add': 'Update' }} Payment Details
        </h2>
        <div class="st-team__add-members-modal-body">
          <template>
            <div class="st-team__add-members-error-message">
              {{ title }}
            </div>
          </template>
          <stripe-element-card
            ref="elementRef"
            :pk="publishableKey"
            @token="tokenCreated"
          />
          <div class="mt-5 flex justify-end">
            <st-button @click="submit">
              {{ type == 'add' ? 'Add': 'Update' }} payment method
            </st-button>
          </div>
        </div>
      </div>
    </div>
  </st-modal>

  <!-- <div>
    <stripe-element-card
      ref="elementRef"
      :pk="publishableKey"
      @token="tokenCreated"
    />
    <button @click="submit">
      Generate token
    </button>
  </div> -->
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { StModal, StButton } from '.'
export default {
  name: 'StripePaymentModal',

  components: {
    StripeElementCard,
    StModal,
    StButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'add'
    }
  },
  data () {
    this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_51MYrFCKzxhMDsXa2LUprUT7K1DSZ1Du6sEaD48I9QM6iAO26WRDjPBPuJ5Li7AXMYJmQNmgCId2BmcVtRX5QU8SA00ehomQLPm'
    return {
      token: null,
      paymentAdded: false,
      showModal: false,
      error: null
    }
  },

  mounted () {
    // console.log(process.env.STRIPE_PUBLISHABLE_KEY)
    // this.stripe = new VueStripe(process.env.STRIPE_PUBLISHABLE_KEY) // replace with your public stripe key
  },
  methods: {
    submit () {
      // this will trigger the process
      this.$refs.elementRef.submit()
    },
    toggle () {
      this.showModal = !this.showModal
    },
    refreshPage () {
      this.$store.commit('toggleFullSpinner', true)
      window.location.reload()
    },
    tokenCreated (token) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios
        .request({
          method: 'post',
          url: this.type == 'add' ? '/payments/create_customer' : '/payments/update_card',
          data: {
            token: token
          }
        })
        .then((res) => {
          console.log(res)
          this.$store.commit('toggleFullSpinner', false)
          if (res.data) {
            this.paymentAdded = true
          }
          // change status to 'deactivated' for selected members
          // this.selectedMembers.forEach(member => {
          //   member.status = 'deactivated'
          //   member.selected = false
          // })
          // this.deleteMemberModal = false
          // this.toggleAllChecks(false)
        })
        .catch((err) => {
          this.$store.commit('toggleFullSpinner', false)
          console.error(err)
          this.error = 'Something went wrong. Please try again.'
        })
      // handle the token
      // send it to your server
    }
  }
}
</script>
