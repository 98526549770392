<template>
  <div class="icon_help">
    <div
      class="icon_help__svg"
      style="width:20px; height:20px;"
    >
      <svg
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-1 0a7 7 0 10-7 7 7 7 0 007-7z" />
        <path
          d="M-21003.975-9292.724l-1.037.124c-.348-3.487 3.861-3.6 5.229-2.221a2.981 2.981 0 01.232 3.659c-.77.992-2.137 1.6-2.127 3l-1.047.008c-.014-1.946 1.658-2.752 2.344-3.637a1.958 1.958 0 00-.15-2.3 2.593 2.593 0 00-3.129.042 1.774 1.774 0 00-.315 1.325z"
          transform="translate(21010.031 9298.642)"
        />
        <path
          d="M1 .5V1H0V0h1z"
          transform="translate(7.333 12)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
