<template>
  <div class="bx--grid">
    <!-- <div class="mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
    </div> -->
    <div class="bx--offset-sm-3 bx--col-sm-6">
      <h1 class="st-published__header-spacing">
        Great Job!
      </h1>
      <p class="st-published__instructions-text st-published__instructions-text--top">
        Survey "{{ survey.name }}" has been published. You will be notified as soon as you start getting responses.
      </p>
      <p class="st-published__instructions-text st-published__instructions-text--bottom">
        If you want to share this survey with more people, click the share link below, or edit the audience within the survey hub.
      </p>
      <textarea
        ref="copyText"
        class="st-share-text-area"
        readonly
        :value="`${baseUrl}/study/${survey.id}`"
      />
      <st-tooltip
        class="st-published__share"
        direction="top"
        :tip="tipMessage"
        :is-active="false"
        :flash="showTooltip"
      >
        <st-button
          class="st-published__share"
          caption="Share Link"
          icon-name="share-link"
          :show-action="true"
          @click="copyToClipboard"
        />
      </st-tooltip>
    </div>
  </div>
</template>

<script>
import { IconWrapper, StButton, StTooltip } from '../../common'

export default {
  components: {
    IconWrapper,
    StButton,
    StTooltip
  },
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      showTooltip: false,
      tipMessage: 'Link Copied'
    }
  },
  computed: {
    baseUrl () { return window.location.origin }
  },
  methods: {
    copyToClipboard () {
      this.$refs.copyText.select()
      let supported = document.execCommand('copy')
      if (!supported) { this.tipMessage = 'Tap to copy is not supported' }
      this.showLinkCopiedMessage()
    },
    showLinkCopiedMessage () {
      this.showTooltip = true
      setTimeout(() => {
        this.$nextTick(() => {
          this.showTooltip = false
        })
      }, 1250)
    }
  }
}
</script>
