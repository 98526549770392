<template>
  <label
    :for="id"
    class="tw-flex tw-cursor-pointer tw-select-none tw-items-center"
  >
    <div class="tw-relative">
      <input
        :id="id"
        v-model="isChecked"
        type="checkbox"
        class="tw-sr-only"
        @change="handleChange"
      >
      <div :class="{'tw-block tw-h-6 tw-w-10 tw-rounded-full':true, 'tw-bg-white-two': !checked, 'tw-bg-primary/20': checked}" />
      <div
        :class="{
          'tw-absolute tw-h-4 tw-w-4 tw-top-1 tw-rounded-full tw-transition  ' : true,
          'tw-bg-primary tw-right-1 ': checked,
          'tw-bg-white-three tw-left-1 ': !checked
        }"
      />
    </div>
  </label>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    }

  },
  data () {
    return {
      isChecked: this.checked // Use a local data property
    }
  },

  // watch for the checked to change
  watch: {
    checked (val) {
      this.isChecked = val
    }
  },

  mounted () {
    this.isChecked = this.checked || false // Update the local data property
  },
  methods: {
    handleChange () {
      this.isChecked = !this.isChecked
      this.$nextTick(() => {
        this.$emit('input', this.isChecked)
      })
    }
  }
}
</script>
