var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-participant-invite-page"},[(_vm.survey.pageImageUrl)?_c('div',{staticClass:"edit-cover-photo__photo-and-button st-participant-invite-page__cover-photo",on:{"focus":function($event){_vm.coverPhotoHovered = true},"focusout":function($event){_vm.coverPhotoHovered = false},"mouseleave":function($event){_vm.coverPhotoHovered = false},"mouseover":function($event){_vm.coverPhotoHovered = true}}},[_c('div',{class:{
        'edit-cover-photo__photo': true,
        'edit-cover-photo__default': !_vm.survey.pageImageUrl,
        'edit-cover-photo__photo--hovered': _vm.coverPhotoHovered
      },style:(_vm.surveyCoverPhotoStyle)})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bx--grid st-participant-invite-page__grid"},[_c('div',{staticClass:"bx--row st-participant-invite-page__row"},[_c('div',{class:{
          'st-participant-invite-page__information-and-button': true,
          'st-participant-invite-page__information-and-button--no-cover-photo': !_vm.survey.pageImageUrl
        }},[_c('div',{key:"company-logo",staticClass:"edit-company-logo__company-logo edit-company-logo--participant-invite-page",style:(_vm.companyLogoStyle)}),_vm._v(" "),_c('div',{staticClass:"st-participant-invite-page__archived-section",class:{'st-participant-invite-page__archived-section--without-cover-photo': !_vm.survey.pageImageUrl }},[_c('icon-wrapper',{staticClass:"st-participant-invite-page__archived-icon",attrs:{"icon-name":"info","actionable":false}}),_vm._v(" "),_c('div',{staticClass:"st-participant-invite-page__archived-text"},[_vm._v("\n            Hi, this survey is no longer available.\n          ")])],1)]),_vm._v(" "),_c('div',{staticClass:"st-powered-by-spacetrics st-powered-by-spacetrics--participant-invite"},[_vm._v("\n        Powered by\n        "),_c('icon-wrapper',{staticClass:"st-powered-by-spacetrics__icon",attrs:{"actionable":false,"icon-name":"spacetrics"}}),_vm._v("\n        AskHumans\n      ")],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }