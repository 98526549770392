<template>
  <div class="tw-max-w-[520px] tw-mx-auto h-full tw-flex tw-flex-col fade-in">
    <div class="flex tw-justify-center tw-flex-col tw-w-full tw-max-h-full  ">
      <div class="tw-flex tw-flex-col tw-items-center ">
        <img
          :src="wizardIcon"
          alt=""
          class="tw-pl-2 tw-my-2"
        >
        <span class="tw-text-2xl tw-font-semibold ">
          Wizard
        </span>
      </div>
      <p class="tw-text-black-two tw-mt-4">
        Simply enter in details about your organization, the type of insights you want and also your role.  Our Wizard will then suggest a series of open ended questions for your study that you will then be able to edit or publish.
      </p>
      <div class="tw-flex tw-items-center tw-space-x-2 tw-mt-3">
        <span class="tw-h-[1px] tw-bg-white-two tw-w-1/5 " />
        <p
          v-if="currentUser.companyName"
          class="tw-w-3/5 tw-px-2 tw-text-center"
        >
          From your account, we know you work at
          {{ currentUser.companyName }}, but can you share
          more details about your organization?
        </p>
        <p v-else>
          Can you tell us about your organization?
        </p>
        <span class="tw-h-[1px] tw-bg-white-two  tw-w-1/5" />
      </div>
      <div class="tw-mt-6">
        <ah-input
          v-model="organizationInfo"
          :classes="['!tw-text-lg']"
          placeholder="Place your answer here*"
        />
      </div>
      <div class="tw-flex tw-items-center tw-space-x-2 tw-mt-3">
        <span class="tw-h-[1px] tw-bg-white-two tw-w-1/5 " />
        <p class="tw-w-3/5 tw-px-2 tw-text-center">
          What is the primary topic you would like
          to have answered?
        </p>
        <span class="tw-h-[1px] tw-bg-white-two  tw-w-1/5" />
      </div>
      <div class="tw-mt-6">
        <ah-input
          v-model="primaryTopic"
          :classes="['!tw-text-lg']"
          placeholder="Place your answer here*"
        />
      </div>
      <div class="tw-flex tw-items-center tw-space-x-2 tw-mt-3">
        <span class="tw-h-[1px] tw-bg-white-two tw-w-1/5 " />
        <p class="tw-w-3/5 tw-px-2 tw-text-center">
          What themes are you interested in exploring
          from people’s answers?
        </p>
        <span class="tw-h-[1px] tw-bg-white-two  tw-w-1/5" />
      </div>
      <div class="tw-mt-6">
        <ah-input
          v-model="themesInterested"
          :classes="['!tw-text-lg']"
          placeholder="Place your answer here*"
        />
      </div>
    </div>
    <div
      v-if="!showAdditionalGuidance"
      class="tw-mt-4"
    >
      <ah-button
        type="outlined"
        size="large"

        @click="displayAdditionalGuidance"
      >
        Add additional guidance
      </ah-button>
    </div>
    <div
      v-else
      class="tw-flex tw-mt-4 tw-justify-between tw-space-x-5 tw-items-center"
    >
      <ah-input
        ref="additionalGuidance"
        v-model="additionalGuidance"
        placeholder="Write any additional guidance here"
      />
      <svg
        class="tw-cursor-pointer tw-mb-3"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="hideAdditionalGuidance"
      >
        <g clip-path="url(#clip0_222_456)">
          <g clip-path="url(#clip1_222_456)">
            <path
              d="M8.00003 15.2495C11.9246 15.2495 15.1111 12.1128 15.1111 8.24951C15.1111 4.38622 11.9246 1.24951 8.00003 1.24951C4.07541 1.24951 0.888916 4.38622 0.888916 8.24951C0.888916 12.1128 4.07541 15.2495 8.00003 15.2495ZM8.00003 2.49002C11.2225 2.49002 13.8509 5.07736 13.8509 8.24951C13.8509 11.4217 11.2225 14.009 8.00003 14.009C4.77752 14.009 2.14911 11.4217 2.14911 8.24951C2.14911 5.07736 4.77752 2.49002 8.00003 2.49002Z"
              fill="#474747"
            />
            <path
              d="M5.65964 8.95828H10.3404C10.6824 8.95828 10.9705 8.67474 10.9705 8.33803C10.9705 8.00132 10.6824 7.71777 10.3404 7.71777H5.65964C5.31759 7.71777 5.02954 8.00132 5.02954 8.33803C5.02954 8.67474 5.31759 8.95828 5.65964 8.95828Z"
              fill="#474747"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_222_456">
            <rect
              width="16"
              height="15"
              fill="white"
              transform="translate(0 1)"
            />
          </clipPath>
          <clipPath id="clip1_222_456">
            <rect
              width="14.2222"
              height="14"
              fill="white"
              transform="translate(0.888916 1.24951)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="tw-w-full tw-flex tw-justify-between tw-mt-6">
      <ah-button
        type="outlined"
        size="large"
        @click="goBack"
      >
        Go back
      </ah-button>
      <ah-button
        type="primary"
        size="large"
        :disabled="!organizationInfo || !primaryTopic || !themesInterested"
        @click="generateTemplate"
      >
        Generate template
      </ah-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { AhInput, AhButton } from '../../common'
import wizardIcon from '../../../../assets/images/wizard_icon.png'

export default {

  components: {
    AhInput,
    AhButton
  },

  props: {

  },
  data () {
    return {
      organizationInfo: '',
      primaryTopic: '',
      themesInterested: '',
      showAdditionalGuidance: false,
      additionalGuidance: null,
      wizardIcon: wizardIcon
    }
  },
  computed: {
    ...mapState(['currentUser'])
  },
  mounted () {

  },

  methods: {
    displayAdditionalGuidance () {
      this.showAdditionalGuidance = true
      setTimeout(() => {
        this.$refs.additionalGuidance.focus()
      }, 100)
    },
    hideAdditionalGuidance () {
      this.showAdditionalGuidance = false
    },
    // emit back handler
    goBack () {
      this.$emit('back')
    },
    generateTemplate () {
      this.$emit('create', {
        organizationInfo: this.organizationInfo,
        primaryTopic: this.primaryTopic,
        themesInterested: this.themesInterested,
        additionalGuidance: this.additionalGuidance
      })
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
