<template>
  <div class="tw-w-full tw-h-full tw-mb-5 tw-mt-16">
    <div class="tw-flex tw-w-full tw-justify-between">
      <div class="flex tw-justify-center tw-w-full tw-max-h-full tw-items-center">
        <wizard-information
          v-if="currentStep==1"
          @back="goBack"
          @create="goToStepTwo"
        />
        <wizard-generation
          v-if="currentStep==2"
          :survey="survey"
          :questions="generatedQuestions"
          @back="goToStepOne"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WizardInformation from './wizard-information.vue'
import WizardGeneration from './wizard-generation.vue'

export default {
  components: {
    WizardInformation,
    WizardGeneration
  },
  props: {
    survey: {
      type: Object,
      required: true
    }

  },
  data () {
    return {
      currentStep: 1,
      generatedQuestions: []
    }
  },
  computed: {
    ...mapState(['currentUser'])
  },
  mounted () {

    // setTimeout(() => {
    //   console.log(JSON.stringify(this.currentUser))
    // }, 100)
  },

  methods: {

    goBack () {
      this.$emit('back')
    },

    // emit back handler
    goToStepOne () {
      this.currentStep = 1
      this.$emit('hidePreview')
    },
    goToStepTwo (questions) {
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.post(`/study/${this.survey.id}/wizard`, {

        first_answer: questions.organizationInfo,
        second_answer: questions.primaryTopic,
        third_answer: questions.themesInterested,
        additionalInfo: questions.additionalGuidance

      }).then((response) => {
        this.$store.commit('toggleFullSpinner', false)
        console.log(response)
        this.generatedQuestions = response.data.data
        this.currentStep = 2
        // this.$emit('showPreview')
      }).catch((error) => {
        this.$store.commit('toggleFullSpinner', false)
        console.log(error)
      })
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
