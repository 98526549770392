<template>
  <div>
    <div class="tw-bg-white-one tw-py-10">
      <div class="bx--grid">
        <div class="tw-flex tw-justify-between tw-items-center">
          <div class="tw-flex tw-space-x-3">
            <div class="tw-flex tw-space-x-2">
              <a
                href="/admin"
                class="tw-bg-white tw-w-10 tw-h-10 tw-rounded-full  tw-items-center tw-flex tw-justify-center"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_58_1383)">
                    <path
                      d="M5.50013 8.01197C5.50013 7.83774 5.56382 7.66651 5.67963 7.54034L9.39127 3.69822C9.64315 3.43387 10.0543 3.43387 10.309 3.69522C10.5638 3.95657 10.5638 4.38314 10.3119 4.64749L7.05484 8.01498L10.3119 11.3795C10.5522 11.6528 10.532 12.0764 10.2685 12.3257C10.0224 12.557 9.64604 12.557 9.39995 12.3257L5.67963 8.4836C5.56382 8.35743 5.49723 8.18621 5.50013 8.01197Z"
                      fill="#474747"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_58_1383">
                      <rect
                        width="5"
                        height="9"
                        fill="white"
                        transform="translate(10.5 12.5) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
            <div class="tw-flex tw-flex-col tw-space-y-1">
              <span class="tw-text-black-three tw-font-[13px]">
                Edit prompts
              </span>
              <p class="tw-text-lg tw-font-semibold">
                {{ study.name }}
              </p>
            </div>
          </div>
          <div class="tw-flex tw-space-x-3">
            <ah-button @click="saveNewPrompts">
              Save changes
            </ah-button>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-mt-10">
      <div
        class="tw-flex tw-justify-center  tw-items-start tw-space-x-10 tw-py-5 tw-border-b tw-border-solid tw-border-white-two"
      >
        <p class="tw-w-[200px] tw-mt-3">
          For suggested reply
        </p>
        <div class="tw-flex tw-flex-col tw-space-y-1">
          <span class="tw-text-sm">Current</span>
          <textarea
            v-model="suggestedReplyCurrentPrompt"
            type="text"
            disabled
            placeholder="suggested reply prompt"
            class="tw-px-2 tw-py-4 disabled:tw-cursor-not-allowed !tw-rounded-[10px] tw-border-none tw-ring-2 tw-ring-white-three tw-min-w-[340px]"
          />
          <p

            class="tw-border tw-border-white-two tw-border-solid tw-bg-primary-light tw-max-w-[350px] tw-p-2 tw-rounded-lg !tw-mt-3"
          >
            {{ suggestedReplyCurrentLoading? 'Loading...' : suggestedReplyCurrentResponse }}
          </p>
        </div>
        <div class="tw-flex space-x-4 tw-items-start">
          <div class="tw-flex tw-flex-col tw-space-y-1">
            <span class="tw-text-sm">New</span>
            <textarea
              v-model="suggestedReplyNewPrompt"
              type="text"
              placeholder="suggested reply prompt"
              class="tw-px-2 tw-py-4 !tw-rounded-[10px] tw-border-none tw-ring-2 tw-ring-white-three tw-min-w-[340px]"
            />
            <p
              v-if="suggestedReplyNewLoading || suggestedReplyNewResponse"
              class="tw-border tw-border-white-two tw-border-solid tw-bg-primary-light tw-max-w-[350px] tw-p-2 tw-rounded-lg !tw-mt-3"
            >
              {{ suggestedReplyNewLoading? 'Loading...' : suggestedReplyNewResponse }}
            </p>
          </div>
          <button
            :disabled="!suggestedReplyNewPrompt"
            class="tw-px-4 tw-ml-5 disabled:tw-cursor-not-allowed tw-mt-8 tw-text-sm tw-py-2 tw-bg-primary-light  tw-rounded-2xl hover:tw-bg-primary hover:tw-text-white tw-text-primary-dark"
            @click="testSuggestedReplyPrompt(true)"
          >
            Test
          </button>
        </div>
      </div>
      <div
        class="tw-flex tw-justify-center  tw-items-start tw-space-x-10 tw-py-5 tw-border-b tw-border-solid tw-border-white-two"
      >
        <p class="tw-w-[200px] tw-mt-3">
          For theme for improvements
        </p>
        <div class="tw-flex tw-flex-col tw-space-y-1">
          <span class="tw-text-sm">Current</span>
          <textarea
            v-model="themeForImprovementsCurrentPrompt"
            disabled
            type="text"
            placeholder="Theme for improvements prompt"
            class="tw-px-2 disabled:tw-cursor-not-allowed tw-py-4 !tw-rounded-[10px] tw-border-none tw-ring-2 tw-ring-white-three tw-min-w-[340px]"
          />
          <p

            class="tw-border tw-border-white-two tw-border-solid tw-bg-primary-light tw-max-w-[350px] tw-p-2 tw-rounded-lg !tw-mt-3"
          >
            <span
              v-if="themeForImprovementsCurrentLoading"
              class="tw-flex tw-flex-col tw-space-y-1"
            >
              Loading...
            </span>
            <span
              v-else-if="themeForImprovementsCurrentResponse.heading"
              class="tw-flex tw-flex-col tw-gap-2 tw-capitalize"
            >
              <span>
                <span class="tw-font-semibold">heading</span>: {{ themeForImprovementsCurrentResponse.heading }}
              </span>
              <span>
                <span class="tw-font-semibold">  description  </span> : {{ themeForImprovementsCurrentResponse.description }}
              </span>
              <span>
                <span class="tw-font-semibold"> suggested action </span>: {{ themeForImprovementsCurrentResponse.suggestedAction }}
              </span>
            </span>
          </p>
        </div>
        <div class="tw-flex space-x-4 tw-items-start">
          <div class="tw-flex tw-flex-col tw-space-y-1">
            <span class="tw-text-sm">New</span>
            <textarea
              v-model="themeForImprovementsNewPrompt"
              type="text"
              placeholder="suggested reply prompt"
              class="tw-px-2 tw-py-4 !tw-rounded-[10px] tw-border-none tw-ring-2 tw-ring-white-three tw-min-w-[340px]"
            />
            <p
              v-if="themeForImprovementsNewLoading || themeForImprovementsNewResponse.heading"
              class="tw-border tw-border-white-two tw-border-solid tw-bg-primary-light tw-max-w-[350px] tw-p-2 tw-rounded-lg !tw-mt-3"
            >
              <span
                v-if="themeForImprovementsNewLoading"
                class="tw-flex tw-flex-col tw-space-y-1"
              >
                Loading...
              </span>
              <span
                v-else-if="themeForImprovementsNewResponse.heading"
                class="tw-flex tw-flex-col tw-gap-2 tw-capitalize"
              >
                <span>
                  <span class="tw-font-semibold">heading</span>: {{ themeForImprovementsNewResponse.heading }}
                </span>
                <span>
                  <span class="tw-font-semibold">  description  </span> : {{ themeForImprovementsNewResponse.description }}
                </span>
                <span>
                  <span class="tw-font-semibold"> suggested action </span>: {{ themeForImprovementsNewResponse.suggestedAction }}
                </span>
              </span>
            </p>
          </div>
          <button
            :disabled="!themeForImprovementsNewPrompt"
            class="tw-px-4 tw-ml-5 disabled:tw-cursor-not-allowed tw-mt-8 tw-text-sm tw-py-2 tw-bg-primary-light  tw-rounded-2xl hover:tw-bg-primary hover:tw-text-white tw-text-primary-dark"
            @click="testImprovementPrompt(true)"
          >
            Test
          </button>
        </div>
      </div>
      <div
        class="tw-flex tw-justify-center  tw-items-start tw-space-x-10 tw-py-5 tw-border-b tw-border-solid tw-border-white-two"
      >
        <p class="tw-w-[200px] tw-mt-3">
          For answers
        </p>
        <div class="tw-flex tw-flex-col tw-space-y-1">
          <span class="tw-text-sm">Current</span>
          <textarea
            v-model="suggestedAnswersCurrentPrompt"
            disabled
            type="text"
            placeholder="suggested reply prompt"
            class="tw-px-2 tw-py-4 disabled:tw-cursor-not-allowed !tw-rounded-[10px] tw-border-none tw-ring-2 tw-ring-white-three tw-min-w-[340px]"
          />
          <p

            class="tw-border tw-border-white-two tw-border-solid tw-bg-primary-light tw-max-w-[350px] tw-p-2 tw-rounded-lg !tw-mt-3"
          >
            {{ suggestedAnswerCurrentLoading? 'Loading...' : suggestedAnswerCurrentResponse }}
          </p>
        </div>
        <div class="tw-flex space-x-4 tw-items-start">
          <div class="tw-flex tw-flex-col tw-space-y-1">
            <span class="tw-text-sm">New</span>
            <textarea
              v-model="suggestedAnswersNewPrompt"
              type="text"
              placeholder="suggested reply prompt"
              class="tw-px-2 tw-py-4 !tw-rounded-[10px] tw-border-none tw-ring-2 tw-ring-white-three tw-min-w-[340px]"
            />
            <p
              v-if="suggestedAnswerNewLoading || suggestedAnswerNewResponse"
              class="tw-border tw-border-white-two tw-border-solid tw-bg-primary-light tw-max-w-[350px] tw-p-2 tw-rounded-lg !tw-mt-3"
            >
              {{ suggestedAnswerNewLoading? 'Loading...' : suggestedAnswerNewResponse }}
            </p>
          </div>
          <button
            :disabled="!suggestedAnswersNewPrompt"
            class="tw-px-4 tw-ml-5 disabled:tw-cursor-not-allowed tw-mt-8 tw-text-sm tw-py-2 tw-bg-primary-light  tw-rounded-2xl hover:tw-bg-primary hover:tw-text-white tw-text-primary-dark"
            @click="testSuggestedAnswersPrompt(true)"
          >
            Test
          </button>
        </div>
      </div>
      <div
        class="tw-flex tw-justify-center  tw-items-start tw-space-x-10 tw-py-5 tw-border-b tw-border-solid tw-border-white-two"
      >
        <p class="tw-w-[200px] tw-mt-3">
          For Reports
        </p>
        <!-- <span class="tw-absolute tw-text-xl tw-font-bold tw-right-[42%] tw-z-10 tw-text-primary">
          Coming Soon...
        </span> -->
        <div class="tw-flex tw-flex-col tw-space-y-1  ">
          <span class="tw-text-sm">Current</span>
          <textarea
            v-model="strategiesCurrentPrompt"
            disabled
            type="text"
            placeholder="suggested reply prompt"
            class="tw-px-2 tw-py-4 disabled:tw-cursor-not-allowed !tw-rounded-[10px] tw-border-none tw-ring-2 tw-ring-white-three tw-min-w-[340px]"
          />
        </div>
        <div class="tw-flex space-x-4 tw-items-start  tw-cursor-not-allowed ">
          <div class="tw-flex tw-flex-col tw-space-y-1">
            <span class="tw-text-sm">New</span>
            <textarea
              v-model="strategiesNewPrompt"
              type="text"
              placeholder=""
              class="tw-px-2 tw-py-4 !tw-rounded-[10px] tw-border-none tw-ring-2 tw-ring-white-three tw-min-w-[340px]"
            />
          </div>
          <button
            class="tw-px-4 tw-ml-5 disabled:tw-cursor-not-allowed tw-mt-8 tw-text-sm tw-py-2 tw-bg-primary-light  tw-rounded-2xl hover:tw-bg-primary hover:tw-text-white tw-text-primary-dark"
          >
            Test
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AhButton } from '../common'
export default {
  components: {
    AhButton
  },
  props: {
    study: {
      type: Object,
      required: false,
      default: () => {}
    },
    surveyResponseId: {
      type: String,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      suggestedReplyCurrentPrompt: '',
      suggestedReplyNewPrompt: '',
      suggestedReplyCurrentResponse: '',
      suggestedReplyNewResponse: '',
      suggestedReplyCurrentLoading: false,
      suggestedReplyNewLoading: false,
      strategiesNewLoading: false,

      themeForImprovementsCurrentPrompt: '',
      themeForImprovementsNewPrompt: '',
      themeForImprovementsCurrentResponse: {
        heading: '',
        description: '',
        suggestedAction: ''
      },
      themeForImprovementsNewResponse: {
        heading: '',
        description: '',
        suggestedAction: ''
      },
      themeForImprovementsCurrentLoading: false,
      themeForImprovementsNewLoading: false,

      suggestedAnswersCurrentPrompt: '',
      suggestedAnswersNewPrompt: '',
      suggestedAnswerCurrentResponse: '',
      suggestedAnswerNewResponse: '',
      suggestedAnswerCurrentLoading: false,
      suggestedAnswerNewLoading: false,
      strategiesCurrentPrompt: '',
      strategiesNewPrompt: '',
      cloudFunctionEnv: '--test'
    }
  },
  mounted () {
    this.suggestedReplyCurrentPrompt = this.study.participantSummaryPrompt
    this.themeForImprovementsCurrentPrompt = this.study.themeForImprovementPrompt
    this.suggestedAnswersCurrentPrompt = this.study.suggestedAnswerPrompt
    this.strategiesCurrentPrompt = this.study.generalStrategiesPrompt
    console.log(this.study)
    setTimeout(() => {
      this.testSuggestedReplyPrompt()
      this.testImprovementPrompt()
      this.testSuggestedAnswersPrompt()
    }, 200)

    const bucket = process.env.GCS_BUCKET

    if (bucket === 'opinion-graphs') {
      this.cloudFunctionEnv = '--dev'
    } else if (bucket === 'spacetrics-opinions--staging') {
      this.cloudFunctionEnv = '--staging'
    }
  },
  methods: {
    saveNewPrompts () {
    //  update survey with new prompts
      this.$axios
        .patch(
          `/study/${this.study.id}`,
          {
            participant_summary_prompt: this.suggestedReplyNewPrompt ? this.suggestedReplyNewPrompt : this.suggestedReplyCurrentPrompt,
            theme_for_improvement_prompt: this.themeForImprovementsNewPrompt ? this.themeForImprovementsNewPrompt : this.themeForImprovementsCurrentPrompt,
            suggested_answer_prompt: this.suggestedAnswersNewPrompt ? this.suggestedAnswersNewPrompt : this.suggestedAnswersCurrentPrompt,
            general_strategies_prompt: this.strategiesNewPrompt ? this.strategiesNewPrompt : this.strategiesCurrentPrompt
          }
        )
        .then((res) => {
          console.log(res)
          this.window.location.reload()
          // this.$toast.success('Prompts updated successfully')
        })
        .catch((err) => {
          console.log(err)
          // this.$toast.error('Error updating prompts')
        })
    },
    async testImprovementPrompt (isNew = false) {
      if (isNew) {
        this.themeForImprovementsNewLoading = true
      } else {
        this.themeForImprovementsCurrentLoading = true
      }
      const getThemes = await fetch(
        'https://us-central1-spacetrics.cloudfunctions.net/improvement-themes' + this.cloudFunctionEnv,
        // 'http://localhost:8080/',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            survey_id: this.study.id,
            theme_for_improvement_prompt: isNew ? this.themeForImprovementsNewPrompt : this.themeForImprovementsCurrentPrompt,
            is_test: true
          })
        }
      )

      if (!getThemes.ok) {
        throw new Error(`HTTP error! status: ${getThemes.status}`)
      }
      const getThemesData = await getThemes.json()
      this.themeForImprovementsCurrentLoading = false
      this.themeForImprovementsNewLoading = false
      if (isNew) {
        this.themeForImprovementsNewResponse.heading = getThemesData[0].heading
        this.themeForImprovementsNewResponse.description = getThemesData[0].description
        this.themeForImprovementsNewResponse.suggestedAction = getThemesData[0].suggested_action
      } else {
        this.themeForImprovementsCurrentResponse.heading = getThemesData[0].heading
        this.themeForImprovementsCurrentResponse.description = getThemesData[0].description
        this.themeForImprovementsCurrentResponse.suggestedAction = getThemesData[0].suggested_action
      }
      console.log(getThemesData)
    },
    async testSuggestedReplyPrompt (isNew = false) {
      if (isNew) {
        this.suggestedReplyNewLoading = true
      } else {
        this.suggestedReplyCurrentLoading = true
      }
      const getReply = await fetch(
        'https://us-central1-spacetrics.cloudfunctions.net/participant-summary' + this.cloudFunctionEnv,
        // 'http://localhost:8080/',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            survey_response_id: this.study.surveyResponseId,
            regenerate: true,
            participant_summary_prompt: isNew ? this.suggestedReplyNewPrompt : this.suggestedReplyCurrentPrompt,
            is_test: true
          })
        }
      )

      if (!getReply.ok) {
        throw new Error(`HTTP error! status: ${getReply.status}`)
      }
      const getReplyData = await getReply.json()

      this.suggestedReplyCurrentLoading = false
      this.suggestedReplyNewLoading = false

      if (isNew) {
        this.suggestedReplyNewResponse = getReplyData.suggested_reply
      } else {
        this.suggestedReplyCurrentResponse = getReplyData.suggested_reply
      }

      console.log(getReplyData.suggested_reply)
    },
    async testSuggestedAnswersPrompt (isNew = false) {
      if (isNew) {
        this.suggestedAnswerNewLoading = true
      } else {
        this.suggestedAnswerCurrentLoading = true
      }

      const getAnswer = await fetch(
        'https://us-central1-spacetrics.cloudfunctions.net/suggested-answers' + this.cloudFunctionEnv,
        // 'http://localhost:8080/',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            type: 'answer',
            suggested_question: this.study.suggestedQuestion,
            project_ids: [this.study.projectId],
            survey_ids: [this.study.id],
            history_id: null,
            is_test: true
          })
        }
      )

      if (!getAnswer.ok) {
        throw new Error(`HTTP error! status: ${getAnswer.status}`)
      }
      console.log(getAnswer)
      const getAnswerData = await getAnswer.json()
      this.suggestedAnswerCurrentLoading = false
      this.suggestedAnswerNewLoading = false
      if (isNew) {
        this.suggestedAnswerNewResponse = getAnswerData.answer
      } else {
        this.suggestedAnswerCurrentResponse = getAnswerData.answer
      }
      console.log(getAnswerData)
    }
  }
}
</script>

<style></style>
