<template>
  <div class="icon_microphone">
    <div class="icon_microphone__svg">
      <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_318_2)">
<path d="M7.49995 13.8612C8.63765 13.86 9.72812 13.3957 10.5325 12.5695C11.3373 11.7434 11.7898 10.6235 11.791 9.45503V4.81668C11.76 3.2638 10.9355 1.84252 9.62089 1.07523C8.30628 0.307932 6.69516 0.307932 5.38055 1.07523C4.06595 1.84252 3.24142 3.2638 3.21045 4.81668V9.45503C3.212 10.6231 3.66414 11.743 4.46815 12.5687C5.27216 13.3949 6.36264 13.8596 7.49995 13.8612ZM4.56454 4.81668C4.59009 3.75718 5.15526 2.78951 6.05295 2.26751C6.95065 1.74512 8.04925 1.74512 8.94733 2.26751C9.84502 2.78951 10.4102 3.75757 10.4357 4.81668V9.45503C10.435 10.2545 10.1253 11.0206 9.57482 11.5856C9.02475 12.1509 8.27841 12.4685 7.50033 12.4697C6.72187 12.4689 5.97592 12.1509 5.42584 11.5856C4.87538 11.0206 4.56609 10.2541 4.56493 9.45503V4.81668H4.56454Z" fill="white"/>
<path d="M1.17743 8.29541C0.997816 8.29541 0.825554 8.36856 0.698197 8.49896C0.571227 8.62976 0.5 8.80628 0.5 8.99114C0.502323 10.7766 1.15072 12.4972 2.319 13.8195C3.48728 15.1418 5.0926 15.9707 6.82257 16.1453V17.8039C6.82257 18.0524 6.95186 18.2822 7.16128 18.4066C7.37071 18.531 7.62929 18.5306 7.83872 18.4066C8.04814 18.2826 8.17743 18.0528 8.17743 17.8039V16.1453C9.9074 15.9703 11.5127 15.1414 12.681 13.8195C13.8493 12.4976 14.4977 10.7766 14.5 8.99114C14.5 8.74267 14.3707 8.51288 14.1613 8.38884C13.9519 8.2644 13.6933 8.2644 13.4839 8.38884C13.2744 8.51288 13.1451 8.74267 13.1451 8.99114C13.1451 11.0624 12.0694 12.9767 10.3228 14.0123C8.57615 15.048 6.42424 15.048 4.67724 14.0123C2.93063 12.9767 1.85486 11.0624 1.85486 8.99114C1.85486 8.80667 1.78364 8.62976 1.65667 8.49896C1.52931 8.36856 1.35744 8.29541 1.17743 8.29541Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_318_2">
<rect width="14" height="18" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>

    </div>
  </div>
</template>

<script>
export default {}
</script>
