<template>
  <div class="bx--grid">
    <div class="mobile-header hidden">
      <a href="/welcome">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          v-if="isUserAdmin"
          class="page-dropdown-button"
          caption="Manage Members"
          @click="addMemberHandler"
        />
      </div>
    </div>
    <div class="bx--offset-sm-1 bx--col-sm-10 bx--col-xs-12">
      <div class="bx--col-sm-12 bx--col-xs-12">
        <div class="desktop-page-and-dropdown-button w-full  mt-10">
          <div class="page-header flex justify-between w-full">
            All Members
          </div>
          <st-button
            v-if="isUserAdmin"
            class="page-dropdown-button"
            caption="Manage Members"
            @click="addMemberHandler"
          />
        </div>
        <!-- <div class="st-team__info-text bx--col-sm-9">
          There are a total of
          <span class="st-team__number--bold">
            {{ activeMembersCount }}
          </span>
          members in your team. You have
          <span class="st-team__number--bold">
            {{ seatsRemaining }}
          </span>
          remaining seats in your account.
        </div> -->
        <div class="lookup-and-dropdown flex justify-between">
          <st-input
            v-model="memberLookup"
            class="st-input--default lookup-and-dropdown__space-input"
            label="Member Lookup"
            style-type="secondary"
            label-in-field-class="st-input--hide-mobile-label"
            :show-icon="true"
            :show-label="false"
            @blur="updateSearchParam"
          />
          <st-dropdown-menu
            caption="Member Filter"
            :display-caption="true"
            :initial-active-option="filterOption"
            :options="[
              'Active Members',
              'Recently Added',
              'All Members',
              'Inactive Members',
              'Active & Pending Members',
              'Pending Members',
            ]"
            @updateOption="setFilterOption"
          />
        </div>
        <st-data-table
          container-class=""
          :active-column="activeColumn"
          :centered-headers="['Role']"
          :headers="[
            'Name',
            'Email',
            'Role',
            'Department',
            'Projects',
            'Status',
          ]"
          :rows="paginatedMembers"
          :sortable-columns="['Name', 'Email', 'Role', 'Projects', 'Status']"
          @setActiveColumn="updateActiveColumn"
          @toggleActiveColumn="updateActiveColumn"
          @selectAll="toggleAllChecks(true)"
          @unselectAll="toggleAllChecks(false)"
        >
          <template v-slot:selected-actions>
            <div class="st-team__table-actions">
              <div
                class="st-team__table-action"
                @click="groupModal = true"
              >
                <icon-wrapper
                  icon-name="add-list"
                  class="st-team__table-action-icon"
                  :invert="true"
                />
                <div>Create/Add to Group</div>
              </div>
              <div
                v-if="isUserAdmin"
                class="st-team__table-action"
                @click="deleteMemberModal = true"
              >
                <icon-wrapper
                  icon-name="trash"
                  class="st-team__table-action-icon"
                  :invert="true"
                />
                <div>Delete</div>
              </div>
            </div>
          </template>
          <template v-slot:default="{ row: member, anyRowsChecked }">
            <st-data-table-checkbox-row
              :key="member.id"
              :avatar-url="member.avatarUrl"
              :cell-keys="[
                'fullName',
                'email',
                'kind',
                'departments',
                'projects',
                'status',
              ]"
              :checked="member.selected"
              :row-data="member"
              :show-all-checks="anyRowsChecked"
              @input="
                (val) => {
                  updateCheck(member.id, val);
                }
              "
            >
              <template v-slot:cell-3>
                <div
                  class="st-team__table-role st-data-table-centered__text-values"
                >
                  {{ memberKind(member.kind) }}
                </div>
              </template>
              <template v-slot:cell-4>
                <div v-if="member.departments.length === 0" />
                <st-data-table-dropdown-info
                  v-else
                  class="st-team__departments-dropdown-info"
                  :disabled="!isDesktop"
                  :display-text="departmentsDisplay(member.departments)"
                  :info-rows="member.departments.map((d) => d.name)"
                  :open-title="departmentsOpenDisplay(member.departments)"
                  :show-count="true"
                />
              </template>
              <template v-slot:cell-5>
                <st-data-table-dropdown-info
                  class="st-team__projects-info"
                  :disabled="!isDesktop"
                  :display-text="
                    member.projects
                      .filter((project) => !project.archived)
                      .length.toString()
                  "
                  :info-rows="
                    member.projects
                      .filter((project) => !project.archived)
                      .map((p) => p.name)
                  "
                  :open-title="
                    projectsOpenDisplay(
                      member.projects.filter((project) => !project.archived)
                    )
                  "
                />
              </template>
            </st-data-table-checkbox-row>
          </template>
        </st-data-table>
        <st-pagination
          class="bx--col-sm-12 pagination-spacing"
          :number-of-items="sortedAndFilteredMembers.length"
          @updateActiveIndices="onActiveUpdateIndices"
        />
        <st-modal
          v-if="canAddNewMembers"
          v-model="addMembersModal"
        >
          <template v-slot:modalTitle>
            Add Team Members
          </template>
          <template v-slot:modalActions>
            <div class="st-team__add-members-modal-body">
              <template>
                <st-email-input
                  v-if="newEmails.length < seatsRemaining"
                  ref="emailInput"
                  invite-text="account"
                  label="Type an email"
                  :without-autocomplete="true"
                  @addNewEmail="addInviteEmail"
                  @removeEmail="removeInviteEmail"
                />
                <div v-else>
                  <div class="st-team__add-members-email-error-message">
                    You have reached your allotted seat limit
                  </div>
                  <div class="st-team__add-members-email-limit-box">
                    <div
                      v-for="email in newEmails"
                      :key="email.email"
                      class="st-email-input__email-tag"
                    >
                      <div>{{ email.email }}</div>
                      <div
                        class="st-email-input__email-delete"
                        @click="removeInviteEmail(email)"
                      >
                        <icon-wrapper
                          icon-name="plus"
                          class="st-email-input__email-delete-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <st-dropdown-menu
                  caption="Departments"
                  class="st-team__modal-dropdown"
                  :display-label="true"
                  :options="departmentNames"
                  placeholder="Select as many as they apply"
                  :select-multiple="true"
                  @updateMultiple="setDepartments"
                />
                <st-dropdown-menu
                  caption="Role"
                  class="st-team__modal-dropdown"
                  :display-label="true"
                  :initial-active-option="newMemberRole"
                  :options="['Member', 'Admin']"
                  placeholder="Select one"
                  @updateOption="setRole"
                />
              </template>
              <!-- <template v-else>
                <div class="st-team__add-members-error-message">
                  You have zero remaining team member slots, delete a member or contact us at <a href="mailto:zak@opiniongraphs.com">zak@opiniongraphs.com</a> to get more seats.
                </div>
                <div class="st-team__add-members-error-message">
                  (Pending members count in your seat allotment).
                </div>
                <button class="px-4 py-3 text-white bg-red">
                  Upgrade Plan to Pro
                </button>
              </template> -->
            </div>
          </template>
          <template v-slot:modalButton>
            <st-button
              caption="Add Members"
              icon-name="add-member"
              :disabled="
                newEmails.length === 0 ||
                  $refs.emailInput.errorMessage.length !== 0 ||
                  activeMembersCount === allottedSeatsCount
              "
              :show-action="true"
              @click="addMembers"
            />
          </template>
        </st-modal>
        <upgrade-plan-modal
          ref="upgradePlan"
          title="Add member"
          description="You have already reached the maximum number of members allowed for your plan."
        />
        <st-modal
          v-model="uploadCsvModal"
          size-class="st-modal--large"
        >
          <template v-slot:modalTitle>
            Import CSV File
          </template>
          <template v-slot:modalActions>
            <st-dropzone
              v-if="previewFile === null"
              help-text="Make sure your CSV file includes at least one column with emails"
              :multiple="false"
              name="filename"
              @input="onCsvInput"
            />
            <st-upload-preview
              v-else
              :file="file"
              :upload-percent="progress * 100"
              @remove="remove"
            />
          </template>
          <template v-slot:modalButton>
            <st-button
              caption="Import CSV"
              class="st-team__modal-button"
              icon-name="import-csv"
              :show-action="true"
              @click="uploadCsv"
            />
          </template>
        </st-modal>
        <st-modal
          v-model="groupModal"
          @closeModal="selectedGroup = null"
        >
          <template v-slot:modalTitle>
            Add Member To A Group
          </template>
          <template v-slot:modalActions>
            <div class="st-member-groups__modal-text">
              <st-autocomplete
                :caption-fn="(selectedGroup) => selectedGroup.name"
                create-caption="a New Group"
                :create-fn="createNewGroup"
                :initial-matchable-list="groups"
                :initial-value="selectedGroup"
                label="Select a group or create one"
                option-icon-name="list"
                :placeholder="
                  groups.length > 0
                    ? ''
                    : 'There are no groups create one to get started'
                "
                toast-caption="Group Created"
                :variant="['separateCreate']"
                @failure="handleAutocompleteFailure"
                @selectOption="
                  (val) => {
                    selectedGroup = val;
                  }
                "
                @success="handleAutocompleteSuccess"
                @unselectOption="selectedGroup = null"
              />
            </div>
          </template>
          <template v-slot:modalButton>
            <st-button
              :caption="
                selectedMembers.length > 1 ? 'Add Members' : 'Add Members'
              "
              :disabled="!selectedGroup"
              icon-name="list"
              :show-action="true"
              @click="addMembersToGroup"
            />
          </template>
        </st-modal>
        <st-modal v-model="deleteMemberModal">
          <template v-slot:modalTitle>
            <template v-if="selectedMembers.length > 1">
              Delete Members
            </template>
            <template v-else>
              Delete Member
            </template>
          </template>
          <template v-slot:modalActions>
            <div class="st-member-groups__modal-text">
              By deleting a member you will deny them access to the account. Are
              you sure you want to delete {{ selectedMembersString }}?
            </div>
          </template>
          <template v-slot:modalButton>
            <st-button
              caption="Delete Member"
              icon-name="trash"
              :show-action="true"
              @click="deleteMembers"
            />
          </template>
        </st-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { startCase } from 'lodash'
import {
  caseInsensitiveSortBy,
  doubleCaseInsensitiveSortBy,
  recentDateLimit
} from '../../../utilities/data_utilities'
import { isDesktop, isUserAdmin, refineUrlFilter } from '../../../mixins'
import {
  IconWrapper,
  StAutocomplete,
  StButton,
  StDataTable,
  StDataTableCheckboxRow,
  StDataTableDropdownInfo,
  StDropdownButton,
  StDropdownMenu,
  StDropzone,
  StEmailInput,
  StInput,
  StModal,
  StPagination,
  StUploadPreview,
  StripePaymentForm
} from '../../common'
import UpgradePlanModal from '../plan/upgrade-plan-modal.vue'

const DROPDOWN_OPTIONS = [
  {
    caption: 'Add Members',
    iconName: 'add-member'
  }
  // , {
  //   caption: 'Import CSV',
  //   iconName: 'import-csv'
  // }, {
  //   caption: 'Export Members',
  //   iconName: 'export-list'
  // }
]

export default {
  components: {
    IconWrapper,
    StAutocomplete,
    StButton,
    StDataTable,
    StDataTableCheckboxRow,
    StDataTableDropdownInfo,
    StDropdownButton,
    StDropdownMenu,
    StDropzone,
    StEmailInput,
    StInput,
    StModal,
    StPagination,
    StUploadPreview,
    StripePaymentForm,
    UpgradePlanModal
  },
  mixins: [isDesktop, isUserAdmin, refineUrlFilter],
  props: {
    allottedSeatsCount: {
      type: Number,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    initialGroups: {
      type: Array,
      required: true
    },
    initialMembers: {
      type: Array,
      required: true
    },
    initialSeatsRemaining: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      activeColumn: { name: 'Name', ascending: true },
      addMembersModal: false,
      deleteMemberModal: false,
      dropdownOptions: DROPDOWN_OPTIONS,
      filterOption: 'Active & Pending Members',
      groupModal: false,
      groups: this.initialGroups,
      members: this.initialMembers.map((member) => {
        return Object.assign({ selected: false }, member)
      }),
      memberLookup: '',
      newEmails: [],
      newMemberDepartments: [],
      newMemberRole: 'Member',
      paginationIndices: [0, 0],
      previewFile: null,
      selectedGroup: null,
      uploadCsvModal: false
    }
  },
  computed: {
    activeMembersCount () {
      return this.members.filter((user) => !user.inactive).length
    },
    canAddNewMembers () {
      // In the future we will not check if the user has enough seats
      // because seats limit will be removed and unlimited members can be added

      // return this.activeMembersCount < this.allottedSeatsCount
      return true
    },
    departmentNames () {
      return this.departments.map((department) => department.name)
    },
    filteredMembers () {
      if (this.memberLookup) {
        return this.mappedMembers.filter((user) => {
          const regex = new RegExp(this.memberLookup, 'gi')
          return user.fullName.match(regex) || user.email.match(regex)
        })
      } else {
        return this.mappedMembers
      }
    },
    inactiveMembers () {
      return this.members.filter((member) => member.inactive)
    },
    initialActiveItems () {
      return this.sortedAndFilteredMembers.length < 10
        ? this.sortedAndFilteredMembers.length
        : 10
    },
    mappedMembers () {
      return this.members.map((member) => {
        if (member.inactive) {
          member.status = 'deactivated'
          if (!member.firstName) {
            member.firstName = ''
          }
          if (!member.lastName) {
            member.lastName = ''
          }
        } else if (member.invitationCreatedAt && !member.invitationAcceptedAt) {
          member.firstName = 'Pending'
          member.lastName = 'Invitation'
          member.fullName = 'Pending Invitation'
          member.pending = true
          member.status = 'pending'
        } else {
          member.status = 'active'
        }
        return member
      })
    },
    newMemberDepartmentIds () {
      return this.newMemberDepartments.map((department) => {
        return this.departmentIdFromName(department)
      })
    },
    paginatedMembers () {
      return this.sortedMembersByActiveColumn.slice(...this.paginationIndices)
    },
    seatsRemaining () {
      return (
        this.initialMembers.length +
        this.initialSeatsRemaining -
        this.members.length
      )
    },
    selectedMembers () {
      return this.members.filter((m) => m.selected)
    },
    selectedMembersIds () {
      return this.selectedMembers.map((m) => m.id)
    },
    selectedMembersString () {
      let memberNames = this.selectedMembers.map((m) => m.fullName)
      return memberNames.length === 1
        ? memberNames[0]
        : memberNames.slice(0, -1).join(', ') + ' and ' + memberNames.slice(-1)
    },
    sortedAndFilteredMembers () {
      let members
      switch (this.filterOption) {
        case 'All Members':
          members = this.filteredMembers
          break
        case 'Recently Added':
          members = this.filteredMembers.filter((user) => {
            return recentDateLimit(user.createdAt, 15)
          })
          break
        case 'Inactive Members':
          members = this.filteredMembers.filter((user) => user.inactive)
          break
        case 'Active & Pending Members':
          members = this.filteredMembers.filter((user) => !user.inactive)
          break
        case 'Pending Members':
          members = this.filteredMembers.filter((user) => user.pending)
          break
        case 'Active Members':
          members = this.filteredMembers.filter(
            (user) => !user.inactive && !user.pending
          )
          break
      }
      return members
    },
    sortedMembersByActiveColumn () {
      let recipients = []
      switch (this.activeColumn.name) {
        case 'Name':
          return this.activeColumn.ascending
            ? doubleCaseInsensitiveSortBy(
              this.sortedAndFilteredMembers,
              'lastName',
              'firstName'
            )
            : doubleCaseInsensitiveSortBy(
              this.sortedAndFilteredMembers,
              'lastName',
              'firstName',
              true
            )
        case 'Email':
          return this.sortColumnProperty('email')
        case 'Role':
          return this.sortColumnProperty('kind')
        case 'Projects':
          recipients = this.activeColumn.ascending
            ? this.sortedAndFilteredMembers
              .slice()
              .sort((a, b) => b.projects.length - a.projects.length)
            : this.sortedAndFilteredMembers
              .slice()
              .sort((a, b) => a.projects.length - b.projects.length)
          break
        case 'Status':
          return this.sortColumnProperty('status')
      }
      return recipients
    }
  },
  mounted () {
    this.$store.commit('setActivePage', 'all-members')
  },
  created () {
    this.defaultFilter = 'All Members'
    this.filterOption = this.filterParam
    this.memberLookup = this.searchParam
  },
  methods: {
    ...mapMutations(['updateToastOptions']),
    addInviteEmail (email) {
      this.newEmails.push(email)
    },
    addMembersToGroup () {
      this.$axios
        .request({
          method: 'post',
          url: `/user_groups/${this.selectedGroup.id}/users/`,
          data: { id: this.selectedGroup.id, ids: this.selectedMembersIds }
        })
        .then((res) => {
          this.groupModal = false
          this.selectedGroup = null
          this.toggleAllChecks(false)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    addMembers () {
      let emailsToAdd = this.newEmails.filter((member) => {
        return !this.members.some((current) => current.email === member.email)
      })
      this.$axios
        .request({
          method: 'post',
          url: '/users/invite',
          data: {
            emails: emailsToAdd.map((member) => member.email),
            department_ids: this.newMemberDepartmentIds,
            kind: this.newMemberRole.toLowerCase()
          }
        })
        .then((res) => {
          this.members = this.members.concat(res.data)
          this.$nextTick(() => {
            this.addMembersModal = false
            this.newEmails = []
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    createNewGroup (newName) {
      return this.$axios
        .request({
          method: 'post',
          url: '/user_groups',
          data: { name: newName }
        })
        .then((res) => {
          this.groups.push(res.data)
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    deleteMembers () {
      this.$axios
        .request({
          method: 'patch',
          url: '/users/deactivate',
          data: {
            ids: this.selectedMembersIds
          }
        })
        .then((res) => {
          // change status to 'deactivated' for selected members
          this.selectedMembers.forEach((member) => {
            member.status = 'deactivated'
            member.selected = false
          })
          this.deleteMemberModal = false
          this.toggleAllChecks(false)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    departmentsDisplay (departments) {
      return departments[0].name
    },
    departmentIdFromName (name) {
      let idx = this.departmentNames.indexOf(name)
      return this.departments[idx].id
    },
    departmentsOpenDisplay (departments) {
      let text = `${departments.length} Department`
      if (departments.length > 1) {
        text += 's'
      }
      return text
    },
    handleAutocompleteFailure (reason = '') {
      let toastCaption
      if (reason === 'duplicate') {
        toastCaption = 'This group already exists, and has been selected'
        this.updateToastOptions({
          caption: toastCaption,
          kind: 'info',
          title: 'Info'
        })
      } else {
        toastCaption = 'Groups without a name cannot be created'
        this.updateToastOptions({
          caption: toastCaption,
          kind: 'error',
          title: 'Error'
        })
      }
    },
    handleAutocompleteSuccess () {
      let toastCaption
      toastCaption =
        'Member group has been created, see more detail of this group in the Groups page'
      this.updateToastOptions({
        caption: toastCaption,
        kind: 'info',
        title: 'Info'
      })
    },
    addMemberHandler () {
      this.addMembersModal = true
      if (!this.canAddNewMembers) {
        this.$refs.upgradePlan.toggle()
      }
    },
    memberKind (kind) {
      return startCase(kind)
    },
    onActiveUpdateIndices (paginationIndices) {
      this.paginationIndices = paginationIndices
    },
    onCsvInput () {
      // TBD
    },
    projectsOpenDisplay (projects) {
      let text = `${projects.length} Project`
      if (projects.length > 1 || projects.length === 0) {
        text += 's'
      }
      return text
    },
    removeInviteEmail (email) {
      let idx = this.newEmails.indexOf(email)
      this.newEmails.splice(idx, 1)
    },
    setDepartments (departmentsArray) {
      this.newMemberDepartments = departmentsArray
    },
    setFilterOption (option) {
      this.updateUrlParams('filter', option)
      this.filterOption = option
    },
    setRole (role) {
      this.newMemberRole = role
    },
    sortColumnProperty (property) {
      return this.activeColumn.ascending
        ? caseInsensitiveSortBy(this.sortedAndFilteredMembers, property)
        : caseInsensitiveSortBy(this.sortedAndFilteredMembers, property, true)
    },
    toggleAllChecks (bool) {
      this.members.forEach((member) => {
        member.selected = bool
      })
    },
    updateActiveColumn (payload) {
      this.activeColumn = payload
    },
    updateCheck (id, val) {
      let memberToUpdate = this.members.find((member) => {
        return member.id === id
      })
      memberToUpdate.selected = val
    },
    uploadCsv () {
      // TBD
    }
  }
}
</script>
