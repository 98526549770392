<template>
  <div class="bx--grid tw-flex tw-justify-center tw-items-center tw-w-full">
    <div class="tw-mx-auto ">
      <h2 class="st-published__header-spacing">
        Please check your email to verify your account.
      </h2>
      <div class="tw-flex tw-justify-center">
        <st-button
          class="tw-px-8 tw-rounded-xl"
          caption="Login"
          :show-action="true"
          @click="redirectToLogin"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { StButton } from '../../common'

export default {
  components: {
    StButton
  },
  data () {
  },
  methods: {
    redirectToLogin () {
      window.location = '/users/sign_in'
    }
  }
}
</script>
