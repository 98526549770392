<template>
  <div>
    <div class="tw-w-full tw-mx-auto">
      <div class="tw-flex tw-py-3  tw-items-center  tw-mx-auto tw-relative tw-w-full ">
        <div

          class="tw-relative tw-mt-4 tw-overflow-hidden tw-w-full tw-flex tw-cursor-pointer tw-justify-center tw-items-center tw-mx-auto"
        >
          <div class="tw-w-[95%] tw-mx-auto  tw-flex tw-justify-center tw-items-center tw-max-w-[998px]">
            <div class="tw-absolute tw-top-0 tw-rounded-[20px] tw-w-[850px] tw-h-full tw-backdrop-brightness-[0.6] tw-backdrop-blur-[6px]  tw-bg-transparent tw-z-10 " />
            <video
              ref="tempVideoOne"
              style="transform:scaleX(-1)"
              class="tw-w-[850px] tw-h-auto tw-rounded-[20px] tw-bg-black/10"
              autoplay
            />
          </div>
          <video
            ref="tempVideoTwo"

            class="tw-w-[360px] tw-rounded-[20px] tw-h-[95%] tw-scale-x-[-1] tw-z-20 tw-border-[3px]  tw-border-white tw-border-dashed tw-object-cover tw-absolute  "
            autoplay
          />
        </div>
      </div>
    </div>
    <p
      v-if="showError"
      class="tw-text-error tw-mx-auto tw-text-center tw-w-full"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { AhButton } from '../../common'
export default {
  components: {
    AhButton
  },
  props: {

  },

  data () {
    return {

      showError: false,
      errorMessage: ''
    }
  },
  computed: {
  },
  mounted () {
    this.showRecorder = true
    this.checkPermissionsAndStartStream()
  },

  methods: {
    async checkPermissionsAndStartStream () {
      try {
        // Check if the user has already granted permission for the media devices
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true })

        // Permission already granted, initialize the recorder
        await this.initializeReflection()
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.showError = true
          this.errorMessage = 'Please allow access to camera and microphone by going to browser settings'
          console.log('User has not granted permission for microphone and video by going to browser settings')
          // Handle the case where permission is not granted
        } else {
          console.error('Error accessing camera and microphone:', error)
        }
      }
    },

    async initializeReflection () {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        const tempVideoTwo = this.$refs.tempVideoTwo
        const tempVideoOne = this.$refs.tempVideoOne
        tempVideoTwo.muted = true
        tempVideoOne.muted = true
        tempVideoTwo.srcObject = mediaStream
        tempVideoOne.srcObject = mediaStream
        this.showError = false
        tempVideoTwo.style.transform = 'scaleX(-1)'
        this.errorMessage = ''
        tempVideoTwo.play()
        tempVideoOne.play()
      } catch (error) {
        console.error('Error accessing camera and microphone:', error)
      }
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
