<template>
  <div class="st-sentiment-data-table-container">
    <table
      class="st-sentiment-data-table"
      :class="{'table-border-bottom':
        tableCompleted}"
    >
      <thead class="st-sentiment-data-table__header">
        <tr class="">
          <th class="st-sentiment-data-table__head-item">
            <div class="st-sentiment-data-table__header-and-column-chevron tw-font-semibold tw-space-x-1">
              <svg
                class="tw-mt-[2px]"
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.77389 9.03688L1.14627 2.5277C0.600402 1.75988 1.04344 0.558228 1.87239 0.558228L11.1276 0.558228C11.9566 0.558228 12.3996 1.75988 11.8537 2.5277L7.22612 9.03687C6.84172 9.57758 6.15829 9.57758 5.77389 9.03688Z"
                  fill="black"
                />
              </svg>
              <span>
                {{ columnTitle }}
                ({{ filterGroupedRowData("unGroup").length }})
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="tw-border-solid tw-border-[#ECECEC] tw-border">
        <template v-for="(row, index) in Object.keys(filterSentimentRow)">
          <template v-for="(sentimentRow, idxx) in filterGroupedRowData(row)">
            <tr
              v-if="isExpandedGroup(index) && row !== 'unGroup'"
              :key="sentimentRow.entityId"
              :class="{
                'st-sentiment-data-table__row px-5': true,
                'st-sentiment-data-table__row--header':
                  sentimentRow.entityId === 0 && isFirstRowHeader,
                'st-sentiment-data-table__row--expandable': expandable,
              }"
              @click="toggleRowExpansion(sentimentRow.entityId, sentimentRow)"
            >
              <td
                v-if="expandable"
                class="st-sentiment-data-table__item st-sentiment-data-table__item--link pl-20 bg-yellow-200"
              >
                <div
                  :class="{
                    'st-sentiment-data-table__keyword md:tw-min-w-[100px]': true,
                    'st-sentiment-data-table__keyword-open': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                >
                  {{ sentimentRow.title }}
                </div>
                <div
                  :class="{
                    'st-sentiment-data-table__spacer': true,
                    'st-sentiment-data-table__spacer--expanded': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                />
              </td>
              <td
                v-else
                class="st-sentiment-data-table__item"
              >
                {{ sentimentRow.title }}
              </td>
              <td class="st-sentiment-data-table__item">
                <sentiment-tag
                  :sentiment="
                    rowAverages.find(
                      (row) => row.entityId === sentimentRow.entityId
                    ).sentiments
                  "
                />
              </td>
              <td
                v-if="includeCount"
                class="st-sentiment-data-table__item"
              >
                {{
                  rowTotalCounts.find(
                    (row) => row.entityId === sentimentRow.entityId
                  ).sentimentsLength
                }}
              </td>
              <template v-if="activeQuestionModifier === 'votes'">
                <td
                  v-for="(sentimentCount,
                          percentIdx) in rowSentimentCounts.find(
                            (row) => row.entityId == sentimentRow.entityId
                          ).sentimentCounts"
                  :key="percentIdx + 'percentIndex'"
                  class="st-sentiment-data-table__item"
                >
                  {{ sentimentCount }}
                </td>
                <td class="st-sentiment-data-table__item">
                  <div
                    v-if="userSignedIn === 'true'"
                    class="flex relative"
                  >
                    <div
                      v-if="isPopupOpenForRow(sentimentRow.entityId)"
                      class="flex flex-col  justify-center rounded-6p absolute bg-white shadow-5p group-popup"
                    >
                      <span
                        class="font-bold"
                        @click="
                          addToGroupHandler(
                            $event,
                            sentimentRow.entityId,
                            sentimentRow.entitySentimentsGroupId
                          )
                        "
                      >
                        Add to other group
                      </span>
                      <span
                        class="font-bold"
                        @click="
                          removeFromGroup(
                            $event,
                            sentimentRow.entityId,
                            sentimentRow.entitySentimentsGroupId
                          )
                        "
                      >
                        Remove from group
                      </span>
                    </div>
                    <div @click="togglePopup($event, sentimentRow.entityId)">
                      <span
                        :class="{
                          'st-sentiment-data-table__chevron': true,
                          'st-sentiment-data-table__chevron--open': isExpanded(
                            sentimentRow.entityId
                          ),
                        }"
                      >
                        <span
                          class="flex flex-col justify-center items-center rotate-90"
                        >
                          <svg
                            width="4"
                            height="16"
                            viewBox="0 0 4 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="2"
                              cy="2"
                              r="2"
                              fill="#1B1B1B"
                            />
                            <circle
                              cx="2"
                              cy="8"
                              r="2"
                              fill="#1B1B1B"
                            />
                            <circle
                              cx="2"
                              cy="14"
                              r="2"
                              fill="#1B1B1B"
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </td>
              </template>
              <template v-else>
                <!-- <td
                  v-for="(sentimentPercent,
                          percentIdx) in rowSentimentPercents.find(
                            (row) => row.entityId == sentimentRow.entityId
                          ).sentimentPercents"
                  :key="percentIdx + 'anotherpercent'"
                  class="st-sentiment-data-table__item"
                >
                  {{ sentimentPercent }}%
                </td> -->
              </template>
            </tr>

            <tr
              v-else-if="
                row === 'unGroup' && index + idxx < keywordsTableLength
              "
              :key="sentimentRow.entityId"
              :id="`keyword-breakout-${idxx}`"
              :class="{
                'st-sentiment-data-table__row px-5 cursor-pointer': true,
                'st-sentiment-data-table__row--header':
                  sentimentRow.entityId === 0 && isFirstRowHeader,
              }"
              @click="toggleRowExpansion(sentimentRow.entityId, sentimentRow)"
            >
              <!-- 'st-sentiment-data-table__row--expandable': expandable, -->
              <td
                v-if="expandable"
                class="st-sentiment-data-table__item st-sentiment-data-table__item--link md:tw-min-w-[316px] tw-max-w-[350px]"
              >
                <!-- @click="showDrawer" -->
                <!-- <icon-wrapper
                  icon-name="chevron"
                  :class="{
                    'st-sentiment-data-table__chevron': true,
                    'st-sentiment-data-table__chevron--open': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                  :neutral="!isExpanded(sentimentRow.entityId)"
                /> -->
                <div
                  :class="{
                    'st-sentiment-data-table__keyword tw-flex tw-flex-col tw-space-y-2 md:tw-space-y-0': true,
                    'st-sentiment-data-table__keyword-open': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                >
                 <span>

                   {{ sentimentRow.title }}
                 </span> 
                  <span
               
                class="md:hidden"
              >
                {{
                  rowTotalCounts.find(
                    (row) => row.entityId === sentimentRow.entityId
                  ).sentimentsLength
                }} mentions
              </span>

                </div>
                <div
                  :class="{
                    'st-sentiment-data-table__spacer': true,
                    'st-sentiment-data-table__spacer--expanded': isExpanded(
                      sentimentRow.entityId
                    ),
                  }"
                />
              </td>
              <td
                v-else
                class="st-sentiment-data-table__item"
              >
                {{ sentimentRow.title }}
                
               
              </td>
              <td
                class="st-sentiment-data-table__item md:tw-min-w-[300px]"
                colspan="6"
              >
                <!-- <sentiment-tag
                  :sentiment="
                    rowAverages.find(
                      (row) => row.entityId === sentimentRow.entityId
                    ).sentiments
                  "
                /> -->
                <sentiment-mentions-bar
                  :mentions="
                    rowTotalCounts.find(
                      (row) => row.entityId === sentimentRow.entityId
                    ).sentimentsLength"
                  :sentiments="rowSentimentCounts.find(
                    (row) => row.entityId == sentimentRow.entityId
                  ).sentimentCounts"
                />
              </td>
              <td
                v-if="includeCount"
                class="st-sentiment-data-table__item tw-hidden md:tw-table-cell"
              >
                {{
                  rowTotalCounts.find(
                    (row) => row.entityId === sentimentRow.entityId
                  ).sentimentsLength
                }} mentions
              </td>
              <template v-if="activeQuestionModifier === 'votes'">
                <!-- <td
                  v-for="(sentimentCount,
                          percentIdx) in rowSentimentCounts.find(
                            (row) => row.entityId == sentimentRow.entityId
                          ).sentimentCounts"
                  :key="percentIdx + 'percentIndex'"
                  class="st-sentiment-data-table__item"
                >
                  {{ sentimentCount }}
                </td> -->
                <td class="st-sentiment-data-table__item tw-px-4 md:tw-min-w-[164px] tw-flex tw-justify-end">
                  <span
                    v-if="userSignedIn === 'true'"
                    :class="{
                      'st-sentiment-data-table__chevron': true,
                      'st-sentiment-data-table__chevron--open': isExpanded(
                        sentimentRow.entityId
                      ),
                      'cursor-pointer': true,
                    }"
                    @click="
                      addToGroupHandler(
                        $event,
                        sentimentRow.entityId,
                        sentimentRow.entitySentimentsGroupId
                      )
                    "
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_230_6389)">
                        <path
                          d="M11 0.000366211C4.93429 0.000366211 0 4.9357 0 11.0004C0 17.065 4.93429 22.0004 11 22.0004C17.0657 22.0004 22 17.065 22 11.0004C22 4.9357 17.0657 0.000366211 11 0.000366211ZM11 20.7783C5.60895 20.7783 1.22205 16.3919 1.22205 11.0004C1.22205 5.60879 5.60843 1.22241 11 1.22241C16.3916 1.22241 20.778 5.60879 20.778 11.0004C20.778 16.3919 16.391 20.7783 11 20.7783Z"
                          fill="#202020"
                        />
                        <path
                          d="M14.0554 10.3891H11.6107V7.9445C11.6107 7.60717 11.3373 7.33374 11 7.33374C10.6626 7.33374 10.3887 7.60769 10.3887 7.94503V10.3896H7.94407C7.60674 10.3896 7.33331 10.6631 7.33331 11.0004C7.33331 11.3377 7.60727 11.6117 7.9446 11.6117H10.3892V14.0563C10.3892 14.3936 10.6632 14.6676 11.0005 14.6676C11.3378 14.6676 11.6118 14.3936 11.6118 14.0563V11.6117H14.0564C14.3937 11.6117 14.6677 11.3377 14.6677 11.0004C14.6677 10.6631 14.3937 10.3891 14.0559 10.3891H14.0554Z"
                          fill="#202020"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_230_6389">
                          <rect
                            width="22"
                            height="22"
                            fill="white"
                            transform="translate(0 0.000366211)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                  </span>
                </td>
              </template>
              <!-- <template v-else>
                <td
                  v-for="(sentimentPercent,
                          percentIdx) in rowSentimentPercents.find(
                            (row) => row.entityId == sentimentRow.entityId
                          ).sentimentPercents"
                  :key="percentIdx + 'anotherpercent'"
                  class="st-sentiment-data-table__item"
                >
                  {{ sentimentPercent }}%
                </td>
              </template> -->
            </tr>
            <tr
              v-if="isExpanded(sentimentRow.entityId)"
              :key="'expanded' + sentimentRow.entityId"
            >
              <td
                class="st-sentiment-data-table__item"
                :colspan="includeCount ? 8 : 7"
              >
                <slot
                  name="expanded-row"
                  :row="sentimentRow"
                  :row-idx="sentimentRow.entityId"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
    <st-modal
      v-if="showEditModal"
      v-model="showEditModal"
      
    >
      <div class="tw-flex tw-justify-between tw-flex-col tw-h-full">
        <div>
        <h3 class="font-600">
        Edit keyword
      </h3>
      <div>
        <p class="tw-my-2">
          Enter the new keyword title
        </p>
        
        <p>
          <span class="tw-text-sm">
            Title
          </span>
          <st-input  v-model="drawerSentimentRowTitleToEdit" style-type="rounded" />
          
        </p>
      </div>
      </div>
      <div class="flex justify-end tw-mt-5">
        <st-button

          @click="editKeywordConfirmation"
        >
          Save
        </st-button>
      </div>
      </div>
    </st-modal>
    <st-modal
      v-if="showChangeModal"
      v-model="showChangeModal"
      size-class=""
    >
      <div class="tw-flex tw-justify-between tw-flex-col tw-h-full">
        <div>
        <h3 class="font-600">
        Change sentiment
      </h3>
      <div>
        <p class="tw-my-2">
          Change the sentiment for this transcript?
        </p>
        <div class="my-4 ">
        
        <div class="tw-flex tw-space-x-3">
          <span v-for="sentiment in sentimentOptions.map((option) => {
            return option.name
          })"
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': selectedSentimentToChange==sentiment }"
            
            @click="changeSentimentToUpdateTranscript(sentiment)"
          >{{ sentiment }}</span>
        </div>
        <!-- <st-dropdown-menu
          container-class="h-40p  border-none rounded-4p border-black"
          selected-class="rounded-4p w border-gray-important"
          :display-caption="true"
          :initial-active-option="selectedSentimentOption"
          :options="sentimentOptions.map((option) => {
            return option.name
          })"
          :class="{ '': true }"
          @updateOption="changeSentimentOption"
        /> -->
      </div>
      </div>
      </div>
      <div class="flex justify-end tw-mt-5">
        <st-button

          @click="changeSentimentConfirmation"
        >
          Save
        </st-button>
      </div>
      </div>
    </st-modal>
    <st-modal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      
    >
      <div class="tw-flex tw-justify-between tw-flex-col tw-h-full">
        <div>
        <h3 class="font-600">
        Delete sentiment
      </h3>
      <div>
        <p class="tw-my-2">
          Delete sentiment for this transcript?
        </p>

      </div>
      </div>
      <div class="flex justify-end tw-mt-5">
        <st-button
          @click="deleteKeywordConfirmation">
          Delete
        </st-button>
      </div>
      </div>
    </st-modal>
    <st-drawer v-model="showModal">
      <template v-slot:modalTitle>
        <!-- <div class="flex justify-between  "> -->
          <div class="tw-flex tw-items-center tw-space-x-4 ">
            <span class="font-18p font-bold tw-h-fit">
            {{ drawerSentimentRowTitle }}
          </span>
          <span class="tw-underline tw-cursor-pointer tw-text-sm tw-text-black tw-min-w-fit" @click="editKeyword">
            Rename keyword
          </span>
          </div>
        <!-- </div> -->
      </template>
      <template />

      <template v-slot:modalActions>
        <div class="py-7 text-1p2-rem  tw-text-white tw-bg-territory-dark ">
          <p class="text-center">
            {{ entityMentionCount }}/{{ responses.length }} participants have mentioned the word
          </p>
          <p class="text-center text-1p4-rem font-bold">
            "{{ drawerSentimentRowTitle }}"
          </p>
        </div>
        <div class="tw-px-3 flex tw-space-x-3 py-4">
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==4 }"
            v-if="activeTranscriptsForSentiments(4)"
            @click="updateSentimentOption('Very Positive')"
          >Very Positive  ({{ activeTranscriptsForSentiments(4) }})</span>
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==3 }"
            v-if="activeTranscriptsForSentiments(3)"
            @click="updateSentimentOption('Positive')"
          >Positive  ({{ activeTranscriptsForSentiments(3) }})</span>
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==2 }"
            v-if="activeTranscriptsForSentiments(2)"
            @click="updateSentimentOption('Neutral')"
          >Neutral  ({{ activeTranscriptsForSentiments(2) }})</span>
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==1 }"
            v-if="activeTranscriptsForSentiments(1)"
            @click="updateSentimentOption('Negative')"
          >Negative  ({{ activeTranscriptsForSentiments(1) }})</span>
          <span
            class="px-3 py-2 tw-text-xs cursor-pointer border-slate-100  tw-rounded-lg    tw-flex tw-justify-center tw-items-center"
            :class="{ 'tw-bg-territory text-white': activeSentimentIdx==0 }"
            v-if="activeTranscriptsForSentiments(0)"
            @click="updateSentimentOption('Very Negative')"
          >Very Negative ({{ activeTranscriptsForSentiments(0) }})</span>
        </div>
        <p class="text-12p font-600 py-4 text-center" v-if="sentimentMentionCount">
          {{ sentimentMentionCount }}/{{ entityMentionCount }} participants have
          {{ activeSentiment }} views about "{{ drawerSentimentRowTitle }}"
        </p>
        <p v-else class="tw-px-5 tw-py-3 tw-text-center">
          Select sentiment type to view list of participants        
        </p>
        <div class="st-sentiment-data-table-detail__transcripts py-4 w-full">
          <div
            v-for="transcript in activeTranscripts"
            :key="transcript.responseId"
            class="st-sentiment-data-table-detail__one-transcript-container "
          >
            <div class="border-b-slate-100 px-8 ">
              <div class="st-insights__transcript-avatar-row ">
                <div
                  class="st-insights__transcript-avatar-with-name tw-flex tw-justify-between tw-w-full"
                  :class="{'hover:tw-cursor-pointer':transcript.srcUrl}"
                  @click="playTranscript(transcript.responseId)"
                >
                  <div class="tw-flex tw-items-center">
                    <participant-avatar :participant="transcript" />
                  <div class="st-sentiment-data-table-detail__one-transcript-name">
                    {{ activeName(transcript) }}
                  </div>
                  </div>
                  <div class="tw-flex tw-text-sm tw-space-x-2">
                    <span class="tw-underline" @click="changeSentiment($event, transcript)">
                    Change sentiment
                  </span>
                  <span class="tw-underline tw-text-negative" @click="deleteKeyword($event, transcript)">
                    Delete sentiment
                  </span>
                  </div>
                </div>
              </div>
              <div class="st-sentiment-data-table-detail__one-transcript-section">
                <div
                  class="st-insights__transcript-section-text st-sentiment-data-table-detail__transcript text-16p"
                  
                >
                
                  <template v-for="(text, idx) in splitTranscriptText(transcript)">
                    <span
                      v-if="idx > 0"
                      :key="'keyword' + idx"
                      class="st-sentiment-data-table-detail__bold-keyword font-600 text-black"
                    >
                      {{ drawerSentimentRowTitle }}
                    </span>
                    <span :key="'text' + idx">
                      {{ text }}
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="activeAudioUrl"
            :class="{
              'st-virtual-tour-insights__audio-bar': true,
            }"
          >
            <audio
              ref="transcriptAudio"
              class="st-virtual-tour-insights__full-audio"
              autoplay
              controls
              @timeupdate="handleTimeUpdate"
            >
              <source
                :src="activeAudioUrl"
                :type="activeAudioContentType"
              >
              <!-- <source
                :src="activeAudioUrl"
                type="audio/mpeg"
              > -->
              <source
                :src="activeAudioUrl"
                type="audio/ogg"
              >
              <source
                v-if="activeAudioFlacUrl"
                :src="activeAudioFlacUrl"
                :type="activeAudioFlacContentType"
              />
            </audio>
          </div>
        </div>
        <!-- <div class="py-4 px-8 border-b-slate-100">
          <div class="flex items-center space-x-3 p-1">
            <div class="rounded-full p-1 bg-primary-brown text-white text-12p">
              P1
            </div>
            <span>
              Participant
            </span>
          </div>
          <div class="px-2-important py-2">
            <p class="text-12p  ">
              I really love my iPhone , many. I have the 12 and I would love for Apple in the future to build another one, and particularly love the form factor. I love the size, those are all great. The battery life is not that great, but I'm willing to sacrifice a little bit of battery life for a smaller device Factor.
            </p>
          </div>
        </div>
        <div class="py-4 px-8 border-b-slate-100">
          <div class="flex items-center space-x-3 p-1">
            <div class="rounded-full p-1 bg-primary-brown text-white text-12p">
              P1
            </div>
            <span>
              Participant
            </span>
          </div>
          <div class="px-2-important py-2">
            <p class="text-12p  ">
              I really love my iPhone , many. I have the 12 and I would love for Apple in the future to build another one, and particularly love the form factor. I love the size, those are all great. The battery life is not that great, but I'm willing to sacrifice a little bit of battery life for a smaller device Factor.
            </p>
          </div>
        </div> -->
      </template>
    </st-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  average,
  bucketCounts,
  bucketIdx,
  caseInsensitiveSortBy,
  percent,
  sum
} from '../../../utilities/data_utilities'
import { IconWrapper, StButton, StDrawer, StDropdownMenu, StInput, StModal, } from '../../common'
import participantAvatar from './participant-avatar.vue'
import SentimentMentionsBar from './sentiment-mentions-bar.vue'
import SentimentTag from './sentiment-tag'
const SENTIMENTS = [
  'Very Negative',
  'Negative',
  'Neutral',
  'Positive',
  'Very Positive'
]

export default {
  components: {
    IconWrapper,
    StInput,
    StDrawer,
    'sentiment-tag': SentimentTag,
    
    SentimentMentionsBar,
    participantAvatar,
    StModal,
    StButton,
    StDropdownMenu
  },
  props: {
    tableCompleted: {
      type: Boolean,
      required: false,
      default: true
    },
    sentimentRows: {
      type: Array, // each should be an object with title and sentiments[] defined
      required: false,
      default: () => []
    },
    includeCount: {
      type: Boolean,
      required: false,
      default: false
    },
    isFirstRowHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    totalParticipants: {
      type: Number,
      required: false,
      default: 0
    },
    expandable: {
      type: Boolean,
      required: false,
      default: false
    },
    columnTitle: {
      type: String,
      required: false,
      default: 'Room Name'
    },
    sentimentGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    userSignedIn: {
      type: String,
      required: false
    },
    keywordsTableLength: {
      type: Number,
      required: true
    },
    responses: {
      type: Array,
      required: false
    }
  },
  data: function () {
    return {
      activeColumn: { name: 'Avg. Sentiment', ascending: true },
      expandedRows: {},
      expendedGroups: {},
      showChangeModal: false,
      activeResponse: {},
      showByIndex: null,
      isPopupOpen: {},
      showDeleteModal: false,
      drawerSentimentRowTitle: '',
      drawerSentimentSelectedEntityId: null,
      drawerSentimentRowTitleToEdit: '',
      transcriptIdToDeleteSentiment: null,
      transcriptIdToChangeSentiment: null,
      entitySentimentIdToDelete: null,
      sentimentOptions: [
        { name: 'Very Negative', value: -0.9 },
        { name: 'Negative', value: -0.5 },
        { name: 'Neutral', value: 0.0 },
        { name: 'Positive', value: 0.5 },
        { name: 'Very Positive', value: 0.9 }
      ],
      selectedSentimentOption: null,
      selectedSentimentToChange: null,
      headers: [
        'Avg. Sentiment',
        'Mentions',
        'Very Positive',
        'Positive',
        'Neutral',
        'Negative',
        'Very Negative',
        ''
      ],
      sentiments: [
        'Very Negative',
        'Negative',
        'Neutral',
        'Positive',
        'Very Positive'
      ],
      showModal: false,
      hoverIdx: null,
      showEditModal: false,
      activeSentimentIdx: -1
    }
  },
  mounted () {
    // this.activeSentimentIdx = 4
    // console.log(this.keywordsTableLength)
  },
  computed: {
    ...mapState('insights', ['activeQuestionModifier']),
    activeAudioFlacUrl () {
      return this.activeResponse?.mpegSrcUrl
    },
    activeAudioFlacContentType() {
      return this.activeResponse.mpegContentType;
    },
    activeAudioUrl () {
      return this.activeResponse?.srcUrl
    },
    activeAudioContentType () {
      return this.activeResponse?.contentType
    },
    sentimentMentionCount () {
      return this.activeTranscripts.length
    },
    transcripts () {
      return this.entitySentimentsKeywords[this.drawerSentimentRowTitle].transcripts
    },
    entityMentionCount () {
      return this.transcripts.length
    },
    activeSentiment () {
      return SENTIMENTS[this.activeSentimentIdx]
    },
    activeTranscripts () {
      return this.transcripts.filter(
        (transcript) => transcript.bucketIdx === Number(this.activeSentimentIdx)
      )
    },

    entitySentimentsKeywords () {
      let keyWordSentiments = {}
      this.responses.forEach((response) => {
        response.entitySentiments.map((entity) => {
          if (keyWordSentiments.hasOwnProperty(entity.name)) {
            keyWordSentiments[entity.name].sentiments.push(
              entity.sentimentScore
            )
            keyWordSentiments[entity.name].transcripts.push({
              bucketIdx: entity.sentimentValueIdx,
              firstName: response.firstName,
              lastName: response.lastName,
              name: response.fullName,
              surveyResponseId: response.surveyResponseId,
              responseId: response.id,
              transcript: response.transcript,
              srcUrl: response.srcUrl
            })
          } else {
            keyWordSentiments[entity.name] = {
              entityId: entity.id,
              entitySentimentsGroupId: entity.entitySentimentsGroupId,
              sentiments: [entity.sentimentScore],
              transcripts: [
                {
                  bucketIdx: entity.sentimentValueIdx,
                  firstName: response.firstName,
                  lastName: response.lastName,
                  name: response.fullName,
                  responseId: response.id,
                  surveyResponseId: response.surveyResponseId,
                  transcript: response.transcript,
                  srcUrl: response.srcUrl
                }
              ]
            }
          }
        })
      })
      return keyWordSentiments
    },

    filterSentimentRow () {
      const filterSentimentRows = { unGroup: [] }

      for (let i = 0; i < this.sentimentGroups.length; i++) {
        const group = this.sentimentGroups[i]
        filterSentimentRows[group.name] = []
      }

      for (let i = 0; i < this.sentimentRows.length; i++) {
        const sentiment = this.sentimentRows[i]
        let groupFound = false
        for (let j = 0; j < this.sentimentGroups.length; j++) {
          const group = this.sentimentGroups[j]
          if (sentiment.entitySentimentsGroupId === group.id) {
            filterSentimentRows[group.name].push(sentiment)
            groupFound = true
          }
        }
        if (!groupFound) {
          filterSentimentRows.unGroup.push(sentiment)
        }
      }

      for (const key in filterSentimentRows) {
        if (
          Array.isArray(filterSentimentRows[key]) &&
          !filterSentimentRows[key].length
        ) {
          delete filterSentimentRows[key]
        }
      }

      let sortGroupedRow = {}
      let unGroupRow = {}
      const reversedKeys = Object.keys(filterSentimentRows).sort()
      reversedKeys.forEach((key) => {
        if ([key] !== 'unGroup') {
          sortGroupedRow = {
            ...sortGroupedRow,
            [key]: filterSentimentRows[key]
          }
        } else {
          unGroupRow = {
            ...unGroupRow,
            [key]: filterSentimentRows[key]
          }
        }
      })
      return { ...unGroupRow, ...sortGroupedRow }
    },
    rowAverages () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentiments: average(sentimentRow.sentiments || [])
        }
      })
    },
    rowTotalCounts () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentimentsLength: sentimentRow.sentiments.length
        }
      })
    },
    rowAverageBucketIndexes () {
      return this.rowAverages.map((average) => bucketIdx(average))
    },
    rowSentimentCounts () {
      return this.sortedRowsByActiveColumn.map((row) => {
        return {
          entityId: row.entityId,
          sentimentCounts: this.sentimentCounts(row.sentiments)
        }
      })
    },
    rowSentimentPercents () {
      return this.sortedRowsByActiveColumn.map((sentimentRow) => {
        return {
          entityId: sentimentRow.entityId,
          sentimentPercents: this.sentimentPercents(sentimentRow.sentiments)
        }
      })
    },
    tableHeaders () {
      return this.includeCount
        ? this.headers
        : this.headers.filter((header) => header !== 'Mentions')
    },
    sortedRowsByActiveColumn () {
      let rows = []
      switch (this.activeColumn.name) {
        case 'Keyword':
          return this.sortColumnProperty('title')
        case 'Avg. Sentiment':
          rows = this.activeColumn.ascending
            ? this.sentimentRows
              .slice()
              .sort((a, b) => b.averageSentiment - a.averageSentiment)
            : this.sentimentRows
              .slice()
              .sort((a, b) => a.averageSentiment - b.averageSentiment)
          break
        case 'Participant Mentions':
          rows = this.activeColumn.ascending
            ? this.sentimentRows
              .slice()
              .sort((a, b) => b.sentiments.length - a.sentiments.length)
            : this.sentimentRows
              .slice()
              .sort((a, b) => a.sentiments.length - b.sentiments.length)
          break
        case 'Very Negative':
          return this.sortSentiment(0)
        case 'Negative':
          return this.sortSentiment(1)
        case 'Neutral':
          return this.sortSentiment(2)
        case 'Positive':
          return this.sortSentiment(3)
        case 'Very Positive':
          return this.sortSentiment(4)
      }

      return rows
    },
    keywordPattern () {
      let strippedKeyword = this.drawerSentimentRowTitle.trim()
      if (strippedKeyword.slice(-1) === '.') {
        strippedKeyword = strippedKeyword.slice(0, -1)
      }
      let keywordPattern = strippedKeyword.replace('.', '\\.')
      return new RegExp(keywordPattern)
    }
  },

  methods: {
    changeSentimentToUpdateTranscript (sentiment) {
      this.selectedSentimentToChange = sentiment
     
    },
    handleTimeUpdate (ev) {
      this.currentAudioTime = ev.srcElement.currentTime
    },
    activeTranscriptsForSentiments (index) {
      return this.transcripts.filter(
        (transcript) => transcript.bucketIdx === Number(index)
      ).length
    },
    playTranscript (responseId) {
      const response = this.$store.getters['insights/findResponse'](responseId)
      if(!response.srcUrl){
        return
      }
      if (response.srcUrl !== this.activeAudioUrl) {
        this.activeResponse = response
        this.$nextTick(() => {
          this.$refs.transcriptAudio.load()
        })
      }
    },
    updateSentimentOption (sentiment) {
      this.activeSentimentIdx = SENTIMENTS.indexOf(sentiment)
    },
    transcriptText (transcript) {
      if (this.identifier) {
        let response = this.$store.getters['insights/findResponse'](
          transcript.responseId
        )
        let matchingSentences = response.sentences.filter((sentence) =>
          sentence.occursOverRoom(this.identifier)
        )
        return matchingSentences.map((sentence) => sentence.text).join(' ').replace(/�/g, '')
      } else {
        if (!transcript.transcript) return ''
        return transcript.transcript.replace(/�/g, '')
      }
    },
    splitTranscriptText (transcript) {
      // if a period is in tHe keyword it will be missing when we combine
      return this.transcriptText(transcript).split(this.keywordPattern)
    },
    activeName (activeResponse) {
      let name = 'Anonymous User'
      if (activeResponse.firstName && activeResponse.lastName) {
        name = activeResponse.firstName + ' ' + activeResponse.lastName
      } else if (activeResponse.email) {
        name = activeResponse.email
      }
      return name
    },
    renameGroupHandler (e, groupId, groupName) {
      e.stopPropagation()
      this.$emit('groupModalHandler', 'Rename Group', groupId, groupName)
    },
    closeModal () {
      this.showModal = false
    },
    deleteGroupHandler (e, groupId, groupName) {
      e.stopPropagation()
      this.$emit('deleteGroupModalHandler', groupId, groupName)
    },
    addToGroupHandler (e, id, groupId) {
      let oldValue = this.isPopupOpen[id]
      this.$set(this.isPopupOpen, id, !oldValue)
      e.stopPropagation()
      this.$emit('existGroupModalHandler', id, groupId)
      this.isPopupOpen = {}
    },
    removeFromGroup (e, id) {
      let oldValue = this.isPopupOpen[id]
      this.$set(this.isPopupOpen, id, !oldValue)
      e.stopPropagation()

      this.$axios
        .request({
          url: `/entity_sentiments/${id}/assign/`,
          method: 'patch',
          data: { entity_sentiments_group_id: null }
        })
        .then((res) => {
          this.$store.dispatch('insights/updateEntitySentimentGroup', {
            entityId: id,
            groupId: null
          })
          this.$emit('showToast', 'Remove Successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    sentimentPercents (sentiments) {
      let rowCounts = this.sentimentCounts(sentiments)
      let totalCount = sum(rowCounts)
      return rowCounts.map((rowCount) => percent(rowCount, totalCount, 0))
    },
    sentimentCounts (sentiments) {
      // reversing this array because the previous implementation headers were like this
      // ["very negative","negative", "neutral", "positive", "very positive"]
      // the new implementation headers are changed and reversed. so the array is reversed
      // ["very positive","positive", "neutral", "negative", "very negative"]
      return bucketCounts(sentiments).reverse()
    },
    handleColumnSorting (header) {
      this.activeColumn.name === header
        ? (this.activeColumn = {
          ...this.activeColumn,
          ascending: !this.activeColumn.ascending
        })
        : (this.activeColumn = { name: header, ascending: true })
    },
    isExpanded (idx) {
      return Boolean(this.expandedRows[idx])
    },
    isPopupOpenForRow (idx) {
      return Boolean(this.isPopupOpen[idx])
    },
    isExpandedGroup (idx) {
      return Boolean(this.expendedGroups[idx])
    },
    sortColumnProperty (property) {
      return this.activeColumn.ascending
        ? caseInsensitiveSortBy(this.sentimentRows, property)
        : caseInsensitiveSortBy(this.sentimentRows, property, true)
    },
    sortSentiment (index) {
      if (this.activeQuestionModifier === 'votes') {
        return this.activeColumn.ascending
          ? this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentCounts(b.sentiments)[index] -
                  this.sentimentCounts(a.sentiments)[index]
            )
          : this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentCounts(a.sentiments)[index] -
                  this.sentimentCounts(b.sentiments)[index]
            )
      } else {
        return this.activeColumn.ascending
          ? this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentPercents(b.sentiments)[index] -
                  this.sentimentPercents(a.sentiments)[index]
            )
          : this.sentimentRows
            .slice()
            .sort(
              (a, b) =>
                this.sentimentPercents(a.sentiments)[index] -
                  this.sentimentPercents(b.sentiments)[index]
            )
      }
    },
    totalFilterGroupedRowData (key) {
      let totalParticipantMember = 0
      let veryNegative = 0
      let negative = 0
      let neutral = 0
      let positive = 0
      let veryPositive = 0
      let totalSentiments = 0
      let counter = 0
      for (let i = 0; i < this.filterSentimentRow[key].length; i++) {
        const sentimentRow = this.filterSentimentRow[key][i]

        totalParticipantMember += sentimentRow.sentiments.length
        const sentimentCounts = this.sentimentCounts(sentimentRow.sentiments)

        totalSentiments += average(sentimentRow.sentiments || [])

        counter++

        veryNegative += sentimentCounts[4]
        negative += sentimentCounts[3]
        neutral += sentimentCounts[2]
        positive += sentimentCounts[1]
        veryPositive += sentimentCounts[0]
      }
      return {
        totalParticipantMember,
        veryNegative,
        negative,
        neutral,
        positive,
        veryPositive,
        totalSentiments: totalSentiments / counter
      }
    },
    filterGroupedRowData (key) {
      const data = this.filterSentimentRow
      return data[key]
    },
    toggleRowExpansion (idx, row) {
      // console.log(idx)
      this.drawerSentimentSelectedEntityId = idx
      this.drawerSentimentRowTitle = row.title
      if (!this.expandable) {
        return
      }
      // let oldValue = this.expandedRows[idx]
      // this.$set(this.expandedRows, idx, !oldValue)
      this.isPopupOpen = {}
      this.activeSentimentIdx = 4
      this.showDrawer()
    },
    showDrawer () {
      this.showModal = true
    },
    toggleGroupExpansion (idx) {
      let oldValue = this.expendedGroups[idx]
      this.$set(this.expendedGroups, idx, !oldValue)
    },
    togglePopup (e, idx) {
      e.stopPropagation()
      let oldValue = this.isPopupOpen[idx]
      this.$set(this.isPopupOpen, idx, !oldValue)
    },
    editKeyword (e) {
      e.stopPropagation()
      this.showEditModal = true
      this.drawerSentimentRowTitleToEdit = this.drawerSentimentRowTitle
      this.closeModal() 
    },
    editKeywordConfirmation(){
      // console.log(this.transcripts)
      this.$axios
        .request({
          url: `/entity_sentiments/update_name_by_ids`,
          method: 'post',
          data: { 
          old_name: this.drawerSentimentRowTitle,
          new_name: this.drawerSentimentRowTitleToEdit,
          parent_ids: this.transcripts.map((transcript) => transcript.responseId)
          }
        })
        .then((res) => {
          this.$store.dispatch('insights/updateEntitySentimentName', {
            entityId: this.drawerSentimentSelectedEntityId,
            name: this.drawerSentimentRowTitleToEdit
          })
          this.$emit('showToast', 'Update Successfully')
          this.closeModal()
          this.showEditModal = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    
    deleteKeyword (e, transcript) {
      const response = this.responses.find(item => {
          return item.surveyResponseId === transcript.surveyResponseId
      })
      const transcriptId = response.entitySentiments.find(item => {
        return item.name === this.drawerSentimentRowTitle
      }).id
      this.entitySentimentIdToDelete = transcriptId
      
      // console.log(this.activeTranscripts)
      this.showDeleteModal = true
        e.stopPropagation()
        this.closeModal()
    
    },
    deleteKeywordConfirmation(){
       this.$axios
        .request({
          url: `/entity_sentiments/${this.entitySentimentIdToDelete}`,
          method: 'delete'
        })
        .then((res) => {
          // check if the counts in the entity is more than 1 then just remove the entity from the entity keywords
          // else remove the entity from the entity keywords and also remove the entity from the entity sentiments
          if(this.transcripts.length < 2){
            this.$store.dispatch('insights/deleteEntitySentiment', {
              entityId: this.drawerSentimentSelectedEntityId
            })
          }else{
            this.$store.dispatch('insights/deleteEntitySentiment', {
              entityId: this.drawerSentimentSelectedEntityId
            })
            this.$store.dispatch('insights/deleteEntitySentimentFromEntitySentiments', {
              entityId: this.drawerSentimentSelectedEntityId,
              entitySentimentId: this.entitySentimentIdToDelete
            })
            
          }
          this.$emit('showToast', 'Delete Successfully')
          this.closeModal()
        })
        .catch((err) => {
          console.log(err)
        })
      this.closeModal()
      this.showDeleteModal = false
    
    },
    changeSentiment(e, transcript){
      this.showChangeModal = true
        e.stopPropagation()
        this.closeModal()
        const response = this.responses.find(item => {
          return item.surveyResponseId === transcript.surveyResponseId
      })
      const currentTranscript = response.entitySentiments.find(item => {
        return item.name === this.drawerSentimentRowTitle
      })
      this.transcriptIdToChangeSentiment = currentTranscript.id
     console.log(currentTranscript)
      
      this.selectedSentimentToChange = SENTIMENTS[currentTranscript.sentimentValueIdx]
      
      // console.log(this.activeTranscripts)
        e.stopPropagation()
        this.closeModal()
    },
    changeSentimentConfirmation(){
      let sentimentScore = 0
      if(this.selectedSentimentToChange === 'Very Negative'){
        sentimentScore = -0.9
      }else if(this.selectedSentimentToChange === 'Negative'){
        sentimentScore = -0.4
      }else if(this.selectedSentimentToChange === 'Neutral'){
        sentimentScore = 0
      }else if(this.selectedSentimentToChange === 'Positive'){
        sentimentScore = 0.4
      }else if(this.selectedSentimentToChange === 'Very Positive'){
        sentimentScore = 0.9
      }
      this.$axios
        .request({
          url: `/entity_sentiments/${this.transcriptIdToChangeSentiment}/assign/`,
          method: 'patch',
          data: { sentiment_score: sentimentScore, sentiment_magnitude: sentimentScore, salience: sentimentScore }
        })
        .then((res) => {
          this.showChangeModal = false
          
          this.$store.dispatch('insights/updateEntitySentiment', {
            entityId: this.transcriptIdToChangeSentiment,
            sentiment: sentimentScore
          })
          this.$emit('showToast', 'Remove Successfully')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  }

</script>
