<template>
  <div>
    <div class="bx--grid mobile-header">
      <a
        href="/welcome"
      >
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-dropdown-button
          caption="Manage Recipients"
          icon-name="caret-down"
          :show-action="true"
          :options="dropdownOptions"
          @updateOption="handleDropdownOption"
        />
      </div>
    </div>
    <div class="bx--grid">
      <div class="bx--col-sm-12 bx--col-xs-12">
        <div class="desktop-page-and-dropdown-button">
          <h1 class="page-header">
            All Recipients
          </h1>
          <st-dropdown-button
            class="page-dropdown-button"
            caption="Manage Recipients"
            icon-name="caret-down"
            :show-action="true"
            :options="dropdownOptions"
            @updateOption="handleDropdownOption"
          />
        </div>
        <p class="page-summary-information page-summary-information--extra-space">
          You have a total of<span class="page-summary-information__bolded-stat">
            {{ allRecipients.length }}
          </span>recipients collected.
        </p>
        <div class="lookup-and-dropdown">
          <st-input
            ref="search"
            v-model="recipientLookupValue"
            class="st-input--default lookup-and-dropdown__space-input"
            :show-icon="true"
            :show-label="false"
            label="Recipient Lookup"
            label-in-field-class="st-input--hide-mobile-label"
            @blur="updateSearchParam"
          />
          <st-dropdown-menu
            caption="Sort By"
            :display-caption="true"
            :initial-active-option="activeRecipientsFilterOption"
            :options="recipientFilterValues"
            @updateOption="setDropdownOption"
          />
        </div>
        <st-data-table
          :active-column="activeColumn"
          :headers="['Name', 'Email', 'Surveys Taken', 'Status', 'Source']"
          :rows="activePageRecipients"
          :sortable-columns="['Name', 'Email', 'Surveys Taken', 'Status']"
          @setActiveColumn="updateActiveColumn"
          @toggleActiveColumn="updateActiveColumn"
          @selectAll="toggleAllChecks(true)"
          @unselectAll="toggleAllChecks(false)"
        >
          <template v-slot:selected-actions>
            <div class="st-team__table-actions">
              <div
                class="st-team__table-action"
                @click="createListModal = true"
              >
                <icon-wrapper
                  icon-name="add-list"
                  class="st-team__table-action-icon"
                  :invert="true"
                />
                <div>Create List</div>
              </div>
              <div
                v-if="canDeleteSelected"
                class="st-team__table-action"
                @click="deleteParticipantsModal = true"
              >
                <icon-wrapper
                  icon-name="trash"
                  class="st-team__table-action-icon"
                  :invert="true"
                />
                <div>Delete</div>
              </div>
            </div>
          </template>
          <template v-slot:default="{ row: recipient, anyRowsChecked }">
            <st-data-table-checkbox-row
              :key="recipient.id"
              :row-data="recipient"
              :avatar-url="recipient.avatarUrl"
              :cell-keys="['fullName', 'email', 'surveys taken', 'status', 'source']"
              :checked="recipient.selected"
              :show-all-checks="anyRowsChecked"
              @input="(val) => { updateCheck(recipient.id, val)}"
            >
              <template v-slot:cell-3>
                <st-data-table-dropdown-info
                  class="st-data-table-centered__surveys-taken-dropdown"
                  :center-closed-text="true"
                  :disabled="!isDesktop"
                  :display-text="recipient.surveysCompleted.length + ' / ' + recipient.surveyProgress.length"
                  :open-title="openTitle(recipient)"
                >
                  <div
                    v-for="(survey, index) in recipient.surveyProgress"
                    :key="survey.id"
                    class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__spacer"
                  >
                    <div class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__stacker">
                      <p class="st-all-recipients-table-dropdown__survey-name">
                        {{ survey.surveyTitle }}
                      </p>
                      <div class="st-all-recipients-table-dropdown st-all-recipients-table-dropdown__questions">
                        <p>
                          {{ survey.questionsComplete }}
                        </p>
                        /
                        <p class="st-all-recipients-table-dropdown__question-spacing">
                          {{ survey.questionsCount }}
                        </p>
                        questions
                      </div>
                    </div>
                    <p
                      v-if="survey.questionsComplete === survey.questionsCount"
                      class="st-all-recipients-table-dropdown__complete"
                    >
                      Completed
                    </p>
                    <p
                      v-else-if="survey.surveyArchived"
                      class="st-all-recipients-table-dropdown__complete"
                    >
                      Archived
                    </p>
                    <template v-else-if="survey.surveyPublished">
                      <st-tooltip
                        direction="top"
                        tip="Invite Sent!"
                        :is-active="false"
                        :flash="showTooltip && hoverIdx === index"
                      >
                        <st-hyperlink
                          text="Invite Again"
                          icon-name="paper-plane"
                          @click.native="sendInviteAgain(recipient.id, survey.surveyId, index)"
                        />
                      </st-tooltip>
                    </template>
                    <p
                      v-else
                      class="st-all-recipients-table-dropdown__complete"
                    >
                      Survey Not Published
                    </p>
                  </div>
                </st-data-table-dropdown-info>
              </template>
              <template v-slot:cell-4>
                <div
                  class="st-data-table-centered__text-values"
                >
                  {{ recipient.status }}
                </div>
              </template>
              <template v-slot:cell-5>
                <div
                  class="st-data-table-centered__text-values"
                >
                  {{ recipient.source }}
                </div>
              </template>
            </st-data-table-checkbox-row>
          </template>
        </st-data-table>
        <st-pagination
          class="pagination-spacing"
          :number-of-items="filteredSurveysBySearch.length"
          @updateActiveIndices="updateIndicesOfSurveysShown"
        />
      </div>
    </div>
    <st-modal
      v-model="displayAddRecipientsModal"
      size-class="st-modal--large"
    >
      <template v-slot:modalTitle>
        Add Recipients
      </template>
      <template v-slot:modalActions>
        <div
          class="st-member-groups__modal-text"
        >
          <st-email-input
            ref="emailInput"
            label="Recipients' Emails"
            add-email-word="Add"
            after-email-text="as a recipient"
            @addNewEmail="addNewEmail"
            @removeEmail="removeNewEmail"
          />
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          :disabled="newEmails.length<1"
          caption="Add Recipients"
          icon-name="add-member"
          @click="addRecipients"
        />
      </template>
    </st-modal>
    <st-modal v-model="createListModal">
      <template v-slot:modalTitle>
        Create Audience List
      </template>
      <template v-slot:modalActions>
        <div
          class="st-member-groups__modal-text"
        >
          <st-input
            v-model="newListName"
            class="st-input--default st-member-groups__group-name-input"
            :show-label="true"
            label="Audience List Name"
            placeholder="Name goes here"
            @emitEnter="createNewList"
          />
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          caption="Create List"
          :show-action="true"
          icon-name="list"
          @click="createNewList"
        />
      </template>
    </st-modal>
    <st-modal v-model="deleteParticipantsModal">
      <template v-slot:modalTitle>
        <template>
          {{ participantsTitleString }}
        </template>
      </template>
      <template v-slot:modalActions>
        <div
          class="st-member-groups__modal-text"
        >
          Are you sure you want to delete {{ participantsBodyString }}? If invitation emails have been sent, it will create an error if they try to follow the link to take the survey.
        </div>
      </template>
      <template v-slot:modalButton>
        <st-button
          :caption="participantsTitleString"
          :show-action="true"
          icon-name="trash"
          @click="deleteParticipants"
        />
      </template>
    </st-modal>
  </div>
</template>

<script>
import {
  caseInsensitiveSortBy,
  doubleCaseInsensitiveSortBy
} from '../../../utilities/data_utilities'
import { isDesktop } from '../../../mixins'
import adminbackground from '../../../../assets/images/admin_bg.png'
import {
  IconWrapper,
  StButton,
  StDataTable,
  StDataTableCheckboxRow,
  StDataTableDropdownInfo,
  StDropdownButton,
  StDropdownMenu,
  StEmailInput,
  StHyperlink,
  StInput,
  StModal,
  StDrawer,
  StPagination,
  StTooltip,
  ProfileImage
} from '../../common'
// import { ActivityTab, AccountsTab, UsersTab, BillingTab} from "../admin"
import refineUrlFilter from '../../../mixins/refineUrlFilter'
import { mapState } from 'vuex'

const DROPDOWN_OPTIONS = [
  {
    caption: 'Add Recipients',
    iconName: 'add-member'
  }

  // TO DO: add Import CSV, Create List, Export List to All Recipients,

  // {
  //   caption: 'Import CSV',
  //   iconName: 'import-csv'
  // }, {
  //   caption: 'Create List',
  //   iconName: 'add-list'
  // }, {
  //   caption: 'Export List',
  //   iconName: 'export-list'
  // }
]

export default {
  components: {
    IconWrapper,
    StButton,
    StDropdownMenu,
    StDataTable,
    StDataTableCheckboxRow,
    StDataTableDropdownInfo,
    StDropdownButton,
    StEmailInput,
    StHyperlink,
    StInput,
    StModal,
    StDrawer,
    StPagination,
    StTooltip,
    // ActivityTab,
    // AccountsTab,
    // UsersTab,
    // BillingTab,
    ProfileImage
  },
  mixins: [isDesktop, refineUrlFilter],
  props: {
    recipients: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      Tabs: ['Activity', 'Accounts', 'Users', 'Billing'],

      activeTab: 'Activity',
      activeColumn: { name: 'Name', ascending: true },
      activeIndices: [0, 0],

      showCreatePopup: false,
      activeRecipientsFilterOption: '',
      allRecipients: this.recipients.map((recipient) => {
        return Object.assign(
          {
            selected: false,
            status: 'Active',
            source: 'Email',
            accountParticipantId: recipient.id
          },
          recipient.participant
        )
      }),
      createListModal: false,
      deleteParticipantsModal: false,
      displayAddRecipientsModal: false,
      displayCsvModal: false,
      dropdownOptions: DROPDOWN_OPTIONS,
      newEmails: [],
      newListName: '',
      recipientLookupValue: '',
      hoverIdx: null,
      showTooltip: false,
      backgroundPath: adminbackground
    }
  },
  computed: {
    ...mapState(['currentUser']),
    recipientFilterValues () {
      return ['All Recipients', 'No Surveys Taken', 'All Surveys Taken']
    },
    // getPlanInfo() {
    //   return this.currentUser?.account?.plan;
    // },

    filteredRecipientsByDropdown () {
      let recipients = []
      switch (this.activeRecipientsFilterOption) {
        case 'No Surveys Taken':
          recipients = this.allRecipients.filter(
            (recipient) => recipient.surveysCompleted.length !== 0
          )
          break
        case 'All Surveys Taken':
          recipients = this.allRecipients.filter(
            (recipient) =>
              recipient.surveyProgress.length ===
              recipient.surveysCompleted.length
          )
          break
        default:
          recipients = this.allRecipients
      }
      return recipients
    },
    sortedRecipientsByActiveColumn () {
      let recipients = []
      switch (this.activeColumn.name) {
        case 'Name':
          return this.activeColumn.ascending
            ? doubleCaseInsensitiveSortBy(
              this.filteredRecipientsByDropdown,
              'lastName',
              'firstName'
            )
            : doubleCaseInsensitiveSortBy(
              this.filteredRecipientsByDropdown,
              'lastName',
              'firstName',
              true
            )
        case 'Email':
          return this.sortColumnProperty('email')
        case 'Surveys Taken':
          return this.activeColumn.ascending
            ? this.sortParticipantSurveyProgress(true)
            : this.sortParticipantSurveyProgress(false)
        case 'Status':
          return this.sortColumnProperty('status')
      }
      return recipients
    },
    filteredSurveysBySearch () {
      return this.sortedRecipientsByActiveColumn.filter((recipient) => {
        return recipient.fullName
          .toLowerCase()
          .includes(this.recipientLookupValue.toLowerCase())
      })
    },
    activePageRecipients () {
      return this.filteredSurveysBySearch.slice(...this.activeIndices)
    },
    selectedParticipants () {
      return this.activePageRecipients.filter(
        (recipient) => recipient.selected
      )
    },
    selectedParticipantIds () {
      return this.selectedParticipants.map((recipient) => recipient.id)
    },
    selectedAccountParticipantIds () {
      return this.selectedParticipants.map(
        (recipient) => recipient.accountParticipantId
      )
    },
    selectedParticipantsWithSurveyResults () {
      return this.selectedParticipants.filter(
        (recipient) => recipient.surveysCompleted.length > 0
      )
    },
    canDeleteSelected () {
      return this.selectedParticipantsWithSurveyResults.length === 0
    },
    participantsTitleString () {
      return this.selectedParticipants.length === 1
        ? 'Delete Recipient'
        : 'Delete Recipients'
    },
    participantsBodyString () {
      return this.selectedParticipants.length === 1
        ? 'this participant'
        : 'these participants'
    }
  },
  mounted () {

  },
  created () {
    this.defaultFilter = this.recipientFilterValues[0]
    this.recipientLookupValue = this.searchParam
    this.activeRecipientsFilterOption = this.filterParam
  },
  methods: {
    addNewEmail (email) {
      this.newEmails.push(email.email)
    },
    addRecipients () {
      let emailsToAdd = this.newEmails.filter((email) => {
        return !this.allRecipients.some((current) => current.email === email)
      })

      let url = `/participants`
      this.$axios
        .request({
          method: 'post',
          url: url,
          data: { emails: emailsToAdd }
        })
        .then((res) => {
          let mappedParticipants = res.data.map((recipient) => {
            return Object.assign(
              {
                selected: false,
                status: 'Active',
                source: 'Email'
              },
              recipient
            )
          })
          this.allRecipients = this.allRecipients.concat(mappedParticipants)
          this.displayAddRecipientsModal = false
          this.newEmails = []
        })
        .catch((err) => console.error(err))
    },
    handleDropdownOption (option) {
      switch (option.caption) {
        case 'Add Recipients':
          this.displayAddRecipientsModal = true
          break

        // TODO: add import CSV, create list, export list capabilities
        // case 'Import CSV':
        //   this.displayCsvModal = true
        //   break
        // case 'Create List':
        //   this.createList = true
        //   break
        // case 'Export List':
        //   this.exportList = true
        //   break
      }
    },
    openTitle (recipient) {
      return recipient.surveyProgress.length === 1
        ? `${recipient.surveysCompleted.length} out of ${recipient.surveyProgress.length} Survey Taken`
        : `${recipient.surveysCompleted.length} out of ${recipient.surveyProgress.length} Surveys Taken`
    },
    removeNewEmail (email) {
      if (!email.error) {
        this.newEmails.splice(this.newEmails.indexOf(email.email), 1)
      }
    },
    setDropdownOption (payload) {
      this.updateUrlParams('filter', payload)
      this.activeRecipientsFilterOption = payload
    },
    sendInviteAgain (participantId, surveyId, idx) {
      let url = `/participants/${participantId}/invite_again`
      this.$axios
        .request({
          method: 'post',
          url: url,
          data: { survey_id: surveyId }
        })
        .then((res) => {
          this.showTooltip = true
          this.hoverIdx = idx
          setTimeout(() => {
            this.$nextTick(() => {
              this.showTooltip = false
              this.hoverIdx = null
            })
          }, 1250)
        })
        .catch((err) => {
          console.error('err', err)
        })
    },
    sortColumnProperty (property) {
      return this.activeColumn.ascending
        ? caseInsensitiveSortBy(this.filteredRecipientsByDropdown, property)
        : caseInsensitiveSortBy(
          this.filteredRecipientsByDropdown,
          property,
          true
        )
    },
    sortParticipantSurveyProgress (ascending) {
      let zeroSurveyParticipants = this.filteredRecipientsByDropdown.filter(
        (recipient) => !recipient.surveyProgress.length
      )
      let participantsWithSurveys = this.filteredRecipientsByDropdown.filter(
        (recipient) => recipient.surveyProgress.length
      )
      if (ascending) {
        let sortedParticipantsWithSurveys = participantsWithSurveys
          .slice()
          .sort(
            (a, b) =>
              b.surveysCompleted.length / b.surveyProgress.length -
              a.surveysCompleted.length / a.surveyProgress.length
          )
        return [...sortedParticipantsWithSurveys, ...zeroSurveyParticipants]
      } else {
        let sortedParticipantsWithSurveys = participantsWithSurveys
          .slice()
          .sort(
            (a, b) =>
              a.surveysCompleted.length / a.surveyProgress.length -
              b.surveysCompleted.length / b.surveyProgress.length
          )
        return [...zeroSurveyParticipants, ...sortedParticipantsWithSurveys]
      }
    },
    toggleAllChecks (bool) {
      this.allRecipients.forEach((recipient) => {
        recipient.selected = bool
      })
    },

    toggleCreatePopup () {
      this.showCreatePopup = !this.showCreatePopup
    },
    updateCheck (id, val) {
      let recipient = this.allRecipients.find((recipient) => {
        return recipient.id === id
      })
      recipient.selected = val
    },
    updateIndicesOfSurveysShown (payload) {
      this.activeIndices = payload
    },
    deleteParticipants () {
      if (this.canDeleteSelected) {
        let sendEmailsPromises = this.selectedAccountParticipantIds.map(
          (id) => {
            let url = `/account_participants/${id}`
            this.$axios
              .request({
                method: 'delete',
                url: url
              })
              .then((res) => {
                this.removeParticipant(id)
              })
              .catch((err) => {
                console.error('err', err)
              })
          }
        )

        Promise.all(sendEmailsPromises)
          .then((res) => {
            this.$nextTick(() => {
              this.deleteParticipantsModal = false
              this.toggleAllChecks(false)
            })
          })
          .catch((err) => {
            console.error('err', err)
          })
      }
    },

    createNewList () {
      let url = `/audience_lists/`
      this.$axios
        .request({
          method: 'post',
          url: url,
          data: {
            name: this.newListName,
            participant_ids: this.selectedParticipantIds
          }
        })
        .then((res) => {
          this.newListName = ''
          this.createListModal = false
          window.location.href = '/audience/lists'
        })
        .catch((err) => {
          console.error(err)
        })
    },
    removeParticipant (accountParticipantId) {
      let idx = this.allRecipients
        .map((user) => user.accountParticipantId)
        .indexOf(accountParticipantId)
      this.allRecipients.splice(idx, 1)
    },
    updateActiveColumn (payload) {
      this.activeColumn = payload
    }

  }
}
</script>
<style>
.card-bg {
  background: linear-gradient(166deg, #ddd 0%, #fbfbfb 100%);
}
</style>
