var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:{
    // disable classes
    'tw-transition-all tw-duration-200': !_vm.disabled,
    'tw-cursor-not-allowed tw-opacity-40': _vm.disabled,

    // rounded classes
    'tw-rounded-full': _vm.rounded,
    'tw-rounded-xl': !_vm.rounded,

    // variant classes
    'tw-bg-primary tw-text-white': _vm.type === 'primary',
    'tw-text-black-three tw-bg-white': _vm.type === 'tertiary',
    'tw-bg-primary-light tw-text-primary': _vm.type === 'secondary',
    'tw-bg-white tw-text-black tw-border tw-border-solid tw-border-white-two': _vm.type === 'outlined',

    // hover classes
    'hover:tw-bg-primary-dark': !_vm.disabled && _vm.type === 'primary',
    'hover:tw-bg-secondary-dark hover:tw-text-black': !_vm.disabled && _vm.type === 'secondary',
    'hover:tw-bg-light-gray-four hover:tw-text-black': !_vm.disabled && _vm.type === 'outlined',

    // size classes
    'tw-p-4 tw-text-sm': _vm.size === 'large',
    'tw-p-3 tw-text-[13px]': _vm.size === 'default',
    'tw-p-2 tw-text-[13px]': _vm.size === 'small',
    'tw-w-full tw-p-3': _vm.size === 'full'
  },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')},"blur":function($event){return _vm.$emit('blur')}}},[_vm._t("default",function(){return [_c('span',[_vm._v("\n      "+_vm._s(_vm.caption)+"\n    ")])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }