<template>
  <div>
    <div
      v-if="isPaymentAboutToExpire"
      class="tw-bg-[#FAF3DA] tw-flex tw-space-x-3 tw-items-center px-20 tw-py-4"
    >
      <span>
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1077_3166)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0003 21.5C4.4771 21.5 0 16.7989 0 11C0 5.20109 4.4771 0.5 10.0003 0.5C15.5235 0.5 20 5.20109 20 11C20 16.7995 15.5229 21.5 10.0003 21.5ZM10.0401 3.03975C5.8527 3.01731 2.4413 6.56261 2.41933 10.9595C2.39796 15.3557 5.77433 18.9384 9.96111 18.9615C14.1479 18.9839 17.5599 15.438 17.5819 11.0418C17.6038 6.6449 14.2269 3.06219 10.0401 3.03975ZM14.0654 14.9761L13.7834 15.2722C13.3862 15.6892 12.742 15.6892 12.3454 15.2722L9.97833 12.7867L7.61124 15.2722C7.21406 15.6892 6.57049 15.6892 6.1733 15.2722L5.89129 14.9761C5.49411 14.559 5.49411 13.8826 5.89129 13.4662L8.25838 10.9807L5.89129 8.49516C5.49411 8.0781 5.49411 7.40171 5.89129 6.98528L6.1733 6.68916C6.57049 6.2721 7.21465 6.2721 7.61124 6.68916L9.97833 9.17467L12.3454 6.68916C12.7426 6.2721 13.3868 6.2721 13.7834 6.68916L14.0654 6.98528C14.4626 7.40233 14.4626 8.07873 14.0654 8.49516L11.6983 10.9807L14.0654 13.4662C14.4626 13.8832 14.4626 14.5596 14.0654 14.9761Z"
              fill="#202020"
            />
          </g>
          <defs>
            <clipPath id="clip0_1077_3166">
              <rect
                width="20"
                height="21"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>

      </span>
      <span>
        Your payment method has expired. Kindly refresh your information. If you fail to do so by the end of the month, open studies will be marked as "Closed" until you update your details.
      </span>
      <span
        class="tw-px-3 tw-bg-transparent tw-border tw-cursor-pointer tw-border-black tw-border-solid tw-rounded-xl tw-min-w-fit tw-py-2"
        @click="changePaymentPlanHandler"
      >
        Update payment method
      </span>
    </div>
    <div
      v-else-if="isPaymentMethodExpired"
      class="tw-bg-[#AC5555] tw-flex tw-space-x-3 tw-items-center tw-text-white px-20 tw-py-4"
    >
      <span>
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1077_3157)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0003 21.5C4.4771 21.5 0 16.7989 0 11C0 5.20109 4.4771 0.5 10.0003 0.5C15.5235 0.5 20 5.20109 20 11C20 16.7995 15.5229 21.5 10.0003 21.5ZM10.0401 3.03975C5.8527 3.01731 2.4413 6.56261 2.41933 10.9595C2.39796 15.3557 5.77433 18.9384 9.96111 18.9615C14.1479 18.9839 17.5599 15.438 17.5819 11.0418C17.6038 6.6449 14.2269 3.06219 10.0401 3.03975ZM14.0654 14.9761L13.7834 15.2722C13.3862 15.6892 12.742 15.6892 12.3454 15.2722L9.97833 12.7867L7.61124 15.2722C7.21406 15.6892 6.57049 15.6892 6.1733 15.2722L5.89129 14.9761C5.49411 14.559 5.49411 13.8826 5.89129 13.4662L8.25838 10.9807L5.89129 8.49516C5.49411 8.0781 5.49411 7.40171 5.89129 6.98528L6.1733 6.68916C6.57049 6.2721 7.21465 6.2721 7.61124 6.68916L9.97833 9.17467L12.3454 6.68916C12.7426 6.2721 13.3868 6.2721 13.7834 6.68916L14.0654 6.98528C14.4626 7.40233 14.4626 8.07873 14.0654 8.49516L11.6983 10.9807L14.0654 13.4662C14.4626 13.8832 14.4626 14.5596 14.0654 14.9761Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1077_3157">
              <rect
                width="20"
                height="21"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>

      </span>
      <span>
        Your payment method has expired. Kindly refresh your information. Open studies are currently marked as "Closed" until you update your details.
      </span>
      <span
        class="tw-px-3 tw-bg-transparent tw-border tw-cursor-pointer tw-border-white tw-border-solid tw-rounded-xl tw-min-w-fit tw-py-2"
        @click="changePaymentPlanHandler"
      >
        Update payment method
      </span>
    </div>
    <div

      class="tw-mt-10 px-20"
    >
      <p class="tw-text-2xl tw-font-semibold">
        Account information
      </p>
      <div class="tw-mt-10 tw-flex tw-space-x-5">
        <div class="tw-p-5 tw-relative  tw-w-[250px] tw-border tw-rounded-xl tw-border-solid tw-border-white-two tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-justify-between">
            <p>
              Active studies
            </p>
            <p class="tw-font-semibold tw-text-xl tw-mt-4 ">
              {{ billingData ? billingData.active_studies : 0 }}
            </p>
          </div>
          <div class="tw-rounded-full tw-absolute tw-bg-black tw-w-16 tw-h-16 -tw-bottom-1 -tw-right-2 tw-" />
          <div class="tw-rounded-full tw-absolute tw-bg-territory-light tw-w-16 tw-h-16 tw-top-0 tw-z-10 -tw-right-7" />
        </div>
        <div class="tw-p-5 tw-relative  tw-w-[250px] tw-border tw-rounded-xl tw-border-solid tw-border-white-two tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-justify-between">
            <p>
              Lifetime respondents
            </p>
            <p class="tw-font-semibold tw-text-xl tw-mt-4 ">
              {{ billingData ? billingData.lifetime_respondents.toLocaleString() : 0 }}
            </p>
          </div>
          <div class="tw-rounded-full tw-absolute tw-bg-black tw-w-16 tw-h-16 -tw-bottom-1 -tw-right-2 tw-" />
          <div class="tw-rounded-full tw-absolute tw-bg-territory-light tw-w-16 tw-h-16 tw-top-0 tw-z-10 -tw-right-7" />
        </div>
        <div class="tw-p-5 tw-relative  tw-w-[250px] tw-border tw-rounded-xl tw-border-solid tw-border-white-two tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-justify-between">
            <p>
              Lifetime budget invested
            </p>
            <p class="tw-font-semibold  tw-mt-4 ">
              <span>
                $
              </span>
              <span class="tw-text-xl">
                {{ billingData ? (billingData.lifetime_budget_invested/100).toLocaleString() : 0 }}
              </span>
            </p>
          </div>
          <div class="tw-rounded-full tw-absolute tw-bg-black tw-w-16 tw-h-16 -tw-bottom-1 -tw-right-2 tw-" />
          <div class="tw-rounded-full tw-absolute tw-bg-territory-light tw-w-16 tw-h-16 tw-top-0 tw-z-10 -tw-right-7" />
        </div>
        <div class="tw-p-5 tw-relative  tw-w-[250px] tw-border tw-rounded-xl tw-border-solid tw-border-white-two tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-justify-between">
            <p>
              Credit balance
            </p>
            <p class="tw-font-semibold  tw-mt-4 ">
              <span>
                $
              </span>
              <span class="tw-text-xl">
                {{ creditBalance.toLocaleString() }}
              </span>
            </p>
          </div>
          <div class="tw-rounded-full tw-absolute tw-bg-black tw-w-16 tw-h-16 -tw-bottom-1 -tw-right-2 tw-" />
          <div class="tw-rounded-full tw-absolute tw-bg-territory-light tw-w-16 tw-h-16 tw-top-0 tw-z-10 -tw-right-7" />
        </div>
      </div>
      <div class="tw-mt-10">
        <p class="tw-font-semibold tw-text-2xl">
          Billing
        </p>
        <div class="tw-my-5 tw-border tw-border-solid tw-border-white-two tw-w-full" />
        <div
          v-if="loadingStudiesBilling"
          class="tw-text-center tw-text-2xl tw-py-10"
        >
          Loading billing for studies...
        </div>
        <div
          v-else-if="billingData && paymentInformation"
          class="tw-flex tw-justify-start tw-space-x-3 tw-max-w-[300px] tw-items-center "
        >
          <st-dropdown-menu

            ref="filterDropDown"
            container-class=" w-full border-none tw-rounded-xl border-black"
            selected-class="tw-rounded-xl tw-border-white-two bg-white tw-py-4 "
            caption="Select month"
            :display-caption="true"
            :full-width="true"
            :initial-active-option="selectedInvoiceFilter"
            :options="invoiceFilterOptions"
            @updateOption="changeSelectedInvoice"
          />
          <span
            class="tw-cursor-pointer"
            title="Download"
            @click="exportPDF"
          >
            <svg
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="1"
                width="39"
                height="39"
                rx="19.5"
                fill="white"
              />
              <g clip-path="url(#clip0_1076_924)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 13.5C20.3867 13.5 20.6999 13.8135 20.6999 14.1999V20.9101L23.0051 18.6052C23.2784 18.3319 23.7219 18.3319 23.9952 18.6052C24.2685 18.8785 24.2685 19.3216 23.9952 19.5952L20.4953 23.0953C20.222 23.3686 19.7786 23.3686 19.5053 23.0953L16.0054 19.5952C15.7321 19.3219 15.7321 18.8785 16.0054 18.6052C16.2787 18.3319 16.7219 18.3319 16.9955 18.6052L19.3007 20.9101V14.1999C19.3007 13.8132 19.6142 13.5 20.0006 13.5H20ZM13.6999 21.9001C14.0866 21.9001 14.3998 22.2136 14.3998 22.6V23.44C14.3998 24.0398 14.4004 24.4472 14.4262 24.7625C14.4514 25.0694 14.4967 25.2263 14.5525 25.3358C14.6869 25.5992 14.9008 25.8134 15.1645 25.9475C15.274 26.0033 15.4309 26.0489 15.7378 26.0738C16.0528 26.0996 16.4605 26.1002 17.06 26.1002H22.94C23.5398 26.1002 23.9472 26.0996 24.2625 26.0738C24.5694 26.0486 24.7263 26.0033 24.8358 25.9475C25.0992 25.8134 25.3134 25.5992 25.4475 25.3358C25.5033 25.2263 25.5489 25.0694 25.5738 24.7625C25.5996 24.4475 25.6002 24.0398 25.6002 23.44V22.6C25.6002 22.2133 25.9137 21.9001 26.3001 21.9001C26.6865 21.9001 27 22.2136 27 22.6V23.4688C27 24.0323 27 24.4973 26.9691 24.8762C26.937 25.2698 26.868 25.6313 26.6949 25.9712C26.4264 26.498 25.998 26.9264 25.4715 27.1949C25.1316 27.368 24.7701 27.437 24.3765 27.4691C23.9976 27.5 23.5325 27.5 22.9691 27.5H17.0312C16.4677 27.5 16.0024 27.5 15.6238 27.4691C15.2302 27.437 14.8687 27.368 14.5288 27.1949C14.002 26.9264 13.5736 26.498 13.3051 25.9712C13.132 25.6313 13.063 25.2698 13.0309 24.8762C13 24.4973 13 24.0323 13 23.4688V22.6C13 22.2133 13.3132 21.9001 13.6999 21.9001Z"
                  fill="#474747"
                />
              </g>
              <rect
                x="0.5"
                y="1"
                width="39"
                height="39"
                rx="19.5"
                stroke="#ECECEC"
              />
              <defs>
                <clipPath id="clip0_1076_924">
                  <rect
                    width="14"
                    height="14"
                    fill="white"
                    transform="translate(13 13.5)"
                  />
                </clipPath>
              </defs>
            </svg>

          </span>
        </div>
        <!-- TABLE -->
        <div

          class="my-5"
        >
          <div
            v-if="billingData && paymentInformation && !loadingStudiesBilling"
            ref="invoiceTable"
          >
            <div class="tw-grid tw-grid-cols-5 tw-pr-2 tw-py-2 tw-text-sm tw-text-black-two tw-border-b tw-border-solid tw-border-white-two">
              <p class="tw-text-sm ">
                Study
              </p>
              <p class="tw-text-sm ">
                Start date
              </p>
              <p class="tw-text-sm ">
                Add ons
              </p>
              <p class="tw-text-sm ">
                Incentives
              </p>
              <p class="tw-text-sm tw-text-right">
                Prorated charges
              </p>
            </div>
            <div
              v-if="billingData && billingData.study_details && billingData.study_details.length > 0"
              class="tw-mt-5"
            >
              <div

                v-for="study in billingData.study_details"
                :key="study.name"
                class="tw-py-3 tw-grid tw-grid-cols-5 tw-pr-2 tw-text-black-two tw-border-b tw-border-solid tw-items-center tw-border-white-two"
              >
                <p class="tw-flex tw-flex-col tw-space-y-1">
                  <span class="tw-text-base tw-font-semibold tw-pr-4">
                    {{ study.survey.name }}
                  </span>
                  <span
                    v-if="study.base_plan"
                    class="tw-text-[13px]"
                  >
                    {{ study.base_plan.split(" ")[0] }} study
                  </span>
                </p>
                <p class="tw-text-sm ">
                  {{ convertDateToReadableFormat(study.survey.created_at) }}
                </p>
                <p class="tw-text-sm ">
                  ${{ study.add_on / 100 }}
                  <!-- Answers & Strategies -->
                </p>
                <p

                  class="tw-text-sm"
                >
                  <span v-if="study.survey.reward && study.survey.reward_type=='gift card'">
                    ${{ (Number(study.incentive_amount)/100).toLocaleString() }}
                  </span>
                </p>

                <p class="tw-text-end tw-text-sm">
                  ${{ (study.prorated_charges / 100).toLocaleString() }}
                </p>
              </div>
            </div>

            <div
              v-if="billingData && billingData.study_details.length > 0"
              class="tw-w-full tw-bg-white-one tw-flex tw-py-4 tw-justify-end tw-items-center tw-space-x-10 tw-pr-2"
            >
              <span class="tw-text-black-two tw-text-sm">
                Total charges
              </span>
              <span class="tw-font-semibold tw-text-sm">
                ${{ getTotalCharges().toLocaleString() }}
              </span>
            </div>
          </div>
          <div
            v-else-if="billingData && billingData.study_details.length == 0"
            class="tw-w-full tw-flex tw-flex-col tw-justify-end tw-items-center tw-bg-white-one tw-p-20"
          >
            <p class="tw-text-lg tw-font-semibold">
              No billing information available
            </p>
            <p>
              To access the information you must have previously created a study
            </p>
          </div>
          <div class="tw-flex tw-space-x-5 tw-py-4 tw-border-b tw-border-solid tw-border-white-two">
            <div class="tw-w-[300px]">
              <p class="tw-font-semibold">
                Billing contact
              </p>
            </div>
            <div class="tw-w-[300px]">
              <p class="tw-font-semibold">
                {{ currentUser.fullName }}
              </p>
              <p class="tw-text-sm">
                {{ currentUser.email }}
              </p>
            </div>
          </div>
          <div

            class="tw-flex tw-space-x-5 tw-py-4 tw-border-b tw-border-solid tw-border-white-two"
          >
            <div class="tw-w-[300px]">
              <p class="tw-font-semibold">
                Payment method
              </p>
            </div>
            <div v-if="loadingPaymentInformation">
              Loading payment information...
            </div>
            <div
              v-else-if="paymentInformation && !loadingPaymentInformation"
              class="tw-w-[300px]"
            >
              <p class="tw-font-semibold">
                {{ paymentInformation.brand }} ending with {{ paymentInformation.last4 }}
              </p>
              <p class="tw-text-sm">
                Expires {{ paymentInformation.expireMonth }}/{{ paymentInformation.expireYear }}
              </p>
              <span
                class="tw-text-primary hover:tw-underline tw-block tw-mt-3 tw-cursor-pointer"
                @click="changePaymentPlanHandler"
              >
                Change payment method
              </span>
            </div>
            <div
              v-else
              class="tw-flex tw-flex-col tw-space-y-2"
            >
              <span>
                No payment method available
              </span> <span
                class="tw-cursor-pointer tw-text-primary hover:tw-underline"
                @click="changePaymentPlanHandler"
              >Add payment method </span>
            </div>
          </div>
          <div class="tw-flex tw-space-x-5 tw-py-4 ">
            <div class="tw-w-[300px]">
              <p class="tw-font-semibold">
                Account created
              </p>
            </div>
            <div class="tw-w-[300px]">
              <p class="">
                Created on {{ currentUserCreatedDate }}
              </p>
            </div>
          </div>
          <div class="tw-flex tw-mt-10 tw-space-x-5 tw-py-4 ">
            <p class="tw-font-semibold tw-text-2xl">
              Account management
            </p>
          </div>
          <!-- <div class="tw-mt-10 tw-flex tw-space-x-3 tw-mb-10">
            <ah-button
              type="outlined"
              @click="showDownloadModalToggle"
            >
              Download account data
            </ah-button>
            <ah-button type="outlined">
              Cancel account
            </ah-button>
          </div> -->
        </div>

        <vue-html2pdf
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="invoicePDF"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="1300px"
          :html-to-pdf-options="{
            filename: 'invoice.pdf',
            margin: [0.4, 0, 0.4, 0],
            jsPDF: {
              unit: 'in',
              format: 'a4',
              orientation: 'portrait',
            },
          }"
          @hasDownloaded="hasDownloaded"
        >
          <section
            slot="pdf-content"
            class=""
          >
            <div class="tw-max-w-[1000px] tw-pl-10 tw-pr-60">
              <div class="tw-flex tw-space-x-2 tw-items-center tw-w-fit tw-mx-auto tw-my-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1183_1419)">
                    <path
                      opacity="0.8"
                      d="M21.3881 5.21827L18.5947 3.42763L17.7359 2.87795L14.4913 0.796864C13.7018 0.277127 12.776 0 11.8294 0C10.8827 0 9.95692 0.277127 9.16738 0.796864L5.91076 2.87795L2.25853 5.21827C1.55748 5.65374 0.981274 6.26192 0.585708 6.98395C0.190141 7.70598 -0.0113924 8.51739 0.000616489 9.33964V19.1522C-0.0126463 19.9757 0.188289 20.7886 0.583914 21.5119C0.97954 22.2353 1.55641 22.8447 2.25853 23.2808L6.40287 20.5708L23.6412 9.33964C23.654 8.5178 23.4533 7.7066 23.0585 6.98457C22.6638 6.26255 22.0884 5.65415 21.3881 5.21827Z"
                      fill="#6FA8DC"
                    />
                    <path
                      opacity="0.8"
                      d="M22.739 0.717773L18.5947 3.42775L12.8462 7.17226L1.35153 14.6613C1.34095 15.4841 1.54415 16.2956 1.94143 17.0172C2.33871 17.7389 2.9166 18.3462 3.61909 18.7803L5.00617 19.6804L6.4029 20.5757L7.26409 21.1206L10.5062 23.1993C11.297 23.7215 12.2251 24.0001 13.1742 24.0001C14.1234 24.0001 15.0514 23.7215 15.8422 23.1993L19.0868 21.1206L22.739 18.7779C23.4405 18.3433 24.0172 17.7356 24.4132 17.014C24.8093 16.2923 25.0112 15.4811 24.9994 14.6589V4.84634C25.0131 4.02252 24.8121 3.20922 24.416 2.48568C24.0198 1.76214 23.4421 1.15306 22.739 0.717773Z"
                      fill="#FE795D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1183_1419">
                      <rect
                        width="25"
                        height="24"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <span class="tw-font-semibold tw-text-lg">
                  AskHumans
                </span>
              </div>

              <p class="tw-flex tw-justify-center tw-center tw-w-full tw-py-3 tw-my-5 ">
                <span>
                  Invoice for {{ getCurrentMonth }}
                </span>
              </p>
              <div class="tw-grid tw-grid-cols-6 tw-pr-2 tw-py-2 tw-gap-2 tw-text-sm tw-text-black-two tw-border-b tw-border-solid tw-border-white-two">
                <p class="tw-text-sm tw-col-span-2 ">
                  Study
                </p>
                <p class="tw-text-sm ">
                  Start date
                </p>
                <p class="tw-text-sm ">
                  Add ons
                </p>
                <p class="tw-text-sm ">
                  Incentives
                </p>
                <p class="tw-text-sm tw-text-right">
                  Prorated charges
                </p>
              </div>
              <div
                v-if="billingData && billingData.study_details && billingData.study_details.length > 0"
                class="tw-mt-5"
              >
                <div

                  v-for="study in billingData.study_details"
                  :key="study.name"
                  class="tw-py-3 tw-grid tw-grid-cols-6 tw-pr-2 tw-gap-2 tw-text-black-two tw-border-b tw-border-solid tw-items-center tw-border-white-two"
                >
                  <p class="tw-flex tw-flex-col tw-space-y-1  tw-col-span-2 ">
                    <span class="tw-text-base tw-font-semibold tw-pr-4">
                      {{ study.survey.name }}
                    </span>
                    <span
                      v-if="study.base_plan"
                      class="tw-text-[13px]"
                    >
                      {{ study.base_plan.split(" ")[0] }} study
                    </span>
                  </p>
                  <p class="tw-text-sm tw-pr-4">
                    {{ convertDateToReadableFormat(study.survey.created_at) }}
                  </p>
                  <p class="tw-text-sm">
                    ${{ study.add_on / 100 }}
                    <!-- Answers & Strategies -->
                  </p>
                  <p

                    class="tw-text-sm"
                  >
                    <span v-if="study.survey.reward && study.survey.reward_type=='gift card'">
                      ${{ Number(study.incentive_amount)/100 }}
                    </span>
                  </p>

                  <p class="tw-text-end tw-text-sm">
                    ${{ study.prorated_charges / 100 }}
                  </p>
                </div>
              </div>

              <div class="tw-grid tw-bg-white-one tw-py-4 tw-grid-cols-5">
                <div class="tw-col-span-5 tw-text-end tw-flex tw-justify-end tw-pr-2 tw-space-x-10">
                  <span class="tw-text-black-two tw-text-sm">
                    Total charges
                  </span>
                  <span class="tw-font-semibold tw-text-sm">
                    ${{ getTotalCharges() }}
                  </span>
                </div>
              </div>
              <p class="tw-flex tw-justify-center tw-center tw-w-full tw-py-3 tw-mt-5 ">
                <span>
                  If you have any questions, please contact us at <span class="tw-text-primary"> contact@askhumans.com </span>
                </span>
              </p>
              <!-- <div
                v-if="billingData && billingData.study_details.length > 0"
                class="tw-w-full tw-bg-white-one tw-flex tw-py-4 tw-justify-end tw-items-center tw-space-x-10 tw-pr-2"
              >
                <span class="tw-text-black-two tw-text-sm">
                  Total charges
                </span>
                <span class="tw-font-semibold tw-text-sm">
                  ${{ getTotalCharges() }}
                </span> -->
            </div>
          </section>
        </vue-html2pdf>

        <st-drawer
          v-model="showDownloadModal"
          @closeModal="closingModal"
        >
          <template v-slot:modalTitle>
            <div class="flex justify-between  ">
              <span class="font-18p">
                Download account data
              </span>
            </div>
          </template>

          <template v-slot:modalActions>
            <div
              v-if="!dataDownloaded"
              class="tw-flex tw-space-x-1 tw-justify-between  tw-h-full tw-flex-col"
            >
              <div>
                <div class="tw-bg-black tw-text-white tw-px-10 tw-py-12">
                  <p class="tw-text-[30px] tw-mt-2 tw-leading-tight tw-font-semibold">
                    Download your account data
                  </p>
                  <p class="tw-mt-4">
                    Export your whole account in a zip file (.zip)
                  </p>
                </div>
                <div class="tw-px-10 tw-mt-5">
                  <p class="tw-text-black-two">
                    All of the account history, information, account’s boards (including Shareable and Private boards) will be packed into a ZIP file with any files you might have in those boards.
                  </p>
                  <p class="tw-mt-5 tw-flex tw-space-x-3 tw-items-center">
                    <span class="tw-mt-1">
                      <svg
                        width="34"
                        height="18"
                        viewBox="0 0 34 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1076_2863)">
                          <path
                            d="M24.7385 0C21.6102 0 18.6877 1.56 17 4.04C15.3123 1.56 12.3898 0 9.2615 0C4.15739 0 0 4.04 0 9C0 13.96 4.15739 18 9.2615 18C12.3898 18 15.3123 16.44 17 13.96C18.6877 16.44 21.6102 18 24.7385 18C29.8426 18 34 13.96 34 9C34 4.04 29.8838 0 24.7385 0ZM24.7385 16.4C21.7337 16.4 18.9758 14.64 17.7409 11.96L17 10.28L16.2591 11.96C15.0242 14.64 12.3075 16.4 9.2615 16.4C5.06295 16.4 1.64649 13.08 1.64649 9C1.64649 4.92 5.06295 1.6 9.2615 1.6C12.2663 1.6 15.0242 3.32 16.2591 6.04L17 7.72L17.7409 6.04C18.9758 3.36 21.6925 1.6 24.7385 1.6C27.7845 1.6 30.6659 3.52 31.8184 6.2C31.0775 5.6 30.1719 5.2 29.1017 5.2C26.8378 5.2 25.0266 7 25.0266 9.16C25.0266 11.32 26.8789 13.12 29.1017 13.12C30.0073 13.12 30.8305 12.84 31.5303 12.36C30.2542 14.76 27.7022 16.4 24.7385 16.4Z"
                            fill="black"
                          />
                          <path
                            d="M12.2663 13.1602C14.5169 13.1602 16.3414 11.3873 16.3414 9.20023C16.3414 7.01319 14.5169 5.24023 12.2663 5.24023C10.0158 5.24023 8.19128 7.01319 8.19128 9.20023C8.19128 11.3873 10.0158 13.1602 12.2663 13.1602Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1076_2863">
                            <rect
                              width="34"
                              height="18"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                    </span>
                    <span class="tw-text-territory-dark">
                      Account’s data can be exported once every 24 hours.
                    </span>
                  </p>
                  <p class="tw-mt-4 tw-flex tw-items-center tw-space-x-3">
                    <ah-toggle-switch
                      id="excludeFilesButton"
                      :checked="excludeFiles"
                      @input="changeExcludeFileHandler"
                    />
                    <span
                      class="tw-cursor-pointer"
                      @click="changeExcludeFileHandler"
                    >
                      Exclude files attached to board columns & updates for a faster export
                    </span>
                  </p>
                </div>
              </div>
              <div class="tw-px-10">
                <button

                  class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
                  @click="downloadAccountData"
                >
                  Download data
                </button>
              </div>
            </div>
            <div
              v-else
              class="tw-px-10 tw-w-full tw-h-full  tw-flex  tw-justify-center tw-items-center"
            >
              <div class="">
                <p class="tw-text-9xl tw-text-center">
                  ✔️
                </p>
                <p class="tw-mt-2 tw-font-semibold tw-text-[30px] tw-text-center">
                  Data exported successfully
                </p>
                <p class="tw-mt-2 tw-text-center">
                  A download link has been sent to your email.
                </p>
                <p class="tw-mt-2 tw-text-center">
                  Link will be valid until {{ nextDayDate }}
                </p>
              </div>
            </div>
          </template>
        </st-drawer>
        <stripe-payment-form
          ref="stripeForm"
          :type="paymentMethodType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'
import { StDropdownMenu, StripePaymentForm, AhButton, StDrawer, AhToggleSwitch } from '../../common'
import { monthNames } from '../insights/pdfs/utilities'
import paymentInformation from './payment-information.vue'
export default {
  components: {
    StDropdownMenu,
    StripePaymentForm,
    AhButton,
    StDrawer,
    AhToggleSwitch,
    VueHtml2pdf
  },
  data () {
    return {
      invoiceFilterOptions: ['This month'],
      selectedInvoiceFilter: '',
      paymentInformation: null,
      loadingPaymentInformation: false,
      showDownloadModal: false,
      paymentMethodType: 'update',
      excludeFiles: false,
      billingData: null,
      dataDownloaded: false,
      loadingStudiesBilling: false
    }
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('stripe', ['creditBalance']),

    nextDayDate () {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return `${monthNames[tomorrow.getMonth()]} ${tomorrow.getDate()}, ${tomorrow.getFullYear()}`
    },

    getCurrentMonth () {
      const today = new Date()
      return `${monthNames[today.getMonth()]} ${today.getFullYear()}`
    },

    currentUserCreatedDate () {
      const createdAt = new Date(this.currentUser.createdAt)
      return `${monthNames[createdAt.getMonth()]} ${createdAt.getDate()}, ${createdAt.getFullYear()}`
    },
    // check if the expiry of the is in the current month of the date
    isPaymentAboutToExpire () {
      console.log(this.paymentInformation)
      if (!this.paymentInformation) return false

      const today = new Date()
      const expiryDate = new Date(this.paymentInformation.expireYear, this.paymentInformation.expireMonth - 1)

      // Check if the expiry year is the same as the current year
      if (today.getFullYear() === expiryDate.getFullYear()) {
        // Check if the expiry month is the same or earlier than the current month
        return today.getMonth() >= expiryDate.getMonth()
      }

      // If the expiry year is later than the current year, it is not about to expire
      return false
    },

    // check if the payment method is expired
    isPaymentMethodExpired () {
      if (!this.paymentInformation) return false
      const today = new Date()
      const expiryDate = new Date(this.paymentInformation.expireYear, this.paymentInformation.expireMonth)
      return today > expiryDate
    }
  },
  mounted () {
    this.$store.commit('toggleFullSpinner', true)
    setTimeout(() => {
      this.checkPaymentInformation()
      this.getBillingDetails()
    }, 200)
  },

  methods: {
    hasDownloaded () {

    },
    monthsFromAccountCreated () {
      // get month names by calculating the account creation date and today date
      const today = new Date()
      const createdAt = new Date(this.currentUser.createdAt)
      const months = []
      let month = createdAt.getMonth()
      let year = createdAt.getFullYear()

      // loop through the months and add them to the array
      while (month <= today.getMonth() || year <= today.getFullYear()) {
        months.push(`${monthNames[month]} ${year}`)
        if (year === today.getFullYear() && month === today.getMonth()) break
        if (month === 11) {
          month = 0
          year++
        } else {
          month++
        }
      }

      this.invoiceFilterOptions = months
      if (this.selectedInvoiceFilter === '') {
        this.selectedInvoiceFilter = months[months.length - 1]
      }
      return months
    },

    convertDateToReadableFormat (date) {
      const d = new Date(date)
      const month = monthNames[d.getMonth()]
      const day = d.getDate()
      const year = d.getFullYear()
      return `${month} ${day}, ${year}`
    },

    changeSelectedInvoice (option) {
      this.selectedInvoiceFilter = option

      this.getBillingDetails(option.split(' ')[0].slice(0, 3), option.split(' ')[1].slice(2))
    },

    changeExcludeFileHandler () {
      this.excludeFiles = !this.excludeFiles
    },
    downloadAccountData () {
      this.dataDownloaded = true
    },
    showDownloadModalToggle () {
      this.showDownloadModal = !this.showDownloadModal
    },
    closingModal () {
      this.showDownloadModal = false
      this.dataDownloaded = false
    },
    changePaymentPlanHandler () {
      if (!this.paymentInformation) {
        this.paymentMethodType = 'add'
      }
      this.$refs.stripeForm.toggle()
    },
    getTotalCharges () {
      let total = 0
      if (this.billingData && this.billingData.study_details && this.billingData.study_details.length > 0) {
        this.billingData.study_details.forEach((study) => {
          total += study.prorated_charges + (Number(study.incentive_amount)) + study.add_on
        })
      }
      return total / 100
    },
    getBillingDetails (month = null, year) {
      let currentDateMonth = null
      let currentDateYear = null
      if (!month) {
        currentDateMonth = new Date().toLocaleString('default', { month: 'short' })
        currentDateYear = String(new Date().getFullYear()).slice(2)
      } else {
        currentDateMonth = month
        currentDateYear = year
      }
      this.loadingStudiesBilling = true
      // console.log('calling API for billing details')
      // this.$store.commit('toggleFullSpinner', true)
      this.$axios
        .request({
          method: 'post',
          data: {
            month: `${currentDateMonth.toLocaleLowerCase()}-${currentDateYear}`
          },
          url: `accounts/billing_details`
        })
        .then((res) => {
          // console.log('data fetched', res)
          this.$store.commit('toggleFullSpinner', false)
          this.loadingStudiesBilling = false
          // console.log(res.data.billing_details)
          this.monthsFromAccountCreated()
          this.billingData = res.data.billing_details
        })
        .catch((err) => {
          this.loadingStudiesBilling = false
          this.$store.commit('toggleFullSpinner', false)
          console.log(err)
        })
    },
    checkPaymentInformation () {
      this.loadingPaymentInformation = true
      this.$axios
        .request({
          method: 'get',
          url: `payments/payment_information`
        })
        .then((res) => {
          this.$store.commit('toggleFullSpinner', false)
          // console.log(res)
          const info = {}
          if (res.data.data) {
            console.log(res.data)
            info.brand = res.data.data.brand
            info.last4 = res.data.data.last4
            info.expireYear = res.data.data.exp_year
            info.expireMonth = res.data.data.exp_month
            this.paymentInformation = { ...info }
          }
          this.loadingPaymentInformation = false
        })
        .catch((err) => {
          this.loadingPaymentInformation = false
          this.$store.commit('toggleFullSpinner', false)
          console.log(err)
        })
    },
    previewPDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    exportPDF () {
      // this.isPDFDownloading = true
      this.$refs.html2Pdf.generatePdf()
    }
    // async exportToPdf () {
    //   console.log(this.$refs)
    //   const content = this.$refs.invoiceTable // Reference to the content you want to export
    //   console.log(content)
    //   const options = {
    //     margin: 10,
    //     filename: 'exported-document.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    //   }
    //   try {
    //     const pdf = await html2pdf().from(content).set(options).outputPdf()
    //     // Handle the PDF here
    //     console.log(pdf)
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }

  }
}
</script>

<style lang="scss" scoped>

</style>
