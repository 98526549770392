<template>
  <div
    class="tw-h-6 tw-px-3 tw-py-0.5  tw-rounded-full tw-flex tw-items-center tw-justify-center tw-min-w-fit"
    :class="type!=='Neutral' ? 'tw-text-white' : 'tw-text-black'"
    :style="{'background':colors[type]}"
  >
    {{ type }}
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      colors: {
        'Very Negative': '#AC5555',
        'Negative': '#F9776F',
        'Neutral': '#E9E9E9',
        'Positive': '#7ECD87',
        'Very Positive': '#667E6E'
      }

    }
  },

  mounted () {

  },

  methods: {

  }
}
</script>
