<template>
  <div class="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-screen ">
    <div
      v-if="survey.published"
      class="tw-max-w-[500px] tw-mx-auto tw-py-10 tw-my-10 tw-px-3 "
    >
      <div class="tw-text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="168"
          height="191"
          viewBox="0 0 168 191"
          fill="none"
        >
          <path
            d="M154.619 0.769409H13.3829C6.49654 0.769409 0.914062 6.35189 0.914062 13.2382V154.474C0.914062 161.36 6.49654 166.943 13.3829 166.943H62.8112L77.0283 186.662C80.4581 191.42 87.5406 191.42 90.9705 186.662L105.188 166.943H154.616C161.502 166.943 167.085 161.36 167.085 154.474V13.2382C167.085 6.35189 161.502 0.769409 154.616 0.769409H154.619Z"
            fill="#6EA474"
          />
          <path
            d="M32.9814 71.9418C37.0798 67.8433 43.7233 67.8433 47.8218 71.9418L76.1559 100.276L125.364 50.9144C129.46 46.8052 136.113 46.7999 140.215 50.9024C144.308 54.9955 144.313 61.6309 140.227 65.7307L90.9949 115.116L86.8271 119.284C80.9323 125.179 71.3768 125.179 65.4821 119.284L61.3142 115.116L32.9801 86.7821C28.8816 82.6837 28.8816 76.0402 32.9801 71.9418H32.9814Z"
            fill="white"
          />
        </svg>
      </div>
      <p class="tw-text-center tw-text-[30px] tw-font-semibold tw-my-5">
        Congratulations! Your study has been successfully created and it’s ready to be shared.
      </p>
      <div class="tw-py-5 tw-text-territory-dark tw-border-t tw-border-b tw-border-solid tw-border-white-two tw-text-center">
        <p class="tw-text-sm tw-text-black-three">
          Study name
        </p>
        <p class="tw-text-2xl">
          {{ survey.name }}
        </p>
        <!-- TYPE OF STUDY. WITH INSIGHTS -->
        <p class="tw-text-sm tw-text-black-three">
          {{ studyType }}
        </p>
      </div>
      <div class="tw-py-5 tw-text-center">
        <p class="tw-text-sm tw-text-black-three">
          Next step
        </p>
        <p>
          Share your study with your respondents and start collecting insights.
        </p>
      </div>
      <div class="tw-flex tw-justify-center tw-space-x-3">
        <ah-button @click="redirectTo('insights')">
          Go to insights
        </ah-button>
        <ah-button
          type="secondary"
          @click="redirectTo('home')"
        >
          Go to home page
        </ah-button>
      </div>
    </div>
    <div
      v-else
      class="tw-max-w-[500px] tw-mx-auto tw-py-10 tw-my-10 tw-px-3 "
    >
      <div class="tw-text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="168"
          height="191"
          viewBox="0 0 168 191"
          fill="none"
        >
          <path
            d="M12.7587 0.5H155.243C162.19 0.5 167.822 6.13182 167.822 13.079V155.563C167.822 162.51 162.19 168.142 155.243 168.142H105.378L92.3313 186.236C88.233 191.921 79.7684 191.921 75.6701 186.236L62.6239 168.142H12.7587C5.81151 168.142 0.179688 162.51 0.179688 155.563V13.079C0.179688 6.13182 5.81151 0.5 12.7587 0.5Z"
            fill="#B71E1E"
          />
          <path
            d="M128.712 40.7656C124.578 36.6309 117.876 36.6309 113.742 40.7656L85.1577 69.3501L56.5731 40.7656C52.4385 36.6309 45.7363 36.6309 41.603 40.7656C37.4683 44.9002 37.4683 51.6024 41.603 55.7371L70.1875 84.3216L41.603 112.906C37.4683 117.041 37.4696 123.743 41.603 127.878C45.7376 132.011 52.4398 132.011 56.5731 127.878L85.1577 99.2932L113.742 127.878C117.876 132.011 124.579 132.012 128.712 127.878C132.847 123.743 132.847 117.041 128.712 112.906L100.128 84.3216L128.712 55.7371C132.847 51.6024 132.847 44.9002 128.712 40.7669V40.7656Z"
            fill="white"
          />
        </svg>
      </div>
      <p class="tw-text-2xl tw-my-5 tw-text-center tw-font-semibold">
        Sorry, we were not able to process payment. Please try again.
      </p>
      <div class="tw-flex tw-flex-col tw-justify-center tw-space-y-3 tw-items-center">
        <ah-button
          @click="redirectTo('plans')"
        >
          Go back to creation
        </ah-button>
        <span class="tw-text-black-three">
          or
        </span>
        <ah-button
          type="secondary"
          @click="toggleDeleteModal"
        >
          Abandon
        </ah-button>
      </div>
    </div>
    <st-modal v-model="showDeleteModal">
      <template v-slot:modalTitle>
        <span class="font-18p font-bold">
          Delete draft
        </span>
      </template>
      <template v-slot:modalActions>
        <p class="tw-my-3 tw-w-[500px]">
          After you sure you want delete this draft? you will lose all the
          information already created. Do you wish to proceed?
        </p>
        <div class="flex space-x-2 justify-end">
          <ah-button
            type="outlined"
            @click="toggleDeleteModal"
          >
            Cancel
          </ah-button>
          <ah-button
            @click="deleteStudyHandler"
          >
            Delete
          </ah-button>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import { AhButton, StModal } from '../../common'
export default {

  components: {
    AhButton,
    StModal
  },

  props: {
    survey: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showDeleteModal: false
    }
  },

  computed: {
    studyType () {
      if (this.survey.insightType && this.survey.anonymous) {
        return 'Public Respondents w/ Public Insights'
      } else if (this.survey.insightType && !this.survey.anonymous) {
        return 'Public Respondents w/ Private Insights'
      } else if (!this.survey.insightType && this.survey.anonymous) {
        return 'Private Respondents w/ Public Insights'
      } else if (!this.survey.insightType && !this.survey.anonymous) {
        return 'Private Respondents w/ Private Insights'
      } else {
        return 'Unknown'
      }
    }
  },

  mounted () {
    // console.log(this.survey)
  },

  methods: {
    toggleDeleteModal () {
      this.showDeleteModal = !this.showDeleteModal
    },
    redirectTo (page) {
      if (page == 'home') {
        window.location = '/welcome'
      } else if (page == 'plans') {
        window.location = `/study/${this.survey.id}/plan-details`
      } else if (page == 'insights') {
        window.location = `/study/${this.survey.id}/results`
      }
    },
    deleteStudyHandler () {
      this.$axios
        .delete(`/study/${this.surveyId}`)
        .then((res) => {
          if (res.status === 200) {
            this.$emit('updateStudiesData')
            window.location.href = '/welcome'
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
