<template>
  <div
    class="tw-flex tw-items-center tw-px-4 tw-gap-2 tw-flex-wrap "
    :class="containerClass"
  >
    <button
      title="Previous"
      type="button"
      class="tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-py-0 tw-border tw-rounded-md  tw-bg-gray-100 tw-border-gray-800"
      :class="{
        'tw-opacity-80 tw-cursor-not-allowed':
          selectedPage === 1,
      }"
      :disabled="selectedPage === 1"
      @click="
        changePageHandler(
          $event,
          selectedPage - 1
        )
      "
    >
      <svg
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="tw-w-4"
      >
        <polyline points="15 18 9 12 15 6" />
      </svg>
    </button>
    <button
      v-for="range in totalPages"
      :key="range"
      type="button"
      class="tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-text-sm  tw-rounded  "
      :class="{
        'tw-bg-primary-light tw-border-primary/30 tw-text-primary tw-font-semibold tw-border tw-border-solid ':
          range === selectedPage,
        'tw-bg-gray-100 tw-text-black':
          range !== selectedPage,
      }"
      @click="changePageHandler($event, range)"
    >
      {{ range }}
    </button>

    <button
      :class="{
        'tw-opacity-70 tw-cursor-not-allowed':
          selectedPage ===
          totalPages,
      }"
      :disabled="
        selectedPage ===
          totalPages
      "
      title="Next"
      type="button"
      class="tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-py-0 tw-border tw-rounded-md  tw-bg-gray-100 tw-border-gray-800"
      @click="
        changePageHandler(
          $event,
          selectedPage + 1
        )
      "
    >
      <svg
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="tw-w-4"
      >
        <polyline points="9 18 15 12 9 6" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
      required: true
    },
    selectedPage: {
      type: Number,
      required: true
    },
    containerClass: {
      type: String,
      required: false,
      default: ''
    }
  },

  data () {
    return {

    }
  },

  mounted () {

  },

  methods: {
    changePageHandler (event, page) {
      event.stopPropagation()
      this.$emit('changePage', page)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
