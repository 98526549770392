<template>
  <st-drawer v-model="showDrawer" @closeModal="closingModal">
    <template v-slot:modalTitle>
      <div class="flex justify-between  ">
        <span class="font-18p">
          {{ title }}
        </span>
      </div>
    </template>

    <template v-slot:modalActions>
      <!-- <div class="py-7  tw-bg-[#494A3D] tw-text-white ">
        <p class="text-center text-1p4-rem tw-font-semibold">
          Spotify Vs. Apple Music
        </p>
      </div> -->

      <div
        class="tw-flex tw-space-x-1 tw-justify-between  tw-h-full tw-flex-col"
      >
        <div>
          <div class="tw-bg-black tw-text-white tw-px-10 tw-py-12">
            <p v-if="type == 'answers'">
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_45_37430)">
                  <path
                    d="M15.5293 0C14.6503 0 14.0057 0.703214 14.0057 1.58223V3.22306C8.43856 3.92628 3.98488 8.37996 3.28166 13.9471H1.64083C1.64083 13.9471 1.52363 13.9471 1.46503 13.9471C0.644612 14.0057 0 14.7089 0 15.5879C0 16.4669 0.761815 17.1115 1.64083 17.0529H3.28166C3.98488 22.62 8.43856 27.0737 14.0057 27.7769V29.4178C14.0057 30.2968 14.7089 30.9414 15.5293 30.9414C16.4083 30.9414 17.0529 30.2382 17.0529 29.4178V27.7769C22.62 27.0737 27.0737 22.62 27.7769 17.0529H29.4178C30.2968 17.0529 30.9414 16.3497 31 15.5293C31 14.6503 30.2968 13.9471 29.4764 13.9471H27.8355C27.1323 8.37996 22.6786 3.92628 17.1115 3.22306V1.58223C17.1115 0.703214 16.4083 0 15.5293 0ZM17.1115 6.38752C20.9792 7.03214 24.0265 10.0794 24.7297 14.0057H23.3233C23.3233 14.0057 23.2061 14.0057 23.1474 14.0057C22.2684 14.0057 21.6238 14.7675 21.6824 15.6465C21.6824 16.5255 22.4442 17.1701 23.3233 17.1115H24.7297C24.0851 21.0378 21.0378 24.0851 17.1115 24.7297V23.3233C17.1115 22.4442 16.4669 21.741 15.5879 21.741C14.7089 21.741 14.0643 22.4442 14.0643 23.3233V24.7297C10.1966 24.0851 7.09074 21.0378 6.44613 17.1115H7.85255C8.73157 17.1115 9.37618 16.4083 9.43478 15.5879C9.43478 14.7089 8.73157 14.0057 7.91115 14.0057H6.50473C7.14934 10.0794 10.1966 7.09074 14.1229 6.38752V7.79395C14.1229 8.67297 14.8261 9.31758 15.6465 9.31758C16.5255 9.31758 17.1701 8.61437 17.1701 7.79395V6.38752H17.1115Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_45_37430">
                    <rect width="31" height="31" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </p>
            <p v-if="type == 'strategies'">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_102_3013)">
                  <path
                    d="M24.8883 0.9375H4.1117C2.35723 0.9375 0.9375 2.35723 0.9375 4.1117V19.117C0.9375 20.8715 2.35723 22.2855 4.1117 22.2912H14.7886L21.4717 27.8605C21.6333 27.999 21.8411 28.0683 22.0488 28.0625C22.1758 28.0625 22.3028 28.0336 22.4182 27.9817C22.701 27.8259 22.8741 27.52 22.8684 27.1968V22.2912H24.8883C26.6428 22.2912 28.0567 20.8715 28.0625 19.117V4.1117C28.0625 2.35723 26.6428 0.943271 24.8883 0.9375ZM26.3311 19.117C26.3311 19.9135 25.6847 20.5598 24.8883 20.5598H22.0027C21.5236 20.5598 21.137 20.9465 21.137 21.4255V25.35L17.4664 22.2912L15.6312 20.7618C15.4696 20.6291 15.2618 20.5541 15.054 20.5598H4.08862C3.29795 20.5483 2.66888 19.9077 2.66888 19.117V4.1117C2.66888 3.31527 3.31527 2.66888 4.1117 2.66888H24.8883C25.6847 2.66888 26.3311 3.31527 26.3311 4.1117V19.117Z"
                    fill="white"
                  />
                  <path
                    d="M22.0026 7.86298H6.99728C6.51827 7.86298 6.13159 7.4763 6.13159 6.99728C6.13159 6.51827 6.51827 6.13159 6.99728 6.13159H22.0026C22.4816 6.13159 22.8683 6.51827 22.8683 6.99728C22.8683 7.4763 22.4816 7.86298 22.0026 7.86298Z"
                    fill="white"
                  />
                  <path
                    d="M22.0026 12.4802H6.99728C6.51827 12.4802 6.13159 12.0935 6.13159 11.6145C6.13159 11.1355 6.51827 10.7488 6.99728 10.7488H22.0026C22.4816 10.7488 22.8683 11.1355 22.8683 11.6145C22.8683 12.0935 22.4816 12.4802 22.0026 12.4802Z"
                    fill="white"
                  />
                  <path
                    d="M14.4999 17.0971H6.99728C6.51827 17.0971 6.13159 16.7104 6.13159 16.2314C6.13159 15.7524 6.51827 15.3657 6.99728 15.3657H14.4999C14.979 15.3657 15.3656 15.7524 15.3656 16.2314C15.3656 16.7104 14.979 17.0971 14.4999 17.0971Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_102_3013">
                    <rect
                      width="27.125"
                      height="27.125"
                      fill="white"
                      transform="translate(0.9375 0.9375)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </p>
            <p class="tw-text-[30px] tw-mt-2 tw-leading-tight tw-font-semibold">
              {{ description }}
            </p>
            <p v-if="action !== 'add'" class="tw-text-white tw-mt-3">
              However, all history related to this study will remain recorded in
              your account.
            </p>
          </div>
          <div class="tw-px-10">
            <div
              class="tw-py-4 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"
            >
              <p class="tw-text-sm tw-text-black-three">
                Study
              </p>
              <p class="tw-text-base tw-font-semibold">
                {{ study.name }}
              </p>
            </div>
            <div
              v-if="action != 'upgrade' && action != 'downgrade'"
              class="tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"
            >
              <p class="tw-text-sm tw-text-black-three">
                Add on
              </p>
              <p class="tw-text-base tw-font-semibold">
                {{ type == "answers" ? "Answers" : "Answers & Reports" }}
              </p>
              <p class="tw-text-[15px]">
                ${{ newPlan && newPlan.price.toLocaleString() }} per month
              </p>
            </div>
            <div
              v-if="action == 'upgrade' || action == 'downgrade'"
              class="tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid tw-flex tw-space-x-3 tw-justify-between"
            >
              <div>
                <p class="tw-text-sm tw-text-black-three">
                  Current Add on
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    action == "downgrade" ? "Answers & Reports" : "Answers"
                  }}
                </p>
                <p class="tw-text-[15px]">
                  ${{
                    currentAnswerStrategyPlan &&
                      currentAnswerStrategyPlan.price.toLocaleString()
                  }}
                  per month
                </p>
              </div>
              <div>
                <p class="tw-text-sm tw-text-black-three">
                  New Add on
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    action == "downgrade" ? "Answers" : "Answers & Reports"
                  }}
                </p>
                <p class="tw-text-[15px]">
                  ${{
                    currentAnswerStrategyPlan &&
                      currentAnswerStrategyPlan.price.toLocaleString()
                  }}
                  per month
                </p>
              </div>
            </div>
            <template v-if="action != 'remove'">
              <div v-if="loadingPaymentInformation">
                <p class="tw-text-center">
                  Loading payment method...
                </p>
              </div>
              <div
                v-if="paymentInformation && !loadingPaymentInformation"
                class="tw-py-3 tw-h-full tw-flex tw-justify-between tw-items-center tw-border-b tw-border-b-white-two tw-border-solid"
              >
                <div>
                  <p class="tw-text-sm tw-text-black-three">
                    Payment method
                  </p>

                  <p class="tw-text-base tw-font-semibold">
                    {{ paymentInformation.brand }} ending
                    {{ paymentInformation.last4 }}
                  </p>
                </div>
                <p
                  class="tw-text-sm tw-text-primary tw-cursor-pointer"
                  @click="changePaymentPlan"
                >
                  Change payment method
                </p>
              </div>
              <div
                v-else-if="!loadingPaymentInformation && !checkCreditBalance && !paymentInformation"
                class="tw-py-3  tw-h-full tw-flex tw-justify-end"
              >
                <p
                  class="tw-text-sm tw-text-primary tw-cursor-pointer"
                  @click="changePaymentPlan"
                >
                  Add payment method
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  Credit balance
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    creditBalance >= 0
                      ? `$${creditBalance.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  To be charged
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    proratedPrice !== null
                      ? `$${proratedPrice.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  Remaining credit balance
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    remainingCreditBalance !== null
                      ? `$${remainingCreditBalance.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  Total
                </p>
                <p class="tw-text-base tw-font-semibold">
                  {{
                    currentAmountDue >= 0
                      ? `$${currentAmountDue.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
              <div class="tw-py-3  tw-h-full ">
                <p class="tw-text-sm tw-text-black-three">
                  To be charged every month after
                </p>
                <p v-if="newPlan" class="tw-text-base tw-font-semibold">
                  {{
                    newPlan
                      ? `$${newPlan.price.toLocaleString()}`
                      : "Calculating..."
                  }}
                </p>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="!checkCreditBalance && !paymentInformation && !loadingPaymentInformation"
          class="tw-px-10"
        >
          <button
            class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
            @click="changePaymentPlan"
          >
            Add payment method
          </button>
        </div>
        <div v-else-if="!loadingPaymentInformation">
          <div class="tw-px-10" v-if="action == 'add'">
            <button
              class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
              @click="addPlanToStudy"
            >
              {{ buttonText }}
            </button>
          </div>
          <div class="tw-px-10" v-else-if="action == 'remove'">
            <button
              class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
              @click="removePlanFromStudy"
            >
              {{ buttonTextForRemoving }}
            </button>
          </div>
          <div class="tw-px-10" v-else>
            <button
              class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
              @click="upgradeDowngradeHandler"
            >
              {{ buttonTextUpgradingDowngrading }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <stripe-payment-form ref="stripeForm" :type="paymentType" />
  </st-drawer>
</template>

<script>
// import { StDrawer, SecondaryButton, ToggleButton, SentimentChip } from "./";
import StDrawer from "./st-drawer.vue";
import SecondaryButton from "./secondary-button.vue";
import ToggleButton from "./toggle-button.vue";
import SentimentChip from "./sentiment-chip.vue";
import StripePaymentForm from "./stripe-payment-form.vue";
import { mapState } from "vuex";
export default {
  components: {
    StDrawer,
    SecondaryButton,
    ToggleButton,
    StripePaymentForm,
    SentimentChip,
  },
  props: {
    type: {
      type: String,
      require: true,
      default: "",
    },
    study: {
      type: Object,
      require: true,
      default: () => {},
    },
    action: {
      type: String,
      require: false,
      default: "add",
    },
    close: {
      type: Function,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      paymentInformation: null,
      showDrawer: false,
      loadingPaymentInformation: false,
      allPlans: [],
      newPlan: null,
      currentAnswerStrategyPlan: null,
      proratedPrice: null,
      remainingCreditBalance: null,
      paymentType: "update",
    };
  },
  computed: {
    ...mapState("stripe", ["creditBalance"]),
    currentAmountDue() {
      return this.totalAmount();
    },
    checkCreditBalance() {
      return this.totalAmount() > 0 ? false : true;
    },
    title() {
      return this.type == "answers"
        ? "Add Answers:"
        : "Add Answers & Reports:";
    },
    description() {
      if (this.type == "answers" && this.action == "add") {
        return "Generate answers that will let you understand your studies and get better insights";
      } else if (this.type == "answers" && this.action == "remove") {
        return "After removing Answers, this study will not have access to this feature.";
      } else if (
        (this.type == "strategies" && this.action == "add") ||
        (this.type == "strategies" && this.action == "upgrade")
      ) {
        return "Perform a better analysis of your studies through our reports resources";
      } else if (this.type == "strategies" && this.action == "remove") {
        return "After removing Strategies, this study will not have access to this feature.";
      } else if (this.type == "answers" && this.action == "downgrade") {
        return "After downgrading, you will no longer have access to reports.";
      }
    },
    buttonText() {
      return this.type == "answers" ? "Add answers" : "Add reports";
    },
    buttonTextForRemoving() {
      return this.type == "answers" ? "Remove answers" : "Remove reports";
    },
    buttonTextUpgradingDowngrading() {
      return this.action == "upgrade"
        ? "Upgrade to answers & reports"
        : "Downgrade to answers";
    },
  },
  mounted() {
    if (this.action == "downgrade") {
      this.currentAnswerStrategyPlan = this.study.plans.find((plan) => {
        return plan.title.toLowerCase().includes("strategies");
      });
    }
    if (this.action == "upgrade") {
      this.currentAnswerStrategyPlan = this.study.plans.find((plan) => {
        return plan.title.toLowerCase().includes("answers");
      });
    }

    setTimeout(() => {
      this.getAllPlans();
      this.checkPaymentInformation();
    }, 100);
    setTimeout(() => {
      this.getAddonPlanForStudy();
    }, 1500);
  },
  // watch for type to change
  watch: {
    type: function(val) {
      this.getAddonPlanForStudy();
    },
  },
  methods: {
    totalAmount(){
      const totalAmount = this.creditBalance - this.proratedPrice;
      return totalAmount > 0 ? 0 : Math.abs(totalAmount);
    },
    closingModal() {
      this.$emit("close");
    },
    upgradeDowngradeHandler() {
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "post",
          url: "payments/update_subscription",
          data: {
            plan_id: [this.newPlan?.id],
            survey_id: this.study.id,
          },
        })
        .then((res) => {
          // console.log(res);
          this.showDrawer = false;
          // this.$store.commit("toggleFullSpinner", false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleFullSpinner", false);
        });
    },
    removePlanFromStudy() {
      const currentAnswerStrategyPlan = this.study.plans.find((plan) => {
        return plan.title.toLowerCase().includes(this.type);
      });
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "post",
          url: `payments/cancel_subscription`,
          data: {
            survey_id: this.study.id,
            price_id: currentAnswerStrategyPlan.priceId,
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.showDrawer = false;
          window.location.reload();
          // this.$store.commit("toggleFullSpinner", false);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleFullSpinner", false);
        });
    },
    checkPaymentInformation() {
      this.loadingPaymentInformation = true;
      this.$axios
        .request({
          method: "get",
          url: `payments/payment_information`,
        })
        .then((res) => {
          // this.$store.commit('toggleFullSpinner', false)
          // console.log(res)
          if (!res.data.data) {
            this.loadingPaymentInformation = false;
            this.paymentType = "add";
            return;
          }
          const info = {};
          info.brand = res.data.data.brand;
          info.last4 = res.data.data.last4;
          info.expireYear = res.data.data.exp_year;
          info.expireMonth = res.data.data.exp_month;
          this.paymentInformation = { ...info };
          this.loadingPaymentInformation = false;
        })
        .catch((err) => {
          this.loadingPaymentInformation = false;
          this.$store.commit("toggleFullSpinner", false);
          console.log(err);
        });
    },

    populateProratedCharges() {
      // this.filteredPlan.forEach(plan => {
      // console.log("this is the new plan", this.newPlan)
      this.$axios
        .request({
          method: "post",
          url: "payments/calculate_upcoming_invoice",
          data: {
            plan_id: [this.newPlan?.id],
          },
        })
        .then((res) => {
          const { upcoming_invoice : upcomingInvoice } = res.data;
          this.proratedPrice = upcomingInvoice.prorated_price;
          this.remainingCreditBalance = upcomingInvoice.remaining_credit_balance;
        })
        .catch((err) => {
          console.log(err);
        });
      // })
    },
    getAllPlans() {
      this.$axios
        .request({
          method: "get",
          url: `plans`,
        })
        .then(({ data }) => {
          this.allPlans = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAddonPlanForStudy() {
      // console.log(this.allPlans)
      const plansForCurrentStudy = this.allPlans.filter((plan) => {
        return plan.plan_type == this.study.plans[0].planType;
      });

      const planToAddUpgrade = plansForCurrentStudy.find((plan) => {
        return plan.title.toLowerCase().includes(this.type);
      });
      //  console.log(planToAddUpgrade, "new plan", this.type, plansForCurrentStudy)
      this.newPlan = planToAddUpgrade;
      this.populateProratedCharges();
      // if(this.type== 'answers'){
      //   return
      // }
      // else{
      //   this.newPlan = planToAddUpgrade[1]
      //   return
      // }
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    changePaymentPlan() {
      this.$refs.stripeForm.toggle();
    },
    addPlanToStudy() {
      this.$store.commit("toggleFullSpinner", true);
      this.$axios
        .request({
          method: "post",
          url: `payments/create_subscription`,
          data: {
            plan_id: this.newPlan?.id,
            survey_id: this.study.id,
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.showDrawer = false;
          // this.$store.commit("toggleFullSpinner", false);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleFullSpinner", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
