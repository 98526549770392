<template>
  <div
    class="tw-h-full tw-w-full "
  >
    <st-toast-notification
      v-if="showToast"
      class="st-toast-notification"
      :kind="toastKind"
      :title="toastTitle"
      :caption="toastCaption"
      @click="showToast = false"
    />
    <div
      class="tw-border-t tw-border-y-white-two tw-border-solid  tw-justify-between tw-pt-1 tw-items-center bg-insights-bar md:tw-bg-none"
    >
      <div class="bx--grid ">
        <div class="bx--row tw-w-full">
          <div
            class="tw-w-full  tw-flex  tw-px-2 tw-justify-between tw-text-base tw-py-3"
          >
            <div class="tw-flex tw-space-x-2 tw-items-end ">
              <div class="tw-gap-2 tw-flex-col">
                <div class="tw-flex tw-space-x-1 tw-items-center">
                  <div class="tw-font-semibold tw-text-lg tw-flex">
                    <span class="tw-pr-2">
                      {{ strategy && strategy.name }}
                    </span>
                    <div class="tw-relative tw-w-[180px] tw-items-center tw-flex">
                      <input
                        v-if="selectedStrategyVersion && selectedStrategyVersion.name !== 'initial version'"
                        v-model="selectedVersionName"
                        type="text"
                        class="!tw-max-w-[400px] tw-w-4/5 tw-rounded-md hover-tw-rounded-md hover:tw-border-l-black hover:tw-border-t-2 hover:tw-border-t-black hover:tw-border-r-2 hover:tw-border-r-black hover:tw-border-b-2 hover:tw-border-b-black tw-overflow-ellipsis tw-px-2 tw-py-1 tw-border-t-transparent tw-border-r-transparent tw-border-b-transparent tw-border-l-[2px] tw-text-[15px] tw-border-white-three tw-ml-1 tw-font-normal tw-border-solid"
                        @keydown.enter.prevent="renameVersion"
                      >
                      <!-- {{ selectedStrategyVersion && selectedStrategyVersion.name !== 'initial version' ? `${selectedStrategyVersion.name}` : '' }} -->
                      <div
                        v-if="isOwner && selectedStrategyVersion.name !== 'initial version'"
                        class="tw-flex tw-items-end tw-w-1/5  tw-justify-end tw-cursor-pointer"
                        title="Add or remove from favorite"
                      >
                        <svg
                          v-if="!isFavourite"
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"

                          @click="addOrRemoveFavourite"
                        >
                          <path
                            d="M20.1451 9.54L15.3051 9.12L13.4151 4.67C13.0751 3.86 11.9151 3.86 11.5751 4.67L9.68507 9.13L4.85507 9.54C3.97507 9.61 3.61507 10.71 4.28507 11.29L7.95507 14.47L6.85507 19.19C6.65507 20.05 7.58507 20.73 8.34507 20.27L12.4951 17.77L16.6451 20.28C17.4051 20.74 18.3351 20.06 18.1351 19.2L17.0351 14.47L20.7051 11.29C21.3751 10.71 21.0251 9.61 20.1451 9.54ZM12.4951 15.9L8.73507 18.17L9.73507 13.89L6.41507 11.01L10.7951 10.63L12.4951 6.6L14.2051 10.64L18.5851 11.02L15.2651 13.9L16.2651 18.18L12.4951 15.9Z"
                            fill="#C9C9C9"
                          />
                        </svg>
                        <svg
                          v-else
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          @click="addOrRemoveFavourite"
                        >
                          <path
                            d="M12.0234 17.7698L16.1734 20.2798C16.9334 20.7398 17.8634 20.0598 17.6634 19.1998L16.5634 14.4798L20.2334 11.2998C20.9034 10.7198 20.5434 9.61977 19.6634 9.54977L14.8334 9.13977L12.9434 4.67977C12.6034 3.86977 11.4434 3.86977 11.1034 4.67977L9.21343 9.12977L4.38343 9.53977C3.50343 9.60977 3.14343 10.7098 3.81343 11.2898L7.48343 14.4698L6.38343 19.1898C6.18343 20.0498 7.11343 20.7298 7.87343 20.2698L12.0234 17.7698Z"
                            fill="#DCAD05"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tw-flex tw-space-x-2 tw-items-center">
                  <p
                    class="tw-flex tw-text-[15px] tw-text-black-two tw-divide-black-two tw-divide-solid tw-divide-x-[1px] tw-text-sm"
                  >
                    <span class="tw-pr-2">
                      {{ removeCamelCase(strategy.framework) }} {{ strategy.framework === 'SWOT' ? 'Analysis' : '' }}
                    </span>
                    <span class="tw-px-2">
                      {{ getReadableDate(strategy.createdAt) }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="selectedStrategyVersion && isReportAvailable && !isEditingMode && isOwner"
              class="tw-flex tw-space-x-3 tw-items-center"
            >
              <!-- <ah-button
                v-if="selectedDropdownVersion !== 'initial version'"
                type="secondary"
                @click="showSaveAsNewModal"
              >
                Save as new report
              </ah-button> -->
              <ah-button
                v-if="selectedDropdownVersion !== 'initial version'"
                id="go-back-to-report-view"
                type="secondary"
                @click="redirectToInitialVersion"
              >
                Go back to report
              </ah-button>
              <div
                v-if="!isEditingMode"
                class="tw-flex tw-space-x-3 tw-items-center"
              >
                <div class="tw-flex tw-space-x-3 tw-items-center tw-relative">
                  <template v-if="isOwner && selectedStrategyVersion ">
                    <!-- <button
                      v-if="!compareChanges"
                      class="tw-flex tw-bg-white tw-relative tw-min-w-fit tw-justify-between  tw-py-2 tw-rounded-xl tw-px-3 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                      @click="showVersions('selected')"
                    >
                      <span
                        class=""
                        :class="{
                          'tw-font-semibold': selectedStrategyVersion.name === 'initial version'
                        }"
                      >
                        {{ selectedStrategyVersion.name =='initial version' ? strategy.name : selectedStrategyVersion.name }}
                      </span>
                      <span class="tw-text-end tw-block tw-pt-1">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_930_2517)">
                            <path
                              d="M8.50709 10.9945C8.33286 10.9945 8.16163 10.9308 8.03546 10.815L4.19334 7.10336C3.92899 6.85148 3.92899 6.44036 4.19034 6.18559C4.45168 5.93081 4.87825 5.93081 5.14261 6.18269L8.51009 9.43978L11.8746 6.18269C12.1479 5.94239 12.5715 5.96266 12.8208 6.22612C13.0522 6.47221 13.0522 6.84859 12.8208 7.09468L8.97872 10.815C8.85255 10.9308 8.68132 10.9974 8.50709 10.9945Z"
                              fill="#474747"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_930_2517">
                              <rect
                                width="5"
                                height="9"
                                fill="white"
                                transform="translate(12.9951 5.99414) rotate(90)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                      </span>
                      <div
                        v-if="showStrategyVersions && allStrategyVersions.length"
                        class="flex flex-col justify-center !tw-text-sm !tw-min-w-fit !tw-left-auto tw-right-0 !tw-top-10 cursor-pointer absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
                      >
                        <template
                          v-for="(version, index) in allStrategyVersions"
                        >
                          <span
                            v-if="version.name!==selectedDropdownVersion"
                            :key="`version_${index}`"
                            class="!tw-p-2 tw-truncate !tw-min-w-fit tw-flex !tw-flex-col "

                            @click="
                              changeSelectedVersion($event, version)
                            "
                          >
                            <strong
                              :class="
                                version.name === 'initial version' ? '!tw-font-semibold': '!tw-font-normal'
                              "
                            >

                              {{ version.name =='initial version' ? strategy.name : version.name }}
                            </strong>
                            <strong
                              v-if="version.name !='initial version'"
                              class="!tw-text-left !tw-font-normal tw-text-xs "
                            >
                              - {{ getReadableDate(version.createdAt) }}
                            </strong>
                          </span>
                        </template>
                      </div>
                    </button> -->
                    <div
                      v-if="allStrategyVersions.length > 2 && selectedStrategyVersion.name !== 'initial version'"
                      id="compare-changes-view"
                      class="tw-flex tw-text-sm tw-space-x-2 tw-items-center"
                    >
                      <span>
                        Compare
                      </span>
                      <ah-toggle-switch
                        id="compare-toggle"
                        class="tw-ml-2"
                        :checked="compareChanges"
                        @input="toggleCompareChanges"
                      />
                      <div
                        v-if="compareChanges"
                        id="show-changes-view"
                        class="tw-min-w-fit tw-flex tw-items-center"
                      >
                        <span>
                          Show changes
                        </span>
                        <ah-toggle-switch
                          id="show-toggle-first"
                          class="tw-ml-2"
                          :checked="showChanges"
                          @input="toggleShowChanges"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <ah-button
                v-if="selectedDropdownVersion === 'initial version' && isOwner && selectedStrategyVersion.feedbacks.length"
                @click="showGenerateVersionModal"
              >
                Generate version
              </ah-button>
            </div>

            <div
              v-else-if="isEditingMode && isOwner && isReportAvailable"
              class="tw-flex tw-space-x-3 tw-items-center"
            >
              <ah-button
                type="outlined"
                @click="cancelEditingMode"
              >
                Cancel
              </ah-button>
              <ah-button @click="saveNewStrategyDescription">
                Save changes
              </ah-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tw-bg-white-one tw-w-full tw-h-[calc(100vh-155px)]  tw-flex tw-justify-between"
      >
        <!-- Strategy report details starts here -->
        <div
          v-if="!compareChanges"
          class="tw-h-full tw-flex tw-w-full tw-py-7"
        >
          <div class="tw-flex tw-w-full  tw-justify-center  tw-max-h-full tw-mx-auto">
            <div class="tw-w-full tw-max-w-3xl ">
              <div class="tw-flex">
                <div
                  v-if="isReportAvailable && !isEditingMode"
                  :class="{
                    'tw-justify-between': isOwner && allStrategyVersions.length > 1,

                  }"
                  class="tw tw-flex tw-gap-3 tw-w-full tw-items-center  tw-py-1 tw-mb-3 tw-justify-end"
                >
                  <button
                    v-if="!compareChanges && isOwner && allStrategyVersions.length > 1"
                    class="tw-flex tw-bg-white tw-relative tw-min-w-fit tw-justify-between  tw-py-2 tw-rounded-xl tw-px-3 tw-items-center  tw-border tw-border-white-two tw-border-solid "
                    @click="showVersions('selected')"
                  >
                    <span
                      class=""
                      :class="{
                        'tw-font-semibold': selectedStrategyVersion.name === 'initial version'
                      }"
                    >
                      {{ selectedStrategyVersion.name =='initial version' ? strategy.name : selectedStrategyVersion.name }}
                    </span>
                    <span
                      v-if="allStrategyVersions.length > 1"
                      class="tw-text-end tw-block tw-pt-1"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_930_2517)">
                          <path
                            d="M8.50709 10.9945C8.33286 10.9945 8.16163 10.9308 8.03546 10.815L4.19334 7.10336C3.92899 6.85148 3.92899 6.44036 4.19034 6.18559C4.45168 5.93081 4.87825 5.93081 5.14261 6.18269L8.51009 9.43978L11.8746 6.18269C12.1479 5.94239 12.5715 5.96266 12.8208 6.22612C13.0522 6.47221 13.0522 6.84859 12.8208 7.09468L8.97872 10.815C8.85255 10.9308 8.68132 10.9974 8.50709 10.9945Z"
                            fill="#474747"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_930_2517">
                            <rect
                              width="5"
                              height="9"
                              fill="white"
                              transform="translate(12.9951 5.99414) rotate(90)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                    </span>
                    <div
                      v-if="showStrategyVersions && allStrategyVersions.length"
                      class="flex flex-col justify-center !tw-text-sm !tw-min-w-fit !tw-right-auto tw-left-0 !tw-top-10 cursor-pointer absolute bg-white shadow-3p share-popup tw-z-[100] !tw-text-left"
                    >
                      <template
                        v-for="(version, index) in allStrategyVersions"
                      >
                        <span
                          v-if="version.name!==selectedDropdownVersion"
                          :key="`version_${index}`"
                          class="!tw-p-2 tw-truncate !tw-min-w-fit tw-flex !tw-flex-col "

                          @click="
                            changeSelectedVersion($event, version)
                          "
                        >
                          <strong
                            :class="
                              version.name === 'initial version' ? '!tw-font-semibold': '!tw-font-normal'
                            "
                          >

                            {{ version.name =='initial version' ? strategy.name : version.name }}
                          </strong>
                          <strong
                            v-if="version.name !='initial version'"
                            class="!tw-text-left !tw-font-normal tw-text-xs "
                          >
                            - {{ getReadableDate(version.createdAt) }}
                          </strong>
                        </span>
                      </template>
                    </div>
                  </button>
                  <span
                    v-if="!isOwner"
                    class="tw-bg-white tw-p-2 tw-cursor-pointer tw-rounded-full tw-relative tw-flex tw-items-center"
                    title="Copy"
                    @click="copyStrategy"
                  >
                    <span
                      v-if="copied"
                      class="tw-flex tw-space-x-2  tw-bg-white tw-px-2 tw-py-1 tw-rounded-md tw-text-black tw-ml-6 tw-items-center tw-absolute"
                    >
                      Copied
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="tw-w-4 tw-h-4 tw-ml-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>

                    </span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_370_1059)">
                        <path
                          d="M2.59618 4.45565H4.31604V1.69632C4.31604 1.22931 4.50947 0.805032 4.82117 0.497835C5.13261 0.190638 5.56336 0 6.03722 0H12.2791C12.7529 0 13.1834 0.190638 13.4951 0.497835C13.8068 0.805032 14.0003 1.22931 14.0003 1.69632V7.84803C14.0003 8.31504 13.8063 8.73932 13.4951 9.04651C13.1834 9.35371 12.7529 9.54435 12.2791 9.54435H10.5592V12.3037C10.5592 12.7707 10.3658 13.195 10.0541 13.5022C9.74239 13.8094 9.3119 14 8.83804 14H2.59618C2.12232 14 1.69183 13.8094 1.38013 13.5022C1.06843 13.195 0.875 12.7707 0.875 12.3037V6.15197C0.875 5.68496 1.06843 5.26068 1.38013 4.95349C1.69183 4.64629 2.12232 4.45565 2.59618 4.45565ZM5.27795 4.45565H8.83804C9.3119 4.45565 9.74239 4.64629 10.0541 4.95349C10.3658 5.26068 10.5592 5.68496 10.5592 6.15197V8.59634H12.2791C12.4875 8.59634 12.6773 8.5119 12.8147 8.37617C12.9524 8.24044 13.0381 8.05343 13.0381 7.84829V1.69658C13.0381 1.49117 12.9524 1.30416 12.8147 1.16818C12.677 1.03245 12.487 0.948011 12.2791 0.948011H6.03722C5.82881 0.948011 5.63905 1.03245 5.50134 1.16818C5.36362 1.3039 5.27795 1.49117 5.27795 1.69658V4.45591V4.45565ZM4.79936 5.40366H2.59565C2.38724 5.40366 2.19749 5.4881 2.06003 5.62383C1.92232 5.75956 1.83664 5.94657 1.83664 6.15223V12.3039C1.83664 12.5093 1.92232 12.6964 2.06003 12.8321C2.19775 12.9678 2.3875 13.0522 2.59618 13.0522H8.83804C9.04646 13.0522 9.23621 12.9678 9.37392 12.8321C9.51164 12.6964 9.59732 12.5093 9.59732 12.3039V6.15223C9.59732 5.94683 9.51164 5.75982 9.37392 5.62383C9.23621 5.4881 9.04646 5.40366 8.83804 5.40366H4.79936Z"
                          fill="#747474"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_370_1059">
                          <rect
                            width="13.125"
                            height="14"
                            fill="white"
                            transform="translate(0.875)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                  </span>
                  <div
                    v-else
                    class="tw-relative"
                  >
                    <span
                      class="tw-bg-white tw-p-1.5 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-full"
                      @click="toggleDownloadMenus"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="tw-w-5 tw-h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                        />
                      </svg>

                    </span>
                    <div
                      v-if="showDownloadMenus"
                      class="tw-absolute tw-left-10 tw-top-0 tw-flex tw-flex-col tw-space-y-3 tw-justify-center tw-items-center"
                    >
                      <span
                        v-if="isOwner && selectedStrategyVersion.name === 'initial version' && !selectedStrategyVersion.sharedUsers.length"
                        class="tw-bg-white tw-p-2 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-rounded-full"
                        title="Edit"
                        @click="showEditingMode()"
                      >
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_370_1032)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.14375 12.25H11.375V10.8281H5.56562L4.14375 12.25ZM7.46484 2.65234L0 10.1172V12.25H2.13281L9.59766 4.78516L7.46484 2.65234ZM11.375 3.00781C11.375 1.83009 10.4203 0.875 9.24219 0.875L8.09717 2.02042L10.23 4.15323L11.375 3.00781Z"
                              fill="#747474"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_370_1032">
                              <rect
                                width="11.375"
                                height="11.375"
                                fill="white"
                                transform="translate(0 0.875)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                      </span>
                      <span
                        class="tw-bg-white tw-p-2 tw-cursor-pointer tw-rounded-full tw-relative tw-flex tw-items-center"
                        title="Copy"
                        @click="copyStrategy"
                      >
                        <span
                          v-if="copied"
                          class="tw-flex tw-space-x-2  tw-bg-white tw-px-2 tw-py-1 tw-rounded-md tw-text-black tw-ml-6 tw-items-center tw-absolute"
                        >
                          Copied
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="tw-w-4 tw-h-4 tw-ml-1"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m4.5 12.75 6 6 9-13.5"
                            />
                          </svg>

                        </span>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_370_1059)">
                            <path
                              d="M2.59618 4.45565H4.31604V1.69632C4.31604 1.22931 4.50947 0.805032 4.82117 0.497835C5.13261 0.190638 5.56336 0 6.03722 0H12.2791C12.7529 0 13.1834 0.190638 13.4951 0.497835C13.8068 0.805032 14.0003 1.22931 14.0003 1.69632V7.84803C14.0003 8.31504 13.8063 8.73932 13.4951 9.04651C13.1834 9.35371 12.7529 9.54435 12.2791 9.54435H10.5592V12.3037C10.5592 12.7707 10.3658 13.195 10.0541 13.5022C9.74239 13.8094 9.3119 14 8.83804 14H2.59618C2.12232 14 1.69183 13.8094 1.38013 13.5022C1.06843 13.195 0.875 12.7707 0.875 12.3037V6.15197C0.875 5.68496 1.06843 5.26068 1.38013 4.95349C1.69183 4.64629 2.12232 4.45565 2.59618 4.45565ZM5.27795 4.45565H8.83804C9.3119 4.45565 9.74239 4.64629 10.0541 4.95349C10.3658 5.26068 10.5592 5.68496 10.5592 6.15197V8.59634H12.2791C12.4875 8.59634 12.6773 8.5119 12.8147 8.37617C12.9524 8.24044 13.0381 8.05343 13.0381 7.84829V1.69658C13.0381 1.49117 12.9524 1.30416 12.8147 1.16818C12.677 1.03245 12.487 0.948011 12.2791 0.948011H6.03722C5.82881 0.948011 5.63905 1.03245 5.50134 1.16818C5.36362 1.3039 5.27795 1.49117 5.27795 1.69658V4.45591V4.45565ZM4.79936 5.40366H2.59565C2.38724 5.40366 2.19749 5.4881 2.06003 5.62383C1.92232 5.75956 1.83664 5.94657 1.83664 6.15223V12.3039C1.83664 12.5093 1.92232 12.6964 2.06003 12.8321C2.19775 12.9678 2.3875 13.0522 2.59618 13.0522H8.83804C9.04646 13.0522 9.23621 12.9678 9.37392 12.8321C9.51164 12.6964 9.59732 12.5093 9.59732 12.3039V6.15223C9.59732 5.94683 9.51164 5.75982 9.37392 5.62383C9.23621 5.4881 9.04646 5.40366 8.83804 5.40366H4.79936Z"
                              fill="#747474"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_370_1059">
                              <rect
                                width="13.125"
                                height="14"
                                fill="white"
                                transform="translate(0.875)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                      </span>
                      <download-strategy-report
                        v-if="isOwner && isReportAvailable"
                        :version="selectedStrategyVersion"
                        :type="strategy.framework"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <p
                v-if="!isReportAvailable"
                class="tw-bg-white tw-p-8 tw-rounded-xl tw-text-xl tw-font-semibold"
              >
                We are still processing the analysis on the report. Please come back later.
              </p>
              <div
                v-else

                class="tw-bg-white tw-p-8 tw-relative tw-rounded-xl tw-overflow-x-hidden scrollbar-thin tw-overflow-y-auto scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black  tw-h-[calc(100vh-260px)]"
              >
                <report-renderer
                  ref="strategyDescription"
                  :version-description="selectedStrategyVersion.description"
                  :type="strategy.framework"
                  :is-editing-mode="isEditingMode"
                  :save-report-changes="saveChangesOnBlur"
                  :feedback-time-expired="feedbackTimeExpired"
                  :show-section-feedback="selectedStrategyVersion.name === 'initial version'"
                  @feedback-submitted="feedbackSubmitted"
                  @save-report-changes="saveChangesOnBlur"
                />
              </div>
            </div>
          </div>
        </div>
        <report-view
          v-else-if="compareChanges"
          :is-report-available="isReportAvailable"
          :first-version="selectedStrategyVersion"
          :second-version="selectedVersionToCompare"
          :is-selected="selectedVersionToCompare.name == 'Select version'? false: true"
          :strategy="strategy"
          :current-user="currentUser"
          :show-changes="showChanges"
          :all-strategy-versions="allStrategyVersions"
          @changeTab="changeTab"
          @changeSelectedVersionToCompare="changeSelectedVersionToCompare"
        />

        <!-- Strategy report details ends here -->

        <!-- RIGHT SIDEBAR STARTS HERE -->
        <template v-if="isReportAvailable">
          <div
            v-if="!compareChanges"
            class="tw-w-[570px] tw-max-h-full tw-flex tw-h-[calc(100vh-155px)] tw-overflow-y-auto tw-overflow-x-hidden scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black  tw-flex-col  tw-bg-white"
            :class="isEditingMode ? 'tw-blur-sm tw-pointer-events-none': null"
          >
            <!-- TO SHOW STUDIES IN A STRATEGY -->

            <strategy-studies
              v-if="showStrategyStudies"
              :strategy="strategy"
              :close-handler="hideStrategyStudies"
              :sharing-handler="showInvitationWithNote"
              @changeTab="changeTab"
            />

            <!-- IF THE USER IS THE CREATOR OF THE STUDY THEN SHOW CREATOR VIEW -->
            <creator-view
              v-else-if="isOwner && showOwnerView"
              ref="creatorView"
              :strategy="strategy"
              :version="selectedStrategyVersion"
              :sharing-handler="showInvitationWithNote"
              @changeTab="changeTab"
            />

            <!-- IF THE USER IS THE COLLEAGUE OF THE STUDY THEN SHOW COLLEAGUE VIEW -->
            <colleague-view
              v-else-if="!isOwner && showColleaguesView"
              ref="colleagueView"
              :version="selectedStrategyVersion"
              :strategy="strategy"
            />
            <!-- AFTER INVITATION.. WAITING FOR RESPONSE -->
            <template v-else-if="invited && !isSharingOpen && !showStrategyStudies">
              <!-- <div
              class="tw-flex tw-justify-between  tw-px-8 tw-items-center tw-py-3 tw-border-y tw-border-solid tw-border-white-two"
            >
              <shared-with :users="selectedStrategyVersion.sharedUsers" />

              <div class="tw-flex tw-space-x-2">
                <ah-button
                  type="outlined"
                  @click="showInvitationWithNote"
                >
                  Share
                </ah-button>
              </div>
            </div> -->
              <div
                v-if="selectedStrategyVersion.sharedUsers.length && !isSharingOpen && isOwner"
                class="tw-flex tw-flex-col tw-justify-center tw-flex-1  tw-overflow-y-auto"
              >
                <creator-view

                  :strategy="strategy"
                  :version="selectedStrategyVersion"
                  :sharing-handler="showInvitationWithNote"
                  @changeTab="changeTab"
                />
              <!-- <share-colleague-view
                :share-handler="showInvitationWithNote"
                text="Nice job! We are waiting for your colleagues to provide their comments."
              /> -->
              </div>
            </template>

            <!-- FOR INVITE PEOPLE TO JOIN -->
            <template v-else-if="isSharingOpen && isOwner && !showInitialView && !invited">
              <share-strategy
                :close-handler="hideSharingScreen"
                :shared-handler="userInvitedHandler"
                :strategy="strategy"
                :version="selectedStrategyVersion"
              />
            </template>
            <template v-if="showStaticFeedbacks && !showOwnerView">
              <feedback-static-view
                :strategy="strategy"
                :version="selectedStrategyVersion"
                :current-user="currentUser"
              />
            </template>

            <!-- FOR EMPTY SCREEN WHERE YOU CAN INVITE PEOPLE TO JOIN -->
            <template v-if="!invited && showInitialView && !isSharingOpen && !showStrategyStudies ">
              <div
                id="sharing-view"
                class="tw-flex tw-justify-between tw-bg-white-two  tw-px-8 tw-items-center tw-py-3 tw-border-y tw-border-solid tw-border-white-two"
              >
                <div
                  class="tw-flex tw-space-x-3 tw-text-sm tw-justify-center tw-items-center "
                >
                  <span class="tw-mt-1">
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 0C12.14 0 15.5 3.36 15.5 7.5C15.5 11.64 12.14 15 8 15C3.86 15 0.5 11.64 0.5 7.5C0.5 3.36 3.86 0 8 0ZM8 1C4.415 1 1.5 3.91 1.5 7.5C1.5 11.09 4.415 14 8 14C11.585 14 14.5 11.085 14.5 7.5C14.5 3.915 11.585 1 8 1ZM7.5 3C7.5 2.725 7.725 2.5 8 2.5C8.275 2.5 8.5 2.725 8.5 3V6.92C8.5 7.1 8.595 7.265 8.75 7.355L11.745 9.065C11.985 9.2 12.07 9.51 11.93 9.745C11.795 9.985 11.485 10.07 11.25 9.93L8.255 8.22C7.79 7.955 7.5 7.455 7.5 6.92V3Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <span>
                    Feedback time not set
                  </span>
                </div>
                <div
                  id="share"
                  class="tw-flex tw-space-x-2"
                >
                  <ah-button
                    type="outlined"
                    @click="
                      showInvitationWithNote
                    "
                  >
                    Share
                  </ah-button>
                </div>
              </div>
              <share-colleague-view
                :version="selectedStrategyVersion"
                :share-handler="showInvitationWithNote"
                :feedback-given="showFeedbacksView"
                text="Share this report with your colleagues and generate new
                    results based on real feedback"
              />
            </template>
          </div>
        </template>
        <!-- RIGHT SIDEBAR ENDS HERE -->
      </div>
    </div>

    <st-modal
      v-model="processFeedbackModal"
      @closeModal="processFeedbackModal = false"
    >
      <p class="tw-text-lg tw-py-3 tw-font-semibold">
        Generate version
      </p>
      <template v-slot:modalActions>
        <div
          v-if="!feedbackTimeExpired"
          class="tw-text-black-two !tw-leading-6"
        >
          The deadline established for a response on this report has not yet
          expired. Do you wish to proceed? If you accept, the remaining users
          will not be able to comment on it.
        </div>
        <div
          v-else
          class="tw-text-black-two !tw-leading-6"
        >
          After processing the feedback, a new version of the report will be
          generated. It will take some time to process data. Please come back
          later to see the new version.
        </div>
        <div class="tw-my-3 tw-flex tw-flex-col tw-space-y-2">
          <span class="!tw-text-[15px]">
            New version name
          </span>
          <ah-input
            v-model="versionName"
            placeholder="Version name"
            :classes="['!tw-pr-10 !tw-mb-0 !tw-text-[15px]']"
          />
          <span
            v-if="emptyVersionNameError"
            class="tw-text-very-negative"
          >{{
            emptyVersionNameError
          }}</span>
        </div>
        <div class="tw-w-full tw-mt-4 tw-flex tw-justify-end">
          <ah-button
            @click="processFeedbackConfirmation"
          >
            Generate version
          </ah-button>
        </div>
      </template>
    </st-modal>
    <st-modal
      v-model="saveAsNewStrategy"
      @closeModal="saveAsNewStrategy = false"
    >
      <p class="tw-text-xl tw-font-semibold tw-py-3">
        Save version as new report
      </p>
      <template v-slot:modalActions>
        <p class="tw-my-3">
          By proceeding we will be creating a new document with this version.
        </p>
        <p class="tw-my-3">
          Comments given by colleagues with access to this report will not be
          included or processed.
        </p>
        <div class="tw-my-3 tw-mt-4 tw-flex tw-flex-col tw-space-y-2">
          <span class="!tw-text-[15px]">
            New report name
          </span>
          <ah-input
            v-model="versionName"
            placeholder="Version name"
            :classes="['!tw-pr-10 !tw-mb-0 !tw-text-[15px]']"
          />
          <span
            v-if="emptyVersionNameError"
            class="tw-text-very-negative"
          >{{
            emptyVersionNameError
          }}</span>
        </div>
        <div class="tw-w-full tw-mt-4 tw-flex tw-justify-end">
          <ah-button
            type="secondary"
            @click="processFeedbackConfirmation"
          >
            Save version as new report
          </ah-button>
        </div>
      </template>
    </st-modal>
    <tour-view
      v-if="showSharingTour"
      name="share-report"
      :steps="sharingSteps"
      :show-tour="showSharingTour"
      @tourFinished="tourFinished"
    />
    <tour-view
      v-if="showColleagueSourcesTour"
      name="colleague-sources"
      :steps="colleagueSourcesSteps"
      :show-tour="showColleagueSourcesTour"
      @tourFinished="tourFinished"
    />
    <tour-view
      v-if="allStrategyVersions.length > 2 && selectedStrategyVersion.name !== 'initial version' && showCompareVersionsTour"
      name="compare-versions"
      :steps="compareVersionsSteps"
      :show-tour="allStrategyVersions.length > 2 && selectedStrategyVersion.name !== 'initial version'"
      @tourFinished="tourFinished"
    />
    <tour-view
      v-if="compareChanges && showShowChangesTour"
      name="show-changes"
      :steps="showChangesSteps"
      :show-tour="showShowChangesTour"
      @tourFinished="tourFinished"
    />

    <!-- ONLY FOR PDF EXPORT ENDS HERE -->
  </div>
</template>

<script>
import {
  AhButton,
  AhToggleSwitch,
  StModal,
  AhInput,
  TourView,
  StToastNotification

} from '../../common'
import ColleagueView from './colleague-view.vue'
import ShareStrategy from './share-strategy.vue'
import StrategyStudies from './strategy-studies.vue'
import SharedWith from './shared-with.vue'
import CreatorView from './creator-view.vue'
import ShareColleagueView from './sharing-colleague-view.vue'
// import VueHtml2pdf from 'vue-html2pdf'
import ReportView from './report-view.vue'
import ChangesListItem from './changes-list-item.vue'
import DownloadStrategyReport from './download-strategy-report.vue'
import FeedbackStaticView from './feedbacks-static-view.vue'
import feedbackTime from './feedback-time.vue'
import ReportRenderer from './new-report-description.vue'
import _ from 'lodash'
import {
  audioRecorder,
  handlePreviewBack,
  isDesktop,
  previousResponse,
  submitQuestionAnswer
} from '../../../mixins'
import { mapState } from 'vuex'
export default {

  name: 'AskhumansStrategyDetails',
  components: {
    TourView,
    StToastNotification,
    AhButton,
    SharedWith,
    ShareColleagueView,
    ColleagueView,
    StrategyStudies,
    ChangesListItem,
    ReportRenderer,
    AhToggleSwitch,
    CreatorView,
    ShareStrategy,
    DownloadStrategyReport,
    ReportView,
    AhInput,
    StModal,
    feedbackTime,
    FeedbackStaticView
  },
  mixins: [
    audioRecorder,
    handlePreviewBack,
    isDesktop,
    previousResponse,
    submitQuestionAnswer
  ],

  props: {
    strategy: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedDropdownVersion: null,
      showToast: false,
      toastKind: 'success',
      toastTitle: '',
      toastCaption: '',
      selectedDropdownVersionToCompare: null,
      feedback: null,
      showStrategyVersionsToCompare: false,
      selectedStrategyVersion: null,
      showDownloadMenus: false,
      selectedVersionToCompare: {
        name: 'Select version',
        description: {
          strengths: [],
          weaknesses: [],
          opportunities: [],
          threats: []
        }
      },
      flattenedReport: {},
      showInitialView: false,
      isSharingOpen: false,
      selectedVersionName: '',
      invited: false,
      feedbackTimeExpired: false,
      showOriginalComment: false,
      processFeedbackModal: false,
      versionName: '',
      saveAsNewStrategy: false,
      isOwner: false,
      copied: false,
      showStrategyStudies: false,
      showColleaguesView: false,
      showOwnerView: false,
      showStrategyVersions: false,
      isReportAvailable: false,
      editedStrengths: '',
      editedWeaknesses: '',
      editedOpportunities: '',
      editedThreats: '',
      isEditingMode: false,
      allStrategyVersions: [],
      isInitialVersion: false,
      compareChanges: false,
      emptyVersionNameError: null,
      isFavourite: false,
      saveAsNewVersion: false,
      showChanges: false,
      showStaticFeedbacks: false,
      showSharingTour: false,
      showColleagueSourcesTour: false,
      showCompareVersionsTour: false,
      showShowChangesTour: false,
      sharingSteps: [
        {
          target: '#share', // We're using document.querySelector() under the hood
          header: {
            title: 'Share report'
          },
          content: `Share generated report to get colleagues feedback`,
          params: {
            highlight: true
          }

        },
        {
          target: '#feedback-buttons',
          header: {
            title: 'Leave your feedback'
          },
          content: 'After reading the generated report record or type your feedback to generate new version.',
          params: {
            highlight: true
          }

        }

      ],

      colleagueSourcesSteps: [
        {
          target: '#colleague-sources-view', // We're using document.querySelector() under the hood
          header: {
            title: 'Feedback and Sources'
          },
          content: `Switch between feedback by colleague and by theme to sources, and identify the report on which each feedback is based.`,
          params: {
            highlight: true
          }

        }

      ],
      compareVersionsSteps: [
        {
          target: '#compare-changes-view', // We're using document.querySelector() under the hood
          header: {
            title: 'Compare versions'
          },
          content: `You can compare versions of the report to see the changes made by your colleagues`,
          params: {
            highlight: true
          }

        }
      ],
      showChangesSteps: [
        {
          target: '#show-changes-view', // We're using document.querySelector() under the hood
          header: {
            title: 'Show changes'
          },
          content: `Toggle on to highlight the changes between two selected reports.`,
          params: {
            highlight: true
          }

        },
        {
          target: '#go-back-to-report-view', // We're using document.querySelector() under the hood
          header: {
            title: 'Go back to report'
          },
          content: `Click here to come back to the original report and generate more versions.`,
          params: {
            highlight: true
          }

        }
      ]
      // secondVersion: {
      //   description: {
      //     strengths: [],
      //     weaknesses: [],
      //     opportunities: [],
      //     threats: []
      //   }
      // }
    }
  },
  computed: {
    ...mapState(['currentUser'])
  },

  // watch for strategy changes
  watch: {
    strategy: {
      handler (newVal, oldVal) {
        const queryParams = new URLSearchParams(window.location.search)
        const versionId = Number(queryParams.get('version'))
      },
      deep: true
    },
    // watch for allStrategyVersions changes and show the tour if there are more than 2 versions
    allStrategyVersions: {
      handler (newVal, oldVal) {
        if (newVal.length > 2 && this.currentUser && !this.isTourSeen('compare-versions')) {
          this.showCompareVersionsTour = true
          this.$nextTick(() => {
            this.$tours['compare-versions'].start()
          })
        }
      },
      deep: true
    },

    // watch for compareChanges changes and show the tour if compareChanges is true
    compareChanges: {
      handler (newVal, oldVal) {
        if (newVal && this.currentUser && !this.isTourSeen('show-changes')) {
          this.showShowChangesTour = true
          this.$nextTick(() => {
            this.$tours['show-changes'].start()
          })
        }
      },
      deep: true
    }

  },
  mounted () {
    this.$nextTick(() => {
      const sharingView = document.getElementById('sharing-view')
      const colleagueSourcesView = document.getElementById('colleague-sources-view')
      if (sharingView && this.currentUser && !this.isTourSeen('share-report')) {
        this.showSharingTour = true
        this.$nextTick(() => {
          this.$tours['share-report'].start()
        })
      }
      if (colleagueSourcesView && this.currentUser && !this.isTourSeen('colleague-sources')) {
        this.showColleagueSourcesTour = true
        this.$nextTick(() => {
          this.$tours['colleague-sources'].start()
        })
      }
    })
    // const sharingView = document.getElementById('sharing-view')
    // console.log('sharingView', sharingView)
    // if (sharingView) {
    //   this.$tours['share-report'].start()
    // }
    // console.log('this.strategy', this.strategy)
    const queryParams = new URLSearchParams(window.location.search)
    const versionId = Number(queryParams.get('version'))

    const selectedVersion = this.strategy.versions.find((version) => {
      return version.id == versionId
    })
    this.selectedVersionName = selectedVersion.name
    if (selectedVersion) {
      this.isFavourite = selectedVersion.isFavourite
    }
    this.selectedStrategyVersion = selectedVersion
    if (this.selectedStrategyVersion) {
      this.selectedDropdownVersion = selectedVersion.name
    }
    if (selectedVersion.expiryEndAt) {
      const expiryEndAt = new Date(selectedVersion.expiryEndAt)
      const now = new Date()
      const diff = expiryEndAt - now
      if (diff < 0) {
        this.feedbackTimeExpired = true
      }
    }

    if (this.selectedStrategyVersion && Object.keys(this.selectedStrategyVersion.description).length > 0) {
      this.isReportAvailable = true
    }
    this.showColleagueOrOwnerView()
    setTimeout(() => {
      this.getAllStrategyVersions()
    }, 300)
  },

  methods: {
    feedbackSubmitted (feedback) {
      if (!this.isOwner) {
        if (feedback.explanation_type == 'text') {
          this.$refs.colleagueView.saveTextResponse(feedback)
        } else {
          this.$refs.colleagueView.saveAudioResponse(feedback)
        }
      } else {
        if (feedback.explanation_type == 'text') {
          console.log(this.$refs)
          this.$refs.creatorView.saveTextResponse(feedback)
        } else {
          this.$refs.creatorView.saveAudioResponse(feedback)
        }
      }
    },
    isTourSeen (name) {
      return this.currentUser.tours.some((tour) => tour.name === name)
    },
    tourFinished (name) {
      if (name === 'share-report') {
        this.showSharingTour = false
      }
      if (name === 'colleague-sources') {
        this.showColleagueSourcesTour = false
      }
      if (name === 'compare-versions') {
        this.showCompareVersionsTour = false
      }
      if (name === 'show-changes') {
        this.showShowChangesTour = false
      }
      this.$axios
        .post('/tours', {
          name: name,
          user_id: this.currentUser.id
        })
        .then((res) => {
          // console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    redirectToInitialVersion () {
      const initialVersionId = this.allStrategyVersions.find((version) => {
        return version.name === 'initial version'
      }).id
      this.$store.commit('toggleFullSpinner', true)
      window.location.href = `/reports/${this.strategy.id}?version=${initialVersionId}`
    },
    showGenerateVersionModal () {
      this.processFeedbackModal = true
      this.saveAsNewVersion = false
    },
    toggleDownloadMenus () {
      this.showDownloadMenus = !this.showDownloadMenus
    },
    showSaveAsNewModal () {
      this.saveAsNewStrategy = true
      this.saveAsNewVersion = true
    },
    cancelEditingMode () {
      this.isEditingMode = false
      this.showDownloadMenus = false
      this.showColleagueOrOwnerView()
    },
    processFeedbackConfirmation () {
      if (!this.versionName) {
        this.emptyVersionNameError = 'Please enter a version name'
      } else {
        this.emptyVersionNameError = ''
        this.$axios
          .post('/versions', {
            name: this.versionName,
            current_version_id: this.selectedStrategyVersion.id,
            save_as_new: this.saveAsNewVersion
          })
          .then((response) => {
            this.toastKind = 'success'
            this.toastTitle = 'Success'
            this.toastCaption = 'New version has been generated'
            // this.$router.push({
            //   name: 'strategies.show',
            //   params: {
            //     id: response.data.strategy_id
            //   }
            // })
            console.log(response)
            this.$store.commit('toggleFullSpinner', true)
            window.location.href = `/reports`
          })
          .catch((error) => {
            console.log(error)
            this.toastKind = 'error'
            this.toastTitle = 'Error'
            this.toastCaption = 'Something went wrong'
            this.emptyVersionNameError = 'The name has already been taken'
          })
      }
    },
    renameVersion () {
      console.log('sdfsdfsd')
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        method: 'patch',
        url: `/versions/${this.selectedStrategyVersion.id}`,
        data: {
          name: this.selectedVersionName
        }
      }).then((response) => {
        this.$store.commit('toggleFullSpinner', false)
        this.showToast = true
        this.toastKind = 'success'
        this.toastTitle = 'Success'
        this.toastCaption = 'Version renamed successfully'
        // this.strategies = this.strategies.map((strategy) => {
        //   return {
        //     ...strategy,
        //     versions: strategy.versions.map((version) => {
        //       if (version.id === this.selectedVersionToEditId) {
        //         return {
        //           ...version,
        //           name: this.renameVersionText
        //         }
        //       } else {
        //         return version
        //       }
        //     })
        //   }
        // })
        // console.log(this.strategies)
        // console.log(response)
        // this.selectedStrategyVersion.isFavourite = response.data.is_favourite
      }).catch((error) => {
        this.$store.commit('toggleFullSpinner', false)
        this.showToast = true
        this.toastKind = 'error'
        this.toastTitle = 'error'
        this.toastCaption = error
        console.log(error)
      })
    },
    toggleShowChanges () {
      this.showChanges = !this.showChanges
    },
    addOrRemoveFavourite (e) {
      this.isFavourite = !this.isFavourite
      e.preventDefault()
      this.$store.commit('toggleFullSpinner', true)
      this.$axios.request({
        method: 'patch',
        url: `/versions/${this.selectedStrategyVersion.id}`,
        data: {
          is_favourite: this.isFavourite
        }
      }).then((response) => {
        this.$store.commit('toggleFullSpinner', false)
        this.toastKind = 'success'
        this.toastTitle = 'Success'
        this.toastCaption = 'Report has been added to favourites'
        // console.log(response)
        // this.selectedStrategyVersion.isFavourite = response.data.is_favourite
      }).catch((error) => {
        this.$store.commit('toggleFullSpinner', false)
        console.log(error)
      })
    },
    getAllStrategyVersions () {
      if (!this.isOwner) {
        return
      }
      this.$axios.request({
        method: 'get',
        url: `/strategies/${this.strategy.id}`
      }).then((response) => {
        this.allStrategyVersions = response.data.versions
        const initialVersion = this.allStrategyVersions.find((version) => {
          return version.name === 'initial version'
        })
        this.getVersionsToCompare(initialVersion.id)
      }).catch((error) => {
        console.log(error)
      })
    },
    changeTab (tab) {
      if (tab === 'studies') {
        this.showStudies()
      } else if (tab === 'feedbacks') {
        this.showStrategyStudies = false
        this.showColleagueOrOwnerView()
      }
      // console.log('this is selected strategy version', this.selectedStrategyVersion)
    },
    removeCamelCase (string) {
      if (string == 'SWOT') return string
      return string.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    },
    changeSelectedVersion (e, version) {
      e.stopPropagation()
      this.selectedStrategyVersion = version
      this.selectedDropdownVersion = version.name
      this.showVersions('selected')
      this.redirectToVersion(version.id)
    },
    changeSelectedVersionToCompare (e, version) {
      e.stopPropagation()
      this.selectedVersionToCompare = version
      this.selectedDropdownVersionToCompare = version.name
      this.showVersions('compare')
      this.getVersionsToCompare(version.id)
    },
    redirectToVersion (id) {
      this.$store.commit('toggleFullSpinner', true)
      window.location.href = `/reports/${this.strategy.id}?version=${id}`
    },
    saveNewStrategyDescription () {
      this.$store.commit('toggleFullSpinner', true)
      if (this.strategy.framework === 'SWOT') {
        const strengths = this.editedStrengths.split('\n')
        const weaknesses = this.editedWeaknesses.split('\n')
        const opportunities = this.editedOpportunities.split('\n')
        const threats = this.editedThreats.split('\n')
        const description = {
          strengths,
          weaknesses,
          opportunities,
          threats
        }
        this.$axios.patch(`/versions/${this.selectedStrategyVersion.id}`, {
          description
        }).then((response) => {
        // this.$store.commit('toggleFullSpinner', false)
          window.location.reload()
        // this.selectedStrategyVersion = response.data
        // this.selectedStrategyVersion = response.data
        // this.selectedDropdownVersion = response.data.name
        // this.isEditingMode = false
        }).catch((error) => {
          this.$store.commit('toggleFullSpinner', false)
          console.log(error)
        })
      }
      this.isEditingMode = false
      this.$store.commit('toggleFullSpinner', false)
    },
    saveChangesOnBlur (description) {
      // this.$store.commit('toggleFullSpinner', true)
      this.$axios.patch(`/versions/${this.selectedStrategyVersion.id}`, {
        description
      }).then((response) => {
        // this.$store.commit('toggleFullSpinner', false)
        // window.location.reload()
        // this.selectedStrategyVersion = response.data
        // this.selectedStrategyVersion = response.data
        // this.selectedDropdownVersion = response.data.name
        // this.isEditingMode = false
      }).catch((error) => {
        // this.$store.commit('toggleFullSpinner', false)
        console.log(error)
      })
      this.showDownloadMenus = false
      // this.isEditingMode = false
      // this.saveNewStrategyDescription()
    },
    showEditingMode () {
      this.isEditingMode = true
      if (this.strategy.framework == 'SWOT') {
        this.editedStrengths = this.selectedStrategyVersion.description.strengths.join('\n')
        this.editedWeaknesses = this.selectedStrategyVersion.description.weaknesses.join('\n')
        this.editedOpportunities = this.selectedStrategyVersion.description.opportunities.join('\n')
        this.editedThreats = this.selectedStrategyVersion.description.threats.join('\n')
      }
    },
    userInvitedHandler () {
      // console.log('shared')
      this.isSharingOpen = false
      this.invited = true
      this.showStrategyStudies = false
      this.showStrategyVersions = false
      this.showInitialView = false
      this.$store.commit('toggleFullSpinner', true)
      window.location.reload()
      // this.showColleagueOrOwnerView()

      // this.invited = true
      // this.showInitialView = false
      // this.showColleagueOrOwnerView()
    },
    hideSharingScreen () {
      this.isSharingOpen = false
      this.showColleagueOrOwnerView()
    },
    getReadableDate (unformattedDate) {
      if (!unformattedDate) return
      const date = new Date(unformattedDate)
      const month = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      const year = date.getFullYear()
      return `${month} ${day}, ${year}`
    },
    showColleagueOrOwnerView () {
      if (this.currentUser.id === this.strategy.createdById || this.currentUser.kind == 'spacetrics_admin') {
        this.isOwner = true
        if (this.selectedStrategyVersion.name !== 'initial version') {
          this.showStaticFeedbacks = true
          return
        }
        if (this.selectedStrategyVersion.name == 'initial version' && this.selectedStrategyVersion.feedbacks && this.selectedStrategyVersion.feedbacks.length) {
          this.showOwnerView = true
          this.invited = true
        }
        if (this.selectedStrategyVersion.name == 'initial version' && !this.selectedStrategyVersion.sharedUsers) {
          this.showInitialView = true
          return
        }
        if (this.selectedStrategyVersion.name == 'initial version' && this.selectedStrategyVersion.sharedUsers && !this.selectedStrategyVersion.sharedUsers.length) {
          this.showInitialView = true
          return
        }
        if (this.selectedStrategyVersion.sharedUsers && this.selectedStrategyVersion.sharedUsers.length && !this.selectedStrategyVersion.feedbacks.length) {
          this.invited = true
        }
      } else {
        this.isOwner = false
        this.showColleaguesView = true
      }
    },

    showStudies () {
      this.showStrategyStudies = true
      // this.showStrategyVersions = false
    },
    showVersions (type) {
      if (type == 'selected') {
        if (this.showStrategyVersions) {
          this.showStrategyVersions = false
        } else {
          this.showStrategyVersions = true
        }
      } else if (type == 'compare') {
        if (this.showStrategyVersionsToCompare) {
          this.showStrategyVersionsToCompare = false
        } else {
          this.showStrategyVersionsToCompare = true
        }
      }

      // this.showStrategyStudies = false
    },
    hideStrategyVersions () {
      this.showStrategyStudies = false
      this.invited = false
      this.showInitialView = false
      this.isSharingOpen = false
      // this.showStrategyVersions = false
      this.showColleagueOrOwnerView()
    },
    hideStrategyStudies () {
      this.showInitialView = false
      this.showStrategyStudies = false
      this.invited = false
      this.isSharingOpen = false
      // this.showStrategyVersions = false
      this.showColleagueOrOwnerView()
    },
    showFeedbacksView () {
      this.showStaticFeedbacks = true
      this.showColleagueOrOwnerView()
    },
    showInvitationWithNote () {
      // console.log('show sharing screen')
      this.isSharingOpen = true
      this.invited = false
      this.showInitialView = false
      this.showOwnerView = false
      // this.showColleagueOrOwnerView()
    },

    getVersionsToCompare (compareVersionId) {
      this.$axios.request({
        method: 'post',
        url: `/versions/compare`,
        data: {
          current_version_id: this.selectedStrategyVersion.id,
          compare_version_id: compareVersionId
        }
      }).then((response) => {
        // this.selectedStrategyVersion = response.data.current_version
        // this.secondVersion = response.data.compare_version
        // this.selectedVersionToCompare = response.data.compare_version
      }).catch((error) => {
        console.log(error)
      })
    },

    copyStrategy () {
      const extractTextContent = (node) => {
        let textContent = ''
        if (node.nodeType === Node.TEXT_NODE) {
          textContent += node.textContent.trim() + ' '
        } else {
          for (const childNode of node.childNodes) {
            textContent += extractTextContent(childNode)
          }
        }
        return textContent
      }

      // Extract text content from the report-renderer component
      const reportRendererComponent = this.$refs.strategyDescription.$el
      const textContentToCopy = extractTextContent(reportRendererComponent)

      // Create a textarea element to hold the content temporarily
      const textArea = document.createElement('textarea')
      textArea.value = textContentToCopy.trim()

      // Append the textarea to the document body
      document.body.appendChild(textArea)

      // Select the content inside the textarea
      textArea.select()
      this.showDownloadMenus = false
      try {
        // Copy the text content to the clipboard
        document.execCommand('copy')
        console.log('Text content copied to clipboard')
        this.showToast = true
        this.toastKind = 'success'
        this.toastTitle = 'Copied!'
        this.toastCaption = 'Copied to clipboard'
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 2000)
      } catch (err) {
        console.error('Unable to copy text content to clipboard', err)
      }

      // Remove the dynamically created textarea
      document.body.removeChild(textArea)

      // console.log('copying strategy')
      // const strategyDescription = this.$refs.strategyDescription
      // const range = document.createRange()
      // console.log(range, strategyDescription)
      // range.selectNode(strategyDescription)
      // const selection = window.getSelection()
      // console.log(selection)
      // selection.removeAllRanges()
      // selection.addRange(range)

      // try {
      //   // Copy the HTML content
      //   document.execCommand('copy')
      //   console.log('Content copied to clipboard')
      //   this.showToast = true
      //   this.toastCaption = 'Copied to clipboard'
      //   this.copied = true
      //   setTimeout(() => {
      //     this.copied = false
      //   }, 2000)
      // } catch (err) {
      //   console.error('Unable to copy content to clipboard', err)
      // }

      // // Clear the selection
      // selection.removeAllRanges()
    },
    previewPDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    exportPDF () {
      // this.isPDFDownloading = true
      this.$refs.html2Pdf.generatePdf()
    },
    hasDownloaded () {

    },
    toggleCompareChanges () {
      if (this.allStrategyVersions.length > 2) {
        this.compareChanges = !this.compareChanges
      } else {
        console.log('You need at least 2 versions to compare')
      }
    }
  }
}
</script>
<style scoped>

</style>
