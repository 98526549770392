<template>
  <div class="">
    <div
      class="icon_chevron__svg"
      style="transform: rotate(180deg) translate(1px, -4px);"
    >
      <svg
        width="13"
        height="8"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.77349 7.27965L1.14588 1.99094C0.600006 1.36709 1.04304 0.390747 1.87199 0.390747H11.1272C11.9562 0.390747 12.3992 1.36709 11.8533 1.99094L7.22573 7.27965C6.84132 7.71897 6.1579 7.71897 5.77349 7.27965Z"
          fill="black"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
