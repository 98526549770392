<template>
  <div>
    <div class="tw-w-full tw-mx-auto">
      <div class="tw-flex tw-py-3  tw-items-center  tw-mx-auto tw-relative tw-w-full ">
        <div
          v-if="chunks.length > 0 && seconds > 0"
          class="tw-flex tw-justify-between tw-items-center tw-overflow-hidden tw-w-full tw-rounded-[20px] "
        >
          <div
            class="tw-relative tw-mt-4 tw-overflow-hidden tw-w-[370px] tw-h-[600px] tw-rounded-[20px] tw-bg-white tw-flex tw-cursor-pointer tw-justify-center tw-items-center  tw-mx-auto"
          >
            <span
              v-if="playingVideo"
              class="tw-p-2 tw-absolute tw-text-black tw-bg-white tw-rounded-xl tw-z-20 tw-bottom-2"
              @click="stopRecordingPlay"
            >
              Stop
            </span>
            <div
              v-if="playingVideo"
              class="tw-w-[300px] tw-absolute tw-h-[6px] tw-overflow-hidden tw-rounded-full tw-bg-black-three tw-bottom-12 tw-z-20 tw-mx-auto"
            >
              <div
                class="tw-h-[6px] tw-rounded-full tw-absolute tw-bg-white"
                :style="{width: getAudioPercentage} "
              />
            </div>
            <span
              v-if="showReplayButton && !playingVideo"
              class="tw-absolute tw-z-10 tw-w-full tw-h-full tw-bg-gray-900/30"
              @click="playRecording"
            >
              <svg
                class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2"
                width="38"
                height="44"
                viewBox="0 0 38 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 25.4641C38.6667 23.9245 38.6667 20.0755 36 18.5359L6 1.21539C3.33333 -0.324211 0 1.60029 0 4.67949L0 39.3205C0 42.3997 3.33333 44.3242 6 42.7846L36 25.4641Z"
                  fill="white"
                />
              </svg>

            </span>

            <video
              ref="existingVideoElement"
              :style="
                !isUploadedVideo ? 'transform:scaleX(-1)' : ''
              "
              class="tw-w-[800px] tw-h-[800px]"
              autoplay
              @ended="videoEnded"
            />
          </div>
        </div>
        <div
          v-else-if="url"
          class="tw-flex tw-justify-between tw-items-center tw-overflow-hidden tw-w-full tw-rounded-[20px] "
        >
          <div
            class="tw-relative tw-mt-4 tw-overflow-hidden tw-w-[370px] tw-h-[600px] tw-rounded-[20px] tw-bg-white tw-flex tw-cursor-pointer tw-justify-center tw-items-center  tw-mx-auto"
          >
            <span
              v-if="playingVideo"
              class="tw-p-2 tw-absolute tw-text-black tw-bg-white tw-rounded-xl tw-z-20 tw-bottom-2"
              @click="stopRecordingPlay"
            >
              Stop
            </span>
            <div
              v-if="playingVideo"
              class="tw-w-[300px] tw-absolute tw-h-[6px] tw-overflow-hidden tw-rounded-full tw-bg-black-three tw-bottom-12 tw-z-20 tw-mx-auto"
            >
              <div
                class="tw-h-[6px] tw-rounded-full tw-absolute tw-bg-white"
                :style="{width: getAudioPercentageForExisting} "
              />
            </div>
            <span
              v-if="showReplayButton && !playingVideo"
              class="tw-absolute tw-z-10 tw-w-full tw-h-full tw-bg-gray-900/30"
              @click="playExistingVideo"
            >
              <svg
                class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2"
                width="38"
                height="44"
                viewBox="0 0 38 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 25.4641C38.6667 23.9245 38.6667 20.0755 36 18.5359L6 1.21539C3.33333 -0.324211 0 1.60029 0 4.67949L0 39.3205C0 42.3997 3.33333 44.3242 6 42.7846L36 25.4641Z"
                  fill="white"
                />
              </svg>

            </span>

            <video
              ref="existingVideoElement"
              :style="
                !isUploadedVideo ? 'transform:scaleX(-1)' : ''
              "
              class="tw-w-[800px] tw-h-[800px]"
              autoplay
              @ended="videoEnded"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AhButton } from '../../common'
export default {
  components: {
    AhButton
  },
  props: {
    seconds: {
      type: Number,
      required: false,
      default: 0
    },
    url: {
      type: String,
      required: false,
      default: ''
    },
    isUploadedVideo: {
      type: Boolean,
      required: false,
      default: false
    },
    chunks: {
      type: Array,
      required: false,
      default: []
    }

  },

  data () {
    return {
      recordedChunks: [],
      intervalIds: [],
      countdownValue: 5,
      showReplayButton: false,
      playingVideo: false,
      currentPlayedSeconds: 0,
      existingVideoSeconds: 0
    }
  },
  computed: {
    getAudioPercentage () {
      return `${this.currentPlayedSeconds * 100 / this.seconds}%`
    },
    getAudioPercentageForExisting () {
      if (this.existingVideoSeconds === Infinity) {
        return `${this.currentPlayedSeconds * 100 / 10}%`
      }
      return `${this.currentPlayedSeconds * 100 / this.existingVideoSeconds}%`
    }
  },

  // watch for video url to change
  watch: {
    url: function (newVal, oldVal) {
      if (newVal) {
        this.$refs.existingVideoElement.src = newVal
        this.$refs.existingVideoElement.load()
        this.$refs.existingVideoElement.style.transform = 'scaleX(-1)'
        this.existingVideoSeconds = this.$refs.existingVideoElement.duration
        this.showReplayButton = true
      }
    }
  },
  mounted () {
    console.log(this.isUploadedVideo)
    // console.log('mounted', this.seconds)
    // console.log('url............', this.url)
    if (this.chunks.length > 0) {
      this.showRep = true
      this.recordedChunks = this.chunks

      this.playRecording()
    } else if (this.url) {
      this.$refs.existingVideoElement.src = this.url
      this.$refs.existingVideoElement.load()
      this.showReplayButton = true
      if (!this.isUploadedVideo) {
        this.$refs.existingVideoElement.style.transform = 'scaleX(-1)'
      }
    }
  },

  methods: {

    videoEnded () {
      console.log('video recording ended')
      this.clearAllIntervals()
      this.existingVideoSeconds = 0
      this.playingVideo = false
      this.showReplayButton = true
      this.currentPlayedSeconds = 0
    },
    stopRecordingPlay () {
      this.playingVideo = false
      this.showReplayButton = true
      this.currentPlayedSeconds = 0
      this.$refs.existingVideoElement.stop()
      this.$refs.existingVideoElement.currentTime = 0
      this.videoEnded()
    },
    playRecording () {
      this.$refs.existingVideoElement.muted = false
      this.showReplayButton = false
      this.playingVideo = true
      const blob = new Blob(this.recordedChunks, { type: 'video/mp4' })
      const videoUrl = URL.createObjectURL(blob)
      this.$refs.existingVideoElement.src = videoUrl
      this.$refs.existingVideoElement.load()
      const promise = this.$refs.existingVideoElement.play()

      if (promise !== undefined) {
        promise.then(() => {
          console.log('Autoplay started!')

          const intervalId = setInterval(() => {
            this.currentPlayedSeconds += 1
            if (this.currentPlayedSeconds === this.seconds || this.currentPlayedSeconds === this.existingVideoSeconds) {
              clearInterval(intervalId)
            }
          }, 1000)
          this.intervalIds.push(intervalId)
          setTimeout(() => {
            clearInterval(intervalId)
            this.currentPlayedSeconds = 0
          }, 15000)
          // Autoplay started!
        }).catch(error => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          console.error('Error playing video:', error)
          this.playingVideo = false
          this.existingVideoElement.muted = true

          this.showReplayButton = true
        })
      }
    },
    clearAllIntervals () {
      for (const intervalId of this.intervalIds) {
        clearInterval(intervalId) // Clear each interval
      }
      this.intervalIds = [] // Clear the array of interval IDs
    },
    playExistingVideo () {
      this.playingVideo = true
      console.log('playing video bu button')
      this.existingVideoSeconds = this.$refs.existingVideoElement.duration
      const promise = this.$refs.existingVideoElement.play()
      this.$refs.existingVideoElement.muted = false

      if (promise !== undefined) {
        promise.then(() => {
          console.log('Autoplay started!')
          this.currentPlayedSeconds = 0
          const intervalId = setInterval(() => {
            this.currentPlayedSeconds += 1
            if (this.currentPlayedSeconds === this.existingVideoSeconds) {
              clearInterval(intervalId)
              this.currentPlayedSeconds = 0
            }
          }, 1000)
          this.intervalIds.push(intervalId)
          setTimeout(() => {
            clearInterval(intervalId)
            this.currentPlayedSeconds = 0
            this.existingVideoSeconds = 0
          }, 15000)
        }).catch(error => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          console.error('Error playing video:', error)
          this.playingVideo = false
          this.videoElement.muted = true

          this.showReplayButton = true
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
