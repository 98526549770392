var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-items-center tw-px-4 tw-gap-2 tw-flex-wrap ",class:_vm.containerClass},[_c('button',{staticClass:"tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-py-0 tw-border tw-rounded-md  tw-bg-gray-100 tw-border-gray-800",class:{
      'tw-opacity-80 tw-cursor-not-allowed':
        _vm.selectedPage === 1,
    },attrs:{"title":"Previous","type":"button","disabled":_vm.selectedPage === 1},on:{"click":function($event){return _vm.changePageHandler(
        $event,
        _vm.selectedPage - 1
      )}}},[_c('svg',{staticClass:"tw-w-4",attrs:{"viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"15 18 9 12 15 6"}})])]),_vm._v(" "),_vm._l((_vm.totalPages),function(range){return _c('button',{key:range,staticClass:"tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-text-sm  tw-rounded  ",class:{
      'tw-bg-primary-light tw-border-primary/30 tw-text-primary tw-font-semibold tw-border tw-border-solid ':
        range === _vm.selectedPage,
      'tw-bg-gray-100 tw-text-black':
        range !== _vm.selectedPage,
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.changePageHandler($event, range)}}},[_vm._v("\n    "+_vm._s(range)+"\n  ")])}),_vm._v(" "),_c('button',{staticClass:"tw-inline-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-py-0 tw-border tw-rounded-md  tw-bg-gray-100 tw-border-gray-800",class:{
      'tw-opacity-70 tw-cursor-not-allowed':
        _vm.selectedPage ===
        _vm.totalPages,
    },attrs:{"disabled":_vm.selectedPage ===
        _vm.totalPages,"title":"Next","type":"button"},on:{"click":function($event){return _vm.changePageHandler(
        $event,
        _vm.selectedPage + 1
      )}}},[_c('svg',{staticClass:"tw-w-4",attrs:{"viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"9 18 15 12 9 6"}})])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }