<template>
  <st-drawer
    v-model="showDrawer"
    @closeModal="closingModal"
  >
    <template v-slot:modalTitle>
      <div class="flex justify-between  ">
        <span class="font-18p">
          {{ title }}
        </span>
      </div>
    </template>

    <template v-slot:modalActions>
      <div class="tw-flex tw-space-x-1 tw-justify-between  tw-h-full tw-flex-col">
        <div>
          <div class="tw-bg-black tw-text-white tw-px-10 tw-py-12">
            <p class="tw-text-[30px] tw-mt-2 tw-leading-tight tw-font-semibold">
              {{ description }}
            </p>
            <p
              v-if="action!=='add'"
              class="tw-text-white tw-mt-3"
            >
              <!-- {{ currentStudyBasePlan }} -->
              This study has received {{ study.totalResponsesCompleted }} of {{ study.allowResponses }} respondents. The plan selected only allows {{ planToDowngrade.allow_responses }} respondents.
            </p>
            <!-- <p class="tw-text-base tw-font-semibold">
              {{ currentStudyBasePlan && currentStudyBasePlan.title }}
            </p>
            <p class="tw-text-[15px]">
              ${{ currentStudyBasePlan && currentStudyBasePlan.price }} per month
            </p> -->
          </div>
        <!-- <div class="tw-px-10">
          <div
            class="tw-py-4 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"
          >
            <p class="tw-text-sm tw-text-black-three">
              Study
            </p>
            <p class="tw-text-base tw-font-semibold">
              {{ study.name }}
            </p>
          </div>

          <div
            v-if="action=='upgrade' || action=='downgrade'"
            class="tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid tw-flex tw-space-x-3 tw-justify-between"
          >
            <div>
              <p class="tw-text-sm tw-text-black-three">
             Current plan
            </p>
            <p class="tw-text-base tw-font-semibold">
             {{ currentStudyBasePlan && currentStudyBasePlan.title }}
            </p>
            <p class="tw-text-[15px]">
              ${{ currentStudyBasePlan && currentStudyBasePlan.price }} per month
            </p>
            </div>
            <div>
              <p class="tw-text-sm tw-text-black-three">
             New plan
            </p>
            <p class="tw-text-base tw-font-semibold">
             {{ newPlan && newPlan.title }}
            </p>
            <p class="tw-text-[15px]">
              ${{ newPlan && newPlan.price }} per month
            </p>
            </div>

          </div>
          <div
            v-if="answersPlan"
            class="tw-py-3 tw-h-full tw-border-b tw-border-b-white-two tw-border-solid"
          >
            <p class="tw-text-sm tw-text-black-three">
              Add on
            </p>
            <p class="tw-text-base tw-font-semibold">
             {{ type=='answers' ? 'Answers' : 'Answers & Strategies' }}
            </p>
            <p class="tw-text-[15px]">
              ${{ answersPlan && answersPlan.price }} per month
            </p>
          </div>
          <template v-if="action!='remove'">
            <div
            class="tw-py-3 tw-h-full tw-flex tw-justify-between tw-items-center tw-border-b tw-border-b-white-two tw-border-solid"
          >
            <div>
              <p class="tw-text-sm tw-text-black-three">
                Payment method
              </p>

              <p class="tw-text-base tw-font-semibold">
                {{ paymentInformation.brand }} ending
                {{ paymentInformation.last4 }}
              </p>
            </div>
            <p class="tw-text-sm tw-text-primary tw-cursor-pointer" @click="changePaymentPlan">
              Change payment method
            </p>
          </div>
          <div class="tw-py-3  tw-h-full ">
            <p class="tw-text-sm tw-text-black-three">
              To be charged
            </p>
            <p class="tw-text-base tw-font-semibold">
              {{ proratedPrice!==null ? `$${proratedPrice}`: 'Calculating...' }}
            </p>
          </div>
          <div class="tw-py-3  tw-h-full ">
            <p class="tw-text-sm tw-text-black-three">
              To be charged every month after
            </p>
            <p v-if="newPlan" class="tw-text-base tw-font-semibold">
              {{ newPlan ? `$${newPlan.price}` : 'Calculating...'  }}
            </p>
          </div>
          </template>

        </div> -->
        </div>

        <div class="tw-px-10">
          <button
            class="tw-bg-primary tw-text-white tw-rounded-lg tw-p-3 tw-w-full"
            @click="closingModal"
          >
            Close
          </button>
        </div>
      </div>
    </template>
    <stripe-payment-form
      ref="stripeForm"
      type="update"
    />
  </st-drawer>
</template>

<script>
// import { StDrawer, SecondaryButton, ToggleButton, SentimentChip } from "./";
import StDrawer from './st-drawer.vue'
import SecondaryButton from './secondary-button.vue'
import ToggleButton from './toggle-button.vue'
import SentimentChip from './sentiment-chip.vue'
import StripePaymentForm from './stripe-payment-form.vue'
export default {
  components: {
    StDrawer,
    SecondaryButton,
    ToggleButton,
    StripePaymentForm,
    SentimentChip
  },
  props: {
    type: {
      type: String,
      require: true,
      default: ''
    },
    study: {
      type: Object,
      require: true,
      default: () => {}
    },
    action: {
      type: String,
      require: false,
      default: 'add'

    },
    close: {
      type: Function,
      require: false,
      default: () => {}

    },
    newPlan: {
      type: Object,
      require: false,
      default: () => {}
    },
    answersPlan: {
      type: Object,
      require: false,
      default: () => {}
    }
  },
  data () {
    return {
      paymentInformation: {},
      showDrawer: false,
      allPlans: [],
      currentStudyBasePlan: null,
      currentAnswerStrategyPlan: null,
      proratedPrice: null,
      planToDowngrade: null
    }
  },
  computed: {
    title () {
      return 'Downgrade plan:'
    },
    description () {
      return 'Sorry, you cannot downgrade your plan to a study that has received more responses than the plan allows.'
    }

  },
  // watch for type to change
  watch: {
    type: function (val) {
      this.filterCurrentPlan()
    }
  },
  mounted () {
    // this.checkPaymentInformation()
    this.getAllPlans()
    setTimeout(() => {
      this.filterCurrentPlan()
    }, 200)
  },
  methods: {
    closingModal () {
      this.$emit('close')
      this.showDrawer = false
    },

    checkPaymentInformation () {
      this.$axios
        .request({
          method: 'get',
          url: `payments/payment_information`
        })
        .then(({ data }) => {
          // console.log(data);
          const info = {}
          info.brand = data.data.brand
          info.last4 = data.data.last4
          info.expireYear = data.data.exp_year
          info.expireMonth =
            data.data.exp_month < 10
              ? '0' + data.data.exp_month
              : data.data.exp_month
          this.paymentInformation = { ...info }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toggleDrawer () {
      this.showDrawer = true
    },
    getAllPlans () {
      this.$axios
        .request({
          method: 'get',
          url: `plans`
        })
        .then(({ data }) => {
          this.allPlans = data
          // console.log(this.allPlans)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    filterCurrentPlan () {
      const currentStudyPlan = this.study.plans.find((plan) => {
        return plan.title.toLowerCase().includes('answers') == false
      })
      this.currentStudyBasePlan = currentStudyPlan

      const newStudyPlanWithAllowedResponses = this.allPlans.find((plan) => {
        return plan.id == this.newPlan.id
      })
      this.planToDowngrade = newStudyPlanWithAllowedResponses
    }

  }
}
</script>

<style lang="scss" scoped></style>
