var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},_vm._l((_vm.versionDescription),function(value,key){return _c('div',{key:((key[0]) + "_" + (key[1]) + "_v_" + (key[2])),staticClass:"tw-mb-5 tw-max-h-full"},[_c('div',{style:({ marginLeft: _vm.getIndentationLevel() + 'px' })},[((typeof key) !== 'number')?_c('p',{staticClass:"tw-font-semibold tw-pb-1 tw-capitalize",style:({
          fontSize: _vm.getFontSize(_vm.getIndentationLevel()),
        })},[_vm._v("\n        "+_vm._s(_vm.removeUnderscore(key))+"\n      ")]):_vm._e(),_vm._v(" "),(typeof value === 'object' && !Array.isArray(value))?_c('ReportChangesRenderer',{attrs:{"version-description":value,"changes":_vm.changes,"show-changes":_vm.showChanges}}):_vm._e()],1),_vm._v(" "),(Array.isArray(value))?[_c('div',{style:({ marginLeft: _vm.getIndentationLevel() + 'px' })},[_vm._l((value),function(item,index){return [(typeof item === 'object' && !Array.isArray(item))?_c('ReportChangesRenderer',{key:("item_" + index),attrs:{"version-description":item,"changes":_vm.changes,"show-changes":_vm.showChanges}}):_c('div',{key:("item_else_" + index),staticClass:"tw-pb-1"},[_c('p',{key:item,staticClass:"tw-text-[15px]",class:{
                  'tw-bg-red-100': _vm.showChanges=='removed' && _vm.checkForRemovedItems(item),
                  'tw-bg-green-100': _vm.showChanges=='added' && _vm.checkForAddedItems(item),
                  // 'tw-bg-red-100': checkForChangeItemInLeft(item),
                  // 'tw-bg-green-100': checkForChangeItemInRight(item)
                },attrs:{"data-title":_vm.showChanges=='removed' && _vm.checkForRemovedItems(item) ? 'Removed' : _vm.showChanges=='added' && _vm.checkForAddedItems(item) ? 'Added' : 'No changes'}},[_vm._v("\n              "+_vm._s(item)+"\n            ")])])]})],2)]:((typeof value != 'object'))?[_c('div',{staticClass:"tw-text-[15px]",class:{
            'tw-bg-red-100': _vm.showChanges=='removed' && _vm.checkForRemovedItems(value),
            'tw-bg-green-100': _vm.showChanges=='added' && _vm.checkForAddedItems(value),
            'tw-bg-red-100': _vm.showChanges=='removed' && _vm.checkForChangeItemInLeft(value),
            'tw-bg-green-100': _vm.showChanges=='added' && _vm.checkForChangeItemInRight(value),
            'tw-bg-red-100': _vm.showChanges=='removed' && _vm.getDictRemoveItem(value),
            'tw-bg-green-100': _vm.showChanges=='added' && _vm.getDictAddedItem(value)
          },style:({ marginLeft: _vm.getIndentationLevel() + 'px' }),attrs:{"data-title":_vm.showChanges=='removed' && _vm.checkForRemovedItems(_vm.item) ? 'Removed' : _vm.showChanges=='added' && _vm.checkForAddedItems(_vm.item) ? 'Added' : 'No changes'}},[_c('p',[_vm._v(_vm._s(value))])])]:_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }