<template>
  <div>
    <st-card
      :class="{
        'st-survey-hub-card__contents': true,
        'st-survey-hub-card__contents--section-complete': complete
      }"
      @click="advanceToUrl(`/study/${surveyId}/edit/${urlEnd}`)"
    >
      <template v-slot:card-content>
        <div class="st-survey-hub-card__section">
          <icon-wrapper
            class="st-survey-hub-card__section-icon"
            :icon-name="icon"
          />
          <p class="st-survey-hub-card__title">
            {{ title }}
          </p>
          <p class="st-survey-hub-card__description">
            <span
              v-for="(word, index) in splitDescription"
              :key="index"
            >
              <span
                v-if="boldedText.includes(word)"
                class="st-survey-hub-card__description--bold"
              >{{ word }} </span>
              <span
                v-else
                class="st-survey-hub-card__description"
              >{{ word }} </span>
            </span>
          </p>
          <div
            v-if="complete"
            class="st-survey-hub-card__complete-wrapper"
          >
            <icon-wrapper
              class="st-survey-hub-card__complete-icon"
              icon-name="check"
              :invert="true"
            />
          </div>
        </div>
      </template>
    </st-card>
  </div>
</template>

<script>
import { advanceToUrl } from '../../../mixins'
import { IconWrapper, StCard } from '../../common'

export default {
  components: {
    IconWrapper,
    StCard
  },
  mixins: [advanceToUrl],
  props: {
    boldedText: {
      type: Array,
      required: false,
      default: () => []
    },
    complete: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    surveyId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    urlEnd: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    splitDescription () {
      return this.description.split(' ')
    }
  },
  methods: {
  }
}
</script>
