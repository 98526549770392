<template>
 <div>
  <div class="thematic-analysis tw-pb-3" v-if="section==='collection'">
    <p><span class="tw-font-semibold">{{ data.number_of_participants }}</span> participants</p>
    <p><span class="tw-font-semibold">{{ data.total_number_of_answers }}</span> answers collected</p>
    <p><span class="tw-font-semibold">{{ data.approximate_total_speaking_time }}</span> of approximate speaking time</p>
  
  
  </div>
  <div v-if="section==='thematicBreakdown'">
    <!-- {{ data }} -->
    <section class="pdf-item" v-for="theme in data">
      <p>
        <span class="tw-font-semibold tw-underline">{{ theme.theme }}</span>
      </p>
      <p>
        {{ theme.description }}
      </p>
      <div class="pdf-content" v-for="quote in theme.representative_quotes">
        <div  class="tw-flex tw-py-2 tw-flex-col tw-justify-end pdf-content">
          <span class="tw-block">
            “{{ quote.quote }}”
          </span>
          <span class="tw-block tw-text-end ">-{{ quote.name }}</span>
        </div>
        
      </div>
    </section>
  </div>  
 </div>
</template>

<script>
export default {
  name: 'ThematicAnalysisRenderer',
  props: {
    data: {
      type: [Object, Array, String],
      required: true,
      validator: (value) => value instanceof Object || Array.isArray(value) || typeof value === 'string'
    },
    section: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      };
  },
  mounted () {
    console.log(this.data);
  },
};
</script>

<style>
.thematic-analysis {
  /* Add your custom styles here */
}
</style>
