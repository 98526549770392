<template>
  <div class="icon_overflowmenu">
    <div class="icon_overflowmenu__svg">
      <svg
        class="relative right-24p"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h48v48H0z"
          class="st-icon--no-fill"
        />
        <path
          d="M0 0h3v3H0z"
          transform="translate(23 17)"
        />
        <path
          d="M0 0h3v3H0z"
          transform="translate(23 23)"
        />
        <path
          d="M0 0h3v3H0z"
          transform="translate(23 29)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
