var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'st-upload-preview tw-flex tw-items-center tw-space-x-3': true,
    'st-upload-preview--hide-bar': _vm.hideBar,
    'st-upload-preview--large-preview': _vm.displayLargePreview && !_vm.hideBar,
    'st-upload-preview--large-modal-preview': _vm.isLargeModalPreview
  }},[(_vm.hideBar)?_c('div',{staticClass:"st-upload-preview__media-and-filename"},[(_vm.isVideo)?_c('video',{staticClass:"st-upload-preview__media st-upload-preview__media--hide-bar"},[_vm._v("\n      >\n      "),_c('source',{attrs:{"src":_vm.src,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag.\n    ")]):_c('div',{class:{
        'st-upload-preview__media--hide-bar': true,
        'st-upload-preview__media--hide-bar st-upload-preview__media--hide-bar--large': _vm.displayLargePreview,
      },style:({
        background: ("url('" + _vm.previewUrl + "') center / cover no-repeat")
      })})]):_vm._e(),_vm._v(" "),(!_vm.hideBar)?_c('div',{class:{
      'st-upload-preview__media tw-w-[150px] tw-h-[150px] tw-rounded-lg': true,
      'st-upload-preview__media--large-modal-preview': _vm.isLargeModalPreview
    }},[(_vm.isVideo)?_c('video',{staticClass:"st-upload-preview__media-contents st-upload-preview__media-contents--video"},[_vm._v("\n      >\n      "),_c('source',{attrs:{"src":_vm.src || _vm.previewUrl,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag.\n    ")]):_c('div',{staticClass:"st-upload-preview__media-contents tw-rounded-lg",style:({
        background: ("url('" + _vm.previewUrl + "') center / cover no-repeat")
      })})]):_vm._e(),_vm._v(" "),(_vm.shouldShowPercent)?_c('div',{class:{
      'st-upload-preview__bar-container': true,
      'st-upload-preview__bar-container--large-modal-preview': _vm.isLargeModalPreview
    }},[_c('div',{staticClass:"st-upload-preview__bar",style:({ width: _vm.uploadPercent + '%' })}),_vm._v(" "),_c('div',{staticClass:"st-upload-preview__percentage"},[_vm._v("\n      "+_vm._s(_vm.uploadPercent)+"%\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{class:{
      'st-upload-preview__delete': true,
      'st-upload-preview__delete--disabled': _vm.disabled,
      'st-upload-preview__delete--hide-bar': _vm.hideBar,
      'st-upload-preview__delete--large-modal-preview': _vm.isLargeModalPreview
    },attrs:{"tabindex":"0"},on:{"click":_vm.handleRemove,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleRemove.apply(null, arguments)}}},[_c('icon-wrapper',{class:{
        'st-upload-preview__delete-icon': true,
        'st-upload-preview__delete-icon--disabled': _vm.disabled,
      },attrs:{"icon-name":"trash"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }