<template>
  <div class="">
    <div
      v-for="(value, key) in versionDescription"
      :key="`${key[0]}_${key[1]}_v_${key[2]}`"
      class="tw-mb-5 tw-max-h-full"
    >
      <div

        :style="{ marginLeft: getIndentationLevel() + 'px' }"
      >
        <p
          v-if="(typeof key) !== 'number'"
          class="tw-font-semibold tw-pb-1 tw-capitalize"
          :style="{
            fontSize: getFontSize(getIndentationLevel()),
          }
          "
        >
          {{ removeUnderscore(key) }}
        </p>
        <ReportChangesRenderer
          v-if="typeof value === 'object' && !Array.isArray(value)"
          :version-description="value"
          :changes="changes"
          :show-changes="showChanges"
        />
      </div>
      <template v-if="Array.isArray(value)">
        <div :style="{ marginLeft: getIndentationLevel() + 'px' }">
          <template
            v-for="(item, index) in value"
          >
            <ReportChangesRenderer
              v-if="typeof item === 'object' && !Array.isArray(item)"
              :key="`item_${index}`"
              :version-description="item"
              :changes="changes"
              :show-changes="showChanges"
            />
            <div
              v-else
              :key="`item_else_${index}`"
              class="tw-pb-1"
            >
              <p

                :key="item"
                class="tw-text-[15px]"
                :data-title="showChanges=='removed' && checkForRemovedItems(item) ? 'Removed' : showChanges=='added' && checkForAddedItems(item) ? 'Added' : 'No changes'"
                :class="
                  {
                    'tw-bg-red-100': showChanges=='removed' && checkForRemovedItems(item),
                    'tw-bg-green-100': showChanges=='added' && checkForAddedItems(item),
                    // 'tw-bg-red-100': checkForChangeItemInLeft(item),
                    // 'tw-bg-green-100': checkForChangeItemInRight(item)
                  }
                "
              >
                {{ item }}
              </p>
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="(typeof value != 'object')">
        <div
          :style="{ marginLeft: getIndentationLevel() + 'px' }"
          class="tw-text-[15px]"
          :data-title="showChanges=='removed' && checkForRemovedItems(item) ? 'Removed' : showChanges=='added' && checkForAddedItems(item) ? 'Added' : 'No changes'"
          :class="
            {
              'tw-bg-red-100': showChanges=='removed' && checkForRemovedItems(value),
              'tw-bg-green-100': showChanges=='added' && checkForAddedItems(value),
              'tw-bg-red-100': showChanges=='removed' && checkForChangeItemInLeft(value),
              'tw-bg-green-100': showChanges=='added' && checkForChangeItemInRight(value),
              'tw-bg-red-100': showChanges=='removed' && getDictRemoveItem(value),
              'tw-bg-green-100': showChanges=='added' && getDictAddedItem(value)
            }
          "
        >
          <p>{{ value }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReportChangesRenderer',
  props: {
    versionDescription: {
      type: [Object, Array, String],
      required: true,
      validator: (value) => value instanceof Object || Array.isArray(value) || typeof value === 'string'
    },
    removedItems: {
      type: Array,
      default: () => []
    },
    addedItems: {
      type: Array,
      default: () => []
    },
    valueChanged: {
      type: Array,
      default: () => []
    },
    changes: {
      type: Object,
      default: () => {}
    },
    showChanges: {
      type: String,
      required: false,
      default: ''
    }

  },
  mounted () {
    // console.log(this.versionDescription)
    // console.log(this.versionDescription)
    // console.log(this.addedItems)
    // console.log(this.removedItems)
    // console.log(this.valueChanged)
    // console.log(this.changes)
    // console.log(this.showChanges)
  },
  createRandomKey () {
    return Math.random().toString(36).substring(7)
  },
  methods: {
    removeUnderscore (string) {
      return string.replace(/_/g, ' ')
    },
    checkForRemovedItems (item) {
      if (this.changes['list:remove'] && this.changes['list:remove'].length > 0) {
        return this.changes['list:remove'].some((removedItem) => {
          return removedItem.left === item
        })
      }

      return false
    },
    checkForChangeItemInLeft (item) {
      if (this.changes['value_changes'] && this.changes['value_changes'].length > 0) {
        return this.changes['value_changes'].some((changedItem) => {
          return changedItem.left === item
        })
      }

      return false
    },
    checkForChangeItemInRight (item) {
      if (this.changes['value_changes'] && this.changes['value_changes'].length > 0) {
        return this.changes['value_changes'].some((changedItem) => {
          return changedItem.right === item
        })
      }

      return false
    },
    checkForAddedItems (item) {
      if (this.changes['list:add'] && this.changes['list:add'].length > 0) {
        return this.changes['list:add'].some((addedItem) => {
          return addedItem.right === item
        })
      }

      return false
    },
    getDictRemoveItem (item) {
      if (this.changes['dict:remove'] && this.changes['dict:remove'].length > 0) {
        return this.changes['dict:remove'].some((removedItem) => {
          return removedItem.left === item
        })
      }

      return false
    },
    getDictAddedItem (item) {
      if (this.changes['dict:add'] && this.changes['dict:add'].length > 0) {
        return this.changes['dict:add'].some((addedItem) => {
          return addedItem.right === item
        })
      }

      return false
    },
    getFontSize (level) {
      if (level === 0) {
        return '22px'
      } else if (level === 10) {
        return '20px'
      } else if (level === 20) {
        return '17px'
      } else if (level === 3) {
        return '15px'
      } else return '15px'
    },
    getIndentationLevel () {
      let level = 0
      let parent = this.$parent

      while (parent && parent.$options.name === 'ReportChangesRenderer') {
        level++
        parent = parent.$parent
      }

      // Assuming each level of indentation is 20px, adjust as needed
      return level * 10
    }
  }
}
</script>
<style>
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-size: 14px;
  border-radius: 999px;
  position: absolute;
  padding: 2px 2px 2px 2px;
  bottom: -1.6em;
  right: 0px;
  margin-left: 300px;
  float: right;
  white-space: nowrap;
  max-width: 70px;
  text-align: center;
  /* box-shadow: 1px 1px 3px #b3b3b3; */
  opacity: 0;
  /* border: 1px solid #111111; */
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
  /* background-color: red; */
  /* display: flex;
  justify-content: end; */
}</style>
