<template>
  <div class="st-layout__body">
    <div class="st-body">
      <div class="bx--grid">
        <div class="bx--row">
          <div class="bx--col-xs-12">
            <div class="st-survey-hub__head">
              <st-hyperlink
                class="back-hyperlink-spacing--extra-space"
                :back-link="true"
                text="Back"
                :url="`/study/${survey.id}/publish`"
              />
              <div
                class="desktop-page-right-action-button desktop-page-right-action-button--minimal-space"
              >
                <div class="flex space-x-10">
                  <st-button
                    :url="`/study/${survey.id}/publish`"
                    caption="Continue"
                    icon-name="arrow"
                  />
                  <textarea
                    ref="copyText"
                    class="st-share-text-area"
                    readonly
                    :value="
                      `${baseUrl}/study/${survey.id}/custom_page_submission`
                    "
                  />
                  <st-tooltip
                    class="st-published__share"
                    direction="bottom"
                    :tip="tipMessage"
                    :is-active="false"
                    :flash="showTooltip"
                  >
                    <st-button
                      caption="Share"
                      icon-name="share"
                      @click="copyToClipboard"
                    />
                  </st-tooltip>
                </div>
              </div>
            </div>

            <div
              class="flex justify-between flex-col md:flex-row items-center mt-80p"
            >
              <div class="flex items-center">
                <img
                  :src="logoUrl"
                  class="max-w-200 max-h-40 object-cover"
                  alt=""
                />
                <button
                  @click="showLogoModal = true"
                  class="ml-40p custom-page-upload-button"
                >
                  Change Logo
                </button>
              </div>
              <div class="flex mt-10p md:mt-0">
                <div class="flex items-center">
                  <span>Primary Color</span>
                  <color-picker
                    class="ml-10p"
                    @changed="onPrimaryChanged"
                    @closed="handlePrimaryClosed"
                    :color="primaryColor"
                  />
                </div>
                <div class="flex items-center ml-20p">
                  <span>Secondary Color</span>
                  <color-picker
                    class="ml-10p"
                    @changed="onSecondaryChanged"
                    @closed="handleSecondaryClosed"
                    :color="secondaryColor"
                  />
                </div>
                <div></div>
              </div>
            </div>
            <div class="mt-80p">
              <div
                class="flex flex-col space-y-5 md:space-y-0 md:flex-row justify-between my-auto md:space-x-10 space-x-0"
              >
                <div class="w-full md:w-1/2 flex flex-col">
                  <div class="w-full ax-h-430 h-full">
                    <div
                      :class="{
                        'edit-cover-photo__photo': true,
                        'w-full object-cover max-h-430 h-full': true,
                        'edit-cover-photo__default': true,
                      }"
                      v-if="!isMediaUrl"
                    ></div>
                    <img
                      v-if="isMediaUrl"
                      :src="questionMediaUrl"
                      class="w-full object-cover max-h-430"
                      alt=""
                    />
                  </div>
                  <div class="flex justify-end">
                    <button
                      class="custom-page-upload-button"
                      @click="showCoverModal = true"
                    >
                      Change Cover Photo
                    </button>
                  </div>
                </div>
                <div class="w-full md:w-1/2">
                  <h2 class="font-38">
                    {{ survey.headline }}
                  </h2>
                  <p class="mt-40p">
                    {{ this.questions[0].description }}
                  </p>
                  <div class="mt-60p">
                    <a
                      href="#"
                      class="custom-page-button"
                      :style="'background:' + primaryColor"
                      >Record Your Thoughts</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <st-media-uploader
              v-if="showLogoModal"
              :aspect-ratio="4.23"
              :folders="folders"
              modal-title="Change Logo"
              @closeMediaUploader="showLogoModal = false"
              @uploadComplete="uploadLogoComplete"
            />
            <st-media-uploader
              v-if="showCoverModal"
              :aspect-ratio="1.7"
              :folders="folders"
              modal-title="Page Cover Photo"
              @closeMediaUploader="showCoverModal = false"
              @uploadComplete="uploadCoverComplete"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInput,
  StModal,
  StMediaUploader,
  StTooltip,
} from "../../common";
import { ParticipantAvatar } from "../insights";
import SurveyHubCard from "./survey-hub-card";
import SurveyMembers from "./survey-members";
import { buttonPrimaryColor, buttonSecondaryColor } from "./constants";
import { any } from "expect";
import ColorPicker from "./question_specifications/slide/ColorPicker";
export default {
  components: {
    IconWrapper,
    StButton,
    StHyperlink,
    StInput,
    StModal,
    SurveyHubCard,
    StMediaUploader,
    SurveyMembers,
    ParticipantAvatar,
    StTooltip,
    ColorPicker,
  },
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => [],
    },
    survey: {
      type: Object,
      required: true,
    },

    account: {
      type: Object,
    },
    questions: {
      type: any,
    },
  },
  mounted: function() {
    this.logoUrl = this.account.logoUrl;
    if (this.survey.customPageLogoUrl) {
      this.logoUrl = this.survey.customPageLogoUrl;
    }
    if (this.survey.customPageCoverUrl) {
      this.isMediaUrl = true;
      this.questionMediaUrl = this.survey.customPageCoverUrl;
    }

    if (this.survey.primaryColor) {
      this.primaryColor = this.survey.primaryColor;
    }
    if (this.survey.secondaryColor) {
      this.secondaryColor = this.survey.secondaryColor;
    }
  },
  data: function() {
    return {
      showLogoModal: false,
      showCoverModal: false,
      isMediaUrl: false,
      logoUrl: "",
      questionMediaUrl: "",
      surveyInfo: this.survey,
      url: "/study/" + this.survey.id,
      primaryColor: buttonPrimaryColor,
      secondaryColor: buttonSecondaryColor,
      showTooltip: false,
      tipMessage: "Link Copied",
      showPicker: false,
    };
  },
  computed: {
    baseUrl() {
      return window.location.origin;
    },
  },
  methods: {
    onPrimaryChanged(color) {
      this.primaryColor = color;
      this.savePrimaryColor();
      this.showPicker = false;
    },
    handlePrimaryClosed() {
      this.closeMenu("showSlide");
    },
    onSecondaryChanged(color) {
      this.secondaryColor = color;
      this.saveSecondaryColor();
      this.showPicker = false;
    },
    handleSecondaryClosed() {
      this.closeMenu("showSlide");
    },
    closeMenu(prop) {
      if (this[prop]) {
        this[prop] = false;
      }
    },
    copyToClipboard() {
      this.$refs.copyText.select();
      let supported = document.execCommand("copy");
      if (!supported) {
        this.tipMessage = "Tap to copy is not supported";
      }
      this.showLinkCopiedMessage();
    },
    showLinkCopiedMessage() {
      this.showTooltip = true;
      setTimeout(() => {
        this.$nextTick(() => {
          this.showTooltip = false;
        });
      }, 1250);
    },
    uploadLogoComplete(payload) {
      let formData = new FormData();
      formData.append("survey[custom_page_logo]", payload);

      this.$axios
        .request({
          method: "patch",
          url: this.url,
          data: formData,
        })
        .then((res) => {
          console.log(res);
          this.logoUrl = res.data.customPageLogoUrl;
          this.showLogoModal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadCoverComplete(payload) {
      let formData = new FormData();
      formData.append("survey[custom_page_cover]", payload);

      this.$axios
        .request({
          method: "patch",
          url: this.url,
          data: formData,
        })
        .then((res) => {
          this.questionMediaUrl = res.data.customPageCoverUrl;
          this.showCoverModal = false;
          this.isMediaUrl = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    savePrimaryColor() {
      this.$axios
        .request({
          method: "patch",
          url: this.url,
          data: { primary_color: this.primaryColor },
        })
        .then((res) => {
          if (this.colors.indexOf(code) != -1) {
            this.closeMenu("showSlide");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveSecondaryColor() {
      this.$axios
        .request({
          method: "patch",
          url: this.url,
          data: { secondary_color: this.secondaryColor },
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
