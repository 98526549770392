<template>
  <div
    class="st-input__container"
    :class="containerClass"
    @mouseenter="hover = true"
    @mouseleave="hover= false"
  >
    <div
      v-if="styleType=='secondary'"
      class="w-full"
    >
      <!-- <label /> -->
      <input
        :id="stUid"
        :ref="'stInput'"
        class="secondary-input font-14p"
        :disabled="disabled"
        :name="name"
        :autocomplete="autocomplete"
        :focus="hasFocus"
        :placeholder="placeholder"
        :type="inputType"
        :value="value"
        :tabindex="tabIndex"
        v-on="inputListeners"
        @keyup.enter="handleEnter"
        @blur="$emit('blur', $event)"
      >
    </div>
    <div
      v-else-if="styleType=='rounded'"
      class="w-full"
    >
      <!-- <label /> -->
      <input
        :id="stUid"
        :ref="'stInput'"
        :class="{'w-full my-2 tw-py-4 !tw-rounded-lg !tw-px-4 tw-placeholder-black-three  font-14p':true, 'border-gray-100': errorClass==null, '!tw-border-red-500 !tw-outline-none':errorClass=='error' }"
        :disabled="disabled"
        :name="name"
        :autocomplete="autocomplete"
        :focus="hasFocus"
        :placeholder="placeholder"
        :type="inputType"
        :value="value"
        :tabindex="tabIndex"
        v-on="inputListeners"
        @keyup.enter="handleEnter"
        @blur="$emit('blur', $event)"
      >
    </div>
    <div
      v-else
      :class="[{
        'st-input': true,
        'st-input__focused': inputBoxFocused || hover,
        'st-input__label-above-spacing': label && showLabel,
        'st-input__hover-delete': hoverDelete && hover,
        'st-input--disabled': disabled
      }, inputContainerClasses, inputContainerClass]"
      @click="handleMobileClick"
    >
      <transition
        name="fade"
      >
        <label
          v-if="showLabelAbove"
          :for="stUid"
          :class="[{ 'st-input__show-label-above': true }, labelAboveClasses]"
        >
          {{ label }}
          <div
            v-if="newPassword"
            class="st-input-password__bars-container"
          >
            <div
              v-for="(bar, index) in 4"
              :key="bar.index"
              class="st-input-password__bar"
            >
              <transition-group
                name="fade"
                mode="out-in"
              >
                <div
                  v-if="passwordLevel >= index + 1"
                  :key="index"
                  :class="passwordClass()"
                />
              </transition-group>
            </div>
          </div>
        </label>
      </transition>
      <icon-wrapper
        v-if="showIcon"
        :class="{
          'st-input__icon': true,
          'st-input__icon--input-focused': inputBoxFocused || (hover && value),
        }"
        :icon-name="iconName"
      />
      <div
        class="st-input__align-content"
      >
        <!-- for="st-input" -->
        <label
          v-if="!inputBoxFocused && !value"
          :for="stUid"
          :class="[{
            'st-input__show-label-in-field': true,
            'st-input__show-label-in-field--disabled': disabled
          }, labelInFieldClass, labelInFieldClasses]"
          style="overflow: hidden"
          @click="reFocusInput()"
        >
          {{ label }}
        </label>
        <input
          :id="stUid"
          :ref="'stInput'"
          :class="[{
            'st-input__field': true,
            'st-input__field--toggle-mobile-on font-14p': inputBoxFocused,
            'st-input__label': inputBoxFocused && label,
            'st-input__field--disabled': disabled,
          }, inputClass]"
          :disabled="disabled"
          :name="name"
          :autocomplete="autocomplete"
          :focus="hasFocus"
          :placeholder="placeholder"
          :type="inputType"
          :value="value"
          :tabindex="tabIndex"
          v-on="inputListeners"
          @keyup.enter="handleEnter"
          @blur="$emit('blur', $event)"
        >
        <label
          v-if="password"
          :for="stUid"
          type="button"
          @click="viewPassword"
          @mousedown="flippingPasswordMode = true"
          @mouseup="flippingPasswordMode = false"
          @mouseout="flippingPasswordMode = false"
        >
          <icon-wrapper
            v-if="showPassword"
            :class="{
              'st-input__password-icon': true,
              'st-input__password-icon--value-entered': value && inputBoxFocused
            }"
            icon-name="hide-password"
          />
          <icon-wrapper
            v-else
            :class="{
              'st-input__password-icon': true,
              'st-input__password-icon--value-entered': value && inputBoxFocused
            }"
            icon-name="view-password"
          />
        </label>
      </div>
    </div>
    <div
      v-if="hoverDelete && (hover || !isDesktop)"
      class="st-input__trash-icon"
      @click="$emit('onDelete')"
    >
      <icon-wrapper icon-name="trash" />
    </div>
  </div>
</template>

<script>
import IconWrapper from '../common/icon-wrapper'
import StUid from '../../mixins/uid'
import isDesktop from '../../mixins/isDesktop'

export default {
  components: {
    'icon-wrapper': IconWrapper
  },
  mixins: [isDesktop, StUid],
  props: {
    errorClass: {
      type: String,
      required: false,
      default: null
    },
    containerClass: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Whether this should act like it has focus even when this element doesn't.
     * Intended for compound components to set focus more broadly
     */
    artificialFocus: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Blocks the input from usage. */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Used to change the type of icon shown to the left in the input. */
    iconName: {
      type: String,
      required: false,
      default: 'search'
    },
    /** Used to change the class of the <input>. */
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    inputContainerClass: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * Name of input field.
       */
    label: {
      type: String,
      required: false,
      default: ''
    },
    labelInFieldClass: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * Variant of input.
       */
    password: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Variant of input.
       */
    newPassword: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Example text of input field.
       */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * Determines if an icon appears in the input.
       */
    showIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
       * Determines if the label appears above the input.
       */
    showLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
       * Characters entered by the user.
       */
    value: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * input name
       */
    name: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    /**
       * Determines if hovering over input shows delete icon
       */
    hoverDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    /** Can be used to change the kind of input (ex: fullBorder) */
    variant: {
      type: Array,
      required: false,
      default: () => []
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    tabIndex: {
      type: [String, Number],
      required: false,
      default: ''
    },
    styleType: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      hover: false,
      hasFocus: false,
      flippingPasswordMode: false,
      passwordLevel: 0,
      showPassword: false,
      showPasswordBar: false
    }
  },
  computed: {
    inputBoxFocused () {
      return this.artificialFocus || this.hasFocus
    },
    inputContainerClasses () {
      return {
        'st-input--fullBorder': this.variant.includes('fullBorder'),
        'st-input--invalid': this.variant.includes('invalid')
      }
    },
    inputType () {
      if (this.password) {
        if (this.showPassword) {
          return 'text'
        }
        return 'password'
      } else if (this.name === 'user[email]') {
        return 'email'
      }
      return 'text'
    },
    inputListeners () {
      return {
        ...this.$listeners,
        input: this.handleInput,
        focus: this.onInputFocus,
        focusout: this.checkFocusOut
      }
    },
    labelAboveClasses () {
      return {
        'st-input__show-label-above--password': this.password,
        'st-input__show-label-above--persist': this.variant.includes('persistLabelAbove') && !this.hasFocus,
        'st-input__show-label-above--invalid': this.variant.includes('invalid')
      }
    },
    labelInFieldClasses () {
      return {
        'st-input__show-label-in-field--large': this.variant.includes('largeFieldLabel'),
        'st-input__show-label-in-field--invalid': this.variant.includes('invalid')
      }
    },
    showLabelAbove () {
      return (this.inputBoxFocused && this.showLabel) ||
      (this.hover && this.value && this.showLabel) ||
      (this.variant.includes('persistLabelAbove') && this.value)
    }
  },
  mounted () {
    if (this.autofocus) {
      setTimeout(() => {
        this.focus()
      }, 500)
    }
  },
  methods: {
    focus () {
      // pass focus html element calls to input element
      this.$refs.stInput.focus()
      this.$emit('focus')
    },
    handleMobileClick () {
      if (!this.isDesktop && this.showIcon) { this.reFocusInput() }
    },
    checkFocusOut (ev) {
      if (this.flippingPasswordMode) { return false }

      this.$emit('focusOut')
      this.hasFocus = false
      this.hover = false

      if (this.$listeners.hasOwnProperty('focusout')) { this.$listeners.focusout(ev) }
    },
    checkPasswordLevel (event) {
      if (event.target.value === null) { return }

      let roundedVal = Math.floor(event.target.value.length / 4)
      this.passwordLevel = Math.min(roundedVal, 4)
    },
    handleInput (ev) {
      this.$emit('input', ev.target.value)
      if (this.password) {
        this.$nextTick(() => {
          this.checkPasswordLevel(ev)
        })
      }
    },
    onInputFocus (ev) {
      this.hasFocus = true
      if (this.$listeners.hasOwnProperty('focus')) {
        this.$listeners.focus(ev)
      }
    },
    passwordClass () {
      return { [`st-input-password__bar--level-${this.passwordLevel}`]: true }
    },
    reFocusInput () {
      this.$refs.stInput.focus()
    },
    viewPassword () {
      this.reFocusInput()
      this.showPassword = !this.showPassword
    },
    handleEnter (ev) {
      this.$emit('emitEnter')
      this.hasFocus = false
    }
  }
}
</script>
