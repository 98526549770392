<template>
  <div class="">
    <st-toast-notification
      v-if="showToast"
      kind="info"
      title="Info"
      :sub-title="toastMessage"
      @click="showToast = false"
    />
    <!-- <div class="mobile-header">
      <a href="/welcome">
        <icon-wrapper
          class="mobile-header__spacetrics-icon"
          icon-name="spacetrics"
        />
      </a>
      <div class="mobile-header__button">
        <st-button
          :caption="'Save & Continue'"
          :disabled="buttonDisabled"
          @click="saveAndToHub"
        />
      </div>
    </div> -->
    <div>
      <!-- <div class="bx--col-sm-12 bx--col-xs-12">
        <div class="space-between">
          <div>
            <div class="st-edit-survey__back-and-save">
              <st-hyperlink
                :back-link="true"
                text="Back"
                @click.native="saveAndToHub"
              />
              <div class="st-edit-survey__mobile-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                />
                <st-hyperlink
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
            </div>
            <div class="st-edit-survey__header-and-icon st-edit-incentives__header">
              <icon-wrapper
                class="st-edit-survey__header-icon"
                icon-name="incentives"
              />
              <h1 class="st-edit-survey__page-header">
                Incentives
              </h1>
            </div>
            <p class="st-page-instructions st-edit-incentives__header-spacing">
              Add a reward to your survey (disbursement is done through the Admin Dashboard).
            </p>
          </div>
          <div>
            <div class="">
              <div class="st-edit-survey__inline-spiner-and-save">
                <st-inline-spinner
                  v-if="$store.state.isInlineSpinnerVisible"
                  class="st-edit-survey__desktop-inline-spinner"
                />
                <st-hyperlink
                  :disabled="isEditingDisabled"
                  icon-name="save"
                  text="Save"
                  @click="saveProgress"
                />
              </div>
              <st-button
                :caption="'Save & Continue'"
                :disabled="buttonDisabled"
                @click="saveAndToHub"
              />
            </div>
          </div>
        </div>
        <div class="bx--offset-lg-1 bx--col-lg-10 bx--offset-xxl-2 st-edit-incentives__selectables">
          <p class="st-edit-incentives__sub-header">
            Select Gift Card
          </p>
          <div class="st-edit-incentives__gift-cards">
            <st-card
              v-for="(amount) in amounts"
              :key="amount"
              :class="{
                'st-edit-incentives-gift-card__contents': true,
                'st-edit-incentives-gift-card__contents--disabled': isEditingDisabled,
                'st-edit-incentives-gift-card__contents--selected': amount === incentiveAmount
              }"
              @click="updateIncentiveAmount(amount)"
            >
              <template v-slot:card-content>
                <div class="st-edit-incentives-gift-card__contents-wrapper">
                  <div
                    class="st-edit-incentives-gift-card__amazon-logo"
                  />
                  <p class="st-edit-incentives-gift-card__type">
                    Amazon Gift Card
                  </p>
                  <p class="st-edit-incentives-gift-card__price">
                    ${{ amount.toFixed(2) }}
                  </p>
                </div>
              </template>
            </st-card>
          </div>
        </div>
      </div> -->

      <preview-bar :survey-id="survey.id" :show-preview="isStudyBeingCreated" />
      <!-- <div class="bx--col-sm-12  ">
        <steps
        v-if="isStudyBeingCreated"
        :step="3"
        :survey-id="survey.id"
        />
        <widget-steps
        v-else
        :step="3"
        :survey-id="survey.id"
        />
      </div> -->

      <div class="tw-flex">
        <study-sidebar :current-step="3" :survey-id="survey.id" :survey="survey" />
        <div class="tw-flex tw-justify-between tw-w-full">
          <div class="tw-w-full">
          <div class="flex flex-col h-full tw-px-20 tw-py-8 tw-max-h-[87vh] tw-overflow-auto scrollbar-track-gray-400 scrollbar-hover-black scrollbar-thumb-dark-gray-400 scrollbar-thin">
            <div class="tw-max-w-[550px] tw-mx-auto">
              <study-information :survey="survey" />
              <div class="tw-w-full tw-mt-10">
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-space-y-3"
                >
                  <span
                    class="font-28p font-600"
                    v-if="survey.rewardType == 'gift card' || !survey.reward"
                  >
                    Amazon Gift Cards
                  </span>
                  <span
                    class="font-28p font-600"
                    v-if="survey.reward && survey.rewardType == 'coupon'"
                  >
                    Discount code
                  </span>
                </div>
              </div>
              <div class="tw-w-full">
                
                <div class="mt-5" />
                <div v-if="!survey.reward">
                  <div
                    
                    class="st-edit-audience__anonymous-section mt-0-important tw-opacity-50  tw-cursor-not-allowed"
                  >
                    <div class="tw-pointer-events-none">
                      <div class="mt-5 ">
                      <div class="tw-flex tw-space-x-3 tw-flex-wrap">
                        <div
                          :class="
                            incentiveAmount === 5
                              ? 'tw-border-[3px] tw-border-secondary-dark'
                              : 'tw-border-[3px] tw-border-white-two'
                          "
                          class="tw-p-5 tw-text-center tw-border-solid tw-cursor-pointer tw-rounded-xl"
                          @click="updateIncentiveAmount(5)"
                        >
                          $5
                        </div>
                        <div
                          :class="
                            incentiveAmount === 10
                              ? 'tw-border-[3px] tw-border-secondary-dark'
                              : 'tw-border-[3px] tw-border-white-two'
                          "
                          class="tw-p-5 tw-text-center tw-border-solid tw-cursor-pointer tw-rounded-xl"
                          @click="updateIncentiveAmount(10)"
                        >
                          $10
                        </div>
                        <div
                          :class="
                            incentiveAmount === 20
                              ? 'tw-border-[3px] tw-border-secondary-dark'
                              : 'tw-border-[3px] tw-border-white-two'
                          "
                          class="tw-p-5 tw-text-center tw-border-solid tw-cursor-pointer tw-rounded-xl"
                          @click="updateIncentiveAmount(20)"
                        >
                          $20
                        </div>
                        <div
                          :class="
                            incentiveAmount === 50
                              ? 'tw-border-[3px] tw-border-secondary-dark'
                              : 'tw-border-[3px] tw-border-white-two'
                          "
                          class="tw-p-5 tw-text-center tw-border-solid tw-cursor-pointer tw-rounded-xl"
                          @click="updateIncentiveAmount(50)"
                        >
                          $50
                        </div>
                      </div>
                    </div>
                    <div class="mt-5">
                      <div>
                        <label class=""
                          >How many people do you want to complete this
                          study?*</label
                        >
                        <!-- :disabled="isEditingDisabled" -->
                        <st-input
                          v-model="totalRespondents"
                          class="max-w-500p"
                          style-type="rounded"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="tw-mt-10 tw-flex tw-space-x-3">
                      <span>
                        <svg
                          width="35"
                          height="18"
                          viewBox="0 0 35 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_77_16032)">
                            <path
                              d="M25.2385 0C22.1102 0 19.1877 1.56 17.5 4.04C15.8123 1.56 12.8898 0 9.7615 0C4.65739 0 0.5 4.04 0.5 9C0.5 13.96 4.65739 18 9.7615 18C12.8898 18 15.8123 16.44 17.5 13.96C19.1877 16.44 22.1102 18 25.2385 18C30.3426 18 34.5 13.96 34.5 9C34.5 4.04 30.3838 0 25.2385 0ZM25.2385 16.4C22.2337 16.4 19.4758 14.64 18.2409 11.96L17.5 10.28L16.7591 11.96C15.5242 14.64 12.8075 16.4 9.7615 16.4C5.56295 16.4 2.14649 13.08 2.14649 9C2.14649 4.92 5.56295 1.6 9.7615 1.6C12.7663 1.6 15.5242 3.32 16.7591 6.04L17.5 7.72L18.2409 6.04C19.4758 3.36 22.1925 1.6 25.2385 1.6C28.2845 1.6 31.1659 3.52 32.3184 6.2C31.5775 5.6 30.6719 5.2 29.6017 5.2C27.3378 5.2 25.5266 7 25.5266 9.16C25.5266 11.32 27.3789 13.12 29.6017 13.12C30.5073 13.12 31.3305 12.84 32.0303 12.36C30.7542 14.76 28.2022 16.4 25.2385 16.4Z"
                              fill="black"
                            />
                            <path
                              d="M12.7665 13.16C15.0171 13.16 16.8415 11.387 16.8415 9.2C16.8415 7.01295 15.0171 5.24 12.7665 5.24C10.5159 5.24 8.69141 7.01295 8.69141 9.2C8.69141 11.387 10.5159 13.16 12.7665 13.16Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_77_16032">
                              <rect
                                width="34"
                                height="18"
                                fill="white"
                                transform="translate(0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span class="tw-text-territory-dark">
                        Sending rewards must be done manually through our platform.
                      </span>
                    </div>
                    </div>
  
                    <!-- <div
                      class="st-edit-audience__anonymous-instructions mt-3 mb-0"
                    >
                      What would you like to offer?
                      <st-tooltip
                        class="st-edit-audience__anonymous-tooltip"
                        :direction="isDesktop ? 'top' : 'left'"
                        :max-width="isDesktop ? 411 : 250"
                        tip="This is about the type of reward you'll give to respondents. Amazon gift card, coupon code"
                      >
                        <icon-wrapper icon-name="info" />
                      </st-tooltip>
                    </div>
                    <div class="mt-3" />
                    <st-radio-button
                      v-model="isGiftCard"
                      class="st-radio-button__default-container"
                      :options="[
                        { caption: 'Amazon Gift card', value: true },
                        { caption: 'Discount code', value: false },
                      ]"
                    /> -->
                  </div>
                  <div class="tw-mt-4">
                    <ah-button type="secondary" size="large" @click="addRemoveIncentives(true)">Add incentives</ah-button>
                  </div>
                </div>
                <div v-if="survey.reward">
                  <div v-if="survey.reward && survey.rewardType == 'gift card'">
                  <div class="mt-5 ">
                    <div class="tw-flex tw-space-x-3 tw-flex-wrap">
                      <div
                        :class="
                          incentiveAmount === 5
                            ? 'tw-border-[3px] tw-border-secondary-dark'
                            : 'tw-border-[3px] tw-border-white-two'
                        "
                        class="tw-p-5 tw-text-center tw-border-solid tw-cursor-pointer tw-rounded-xl"
                        @click="updateIncentiveAmount(5)"
                      >
                        $5
                      </div>
                      <div
                        :class="
                          incentiveAmount === 10
                            ? 'tw-border-[3px] tw-border-secondary-dark'
                            : 'tw-border-[3px] tw-border-white-two'
                        "
                        class="tw-p-5 tw-text-center tw-border-solid tw-cursor-pointer tw-rounded-xl"
                        @click="updateIncentiveAmount(10)"
                      >
                        $10
                      </div>
                      <div
                        :class="
                          incentiveAmount === 20
                            ? 'tw-border-[3px] tw-border-secondary-dark'
                            : 'tw-border-[3px] tw-border-white-two'
                        "
                        class="tw-p-5 tw-text-center tw-border-solid tw-cursor-pointer tw-rounded-xl"
                        @click="updateIncentiveAmount(20)"
                      >
                        $20
                      </div>
                      <div
                        :class="
                          incentiveAmount === 50
                            ? 'tw-border-[3px] tw-border-secondary-dark'
                            : 'tw-border-[3px] tw-border-white-two'
                        "
                        class="tw-p-5 tw-text-center tw-border-solid tw-cursor-pointer tw-rounded-xl"
                        @click="updateIncentiveAmount(50)"
                      >
                        $50
                      </div>
                    </div>
                  </div>
                  <div class="mt-5">
                    <div>
                      <label class=""
                        >How many people do you want to complete this
                        study?*</label
                      >
                      <!-- :disabled="isEditingDisabled" -->
                      <st-input
                        v-model="totalRespondents"
                        class="max-w-500p"
                        style-type="rounded"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="tw-mt-10 tw-flex tw-space-x-3">
                    <span>
                      <svg
                        width="35"
                        height="18"
                        viewBox="0 0 35 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_77_16032)">
                          <path
                            d="M25.2385 0C22.1102 0 19.1877 1.56 17.5 4.04C15.8123 1.56 12.8898 0 9.7615 0C4.65739 0 0.5 4.04 0.5 9C0.5 13.96 4.65739 18 9.7615 18C12.8898 18 15.8123 16.44 17.5 13.96C19.1877 16.44 22.1102 18 25.2385 18C30.3426 18 34.5 13.96 34.5 9C34.5 4.04 30.3838 0 25.2385 0ZM25.2385 16.4C22.2337 16.4 19.4758 14.64 18.2409 11.96L17.5 10.28L16.7591 11.96C15.5242 14.64 12.8075 16.4 9.7615 16.4C5.56295 16.4 2.14649 13.08 2.14649 9C2.14649 4.92 5.56295 1.6 9.7615 1.6C12.7663 1.6 15.5242 3.32 16.7591 6.04L17.5 7.72L18.2409 6.04C19.4758 3.36 22.1925 1.6 25.2385 1.6C28.2845 1.6 31.1659 3.52 32.3184 6.2C31.5775 5.6 30.6719 5.2 29.6017 5.2C27.3378 5.2 25.5266 7 25.5266 9.16C25.5266 11.32 27.3789 13.12 29.6017 13.12C30.5073 13.12 31.3305 12.84 32.0303 12.36C30.7542 14.76 28.2022 16.4 25.2385 16.4Z"
                            fill="black"
                          />
                          <path
                            d="M12.7665 13.16C15.0171 13.16 16.8415 11.387 16.8415 9.2C16.8415 7.01295 15.0171 5.24 12.7665 5.24C10.5159 5.24 8.69141 7.01295 8.69141 9.2C8.69141 11.387 10.5159 13.16 12.7665 13.16Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_77_16032">
                            <rect
                              width="34"
                              height="18"
                              fill="white"
                              transform="translate(0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span class="tw-text-territory-dark">
                      Sending rewards must be done manually through our platform.
                    </span>
                  </div>
                  <div v-if="totalRespondents && incentiveAmount > 0" class="tw-flex tw-p-5 tw-mt-8 tw-flex-col tw-space-y-3 tw-border tw-border-solid tw-border-white-two tw-rounded-lg">
                    <p class="tw-font-semibold tw-text-lg">
                      Incentive summary
                    </p>
                    <p class="tw-flex tw-justify-between tw-items-center">
                      <span class="tw-text-black-two">
                        Total Gift cards to give
                      </span>
                      <span class="tw-font-semibold">
                        {{ totalRespondents }}
                      </span>
                    </p>
                    <p class="tw-flex tw-justify-between tw-items-center">
                      <span class="tw-text-black-two">
                        To be charged
                      </span>
                      <span class="tw-font-semibold">
                        ${{ budgetRequired.toLocaleString() }}
                      </span>
                    </p>
                  </div>
                </div>
                <div v-if="!isGiftCard && survey.rewardType == 'coupon'">
                  
                 
                  <div class="mt-5 tw-flex tw-space-x-3">
                    <div class="w-full ">
                      <label class="">Discount Title*</label>
                      <st-input
                        v-model="discountTitle"
                        class=""
                        style-type="rounded"
                        placeholder='EG: "get a free coffee"'
                      />
                    </div>
                    <div class="w-full">
                      <label class="">Discount code*</label>
                      <st-tooltip
                        class="st-edit-audience__anonymous-tooltip"
                        :direction="isDesktop ? 'top' : 'left'"
                        :max-width="isDesktop ? 411 : 250"
                        tip="Discount code like NEWYEAR"
                      >
                        <icon-wrapper icon-name="info" />
                      </st-tooltip>
                      <!-- :disabled="isEditingDisabled" -->
                      <st-input
                        v-model="discountCode"
                        class=""
                        style-type="rounded"
                        placeholder="NEWYEARSALE"
                      />
                    </div>
                  </div>
                  <div class="mt-5 tw-flex tw-space-x-3">
                    <div class="tw-w-full">
                    <label class="">Type</label>

                    <st-dropdown-menu
                      container-class="tw-h-[50px] mt-3 w-full border-none rounded-4p border-black tw-w-full w-full-important"
                      selected-class="rounded-4p border-gray-important bg-white min-w-full w-full-important"
                      :display-caption="true"
                      :initial-active-option="selectedDiscountType"
                      :options="discountTypeOptions"
                      :full-width="true"
                      :class="{ ' border-gray-100 rounded-4p w-full': true }"
                      @updateOption="changeDiscountTypeHandler"
                    />
                  </div>
                    <div class="tw-w-full">
                      <label class="">Discount value*</label>
                      <st-tooltip
                        class="st-edit-audience__anonymous-tooltip"
                        :direction="isDesktop ? 'top' : 'left'"
                        :max-width="isDesktop ? 411 : 250"
                        tip="Discount value like 20"
                      >
                        <icon-wrapper icon-name="info" />
                      </st-tooltip>
                      <!-- :disabled="isEditingDisabled" -->
                      <st-input
                        v-model="discountValue"
                        class="w-full"
                        style-type="rounded"
                        placeholder="20"
                        :error-class="discountValueError? 'error': null"
                        @input="updateDiscountValue"
                      />
                      <span class="tw-text-xs tw-text-error">
                        {{ discountValueError }}
                      </span>

                    </div>
                  </div>
                  <div class="tw-mt-10 tw-flex tw-space-x-3">
                    <span>
                      <svg
                        width="35"
                        height="18"
                        viewBox="0 0 35 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_77_16032)">
                          <path
                            d="M25.2385 0C22.1102 0 19.1877 1.56 17.5 4.04C15.8123 1.56 12.8898 0 9.7615 0C4.65739 0 0.5 4.04 0.5 9C0.5 13.96 4.65739 18 9.7615 18C12.8898 18 15.8123 16.44 17.5 13.96C19.1877 16.44 22.1102 18 25.2385 18C30.3426 18 34.5 13.96 34.5 9C34.5 4.04 30.3838 0 25.2385 0ZM25.2385 16.4C22.2337 16.4 19.4758 14.64 18.2409 11.96L17.5 10.28L16.7591 11.96C15.5242 14.64 12.8075 16.4 9.7615 16.4C5.56295 16.4 2.14649 13.08 2.14649 9C2.14649 4.92 5.56295 1.6 9.7615 1.6C12.7663 1.6 15.5242 3.32 16.7591 6.04L17.5 7.72L18.2409 6.04C19.4758 3.36 22.1925 1.6 25.2385 1.6C28.2845 1.6 31.1659 3.52 32.3184 6.2C31.5775 5.6 30.6719 5.2 29.6017 5.2C27.3378 5.2 25.5266 7 25.5266 9.16C25.5266 11.32 27.3789 13.12 29.6017 13.12C30.5073 13.12 31.3305 12.84 32.0303 12.36C30.7542 14.76 28.2022 16.4 25.2385 16.4Z"
                            fill="black"
                          />
                          <path
                            d="M12.7665 13.16C15.0171 13.16 16.8415 11.387 16.8415 9.2C16.8415 7.01295 15.0171 5.24 12.7665 5.24C10.5159 5.24 8.69141 7.01295 8.69141 9.2C8.69141 11.387 10.5159 13.16 12.7665 13.16Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_77_16032">
                            <rect
                              width="34"
                              height="18"
                              fill="white"
                              transform="translate(0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span class="tw-text-territory-dark">
                      This discount code will be visible automatically to everyone who completes this study.
                    </span>
                  </div>
                  <div class="tw-mt-8 tw-flex tw-space-x-3">
                    <ah-toggle-switch id="expiringOfferToggle" :checked="!isAllTimeOffer" @input="toggleAllTimeOffer"></ah-toggle-switch>
                    <span @click="toggleAllTimeOffer">
                      Set as expiring offer
                    </span>
                  </div>
                  <div v-if="!isGiftCard && !isAllTimeOffer">
                    <label class="tw-mt-5 tw-block">Redemption time*</label>

                    <st-dropdown-menu
                      container-class="tw-h-[50px] mt-3 w-full border-none rounded-4p border-black w-full-important"
                      selected-class="rounded-4p border-gray-important bg-white min-w-full w-full-important"
                      :display-caption="true"
                      :initial-active-option="redemptionTimeOptions[0]"
                      :options="redemptionTimeOptions"
                      :class="{ ' border-gray-100 rounded-4p w-full': true }"
                      @updateOption="changeRedemptionTimeHandler"
                    />
                  </div>
                </div>
                <div class="tw-mt-10">
                  <span class="tw-p-3 tw-cursor-pointer hover:tw-underline tw-text-black-two" @click="dontIncludeIncentives">
                    Don't include incentives
                  </span>
                </div>
                </div>
              </div>
            </div>
            <!-- <div class="st-edit-survey__back-and-save">
            <st-hyperlink
              :back-link="true"
              text="Back"
              @click="saveAndToHub"
            />
            <div class="st-edit-survey__mobile-save">
              <st-inline-spinner
                v-if="$store.state.isInlineSpinnerVisible"
              />
              <st-hyperlink
                v-if="!survey.published"
                :disabled="isEditingDisabled"
                icon-name="save"
                text="Save"
                @click="saveProgress"
              />
            </div>
          </div> -->
            <!-- <div class="st-edit-survey__header-and-icon">
            <icon-wrapper
              class="st-edit-survey__header-icon"
              icon-name="welcome"
            />
            <h1 class="st-edit-survey__page-header">
              Welcome Page
            </h1>
          </div> -->
            <!-- <p
            class="st-page-instructions st-edit-invitation__instructions-spacing"
          >
            This is the very first screen participants will see before taking the survey.
          </p> -->
            <div
              class="flex mt-5 pb-30 space-x-5 flex-col md:flex-row justify-between"
            >
              
              
              <!-- <div v-if="true" class=" border-left-gray-100 px-5">
                <div>
                  <p class="flex justify-between">
                    <span class="font-600">Total budget</span>
                    <span class="">${{ budgetRequired }}</span>
                  </p>
                  <div class="border-top-gray-100 my-3"></div>
                  <p class="flex justify-between mt-3">
                    <span class="font-600">Current reward Balance</span>
                    <span class="">${{ currentBalance / 100 }}</span>
                  </p>
                  <div class="flex space-x-2 items-center">
                    <div>
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1516_32409)">
                          <path
                            d="M8.48887 17C5.64483 17 2.1112 16.3709 0.732389 14.603C-1.0516 12.359 0.904879 7.94756 1.54239 6.65545C3.15426 3.34048 5.90338 0 8.48887 0C11.0744 0 13.8235 3.34048 15.4612 6.65545C16.0991 7.94756 18.0467 12.359 16.2712 14.603C14.8666 16.3709 11.3329 17 8.48887 17ZM13.9099 7.39531C12.3328 4.19946 9.95411 1.70011 8.48887 1.70011C7.02364 1.70011 4.64498 4.19909 3.06783 7.39495C1.74073 10.0892 1.34404 12.6224 2.06798 13.5575C2.79192 14.4926 5.47197 15.3003 8.4885 15.3003C11.505 15.3003 14.0817 14.6034 14.909 13.6001C15.7364 12.5969 15.2366 10.0895 13.9092 7.39531H13.9095H13.9099Z"
                            fill="#AC5555"
                          />
                          <path
                            d="M7.41152 12.3252C7.41152 12.912 7.8939 13.3878 8.48893 13.3878C9.08397 13.3878 9.56635 12.912 9.56635 12.3252C9.56635 11.7383 9.08397 11.2625 8.48893 11.2625C7.8939 11.2625 7.41152 11.7383 7.41152 12.3252Z"
                            fill="#AC5555"
                          />
                          <path
                            d="M8.48891 10.2003C8.26028 10.2003 8.04125 10.1107 7.87947 9.95112C7.71769 9.79156 7.6272 9.57554 7.6272 9.35005V5.9502C7.6272 5.64639 7.79156 5.36589 8.05787 5.21398C8.32418 5.06208 8.65327 5.06208 8.91995 5.21398C9.18662 5.36589 9.35062 5.64639 9.35062 5.9502V9.35005C9.35062 9.57554 9.25976 9.79156 9.09835 9.95112C8.93694 10.1107 8.71754 10.2003 8.48891 10.2003Z"
                            fill="#AC5555"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1516_32409">
                            <rect
                              width="17"
                              height="17"
                              fill="white"
                              transform="matrix(-1 0 0 1 17 0)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div class="text-warning">
                      <p>
                        Funds will be deposited as Reward balance into you
                        account within 2-3 business days.
                      </p>
                      <p class="mt-4">
                        You’ll need to wait until funds are deposited before
                        publishing your study.
                      </p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div
                  class="tw-flex tw-space-x-2 tw-max-w-[550px] tw-justify-end tw-w-full tw-mt-8"
                >
                  <!-- <div>
                    <ah-button
                      type="outlined"
                      @click="changeToStaticOrVideo('static')"
                    >
                      Change to static message
                    </ah-button>
                  </div> -->
                  <div class="tw-flex tw-space-x-2">
                    <ah-button @click="handleSave" :disabled="discountValueError">
                      Next step
                    </ah-button>
                  </div>
                </div>
          </div>
        </div>
        <phone-preview :survey="updatedSurvey" v-if="survey.reward" :step="3"></phone-preview>
        </div>
      </div>
    </div>
    <!-- <div class=" ">
      <div class="p-5 md:padding-container bg-white fixed bottom-0 w-full border-top-gray-100 ">
        <div class="flex w-full justify-between px-2 ">
          <a
            href="/"
            class="bg-gray-100 text-gray-two rounded-4p p-3 "
          >
            Cancel
          </a>
          <div class="flex items-center space-x-2 md:space-x-5">
            <div class="st-edit-survey__inline-spiner-and-save">
              <st-inline-spinner
                v-if="$store.state.isInlineSpinnerVisible"
                class="st-edit-survey__desktop-inline-spinner"
              />
              <button
                v-if="!survey.published"
                :disabled="isEditingDisabled"
                icon-name="save"
                text="Save"
                class="bg-create-group rounded-4p px-3 py-3 font-16p text-blue-primary"
                @click="saveProgress"
              >
                Save draft
              </button>
            </div>
            
            <st-button
            v-if="addReward && isGiftCard && budgetRequired>currentBalance/100"
              :caption="`Add funds ($${budgetRequired - currentBalance/100 }) & continue`"
              :disabled="isEditingDisabled"
              @click="chargeForGifts"
            />
            <st-button
              v-else
              :caption="'Save & Continue'"
              :disabled="isEditingDisabled"
              @click="handleSave"
            />
          </div>
        </div>
      </div>
    </div> -->
    <!-- <st-modal v-model="showChargeModal">
      <div>
        <p class="font-18p font-600">Add funds</p>
        <p class="mt-4">
          You are about to be charged
        </p>
        <p class="mt-2 font-600">
          ${{ budgetRequired - currentBalance / 100 }}
        </p>
      </div>
      <div v-if="useExistingPayment" class="mt-4">
        <p class="font-bold">
          {{ paymentInformation.brand }} ending with
          {{ paymentInformation.last4 }}
        </p>
        <p>
          Expires {{ paymentInformation.expireMonth }}/{{
            paymentInformation.expireYear
          }}
        </p>
      </div>
      <div class="py-4">
        <st-radio-button
          v-model="useExistingPayment"
          :block="true"
          :onChangeHandler="paymentMethodChangeHandler"
          class="st-radio-button__default-container"
          :options="[
            { caption: 'Use existing payment method', value: true },
            { caption: 'Add new payment method', value: false },
          ]"
        />
      </div>
      <div class="flex space-x-2 justify-end mt-10">
        <button
          class="bg-gray-100 text-gray-two rounded-4p p-3 "
          @click="showChargeModal = false"
        >
          Cancel
        </button>
        <st-button
          :caption="chargeButtonCaption"
          :disabled="isEditingDisabled"
          @click="chargeForGiftsAndContinue"
        />
      </div>
    </st-modal> -->
    <st-modal v-model="showExcludeIncentivesModal">
      <div>
        <p class="font-18p font-600">Don’t include incentives</p>
        <p class="mt-4 tw-text-black-three">
          Are you sure you do not want to include incentives in this study? These are very supportive of users completing this survey. However, if you wish to add incentives later, you can do so from the main page of this study.
        </p>
        </div>
      <div class="flex space-x-2 justify-end mt-10">
        <ah-button
          type="outlined"
          @click="showExcludeIncentivesModal = false"
        >
          Cancel
        </ah-button>
        <st-button
          type="secondary"
          caption="Don’t include incentives"
          @click="addRemoveIncentives(false)"
        />
      </div>
    </st-modal>
    <!-- <stripe-payment-form ref="stripeForm"></stripe-payment-form> -->
  </div>
</template>

<script>
import {
  IconWrapper,
  StButton,
  StCard,
  StHyperlink,
  StripePaymentForm,
  StToastNotification,
  StRadioButton,
  StTooltip,
  StInlineSpinner,
  StDropdownMenu,
  StInput,
  StModal,
  AhToggleSwitch,
  PhonePreview,
  AhButton,
} from "../../common";
import isEditingDisabled from "./isEditingDisabled";
import saveUpdates from "./saveUpdates";
import Steps from "./steps.vue";
import WidgetSteps from "./widget-steps.vue";
import { isDesktop } from "../../../mixins";
import { REWARD_TYPES } from "./constants";
import { mapState } from "vuex";
import PreviewBar from "./preview-bar.vue";
import WidgetConfiguration from "./widget-configuration.vue";
import StudySidebar from "./study-sidebar.vue";
import StudyInformation from "./study-information.vue";
export default {
  components: {
    IconWrapper,
    StDropdownMenu,
    AhButton,
    StButton,
    StCard,
    StHyperlink,
    Steps,
    WidgetSteps,
    StModal,
    PhonePreview,
    StToastNotification,
    StRadioButton,
    StTooltip,
    StInput,
    StInlineSpinner,
    WidgetConfiguration,
    StripePaymentForm,
    PreviewBar,
    StudySidebar,
    StudyInformation,
    AhToggleSwitch
  },
  mixins: [isEditingDisabled, saveUpdates, isDesktop],
  props: {
    survey: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      updatedSurvey: this.survey,
      chargingAmount: false,
      useExistingPayment: true,
      showChargeModal: false,
      currentBalance: 0,
      isAllTimeOffer: true,
      discountTitle: "",
      showExcludeIncentivesModal: false,
      redemptionTimeOptions: ["3 Hours", "24 Hours", "72 Hours"],
      discountTypeOptions: ["Discount %", "Credit $", ],
      selectedDiscountType : "Credit %",
      defaultRedemptionTime: "3 Hours",
      isGiftCard: false,
      totalRespondents: null,
      discountCode: null,
      discountValue: null,
      addReward: false,
      discountValueError: null,
      amounts: [5, 10, 15, 20, 25, 30, 40, 50],
      incentiveAmount: this.survey.incentiveAmount,
      rewardTypeOptions: ["Amazon Gift card", "Discount code"],
      paymentInformation: {},
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    // isFREEPlan() {
    //   return this.currentUser?.account?.plan.title==="FREE"? true: false;
    // },
    chargeButtonCaption() {
      if (this.chargingAmount) {
        return "Charging amount...";
      } else {
        return `Charge $${this.budgetRequired - this.currentBalance / 100}`;
      }
    },
    isStudyBeingCreated() {
      return this.survey.surveyType === "study";
    },
    backURL() {
      return `/study/${this.survey.id}/edit/landing_page`;
    },
    buttonDisabled() {
      return (
        (this.incentiveAmount === 0 && !this.isEditingDisabled) ||
        this.isEditingDisabled
      );
    },
    budgetRequired() {
      if (!this.totalRespondents || !this.incentiveAmount) {
        return 0;
      }
      return Number(this.totalRespondents) * this.incentiveAmount;
    },
  },
  mounted() {
    
    // console.log(this.survey);
    if(this.survey.couponType=='discount'){
      this.selectedDiscountType = "Discount %"
    }
    else if(this.survey.couponType=='credit'){
      this.selectedDiscountType = "Credit $"
    }
    else{
      this.selectedDiscountType = "Discount %"
    }
    this.totalRespondents = this.survey.totalRespondents
      ? String(this.survey.totalRespondents)
      : "";
    this.coupon
    this.addReward = this.survey.reward ?? false;
    this.isGiftCard = this.survey.rewardType === REWARD_TYPES.GIFT_CARD;
    this.incentiveAmount = this.survey.incentiveAmount;
    this.discountCode = this.survey.couponCode;
    this.discountTitle = this.survey.couponTitle;
    this.discountValue = this.survey.couponValue;
    this.isAllTimeOffer = this.survey.redemptionTime > 0 ? false : true;
    // setTimeout(() => {
    //   this.checkBalance();
    //   this.checkPaymentInformation();
    // }, 500);
  },
  methods: {
    updateDiscountValue(){
      if(this.selectedDiscountType === "Discount %"){
        if(this.discountValue > 100){
          this.discountValueError = "Discount value can't be greater than 100"
        }
        else{
          this.discountValueError = null
        }
      }
      else{
        this.discountValueError = null
      }
      this.updatedSurvey.couponValue = this.discountValue;
    },
    updateIncentiveAmount(amount) {
      if (this.isEditingDisabled) {
        return;
      }
      this.incentiveAmount = amount === this.incentiveAmount ? 0 : amount;
      this.updatedSurvey.incentiveAmount = amount;
    },
    toggleAllTimeOffer() {
      this.isAllTimeOffer = !this.isAllTimeOffer;
    },
    paymentMethodChangeHandler() {
      if (!this.useExistingPayment) {
        this.$refs.stripeForm.toggle();
      }
    },

    updateSurveyData(data){
      this.$axios
        .request({
          method: "patch",
          url: `study/${this.survey.id}/`,
          data: {
            survey: { ...data },
          },
        })
        .then((res) => {
          // console.log("saved");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateSurvey() {
      let data = { reward: false };
      if (this.addReward) {
        data = {
          reward: this.addReward,
          reward_type: this.isGiftCard
            ? REWARD_TYPES.GIFT_CARD
            : REWARD_TYPES.COUPON,
          coupon_title: this.discountTitle,
          coupon_code: this.discountCode,
          coupon_value: this.discountValue,
          incentive_amount: this.incentiveAmount,
          total_respondents: this.totalRespondents
            ? Number(this.totalRespondents)
            : null,
          redemption_time: !this.isAllTimeOffer
            ? Number(this.defaultRedemptionTime.split(" ")[0])
            : 0,
        };
      }
      return this.$axios
        .request({
          method: "patch",
          url: `study/${this.survey.id}/`,
          data: {
            survey: { ...data },
          },
        })
        .then((res) => {
          // console.log("saved");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    chargeForGifts() {
      //  this.checkPaymentInformation()
      if (!Object.values(this.paymentInformation).length) {
        this.$refs.stripeForm.toggle();
      } else {
        this.showChargeModal = true;
      }
    },
    chargeForGiftsAndContinue() {
      this.chargingAmount = true;
      this.$axios
        .request({
          method: "post",
          url: `charges/create_charges`,
          data: {
            charges: this.budgetRequired * 100,
          },
        })
        .then((res) => {
          this.chargingAmount = false;
          this.showChargeModal = false;
          this.handleSave();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkPaymentInformation() {
      this.$axios
        .request({
          method: "get",
          url: `payments/payment_information`,
        })
        .then(({ data }) => {
          const info = {};
          info.brand = data.data.brand;
          info.last4 = data.data.last4;
          info.expireYear = data.data.exp_year;
          info.expireMonth = data.data.exp_month;
          this.paymentInformation = { ...info };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkBalance() {
      this.$axios
        .request({
          method: "get",
          url: `charges/check_balance`,
        })
        .then((res) => {
          this.currentBalance = res.data.balance;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dontIncludeIncentives() {
      this.showExcludeIncentivesModal = true;
    },
    addRemoveIncentives(type){
      this.addReward = type;
      this.survey.reward = type;
      this.survey.rewardType = REWARD_TYPES.GIFT_CARD;
      this.saveAndToHub("segments");
    },
    changeRedemptionTimeHandler(value) {
      this.defaultRedemptionTime = value;
    },
    changeDiscountTypeHandler(value) {
      let type
      if(value.includes('$')){
        type = 'credit'
      }
      else{
        type = 'percent'
      }
      this.updatedSurvey.couponType = type;
      this.selectedDiscountType = value;
      this.updateSurveyData({ coupon_type: type });
    },
    handleSave() {
      this.saveAndToHub('segments')
      // if (this.addReward) {
      //   if (this.isGiftCard) {
      //     if (this.incentiveAmount === 0) {
      //     }
      //   }
      // }

      // if (this.isStudyBeingCreated) {
      //   this.saveAndToHub("content");
      //   return;
      // }

      // if (!this.isStudyBeingCreated) {
      //   this.saveProgress();
      //   this.saveAndToHub("widget");
      //   return;
      // }
    },
  },
};
</script>
